import React from 'react'

import { selectUser } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { getFormattedDate } from '@helpers/ui/date'

interface CollectionDateProps {
  date: string
  pattern: string
}

export const CollectionDate: React.FC<CollectionDateProps> = ({ date, pattern }) => {
  const { timezone } = useAppSelector(selectUser)

  return <>{getFormattedDate(pattern, date, timezone) || '-'}</>
}
