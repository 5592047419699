import React from 'react'
import { Box } from '@mui/material'
import { RegisterOptions } from 'react-hook-form'

import { Dropdown, Input } from '@components/shared/sampleFields'
import { IOption } from '@models/common/app'

import { DropdownContainer, DropdownInputContainer } from './styles'

interface DropdownInputProps {
  name: string
  dropdownName?: string
  dropdownOptions?: IOption[]
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  placeholder?: string
  defaultValue?: string
  width?: string
  dropdownWidth?: string
  inputDisabled?: boolean
  isCopy?: boolean
  pr?: string
  type?: string
}

export const DropdownInput: React.FC<DropdownInputProps> = React.memo(props => {
  const {
    name,
    dropdownName = '',
    dropdownOptions,
    placeholder,
    rules,
    defaultValue,
    width,
    dropdownWidth,
    inputDisabled,
    isCopy,
    pr,
    type,
  } = props

  return (
    <Box sx={{ width: width || '100%' }}>
      <DropdownInputContainer>
        <Input
          type={type}
          disabled={inputDisabled}
          placeholder={placeholder}
          defaultValue={defaultValue}
          rules={rules}
          width='100%'
          name={name}
          isCopy={isCopy}
          pr={pr}
        />
        <DropdownContainer>
          <Dropdown
            width={dropdownWidth}
            variant='standard'
            name={dropdownName}
            options={dropdownOptions}
          />
        </DropdownContainer>
      </DropdownInputContainer>
    </Box>
  )
})
