import { styled, Chip, ChipProps } from '@mui/material'

interface StyledChipProps extends ChipProps {
  $isAdditionalStatus?: boolean
  $isMobile?: boolean
  $isLightGrey?: boolean
  $isMethodChip?: boolean
}

export const StyledChip = styled<React.FC<StyledChipProps>>(Chip)`
  font-size: 12px;
  line-height: 14px;
  font-weight: ${({ $isAdditionalStatus }) => ($isAdditionalStatus ? 500 : 400)};
  color: ${({ $isAdditionalStatus }) => ($isAdditionalStatus ? '#616D84' : '#0f1934')};
  background-color: ${({ theme }) => theme.additional.stroke.main};
  border-radius: 28px;
  min-width: 74px;
  height: auto;

  &.MuiChip-filled {
    padding: ${({ $isMobile }) => ($isMobile ? '2px' : '4px 2px')};
  }

  &.MuiChip-colorDefault {
    padding: ${({ $isAdditionalStatus }) => ($isAdditionalStatus ? '8px 20px' : '6px 6px')};
    font-size: ${({ $isAdditionalStatus }) => ($isAdditionalStatus ? '14px' : '12px')};
  }

  &.MuiChip-colorSuccess,
  &.MuiChip-colorError,
  &.MuiChip-colorInfo,
  &.MuiChip-colorWarning {
    font-size: 0.875rem;
    line-height: 1.43;
    font-weight: 500;
    border-radius: 35px;
  }

  &.MuiChip-colorSuccess {
    background-color: #e2f5ea;
    color: #18954b;
  }

  &.MuiChip-colorError {
    background-color: #fce4e4;
    color: #af4645;
    border-radius: 53px;
  }

  &.MuiChip-colorInfo {
    background-color: #eaf8fc;
    color: #076aa2;
  }

  &.MuiChip-colorSecondary {
    font-weight: 500;
    font-size: ${({ $isMethodChip }) => ($isMethodChip ? '12px' : '14px')};
    line-height: 20px;
    background-color: ${({ theme, $isLightGrey, $isMethodChip }) =>
      $isLightGrey || $isMethodChip ? theme.additional.chosen : theme.additional.stroke.main};
    color: ${({ theme, $isMethodChip }) =>
      $isMethodChip ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }

  &.MuiChip-colorWarning {
    background-color: #fcf5dd;
    color: #b08904;
  }
`

interface StyledChipMainProps extends ChipProps {
  $isMainChip: boolean
}

export const StyledChipMain = styled<React.FC<StyledChipMainProps>>(Chip)`
  padding: 4px 0;
  background: ${({ theme, $isMainChip }) => ($isMainChip ? theme.primary[20] : theme.primary[30])};
  margin-right: 10px;
  font-size: 14px;
  font-weight: ${({ $isMainChip }) => ($isMainChip ? 600 : 400)};

  & .MuiChip-filled {
    display: block;
  }

  & .MuiChip-icon {
    margin-left: 12px;
    max-width: 6px;
    max-height: 6px;
  }
`

export const IconChip = styled('span')<{ $isMainChip: boolean }>`
  display: ${({ $isMainChip }) => ($isMainChip ? 'block' : 'none')};
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.main};
`
