import React from 'react'
import { TabContext } from '@mui/lab'
import { StringParam, useQueryParam } from 'use-query-params'
import { useMediaQuery } from '@mui/material'

import { ITasksTabValue } from '@models/tasks'
import { StyledFlexBox } from '@styles'
import { DESIRED_TASKS } from '@constants/tasks'

import { TabButtons, TabContent } from './components'

interface TabsProps {
  shouldHiddenTabButtons: boolean
  shouldHiddenTabButtonsHandler: () => void
}

export const Tabs: React.FC<TabsProps> = props => {
  const { shouldHiddenTabButtons, shouldHiddenTabButtonsHandler } = props
  const [queryString, setQueryString] = useQueryParam('tasks_type', StringParam)
  const [, setSearchQueryString] = useQueryParam(DESIRED_TASKS, StringParam)

  const isMobile = useMediaQuery('(max-width:767px)')

  let queryTab: ITasksTabValue

  switch (queryString) {
    case 'awaiting':
      queryTab = 'awaiting'
      break

    case 'completed':
      queryTab = 'completed'
      break

    default:
      queryTab = 'awaiting'
      break
  }

  const [currentTab, setCurrentTab] = React.useState<ITasksTabValue>(queryTab)

  React.useEffect(() => {
    setCurrentTab(queryTab)
    setSearchQueryString(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString, queryTab])

  return (
    <TabContext value={currentTab}>
      {isMobile ? (
        <StyledFlexBox
          $isMobile={isMobile}
          $sticky
          $top='62px'
          $withoutPadding={shouldHiddenTabButtons}
        >
          <TabButtons
            onChangeTab={setCurrentTab}
            onChangeQuery={setQueryString}
            shouldHiddenTabButtons={shouldHiddenTabButtons}
            shouldHiddenTabButtonsHandler={shouldHiddenTabButtonsHandler}
          />
        </StyledFlexBox>
      ) : (
        <TabButtons
          onChangeTab={setCurrentTab}
          onChangeQuery={setQueryString}
          shouldHiddenTabButtons={shouldHiddenTabButtons}
          shouldHiddenTabButtonsHandler={shouldHiddenTabButtonsHandler}
        />
      )}
      <TabContent />
    </TabContext>
  )
}
