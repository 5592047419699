import { styled as styledMUI, Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledBoxRemoveSkill = styledMUI(Box)`
  padding: 24px; 
  border: 1px solid #E5E9F1;
  border-radius: 8px;
  box-shadow: -5px 0px 15px rgb(53, 108, 151, 0.12);
  color: #616D84;
  margin-bottom: 27px;
`

export const StyledParagraphNumber = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  padding: 3px;
  font-size: 12px;
  font-weight: 700;
  background-color: #eaf8fc;
  border-radius: 50%;
  text-align: center;
  color: #076aa2;
`

export const StyledTypographyRemoveSkill = styledMUI(Typography)`
  position: relative;
  font-size: 14px;
  margin-left: 35px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`
