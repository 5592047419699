import { useAppDispatch } from '@hooks/redux'

export const useToggleSample = (
  sampleId: number,
  selectCallback: (samples: number[]) => {
    payload: number[]
    type: string
  },
  selectedSamples: number[]
) => {
  const dispatch = useAppDispatch()
  const indexOfSample = selectedSamples.indexOf(sampleId)

  const selectSampleHandler = () => {
    let newCheckedSampleId: number[] = []

    if (indexOfSample === -1) {
      newCheckedSampleId = newCheckedSampleId.concat(selectedSamples, sampleId)
    } else if (indexOfSample === 0) {
      newCheckedSampleId = newCheckedSampleId.concat(selectedSamples.slice(1))
    } else if (indexOfSample === selectedSamples.length - 1) {
      newCheckedSampleId = newCheckedSampleId.concat(selectedSamples.slice(0, -1))
    } else if (indexOfSample > 0) {
      newCheckedSampleId = newCheckedSampleId.concat(
        selectedSamples.slice(0, indexOfSample),
        selectedSamples.slice(indexOfSample + 1)
      )
    }

    dispatch(selectCallback(newCheckedSampleId))
  }

  return { selectSampleHandler, isSelected: indexOfSample !== -1 }
}
