import * as React from 'react'
import Box from '@mui/material/Box'

import { MobileCardLoader } from '@components/shared'

import { MobileRow } from './MobileRow'
import { MobileTableProps } from './mobileTable.types'

const renderRowsLoader = () =>
  new Array(10).fill('').map((_, idx) => (
    <Box mt={1} key={idx}>
      <MobileCardLoader />
    </Box>
  ))

export const MobileTable: React.FC<MobileTableProps> = props => {
  const { tasks, isMobile, isFetchingNextPage, contentType } = props

  return (
    <>
      <Box>
        {tasks?.map(task => (
          <MobileRow key={task.id} task={task} contentType={contentType} isMobile={isMobile} />
        ))}
      </Box>

      {isFetchingNextPage && renderRowsLoader()}
    </>
  )
}
