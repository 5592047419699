import React from 'react'
import { Typography } from '@mui/material'

import { POSITIVE } from '@constants/tasks'
import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'

interface PosNegResultProps {
  name: string
  resultOfTest?: string
  widthName?: string
  widthResult?: string
  short?: boolean
}

export const PosNegResult: React.FC<PosNegResultProps> = ({
  name,
  resultOfTest = '',
  widthName,
  widthResult,
  short = true,
}) => {
  return (
    <>
      <Tooltip
        isPureTooltip
        title={name || ''}
        shouldBeHidden={((name && name.length) || 0) <= TRUNCATED_TEXT_LENGTH_S}
      >
        <Typography variant='body1' width={widthName}>
          {name ? stringSlice(name, TRUNCATED_TEXT_LENGTH_S) : '-'}
        </Typography>
      </Tooltip>
      <Typography
        variant='body1'
        color={resultOfTest?.toLocaleLowerCase() === POSITIVE ? 'success.dark' : 'error.light'}
        fontWeight={500}
        width={widthResult}
      >
        {resultOfTest ? (short ? resultOfTest?.slice(0, 3) : resultOfTest) : '-'}
      </Typography>
    </>
  )
}
