import { Collapse, styled, CollapseProps, Box, IconButton } from '@mui/material'
import { IoClose } from 'react-icons/io5'

import { Button } from '@components/shared'

interface StyledNotificationProps extends CollapseProps {
  $isMobile?: boolean
}

export const StyledNotification = styled<React.FC<StyledNotificationProps>>(Collapse)`
  position: fixed;
  top: ${({ theme }) => theme.header.height + 40 + 'px'};
  right: 80px;
  z-index: 9999;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 3px 20px rgba(229, 233, 241, 0.8);
  padding: 20px;
`

export const NotificationIcon = styled('img')`
  align-items: self-end;
  width: 20px;
  height: 20px;
  margin-right: 10px;
`
export const StyledBox = styled(Box)`
  display: flex;
  max-width: 300px;
  margin-bottom: 23px;
`
export const ButtonBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
`

export const StyledButton = styled(Button)`
  height: 32px;
`
export const IconClose = styled(IoClose)`
  width: 20px;
  height: 20px;
`

export const StyledIconButton = styled(IconButton)`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 2px 2px 8px rgba(71, 93, 168, 0.2);
  position: absolute;
  top: -10px;
  left: -10px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error.light};

    & > svg {
      fill: ${({ theme }) => theme.palette.common.white};
    }
  }

  &:target {
    display: none;
  }
`
