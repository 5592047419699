import { styled } from '@mui/material'

export const Wrapper = styled('div')`
  margin: 8px 0;
  padding: 8px 20px;
  border: 1px solid rgba(157, 170, 200, 0.2);
  border-radius: 10px;
`
export const CheckboxNameWrap = styled('label')`
  display: flex;
  align-items: center;
  margin-left: -9px;
  cursor: pointer;
`
export const Name = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-weight: 700;
  color: #282b29;
`
export const CounterBox = styled('div')`
  display: inline-flex;
  align-items: center;
  margin: 3px 0 8px;
  padding: 0 12px;
  height: 32px;
  border: 1px solid #e1e7f3;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
`
export const CounterBtn = styled('button')`
  position: relative;
  width: 14px;
  height: 14px;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: transparent;
  &:after {
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    border-top: 2px solid #adb4c2;
  }
  &[data-type='increment']:before {
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    content: '';
    width: 100%;
    height: 100%;
    border-left: 2px solid #adb4c2;
  }
  &[data-disable='true'] {
    &:after,
    &:before {
      border-color: #e1e7f3;
    }
  }
`
export const CounterNumber = styled('span')`
  display: inline-block;
  padding-top: 4px;
  width: 44px;
  color: #616d84;
  text-align: center;
`
