import React from 'react'
import { IconButton } from '@mui/material'

import { useFieldArrayFormContext } from '@context'
import activeDeleteIcon from '@assets/icons/delete-icon-active.svg'
import disabledDeleteIcon from '@assets/icons/delete-icon-disabled.svg'

import { StyledIconDelete } from './styles'

interface DeleteSampleButtonProps {
  sampleIdx: number
}

export const DeleteSampleButton: React.FC<DeleteSampleButtonProps> = ({ sampleIdx }) => {
  const { fields: samples, remove } = useFieldArrayFormContext()

  const deleteSampleHandler = () => remove(sampleIdx)

  return (
    <IconButton aria-label='delete' disabled={samples.length < 2} onClick={deleteSampleHandler}>
      <StyledIconDelete
        src={samples.length < 2 ? disabledDeleteIcon : activeDeleteIcon}
        alt='delete'
      />
    </IconButton>
  )
}
