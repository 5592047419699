import { Typography } from '@mui/material'
import React from 'react'

import { Chip, ParagraphLink, Tooltip } from '@components/shared'
import { ReactComponent as InfoIcon } from '@assets/icons/info.svg'
import { HrTestMethod } from '@models/tasks'
import { DrugsModal } from '@components/shared/Hr/TandemMS/DrugsModal'
import { useAppDispatch } from '@hooks/redux'
import { setDataAndPosition } from '@state/common/tooltipState'

import { isOtherMethod } from './helpers'

interface TestTypeProps {
  testTypeMethod: HrTestMethod | null
  chipStatus?: string | null
}

export const MethodChip: React.FC<TestTypeProps> = ({
  testTypeMethod,
  chipStatus = 'methodChip',
}) => {
  const dispatch = useAppDispatch()
  const [openModal, setOpenModal] = React.useState(false)

  const tooltipTitle = React.useMemo(() => {
    if (testTypeMethod?.name) {
      return (
        <>
          <Typography m='0' fontSize='13px'>
            <span style={{ fontWeight: 500 }}>{testTypeMethod.name}</span> (
            {testTypeMethod.description})
          </Typography>
          <ParagraphLink
            fontSize='13px'
            width='fit-content'
            onClick={() => {
              dispatch(setDataAndPosition(null)) //hide tooltip
              setOpenModal(true)
            }}
          >
            List of supported drugs
          </ParagraphLink>
        </>
      )
    } else
      return (
        <Typography m='0' fontSize='13px'>
          <Typography m='0' fontSize='13px' fontWeight={500}>
            Method is not specified
          </Typography>
          Selecting a method is at Lab Assistant's discretion
        </Typography>
      )
  }, [testTypeMethod?.description, testTypeMethod?.name, dispatch])

  const isIcon = () => {
    return isOtherMethod(testTypeMethod?.name) ? <></> : <InfoIcon />
  }

  return (
    <>
      <Tooltip
        isPureTooltip
        title={tooltipTitle}
        maxWidth={250}
        shouldBeHidden={isOtherMethod(testTypeMethod?.name) || false}
      >
        <Chip
          label={testTypeMethod?.name || 'Not specified'}
          deleteIcon={isIcon()}
          onDelete={() => null}
          status={chipStatus}
        />
      </Tooltip>
      {testTypeMethod ? (
        <DrugsModal open={openModal} method={testTypeMethod} onClose={() => setOpenModal(false)} />
      ) : null}
    </>
  )
}
