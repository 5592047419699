import React from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { BlockchainHistory, Breadcrumbs, Button } from '@components/shared'
import { protectedRoutes } from '@configs/routes.config'
import { PageTitle, Link } from '@styles'
import { IPackage } from '@models/packages'
import { IBreadcrumbsRoute } from '@models/common/app'
import { isContainRoute } from '@helpers/routes'

import { StyledBox, StyledPageBox } from './styles'

interface HeaderProps {
  packageItem: IPackage
  packageId: string
  packageLoading: boolean
  packageTooltip: string
}

export const PageHeaderPackageDetails: React.FC<HeaderProps> = ({
  packageItem,
  packageId,
  packageLoading,
  packageTooltip,
}) => {
  const history = useHistory()
  const { state } = useLocation<IBreadcrumbsRoute[]>()
  const { path, url } = useRouteMatch()

  const generateQrEndpoint = {
    pathname: protectedRoutes.generateQr.path.replace(':packageId', `${packageId}`),
    state: history.location.state,
  }

  React.useEffect(() => {
    if (state && !isContainRoute(state, url)) {
      history.replace({
        state: [
          ...state,
          {
            path,
            url,
            title: `${protectedRoutes.packageDetails.name} ${packageItem.specific_id}`,
          },
        ],
      })
    }
  }, [history, packageItem.specific_id, path, state, url])

  return (
    <>
      <StyledPageBox>
        <StyledBox>
          <PageTitle>Package details</PageTitle>
          <Link to={generateQrEndpoint} $isTextDecorationAbsence>
            <Button variant='contained' loading={packageLoading} disabled={packageLoading}>
              {packageItem?.generated_qr_code ? 'Scan' : 'Generate'} QR-code
            </Button>
          </Link>
        </StyledBox>

        <BlockchainHistory blockchainHistoryType='packages' itemId={Number(packageId)} />
      </StyledPageBox>
      <Breadcrumbs packageTooltip={packageTooltip} />
    </>
  )
}
