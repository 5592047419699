import React from 'react'

import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { StyledTextCell } from '@styles'

interface CollectionLocationProps {
  location: string
}
const TRUNCATED_TEXT_LENGTH = 37

export const CollectionLocation: React.FC<CollectionLocationProps> = ({ location }) => {
  return (
    <Tooltip
      isPureTooltip
      title={location || ''}
      shouldBeHidden={!location?.length || location?.length < TRUNCATED_TEXT_LENGTH}
    >
      <StyledTextCell>
        {location ? stringSlice(location, TRUNCATED_TEXT_LENGTH) : '-'}
      </StyledTextCell>
    </Tooltip>
  )
}
