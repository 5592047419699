import React from 'react'

import { IPackage } from '@models/packages'
import { StyledPackageInfo } from '@styles'

import { PackageInfoMain } from './PackageInfoMain'
import { PackageInfoDelivery } from './PackageInfoDelivery'
import { PackageAttachments } from './PackageAttachments'

interface PackageInfoProps {
  data: IPackage
}

export const PackageInfo: React.FC<PackageInfoProps> = ({ data }) => {
  const {
    id,
    specific_id,
    destination,
    delivery_status,
    quantity,
    expected_date,
    actual_date,
    updated_at,
    source,
    units,
    unit_type,
    currency,
    price_per_unit,
    total_price,
    notes,
    max_temperature,
    min_temperature,
    max_temperature_measurement_format,
    min_temperature_measurement_format,
    toxicity_level,
    delivery_service,
    tracking_number,
    package_type,
  } = data

  return (
    <StyledPackageInfo>
      <PackageInfoMain
        {...{
          id,
          specific_id,
          quantity,
          updated_at,
          units,
          unit_type,
          currency,
          price_per_unit,
          total_price,
          source,
          max_temperature,
          min_temperature,
          max_temperature_measurement_format,
          min_temperature_measurement_format,
          toxicity_level,
          delivery_service,
          tracking_number,
          package_type,
        }}
      />
      <PackageInfoDelivery
        {...{
          id,
          destination,
          delivery_status,
          expected_date,
          actual_date,
          source,
          notes,
          specific_id,
        }}
      />
      <PackageAttachments data={data} />
    </StyledPackageInfo>
  )
}
