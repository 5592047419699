import React from 'react'

import { IPackage, PackageContentType } from '@models/packages'
import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'

import { StyledTableCell } from '../../../styles'
import { StyledTypography } from './styles'

interface TrackingNumberProps {
  pack: IPackage
  contentType: PackageContentType
}

export const TrackingNumber: React.FC<TrackingNumberProps> = React.memo(({ pack }) => {
  return (
    <StyledTableCell>
      {pack?.tracking_number ? (
        <Tooltip title={pack?.tracking_number}>
          <StyledTypography>
            {stringSlice(pack?.tracking_number, TRUNCATED_TEXT_LENGTH_S)}
          </StyledTypography>
        </Tooltip>
      ) : (
        <StyledTypography>{'-'}</StyledTypography>
      )}
    </StyledTableCell>
  )
})
