import styled from 'styled-components'

export interface AutocompleteWrapperProps {
  bgColor?: string
  width?: string
  height?: number
  mr?: string
  mb?: string
  mt?: string
  pr?: string
  pl?: string
  position?: string
  heightPopper?: string
  padding?: string
  $widthAutocomplete?: string
  $isFieldValue?: boolean
}

export const AutocompleteWrapper = styled.div<AutocompleteWrapperProps>`
  width: ${({ width }) => width || '100%'};
  margin-right: ${({ mr }) => mr};
  margin-bottom: ${({ mb }) => mb};
  margin-top: ${({ mt }) => mt};
  position: ${({ position }) => position};

  & .MuiAutocomplete-root {
    & .MuiInputBase-root {
      background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.palette.common.white)};
    }

    & fieldset {
      border-color: ${({ theme }) => theme.additional.stroke.main};
    }

    & .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
      padding: ${({ theme, height, padding }) =>
        padding ? padding : theme.spacing(height ? (height - 28) / 2 / 8 : 1.2, 1.5)};
      padding-right: ${({ pr }) => pr};
      padding-left: ${({ pl }) => pl};
      width: ${({ $widthAutocomplete }) => $widthAutocomplete};
    }

    & .MuiSvgIcon-root {
      color: #adb4c2;
    }
  }

  .gmp-search-icon {
    position: absolute;
    top: 9px;
    left: 12px;
    z-index: 10;

    path {
      fill: #adb4c2;
    }
  }

  & .MuiAutocomplete-popper {
    height: ${({ heightPopper }) => heightPopper || '100%'};
  }

  & .MuiAutocomplete-clearIndicator {
    visibility: ${({ $isFieldValue }) => (!$isFieldValue ? 'hidden' : 'visible')};
  }
`
