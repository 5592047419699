import React from 'react'

import {
  TOWNS,
  BRANDS,
  COUNTRIES,
  PHARMACIES,
  UNIT_TYPE,
  DOSAGE_TYPE,
  MANUFACTURER_ADDRESSES,
  REGULATORY_REGISTRATIONS_TYPE,
} from '@constants/queries'
import {
  fetchTowns,
  fetchBrands,
  fetchCountries,
  fetchPharmacies,
  fetchDosageType,
  fetchValueType,
  fetchManufacturerAddresses,
  fetchRegulatoryRegistrationsType,
  fetchToxicityLevel,
} from '@api/samples'
import { IChoice, IOption } from '@models/common/app'
import { useQueriesTyped } from '@hooks/queries'
import { fetchUnitType } from '@api/requests'
import { TOXICITY_LEVEL, VALUE_TYPE } from '@constants/samples'
import { AddressesTypes } from '@components/shared/sampleFields/GMPlaceAutocomplete'

export interface ISampleOptionsContext {
  towns: AddressesTypes[]
  pharmacies: IOption[]
  manufacturerAddresses: AddressesTypes[]
  brands: IOption[]
  countries: IOption[]
  dosageType: IOption[]
  unitType: IOption[]
  valueType: IOption[]
  toxicityLevel: IChoice[]
  regulatoryRegistrationsType: IOption[]
  isLoading: boolean
}

export const SampleOptionsContext = React.createContext<ISampleOptionsContext>({
  towns: [],
  pharmacies: [],
  manufacturerAddresses: [],
  brands: [],
  countries: [],
  dosageType: [],
  unitType: [],
  valueType: [],
  toxicityLevel: [],
  regulatoryRegistrationsType: [],
  isLoading: true,
})

export const SampleOptionsProvider: React.FC = ({ children }) => {
  const fetchSampleOptionsQ = useQueriesTyped([
    { queryKey: [TOWNS], queryFn: fetchTowns },
    { queryKey: [PHARMACIES], queryFn: fetchPharmacies },
    { queryKey: [MANUFACTURER_ADDRESSES], queryFn: fetchManufacturerAddresses },
    { queryKey: [BRANDS], queryFn: fetchBrands },
    { queryKey: [COUNTRIES], queryFn: fetchCountries },
    { queryKey: [DOSAGE_TYPE], queryFn: fetchDosageType },
    { queryKey: [UNIT_TYPE], queryFn: fetchUnitType },
    { queryKey: [VALUE_TYPE], queryFn: fetchValueType },
    { queryKey: [TOXICITY_LEVEL], queryFn: fetchToxicityLevel },
    { queryKey: [REGULATORY_REGISTRATIONS_TYPE], queryFn: fetchRegulatoryRegistrationsType },
  ])

  const [
    towns,
    pharmacies,
    manufacturerAddresses,
    brands,
    countries,
    dosageType,
    unitType,
    valueType,
    toxicityLevel,
    regulatoryRegistrationsType,
  ] = fetchSampleOptionsQ

  const isLoading = fetchSampleOptionsQ.some(query => query.isLoading)

  const options = {
    towns: towns.data || [],
    pharmacies: pharmacies.data || [],
    manufacturerAddresses: manufacturerAddresses.data || [],
    brands: brands.data || [],
    countries: countries.data || [],
    dosageType: dosageType.data || [],
    unitType: unitType.data || [],
    valueType: valueType.data || [],
    toxicityLevel: toxicityLevel.data || [],
    regulatoryRegistrationsType: regulatoryRegistrationsType.data || [],
    isLoading,
  }

  return <SampleOptionsContext.Provider value={options}>{children}</SampleOptionsContext.Provider>
}
