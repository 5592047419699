import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { BoxProps } from '@mui/system'

export const StyledCollapseRow = styled(Box)`
  border-radius: 4px;
`

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;

  & .MuiBadge-root {
    background-color: #ffb135;
    font-size: 12px;
    height: 20px;
    border-radius: 10px;
    margin: 0 10px 0 0;

    .MuiBadge-badge {
      font-weight: 700;
      height: 100%;
      width: 100%;
      position: relative;
      top: unset;
      right: unset;
      transform: unset;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #0f1934;
    }
  }
`

interface StyledCardBoxProps extends BoxProps {
  $isFilterClose?: boolean
  $isMobile?: boolean
}

export const StyledCardBox = styled<React.FC<StyledCardBoxProps>>(Box)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 16px 0 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
`

export const CardRow = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
  border-bottom: 1px solid #e1e7f3;
  position: relative;
`

export const FilterTitle = styled(Typography)`
  display: inline-block;
  margin-right: 5px;
  font-weight: 500;
`

export const FilterNumber = styled(Typography)`
  display: inline-block;
  color: #8f929b;
  font-weight: 500;
`

export const StyledShowMoreLinkBox = styled(Box)`
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
`
