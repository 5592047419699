import styled from 'styled-components'

interface StyledBoxProps {
  centred?: boolean
  pt?: string
}

export const StyledBox = styled.div<StyledBoxProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${({ pt }) => (pt ? pt : 'auto')};
  height: ${({ centred }) => (centred ? 'calc(100vh - 130px)' : 'initial')};
  color: #778299;
`
