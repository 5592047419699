// * [View list of samples constants

export const MAX_SELECTED_SAMPLE_NUMBER = 200
export const SAMPLES_AMOUNT_LOADING_LIMIT = 50

// * View list of samples constants]

// * [Create Sample constants

export const LIMITATION_ON_SAMPLE_CREATION = 100

export const SAMPLE_CREATION = 'sampleCreation'
export const DRUG_CLASS = 'drug_class'
export const LABORATORY = 'laboratory'
export const DOSAGE = 'dosage'
export const DOSAGE_TYPE = 'dosage_type'
export const NOTES = 'notes'
export const LOT = 'lot'
export const BUYERS_NAME = 'buyers_name'
export const PURCHASE_DATE = 'purchase_date'
export const TOWN = 'town'
export const PHARMACY = 'pharmacy'
export const PHARMACY_GPS = 'pharmacy_gps'
export const MANUFACTURER = 'manufacturer'
export const ADDRESS_OF_MANUFACTURER = 'address_of_manufacturer'
export const BRAND = 'brand'
export const MAIN_APIS = 'main_apis'
export const OPTIONAL_APIS = 'optional_apis'
export const EXCIPIENTS = 'excipients'
export const TESTS = 'test_types'
export const MANUFACTURE_DATE = 'manufacture_date'
export const EXPIRATION_DATE = 'expiration_date'
export const COUNTRY = 'country'
export const PROJECT = 'project'
export const FUNDING = 'funding'
export const DESIRED_SAMPLES = 'desired_samples'
export const DESIRED_SAMPLES_INSIDE_PACKAGE = 'desired_samples_inside_package'
export const AMOUNT_OF_SAMPLES = 'amount_of_samples'
export const AMOUNT_OF_UNITS_IN_ONE_SAMPLE = 'amount_of_units_in_one_sample'
export const UNIT_TYPE = 'unit_type'
export const UNITS = 'units'
export const VALUE = 'price_per_unit'
export const VALUE_TYPE = 'currency'
export const REGULATORY_REGISTRATION = 'regulatory_registration_status'
export const SAMPLE_TYPE = 'sample_type'
export const TOXICITY_LEVEL = 'toxicity_level'
export const MAX_TEMPERATURE = 'max_temperature'
export const MIN_TEMPERATURE = 'min_temperature'
export const TEMPERATURE_FORMAT = 'temperature_measurement_format' // Celsius (°C) or Fahrenheit (°F) for sample
export const STORAGE_NOTES = 'storage_notes'
export const ATTACHMENTS = 'attachments'
export const DEFAULT_VALUE_TYPE_NAME = 'USD'
export const DEFAULT_VALUE_TYPE_ID = 159
export const MAXIMUM_SPLIT_NUMBER = 5

// --------------------------------------------
export const TOXIC_NAME = 'Toxic'
export const TEMPERATURE_NAME = 'Temperature-sensitive'
// --------------------------------------------

// * Create Sample constants]

// * [Statuses
export const NEW = 'new'
export const IN_TEST = 'in_test'
export const TESTED = 'tested'

// * Statuses]
