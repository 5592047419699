import React from 'react'
import { TabContext } from '@mui/lab'
import { StringParam, useQueryParam } from 'use-query-params'

import { IPackagesTabValue, PackageContentType } from '@models/packages'

import { TabButtons, TabContent } from './components'
import { PackagesPageHeader } from '../..'

export const Tabs = () => {
  const [queryString, setQueryString] = useQueryParam('package_type', StringParam)

  const queryTab: IPackagesTabValue =
    queryString === 'incoming'
      ? 'incoming packages'
      : queryString === 'outgoing'
      ? 'outgoing packages'
      : 'packages'

  const contentTypeForFilterAside: PackageContentType =
    queryString === 'incoming' ? 'incoming' : queryString === 'outgoing' ? 'outgoing' : 'default'

  const [currentTab, setCurrentTab] = React.useState<IPackagesTabValue>(queryTab)

  React.useEffect(() => {
    setCurrentTab(queryTab)
  }, [queryString, queryTab])

  return (
    <>
      <PackagesPageHeader contentType={contentTypeForFilterAside} />
      <TabContext value={currentTab}>
        <TabButtons onChangeTab={setCurrentTab} onChangeQuery={setQueryString} />
        <TabContent />
      </TabContext>
    </>
  )
}
