import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { useGeneratePackageQr, useDownloadPackagedQr } from '@hooks/queries'
import { Modal, Button } from '@components/shared'
import { ButtonBox, StyledModalTitle, Image } from '@styles'

import { ImageBox, Container } from './styles'

interface IParams {
  packageId: string
}

export const GeneratePackageQr: React.FC = () => {
  const { packageId } = useParams<IParams>()
  const history = useHistory()

  const generateQrQ = useGeneratePackageQr(packageId)
  const downloadQrQ = useDownloadPackagedQr(packageId)

  const goBack = () => history.goBack()

  const printQr = () => downloadQrQ.mutate('print')
  const downloadQr = () => downloadQrQ.mutate('download')

  return (
    <Modal open onClose={goBack}>
      <Container>
        <StyledModalTitle variant='h3'>QR-code successfully generated</StyledModalTitle>

        {generateQrQ.data && (
          <ImageBox>
            <Box>
              <Image
                mt='15px'
                mb='20px'
                width='260px'
                height='260px'
                sx={{ objectFit: 'fill' }}
                src={`data:image/rgb;base64, ${generateQrQ.data}`}
                component='img'
              />
            </Box>
          </ImageBox>
        )}

        <ButtonBox>
          <Button type='button' variant='contained' width='225px' height='48px' onClick={printQr}>
            Print
          </Button>
          <Button
            type='button'
            variant='contained'
            width='225px'
            height='48px'
            onClick={downloadQr}
          >
            Download
          </Button>
        </ButtonBox>
      </Container>
    </Modal>
  )
}
