import { Box, BoxProps, styled } from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'

interface TableCellProps extends BoxProps {
  isLastSample?: boolean
}

const TableCellConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'isLastSample':
        return false

      default:
        return true
    }
  },
}

export const FixedTableCell = styled<React.FC<TableCellProps>>(Box, TableCellConfig)`
  position: sticky;
  display: flex;
  align-items: flex-start;
  right: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  cursor: auto;
  z-index: 100;
  box-shadow: -15px 0 15px -5px rgba(53, 108, 151, 0.12);
  padding: 16px 24px 16px 32px;
  border-color: transparent;

  &:first-child {
    border-top-left-radius: 10px;
    border-radius: 10px 0px 0px 10px;
  }

  border-bottom-left-radius: ${({ isLastSample }) => (isLastSample ? '10px' : '0')};
  border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};

  &::before {
    content: '';
    display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent transparent ${({ theme }) => theme.palette.background.default}
      transparent;
  }

  &::after {
    content: '';
    display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
    position: absolute;
    bottom: 0.8px;
    right: 0.6px;
    width: 15px;
    height: 15px;
    border-radius: 60%;
    background-color: #ffffff;
  }
`
