import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { http } from '@services/http'
import { ReactComponent as AddIcon } from '@assets/icons/add-icon-blue.svg'
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { setIsEditAttachments } from '@state/tasks'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { hrAttachmentsCreateSample, setHrCreateSampleAttachments } from '@state/app'
import { IAttachmentWithThumbnail } from '@models/attachments'
import { setMaintenanceModePage } from '@state/user'
import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'

import { IPhotoAttachmentsButtonsProps } from './photoAttachmentsButtons.types'
import { PhotoPreview } from './PhotoPreview'
import * as Styled from './styles'

export const PhotoAttachmentsButtons: React.FC<IPhotoAttachmentsButtonsProps> = props => {
  const { attachMaterialsEndpoint, initialAttachments, saveRequestedAttachment } = props
  const { setValue, getValues } = useFormContext()
  const { sample } = getValues()
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const attachmentsAndModalStore = useAppSelector(hrAttachmentsCreateSample)

  const [loadingPhoto, setLoadingPhoto] = React.useState<IAttachmentWithThumbnail | null>(null)

  // CLEAN STORE after unmount Component
  React.useEffect(
    () => () => {
      // Clean attachments store
      dispatch(setHrCreateSampleAttachments(null))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  // When we SAVE ATTACHMENT
  React.useEffect(() => {
    let intervalFetchPhoto: NodeJS.Timeout
    // Request
    const fetchData = async (photoId: number) => {
      const settingFunc = (response?: IAttachmentWithThumbnail) => {
        // Add data from request
        if (response) {
          saveRequestedAttachment([response])
          dispatch(
            setHrCreateSampleAttachments({ attachments: [response], exitAttachModal: 'save' })
          )
        } else {
          saveRequestedAttachment([])
          dispatch(setHrCreateSampleAttachments({ attachments: null, exitAttachModal: 'save' }))
        }
        // Stop interval and stop preloading animation
        clearInterval(intervalFetchPhoto)
        setLoadingPhoto(null)
        setValue('photoIsLoading', false)
      }

      try {
        // Request response
        const response: IAttachmentWithThumbnail = await http.get(`/attachments/${photoId}/`)
        if (response.link) settingFunc(response)
      } catch (error: any) {
        // Request error
        settingFunc()
        if (error.status === 503) {
          dispatch(setMaintenanceModePage(true))
        } else {
          showNotification(notifications.uploadingPhotoFailed)
        }
      }
    }

    if (attachmentsAndModalStore.exitAttachModal === 'save') {
      if (initialAttachments.length && !attachmentsAndModalStore.attachments) {
        // Delete previous photo
        saveRequestedAttachment([])
      } else if (
        // We check whether there is a difference between the initial data and the saved data in the modal
        attachmentsAndModalStore.attachments &&
        initialAttachments[0]?.id !== attachmentsAndModalStore.attachments[0].id
      ) {
        setValue('photoIsLoading', true)
        const photoId = +attachmentsAndModalStore.attachments[0].id
        if (attachmentsAndModalStore.attachments) {
          setLoadingPhoto(attachmentsAndModalStore.attachments[0])
        }
        // Interval for the request
        intervalFetchPhoto = setInterval(() => fetchData(photoId), 3000)
      }
    }

    return () => {
      clearInterval(intervalFetchPhoto)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsAndModalStore, initialAttachments])
  // When we CANCEL ATTACHMENT
  React.useEffect(() => {
    if (attachmentsAndModalStore.exitAttachModal === 'cancel') {
      if (initialAttachments.length && !attachmentsAndModalStore.attachments) {
        // Return to store initial data
        dispatch(setHrCreateSampleAttachments({ attachments: initialAttachments }))
      } else if (!initialAttachments.length && attachmentsAndModalStore.attachments) {
        // Clean store
        dispatch(setHrCreateSampleAttachments({ attachments: null }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsAndModalStore, initialAttachments])

  const handleClickToLink = (e: any) => {
    if (!loadingPhoto) {
      dispatch(setIsEditAttachments(true))
    } else {
      e.preventDefault()
    }
  }

  const iconsFill = !!loadingPhoto ? '#8f929b' : theme.palette.primary.main

  return (
    <>
      {/* PHOTO PREVIEW */}
      <PhotoPreview loadingPhoto={loadingPhoto} initialAttachments={initialAttachments} />
      {(!sample || sample.allow_editing) && (
        // LINK to ATTACHMENT MODAL
        <Styled.AddAttachedMaterialLink
          $isDeactivate={!!loadingPhoto}
          $isTextDecorationAbsence
          to={attachMaterialsEndpoint}
          onClick={handleClickToLink}
        >
          {initialAttachments.length ? <EditIcon fill={iconsFill} /> : <AddIcon fill={iconsFill} />}
          <Typography
            sx={{
              margin: `${initialAttachments.length ? '4px' : '0'} 0 0 12px`,
              fontWeight: '500',
            }}
            variant='body1'
          >
            {initialAttachments.length ? 'Edit' : 'Add'} photo of sample
          </Typography>
        </Styled.AddAttachedMaterialLink>
      )}
    </>
  )
}
