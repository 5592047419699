import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material'
import React from 'react'

import unionTop from '@assets/images/union-top.svg'
import unionBottom from '@assets/images/union-bottom.svg'

interface MaintenanceModeBoxProps extends BoxProps {
  $isMobile: boolean
}

export const OtherSessionBox = styled<React.FC<MaintenanceModeBoxProps>>(Box)`
  padding: 2% 4% 0;
  width: 100vw;
  height: ${({ $isMobile }) => ($isMobile ? '97vh' : '100vh')};
  background-color: #0a2c6d;
  color: #ffffff;
  background-image: url(${unionTop}), url(${unionBottom});
  background-size: cover cover;
  background-repeat: no-repeat no-repeat;
  background-position: top right, bottom left;
`

export const Logo = styled('img')`
  align-self: flex-start;
  display: block;
`

interface MaintenanceTextProps extends TypographyProps {
  $isMobile: boolean
}

export const MaintenanceTitle = styled<React.FC<MaintenanceTextProps>>(Typography)`
  margin-top: ${({ $isMobile }) => ($isMobile ? '52px' : '2%')};
  font-size: ${({ $isMobile }) => ($isMobile ? '32px' : '48px')};
  line-height: ${({ $isMobile }) => ($isMobile ? '37.5px' : '56px')};
  font-weight: 700;
  text-align: center;
`

export const OtherSessionTitle = styled<React.FC<MaintenanceTextProps>>(Typography)`
  margin-top: ${({ $isMobile }) => ($isMobile ? '0.5%' : '1%')};
  margin-bottom: ${({ $isMobile }) => ($isMobile ? '12px' : '10%')};
  font-size: ${({ $isMobile }) => ($isMobile ? '16px' : '24px')};
  line-height: ${({ $isMobile }) => ($isMobile ? '21px' : '31px')};
  font-weight: 400;
  text-align: center;
  color: #e1e7f3;
`

export const Image = styled('img')`
  display: block;
  width: 32%;
  height: auto;
  position: absolute;
  bottom: 10%;
  right: 10%;
`
