import { Box, Typography, useMediaQuery } from '@mui/material'

import { IHrTaskLab } from '@models/tasks'
import { TRUNCATED_TEXT_LENGTH_L, TRUNCATED_TEXT_LENGTH_S } from '@constants/common'
import { TaskNotesField, Tooltip } from '@components/shared'
import { TooltipMobile } from '@components/shared/Tooltip/components'
import { stringSlice } from '@helpers/ui/common'
import { SubText } from '@styles'
import { TASKS } from '@constants/queries'
import { SpecificId } from '@components/shared/sampleFields'

import { StepSidebarRow } from '../steps/Testing/styles'

interface InfoProps {
  task: IHrTaskLab
}

export const Info: React.FC<InfoProps> = ({ task }) => {
  const isMobile = useMediaQuery('(max-width:767px)')

  return (
    <Box className='test-sidebar' sx={{ padding: isMobile ? '0 15px' : '' }}>
      {!isMobile && <Typography variant='h4'>Main info section</Typography>}
      <Box>
        <StepSidebarRow $inlineFlex>
          <Box>
            <Typography variant='h5' component='span'>
              Sample ID
            </Typography>
          </Box>

          <Box display='inline-flex'>
            <SpecificId
              labelTooltip={task.initial_laboratory?.label_tooltip}
              specificId={`${task?.sample}`}
              withoutLink
            />
          </Box>
        </StepSidebarRow>

        <StepSidebarRow>
          <Box>
            <Typography variant='h5' component='span'>
              Sample name
            </Typography>
          </Box>

          {isMobile ? (
            <Box sx={{ width: '100%' }}>
              <TooltipMobile
                title={task?.name || ''}
                shouldBeHidden={(task?.name?.length || 0) <= TRUNCATED_TEXT_LENGTH_S}
                alignItems='flex-start'
              >
                <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
                  {task?.name ? stringSlice(task.name, TRUNCATED_TEXT_LENGTH_S) : '-'}
                </Typography>
              </TooltipMobile>
            </Box>
          ) : (
            <Box>
              <Tooltip
                title={task?.name || '-'}
                shouldBeHidden={(task?.name?.length || 0) <= TRUNCATED_TEXT_LENGTH_L}
              >
                <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
                  {task?.name ? stringSlice(task?.name, TRUNCATED_TEXT_LENGTH_L) : '-'}
                </Typography>
              </Tooltip>
              <br />

              <SubText>{task?.submitter_code ? `Code: ${task.submitter_code}` : '-'}</SubText>
            </Box>
          )}
        </StepSidebarRow>

        <StepSidebarRow sx={{ position: 'relative' }}>
          <Box>
            <Typography variant='h5' component='span'>
              Task notes
            </Typography>
          </Box>

          <TaskNotesField
            isAbleToAddTaskNotes={task.is_able_to_add_task_notes}
            notes={task.task_notes}
            sampleId={task.sample}
            taskId={task.id}
            contentType={TASKS}
            left='75px'
            taskInfoInTest
          />
        </StepSidebarRow>

        <StepSidebarRow>
          <Box>
            <Typography variant='h5' component='span'>
              Physical form of sample
            </Typography>
          </Box>

          <Box>
            {isMobile ? (
              <TooltipMobile
                title={task?.physical_form?.name || ''}
                shouldBeHidden={(task?.physical_form?.name.length || 0) <= TRUNCATED_TEXT_LENGTH_L}
                placement='top-end'
              >
                <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
                  {task.physical_form
                    ? stringSlice(task.physical_form.name, TRUNCATED_TEXT_LENGTH_L)
                    : '-'}
                </Typography>
              </TooltipMobile>
            ) : (
              <Tooltip
                title={task.physical_form?.name || ''}
                shouldBeHidden={(task?.physical_form?.name.length || 0) <= TRUNCATED_TEXT_LENGTH_L}
                placement='top-end'
              >
                <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
                  {task.physical_form
                    ? stringSlice(task.physical_form.name, TRUNCATED_TEXT_LENGTH_L)
                    : '-'}
                </Typography>
              </Tooltip>
            )}
          </Box>
        </StepSidebarRow>

        <StepSidebarRow>
          <Box>
            <Typography variant='h5' component='span'>
              Weight
            </Typography>
          </Box>

          <Box>
            <Typography variant='body1' component='span'>
              {task.weight ? parseFloat(task.weight) : '-'}
            </Typography>
            &nbsp;
            {task.weight && (
              <Typography variant='body2' component='span' className='text-colored'>
                <SubText sx={{ marginLeft: '8px', lineHeight: '20px', fontSize: 14 }}>
                  {task?.dosage_type?.name || ''}
                </SubText>
              </Typography>
            )}
          </Box>
        </StepSidebarRow>

        <StepSidebarRow>
          <Box>
            <Typography variant='h5' component='span'>
              Expected main substances
            </Typography>
          </Box>

          <Box>
            <Tooltip
              isPureTooltip
              maxWidth={250}
              title={task.expected_main_substances || ''}
              shouldBeHidden={
                (task?.expected_main_substances?.length || 0) <= TRUNCATED_TEXT_LENGTH_L
              }
            >
              <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
                {task.expected_main_substances
                  ? stringSlice(task.expected_main_substances, TRUNCATED_TEXT_LENGTH_L)
                  : '-'}
              </Typography>
            </Tooltip>
          </Box>
        </StepSidebarRow>

        <StepSidebarRow $isMobile={isMobile}>
          <Box>
            <Typography variant='h5' component='span'>
              Expected other substances
            </Typography>
          </Box>

          <Box>
            <Tooltip
              isPureTooltip
              maxWidth={250}
              title={task.expected_other_substances || ''}
              shouldBeHidden={
                (task?.expected_other_substances?.length || 0) <= TRUNCATED_TEXT_LENGTH_L
              }
            >
              <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
                {task.expected_other_substances
                  ? stringSlice(task.expected_other_substances, TRUNCATED_TEXT_LENGTH_L)
                  : '-'}
              </Typography>
            </Tooltip>
          </Box>
        </StepSidebarRow>
      </Box>
    </Box>
  )
}
