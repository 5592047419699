import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IHrLaboratory, ILaboratory } from '@models/common/app'
import { IUser } from '@models/common/user'

export interface IEditLaboratorySwitcherType {
  title: string
  active: boolean
}

export interface WorkSpaceState {
  selectedLaboratory: ILaboratory | IHrLaboratory | null
  selectedUser?: IUser
  editLaboratoryModalType: IEditLaboratorySwitcherType[]
  isWasOpenedAdditionalInfo: boolean
  isOpenEditLaboratoryModal: boolean
}

const initialState: WorkSpaceState = {
  selectedLaboratory: null,
  selectedUser: undefined,

  editLaboratoryModalType: [
    { title: 'Contacts', active: true },
    { title: 'Additional info', active: false },
  ],
  isWasOpenedAdditionalInfo: false,
  isOpenEditLaboratoryModal: false,
}

export const workSpaceStateSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setLaboratoryForWorkspace: (state, { payload }: PayloadAction<ILaboratory | IHrLaboratory>) => {
      state.selectedLaboratory = payload
    },
    toggleEditLaboratoryModalType: (state, { payload }: PayloadAction<number>) => {
      state.editLaboratoryModalType = state.editLaboratoryModalType.map((info, idx) => {
        if (idx === payload) return { ...info, active: true }
        return { ...info, active: false }
      })
    },
    setWasOpenedAdditionalInfo: (state, { payload }: PayloadAction<boolean>) => {
      state.isWasOpenedAdditionalInfo = payload
    },
    setIsOpenEditLaboratoryModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenEditLaboratoryModal = payload
    },
    setUserForEditing: (state, { payload }: PayloadAction<IUser | undefined>) => {
      state.selectedUser = payload
    },
  },
})

export const {
  reducer: workSpaceReducer,
  actions: {
    setLaboratoryForWorkspace,
    setUserForEditing,
    toggleEditLaboratoryModalType,
    setWasOpenedAdditionalInfo,
    setIsOpenEditLaboratoryModal,
  },
} = workSpaceStateSlice
