import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, InputAdornment, useMediaQuery } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'

import { useUpdateTest } from '@hooks/queries'
import { Input } from '@components/shared'
import { REQUIRED_FIELD } from '@constants/validation'
import { IApisWithPercentage } from '@models/common/app'
import { Image } from '@styles'
import percentIcon from '@assets/icons/percent-icon.svg'

import { StyledLabel } from '../styles'

interface HPLCTestResultProps {
  taskId: number
  taskApis: IApisWithPercentage[]
  editTestResult?: boolean
  changeMainApis: (arg: Omit<IApisWithPercentage, 'name'>) => void
  setIsErrorUpdateTest: React.Dispatch<React.SetStateAction<boolean>>
  setIsLoadingUpdateTest: React.Dispatch<React.SetStateAction<boolean>>
  isLoadingUpdateTest: boolean
  isErrorUpdateTest: boolean
}

export const HPLCTestResult: React.FC<HPLCTestResultProps> = props => {
  const {
    taskApis,
    taskId,
    editTestResult,
    changeMainApis,
    setIsErrorUpdateTest,
    setIsLoadingUpdateTest,
    isLoadingUpdateTest,
  } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  const { register, getValues, formState, trigger } = useFormContext<Record<string, string>>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apis = React.useMemo(() => taskApis, [])
  const updateTestM = useUpdateTest(false, true)
  const { errors } = formState
  const [currentUpdateId, setCurrentUpdateId] = useState<number | null>(null)

  useEffect(() => {
    if (updateTestM.isLoading) setIsLoadingUpdateTest(true)
    else setIsLoadingUpdateTest(false)

    if (updateTestM.isError) setIsErrorUpdateTest(true)
    else setIsErrorUpdateTest(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTestM.isError, updateTestM.isLoading])

  const debounced = useDebouncedCallback(resultData => {
    !editTestResult && updateTestM.mutate({ taskId, data: resultData })
  }, 500)

  const updateApiField = (apiId: number) => {
    const resultData = {
      main_apis: [
        {
          id: apiId,
          percentage: getValues(String(apiId)) || null,
        },
      ],
    }

    changeMainApis({
      id: apiId,
      percentage: getValues(String(apiId)) || null,
    })

    debounced(resultData)
  }

  const changeFieldHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChangeCallback: React.ChangeEventHandler<HTMLInputElement>,
    apiId: number
  ) => {
    onChangeCallback(e)
    updateApiField(apiId)
  }

  const handleStopEnterEAndArrows = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'e' || e.key === 'E' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault()
    }

    if (getValues(String(currentUpdateId))?.length >= 13 && e.key !== 'Backspace')
      e.preventDefault()
  }

  return (
    <Box>
      {apis
        .sort((a, b) => a.id - b.id)
        .map(({ id, name, percentage }, idx) => {
          const apiField = register(String(id), {
            min: {
              value: 0,
              message: "Can't be negative",
            },
            validate: {
              required: (v: string) => {
                return v.trim().length > 0 || REQUIRED_FIELD
              },
              dosageEFormat: (v: string) => {
                return Number(v) > 0 || "Can't be negative"
              },
              dosageIntFormat: (v: string) => {
                return (
                  /^\d{0,6}(\.\d{0,6})?$/.test(v) ||
                  'Maximum number of characters 6 after dot and 6 before dot'
                )
              },
            },
          })

          const error = errors[id]

          return (
            <StyledLabel
              $isMobile={isMobile}
              key={idx}
              label={name}
              control={
                <Input
                  type='number'
                  id='apiField'
                  fullWidth
                  variant='outlined'
                  placeholder='Type here number...'
                  autoFocus={idx === 0}
                  defaultValue={percentage ? parseFloat(percentage) : ''}
                  error={!!error}
                  helperText={error && error.message}
                  {...apiField}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeFieldHandler(e, apiField.onChange, id)
                    trigger(String(id))
                    setCurrentUpdateId(id)
                  }}
                  onKeyDown={handleStopEnterEAndArrows}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isLoadingUpdateTest && currentUpdateId === id && (
                          <CircularProgress size={16} style={{ marginRight: '32px' }} />
                        )}
                        <Image component='img' src={percentIcon} width='24px' height='24px' />
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
          )
        })}
    </Box>
  )
}
