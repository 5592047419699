import { useMutation, useQueryClient } from 'react-query'

import { USERS } from '@components/shared/FilterAside/constants'
import { IError } from '@models/common/app'
import { createUser, HttpServiceRequestParam, IUserCreateRequestData } from '@api/user'

export const useCreateUser = () => {
  const client = useQueryClient()

  const mutation = useMutation<
    IUserCreateRequestData,
    IError,
    HttpServiceRequestParam<IUserCreateRequestData>
  >(createUser, {
    onSuccess: () => {
      client.invalidateQueries([USERS])
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
