import React from 'react'
import { v4 as uuid } from 'uuid'
import { Typography, useMediaQuery } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { StyledForm, StyledLabel } from '@styles'
import { Button, Input } from '@components/shared'
import { useToggle } from '@hooks/common'
import { validationSchemas } from '@services/validation'
import { IAttachData, IAttachLink } from '@models/common/forms'
import { IAttachment } from '@models/attachments'
import topArrow from '@assets/icons/top-arrow-icon.svg'
import bottomArrow from '@assets/icons/bottom-arrow-icon.svg'
import deactivateBottomArrow from '@assets/icons/deactivate-bottom-arrow-icon.svg'

import { ButtonBox, StyledAttachLinkCollapse, StyledIcon, StyledCollapse } from './styles'
import { IAttachLinkProps } from './attachLinkForm.types'

export const AttachLinkForm: React.FC<IAttachLinkProps> = props => {
  const {
    isDeactivate,
    setAttachments,
    addAttachments,
    contentType,
    section,
    sectionTitle,
    placeholders,
  } = props

  const { register, handleSubmit, reset, formState } = useForm<IAttachLink>({
    resolver: yupResolver(validationSchemas.attachLink),
    mode: 'onChange',
  })

  const [isToggleCollapse, toggleCollapse] = useToggle()
  const isMobile = useMediaQuery('(max-width:767px)')

  const submitHandler: SubmitHandler<IAttachLink> = data => {
    const formData: FormData = new FormData()
    const attachmentId = uuid()
    const attachmentIds: string[] = [attachmentId]

    const linkData: IAttachData = {
      name: data.title,
      link: data.url,
      attachment_type: 'link',
      content_type: contentType,
      attachment_section: section,
    }

    const attachment: IAttachment = {
      id: attachmentId,
      name: data.title,
      link: data.url,
      loading: true,
      attachment_type: {
        key: 'link',
        value: 'Link',
      },
      attachment_section: {
        key: section,
        value: sectionTitle,
      },
    }

    setAttachments(prev => [...prev, attachment])

    Object.entries(linkData).forEach(([key, value]) => formData.append(key, value))

    addAttachments(formData)
      .then()
      .catch(() => {
        setAttachments(prev => prev.filter(({ id }) => !attachmentIds.includes(id.toString())))
      })

    reset()
    toggleCollapse()
  }
  return (
    <StyledAttachLinkCollapse>
      <ButtonBox isDeactivate={isDeactivate} onClick={!isDeactivate ? toggleCollapse : () => {}}>
        <Typography variant='body1'>Attach a link</Typography>
        {isToggleCollapse ? (
          <StyledIcon>
            <img src={topArrow} alt='bottom arrow' />
          </StyledIcon>
        ) : (
          <StyledIcon>
            <img src={isDeactivate ? deactivateBottomArrow : bottomArrow} alt='top arrow' />
          </StyledIcon>
        )}
      </ButtonBox>
      <StyledCollapse in={isToggleCollapse} timeout='auto' unmountOnExit>
        <StyledForm onSubmit={handleSubmit(submitHandler)}>
          <StyledLabel
            mb={isMobile ? '20px' : '43px'}
            sx={{ marginTop: '10px' }}
            label='URL'
            control={
              <Input
                fullWidth
                dataTestId='input-email'
                variant='outlined'
                type='text'
                placeholder={placeholders ? placeholders.url : 'https:/...'}
                error={!!formState.errors.url?.message}
                helperText={formState.errors.url?.message}
                {...register('url')}
              />
            }
          />
          <StyledLabel
            sx={{ marginBottom: '24px' }}
            label='Title'
            control={
              <Input
                fullWidth
                dataTestId='input-email'
                variant='outlined'
                type='text'
                placeholder={placeholders ? placeholders.title : 'Enter title...'}
                error={!!formState.errors.title?.message}
                helperText={formState.errors.title?.message}
                {...register('title')}
              />
            }
          />
          <Button
            variant='contained'
            disabled={!formState.isValid}
            type='submit'
            mb='32px'
            width='90px'
            height='32px'
          >
            Insert
          </Button>
        </StyledForm>
      </StyledCollapse>
    </StyledAttachLinkCollapse>
  )
}
