import { CONTACT_INFO, USERS } from '@constants/common'

import { ContactInformation } from './components/ContactInformation'
import { TabListOfUsers } from './components/TabListOfUsers'

interface WorkspaceContentProps {
  contentType?: string | null
}

export const WorkspaceContent: React.FC<WorkspaceContentProps> = ({ contentType }) => {
  return (
    <>
      {(contentType === CONTACT_INFO || !contentType) && <ContactInformation />}
      {contentType === USERS && <TabListOfUsers />}
    </>
  )
}
