import React from 'react'

import { selectUser } from '@state/user'
import { ISample } from '@models/samples'
import { useAppSelector } from '@hooks/redux'
import { datePatterns } from '@constants/dates'
import { getFormattedDate } from '@helpers/ui/date'
import { StyledTableSampleCell } from '@styles'

interface LastUpdatedProps {
  report: ISample
  hideBorder?: boolean
}

export const LastUpdated: React.FC<LastUpdatedProps> = ({ report, hideBorder }) => {
  const { timezone } = useAppSelector(selectUser)

  return (
    <StyledTableSampleCell sx={{ display: 'flex' }} isClickable hideBorder={hideBorder}>
      {getFormattedDate(datePatterns.timeDayMonthYear, report.last_completed_task_date, timezone) ||
        '-'}
    </StyledTableSampleCell>
  )
}
