import styled from 'styled-components'

export const StyledHeaderBox = styled('div')`
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
`

export const StyledTandemMSChip = styled('span')`
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 1px 8px;
  margin-left: 10px;

  color: #616d84;
  border-radius: 53px;
  border: 1px solid #e1e7f3;
  background: #fff;
  font-size: 12px;
  font-weight: 500;
`
