import React from 'react'
import { v4 as uuid } from 'uuid'
import { MutateOptions } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMediaQuery } from '@mui/material'

import {
  AttachSection,
  IAttachment,
  IAttachmentResultData,
  IAttachments,
} from '@models/attachments'
import { IError } from '@models/common/app'
import { IAttachLink } from '@models/common/forms'
import { validationSchemas } from '@services/validation'
import { Input, Modal, Button } from '@components/shared'
import { StyledLabel } from '@styles'

import { StyledAttachModalTitle, StyledButtonBox, StyledForm } from './styles'

interface AttachLinkModalProps {
  title: string
  subTitle?: string
  cancelBtnText?: string
  confirmButtonText?: string
  onClose: () => void
  setAttachments: React.Dispatch<React.SetStateAction<IAttachments>>
  addAttachments: (
    variables: FormData,
    options?: MutateOptions<IAttachmentResultData, IError, FormData, unknown> | undefined
  ) => Promise<IAttachmentResultData>
  taskId: string
  section: AttachSection
  attachments: IAttachments
}

export const AttachLinkModal: React.FC<AttachLinkModalProps> = props => {
  const {
    title,
    cancelBtnText = 'Cancel',
    confirmButtonText = 'Insert link',
    onClose,
    setAttachments,
    addAttachments,
    taskId,
    section,
  } = props

  const { register, handleSubmit, reset, formState } = useForm<IAttachLink>({
    resolver: yupResolver(validationSchemas.attachLink),
    mode: 'onChange',
  })
  const isMobile = useMediaQuery('(max-width:767px)')

  const submitHandler: SubmitHandler<IAttachLink> = data => {
    const formData: FormData = new FormData()
    const attachmentId = uuid()
    const attachmentIds: string[] = [attachmentId]

    const linkData = {
      name: data.title,
      link: data.url,
      object_id: String(taskId),
      content_type: 'task',
      attachment_type: 'link',
      attachment_section: section,
    }

    const attachment: IAttachment = {
      id: attachmentId,
      name: data.title,
      link: data.url,
      loading: true,
      attachment_type: {
        key: 'link',
        value: 'Link',
      },
      attachment_section: {
        key: section,
        value: 'Test report',
      },
    }

    setAttachments(prev => [...prev, attachment])

    Object.entries(linkData).forEach(([key, value]) => formData.append(key, value))

    addAttachments(formData).catch(() => {
      setAttachments(prev => prev.filter(({ id }) => !attachmentIds.includes(id.toString())))
    })

    reset()
    onClose()
  }

  return (
    <Modal open onClose={onClose} className='attach-link-modal'>
      <StyledForm $isMobile={isMobile} component='form' onSubmit={handleSubmit(submitHandler)}>
        <StyledAttachModalTitle variant='h3'>{title}</StyledAttachModalTitle>

        <StyledLabel
          mb='20px'
          label='URL'
          control={
            <Input
              autoFocus
              fullWidth
              variant='outlined'
              placeholder='Put here URL in this format https:/...'
              error={!!formState.errors.url?.message}
              helperText={formState.errors.url?.message}
              {...register('url')}
            />
          }
        />

        <StyledLabel
          label='Title'
          control={
            <Input
              fullWidth
              variant='outlined'
              placeholder='Type here Title...'
              error={!!formState.errors.title?.message}
              helperText={formState.errors.title?.message}
              {...register('title')}
            />
          }
        />

        <StyledButtonBox $isMobile={isMobile}>
          <Button
            variant='outlined'
            mr={isMobile ? '' : '28px'}
            mt={isMobile ? '16px' : ''}
            sx={{ order: isMobile ? 2 : 1 }}
            width={isMobile ? '100%' : '205px'}
            height='48px'
            onClick={onClose}
          >
            {cancelBtnText}
          </Button>

          <Button
            variant='contained'
            type='submit'
            width={isMobile ? '100%' : '205px'}
            height='48px'
            sx={{ order: isMobile ? 1 : 2 }}
          >
            {confirmButtonText}
          </Button>
        </StyledButtonBox>
      </StyledForm>
    </Modal>
  )
}
