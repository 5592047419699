import React from 'react'
import { useHistory } from 'react-router-dom'

import { ITaskLab, ITasksTabValue } from '@models/tasks'
import { DETAILS } from '@constants/common'
import { IN_PROGRESS } from '@constants/packages'
import { protectedRoutes } from '@configs/routes.config'
import { Button, ParagraphLink } from '@components/shared'
import { FixedTableCell } from '@styles'

interface ActionCellProps {
  task: ITaskLab
  isLastTask?: boolean
  isMobile?: boolean
  contentType: ITasksTabValue
  isAwaitingContentType: boolean
}

export const ActionCell: React.FC<ActionCellProps> = React.memo(props => {
  const { isAwaitingContentType, task, isLastTask, contentType, isMobile } = props
  const { id: taskId, test_type: testType, status } = task

  const history = useHistory()

  const actionBtnText = status.key === IN_PROGRESS ? 'Keep on' : 'Test'

  const taskDetailsEndpoint = () => {
    history.replace({
      pathname: `${protectedRoutes.tasks.path}/${taskId}${DETAILS}`,
      state: [
        {
          path: protectedRoutes.tasks.path,
          url: protectedRoutes.tasks.path,
          search: `?tasks_type=${contentType}`,
          title: `${contentType} tasks`,
        },
      ],
    })
  }

  const redirectToTest = () => {
    history.replace({
      pathname: `${protectedRoutes.test.path
        .replace(':testType', testType.group.name.toLowerCase())
        .replace(':taskId', String(taskId))}`,
      state: [
        {
          path: protectedRoutes.tasks.path,
          url: protectedRoutes.tasks.path,
          search: `?tasks_type=${contentType}`,
          title: `${contentType} tasks`,
          testTitle: `${testType.name} test`,
        },
      ],
    })
  }

  return (
    <FixedTableCell
      data-horizontal-drag-side='right'
      $isMobile={isMobile}
      id='not-expand'
      sx={{ borderBottomLeftRadius: isLastTask ? '10px' : '0px' }}
    >
      {isAwaitingContentType ? (
        <Button variant='outlined' width='90px' padding='0' fz={15} onClick={redirectToTest}>
          {actionBtnText}
        </Button>
      ) : (
        <ParagraphLink mt='8px' onClick={taskDetailsEndpoint}>
          View details
        </ParagraphLink>
      )}
    </FixedTableCell>
  )
})
