import React from 'react'

import { selectUser } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { datePatterns } from '@constants/dates'
import { getFormattedDate } from '@helpers/ui/date'
import { IPackage, PackageContentType } from '@models/packages'

import { StyledTableCell } from '../../../styles'

interface LastUpdatesProps {
  pack: IPackage
  contentType: PackageContentType
}

export const LastUpdates: React.FC<LastUpdatesProps> = React.memo(
  ({ pack }) => {
    const { timezone } = useAppSelector(selectUser)

    return (
      <StyledTableCell>
        {getFormattedDate(datePatterns.timeDayMonthYear, pack.updated_at, timezone)}
      </StyledTableCell>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.pack.updated_at === nextProps.pack.updated_at
  }
)
