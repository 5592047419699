import * as React from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { Box, Typography } from '@mui/material'

import { useAppSelector } from '@hooks/redux'
import { addSampleButtonPushedNumb, setAddSampleButtonPushed } from '@state/app'
import { Button } from '@components/shared'

import { TooltipWithButtonProps } from '../tooltip.types'
import { StyledTooltip } from './StyledTooltip'
import { BorderBox } from '../styles'

export const TooltipWithButton: React.FC<TooltipWithButtonProps> = props => {
  const {
    tooltipWithButtonTextBtn,
    isTooltipWithButtonHelpText,
    shouldBeHidden,
    children,
    ...restProps
  } = props

  const [toggleTooltip, setTooltipHandler] = React.useState(false)
  const addSampleButtonPushed = useAppSelector(addSampleButtonPushedNumb)

  const dispatch = useDispatch()

  React.useEffect(() => {
    setTooltipHandler(!!shouldBeHidden)
  }, [shouldBeHidden, toggleTooltip])

  const closeTooltipHandler = () => {
    setTooltipHandler(false)
    dispatch(setAddSampleButtonPushed(addSampleButtonPushed + 3))
  }

  return (
    <>
      <div key={uuid()}>
        <StyledTooltip
          placement='top'
          arrow
          open={toggleTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          {...restProps}
          title={
            <React.Fragment>
              <Box sx={{ textAlign: 'center' }}>
                <Typography textAlign='center' mb='16px'>
                  {isTooltipWithButtonHelpText}
                </Typography>
                <BorderBox />
                <Box width='100%' height='8px' />
                <Button variant='contained' onClick={closeTooltipHandler} width='86px'>
                  {tooltipWithButtonTextBtn}
                </Button>
              </Box>
            </React.Fragment>
          }
          sx={{ zIndex: 300, ...restProps.sx }}
        >
          {children}
        </StyledTooltip>
      </div>
    </>
  )
}
