import React from 'react'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

import { ICustomizeModalType } from '@state/user'
import { useAppDispatch } from '@hooks/redux'

import { StyledSwitchBox, StyledSwitchButton, StyledSwitchButtonProps } from './styles'

interface SwitcherProps extends StyledSwitchButtonProps {
  buttonsTitles: ICustomizeModalType[]
  functionToggler: ActionCreatorWithPayload<number, string>
  isEditLabInfoModal?: boolean
}

export const Switcher: React.FC<SwitcherProps> = props => {
  const { $buttonWidth, buttonsTitles, functionToggler, isEditLabInfoModal } = props
  const dispatch = useAppDispatch()

  return (
    <StyledSwitchBox $isEditLabInfoModal={isEditLabInfoModal}>
      <StyledSwitchButton
        onClick={() => {
          dispatch(functionToggler(0))
        }}
        $active={buttonsTitles[0].active}
        $buttonWidth={$buttonWidth}
      >
        {buttonsTitles[0].title}
      </StyledSwitchButton>
      <StyledSwitchButton
        onClick={() => {
          dispatch(functionToggler(1))
        }}
        $active={buttonsTitles[1].active}
        $buttonWidth={$buttonWidth}
      >
        {buttonsTitles[1].title}
      </StyledSwitchButton>
    </StyledSwitchBox>
  )
}
