import React from 'react'
import { v4 as uuid } from 'uuid'
import { FileRejection } from 'react-dropzone'

import { DropZone } from '@components/shared'
import { IAttachment } from '@models/attachments'
import { IAttachData } from '@models/common/forms'

import { AttachFilePropTypes } from '../pureAttachments.types'

export const AttachFile: React.FC<AttachFilePropTypes> = ({
  attachments,
  setAttachments,
  addAttachments,
  addingError,
  contentType,
  section,
  sectionTitle,
  modalType,
  maxNumberOfAttachments,
  objectId,
  deletedInitialAttachmentIds,
}) => {
  const onDropHandler = React.useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const formData: FormData = new FormData()
      const attachmentIds: string[] = []
      const hasAddingError: boolean = !!fileRejections.length
      let notSandedInsteadIds: string[] = []

      const data: IAttachData = {
        attachment_type: 'file',
        content_type: contentType,
        attachment_section: section,
      }

      if (objectId) data.object_id = String(objectId)

      if (deletedInitialAttachmentIds.length) {
        // If some attachments have "instead" key
        if (attachments.some(attach => attach.instead)) {
          notSandedInsteadIds = deletedInitialAttachmentIds.filter(deletedInitAttachId =>
            attachments.some(attach => +deletedInitAttachId !== attach.instead)
          )
        } else {
          // If any attachments haven't had "instead" key
          notSandedInsteadIds = deletedInitialAttachmentIds
        }
      }

      acceptedFiles.forEach(file => {
        const attachmentId = uuid()
        attachmentIds.push(attachmentId)

        const attachment: IAttachment = {
          id: attachmentId,
          name: file.name,
          loading: true,
          attachment_type: {
            key: 'file',
            value: 'File',
          },
          attachment_section: {
            key: section,
            value: sectionTitle,
          },
        }

        if (!hasAddingError) {
          setAttachments(prev => [...prev, attachment])
        }

        const data2Send = notSandedInsteadIds.length
          ? { ...data, instead: notSandedInsteadIds[0], file }
          : { ...data, file }
        if (notSandedInsteadIds.length) {
          notSandedInsteadIds.splice(0, 1)
        }

        Object.entries(data2Send).forEach(([key, value]) => formData.append(key, value))
      })

      if (!hasAddingError) {
        addAttachments(formData)
          .then()
          .catch(() => {
            setAttachments(prev => prev.filter(({ id }) => !attachmentIds.includes(id.toString())))
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addAttachments, section, sectionTitle, setAttachments, contentType, objectId]
  )

  return (
    <DropZone
      modalType={modalType}
      onDrop={onDropHandler}
      addingError={addingError}
      attachmentsLength={attachments.length}
      section={section}
      maxNumberOfAttachments={maxNumberOfAttachments}
    />
  )
}
