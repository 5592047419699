import { Box, Typography, useMediaQuery } from '@mui/material'

import { IAttachmentList, IAttachments } from '@models/attachments'
import { StyledDetailsLabel, StyledPageSubTitle } from '@styles'
import { useAddAttachments, useRemoveAttachments } from '@hooks/queries'
import { Input } from '@components/shared/sampleFields'
import { AttachedMaterialItem } from '@components/shared'
import {
  TRUNCATED_ATTACHED_MODAL_MOBILE_TEXT,
  TRUNCATED_ATTACHED_MODAL_TEXT,
} from '@constants/common'
import { TestGroupName } from '@models/tests'
import { isVisualTestGroup } from '@helpers/ui/tests'
import { MAX_CHARACTERS } from '@constants/validation'

import { Attachment } from './components'
import { StepBody } from '../../../Testing/styles'

interface BodyProps {
  note?: string
  taskId: number
  attachments: IAttachments
  setAttachments: React.Dispatch<React.SetStateAction<IAttachments>>
  attachmentList: IAttachmentList
  testGroupName: TestGroupName
}

export const Body: React.FC<BodyProps> = props => {
  const { note, taskId, attachmentList, attachments, setAttachments, testGroupName } = props

  const addAttachmentsM = useAddAttachments()
  const isMobile = useMediaQuery('(max-width:767px)')
  const isVisualGroup = isVisualTestGroup(testGroupName)

  const removeAttachmentsM = useRemoveAttachments(taskId)
  const removeAttachment = (id: string) => removeAttachmentsM.mutate(id)

  const attachmentsBySection = attachments.filter(
    ({ attachment_section: attachmentSection }) =>
      attachmentSection.key === attachmentList[0].section
  )

  const attachmentListForMap =
    attachmentList.length > 2 ? attachmentList.slice(-2) : attachmentList.slice(-1)

  return (
    <StepBody className='test-body'>
      <StyledPageSubTitle variant='h3' $isMobile={isMobile}>
        Attach results
      </StyledPageSubTitle>
      {isVisualGroup ? (
        <Typography variant='body1'>
          Please <b>indicate the result</b> of the analysis and <b>attach the report</b> here.
        </Typography>
      ) : (
        <Typography variant='body1'>Please attach your analysis results here.</Typography>
      )}

      {attachmentList[0].title && (
        <Typography variant='h4' component='h4'>
          {`1. ${attachmentList[0].title}`}
        </Typography>
      )}

      <Box display={isMobile ? '' : 'flex'} mb='40px'>
        <Box width='100%' mb={isMobile ? '24px' : ''}>
          <StyledDetailsLabel mb='9px'>Task notes</StyledDetailsLabel>
          <Input
            multiline
            name={'task_notes'}
            rows={isMobile ? 6 : 9}
            defaultValue={note}
            rules={{
              maxLength: {
                value: 500,
                message: MAX_CHARACTERS(500),
              },
            }}
            mb='8px'
            mrErr='0'
            width={isVisualGroup && !isMobile ? '50%' : '100%'}
            placeholder='Type here notes...'
          />
        </Box>

        {attachments && !isVisualGroup && (
          <Attachment
            key={attachmentList[0].counter}
            {...attachmentList[0]}
            taskId={taskId}
            attachments={attachmentsBySection}
            setAttachments={setAttachments}
            addAttachmentsM={addAttachmentsM}
            width='100%'
            ml={isMobile ? '' : '15px'}
            height='226px'
            testStepWithNotes
          />
        )}
      </Box>

      {!!attachmentsBySection.length && (
        <StyledPageSubTitle sx={{ marginBottom: '16px' }} variant='body1'>
          Attached materials
        </StyledPageSubTitle>
      )}

      {attachmentsBySection.map(attachment => (
        <AttachedMaterialItem
          key={attachment.id}
          truncatedTextLength={
            isMobile ? TRUNCATED_ATTACHED_MODAL_MOBILE_TEXT : TRUNCATED_ATTACHED_MODAL_TEXT
          }
          attachment={attachment}
          removeAttachment={removeAttachment}
        />
      ))}

      {attachments &&
        attachmentListForMap.map(attachment => {
          const attachmentsBySection = attachments.filter(
            ({ attachment_section: attachmentSection }) =>
              attachmentSection.key === attachment.section
          )

          return (
            <Attachment
              key={attachment.counter}
              {...attachment}
              taskId={taskId}
              attachments={attachmentsBySection}
              setAttachments={setAttachments}
              addAttachmentsM={addAttachmentsM}
            />
          )
        })}
    </StepBody>
  )
}
