import React from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { UseMutationResult, useQueryClient } from 'react-query'
import { useMediaQuery } from '@mui/material'

import { IPackage, IUpdatedPackageData } from '@models/packages'
import { Button } from '@components/shared'
import { ButtonBox, StyledDetailsLabel, StyledForm } from '@styles'
import { NOTES } from '@constants/samples'
import { IUpdatedSample } from '@models/samples'
import { ContentOfPage } from '@constants/queries'
import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'
import { MAX_CHARACTERS } from '@constants/validation'

import { Input } from '../../Input'

interface EditNotesFieldProps {
  id: number
  notes?: string | null
  content: ContentOfPage
  editDetailsNotes?: boolean
  toggleEditing: () => void
  withoutTitle?: boolean
  updateSampleM: UseMutationResult<unknown, unknown, IUpdatedSample, unknown>
  updatePackageM: UseMutationResult<IPackage, unknown, IUpdatedPackageData, unknown>
  labelText?: string
  notesLength?: number
}

export const EditNotesField: React.FC<EditNotesFieldProps> = props => {
  const {
    notes,
    id,
    editDetailsNotes,
    toggleEditing,
    updateSampleM,
    updatePackageM,
    withoutTitle,
    content,
    labelText,
    notesLength,
  } = props

  const client = useQueryClient()
  const isMobile = useMediaQuery('(max-width:767px)')
  const formMethods = useForm({ defaultValues: { notes } })
  const isPackageContent =
    content === 'packages' || content === 'incomingPackages' || content === 'outgoingPackages'

  const isLoading = updateSampleM.isLoading || updatePackageM.isLoading

  const editHandler: SubmitHandler<IUpdatedSample> = notesData => {
    if (!isPackageContent) {
      updateSampleM.mutate(
        { ...notesData, id },
        {
          onSettled: () => {
            client.invalidateQueries([content])
            toggleEditing()
          },
        }
      )
    }

    if (isPackageContent) {
      updatePackageM.mutate(
        { id, data: { notes: notesData.notes } },
        {
          onSettled: () => {
            client.invalidateQueries([content])
            toggleEditing()
          },
          onSuccess: () => {
            showNotification(notifications.packageUpdatedSuccessfully)
          },
          onError: () => {
            showNotification(notifications.packageUpdateFailed)
          },
        }
      )
    }
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <StyledForm
          onSubmit={formMethods.handleSubmit(editHandler)}
          id='not-expand'
          style={{ width: editDetailsNotes ? '350px' : isMobile ? '100%' : '190px' }}
        >
          {editDetailsNotes && !withoutTitle && !isMobile && (
            <StyledDetailsLabel mb='9px' variant='h5'>
              {labelText} notes
            </StyledDetailsLabel>
          )}
          <Input
            name={NOTES}
            rows={editDetailsNotes || isMobile ? 6 : 4}
            multiline
            rules={{
              maxLength: {
                value: notesLength || 150,
                message: MAX_CHARACTERS(notesLength || 150),
              },
            }}
            mb='8px'
            mrErr='0'
            type='text'
          />
          <ButtonBox width={isMobile ? '100%' : '190px'} $isMobile={isMobile}>
            <Button
              type='button'
              variant='outlined'
              color='secondary'
              bgColor='#fff'
              width={isMobile ? '100%' : '90px'}
              height={isMobile ? '48px' : '32px'}
              mr={isMobile ? '' : '8px'}
              mt={isMobile ? '16px' : ''}
              sx={{ order: isMobile ? 2 : 1 }}
              fz={isMobile ? 16 : 14}
              onClick={toggleEditing}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              width={isMobile ? '100%' : '90px'}
              height={isMobile ? '48px' : '32px'}
              mt={isMobile ? '24px' : ''}
              sx={{ order: isMobile ? 1 : 2 }}
              fz={isMobile ? 16 : 14}
              loading={isLoading}
            >
              Save
            </Button>
          </ButtonBox>
        </StyledForm>
      </FormProvider>
    </>
  )
}
