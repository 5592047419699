import { Typography } from '@mui/material'

import { Chip } from '@components/shared'
import { HIDDEN } from '@constants/tasks'

import { ActionsTypesProps } from './Actions.types'

export const ActionWithHiddenResult: React.FC<ActionsTypesProps> = props => {
  const { action } = props

  return (
    <Typography component='span' sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Typography component='span' sx={{ marginRight: '4px' }}>
        {action.slice(0, 39)}
      </Typography>

      <Chip
        sx={{
          fontSize: '12px !important',
          fontWeight: '400 !important',
          padding: '4px 12px !important',
        }}
        label='Hidden'
        status={HIDDEN}
      />
    </Typography>
  )
}
