import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { EditTestResultsModal } from '@components/modules/Modals'
import {
  TEST_BATCH,
  TEST_BRAND,
  TEST_NOTES,
  TEST_PREPARATION,
  TEST_RESULT,
  TEST_STRIP_ATTACHMENTS,
} from '@constants/tests'
import { UpdateResultTypes } from '@models/tasks'
import { useUpdateTask } from '@hooks/queries/useUpdateTask'
import { TASKS } from '@constants/queries'
import { COMPLETED } from '@constants/tasks'
import { setTestUpdatingId } from '@state/test'

import { CommonNotes } from './CommonNotes'
import { ResultsOfTest } from './ResultsOfTest'
import { PhotoOfTestStrip } from './PhotoOfTestStrip'
import { TestStripDetails } from './TestStripDetails'
import { EditingExitModal } from './EditingExitModal'
import { ScreeningEditResultsPropTypes } from './screeningEditResults.types'
import { StyledSectionsBox } from './styles'

export const ScreeningEditResults: React.FC<ScreeningEditResultsPropTypes> = props => {
  const { onClose, task, isEditFromCompletedTable } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const client = useQueryClient()
  const updateTaskM = useUpdateTask()
  const dispatch = useDispatch()

  const initialFieldsData = {
    task,
    fields: {
      [TEST_RESULT]: task.result.key,
      [TEST_STRIP_ATTACHMENTS]: task.attachments.map(attachment => attachment.id) || [],
      [TEST_NOTES]: task.test_notes || '',
      [TEST_PREPARATION]: task.preparation || '',
      [TEST_BRAND]: task.test_strip_brand || '',
      [TEST_BATCH]: task.test_strip_batch || '',
    } as UpdateResultTypes, // required "initialFieldsData" key
  }

  const handleSave = ({ fields }: { fields: UpdateResultTypes }) => {
    updateTaskM.mutate({ taskId: task.id, fields })
  }

  React.useEffect(() => {
    if (updateTaskM.isSuccess) {
      onClose()
      updateTaskM.reset()
      if (isEditFromCompletedTable) {
        dispatch(setTestUpdatingId(task.id))
        client.invalidateQueries(COMPLETED)
      } else client.invalidateQueries([TASKS, task.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTaskM])

  return (
    <EditTestResultsModal
      onClose={onClose}
      isCloseFromSomeComponentInside
      initialFieldsData={initialFieldsData}
      modalTitle='Edit results'
      modalSubtitle='You can edit test results here.'
      handleAction={handleSave}
      isLoading={updateTaskM.isLoading}
    >
      <StyledSectionsBox $isMobile={isMobile}>
        <ResultsOfTest />
        <PhotoOfTestStrip />
        <CommonNotes name={TEST_NOTES} placeholder='Type notes here...' />
        <CommonNotes
          name={TEST_PREPARATION}
          placeholder='Type info about test preparation here...'
        />
        <TestStripDetails />
        <EditingExitModal closeEditResultsModal={onClose} />
      </StyledSectionsBox>
    </EditTestResultsModal>
  )
}
