import { Typography } from '@mui/material'

import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'
import { TooltipMobile } from '@components/shared/Tooltip/components'

interface ManufactureProps {
  manufacturer?: string
}

export const Manufacture: React.FC<ManufactureProps> = ({ manufacturer }) => {
  return (
    <div>
      <TooltipMobile
        title={manufacturer || ''}
        shouldBeHidden={((manufacturer && manufacturer?.length) || 0) <= TRUNCATED_TEXT_LENGTH_S}
      >
        <Typography variant='body1'>
          {manufacturer ? stringSlice(manufacturer, TRUNCATED_TEXT_LENGTH_S) : '-'}
        </Typography>
      </TooltipMobile>
    </div>
  )
}
