import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { fetchUser, getTenantsForUser } from '@api/user'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { selectSession, setAuthenticated, setLoading } from '@state/session'
import { redirectToTenants } from '@state/user'
import { publicRoutes } from '@configs/routes.config'
import { UrlManager } from '@helpers/url'

export const useIsAuthenticated = () => {
  const dispatch = useAppDispatch()
  const { isAuthenticated, isLoading } = useAppSelector(selectSession)
  const { pathname } = useLocation()
  const isTenantsPickerPage = pathname === publicRoutes.tenants.path

  useEffect(() => {
    ;(async () => {
      try {
        dispatch(setLoading(true))
        const { isTenantPage } = new UrlManager()

        if (isTenantPage) {
          await fetchUser()
        } else {
          const tenants = await getTenantsForUser()

          if (!isTenantsPickerPage) {
            redirectToTenants(tenants)
          }
        }

        dispatch(setAuthenticated(true))
      } catch (error) {
        dispatch(setAuthenticated(false))
      } finally {
        dispatch(setLoading(false))
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isAuthenticatedLoading: isLoading, isAuthenticated }
}
