export const useUrl = () => {
  const url = window.location.hostname
  const urlArr = url.split('.')
  let envType = 'Default'

  if (urlArr[urlArr.length - 1] === 'localhost') {
    envType = 'Localhost'
  } else if (urlArr[0] === 'dev') {
    envType = 'Development'
  } else if (urlArr[0] === 'artifactsofresearch') {
    envType = 'Production'
  } else if (urlArr[0] === 'stage') {
    envType = 'Stage'
  } else if (urlArr[0] === 'custom') {
    envType = 'Training'
  }

  return { envType }
}
