import React from 'react'

import { IPackage, PackageContentType } from '@models/packages'
import { SubText } from '@styles'

import { StyledTableCell } from '../../../styles'

interface QuantityProps {
  pack: IPackage
  contentType: PackageContentType
}

export const Quantity: React.FC<QuantityProps> = React.memo(
  ({ pack }) => {
    return (
      <StyledTableCell>
        {pack.quantity}
        <SubText sx={{ marginLeft: '8px', lineHeight: '20px', fontSize: 14 }}>
          {pack.quantity > 1 ? 'samples' : 'sample'}
        </SubText>
      </StyledTableCell>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.pack.quantity === nextProps.pack.quantity
  }
)
