import * as React from 'react'
import { useTheme, Box, Typography } from '@mui/material'

import { StyledTitle } from '@styles'
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { FullPageError } from '@components/shared'
import { PhotoWithAttachments as SharedPhotoWithAttachments } from '@components/shared/Hr'
import { HrIdPADEditResults } from '@components/harmModules/HrTasksDetails/components/HrTaskInfo/components/HrScreeningResults/HrIdPADEditResults/HrIdPADEditResults'

import { Loader } from '../../../harmModules/HrTasksDetails/components/HrTaskInfo/components/Loader'
import * as Styled from './styles'
import { HrIdPADResultsPropTypes } from './IdPAD.types'
import { SubstanceResult } from './SubstanceResult'

export const HrIdPADResults: React.FC<HrIdPADResultsPropTypes> = ({ isLoading, task }) => {
  const theme = useTheme()
  const [isOpenEditModal, setIsOpenEditModal] = React.useState<boolean>(false)
  const isResultsEmpty = task?.step === -1

  if (isLoading) return <Loader />

  return (
    <Box width={isResultsEmpty ? '30%' : '50%'}>
      <StyledTitle mb='32px' variant='h3' fz={24}>
        Results
        {task?.is_able_to_edit_task && (
          <Styled.ImageButton onClick={() => setIsOpenEditModal(true)}>
            <EditIcon fill={theme.palette.primary.main} />
          </Styled.ImageButton>
        )}
      </StyledTitle>
      {isResultsEmpty ? (
        <FullPageError
          imgVariant='common'
          errorTextTitle='There are no test results yet'
          pt='70px'
          svgWidth='110px'
          svgHeight='110px'
        />
      ) : (
        <>
          {/* ----- SUBSTANCES RECOGNIZED ----- */}
          <Typography variant='subtitle1' mb='24px'>
            Substances recognized
          </Typography>
          <SubstanceResult
            mb='37px'
            substanceResult={task?.substance_result}
            resultIsNotRecognized={task?.result_is_not_recognized}
          />
          {/* ----- TEST REPORT ----- */}
          <Typography variant='subtitle1' mb='24px'>
            Test report
          </Typography>
          {/* Notes */}
          <Styled.DetailsBox>
            <Typography variant='h5' width='218px' mb='18px'>
              Notes
            </Typography>
            <Box mb='18px' width='calc(100% - 218px)'>
              {task?.test_notes ? (
                <Typography variant='body1'>{task?.test_notes}</Typography>
              ) : (
                <Typography variant='body1' color='primary.light'>
                  Information has not been provided
                </Typography>
              )}
            </Box>
          </Styled.DetailsBox>
          {/* Attachments */}
          <Styled.DetailsBox>
            <Typography variant='h5' width='218px' mb='18px'>
              Attachments
            </Typography>
            <Box mb='18px' width='calc(100% - 218px)'>
              {task?.attachments.length ? (
                <SharedPhotoWithAttachments
                  attachments={task?.attachments}
                  isGoodQualityOfPhoto
                  width='103px'
                  height='103px'
                  mr='8px'
                />
              ) : (
                <Typography variant='body1' color='primary.light' sx={{ marginBottom: '37px' }}>
                  Attachments were not provided
                </Typography>
              )}
            </Box>
          </Styled.DetailsBox>
        </>
      )}
      {/* EDIT RESULTS */}
      {isOpenEditModal && task && (
        <HrIdPADEditResults task={task} onClose={() => setIsOpenEditModal(false)} />
      )}
    </Box>
  )
}
