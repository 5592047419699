import styled from 'styled-components'

export const StyledTableBox = styled.div`
  margin-top: -12px;
  overflow-x: auto;
  overflow-y: hidden;
`

export const StyledTable = styled.div`
  border-top-left-radius: 10px;
  text-align: left;
  background-color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
`
