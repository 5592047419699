import { TENANT_TYPE } from '@constants/tenants'
import { RootState } from '@state/store'

export const selectedFilters = (state: RootState) => state.filters
export const selectEnteringDate = (state: RootState) => state.filters.enteringStoreDate
export const selectFilterError = (state: RootState) => state.filters.filterError

export const selectedFiltersLength = (state: RootState) =>
  state.config.tenant?.type !== TENANT_TYPE.streetDrugs
    ? state.filters.appliedApisSelectedFilters.length +
      state.filters.appliedExcipientsSelectedFilters.length +
      state.filters.appliedDrugClassesSelectedFilters.length +
      state.filters.appliedManufacturersSelectedFilters.length +
      state.filters.appliedAssigneesSelectedFilters.length +
      state.filters.appliedTaskStatusSelectedFilters.length +
      state.filters.appliedTaskResultSelectedFilters.length +
      state.filters.appliedRequestStatusSelectedFilters.length +
      state.filters.appliedLaboratoriesSelectedFilters.length +
      state.filters.appliedTestingDateSelectedFilters.length +
      state.filters.appliedDeliveryStatusSelectedFilters.length +
      state.filters.appliedLastUpdatesSelectedFilters.length +
      state.filters.appliedExpirationDateSelectedFilters.length +
      state.filters.appliedUserStatusSelectedFilters.length +
      state.filters.appliedSampleStatusSelectedFilters.length +
      state.filters.appliedTestTypeSelectedFilters.length
    : state.filters.appliedAssigneesSelectedFilters.length +
      state.filters.appliedTaskStatusSelectedFilters.length +
      state.filters.appliedTaskResultSelectedFilters.length +
      state.filters.appliedRequestStatusSelectedFilters.length +
      state.filters.appliedLaboratoriesSelectedFilters.length +
      state.filters.appliedTestingDateSelectedFilters.length +
      state.filters.appliedCollectionDateSelectedFilters.length +
      state.filters.appliedDeliveryStatusSelectedFilters.length +
      state.filters.appliedLastUpdatesSelectedFilters.length +
      state.filters.appliedUserStatusSelectedFilters.length +
      state.filters.appliedSampleStatusSelectedFilters.length +
      state.filters.appliedTestTypeSelectedFilters.length +
      state.filters.appliedPhysicalFormSelectedFilters.length

export const isDisabledApplyAll = (state: RootState) =>
  state.config.tenant?.type !== TENANT_TYPE.streetDrugs
    ? !state.filters.apisSelectedFilters.filters.length &&
      !state.filters.excipientsSelectedFilters.filters.length &&
      !state.filters.drugClassesSelectedFilters.filters.length &&
      !state.filters.manufacturersSelectedFilters.filters.length &&
      !state.filters.assigneesSelectedFilters.filters.length &&
      !state.filters.taskStatusSelectedFilters.filters.length &&
      !state.filters.requestStatusSelectedFilters.filters.length &&
      !state.filters.laboratoriesSelectedFilters.filters.length &&
      !state.filters.deliveryStatusSelectedFilters.filters.length &&
      !state.filters.testingDateSelectedFilters.filters.length &&
      !state.filters.lastUpdatesSelectedFilters.filters.length &&
      !state.filters.expirationDateSelectedFilters.filters.length &&
      !state.filters.taskResultSelectedFilters.filters.length &&
      !state.filters.sampleStatusSelectedFilters.filters.length &&
      !state.filters.testTypeSelectedFilters.filters.length &&
      !state.filters.userStatusSelectedFilters.filters.length
    : !state.filters.assigneesSelectedFilters.filters.length &&
      !state.filters.taskStatusSelectedFilters.filters.length &&
      !state.filters.requestStatusSelectedFilters.filters.length &&
      !state.filters.laboratoriesSelectedFilters.filters.length &&
      !state.filters.deliveryStatusSelectedFilters.filters.length &&
      !state.filters.testingDateSelectedFilters.filters.length &&
      !state.filters.collectionDateSelectedFilters.filters.length &&
      !state.filters.lastUpdatesSelectedFilters.filters.length &&
      !state.filters.taskResultSelectedFilters.filters.length &&
      !state.filters.sampleStatusSelectedFilters.filters.length &&
      !state.filters.testTypeSelectedFilters.filters.length &&
      !state.filters.userStatusSelectedFilters.filters.length &&
      !state.filters.physicalFormSelectedFilters.filters.length

export const filtersAside = (state: RootState) => state.filters.isFilterOpen
export const filtersActualPage = (state: RootState) => state.filters.filtersPage
export const updatedRadioBtnStatus = (state: RootState) => state.filters.isResetSampleStatusSelected

// save for BE as well
export const saveNewFiltersSamplesPage = (state: RootState) => ({
  page_title: state.filters.filtersPage,
  filters:
    state.config.tenant?.type !== TENANT_TYPE.streetDrugs
      ? [
          {
            query_param: state.filters.apisSelectedFilters.title_url,
            values: state.filters.apisSelectedFilters.filters,
          },
          {
            query_param: state.filters.excipientsSelectedFilters.title_url,
            values: state.filters.excipientsSelectedFilters.filters,
          },
          {
            query_param: state.filters.taskStatusSelectedFilters.title_url,
            values: state.filters.taskStatusSelectedFilters.filters,
          },
          {
            query_param: state.filters.taskResultSelectedFilters.title_url,
            values: state.filters.taskResultSelectedFilters.filters,
          },
          {
            query_param: state.filters.assigneesSelectedFilters.title_url,
            values: state.filters.assigneesSelectedFilters.filters,
          },
          {
            query_param: state.filters.drugClassesSelectedFilters.title_url,
            values: state.filters.drugClassesSelectedFilters.filters,
          },
          {
            query_param: state.filters.manufacturersSelectedFilters.title_url,
            values: state.filters.manufacturersSelectedFilters.filters,
          },
          {
            query_param: state.filters.laboratoriesSelectedFilters.title_url,
            values: state.filters.laboratoriesSelectedFilters.filters,
          },
          {
            query_param: state.filters.expirationDateSelectedFilters.title_url,
            values: state.filters.expirationDateSelectedFilters.filters,
          },
          {
            query_param: state.filters.requestStatusSelectedFilters.title_url,
            values: state.filters.requestStatusSelectedFilters.filters,
          },
          {
            query_param: state.filters.deliveryStatusSelectedFilters.title_url,
            values: state.filters.deliveryStatusSelectedFilters.filters,
          },
          {
            query_param: state.filters.testingDateSelectedFilters.title_url,
            values: state.filters.testingDateSelectedFilters.filters,
          },
          {
            query_param: state.filters.lastUpdatesSelectedFilters.title_url,
            values: state.filters.lastUpdatesSelectedFilters.filters,
          },
          {
            query_param: state.filters.userStatusSelectedFilters.title_url,
            values: state.filters.userStatusSelectedFilters.filters,
          },
          {
            query_param: state.filters.sampleStatusSelectedFilters.title_url,
            values: state.filters.sampleStatusSelectedFilters.filters,
          },
          {
            query_param: state.filters.testTypeSelectedFilters.title_url,
            values: state.filters.testTypeSelectedFilters.filters,
          },
        ]
      : [
          {
            query_param: state.filters.taskStatusSelectedFilters.title_url,
            values: state.filters.taskStatusSelectedFilters.filters,
          },
          {
            query_param: state.filters.taskResultSelectedFilters.title_url,
            values: state.filters.taskResultSelectedFilters.filters,
          },
          {
            query_param: state.filters.assigneesSelectedFilters.title_url,
            values: state.filters.assigneesSelectedFilters.filters,
          },

          {
            query_param: state.filters.laboratoriesSelectedFilters.title_url,
            values: state.filters.laboratoriesSelectedFilters.filters,
          },
          {
            query_param: state.filters.requestStatusSelectedFilters.title_url,
            values: state.filters.requestStatusSelectedFilters.filters,
          },
          {
            query_param: state.filters.deliveryStatusSelectedFilters.title_url,
            values: state.filters.deliveryStatusSelectedFilters.filters,
          },
          {
            query_param: state.filters.testingDateSelectedFilters.title_url,
            values: state.filters.testingDateSelectedFilters.filters,
          },
          {
            query_param: state.filters.collectionDateSelectedFilters.title_url,
            values: state.filters.collectionDateSelectedFilters.filters,
          },
          {
            query_param: state.filters.lastUpdatesSelectedFilters.title_url,
            values: state.filters.lastUpdatesSelectedFilters.filters,
          },
          {
            query_param: state.filters.userStatusSelectedFilters.title_url,
            values: state.filters.userStatusSelectedFilters.filters,
          },
          {
            query_param: state.filters.sampleStatusSelectedFilters.title_url,
            values: state.filters.sampleStatusSelectedFilters.filters,
          },
          {
            query_param: state.filters.testTypeSelectedFilters.title_url,
            values: state.filters.testTypeSelectedFilters.filters,
          },
          {
            query_param: state.filters.physicalFormSelectedFilters.title_url,
            values: state.filters.physicalFormSelectedFilters.filters,
          },
        ],
})
