import { createAsyncThunk } from '@reduxjs/toolkit'

import { UserActions } from '@enums/actions'
import { IUpdatedUser } from '@models/common/user'
import { fetchUser, getTenantsForUser, logoutUser, updateUser } from '@api/user'
import { RootState } from '@state/store'
import { IError } from '@models/common/app'
import { axiosInstance } from '@configs/axios.config'
import { UrlManager } from '@helpers/url'
import { setAuthenticated } from '@state/session'
import { LOGIN_ROUTE } from '@constants/common'

import {
  setApiErrorUpdatePhoneOrEmail,
  setIsSuccessfulUpdatedProfile,
  setMaintenanceModePage,
  setUser,
} from './user.slice'

export const redirectToTenants = (tenants: any[]) => {
  if (tenants.length > 1) {
    window.open('/account/tenants', '_self')
  } else {
    window.open(tenants[0].public_url, '_self')
  }
}

export const auth = createAsyncThunk<void>(UserActions.auth, async (_, thunkApi) => {
  let tenants
  try {
    tenants = await getTenantsForUser()

    const { apiUrl } = new UrlManager(tenants[0].public_url)

    axiosInstance.interceptors.request.use(
      async config => {
        config.baseURL = apiUrl
        return config
      },
      error => Promise.reject(error)
    )

    thunkApi.dispatch(setAuthenticated(true))

    redirectToTenants(tenants)
  } catch (error) {
    return Promise.reject(error)
  }
})

export const getProfile = createAsyncThunk<void>(UserActions.auth, async (_, thunkApi) => {
  try {
    const user = await fetchUser()

    const currentTimeZone = user.timezone_autodetect
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : user.timezone

    if (currentTimeZone !== user.timezone) await updateUser({ timezone: currentTimeZone })

    thunkApi.dispatch(
      setUser({
        ...user,
        timezone: currentTimeZone,
      })
    )
  } catch (error) {
    const { isTenantPage, publicClientUrl } = new UrlManager()
    if (isTenantPage && window.location.pathname.includes('login')) {
      window.open(publicClientUrl, '_self')
    }
    return Promise.reject(error)
  }
})

export const update = createAsyncThunk<void, IUpdatedUser, { state: RootState }>(
  UserActions.update,
  async (updatedUserData, thunkApi) => {
    try {
      const updatedUser = await updateUser(updatedUserData)

      const hasEmailBeenChanged = Boolean(
        updatedUserData.email && updatedUserData.email !== thunkApi.getState().user.email
      )

      const emailSendingStatuses = {
        firstTry: hasEmailBeenChanged,
        secondTry: thunkApi.getState().user.emailSendingStatuses.firstTry,
      }

      thunkApi.dispatch(setUser({ ...updatedUser, emailSendingStatuses }))
      thunkApi.dispatch(setApiErrorUpdatePhoneOrEmail(null))
      thunkApi.dispatch(setIsSuccessfulUpdatedProfile('successful'))
    } catch (err) {
      const error = err as IError
      if (error.status === 503) thunkApi.dispatch(setMaintenanceModePage(true))

      if (error.message) {
        thunkApi.dispatch(setIsSuccessfulUpdatedProfile('error'))
        thunkApi.dispatch(setApiErrorUpdatePhoneOrEmail(error.errorObject))
      }

      return Promise.reject(error)
    }
  }
)

export const getLoginUrlForTenantPages = () => {
  const { isTenantPage, publicClientUrl } = new UrlManager()

  return isTenantPage ? new URL(LOGIN_ROUTE, publicClientUrl).toString() : null
}

export const logout = createAsyncThunk(UserActions.logout, async (_, thunkApi) => {
  try {
    await logoutUser()
    const hostnames = window.location.hostname.split('.')

    const { isTenantPage } = new UrlManager()

    if (isTenantPage) {
      const url = getLoginUrlForTenantPages()

      url && window.open(url, '_self')
    } else {
      thunkApi.dispatch(setAuthenticated(false))
    }
  } catch (error) {
    return Promise.reject(error)
  }
})
