import { ChangedTestTypeOptionsType } from '@components/modules/SampleCreate/components/TableCells/Tests/testsOptions.types'
import { IAvailableTestTypesInfo } from '@models/yourWorkspace'

export const formationTests = (options: IAvailableTestTypesInfo[]) => {
  const changedOptions: ChangedTestTypeOptionsType[] = []

  if (options.length) {
    options.forEach(({ id, name, is_other, group, methods }) => {
      const existingOptionIndex = changedOptions.findIndex(
        newOption => newOption.groupId === group.id
      )

      if (existingOptionIndex !== -1) {
        changedOptions[existingOptionIndex].tests.push({ id, name, is_other, methods })
      } else {
        changedOptions.push({
          groupId: group.id,
          groupName: group.name,
          is_accurate: group.is_accurate,
          is_visual: group.is_visual,
          tests: [{ id, name, is_other, methods }],
        })
      }
    })
  }

  return changedOptions
}
