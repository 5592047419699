import * as React from 'react'
import { useController } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import throttle from 'lodash/throttle'

import { useAddNewAddressManufacturer } from '@hooks/queries/useAddNewAddressManufacturer'
import { useAddNewTowns } from '@hooks/queries/useAddNewTowns'
import { PlaceDetailTypes } from '@components/shared/sampleFields/createSampleCells/GMPlaceAutocomplete/gMPlaceAutocomplete.types'
import { ADDRESS_OF_MANUFACTURER, TOWN } from '@constants/samples'
import { setCellValueToSample } from '@state/sampleCreate'
import { PureDropdownAutocomplete } from '@components/ui'
import { IOptionExtended } from '@components/ui/PureDropdownAutocomplete/pureDropdownAutocomplete.types'
import { ReactComponent as SearchIcon } from '@assets/icons/search-icon-blue.svg'

import { PureCellPropTypes, AddressesTypes, IPlaceOption } from './pureFields.types'
import { StyledGreyIconColor } from './styles'

export const GoggleMapPlaceField: React.FC<PureCellPropTypes> = ({
  control,
  gpOptions,
  isCopy,
  name,
  rules,
  cellName,
  sampleId,
  initialVal,
}) => {
  const correctOptions: IOptionExtended[] = React.useMemo(() => {
    if (gpOptions && gpOptions.length) {
      return gpOptions.map((option: AddressesTypes) => ({ ...option, name: option.description }))
    }
    return []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const autocompleteServiceRef = React.useRef(null)
  const detailServiceRef = React.useRef(null)
  const [options, setOptions] = React.useState<IOptionExtended[] | AddressesTypes[]>(correctOptions)

  const addNewManufacturerAddress = useAddNewAddressManufacturer()
  const addNewTowns = useAddNewTowns()
  const dispatch = useDispatch()

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: initialVal.val })

  const startIcon = (
    <StyledGreyIconColor>
      <SearchIcon />
    </StyledGreyIconColor>
  )

  const setCellValue = (val: IPlaceOption | null, error: string) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val, error },
        cellName,
        sampleId,
      })
    )
  }

  const fetchPredictions = React.useMemo(
    () =>
      throttle(
        (request: { input: string }, callback: (results?: readonly AddressesTypes[]) => void) => {
          ;(autocompleteServiceRef.current as any).getPlacePredictions(request, callback)
        },
        200
      ),
    []
  )
  const fetchDetails = React.useMemo(
    () =>
      throttle(
        (
          request: { placeId: string },
          callback: (results: PlaceDetailTypes, status: string) => void
        ) => {
          ;(detailServiceRef.current as any).getDetails(request, callback)
        },
        200
      ),
    []
  )

  const addNewDataMutate = React.useMemo(() => {
    switch (true) {
      case name.includes(TOWN):
        return addNewTowns
      case name.includes(ADDRESS_OF_MANUFACTURER):
        return addNewManufacturerAddress
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if ((window as any).google) {
      if (!autocompleteServiceRef.current) {
        autocompleteServiceRef.current = new (
          window as any
        ).google.maps.places.AutocompleteService()
      }
      if (!detailServiceRef.current) {
        detailServiceRef.current = new (window as any).google.maps.places.PlacesService(
          document.createElement('div')
        )
      }
    }
  }, [])

  // Filling 'react-hook-form' with data from the server after successfully creating new address
  React.useEffect(() => {
    if (addNewManufacturerAddress.isSuccess || addNewTowns.isSuccess) {
      const newItem = addNewTowns.isSuccess ? addNewTowns.data : addNewManufacturerAddress.data
      const newData = {
        ...newItem,
        name: newItem?.description || '',
      } as IPlaceOption
      setCellValue(newData, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewManufacturerAddress.isSuccess, addNewTowns.isSuccess])

  const onInputChange = (value: string) => {
    field.onChange({ target: { value: value } })
    if (value === '') {
      setOptions(correctOptions as AddressesTypes[])
    } else {
      const newOptions: AddressesTypes[] = []

      correctOptions.forEach(item => {
        const someItem = item as AddressesTypes
        if (someItem.description.toLowerCase().includes(value.trim().toLowerCase())) {
          newOptions.push(someItem)
        }
      })

      if (newOptions.length) {
        setOptions(newOptions)
      } else {
        if (!autocompleteServiceRef.current) return undefined

        fetchPredictions({ input: value }, (results?: readonly AddressesTypes[]) => {
          let newOptions: IOptionExtended[] = []

          if (results) {
            const createCorrectResults = results.map((item: AddressesTypes, idx) => ({
              ...item,
              id: newOptions.length - 1 + idx,
              name: item.description,
            })) as IOptionExtended[]

            setOptions([...newOptions, ...createCorrectResults])
          } else {
            if (options.length) setOptions([])
          }
        })
      }
    }
  }

  const onDropdownChange = (val: IOptionExtended) => {
    const newValue = { ...val } as IPlaceOption

    if (newValue.place_id) {
      fetchDetails({ placeId: newValue.place_id }, (results: PlaceDetailTypes, status: string) => {
        if (results && status === 'OK') {
          newValue.description = results.formatted_address
          setCellValue({ ...newValue }, '')
        }
      })
    } else {
      setCellValue({ ...newValue }, '')
    }

    field.onChange({ target: { value: val } })
  }

  const onAddNewItem = (val: string) => {
    addNewDataMutate?.mutate({ description: val, place_id: '' })
  }

  return (
    <PureDropdownAutocomplete
      isCopy={isCopy}
      startIcon={startIcon}
      initialVal={initialVal.val as IOptionExtended | null}
      name={name}
      options={(options as IOptionExtended[]) || []}
      errorMessage={error?.message || ''}
      onInputChange={onInputChange}
      onDropdownChange={onDropdownChange}
      onAddNewItem={onAddNewItem}
    />
  )
}
