export const removeTrailingZeros = (val: string | number) => {
  let initNumber = val
  if (typeof val === 'string') initNumber = +val

  const [integerPart, decimalPart] = String(initNumber).split('.')
  if (decimalPart) {
    const trimmedDecimalPart = decimalPart.replace(/0+$/, '')
    if (trimmedDecimalPart.length > 0) {
      return `${integerPart}.${trimmedDecimalPart}`
    } else {
      return integerPart
    }
  }

  return initNumber
}
