import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { useDispatch } from 'react-redux'

import { fetchPhysicalForms } from '@api/common'
import { PHYSICAL_FORMS } from '@components/shared/FilterAside/constants'
import { IError, IOption } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useFetchPhysicalForms = (
  desiredPhysicalForms?: string | null
): UseInfiniteQueryResult<IOption[], IError> => {
  const dispatch = useDispatch()

  return useInfiniteQuery([PHYSICAL_FORMS, desiredPhysicalForms], fetchPhysicalForms, {
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
