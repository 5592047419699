import { styled as styledMUI, Button, Box } from '@mui/material'
import styled from 'styled-components'

export const StyledTableBox = styled.div`
  overflow-x: auto;
`

export const StyledButton = styledMUI(Button)`
  width: 90px;
  padding: 0;
  margin-right: 24px;
`

export const StyledTable = styled.div`
  border-radius: unset;
  text-align: left;
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const StyledTableRow = styled.div<{
  bgColor?: string
  isDefaultContentType: boolean
  hideBorder?: boolean
  padding?: string
  header?: boolean
  isPackSelected?: boolean
}>`
  display: grid;
  grid-template-columns: ${({ isDefaultContentType }) =>
    isDefaultContentType
      ? '[checkbox] 86px [package-ID]210px [destination-laboratory]183px [delivery-status]183px [quantity]116px [package-notes]236px [delivery-date]162px [last-updates]216px [action]264px'
      : '[package-ID]210px [destination-laboratory]183px [delivery-status]183px [quantity]116px [delivery-service]183px [tracking-number]184px [package-notes]236px [package-type]166px [delivery-date]162px [last-updates]216px [action]264px'};

  border-bottom: ${({ hideBorder }) => (hideBorder ? '' : '4px solid rgb(248, 251, 255)')};
  background-color: ${({ theme, bgColor, isPackSelected }) =>
    isPackSelected ? theme.primary[30] : bgColor || ''};
  padding: ${({ padding, header, isDefaultContentType }) =>
    header && isDefaultContentType ? '' : padding};

  &:first-child {
    padding: ${({ header, isDefaultContentType }) =>
      !isDefaultContentType && header ? '0 0 0 44px' : ''};
  }
`
interface StyledTableCellProps {
  $isMobile?: boolean
  $isPackSelected?: boolean
  $isLastPack?: boolean
  $isDefaultContentType?: boolean
}

export const StyledTableCell = styledMUI<React.FC<StyledTableCellProps>>(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0;

  &:nth-child(2) {
    padding-left: 16px;
  }

    &::before {
      content: '';
      display: ${({ $isLastPack }) => ($isLastPack ? 'block' : 'none')};
      position: absolute;
      bottom: 4px;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 0 10px;
      border-color: transparent transparent transparent
        ${({ theme }) => theme.palette.background.default};
    }

    &::after {
      content: '';
      display: ${({ $isLastPack }) => ($isLastPack ? 'block' : 'none')};
      position: absolute;
      bottom: 4px;
      left: 0.6px;
      width: 15px;
      height: 15px;
      border-radius: 60%;
      background-color: ${({ theme, $isPackSelected }) =>
        $isPackSelected ? theme.primary[30] : '#ffffff'};
    }
  }
`

export const StyledActionCell = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 1%;

  & .MuiTypography-root {
    white-space: nowrap;
  }
`
