import React, { Suspense, useEffect, lazy } from 'react'
import 'react-virtualized/styles.css'
import 'normalize.css'
import './App.css'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { useSelector } from 'react-redux'

import { useAuth, useCheckPages } from '@hooks/common'
import { BASE_ACCESSIBLE_ROUTE, BASE_APP_ROUTE, CONFIRM_EMAIL_ROUTE } from '@constants/common'
import {
  useFetchMaintenanceMode,
  useFetchSkills,
  useFetchTenant,
  useFetchTestTypes,
} from '@hooks/queries'
import { protectedRoutes, publicRoutes } from '@configs/routes.config'
import { ScrollToTop, TopProgressBar } from '@components/shared'
import {
  maintenanceModeUnsubscribeRoute as MMUnsubscribeRoute,
  emailConfirmationRoute,
} from '@constants/routes'
import { PureDropdownAutocompleteListOptions } from '@components/ui/PureDropdownAutocomplete'
import { PureTooltipContainer } from '@components/ui/PureTooltip'
import { TenantType } from '@models/common/app'
import { TENANT_TYPE } from '@constants/tenants'
import { getLoginUrlForTenantPages, getProfile, selectUser } from '@state/user'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { selectConfig } from '@state/config'
import { useIsAuthenticated } from '@hooks/common/useIsAuthenticated'
import { UrlManager } from '@helpers/url'

import { defaultTheme as themeVerify } from './AppTheme'
import { defaultTheme as themeHarmReduction } from './AppThemHarm'

const EmailConfirmation = lazy(() =>
  import('@components/modules/EmailConfirmation').then(module => ({
    default: module.EmailConfirmation,
  }))
)
const MaintenanceMode = lazy(() =>
  import('@components/modules/MaintenanceMode').then(module => ({
    default: module.MaintenanceMode,
  }))
)
const OtherSessionPage = lazy(() =>
  import('@components/modules/OtherSessionPage').then(module => ({
    default: module.OtherSessionPage,
  }))
)
const UnsubscribedPublicPage = lazy(() =>
  import('@components/modules/UnsubscribedPage').then(module => ({
    default: module.UnsubscribedPage,
  }))
)
const ProtectedRoutes = lazy(() =>
  import('../routes/ProtectedRoutes').then(module => ({ default: module.ProtectedRoutes }))
)
const PublicRoutes = lazy(() =>
  import('../routes/PublicRoutes').then(module => ({ default: module.PublicRoutes }))
)

export const App: React.FC = React.memo(() => {
  useAuth()
  const { isAuthenticatedLoading, isAuthenticated } = useIsAuthenticated()
  const [isAppPage, currentPage] = useCheckPages([`${BASE_APP_ROUTE}/`], { oneLevelAbove: true })
  const [isLoginPage] = useCheckPages([publicRoutes.login.path], { oneLevelAbove: true })
  const history = useHistory()

  const dispatch = useAppDispatch()
  const { tenant } = useAppSelector(selectConfig)
  const { isFetching: isUserFetching } = useAppSelector(selectUser)

  const MaintenanceModeQ = useFetchMaintenanceMode()
  const TenantQ = useFetchTenant()
  const { hasOtherActiveSession, isMaintenanceModePage: storeIsMaintenanceModePage } =
    useSelector(selectUser)

  useFetchSkills(isAuthenticated && TenantQ.data?.type === TENANT_TYPE.legalDrugs)
  useFetchTestTypes(isAuthenticated && !!TenantQ.data, true)

  const isMaintenanceModePage =
    MaintenanceModeQ.data?.maintenance_mode || storeIsMaintenanceModePage
  const hasAuth = isAuthenticated

  const shouldRenderLoader =
    (isAuthenticatedLoading && !isAppPage) || isUserFetching || MaintenanceModeQ.isFetching

  const checkPage = React.useMemo((): string | null => {
    const { isTenantPage } = new UrlManager()

    if (isMaintenanceModePage) {
      return publicRoutes.maintenanceModePage.path
    } else if (hasOtherActiveSession) {
      return publicRoutes.otherSessionPage.path
    } else if (hasAuth && isTenantPage) {
      return isAppPage ? currentPage : protectedRoutes.home.path
    }
    return isTenantPage ? null : publicRoutes.login.path
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAuth, isMaintenanceModePage, hasOtherActiveSession])

  const checkRoute = React.useMemo((): JSX.Element => {
    if (isMaintenanceModePage) {
      return <Route path={publicRoutes.maintenanceModePage.path} component={MaintenanceMode} />
    } else if (hasOtherActiveSession) {
      return <Route path={publicRoutes.otherSessionPage.path} component={OtherSessionPage} />
    } else if (tenant && hasAuth) {
      return <Route path={BASE_APP_ROUTE} component={ProtectedRoutes} />
    }
    return <Route path={BASE_ACCESSIBLE_ROUTE} component={PublicRoutes} />
  }, [hasAuth, hasOtherActiveSession, isMaintenanceModePage, tenant])

  const tenantTheme: Record<TenantType, Object> = {
    [TENANT_TYPE.legalDrugs]: themeVerify,
    [TENANT_TYPE.streetDrugs]: themeHarmReduction,
  }

  useEffect(() => {
    const { isTenantPage } = new UrlManager()
    if (
      !TenantQ.isFetching &&
      !isAuthenticatedLoading &&
      !isUserFetching &&
      !isMaintenanceModePage &&
      !hasOtherActiveSession &&
      !hasAuth &&
      isTenantPage
    ) {
      const url = getLoginUrlForTenantPages()

      url && window.open(url, '_self')
    }
  }, [
    TenantQ.isFetching,
    isAuthenticatedLoading,
    isUserFetching,
    isMaintenanceModePage,
    hasOtherActiveSession,
    hasAuth,
  ])

  useEffect(() => {
    const { isTenantPage } = new UrlManager()

    if (tenant && isTenantPage) {
      dispatch(getProfile())
    }
  }, [tenant, dispatch])
  const shouldRedirectToLoginPage = checkPage === publicRoutes.login.path
  const showLoader =
    TenantQ.isFetching ||
    isAuthenticatedLoading ||
    (isUserFetching && !shouldRedirectToLoginPage && !isLoginPage)

  return (
    <>
      {showLoader ? (
        <TopProgressBar />
      ) : (
        <ThemeProvider theme={tenantTheme[TenantQ.data?.type as TenantType] || themeVerify}>
          <Suspense fallback={<TopProgressBar />}>
            {shouldRenderLoader && <TopProgressBar />}
            <Switch>
              <Route
                exact
                path={`${CONFIRM_EMAIL_ROUTE}/:user_id/:token/:email/:tenant_name`}
                component={EmailConfirmation}
              />
              {checkRoute}
              {!MaintenanceModeQ.isFetching &&
                !MMUnsubscribeRoute.test(history.location.pathname) &&
                !emailConfirmationRoute.test(history.location.pathname) &&
                checkPage !== null && <Redirect to={checkPage || '/'} />}
              <Route path='/subscriptions/manage/:email' component={UnsubscribedPublicPage} />
            </Switch>
          </Suspense>
          <PureDropdownAutocompleteListOptions />
          <PureTooltipContainer />
          <ScrollToTop />
        </ThemeProvider>
      )}
    </>
  )
})
