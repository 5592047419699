import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'

import { MANUFACTURERS } from '@constants/queries'
import { fetchManufacturers } from '@api/samples'
import { IOptionData } from '@models/samples'

export const useFetchManufacture = (
  desiredOptions: string | undefined
): UseInfiniteQueryResult<IOptionData> => {
  return useInfiniteQuery([MANUFACTURERS, desiredOptions], fetchManufacturers, {
    getNextPageParam: lastPage => {
      if (lastPage.current_page < lastPage.count_pages) return lastPage.current_page + 1
      return false
    },
  })
}
