import React from 'react'

import { StyledTableSampleCell } from '@styles'
import { ISample } from '@models/samples'
import { TempSensitiveToxicityIcons } from '@components/shared'
import { SpecificId } from '@components/shared/sampleFields'
import { protectedRoutes } from '@configs/routes.config'

interface SampleIDProps {
  report: ISample
  hideBorder?: boolean
}

export const SampleID: React.FC<SampleIDProps> = ({ report, hideBorder }) => {
  const {
    specific_id: specificId,
    initial_laboratory: specificIdTooltip,
    initial_laboratory,
    toxicity_level,
    min_temperature,
    max_temperature,
    temperature_measurement_format,
  } = report

  return (
    <StyledTableSampleCell
      padding='16px 25px 16px 48px'
      isClickable
      hideBorder={hideBorder}
      sx={{ position: 'relative' }}
    >
      <SpecificId
        labelTooltip={specificIdTooltip.label_tooltip}
        specificId={specificId}
        laboratory={initial_laboratory}
        pathForLink={protectedRoutes.testingReports.path}
        titleForPathLink='Test reports'
      />
      <TempSensitiveToxicityIcons
        toxicity={toxicity_level}
        minTempSensitive={min_temperature}
        maxTempSensitive={max_temperature}
        temperatureMeasurementFormat={temperature_measurement_format?.key}
        mt='-5px'
      />
    </StyledTableSampleCell>
  )
}
