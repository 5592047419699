import React from 'react'

import { ISample } from '@models/samples'
import { SubText } from '@styles'

import { StyledTableCell } from '../styles'

interface DosageProps {
  sample: ISample
}

export const Dosage: React.FC<DosageProps> = ({ sample }) => {
  const { dosage, dosage_type } = sample

  return (
    <StyledTableCell>
      <span>
        {parseFloat(dosage)}
        {dosage_type && (
          <SubText sx={{ marginLeft: '8px', lineHeight: '20px', fontSize: 14 }}>
            {dosage_type.name}
          </SubText>
        )}
      </span>
    </StyledTableCell>
  )
}
