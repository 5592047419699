import { Box, useMediaQuery } from '@mui/material'

import { StyledTemperatureSpan, StyledTempParagraph } from '@styles'
import { ReactComponent as Thermometer } from '@assets/icons/thermometer.svg'
import { getTemperatureSymbol } from '@helpers/obtainingCorrectTemperature'

interface TemperatureFieldProps {
  minTemperature?: string | null
  maxTemperature?: string | null
  temperatureMeasurementFormatKey?: string | null
  maxTemperatureMeasurementFormat?: string
  minTemperatureMeasurementFormat?: string
}

export const TemperatureField: React.FC<TemperatureFieldProps> = props => {
  const {
    maxTemperature,
    minTemperature,
    temperatureMeasurementFormatKey,
    maxTemperatureMeasurementFormat,
    minTemperatureMeasurementFormat,
  } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  // * we will use this logic after implementation feature - temperature format in user profile
  // const tempType = useAppSelector(userTemperatureFormat)
  // const isSampleHasSameTempFormatAsUser =
  //   sample.temperature_measurement_format?.key === tempType.key
  // const tempSymbol = tempType.key === CELSIUS ? '°C' : '°F'

  // * we will use this function after implementation feature - temperature format in user profile
  // const temperature = (temperature: string) => {
  //   if (isSampleHasSameTempFormatAsUser) {
  //     return `${Math.round(+temperature)} ${tempSymbol}`
  //   } else {
  //     const obtainedTemperature = obtainingCorrectTemperature(tempType.key, temperature)

  //     return `${Math.round(+obtainedTemperature)} ${tempSymbol}`
  //   }
  // }

  const minTempSymbol = getTemperatureSymbol(
    minTemperatureMeasurementFormat || temperatureMeasurementFormatKey
  )
  const maxTempSymbol = getTemperatureSymbol(
    maxTemperatureMeasurementFormat || temperatureMeasurementFormatKey
  )
  return (
    <>
      {maxTemperature && minTemperature && (
        <StyledTempParagraph $isMobile={isMobile}>
          <Box mb={isMobile ? '10px' : '0'} sx={{ whiteSpace: 'nowrap' }}>
            min <Thermometer style={{ marginBottom: '-4px' }} />
            <StyledTemperatureSpan style={{ marginRight: '16px' }}>
              {/* {temperature(minTemperature)} */}
              {`${Math.round(+minTemperature)}${minTempSymbol}`}
            </StyledTemperatureSpan>
          </Box>
          <Box sx={{ whiteSpace: 'nowrap' }}>
            max
            <Thermometer style={{ marginBottom: '-4px' }} />
            <StyledTemperatureSpan>
              {/* {temperature(maxTemperature)} */}
              {`${Math.round(+maxTemperature)}${maxTempSymbol}`}
            </StyledTemperatureSpan>
          </Box>
        </StyledTempParagraph>
      )}
    </>
  )
}
