import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { Input } from '@components/shared'
import { MAX_CHARACTERS, MIN_CHARACTERS, REQUIRED_FIELD } from '@constants/validation'
import { StyledDetailsLabel } from '@styles'

import { StyledSubstanceBox } from '../styles'
import { SubstancesItemGroupPropTypes } from '../substancesRecognized.types'

export const Substance: React.FC<SubstancesItemGroupPropTypes> = props => {
  const { index, isNotRecognized } = props
  const {
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext()

  // CLEAR ERRORS after toggle recognized checkbox
  React.useEffect(() => {
    if (isNotRecognized) {
      if (!!errors.fields?.substances?.[index]?.name?.message) {
        clearErrors(`fields.substances[${index}].name`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotRecognized])

  return (
    <StyledSubstanceBox>
      <StyledDetailsLabel color={isNotRecognized ? 'primary.light' : 'text.primary'} mb='4px'>
        Substance
      </StyledDetailsLabel>
      <Input
        width='100%'
        height={48}
        variant='outlined'
        type='text'
        disabled={isNotRecognized}
        placeholder='Type substance here...'
        error={!!errors.fields?.substances?.[index]?.name?.message}
        helperText={errors.fields?.substances?.[index]?.name?.message}
        {...register(`fields.substances[${index}].name`, {
          required: isNotRecognized ? false : REQUIRED_FIELD,
          minLength: { value: 2, message: MIN_CHARACTERS(2) },
          maxLength: { value: 255, message: MAX_CHARACTERS(255) },
        })}
      />
    </StyledSubstanceBox>
  )
}
