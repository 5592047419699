import { useMutation, useQueryClient } from 'react-query'
import { StringParam, useQueryParams } from 'use-query-params'

import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { selectRequests } from '@state/requests'
import { COUNTERS, INCOMING_REQUESTS } from '@constants/queries'
import { updateIncomingRequestStatus } from '@api/requests'
import { IRequestsData, IUpdatedRequestData } from '@models/requests'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'
import { selectSearchSuggestions } from '@state/app'

export const useUpdateIncomingRequestStatus = () => {
  const client = useQueryClient()
  const dispatch = useAppDispatch()
  const suggestionSampleSearching = useAppSelector(selectSearchSuggestions)
  const stringParams = {
    status: StringParam,
  }

  const [queryString] = useQueryParams(stringParams)
  const queryStringStatus = queryString.status?.split(',')

  const mutation = useMutation<unknown, IError, IUpdatedRequestData>(updateIncomingRequestStatus, {
    onSuccess: async (_, { requests, status, request_type }) => {
      if (!!queryStringStatus) client.invalidateQueries(INCOMING_REQUESTS)
      else
        client.setQueryData<{ pageParams: any; pages: IRequestsData[] | undefined }>(
          [
            INCOMING_REQUESTS,
            request_type,
            String(suggestionSampleSearching) || null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ],
          oldData => {
            return {
              pages: oldData?.pages?.map(page => ({
                ...page,
                results: page.results.map(req => {
                  const hasUpdatedReq = requests.find(request => req.id === request)

                  return hasUpdatedReq
                    ? {
                        ...req,
                        status: {
                          key: status,
                          value: status,
                        },
                      }
                    : req
                }),
              })),
              pageParams: oldData?.pageParams,
            }
          }
        )
      dispatch(selectRequests([]))
      client.invalidateQueries(COUNTERS)
    },

    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
