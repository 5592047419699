// * [View Test Models
import { INCONCLUSIVE, NEGATIVE, POSITIVE } from '@constants/tasks'

export const PAD = 'PAD'
export const HPLC = 'HPLC'
export const MINILAB = 'MINILAB'
export const LCMS = 'LC/MS'
export const NEARIR = 'Near IR'
export const VISUAL_INSPECTION = 'Visual inspection'

export type TestType =
  | typeof PAD
  | typeof HPLC
  | typeof MINILAB
  | typeof LCMS
  | typeof NEARIR
  | typeof VISUAL_INSPECTION

export type TestTypeQuery = 'pad' | 'minilab' | 'hplc' | 'lcms' | 'nearir' | 'visual_inspection'

export const ASSAY_GROUP = 'Assay'
export const SCREENING_GROUP = 'Screening'
export const VISUAL_GROUP = 'Visual'

export type TestGroupName = typeof ASSAY_GROUP | typeof SCREENING_GROUP | typeof VISUAL_GROUP

export interface IStep<T> {
  task: T // ITaskLab | IHrTaskLab
  isOpen: boolean
  actualStep: number
  shouldRender: boolean
  isFetching?: boolean
}

// * View Test Models]

// * [HR View Test Models

export const FTS = 'FTS'
export const BTS = 'BTS'
export const XTS = 'XTS'
export const IDPAD = 'idPAD'
export const TandemMS = 'Tandem MS'
export const idPAD = 'idPAD'

export type HrTestType = typeof FTS | typeof BTS | typeof XTS | typeof TandemMS | typeof idPAD

export type HrTestTypeQuery = 'fts'

// * HR View Test Models]

export type TestResultType = typeof POSITIVE | typeof NEGATIVE | typeof INCONCLUSIVE
