import { RootState } from '@state/store'

export const selectNotifications = (state: RootState) => state.app.notifications
export const selectAppFlags = (state: RootState) => state.app.flags
export const selectSearchSuggestions = (state: RootState) => state.app.suggestionSampleSearching
export const selectedApis = (state: RootState) => state.app.editSampleApisValues
export const selectedFundingList = (state: RootState) => state.app.editFundingList
export const sampleCreateIds = (state: RootState) => state.app.sampleCreateIds
export const samplesTouchedFields = (state: RootState) => state.app.isTouchedFields
export const copySampleId = (state: RootState) => state.app.copySampleId
export const addSampleButtonPushedNumb = (state: RootState) => state.app.addSampleButtonPushed
export const isLoadingPackage = (state: RootState) => state.app.isLoadingCreatePackage

export const attachmentsCreateSample = (state: RootState) => state.app.createSampleAttachments
export const hrAttachmentsCreateSample = (state: RootState) => state.app.hrCreateSampleAttachments
