import React from 'react'
import { useDispatch } from 'react-redux'
import { useController } from 'react-hook-form'

import { PureChip, PureDropdownAutocomplete } from '@components/ui'
import { useFetchApisFilters } from '@hooks/queries'
import { setCellValueToSample } from '@state/sampleCreate'
import { IOption } from '@models/common/app'
import { IOptionExtended } from '@components/ui/PureDropdownAutocomplete/pureDropdownAutocomplete.types'
import { ATTACHMENTS, MAIN_APIS } from '@constants/samples'
import { setAddedSkills, setTriggerFlag } from '@state/common/sampleCreateUIState'
import { setCreateSampleAttachments } from '@state/app'

import { PureCellPropTypes } from './pureFields.types'
import { StyledChipWrap } from './styles'

export const Apis: React.FC<PureCellPropTypes> = ({
  control,
  name,
  cellName,
  sampleId,
  rules,
  initialVal,
  inputPlaceholder,
  isCopy,
}) => {
  const dispatch = useDispatch()
  const [searchQuery, setSearchQuery] = React.useState('')
  const [apis, setApis] = React.useState<IOptionExtended[]>([])

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: initialVal.val })

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useFetchApisFilters(searchQuery)
  const pureOptions = data && data.pages.map(page => page.results).flat()

  const correctOptions = React.useMemo(() => {
    if (pureOptions) {
      return pureOptions?.filter(({ id }) => !apis.some(option => option.id === id)) || []
    }
    return []
  }, [pureOptions, apis])

  const setCellValue = (val: IOption[] | null, error: string) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val, error },
        cellName,
        sampleId,
      })
    )

    // update Attachment section if Apis was changed
    dispatch(
      setCellValueToSample({
        cellValue: { val: null, error: '' },
        cellName: ATTACHMENTS,
        sampleId,
      })
    )
    dispatch(
      setCreateSampleAttachments({
        cellValue: [],
        sampleId: '',
        isApplyNewAttachments: false,
      })
    )
  }

  React.useEffect(() => {
    if (initialVal.val && Array.isArray(initialVal.val) && initialVal.val.length) {
      setApis(initialVal.val)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (error && error.message) setCellValue(field.value, error.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onDropdownChange = (val: IOptionExtended) => {
    setApis(prevState => {
      const newArr = [...prevState, val]
      field.onChange({ target: { value: newArr } })
      setCellValue(newArr, '')
      return newArr
    })

    if (val.skills?.length) dispatch(setAddedSkills(val.skills))

    if (searchQuery) setSearchQuery('')
  }

  const handleDeleteSelectedApi = (id: number) => {
    const newArr = apis.filter(({ id: apiId }) => apiId !== id)
    field.onChange({
      target: { value: newArr },
    })
    setCellValue(newArr, '')
    setApis(newArr)
    dispatch(setTriggerFlag())
  }

  return (
    <>
      <PureDropdownAutocomplete
        disabledOptions={name.includes(MAIN_APIS) && apis.length >= 3}
        isCopy={isCopy}
        isLoading={isFetching || isLoading}
        name={name}
        inputPlaceholder={inputPlaceholder}
        options={correctOptions as IOptionExtended[]}
        errorMessage={error?.message || ''}
        onInputChange={val => setSearchQuery(val)}
        onDropdownChange={onDropdownChange}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isCleanInputAfterSelectOption
      />
      <StyledChipWrap>
        {apis.map(api => (
          <PureChip
            tooltipWidth={'calc(100% - 38px)'}
            truncatedLabelLength={23}
            key={api.id}
            isMainChip
            label={api.name}
            onDelete={() => handleDeleteSelectedApi(api.id)}
          />
        ))}
      </StyledChipWrap>
    </>
  )
}
