import React from 'react'

import { useLogIntoPadReaderApp } from '@hooks/queries'
import { Modal } from '@components/shared'

import { DesktopModal } from './DesktopModal'

interface LogIntoPadAppProps {
  toggleModal: () => void
}

export const LogIntoPadApp: React.FC<LogIntoPadAppProps> = ({ toggleModal }) => {
  const scanQrForPadApp = useLogIntoPadReaderApp()
  const padApp = scanQrForPadApp.data

  return (
    <Modal open onClose={toggleModal}>
      <DesktopModal
        padApp={padApp}
        isLoading={scanQrForPadApp.isLoading}
        toggleModal={toggleModal}
      />
    </Modal>
  )
}
