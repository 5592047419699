import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { ReactComponent as AddIcon } from '@assets/icons/add-icon-blue.svg'
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { Paragraph } from '@styles'
import { protectedRoutes } from '@configs/routes.config'
import { selectTasks, setAddedAttachments, setIsEditAttachments } from '@state/tasks'
import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { AddAttachedMaterialBox } from './styles'

interface AddAttachmentProps {
  packageId?: number
  setIsEditMode: () => void
  btnName: 'Edit' | string
  modalType: 'materials' | 'package_storage'
}

export const AddAttachment: React.FC<AddAttachmentProps> = ({
  packageId,
  setIsEditMode,
  btnName,
  modalType,
}) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const { addedAttachments } = useAppSelector(selectTasks)

  const attachPath =
    modalType === 'package_storage'
      ? protectedRoutes.attachShipmentMaterials.path
      : protectedRoutes.attachMaterials.path
  const attachMaterialsEndpoint = {
    pathname: attachPath.replace(':packageId', `${packageId}`).replace(':modalType', modalType),
    state: history.location.state,
  }

  const handleSetEditMode = () => {
    if (addedAttachments) dispatch(setAddedAttachments(null))
    dispatch(setIsEditAttachments(true))
    setIsEditMode()
  }

  return (
    <Box>
      <AddAttachedMaterialBox
        sx={{ alignItems: 'center' }}
        to={attachMaterialsEndpoint}
        $isTextDecorationAbsence
        onClick={handleSetEditMode}
      >
        {btnName === 'Edit' ? (
          <EditIcon fill={theme.palette.primary.main} />
        ) : (
          <AddIcon fill={theme.palette.primary.main} />
        )}
        <Typography sx={{ marginLeft: '13px', fontWeight: 500 }} variant='body1'>
          {btnName}
        </Typography>
      </AddAttachedMaterialBox>
      <Paragraph variant='body2' color='secondary.light'>
        All popular format of image, microsoft document, PDF and spreadsheet
      </Paragraph>
    </Box>
  )
}
