import React from 'react'

import { ArrowTopIcon } from '@components/icons'

import { StyledButton } from './styles'

export const ScrollToTopButton = () => {
  const [visible, setVisible] = React.useState(false)

  const toggleVisible = React.useCallback(() => {
    const scrolled = document.documentElement.scrollTop

    if (scrolled > 400) setVisible(true)
    else if (scrolled <= 400) setVisible(false)
  }, [])

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  React.useEffect(() => {
    window.addEventListener('scroll', toggleVisible)

    return () => window.removeEventListener('scroll', toggleVisible)
  })

  return (
    <StyledButton onClick={scrollToTop} sx={{ display: visible ? 'flex' : 'none' }}>
      <ArrowTopIcon />
    </StyledButton>
  )
}
