import React from 'react'
import { Autocomplete, CircularProgress } from '@mui/material'
import { RegisterOptions, useController } from 'react-hook-form'

import { IOption } from '@models/common/app'
import { AutocompleteWrapper, Input } from '@components/shared'
import { useFetchExcipients } from '@hooks/queries'
import { PureChip } from '@components/ui'

import { StyledBox, StyledStack } from './styles'

interface ApiFieldProps {
  name: string
  isCopy?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}

export const ExcipientsField: React.FC<ApiFieldProps> = React.memo(props => {
  const { name, isCopy, rules } = props
  const [searchQuery, setSearchQuery] = React.useState('')
  const [apisWasTouched, setApisWasTouched] = React.useState(false)

  const [topScroll, setTopScroll] = React.useState<{ oldScrollTop: number; isScrollTop: boolean }>({
    oldScrollTop: 0,
    isScrollTop: false,
  })

  const { field, fieldState } = useController({ name, rules, defaultValue: [] })
  const { data, isFetching, fetchNextPage, hasNextPage } = useFetchExcipients(searchQuery)
  const options = data && data.pages.map(page => page.results).flat()

  const { isTouched, error } = fieldState
  const isHighlighting = isCopy && !isTouched && !apisWasTouched
  const excipients: IOption[] = field.value || []

  const onChangeHandler = (e: any, v: IOption[] | null) => {
    field.onChange({ target: { value: v } })
  }

  const deleteApiHandler = (id: number) => {
    setApisWasTouched(true)
    field.onChange({
      target: { value: excipients.filter(({ id: excipientId }) => excipientId !== id) },
    })
  }

  const onInputChangeHandler = (e: any, v: string) => {
    setSearchQuery(v)
  }

  return (
    <StyledBox>
      <AutocompleteWrapper height={42} bgColor={isHighlighting ? '#FFF5D6' : '#fff'}>
        <Autocomplete
          disableClearable
          multiple
          fullWidth
          size='small'
          options={options || []}
          loading={isFetching}
          filterSelectedOptions
          noOptionsText='Nothing found'
          getOptionLabel={option => option.name}
          getOptionDisabled={() => field.value.length >= 5}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderTags={value => <span>{value[value.length - 1].name}</span>}
          onInputChange={onInputChangeHandler}
          ListboxProps={{
            onScroll: event => {
              const listboxNode = event.currentTarget
              const isFetchNextPage =
                hasNextPage &&
                Math.round(listboxNode.scrollTop + listboxNode.clientHeight) ===
                  listboxNode.scrollHeight

              if (isFetchNextPage) {
                const top = Math.round(listboxNode.scrollHeight - listboxNode.clientHeight)
                fetchNextPage()
                setTopScroll({ oldScrollTop: top, isScrollTop: true })
              }
              if (listboxNode.scrollHeight > topScroll.oldScrollTop && topScroll.isScrollTop) {
                listboxNode.scrollTo(0, topScroll.oldScrollTop)
                setTopScroll(prev => ({ ...prev, isScrollTop: false }))
              }
            },
          }}
          renderInput={params => (
            <Input
              variant='outlined'
              placeholder={!excipients.length ? 'Search here...' : ''}
              error={!!error}
              helperText={error && error.message}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isFetching ? (
                      <CircularProgress color='primary' sx={{ marginRight: '44px' }} size={16} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          {...field}
          onChange={onChangeHandler}
        />
      </AutocompleteWrapper>

      <StyledStack spacing={0.8}>
        {excipients.map((excipient, idx) => (
          <PureChip
            tooltipWidth={'calc(100% - 38px)'}
            truncatedLabelLength={23}
            key={excipient.id}
            isMainChip
            label={excipient.name}
            onDelete={() => deleteApiHandler(excipient.id)}
            isExcipientsChip
          />
        ))}
      </StyledStack>
    </StyledBox>
  )
})
