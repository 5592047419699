import React from 'react'
import { Collapse, IconButton, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'

import closeUnits from '@assets/icons/close-sample-units-list.svg'
import openUnits from '@assets/icons/open-sample-units-list.svg'
import { Button, Input, Modal, Tooltip } from '@components/shared'
import { useFetchSample, useSplitSample } from '@hooks/queries'
import {
  ButtonBox,
  StyledLabel,
  StyledModalContent,
  StyledModalSubtitle,
  StyledModalTitle,
  StyledShadowBox,
} from '@styles'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import {
  normalizeSubSamples,
  selectSamplesSplit,
  setTotalUnits,
  changeEditMode,
  recountListOfUnits,
  resetSplitState,
  changeSubSamples,
  recountMaxLimitPerUnit,
} from '@state/samples'
import { useToggle } from '@hooks/common'
import { MAXIMUM_SPLIT_NUMBER } from '@constants/samples'
import { ISample } from '@models/samples'

import {
  StyledForm,
  StyledModalScroll,
  CounterElement,
  CounterPanel,
  InputContainer,
} from './styles'
import { Unit } from './components/Unit'

interface SplitSampleProps {
  sampleId: number
  units: number
  onClose: () => void
  setSampleDataIsLoading?: (val: boolean) => void
}

export const SplitSample: React.FC<SplitSampleProps> = props => {
  const { sampleId, units, onClose, setSampleDataIsLoading } = props
  const {
    totalUnits,
    unitsLeft,
    subSamples,
    editMode,
    listOfUnits,
    maxLimitPerUnit,
    globalErrorStatus,
    globalRecountStatus,
  } = useAppSelector(selectSamplesSplit)
  const dispatch = useAppDispatch()

  const fetchSampleQ = useFetchSample<ISample>(`${sampleId}`)

  const [inputError, setInputError] = React.useState('')
  const [toggleValidationNotification, toggleValidationNotificationHandler] = React.useState(false)
  const [inputWasFocused, setInputWasFocused] = useToggle()
  const [boxShadow, setBoxShadow] = React.useState(true)

  const isSmallHeight = useMediaQuery('(max-height:700px)')
  const splitSamplesM = useSplitSample()

  React.useEffect(() => {
    if (!!setSampleDataIsLoading) setSampleDataIsLoading(fetchSampleQ.isLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSampleQ])

  const splitNumber =
    totalUnits > MAXIMUM_SPLIT_NUMBER - (fetchSampleQ.data?.family_children_count || 0)
      ? MAXIMUM_SPLIT_NUMBER - (fetchSampleQ.data?.family_children_count || 0)
      : totalUnits
  const availableSplitNumber = splitNumber !== 5 ? splitNumber + 1 : splitNumber
  const isBtnDisabled = +subSamples < 2 || +subSamples > availableSplitNumber

  React.useEffect(() => {
    dispatch(setTotalUnits(units))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units])

  React.useEffect(() => {
    if (isSmallHeight) {
      if (editMode && listOfUnits.length > 0) setBoxShadow(false)
      else setBoxShadow(true)
    } else {
      if (editMode && listOfUnits.length > 20) setBoxShadow(false)
      else setBoxShadow(true)
    }
  }, [listOfUnits, isSmallHeight, setBoxShadow, editMode])

  React.useEffect(() => {
    if (+subSamples < 0) {
      setInputError("Value can't be negative")
    } else if (+subSamples === 0) {
      setInputError("Value can't be null")
    } else if (!Number.isInteger(+subSamples)) {
      setInputError('Value cannot be decimal')
    } else {
      setInputError('')
    }
  }, [subSamples])

  React.useEffect(() => {
    !inputWasFocused && dispatch(recountListOfUnits({}))
  }, [inputWasFocused, subSamples, dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(resetSplitState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const subSamplesOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(changeSubSamples(event.target.value))

  const subSamplesOnBlur = () => {
    if (+subSamples < 2 || +subSamples > totalUnits) toggleValidationNotificationHandler(true)
    setInputWasFocused()
    dispatch(normalizeSubSamples(availableSplitNumber))
    dispatch(recountListOfUnits({}))
    dispatch(recountMaxLimitPerUnit())
  }

  const iconButtonOnclick = () => dispatch(changeEditMode())
  const recountOnClick = () => {
    dispatch(recountListOfUnits({ recount: true }))
  }

  const splitSamplesHandler = () => {
    splitSamplesM.mutate(
      { id: sampleId, data: { count_of_sub_samples: listOfUnits.map(({ value }) => value) } },
      { onSettled: onClose }
    )
  }

  return !fetchSampleQ.isLoading ? (
    <Modal isPaddingAbsence open onClose={onClose} disableAutoFocus={true}>
      <StyledForm sx={{ width: '650px' }} noValidate>
        <StyledShadowBox shadow={boxShadow}>
          <StyledModalTitle variant='h3'>How do you want to split sample?</StyledModalTitle>
          <StyledModalSubtitle withoutMarginBelow>
            Please select amount of sub-samples and distribute units between of them
          </StyledModalSubtitle>
        </StyledShadowBox>

        <StyledModalContent pt='6px'>
          <StyledModalScroll height={isSmallHeight ? '230px' : '300px'}>
            <CounterPanel>
              <CounterElement>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography mb='2px' color='#778299' fontSize='12px' variant='body1'>
                    Total units
                  </Typography>
                  <Typography color='primary.main' fontSize='24px' variant='body1'>
                    {totalUnits}
                  </Typography>
                </Box>
                <Collapse orientation='horizontal' in={unitsLeft < 0}>
                  <Button
                    variant='contained'
                    onClick={recountOnClick}
                    height='32px'
                    ml='16px'
                    padding='6px 20px'
                  >
                    Recount
                  </Button>
                </Collapse>
              </CounterElement>
              <Collapse orientation='horizontal' in={unitsLeft > 0}>
                <CounterElement marginLeft='16px'>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{ whiteSpace: 'nowrap' }}
                      mb='2px'
                      color='#778299'
                      fontSize='12px'
                      variant='body1'
                    >
                      Left units
                    </Typography>
                    <Typography color='#AF4645' fontSize='24px' variant='body1'>
                      {unitsLeft}
                    </Typography>
                  </Box>
                  <Button
                    variant='contained'
                    onClick={recountOnClick}
                    height='32px'
                    ml='16px'
                    padding='6px 20px'
                  >
                    Recount
                  </Button>
                </CounterElement>
              </Collapse>
            </CounterPanel>

            <StyledLabel
              label='Quantity of sub-samples'
              mb='16px'
              width='fit-content'
              control={
                <InputContainer>
                  <Tooltip
                    title={`This number must be between 2 and ${availableSplitNumber}`}
                    toggleValidationNotification={toggleValidationNotification}
                    toggleValidationNotificationHandler={toggleValidationNotificationHandler}
                  >
                    <Box>
                      <Tooltip title={`This number must be between 2 and ${availableSplitNumber}`}>
                        <Input
                          width='79px'
                          variant='outlined'
                          type='number'
                          onChange={subSamplesOnChange}
                          onBlur={subSamplesOnBlur}
                          onFocus={setInputWasFocused}
                          value={subSamples}
                          error={Boolean(inputError)}
                          helperText={inputError}
                          disabled={editMode}
                          $helperTextNoWrap
                        />
                      </Tooltip>
                    </Box>
                  </Tooltip>

                  {!inputError && (
                    <IconButton
                      sx={{ marginLeft: '10px', height: '42px' }}
                      onClick={iconButtonOnclick}
                    >
                      {editMode ? (
                        <Tooltip title='Equal quantity'>
                          <img src={closeUnits} alt='close units' />
                        </Tooltip>
                      ) : (
                        <Tooltip title='Independent quantity'>
                          <img src={openUnits} alt='open units' />
                        </Tooltip>
                      )}
                    </IconButton>
                  )}
                </InputContainer>
              }
            />

            <Collapse in={editMode}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '32px' }}>
                {listOfUnits.map((value, idx) => (
                  <Unit
                    idx={idx}
                    unit={value}
                    key={idx}
                    subSamples={+subSamples}
                    totalUnits={totalUnits}
                    maxLimitPerUnit={maxLimitPerUnit}
                    globalErrorStatus={globalErrorStatus}
                    globalRecountStatus={globalRecountStatus}
                  />
                ))}
              </Box>
            </Collapse>
          </StyledModalScroll>
        </StyledModalContent>

        <StyledShadowBox shadow={boxShadow}>
          <ButtonBox>
            <Button
              type='button'
              variant='outlined'
              color='secondary'
              width='205px'
              height='48px'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Tooltip
              title={
                unitsLeft > 0
                  ? `There are ${unitsLeft} units left, please recount before split sample`
                  : 'There are more units than needed, please recount before split sample'
              }
              shouldBeHidden={unitsLeft === 0}
            >
              <Box>
                <Button
                  variant='contained'
                  width='205px'
                  height='48px'
                  loading={splitSamplesM.isLoading}
                  disabled={unitsLeft !== 0 || isBtnDisabled}
                  onClick={splitSamplesHandler}
                >
                  Split sample
                </Button>
              </Box>
            </Tooltip>
          </ButtonBox>
        </StyledShadowBox>
      </StyledForm>
    </Modal>
  ) : null
}
