import React from 'react'
import { Box, Collapse } from '@mui/material'

import { useToggle } from '@hooks/common'
import { IPackage } from '@models/packages'
import { MobileCardLoader, MobileDetailsInfoCard } from '@components/shared'
import { StyledBoxMobile } from '@styles'
import { useFetchAttachments } from '@hooks/queries'

import { PackageAttachments } from '../../PackageDetailsContent'

import { PackageMainInfoMobile } from '.'

interface PackageInfoMobileProps {
  packagesData: IPackage
  isLoadingPackageDetails?: boolean
  isPackageStatusNotSent?: boolean
}

export const PackageInfoMobile: React.FC<PackageInfoMobileProps> = props => {
  const { packagesData, isLoadingPackageDetails, isPackageStatusNotSent } = props

  const [openMainInfo, setIsOpenMainInfo] = useToggle()
  const [openAttachments, setIsOpenAttachments] = useToggle()

  const fetchAttachmentsQ = useFetchAttachments({
    content_type: 'package',
    object_id: packagesData.id,
  })
  const attachments = fetchAttachmentsQ.data
  const isLoadingAttachSection = fetchAttachmentsQ.isLoading

  const shouldRenderAttachSection = !isLoadingAttachSection && attachments && !!attachments?.length

  const cards = [
    {
      title: 'Attached materials',
      component: PackageAttachments,
      data: packagesData,
      open: openAttachments,
      setIsOpen: setIsOpenAttachments,
      shouldRenderComponent: shouldRenderAttachSection || isPackageStatusNotSent,
    },
    {
      title: 'Main info',
      component: PackageMainInfoMobile,
      data: packagesData,
      open: openMainInfo,
      setIsOpen: setIsOpenMainInfo,
      shouldRenderComponent: true,
    },
  ]

  if (isLoadingPackageDetails || isLoadingAttachSection) return <MobileCardLoader />

  return (
    <>
      {cards.map(
        ({ title, data, open, setIsOpen, component: Component, shouldRenderComponent }) => (
          <React.Fragment key={title}>
            {shouldRenderComponent && (
              <StyledBoxMobile>
                <MobileDetailsInfoCard title={title} open={open} setIsOpen={setIsOpen} />

                <Box>
                  <Collapse in={open} timeout='auto' unmountOnExit>
                    <Component data={data} packageId={data?.id} />
                  </Collapse>
                </Box>
              </StyledBoxMobile>
            )}
          </React.Fragment>
        )
      )}
    </>
  )
}
