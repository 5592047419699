import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { Button } from '@components/shared'

import { ActionFooterButtonsPropTypes } from './editTestResultsModal.types'
import * as Styled from './styles'

export const ActionFooterButtons: React.FC<ActionFooterButtonsPropTypes> = props => {
  const { control, handleSubmit } = useFormContext()
  const [isLoading, isActionBtnDisable] = useWatch({
    control,
    name: ['isLoading', 'isActionBtnDisable'],
  })
  const { onClose, handleAction } = props

  return (
    <Styled.ButtonBox>
      <Button
        type='button'
        variant='outlined'
        color='secondary'
        sx={{ marginRight: '28px' }}
        width='200px'
        height='48px'
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        type='submit'
        variant='contained'
        width='200px'
        height='48px'
        disabled={isActionBtnDisable}
        loading={isLoading}
        onClick={handleSubmit(handleAction)}
      >
        Save
      </Button>
    </Styled.ButtonBox>
  )
}
