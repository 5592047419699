export const DESCRIPTION_STEP_TITLE = 'Test description'
export const TESTING_STEP_TITLE = 'Testing'
export const RESULTS_STEP_TITLE = 'Result'
export const ADDITIONAL_INFO = 'Additional info'

export const ATTACHMENT_MATERIAL_SECTION = 'material'
export const ATTACHMENT_TEST_REPORT_SECTION = 'test_report'
export const ATTACHMENT_PHOTO_OF_SAMPLE_SECTION = 'photo_of_sample'
export const ATTACHMENT_EQUIPMENT_NUMBER_SECTION = 'equipment_number'
export const ATTACHMENT_FEEDBACK_SECTION = 'feedback'
export const ATTACHMENT_STORAGE_SECTION = 'storage_documents'
export const ATTACHMENT_FTS_SECTION = 'photo_of_test_strip'

export const ATTACHMENT_MATERIAL_TITLE = 'Material'
export const ATTACHMENT_TEST_REPORT_TITLE = 'Test report'
export const ATTACHMENT_TEST_MINILAB_TITLE = 'Photographs of TLC plates and lab notebook pages'
export const ATTACHMENT_PHOTO_OF_SAMPLE_TITLE = 'Photo of sample'
export const ATTACHMENT_EQUIPMENT_NUMBER_TITLE =
  'Equipment numbers, control chart, and system suitability'
export const ATTACHMENT_STORAGE_TITLE = 'Storage documents'

export const ATTACHMENT_LINK = 'link'
export const ATTACHMENT_FILE = 'file'

export const DESIRED_TEST_REPORTS = 'desired_test_reports'

export const MAX_NUMBER_OF_ATTACHMENTS = 3

// * [HR
export const ATTACHMENT_TEST_STRIP_TITLE = 'Photo of test strip'
export const TEST_INFO_TITLE = 'Test info'

export const TEST_RESULT = 'result'
export const TEST_STRIP_ATTACHMENTS = 'attachments'
export const ATTACHMENTS = 'attachments'
export const TEST_NOTES = 'test_notes'
export const TEST_PREPARATION = 'preparation'
export const TEST_BRAND = 'test_strip_brand'
export const TEST_BATCH = 'test_strip_batch'
export const TEST_STRIP = 'test_strip'
export const TEST_FOR_PREPARATION = 'preparation_for_test'
export const ATTACHMENT_TEST_INFO_SECTION = 'test_info'
export const SUBSTANCES = 'substances'
export const SUBSTANCES_RECOGNIZED = 'substances_recognized'
export const DELETED_RECOGNIZED = 'deleted_substances'
export const RESULT_RECOGNIZED = 'result_is_not_recognized'

// * HR]
