import ContentLoader from 'react-content-loader'

export const ScrollSuggestionNextSamplesLoader: React.FC = props => (
  <ContentLoader
    speed={2}
    width={91}
    height={27}
    viewBox='0 0 91 27'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    {...props}
  >
    <rect x='0' y='7' rx='4' ry='4' width='72' height='20' />
  </ContentLoader>
)
