import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useQueryClient } from 'react-query'
import { cloneDeep } from 'lodash'
import { useForm } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'

import { Button, Modal, Input } from '@components/shared'
import { PureTooltip } from '@components/ui/PureTooltip'
import { formationTests } from '@helpers/formationTests'
import { TEST_TYPES } from '@constants/queries'
import { IAvailableTestTypesInfo } from '@models/yourWorkspace'
import { ElementWithIncreaseItsValue } from '@components/ui/ElementWithIncreaseItsValue'
import { ReactComponent as InfoIcon } from '@assets/icons/info-circle.svg'
import { MAX_TASKS_TO_ADD } from '@constants/tasks'
import { TandemMSSelectMethod } from '@components/shared/Hr/TandemMS/TandemMSSelectMethod'
import { TandemMS } from '@models/tests'

import { StyledTitle } from '../../../../pages/ForgotPassword/styles'
import { AddingTestModalTypes } from './AddingTestModal.types'
import * as Styled from './styles'

export const AddingTestModal: React.FC<AddingTestModalTypes> = ({
  onClose,
  availableTestsToSet,
  handleCreateTests,
}) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const queryClient = useQueryClient()
  const testTypesData = queryClient.getQueryData<IAvailableTestTypesInfo[]>([TEST_TYPES, true])
  const correctOptions = React.useMemo(() => formationTests(testTypesData || []), [testTypesData])
  const [isSending, setIsSending] = React.useState<boolean>(false)
  const [testData, setTestData] = React.useState<
    { id: number; count: number; methods?: number[] }[]
  >([])
  const [anotherTestIds, setAnotherTestIds] = React.useState<number[]>([])

  const {
    setValue,
    register,
    unregister,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const rules = {
    maxLength: {
      value: 50,
      message: 'Maximum 50 characters',
    },
    minLength: {
      value: 2,
      message: 'Minimum 2 characters',
    },
    validate: {
      specialCharactersAllowed: (v: string) => {
        const val = v ? v.trim() : v
        return (
          /^[A-Za-z0-9-/,':;_ ]+$/.test(val) ||
          "Please add a valid test name. Special characters allowed: - / , ' : ; _"
        )
      },
    },
  }

  const setValues = (data: { id: number; count: number; methods?: number[] }) => {
    if (testData.length) {
      const existingTestIndex = testData.findIndex(test => test.id === data.id)
      if (existingTestIndex !== -1) {
        setTestData(prevState => {
          const newState = cloneDeep(prevState)
          newState[existingTestIndex].count = data.count
          newState[existingTestIndex].methods = data.methods
          return newState
        })
      } else {
        setTestData(prevState => {
          const newState = cloneDeep(prevState)
          newState.push(data)
          return newState
        })
      }
    } else {
      setTestData([data])
    }
  }

  const setAnotherTest = (groupId: number, deleteFlag?: boolean) => {
    if (!deleteFlag) {
      setAnotherTestIds(prevState => [...prevState, groupId])
    } else {
      setValue(`anotherTestName__${groupId}`, '')
      setAnotherTestIds(prevState => {
        const idIndexForDelete = prevState.findIndex(prevId => groupId === prevId)
        const oldIds = [...prevState]
        oldIds.splice(idIndexForDelete, 1)
        return oldIds
      })
      unregister(`anotherTestName__${groupId}`)
    }
  }

  const testSum = testData.reduce((sum, { count }) => sum + count, 0) + anotherTestIds.length

  const submitHandler = (data: FieldValues) => {
    handleCreateTests(data, testData)
    setIsSending(true)
  }

  return (
    <Modal padding='0' open onClose={() => onClose()}>
      <form onSubmit={handleSubmit(submitHandler)} noValidate>
        <Styled.Wrapper style={{ width: isMobile ? 'auto' : '575px' }}>
          {/* Modal TITLE and SUBTITLE */}
          <Styled.HeadBox>
            <StyledTitle variant={isMobile ? 'subtitle1' : 'h3'} sx={{ marginBottom: '4px' }}>
              Add tests for sample
            </StyledTitle>
            <Styled.Subtitle>A maximum of {MAX_TASKS_TO_ADD} tests can be added</Styled.Subtitle>
          </Styled.HeadBox>
          <Styled.ContentBox>
            {/* Content TESTS LIST */}
            {!!correctOptions.length &&
              correctOptions.map(({ groupId, groupName, is_visual, tests }) => {
                const isRequiredAnotherTest = !!anotherTestIds.find(id => groupId === id)
                return (
                  <React.Fragment key={groupId}>
                    {/* GROUP tests SUBTITLE */}
                    <Styled.GroupLabel>{groupName} tests</Styled.GroupLabel>
                    {tests.map(({ id, name, methods }) => {
                      // TEST ITEM
                      if (name === TandemMS) {
                        return (
                          <ElementWithIncreaseItsValue
                            key={id}
                            element={{ id, name }}
                            initialVal={1}
                            isMaxValSet={testSum === availableTestsToSet}
                            setValues={setValues}
                          >
                            <TandemMSSelectMethod
                              element={{ id, name }}
                              isMaxValSet={testSum === availableTestsToSet}
                              setValues={setValues}
                              methods={methods}
                            />
                          </ElementWithIncreaseItsValue>
                        )
                      }
                      return (
                        <ElementWithIncreaseItsValue
                          key={id}
                          element={{ id, name }}
                          initialVal={1}
                          isMaxValSet={testSum === availableTestsToSet}
                          setValues={setValues}
                        />
                      )
                    })}
                    {/* For Visual, there is currently a ban on adding a custom test */}
                    {!is_visual && (
                      // ADDING ANOTHER TEST
                      <ElementWithIncreaseItsValue
                        element={{ id: -1, groupId, name: `+ Another ${groupName} test` }}
                        isMaxValSet={testSum === availableTestsToSet}
                        setAnotherTest={setAnotherTest}
                        endIcon={
                          <PureTooltip
                            title="Other test types are currently not supported. Please add test results to 'Sample notes' field."
                            titleStyle={{ width: 170 }}
                          >
                            <InfoIcon />
                          </PureTooltip>
                        }
                        input={
                          <Input
                            sx={{ margin: '2px 0 4px' }}
                            fullWidth
                            variant='outlined'
                            type='text'
                            placeholder='Type name here...'
                            error={!!errors[`anotherTestName__${groupId}`]?.message}
                            helperText={errors[`anotherTestName__${groupId}`]?.message}
                            {...register(`anotherTestName__${groupId}`, {
                              ...rules,
                              required: isRequiredAnotherTest && 'Please fill in the field',
                            })}
                          />
                        }
                      />
                    )}
                  </React.Fragment>
                )
              })}
          </Styled.ContentBox>
          {/* Modal ACTION BUTTONS */}
          <Styled.ButtonBox>
            <Button
              type='button'
              variant='outlined'
              color='secondary'
              sx={{ marginRight: '28px' }}
              width='200px'
              height='48px'
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <PureTooltip
              title={`You’ve already added max amount of tests for a Sample`}
              shouldBeHidden={testSum !== availableTestsToSet}
              titleStyle={{ width: 170 }}
            >
              <Button
                type='submit'
                variant='contained'
                width='200px'
                height='48px'
                onClick={() => {}}
                disabled={testSum === 0}
                loading={isSending}
              >
                Add tests ({testSum}/{availableTestsToSet})
              </Button>
            </PureTooltip>
          </Styled.ButtonBox>
        </Styled.Wrapper>
      </form>
    </Modal>
  )
}
