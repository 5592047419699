import { Box, IconButton, styled as styledMUI, Typography } from '@mui/material'
import styled from 'styled-components'

export const DetailsBox = styledMUI(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

export const ImageButton = styledMUI(IconButton)`
  margin-left: 14px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid #e5e5e6;
  width: 40px;
  height: 40px;
`

export const SubstancesList = styled.ul<{ $mb?: string }>`
  margin: 0 0 ${({ $mb }) => $mb || 'none'};
  padding: 0;
  list-style: none;
`

export const SubstancesItem = styledMUI('li')<{ mb: number }>`
  position: relative;
  margin-bottom: ${({ mb }) => `${mb}px`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
`

export const GreyCup = styledMUI('span')`
  vertical-align: top;
  padding-left: 2px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.secondary.light};
`

export const DeterminedCup = styledMUI(Typography)`
  color: ${({ theme }) => theme.palette.secondary.light};
  position: absolute;
  left: calc(100% - 30px);
  white-space: nowrap;
`

export const WarningMessage = styled('span')`
  position: absolute;
  top: 2px;
  left: calc(100% + 7px);
  color: #b08904;
  font-size: 12px;
  line-height: normal;
  white-space: nowrap;
`
