import React from 'react'
import { IconButton, Typography } from '@mui/material'

import KeyboardArrowUpIcon from '@assets/icons/sort-up.svg'
import KeyboardArrowDownIcon from '@assets/icons/sort-down.svg'
import { StyledCardBox } from '@styles'

interface MobileDetailsInfoCardProps {
  title: string
  open: boolean
  setIsOpen: () => void
  bg?: string
  mb?: string
  textEndPosition?: boolean
}

export const MobileDetailsInfoCard: React.FC<MobileDetailsInfoCardProps> = props => {
  const { title, open, setIsOpen, bg, mb, textEndPosition } = props

  return (
    <>
      <StyledCardBox bg={bg} mb={mb} textEndPosition={textEndPosition} onClick={setIsOpen}>
        {!textEndPosition && (
          <Typography fontSize={18} fontWeight={500}>
            {title}
          </Typography>
        )}

        <IconButton sx={{ width: '24px', height: '24px' }}>
          {open ? (
            <img src={KeyboardArrowUpIcon} alt='up' />
          ) : (
            <img src={KeyboardArrowDownIcon} alt='down' />
          )}
        </IconButton>

        {textEndPosition && (
          <Typography ml={1} fontSize={18} fontWeight={500}>
            {title}
          </Typography>
        )}
      </StyledCardBox>
    </>
  )
}
