import styled from 'styled-components'

export const StyledBox = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
`

export const StyledHorizontalScrollCup = styled.div`
  position: sticky;
  top: calc(100% - 12px);
  height: 12px;
  z-index: 300;
  overflow-x: auto;
  overflow-y: hidden;

  &.drag::after {
    position: fixed;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }

  & .horizontal-scroll-box-inside {
    border-radius: 5px;
    height: 100%;
    width: 0;
  }
`
