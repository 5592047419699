import { StringParam, useQueryParam } from 'use-query-params'
import React from 'react'

import { PageHeaderWrapper, FlexWrapper, PageTitle } from '@styles'
import { DESIRED_TEST_REPORTS } from '@constants/tests'

import { AutocompleteSearch } from '../AutocompleteSearch'

interface PageHeaderProps {
  pageTitle: string
  placeholder: string
}

export const PageHeader: React.FC<PageHeaderProps> = React.memo(({ pageTitle, placeholder }) => {
  const [searchQueryString, setSearchQueryString] = useQueryParam(DESIRED_TEST_REPORTS, StringParam)
  const [queryString] = useQueryParam('result', StringParam)

  return (
    <FlexWrapper>
      <PageTitle mb={5}>{pageTitle}</PageTitle>

      <PageHeaderWrapper>
        <AutocompleteSearch
          width='320px'
          placeholder={placeholder}
          setQueryString={setSearchQueryString}
          queryString={searchQueryString}
          searchIn='testReports'
          type={queryString}
        />
      </PageHeaderWrapper>
    </FlexWrapper>
  )
})
