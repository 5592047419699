import { Box, Typography, useMediaQuery } from '@mui/material'

import { StyledDetailsLabel } from '@styles'
import { Input } from '@components/shared/sampleFields'
import { MAX_CHARACTERS, SPECIAL_CHARACTERS_ALLOWED } from '@constants/validation'
import { TEST_NOTES, TEST_PREPARATION } from '@constants/tests'

export const FTSResultsFields = () => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const fields = [
    {
      fieldTitle: '2. Test notes',
      name: TEST_NOTES,
      field: Input,
      rules: {
        maxLength: {
          value: 200,
          message: MAX_CHARACTERS(200),
        },
      },
      placeholder: 'Type notes here...',
    },
    {
      fieldTitle: '3. Preparation for test',
      name: TEST_PREPARATION,
      field: Input,
      rules: {
        maxLength: {
          value: 200,
          message: MAX_CHARACTERS(200),
        },
      },

      mb: '8px',
      placeholder: 'Type notes here...',
    },
  ]
  const stripDetailsFieldsRules = {
    maxLength: {
      value: 150,
      message: MAX_CHARACTERS(150),
    },
    validate: {
      specialCharacters: (v: string) => {
        return v === '' || /^[A-Za-z0-9-/,':;_ ]+$/.test(v) || SPECIAL_CHARACTERS_ALLOWED
      },
    },
  }

  return (
    <>
      {fields.map(({ field: Field, ...props }) => (
        <Box width='100%' mb={isMobile ? '24px' : '48px'}>
          <Typography variant='subtitle2' mb='24px'>
            {props.fieldTitle}
          </Typography>
          <Input
            multiline
            name={props.name}
            rules={props.rules}
            mb={props.mb}
            rows={isMobile ? 6 : 9}
            mrErr='0'
            width={!isMobile ? '50%' : '100%'}
            placeholder='Type notes here...'
          />
        </Box>
      ))}

      <Box mb={isMobile ? '24px' : '48px'}>
        <Typography variant='subtitle2' mb='24px'>
          4. Test strip details
        </Typography>
        <Box display='flex'>
          <Box mr='16px'>
            <StyledDetailsLabel mb='9px'>Brand</StyledDetailsLabel>
            <Input
              name={'test_strip_brand'}
              rules={stripDetailsFieldsRules}
              mb='8px'
              mrErr='0'
              width={!isMobile ? '400px' : '100%'}
              placeholder='Type here...'
            />
          </Box>

          <Box>
            <StyledDetailsLabel mb='9px'>Batch/lot number</StyledDetailsLabel>
            <Input
              name={'test_strip_batch'}
              rules={stripDetailsFieldsRules}
              mb='8px'
              mrErr='0'
              width={!isMobile ? '400px' : '100%'}
              placeholder='Type here...'
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
