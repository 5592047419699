import * as sampleConstants from '@constants/samples'
import { IOption } from '@models/common/app'
import { IPlaceOption } from '@components/shared/sampleFields/createSampleCells/GMPlaceAutocomplete'
import { IAttachments } from '@models/attachments'
import { ITestTypesOption } from '@components/modules/SampleCreate/components/TableCells/pureFields.types'

export interface ISampleCreateInitialSamplesDataTypes {
  [sampleConstants.DRUG_CLASS]: { val: IOption | null; error: '' }
  [sampleConstants.DOSAGE]: { val: string; error: '' }
  [sampleConstants.DOSAGE_TYPE]: { val: number; error: '' }
  [sampleConstants.VALUE]: { val: number | null; error: '' }
  [sampleConstants.VALUE_TYPE]: { val: number | null; valName: string; error: '' }
  [sampleConstants.NOTES]: { val: string; error: '' }
  [sampleConstants.LOT]: { val: string; error: '' }
  [sampleConstants.BUYERS_NAME]: { val: string; error: '' }
  [sampleConstants.PURCHASE_DATE]: { val: Date | null; error: '' }
  [sampleConstants.TOWN]: { val: IPlaceOption | null; error: '' }
  [sampleConstants.PHARMACY]: { val: IOption | null; error: '' }
  [sampleConstants.PHARMACY_GPS]: { val: string; error: '' }
  [sampleConstants.MANUFACTURER]: { val: IOption | null; error: '' }
  [sampleConstants.ADDRESS_OF_MANUFACTURER]: { val: IPlaceOption | null; error: '' }
  [sampleConstants.BRAND]: { val: IOption | null; error: '' }
  [sampleConstants.MAIN_APIS]: { val: IOption[]; error: '' }
  [sampleConstants.EXCIPIENTS]: { val: IOption[]; error: '' }
  [sampleConstants.TESTS]: { val: ITestTypesOption[]; error: '' }
  [sampleConstants.MANUFACTURE_DATE]: { val: Date | null; error: '' }
  [sampleConstants.EXPIRATION_DATE]: { val: Date | null; error: '' }
  [sampleConstants.COUNTRY]: { val: IOption | null; error: '' }
  [sampleConstants.REGULATORY_REGISTRATION]: { val: number | null; error: '' }
  [sampleConstants.UNITS]: { val: number | null; error: '' }
  [sampleConstants.UNIT_TYPE]: { val: number; error: '' }
  [sampleConstants.SAMPLE_TYPE]: { val: IOption[] | null; error: '' }
  [sampleConstants.TOXICITY_LEVEL]: { val: string; error: '' }
  [sampleConstants.MAX_TEMPERATURE]: { val: string; error: '' }
  [sampleConstants.MIN_TEMPERATURE]: { val: string; error: '' }
  [sampleConstants.TEMPERATURE_FORMAT]: { val: string; error: '' }
  [sampleConstants.STORAGE_NOTES]: { val: string | null; error: '' }
  [sampleConstants.ATTACHMENTS]: { val: IAttachments | any; error: '' }
  [sampleConstants.PROJECT]: { val: any | null; error: '' }
  [sampleConstants.FUNDING]: { val: any | null; error: '' }
  isCopy: boolean
}

export const sampleCreateInitialSamplesData: ISampleCreateInitialSamplesDataTypes = {
  [sampleConstants.BRAND]: { val: null, error: '' },
  [sampleConstants.BUYERS_NAME]: { val: '', error: '' },
  [sampleConstants.COUNTRY]: { val: null, error: '' },
  [sampleConstants.DRUG_CLASS]: { val: null, error: '' },
  [sampleConstants.DOSAGE]: { val: '', error: '' },
  [sampleConstants.DOSAGE_TYPE]: { val: 1, error: '' },
  [sampleConstants.EXPIRATION_DATE]: { val: null, error: '' },
  [sampleConstants.UNITS]: { val: null, error: '' },
  [sampleConstants.UNIT_TYPE]: { val: 1, error: '' },
  [sampleConstants.VALUE]: { val: null, error: '' },
  [sampleConstants.VALUE_TYPE]: {
    val: null,
    valName: sampleConstants.DEFAULT_VALUE_TYPE_NAME,
    error: '',
  },
  [sampleConstants.LOT]: { val: '', error: '' },
  [sampleConstants.MAIN_APIS]: { val: [], error: '' },
  [sampleConstants.EXCIPIENTS]: { val: [], error: '' },
  [sampleConstants.TESTS]: { val: [], error: '' },
  [sampleConstants.MANUFACTURE_DATE]: { val: null, error: '' },
  [sampleConstants.MANUFACTURER]: { val: null, error: '' },
  [sampleConstants.ADDRESS_OF_MANUFACTURER]: { val: null, error: '' },
  [sampleConstants.NOTES]: { val: '', error: '' },
  [sampleConstants.PHARMACY]: { val: null, error: '' },
  [sampleConstants.PHARMACY_GPS]: { val: '', error: '' },
  [sampleConstants.REGULATORY_REGISTRATION]: { val: 1, error: '' },
  [sampleConstants.PURCHASE_DATE]: { val: new Date(), error: '' },
  [sampleConstants.TOWN]: { val: null, error: '' },
  [sampleConstants.SAMPLE_TYPE]: { val: null, error: '' },
  [sampleConstants.TOXICITY_LEVEL]: { val: '', error: '' },
  [sampleConstants.MAX_TEMPERATURE]: { val: '', error: '' },
  [sampleConstants.MIN_TEMPERATURE]: { val: '', error: '' },
  [sampleConstants.TEMPERATURE_FORMAT]: { val: '', error: '' },
  [sampleConstants.STORAGE_NOTES]: { val: '', error: '' },
  [sampleConstants.ATTACHMENTS]: { val: [], error: '' },
  [sampleConstants.PROJECT]: { val: null, error: '' },
  [sampleConstants.FUNDING]: { val: null, error: '' },
  isCopy: false,
}
