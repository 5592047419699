import React from 'react'
import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { cloneDeep } from 'lodash'

import { useUpdateTest } from '@hooks/queries'
import { Button, ConfirmationModal, Tooltip } from '@components/shared'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import {
  isInvalidJsonApiError,
  isResultRecognizedTandemMSCheckboxState,
  setIsAddedNewTandemMSTest,
  setIsInvalidJsonApiError,
  setTandemMSData2Send,
  tandemMSData,
} from '@state/test'
import { useToggle } from '@hooks/common'
import { TandemMS, SubstancesTypes } from '@models/tests'
import { IHrTaskLab } from '@models/tasks'
import { completeTest } from '@state/tasks'
import { protectedRoutes } from '@configs/routes.config'

import { StepFooter } from '../../../Testing/styles'
import { TestResultsModal } from '../../../Testing/components'
import { finishTandemMSTestHelper } from './helpers'

interface FinishTestProps {
  taskId: number
  loading: boolean
  isTestReport?: boolean
  task: IHrTaskLab
}

export const FinishTest: React.FC<FinishTestProps> = ({
  taskId,
  loading = false,
  task,
  isTestReport,
}) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const isTandemMSTest = task.test_type.name === TandemMS
  const updateTestM = useUpdateTest()

  const { handleSubmit, formState, watch } = useFormContext()
  const isResultNotRecognized = useAppSelector(isResultRecognizedTandemMSCheckboxState)
  const tandemMSData2Send = useAppSelector(tandemMSData)
  const [isFinishTandeMsModalOpen, toggleFinishTandeMsModalOpen] = useToggle()
  const [isMethodsModalOpen, toggleMethodsModal] = useToggle()
  const isApiJsonError = useAppSelector(isInvalidJsonApiError)

  const [substances, isNotRecognized, jsonNotes] = watch([
    'substances',
    'result_is_not_recognized',
    'json_notes',
  ])

  const isEmptyRequiredTandemFields = !jsonNotes && !isResultNotRecognized

  const isDisableFinish = (() => {
    if (isTestReport) {
      if (isNotRecognized) return false

      let isEmptyFields = false
      if (substances) {
        substances.forEach(
          (substance: { name: string; percentage: string; is_not_determined: boolean }) => {
            if (!isEmptyFields) {
              if (!substance.name || (!substance.is_not_determined && !substance.percentage)) {
                isEmptyFields = true
              }
            }
          }
        )
      }
      return isEmptyFields
    }
    if (isTandemMSTest && isEmptyRequiredTandemFields) return true
    return false
  })()

  const finishTestHandler = (data: any) => {
    const clonedData = cloneDeep(data)

    if (data.result_is_not_recognized) {
      delete clonedData.substances
    } else if (
      !data.result_is_not_recognized &&
      typeof data.result_is_not_recognized === 'boolean'
    ) {
      delete clonedData.result_is_not_recognized
    }

    if (clonedData.substances) {
      data.substances.forEach((item: SubstancesTypes, idx: number) => {
        if (item.is_not_determined) {
          delete clonedData.substances[idx].percentage
        } else {
          delete clonedData.substances[idx].is_not_determined
        }
      })
    }

    if (isTandemMSTest) {
      dispatch(setTandemMSData2Send(data))
      if (task && task.properties.is_able_to_create_task) {
        // if the sample has less than 10 tests the user can choose - add new Tandem MS method or finish current test
        toggleFinishTandeMsModalOpen()
      } else finishTandemMSTestHandler() // if it is 10th test for the sample - the test will be finished
    } else updateTestM.mutate({ taskId, data: { status: 'completed', ...clonedData } })
  }

  const finishTandemMSTestHandler = () => {
    if (tandemMSData2Send) {
      const data = finishTandemMSTestHelper(tandemMSData2Send, isResultNotRecognized)

      updateTestM.mutate(
        { taskId, data },
        {
          onSuccess: () => {
            // redirect to Awaiting list
            history.push(protectedRoutes.tasks.path)
            // dispatch the task id for show the notification about finished task on Awaiting list
            dispatch(completeTest(taskId))
          },
          onError: err => {
            const isJsonError = err.message.toLocaleLowerCase().includes('json')
            if (isJsonError) dispatch(setIsInvalidJsonApiError(isJsonError))
          },
        }
      )
    }
  }

  const selectMethodAndStartNewTestHandler = () => {
    toggleMethodsModal()
    dispatch(setIsAddedNewTandemMSTest(true))
  }

  const isFieldsHaveErrors =
    isResultNotRecognized && !!Object.keys(formState.errors).length
      ? false
      : !!Object.keys(formState.errors).length || isApiJsonError

  return (
    <StepFooter>
      <Box sx={{ width: 'fit-content' }}>
        <Tooltip title='Please fill in the required fields' shouldBeHidden={!isDisableFinish}>
          <div>
            <Button
              disabled={loading || updateTestM.isLoading || isFieldsHaveErrors || isDisableFinish}
              variant='contained'
              ml='auto'
              width='205px'
              height='48px'
              onClick={handleSubmit(finishTestHandler)}
            >
              Finish test
            </Button>
          </div>
        </Tooltip>
      </Box>
      {isFinishTandeMsModalOpen ? (
        <ConfirmationModal
          title='Add another Tandem MS method?'
          subtitle='New Tandem MS method will be added to Awaiting list.'
          cancelBtnText='Yes, select method'
          isDisabled={updateTestM.isLoading}
          confirmButtonText='No, finish testing'
          onClose={toggleFinishTandeMsModalOpen}
          onConfirm={finishTandemMSTestHandler}
          extraOnConfirm={selectMethodAndStartNewTestHandler}
        />
      ) : null}

      {isMethodsModalOpen ? <TestResultsModal onClose={toggleMethodsModal} task={task} /> : null}
    </StepFooter>
  )
}
