import React from 'react'

import { AddOptionBtn } from '@styles'

import { TestsOptionsProps } from './testsOptions.types'
import * as Styled from './styles'

export const TestsOptions: React.FC<TestsOptionsProps> = ({
  options,
  onDropdownChange,
  handleAddTest,
}) => {
  return (
    <>
      {options.map(({ groupId, groupName, is_visual, tests }) => (
        <React.Fragment key={groupId}>
          {/* GROUP SUBTITLE */}
          <Styled.GroupTitle>{groupName} test</Styled.GroupTitle>
          {tests.map(test => (
            // TEST ITEM
            <Styled.TestItem key={`${test.id}`} onClick={() => onDropdownChange(test)}>
              {test.name}
            </Styled.TestItem>
          ))}
          {/* BUTTON FOR ADD ANOTHER TEST */}
          {/* For Visual, there is currently a ban on adding a custom test */}
          {!is_visual && (
            <AddOptionBtn onClick={() => handleAddTest({ group: groupId, name: groupName })}>
              Another {groupName} test
            </AddOptionBtn>
          )}
        </React.Fragment>
      ))}
    </>
  )
}
