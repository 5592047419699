import { useLocation } from 'react-router-dom'

export const useCheckPages = (
  pagePaths: (string | RegExp)[],
  { strict = false, oneLevelAbove = false } = {}
): [boolean, string] => {
  const { pathname: currentPage } = useLocation()

  const result = pagePaths
    .find(path => {
      if (typeof path === 'string') {
        return strict ? currentPage === path : currentPage.includes(path)
      } else return currentPage.match(path)
    })
    ?.toString()

  if (!strict && oneLevelAbove && result) {
    return [currentPage.length > result.length, currentPage]
  } else if (!oneLevelAbove) return [!!result, currentPage]

  return [false, currentPage]
}
