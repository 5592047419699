import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IOption } from '@models/common/app'

import { InitialStateTypes } from './sampleCreateUIState.types'

const initialState: InitialStateTypes = {
  isToxicityAdded: false,
  isTemperatureAdded: false,
  triggerFlag: false,
}

export const sampleCreateUIStateSlice = createSlice({
  name: 'sampleCreateUI',
  initialState,
  reducers: {
    setAddedSkills: (state, { payload }: PayloadAction<IOption[]>) => {
      if (!state.isToxicityAdded && payload.some(skill => skill.id === 1)) {
        state.isToxicityAdded = true
      }
      if (!state.isTemperatureAdded && payload.some(skill => skill.id === 2)) {
        state.isTemperatureAdded = true
      }
    },
    setRemovedSkills: (state, { payload }: PayloadAction<number[]>) => {
      if (payload.includes(1) && state.isToxicityAdded) {
        state.isToxicityAdded = false
      }
      if (payload.includes(2) && state.isTemperatureAdded) {
        state.isTemperatureAdded = false
      }
    },
    setTriggerFlag: state => {
      state.triggerFlag = !state.triggerFlag
    },
    cleanFlags: state => {
      state.isToxicityAdded = false
      state.isTemperatureAdded = false
    },
  },
})

export const {
  reducer: sampleCreateUIReducer,
  actions: { setAddedSkills, setRemovedSkills, setTriggerFlag, cleanFlags },
} = sampleCreateUIStateSlice
