import { useMediaQuery } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import { AttachedMaterialItem } from '@components/shared'
import {
  TRUNCATED_ATTACHED_MODAL_MOBILE_TEXT,
  TRUNCATED_ATTACHED_MODAL_TEXT,
} from '@constants/common'
import { StyledPageSubTitle } from '@styles'
import { IAttachment, IAttachments } from '@models/attachments'
import { useAppSelector } from '@hooks/redux'
import { sampleCreate } from '@state/sampleCreate'
import { attachmentsCreateSample, hrAttachmentsCreateSample } from '@state/app'

interface AttachedMaterialsListProps {
  objectId?: number
  filteredAttachments: IAttachment[]
  isFilteredHasNoDeleted: boolean
  allDeletedIds: string[]
  removeAttachment: (id: string, sectionKey: string) => void
  isSamplePhotoEdit?: boolean
  modalType?: string
}

export const AttachedMaterialsList: React.FC<AttachedMaterialsListProps> = props => {
  const {
    objectId,
    filteredAttachments,
    isFilteredHasNoDeleted,
    allDeletedIds,
    removeAttachment,
    isSamplePhotoEdit,
    modalType,
  } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const [items, setItems] = useState<IAttachments>([])
  const addedCreateSampleAttachments = useAppSelector(attachmentsCreateSample)
  const addedHrCreateSampleAttachments = useAppSelector(hrAttachmentsCreateSample)

  const { samples } = useAppSelector(sampleCreate)
  const sample = samples.find(item => item.id === addedCreateSampleAttachments.sampleId)
  const sampleAttachments = useMemo(() => {
    return sample?.attachments.val || []
  }, [sample?.attachments.val])

  const updatedStorageAttachments = useMemo(() => {
    return filteredAttachments.concat(sampleAttachments)
  }, [filteredAttachments, sampleAttachments])

  const attachmentsItems = (() => {
    if (modalType === 'photo') {
      return addedHrCreateSampleAttachments.attachments || []
    }
    return !objectId ? updatedStorageAttachments : items
  })()

  const isTitle: boolean = (() => {
    if (modalType === 'photo') {
      return !!addedHrCreateSampleAttachments.attachments
    } else if (objectId) {
      return filteredAttachments?.length > 0 && isFilteredHasNoDeleted
    }
    return sampleAttachments.length > 0
  })()

  useEffect(() => {
    if (objectId && isFilteredHasNoDeleted && !isSamplePhotoEdit) {
      setItems(filteredAttachments)
    } else if (!objectId && !isFilteredHasNoDeleted && modalType !== 'photo') {
      setItems(updatedStorageAttachments)
    }
  }, [
    filteredAttachments,
    isFilteredHasNoDeleted,
    objectId,
    updatedStorageAttachments,
    isSamplePhotoEdit,
    modalType,
  ])

  return (
    <>
      {isTitle && (
        <StyledPageSubTitle sx={{ marginBottom: '16px' }} variant='body1'>
          Attached materials
        </StyledPageSubTitle>
      )}

      {attachmentsItems.map((attachment: IAttachment) => {
        if (allDeletedIds.includes(`${attachment.id}`)) return null

        return (
          <AttachedMaterialItem
            key={attachment.id}
            objectId={objectId}
            truncatedTextLength={
              isMobile ? TRUNCATED_ATTACHED_MODAL_MOBILE_TEXT : TRUNCATED_ATTACHED_MODAL_TEXT
            }
            attachment={attachment}
            removeAttachment={removeAttachment}
          />
        )
      })}
    </>
  )
}
