import { styled, Box, Icon, MenuItem } from '@mui/material'

import { Dropdown } from '@components/shared'

export const StyledAddSubstancesBtn = styled('button')<{ $disabled: boolean }>`
  margin-bottom: 50px;
  border: none;
  background: transparent;
  padding: 0;
  font-weight: 500;
  color: ${({ theme, $disabled }) => ($disabled ? theme.palette.secondary.light : '#5E91F7')};
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  text-decoration: underline;

  & svg {
    vertical-align: middle;
    margin: -4px 4px 0 0;

    & path {
      fill: ${({ theme, $disabled }) => ($disabled ? theme.palette.secondary.light : '#5E91F7')};
    }
  }
`

export const StyledSubstanceBox = styled('div')`
  margin-bottom: 16px;
  width: 100%;
`

export const StyledProportionBox = styled('div')`
  max-width: 450px;
  width: 100%;
  margin-bottom: 30px;
`

export const DropdownInputContainer = styled(Box)`
  position: relative;

  & .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e1e7f3;
  }

  & .MuiInputBase-input {
    padding: 12.5px 12px;
  }
`

export const StyledDropdown = styled(Dropdown)`
  & .MuiSelect-select {
    display: flex;
  }

  & .MuiPaper-root {
    left: 359px;
  }

  & .MuiSelect-select .MuiTypography-body1 {
    display: none;
  }
`

export const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
`

export const DropdownContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  &:after {
    content: '';
    position: absolute;
    background-color: #e1e7f3;
    width: 1px;
    height: 24px;
    left: -34px;
    top: 50%;
    transform: translateY(-50%);
  }

  & .MuiInput-underline {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
`
