import { Box, BoxProps, styled } from '@mui/material'

export interface StyledBoxProps extends BoxProps {
  mt?: string
}

export const StyledBox = styled<React.FC<StyledBoxProps>>(Box)`
  display: flex;
  align-items: center;
  margin-top: ${({ mt }) => mt};
`

export const StyledText = styled('span')`
  display: inline;
  font-weight: 400;
  font-size: 13px;
`
