import React from 'react'
import { Box } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { debounce } from 'lodash'

import { selectedFilters as filters } from '@state/filters'
import { useToggle, useCustomLazyScroll } from '@hooks/common'
import { useAppSelector } from '@hooks/redux'
import {
  DRUG_CLASSES,
  DRUG_CLASS_FILTER_TITLE,
  selectedFiltersSlice,
} from '@components/shared/FilterAside/constants'
import { useFetchDrugClassesFilters } from '@hooks/queries'
import { ParagraphLink } from '@components/shared/ParagraphLink'
import { FilterLoader } from '@components/shared/FilterAside'

import { CardRow, StyledShowMoreLinkBox } from './styles'
import { FilterCheckbox } from '../components/FilterCheckbox'
import { FilterSelectAll } from '../components/FilterSelectAll'
import { SearchFilters } from '../components/SearchFilters'
import { FilterTopTitle } from '../components/FilterTopTitle'

export const DrugClassFilter = React.memo(() => {
  const selectedFilters = useAppSelector(filters)

  const [isFilterClose, setIsFilterClose] = useToggle()
  const [searchQuery, setSearchQuery] = React.useState('')
  const [isShowMore, setIsShowMore] = React.useState<boolean>(true)

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useFetchDrugClassesFilters(searchQuery)

  const contentScrollBox = document.getElementById('content-scroll-box')
  const parentRef = React.useRef<HTMLElement | HTMLDivElement | null>(contentScrollBox)
  const scrollTriggerRef = React.useRef<HTMLDivElement | null>(null)
  const wrapRef = React.useRef<HTMLDivElement | null>(null)

  useCustomLazyScroll(parentRef, scrollTriggerRef, fetchNextPage, isShowMore, hasNextPage)

  const filterCounter =
    selectedFilters[selectedFiltersSlice.drug_classes].filters.length || undefined

  const choiceQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value),
    []
  )

  const debounceChoiceQuery = debounce(choiceQuery, 500)

  const queriesList = data?.pages
    .map(page => page.results)
    .flat()
    .map(item => ({ ...item, id: String(item.id) }))

  const queriesListItems = React.useMemo(() => {
    if (queriesList) {
      if (isShowMore) return queriesList.slice(0, 5)
      else return queriesList
    }
  }, [queriesList, isShowMore])

  React.useEffect(() => {
    if (isShowMore && wrapRef && wrapRef.current && contentScrollBox) {
      // number 63 - it's aside header height
      contentScrollBox.scrollTo({ top: wrapRef.current.offsetTop - 63 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowMore])

  return (
    <div ref={wrapRef}>
      <FilterTopTitle
        filterNumb={data?.pages[0].count}
        isFilterClose={isFilterClose}
        setIsFilterClose={setIsFilterClose}
        titleOfFilter={DRUG_CLASS_FILTER_TITLE}
        filterCounter={filterCounter}
      />
      <Collapse in={!isFilterClose} unmountOnExit>
        <CardRow>
          <SearchFilters searchFiltersHandler={debounceChoiceQuery} />

          <Box sx={{ width: '100%' }}>
            {(isFetching || isLoading) && isShowMore ? (
              <Box width='100%'>
                <FilterLoader />
              </Box>
            ) : (
              <div style={{ paddingBottom: '12px' }}>
                {queriesListItems && !searchQuery && (
                  <FilterSelectAll type={DRUG_CLASSES} items={queriesList || []} />
                )}

                {queriesListItems &&
                  queriesListItems.map((item, idx) => {
                    if (idx === queriesListItems.length - 10) {
                      return (
                        <React.Fragment key={item.id}>
                          <div ref={scrollTriggerRef} />

                          <FilterCheckbox type={DRUG_CLASSES} item={item} />
                        </React.Fragment>
                      )
                    }
                    return <FilterCheckbox type={DRUG_CLASSES} item={item} key={item.id} />
                  })}

                {data && data?.pages[0].count > 5 && (
                  <StyledShowMoreLinkBox>
                    <ParagraphLink
                      sx={{ padding: '11px 24px 12px' }}
                      onClick={() => setIsShowMore(!isShowMore)}
                    >
                      Show {isShowMore ? 'more' : 'less'}
                    </ParagraphLink>
                  </StyledShowMoreLinkBox>
                )}
              </div>
            )}
          </Box>
        </CardRow>
      </Collapse>
    </div>
  )
})
