import React from 'react'

import { useAppDispatch } from '@hooks/redux'
import { clearCanceledPackages } from '@state/packages'

import { PackagesContent } from '../..'

export const OutgoingPackages = () => {
  const dispatch = useAppDispatch()

  React.useEffect(
    () => () => {
      dispatch(clearCanceledPackages())
    },
    [dispatch]
  )

  return <PackagesContent contentType='outgoing' />
}
