import React from 'react'
import { Icon, Typography, useTheme } from '@mui/material'

import { ISample } from '@models/samples'
import { datePatterns } from '@constants/dates'
import { getFormattedDate } from '@helpers/ui/date'
import { StyledDetailsLabel, StyledParagraph, StyledTitle } from '@styles'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'
import { unitIconSDeterminer } from '@helpers/ui/typeOfUnits'
import { checkSkills } from '@helpers/obtainingSkills'
import { DetailsPageStorageAttachments } from '@components/shared'
import { ATTACHMENT_STORAGE_SECTION } from '@constants/tests'

import { PurchaseDetailsLoader } from '../../Loaders'

interface ViewPurchaseDetailsProps {
  sample: ISample
  isLoading: boolean
}

export const ViewPurchaseDetails: React.FC<ViewPurchaseDetailsProps> = ({ sample, isLoading }) => {
  const { timezone } = useAppSelector(selectUser)
  const theme = useTheme()

  const formattedPurchaseDate = getFormattedDate(
    datePatterns.fullDate,
    sample?.purchase_date,
    timezone
  )
  const { isSampleTemperatureSensitive, isSampleToxic } = checkSkills(sample.main_apis)

  if (isLoading) return <PurchaseDetailsLoader />
  const pricePerUnit = sample?.price_per_unit
    ? parseFloat((+sample?.price_per_unit).toFixed(4))
    : '-'
  const totalPrice = sample?.total_price ? parseFloat((+sample?.total_price).toFixed(4)) : '-'

  const UnitIconType = unitIconSDeterminer(sample?.unit_type?.id || 1)
  const isShowAttachSection = isSampleTemperatureSensitive || isSampleToxic

  return (
    <div>
      {isShowAttachSection && (
        <>
          <StyledTitle variant='h3' mb='32px' fz={24}>
            Attachments
          </StyledTitle>
          <StyledDetailsLabel variant='h5'>Storage documents</StyledDetailsLabel>

          <DetailsPageStorageAttachments
            id={sample?.id}
            allow_editing={sample.allow_editing}
            contentType='sample'
            section={ATTACHMENT_STORAGE_SECTION}
          />
        </>
      )}

      <StyledTitle variant='h3' mb='28px' fz={24}>
        Purchase details
      </StyledTitle>

      <StyledDetailsLabel variant='h5'>Units</StyledDetailsLabel>
      <StyledParagraph sx={{ display: 'flex' }} variant='body1'>
        {sample?.units}
        {sample?.unit_type?.id && (
          <>
            <Icon sx={{ marginLeft: '8px' }}>
              <UnitIconType fill={theme.palette.primary.main} stroke={theme.palette.primary.main} />
            </Icon>

            <Typography
              component='span'
              marginLeft='8px'
              variant='body1'
              color='secondary.light'
              fontSize='14px'
            >
              {sample?.unit_type.name.toLocaleLowerCase()} {sample?.unit_type.description}
            </Typography>
          </>
        )}
      </StyledParagraph>

      <StyledDetailsLabel variant='h5'>Value</StyledDetailsLabel>
      <StyledParagraph variant='body1'>
        <Typography component='span'>
          <Typography sx={{ display: 'block' }} component='span' mb='12px'>
            <Typography
              component='span'
              sx={{ display: 'block', color: '#8F929B' }}
              variant='body2'
            >
              Per 1 unit
            </Typography>
            <Typography component='span' fontSize='16px' variant='body2'>
              ≈ {pricePerUnit}
            </Typography>
            <Typography component='span' ml='8px' sx={{ color: '#8F929B' }} variant='body2'>
              USD
            </Typography>
          </Typography>
          <Typography component='span'>
            <Typography
              component='span'
              sx={{ display: 'block', color: '#8F929B' }}
              variant='body2'
            >
              Total
            </Typography>
            <Typography component='span' fontSize='16px' variant='body2'>
              ≈ {totalPrice}
            </Typography>
            <Typography component='span' ml='8px' sx={{ color: '#8F929B' }} variant='body2'>
              USD
            </Typography>
          </Typography>
        </Typography>
      </StyledParagraph>

      <StyledDetailsLabel variant='h5'>Lot</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{sample?.lot || '-'}</StyledParagraph>

      <StyledDetailsLabel variant='h5'>Buyer's name</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{sample?.buyers_name || '-'}</StyledParagraph>

      <StyledDetailsLabel variant='h5'>Purchase date</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{formattedPurchaseDate || '-'}</StyledParagraph>

      <StyledDetailsLabel variant='h5'>Town purchased</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{sample?.town?.description || '-'}</StyledParagraph>

      <StyledDetailsLabel variant='h5'>Pharmacy name</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{sample?.pharmacy?.name || '-'}</StyledParagraph>

      <StyledDetailsLabel variant='h5'>Pharmacy GPS</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{sample?.pharmacy_gps || '-'}</StyledParagraph>
    </div>
  )
}
