import { styled } from '@mui/material/styles'

import { Link } from '@styles'

export const LoadingPhotoBox = styled('div')`
  position: relative;
  display: inline-block;
  font-size: 0;
`

export const PreviewIconBox = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const PhotoImg = styled('img')`
  display: block;
  margin-bottom: 16px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
`

export const AddAttachedMaterialLink = styled(Link)`
  display: flex;
  align-items: unset;
  cursor: ${({ $isDeactivate }) => ($isDeactivate ? 'default' : 'pointer')};
`
