import React from 'react'

import { AttachmentPhoto, IHRSample } from '@models/samples'
import { Photo as SharedPhoto } from '@components/shared/Hr'

import { StyledTableCell } from '../../styles'

interface IPhotoProps {
  sample: IHRSample
}
export const Photo: React.FC<IPhotoProps> = ({ sample }) => {
  // BE asked to add this check for attachments
  const desiredAttachment = sample.attachments && sample.attachments[0]
  const attachments = [desiredAttachment] as AttachmentPhoto[]

  return (
    <StyledTableCell>
      <SharedPhoto attachments={attachments} photoInModal />
    </StyledTableCell>
  )
}
