import { Stack, StackProps, styled } from '@mui/material'

interface StyledStackProps extends StackProps {
  $isFetching?: boolean
  $isApprovedRequestStatus: boolean
  $isDeclinedRequestStatus: boolean
}

export const StyledStack = styled<React.FC<StyledStackProps>>(Stack)`
  align-items: center;
  justify-content: center;
  background-color: ${({ $isFetching, $isApprovedRequestStatus, $isDeclinedRequestStatus }) =>
    $isFetching
      ? '#E1E7F3'
      : $isApprovedRequestStatus
      ? '#E2F4EA'
      : $isDeclinedRequestStatus
      ? '#FCE4E4'
      : '#EAF8FC'};
  height: 40px;
  white-space: nowrap;
  padding: 4px;
  border-radius: 0.5px;
  margin-right: 22px;
  flex-direction: row;
`
