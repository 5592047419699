import React from 'react'
import { IconButton, useTheme } from '@mui/material'

import { ReactComponent as InfoCircle } from '@assets/icons/info-circle-blue.svg'

interface InfoIconTruncateProps {
  toggleTooltipHandler: () => void
}

export const InfoIconTruncate: React.FC<InfoIconTruncateProps> = ({ toggleTooltipHandler }) => {
  const theme = useTheme()

  return (
    <IconButton sx={{ marginLeft: '8px' }} onClick={toggleTooltipHandler}>
      <InfoCircle fill={theme.palette.primary.main} />
    </IconButton>
  )
}
