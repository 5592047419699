import React from 'react'
import { Typography } from '@mui/material'

import { Image } from '@styles'
import error from '@assets/images/error-404.svg'
import { PillsImage } from '@components/icons'

import { Button } from '../Button'
import { StyledBox } from './styles'

interface FullPageErrorProps {
  imgVariant?: 'fail' | 'common'
  errorTextTitle: string
  errorText?: string
  btnText?: string
  onBtnClick?: React.MouseEventHandler<HTMLButtonElement>
  centred?: boolean
  pt?: string
  mb?: number
  svgWidth?: string
  svgHeight?: string
  textAlign?: string
}

export const FullPageError: React.FC<FullPageErrorProps> = props => {
  const {
    errorTextTitle,
    errorText,
    btnText,
    onBtnClick,
    centred,
    pt,
    mb,
    imgVariant = 'fail',
    svgWidth,
    svgHeight,
  } = props

  const btnClickHandler = onBtnClick || (() => {})

  return (
    <StyledBox centred={centred} pt={pt}>
      {imgVariant === 'fail' ? (
        <Image width='236px' height='236px' mb='24px' src={error} component='img' />
      ) : (
        <PillsImage width={svgWidth} height={svgHeight} />
      )}

      <Typography variant='h4' mb={mb || 6} textAlign='center' mt='30px'>
        {errorTextTitle}
      </Typography>
      {errorText && <Typography variant='body2'>{errorText}</Typography>}

      {btnText && (
        <Button variant='contained' height='48px' width='205px' onClick={btnClickHandler}>
          Refresh
        </Button>
      )}
    </StyledBox>
  )
}
