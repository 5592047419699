import * as yup from 'yup'
import { startOfToday } from 'date-fns'

import {
  EMAIL_MATCH,
  PASSWORDS_MATCH,
  REQUIRED_FIELD,
  LAST_NAME_REQUIRED,
  PASSWORD_MIN_LENGTH,
  FIRST_NAME_REQUIRED,
  LAST_NAME_MAX_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  ATTACH_LINK_URL_MAX_LENGTH,
  ATTACH_LINK_TITLE_MAX_LENGTH,
  INVALID_URL,
  SUBMITTER_CODE_CHARACTERS,
  SUBMITTER_CODE_ALLOWED,
  MIN_CHARACTERS,
  MAX_CHARACTERS,
  SPECIAL_CHARACTERS_ALLOWED,
  NUMERIC_DECIMAL,
  NOT_NEGATIVE,
  DANGEROUS_FIRST_CHARACTER,
  WEIGHT_ALLOWED,
} from '@constants/validation'
import {
  DRUG_CLASS,
  MAX_TEMPERATURE,
  MIN_TEMPERATURE,
  TEMPERATURE_FORMAT,
} from '@constants/samples'
import { CELSIUS, FAHRENHEIT } from '@constants/common'
import {
  SAMPLE_NAME,
  SUBMITTER_CODE,
  SAMPLE_NOTES,
  SAMPLE_QUESTIONS,
  MAIN_SUBSTANCES,
  OTHER_SUBSTANCES,
  SAMPLE_WEIGHT,
  SAMPLE_EFFECT,
} from '@constants/hrSamples'

const minCelsius = -15
const maxCelsius = 30
const minFahrenheit = 5
const maxFahrenheit = 86

export const validationSchemas = {
  login: yup.object().shape({
    email: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(/^(?!@)/, EMAIL_MATCH)
      .email(EMAIL_MATCH),
    password: yup.string().required(REQUIRED_FIELD).min(8, PASSWORD_MIN_LENGTH),
  }),
  email: yup.object().shape({
    email: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(/^(?!@)/, EMAIL_MATCH)
      .email(EMAIL_MATCH),
  }),
  recoverPassword: yup.object().shape({
    password: yup.string().required(REQUIRED_FIELD),
    passwordConfirm: yup
      .string()
      .required(REQUIRED_FIELD)
      .oneOf([yup.ref('password'), null], PASSWORDS_MATCH),
  }),
  profile: yup.object().shape({
    firstName: yup.string().required(FIRST_NAME_REQUIRED).max(25, FIRST_NAME_MAX_LENGTH),
    lastName: yup.string().required(LAST_NAME_REQUIRED).max(25, LAST_NAME_MAX_LENGTH),
    email: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(/^(?!@)/, EMAIL_MATCH)
      .email(EMAIL_MATCH),
  }),
  splitSample: yup.object().shape({
    number: yup.string().required(REQUIRED_FIELD),
  }),
  sendPackages: yup.object().shape({
    delivery_service: yup.string(),
    tracking_number: yup.string().max(250, 'Ensure this field has no more than 250 characters.'),
    package_type: yup.string().max(250, 'Ensure this field has no more than 250 characters.'),
    destination: yup.number().nullable().required(REQUIRED_FIELD),
    eventStartDate: yup.date().default(() => startOfToday()),
    expected_date: yup
      .date()
      .typeError('Please enter a valid date')
      .when(
        'eventStartDate',
        (eventStartDate, schema) =>
          eventStartDate &&
          schema.min(eventStartDate, 'The desire delivery date cannot be in the past')
      )
      .required(REQUIRED_FIELD),
    url: yup
      .string()
      .nullable()
      .max(200, ATTACH_LINK_URL_MAX_LENGTH)
      .matches(
        /(https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/[a-zA-Z0-9]+\.[^\s]{2,})/,
        { message: INVALID_URL, excludeEmptyString: true }
      ),
    title: yup.string().nullable().max(80, ATTACH_LINK_TITLE_MAX_LENGTH),
    [MIN_TEMPERATURE]: yup
      .number()
      .nullable(true)
      .default(0)
      .when(TEMPERATURE_FORMAT, (temperatureFormat, schema) => {
        if (temperatureFormat === CELSIUS) {
          return schema.min(minCelsius, `The value cannot be lower than ${minCelsius}C`)
        } else if (temperatureFormat === FAHRENHEIT) {
          return schema.min(minFahrenheit, `The value cannot be lower than ${minFahrenheit}F`)
        }
      })
      .lessThan(yup.ref(MAX_TEMPERATURE), 'Min value cannot be higher than max value'),
    [MAX_TEMPERATURE]: yup
      .number()
      .nullable(true)
      .default(1)
      .when(TEMPERATURE_FORMAT, (temperatureFormat, schema) => {
        if (temperatureFormat === CELSIUS) {
          return schema.max(maxCelsius, `The value cannot be higher than ${maxCelsius}C`)
        } else if (temperatureFormat === FAHRENHEIT) {
          return schema.max(maxFahrenheit, `The value cannot be higher than ${maxFahrenheit}F`)
        }
      })
      .moreThan(yup.ref(MIN_TEMPERATURE), 'Max value cannot be lower than min value'),
  }),
  attachLink: yup.object().shape({
    url: yup
      .string()
      .required(REQUIRED_FIELD)
      .max(200, ATTACH_LINK_URL_MAX_LENGTH)
      .matches(
        /(https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/[a-zA-Z0-9]+\.[^\s]{2,})/,
        INVALID_URL
      ),
    title: yup.string().required(REQUIRED_FIELD).max(80, ATTACH_LINK_TITLE_MAX_LENGTH),
  }),
}

export const sampleCreationSchema = yup.object().shape({
  [DRUG_CLASS]: yup.object().required(REQUIRED_FIELD),
})

export const editUserSchema = yup.object().shape({
  first_name: yup.string().required(FIRST_NAME_REQUIRED).max(25, FIRST_NAME_MAX_LENGTH),
  last_name: yup.string().required(LAST_NAME_REQUIRED).max(25, LAST_NAME_MAX_LENGTH),
  email: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(/^(?!@)/, EMAIL_MATCH)
    .email(EMAIL_MATCH),
})

export const editLaboratoryInfoSchema = yup.object().shape({
  address: yup.string().notRequired().max(500, 'Maximum 500 characters'),
  test_types: yup.array().max(50, 'Max 50 tests'),
  delivery_services: yup.array().max(30, 'Max 30 delivery services'),
  emails: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .notRequired()
        .matches(/^(?!@)/, EMAIL_MATCH)
        .email(EMAIL_MATCH),
      department: yup.string().notRequired().max(50, 'Maximum 50 characters'),
    })
  ),
  phone_numbers: yup.array().of(
    yup.object().shape({
      department: yup.string().notRequired().max(50, 'Maximum 50 characters'),
    })
  ),
})

export const createHrSampleSchema = yup.object().shape({
  fields: yup.object().shape({
    [SAMPLE_NAME]: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(/^[^@+-].*$/, DANGEROUS_FIRST_CHARACTER)
      .matches(/^[A-Za-z0-9-/,':;_ ]+$/, SPECIAL_CHARACTERS_ALLOWED)
      .min(2, MIN_CHARACTERS(2))
      .max(50, MAX_CHARACTERS(50)),
    [SUBMITTER_CODE]: yup
      .string()
      .test('length', SUBMITTER_CODE_CHARACTERS, value => {
        return value === '' || value?.length === 5
      })
      .test('characters', SUBMITTER_CODE_ALLOWED, value => {
        return value === '' || /^[a-zA-Z0-9]+$/.test(value || '')
      }),
    [SAMPLE_NOTES]: yup
      .string()
      .max(200, MAX_CHARACTERS(200))
      .test('dangerous characters', DANGEROUS_FIRST_CHARACTER, value => {
        const val = value?.trim() || ''
        return val === '' || /^[^@+-].*$/.test(val || '')
      }),
    [SAMPLE_QUESTIONS]: yup.string().max(200, MAX_CHARACTERS(200)),
    [MAIN_SUBSTANCES]: yup
      .string()
      .test('length', MIN_CHARACTERS(2), value => {
        return value === '' || !!(value && value?.length > 1)
      })
      .max(255, MAX_CHARACTERS(255)),

    [OTHER_SUBSTANCES]: yup
      .string()
      .test('length', MIN_CHARACTERS(2), value => {
        return value === '' || !!(value && value?.length > 1)
      })
      .max(255, MAX_CHARACTERS(255)),

    [SAMPLE_WEIGHT]: yup
      .string()
      .test('negative', NOT_NEGATIVE, value => {
        return value === '' || /^(?!-)/.test(value || '')
      })
      .test('digits', WEIGHT_ALLOWED, value => {
        return value === '' || /^[0-9.]+$/.test(value || '')
      })
      .test('numeric-decimal', NUMERIC_DECIMAL, value => {
        return value === '' || /^\d{1,6}(\.\d{1,6})?$/.test(value || '')
      }),
    [SAMPLE_EFFECT]: yup.string().max(200, MAX_CHARACTERS(200)),
  }),
})
