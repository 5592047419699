import React from 'react'

import { IPackagesTabValue } from '@models/packages'

import { Packages, IncomingPackages, OutgoingPackages } from './components'
import { StyledTabPanel } from './styles'

interface ITab {
  component: React.FC
  value: IPackagesTabValue
}

export const TabContent = () => {
  const tabs: ITab[] = [
    { component: Packages, value: 'packages' },
    { component: IncomingPackages, value: 'incoming packages' },
    { component: OutgoingPackages, value: 'outgoing packages' },
  ]

  const renderTab = ({ component: Tab, value }: ITab) => (
    <StyledTabPanel key={value} value={value}>
      <Tab />
    </StyledTabPanel>
  )

  return <>{tabs.map(tab => renderTab(tab))}</>
}
