import { useMutation, useQueryClient } from 'react-query'

import { requestSamples } from '@api/requests'
import { showNotification } from '@helpers/app'
import { REQUEST_SAMPLES } from '@constants/queries'
import { notifications } from '@constants/notifications'
import { useAppDispatch } from '@hooks/redux'
import { selectSampleRequests } from '@state/requests'
import { IRequestSamplesData } from '@models/requests'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useRequestSamples = () => {
  const client = useQueryClient()
  const dispatch = useAppDispatch()

  const mutation = useMutation(requestSamples, {
    onSuccess: (_, { samples }) => {
      showNotification(notifications.requestSamplesSuccessfully)
      dispatch(selectSampleRequests([]))

      client.setQueryData<{ pageParams: any; pages: IRequestSamplesData[] | undefined }>(
        REQUEST_SAMPLES,
        oldData => {
          return {
            pages: oldData?.pages?.map(page => ({
              ...page,
              results: page.results.filter(({ id: sampleId }) => !samples.includes(sampleId)),
            })),
            pageParams: oldData?.pageParams,
          }
        }
      )
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
      showNotification(notifications.requestSamplesFailed)
    },
    onSettled: () => {
      client.invalidateQueries(REQUEST_SAMPLES)
    },
  })

  return mutation
}
