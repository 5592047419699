import React from 'react'

import * as Styled from '@components/ui/PureDropdownAutocomplete/styles'
import { AddAutocompleteNewData } from '@components/shared/AddAutocompleteNewData'
import { useAppSelector } from '@hooks/redux'
import {
  dropdownInputValue,
  dropdownIsLoading,
  dropdownOptionsDataPosition,
} from '@state/common/dropdownState'
import { useCustomLazyScroll } from '@hooks/common'
import { Tooltip } from '@components/shared'
import { toxicityTooltip } from '@helpers/ui/samples'

import { IOptionExtended } from './pureDropdownAutocomplete.types'

export const PureDropdownAutocompleteListOptions: React.FC = () => {
  const wrapRef = React.useRef<HTMLDivElement | null>(null)
  const listRef = React.useRef<HTMLUListElement | null>(null)
  const scrollTriggerRef = React.useRef<HTMLDivElement | null>(null)

  const data = useAppSelector(dropdownOptionsDataPosition)
  const isLoading = useAppSelector(dropdownIsLoading)
  const inputValue = useAppSelector(dropdownInputValue)

  // Setting STYLES to the wrapper
  React.useEffect(() => {
    if (wrapRef.current && listRef.current && data) {
      // Setting HEIGHT
      const { top, left, width, minWidth, maxWidth } = data.position
      const minHeight = 100
      const offsetWindowEnding = 10
      const bodyHeight = document.body.offsetHeight
      const height = bodyHeight + window.scrollY - top - offsetWindowEnding

      if (
        bodyHeight + window.scrollY - offsetWindowEnding < top + listRef.current.offsetHeight &&
        height > minHeight
      ) {
        wrapRef.current.style.height = `${height}px`
      } else {
        wrapRef.current.style.height = 'auto'
        wrapRef.current.style.maxHeight = '400px'
      }

      // Setting position to TOP or BOTTOM
      if (height > minHeight) {
        wrapRef.current.style.top = `${top}px`
      } else {
        wrapRef.current.style.bottom = `${-window.scrollY + offsetWindowEnding}px`
      }

      // Setting COMMON styles
      if (minWidth && maxWidth && listRef.current) {
        const listWidth = listRef.current.offsetWidth
        const correctLeft = listWidth > minWidth ? left - (listWidth - minWidth) : left

        wrapRef.current.style.left = `${correctLeft}px`
        wrapRef.current.style.minWidth = `${minWidth}px`
        wrapRef.current.style.maxWidth = `${maxWidth}px`
      } else {
        wrapRef.current.style.left = `${left}px`
        wrapRef.current.style.width = `${width}px`
      }
    }
  }, [wrapRef, listRef, data])

  useCustomLazyScroll(
    wrapRef,
    scrollTriggerRef,
    data?.lazyScrollData?.fetchNextPage,
    false,
    data?.lazyScrollData?.hasNextPage
  )

  if (!data) return null

  const {
    optionsComponent,
    options,
    dropdownActions,
    disabledOptions,
    isAlwaysShowAddNewItem,
    withTooltip,
    disableAddNewItem,
  } = data

  const handleClickOption = (option: IOptionExtended) => {
    if (!disabledOptions) dropdownActions?.handleChangeDropdown(option)
  }

  const isAddNewItem = !!dropdownActions?.handleAddNewItem && inputValue

  const tooltipText = (value: string) => {
    if (!withTooltip) return ''
    return toxicityTooltip(value)
  }

  return (
    <Styled.ListWrap ref={wrapRef} id='pure-dropdown-options-list'>
      <Styled.List ref={listRef}>
        {/* ----- LOADING MESSAGE ----- */}
        {isLoading && <Styled.Item style={{ color: '#495C82' }}>Loading...</Styled.Item>}
        {/* ----- OPTIONS ----- */}
        {optionsComponent}
        {options.map((item, idx) => {
          const { name, icon: Icon, description, iconColor, value } = item
          return (
            <React.Fragment key={idx}>
              {idx === options.length - 10 && <div ref={scrollTriggerRef} />}
              <Tooltip
                isPureTooltip
                title={tooltipText(value || name)}
                shouldBeHidden={!tooltipText(value || name).length}
                maxWidth={210}
              >
                <Styled.Item
                  style={disabledOptions ? { color: '#8f929b' } : {}}
                  onClick={() => handleClickOption(item)}
                >
                  <div style={Icon ? { display: 'flex' } : {}}>
                    {Icon && (
                      <Styled.IconWrap style={{ textAlign: 'center' }}>
                        <Icon fill={iconColor || ''} stroke={iconColor || ''} />
                      </Styled.IconWrap>
                    )}
                    {/* If we have "IChoice" type of option, then we show "value", else "name" */}
                    {value || name}
                  </div>

                  {description && !item.hasOwnProperty('place_id') && (
                    <Styled.OptionDescription style={Icon ? { textAlign: 'right' } : {}}>
                      {description}
                    </Styled.OptionDescription>
                  )}
                </Styled.Item>
              </Tooltip>
            </React.Fragment>
          )
        })}
        {/* ----- NOTHING FOUND MESSAGE ----- */}
        {!options.length && !isLoading && !optionsComponent && (
          <Styled.Item style={{ color: '#495C82' }}>Nothing found</Styled.Item>
        )}
      </Styled.List>
      {/* ----- ADD NEW ITEM ---- */}
      {((isAddNewItem && isAlwaysShowAddNewItem) || (isAddNewItem && !options.length)) &&
        !disableAddNewItem && (
          <AddAutocompleteNewData
            isWithoutLabel
            isBtnLoading={isLoading || false}
            description={inputValue}
            handleAdd={() =>
              !!dropdownActions?.handleAddNewItem && dropdownActions?.handleAddNewItem(inputValue)
            }
          />
        )}
    </Styled.ListWrap>
  )
}
