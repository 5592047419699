import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import { useAppSelector } from '@hooks/redux'
import { Button, FilterAside } from '@components/shared'
import { PageHeaderWrapper, FlexWrapper, StyledClearAll } from '@styles'
import { ReactComponent as QrAppIcon } from '@assets/icons/qr-code-scan-icon.svg'
import { useToggle } from '@hooks/common'
import { LogIntoPadApp } from '@components/modules/Modals'
import { protectedRoutes } from '@configs/routes.config'
import { AutocompleteSearch } from '@components/shared/AutocompleteSearch'
import { DESIRED_TASKS } from '@constants/tasks'
import {
  ApisFilter,
  DrugClassFilter,
  ExcipientsFilter,
  ManufactureFilter,
} from '@components/shared/filters'
import { filtersAside } from '@state/filters'
import { TESTING_DATE } from '@components/shared/FilterAside/constants'
import { DatePickerRangeFilter } from '@components/shared/filters/allFilters'

interface PageHeaderProps {
  shouldHiddenSearchFieldHandler?: () => void
  shouldHiddenSearchField?: boolean
}

export const PageHeader: React.FC<PageHeaderProps> = props => {
  const { shouldHiddenSearchFieldHandler, shouldHiddenSearchField } = props
  const history = useHistory()
  const isMobile = useMediaQuery('(max-width:767px)')
  const [isModalOpen, toggleModal] = useToggle()
  const isFilterOpen = useAppSelector(filtersAside)
  const theme = useTheme()

  const shouldHiddenSearch = isMobile ? shouldHiddenSearchField : true

  const [searchQueryString, setSearchQueryString] = useQueryParam(DESIRED_TASKS, StringParam)
  const [queryString] = useQueryParam('tasks_type', StringParam)

  const redirectToLogIntoPadAppPage = () => history.push(protectedRoutes.logIntoPadAppMobile.path)
  return (
    <>
      {isMobile ? (
        <FlexWrapper $isMobile={isMobile} $tasks $withoutPadding={isMobile} $direction='column'>
          <PageHeaderWrapper style={{ width: '100%', alignItems: 'center' }}>
            <Typography
              variant={isMobile ? 'h3' : 'h1'}
              mb={isMobile ? '' : shouldHiddenSearchField ? '45px' : 5}
            >
              List of tasks
            </Typography>
            <Button
              onClick={redirectToLogIntoPadAppPage}
              fz={14}
              fw={500}
              lh='20px'
              textColor={theme.palette.primary.main}
              variant='outlined'
              height='36px'
              ml='16px'
              startIcon={<QrAppIcon fill={theme.palette.primary.main} width='24px' height='24px' />}
            >
              PADReader
            </Button>
          </PageHeaderWrapper>

          {shouldHiddenSearch && (
            <Box display='flex' padding='28px 0 14px 0' justifyContent='space-between' width='100%'>
              <AutocompleteSearch
                width={isMobile ? '100%' : '320px'}
                placeholder='Enter sample ID...'
                setQueryString={setSearchQueryString}
                queryString={searchQueryString}
                searchIn='tasks'
                type={queryString}
              />
              <StyledClearAll
                $alignSelf='center'
                $marginLeft='8px'
                onClick={shouldHiddenSearchFieldHandler}
                sx={{ alignSelf: 'center', fontWeight: 'normal' }}
                variant='outlined'
              >
                Cancel
              </StyledClearAll>
            </Box>
          )}
        </FlexWrapper>
      ) : (
        <FlexWrapper $isMobile={isMobile} $tasks $withoutPadding={isMobile}>
          <Typography
            variant={isMobile ? 'h3' : 'h1'}
            mb={shouldHiddenSearchField ? '45px' : isMobile ? '' : 5}
          >
            List of tasks
          </Typography>

          <PageHeaderWrapper>
            <AutocompleteSearch
              width={isMobile ? '100%' : '320px'}
              placeholder='Enter sample ID...'
              setQueryString={setSearchQueryString}
              queryString={searchQueryString}
              searchIn='tasks'
              type={queryString}
            />

            <Button
              variant='contained'
              onClick={toggleModal}
              height='48px'
              width='226px'
              ml={isMobile ? '' : '16px'}
              startIcon={<QrAppIcon fill='#ffffff' width='16px' height='16px' />}
              sx={{ whiteSpace: 'nowrap' }}
            >
              Log into PADReader
            </Button>
          </PageHeaderWrapper>
        </FlexWrapper>
      )}

      {isModalOpen && <LogIntoPadApp toggleModal={toggleModal} />}
      {isFilterOpen.right && (
        <FilterAside>
          <Box>
            {/* list of filters here */}
            <DrugClassFilter />
            <ApisFilter />
            <ExcipientsFilter />
            <ManufactureFilter />
            {queryString === 'completed' && <DatePickerRangeFilter type={TESTING_DATE} />}
          </Box>
        </FilterAside>
      )}
    </>
  )
}
