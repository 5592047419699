import React from 'react'

import { StyledTableSampleCell } from '@styles'
import { ITaskLab } from '@models/tasks'
import { SpecificId } from '@components/shared/sampleFields'
import { TempSensitiveToxicityIcons } from '@components/shared'

interface SampleIDProps {
  task: ITaskLab
  isMobile?: boolean
}

export const SampleID: React.FC<SampleIDProps> = ({ task, isMobile }) => {
  const {
    sample: specificId,
    toxicity_level,
    min_temperature,
    max_temperature,
    temperature_measurement_format,
  } = task
  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      <SpecificId
        labelTooltip={task.initial_laboratory?.label_tooltip}
        specificId={`${specificId}`}
      />
      <TempSensitiveToxicityIcons
        toxicity={toxicity_level}
        minTempSensitive={min_temperature}
        maxTempSensitive={max_temperature}
        temperatureMeasurementFormat={temperature_measurement_format?.key}
        mt='-5px'
      />
    </StyledTableSampleCell>
  )
}
