import React from 'react'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import { styled, CardMedia, CardMediaProps } from '@mui/material'

const imageConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'width':
        return false

      case 'height':
        return false

      case 'mb':
        return false

      default:
        return true
    }
  },
}

export interface ImageProps extends CardMediaProps {
  width?: string
  height?: string
  mb?: string
  mr?: string
  mt?: string
  display?: string
  component: React.ElementType
}

export const Image = styled<React.FC<ImageProps>>(CardMedia, imageConfig)`
  display: ${({ display }) => display || ''};
  width: ${({ width }) => width || ''};
  height: ${({ height }) => height || ''};
  margin-top: ${({ mt }) => mt || ''};
  margin-right: ${({ mr }) => mr || ''};
  margin-bottom: ${({ mb }) => mb || ''};
  align-self: center;
  object-fit: none;
`
