import { LOCAL_API, WEB_SOCKET_API } from '@constants/common'
import { UrlManager } from '@helpers/url'

const connectionTiming = 5000
const { apiUrl } = new UrlManager()
const endpoint =
  apiUrl === LOCAL_API
    ? apiUrl.replace('http', 'ws') + WEB_SOCKET_API
    : apiUrl.replace('https', 'wss') + WEB_SOCKET_API

export const connectWebSocket = (
  toggle: (arg1: boolean) => void,
  webSocketConnected: boolean | null
) => {
  let socket: any
  let messageGenerator: any
  let conditionInterval: any

  let active = true
  let prevActive = false

  let timeout: any = false
  function checkActivity() {
    clearTimeout(timeout)
    active = true
    timeout = setTimeout(() => {
      active = false
    }, 60000)
  }

  document.addEventListener('keydown', checkActivity)
  document.addEventListener('mousedown', checkActivity)
  document.addEventListener('mousemove', checkActivity)
  checkActivity()

  function startConnection() {
    let lastMessage: number
    socket = new WebSocket(endpoint)

    messageGenerator = setInterval(() => {
      socket.readyState === 1 && socket.send(JSON.stringify({ message: 'some message' }))
    }, connectionTiming)

    socket.onmessage = () => {
      toggle(true)
      lastMessage = Date.now()
    }

    conditionInterval = setInterval(() => {
      if (lastMessage + connectionTiming + 5000 < Date.now()) {
        toggle(false)
      }
    }, connectionTiming)
  }

  startConnection()

  function stopConnection() {
    socket.close()
    clearInterval(messageGenerator)
    clearInterval(conditionInterval)
  }

  setInterval(() => {
    if (!active) stopConnection()
    else if (active && prevActive !== active) {
      startConnection()
    }

    prevActive = active
  }, 1000)
}
