import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ISampleTask } from '@models/samples'
import { TRUNCATED_TEXT_LOCATION } from '@constants/common'
import { stringSlice } from '@helpers/ui/common'
import { ParagraphLink, Tooltip } from '@components/shared'
import { protectedRoutes } from '@configs/routes.config'
import { setLaboratoryForWorkspace } from '@state/workSpace'

interface TestLocationProps {
  task: ISampleTask
}

export const TestLocation: React.FC<TestLocationProps> = ({ task }) => {
  const { laboratory } = task
  const dispatch = useDispatch()
  const history = useHistory()

  const viewLaboratoryInfoEndPoint = () => {
    if (laboratory) {
      dispatch(setLaboratoryForWorkspace(laboratory))
    }

    history.replace({
      pathname: protectedRoutes.yourWorkspace.path,
      state: [
        {
          path: protectedRoutes.testingReports.path,
          url: protectedRoutes.testingReports.path,
          title: 'Test reports',
        },
      ],
    })
  }

  if (task.test_type.is_other) return null

  return (
    <Tooltip
      title={laboratory.name || ''}
      shouldBeHidden={(laboratory.name?.length || 0) <= TRUNCATED_TEXT_LOCATION}
    >
      <ParagraphLink onClick={viewLaboratoryInfoEndPoint}>
        {stringSlice(laboratory.name, TRUNCATED_TEXT_LOCATION)}
      </ParagraphLink>
    </Tooltip>
  )
}
