import React from 'react'
import { Icon, IconButton, Typography, CircularProgress, useMediaQuery } from '@mui/material'

import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { fileTypeConverter } from '@helpers/ui/attachments'
import { PureLink } from '@styles'
import checkIcon from '@assets/icons/check-in-circle-attachment-icon.svg'
import deleteIcon from '@assets/icons/delete-icon-disabled.svg'

import { AttachedMaterialItemProps, LinkPropTypes } from './attachedMaterialItem.types'
import { AttachedMaterialBox, MaterialInfo, IconBox, AttachedMaterialContainer } from './styles'

export const AttachedMaterialItem: React.FC<AttachedMaterialItemProps> = ({
  attachment,
  truncatedTextLength,
  removeAttachment,
  disabled,
}) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const { id, name, link, size, loading, withoutDownload, attachment_section } = attachment
  const { iconType, fileType } = fileTypeConverter(name)

  const handleRemoveAttachedMaterialItem = (): void => {
    removeAttachment(String(id), attachment_section.key)
  }

  const truncatedFileName = truncatedTextLength
    ? stringSlice(name || '', truncatedTextLength)
    : name

  const truncatedFileType = truncatedTextLength
    ? stringSlice(link || '', truncatedTextLength)
    : link
  const isNeedDisableDeleteBtn = !disabled ? disabled : !(fileType !== 'link' ? link : link)

  const pureLinkProps = (() => {
    const propsForLink: LinkPropTypes = {
      $isTextDecorationAbsence: fileType !== 'link',
      $isDeactivate: !link,
    }
    if (link) propsForLink.href = link
    return propsForLink
  })()

  const tooltipTitleForTruncatedFileNames =
    truncatedTextLength && name.length > truncatedTextLength ? name : ''

  return (
    <AttachedMaterialContainer>
      <AttachedMaterialBox $isMobile={isMobile}>
        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <Icon sx={{ width: '40px', height: '40px' }}>
            <img src={iconType} alt='document' />
          </Icon>
        )}

        <MaterialInfo>
          {fileType === 'link' ? (
            <Typography variant='body1' width='210px'>
              {truncatedFileName}
            </Typography>
          ) : (
            <Tooltip
              shouldBeHidden={!tooltipTitleForTruncatedFileNames.length}
              title={
                loading || withoutDownload
                  ? tooltipTitleForTruncatedFileNames
                  : link
                  ? 'Click to download file'
                  : "We're processing this file. Please try to download it later."
              }
            >
              {loading || withoutDownload ? (
                <Typography variant='body1' width='210px'>
                  {truncatedFileName}
                </Typography>
              ) : (
                <PureLink {...pureLinkProps} width='210px'>
                  {truncatedFileName}
                </PureLink>
              )}
            </Tooltip>
          )}

          {fileType === 'link' ? (
            loading ? (
              <Typography sx={{ color: '#8F929B' }} variant='body1'>
                Loading...
              </Typography>
            ) : (
              <Tooltip title='Click to open link'>
                <PureLink target={fileType === 'link' ? '_blank' : ''} href={link || ''}>
                  <Typography variant='body1' width='210px'>
                    {truncatedFileType}
                  </Typography>
                </PureLink>
              </Tooltip>
            )
          ) : (
            <Typography sx={{ color: '#8F929B' }} variant='body1'>
              {loading ? 'Loading...' : size}
            </Typography>
          )}
        </MaterialInfo>

        {!loading && (
          <IconButton disabled={isNeedDisableDeleteBtn} onClick={handleRemoveAttachedMaterialItem}>
            <img src={deleteIcon} alt='delete' />
          </IconButton>
        )}
      </AttachedMaterialBox>

      {!loading && (
        <IconBox $isMobile={isMobile}>
          <Icon sx={{ width: '24px', height: '24px' }}>
            <img src={checkIcon} alt={'check'} />
          </Icon>
        </IconBox>
      )}
    </AttachedMaterialContainer>
  )
}
