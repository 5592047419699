import React from 'react'

import { ISample } from '@models/samples'

import { StyledTableCell } from '../styles'

interface IDestinationProps {
  sample: ISample
}

export const Destination: React.FC<IDestinationProps> = ({ sample }) => {
  return <StyledTableCell>{sample.package_destination?.name || '-'}</StyledTableCell>
}
