import { useParams } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'
import React from 'react'

import { useFetchTask } from '@hooks/queries'
import { FullPageError, MobileCardLoader } from '@components/shared'
import { editingNotesFieldId, isEditingNotes } from '@state/samples'
import { ITaskLab } from '@models/tasks'

import { PageLoader, Test } from './components'

interface ITestParams {
  taskId: string
}

export const TestContent = () => {
  const { taskId } = useParams<ITestParams>()
  const fetchTaskQ = useFetchTask<ITaskLab>(Number(taskId))
  const isMobile = useMediaQuery('(max-width:767px)')
  const dispatch = useDispatch()

  const refetchTask = () => fetchTaskQ.refetch()

  React.useEffect(() => {
    if (fetchTaskQ.isFetching) {
      dispatch(isEditingNotes(true))
    } else if (fetchTaskQ.isFetched) {
      dispatch(isEditingNotes(false))
      dispatch(editingNotesFieldId(null))
    }
  }, [dispatch, fetchTaskQ.isFetched, fetchTaskQ.isFetching])

  if (fetchTaskQ.isLoading) {
    return !isMobile ? <PageLoader /> : <MobileCardLoader />
  }

  if (fetchTaskQ.isError) {
    return (
      <FullPageError
        errorTextTitle='Something went wrong with task loading'
        btnText='Refresh'
        onBtnClick={refetchTask}
        pt='20px'
      />
    )
  }

  if (!fetchTaskQ.data) {
    return (
      <FullPageError errorTextTitle="Something went wrong. We can't start the test" pt='20px' />
    )
  }

  return <Test task={fetchTaskQ.data} />
}
