import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Image, Paragraph, StyledBox } from '@styles'
import success from '@assets/images/success.svg'
import error from '@assets/images/error.svg'

import { Modal, Button } from '..'

interface NotificationModalProps {
  title?: string
  description?: React.ReactNode
  btnText?: string
  onBtnClick?: React.MouseEventHandler<HTMLButtonElement>
  variant: 'error' | 'success'
  onClose: () => void
}

export const NotificationModal: React.FC<NotificationModalProps> = props => {
  const { title, description, btnText, onBtnClick, variant, onClose } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  return (
    <Modal open onClose={onClose}>
      <StyledBox>
        <Image
          src={variant === 'success' ? success : error}
          width='80px'
          height='80px'
          mb='16px'
          component='img'
        />

        <Paragraph
          variant={isMobile ? 'subtitle1' : 'h3'}
          color='#0F1934'
          align='center'
          mb={!description && btnText ? '24px' : '8px'}
        >
          {title}
        </Paragraph>

        {description && (
          <Paragraph
            color='#495C82'
            align='center'
            mb={btnText ? '24px' : ''}
            sx={{ lineHeight: '24px' }}
          >
            {description}
          </Paragraph>
        )}

        {btnText && (
          <Button
            variant='contained'
            width='205px'
            sx={{ alignSelf: 'center' }}
            onClick={onBtnClick}
          >
            {btnText}
          </Button>
        )}
      </StyledBox>
    </Modal>
  )
}
