import { Box } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { DETAILS } from '@constants/common'
import { protectedRoutes } from '@configs/routes.config'
import { ISamples } from '@models/samples'
import { selectSamples, selectSamplesState } from '@state/samples'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { MAX_SELECTED_SAMPLE_NUMBER } from '@constants/samples'
import { REQUESTS, SAMPLES } from '@constants/queries'
import { TEST_REPORTS } from '@constants/testReports'
import { selectTestReports, selectTestReportsState } from '@state/testReports'
import { isLoadingPackage } from '@state/app'

import {
  StyledSampleSplitLink,
  StyledTableCell,
  StyledSplitLine,
  StyledSampleSplitBox,
} from './styles'
import { Button } from '../Button'
import { Tooltip } from '../Tooltip'

interface IParentsWithLines {
  id: number
  groupSplitHeight: number
}

interface ParentLinksRowProps {
  nextParents: number[]
  prevParents: number[]
  parentsSpecificIds?: string[]
  isSameSubGroup: boolean
  width: string
  paddingRight?: string
  samples?: ISamples
  contentType: typeof SAMPLES | typeof REQUESTS | typeof TEST_REPORTS
}

export const ParentLinksRow: React.FC<ParentLinksRowProps> = React.memo(props => {
  const {
    nextParents,
    prevParents,
    isSameSubGroup,
    width,
    paddingRight,
    samples,
    contentType,
    parentsSpecificIds,
  } = props

  const parentsWithLines = nextParents.reduce<IParentsWithLines[]>((acc, parent, idx) => {
    return [...acc, { id: parent, groupSplitHeight: nextParents.length * 40.5 }]
  }, [])

  const history = useHistory()
  const dispatch = useAppDispatch()
  const { selectedSamples } = useAppSelector(selectSamplesState)
  const { selectedTestReports } = useAppSelector(selectTestReportsState)
  const isLoadingCreatePackage = useAppSelector(isLoadingPackage)
  const client = useQueryClient()

  const sampleInvalidate = client.isFetching(SAMPLES)
  const selectedItems = contentType === TEST_REPORTS ? selectedTestReports : selectedSamples

  React.useEffect(() => {}, [selectedItems])

  const locationFromState =
    contentType === TEST_REPORTS
      ? {
          path: `${protectedRoutes.testingReports.path}`,
          url: `${protectedRoutes.testingReports.path}`,
          title: `Test reports`,
        }
      : {
          path: protectedRoutes.samples.path,
          url: protectedRoutes.samples.path,
          title: 'Sample list',
        }

  const sampleDetailsEndPoint = (sampleId: number) => {
    history.replace({
      pathname: `${protectedRoutes.samples.path}/${sampleId}${DETAILS}`,
      state: [locationFromState],
    })
  }

  const renderSplitLinks = React.useMemo(() => {
    return parentsWithLines.map(({ id, groupSplitHeight }, idx) => {
      const isTheSameSubParent = id === prevParents[idx]
      const isSamePrevParent = prevParents[0] === parentsWithLines[0].id

      const isSameLengthOfSubGroup = parentsWithLines.length === prevParents.length
      const shouldRenderLastParentOnly =
        isSameSubGroup && isSameLengthOfSubGroup && parentsWithLines.length - 1 !== idx
      const shouldRenderLineWithoutLink = shouldRenderLastParentOnly || isTheSameSubParent

      if (shouldRenderLineWithoutLink) {
        return <StyledSplitLine key={id} $height={groupSplitHeight - 44}></StyledSplitLine>
      }

      const samplesCanBeSelected =
        contentType === TEST_REPORTS
          ? samples && samples.filter(s => s.parent === id).length
          : samples && samples.filter(s => s.parent === id).filter(s => s.allow_packaging).length

      const isAllParentSamplesChecked =
        contentType === TEST_REPORTS
          ? samples &&
            selectedItems
              .map(selectedId => samples.find(s => s.id === selectedId))
              .filter(s => s?.parent === id).length
          : samples &&
            selectedItems
              .map(selectedId => samples.find(s => s.id === selectedId))
              .filter(s => s?.parent === id && s?.allow_packaging).length

      const isSamplesChecked = isAllParentSamplesChecked === samplesCanBeSelected

      const needToDisableButton =
        selectedItems.length >= MAX_SELECTED_SAMPLE_NUMBER ||
        !samplesCanBeSelected ||
        isLoadingCreatePackage ||
        !!sampleInvalidate

      const checkboxHandler = () => {
        let dispatchCheckedSamplesIds: number[] = []

        const samplesIdsInParent =
          contentType === TEST_REPORTS
            ? samples && samples.filter(s => s.parent === id).map(s => s.id)
            : samples && samples.filter(s => s.parent === id && s.allow_packaging).map(s => s.id)

        if (!isSamplesChecked) {
          samplesIdsInParent?.map(checkedId => {
            if (!selectedItems.includes(checkedId)) {
              dispatchCheckedSamplesIds.push(checkedId)
            }
            return dispatchCheckedSamplesIds
          })

          contentType === TEST_REPORTS
            ? dispatch(selectTestReports(selectedItems.concat(dispatchCheckedSamplesIds)))
            : dispatch(selectSamples(selectedItems.concat(dispatchCheckedSamplesIds)))
        } else if (isSamplesChecked) {
          selectedItems.map(checkedId => {
            if (!samplesIdsInParent?.includes(checkedId)) {
              dispatchCheckedSamplesIds.push(checkedId)
            }
            return dispatchCheckedSamplesIds
          })

          contentType === TEST_REPORTS
            ? dispatch(selectTestReports(dispatchCheckedSamplesIds))
            : dispatch(selectSamples(dispatchCheckedSamplesIds))
        }
      }

      return (
        <StyledSampleSplitBox
          key={id}
          height={groupSplitHeight}
          isTheSameSubParent={isSamePrevParent}
        >
          <StyledSampleSplitLink onClick={() => sampleDetailsEndPoint(id)}>
            {parentsSpecificIds && parentsSpecificIds[idx]}
          </StyledSampleSplitLink>

          {(contentType === SAMPLES || contentType === TEST_REPORTS) &&
            !shouldRenderLineWithoutLink && (
              <Tooltip
                isPureTooltip
                positionLeft={-3}
                maxWidth={210}
                positionTop={0}
                title='There are no samples to select from'
                shouldBeHidden={!needToDisableButton}
              >
                <div style={{ display: 'inline-block' }}>
                  <Button
                    variant={isSamplesChecked || needToDisableButton ? 'contained' : 'outlined'}
                    bgColor={!isSamplesChecked ? '#ffffff' : ''}
                    style={{ padding: '2px 14px' }}
                    fz={14}
                    fw={400}
                    ml='20px'
                    disabled={needToDisableButton}
                    onClick={checkboxHandler}
                  >
                    {isSamplesChecked && !needToDisableButton ? 'Unselect' : 'Select all'}
                  </Button>
                </div>
              </Tooltip>
            )}
        </StyledSampleSplitBox>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contentType,
    history,
    isSameSubGroup,
    parentsSpecificIds,
    parentsWithLines,
    prevParents,
    samples,
    selectedItems,
  ])

  return (
    <Box>
      <StyledTableCell sx={{ width, paddingRight }}>{renderSplitLinks}</StyledTableCell>
    </Box>
  )
})
