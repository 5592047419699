import { Box, styled } from '@mui/material'
import React from 'react'

import { ITableColumnWidths } from '@models/common/user'

export const StyledTableRow = styled<
  React.FC<{ columns: ITableColumnWidths; $isBorderBottom?: boolean; $isBackgroundColor?: boolean }>
>(Box)`
  display: grid;
  grid-template-columns: [checkbox]64px [sample_id]290px ${({ columns }) => {
      return columns.map(column => column?.width).join(' ')
    }} 1fr [action]267px;
  border-bottom: 4px solid
    ${({ theme, $isBorderBottom }) => ($isBorderBottom ? 'none' : theme.palette.background.default)};
  background-color: ${({ theme, $isBackgroundColor }) =>
    $isBackgroundColor ? theme.primary[30] : '#fff'};
`
