import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Tenant } from '@models/common/app'

export interface ConfigState {
  tenant?: Tenant
}

const initialState: ConfigState = {
  tenant: undefined,
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setTenant: (state, { payload }: PayloadAction<Tenant | undefined>) => {
      state.tenant = payload
    },
  },
})

export const {
  reducer: configReducer,
  actions: { setTenant },
} = configSlice
