import { PageHeader as ReportsPageHeader } from '@components/shared'

import { ReportsContent } from './components/ReportsContent'
import { ReportsFiltersMenu } from './components'

export const TestingReports = () => {
  return (
    <>
      <ReportsPageHeader pageTitle='Test reports' placeholder='Enter sample ID...' />
      <ReportsFiltersMenu />
      <ReportsContent />
    </>
  )
}
