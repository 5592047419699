import { Typography } from '@mui/material'

import { getTemperatureSymbol } from '@helpers/obtainingCorrectTemperature'

import { StyledText } from '../styles'

interface RenderTempSensitiveTooltipTitleProps {
  minTempSensitive?: string | null
  maxTempSensitive?: string | null
  temperatureMeasurementFormat?: string
  maxTemperatureMeasurementFormat?: string
  minTemperatureMeasurementFormat?: string
}
export const RenderTempSensitiveTooltipTitle: React.FC<RenderTempSensitiveTooltipTitleProps> = ({
  minTempSensitive,
  maxTempSensitive,
  temperatureMeasurementFormat,
  maxTemperatureMeasurementFormat,
  minTemperatureMeasurementFormat,
}) => {
  // * [we will use this logic after implementation feature - temperature format in user profile
  // const tempType = useAppSelector(userTemperatureFormat)
  // const isSampleHasSameTempFormatAsUser = temperatureMeasurementFormat === tempType.key
  // const tempSymbol = getTemperatureSymbol(tempType.key)

  // const tempSensitiveTooltip = (min: string, max: string) => {
  //   let minTemperature = min
  //   let maxTemperature = max

  //   if (!isSampleHasSameTempFormatAsUser) {
  //     minTemperature = obtainingCorrectTemperature(tempType.key, min)
  //     maxTemperature = obtainingCorrectTemperature(tempType.key, max)
  //   }

  //   return `Storage temperature: min ${Math.round(+minTemperature)}${tempSymbol} max ${Math.round(
  //     +maxTemperature
  //   )}${tempSymbol}`
  // }
  // * ]

  const minTempSymbol = getTemperatureSymbol(
    temperatureMeasurementFormat || minTemperatureMeasurementFormat
  )
  const maxTempSymbol = getTemperatureSymbol(
    temperatureMeasurementFormat || maxTemperatureMeasurementFormat
  )
  return (
    <>
      {minTempSensitive && maxTempSensitive && (
        <StyledText>
          Storage temperature:
          <br />
          min
          <Typography component='span' fontWeight={600} fontSize='13px'>
            {` ${Math.round(+minTempSensitive)}${minTempSymbol} `}
          </Typography>
          max
          <Typography component='span' fontWeight={600} fontSize='13px'>
            {` ${Math.round(+maxTempSensitive)}${maxTempSymbol}`}
          </Typography>
        </StyledText>
      )}
    </>
  )
}
