import { useToggle } from '@hooks/common'
import { IOption } from '@models/common/app'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_APIS_LENGTH } from '@constants/common'
import { ParagraphLink, ChipApi, Tooltip } from '@components/shared'

import {
  StyledBox,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './styles'

interface ISampleOptionWithType extends IOption {
  type: 'main' | 'optional'
}

interface ExpandableApisProps {
  bgColor?: string
  borderBottom?: string
  direction?: 'column' | 'row'
  apis: ISampleOptionWithType[]
}

export const ExpandableApis: React.FC<ExpandableApisProps> = props => {
  const { apis, borderBottom, direction = 'row', ...restProps } = props

  const [isExpanded, toggleExpanded] = useToggle()

  const prunedApis = apis.slice(0, 2)
  const showButtonText = isExpanded ? 'Show less' : 'Show more'
  const restApis = apis.filter(item => !prunedApis.includes(item))
  const shouldBeHiddenTooltip = (length: number) => (length || 0) <= TRUNCATED_TEXT_APIS_LENGTH

  return (
    <>
      <StyledAccordion
        square
        elevation={0}
        disableGutters
        id='not-expand'
        expanded={isExpanded}
        {...restProps}
      >
        <StyledAccordionSummary
          direction={direction}
          expandIcon={
            direction === 'row' && (
              <ParagraphLink
                sx={{ whiteSpace: 'nowrap' }}
                marginTop='5px'
                alignSelf='flex-start'
                onClick={toggleExpanded}
              >
                {apis.length > 2 ? showButtonText : ''}
              </ParagraphLink>
            )
          }
        >
          {prunedApis.map((api, idx) => (
            <Tooltip
              key={idx}
              isPureTooltip
              maxWidth={210}
              title={api.name || ''}
              shouldBeHidden={shouldBeHiddenTooltip(api.name.length)}
            >
              <StyledBox $direction={direction}>
                <ChipApi
                  label={stringSlice(api.name, TRUNCATED_TEXT_APIS_LENGTH)}
                  type={api.type}
                />
              </StyledBox>
            </Tooltip>
          ))}
        </StyledAccordionSummary>
        <StyledAccordionDetails borderBottom={borderBottom} direction={direction}>
          {restApis.map((api, idx) => (
            <Tooltip
              key={idx}
              title={api.name || ''}
              shouldBeHidden={shouldBeHiddenTooltip(api.name.length)}
            >
              <StyledBox>
                <ChipApi
                  label={stringSlice(api.name, TRUNCATED_TEXT_APIS_LENGTH)}
                  type={api.type}
                />
              </StyledBox>
            </Tooltip>
          ))}
        </StyledAccordionDetails>
      </StyledAccordion>
      {direction === 'column' && (
        <ParagraphLink sx={{ whiteSpace: 'nowrap' }} onClick={toggleExpanded}>
          {apis.length > 2 ? showButtonText : ''}
        </ParagraphLink>
      )}
    </>
  )
}
