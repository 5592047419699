import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { addNewTowns } from '@api/samples'
import { TOWNS } from '@constants/queries'
import {
  AddressesTypes,
  PlaceForSaveType,
} from '@components/shared/sampleFields/GMPlaceAutocomplete'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useAddNewTowns = () => {
  const dispatch = useDispatch()
  const client = useQueryClient()

  const mutation = useMutation((data: PlaceForSaveType) => addNewTowns(data), {
    onMutate: () => client.getQueryData<AddressesTypes[]>(TOWNS),
    onSuccess: (newTowns, vars, prevTowns) => {
      if (prevTowns) client.setQueryData([TOWNS], [...prevTowns, newTowns])
    },
    onSettled: () => client.invalidateQueries(TOWNS),
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
