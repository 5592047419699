import React from 'react'
import { Typography } from '@mui/material'

import { stringSlice } from '@helpers/ui/common'
import { Chip, Tooltip } from '@components/shared'
import { ISampleTask } from '@models/samples'
import {
  TRUNCATED_TEXT_ASSIGNEE_NAME_L,
  TRUNCATED_TEXT_ASSIGNEE_NAME_S,
  TRUNCATED_TEXT_ASSIGNEE_ROLE,
} from '@constants/common'

import { ChipGroup, StyledBox } from './styles'

interface ViewTaskAssigneeProps {
  task: ISampleTask
}
export const ViewTaskAssignee: React.FC<ViewTaskAssigneeProps> = ({ task }) => {
  const assigneeName = `${task.assignee.first_name} ${task.assignee.last_name}`

  return (
    <StyledBox>
      <Tooltip
        isPureTooltip
        title={assigneeName || ''}
        shouldBeHidden={(assigneeName?.length || 0) <= TRUNCATED_TEXT_ASSIGNEE_NAME_S}
      >
        <Typography>{stringSlice(assigneeName, TRUNCATED_TEXT_ASSIGNEE_NAME_S)}</Typography>
      </Tooltip>

      <ChipGroup>
        {task.laboratory && (
          <Tooltip
            isPureTooltip
            title={task.laboratory.name || ''}
            shouldBeHidden={(task.laboratory.name?.length || 0) <= TRUNCATED_TEXT_ASSIGNEE_NAME_L}
          >
            <Chip
              key={task.laboratory.id}
              label={stringSlice(task.laboratory.name, TRUNCATED_TEXT_ASSIGNEE_NAME_L)}
            />
          </Tooltip>
        )}
        <Tooltip
          isPureTooltip
          title={task.assignee.role.value || ''}
          shouldBeHidden={(task.assignee.role.value?.length || 0) <= TRUNCATED_TEXT_ASSIGNEE_ROLE}
        >
          <Chip
            key={task.assignee.role.key}
            label={stringSlice(task.assignee.role.value, TRUNCATED_TEXT_ASSIGNEE_ROLE)}
          />
        </Tooltip>
      </ChipGroup>
    </StyledBox>
  )
}
