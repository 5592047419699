import { styled } from '@mui/system'
import { Icon, IconButton, Link, Typography, Box, LinkProps } from '@mui/material'
import React from 'react'

interface IFileName extends LinkProps {
  $isLink?: boolean
}

const pd = '20px'

export const WrapStyled = styled(Box)`
  position: fixed;
  right: 80px;
  bottom: 42px;
  z-index: 1000;
  overflow: hidden;
  padding: ${() => pd};
  width: 386px;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0 30px rgba(20, 50, 114, 0.149);
`

export const ContainerStyled = styled(Box)`
  overflow: hidden auto;
  width: 100%;
  padding-right: 10px;
  max-height: calc(100vh - 165px);

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #e5e9f1;
    border-radius: 72px;
    margin: 24px 0;
  }

  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #adb4c2;
  }
`

export const HeaderLabelStyled = styled(Typography)`
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 20px;
`

export const CloseBtnStyled = styled(IconButton)`
  position: absolute;
  top: 21px;
  right: 20px;
  z-index: 100;
  width: 20px;
  height: 20px;
  padding: 4px;
`

export const AttachmentIconStyled = styled(Icon)`
  margin-right: 7px;
  width: 31px;
  height: 31px;

  img {
    width: 100%;
  }
`

export const AttachmentItemStyled = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 14px 0 15px;

  &:not(:last-child) {
    &::after {
      position: absolute;
      left: ${() => `-${pd}`};
      bottom: 0;
      content: '';
      width: ${() => `calc(100% + (${pd} * 2))`};
      height: 1px;
      background: linear-gradient(
        270deg,
        rgba(227, 227, 232, 0) 0%,
        #e3e3e8 50.16%,
        rgba(227, 227, 232, 0) 100%
      );
    }
  }
`

export const FileNameStyled = styled<React.FC<IFileName>>(Link)`
  margin-right: ${() => `calc(${pd} / 1.5)`};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${({ $isLink }) => ($isLink ? '400' : '500')};
  color: ${({ theme, $isLink }) => ($isLink ? theme.palette.primary.main : '#989aa1')};
  font-size: 14px;
  text-decoration: none;
  cursor: ${({ $isLink }) => ($isLink ? 'pointer' : 'default')};
`
