import * as React from 'react'
import { useController } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useTheme } from '@mui/material'

import { setCellValueToSample } from '@state/sampleCreate'
import { IOption, IUnitsOption } from '@models/common/app'
import { PureDropdownAutocomplete } from '@components/ui'
import { unitIconSDeterminer } from '@helpers/ui/typeOfUnits'

import { PureCellPropTypes } from './pureFields.types'

export const Units: React.FC<PureCellPropTypes> = ({
  name,
  cellName,
  dropdownCellName,
  initialVal,
  inputPlaceholder,
  dropdownInitialVal,
  sampleId,
  options,
  rules,
  control,
  isCopy,
}) => {
  const dispatch = useDispatch()
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules, control, defaultValue: initialVal.val })
  const theme = useTheme()

  React.useEffect(() => {
    if (error && error.message) {
      dispatch(
        setCellValueToSample({
          cellValue: { val: field.value, error: error.message },
          cellName,
          sampleId,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const correctOptions = React.useMemo(
    () =>
      options
        ? options.map((option: IUnitsOption) => {
            const UnitIcon = unitIconSDeterminer(option.id)
            return {
              ...option,
              icon: UnitIcon as React.FC<React.SVGProps<SVGSVGElement>>,
              iconColor: theme.palette.primary.main,
            }
          })
        : [],
    [options, theme.palette.primary.main]
  )

  const onInputChange = (val: string) => {
    field.onChange({ target: { value: val } })
    dispatch(
      setCellValueToSample({
        cellValue: { val, error: '' },
        cellName,
        sampleId,
      })
    )
  }

  const onDropdownChange = (val: IOption) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val: val.id, error: '' },
        cellName: dropdownCellName as string,
        sampleId,
      })
    )
  }

  return (
    <PureDropdownAutocomplete
      isCopy={isCopy}
      name={name}
      inputPlaceholder={inputPlaceholder}
      isDropdownWithInput
      options={correctOptions}
      errorMessage={error?.message || ''}
      initialVal={dropdownInitialVal?.val as number | null}
      inputInitialVal={initialVal.val as string}
      onInputChange={onInputChange}
      onDropdownChange={onDropdownChange}
    />
  )
}
