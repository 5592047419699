import React from 'react'
import { Badge } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Dayjs } from 'dayjs'
import { v4 as uuid } from 'uuid'

import { useAppSelector } from '@hooks/redux'
import {
  clearFilter,
  editSelectedFilters,
  selectedFilters,
  selectEnteringDate,
  setStatedEnteringDate,
} from '@state/filters'
import { selectedFiltersSlice } from '@components/shared/FilterAside/constants'
import { typeOfFilter } from '@models/filters'

import * as Styled from '../styles'
import * as DatePickerStyled from './styles'
import { DateFields } from './components/DateFields'
import { Calendar } from './components/Calendar'

interface DatePickerRangeFilterProps {
  type: typeOfFilter
}

export const DatePickerRangeFilter: React.FC<DatePickerRangeFilterProps> = React.memo(
  ({ type }) => {
    const enteringDateRef = React.useRef<number>(0)
    const dispatch = useDispatch()
    const filters = useAppSelector(selectedFilters)

    const startDate = filters[selectedFiltersSlice[type]].filters[0]?.name || null
    const finishDate = filters[selectedFiltersSlice[type]].filters[1]?.name || null

    const enteringStoreDate = useAppSelector(selectEnteringDate)
    const enteringDate = React.useMemo(() => {
      if (enteringStoreDate.type === type) {
        enteringDateRef.current = enteringStoreDate.order
        return enteringStoreDate.order
      }
      return enteringDateRef.current
    }, [enteringStoreDate.order, enteringStoreDate.type, type])
    const setEnteringDate = (order: 0 | 1) => {
      dispatch(setStatedEnteringDate({ order, type }))
    }

    const [currentMonth, setCurrentMonth] = React.useState<string>(
      startDate ? startDate.slice(5, 7) : String(new Date().getMonth() + 1)
    )

    const memoizedConvertDate = React.useCallback((value: Date | Dayjs) => {
      const date = new Date(Date.parse(String(value)))
      const year = date.getFullYear()
      const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      const day = date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate()

      return `${year}-${month}-${day}`
    }, [])

    const dispatchStartDate = (date: string) => {
      dispatch(clearFilter(selectedFiltersSlice[type]))

      dispatch(
        editSelectedFilters({
          type: selectedFiltersSlice[type],
          item: {
            id: date ? 's' + String(date) : '',
            name: date ? String(date) : '',
          },
        })
      )

      dispatch(
        editSelectedFilters({
          type: selectedFiltersSlice[type],
          item: {
            id: 'f' + String(finishDate),
            name: String(finishDate),
          },
        })
      )
      setEnteringDate(1)
    }

    const dispatchFinishDate = (date: string) => {
      dispatch(clearFilter(selectedFiltersSlice[type]))

      dispatch(
        editSelectedFilters({
          type: selectedFiltersSlice[type],
          item: {
            id: 's' + String(startDate),
            name: String(startDate),
          },
        })
      )

      dispatch(
        editSelectedFilters({
          type: selectedFiltersSlice[type],
          item: {
            id: date ? 'f' + String(date) : '',
            name: date ? String(date) : '',
          },
        })
      )
    }

    const clearAllFilters = () => {
      dispatch(clearFilter(selectedFiltersSlice[type]))
      setEnteringDate(0)
    }

    const onBlurStartDate = async (event: any) => {
      const yearIsNormal =
        (startDate && +startDate.split('-')[0] > 2099) ||
        (startDate && +startDate.split('-')[0] < 1900)
      if (!startDate || yearIsNormal) {
        if (finishDate === 'null') dispatchStartDate(memoizedConvertDate(new Date()))
        else finishDate && dispatchStartDate(finishDate)
      }
    }

    const onBlurFinishDate = async (event: any) => {
      const yearIsNormal =
        (startDate && +startDate.split('-')[0] > 2099) ||
        (startDate && +startDate.split('-')[0] < 1900)
      if (!finishDate || yearIsNormal) {
        if (startDate === 'null') dispatchFinishDate(memoizedConvertDate(new Date()))
        else startDate && dispatchFinishDate(startDate)
      }
    }

    const inputStartDate = (date: Dayjs | null) => {
      const dateMLS = new Date(String(date)).getTime()
      const finishDateMLS = new Date(finishDate || '').getTime()

      if (date && String(date) !== 'Invalid Date') {
        finishDateMLS
          ? dateMLS < finishDateMLS
            ? dispatchStartDate(memoizedConvertDate(date))
            : dispatchStartDate('')
          : dispatchStartDate(memoizedConvertDate(date))
      } else dispatchStartDate('')
    }

    const inputFinishDate = (date: Dayjs | null) => {
      const dateMLS = new Date(String(date)).getTime()
      const startDateMLS = new Date(startDate || '').getTime()

      if (date && String(date) !== 'Invalid Date') {
        startDateMLS
          ? dateMLS > startDateMLS
            ? dispatchFinishDate(memoizedConvertDate(date))
            : dispatchFinishDate('')
          : dispatchFinishDate(memoizedConvertDate(date))
      } else dispatchFinishDate('')
    }

    const countOfSelectedDays = React.useMemo(
      () =>
        startDate && finishDate
          ? Math.ceil(
              Math.abs(new Date(startDate).getTime() - new Date(finishDate).getTime()) /
                (1000 * 3600 * 24)
            ) + 1 || 1
          : null,
      [startDate, finishDate]
    )

    const onMonthChange = (date: Date) => setCurrentMonth(memoizedConvertDate(date).slice(5, 7))
    const onYearChange = (date: Date) => setCurrentMonth(memoizedConvertDate(date).slice(5, 7))

    const renderDayInPicker = (date: Date) => {
      const currentTodayDate =
        new Date(Date.parse(String(date)) + 86400000).toISOString().slice(0, 10) ===
        new Date(Date.parse(String(new Date()))).toISOString().slice(0, 10)

      const convertedDate = memoizedConvertDate(date)
      const selectedDate = convertedDate === startDate || convertedDate === finishDate

      const dateMLS = new Date(date).getTime()
      const startDateMLS = new Date(startDate || '').getTime()
      const finishDateMLS = new Date(finishDate || '').getTime()

      const range = dateMLS > startDateMLS && dateMLS < finishDateMLS
      const startRange = Boolean(convertedDate === startDate && finishDate?.replace('null', ''))
      const endRange = Boolean(convertedDate === finishDate && startDate?.replace('null', ''))

      const disabled =
        enteringDate === 0
          ? dateMLS > finishDateMLS
            ? true
            : false
          : dateMLS < startDateMLS - 86400000
          ? true
          : false

      const isCurrentMonth = +currentMonth === +memoizedConvertDate(date).slice(5, 7)

      const dateHandler = () => {
        if (disabled || !isCurrentMonth) return
        if (isCurrentMonth && !enteringDate) dispatchStartDate(convertedDate)
        else dispatchFinishDate(convertedDate)
      }

      return (
        <DatePickerStyled.StyledDayItem
          key={uuid()}
          $range={isCurrentMonth && (range || startRange || endRange)}
          $startRange={isCurrentMonth && startRange}
          $endRange={isCurrentMonth && endRange}
          onClick={dateHandler}
        >
          <DatePickerStyled.StyledDayText
            $disabled={disabled || !isCurrentMonth}
            $selected={isCurrentMonth && selectedDate}
            $currentTodayDate={currentTodayDate}
          >
            {isCurrentMonth && date.getDate()}
          </DatePickerStyled.StyledDayText>
        </DatePickerStyled.StyledDayItem>
      )
    }

    return (
      <>
        <Styled.StyledCardBox sx={{ marginRight: '5px' }}>
          <Styled.FilterTitle>{filters[selectedFiltersSlice[type]].title_ui}</Styled.FilterTitle>
          <Styled.StyledBox>
            {countOfSelectedDays && countOfSelectedDays > 0 && (
              <Badge badgeContent={countOfSelectedDays} max={9999} />
            )}
            <DatePickerStyled.StyledTypography onClick={clearAllFilters}>
              Clear
            </DatePickerStyled.StyledTypography>
          </Styled.StyledBox>
        </Styled.StyledCardBox>

        <DateFields
          setEnteringDate={setEnteringDate}
          enteringDate={enteringDate}
          inputStartDate={inputStartDate}
          inputFinishDate={inputFinishDate}
          startDate={startDate}
          finishDate={finishDate}
          onBlurStartDate={onBlurStartDate}
          onBlurFinishDate={onBlurFinishDate}
        />

        <Calendar
          onYearChange={onYearChange}
          onMonthChange={onMonthChange}
          startDate={startDate}
          finishDate={finishDate}
          enteringDate={enteringDate}
          renderDayInPicker={renderDayInPicker}
        />
      </>
    )
  }
)
