import React from 'react'
import { TextField } from '@mui/material'
import { RegisterOptions, useController } from 'react-hook-form'

import { DatePicker as StyledDatePicker } from '@components/shared'

interface DatePickerProps {
  name: string
  isCopy?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  disableFuture?: boolean
  disablePast?: boolean
}

export const DatePicker: React.FC<DatePickerProps> = React.memo(props => {
  const { name, isCopy, rules, disableFuture, disablePast } = props

  const { field, fieldState } = useController({ name, rules })
  const { isTouched, error } = fieldState
  const isHighlighting = isCopy && !isTouched

  return (
    <StyledDatePicker
      height={42}
      disableFuture={disableFuture}
      disablePast={disablePast}
      onOpen={field.onBlur}
      bgColor={isHighlighting ? '#FFF5D6' : '#fff'}
      renderInput={params => (
        <TextField
          {...params}
          error={!!error}
          onBlur={field.onBlur}
          placeholder='MM/DD/YYYY'
          helperText={error?.message}
          value={params.inputProps?.value}
          inputProps={{ onChange: () => false }}
          disabled
          sx={{
            '& .MuiOutlinedInput-root.Mui-disabled fieldset': {
              borderColor: error ? '#AF4645' : '',
            },
          }}
        />
      )}
      {...field}
    />
  )
})
