import React from 'react'
import { Box, Collapse } from '@mui/material'

import { IHrTaskLab } from '@models/tasks'
import { useToggle } from '@hooks/common'
import { MobileDetailsInfoCard } from '@components/shared'
import { StyledBoxMobile } from '@styles'

import { Info } from '../Info'

interface InfoProps {
  task: IHrTaskLab
}

export const InfoMobile: React.FC<InfoProps> = ({ task }) => {
  const [openMainInfo, setIsOpenMainInfo] = useToggle()

  const cards = [
    {
      title: 'Main info section',
      component: Info,
      task,
      open: openMainInfo,
      setIsOpen: setIsOpenMainInfo,
    },
  ]

  return (
    <>
      {cards.map(({ title, task, open, setIsOpen, component: Component }) => (
        <StyledBoxMobile key={title} mb='0'>
          <MobileDetailsInfoCard title={title} open={open} setIsOpen={setIsOpen} textEndPosition />

          <Box>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Component task={task} />
            </Collapse>
          </Box>
        </StyledBoxMobile>
      ))}
    </>
  )
}
