import React from 'react'

import { INewSamples } from '@models/samples'
import { SAMPLE_CREATION } from '@constants/samples'

import { TableRow } from '../TableRow'

interface TableBodyProps {
  samples: Record<'id', string>[]
}

export const TableBody: React.FC<TableBodyProps> = React.memo(({ samples }) => {
  return (
    <>
      {(samples as INewSamples).map(({ id, isCopy }, idx) => {
        const counterOfSamples = samples.length - idx

        return (
          <TableRow
            key={id}
            sampleIdx={idx}
            baseName={`${SAMPLE_CREATION}.${idx}.`}
            counter={counterOfSamples}
            isCopy={isCopy}
            isLastSample={counterOfSamples === 1}
          />
        )
      })}
    </>
  )
})
