import React from 'react'

import { StyledActionsStickyBox, StyledFilterBox } from '@styles'
import { useAppSelector } from '@hooks/redux'
import { selectedFilters as filters, selectedFiltersLength } from '@state/filters'
import {
  ClearAllFilters,
  FilterAsideButton,
  SetParamsOfFilter,
  ViewSelectedFilters,
} from '@components/shared/FilterAside'
import {
  APIS,
  ASSIGNEES,
  DRUG_CLASSES,
  EXCIPIENTS,
  LABORATORIES_FILTER,
  MANUFACTURERS,
  TASK_RESULT,
  TESTING_DATE,
} from '@components/shared/FilterAside/constants'
import { TEST_TYPES } from '@constants/queries'

interface TasksFiltersProps {
  isPageHasSavedFilters: boolean
}

export const ReportsFiltersView: React.FC<TasksFiltersProps> = props => {
  const { isPageHasSavedFilters } = props

  const {
    appliedApisSelectedFilters,
    appliedExcipientsSelectedFilters,
    appliedAssigneesSelectedFilters,
    appliedDrugClassesSelectedFilters,
    appliedManufacturersSelectedFilters,
    appliedTestingDateSelectedFilters,
    appliedLaboratoriesSelectedFilters,
    appliedTaskResultSelectedFilters,
    appliedTestTypeSelectedFilters,
  } = useAppSelector(filters)
  const allFiltersLength = useAppSelector(selectedFiltersLength)
  const isShouldShowClearAll = Boolean(allFiltersLength)

  return (
    <>
      <SetParamsOfFilter
        isPageHasSavedFilters={isPageHasSavedFilters}
        appliedApisSelectedFilters={appliedApisSelectedFilters}
        appliedExcipientsSelectedFilters={appliedExcipientsSelectedFilters}
        appliedAssigneesSelectedFilters={appliedAssigneesSelectedFilters}
        appliedDrugClassesSelectedFilters={appliedDrugClassesSelectedFilters}
        appliedManufacturersSelectedFilters={appliedManufacturersSelectedFilters}
        appliedTestingDateSelectedFilters={appliedTestingDateSelectedFilters}
        appliedLaboratoriesSelectedFilters={appliedLaboratoriesSelectedFilters}
        appliedTaskResultSelectedFilters={appliedTaskResultSelectedFilters}
        appliedTestTypeSelectedFilters={appliedTestTypeSelectedFilters}
      />

      <StyledActionsStickyBox>
        <StyledFilterBox>
          <FilterAsideButton />
          {!!appliedLaboratoriesSelectedFilters.length && (
            <ViewSelectedFilters type={LABORATORIES_FILTER} testReportsPage />
          )}
          {!!appliedTaskResultSelectedFilters.length && <ViewSelectedFilters type={TASK_RESULT} />}
          {!!appliedTestTypeSelectedFilters.length && <ViewSelectedFilters type={TEST_TYPES} />}

          {!!appliedApisSelectedFilters.length && <ViewSelectedFilters type={APIS} />}
          {!!appliedExcipientsSelectedFilters.length && <ViewSelectedFilters type={EXCIPIENTS} />}
          {!!appliedAssigneesSelectedFilters.length && <ViewSelectedFilters type={ASSIGNEES} />}
          {!!appliedDrugClassesSelectedFilters.length && (
            <ViewSelectedFilters type={DRUG_CLASSES} />
          )}
          {!!appliedManufacturersSelectedFilters.length && (
            <ViewSelectedFilters type={MANUFACTURERS} />
          )}
          {!!appliedTestingDateSelectedFilters.length && (
            <ViewSelectedFilters type={TESTING_DATE} />
          )}

          {isShouldShowClearAll && <ClearAllFilters />}
        </StyledFilterBox>
      </StyledActionsStickyBox>
    </>
  )
}
