import { TableCell, Typography } from '@mui/material'

import { ISampleTasksTable } from '@models/samples'
import { ContentType } from '@models/common/ui'
import { useAppSelector } from '@hooks/redux'
import { selectUserCustomizeTableColumns } from '@state/user'
import { SAMPLES } from '@constants/queries'
import { viewAdditionalTasksWidths } from '@constants/mappings'
import { ITableColumnWidths } from '@models/common/user'

import { StyledTableHead } from './styles'
import { FixedSampleTaskCell, StyledRow } from '../styles'

interface ExpandedTableHeadProps {
  contentType: ContentType
  headerContent: ISampleTasksTable
}

export const ExpandedTableHead: React.FC<ExpandedTableHeadProps> = props => {
  const { headerContent, contentType } = props

  const { task_columns } = useAppSelector(selectUserCustomizeTableColumns)

  const tasksColumns =
    contentType === SAMPLES
      ? [
          { id: 0, content: 'Task', activated: true },
          ...task_columns
            .filter(taskColumn => taskColumn.activated)
            .map(taskColumn => ({
              id: taskColumn.id,
              content: taskColumn.name,
            })),
        ]
      : headerContent.tableHeadCells

  const filteredSampleTableWidths = task_columns
    .filter(taskColumn => taskColumn.activated)
    .map(column =>
      viewAdditionalTasksWidths.find(cell => cell.id === column.id)
    ) as ITableColumnWidths

  return (
    <StyledTableHead $contentType={contentType}>
      <StyledRow columns={filteredSampleTableWidths} contentType={contentType}>
        {tasksColumns.map(({ id, content }) => (
          <TableCell key={id} sx={{ paddingLeft: '0' }}>
            <Typography variant='h5'>{content}</Typography>
          </TableCell>
        ))}
        <TableCell></TableCell>

        <FixedSampleTaskCell
          sx={{
            borderBottom: 'none',
            boxShadow: '-5px 9px 20px -5px rgba(53, 108, 151, 0.12)',
          }}
        ></FixedSampleTaskCell>
      </StyledRow>
    </StyledTableHead>
  )
}
