import { styled, Typography, Box } from '@mui/material'

export const StyledWrapper = styled(Box)`
  & .MuiLoadingButton-root {
    padding: 10px 24px 10px 28px;

    &:hover {
      transition: none;
      background-color: ${({ theme }) => theme.palette.grey[100]};
    }
  }
`

export const NotFoundLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.light};
  margin: 0 24px 5px 24px;
`

export const StyledDescription = styled(Typography)`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
`
