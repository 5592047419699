import { Box, BoxProps, Button, ButtonProps, styled } from '@mui/material'

export interface StyledSwitchButtonProps extends ButtonProps {
  $buttonWidth?: string
  $active?: boolean
}

export interface StyledSwitchBoxProps extends BoxProps {
  $isEditLabInfoModal?: boolean
}

export const StyledSwitchBox = styled<React.FC<StyledSwitchBoxProps>>(Box)`
  display: ${({ $isEditLabInfoModal }) => ($isEditLabInfoModal ? 'flex' : 'block')};
  width: ${({ $isEditLabInfoModal }) => ($isEditLabInfoModal ? '314px' : '111')};
  padding: 4px;
  background: #ffffff;
  border: 1px solid #e1e7f3;
  border-radius: 71px;
  z-index: 400;
`

export const StyledSwitchButton = styled<React.FC<StyledSwitchButtonProps>>(Button)`
  padding: 6px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 71px;
  background-color: ${({ theme, $active }) => ($active ? theme.palette.primary.main : '#ffffff')};
  color: ${({ $active }) => ($active ? '#ffffff' : '#8F929B')};

  &:hover {
    background-color: ${({ theme, $active }) => ($active ? theme.palette.primary.main : '#E1E7F3')};
    color: ${({ $active }) => ($active ? '#ffffff' : '#000')};
  }

  width: ${({ $buttonWidth }) => $buttonWidth};
  &.Mui-disabled {
    background-color: transparent;
  }
`
