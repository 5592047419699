import { UserRole } from '@models/common/user'
import { ILaboratory, IBreadcrumbsRoute } from '@models/common/app'
import { protectedRoutes, publicRoutes } from '@configs/routes.config'
import { IAccessesByUserLaboratory, IProtectedRoute, IPublicRoute } from '@models/common/routes'

export const getProtectedRoutes = (): IProtectedRoute[] => {
  return Object.entries(protectedRoutes).map(([title, data]) => data)
}

const hasAccess = (
  accesses: (UserRole | IAccessesByUserLaboratory)[],
  userRole: UserRole,
  userLaboratory: ILaboratory
) => {
  let denyAccess = false

  return !!accesses.find(access => {
    if (denyAccess) return false

    if (typeof access === 'object') {
      denyAccess = true

      if (access.createSamples) return userLaboratory?.group?.sample_creator
    }

    return access === userRole
  })
}

export const getAllowedRoutes = (
  routes: IProtectedRoute[],
  userRole: UserRole,
  userLaboratory: ILaboratory
): IProtectedRoute[] => {
  return routes.filter(({ path, access }) => {
    // Disable testing reports for some laboratories
    if (path === protectedRoutes.testingReports.path)
      return userLaboratory?.group?.manage_test_reports

    return !access.length || hasAccess(access, userRole, userLaboratory)
  })
}

export const getPublicRoutes = (): IPublicRoute[] => {
  return Object.entries(publicRoutes).map(([title, data]) => ({
    ...data,
    path: data.path,
  }))
}

const getRoutePaths = (type: 'public' | 'protected', protectedRoutes: IProtectedRoute[] = []) => {
  const routes = type === 'public' ? getPublicRoutes() : protectedRoutes

  return routes
    .map(route => route.path)
    .reduce<string[]>((acc, path) => [...acc, path, `${path}/`], [])
}

export const getPublicPaths = (): string[] => getRoutePaths('public')

export const getProtectedPaths = (availableRoutes: IProtectedRoute[]): string[] => {
  return getRoutePaths('protected', availableRoutes)
}

//breadcrumbs

export const isContainRoute = (state: IBreadcrumbsRoute[], route: string): boolean =>
  state.some(({ url }) => url === route)

export const removeRemainingCrumbs = (state: IBreadcrumbsRoute[], url: string) => {
  const indexOfRout = state.findIndex(({ url: route }) => route === url)
  return state.slice(0, indexOfRout)
}
