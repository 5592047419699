import React from 'react'
import { Typography } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { StyledDetailsTableCellMobile } from '@styles'

interface ApiContentMobileProps {
  task?: ITaskLab
}

export const ApiContentMobile: React.FC<ApiContentMobileProps> = ({ task }) => {
  const apis = task?.main_apis

  return (
    <StyledDetailsTableCellMobile>
      {apis &&
        apis.map(api => (
          <React.Fragment key={api.id}>
            <Typography>{api.name}</Typography>
            <Typography variant='body2' color='#616d84' mb={0.5}>
              {(api.percentage && `${parseFloat(api.percentage)}%`) || '-'}
            </Typography>
          </React.Fragment>
        ))}
    </StyledDetailsTableCellMobile>
  )
}
