import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useController } from 'react-hook-form'

import { IOption } from '@models/common/app'
import { setCellValueToSample } from '@state/sampleCreate'
import { PureDropdownAutocomplete } from '@components/ui'
import { IOptionExtended } from '@components/ui/PureDropdownAutocomplete/pureDropdownAutocomplete.types'
import { useAddNewBrand, useAddNewPharmacy } from '@hooks/queries'
import { BRAND, PHARMACY } from '@constants/samples'

import { PureCellPropTypes } from './pureFields.types'

export const CommonAutocomplete: React.FC<PureCellPropTypes> = ({
  name,
  control,
  rules,
  initialVal,
  cellName,
  sampleId,
  options,
  isCopy,
}) => {
  const dispatch = useDispatch()
  const AddNewBrandM = useAddNewBrand()
  const AddNewPharmacyM = useAddNewPharmacy()

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: initialVal.val })

  const setCellValue = (val: IOptionExtended, error: string) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val, error },
        cellName,
        sampleId,
      })
    )
  }

  const onDropdownChange = (val: IOptionExtended) => {
    field.onChange({ target: { value: val } })
    setCellValue(val, '')
  }

  React.useEffect(() => {
    if (!!error && error.message) setCellValue(field.value, error.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onAddNewItem = (val: string) => {
    if (name.includes(PHARMACY)) {
      AddNewPharmacyM.mutate(val, { onSuccess: data => setCellValue(data, '') })
    } else if (name.includes(BRAND)) {
      AddNewBrandM.mutate(val, { onSuccess: data => setCellValue(data, '') })
    }
  }

  const isPharmacyOrBrand = name.includes(PHARMACY) || name.includes(BRAND)

  return (
    <PureDropdownAutocomplete
      isCopy={isCopy}
      isLoading={AddNewPharmacyM.isLoading || AddNewBrandM.isLoading || false}
      initialVal={initialVal.val as IOption | null}
      name={name}
      options={(options as IOption[]) || []}
      errorMessage={error?.message || ''}
      inputChangeValidation={val => field.onChange({ target: { value: val } })}
      onDropdownChange={onDropdownChange}
      onAddNewItem={isPharmacyOrBrand ? onAddNewItem : undefined}
    />
  )
}
