import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'

import { IOptionData } from '@models/projects'
import { fetchProjectsF } from '@api/projects'
import { IError } from '@models/common/app'

export const useFetchProjectsFilters = (
  value: string | undefined
): UseInfiniteQueryResult<IOptionData, IError> => {
  return useInfiniteQuery(['projects', value], fetchProjectsF, {
    getNextPageParam: lastPage => {
      if (lastPage.current_page < lastPage.count_pages) return lastPage.current_page + 1
      return false
    },
  })
}
