import ContentLoader from 'react-content-loader'

export const FilterLoader: React.FC = props => (
  <ContentLoader
    speed={2}
    width={264}
    height={266}
    viewBox='0 0 264 266'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    {...props}
  >
    <rect x='36' y='23' rx='7' ry='7' width='62' height='14' />
    <rect x='25' y='70' rx='5' ry='5' width='19' height='19' />
    <rect x='52' y='69' rx='7' ry='7' width='89' height='14' />
    <rect x='25' y='108' rx='5' ry='5' width='19' height='19' />
    <rect x='52' y='107' rx='7' ry='7' width='89' height='14' />
    <rect x='25' y='146' rx='5' ry='5' width='19' height='19' />
    <rect x='52' y='145' rx='7' ry='7' width='89' height='14' />
    <rect x='25' y='184' rx='5' ry='5' width='19' height='19' />
    <rect x='52' y='183' rx='7' ry='7' width='89' height='14' />
    <rect x='25' y='222' rx='5' ry='5' width='19' height='19' />
    <rect x='25' y='222' rx='5' ry='5' width='19' height='19' />
    <rect x='52' y='221' rx='7' ry='7' width='89' height='14' />
    <rect x='52' y='221' rx='7' ry='7' width='89' height='14' />
  </ContentLoader>
)
