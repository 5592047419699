import React from 'react'
import { useHistory } from 'react-router-dom'

import { IHRSample } from '@models/samples'
import { protectedRoutes } from '@configs/routes.config'
import { ParagraphLink } from '@components/shared'
import { DETAILS } from '@constants/common'

import { StyledTableCell } from '../styles'

interface PackageIDProps {
  sample: IHRSample
}

export const PackageID: React.FC<PackageIDProps> = ({ sample }) => {
  const history = useHistory()

  const packageInfoEndpoint = () => {
    history.replace({
      pathname: `${protectedRoutes.packages.path}/${sample.package?.id}${DETAILS}`,
      state: [{ path: '/', url: '/', title: 'Sample list' }],
    })
  }

  return (
    <StyledTableCell>
      {sample.package ? (
        <ParagraphLink onClick={packageInfoEndpoint} id='not-expand'>
          {sample.package.specific_id}
        </ParagraphLink>
      ) : (
        '-'
      )}
    </StyledTableCell>
  )
}
