import * as React from 'react'
import { useController } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { PureInput } from '@components/ui/PureInput'
import { setCellValueToSample } from '@state/sampleCreate'

import { PureCellPropTypes } from './pureFields.types'

export const CommonInput: React.FC<PureCellPropTypes> = ({
  control,
  sampleId,
  cellName,
  type,
  name,
  rules,
  initialVal,
  isCopy,
  rows,
  inputPlaceholder,
}) => {
  const dispatch = useDispatch()

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: initialVal.val || '' })

  const setCellValue = (val: string, error: string) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val, error },
        cellName,
        sampleId,
      })
    )
  }

  React.useEffect(() => {
    if (!!error && error.message) setCellValue(field.value, error.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onInputChange = (value: string) => {
    const errorMsg = !!error && error.message ? error.message : ''
    field.onChange({ target: { value } })
    setCellValue(value, errorMsg)
  }

  return (
    <PureInput
      isCopy={isCopy}
      name={name}
      onInputChange={onInputChange}
      initialVal={initialVal.val as string}
      type={type}
      rows={rows}
      placeholder={inputPlaceholder}
      errorMessage={error?.message || ''}
    />
  )
}
