import React from 'react'
import { Typography, useMediaQuery } from '@mui/material'

import { DetailsStyledRow } from '@styles'

export const Header = () => {
  const isMobile = useMediaQuery('(max-width:767px)')

  return (
    <DetailsStyledRow>
      <Typography
        variant={isMobile ? 'subtitle1' : 'h3'}
        sx={{ marginBottom: isMobile ? '16px' : '28px' }}
      >
        Task info
      </Typography>
    </DetailsStyledRow>
  )
}
