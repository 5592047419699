import React from 'react'

import { HrTestMethod } from '@models/tasks'
import { PureTooltip } from '@components/ui/PureTooltip'
import { MAX_TASKS_TO_ADD } from '@constants/tasks'
import { Checkbox } from '@components/shared'

import { TandemMSSelectMethodProps } from './tandemMS.types'
import { DrugsModal } from './DrugsModal'
import * as Styled from './styles'
import { isOtherMethod } from './helpers'

export const TandemMSSelectMethod: React.FC<TandemMSSelectMethodProps> = ({
  element,
  methods = [],
  setValues,
  isMaxValSet,
}) => {
  const [method, setMethod] = React.useState<HrTestMethod | null>(null)
  const onClose = () => setMethod(null)
  const [checkedMethods, setCheckedMethods] = React.useState<number[]>([])
  const checkInitialValuesRef = React.useRef<boolean>(false)

  const checkboxOnClick = (id: number) => {
    if (checkedMethods.includes(id)) setCheckedMethods(checkedMethods.filter(el => el !== id))
    else setCheckedMethods([...checkedMethods, id])
  }

  React.useEffect(() => {
    if (checkInitialValuesRef.current === false) {
    } else {
      if (!!setValues)
        setValues({ id: element.id, methods: checkedMethods, count: checkedMethods.length || 1 })
    }
    checkInitialValuesRef.current = true
  }, [checkedMethods]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div style={{ color: '#8f929b', fontSize: '14px', lineHeight: '20px' }}>
        Select Method(s) or leave this choice to Lab Assistant
      </div>
      {methods?.map(method => {
        return (
          <p key={method.id}>
            <PureTooltip
              title={`Maximum of ${MAX_TASKS_TO_ADD} tests can be run for a Sample.`}
              titleStyle={{ width: 170 }}
              shouldBeHidden={
                !(isMaxValSet && !checkedMethods.includes(method.id) && checkedMethods.length)
              }
            >
              <Checkbox
                disabled={
                  !!checkedMethods.length && !checkedMethods.includes(method.id) && isMaxValSet
                }
                sx={{ marginRight: '6px' }}
                checked={checkedMethods.includes(method.id)}
                onChange={() => checkboxOnClick(method.id)}
              />
            </PureTooltip>
            {method.name}

            {isOtherMethod(method.name) ? null : (
              <span style={{ color: '#5e91f7' }}>
                {' '}
                (
                <Styled.TextLink onClick={() => setMethod(method)}>
                  {method.description}
                </Styled.TextLink>
                )
              </span>
            )}
          </p>
        )
      })}
      <DrugsModal method={method} open={!!method} onClose={onClose} />
    </>
  )
}
