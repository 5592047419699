import InfiniteScroll from 'react-infinite-scroller'
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query'
import React from 'react'
import { Box } from '@mui/material'

import { FullPageError, NoSearchedParams } from '@components/shared'
import { IError, ILaboratory, ILaboratoryData } from '@models/common/app'

import { Table } from './Table'
import { TableLoader } from './Table/TableLoader'

interface OtherLaboratoriesProps {
  laboratories?: ILaboratory[]
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<ILaboratoryData, IError>>
  isLoading?: boolean
  isError?: boolean
}

export const OtherLaboratories: React.FC<OtherLaboratoriesProps> = props => {
  const { laboratories, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading, isError } = props

  if (isLoading) return <TableLoader />

  if (isError) {
    return (
      <FullPageError errorTextTitle='Something went wrong with laboratories loading' pt='20px' />
    )
  }

  if (!laboratories?.length) {
    return (
      <Box display='flex' flexDirection='column' alignItems='center'>
        <NoSearchedParams text='No laboratories or managers with such parameters' />
      </Box>
    )
  }

  return (
    <InfiniteScroll hasMore={hasNextPage} loadMore={fetchNextPage as (page: number) => void}>
      <Table laboratories={laboratories} isFetchingNextPage={isFetchingNextPage} />
    </InfiniteScroll>
  )
}
