import { useQuery } from 'react-query'

import { toggleFlag } from '@state/app'
import { useAppDispatch } from '@hooks/redux'
import { checkTokenToRecoverPass } from '@api/user'
import { VERIFY_PASSWORD_RECOVERY_TOKEN } from '@constants/queries'
import { IConfirmationDataToRecoverPass } from '@models/common/user'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useCheckToken = (params: IConfirmationDataToRecoverPass) => {
  const dispatch = useAppDispatch()

  return useQuery(VERIFY_PASSWORD_RECOVERY_TOKEN, checkTokenToRecoverPass.bind(null, params), {
    onSuccess: data => {
      if (!data.result) {
        dispatch(toggleFlag('isPasswordRecoveryTokenInvalid'))
      }
    },
    onError: (error: IError) => {
      if (error.status === 400) dispatch(toggleFlag('isPasswordRecoveryTokenInvalid'))
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    retry: 1,
    retryDelay: 1500,
  })
}
