import React from 'react'
import { useQueryParams, StringParam } from 'use-query-params'
import { useMediaQuery } from '@mui/material'
import { UseQueryResult } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '@hooks/redux'
import { selectUserLaboratory } from '@state/user'
import { getActionBtnInfo } from '@helpers/ui/samples'
import { protectedRoutes } from '@configs/routes.config'
import { FullPageError, InfoBoxTemperature, SamplesHeader } from '@components/shared'
import { MAX_SELECTED_PACKAGE_NUMBER } from '@constants/packages'
import { useFetchSamplesInsidePackage } from '@hooks/queries'
import { StyledActionsStickyBox } from '@styles'
import { IPackage } from '@models/packages'
import { editingNotesFieldId, isEditingNotes } from '@state/samples'
import { infoBoxText } from '@components/shared/InfoBoxes/InfoBoxTemperature'
import { ISamples } from '@models/samples'

import { PackageInfo, TableSamplesInside } from './components'
import { PackageDetailsMobile } from '../PackageDetailsMobile'

interface PackageDetailsContentProps {
  packageId: string
  fetchPackageQ: UseQueryResult<IPackage, unknown>
  generateXLS?: () => void
}

export const PackageDetailsContent: React.FC<PackageDetailsContentProps> = ({
  fetchPackageQ,
  packageId,
  generateXLS,
}) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const history = useHistory()
  const dispatch = useDispatch()

  const [queryString] = useQueryParams({
    desired_samples_inside_package: StringParam,
  })

  const fetchSamplesInsideQ = useFetchSamplesInsidePackage<ISamples>(packageId)
  const { id: currentUserLaboratoryId } = useAppSelector(selectUserLaboratory)
  const packageStatus = fetchPackageQ.data?.delivery_status.key
  const isCurrentUserRecipient = fetchPackageQ.data?.destination?.id === currentUserLaboratoryId
  const { actionBtnText, shouldDisplayActionBtn } = getActionBtnInfo(
    packageStatus,
    isCurrentUserRecipient
  )
  const samples = fetchSamplesInsideQ.data?.pages.map(page => [...page.results]).flat()
  const totalSamples = fetchSamplesInsideQ.data?.pages[0]?.count
  const isLoading = fetchSamplesInsideQ.isLoading

  const hasTableSampleNextPage = fetchSamplesInsideQ.hasNextPage
  const fetchTableSampleNextPage = fetchSamplesInsideQ.fetchNextPage as (page: number) => void
  const isFetchingTableSampleNextPage = fetchSamplesInsideQ.isFetchingNextPage
  const shouldHiddenActionBtn = packageStatus !== 'not_sent' || fetchSamplesInsideQ.isLoading
  const shouldDisableActionBtn = !!totalSamples && totalSamples >= MAX_SELECTED_PACKAGE_NUMBER
  const isPackageStatusNotSent = packageStatus === 'not_sent'
  const tooltipTitle = shouldDisableActionBtn ? 'Maximum number of samples per package 200' : ''

  const refetchSample = () => fetchPackageQ.refetch()

  React.useEffect(() => {
    if (fetchSamplesInsideQ.isFetching) {
      dispatch(isEditingNotes(true))
    } else if (fetchSamplesInsideQ.isFetched) {
      dispatch(isEditingNotes(false))
      dispatch(editingNotesFieldId(null))
    }
  }, [dispatch, fetchSamplesInsideQ.isFetched, fetchSamplesInsideQ.isFetching])

  if (fetchPackageQ.isError) {
    return (
      <FullPageError
        errorTextTitle={`Something went wrong with package loading`}
        btnText='Refresh'
        onBtnClick={refetchSample}
        pt='40px'
      />
    )
  }

  const addSamplesEndpoint = {
    pathname: protectedRoutes.addSamples.path.replace(':packageId', `${packageId}`),
    state: history.location.state,
  }

  return (
    <>
      {isMobile ? (
        <PackageDetailsMobile
          packagesData={fetchPackageQ.data}
          samples={samples}
          isLoadingTable={isLoading}
          isLoadingPackageDetails={fetchPackageQ.isLoading}
          actionBtnText={actionBtnText}
          shouldDisplayMissedText={isCurrentUserRecipient}
          shouldDisplayActionBtn={shouldDisplayActionBtn}
          hasNextPage={hasTableSampleNextPage}
          fetchNextPage={fetchTableSampleNextPage}
          isFetchingNextPage={isFetchingTableSampleNextPage}
          isMobile={isMobile}
          isPackageStatusNotSent={isPackageStatusNotSent}
          generateXLS={generateXLS}
          shouldHiddenActionBtn={shouldHiddenActionBtn}
        />
      ) : (
        <>
          {fetchPackageQ.data && (
            <>
              {(fetchPackageQ.data.toxicity_level || fetchPackageQ.data.min_temperature) && (
                <InfoBoxTemperature
                  toxicityText={fetchPackageQ.data.toxicity_level && infoBoxText.toxicity.package}
                  temperatureText={
                    fetchPackageQ.data.min_temperature && infoBoxText.temperature.package
                  }
                />
              )}
              <PackageInfo data={fetchPackageQ.data} />
            </>
          )}
          <StyledActionsStickyBox>
            <SamplesHeader
              variant='h3'
              searchInsidePackage={true}
              buttonText='Add samples'
              titleText='Samples inside'
              btnLink={addSamplesEndpoint}
              shouldHiddenSearchField={
                !samples?.length && !queryString.desired_samples_inside_package
              }
              tooltipTitle={tooltipTitle}
              shouldHiddenActionBtn={shouldHiddenActionBtn}
              shouldDisableActionBtn={shouldDisableActionBtn}
              generateXLS={generateXLS}
              generateXLSButtonIsDisabled={Boolean(
                fetchPackageQ.data?.samples && fetchPackageQ.data?.samples.length > 0
              )}
            />
          </StyledActionsStickyBox>
          <TableSamplesInside
            samples={samples}
            isLoading={isLoading}
            actionBtnText={actionBtnText}
            shouldDisplayMissedText={isCurrentUserRecipient}
            shouldDisplayActionBtn={shouldDisplayActionBtn}
            hasNextPage={hasTableSampleNextPage}
            fetchNextPage={fetchTableSampleNextPage}
            isFetchingNextPage={isFetchingTableSampleNextPage}
            packagesData={fetchPackageQ.data}
          />
        </>
      )}
    </>
  )
}
