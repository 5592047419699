import React from 'react'
import { Icon, Typography, useTheme } from '@mui/material'

import {
  ExpandableApis,
  ExpandableExcipients,
  TemperatureSensitiveToxicityFields,
} from '@components/shared'
import { getApisWithType } from '@helpers/ui/common'
import { StyledTitle, StyledDetailsLabel, StyledParagraph, SubText } from '@styles'
import { ITaskLab } from '@models/tasks'
import { unitIconSDeterminer } from '@helpers/ui/typeOfUnits'
import { IFunding } from '@models/projects'

import { Loader } from './components'

interface MainInfoProps {
  task?: ITaskLab
  isLoading: boolean
}

export const MainInfo: React.FC<MainInfoProps> = ({ isLoading, task }) => {
  const { mainApisWithType } = getApisWithType({ main_apis: task?.main_apis })
  const theme = useTheme()

  const UnitIconType = unitIconSDeterminer(task?.unit_type.id || 1)

  if (isLoading) return <Loader />

  return (
    <div>
      <StyledTitle mb={'32px'} variant='h3' fz={24}>
        Main info
      </StyledTitle>

      <StyledDetailsLabel variant='h5'>Drug class</StyledDetailsLabel>
      <StyledParagraph variant='body1'> {task?.drug_class.name || '-'} </StyledParagraph>

      <StyledDetailsLabel variant='h5'>Dosage</StyledDetailsLabel>
      <StyledParagraph variant='body1'>
        {task?.dosage ? parseFloat(task?.dosage) : ''}
        <SubText sx={{ marginLeft: '6px', fontSize: 14 }}>
          {task?.dosage_type?.name || ''}
        </SubText>{' '}
      </StyledParagraph>

      <StyledDetailsLabel variant='h5'>Units</StyledDetailsLabel>
      <StyledParagraph sx={{ display: 'flex' }} variant='body1'>
        {task?.units ? task?.units : '-'}
        {task?.unit_type.id && (
          <Icon sx={{ marginLeft: '9px' }}>
            <UnitIconType fill={theme.palette.primary.main} stroke={theme.palette.primary.main} />
          </Icon>
        )}
        <Typography
          component='span'
          marginLeft='8px'
          variant='body1'
          color='secondary.light'
          fontSize='14px'
        >
          {task?.unit_type.name.toLocaleLowerCase()} {task?.unit_type.description}
        </Typography>
      </StyledParagraph>

      <StyledDetailsLabel variant='h5'>Main APIs</StyledDetailsLabel>
      <ExpandableApis bgColor='transparent' borderBottom='none' apis={mainApisWithType} />

      <StyledDetailsLabel variant='h5' sx={{ marginTop: '24px' }}>
        Excipients
      </StyledDetailsLabel>
      {task && task.excipients.length ? (
        <ExpandableExcipients
          bgColor='transparent'
          borderBottom='none'
          excipients={task.excipients}
        />
      ) : (
        '-'
      )}

      <TemperatureSensitiveToxicityFields
        toxicityLevelValue={task?.toxicity_level?.value}
        storageNotes={task?.storage_notes}
        minTemperature={task?.min_temperature}
        maxTemperature={task?.max_temperature}
        temperatureMeasurementFormatKey={task?.temperature_measurement_format?.key}
        mb='24px'
      />

      <StyledDetailsLabel variant='h5' sx={{ marginTop: '24px' }}>
        Regulatory regist. status
      </StyledDetailsLabel>
      <StyledParagraph variant='body1'>
        {task?.regulatory_registration_status.name || '-'}
      </StyledParagraph>

      <StyledDetailsLabel variant='h5'>Project</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{task?.project?.name || '-'}</StyledParagraph>

      <StyledDetailsLabel variant='h5'>Funding</StyledDetailsLabel>
      <StyledParagraph variant='body1'>
        {task?.funding?.map((fund: IFunding) => fund.value).join(', ') || '-'}
      </StyledParagraph>
    </div>
  )
}
