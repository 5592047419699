import * as React from 'react'
import Box from '@mui/material/Box'
import { StringParam, useQueryParams } from 'use-query-params'

import { IPackage } from '@models/packages'
import { FullPageError, MobileCardLoader } from '@components/shared'
import { ISamples } from '@models/samples'
import { NoFilteredSamples } from '@components/modules/Samples/SampleTabs/NoSamples'

import { MobileRow } from './MobileRow'

interface MobileTableProps {
  samples?: ISamples
  isMobile: boolean
  packagesData?: IPackage
  actionBtnText?: 'Remove' | 'Missed'
  isLoadingTable?: boolean
  isFetchingNextPage?: boolean
  shouldDisplayActionBtn?: boolean
  shouldDisplayMissedText?: boolean
}
const renderRowsLoader = () =>
  new Array(10).fill('').map((_, idx) => <MobileCardLoader key={idx} />)

export const MobileTable: React.FC<MobileTableProps> = props => {
  const {
    samples,
    isMobile,
    actionBtnText,
    isLoadingTable,
    isFetchingNextPage,
    shouldDisplayActionBtn,
    shouldDisplayMissedText,
  } = props

  const [queryString] = useQueryParams({
    desired_samples_inside_package: StringParam,
  })

  if (isLoadingTable) return <MobileCardLoader />

  if (!samples?.length && queryString.desired_samples_inside_package) return <NoFilteredSamples />

  if (!samples?.length) {
    return (
      <FullPageError
        imgVariant='common'
        errorTextTitle={`There are no Samples added yet`}
        errorText='Get started by adding samples'
        pt='20px'
        mb={1}
      />
    )
  }

  return (
    <>
      <Box>
        {samples?.map(sample => (
          <MobileRow
            key={sample.id}
            sample={sample}
            isMobile={isMobile}
            actionBtnText={actionBtnText}
            shouldDisplayActionBtn={shouldDisplayActionBtn}
            shouldDisplayMissedText={shouldDisplayMissedText}
          />
        ))}
      </Box>

      {isFetchingNextPage && renderRowsLoader()}
    </>
  )
}
