import { useMutation } from 'react-query'

import { toggleFlag } from '@state/app'
import { confirmPassword } from '@api/user'
import { IError } from '@models/common/app'
import { useAppDispatch } from '@hooks/redux'
import { HttpErrors } from '@enums/httpErrors'
import { IConfirmRecoverPass } from '@models/common/forms'
import { setMaintenanceModePage } from '@state/user'
import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'

export const useConfirmPassword = () => {
  const dispatch = useAppDispatch()

  const mutation = useMutation<unknown, IError, IConfirmRecoverPass>(
    confirmationData => confirmPassword(confirmationData),
    {
      onSuccess: () => {
        dispatch(toggleFlag('isChangePasswordSuccessfully'))
      },
      onError: err => {
        if (err.status === 400) showNotification(notifications.taskFinishedFailed)
        if (err.status === 503) dispatch(setMaintenanceModePage(true))
        if (err.status !== HttpErrors.validation) dispatch(toggleFlag('isChangePasswordFailed'))
      },
    }
  )

  return mutation
}
