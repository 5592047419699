import { Box, Typography } from '@mui/material'
import { useQueryClient } from 'react-query'

import { SkillsField } from '@components/shared'
import { useAppSelector } from '@hooks/redux'
import { workSpaceInfo } from '@state/workSpace'
import { LABORATORIES_FILTER } from '@components/shared/FilterAside/constants'
import { ILaboratoryData, ISkillsData } from '@models/common/app'
import { SKILLS } from '@constants/queries'
import { IUser } from '@models/common/user'

interface SkillsInfoProps {
  currentUser?: IUser
}

export const SkillsInfo: React.FC<SkillsInfoProps> = ({ currentUser }) => {
  const queryClient = useQueryClient()
  const { selectedLaboratory } = useAppSelector(workSpaceInfo)
  const currentLaboratoryData = queryClient.getQueryData<{ pages: Array<ILaboratoryData> }>(
    [LABORATORIES_FILTER, selectedLaboratory?.name],
    { exact: false }
  )
  const generalSkillsData = queryClient.getQueryData<ISkillsData>(SKILLS)
  const currentLaboratorySkills = generalSkillsData?.results.filter(generalSkill =>
    currentLaboratoryData?.pages[0].results[0].skills.find(
      laboratorySkill => laboratorySkill.id === generalSkill.id
    )
  )
  const userHasSkills = currentUser?.skills.length
  const userHasAllPermittedSkills = currentUser?.skills.length === currentLaboratorySkills?.length

  return (
    <SkillsField skillList={currentLaboratorySkills}>
      <Box>
        <>
          {userHasSkills ? (
            <Typography mt='-3px' color='#616D84' sx={{ fontSize: '14px' }}>
              After removing skill user can complete tasks which are in progress status but other
              actions on this sample won`t be available
            </Typography>
          ) : null}
          {userHasAllPermittedSkills || (
            <Typography mt='3px' color='#616D84' sx={{ fontSize: '14px' }}>
              Make sure that the user get trained and can work with this samples
            </Typography>
          )}
        </>
      </Box>
    </SkillsField>
  )
}
