import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import { Typography, styled } from '@mui/material'

import { ParagraphLinkProps } from './ParagraphLink'

const StyledParagraphLinkConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'alignSelf':
        return false
      case 'marginBottom':
        return false
      case 'fontHeight':
        return false
      case 'fontWeight':
        return false
      default:
        return true
    }
  },
}

export const StyledParagraphLink = styled<React.FC<ParagraphLinkProps>>(
  Typography,
  StyledParagraphLinkConfig
)`
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ $isShouldToAddStyles }) => ($isShouldToAddStyles ? '16px' : '')};
  align-self: ${({ alignSelf }) => alignSelf};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
  font-size: ${({ fontSize, theme }) => fontSize || theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  width: ${({ width }) => width};
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`
