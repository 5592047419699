import { Box, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useEffect, useState } from 'react'

import { StylePhoneInput } from '../styles'

interface PhoneFieldProps {
  phoneError: string
}

export const PhoneField: React.FC<PhoneFieldProps> = ({ phoneError }) => {
  const { setValue, getValues } = useFormContext()
  const [error, setError] = useState<string>(phoneError)
  const value = getValues('phone_number')

  useEffect(() => {
    setError(phoneError)
  }, [phoneError])

  const changeHandler = (val: string) => {
    if (`+${val}` !== value) {
      setError('')
    }
    setValue('phone_number', val)
  }

  return (
    <Box>
      <StylePhoneInput
        value={value}
        country={'us'}
        specialLabel={''}
        placeholder='(310) 555 1212'
        masks={{ fr: '(...) ..-..-..', at: '(...) ...-....' }}
        inputStyle={{
          height: '48px',
          fontSize: '16px',
          color: '#0F1934',
          marginLeft: '84px',
          paddingLeft: '12px',
          width: '383px',
          marginTop: '-1px',
          borderColor: `${error ? '#AF4645' : '#E1E7F3'}`,
        }}
        containerStyle={{
          border: `1px solid ${error ? '#AF4645' : '#E1E7F3'}`,
          borderRadius: '4px',
          height: '45.5px',
          width: '80px',
        }}
        dropdownStyle={{ marginLeft: '-10px' }}
        onChange={changeHandler}
      />
      <Box sx={{ height: '16px' }}>
        <Typography component='p' fontSize='12px' color='#AF4645' mt='4px'>
          {error}
        </Typography>
      </Box>
    </Box>
  )
}
