import { ISample } from '@models/samples'
import { ExpandableApis } from '@components/shared'
import { getApisWithType } from '@helpers/ui/common'
import { StyledTableSampleCell } from '@styles'

interface APIsCellProps {
  sample: ISample
  direction?: 'column' | 'row'
  isMobile?: boolean
}

export const APIsCell: React.FC<APIsCellProps> = ({ sample, direction, isMobile }) => {
  const { main_apis, optional_apis } = sample

  const { allApisWithType } = getApisWithType({ main_apis, optional_apis })

  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      <ExpandableApis apis={allApisWithType} borderBottom='none' direction={direction} />
    </StyledTableSampleCell>
  )
}
