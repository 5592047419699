import { useFormContext } from 'react-hook-form'
import React from 'react'
import { useDispatch } from 'react-redux'

import { Input } from '@components/shared'
import { setApiErrorUpdatePhoneOrEmail } from '@state/user'

export interface InputFieldProps {
  name: string
  apiError?: string
}

export const InputField: React.FC<InputFieldProps> = ({ name, apiError }) => {
  const { register, getValues, formState } = useFormContext()
  const dispatch = useDispatch()
  const value = getValues(name)
  const error = formState.errors[name]

  React.useEffect(() => {
    if (name === 'email') {
      dispatch(setApiErrorUpdatePhoneOrEmail(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, name])

  return (
    <Input
      fullWidth
      height={48}
      variant='outlined'
      placeholder={value}
      error={apiError ? !!apiError.length : !!error?.message}
      helperText={apiError ? apiError : error?.message}
      {...register(name)}
    />
  )
}
