import React from 'react'
import { CheckboxProps as CheckboxMUIProps } from '@mui/material'

import { StyledCheckbox, BpCheckedIcon, BpIcon, BpIndeterminateIcon } from './styles'

export interface CheckboxProps extends CheckboxMUIProps {
  padding?: string
  ml?: string
  mb?: string
}

export const Checkbox: React.FC<CheckboxProps> = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  (props, ref) => {
    return (
      <StyledCheckbox
        ref={ref}
        checkedIcon={<BpCheckedIcon />}
        indeterminateIcon={<BpIndeterminateIcon />}
        icon={<BpIcon color={props.disabled ? '#E1E7F3' : '#ADB4C2'} />}
        {...props}
      />
    )
  }
)
