import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'

import { Attachment, AttachmentsActionButtons } from '@components/shared'
import { TRUNCATED_ATTACHMENTS_TASK_DETAILS_TEXT } from '@constants/common'
import { useFetchAttachments } from '@hooks/queries'
import { protectedRoutes } from '@configs/routes.config'
import { useAppDispatch } from '@hooks/redux'
import { setIsEditAttachments } from '@state/tasks'
import { useFetchMutateAttachments } from '@hooks/queries/useFetchAttachments'
import { IAttachments } from '@models/attachments'

import { Props } from './DetailsPageStorageAttachments.types'

export const DetailsPageStorageAttachments: React.FC<Props> = props => {
  const { id, allow_editing, contentType, width, section } = props

  const [attachments, setAttachments] = React.useState<IAttachments | undefined>([])
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false)

  const history = useHistory()
  const dispatch = useAppDispatch()

  const fetchMutateAttachments = useFetchMutateAttachments({
    content_type: contentType,
    object_id: id,
  })

  const fetchAttachmentsQ = useFetchAttachments({
    content_type: contentType,
    object_id: id,
  })

  React.useEffect(() => {
    if (!fetchAttachmentsQ.data && isEditMode) {
      fetchMutateAttachments.mutate()
      setIsEditMode(false)
    }
    if (fetchAttachmentsQ.data) {
      setAttachments(fetchAttachmentsQ.data)
    } else if (fetchMutateAttachments.data && !isEditMode) {
      setAttachments(fetchMutateAttachments.data as IAttachments)
    }
  }, [fetchAttachmentsQ.data, fetchMutateAttachments, isEditMode])

  const attachMaterialsEndpoint = {
    pathname: protectedRoutes.attachStorage.path
      .replace(`:${contentType}Id`, `${id}`)
      .replace(':modalType', 'storage'),
    state: history.location.state,
  }

  const handleSetEditMode = () => {
    dispatch(setIsEditAttachments(true))
    setIsEditMode(true)
  }

  const isEdit = attachments && attachments.length !== 0
  const isAttachmentsSection = attachments?.some(item => item.attachment_section.key === section)

  if (fetchAttachmentsQ.isLoading) return <CircularProgress size={16} />

  return (
    <>
      {attachments?.length && isAttachmentsSection ? (
        <Box width={width ? width : '250px'} mb={allow_editing ? '0' : '40px'}>
          {attachments?.map(attachment =>
            attachment.attachment_section.key === section ? (
              <Attachment
                truncatedTextLength={TRUNCATED_ATTACHMENTS_TASK_DETAILS_TEXT}
                cantDelete
                key={attachment.id}
                attachment={attachment}
                mr='15px'
              />
            ) : null
          )}
        </Box>
      ) : allow_editing ? null : (
        '-'
      )}

      {/* Edit/Add attachments buttons */}
      <Box mb='40px'>
        {allow_editing && (
          <AttachmentsActionButtons
            isEdit={isEdit}
            attachMaterialsEndpoint={attachMaterialsEndpoint}
            isTextDecorationAbsence
            handleSetEditMode={handleSetEditMode}
            addBtnTitle='Add storage documents'
          />
        )}
      </Box>
    </>
  )
}
