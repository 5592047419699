import { Box, BoxProps, styled } from '@mui/material'

interface StyledRoundBadgeProps extends BoxProps {
  $color: string
}

export const StyledRoundBadge = styled<React.FC<StyledRoundBadgeProps>>(Box)`
  position: absolute;
  left: -25px;
  top: -3px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: ${({ $color }) => $color};
  border-radius: 100%;
`
