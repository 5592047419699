import React from 'react'
import { Box, Typography } from '@mui/material'

import { selectUser } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { datePatterns } from '@constants/dates'
import { SubText } from '@styles'
import { ISampleTask } from '@models/samples'
import { getFormattedDate } from '@helpers/ui/date'

interface TestingDateProps {
  task: ISampleTask
}

export const TestingDate: React.FC<TestingDateProps> = ({ task }) => {
  const { started_at: startedAt, finished_at: finishedAt } = task
  const { timezone } = useAppSelector(selectUser)

  return (
    <>
      <Box mb={'5px'}>
        <SubText>Started</SubText>
        <Typography variant='body1'>
          {getFormattedDate(datePatterns.timeDayMonth, startedAt, timezone) || '-'}
        </Typography>
      </Box>

      <Box>
        <SubText>Finished</SubText>
        <Typography variant='body1'>
          {getFormattedDate(datePatterns.timeDayMonth, finishedAt, timezone) || '-'}
        </Typography>
      </Box>
    </>
  )
}
