import { useMutation, useQueryClient } from 'react-query'
import { StringParam, useQueryParams } from 'use-query-params'

import { deleteExtraTask } from '@api/samples'
import { IDeleteExtraTask, ITasksData } from '@models/tasks'
import { SAMPLE_EXTRA_TASK_BUTTON, SAMPLE_TASKS, SAMPLES } from '@constants/queries'
import { useAppDispatch } from '@hooks/redux'
import { addedExtraTask } from '@state/samples'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useDeleteExtraTask = <T>() => {
  const client = useQueryClient()
  const dispatch = useAppDispatch()

  const stringParams = {
    taskStatus: StringParam,
  }

  const [queryString, setQueryString] = useQueryParams(stringParams)
  const queryStringPrev = queryString

  const mutation = useMutation<unknown, IError, IDeleteExtraTask>(deleteExtraTask, {
    onSuccess: async (_, { sampleId, taskId }) => {
      client.setQueryData<ITasksData<T> | undefined>([SAMPLE_TASKS, sampleId], oldData => {
        return (
          oldData && {
            ...oldData,
            //@ts-ignore
            results: oldData.results.filter(data => data.id !== Number(taskId)),
          }
        )
      })
      client.invalidateQueries([SAMPLE_TASKS, sampleId])
      client.invalidateQueries([SAMPLE_EXTRA_TASK_BUTTON, sampleId])
      dispatch(addedExtraTask(true))
      await setQueryString({ taskStatus: undefined })
      setQueryString({ taskStatus: queryStringPrev.taskStatus })
      client.invalidateQueries([SAMPLES])
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
