import React from 'react'
import { v4 as uuid } from 'uuid'
import { Typography, useMediaQuery } from '@mui/material'
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form'
import { MutateOptions } from 'react-query'

import { setNewAttachmentInstead } from '@state/tasks'
import { useAppDispatch } from '@hooks/redux'
import { StyledLabel } from '@styles'
import { Button, Input } from '@components/shared'
import { useToggle } from '@hooks/common'
import { IAttachData, IAttachLink } from '@models/common/forms'
import {
  AttachSection,
  AttachTitle,
  IAttachment,
  IAttachmentResultData,
  IAttachments,
  IModalContentTypeValue,
} from '@models/attachments'
import { IError } from '@models/common/app'
import topArrow from '@assets/icons/top-arrow-icon.svg'
import bottomArrow from '@assets/icons/bottom-arrow-icon.svg'
import deactivateBottomArrow from '@assets/icons/deactivate-bottom-arrow-icon.svg'

import { ButtonBox, StyledAttachLinkCollapse, StyledIcon, StyledCollapse } from './styles'

interface AttachLinkProps {
  isDeactivate: boolean
  attachments: IAttachments
  setAttachments: React.Dispatch<React.SetStateAction<IAttachments>>
  addAttachments: (
    variables: FormData,
    options?: MutateOptions<IAttachmentResultData, IError, FormData, unknown> | undefined
  ) => Promise<IAttachmentResultData>
  objectId: number
  contentType: IModalContentTypeValue
  section: AttachSection
  sectionTitle: AttachTitle
  deletedAttachmentId: string | undefined
}
export const AttachLink: React.FC<AttachLinkProps> = props => {
  const {
    isDeactivate,
    setAttachments,
    addAttachments,
    objectId,
    contentType,
    section,
    sectionTitle,
    deletedAttachmentId: instead,
  } = props

  const dispatch = useAppDispatch()

  const { formState, register, getValues, trigger, setValue, control } = useFormContext()
  const fields = useWatch({ control, name: ['url', 'title'] })

  const [isToggleCollapse, toggleCollapse] = useToggle()
  const isMobile = useMediaQuery('(max-width:767px)')

  const submitHandler: SubmitHandler<IAttachLink> = data => {
    const formData: FormData = new FormData()
    const attachmentId = uuid()
    const attachmentIds: string[] = [attachmentId]

    const linkData: IAttachData = {
      name: data.title,
      link: data.url,
      attachment_type: 'link',
      content_type: contentType,
      attachment_section: section,
    }

    if (objectId) linkData.object_id = String(objectId)

    const attachment: IAttachment = {
      id: attachmentId,
      name: data.title,
      link: data.url,
      loading: true,
      attachment_type: {
        key: 'link',
        value: 'Link',
      },
      attachment_section: {
        key: section,
        value: sectionTitle,
      },
    }

    setAttachments(prev => [...prev, attachment])

    const data2Send = instead ? { ...linkData, instead } : linkData
    Object.entries(data2Send).forEach(([key, value]) => formData.append(key, value))

    addAttachments(formData)
      .then(data => {
        if (instead && !data.result[0].instead) {
          dispatch(setNewAttachmentInstead(instead))
        }
      })
      .catch(() => {
        setAttachments(prev => prev.filter(({ id }) => !attachmentIds.includes(id.toString())))
      })

    toggleCollapse()
    setValue('url', null)
    setValue('title', null)
  }

  return (
    <StyledAttachLinkCollapse>
      <ButtonBox isDeactivate={isDeactivate} onClick={!isDeactivate ? toggleCollapse : () => {}}>
        <Typography variant='body1'>Attach a link</Typography>
        {isToggleCollapse ? (
          <StyledIcon>
            <img src={topArrow} alt='bottom arrow' />
          </StyledIcon>
        ) : (
          <StyledIcon>
            <img src={isDeactivate ? deactivateBottomArrow : bottomArrow} alt='top arrow' />
          </StyledIcon>
        )}
      </ButtonBox>
      <StyledCollapse in={isToggleCollapse} timeout='auto' unmountOnExit>
        <StyledLabel
          mb={isMobile ? '20px' : '43px'}
          sx={{ marginTop: '10px' }}
          label='URL'
          control={
            <Input
              fullWidth
              dataTestId='input-email'
              variant='outlined'
              type='text'
              placeholder='https:/...'
              error={!!formState.errors.url?.message}
              helperText={formState.errors.url?.message}
              {...register('url')}
            />
          }
        />
        <StyledLabel
          sx={{ marginBottom: '24px' }}
          label='Title'
          control={
            <Input
              fullWidth
              dataTestId='input-email'
              variant='outlined'
              type='text'
              placeholder='Enter title...'
              error={!!formState.errors.title?.message}
              helperText={formState.errors.title?.message}
              {...register('title')}
            />
          }
        />

        <Button
          variant='contained'
          disabled={
            !!formState.errors.title?.message ||
            !!formState.errors.url?.message ||
            !(fields[0] && fields[1])
          }
          onClick={async () => {
            const result = await trigger(['url', 'title'], { shouldFocus: true })
            result && submitHandler({ url: getValues('url'), title: getValues('title') })
          }}
          mb='32px'
          width='90px'
          height='32px'
        >
          Insert
        </Button>
      </StyledCollapse>
    </StyledAttachLinkCollapse>
  )
}
