import * as React from 'react'
import { Box, Typography } from '@mui/material'

import { PureTooltip } from '@components/ui/PureTooltip'

import { StyledTitleLink } from '../sampleFields/SpecificId/styles'
import { TooltipProps } from './tooltip.types'
import { StyledTooltip } from './components'

// common tooltip that can be with link

export const Tooltip: React.FC<TooltipProps> = ({
  isPureTooltip,
  title,
  placement,
  shouldBeHidden,
  toggleValidationNotification,
  toggleValidationNotificationHandler,
  tooltipWithLink,
  onLinkHandler,
  labelTooltip,
  specificId,
  tooltipWithId,
  positionLeft,
  positionTop,
  children,
  scrollBlockRef,
  ...restProps
}) => {
  React.useEffect(() => {
    toggleValidationNotification &&
      setTimeout(() => {
        toggleValidationNotificationHandler && toggleValidationNotificationHandler(false)
      }, 3000)
  }, [toggleValidationNotification, toggleValidationNotificationHandler])

  const tooltipTitle = React.useMemo(() => {
    if (tooltipWithId) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          {tooltipWithLink && (
            <StyledTitleLink onClick={onLinkHandler}>{labelTooltip}</StyledTitleLink>
          )}
          {!tooltipWithLink && (
            <Typography m='0' fontSize='13px' fontWeight={400} sx={{ wordBreak: 'break-word' }}>
              {labelTooltip}
            </Typography>
          )}
          <Typography m='0' fontSize='13px' fontWeight={400} sx={{ wordBreak: 'break-word' }}>
            {specificId}
          </Typography>
        </Box>
      )
    } else if (tooltipWithLink) {
      return (
        <Box>
          <StyledTitleLink onClick={onLinkHandler}>{labelTooltip}</StyledTitleLink>
        </Box>
      )
    } else {
      return (
        <Typography m='0' fontSize='13px' fontWeight={400} sx={{ wordBreak: 'break-word' }}>
          {title}
        </Typography>
      )
    }
  }, [labelTooltip, onLinkHandler, specificId, title, tooltipWithId, tooltipWithLink])

  return (() => {
    if (shouldBeHidden) {
      return children
    } else if (isPureTooltip) {
      return (
        <PureTooltip
          scrollBlockRef={scrollBlockRef}
          title={tooltipTitle}
          placement={placement}
          positionLeft={positionLeft}
          positionTop={positionTop}
          titleStyle={restProps.maxWidth ? { maxWidth: restProps.maxWidth } : null}
        >
          {children}
        </PureTooltip>
      )
    }

    return (
      <StyledTooltip
        id='not-expand'
        arrow
        placement={placement || 'top'}
        disableFocusListener={toggleValidationNotification}
        disableHoverListener={toggleValidationNotification}
        disableTouchListener={toggleValidationNotification}
        open={toggleValidationNotification}
        title={<React.Fragment>{tooltipTitle}</React.Fragment>}
        {...restProps}
        sx={{ zIndex: 1500, ...restProps.sx }}
      >
        {children}
      </StyledTooltip>
    )
  })()
}
