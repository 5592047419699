import React from 'react'
import { v4 as uuid } from 'uuid'
import { FileRejection } from 'react-dropzone'
import { useHistory } from 'react-router-dom'

import { DropZone } from '@components/shared'
import { IAttachment, IAttachmentWithThumbnail } from '@models/attachments'
import { useAppDispatch } from '@hooks/redux'
import { setNewAttachmentInstead } from '@state/tasks'
import { setHrCreateSampleAttachments } from '@state/app'
import { AttachFileProps, IAttachData } from '@models/common/forms'

import {
  useApplyNewAttachmentsToSample,
  useAttachmentsLength,
  useHrAttachmentsLength,
} from '../hooks'

export const AttachFile: React.FC<AttachFileProps> = ({
  attachments,
  setAttachments,
  addAttachments,
  addingError,
  objectId,
  contentType,
  section,
  sectionTitle,
  modalType,
  deletedAttachmentId: instead,
}) => {
  const { applyNewAttachmentsToSample } = useApplyNewAttachmentsToSample()
  const { getAttachmentsLength } = useAttachmentsLength()
  const photoAttachmentsLength = useHrAttachmentsLength()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const isSamplePhotoEdit = modalType === 'photo' && history.location.pathname.includes('/edit/')

  const onDropHandler = React.useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const formData: FormData = new FormData()

      const data: IAttachData = {
        attachment_type: 'file',
        content_type: contentType,
        attachment_section: section,
      }

      if (objectId && !isSamplePhotoEdit) data.object_id = String(objectId)

      const attachmentIds: string[] = []
      const hasAddingError = !!fileRejections.length

      acceptedFiles.forEach(file => {
        const attachmentId = uuid()
        attachmentIds.push(attachmentId)

        const attachment: IAttachment = {
          id: attachmentId,
          name: file.name,
          loading: true,
          attachment_type: {
            key: 'file',
            value: 'File',
          },
          attachment_section: {
            key: section,
            value: sectionTitle,
          },
        }

        if (!hasAddingError) {
          setAttachments(prev => [...prev, attachment])
          if (modalType === 'photo') {
            dispatch(
              setHrCreateSampleAttachments({
                attachments: [attachment] as IAttachmentWithThumbnail[],
                exitAttachModal: 'pending',
              })
            )
          }
        }

        const data2Send =
          instead && !isSamplePhotoEdit ? { ...data, instead, file } : { ...data, file }
        Object.entries(data2Send).forEach(([key, value]) => formData.append(key, value))
      })

      if (!hasAddingError) {
        addAttachments(formData)
          .then(data => {
            if (!objectId && modalType !== 'photo') {
              applyNewAttachmentsToSample(data)
            }
            if (modalType === 'photo') {
              dispatch(
                setHrCreateSampleAttachments({
                  attachments: data.result as IAttachmentWithThumbnail[],
                  exitAttachModal: 'pending',
                })
              )
            }
            if (instead && !data.result[0].instead && !isSamplePhotoEdit) {
              dispatch(setNewAttachmentInstead(instead))
            }
          })
          .catch(() => {
            setAttachments(prev => prev.filter(({ id }) => !attachmentIds.includes(id.toString())))
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addAttachments, section, sectionTitle, setAttachments, contentType, objectId, instead]
  )

  return (
    <DropZone
      modalType={modalType}
      onDrop={onDropHandler}
      addingError={addingError}
      attachmentsLength={
        modalType === 'photo'
          ? photoAttachmentsLength
          : getAttachmentsLength(objectId, attachments.length)
      }
      section={section}
    />
  )
}
