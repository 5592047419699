import { Typography } from '@mui/material'

interface TestTypeProps {
  taskTestTypeName?: string
  isOtherTestType?: boolean
}

export const TestType: React.FC<TestTypeProps> = ({ taskTestTypeName, isOtherTestType }) => {
  if (isOtherTestType) return null

  return <Typography variant='body1'>{taskTestTypeName}</Typography>
}
