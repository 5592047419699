import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'
import { fetchConcentrationType } from '@api/tasks'
import { CONCENTRATION_TYPE } from '@constants/hrSamples'

export const useFetchConcentrationType = () => {
  const dispatch = useDispatch()

  return useQuery([CONCENTRATION_TYPE], fetchConcentrationType, {
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
