import React from 'react'
import { Typography, useTheme } from '@mui/material'

import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { EditAttachments } from '@components/modules/TasksDetails/components/SubTaskInfo/components/Tabs/components/TabTestingInfo/components/TaskRow/components/Attachments/styles'
import { ReactComponent as AddIcon } from '@assets/icons/add-icon-blue.svg'
import { Paragraph } from '@styles'
import { AddAttachedMaterialBox } from '@components/modules/PackageDetails/PackageDetailsContent/components/PackageInfo/PackageAttachments/components/AddAttachment/styles'

import { AttachmentsActionButtonsProps } from './AttachmentsActionButtons.types'

export const AttachmentsActionButtons: React.FC<AttachmentsActionButtonsProps> = props => {
  const { isEdit, attachMaterialsEndpoint, handleSetEditMode, addBtnTitle } = props
  const theme = useTheme()

  return (
    <>
      {isEdit ? (
        <EditAttachments
          to={attachMaterialsEndpoint}
          $isTextDecorationAbsence
          onClick={handleSetEditMode}
        >
          <EditIcon fill={theme.palette.primary.main} />
          <Typography
            color='primary.main'
            sx={{ marginLeft: '12px' }}
            variant='body1'
            fontWeight={500}
          >
            Edit
          </Typography>
        </EditAttachments>
      ) : (
        <>
          <AddAttachedMaterialBox
            to={attachMaterialsEndpoint}
            $isTextDecorationAbsence
            onClick={handleSetEditMode}
          >
            <AddIcon fill={theme.palette.primary.main} />
            <Typography sx={{ marginLeft: '13px' }} variant='body1'>
              {addBtnTitle ? addBtnTitle : 'Add new material'}
            </Typography>
          </AddAttachedMaterialBox>
          <Paragraph variant='body2' color='secondary.light'>
            All popular format of image, microsoft document, PDF and spreadsheet
          </Paragraph>
        </>
      )}
    </>
  )
}
