import { styled as styledMUI } from '@mui/system'
import styled from 'styled-components'

export const SubstanceItem = styled.div<{ $mb?: string }>`
  margin-bottom: ${({ $mb }) => $mb || 'none'};
  display: flex;
  justify-content: space-between;
`

export const ShowMoreBtn = styledMUI('button')`
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
  line-height: 1;
  margin: 10px 0 0;
`
