import React from 'react'
import { MenuItem, SelectChangeEvent, Typography } from '@mui/material'

import { Dropdown } from '@components/shared'
import { ISubsample, ISubsamples } from '@models/samples'
import { DetailsStyledRow } from '@styles'
import { SpecificId } from '@components/shared/sampleFields'

import { StyledLabel } from './styles'

interface HeaderProps {
  subSamples: ISubsamples
  selectedSubSample: ISubsample | undefined
  onSubsampleChange: (subSample: ISubsample | undefined) => void
}

export const Header: React.FC<HeaderProps> = props => {
  const { subSamples, selectedSubSample, onSubsampleChange } = props
  const hasSubsamples = !!subSamples.length

  const onChangeHandler = ({ target }: SelectChangeEvent<unknown>) => {
    onSubsampleChange(subSamples?.find(({ id }) => id === Number(target.value)))
  }

  return (
    <DetailsStyledRow>
      <Typography variant='h3' sx={{ marginBottom: '28px' }}>
        {hasSubsamples ? 'Sub-sample info' : 'Sample info'}
      </Typography>

      {hasSubsamples && (
        <StyledLabel
          label='Sub-sample'
          control={
            <Dropdown
              height={48}
              disabled={selectedSubSample?.splitted}
              value={selectedSubSample?.id}
              onChange={onChangeHandler}
            >
              {subSamples.map(subSample => (
                <MenuItem key={subSample.id} value={subSample.id}>
                  <SpecificId specificId={subSample.specific_id} />
                </MenuItem>
              ))}
            </Dropdown>
          }
        />
      )}
    </DetailsStyledRow>
  )
}
