import React, { useEffect } from 'react'

import { useActionsStickyBox } from '@hooks/common'
import { StyledTableBox } from '@styles'
import { ScrollNextSamplesLoader, HorizontalDragLayer, HorizontalDragCup } from '@components/shared'
import { draggableHorizontalScroll } from '@helpers/draggableHorizontalScroll'
import { ISamples } from '@models/samples'

import { TableHead } from './TableHead'
import { TableBody } from './TableBody'

interface TableProps {
  reports?: ISamples
  isFetchingNextPage?: boolean
  totalReports?: number
}

export const Table: React.FC<TableProps> = props => {
  const { isFetchingNextPage, reports, totalReports } = props

  const draggableHorizontalRef = React.useRef<HTMLDivElement | null>(null)
  const horizontalDragCup = React.useRef<HTMLDivElement | null>(null)
  const draggableHorizontalLayerRef = React.useRef<HTMLDivElement | null>(null)
  const tableHeadRef = React.useRef<HTMLDivElement | null>(null)
  const isSticky = useActionsStickyBox(tableHeadRef, '-250px 0px 0px 0px')

  useEffect(() => {
    if (draggableHorizontalRef.current) {
      const { removeDraggableEvents } = draggableHorizontalScroll(
        draggableHorizontalRef,
        draggableHorizontalLayerRef,
        horizontalDragCup
      )
      return () => removeDraggableEvents()
    }
  }, [])

  const horizontalScrollHandler: React.UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    tableHeadRef.current?.scrollTo(currentTarget.scrollLeft, 0)
  }

  const renderRowsLoader = () =>
    new Array(10).fill('').map((_, idx) => <ScrollNextSamplesLoader key={idx} />)

  return (
    <>
      <TableHead reports={reports || []} ref={tableHeadRef} hideBorder={isSticky} />
      <HorizontalDragCup ref={horizontalDragCup} />
      <StyledTableBox $background ref={draggableHorizontalRef} onScroll={horizontalScrollHandler}>
        <HorizontalDragLayer ref={draggableHorizontalLayerRef} />
        <TableBody reports={reports} totalReports={totalReports} />
      </StyledTableBox>

      {isFetchingNextPage && renderRowsLoader()}
    </>
  )
}
