import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'

import { Input } from '@components/shared/sampleFields'
import { StyledDetailsLabel, SubText } from '@styles'
import { MAX_CHARACTERS, MIN_CHARACTERS, NUMERIC_DECIMAL } from '@constants/validation'
import { useAppSelector } from '@hooks/redux'
import { isInvalidJsonApiError, setIsInvalidJsonApiError } from '@state/test'

interface JSONFieldProps {
  isResultNotRecognized: boolean
}

interface IRules {
  maxLength: { value: number; message: string }
  validate?: { validJson: (value: string) => boolean | string }
  required?: string
}

export const JSONField: React.FC<JSONFieldProps> = props => {
  const { isResultNotRecognized } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const theme = useTheme()
  const dispatch = useDispatch()
  const { formState } = useFormContext()
  const [jsonErrorText, setJsonErrorText] = useState('')
  const isApiJsonError = useAppSelector(isInvalidJsonApiError)

  useEffect(() => {
    if (isApiJsonError) {
      setJsonErrorText('Invalid json format')
    }
  }, [isApiJsonError])

  const isValidJson = useCallback((input: string) => {
    if (isResultNotRecognized) return
    try {
      const jsonObject = JSON.parse(input)

      const checkSubstancesLength =
        Array.isArray(jsonObject.substances) && jsonObject.substances.length <= 10

      const isJSONHasKeys = 'sample_preparation' in jsonObject && 'substances' in jsonObject

      const isValidSubstances = jsonObject.substances.every(
        (item: {
          substance: string
          concentration_per_ml: string
          concentration_type: string
          clinically_meaningful: string
        }) => {
          const checkSubstanceMinLength = item.substance.length < 2
          const checkSubstanceMaxLength = item.substance.length <= 255
          const checkConcentration = /^(?:0|\d{0,6}(?:\.\d{1,6})?)$/.test(item.concentration_per_ml)

          if (checkSubstanceMinLength) setJsonErrorText(`${MIN_CHARACTERS(2)} (substances)`)
          if (!checkSubstanceMaxLength) setJsonErrorText(`${MAX_CHARACTERS(255)} (substances)`)
          else if (!checkConcentration)
            setJsonErrorText(`${NUMERIC_DECIMAL} (concentration_per_ml)`)

          return checkSubstanceMaxLength && checkConcentration && !checkSubstanceMinLength
        }
      )

      if (!isJSONHasKeys || !checkSubstancesLength) setJsonErrorText('Invalid json format')
      if (isJSONHasKeys && isValidSubstances && checkSubstancesLength) setJsonErrorText('')
      dispatch(setIsInvalidJsonApiError(false))
      return isJSONHasKeys && isValidSubstances && checkSubstancesLength
    } catch (error) {
      setJsonErrorText('Invalid json format')
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRules = useMemo(() => {
    const rules: IRules = {
      maxLength: {
        value: 2000,
        message: MAX_CHARACTERS(2000),
      },
    }

    if (!isResultNotRecognized) {
      rules.required = 'Please fill in the field'
      rules.validate = {
        validJson: value => isValidJson(value),
      }
    }

    return rules
  }, [isResultNotRecognized, isValidJson])

  const formName = isResultNotRecognized ? 'result_is_not_recognized' : 'json_notes'

  return (
    <Box width='100%' mb={isMobile ? '24px' : ''}>
      <StyledDetailsLabel mb='9px' color={isResultNotRecognized ? '#8F929B' : '#0F1934'}>
        Notes <SubText style={{ fontSize: 16 }}>(Required)</SubText>
      </StyledDetailsLabel>
      <Input
        name={formName}
        rows={isMobile ? 6 : 9}
        multiline
        rules={getRules}
        mb='8px'
        mrErr='0'
        width={'100%'}
        placeholder='Paste copied json data from test report here...'
        disabled={isResultNotRecognized}
        borderColor={jsonErrorText ? theme.palette.error.main : theme.additional.stroke.main}
      />

      {!!jsonErrorText.length && !formState.errors?.json_notes?.message && (
        <Typography fontSize='12px' color={theme.palette.error.main} mt='0px'>
          {jsonErrorText}
        </Typography>
      )}
    </Box>
  )
}
