import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { addNewBrand } from '@api/samples'
import { BRANDS } from '@constants/queries'
import { IError, IOption } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useAddNewBrand = () => {
  const client = useQueryClient()
  const dispatch = useDispatch()

  const mutation = useMutation((name: string) => addNewBrand(name), {
    onMutate: () => client.getQueryData<IOption[]>(BRANDS),
    onSuccess: (newBrand, vars, prevBrands) => {
      if (prevBrands) client.setQueryData([BRANDS], [...prevBrands, newBrand])
    },
    onSettled: () => client.invalidateQueries(BRANDS),
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
