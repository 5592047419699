import React from 'react'
import { IconButton } from '@mui/material'

import { Tooltip } from '@components/shared'
import { useAppSelector } from '@hooks/redux'
import { selectUserCustomizeTableColumns } from '@state/user'
import {
  SORT_BY_EXPIRATION_DATE,
  SORT_BY_SPECIFIC_ID,
  SORT_BY_MANUFACTURER,
} from '@constants/common'
import { CustomizeTableColumnsIcon } from '@components/icons'

import { StubCell, StyledTableTitle } from './styles'
import { FixedTableCell } from '../styles'
import { TableColumnTitle } from '../components/TableColumnTitle'

interface TableHeadTitlesProps {
  isSticky: boolean
  toggleCustomizeTableColumnsModalOpen: () => void
}

export const TableHeadTitles = React.memo<TableHeadTitlesProps>(
  ({ isSticky, toggleCustomizeTableColumnsModalOpen }) => {
    const { sample_columns } = useAppSelector(selectUserCustomizeTableColumns)

    const sampleColumns = [
      { id: 0, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
      ...sample_columns
        .filter(column => column.activated)
        .map(sampleColumn => ({
          id: sampleColumn.id,
          content: sampleColumn.name,
        })),
    ].map(sampleColumn => {
      if (sampleColumn.id === 3) return { ...sampleColumn, ordering: SORT_BY_MANUFACTURER }
      else if (sampleColumn.id === 8) return { ...sampleColumn, ordering: SORT_BY_EXPIRATION_DATE }
      return sampleColumn
    })

    return (
      <>
        {sampleColumns.map(({ id, ...props }) => (
          <TableColumnTitle key={id} {...props} isSticky={isSticky} />
        ))}

        <StubCell isSticky={isSticky}></StubCell>

        <FixedTableCell
          isSticky={isSticky}
          sx={{
            borderTopLeftRadius: '10px',
            boxShadow: '-7px -4px 20px -5px rgba(53, 108, 151, 0.12)',
          }}
        >
          <StyledTableTitle
            sx={{
              width: '100%',
            }}
          >
            Action
          </StyledTableTitle>
          <Tooltip title='Customize columns'>
            <IconButton sx={{ padding: '0' }} onClick={toggleCustomizeTableColumnsModalOpen}>
              <CustomizeTableColumnsIcon />
            </IconButton>
          </Tooltip>
        </FixedTableCell>
      </>
    )
  }
)
