import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { ITaskLab } from '@models/tasks'
import { ITestData } from '@models/tests'
import { editTestResult, updateTest } from '@api/tests'
import { TASKS, TASKS_COMPLETED } from '@constants/queries'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'
import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'

export const useUpdateTest = (editResult?: boolean, isNotShowNotification?: boolean) => {
  const client = useQueryClient()
  const dispatch = useDispatch()

  const mutation = useMutation<ITaskLab, IError, ITestData>(
    editResult ? editTestResult : updateTest,
    {
      onSuccess: (newData, { taskId }) => {
        client.setQueryData([TASKS, taskId], () => {
          return newData
        })

        client.invalidateQueries([TASKS, taskId])
        editResult && client.invalidateQueries([TASKS_COMPLETED])
      },
      onError: error => {
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
        if (!isNotShowNotification) showNotification(notifications.taskFinishedFailed)
      },
      retry: 1,
      retryDelay: 1500,
    }
  )

  return mutation
}
