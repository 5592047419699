import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useQueryClient } from 'react-query'
import { v4 as uuid } from 'uuid'
import { AutocompleteInputChangeReason } from '@mui/core/AutocompleteUnstyled/useAutocomplete'

import { useAssigneePerson } from '@hooks/queries/useAssigneePerson'
import { IAssignee, IAssigneeExpanded, ISampleTask } from '@models/samples'
import { SAMPLES, SAMPLE_TASKS } from '@constants/queries'
import { useUpdateTaskAssignee } from '@hooks/queries/useUpdateTaskAssignee'
import { stringSlice } from '@helpers/ui/common'
import { INVENTORY_MANAGER } from '@constants/user'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'
import { Tooltip } from '@components/shared'

import { StyledMenuItem, StyledName, StyledAutocompleteWrapper } from './styles'

interface EditTaskAssigneeProps {
  sampleId: number
  task: ISampleTask
}

export const EditTaskAssignee: React.FC<EditTaskAssigneeProps> = props => {
  const { sampleId, task } = props
  const [assigneePerson, setAssigneePerson] = React.useState<IAssignee | null>(null)
  const [shouldBeHiddenTooltip, setShouldBeHiddenTooltip] = React.useState<boolean>(true)
  const [searchVal, setSearchVal] = React.useState<string>('')
  const [topScroll, setTopScroll] = React.useState<{ oldScrollTop: number; isScrollTop: boolean }>({
    oldScrollTop: 0,
    isScrollTop: false,
  })

  const { first_name: firstName, last_name: lastName } = useAppSelector(selectUser)

  const updateTaskAssigneeM = useUpdateTaskAssignee()
  const client = useQueryClient()
  const { data, fetchNextPage, hasNextPage, isLoading, isFetching } = useAssigneePerson(
    sampleId,
    searchVal
  )

  const assignees: IAssigneeExpanded[] = React.useMemo(() => {
    if (data) {
      const allAssignees: IAssignee[] = []
      data.pages.forEach(page => allAssignees.push(...page.results.laboratory_assistants))

      // For "IAssignee" object type was added "assigneeId" and change "id" at code below
      // because MUI Autocomplete set errors when it sees, that "id" can be repeated
      return allAssignees.map((assignee, idx) => {
        return { ...assignee, id: idx + 1, assigneeId: assignee.id }
      })
    }
    return []
  }, [data])

  const assigneeName = assigneePerson
    ? `${assigneePerson.first_name} ${assigneePerson.last_name}`
    : ''

  const onChangeHandler = (e: any, value: IAssigneeExpanded | null) => {
    if (value) {
      updateTaskAssigneeM.mutate(
        { assignee: value?.assigneeId, assignee_role: value?.role.key, taskId: task.id },
        {
          onSettled: () => {
            client.invalidateQueries([SAMPLE_TASKS, sampleId])
            client.invalidateQueries([SAMPLES])
          },
        }
      )
    }
  }

  const onInputChange = (
    e: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') setSearchVal(value)
    else if (searchVal) setSearchVal('')
  }

  React.useEffect(() => {
    if (!!assignees.length) {
      const defaultAssignee = task.assignee || null
      setAssigneePerson(defaultAssignee)
    }
  }, [assignees, task.assignee])

  React.useEffect(() => {
    if (task.read_only) setShouldBeHiddenTooltip(!task.read_only)
    else setShouldBeHiddenTooltip((assigneeName?.length || 0) <= 37)
  }, [assigneeName?.length, task.read_only])

  const tooltipTitle = task.read_only ? task.read_only_description : assigneeName || ''

  return (
    <StyledAutocompleteWrapper
      width='400px'
      $left={task?.assignee_role.value === INVENTORY_MANAGER ? '58%' : '53%'}
    >
      <Autocomplete
        size='small'
        id='assigneePerson'
        disabled={task.read_only}
        value={assigneePerson}
        onInputChange={onInputChange}
        onChange={onChangeHandler}
        options={assignees}
        loading={isFetching || isLoading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => {
          return stringSlice(`${option.first_name} ${option.last_name}`, 37)
        }}
        renderOption={(props, option) => {
          const { id, first_name, last_name } = option
          const isCurrentUser = first_name === firstName && last_name === lastName
          const fullName = `${first_name} ${last_name} ${isCurrentUser ? '(you)' : ''}`

          return (
            <Tooltip
              isPureTooltip
              title={fullName}
              shouldBeHidden={(fullName?.length || 0) <= 36}
              key={uuid()}
              maxWidth={250}
            >
              <StyledMenuItem key={uuid()} value={id} {...props}>
                <StyledName variant='body1'>{stringSlice(fullName, 37)}</StyledName>
              </StyledMenuItem>
            </Tooltip>
          )
        }}
        ListboxProps={{
          onScroll: event => {
            const listboxNode = event.currentTarget

            const isFetchNextPage =
              hasNextPage &&
              Math.round(listboxNode.scrollTop + listboxNode.clientHeight) ===
                listboxNode.scrollHeight

            if (isFetchNextPage) {
              const top = Math.round(listboxNode.scrollHeight - listboxNode.clientHeight)
              fetchNextPage()
              setTopScroll({ oldScrollTop: top, isScrollTop: true })
            }

            if (listboxNode.scrollHeight > topScroll.oldScrollTop && topScroll.isScrollTop) {
              listboxNode.scrollTo(0, topScroll.oldScrollTop)

              setTopScroll(prev => ({ ...prev, isScrollTop: false }))
            }
          },
        }}
        renderInput={params => {
          return (
            <Tooltip isPureTooltip title={tooltipTitle} shouldBeHidden={shouldBeHiddenTooltip}>
              <TextField {...params} placeholder='Assign a person' />
            </Tooltip>
          )
        }}
      />
    </StyledAutocompleteWrapper>
  )
}
