import ContentLoader from 'react-content-loader'

export const PageLoader: React.FC = props => (
  <ContentLoader
    speed={1.8}
    width='100%'
    viewBox='0 0 1280 636'
    backgroundColor='#f3f3f3'
    foregroundColor='#e3e3e3'
    {...props}
  >
    <rect x='64' y='264' rx='5' ry='5' width='202' height='48' />
    <rect x='898' y='61' rx='5' ry='5' width='202' height='24' />
    <rect x='895' y='118' rx='5' ry='5' width='79' height='12' />
    <rect x='1029' y='118' rx='5' ry='5' width='79' height='12' />
    <rect x='895' y='152' rx='5' ry='5' width='101' height='12' />
    <rect x='1029' y='152' rx='5' ry='5' width='97' height='12' />
    <rect x='895' y='207' rx='5' ry='5' width='101' height='12' />
    <rect x='895' y='280' rx='5' ry='5' width='87' height='12' />
    <rect x='1029' y='207' rx='5' ry='5' width='135' height='12' />
    <rect x='1030' y='226' rx='5' ry='5' width='96' height='12' />
    <rect x='1030' y='246' rx='5' ry='5' width='119' height='12' />
    <rect x='1030' y='280' rx='5' ry='5' width='101' height='12' />
    <rect x='895' y='315' rx='5' ry='5' width='92' height='12' />
    <rect x='895' y='350' rx='5' ry='5' width='48' height='12' />
    <rect x='1030' y='426' rx='5' ry='5' width='79' height='12' />
    <rect x='1030' y='347' rx='14' ry='14' width='87' height='28' />
    <rect x='1029' y='385' rx='14' ry='14' width='102' height='28' />
    <rect x='1030' y='315' rx='5' ry='5' width='91' height='12' />
    <rect x='64' y='35' rx='5' ry='5' width='152' height='41' />
    <rect x='64' y='124' rx='14' ry='14' width='107' height='28' />
    <rect x='64' y='179' rx='7' ry='7' width='437' height='14' />
    <rect x='64' y='205' rx='7' ry='7' width='253' height='14' />
    <path d='M 10 10 v 484 H 0 V 10 h 10 z M 1280 10 v 484 h -10 V 10 h 10 z M 0 494 h 1280 c 0 5.523 -4.48 10 -10 10 H 10 c -5.523 0 -10 -4.477 -10 -10 z M 0 10 C 0 4.477 4.477 0 10 0 h 1260 c 5.52 0 10 4.477 10 10 H 0 z' />
    <rect x='0' y='513' rx='5' ry='5' width='1280' height='123' />
  </ContentLoader>
)
