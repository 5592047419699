import React from 'react'
import { Box } from '@mui/system'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { useMediaQuery } from '@mui/material'
import { useDebouncedCallback } from 'use-debounce/lib'

import {
  toggleCustomizeModalType,
  selectUserCustomizeTableColumns,
  setEditableColumns,
  reorderColumn,
} from '@state/user'
import { Button, Modal, Switcher } from '@components/shared'
import { ButtonBox, StyledModalContent, StyledModalTitle, StyledShadowBox } from '@styles'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { useCustomizeTableColumns } from '@hooks/queries'
import { useToggle } from '@hooks/common'
import { ITableColumns } from '@models/common/user'

import { Card } from './components'
import { StyledModalScroll } from './styles'
import { PortalAwareItem } from './components/Card'

interface CustomizeTableColumnsProps {
  onClose: () => void
}

export const CustomizeTableColumns: React.FC<CustomizeTableColumnsProps> = ({ onClose }) => {
  const { customizeModalType, columns, sample_columns, task_columns } = useAppSelector(
    selectUserCustomizeTableColumns
  )

  const dispatch = useAppDispatch()
  const [rerenderDND, setRerenderDND] = useToggle(true)
  const [currentTab, setCurrentTab] = React.useState<ITableColumns>()

  React.useEffect(() => {
    if (customizeModalType[0].active) setCurrentTab(columns[0])
    else setCurrentTab(columns[1])
  }, [customizeModalType, columns])

  React.useEffect(() => {
    setRerenderDND()
    setTimeout(() => {
      setRerenderDND()
    }, 100)
  }, [customizeModalType, setRerenderDND])

  React.useEffect(() => {
    dispatch(
      setEditableColumns([
        [{ id: 0, name: 'Sample ID', activated: true }, ...sample_columns],
        [{ id: 0, name: 'Task', activated: true }, ...task_columns],
      ])
    )
  }, [dispatch, sample_columns, task_columns])

  const customizeTableColumnsM = useCustomizeTableColumns()

  const onDragUpdateHandler = useDebouncedCallback((result: DropResult) => {
    result.destination &&
      dispatch(
        reorderColumn({
          id: +result.draggableId,
          idx: result.destination.index,
          activeTab: customizeModalType[0].active ? 0 : 1,
        })
      )
  }, 100)

  const customizeTableColumnsHandler = () => {
    customizeTableColumnsM.mutate(
      {
        sample_column_settings: columns[0]
          .filter(column => column.id !== 0)
          .map((column, idx) => ({
            column: column.id,
            activated: column.activated,
            position: idx + 1,
          })),
        task_column_settings: columns[1]
          .filter(column => column.id !== 0)
          .map((column, idx) => ({
            column: column.id,
            activated: column.activated,
            position: idx + 1,
          })),
      },
      { onSettled: onClose }
    )
  }
  const isSmallHeight = useMediaQuery('(max-height:700px)')

  return (
    <Modal isPaddingAbsence open onClose={onClose}>
      <Box>
        <StyledShadowBox>
          <StyledModalTitle sx={{ marginBottom: '12px' }} variant='h3'>
            Customize Columns
          </StyledModalTitle>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Switcher
              buttonsTitles={customizeModalType}
              $buttonWidth='111px'
              functionToggler={toggleCustomizeModalType}
            />
          </Box>
        </StyledShadowBox>

        <StyledModalContent pt='0'>
          <StyledModalScroll height={isSmallHeight ? '230px' : '450px'}>
            {rerenderDND && currentTab && (
              <DragDropContext onDragUpdate={onDragUpdateHandler} onDragEnd={() => {}}>
                {currentTab[0] && (
                  <Card
                    dndDisabled
                    checked
                    key={currentTab[0].id}
                    id={currentTab[0].id}
                    title={currentTab[0].name}
                  />
                )}
                <Droppable
                  direction='vertical'
                  data-react-beautiful-dnd-scroll-container={'1'}
                  droppableId='droppable'
                >
                  {provided => {
                    return (
                      <Box {...provided.droppableProps} ref={provided.innerRef}>
                        {currentTab
                          .filter(column => column.id !== 0)
                          .map((card, ind) => (
                            <Draggable
                              isDragDisabled={card.id === 0}
                              key={card.id}
                              draggableId={String(card.id)}
                              index={ind}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <PortalAwareItem provided={provided} snapshot={snapshot}>
                                    <Card
                                      isDragging={snapshot.isDragging && !snapshot.dropAnimation}
                                      dndDisabled={card.id === 0}
                                      checked={card.activated}
                                      key={card.id}
                                      id={card.id}
                                      title={card.name}
                                    />
                                  </PortalAwareItem>
                                )
                              }}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </Box>
                    )
                  }}
                </Droppable>
              </DragDropContext>
            )}
          </StyledModalScroll>
        </StyledModalContent>

        <StyledShadowBox padding='24px 70px'>
          <ButtonBox>
            <Button
              type='button'
              variant='outlined'
              color='secondary'
              width='202px'
              height='48px'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Box>
              <Button
                onClick={customizeTableColumnsHandler}
                variant='contained'
                width='202px'
                height='48px'
                loading={customizeTableColumnsM.isLoading}
              >
                Save
              </Button>
            </Box>
          </ButtonBox>
        </StyledShadowBox>
      </Box>
    </Modal>
  )
}
