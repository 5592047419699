import React from 'react'
import { Autocomplete, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { useFetchTimezones } from '@hooks/queries'
import { AutocompleteWrapper, Input } from '@components/shared'
import { ITimezone } from '@models/common/app'

export const TimezoneField = () => {
  const { register, setValue, getValues } = useFormContext()

  const { data } = useFetchTimezones()
  const hasTimezoneAutodetection = getValues('timezone_autodetect')
  const { name } = register('timezone')
  const [timezoneValue, setTimezoneValue] = React.useState<ITimezone | null>(null)

  React.useEffect(() => {
    const formValue = getValues('timezone')
    const value = data?.find(({ timezone_name }) => timezone_name === formValue)

    if (value) setTimezoneValue(value)
    else {
      const val = data?.find(
        ({ timezone_name }) => timezone_name === Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      val && setTimezoneValue(val)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (timezoneValue) setValue('timezone', timezoneValue?.timezone_name)
  }, [timezoneValue, setValue])

  React.useEffect(() => {
    if (hasTimezoneAutodetection) {
      const value = data?.find(
        ({ timezone_name }) => timezone_name === Intl.DateTimeFormat().resolvedOptions().timeZone
      )

      value && setTimezoneValue(value)
    }
  }, [data, hasTimezoneAutodetection, setValue])

  const inputOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = data?.find(
      ({ timezone_representation_name }) => timezone_representation_name === event.target.value
    )
    value && setTimezoneValue(value)
  }

  return (
    <AutocompleteWrapper height={48}>
      <Typography color={hasTimezoneAutodetection ? '#8F929B' : ''} mb='4px'>
        Time zone
      </Typography>
      <Autocomplete
        disabled={hasTimezoneAutodetection}
        options={data || []}
        noOptionsText='Nothing found'
        value={timezoneValue}
        getOptionLabel={option => option.timezone_representation_name}
        isOptionEqualToValue={(option, value) => option.timezone_name === value.timezone_name}
        renderInput={params => (
          <Input variant='outlined' {...params} name={name} onBlur={inputOnBlur} />
        )}
        sx={{
          '& .MuiInputBase-root': {
            paddingTop: '5px',
            paddingBottom: '5px',
          },
        }}
      />
    </AutocompleteWrapper>
  )
}
