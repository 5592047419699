import { EditInfo, SaveInfo } from './components'
import { ButtonBox } from './styles'

interface ActionButtonsProps {
  isEdit: boolean
  toggleEditing: () => void
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ isEdit, toggleEditing }) => {
  return <ButtonBox>{isEdit ? <SaveInfo /> : <EditInfo toggleEditing={toggleEditing} />}</ButtonBox>
}
