import React from 'react'
import { useDispatch } from 'react-redux'
import { useController } from 'react-hook-form'

import { PureChip, PureDropdownAutocomplete } from '@components/ui'
import { sampleCreate, setCellValueToSample } from '@state/sampleCreate'
import { IOption } from '@models/common/app'
import { IOptionExtended } from '@components/ui/PureDropdownAutocomplete/pureDropdownAutocomplete.types'
import { setAddedSkills, setTriggerFlag } from '@state/common/sampleCreateUIState'
import { useAppSelector } from '@hooks/redux'
import { IFunding } from '@models/projects'

import { PureCellPropTypes } from './pureFields.types'
import { StyledChipWrap } from './styles'

export const Funding: React.FC<PureCellPropTypes> = ({
  control,
  name,
  cellName,
  sampleId,
  rules,
  initialVal,
  inputPlaceholder,
  isCopy,
}) => {
  const dispatch = useDispatch()
  const [searchQuery, setSearchQuery] = React.useState('')
  const [options, setOptions] = React.useState<any>(initialVal.val || [])

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: [] })
  const projectOfSample = useAppSelector(sampleCreate).samples.find(
    sample => sample.id === sampleId
  )?.project.val

  const fundingOfProjects = React.useMemo(() => {
    const funding =
      projectOfSample &&
      projectOfSample?.funding
        .filter((fund: IFunding) => fund.value.indexOf(searchQuery) !== -1)
        .map(({ value, ...rest }: any) => ({ ...rest, name: value }))
    return funding
  }, [projectOfSample, searchQuery])

  React.useEffect(() => {
    if (!projectOfSample) {
      setOptions([])
    }
  }, [projectOfSample])

  const pureOptions = fundingOfProjects

  const correctOptions = React.useMemo(() => {
    if (pureOptions) {
      return (
        pureOptions?.filter(({ id }: any) => !options.some((option: any) => option.id === id)) || []
      )
    }
    return []
  }, [pureOptions, options])

  const setCellValue = (val: IOption[] | null, error: string) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val, error },
        cellName,
        sampleId,
      })
    )
  }

  React.useEffect(() => {
    if (error && error.message) setCellValue(field.value, error.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onDropdownChange = (val: IOptionExtended) => {
    setOptions((prevState: any) => {
      const newArr = [...prevState, val]
      field.onChange({ target: { value: newArr } })
      setCellValue(newArr, '')
      return newArr
    })

    if (val.skills?.length) dispatch(setAddedSkills(val.skills))

    if (searchQuery) setSearchQuery('')
  }

  const handleDeleteSelectedOption = (id: number) => {
    const newArr = options.filter(({ id: optionId }: any) => optionId !== id)
    field.onChange({
      target: { value: newArr },
    })
    setCellValue(newArr, '')
    setOptions(newArr)
    dispatch(setTriggerFlag())
  }

  return (
    <>
      <PureDropdownAutocomplete
        disabledOptions={options.length > 5}
        isCopy={isCopy}
        name={name}
        inputPlaceholder={inputPlaceholder}
        options={correctOptions as IOptionExtended[]}
        errorMessage={error?.message || ''}
        onInputChange={val => setSearchQuery(val)}
        onDropdownChange={onDropdownChange}
        isCleanInputAfterSelectOption
        disabled={!projectOfSample}
      />
      <StyledChipWrap>
        {options.map((opt: any) => (
          <PureChip
            tooltipWidth={'calc(100% - 38px)'}
            truncatedLabelLength={23}
            key={opt.id}
            isMainChip
            label={opt.name}
            onDelete={() => handleDeleteSelectedOption(opt.id)}
          />
        ))}
      </StyledChipWrap>
    </>
  )
}
