import { createTheme } from '@mui/material/styles'

export const defaultTheme = createTheme({
  zIndex: {
    modal: 500,
    tooltip: 170,
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#3698E5',
      light: '#929292',
    },
    secondary: {
      main: '#495C82',
      light: '#8f929b',
    },
    error: {
      main: '#AF4645',
    },
    text: {
      primary: '#0F1934',
      secondary: '#495C82',
    },
    background: {
      default: '#F8FBFF',
    },
    warning: {
      main: '#FFB135',
    },
    success: {
      main: '#7EDE6E',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '42px',
    },
    h2: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '2rem',
    },
    h3: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '28px',
    },
    h4: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '32px',
    },
    h5: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px',
    },
    h6: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
    },
    body2: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '21px',
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '27px',
    },
    button: {
      textTransform: 'none',
      fontSize: '16px',
    },
  },
  text: {
    subtitles: {
      main: '#ADB4C2',
    },
    secondary: {
      main: '#495C82',
    },
  },
  additional: {
    icons: {
      main: '#ADB4C2',
    },
    stroke: {
      main: '#E1E7F3',
    },
  },
  primary: {
    90: '#0A2C6D',
    80: '#113E84',
    70: '#85D1F7',
    60: '#2776C4',
    40: '#66BAEF',
    30: '#F2F7FF',
    20: '#AFE7FC',
    10: '#D6F5FD',
  },
  header: {
    height: 59,
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        size: 'large',
      },
      styleOverrides: {
        outlined: {
          color: '#495C82',
          border: '1px solid #E1E7F3',
        },
        contained: {
          backgroundColor: '#3698E5',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: '#E2F5EA',
        },
        standardError: {
          backgroundColor: '#FCE4E4',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
  },
})
