import { Typography } from '@mui/material'

import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'
import { TooltipMobile } from '@components/shared/Tooltip/components'

interface ManufactureProps {
  address?: string
}

export const ManufacturerAddress: React.FC<ManufactureProps> = ({ address }) => {
  return (
    <div>
      <TooltipMobile
        title={address || ''}
        shouldBeHidden={((address && address?.length) || 0) <= TRUNCATED_TEXT_LENGTH_S}
      >
        <Typography variant='body1'>
          {address ? stringSlice(address, TRUNCATED_TEXT_LENGTH_S) : '-'}
        </Typography>
      </TooltipMobile>
    </div>
  )
}
