import React from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'

import { IColumnHeader } from '@models/common/ui'
import { StyledTableSortWrapper, StyledTableCell, StyledTableTitle } from '@styles'

export const TableColumnHeader: React.FC<Omit<IColumnHeader, 'id'>> = ({ content, ordering }) => {
  const [queryString, setQueryString] = useQueryParam('ordering', StringParam)
  const [index, setIndex] = React.useState(0)

  //sorting has three states: off, ascending and descending order
  const options = React.useMemo(() => [undefined, ordering, `-${ordering}`], [ordering])

  React.useEffect(() => {
    if (ordering) {
      const optionIndex = options.findIndex(option => option === queryString)

      setIndex(optionIndex !== -1 ? optionIndex : 0)
    }
  }, [options, ordering, queryString])

  const onClickHandler = () => {
    if (ordering) {
      let nextIndex = index + 1

      if (nextIndex === options.length) nextIndex = 0

      setQueryString(options[nextIndex])
    }
  }

  return (
    <StyledTableCell>
      <StyledTableTitle>{content}</StyledTableTitle>

      {ordering && (
        <StyledTableSortWrapper onClick={onClickHandler}>
          <IoMdArrowDropup color={index === 2 ? '#000000' : '#ADB4C2'} />
          <IoMdArrowDropdown color={index === 1 ? '#000000' : '#ADB4C2'} />
        </StyledTableSortWrapper>
      )}
    </StyledTableCell>
  )
}
