import React from 'react'
import { useHistory } from 'react-router-dom'

import { emailConfirmationRoute } from '@constants/routes'
import { USER } from '@constants/common'
import { logout } from '@state/user'
import { logoutUser } from '@api/user'
import { useAppDispatch } from '@hooks/redux'

import { App } from './App'

export const AppHOC = () => {
  const [createApp, setCreateApp] = React.useState(false)

  const history = useHistory()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (emailConfirmationRoute.test(history.location.pathname)) {
      if (localStorage.getItem(USER)) {
        dispatch(logout())
        logoutUser().finally(() => {
          setCreateApp(true)
        })
      } else {
        setCreateApp(true)
      }
    } else {
      setCreateApp(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return createApp ? <App /> : null
}
