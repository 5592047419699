import React from 'react'
import { Box, useTheme } from '@mui/material'

import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from '@components/shared/ExpandableApis/styles'
import { useToggle } from '@hooks/common'
import { SAMPLES } from '@constants/queries'
import { SampleTasks } from '@components/modules'
import { ConditionalHidden } from '@components/shared'
import { viewSampleTableWidths, sampleTasksTable } from '@constants/mappings'
import { StyledSampleSplitLine } from '@styles'
import { selectUserCustomizeTableColumns } from '@state/user'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { ITableColumnWidths } from '@models/common/user'
import { selectExpandedSamples } from '@state/samples'

import { SampleCells } from './SampleCells'
import { SampleRowProps } from './sampleRow.types'
import { StyledTableRow } from '../styles'

export const SampleRow: React.FC<SampleRowProps> = React.memo(props => {
  const {
    sample,
    parents,
    hiddenSplitLinks,
    isSampleSelected,
    isLastSample,
    setTotalExpandedCells,
    totalExpandedCells,
    setReferredTableWidth,
    referredTableWidth,
    isFetchingSamples,
    isLoadingSamples,
  } = props
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const [isExpandedRow, toggleExpanded] = useToggle()
  const [referredWidth, setReferredWidth] = React.useState<string>()

  const { sample_columns, componentShouldBeRerender } = useAppSelector(
    selectUserCustomizeTableColumns
  )

  const {
    id: sampleId,
    laboratory: { id: labId },
  } = sample

  const headerContent = sampleTasksTable

  React.useEffect(() => {
    if (isExpandedRow) {
      dispatch(selectExpandedSamples({ sampleId }))
    } else {
      dispatch(selectExpandedSamples({ sampleId, deleteFlag: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpandedRow])

  const changeHandler = (e: React.SyntheticEvent<Element, Event>) => {
    const notExpandElement = (e.target as HTMLParagraphElement).closest('#not-expand')
    const notExpandedPortal = (e.target as HTMLParagraphElement).closest('[role=presentation]')

    if (notExpandElement || notExpandedPortal) {
      return
    }

    if (isExpandedRow) setTotalExpandedCells(totalExpandedCells - 1)
    else setTotalExpandedCells(totalExpandedCells + 1)

    toggleExpanded()
  }

  const accordionRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    setReferredWidth(accordionRef.current?.offsetWidth + 'px')
  }, [totalExpandedCells, componentShouldBeRerender])

  const containerRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    setTimeout(() => {
      setReferredTableWidth(containerRef.current?.clientWidth + 'px')
    }, 200)
  }, [
    containerRef,
    isExpandedRow,
    totalExpandedCells,
    setReferredTableWidth,
    componentShouldBeRerender,
  ])

  React.useEffect(() => {
    setReferredTableWidth('')
    setTotalExpandedCells(totalExpandedCells + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentShouldBeRerender])

  const filteredSampleTableWidths = sample_columns
    .filter(column => column.activated)
    .map(column => viewSampleTableWidths.find(cell => cell.id === column.id)) as ITableColumnWidths

  return (
    <Box
      sx={{
        position: 'relative',
        width: isExpandedRow ? 'max-content' : '100%',
      }}
    >
      <Box>
        <ConditionalHidden condition={hiddenSplitLinks}>
          {parents.map(parent => (
            <StyledSampleSplitLine key={parent} />
          ))}
        </ConditionalHidden>
      </Box>

      <StyledAccordion expanded={isExpandedRow} onChange={changeHandler}>
        <StyledAccordionSummary
          ref={accordionRef}
          direction='row'
          sx={{ width: '100% !important' }}
        >
          <Box ref={containerRef} sx={{ width: '100%' }}>
            <StyledTableRow
              $isBorderBottom={isExpandedRow || isLastSample}
              $isBackgroundColor={isSampleSelected}
              columns={filteredSampleTableWidths}
              sx={{
                backgroundColor: isSampleSelected ? theme.primary[30] : '#fff',
                width: totalExpandedCells > 0 ? referredTableWidth : '100%',
              }}
            >
              <SampleCells
                sample={sample}
                isLastSample={!isExpandedRow && isLastSample}
                isSampleSelected={isSampleSelected}
                isFetchingSamples={isFetchingSamples}
                isLoadingSamples={isLoadingSamples}
              />
            </StyledTableRow>
          </Box>
        </StyledAccordionSummary>

        <StyledAccordionDetails direction='row'>
          {isExpandedRow && (
            <SampleTasks
              isFetchingSamples={isFetchingSamples}
              greatestTasksAmount={sample.tasks_count}
              splitStatus={sample.split_status}
              labId={labId}
              referredWidth={referredWidth}
              contentType={SAMPLES}
              sampleId={sampleId}
              headerContent={headerContent}
            />
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </Box>
  )
})
