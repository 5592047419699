import { IconButton, styled as StyledMUI } from '@mui/material'
import styled from 'styled-components'

export const StyledPageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 12px;
`

export const StyledBox = styled.div`
  display: flex;
  align-items: center;
`

export const StyledImageButton = StyledMUI(IconButton)`
  margin-left: 14px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid #e5e5e6;
  width: 40px;
  height: 40px;
`
