import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useQueryClient } from 'react-query'
import { cloneDeep } from 'lodash'
import { useDispatch } from 'react-redux'

import { ITaskApiResult, UpdateIdPADResultTypes } from '@models/tasks'
import { TASKS } from '@constants/queries'
import { useUpdateTask } from '@hooks/queries/useUpdateTask'
import { EditTestResultsModal } from '@components/modules'
import {
  DELETED_RECOGNIZED,
  RESULT_RECOGNIZED,
  SUBSTANCES,
  TEST_NOTES,
  TEST_STRIP_ATTACHMENTS,
} from '@constants/tests'
import { removeTrailingZeros } from '@helpers/removeTrailingZeros'
import { COMPLETED } from '@constants/tasks'
import { setTestUpdatingId } from '@state/test'

import { EditingExitModal } from './EditingExitModal'
import { Notes } from './Notes'
import { Attachments } from './Attachments'
import { Substances } from './Substances'
import { StyledSectionsBox } from '../ScreeningEditResults/styles'
import { ScreeningEditResultsPropTypes } from '../ScreeningEditResults/screeningEditResults.types'

export const HrIdPADEditResults: React.FC<ScreeningEditResultsPropTypes> = ({
  task,
  onClose,
  isEditFromCompletedTable,
}) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const updateTaskM = useUpdateTask()
  const client = useQueryClient()
  const dispatch = useDispatch()

  const handleSave = ({ fields }: { fields: UpdateIdPADResultTypes }) => {
    const clonedData = cloneDeep(fields)

    if (fields[RESULT_RECOGNIZED]) {
      delete clonedData[SUBSTANCES]
      delete clonedData[DELETED_RECOGNIZED]
    } else if (!fields[RESULT_RECOGNIZED] && !fields[SUBSTANCES]?.length) {
      delete clonedData[SUBSTANCES]
      delete clonedData[DELETED_RECOGNIZED]
      clonedData.result_is_not_recognized = true
    } else {
      fields[SUBSTANCES]?.forEach((item: ITaskApiResult, idx: number) => {
        if (item.is_not_determined) {
          delete clonedData[SUBSTANCES]?.[idx].percentage
        } else {
          delete clonedData[SUBSTANCES]?.[idx].is_not_determined
        }
      })
    }

    if (!fields[DELETED_RECOGNIZED]?.length) delete clonedData[DELETED_RECOGNIZED]

    updateTaskM.mutate({ taskId: task.id, fields: clonedData })
  }

  const initialFieldsData = React.useMemo(() => {
    const substancesFormData =
      task.substance_result?.map((substance: ITaskApiResult) => ({
        name: substance.name,
        id: substance.id,
        is_not_determined: substance.is_not_determined,
        percentage: substance.percentage ? `${removeTrailingZeros(substance.percentage)}` : '',
      })) || []

    return {
      task,
      fields: {
        [SUBSTANCES]: substancesFormData.length
          ? substancesFormData
          : [{ name: '', percentage: '', is_not_determined: false }],
        [TEST_STRIP_ATTACHMENTS]: task.attachments.map(attachment => attachment.id) || [],
        [TEST_NOTES]: task.test_notes || '',
        [DELETED_RECOGNIZED]: [],
        [RESULT_RECOGNIZED]: task.result_is_not_recognized,
      } as UpdateIdPADResultTypes, // required "initialFieldsData" key
    }
  }, [task])

  React.useEffect(() => {
    if (updateTaskM.isSuccess) {
      onClose()
      updateTaskM.reset()
      if (isEditFromCompletedTable) {
        client.invalidateQueries(COMPLETED)
        dispatch(setTestUpdatingId(task.id))
      } else client.invalidateQueries([TASKS, task.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTaskM])

  return (
    <EditTestResultsModal
      onClose={onClose}
      isCloseFromSomeComponentInside
      initialFieldsData={initialFieldsData}
      modalTitle='Edit results'
      modalSubtitle='You can edit test results here.'
      handleAction={handleSave}
      isLoading={updateTaskM.isLoading}
    >
      <StyledSectionsBox $isMobile={isMobile}>
        <Substances />
        <Notes />
        <Attachments />
        <EditingExitModal closeEditResultsModal={onClose} />
      </StyledSectionsBox>
    </EditTestResultsModal>
  )
}
