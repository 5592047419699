import * as React from 'react'

import { StyledHorizontalScrollCup } from './styles'

type Ref = HTMLDivElement

export const HorizontalDragCup = React.forwardRef<Ref>((props, ref) => {
  return (
    <StyledHorizontalScrollCup ref={ref}>
      <div className='horizontal-scroll-box-inside' />
    </StyledHorizontalScrollCup>
  )
})
