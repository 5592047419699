import React from 'react'
import { Box, useMediaQuery } from '@mui/material'

import { useToggle } from '@hooks/common'
import { useUpdateTaskNotes } from '@hooks/queries'
import { ContentOfPage } from '@constants/queries'
import { ITaskNote } from '@models/tasks'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'

import { EditTaskNotesField } from './EditTaskNotesField'
import { ViewTaskNotesField } from './ViewTaskNotesField'
import { EditTaskNotesModal } from './EditTaskNotesModal'

interface TaskNotesFieldProps {
  sampleId: number | string
  isAbleToAddTaskNotes?: boolean
  notes: ITaskNote[] | []
  contentType: ContentOfPage
  taskInfoInTest?: boolean
  taskId: number
  top?: string
  left?: string
  width?: string
  isOtherTestType?: boolean
  notesWithoutTruncate?: boolean
  mtEditIcon?: string
  editDetailsNotes?: boolean
}

export const TaskNotesField: React.FC<TaskNotesFieldProps> = props => {
  const {
    notes,
    sampleId,
    taskId,
    taskInfoInTest,
    contentType,
    top,
    left,
    width,
    isAbleToAddTaskNotes,
    isOtherTestType,
    notesWithoutTruncate,
    mtEditIcon,
    editDetailsNotes,
  } = props

  const isMobile = useMediaQuery('(max-width:767px)')
  const { first_name: firstName, last_name: lastName } = useAppSelector(selectUser)
  const [isEditing, toggleEditing] = useToggle()

  const updateTaskNotesM = useUpdateTaskNotes()
  const userAlreadyHasNotes =
    notes.some(note => note.user.first_name === firstName && note.user.last_name === lastName) &&
    notes.some(note => !note.read_only)

  const isCellWithoutNotes = !notes.length

  const userCanEddNewNotes =
    isCellWithoutNotes || !notes.some(note => !note.read_only) || !userAlreadyHasNotes

  const renderNotes = React.useMemo(
    () => (
      <>
        {notes.map(note => (
          <Box key={note.id} position={taskInfoInTest ? 'static' : 'relative'} width='100%'>
            {isEditing && !note.read_only ? (
              <>
                {isMobile || taskInfoInTest ? (
                  <EditTaskNotesModal
                    contentType={contentType}
                    note={note}
                    sampleId={sampleId}
                    toggleEditing={toggleEditing}
                    taskId={taskId}
                    updateTaskNotesM={updateTaskNotesM}
                    isEditNotesTaskInfoInTest={taskInfoInTest}
                  />
                ) : (
                  <EditTaskNotesField
                    contentType={contentType}
                    note={note}
                    toggleEditing={toggleEditing}
                    taskId={taskId}
                    updateTaskNotesM={updateTaskNotesM}
                    editDetailsNotes={editDetailsNotes}
                  />
                )}
              </>
            ) : (
              <ViewTaskNotesField
                isAbleToAddTaskNotes={isAbleToAddTaskNotes}
                isCellWithoutNotes={isCellWithoutNotes}
                note={note}
                toggleEditing={toggleEditing}
                taskInfoInTest={taskInfoInTest}
                taskId={taskId}
                top={top}
                left={left}
                notesWithoutTruncate={notesWithoutTruncate}
                mtEditIcon={mtEditIcon}
              />
            )}
          </Box>
        ))}
      </>
    ),
    [
      contentType,
      editDetailsNotes,
      isAbleToAddTaskNotes,
      isCellWithoutNotes,
      isEditing,
      isMobile,
      left,
      mtEditIcon,
      notes,
      notesWithoutTruncate,
      sampleId,
      taskId,
      taskInfoInTest,
      toggleEditing,
      top,
      updateTaskNotesM,
    ]
  )

  const addNewNotes = React.useMemo(
    () => (
      <>
        {isEditing ? (
          <>
            {isMobile || taskInfoInTest ? (
              <EditTaskNotesModal
                contentType={contentType}
                sampleId={sampleId}
                toggleEditing={toggleEditing}
                taskId={taskId}
                updateTaskNotesM={updateTaskNotesM}
                isEditNotesTaskInfoInTest={taskInfoInTest}
              />
            ) : (
              <EditTaskNotesField
                contentType={contentType}
                toggleEditing={toggleEditing}
                taskId={taskId}
                updateTaskNotesM={updateTaskNotesM}
              />
            )}
          </>
        ) : (
          <ViewTaskNotesField
            isAbleToAddTaskNotes={isAbleToAddTaskNotes}
            toggleEditing={toggleEditing}
            taskId={taskId}
            top={top}
            left={left}
          />
        )}
      </>
    ),
    [
      contentType,
      isAbleToAddTaskNotes,
      isEditing,
      isMobile,
      left,
      sampleId,
      taskId,
      taskInfoInTest,
      toggleEditing,
      top,
      updateTaskNotesM,
    ]
  )

  if (isOtherTestType) return null

  return (
    <Box width={width}>
      {renderNotes}
      {userCanEddNewNotes && addNewNotes}
    </Box>
  )
}
