import React from 'react'

import { Modal, Button } from '@components/shared'

import { StyledTitle, ButtonBox } from './styles'

interface DeactivateSamplesProps {
  onClose: () => void
  samplesAmount: number
}

export const DeactivateSamples: React.FC<DeactivateSamplesProps> = ({ onClose, samplesAmount }) => {
  return (
    <Modal open onClose={onClose}>
      <>
        <StyledTitle variant='h3'>
          Are you sure you want to deactivate <br /> <span>{samplesAmount}</span> samples?
        </StyledTitle>

        <ButtonBox>
          <Button
            type='button'
            variant='outlined'
            color='secondary'
            width='205px'
            mr='60px'
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button type='submit' variant='contained' width='205px'>
            Deactivate
          </Button>
        </ButtonBox>
      </>
    </Modal>
  )
}
