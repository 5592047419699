import { ISample } from '@models/samples'
import { ExpandableExcipients } from '@components/shared'
import { StyledTableSampleCell } from '@styles'

interface APIsCellProps {
  sample: ISample
  direction?: 'column' | 'row'
  isMobile?: boolean
}

export const ExcipientsCell: React.FC<APIsCellProps> = ({ sample, direction, isMobile }) => {
  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      {sample.excipients.length ? (
        <ExpandableExcipients
          excipients={sample.excipients}
          borderBottom='none'
          direction={direction}
        />
      ) : (
        '-'
      )}
    </StyledTableSampleCell>
  )
}
