import React from 'react'

import { useAppSelector } from '@hooks/redux'
import { useTogglePackage } from '@hooks/common'
import { Checkbox, Tooltip } from '@components/shared'
import { IPackage, PackageContentType } from '@models/packages'
import { MAX_SELECTED_PACKAGE_NUMBER } from '@constants/packages'
import { selectPackages, selectPackagesState } from '@state/packages'
import { Image } from '@styles'
import infoIcon from '@assets/icons/info-circle-xs.svg'

import { StyledTableCell } from '../../../styles'

interface TogglerProps {
  pack: IPackage
  contentType: PackageContentType
  isLastPack?: boolean
}

export const Toggler: React.FC<TogglerProps> = ({ pack, isLastPack, contentType }) => {
  const { selectedPackages } = useAppSelector(selectPackagesState)

  const { selectPackageHandler, isSelected } = useTogglePackage(
    pack,
    selectPackages,
    selectedPackages
  )

  const needsToDisable =
    (selectedPackages.length >= MAX_SELECTED_PACKAGE_NUMBER &&
      !selectedPackages.find(({ id }) => id === pack.id)) ||
    !pack.quantity

  return (
    <StyledTableCell
      $isLastPack={isLastPack}
      $isPackSelected={isSelected}
      sx={{ alignItems: 'center' }}
      $isDefaultContentType={contentType === 'default'}
    >
      {!pack.quantity && (
        <Tooltip title='There are no samples in this package'>
          <Image
            component='img'
            src={infoIcon}
            width='18px'
            height='18px'
            sx={{ position: 'absolute', left: '77px', top: '24px', zIndex: '1' }}
          />
        </Tooltip>
      )}

      <Checkbox
        ml='44px'
        padding='0'
        checked={isSelected}
        disabled={needsToDisable}
        onClick={selectPackageHandler}
      />
    </StyledTableCell>
  )
}
