import React from 'react'

import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'

import { AssigneeFilterCentralLab } from './AssigneeFilterComponents/AssigneeFilterCentralLab'
import { AssigneeFilter as AssigneeFilterAllLab } from './AssigneeFilterComponents/AssigneeFilter'

export const AssigneeFilter = React.memo(() => {
  const { laboratory } = useAppSelector(selectUser)
  const isCentralLab = laboratory?.group.name === 'Central Lab.'

  return (
    <>
      {isCentralLab ? (
        <AssigneeFilterCentralLab isCentralLab={isCentralLab} />
      ) : (
        <AssigneeFilterAllLab />
      )}
    </>
  )
})
