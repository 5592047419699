import React from 'react'
import { FormControlLabelProps, RadioGroup, useRadioGroup } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { StyledRadio } from '@styles'
import { HrTestMethod } from '@models/tasks'
import { DrugsModal } from '@components/shared/Hr/TandemMS'
import { TextLink } from '@components/shared/Hr/TandemMS/styles'
import { isOtherMethod } from '@components/shared/Hr/TandemMS/helpers'

import { StyledFormControl, StyledFormControlLabel } from './styles'

interface Props {
  setIsCheckedValue: React.Dispatch<React.SetStateAction<boolean>>
  tandemMSMethods?: HrTestMethod[]
  selectedTandemMSMethod?: HrTestMethod
  isAddedNewTandemMSTest: boolean
}

export const TandemMSResult: React.FC<Props> = props => {
  const { setIsCheckedValue, tandemMSMethods, selectedTandemMSMethod, isAddedNewTandemMSTest } =
    props

  const { register } = useFormContext<Record<string, string>>()
  const [method, setMethod] = React.useState<HrTestMethod | null>(null)
  const onClose = () => setMethod(null)

  const MyFormControlLabel = (props: FormControlLabelProps) => {
    const radioGroup = useRadioGroup()

    let checked = selectedTandemMSMethod?.name
      ? selectedTandemMSMethod?.name === props.value && !isAddedNewTandemMSTest
      : false

    if (checked) setIsCheckedValue(checked)

    if (!!radioGroup?.value) {
      checked = radioGroup.value === props.value
      if (checked) setIsCheckedValue(checked)
    }
    return <StyledFormControlLabel {...register('result')} checked={checked} {...props} />
  }

  return (
    <div>
      <StyledFormControl>
        <RadioGroup name='use-radio-group'>
          {tandemMSMethods?.map(method => (
            <div key={method.id} style={{ marginBottom: '12px' }}>
              <MyFormControlLabel
                value={method.name}
                control={<StyledRadio />}
                label={method.name}
              />

              {isOtherMethod(method.name) ? null : (
                <span style={{ color: '#5e91f7' }}>
                  {' '}
                  (<TextLink onClick={() => setMethod(method)}>{method.description}</TextLink>)
                </span>
              )}
            </div>
          ))}
        </RadioGroup>
      </StyledFormControl>

      <DrugsModal method={method} open={!!method} onClose={onClose} />
    </div>
  )
}
