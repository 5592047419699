import { useTheme } from '@mui/material'

export const QrCodeIcon: React.FC = () => {
  const theme = useTheme()

  return (
    <svg
      width='138'
      height='250'
      viewBox='0 0 138 250'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_8463_271420)'>
        <path
          d='M122.749 210.953L110.282 117.71L66.2289 41.6094C66.2289 41.6094 52.1081 50.1437 59.8109 69.6094C67.5137 89.0752 92.3435 145.932 92.3435 145.932L122.749 210.953Z'
          fill='#F3CABB'
        />
        <path
          d='M66.9917 215.377L93.3645 320.335L159.678 305.638L122.75 210.953L66.9917 215.377Z'
          fill='#F3CABB'
        />
        <path
          d='M96.0076 216.278L87.401 152.871L46.4771 155.49C46.4771 155.49 42.0324 167.364 43.1451 184.778C44.0058 198.223 54.0323 218.687 67.9626 221.386C73.6921 222.495 79.2802 221.06 84.8498 219.846C85.9994 219.593 96.2412 217.979 96.0076 216.278Z'
          fill='#D2A094'
        />
        <path
          d='M77.3241 4.14844H16.9924C9.47551 4.14844 3.38184 10.2565 3.38184 17.7911V164.471C3.38184 172.006 9.47551 178.114 16.9924 178.114H77.3241C84.841 178.114 90.9347 172.006 90.9347 164.471V17.7911C90.9347 10.2565 84.841 4.14844 77.3241 4.14844Z'
          fill={theme.primary[30]}
        />
        <path
          d='M76.4947 0H17.829C7.8885 0 -0.170898 8.07836 -0.170898 18.0423V164.223C-0.170898 174.187 7.8885 182.265 17.829 182.265H76.4947C86.4353 182.265 94.4947 174.187 94.4947 164.223V18.0423C94.4947 8.07836 86.4353 0 76.4947 0ZM90.9352 164.47C90.9352 172.006 84.8431 178.112 77.3247 178.112H16.993C9.47455 178.112 3.38236 172.006 3.38236 164.47V17.7958C3.38236 10.2597 9.47455 4.15318 16.993 4.15318H77.3247C84.8431 4.15318 90.9352 10.2597 90.9352 17.7958V164.476V164.47Z'
          fill='#8C95A6'
        />
        <path
          d='M29.7566 2.80469H64.5638V4.68409C64.5638 7.77124 62.0618 10.2853 58.9758 10.2853H35.3386C32.2587 10.2853 29.7505 7.7774 29.7505 4.68409V2.80469H29.7566Z'
          fill='#8C95A6'
        />
        <path d='M51.4949 5.01953H37.0913V6.19096H51.4949V5.01953Z' fill='#8C95A6' />
        <path
          d='M57.237 5.61358C57.237 6.06982 56.8681 6.44592 56.4071 6.44592C55.946 6.44592 55.5771 6.07599 55.5771 5.61358C55.5771 5.15117 55.946 4.78125 56.4071 4.78125C56.8681 4.78125 57.237 5.15117 57.237 5.61358Z'
          fill='#8C95A6'
        />
        <path
          d='M34.0925 58.6172H21.127V71.5629H34.0925V58.6172ZM32.1108 69.5842H23.1087V60.5959H32.1108V69.5842Z'
          fill='#616D84'
        />
        <path d='M30.0953 62.5977H25.1279V67.5574H30.0953V62.5977Z' fill='#616D84' />
        <path
          d='M61.3496 58.6172V71.5629H74.3152V58.6172H61.3496ZM72.3334 69.5842H63.3314V60.5959H72.3334V69.5842Z'
          fill='#616D84'
        />
        <path d='M70.3151 62.5977H65.3477V67.5574H70.3151V62.5977Z' fill='#616D84' />
        <path
          d='M21.127 111.723H34.0925V98.7773H21.127V111.723ZM23.1087 100.756H32.1108V109.744H23.1087V100.756Z'
          fill='#616D84'
        />
        <path d='M30.0992 102.762H25.1318V107.721H30.0992V102.762Z' fill='#616D84' />
        <path d='M42.3965 58.6172H40.27V60.7404H42.3965V58.6172Z' fill='#616D84' />
        <path
          d='M48.7793 67.1126V69.2358H50.9058V67.1126V64.9893H53.0324H55.1589V67.1126H53.0324V69.2358V71.3591H50.9058H48.7793V69.2358H46.6504V71.3591H44.5239V73.4823H46.6504H48.777H46.6504V75.6079H48.777H48.7793H50.9058V73.4823H53.0324V75.6079H55.1589H57.2878H59.4143V73.4823H57.2878V71.3591V69.2358H59.4143V67.1126H57.2878V64.9893H59.4143V62.8637V60.7404V58.6172H57.2878V60.7404H57.2854V62.8637H55.1589V60.7404V58.6172H53.0324H50.9058V60.7404V62.8637H48.7793V64.9893H46.6504V67.1126H48.7793Z'
          fill='#616D84'
        />
        <path
          d='M40.2706 75.6032H44.5236V73.4799H42.3971V69.2334H46.6501V67.1102H44.5236V64.9869H46.6501V62.8637V60.7404H48.7767V58.6172H44.5236V60.7404V62.8637V60.7404H42.3971V62.8637H38.1441V58.6172H36.0176V64.9893H38.1441V67.1126H36.0176V69.2358H38.1441V71.3591H36.0176V73.4823V75.6056H40.2706V75.6032ZM38.1441 67.1078H40.2706V69.2311H38.1441V67.1078Z'
          fill='#616D84'
        />
        <path
          d='M46.6504 77.7188H44.5239V79.8444V81.9676H46.6504V79.8444V77.7188Z'
          fill='#616D84'
        />
        <path d='M42.3965 88.3438H40.27V90.467H42.3965V88.3438Z' fill='#616D84' />
        <path d='M46.6504 88.3438H44.5239V90.467H46.6504V88.3438Z' fill='#616D84' />
        <path
          d='M44.5233 90.4715H42.3968V92.5948H40.2702V90.4715H38.1414V92.5948H36.0148V90.4715H38.1414V88.3483H36.0148H33.8883V90.4715H31.7618V92.5948H29.6329V90.4715H27.5064V88.3483V86.2227H25.3799V88.3483V90.4715H23.2534H21.1245V92.5948V94.718V96.8436H23.2534V94.718H25.3799H27.5064V96.8436H29.6329H31.7618V94.718H33.8883H36.0148V96.8436H38.1414V94.718H40.2702H42.3968H44.5233V92.5948V90.4715Z'
          fill='#616D84'
        />
        <path
          d='M25.3799 77.7326H27.5064V79.8582H29.6329H31.7618V81.9815H33.8883H36.0148H38.1414H40.2702V79.8582V77.7326V75.6094H38.1414H36.0148H33.8883H31.7618H29.6329H27.5064H25.3799H23.2534H21.1245V77.7326V79.8582H23.2534V77.7326H25.3799Z'
          fill='#616D84'
        />
        <path d='M27.5054 73.4883H25.3789V75.6115H27.5054V73.4883Z' fill='#616D84' />
        <path d='M31.7574 73.4883H29.6309V75.6115H31.7574V73.4883Z' fill='#616D84' />
        <path d='M23.251 73.4883H21.1245V75.6115H23.251V73.4883Z' fill='#616D84' />
        <path
          d='M57.2842 94.7092H59.4107H61.5372V92.5859H59.4107H57.2842V94.7092Z'
          fill='#616D84'
        />
        <path
          d='M70.0456 75.6094H67.9191H65.7902H63.6637H61.5372H59.4107H57.2842V77.7326V79.8582V81.9815H59.4107V79.8582H61.5372V81.9815H59.4107V84.1047V86.228V88.3536V90.4769H61.5372H63.6637V92.6001H65.7902H67.9191V90.4769H70.0456V88.3536V86.228V84.1047H72.1721H74.2987V81.9815H72.1721H70.0456H67.9191H65.7902V84.1047H67.9191V86.228V88.3536H65.7902H63.6637H61.5372V86.228H63.6637V84.1047V81.9815V79.8582V77.7326H65.7902H67.9191H70.0456H72.1721H74.2987V75.6094H72.1721H70.0456Z'
          fill='#616D84'
        />
        <path d='M63.6695 73.4883H61.543V75.6115H63.6695V73.4883Z' fill='#616D84' />
        <path d='M70.0499 73.4883H67.9233V75.6115H70.0499V73.4883Z' fill='#616D84' />
        <path d='M74.3047 73.4883H72.1782V75.6115H74.3047V73.4883Z' fill='#616D84' />
        <path
          d='M72.1763 88.3483V90.4715V92.5948H70.0498V94.718H67.9233H65.7944V96.8436H67.9233V98.9669H70.0498V101.09H67.9233V103.213H70.0498V105.337H67.9233V107.462V109.586H70.0498V111.709H72.1763H74.3028V109.586H72.1763V107.462V105.337V103.213V101.09V98.9669V96.8436V94.718H74.3028V92.5948V90.4715V88.3483V86.2227H72.1763V88.3483Z'
          fill='#616D84'
        />
        <path
          d='M53.0317 101.095H55.1582V98.9722V96.849V94.7234V92.6001V90.4769H57.2871V88.3536V86.228V84.1047H55.1582V86.228H53.0317V84.1047H55.1582V81.9815V79.8582V77.7326H53.0317H50.9052V75.6094H48.7763V77.7326H48.7787V79.8582V81.9815H46.6498V84.1047H44.5233H42.3968H40.2702H38.1414H36.0148H33.8883H31.7618V81.9815H29.6329H27.5064V79.8582H25.3799H23.2534V81.9815H21.1245V84.1047V86.228H23.2534V84.1047H25.3799H27.5064V86.228H29.6329V88.3536V90.4769H31.7618V88.3536H33.8883V86.228H36.0148H38.1414V88.3536H40.2702V86.228H42.3968V88.3536H44.5233V86.228H46.6498H48.7787H50.9052V88.3536V90.4769H53.0317V92.6001V94.7234H50.9052V92.6001H48.7787H46.6498V94.7234H44.5233V96.849H46.6498H48.7787H50.9052V98.9722H53.0317V101.095Z'
          fill='#616D84'
        />
        <path d='M65.796 92.5859H63.6694V94.7092H65.796V92.5859Z' fill='#616D84' />
        <path
          d='M65.792 103.206V98.9592H63.6655V96.8359H57.2836V101.082H55.157V103.206H59.4124V107.455H57.2859V105.331H55.1594V107.455H53.0329V103.206V101.082H50.9064V98.9592H46.6534V101.082H44.5269V105.329H46.6534V111.701H48.7799V103.206H50.9064V107.455H53.0329V109.578H50.9064V111.701H53.0329V109.578H55.1594V111.701H57.2859V109.578H61.539V103.206H63.6655V105.329H67.9209V103.206H65.7943H65.792ZM59.4124 101.082V98.9592H61.539V101.082H59.4124Z'
          fill='#616D84'
        />
        <path
          d='M36.0146 98.9592V101.082H38.1412V103.206H36.0146V105.329V107.455V109.578V111.701H38.1412V109.578V107.455H40.27V109.578H42.3966V111.701H44.5231V109.578V107.455H42.3966V105.329V103.206V101.082V98.9592V96.8359H40.27H38.1412V98.9592H36.0146Z'
          fill='#616D84'
        />
        <path
          opacity='0.56'
          d='M84.0648 70H11.6655V96.4409H84.0648V70Z'
          fill='url(#paint0_linear_8463_271420)'
        />
        <path
          d='M102.217 151.056L65.9162 118.902C65.9162 118.902 53.1478 130.438 66.6847 147.586C80.2215 164.735 83.2768 167.188 83.2768 167.188C83.2768 167.188 57.8937 195.379 87.494 224.457L122.75 210.956L102.217 151.062V151.056Z'
          fill='#F3CABB'
        />
        <path
          d='M16.2333 52.8661H25.1667V50.6328H15.1167C14.5 50.6328 14 51.1328 14 51.7495V61.7995H16.2333V52.8661Z'
          fill={theme.primary[70]}
        />
        <path
          d='M16.2333 106.465H14V116.515C14 117.132 14.5 117.632 15.1167 117.632H25.1667V115.398H16.2333V106.465Z'
          fill={theme.primary[70]}
        />
        <path
          d='M79.8835 50.6328H69.8335V52.8661H78.7668V61.7995H81.0002V51.7495C81.0002 51.1328 80.5002 50.6328 79.8835 50.6328Z'
          fill={theme.primary[70]}
        />
        <path
          d='M78.7669 115.398H72.0669V117.632H79.8836C80.5002 117.632 81.0002 117.132 81.0002 116.515V106.465H78.7669V115.398Z'
          fill={theme.primary[70]}
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_8463_271420'
          x1='47.8682'
          y1='96.4409'
          x2='47.8682'
          y2='70'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#75D5F9' />
          <stop offset='0.682292' stop-color='#E8F6FF' />
        </linearGradient>
        <clipPath id='clip0_8463_271420'>
          <rect width='138' height='250' rx='5' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
