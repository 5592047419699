import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { ConfirmationModal } from '@components/shared'
import {
  DELETED_RECOGNIZED,
  RESULT_RECOGNIZED,
  SUBSTANCES,
  TEST_NOTES,
  TEST_STRIP_ATTACHMENTS,
} from '@constants/tests'
import { IAttachment } from '@models/attachments'
import { ITaskApiResult } from '@models/tasks'
import { removeTrailingZeros } from '@helpers/removeTrailingZeros'

export const EditingExitModal: React.FC<{ closeEditResultsModal: () => void }> = props => {
  const { closeEditResultsModal } = props
  const { control, getValues, setValue } = useFormContext()
  const formData = useWatch({ control })
  const { task } = getValues()
  const [showModal, setShowModal] = React.useState(false)
  const [isChangedAnyField, setIsChangedAnyField] = React.useState(false)

  React.useEffect(() => {
    const { fields } = formData
    let isHasChanged: boolean = false

    for (let key in fields) {
      if (!isHasChanged) {
        if (key === TEST_STRIP_ATTACHMENTS) {
          // CHECKING ATTACHMENTS -----
          if (fields[key].length !== task[key].length) {
            isHasChanged = true
          } else if (fields[key].length) {
            // eslint-disable-next-line no-loop-func
            task[key].forEach((attachment: IAttachment) => {
              if (
                !fields[key].some((fieldId: number) => fieldId === attachment.id) &&
                !isHasChanged
              ) {
                isHasChanged = true
              }
            })
          }
        } else if ((key === TEST_NOTES || key === RESULT_RECOGNIZED) && fields[key] !== task[key]) {
          // CHECKING NOTES and RECOGNIZED RESULTS -----
          isHasChanged = true
        } else if (key === SUBSTANCES) {
          // CHECKING SUBSTANCES -----
          if (
            fields[DELETED_RECOGNIZED].length ||
            fields[key].length !== task.substance_result.length
          ) {
            isHasChanged = true
          } else {
            // eslint-disable-next-line no-loop-func
            fields[SUBSTANCES].forEach((substance: ITaskApiResult) => {
              const taskSubstance = task.substance_result.find(
                (taskSubstance: ITaskApiResult) => taskSubstance.id === substance.id
              )

              if (
                !isHasChanged &&
                (substance.name !== taskSubstance.name ||
                  substance.is_not_determined !== taskSubstance.is_not_determined ||
                  (taskSubstance.percentage &&
                    substance.percentage !== `${removeTrailingZeros(taskSubstance.percentage)}`) ||
                  (!taskSubstance.percentage && substance.percentage !== taskSubstance.percentage))
              ) {
                isHasChanged = true
              }
            })
          }
        }
      }
    }

    if (isHasChanged && !isChangedAnyField) setIsChangedAnyField(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  React.useEffect(() => {
    if (formData.isCloseModal) {
      if (isChangedAnyField) setShowModal(true)
      else closeEditResultsModal()
    }
    if (isChangedAnyField && formData.isActionBtnDisable) {
      setValue('isActionBtnDisable', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedAnyField, formData])

  const onBackButtonEvent = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    if (isChangedAnyField) setShowModal(true)
  }

  React.useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent)

    if (isChangedAnyField) {
      window.onbeforeunload = () => {
        return 'Are you sure you want to cancel sample editing?'
      }
    }

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
      window.onbeforeunload = () => {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedAnyField])

  const onClose = () => {
    setShowModal(false)
    setValue('isCloseModal', false)
  }

  return showModal ? (
    <ConfirmationModal
      title='Cancel test results edit?'
      subtitle={
        <span>
          If you cancel the editing of the test results, any changes
          <br />
          you have made so far will not be saved.
        </span>
      }
      cancelBtnText='No, continue editing'
      confirmButtonText='Yes, cancel editing'
      onClose={onClose}
      onConfirm={() => closeEditResultsModal()}
    />
  ) : null
}
