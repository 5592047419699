import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { SAMPLES } from '@constants/queries'
import { fetchSample } from '@api/samples'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export function useFetchSample<T>(sampleId: string) {
  const dispatch = useDispatch()
  return useQuery<unknown, IError, T>([SAMPLES, sampleId], fetchSample.bind(null, sampleId), {
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
