import { Box, Typography } from '@mui/material'

import { Tooltip } from '@components/shared'
import { TRUNCATED_SUBSTANCE_TEXT } from '@constants/common'
import { stringSlice } from '@helpers/ui/common'
import { removeTrailingZeros } from '@helpers/removeTrailingZeros'

import { TandemMSResultsProps } from '../tandemMS.types'
import { Icon } from './Icon'

export const TandemMSResultsItems: React.FC<TandemMSResultsProps> = props => {
  const { substanceResult, marginBottom, testDetailsPage } = props

  if (!substanceResult?.length)
    return (
      <Typography
        variant='body1'
        color='primary.light'
        sx={{ marginBottom: marginBottom ? marginBottom : '37px' }}
      >
        Substances were not recognized
      </Typography>
    )

  const textTruncatedLength = testDetailsPage ? TRUNCATED_SUBSTANCE_TEXT : 13

  return (
    <Box width={'fit-content'}>
      {substanceResult?.map(
        ({ name, concentration, concentration_type, clinically_meaningful }, idx) => (
          <Box
            key={idx}
            display='flex'
            alignItems='center'
            justifyContent={'space-between'}
            width={testDetailsPage ? '100%' : '340px'}
          >
            <Tooltip
              isPureTooltip
              title={name || ''}
              shouldBeHidden={((name && name.length) || 0) <= textTruncatedLength}
              maxWidth={250}
            >
              <Typography
                variant='body1'
                width={testDetailsPage ? '218px' : '138px'}
                lineHeight='0'
              >
                {name ? stringSlice(name, textTruncatedLength) : '-'}
              </Typography>
            </Tooltip>
            <Box display='flex' alignItems='center' justifyContent='flex-end'>
              <Typography variant='body1' lineHeight='0'>
                {removeTrailingZeros(concentration as string)}
              </Typography>
              <Typography variant='body1' lineHeight='0' color='secondary.light' ml='4px'>
                {concentration_type?.key}
              </Typography>
              <Icon clinicallyMeaningful={clinically_meaningful} />
            </Box>
          </Box>
        )
      )}
    </Box>
  )
}
