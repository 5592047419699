import React from 'react'
import { useHistory } from 'react-router-dom'

import { DETAILS } from '@constants/common'
import { protectedRoutes } from '@configs/routes.config'
import { FixedTableCell } from '@styles'
import { ISample } from '@models/samples'
import { ParagraphLink } from '@components/shared'

interface ActionCellProps {
  report: ISample
  isLastReport?: boolean
  hideBorder?: boolean
}

export const ActionCell: React.FC<ActionCellProps> = React.memo(props => {
  const { report, isLastReport, hideBorder } = props
  const history = useHistory()

  const sampleDetailsEndPoint = () => {
    history.replace({
      pathname: `${protectedRoutes.samples.path}/${report.id}${DETAILS}`,
      state: [
        {
          path: `${protectedRoutes.testingReports.path}`,
          url: `${protectedRoutes.testingReports.path}`,
          title: `Test reports`,
        },
      ],
    })
  }

  return (
    <FixedTableCell
      id='not-expand'
      sx={{ borderBottomLeftRadius: isLastReport ? '10px' : '0px' }}
      hideBorder={hideBorder}
    >
      <ParagraphLink onClick={sampleDetailsEndPoint}>View details</ParagraphLink>
    </FixedTableCell>
  )
})
