import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { useDispatch } from 'react-redux'

import { ASSIGNEE_PERSON } from '@constants/queries'
import { fetchAssigneePerson } from '@api/samples'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'
import { IAssigneeData } from '@models/samples'

export const useAssigneePerson = (
  sampleId: number,
  searchVal?: string
): UseInfiniteQueryResult<IAssigneeData> => {
  const dispatch = useDispatch()
  return useInfiniteQuery([ASSIGNEE_PERSON, sampleId, searchVal], fetchAssigneePerson, {
    getNextPageParam: lastPage => {
      if (lastPage.current_page < lastPage.count_pages) return lastPage.current_page + 1
      return false
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
