import React from 'react'

import { selectUser } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { useFetchTimezones } from '@hooks/queries'

import { InputField, InputFieldProps, PhoneField, RadioGroup } from './components'
import { InfoBox, StyledBox, StyledFieldTitle, StyledFieldText } from './styles'
import { StyledTitle } from '../../../../../../styles'
import { TimezoneField } from './components/fields'

interface IInfoField {
  title: string
  label: string
  defaultLabel?: string
  input?: React.FC<InputFieldProps>
  name: string
  apiError?: string
}

type InfoFields = IInfoField[]

interface WholeInfoProps {
  isEdit: boolean
  timezone: string | null
}

export const WholeInfo: React.FC<WholeInfoProps> = ({ isEdit, timezone }) => {
  const { first_name, last_name, sex, laboratory, email, phone_number, apiErrorsUserUpdate } =
    useAppSelector(selectUser)

  const { data: timezones } = useFetchTimezones()
  const currentTimezone = timezones?.find(({ timezone_name }) => timezone_name === timezone)

  const infoFields = {
    personalInfoFields: [
      { title: 'First name', label: first_name, input: InputField, name: 'firstName' },
      { title: 'Last name', label: last_name, input: InputField, name: 'lastName' },
      { title: 'Avatar', label: sex.value, input: RadioGroup, name: 'sex' },
      { title: 'Laboratory', label: laboratory.name, name: 'laboratory' },
    ],
    contactFields: [
      {
        title: 'Email',
        label: email,
        input: InputField,
        name: 'email',
        apiError: apiErrorsUserUpdate?.email,
      },
      {
        title: 'Phone number',
        label: phone_number || '',
        defaultLabel: 'No phone number provided',
        input: PhoneField,
        name: 'phoneNumber',
        apiError: apiErrorsUserUpdate?.phone_number,
      },
      {
        title: 'Time location',
        label: currentTimezone?.timezone_representation_name || 'Determining...',
        input: TimezoneField,
        name: 'timezone',
      },
    ],
  }

  const infoBlocks = [
    {
      title: 'Personal info',
      fields: infoFields.personalInfoFields,
    },
    {
      title: 'Contacts',
      fields: infoFields.contactFields,
    },
  ]

  const renderField = (
    title: string,
    label: string,
    name: string,
    Input?: React.FC<InputFieldProps>,
    apiError?: string
  ) => (
    <StyledBox key={title}>
      <StyledFieldTitle>{title}</StyledFieldTitle>
      {isEdit && Input ? (
        <Input name={name} apiError={apiError} />
      ) : (
        <StyledFieldText>{label}</StyledFieldText>
      )}
    </StyledBox>
  )

  const renderInfoBlock = (title: string, fields: InfoFields) => (
    <InfoBox key={title}>
      <StyledTitle>{title}</StyledTitle>

      {fields.map(({ title, label, defaultLabel, input, name, apiError = '' }) =>
        renderField(title, defaultLabel && !label ? defaultLabel : label, name, input, apiError)
      )}
    </InfoBox>
  )

  return <>{infoBlocks.map(({ title, fields }) => renderInfoBlock(title, fields))}</>
}
