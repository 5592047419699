import { Stack, StackProps, styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'

interface StyledStackProps extends StackProps {
  mb?: string
}

const StyledStackConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'mb':
        return false

      default:
        return true
    }
  },
}
export const StyledIcon = styled('img')`
  width: 6px;
  height: 12px;
  margin-left: 6px;
  margin-right: 6px;
`

export const StyledStack = styled<React.FC<StyledStackProps>>(Stack, StyledStackConfig)`
  margin-bottom: ${({ mb }) => (mb ? mb : '48px')};
`

export const StyledTypography = styled(Typography)`
  color: #0f1934;
`

export const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: #616d84;
  cursor: pointer;

  &::first-letter {
    text-transform: uppercase;
  }

  &:hover {
    text-decoration: underline;
  }
`

export const StyledTypographyLink = styled(Typography)`
  color: #616d84;
  cursor: pointer;

  &::first-letter {
    text-transform: uppercase;
  }

  &:hover {
    text-decoration: underline;
  }
`
