import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useWatch } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { MAIN_APIS } from '@constants/samples'
import { IAttachments } from '@models/attachments'
import { sampleCreate, setCellValueToSample } from '@state/sampleCreate'
import { useAppSelector } from '@hooks/redux'
import { sampleCreateUIData } from '@state/common/sampleCreateUIState'
import { protectedRoutes } from '@configs/routes.config'
import { setIsEditAttachments } from '@state/tasks'
import { setCreateSampleAttachments } from '@state/app'

import { PureCellPropTypes } from '../pureFields.types'
import { StorageDocumentsSection } from './StorageDocumentsSection'

export const StorageDocuments: React.FC<PureCellPropTypes> = React.memo(
  ({ cellName, control, sampleId, width, name, isCopy }) => {
    const { isToxicityAdded, isTemperatureAdded } = useAppSelector(sampleCreateUIData)

    const isShowAllCell = isToxicityAdded || isTemperatureAdded
    const [documents, setDocuments] = React.useState<IAttachments | null>(null)
    const dispatch = useDispatch()
    const history = useHistory()

    const [api] = useWatch({ control, name: [`${sampleId}_${MAIN_APIS}`] })

    const { samples } = useAppSelector(sampleCreate)

    const sample = samples.find(item => item.id === sampleId)
    const sampleAttachments = useMemo(() => {
      return sample?.attachments.val || []
    }, [sample?.attachments.val])

    React.useEffect(() => {
      if (sampleAttachments || isCopy) {
        setDocuments(sampleAttachments)
      }
    }, [isCopy, sampleAttachments])

    React.useEffect(() => {
      // add api at first render with new Api
      if (api.length && !sample?.attachments.val) {
        const attachments = api.length === 1 && api[0].skills.length ? api[0].attachments : []

        dispatch(
          setCellValueToSample({
            cellValue: { val: attachments, error: '' },
            cellName,
            sampleId,
          })
        )

        setDocuments(attachments)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api])

    const attachMaterialsEndpoint = {
      pathname: protectedRoutes.createSampleAttachStorage.path.replace(':modalType', 'storage'),
      state: history.location.state,
    }

    const handleSetEditMode = () => {
      // * setIsEditAttachments - for saving new attaches (attach modal logic)
      dispatch(setIsEditAttachments(true))

      if (documents) {
        dispatch(
          setCreateSampleAttachments({
            cellValue: documents,
            sampleId,
            isApplyNewAttachments: false,
          })
        )
      }
    }

    return (
      <StorageDocumentsSection
        documents={documents}
        handleSetEditMode={handleSetEditMode}
        attachMaterialsEndpoint={attachMaterialsEndpoint}
        isShowAllCell={isShowAllCell}
        api={api}
        name={name}
        width={width}
      />
    )
  }
)
