import React from 'react'
import { TableBody, useMediaQuery } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { StyledDetailsTable, StyledDetailsTableMobile, TableHeadMobile } from '@styles'
import { useToggle } from '@hooks/common'
import { TestResultsModal } from '@components/modules/Tests/components/contents/TestContent/components/Test/components/steps/Testing/components'

import { TableHead, TaskRow } from './components'
import { TaskRowMobile } from '../TaskRowMobile'

interface TabTestingInfoProps {
  task?: ITaskLab
}

export const TabTestingInfo: React.FC<TabTestingInfoProps> = ({ task }) => {
  const isMobile = useMediaQuery('(max-width:767px)')

  const [isResultsModalOpen, setIsResultsModalOpen] = useToggle()

  return (
    <>
      {!isMobile ? (
        <StyledDetailsTable>
          <TableHead />
          <TableBody>
            {task && <TaskRow setIsResultsModalOpen={setIsResultsModalOpen} task={task} />}
          </TableBody>
        </StyledDetailsTable>
      ) : (
        <StyledDetailsTableMobile>
          <TableHeadMobile />
          {task && (
            <TaskRowMobile
              setIsResultsModalOpen={setIsResultsModalOpen}
              task={task}
              contentType='testing'
            />
          )}
        </StyledDetailsTableMobile>
      )}

      {task && isResultsModalOpen && (
        <TestResultsModal
          editTestResult
          testGroupName={task.test_type.group.name}
          taskId={task.id}
          taskApis={task.main_apis}
          onClose={setIsResultsModalOpen}
          resultBtnName={task.available_result}
        />
      )}
    </>
  )
}
