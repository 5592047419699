import React from 'react'

import { ISample } from '@models/samples'

import { Dosage } from './Dosage'
import { APIsCell } from './APIsCell'
import { ExcipientsCell } from './ExcipientsCell'
import { SampleID } from './SampleID'
import { DrugClass } from './DrugClass'
import { Manufacturer } from './Manufacturer'
import { ActionCell } from './ActionCell'
import { PharmacyName } from './PharmacyName'
import { LastUpdated } from './LastUpdated'
import { Notes } from './Notes'
import { Units } from './Units'
import { TotalValue } from './TotalValue'
import { Toggler } from './Toggler'

interface SampleCellsProps {
  report: ISample
  isLastReport?: boolean
  hideBorder?: boolean
}

export const SampleCells: React.FC<SampleCellsProps> = props => {
  const { report, isLastReport, hideBorder } = props

  const cells = [
    SampleID,
    PharmacyName,
    Manufacturer,
    Notes,
    DrugClass,
    Dosage,
    Units,
    TotalValue,
    APIsCell,
    ExcipientsCell,
    LastUpdated,
    ActionCell,
  ]

  return (
    <>
      <Toggler report={report} hideBorder={hideBorder} />

      {cells.map((Cell, idx) => (
        <Cell key={idx} report={report} isLastReport={isLastReport} hideBorder={hideBorder} />
      ))}
    </>
  )
}
