import React from 'react'

import { auth, setMaintenanceModePage } from '@state/user'
import { loginUser } from '@api/user'
import { IError } from '@models/common/app'
import { useAppDispatch } from '@hooks/redux'
import { ICredentials } from '@models/common/user'

export const useLogin = (credentials: ICredentials | undefined) => {
  const [isAuthenticating, setIsAuthenticating] = React.useState<boolean>(false)
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')
  const dispatch = useAppDispatch()

  const loginHandler = React.useCallback(async () => {
    if (credentials) {
      try {
        setIsAuthenticating(true)

        await loginUser(credentials)

        setIsAuthenticated(true)
      } catch (err) {
        const error = err as IError
        if (error.status === 503) dispatch(setMaintenanceModePage(true))

        setError(error.message)
      } finally {
        setIsAuthenticating(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  React.useEffect(() => {
    loginHandler()
  }, [loginHandler])

  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(auth())
    }
  }, [isAuthenticated, dispatch])

  return { loginError: error, clearError: () => setError(''), isAuthenticating }
}
