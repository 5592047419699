import { styled } from '@mui/material'

export const FooterBox = styled('footer')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 20px;
  background-color: ${({ theme }) => theme.palette.common.white};
  font-size: 0.75rem;
  color: #616d84;
  border-top: 1px solid #e5e9f1;

  .MuiTypography-root {
    font: inherit;
  }
`
