import styled from 'styled-components'

export const StyledPageBox = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 12px;
`

export const StyledBox = styled('div')`
  display: flex;
  align-items: center;

  & .MuiTypography-root {
    margin-right: 24px;
  }
`
