import React from 'react'
import { isToday } from 'date-fns'
import { useWatch, Validate } from 'react-hook-form'

import { ISample } from '@models/samples'
import { SampleOptionsContext } from '@context'
import { Autocomplete, DatePicker, GMPlaceAutocomplete } from '@components/shared/sampleFields'
import {
  BRAND,
  COUNTRY,
  EXPIRATION_DATE,
  MANUFACTURER,
  MANUFACTURE_DATE,
  ADDRESS_OF_MANUFACTURER,
} from '@constants/samples'
import { StyledTitle } from '@styles'
import { ManufactureField } from '@components/shared/sampleFields/createSampleCells'

import { ManufactureDetailsLoader } from '../../Loaders'
import { StyledForm, StyledLabel } from '../styles'
import { StyledFormRow } from './styles'

interface EditManufacturerDetailsProps {
  sample: ISample
}

export const EditManufacturerDetails: React.FC<EditManufacturerDetailsProps> = ({ sample }) => {
  const { brands, countries, isLoading, manufacturerAddresses } =
    React.useContext(SampleOptionsContext)

  const expirationDate = useWatch({ name: EXPIRATION_DATE })

  const fields = [
    {
      label: 'Manufacturer name',
      name: MANUFACTURER,
      field: ManufactureField,
      pr: '42px',
      newOptionsType: MANUFACTURER,
      enableToAddOptions: true,
    },

    {
      label: 'Address of manufacturer',
      name: ADDRESS_OF_MANUFACTURER,
      field: GMPlaceAutocomplete,
      dataOptions: manufacturerAddresses,
      addressId: sample.address_of_manufacturer?.id,
      rules: {
        maxLength: {
          value: 150,
          message: 'Max 150 characters',
        },
        validate: {
          dangerousChars: (v: string) => {
            return /^(?![=+\-@\t\r]).*/.test(v) || 'Field starts with dangerous char.'
          },
        },
      },
    },

    {
      label: 'Brand',
      name: BRAND,
      field: Autocomplete,
      pr: '30px',
      options: brands,
      rules: {
        validate: {
          dangerousChars: (v: string) => {
            return /^(?![=+\-@\t\r]).*/.test(v) || 'Field starts with dangerous char.'
          },
        },
      },
      newOptionsType: BRAND,
      enableToAddOptions: true,
    },

    {
      label: 'Manufacture date',
      name: MANUFACTURE_DATE,
      field: DatePicker,
      disableFuture: true,
      rules: {
        validate: {
          manufactureDateEarlierThanExpiration: (manufactureDate: Date) => {
            return (
              !Boolean(manufactureDate && expirationDate) ||
              !(isToday(manufactureDate) && isToday(expirationDate)) ||
              'Manufacture date must be earlier than the expiration date'
            )
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
    },

    {
      label: 'Expiration date',
      name: EXPIRATION_DATE,
      field: DatePicker,
      disablePast: true,
    },

    {
      label: 'Country of origin',
      name: COUNTRY,
      field: Autocomplete,
      pr: '30px',
      options: countries,
    },
  ]

  if (isLoading) return <ManufactureDetailsLoader />

  return (
    <StyledForm>
      <StyledTitle variant='h3' mb='28px' fz={24}>
        Manufacturer details
      </StyledTitle>

      {fields.map(({ field: Field, label, ...props }, idx) => {
        if (idx === 4) return null
        else if (idx === 3) {
          const { field: NextField, label: nextFieldLabel, ...nextFieldProps } = fields[idx + 1]

          return (
            <StyledFormRow key={idx}>
              <StyledLabel
                label={label}
                control={<Field {...props} />}
                sx={{ marginRight: '40px' }}
              />
              <StyledLabel label={nextFieldLabel} control={<NextField {...nextFieldProps} />} />
            </StyledFormRow>
          )
        }

        return <StyledLabel key={idx} label={label} control={<Field {...props} />} />
      })}
    </StyledForm>
  )
}
