import React from 'react'
import { FieldValues, UseFieldArrayReturn } from 'react-hook-form'

export const FieldArrayFormContext = React.createContext<UseFieldArrayReturn | null>(null)

FieldArrayFormContext.displayName = 'RHFArrayContext'

export const useFieldArrayFormContext = <
  TFieldValues extends FieldValues
>(): UseFieldArrayReturn<TFieldValues> => {
  return React.useContext(FieldArrayFormContext) as UseFieldArrayReturn<TFieldValues>
}

export declare type FieldArrayFormProviderProps<TFieldValues extends FieldValues = FieldValues> = {
  children: React.ReactNode
} & UseFieldArrayReturn<TFieldValues>

export const FieldArrayFormProvider = <TFieldValues extends FieldValues>({
  children,
  ...props
}: FieldArrayFormProviderProps<TFieldValues>) => {
  return (
    <FieldArrayFormContext.Provider value={{ ...props } as UseFieldArrayReturn}>
      {children}
    </FieldArrayFormContext.Provider>
  )
}
