import React from 'react'
import { Box } from '@mui/material'

import { StyledActionsStickyBox } from '@styles'
import { useAppSelector } from '@hooks/redux'
import { selectedFilters as filters, selectedFiltersLength } from '@state/filters'
import {
  ClearAllFilters,
  FilterAsideButton,
  SetParamsOfFilter,
  ViewSelectedFilters,
} from '@components/shared/FilterAside'
import {
  APIS,
  EXCIPIENTS,
  DRUG_CLASSES,
  MANUFACTURERS,
  TESTING_DATE,
} from '@components/shared/FilterAside/constants'
import { ITasksTabValue } from '@models/tasks'

interface TasksFiltersProps {
  contentType: ITasksTabValue
  isPageHasSavedFilters: boolean
}

export const TasksFilters: React.FC<TasksFiltersProps> = props => {
  const { isPageHasSavedFilters } = props

  const {
    appliedApisSelectedFilters,
    appliedExcipientsSelectedFilters,
    appliedDrugClassesSelectedFilters,
    appliedManufacturersSelectedFilters,
    appliedTestingDateSelectedFilters,
  } = useAppSelector(filters)
  const allFiltersLength = useAppSelector(selectedFiltersLength)
  const isShouldShowClearAll = Boolean(allFiltersLength)

  return (
    <>
      <SetParamsOfFilter
        isPageHasSavedFilters={isPageHasSavedFilters}
        appliedApisSelectedFilters={appliedApisSelectedFilters}
        appliedExcipientsSelectedFilters={appliedExcipientsSelectedFilters}
        appliedDrugClassesSelectedFilters={appliedDrugClassesSelectedFilters}
        appliedManufacturersSelectedFilters={appliedManufacturersSelectedFilters}
        appliedTestingDateSelectedFilters={appliedTestingDateSelectedFilters}
      />

      <StyledActionsStickyBox zIndex={350} top='59px'>
        <Box display='flex' alignItems='center' flexWrap='wrap'>
          <FilterAsideButton />

          {!!appliedApisSelectedFilters.length && <ViewSelectedFilters type={APIS} />}

          {!!appliedExcipientsSelectedFilters.length && <ViewSelectedFilters type={EXCIPIENTS} />}

          {!!appliedDrugClassesSelectedFilters.length && (
            <ViewSelectedFilters type={DRUG_CLASSES} />
          )}

          {!!appliedManufacturersSelectedFilters.length && (
            <ViewSelectedFilters type={MANUFACTURERS} />
          )}

          {!!appliedTestingDateSelectedFilters.length && (
            <ViewSelectedFilters type={TESTING_DATE} />
          )}

          {isShouldShowClearAll && <ClearAllFilters />}
        </Box>
      </StyledActionsStickyBox>
    </>
  )
}
