import { Chip, styled as styledMUI, Typography } from '@mui/material'
import styled from 'styled-components'

export const AvatarBox = styled.div`
  width: 205px;
  display: flex;
  flex-shrink: 0;
  margin-right: 79px;
  padding-right: 85px;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #e5e9f1;
`

export const Name = styledMUI(Typography)`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #1a2233;
  word-break: break-word;
`

export const Role = styledMUI(Chip)`
  color: #0f1934;
  background: ${({ theme }) => theme.primary[20]};;
  border-radius: 28px;
`
