import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'

import { useToggle } from '@hooks/common'
import { CardRow, CardTitleCell, MobileCollapseRow } from '@styles'
import { Brand } from '@components/shared'
import { TOXICITY, TEMPERATURE } from '@constants/tasks'

import { TestType } from '../../Table/TasksRows/TasksCells/TestType'
import { SampleID } from '../../Table/TasksRows/TasksCells/SampleID'
import { Toxicity } from '../../Table/TasksRows/TasksCells/Toxicity'
import { Temperature } from '../../Table/TasksRows/TasksCells/Temperature'
import { Units } from '../../Table/TasksRows/TasksCells/Units'
import { Manufacturer } from '../../Table/TasksRows/TasksCells/Manufacturer'
import { Notes } from '../../Table/TasksRows/TasksCells/Notes'
import { APIsCell } from '../../Table/TasksRows/TasksCells/APIsCell'
import { ExcipientsCell } from '../../Table/TasksRows/TasksCells/ExcipientsCell'
import { Dosage } from '../../Table/TasksRows/TasksCells/Dosage'
import { DrugClass } from '../../Table/TasksRows/TasksCells/DrugClass'
import { ExpirationDateCell } from '../../Table/TasksRows/TasksCells/ExpirationDateCell'
import { ApisPercentage } from '../../Table/TasksRows/TasksCells/ApisPercentage'
import { TestingDate } from '../../Table/TasksRows/TasksCells/TestingDate'
import { MobileCell } from '../MobileCell'
import { RowProps } from './mobileRow.types'

export const Row: React.FC<RowProps> = props => {
  const { isMobile, task, contentType } = props
  const [openCard, setOpenCard] = useToggle()

  const rows =
    contentType === 'awaiting'
      ? [
          { id: 1, title: 'Test type', component: TestType },
          { id: 2, title: 'Sample ID', component: SampleID },
          { id: 3, title: TOXICITY, component: Toxicity },
          { id: 4, title: TEMPERATURE, component: Temperature },
          { id: 5, title: 'Manufacturer', component: Manufacturer },
          { id: 6, title: 'Brand', component: Brand },
          { id: 7, title: 'Task notes', component: Notes },
          { id: 8, title: 'Drug class', component: DrugClass },
          { id: 9, title: 'Dosage', component: Dosage },
          { id: 10, title: 'Units', component: Units },
          { id: 11, title: 'APIs', component: APIsCell },
          { id: 12, title: 'Excipients', component: ExcipientsCell },
          { id: 13, title: 'Expiration date', component: ExpirationDateCell },
        ]
      : [
          { id: 1, title: 'Test type', component: TestType },
          { id: 2, title: 'Sample ID', component: SampleID },
          { id: 3, title: TOXICITY, component: Toxicity },
          { id: 4, title: TEMPERATURE, component: Temperature },
          { id: 5, title: 'APIs', component: ApisPercentage },
          { id: 6, title: 'Testing date', component: TestingDate },
          { id: 7, title: 'Manufacturer', component: Manufacturer },
          { id: 8, title: 'Brand', component: Brand },
          { id: 9, title: 'Task notes', component: Notes },
          { id: 10, title: 'Drug class', component: DrugClass },
          { id: 11, title: 'Dosage', component: Dosage },
          { id: 12, title: 'Units', component: Units },
          { id: 13, title: 'APIs', component: APIsCell },
          { id: 14, title: 'Excipients', component: ExcipientsCell },
          { id: 15, title: 'Expiration date', component: ExpirationDateCell },
        ]

  return (
    <>
      <MobileCell
        task={task}
        isMobile={isMobile}
        contentType={contentType}
        setOpenCard={setOpenCard}
        openCard={openCard}
      />

      <MobileCollapseRow>
        <Collapse in={openCard} timeout='auto' unmountOnExit>
          <Box sx={{ backgroundColor: '#fff', paddingBottom: '36px' }}>
            {rows.map(({ id, title, component: Component }) => {
              if (!task.toxicity_level && title === TOXICITY) return null
              if (!task.max_temperature && title === TEMPERATURE) return null

              return (
                <CardRow key={id}>
                  <CardTitleCell>
                    <Typography variant='h5'>{title}</Typography>
                  </CardTitleCell>
                  <Box sx={{ width: '100%' }}>
                    <Component
                      task={task}
                      isMobile={isMobile}
                      brand={task.brand?.name}
                      contentType={contentType}
                    />
                  </Box>
                </CardRow>
              )
            })}
          </Box>
        </Collapse>
      </MobileCollapseRow>
    </>
  )
}
