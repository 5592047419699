import styled from 'styled-components'
import { Box, styled as styledMUI } from '@mui/material'
import React from 'react'

import { ButtonBox, ButtonBoxProps } from '@styles'

export const StepContainer = styledMUI(Box)` 
  padding: 48px 5%;
  background-color: #fff;
  margin-bottom: 8px;
`

export const StepHeader = styledMUI(Box)`
  display: flex;

  h3 {
    margin: 0 0 2px 16px;
    line-height: 1;
  }

  .MuiStepper-root .MuiDivider-root {
    display: none;
  }
`

export const StepFooter = styledMUI(Box)`
  margin: 48px 0 17px 0;
`

interface StepContainerExpandedProps {
  $isMobile?: boolean
}

export const StepContainerExpanded = styledMUI<React.FC<StepContainerExpandedProps>>(Box)`
  padding: 31px 5%;
  background-color: #fff;
  margin-bottom: 8px;

  display: ${({ $isMobile }) => ($isMobile ? '' : 'grid')} ;
  grid-template-columns: minmax(auto, 330px) 380px;
  grid-template-rows: auto 1fr;
  column-gap: 30px;
  justify-content: space-between;

  .test-header {
    grid-area: 1 / 1 / 2 / 2;
    margin-top: 20px;
  }

  .test-body {
    grid-area: 2 / 1 / 3 / 2;
  }

  .test-sidebar {
    grid-area: 1 / 2 / 3 / 3;
  }

  .test-body {
    margin-top: ${({ $isMobile }) => ($isMobile ? '' : '48px')} ;
  }

  .test-sidebar {
    background-color: ${({ theme }) => theme.palette.background.default};
    border-radius: 10px;
    padding: 25px;
    align-self: flex-start;
  }

  .test-sidebar h4 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .MuiTypography-root.text-colored {
    color: #8f929b;
  }
`

interface StepContainerExpandedNoSidebarProps {
  $isMobile?: boolean
}

export const StepContainerExpandedNoSidebar = styledMUI<
  React.FC<StepContainerExpandedNoSidebarProps>
>(Box)`
  padding: ${({ $isMobile }) => ($isMobile ? '0 5% 31px 5%' : '31px 5%')} ;
  background-color: #fff;
  margin-bottom: 8px;

  h4 {
    font-size: 18px;
    margin: 38px 0 20px;
  }

  .test-header {
    margin-top: 20px;
  }

  .test-body {
    margin-top: ${({ $isMobile }) => ($isMobile ? '' : '48px')} ;
  }

  .MuiTypography-root.text-colored {
    color: #8f929b;
  }
`

interface StepSidebarRowProps {
  $isMobile?: boolean
  $inlineFlex?: boolean
}

export const StepSidebarRow = styledMUI<React.FC<StepSidebarRowProps>>(Box)`
  width: 100%;
  margin-bottom: 16px;

  & > div {
    display: ${({ $inlineFlex }) => ($inlineFlex ? 'inline-flex' : 'inline-block')} ;
    vertical-align: top;
  }

  & > div:nth-of-type(1) {
    width: 35%;
    padding-right: 58px;
  }

  & > div:nth-of-type(2) {
  width: ${({ $isMobile }) => ($isMobile ? '28%' : '38%')} ;
  }
`

export const ApisBox = styledMUI(Box)`
  display: flex;
  flex-wrap: wrap;
`

export const StepSidebar = styledMUI(Box)``
export const StepSidebarContent = styledMUI(Box)``
export const StepSidebarCell = styledMUI(Box)``

export const AttachLinkBox = styledMUI(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin-top: 32px;

  .MuiButton-root {
    margin: 24px 0 0 0;
  }
`

export const TextareaBox = styledMUI(Box)`
  .MuiFormControlLabel-root {
    margin: 40px 0 15px;
  }

  textarea {
    width: 100%;
    min-height: 127px;
    background: #ffffff;
    resize: vertical;
    padding: 17px 12px;

    border: 1px solid {({ theme }) => theme.additional.stroke.main};
    box-sizing: border-box;
    border-radius: 4px;

    &:focus {
      border: 1px solid #85d1f7;
      outline: none;
    }
  }
`

export const StyledButtonBox = styled<React.FC<ButtonBoxProps>>(ButtonBox)`
  justify-content: unset;
  margin-top: ${({ $isMobile }) => ($isMobile ? '32px' : '60px')};

  .MuiButton-containedPrimary.Mui-disabled {
    opacity: 0.5;
    color: #ffffff;
    background: ${({ theme }) => theme.palette.primary.main};
  }
`
