import React from 'react'

import { TRUNCATED_ATTACHMENTS_TASK_DETAILS_TEXT } from '@constants/common'
import { Attachment, AttachmentsActionButtons } from '@components/shared'
import { obtainingSkills } from '@helpers/obtainingSkills'

import { StyledAdditionalText, StyledInnerTableCell } from '../styles'
import { StorageDocumentsSectionProps } from '../pureFields.types'

export const StorageDocumentsSection: React.FC<StorageDocumentsSectionProps> = React.memo(props => {
  const { documents, attachMaterialsEndpoint, handleSetEditMode, name, width, api, isShowAllCell } =
    props
  return (() => {
    // If APIs selected

    // If we have skills
    if (obtainingSkills(api).length) {
      if (documents?.length) {
        // If selected one APIs and we have some attachments
        return (
          <StyledInnerTableCell id={name} style={{ minWidth: width }}>
            {documents?.map(attachment => (
              <Attachment
                truncatedTextLength={TRUNCATED_ATTACHMENTS_TASK_DETAILS_TEXT}
                cantDelete
                key={attachment.id}
                attachment={attachment}
              />
            ))}

            {/* Edit/Add attachments buttons */}
            <AttachmentsActionButtons
              isEdit={!!documents?.length}
              attachMaterialsEndpoint={attachMaterialsEndpoint}
              isTextDecorationAbsence
              handleSetEditMode={handleSetEditMode}
            />
          </StyledInnerTableCell>
        )
      } else {
        return (
          <StyledInnerTableCell id={name} style={{ minWidth: width }}>
            <AttachmentsActionButtons
              isEdit={false}
              attachMaterialsEndpoint={attachMaterialsEndpoint}
              isTextDecorationAbsence
              handleSetEditMode={handleSetEditMode}
            />
          </StyledInnerTableCell>
        )
      }
    }

    // If we don't have skills
    return isShowAllCell ? (
      <StyledInnerTableCell style={{ minWidth: width }}>
        <StyledAdditionalText>For chemotherapy and temperature-sensitive only</StyledAdditionalText>
      </StyledInnerTableCell>
    ) : null
  })()
})
