import { styled } from '@mui/material'

export const NotificationWrap = styled('div')`
  display: flex;
  padding: 12px;
  border: 1px solid #e5e9f1;
  border-radius: 10px;
  box-shadow: -5px 0 15px rgb(53, 108, 151, 0.12);
  margin-bottom: 16px;

  & svg {
    min-width: 24px;
    margin-right: 8px;
  }
`

export const NotificationDescription = styled('p')`
  color: #8f929b;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  line-height: 20px;
  letter-spacing: 0.3px;
`
