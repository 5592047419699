import React from 'react'
import { Autocomplete as MUIAutocomplete, Grid, Typography } from '@mui/material'
import parse from 'autosuggest-highlight/parse'
import { v4 as uuid } from 'uuid'

import { ReactComponent as SearchIcon } from '@assets/icons/search-icon-blue.svg'
import { AddAutocompleteNewData } from '@components/shared/AddAutocompleteNewData'
import { AutocompleteWrapper, Input, Tooltip } from '@components/shared'

import { GMPlaceAutocompleteRenderTypes } from './gMPlaceAutocomplete.types'

export const GMPlaceAutocompleteRender: React.FC<GMPlaceAutocompleteRenderTypes> = props => {
  const {
    isHighlighting,
    isOldHighlighting,
    showGP,
    serverOptions,
    oldField,
    onChange,
    onInputChange,
    oldDataValue,
    options,
    value,
    onGPChange,
    error,
    oldError,
    isLoading,
    inputValue,
    handleAddNewAddress,
  } = props

  const checkValueLength =
    (inputValue.length || oldDataValue?.description.length || value?.description.length || 0) <= 30

  return (
    <AutocompleteWrapper
      height={42}
      position='relative'
      pl='39px'
      pr='66px'
      bgColor={isHighlighting || isOldHighlighting ? '#FFF5D6' : '#fff'}
    >
      {/* the icon below doesn't put in to the InputProps parameter of autocomplete input
      because it creates a Google place bug with options visibility */}
      <SearchIcon className='gmp-search-icon' />
      {!showGP ? (
        <MUIAutocomplete
          fullWidth
          size='small'
          options={serverOptions}
          noOptionsText='Nothing found'
          getOptionLabel={option => option.name || option.description || ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <Tooltip
              isPureTooltip
              maxWidth={230}
              shouldBeHidden={checkValueLength}
              title={inputValue || oldDataValue?.description || value?.description || ''}
            >
              <Input
                error={!!oldError}
                variant='outlined'
                helperText={oldError?.message}
                placeholder='Type here values...'
                {...params}
              />
            </Tooltip>
          )}
          {...oldField}
          onChange={onChange}
          onInputChange={onInputChange}
          value={oldDataValue}
        />
      ) : (
        <MUIAutocomplete
          fullWidth
          size='small'
          getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
          filterOptions={x => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={onGPChange}
          onInputChange={onInputChange}
          renderInput={params => (
            <Tooltip
              isPureTooltip
              maxWidth={230}
              shouldBeHidden={checkValueLength}
              title={inputValue || oldDataValue?.description || value?.description || ''}
            >
              <Input
                error={!!error}
                variant='outlined'
                helperText={error?.message}
                placeholder='Type here values...'
                {...params}
              />
            </Tooltip>
          )}
          renderOption={(props, option) => {
            // In this "main_text" field below we can see '@this_option_for_save@' because we set it manual
            // at the fetch method if Google Place can't find anything
            if (option.structured_formatting.main_text !== '@this_option_for_save@') {
              const matches = option.structured_formatting.main_text_matched_substrings || []
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match: any) => [match.offset, match.offset + match.length])
              )
              return (
                <li {...props}>
                  <Grid container alignItems='center'>
                    <Grid item xs>
                      {parts.map(part => (
                        <span key={uuid()} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                          {part.text}
                        </span>
                      ))}
                      <Typography variant='body2' color='text.secondary'>
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )
            }
            // Return below for custom address
            return (
              <li {...props} style={{ background: 'none', padding: 0 }}>
                <Grid container alignItems='center'>
                  <Grid item xs>
                    <AddAutocompleteNewData
                      isWithoutLabel
                      isBtnLoading={isLoading}
                      description={inputValue}
                      handleAdd={() => handleAddNewAddress(option)}
                    />
                  </Grid>
                </Grid>
              </li>
            )
          }}
        />
      )}
    </AutocompleteWrapper>
  )
}
