import { ISample } from '@models/samples'
import { ExpirationDate } from '@components/shared'
import { StyledTableSampleCell } from '@styles'

interface ExpirationDateCellProps {
  sample: ISample
  isMobile?: boolean
}

export const ExpirationDateCell: React.FC<ExpirationDateCellProps> = ({ sample, isMobile }) => {
  const { expiration_date: expDate } = sample
  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      <ExpirationDate expirationDate={expDate} />
    </StyledTableSampleCell>
  )
}
