import React from 'react'
import { useHistory } from 'react-router-dom'

import { IPackage, PackageContentType } from '@models/packages'
import { TempSensitiveToxicityIcons, Tooltip } from '@components/shared'
import infoIcon from '@assets/icons/info-circle-xs.svg'
import { Image } from '@styles'
import { protectedRoutes } from '@configs/routes.config'
import { SpecificId } from '@components/shared/sampleFields'
import { DETAILS, FAHRENHEIT } from '@constants/common'
import { getTemperature } from '@helpers/formatTemperature'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'

import { StyledTableCell } from '../../../styles'

interface PackageIDProps {
  pack: IPackage
  contentType: PackageContentType
  isLastPack?: boolean
}

export const PackageID: React.FC<PackageIDProps> = React.memo(
  ({ pack, contentType, isLastPack }) => {
    const history = useHistory()
    const { isTenantType } = useTenant()
    const pageTitle = contentType === 'default' ? 'Your packages' : `${contentType} packages`

    const packageInfoEndpoint = () => {
      history.replace({
        pathname: `${protectedRoutes.packages.path}/${pack.id}${DETAILS}`,
        state: [
          {
            path: protectedRoutes.packages.path,
            url: protectedRoutes.packages.path,
            search: `?package_type=${contentType}`,
            title: pageTitle,
          },
        ],
      })
    }
    const { maxTemperature, minTemperature, temperatureFormat } = React.useMemo(() => {
      if (!isTenantType(TENANT_TYPE.legalDrugs))
        return {
          maxTemperature: undefined,
          minTemperature: undefined,
          temperatureFormat: undefined,
        }

      return getTemperature(
        FAHRENHEIT,
        pack.min_temperature,
        pack.max_temperature,
        pack.min_temperature_measurement_format?.key,
        pack.max_temperature_measurement_format?.key
      )
    }, [
      isTenantType,
      pack.max_temperature,
      pack.max_temperature_measurement_format?.key,
      pack.min_temperature,
      pack.min_temperature_measurement_format?.key,
    ])

    return (
      <StyledTableCell
        $isDefaultContentType={contentType === 'default'}
        $isLastPack={isLastPack && contentType !== 'default'}
      >
        {contentType !== 'default' && pack.has_missed_samples && (
          <Tooltip title='There are missed samples' placement='top-start'>
            <Image
              component='img'
              src={infoIcon}
              width='18px'
              height='18px'
              sx={{ position: 'absolute', left: '-25px' }}
            />
          </Tooltip>
        )}
        <SpecificId
          labelTooltip={pack.source.label_tooltip}
          specificId={pack.specific_id}
          laboratory={pack.source}
          pathForLink={protectedRoutes.packages.path}
          titleForPathLink={pageTitle}
          searchStateForLinkPath={`?package_type=${contentType}`}
          infoEndpoint={packageInfoEndpoint}
        />
        {isTenantType(TENANT_TYPE.legalDrugs) && (
          <TempSensitiveToxicityIcons
            toxicity={pack.toxicity_level}
            minTempSensitive={minTemperature}
            maxTempSensitive={maxTemperature}
            temperatureMeasurementFormat={temperatureFormat}
          />
        )}
      </StyledTableCell>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.pack.id !== nextProps.pack.id
  }
)
