import { TabContext } from '@mui/lab'
import React from 'react'

import { ITaskLab } from '@models/tasks'
import { StyledTabPanel } from '@styles'

import { TabButtons, TabBasicInfo, TabTestingInfo, Loader } from './components'

export type ITaskInfoTabValue = 'basic info' | 'testing info'

interface TabsProps {
  task?: ITaskLab
  isLoading: boolean
}

export const Tabs: React.FC<TabsProps> = ({ task, isLoading }) => {
  const [currentTab, setCurrentTab] = React.useState<ITaskInfoTabValue>('basic info')

  if (isLoading) {
    return <Loader />
  }

  return (
    <TabContext value={currentTab}>
      <TabButtons onChangeTab={setCurrentTab} />
      <StyledTabPanel value='basic info'>
        <TabBasicInfo task={task} />
      </StyledTabPanel>
      <StyledTabPanel value='testing info'>
        <TabTestingInfo task={task} />
      </StyledTabPanel>
    </TabContext>
  )
}
