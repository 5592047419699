import { formatInTimeZone } from 'date-fns-tz'

export const formatDate = (
  dateString?: string | null,
  expectedFormat?: string,
  timezone?: string | null
) => {
  if (!dateString) return

  const date = new Date(dateString)

  return expectedFormat && timezone
    ? formatInTimeZone(date, timezone, expectedFormat)
    : new Intl.DateTimeFormat('en').format(date)
}

export const parseDate = (date: any) => {
  return date?.replace('null', '')?.replace('f', '')?.replace('s', '').split(',')
}

export const transformDateFormat = (date: string) => {
  const [year, month, day] = date.split('/')
  return [month, day, year].join('/')
}
