import { encodeQueryParams, NumberParam, StringParam } from 'use-query-params'
import { stringify } from 'query-string'

import { http } from '@services/http'
import { IAttachmentResultData, IFetchAttachments, PutAttachmentsTypes } from '@models/attachments'

// * [Attachments

const attachmentsQueryParams = {
  content_type: StringParam,
  object_id: NumberParam,
}

export const fetchAttachments = (data: IFetchAttachments) => {
  const cleanData = { content_type: data.content_type, object_id: data.object_id }
  const encodedQuery = encodeQueryParams(attachmentsQueryParams, cleanData)
  const queryString = stringify(encodedQuery)

  if (!data.isFetch) {
    try {
      return http.get(`/attachments/?${queryString}`)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const fetchAttachmentsForMutate = (data: IFetchAttachments) => {
  const encodedQuery = encodeQueryParams(attachmentsQueryParams, data)
  const queryString = stringify(encodedQuery)

  try {
    return http.get(`/attachments/?${queryString}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteAttachment = (attachmentId: string) => {
  try {
    return http.delete(`/attachments/${attachmentId}/`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addAttachments = async (data: FormData) => {
  try {
    return http.post<IAttachmentResultData, FormData>('/attachments/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateAttachments = async (data: PutAttachmentsTypes) => {
  try {
    return http.put<IAttachmentResultData, PutAttachmentsTypes>('/attachments/save/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

// * Attachments]
