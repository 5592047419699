import { useFormContext } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import React, { forwardRef, useImperativeHandle } from 'react'
import { useQueryClient } from 'react-query'
import { cloneDeep } from 'lodash'

import {
  StorageTemperature,
  Toxicity,
} from '@components/modules/SampleCreate/components/TableCells'
import { TEMPERATURE_FORMAT, TOXICITY_LEVEL, MIN_TEMPERATURE } from '@constants/samples'
import { Label } from '@components/shared/SkillsField/styles'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { ModalTypes } from '@models/packages'
import { useFetchAttachments } from '@hooks/queries/useFetchAttachments'
import { dataModals, IDataModalsContent } from '@constants/mappings'
import { useAddAttachments, useRemoveAttachments, useUpdateAttachments } from '@hooks/queries'
import { IAttachments, PutAttachmentsTypes } from '@models/attachments'
import { TASKS } from '@constants/queries'
import { ITaskLab } from '@models/tasks'
import {
  selectTasks,
  setAddedAttachments,
  setIsEditAttachments,
  setNewAttachmentInstead,
} from '@state/tasks'
import { MINILAB, NEARIR, PAD } from '@models/tests'
import { AttachFile } from '@components/modules/Modals/EditAttachedMaterials/components'
import { AttachedMaterialsList } from '@components/modules/Modals/EditAttachedMaterials/components/AttachedMaterialsList/AttachedMaterialsList'
import { useAttachmentsLength } from '@components/modules/Modals/EditAttachedMaterials/components/hooks'
import { AttachLink } from '@components/shared/AttachLink'

type DeletedAttachmentsTypes = {
  sectionKey: string
  ids: string[]
}

let deletedAttachmentsData: DeletedAttachmentsTypes[] = []
let allDeletedIds: string[] = []
let isChangesFinish: boolean = true
let isFetch: boolean = false

interface StorageConditionsDetailsProps {
  scrollBlockRef: React.MutableRefObject<HTMLDivElement | null>
  packageId: string
  onClose?: () => void
  setIsOpenExitModal?: React.Dispatch<React.SetStateAction<boolean>>
}

export const StorageConditionsDetails = forwardRef(
  (
    { packageId, onClose, setIsOpenExitModal, scrollBlockRef }: StorageConditionsDetailsProps,
    ref
  ) => {
    const { clearErrors, getValues } = useFormContext()
    const toxicity = {
      label: 'Toxicity',
      name: TOXICITY_LEVEL,
      initialVal: { val: ' ', error: '' },
      isEditing: true,
      isOnlyToxicity: true,
    } as any

    const temperature = {
      label: 'Storage temperature',
      name: TEMPERATURE_FORMAT,
      initialVal: { val: ' ', error: '' },
      isEditing: true,
      isOnlyTemperature: true,
      clearErrors: clearErrors,
    } as any

    //START

    const [attachments, setAttachments] = React.useState<IAttachments>([])
    const { isEditAttachments, addedAttachments, newAttachmentInstead } =
      useAppSelector(selectTasks)
    const modalType: ModalTypes = 'package_storage'
    const { getAttachmentsLength } = useAttachmentsLength()
    // ! dataModals will be from BE like we have now

    const objectId = +packageId
    const contentType = dataModals[modalType].contentType
    const dispatch = useAppDispatch()
    const client = useQueryClient()
    const task = client.getQueryData<ITaskLab>([TASKS, objectId])
    const dataModalsContent = dataModals[modalType].content
    const contents = Array.isArray(dataModalsContent)
      ? dataModalsContent
      : dataModalsContent[task?.test_type.name || PAD || MINILAB || NEARIR]

    // Requests
    const addAttachmentsM = useAddAttachments(isEditAttachments)
    const updateAttachmentsM = useUpdateAttachments(objectId)
    const removeAttachmentsM = useRemoveAttachments(objectId)
    const fetchAttachmentsQ = useFetchAttachments({
      content_type: contentType,
      object_id: objectId,
      isFetch,
    })

    const exitAndCleanDeleteArrays = (): void => {
      if (isEditAttachments) dispatch(setIsEditAttachments(false))
      if (deletedAttachmentsData.length) deletedAttachmentsData = []
      if (allDeletedIds.length) allDeletedIds = []
      if (!isChangesFinish) isChangesFinish = true
      if (isFetch) isFetch = false
      if (addedAttachments) dispatch(setAddedAttachments(null))
    }

    // Fetch attachments
    React.useEffect(() => {
      if (
        attachments.find(attachment => attachment.loading) &&
        attachments.length !== fetchAttachmentsQ.data?.length
      ) {
      } else {
        if (
          (fetchAttachmentsQ.data && !isEditAttachments) ||
          (fetchAttachmentsQ.data &&
            isEditAttachments &&
            attachments.length === 0 &&
            isChangesFinish)
        ) {
          if (isEditAttachments && !isFetch) isFetch = true
          setAttachments(fetchAttachmentsQ.data)
        }
      }
    }, [fetchAttachmentsQ.data, attachments, isEditAttachments])

    // Added fresh attaches to component state and clean @arrays from old data after deleted initial attachment
    // --------------------------
    // We get attachments data from store, because sometimes cache doesn't clean at "useMutation()" hook,
    // and we get correct data from BE but not correct data from "useMutation()" hook
    React.useEffect(() => {
      if (addedAttachments) {
        const { instead } = addedAttachments.result[0]
        // Remove deleted attachments and temporary "loading" attachment(item.id: string) from components state
        const oldAttachments = cloneDeep(attachments)
        addedAttachments.result.forEach(newAttach => {
          const oldAttachIndex = oldAttachments.findIndex(
            oldAttach => oldAttach.name === newAttach.name && typeof oldAttach.id === 'string'
          )
          oldAttachments.splice(oldAttachIndex, 1)
        })

        allDeletedIds = allDeletedIds.filter(item => +item !== instead || newAttachmentInstead)
        deletedAttachmentsData = deletedAttachmentsData.map(item => {
          if (item.ids.includes(`${instead || newAttachmentInstead}`)) {
            item.ids = item.ids.slice(0, item.ids.length - 1)
          }
          return item
        })
        if (newAttachmentInstead) {
          dispatch(setNewAttachmentInstead(''))
        }

        setAttachments([...oldAttachments, ...addedAttachments.result])
        isChangesFinish = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addedAttachments, newAttachmentInstead])

    // Exit from edit attachment modal after save
    React.useEffect(() => {
      if (updateAttachmentsM.isSuccess) {
        exitAndCleanDeleteArrays()
      } else if (updateAttachmentsM.isError && isFetch) {
        isFetch = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateAttachmentsM.isSuccess])

    const removeAttachment = (id: string, sectionKey: string): void => {
      if (!isEditAttachments) {
        removeAttachmentsM.mutate(id)
      } else {
        const removedAttachment = attachments.find(item => item.id === +id)

        if (!removedAttachment?.instead) {
          // Create @arrays with deleted attaches data
          const deletedAttachmentSection = deletedAttachmentsData.find(
            item => item.sectionKey === sectionKey
          )
          if (!deletedAttachmentSection) {
            deletedAttachmentsData.push({ sectionKey, ids: [id] })
          } else {
            deletedAttachmentSection.ids.push(id)
            deletedAttachmentsData = deletedAttachmentsData.map(item => {
              if (item.sectionKey === sectionKey) return deletedAttachmentSection
              return item
            })
          }

          allDeletedIds.push(id)
        } else {
          // Push previously deleted initial attached data back to the @arrays after delete fresh attach
          deletedAttachmentsData = deletedAttachmentsData.map(item => {
            if (item.sectionKey === sectionKey) item.ids.push(`${removedAttachment.instead}`)
            return item
          })
          allDeletedIds.push(`${removedAttachment.instead}`)
        }

        setAttachments([...attachments.filter(item => item.id !== +id)])
        isChangesFinish = false
      }
    }

    const publicRef = {
      handleCloseEditing: (closeWithSaving?: boolean): void => {
        if (isEditAttachments && !isChangesFinish && !closeWithSaving)
          setIsOpenExitModal && setIsOpenExitModal(true)
        else {
          setIsOpenExitModal && setIsOpenExitModal(false)
          exitAndCleanDeleteArrays()
          onClose && onClose()
        }
      },
      handleExitEditing: (): void => {
        setIsOpenExitModal && setIsOpenExitModal(false)
        exitAndCleanDeleteArrays()
        onClose && onClose()
      },
      handleSave: (): void => {
        if (isEditAttachments && !isChangesFinish && objectId) {
          const data2Send: PutAttachmentsTypes = {
            content_type: contentType,
            object_id: objectId,
            attachments_ids: [...attachments.map(attachment => +attachment.id)],
          }
          updateAttachmentsM.mutate(data2Send)
          isFetch = true
        } else {
          exitAndCleanDeleteArrays()
        }
      },
      exitAndCleanDeleteArrays: exitAndCleanDeleteArrays,
    }

    useImperativeHandle(ref, () => publicRef)
    //END

    return (
      <div>
        <Typography sx={{ fontWeight: 'bold', marginBottom: '24px' }}>
          Storage conditions details
        </Typography>
        {!!getValues(MIN_TEMPERATURE) && (
          <>
            <Label>Expected storage temperature</Label>
            <Box sx={{ width: '300px', marginBottom: '43px' }}>
              <StorageTemperature {...temperature} />
            </Box>
          </>
        )}
        {!!getValues(TOXICITY_LEVEL) && (
          <>
            <Label>Toxicity</Label>
            <Box sx={{ marginBottom: '43px' }}>
              <Toxicity {...toxicity} scrollBlockRef={scrollBlockRef} />
            </Box>
          </>
        )}
        <Label>Storage documents</Label>
        {contents.map((content: IDataModalsContent) => {
          const filteredAttachments = attachments?.filter(
            attachment => attachment.attachment_section.key === content.section.key
          )
          const isFilteredHasNoDeleted = filteredAttachments.some(
            item => !allDeletedIds.includes(`${item.id}`)
          )
          const lastDeletedAttachmentSection = deletedAttachmentsData.find(
            item => item.sectionKey === content.section.key
          )
          const lastDeletedAttachmentId =
            lastDeletedAttachmentSection?.ids[lastDeletedAttachmentSection.ids.length - 1]

          return (
            <Box key={content.id} sx={{ marginBottom: '32px' }}>
              <Typography variant='h5' fontSize={18} mb={3} mt={3} lineHeight='24px'>
                {content.sectionTitle}
              </Typography>

              <AttachFile
                modalType={modalType}
                deletedAttachmentId={lastDeletedAttachmentId}
                contentType={contentType}
                objectId={objectId}
                section={content.section.key}
                sectionTitle={content.section.value}
                addingError={addAttachmentsM.error}
                attachments={filteredAttachments}
                setAttachments={setAttachments}
                addAttachments={addAttachmentsM.mutateAsync}
              />

              <Typography
                variant='body1'
                sx={{ color: '#8F929B', textAlign: 'center', marginTop: '16px' }}
              >
                or
              </Typography>
              <AttachLink
                deletedAttachmentId={lastDeletedAttachmentId}
                isDeactivate={getAttachmentsLength(objectId, filteredAttachments.length) >= 3}
                contentType={contentType}
                objectId={objectId}
                section={content.section.key}
                sectionTitle={content.section.value}
                attachments={attachments}
                setAttachments={setAttachments}
                addAttachments={addAttachmentsM.mutateAsync}
              />

              {/* All Attached materials */}
              <AttachedMaterialsList
                filteredAttachments={filteredAttachments}
                isFilteredHasNoDeleted={isFilteredHasNoDeleted}
                allDeletedIds={allDeletedIds}
                removeAttachment={removeAttachment}
                objectId={objectId}
              />
            </Box>
          )
        })}
      </div>
    )
  }
)
