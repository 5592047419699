import React from 'react'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'
import { StringParam, useQueryParam } from 'use-query-params'

import { StyledTableSampleCell, StyledTableTitle } from '@styles'

import { StyledTableSortWrapper } from './styles'

interface TableHeadCellProps {
  hideBorder: boolean
  content: string
  ordering?: string[]
}

export const TableHeadCell: React.FC<TableHeadCellProps> = ({ hideBorder, content, ordering }) => {
  const options = React.useMemo(
    () => [undefined, ordering?.join(','), ordering?.map(item => '-' + item).join(',')],
    [ordering]
  )

  const [index, setIndex] = React.useState(0)
  const [queryString, setQueryString] = useQueryParam('ordering', StringParam)

  React.useEffect(() => {
    if (ordering) {
      const optionIndex = options.findIndex(option => option === queryString)

      setIndex(optionIndex !== -1 ? optionIndex : 0)
    }
  }, [options, ordering, queryString])

  const onClickHandler = () => {
    if (!ordering) {
      return
    }

    let nextIndex = index + 1
    if (nextIndex === options.length) {
      nextIndex = 0
    }
    setQueryString(options[nextIndex])
  }

  return (
    <StyledTableSampleCell
      sx={{ display: 'flex', alignItems: 'center' }}
      hideBorder={hideBorder}
      padding='15px 0'
      $plFirstChild='67px !important'
    >
      <StyledTableTitle>{content}</StyledTableTitle>
      {ordering && (
        <StyledTableSortWrapper onClick={onClickHandler}>
          <IoMdArrowDropup color={index === 2 ? '#000000' : '#ADB4C2'} />
          <IoMdArrowDropdown color={index === 1 ? '#000000' : '#ADB4C2'} />
        </StyledTableSortWrapper>
      )}
    </StyledTableSampleCell>
  )
}
