import { Tooltip } from '@components/shared'
import { createSampleTable } from '@constants/mappings'
import { Image, Paragraph, StyledTableTitle } from '@styles'
import iconForGpsTooltip from '@assets/icons/gps-tooltip-icon.svg'

import { StyledTableCell } from '../../../../styles'
import { FixedTableHead } from './styles'

type F = (
  content: string,
  isRequired: boolean | undefined,
  hasTooltip?: boolean | undefined
) => JSX.Element

interface ColumnHeadersProps {
  hideBorder: boolean
}

export const ColumnHeaders: React.FC<ColumnHeadersProps> = ({ hideBorder }) => {
  const renderCellContent: F = (content, isRequired, hasTooltip) => (
    <>
      <StyledTableTitle>{content} &nbsp;</StyledTableTitle>
      {isRequired && (
        <Paragraph variant='body1' color='secondary.light'>
          (Required)
        </Paragraph>
      )}

      {hasTooltip && (
        <Tooltip
          title='Type address or coordinates
  Ex.: -77.508333° or 77°30.5`S or 77°30`29.9988``S'
        >
          <Image src={iconForGpsTooltip} width='24px' height='24px' component='img' mt='-3px' />
        </Tooltip>
      )}
    </>
  )

  return (
    <>
      {createSampleTable.tableHeadCells.map(({ id, content, isRequired, hasTooltip }) => (
        <StyledTableCell key={id} hideBorder={hideBorder} fontWeight={700}>
          {renderCellContent(content, isRequired, hasTooltip)}
        </StyledTableCell>
      ))}
      <FixedTableHead>
        <StyledTableTitle>Action</StyledTableTitle>
      </FixedTableHead>
    </>
  )
}
