import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { MAIN_APIS, EXCIPIENTS, PURCHASE_DATE } from '@constants/samples'
import { generateNewSample } from '@helpers/samples'
import { useAppSelector } from '@hooks/redux'
import { selectSamplesChanged, setIsChangedField } from '@state/samples'
import { protectedRoutes } from '@configs/routes.config'
import { ConfirmationModal } from '@components/shared'
import { samplesTouchedFields } from '@state/app'
import { sampleCreate } from '@state/sampleCreate'

export const PageExitModalWrapper: React.FC = () => {
  const isChangedFieldFromStore = useAppSelector(selectSamplesChanged)
  const isTouchedFields = useAppSelector(samplesTouchedFields)
  const history = useHistory()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = React.useState(false)
  const [isChangedAnyField, setIsChangedAnyField] = React.useState(false)
  const [samplesIsEmpty, setSamplesIsEmpty] = React.useState(false)
  const { samples } = useAppSelector(sampleCreate)

  const isChangedField = !isChangedFieldFromStore && isTouchedFields

  const goBackConfirmation = () => {
    history.replace({
      pathname: protectedRoutes.samples.path,
    })
    setIsChangedAnyField(false)
    dispatch(setIsChangedField(false))
  }

  const onBackButtonEvent = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    history.replace({
      state: [
        {
          path: protectedRoutes.samples.path,
          url: protectedRoutes.samples.path,
          title: 'Sample list',
        },
      ],
    })

    if (samplesIsEmpty) setShowModal(true)
    else history.goBack()
  }

  const initialSample = generateNewSample()

  React.useEffect(() => {
    if (samples[0]) {
      if (samples.length > 1) {
        setSamplesIsEmpty(true)
        return
      }
      for (const key in initialSample) {
        if (key === 'isCopy') continue
        else if (key === PURCHASE_DATE)
          samples[0][PURCHASE_DATE].val?.getDay() !== initialSample[PURCHASE_DATE].getDay() &&
            setSamplesIsEmpty(true)
        else if (key === EXCIPIENTS || key === MAIN_APIS)
          samples[0][key].val.length && setSamplesIsEmpty(true)
        else if ((samples[0] as any)[key].val !== (initialSample as any)[key]) {
          setSamplesIsEmpty(true)
        }
      }
    }
  }, [samples, samplesIsEmpty, initialSample])

  React.useEffect(() => {
    window.history.pushState(null, protectedRoutes.samples.path, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)

    if (samplesIsEmpty) {
      setIsChangedAnyField(true)
      dispatch(setIsChangedField(true))
    }

    if (samplesIsEmpty) {
      window.onbeforeunload = () => {
        return 'Are you sure you want to cancel sample creation?'
      }
    }

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
      window.onbeforeunload = () => {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedField, isChangedAnyField, samplesIsEmpty])

  return showModal ? (
    <ConfirmationModal
      title='Are you sure you want to cancel sample creation?'
      cancelBtnText='Continue creation'
      confirmButtonText='Cancel'
      goBackModal
      onClose={() => setShowModal(false)}
      onConfirm={goBackConfirmation}
    />
  ) : null
}
