import React from 'react'
import { Typography } from '@mui/material'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { ISample } from '@models/samples'
import { useToggle } from '@hooks/common'
import { DETAILS } from '@constants/common'
import { protectedRoutes } from '@configs/routes.config'
import { Button, ConfirmationModal, Tooltip } from '@components/shared'
import { useMarkSampleAsMissed, useRemoveSamplesInsidePackage } from '@hooks/queries'
import { FixedTableCell, Link } from '@styles'

interface ActionCellProps {
  sample: ISample
  isLastSample?: boolean
  isMobile?: boolean
  actionBtnText?: 'Remove' | 'Missed'
  shouldDisplayActionBtn?: boolean
  shouldDisplayMissedText?: boolean
}

interface MatchParams {
  packageId: string
}

export const ActionCell: React.FC<ActionCellProps> = React.memo(props => {
  const {
    sample,
    isMobile,
    isLastSample,
    actionBtnText,
    shouldDisplayActionBtn,
    shouldDisplayMissedText,
  } = props

  const removeSampleFromPackageM = useRemoveSamplesInsidePackage()
  const markSampleAsMissedM = useMarkSampleAsMissed()

  const { params } = useRouteMatch<MatchParams>()
  const history = useHistory()

  const [isRemoveModalOpen, toggleRemoveModal] = useToggle()
  const [isMissedModalOpen, toggleMissedModal] = useToggle()

  let modalTitleText: string = ''
  let modalSubtitleText: string = ''

  if (actionBtnText === 'Remove') {
    if (isMobile) {
      modalTitleText = 'Are you sure you want to remove sample from package?'
    } else {
      modalTitleText = 'Are you sure you want to remove '
      modalSubtitleText = 'sample from package?'
    }
  } else if (actionBtnText === 'Missed') {
    if (isMobile) {
      modalTitleText = 'Are you sure that this sample is missed in the package?'
    } else {
      modalTitleText = 'Are you sure that this sample is'
      modalSubtitleText = 'missed in the package?'
    }
  }

  const sampleDetailsEndPoint = {
    pathname: `${protectedRoutes.samples.path}/${sample.id}${DETAILS}`,
    state: history.location.state,
  }

  const removeSampleHandler = () => {
    removeSampleFromPackageM.mutate({ packageId: params.packageId, sample_ids: [sample.id] })
  }

  const markSampleAsMissedHandler = () => {
    markSampleAsMissedM.mutate({ packageId: params.packageId, sampleId: sample.id })
  }

  const actionLoading =
    removeSampleFromPackageM.isLoading ||
    removeSampleFromPackageM.isSuccess ||
    markSampleAsMissedM.isLoading ||
    markSampleAsMissedM.isSuccess

  const isAllowMissedAction = actionBtnText === 'Missed' && !!sample.allow_mark_missed

  return (
    <FixedTableCell id='not-expand' $isMobile={isMobile} isLastSample={isLastSample}>
      {shouldDisplayActionBtn && !sample.missed && (
        <Tooltip title={sample.allow_mark_missed} shouldBeHidden={!isAllowMissedAction}>
          <div>
            <Button
              id='not-expand'
              variant='outlined'
              width='90px'
              padding='0'
              mr={isMobile ? '' : '24px'}
              fz={15}
              loading={actionLoading}
              disabled={actionLoading || isAllowMissedAction}
              onClick={actionBtnText === 'Remove' ? toggleRemoveModal : toggleMissedModal}
            >
              {actionBtnText}
            </Button>
          </div>
        </Tooltip>
      )}

      {sample.missed && shouldDisplayMissedText && (
        <Typography
          sx={{ width: '90px', marginRight: isMobile ? '' : '24px', lineHeight: '28.25px' }}
          color='#F07171'
          textAlign={'center'}
        >
          Missed
        </Typography>
      )}

      {(isRemoveModalOpen || isMissedModalOpen) && (
        <ConfirmationModal
          isMobile={isMobile}
          title={modalTitleText}
          subtitle={modalSubtitleText}
          subtitleLikeTitle
          cancelBtnText='Cancel'
          isDisabled={actionLoading}
          confirmButtonText={actionBtnText === 'Remove' ? 'Remove' : 'Confirm'}
          onClose={actionBtnText === 'Remove' ? toggleRemoveModal : toggleMissedModal}
          onConfirm={actionBtnText === 'Remove' ? removeSampleHandler : markSampleAsMissedHandler}
        />
      )}

      {!isMobile && <Link to={sampleDetailsEndPoint}>View details</Link>}
    </FixedTableCell>
  )
})
