import { styled as styledMUI, FormControlLabel, Typography, List } from '@mui/material'
import styled from 'styled-components'

export const StyledForm = styled('form')`
  & .MuiInputBase-root:not(.MuiInputBase-multiline) {
    height: 48px;
  }

  & .MuiTypography-h5 {
    margin-bottom: 20px;
  }

  & .MuiTypography-h3:not(:first-of-type) {
    margin-top: 62px;
  }
`

export const StyledLabel = styledMUI(FormControlLabel)`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: 0;
  margin-bottom: 43px;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: ${({ theme }) => theme.palette.text.primary};
  max-width: 408px;

  & .MuiFormControlLabel-label {
    font-weight: 400;
    margin-bottom: 5px;
  }

  & .MuiFormHelperText-root {
    margin-top: 4px;
    margin-left: 0;
    margin-right: 0;
  }
`

export const StyledTypography = styledMUI(Typography)`
  color: #8f929b;
  position: absolute;
  top: -24px;
  right: 0;
`

export const StyledRow = styled('div')`
  position: relative;
  width: 100%;
`

export const StyledList = styledMUI(List)`
  padding: 0;
  margin: 0;

  & .MuiListItem-root {
    padding: 0;
    margin: 0 0 1rem 0;
    font-weight: 500;
  }

  & .MuiLink-root {
    cursor: pointer;
  }

  & .MuiListItemText-root span {
    font-weight: 500;
  }
`
