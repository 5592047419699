export const containerStyles = {
  border: '1px solid #E1E7F3',
  borderRadius: '4px',
  backgroundColor: '#ffffff',
}

export const inputStyles = {
  border: '1px solid #E1E7F3',
  height: '46.3px',
  fontSize: '16px',
  borderColor: 'transparent',
  paddingLeft: '95px',
  color: '#0F1934',
  backgroundColor: '#ffffff',
  width: '256px',
}

export const buttonStyles = {
  width: '50px',
  backgroundColor: '#ffffff',
  paddingLeft: '10px',
  borderColor: 'transparent',
  borderRight: '1px solid #E1E7F3',
}
