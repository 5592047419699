import React from 'react'
import { styled, Box } from '@mui/material'

import { customScrollBarStyles } from '@components/ui/commonUIStyles'

const commonStyles = `
  padding: 10px 12px;
  width: 100%;
	border-radius: 4px;
  border: 1px solid #e1e7f3;
  line-height: 23px;
  outline: transparent;
  background: transparent;
  box-sizing: border-box;
  &:hover {
    border-color: #0f1934;
  }
  &:focus {
    border-color: #3698e5;
    box-shadow: 0 0 0 1px #3698e5 inset;
  }
  &.border-error {
    border-color: #af4645;
  }
`

export const IconBox = styled<React.FC<{ $position: string }>>(Box)`
  display: flex;
  position: absolute;
  top: 14px;
  left: ${({ $position }) => ($position === 'left' ? '12px' : 'auto')};
  right: ${({ $position }) => ($position === 'right' ? '12px' : 'auto')};
`

export const Input = styled('input')`
  position: relative;
  z-index: 1;
  ${commonStyles}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.input-error {
    border-color: #af4645;

    &:focus {
      box-shadow: 0 0 0 1px #af4645 inset;
    }
  }
`

export const Textarea = styled('textarea')`
  max-width: 100%;
  ${commonStyles}
  ${customScrollBarStyles}
  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  &.input-error {
    border-color: #af4645;

    &:focus {
      box-shadow: 0 0 0 1px #af4645 inset;
    }
  }
`
