import React from 'react'
import { FiPlus as AddIcon } from 'react-icons/fi'
import { PaperProps as PaperPropsMUI } from '@mui/material'

import { AutocompletePaper } from '@components/shared'
import { getAddBtnText } from '@helpers/ui/autocomplete'
import { useAddNewManufacturer } from '@hooks/queries'

interface PaperProps extends PaperPropsMUI {
  inputValue: string
  name: string
  hasError: boolean
  type: string
}

export const Paper: React.FC<PaperProps> = props => {
  const { inputValue, name, type, hasError, ...restProps } = props
  const AddNewManufacturerM = useAddNewManufacturer()

  const isLoading = AddNewManufacturerM.isLoading

  const isIdle = AddNewManufacturerM.isIdle

  const addNewTypeHandler: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()
    AddNewManufacturerM.mutate(inputValue)
  }

  return (
    <AutocompletePaper
      btnIsLoading={isLoading}
      onBtnClick={addNewTypeHandler}
      btnIcon={<AddIcon color='primary' />}
      btnText={getAddBtnText({ isIdle, isLoading })}
      inputValue={inputValue}
      shouldRenderBtn={!!inputValue}
      {...restProps}
    />
  )
}
