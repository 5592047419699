import React from 'react'

import { StyledTableHead, StyledTableHeadWrapper, StyledTableReportsRow } from '@styles'
import { ISamples } from '@models/samples'

import { TableHeadTitles } from './TableHeadTitles'
import { ReportsToggler } from './ReportsToggler'

interface TableHeadProps {
  hideBorder: boolean
  reports: ISamples
}

export const TableHead = React.forwardRef<HTMLDivElement, TableHeadProps>(
  ({ hideBorder, reports }, ref) => {
    return (
      <StyledTableHeadWrapper ref={ref} hideBorder={hideBorder} zIndex={350} top='148px'>
        <StyledTableHead reportsContent>
          <StyledTableReportsRow>
            <ReportsToggler reports={reports} />

            <TableHeadTitles hideBorder={!hideBorder} />
          </StyledTableReportsRow>
        </StyledTableHead>
      </StyledTableHeadWrapper>
    )
  }
)
