import React from 'react'
import { AiOutlineFolder } from 'react-icons/ai'

import { sampleDetailsTabs } from '@constants/mappings'
import { ISampleInfoTabValue } from '@models/samples'

import { StyledTab, StyledTabList } from './styles'

interface TabButtonsProps {
  onChangeTab: (tab: ISampleInfoTabValue) => void
}

export const TabButtons: React.FC<TabButtonsProps> = ({ onChangeTab }) => {
  const changeTabHandler = (e: React.SyntheticEvent, tab: ISampleInfoTabValue) => onChangeTab(tab)

  return (
    <StyledTabList onChange={changeTabHandler}>
      {sampleDetailsTabs.map(tab => (
        <StyledTab icon={<AiOutlineFolder />} iconPosition='start' key={tab.value} {...tab} />
      ))}
    </StyledTabList>
  )
}
