import React from 'react'

import { StyledTableHead, StyledTableHeadWrapper, StyledTableTasksRow } from '@styles'

import { TableHeadTitles } from './TableHeadTitles'
import { TableHeadProps } from './tableHead.types'

export const TableHead = React.forwardRef<HTMLDivElement, TableHeadProps>(
  ({ hideBorder, isAwaitingContentType, isHasToxicity, isHasTemperature }, ref) => {
    return (
      <StyledTableHeadWrapper ref={ref} hideBorder={hideBorder} zIndex={350} top='130px'>
        <StyledTableHead>
          <StyledTableTasksRow
            isAwaitingContentType={isAwaitingContentType}
            // ----- THESE TWO PROPS BELOW MAY BE ADDED IN THE FUTURE -----
            // isHasToxicity={isHasToxicity}
            // isHasTemperature={isHasTemperature}
            // ------------------------------------------------------------
            isHasToxicity={false}
            isHasTemperature={false}
          >
            <TableHeadTitles
              hideBorder={!hideBorder}
              isAwaitingContentType={isAwaitingContentType}
              isHasToxicity={isHasToxicity}
              isHasTemperature={isHasTemperature}
            />
          </StyledTableTasksRow>
        </StyledTableHead>
      </StyledTableHeadWrapper>
    )
  }
)
