import styled from 'styled-components'

export const FooterBox = styled.footer<{ position?: string; isFooterHidden?: boolean }>`
  position: ${({ position }) => position};
  bottom: 0;
  right: 0;
  left: 0;
  display: ${({ isFooterHidden }) => (isFooterHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  padding: 10px 80px;
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const LogoImg = styled('img')`
  display: block;
  width: 130px;
  max-width: 100%;

  &.footer-logo-pad {
    width: 100%;
    max-width: 41px;
  }
`
