import React from 'react'
import { Typography } from '@mui/material'

import { ISampleTask } from '@models/samples'
import { StyledDetailsTableCell } from '@styles'
import { useAppSelector } from '@hooks/redux'
import { selectUserLaboratory } from '@state/user'
import { Chip, Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_APIS_LENGTH } from '@constants/common'

import { StyledTypography } from './styles'

interface ApiContentProps {
  task: ISampleTask
}

export const ApiContent: React.FC<ApiContentProps> = ({ task }) => {
  const {
    group: { is_able_to_view_results },
  } = useAppSelector(selectUserLaboratory)

  if (!is_able_to_view_results && task.main_apis === null)
    return (
      <StyledDetailsTableCell
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Chip label='Hidden' status='hidden' />{' '}
      </StyledDetailsTableCell>
    )

  return (
    <StyledDetailsTableCell
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      {task.main_apis?.map(api => (
        <React.Fragment key={api.id}>
          <Tooltip
            isPureTooltip
            title={api.name}
            shouldBeHidden={api.name.length <= TRUNCATED_TEXT_APIS_LENGTH}
          >
            <StyledTypography>{stringSlice(api.name, TRUNCATED_TEXT_APIS_LENGTH)}</StyledTypography>
          </Tooltip>

          <Typography variant='body2' color='#616d84'>
            {(api.percentage && `${parseFloat(api.percentage)}%`) || '-'}
          </Typography>
        </React.Fragment>
      ))}
    </StyledDetailsTableCell>
  )
}
