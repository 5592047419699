import React from 'react'

import { useActionsStickyBox } from '@hooks/common'
import { HorizontalDragCup, HorizontalDragLayer, ScrollNextSamplesLoader } from '@components/shared'
import { StyledTableBox } from '@styles'
import { ILaboratory } from '@models/common/app'
import { draggableHorizontalScroll } from '@helpers/draggableHorizontalScroll'

import { TableHead } from './TableHead'
import { TableBody } from './TableBody'

interface TableProps {
  laboratories?: ILaboratory[]
  isFetchingNextPage?: boolean
}

export const Table: React.FC<TableProps> = props => {
  const { isFetchingNextPage, laboratories } = props
  const draggableHorizontalRef = React.useRef<HTMLDivElement | null>(null)
  const horizontalDragCup = React.useRef<HTMLDivElement | null>(null)
  const draggableHorizontalLayerRef = React.useRef<HTMLDivElement | null>(null)
  const tableHeadRef = React.useRef<HTMLDivElement | null>(null)
  const isSticky = useActionsStickyBox(tableHeadRef, '-250px 0px 0px 0px')

  React.useEffect(() => {
    if (draggableHorizontalRef.current) {
      const { removeDraggableEvents } = draggableHorizontalScroll(
        draggableHorizontalRef,
        draggableHorizontalLayerRef,
        horizontalDragCup
      )
      return () => removeDraggableEvents()
    }
  }, [])

  const renderRowsLoader = () =>
    new Array(10).fill('').map((_, idx) => <ScrollNextSamplesLoader key={idx} />)

  const horizontalScrollHandler: React.UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    tableHeadRef.current?.scrollTo(currentTarget.scrollLeft, 0)
  }

  return (
    <>
      <TableHead ref={tableHeadRef} hideBorder={isSticky} />
      <HorizontalDragCup ref={horizontalDragCup} />
      <StyledTableBox ref={draggableHorizontalRef} onScroll={horizontalScrollHandler}>
        <HorizontalDragLayer ref={draggableHorizontalLayerRef} />
        <TableBody laboratories={laboratories} />
      </StyledTableBox>

      {isFetchingNextPage && renderRowsLoader()}
    </>
  )
}
