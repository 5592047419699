import React from 'react'

import { FilterCheckbox } from '@components/shared/filters/components/FilterCheckbox'

interface ListOfAssigneeProps {
  idx: number
  id: string
  itemsLength: number
  firstName: string
  lastName: string
  labOwner?: boolean
}

export const ListOfAssignee: React.FC<ListOfAssigneeProps> = React.memo(props => {
  const { idx, id, firstName, lastName, labOwner } = props
  return (
    <FilterCheckbox
      type='assignees'
      item={{
        id,
        name: `${firstName} ${lastName}`,
      }}
      key={idx}
      labOwner={labOwner}
    />
  )
})
