import React from 'react'

import { viewSampleTableAwaiting, viewSampleTableCompleted } from '@constants/mappings'
import { FixedTableCell, StyledTableTitle } from '@styles'
import { TOXICITY, TEMPERATURE } from '@constants/tasks'

import { TableColumnTitle } from '../components'
import { TableHeadTitlesProps } from './tableHeadTitles.types'

export const TableHeadTitles = React.memo<TableHeadTitlesProps>(props => {
  const { hideBorder, isAwaitingContentType } = props

  return (
    <>
      {isAwaitingContentType
        ? viewSampleTableAwaiting.tableHeadCells.map(({ id, ...props }) => {
            // ----- THESE TWO FIELDS BELOW MAY BE ADDED IN THE FUTURE -----
            // if (!isHasToxicity && props.content === TOXICITY) return null
            // if (!isHasTemperature && props.content === TEMPERATURE) return null
            if (props.content === TOXICITY) return null
            if (props.content === TEMPERATURE) return null
            // -------------------------------------------------------------
            return <TableColumnTitle key={id} {...props} hideBorder={hideBorder} />
          })
        : viewSampleTableCompleted.tableHeadCells.map(({ id, ...props }) => {
            // ----- THESE TWO FIELDS BELOW MAY BE ADDED IN THE FUTURE -----
            // if (!isHasToxicity && props.content === TOXICITY) return null
            // if (!isHasTemperature && props.content === TEMPERATURE) return null
            if (props.content === TOXICITY) return null
            if (props.content === TEMPERATURE) return null
            // -------------------------------------------------------------
            return <TableColumnTitle key={id} {...props} hideBorder={hideBorder} />
          })}

      <FixedTableCell
        hideBorder={hideBorder}
        sx={{
          borderTopLeftRadius: '10px',
          boxShadow: '-7px -4px 20px -5px rgba(53, 108, 151, 0.12)',
        }}
      >
        <StyledTableTitle>Action</StyledTableTitle>
      </FixedTableCell>
    </>
  )
})
