import { styled, Typography } from '@mui/material'

export const StyledTitle = styled(Typography)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(97)};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const ButtonBox = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
