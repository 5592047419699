import React from 'react'
import { Box } from '@mui/material'

import { AttachmentPhoto } from '@models/samples'
import { Attachment } from '@components/shared/Attachment'
import { validPhotoExtensions } from '@constants/common'
import { IAttachments } from '@models/attachments'

import { Photo } from '../Photo'

interface PhotoWithAttachmentsProps {
  attachments?: AttachmentPhoto[]
  width?: string
  height?: string
  mr?: string
  isGoodQualityOfPhoto?: boolean
}

export const PhotoWithAttachments: React.FC<PhotoWithAttachmentsProps> = props => {
  const { attachments, width = '45px', height = '45px', mr, isGoodQualityOfPhoto } = props
  function hasValidPhotoExtension(name: string) {
    return validPhotoExtensions.some(ext => name.toLowerCase().endsWith(`.${ext}`))
  }

  const photoOfTestStrip = attachments?.filter(attachment => {
    return hasValidPhotoExtension(attachment.name)
  })

  const anotherAttachments = attachments?.filter(
    attachment => !photoOfTestStrip?.some(item => item.id === attachment.id)
  ) as IAttachments

  return (
    <>
      {!!anotherAttachments?.length
        ? anotherAttachments?.map(attachment => (
            <Box width='60%' key={attachment.id}>
              <Attachment truncatedTextLength={20} cantDelete attachment={attachment} />
            </Box>
          ))
        : null}

      {!!photoOfTestStrip?.length ? (
        <Photo
          attachments={photoOfTestStrip}
          isGoodQualityOfPhoto={isGoodQualityOfPhoto}
          height={height}
          width={width}
          mr={mr}
        />
      ) : null}
    </>
  )
}
