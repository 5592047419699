import React from 'react'
import { isAfter, isToday } from 'date-fns'
import { useWatch, Validate } from 'react-hook-form'

import {
  LOT,
  TOWN,
  BRAND,
  NOTES,
  DOSAGE,
  COUNTRY,
  PHARMACY,
  // MAIN_APIS,
  // DRUG_CLASS,
  BUYERS_NAME,
  DOSAGE_TYPE,
  // MANUFACTURER,
  PHARMACY_GPS,
  // OPTIONAL_APIS,
  PURCHASE_DATE,
  EXPIRATION_DATE,
  MANUFACTURE_DATE,
  REGULATORY_REGISTRATION,
  UNITS,
  UNIT_TYPE,
  VALUE,
  VALUE_TYPE,
  ADDRESS_OF_MANUFACTURER,
} from '@constants/samples'
import {
  Input,
  // ApiField,
  DatePicker,
  Autocomplete,
  PriceValue,
  GMPlaceAutocomplete,
} from '@components/shared/sampleFields'
import { SampleOptionsContext } from '@context'
import { REQUIRED_FIELD } from '@constants/validation'
// import { MAIN_APIS_REQUIRED  } from '@constants/validation'
import { DropdownInput } from '@components/shared/sampleFields/DropdownInput'
import { Units } from '@components/shared/sampleFields/Units'
import { RadioButtons } from '@components/shared/sampleFields/RadioButtons'

import { Action, Counter } from './components'
import { StyledTableCell } from './styles'

interface TableCellsProps {
  baseName: string
  counter: number
  isCopy: boolean
  sampleIdx: number
  isLastSample?: boolean
}

export const TableCells: React.FC<TableCellsProps> = props => {
  const { baseName, isCopy, counter, sampleIdx, isLastSample } = props

  const {
    // drugClasses,
    // apis,
    towns,
    pharmacies,
    // manufacturers,
    manufacturerAddresses,
    brands,
    countries,
    dosageType,
    unitType,
    valueType,
    regulatoryRegistrationsType,
  } = React.useContext(SampleOptionsContext)
  const [manufactureDate, expirationDate] = useWatch({
    name: [`${baseName}${MANUFACTURE_DATE}`, `${baseName}${EXPIRATION_DATE}`],
  })

  const cells = [
    // {
    //   name: `${baseName}${DRUG_CLASS}`,
    //   field: Autocomplete,
    //   options: drugClasses,
    //   rules: { required: REQUIRED_FIELD },
    //   isCopy,
    // },

    {
      name: `${baseName}${DOSAGE}`,
      dropdownName: `${baseName}${DOSAGE_TYPE}`,
      field: DropdownInput,
      dropdownOptions: [...dosageType].reverse(),
      pr: '65px',
      rules: {
        required: REQUIRED_FIELD,
        maxLength: {
          value: 13,
          message: 'Max 13 characters',
        },
        min: {
          value: 0,
          message: `The value cannot be negative`,
        },
        validate: {
          digit: (v: string) => {
            return !isNaN(Number(v)) || 'Format must be either 1 or 1.01'
          },
          dosageIntFormat: (v: string) => {
            return (
              /^\d{1,6}$|^\d{1,6}\.\d{1,6}$/.test(v) ||
              'Maximum number of characters 6 after dot and 6 before dot'
            )
          },
          greaterThanMinValue: (v: string) => {
            return Number(v) >= 0.000001 || 'Ensure this value is greater than or equal to 0.000001'
          },
        },
      },
      isCopy,
    },

    {
      name: `${baseName}${UNITS}`,
      dropdownName: `${baseName}${UNIT_TYPE}`,
      field: Units,
      dropdownOptions: unitType,
      placeholder: 'Amount',
      pr: '162px',
      rules: {
        required: REQUIRED_FIELD,
        validate: {
          decimal: (v: string) => {
            return +v % 1 === 0 || !!isNaN(Number(v)) || 'Value cannot be decimal'
          },
          then1000: (v: string) => {
            return !(+v > 1000) || 'Value cannot be more than 1000'
          },
          сanNotBeZero: (v: string) => {
            return !(+v === 0) || 'Value cannot be 0'
          },
          negative: (v: string) => {
            return !(+v < 0) || "Value can't be negative"
          },
          digit: (v: string) => {
            return !isNaN(Number(v)) || 'May contain only numbers'
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
      isCopy,
    },

    {
      name: `${baseName}${VALUE}`,
      dropdownName: `${baseName}${VALUE_TYPE}`,
      field: PriceValue,
      type: 'create_samples',
      options: valueType,
      dropdownWidth: '180px',
      placeholder: 'Value per 1 unit',
      pr: '106px',
      rules: {
        validate: {
          negative: (v: string) => {
            return !(+v < 0) || "Value can't be negative"
          },
          min: (v: string) => {
            return !(v?.split('.')[1]?.length > 4) || 'Min 0.0001 number'
          },
          max: (v: string) => {
            return !(+v > 999999999) || 'Max 999999999 number'
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
      baseName,
      isCopy,
    },

    // {
    //   name: `${baseName}${MAIN_APIS}`,
    //   nameToSync: `${baseName}${OPTIONAL_APIS}`,
    //   field: ApiField,
    //   options: apis,
    //   rules: { required: MAIN_APIS_REQUIRED },
    //   isCopy,
    // },

    // {
    //   name: `${baseName}${OPTIONAL_APIS}`,
    //   nameToSync: `${baseName}${MAIN_APIS}`,
    //   field: ApiField,
    //   options: apis,
    //   isOptional: true,
    //   isCopy,
    // },

    {
      name: `${baseName}${NOTES}`,
      field: Input,
      rows: 3,
      multiline: true,
      rules: {
        maxLength: {
          value: 150,
          message: 'Maximum length - 150 characters',
        },
      },
      isCopy,
    },

    {
      name: `${baseName}${LOT}`,
      field: Input,
      rules: {
        maxLength: {
          value: 50,
          message: 'Max 50 characters',
        },
      },
      isCopy,
    },

    {
      name: `${baseName}${BUYERS_NAME}`,
      field: Input,
      rules: {
        maxLength: {
          value: 50,
          message: 'Max 50 characters',
        },
      },
      isCopy,
    },

    {
      name: `${baseName}${PURCHASE_DATE}`,
      field: DatePicker,
      disableFuture: true,
      rules: {
        validate: {
          purchaseDateLaterThanManufacture: (purchaseDate: Date) => {
            return (
              !Boolean(purchaseDate && manufactureDate) ||
              isAfter(purchaseDate, manufactureDate) ||
              'Purchase date must be later than manufacture date'
            )
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
      isCopy,
    },

    {
      name: `${baseName}${TOWN}`,
      field: GMPlaceAutocomplete,
      dataOptions: towns,
      isCopy,
    },

    {
      name: `${baseName}${PHARMACY}`,
      field: Autocomplete,
      options: pharmacies,
      newOptionsType: PHARMACY,
      enableToAddOptions: true,
      isCopy,
    },

    {
      name: `${baseName}${PHARMACY_GPS}`,
      placeholder: 'Type address or coordinates...',
      field: Input,
      rules: {
        maxLength: {
          value: 50,
          message: 'Max 50 characters',
        },
      },
      isCopy,
    },

    // {
    //   name: `${baseName}${MANUFACTURER}`,
    //   field: Autocomplete,
    //   options: manufacturers,
    //   newOptionsType: MANUFACTURER,
    //   enableToAddOptions: true,
    //   pr: '30px',
    //   isCopy,
    // },

    {
      name: `${baseName}${ADDRESS_OF_MANUFACTURER}`,
      field: GMPlaceAutocomplete,
      dataOptions: manufacturerAddresses,
      rules: {
        maxLength: {
          value: 150,
          message: 'Max 150 characters',
        },
      },
      isCopy,
    },

    {
      name: `${baseName}${BRAND}`,
      field: Autocomplete,
      options: brands,
      newOptionsType: BRAND,
      enableToAddOptions: true,
      isCopy,
    },

    {
      name: `${baseName}${MANUFACTURE_DATE}`,
      field: DatePicker,
      disableFuture: true,
      rules: {
        validate: {
          manufactureDateEarlierThanExpiration: (manufactureDate: Date) => {
            return (
              !Boolean(manufactureDate && expirationDate) ||
              !(isToday(manufactureDate) && isToday(expirationDate)) ||
              'Manufacture date must be earlier than the expiration date'
            )
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
      isCopy,
    },

    {
      name: `${baseName}${EXPIRATION_DATE}`,
      field: DatePicker,
      disablePast: true,
      isCopy,
    },

    {
      name: `${baseName}${REGULATORY_REGISTRATION}`,
      field: RadioButtons,
      options: regulatoryRegistrationsType,
      isCopy,
    },

    {
      name: `${baseName}${COUNTRY}`,
      field: Autocomplete,
      options: countries,
      isCopy,
    },
  ]

  return (
    <>
      <Counter counter={counter} isLastSample={isLastSample} />
      {cells.map(({ field: Field, ...props }) => {
        return (
          <StyledTableCell key={props.name}>
            <Field {...props} />
          </StyledTableCell>
        )
      })}
      <Action sampleIdx={sampleIdx} isLastSample={isLastSample} />
    </>
  )
}
