import { useMediaQuery, useTheme } from '@mui/material'

export const AutoLogoutImage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery('(max-width:767px)')

  return (
    <svg
      width={isMobile ? '182px' : '238px'}
      height={isMobile ? '140px' : '183px'}
      viewBox='0 0 239 184'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <ellipse cx='117.5' cy='170' rx='77' ry='14' fill='#ECF1FA' />
      <ellipse
        rx='2.97689'
        ry='2.97878'
        transform='matrix(1 0 0 -1 25.0472 32.322)'
        fill={theme.primary[20]}
      />
      <ellipse
        rx='9.92296'
        ry='9.92925'
        transform='matrix(1 0 0 -1 201.423 106.102)'
        fill={theme.primary[20]}
      />
      <ellipse
        rx='2.97689'
        ry='2.97878'
        transform='matrix(-1 0 0 1 226.484 73.5296)'
        fill={theme.primary[20]}
      />
      <ellipse
        rx='2.97689'
        ry='2.97878'
        transform='matrix(-1 0 0 1 2.97722 113.127)'
        fill={theme.primary[20]}
      />
      <ellipse
        rx='4.96148'
        ry='4.96463'
        transform='matrix(-1 0 0 1 39.0063 127.051)'
        fill={theme.primary[20]}
      />
      <ellipse
        rx='4.96148'
        ry='4.96463'
        transform='matrix(-1 0 0 1 211.566 5.95291)'
        fill={theme.primary[20]}
      />
      <path
        d='M179.99 54.8159L169.406 79.3088C164.222 91.3045 152.382 98.4797 140.039 98.398C135.945 98.3655 131.798 97.5352 127.817 95.8148C123.836 94.0944 120.39 91.6439 117.56 88.6841C109.043 79.7515 106.154 66.2112 111.338 54.2156L121.922 29.7227L179.99 54.8159Z'
        fill='#E4F4F9'
      />
      <path
        d='M146.264 132.861L133.031 163.483L74.9623 138.39L88.1954 107.767C93.3791 95.7718 105.22 88.5965 117.554 88.6814L117.56 88.6838C121.657 88.7107 125.803 89.5411 129.784 91.2615C133.765 92.9819 137.211 95.4324 140.039 98.3977L140.044 98.4001C148.559 107.325 151.447 120.865 146.264 132.861Z'
        fill='#E4F4F9'
      />
      <path
        d='M131.139 168.629L72.5126 143.295C71.1252 142.695 70.4859 141.084 71.0862 139.694C71.6861 138.306 73.2974 137.666 74.6858 138.266L133.312 163.6C134.699 164.199 135.339 165.811 134.738 167.2C134.138 168.589 132.527 169.229 131.139 168.629Z'
        fill='#66BAEF'
      />
      <path
        d='M179.184 57.4493L120.557 32.115C119.17 31.5155 118.531 29.9039 119.131 28.5145C119.731 27.1263 121.342 26.4859 122.731 27.0859L181.357 52.4202C182.744 53.0198 183.384 54.6314 182.783 56.0207C182.183 57.4089 180.572 58.0493 179.184 57.4493Z'
        fill='#66BAEF'
      />
      <path
        d='M130.84 156.318C131.922 153.013 139.826 139.862 137.112 134.848C130.841 127.497 116.233 135.442 108.598 133.058C100.963 130.673 99.6719 123.723 93.0529 121.634C87.7577 119.963 83.3857 130.221 81.8616 135.56C81.3241 136.831 82.1291 138.387 83.6603 139.034L127.347 157.498C128.82 158.12 130.427 157.581 130.84 156.318Z'
        fill='#FFCA67'
      />
      <path
        d='M108.981 136.509C104.181 135.709 97.648 129.176 94.9814 126.009C92.3148 122.842 91.3147 128.009 92.4814 129.509C93.6481 131.009 96.9814 134.709 100.981 137.509C104.981 140.309 113.648 141.342 117.481 141.509C121.315 141.675 118.315 137.509 117.481 137.509C116.648 137.508 113.782 137.308 108.981 136.509Z'
        fill='#FFF5D6'
      />
      <path
        d='M130.289 136.29C129.658 136.404 129.028 136.517 128.398 136.632C127.369 136.819 126.469 137.752 126.653 138.861C126.822 139.88 127.779 140.804 128.88 140.606C129.511 140.491 130.141 140.378 130.771 140.263C131.8 140.076 132.7 139.143 132.516 138.034C132.347 137.015 131.391 136.091 130.289 136.29Z'
        fill='#FFF5D6'
      />
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M122.873 39.6878C121.624 42.8367 120.21 45.904 118.626 48.899C118.024 50.0372 119.863 50.8253 120.462 49.6921C122.046 46.6971 123.459 43.6298 124.708 40.4809C125.182 39.286 123.348 38.4879 122.873 39.6878Z'
          fill='white'
        />
      </g>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M117.063 53.1604C116.648 53.9594 116.387 54.8344 116.282 55.7281C116.133 57.0044 118.114 57.2886 118.263 56.0047C118.349 55.2701 118.558 54.6098 118.898 53.9535C119.148 53.4739 118.848 52.8415 118.377 52.6379C117.851 52.4107 117.312 52.6781 117.062 53.1599L117.063 53.1604Z'
          fill='white'
        />
      </g>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M93.5327 106.36C93.1227 106.861 92.7127 107.362 92.3028 107.863C91.9602 108.281 92.0371 108.935 92.4556 109.27C92.8816 109.612 93.5186 109.535 93.8617 109.116C94.2717 108.615 94.6817 108.114 95.0916 107.613C95.4342 107.195 95.3573 106.541 94.9388 106.206C94.5128 105.864 93.8758 105.94 93.5327 106.36Z'
          fill='white'
        />
      </g>
    </svg>
  )
}
