import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'

import { ASSIGNEE_PERSON } from '@constants/queries'
import { fetchAssigneePersonF } from '@api/samples'
import { IAssigneeFilters } from '@models/filters'

export const useFetchAssigneeFilters = (
  desiredAssignee: string | undefined
): UseInfiniteQueryResult<IAssigneeFilters> => {
  return useInfiniteQuery([ASSIGNEE_PERSON, desiredAssignee], fetchAssigneePersonF, {
    getNextPageParam: lastPage => {
      if (lastPage.current_page < lastPage.count_pages) return lastPage.current_page + 1
      return false
    },
  })
}
