import React from 'react'

import { IViewSampleCell } from '@models/samples/ui'
import { CellsOrdering } from '@components/shared'

import { StyledTableCell } from '../styles'
import { StyledTableTitle } from '../TableHeadTitles/styles'

interface TableSortButtonProps extends Omit<IViewSampleCell, 'id'> {
  isSticky: boolean
}

export const TableColumnTitle: React.FC<TableSortButtonProps> = props => {
  const { ordering, isSticky, content } = props

  return (
    <>
      <StyledTableCell isSticky={isSticky}>
        <StyledTableTitle>{content}</StyledTableTitle>
        {ordering && <CellsOrdering ordering={ordering} />}
      </StyledTableCell>
    </>
  )
}
