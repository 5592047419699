import { Paper, styled, Typography } from '@mui/material'

export const StyledPopper = styled('div')``

export const StyledBox = styled(Paper)`
  display: none;
  position: absolute;
  top: -70px;
  right: 10px;
  padding: 15px;
  border: 1px solid #d9dde4;
  box-sizing: border-box;
  box-shadow: 1px 12px 20px rgba(103, 122, 170, 0.08);
  border-radius: 4px;
  z-index: 10000;
  box-shadow: 1px 12px 20px rgba(103, 122, 170, 0.08);

  &::after {
    content: '';
    position: absolute;
    right: 15px;
    bottom: -5.5px;
    width: 8px;
    height: 8px;

    border-bottom-style: solid;
    border-left-style: solid;
    border-bottom-color: #d9dde4;
    border-left-color: #d9dde4;
    border-bottom-width: 1px;
    border-left-width: 1px;
    transform: rotate(0.87turn);
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`

export const PopperText = styled(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #616d84;
`
