import { Chip, Stack, styled } from '@mui/material'

export const StyledBox = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledStack = styled(Stack)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  padding: 2px 0 2px 5px;

  & > :not(style) + :not(style) {
    margin-right: 5px;
  }
`

export const StyledChip = styled(Chip)`
  padding: 4px 0;
  background: ${({ theme }) => theme.palette.secondary.light};
  font-size: 14px;

  &:first-child {
    margin-top: 6.4px;
    margin-right: 5px;
  }

  & .MuiChip-filled {
    display: block;
  }

  & .MuiChip-icon {
    margin-left: 12px;
    max-width: 6px;
    max-height: 6px;
  }
`
