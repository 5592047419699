import React from 'react'

import { Button } from '@components/shared'

interface EditInfoProps {
  toggleEditing: () => void
}

export const EditInfo: React.FC<EditInfoProps> = ({ toggleEditing }) => {
  const toggleEditingHandler: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()
    toggleEditing()
  }

  return (
    <Button variant='contained' width='205px' onClick={toggleEditingHandler}>
      Edit
    </Button>
  )
}
