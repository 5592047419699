import { useFormContext } from 'react-hook-form'
import React from 'react'

import { Input } from '@components/shared'

export interface InputFieldProps {
  name: string
  isDisabled?: boolean
  width?: string
  ml?: string
  apiError?: string
  placeholder?: string
}

export const InputField: React.FC<InputFieldProps> = ({
  name,
  isDisabled,
  width,
  ml,
  apiError,
  placeholder,
}) => {
  const { register, getValues, formState } = useFormContext()
  const value = getValues(name)
  const error = name === 'address' && formState.errors?.address?.message

  return (
    <Input
      fullWidth
      width={width}
      ml={ml}
      disabled={isDisabled}
      height={48}
      variant='outlined'
      placeholder={placeholder || value}
      helperText={error}
      error={!!apiError || !!error}
      {...register(name)}
    />
  )
}
