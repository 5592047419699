import React from 'react'
import { useController, useWatch } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { PureInput, PureRadioButtons } from '@components/ui'
import { setCellValueToSample } from '@state/sampleCreate'
import { MAIN_APIS, MIN_TEMPERATURE, MAX_TEMPERATURE, TEMPERATURE_NAME } from '@constants/samples'
import { CELSIUS, FAHRENHEIT } from '@constants/common'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'
import { ReactComponent as TemperatureIcon } from '@assets/icons/thermometer.svg'
import { REQUIRED_FIELD } from '@constants/validation'
import { obtainingCorrectTemperature } from '@helpers/obtainingCorrectTemperature'
import { obtainingSkills } from '@helpers/obtainingSkills'
import { sampleCreateUIData } from '@state/common/sampleCreateUIState'

import {
  StyledAdditionalText,
  StyledInputLabel,
  StyledSpanMaxMin,
  StyledTemperatureInputBox,
} from './styles'
import { PureCellPropTypes } from './pureFields.types'
import { TableCellWrapper } from './TableCellWrapper'

export interface StorageTemperatureProps extends PureCellPropTypes {
  isOnlyTemperature?: boolean
}
export const StorageTemperature: React.FC<StorageTemperatureProps> = props => {
  const {
    initialVal,
    cellName,
    sampleId,
    isCopy,
    name,
    control,
    multipleInitialValues,
    isEditing = false,
    isOnlyTemperature = false,
    clearErrors,
    width,
  } = props
  const checkInitialValuesRef = React.useRef<boolean>(false)
  const fieldApiName = isEditing ? [MAIN_APIS] : [`${sampleId}_${MAIN_APIS}`]
  const [api] = useWatch({ control, name: fieldApiName })
  const isHaveTemperatureInSkills =
    isOnlyTemperature || !!obtainingSkills(api).find(s => s.name === TEMPERATURE_NAME)
  const {
    temperature_measurement_format: { key: profileTemperatureFormat },
  } = useAppSelector(selectUser)
  const { isTemperatureAdded } = useAppSelector(sampleCreateUIData)
  const dispatch = useDispatch()
  const minTemp = React.useMemo(
    () => multipleInitialValues?.find(item => item.cellName.includes(MIN_TEMPERATURE)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const maxTemp = React.useMemo(
    () => multipleInitialValues?.find(item => item.cellName.includes(MAX_TEMPERATURE)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const radioButtonsData = [
    { id: CELSIUS, label: 'Celsius (°C)' },
    { id: FAHRENHEIT, label: 'Fahrenheit (°F)' },
  ]

  const { field: fieldTemperatureFormat } = useController({ name: name, control })

  const [temperatureFormat, setTemperatureFormat] = React.useState<string>(
    isEditing
      ? fieldTemperatureFormat.value
      : (initialVal?.val as string) || profileTemperatureFormat || CELSIUS
  )
  const [minTemperature, setMinTemperature] = React.useState<string>((minTemp?.val as string) || '')
  const [maxTemperature, setMaxTemperature] = React.useState<string>((maxTemp?.val as string) || '')

  const validMinTemperature = temperatureFormat === CELSIUS ? -15 : 5
  const validMaxTemperature = temperatureFormat === CELSIUS ? 30 : 86
  const {
    field: fieldMin,
    fieldState: { error: minError },
  } = useController({
    name: isEditing ? MIN_TEMPERATURE : minTemp?.name || '',
    rules: {
      required: isHaveTemperatureInSkills ? REQUIRED_FIELD : false,
      validate: {
        greaterThanMaxValue: (v: string) => {
          return +v <= +maxTemperature || `Min value cannot be higher than max value`
        },
      },
      min: {
        value: validMinTemperature,
        message: `The value cannot be lower than ${temperatureFormat === CELSIUS ? '-15С' : '5F'}`,
      },
      max: {
        value: validMaxTemperature,
        message: `The value cannot be higher than ${temperatureFormat === CELSIUS ? '30С' : '86F'}`,
      },
    },
    control,
    defaultValue: minTemp?.val || '',
  })
  const {
    field: fieldMax,
    fieldState: { error: maxError },
  } = useController({
    name: isEditing ? MAX_TEMPERATURE : maxTemp?.name || '',
    rules: {
      required: isHaveTemperatureInSkills ? REQUIRED_FIELD : false,
      validate: {
        lowerThanMinValue: (v: string) => {
          return +v >= +minTemperature || `Max value cannot be lower than min value`
        },
      },
      min: {
        value: validMinTemperature,
        message: `The value cannot be lower than ${temperatureFormat === CELSIUS ? '-15С' : '5F'}`,
      },
      max: {
        value: validMaxTemperature,
        message: `The value cannot be higher than ${temperatureFormat === CELSIUS ? '30С' : '86F'}`,
      },
    },
    control,
    defaultValue: maxTemp?.val || '',
  })

  React.useEffect(() => {
    // Clearing or setting the error in the inputs
    if (
      maxTemperature &&
      minTemperature &&
      (minError || maxError) &&
      clearErrors &&
      !Array.isArray(clearErrors)
    ) {
      // MIN ERRORS
      if (
        minError &&
        +minTemperature <= +maxTemperature &&
        +minTemperature >= validMinTemperature
      ) {
        clearErrors(minTemp?.name)
        setCellValues(minTemperature, '', MIN_TEMPERATURE)
      } else if (minError && minError.message) {
        setCellValues(minTemperature, minError.message, MIN_TEMPERATURE)
      }
      // MAX ERRORS
      if (
        maxError &&
        +maxTemperature >= +minTemperature &&
        +maxTemperature <= validMaxTemperature
      ) {
        clearErrors(maxTemp?.name)
        setCellValues(maxTemperature, '', MAX_TEMPERATURE)
      } else if (maxError && maxError.message) {
        setCellValues(maxTemperature, maxError.message, MAX_TEMPERATURE)
      }
      // If we are selecting more than one API and min\max fields are empty
    } else if (!maxTemperature || !minTemperature) {
      // MIN ERRORS
      if (!minTemperature && minError && minError.message) {
        setCellValues(minTemperature, minError.message, MIN_TEMPERATURE)
      }
      // MAX ERRORS
      if (!maxTemperature && maxError && maxError.message) {
        setCellValues(maxTemperature, maxError.message, MAX_TEMPERATURE)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minTemperature, maxTemperature, minError, maxError])

  const setCellValues = React.useCallback(
    (val: string, error: string, inputCellName: string) => {
      !isEditing &&
        dispatch(
          setCellValueToSample({
            cellValue: { val, error },
            cellName: inputCellName,
            sampleId,
          })
        )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const setValues = React.useCallback((min: string, max: string) => {
    setCellValues(!min ? min : `${Math.round(+min)}`, '', MIN_TEMPERATURE)
    setCellValues(!max ? max : `${Math.round(+max)}`, '', MAX_TEMPERATURE)
    setMinTemperature(!min ? min : `${Math.round(+min)}`)
    setMaxTemperature(!max ? max : `${Math.round(+max)}`)
    setTimeout(() => {
      fieldMin.onChange({ target: { value: !min ? min : `${Math.round(+min)}` } })
      fieldMax.onChange({ target: { value: !max ? max : `${Math.round(+max)}` } })
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!initialVal?.val) {
      checkInitialValuesRef.current = true
    }
    if (!checkInitialValuesRef.current) {
      if (isEditing) {
        if (
          (isHaveTemperatureInSkills && api?.length && api?.length === 1) ||
          fieldMin.value ||
          fieldMax.value
        ) {
          // if (fieldTemperatureFormat.value === profileTemperatureFormat) {
          // setMinTemperature(!fieldMin.value ? fieldMin.value : `${Math.round(+fieldMin.value)}`)
          // setMaxTemperature(!fieldMax.value ? fieldMax.value : `${Math.round(+fieldMax.value)}`)
          // } else {
          // const tempType = profileTemperatureFormat
          // const min = obtainingCorrectTemperature(tempType, fieldMin.value)
          // const max = obtainingCorrectTemperature(tempType, fieldMax.value)
          // setValues(min, max)
          // fieldTemperatureFormat.onChange({ target: { value: tempType } })
          // }

          if (fieldMin.value || fieldMax.value) {
            setValues(fieldMin.value, fieldMax.value)
          } else {
            setValues(api?.[0].min_temperature, api?.[0].max_temperature)
          }
        }
      }
    }
    if (checkInitialValuesRef.current) {
      // If APIs selected and we have temperature-sensitive skill
      if (api?.length && isHaveTemperatureInSkills) {
        if (api?.length === 1) {
          // If we have only one API
          // If the profile temperature format is equal to the API temperature format
          // if (api[0].temperature_measurement_format.key === profileTemperatureFormat) {
          //   setValues(api[0].min_temperature, api[0].max_temperature)
          //   fieldTemperatureFormat.onChange({ target: { value: profileTemperatureFormat } })
          // } else {
          //   const min = obtainingCorrectTemperature(
          //     profileTemperatureFormat,
          //     api[0].min_temperature
          //   )
          //   const max = obtainingCorrectTemperature(
          //     profileTemperatureFormat,
          //     api[0].max_temperature
          //   )
          //   setValues(min, max)
          //   fieldTemperatureFormat.onChange({ target: { value: profileTemperatureFormat } })
          // }
          if (api[0].temperature_measurement_format.key === temperatureFormat) {
            setValues(api[0].min_temperature, api[0].max_temperature)
          } else {
            const min = obtainingCorrectTemperature(temperatureFormat, api[0].min_temperature)
            const max = obtainingCorrectTemperature(temperatureFormat, api[0].max_temperature)
            setValues(min, max)
          }

          if (clearErrors && !Array.isArray(clearErrors)) {
            clearErrors(minTemp?.name)
            clearErrors(maxTemp?.name)
          }
        } else {
          // If we have multiple API
          if (!minError && !maxError) {
            setValues('', '')
          } else {
            setCellValues('', (minError && minError.message) || '', MIN_TEMPERATURE)
            setCellValues('', (maxError && maxError.message) || '', MAX_TEMPERATURE)
          }
        }

        setCellValues(temperatureFormat, '', cellName)
      } else {
        if (clearErrors && !Array.isArray(clearErrors)) {
          clearErrors(minTemp?.name)
          clearErrors(maxTemp?.name)
        }
        setValues('', '')
      }
    } else {
      checkInitialValuesRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api])

  const onInputChange = (val: string, inputCellName: string) => {
    const roundedVal = `${Math.round(+val)}`
    setCellValues(roundedVal, '', inputCellName)
    if (inputCellName === MIN_TEMPERATURE) {
      setMinTemperature(roundedVal)
      fieldMin.onChange({ target: { value: roundedVal } })
    }
    if (inputCellName === MAX_TEMPERATURE) {
      setMaxTemperature(roundedVal)
      fieldMax.onChange({ target: { value: roundedVal } })
    }
  }

  const onChangeTemperatureFormat = (val: string) => {
    setCellValues(val, '', cellName)
    const min = obtainingCorrectTemperature(val, minTemperature)
    const max = obtainingCorrectTemperature(val, maxTemperature)
    setValues(min, max)
    setTemperatureFormat(val)
    fieldTemperatureFormat.onChange({ target: { value: val } })
  }

  return (() => {
    if (isOnlyTemperature || (api?.length && isHaveTemperatureInSkills)) {
      // If APIs selected or we have temperature-sensitive skill
      return (
        <>
          {!isOnlyTemperature && isEditing ? (
            <StyledInputLabel>Storage temperature</StyledInputLabel>
          ) : null}
          <TableCellWrapper
            id={name}
            isEditing={isEditing}
            stylesCreateForm={{
              minWidth: width,
            }}
            stylesEditForm={{ marginBottom: !isOnlyTemperature && isEditing ? 40 : 0 }}
          >
            <>
              <StyledTemperatureInputBox>
                <StyledSpanMaxMin>min</StyledSpanMaxMin>
                <PureInput
                  isCopy={isCopy}
                  name={name}
                  type='number'
                  style={{ marginRight: 16, background: '#fff' }}
                  endIcon={<TemperatureIcon />}
                  onInputChange={val => onInputChange(val, MIN_TEMPERATURE)}
                  value={minTemperature}
                  placeholder=''
                  errorMessage={minError?.message || ''}
                />
                <StyledSpanMaxMin>max</StyledSpanMaxMin>
                <PureInput
                  isCopy={isCopy}
                  name={name}
                  type='number'
                  style={{ marginRight: 20, background: '#fff' }}
                  endIcon={<TemperatureIcon />}
                  onInputChange={val => onInputChange(val, MAX_TEMPERATURE)}
                  value={maxTemperature}
                  placeholder=''
                  errorMessage={maxError?.message || ''}
                />
              </StyledTemperatureInputBox>
              <PureRadioButtons
                initialValue={temperatureFormat}
                name={name}
                data={radioButtonsData}
                onChange={onChangeTemperatureFormat}
              />
            </>
          </TableCellWrapper>
        </>
      )
    } else if (api?.length && !isHaveTemperatureInSkills) {
      // If we don't have temperature-sensitive skill
      return isTemperatureAdded ? (
        <TableCellWrapper
          isEditing={!!props.isEditing}
          stylesCreateForm={{ minWidth: props.width }}
        >
          <StyledAdditionalText>For temperature-sensitive only</StyledAdditionalText>
        </TableCellWrapper>
      ) : null
    } else {
      // If APIs are not selected
      return isTemperatureAdded ? (
        <TableCellWrapper
          isEditing={!!props.isEditing}
          stylesCreateForm={{ minWidth: props.width }}
        >
          -
        </TableCellWrapper>
      ) : null
    }
  })()
}
