import React from 'react'
import { Box, Typography } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { Chip, MobileDetailsCard, TaskNotesField } from '@components/shared'
import { TASKS } from '@constants/queries'

import { ApiContentMobile } from './components/ApiContentMobile'
import { Attachments } from '../TabTestingInfo/components/TaskRow/components'
import { StyledDetailsBox } from './styles'
import { TestingDate } from '../TabTestingInfo/components/TaskRow/components/TestingDate'

interface TaskRowMobileProps {
  task: ITaskLab
  contentType: 'basic' | 'testing'
  setIsResultsModalOpen?: () => void
}

export const TaskRowMobile: React.FC<TaskRowMobileProps> = ({
  task,
  contentType,
  setIsResultsModalOpen,
}) => {
  const rows =
    contentType === 'basic'
      ? [
          { id: 1, editButtonAction: null, title: 'Task ID', data: task?.id },
          { id: 2, editButtonAction: null, title: 'Test type', data: task?.test_type.name },
          {
            id: 3,
            editButtonAction: null,
            title: 'Serial number of equipment',
            data: task?.serial_number_of_equipment,
          },
          {
            id: 4,
            title: 'Task status',
            editButtonAction: null,

            data: <Chip label={task?.status.value} status={task?.status.key} />,
            isDataTypeComponent: true,
          },
        ]
      : [
          {
            id: 1,
            title: 'Testing date',
            editButtonAction: null,

            data: <TestingDate task={task} />,
            isDataTypeComponent: true,
          },
          {
            id: 3,
            title: 'Result',
            editButtonAction: setIsResultsModalOpen,
            data: <Chip label={task?.result_name} status={task?.result.key} />,
            isDataTypeComponent: true,
          },
          {
            id: 4,
            title: 'APIs',
            editButtonAction: null,
            data: <ApiContentMobile task={task} />,
            isDataTypeComponent: true,
          },
        ]

  return (
    <>
      {rows.map(({ id, title, isDataTypeComponent, data, editButtonAction }) => (
        <MobileDetailsCard
          edit={task.is_able_to_edit_task}
          editButtonAction={editButtonAction}
          key={id}
          title={title}
          isDataTypeComponent={isDataTypeComponent}
          data={data}
          minWidth='139px'
        />
      ))}
      {contentType === 'testing' && (
        <>
          {task && (
            <MobileDetailsCard
              key={5}
              title={'Task notes'}
              isDataTypeComponent
              data={
                <TaskNotesField
                  isAbleToAddTaskNotes={task.is_able_to_add_task_notes}
                  notes={task.task_notes}
                  sampleId={task.sample}
                  taskId={task.id}
                  contentType={TASKS}
                  taskInfoInTest
                />
              }
              minWidth='139px'
            />
          )}

          <Box>
            <Typography variant='h5' sx={{ backgroundColor: '#fff', paddingLeft: '20px' }}>
              Attachments
            </Typography>

            <StyledDetailsBox>
              <Attachments taskId={task?.id} isAbleToEditTask={task.is_able_to_edit_task} />
            </StyledDetailsBox>
          </Box>
        </>
      )}
    </>
  )
}
