import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { Autocomplete } from '@mui/material'

import { Input, AutocompleteWrapper } from '@components/shared'
import { StyledLabel } from '@styles'
import { useGetDeliveryServices } from '@hooks/queries/useGetDeliveryServices'

export const DeliveryServiceField: React.FC = () => {
  const { setValue, trigger } = useFormContext()

  const getDeliveryServicesQ = useGetDeliveryServices()

  const deliveryServicesItems: any = getDeliveryServicesQ?.data || []

  const handleChange = useCallback(
    (event, v) => {
      if (!v) {
        setValue('delivery_service', '')
        trigger(['delivery_service'])
        return
      }
      setValue('delivery_service', v.id)
      trigger(['delivery_service'])
    },
    [setValue, trigger]
  )

  return (
    <>
      <StyledLabel
        mb='25px'
        label='Delivery Service'
        control={
          <AutocompleteWrapper>
            <Autocomplete
              fullWidth
              size='small'
              loading={getDeliveryServicesQ.isFetching}
              onChange={handleChange}
              options={deliveryServicesItems}
              noOptionsText='Nothing found'
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={params => (
                <Input {...params} variant='outlined' placeholder='Select here...' height={48} />
              )}
            />
          </AutocompleteWrapper>
        }
      />
    </>
  )
}
