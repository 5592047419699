import React from 'react'
import { Box } from '@mui/material'

import { ISample } from '@models/samples'
import {
  ExpandableApis,
  ExpandableExcipients,
  TemperatureSensitiveToxicityFields,
  TempSensitiveToxicityIcons,
} from '@components/shared'
import { getApisWithType } from '@helpers/ui/common'
import { StyledDetailsLabel, StyledParagraph, StyledTitle, SubText } from '@styles'
import { NotesField, SpecificId } from '@components/shared/sampleFields'
import { SAMPLES } from '@constants/queries'
import { protectedRoutes } from '@configs/routes.config'
import { DETAILS } from '@constants/common'
import { IFunding } from '@models/projects'

import { MainInfoLoader } from '../../Loaders'

interface ViewMainInfoProps {
  sample: ISample
  isLoading: boolean
}

export const ViewMainInfo: React.FC<ViewMainInfoProps> = ({ sample, isLoading }) => {
  const { mainApisWithType } = getApisWithType({
    main_apis: sample?.main_apis,
    optional_apis: sample?.optional_apis,
  })
  if (isLoading) return <MainInfoLoader />

  return (
    <div>
      <StyledTitle variant='h3' mb='28px' fz={24}>
        Main info
      </StyledTitle>

      <StyledDetailsLabel variant='h5'>Drug class</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{sample.drug_class.name || '-'}</StyledParagraph>

      <StyledDetailsLabel variant='h5'>Dosage</StyledDetailsLabel>
      <StyledParagraph variant='body1'>
        {sample.dosage ? parseFloat(sample.dosage) : '-'}{' '}
        <SubText>{sample?.dosage_type ? sample.dosage_type.name : '-'}</SubText>
      </StyledParagraph>

      <StyledDetailsLabel variant='h5'>Main APIs</StyledDetailsLabel>
      <ExpandableApis bgColor='transparent' borderBottom='none' apis={mainApisWithType} />

      <StyledDetailsLabel variant='h5' sx={{ marginTop: '14px' }}>
        Excipients
      </StyledDetailsLabel>
      {sample.excipients.length ? (
        <ExpandableExcipients
          bgColor='transparent'
          borderBottom='none'
          excipients={sample.excipients}
        />
      ) : (
        '-'
      )}

      <TemperatureSensitiveToxicityFields
        toxicityLevelValue={sample.toxicity_level?.value}
        storageNotes={sample.storage_notes}
        minTemperature={sample.min_temperature}
        maxTemperature={sample.max_temperature}
        temperatureMeasurementFormatKey={sample.temperature_measurement_format?.key}
        mb='24px'
      />

      <StyledDetailsLabel variant='h5' sx={{ marginTop: '24px' }}>
        Regulatory regist. status
      </StyledDetailsLabel>
      <StyledParagraph variant='body1'>
        {sample?.regulatory_registration_status.name || '-'}
      </StyledParagraph>

      <StyledDetailsLabel variant='h5'>Project</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{sample.project?.name || '-'}</StyledParagraph>

      <StyledDetailsLabel variant='h5'>Funding</StyledDetailsLabel>
      <StyledParagraph variant='body1'>
        {sample.funding?.map((fund: IFunding) => fund.value).join(', ') || '-'}
      </StyledParagraph>

      <StyledTitle variant='h3' sx={{ marginTop: '40px' }} mb='32px' fz={24}>
        Identification
      </StyledTitle>

      <Box mb='24px'>
        <StyledDetailsLabel variant='h5'>Sample ID</StyledDetailsLabel>
        <Box display='flex' alignContent='center'>
          <SpecificId
            labelTooltip={sample.initial_laboratory.label_tooltip}
            specificId={sample.specific_id || '-'}
            laboratory={sample.initial_laboratory}
            pathForLink={`${protectedRoutes.samples.path}/${sample.id}${DETAILS}`}
            titleForPathLink={`Sample ${sample.specific_id}`}
          />

          <TempSensitiveToxicityIcons
            toxicity={sample.toxicity_level}
            minTempSensitive={sample.min_temperature}
            maxTempSensitive={sample.max_temperature}
            temperatureMeasurementFormat={sample.temperature_measurement_format?.key}
            mt='-5px'
          />
        </Box>
      </Box>

      <NotesField
        notes={sample.notes}
        id={sample.id}
        content={SAMPLES}
        labelText='Sample'
        withoutTruncate
        editDetailsNotes
      />
    </div>
  )
}
