import { http } from '@services/http'
import { IConfirmRecoverPass, IEmailField, IRequestAccess } from '@models/common/forms'
import {
  ICredentials,
  IUpdatedUser,
  IUser,
  CheckResult,
  IConfirmationDataToRecoverPass,
  IConfirmationEmailData,
  IMaintenanceMode,
  UserRole,
  UserSex,
} from '@models/common/user'
import { IEditInfoLaboratoryData } from '@models/yourWorkspace'
import { axiosInstance } from '@configs/axios.config'
import { UrlManager } from '@helpers/url'

export const loginUser = (credentials: ICredentials) => {
  try {
    return http.post('/users/login/', credentials)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const logoutUser = () => {
  const { publicApiUrl } = new UrlManager()

  axiosInstance.interceptors.request.use(
    async config => {
      config.baseURL = publicApiUrl
      return config
    },
    error => Promise.reject(error)
  )
  try {
    return http.post('/users/logout/', {}, { skipForbiddenError: true })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchUser = () => {
  try {
    return http.get<IUser>(`/users/profile/`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getTenantsForUser = () => {
  try {
    return http.get<any>(`/users/tenants/`, { skipForbiddenError: true })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateUser = (data: IUpdatedUser) => {
  try {
    return http.patch<IUser, IUpdatedUser>(`/users/edit_profile/`, data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const recoverPassword = (data: IEmailField) => {
  try {
    return http.post('/users/reset_password/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const requestAccess = (data: IRequestAccess) => {
  try {
    return http.post('/request_access/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const checkTokenToRecoverPass = ({
  user_id,
  token,
  tenant_name,
}: IConfirmationDataToRecoverPass) => {
  try {
    return http.get<CheckResult>(
      `/users/reset_password/confirm/?user_id=${user_id}&token=${token}&tenant_name=${tenant_name}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const confirmPassword = (data: IConfirmRecoverPass) => {
  try {
    return http.post('/users/reset_password/confirm/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const confirmEmail = ({ user_id, token, email, tenant_name }: IConfirmationEmailData) => {
  try {
    return http.get(
      `/users/confirm_email/?user_id=${user_id}&token=${token}&email=${email}&tenant_name=${tenant_name}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const userSendFeedbackForm = (data: FormData) => {
  try {
    return http.post('/users/send-feedback/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const subscribeToMailing = (data: IEmailField) => {
  try {
    return http.post('/subscriptions/manage/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const unsubscribeFromMailing = (data: string) => {
  try {
    return http.delete(`/subscriptions/manage/?email=${data}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchMaintenanceMode = () => {
  try {
    return http.get<IMaintenanceMode>(`/maintenance-mode/`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateLaboratoryInfo = async ({ id, data }: IEditInfoLaboratoryData) => {
  try {
    return http.patch(`/laboratories/${id}/`, data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export type IEditUserRequestData = Pick<
  IUser,
  | 'first_name'
  | 'last_name'
  | 'role_switcher'
  | 'can_manage_users'
  | 'phone_number'
  | 'email'
  | 'timezone'
  | 'timezone_autodetect'
  | 'is_active'
> & {
  skills: Array<number>
  role: UserRole
  sex: UserSex
}
export interface HttpServiceRequestParam<Data = {}, Params = {}> {
  data: Data
  params?: Params
}

export const editUser = async (
  request: HttpServiceRequestParam<Partial<IEditUserRequestData>, { id: string }>
): Promise<IEditUserRequestData> => {
  try {
    return http.patch(`/users/${request?.params?.id}/edit/`, request.data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export type IUserCreateRequestData = Pick<
  IUser,
  | 'first_name'
  | 'last_name'
  | 'role_switcher'
  | 'can_manage_users'
  | 'phone_number'
  | 'email'
  | 'timezone'
  | 'timezone_autodetect'
> & {
  skills: Array<number>
  role: UserRole
  sex: UserSex
}
export const createUser = async (
  request: HttpServiceRequestParam<IUserCreateRequestData>
): Promise<IUserCreateRequestData> => {
  try {
    return http.post(`/users/`, request.data)
  } catch (error) {
    return Promise.reject(error)
  }
}
