import {
  styled,
  FormControlLabel,
  Typography,
  FormControlLabelProps,
  TypographyProps,
  RadioProps,
  Radio,
  FormControl,
} from '@mui/material'
import { Box, BoxProps } from '@mui/system'
import React from 'react'

interface StyledWrapperProps extends BoxProps {
  $isMobile?: boolean
}

export const StyledWrapper = styled<React.FC<StyledWrapperProps>>(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ $isMobile }) => ($isMobile ? '' : '469px')};
`

interface StyledTitleProps extends TypographyProps {
  $isMobile?: boolean
}

export const StyledTitle = styled<React.FC<StyledTitleProps>>(Typography)`
  text-align: center;
  margin-bottom: ${({ theme, $isMobile }) => ($isMobile ? '15px' : theme.typography.pxToRem(32))};
  color: ${({ theme }) => theme.palette.text.primary};
`

//[Radio buttons group
export const StyledFormControl = styled(FormControl)`
  display: block;
  width: 120px;
  margin-left: auto;
  margin-right: auto;
`

interface StyledRadioProps extends RadioProps {
  padding?: string
  mr?: string
}

export const StyledRadio = styled<React.FC<StyledRadioProps>>(Radio)`
  color: #adb4c2;
  padding: ${({ padding }) => (padding ? padding : '4px')};
  margin-right: ${({ mr }) => mr};

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & .MuiSvgIcon-root {
    font-size: 20px;
  }
`
interface StyledLabelProps extends FormControlLabelProps {
  $isMobile?: boolean
  mb?: string
  checked: boolean
}

export const StyledFormControlLabel = styled<React.FC<StyledLabelProps>>(FormControlLabel)`
  color: ${({ theme, checked }) =>
    checked ? theme.palette.text.primary : theme.palette.secondary.light};
  &:not(:last-child) {
    margin-bottom: 11px;
  }
`
//Radio buttons group]
