import React from 'react'
import { Box, Typography } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { MobileDetailsCard, TempSensitiveToxicityIcons } from '@components/shared'

interface IdentificationMobileProps {
  data?: ITaskLab
}

export const IdentificationMobile: React.FC<IdentificationMobileProps> = ({ data }) => {
  const taskInfo = [
    {
      id: 1,
      title: 'Sample ID',
      data: (
        <>
          {data && (
            <Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center'>
              <Typography mr='8px' mb='8px'>
                {data.sample}
              </Typography>
              <TempSensitiveToxicityIcons
                toxicity={data?.toxicity_level}
                minTempSensitive={data?.min_temperature}
                maxTempSensitive={data?.max_temperature}
                temperatureMeasurementFormat={data?.temperature_measurement_format?.key}
                ml='0'
              />
            </Box>
          )}
        </>
      ),
    },
  ]

  return (
    <Box pb='12px'>
      {taskInfo.map(({ id, title, data }) => (
        <MobileDetailsCard key={id} title={title} data={data} minWidth='159px' />
      ))}
    </Box>
  )
}
