import React from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { Box } from '@mui/material'

import { useAppSelector } from '@hooks/redux'
import { FilterAside } from '@components/shared'
import {
  ApisFilter,
  DatePickerRangeFilter,
  DrugClassFilter,
  ExcipientsFilter,
  LaboratoriesFilter,
  ManufactureFilter,
  TaskResultFilter,
  TestTypeFilter,
} from '@components/shared/filters'
import { filtersAside } from '@state/filters'
import { TESTING_DATE } from '@components/shared/FilterAside/constants'
import { DESIRED_TEST_REPORTS } from '@constants/tests'
import { REPORTS } from '@constants/queries'

export const ReportsFiltersMenu = () => {
  const isFilterOpen = useAppSelector(filtersAside)

  const [queryString] = useQueryParam('result', StringParam)
  const [, setSearchQueryString] = useQueryParam(DESIRED_TEST_REPORTS, StringParam)

  React.useEffect(() => {
    setSearchQueryString(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  return (
    <>
      {isFilterOpen.right && (
        <FilterAside>
          <Box>
            {/* list of filters here */}

            <LaboratoriesFilter contentType={REPORTS} displayCurrentLaboratory />
            <TestTypeFilter />
            <TaskResultFilter />
            <DrugClassFilter />
            <ApisFilter />
            <ExcipientsFilter />
            <ManufactureFilter />
            <DatePickerRangeFilter type={TESTING_DATE} />
          </Box>
        </FilterAside>
      )}
    </>
  )
}
