import { Typography, styled as styledMUI } from '@mui/material'
import styled from 'styled-components'

export const InfoBox = styled.div`
  min-width: 300px;
  width: 408px;
  margin-right: 28px;
`

export const StyledBox = styled.div`
  margin-bottom: 30px;
`

export const StyledFieldTitle = styledMUI(Typography)`
  margin-bottom: 15px;

  font-weight: 'normal';
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  color: ${({ theme }) => theme.palette.text.primary};

  &:first-child {
    font-weight: 700;
  }
`

export const StyledFieldText = styledMUI(Typography)`
  font-weight: 'normal';
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  color: ${({ theme }) => theme.palette.text.primary};

  &:first-child {
    font-weight: 700;
  }
`
