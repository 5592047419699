import ReactDOM from 'react-dom'

import { AppProviders, AppHOC } from './app'

const app = (
  <AppProviders>
    <AppHOC />
  </AppProviders>
)

ReactDOM.render(app, document.getElementById('root'))
