import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useController } from 'react-hook-form'

import { PureDropdownAutocomplete } from '@components/ui'
import { setCellValueToSample } from '@state/sampleCreate'
import { IOption } from '@models/common/app'
import { useFetchProjectsFilters } from '@hooks/queries/filters/useFetchProjectsFilters'

import { PureCellPropTypes } from './pureFields.types'

export const Project: React.FC<PureCellPropTypes> = props => {
  const { control, name, cellName, sampleId, rules, initialVal, isCopy } = props
  const [searchQuery, setSearchQuery] = React.useState('')
  const dispatch = useDispatch()

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useFetchProjectsFilters(searchQuery)
  const options = data && data.pages.map(page => page.results).flat()

  const setCellValue = useCallback(
    (val: IOption | null, error: string) => {
      dispatch(
        setCellValueToSample({
          cellValue: { val, error },
          cellName,
          sampleId,
        })
      )
    },
    [cellName, dispatch, sampleId]
  )

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: initialVal.val })

  React.useEffect(() => {
    if (error && error.message) setCellValue(field.value, error.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onDropdownChange = (val: IOption) => {
    field.onChange({ target: { value: val } })

    setCellValue(null, '')
    setTimeout(() => setCellValue(val, ''), 500)
    // setCellValue(val, '')
  }

  const onInputChange = (val: string) => {
    setSearchQuery(val)
    setCellValue(null, '')
  }

  return (
    <PureDropdownAutocomplete
      isCopy={isCopy}
      isLoading={isFetching || isLoading}
      initialVal={initialVal.val as IOption | null}
      name={name}
      options={options || []}
      errorMessage={error?.message || ''}
      onInputChange={onInputChange}
      onDropdownChange={onDropdownChange}
      withTooltip
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
    />
  )
}
