import React from 'react'
import { TabList } from '@mui/lab'
import { Badge, CircularProgress } from '@mui/material'
import { StringParam, useQueryParam } from 'use-query-params'

import { packageTabs } from '@constants/mappings'
import { useFetchCounters } from '@hooks/queries'
import { IPackagesTabValue } from '@models/packages'
import { StyledTab, StyledTabBox } from '@styles'
import { DESIRED_PACKAGE } from '@constants/packages'

interface TabButtonsProps {
  onChangeTab: (tab: IPackagesTabValue) => void
  onChangeQuery: (newValue: string | null | undefined) => void
}

export const TabButtons: React.FC<TabButtonsProps> = ({ onChangeTab, onChangeQuery }) => {
  const { data, isLoading: counterLoading } = useFetchCounters()
  const [, setSearchQueryString] = useQueryParam(DESIRED_PACKAGE, StringParam)

  const changeTabHandler = (e: React.SyntheticEvent, tab: IPackagesTabValue) => {
    onChangeTab(tab)
    setSearchQueryString(undefined)

    onChangeQuery(
      tab === 'incoming packages'
        ? 'incoming'
        : tab === 'outgoing packages'
        ? 'outgoing'
        : undefined
    )
  }

  const renderIncomingPacksCounter = (tab: IPackagesTabValue, content: number) => {
    if (tab === 'incoming packages') {
      return counterLoading ? (
        <CircularProgress size={16} sx={{ marginLeft: '16px' }} />
      ) : content > 0 ? (
        <Badge badgeContent={content} max={9999} />
      ) : (
        ''
      )
    }
  }

  return (
    <StyledTabBox>
      <TabList onChange={changeTabHandler}>
        {packageTabs.map(tab => (
          <StyledTab
            key={tab.value}
            {...tab}
            icon={renderIncomingPacksCounter(tab.value, data?.incoming_packages || 0)}
            iconPosition='end'
          />
        ))}
      </TabList>
    </StyledTabBox>
  )
}
