import React from 'react'
import { Box, Typography } from '@mui/material'

import { ISample } from '@models/samples'
import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_ADD_SAMPLES_MODAL_MANUFACTURE_TEXT } from '@constants/common'

import { StyledTableCell } from '../../styles'
import { SubText } from './styles'

interface ManufacturerProps {
  sample: ISample
}

export const Manufacturer: React.FC<ManufacturerProps> = ({ sample }) => {
  const { manufacturer, brand } = sample

  return (
    <StyledTableCell>
      <Box sx={{ marginTop: '-16px' }}>
        <Tooltip
          isPureTooltip
          title={manufacturer?.name || ''}
          shouldBeHidden={
            ((manufacturer && manufacturer.name.length) || 0) <=
            TRUNCATED_ADD_SAMPLES_MODAL_MANUFACTURE_TEXT
          }
        >
          <Typography>
            {manufacturer
              ? stringSlice(manufacturer.name, TRUNCATED_ADD_SAMPLES_MODAL_MANUFACTURE_TEXT)
              : '-'}
          </Typography>
        </Tooltip>

        <Tooltip
          title={brand?.name || ''}
          shouldBeHidden={
            ((brand && brand.name.length) || 0) <= TRUNCATED_ADD_SAMPLES_MODAL_MANUFACTURE_TEXT
          }
        >
          <SubText>
            {brand ? stringSlice(brand.name, TRUNCATED_ADD_SAMPLES_MODAL_MANUFACTURE_TEXT) : '-'}
          </SubText>
        </Tooltip>
      </Box>
    </StyledTableCell>
  )
}
