import React from 'react'
import { Box } from '@mui/system'

import { sampleCreate } from '@state/sampleCreate'
import { useAppSelector } from '@hooks/redux'
import { TooltipWithButton } from '@components/shared/Tooltip/components'
import { addSampleButtonPushedNumb } from '@state/app'

import { HelperTooltipWrapperTypes } from '../sampleCreate.types'

export const HelperTooltipWrapper: React.FC<HelperTooltipWrapperTypes> = props => {
  const { idx, isDisableCopyTooltip } = props
  const { samples } = useAppSelector(sampleCreate)
  const addSampleButtonPushed = useAppSelector(addSampleButtonPushedNumb)
  const [hasSampleSimilarValues, setHasSampleSimilarValues] = React.useState(false)

  const isShowHelpCase1 = samples.length === 2 && idx === 0 && hasSampleSimilarValues
  const isShowHelpCase2 = samples.length === 3 && idx === 0 && hasSampleSimilarValues

  const showTooltip = isShowHelpCase1 || isShowHelpCase2

  React.useEffect(() => {
    let isFieldsIsEmpty
    let compareMainApis

    if (samples.length === 2 && addSampleButtonPushed < 2) {
      isFieldsIsEmpty =
        !samples[0].dosage.val.length ||
        !samples[1].dosage.val.length ||
        !samples[0].main_apis.val.length ||
        !samples[1].main_apis.val.length

      if (isFieldsIsEmpty) {
        setHasSampleSimilarValues(false)
        return
      }

      const startMainApis = samples[1].main_apis.val.map(api => api.name)
      const addedMainApis = samples[0].main_apis.val.map(api => api.name)

      if (startMainApis.length === addedMainApis.length) {
        compareMainApis = startMainApis.every(item => addedMainApis.indexOf(item) !== -1)
      }

      const compareDrugClasses = samples[0].drug_class.val?.name === samples[1].drug_class.val?.name
      const compareDosage = samples[0].dosage.val === samples[1].dosage.val

      if (compareMainApis && compareDrugClasses && compareDosage) {
        setHasSampleSimilarValues(true)
      } else setHasSampleSimilarValues(false)
    }

    if (addSampleButtonPushed === 2) setHasSampleSimilarValues(true)
    if (addSampleButtonPushed > 2) setHasSampleSimilarValues(false)
  }, [addSampleButtonPushed, samples])

  return (
    <>
      <TooltipWithButton
        title={''}
        tooltipWithButtonTextBtn='Got it'
        isTooltipWithButtonHelpText='This sample has similar values in fields. Make a copy to not re-enter the same options'
        shouldBeHidden={showTooltip && !isDisableCopyTooltip}
      >
        <Box />
      </TooltipWithButton>
    </>
  )
}
