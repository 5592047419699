import {
  NumberParam,
  StringParam,
  DecodedValueMap,
  encodeQueryParams,
  QueryParamConfigMap,
  ArrayParam,
} from 'use-query-params'
import { stringify } from 'query-string'

import { parseDate } from '@helpers/dates'

export const getSampleQueryString = (params: Partial<DecodedValueMap<QueryParamConfigMap>>) => {
  const {
    page,
    limit,
    samples,
    ordering,
    free_samples,
    apis,
    excipients,
    task_status,
    drug_classes,
    manufacturers,
    started_date,
    finished_date,
    started_expiration_date,
    ended_expiration_date,
    laboratories,
    sample_status,
    physical_form,
    started_collection_date,
    initial_laboratory,
  } = params

  const parsedCollectionDate = parseDate(started_collection_date)

  const searchParams = {
    page,
    limit,
    samples,
    ordering,
    free_samples,
    apis,
    excipients,
    task_status,
    drug_classes,
    manufacturers,
    started_date,
    finished_date,
    started_expiration_date,
    ended_expiration_date,
    laboratories,
    sample_status,
    physical_form,
    started_collection_date:
      started_collection_date && (parsedCollectionDate[0] || parsedCollectionDate[1]),
    ended_collection_date:
      started_collection_date && (parsedCollectionDate[1] || parsedCollectionDate[0]),
    initial_laboratory,
  }

  const queryParams = {
    page: StringParam,
    limit: NumberParam,
    samples: StringParam,
    ordering: StringParam,
    free_samples: StringParam,
    apis: StringParam,
    excipients: StringParam,
    task_status: StringParam,
    drug_classes: StringParam,
    manufacturers: StringParam,
    started_date: StringParam,
    finished_date: StringParam,
    started_expiration_date: StringParam,
    ended_expiration_date: StringParam,
    laboratories: StringParam,
    sample_status: StringParam,
    physical_form: StringParam,
    started_collection_date: StringParam,
    initial_laboratory: StringParam,
  }

  const encodedQuery = encodeQueryParams(queryParams, searchParams)
  const queryString = stringify(encodedQuery)

  return queryString
}

export const getRequestsQueryString = (params: Partial<DecodedValueMap<QueryParamConfigMap>>) => {
  const {
    page,
    limit,
    request_type,
    samples,
    ordering,
    view_type,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    started_expiration_date,
    ended_expiration_date,
    status,
    laboratories,
  } = params

  const searchParams = {
    page,
    limit,
    ordering,
    samples,
    request_type,
    view_type,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    started_expiration_date,
    ended_expiration_date,
    status,
    laboratories,
  }

  const queryParams = {
    page: StringParam,
    limit: NumberParam,
    ordering: StringParam,
    samples: StringParam,
    view_type: StringParam,
    request_type: StringParam,
    apis: StringParam,
    excipients: StringParam,
    drug_classes: StringParam,
    manufacturers: StringParam,
    started_expiration_date: StringParam,
    ended_expiration_date: StringParam,
    status: ArrayParam,
    laboratories: StringParam,
  }

  const encodedQuery = encodeQueryParams(queryParams, searchParams)
  const queryString = stringify(encodedQuery)

  return queryString
}
