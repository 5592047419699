import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Typography } from '@mui/material'

import { PureAttachments } from '@components/shared/Hr'
import { ATTACHMENT_TEST_INFO_SECTION, TEST_INFO_TITLE, TEST_PREPARATION } from '@constants/tests'
import { IAttachment, IAttachments } from '@models/attachments'
import { AvailableSectionSettings } from '@models/tasks'

import { StyledTitle, StyledResultsBox } from '../styles'
import { CommonNotes } from '../CommonNotes'
import { SubstancesRecognized } from '../SubstancesRecognized'

export const TestInfo = () => {
  const { getValues, setValue } = useFormContext()
  const { task } = getValues()

  const { ordering_key: order, title } = React.useMemo(() => {
    return task.available_section_settings.find(
      (item: AvailableSectionSettings) => item.section.name === ATTACHMENT_TEST_INFO_SECTION
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEditedAttachments = (data: IAttachments) => {
    setValue(
      `fields.${ATTACHMENT_TEST_INFO_SECTION}`,
      data.map(attachment => attachment.id)
    )
  }

  const initialAttachments = task.attachments.filter(
    (item: IAttachment) => item.attachment_section.key === ATTACHMENT_TEST_INFO_SECTION
  )

  return (
    <StyledResultsBox $mb={24} $order={order}>
      <StyledTitle>{`${order}. ${title}`}</StyledTitle>
      <CommonNotes
        name={TEST_PREPARATION}
        placeholder='Type info about test preparation here...'
        label='Preparation for test'
      />
      <SubstancesRecognized />

      <Typography mb='8px'>Attachments</Typography>
      <PureAttachments
        objectId={task.id}
        contentType='task'
        modalType='materials'
        section={ATTACHMENT_TEST_INFO_SECTION}
        initialAttachments={initialAttachments}
        sectionTitle={TEST_INFO_TITLE}
        setAttachmentsToParent={handleEditedAttachments}
        attachmentsIsLoading={(loading: boolean) => setValue('isLoading', loading)}
      />
    </StyledResultsBox>
  )
}
