import { useInfiniteQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { StringParam, useQueryParams } from 'use-query-params'

import { TASKS_AWAITING } from '@constants/queries'
import { ITasksData } from '@models/tasks'
import { IError } from '@models/common/app'
import { fetchTasksList } from '@api/tasksLab'
import { setMaintenanceModePage } from '@state/user'
import { filterStringParamsQueries } from '@components/shared/FilterAside/constants'

export function useFetchTasksListAwaiting<T>() {
  const dispatch = useDispatch()

  const [queryString] = useQueryParams({
    ordering: StringParam,
    desired_tasks: StringParam,
    ...filterStringParamsQueries,
  })

  const {
    ordering,
    desired_tasks: desiredTasks,
    apis,
    excipients,
    drug_classes,
    manufacturers,
  } = queryString

  const queryKey = [
    TASKS_AWAITING,
    desiredTasks,
    ordering,
    apis,
    excipients,
    drug_classes,
    manufacturers,
  ]

  return useInfiniteQuery<ITasksData<T>, IError>(queryKey, fetchTasksList, {
    getNextPageParam: lastPage => {
      const { next: nextPageUrl } = lastPage.links

      if (nextPageUrl) {
        const queryString = nextPageUrl.slice(nextPageUrl.indexOf('?'))
        const urlParams = new URLSearchParams(queryString)
        const nextPage = urlParams.get('page')

        return nextPage
      }
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
