import { ListItemIcon } from '@mui/material'
import { useHistory } from 'react-router-dom'
import React from 'react'

import { protectedRoutes } from '@configs/routes.config'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import {
  isDefaultManagerInCentralLab,
  logout,
  selectUser,
  selectUserLaboratory,
  setSwitcherForRole,
  update,
} from '@state/user'
import { INVENTORY_MANAGER, LABORATORY_ASSISTANT } from '@constants/user'
import { ReactComponent as UserIcon } from '@assets/icons/header-menu-user.svg'
import { ReactComponent as ExitIcon } from '@assets/icons/header-menu-logout.svg'
import { ReactComponent as SwitchRoleIcon } from '@assets/icons/switch-role.svg'
import { ReactComponent as LetterIcon } from '@assets/icons/letter-header.svg'
import { ReactComponent as WorkspaceIcon } from '@assets/icons/workspace.svg'
import { ReactComponent as ManageProjectsIcon } from '@assets/icons/manage-projects.svg'
import SwitcherIcon from '@assets/icons/temporary/switcher_mobile.png'
import { AvatarBox, InfoUserBox, Role } from '@components/modules/Layout/Header/styles'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_USER_NAME } from '@constants/common'
import { setIsChangedField } from '@state/samples'
import { setLaboratoryForWorkspace } from '@state/workSpace'
import { UserAvatar } from '@components/shared'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'

import {
  StyledMenu,
  Popper,
  StyledMenuItem,
  StyledListItemText,
  StyledMenuList,
  StyledName,
  StyledAvatarHolder,
} from './styles'

type ProfileMenuTypes = {
  toggleModal: () => void
  isMobile?: boolean
  isOpenProfileMenu: boolean
}

export const ProfileMenu: React.FC<ProfileMenuTypes> = ({
  toggleModal,
  isMobile,
  isOpenProfileMenu,
}) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [isUserAuthorized, setIsUserAuthorized] = React.useState<boolean>(true)
  const { role_switcher, role, first_name, sex } = useAppSelector(selectUser)
  const currentLaboratory = useAppSelector(selectUserLaboratory)
  const isDefaultManagerInCentralLabBool = useAppSelector(isDefaultManagerInCentralLab)
  const { isTenantType } = useTenant()
  const isLegalDrugs = isTenantType(TENANT_TYPE.legalDrugs)

  const roleName = (() => {
    if (role.key === INVENTORY_MANAGER) return 'Laboratory assistant'
    return 'Inventory manager'
  })()

  const updateRoleHandler = () => {
    dispatch(setIsChangedField(false))
    if (!isUserAuthorized) return

    const newRole = role.key === INVENTORY_MANAGER ? LABORATORY_ASSISTANT : INVENTORY_MANAGER

    dispatch(update({ role: newRole }))
    dispatch(setSwitcherForRole(true))

    if (newRole === INVENTORY_MANAGER) {
      history.push(protectedRoutes.samples.path)
    } else {
      history.push(protectedRoutes.tasks.path)
    }
  }

  const profileHandler = () => {
    dispatch(setIsChangedField(false))
    if (isUserAuthorized) history.push(protectedRoutes.profile.path)
  }

  const yourWorkspaceHandler = () => {
    dispatch(setIsChangedField(false))
    dispatch(setLaboratoryForWorkspace(currentLaboratory))
    if (isUserAuthorized) history.push(protectedRoutes.yourWorkspace.path)
  }

  const manageProjectsHandler = () => {
    dispatch(setIsChangedField(false))
    if (isUserAuthorized) history.push(protectedRoutes.manageProjects.path)
  }

  const feedbackFormHandler = () => {
    dispatch(setIsChangedField(false))
    if (isUserAuthorized) toggleModal()
  }

  const logoutHandler = () => {
    dispatch(setIsChangedField(false))
    if (isUserAuthorized) {
      localStorage.removeItem('auto-logout')
      localStorage.removeItem('_expiredTime')
      setIsUserAuthorized(false)
      dispatch(logout())
    }
  }

  return (
    <Popper className='popper' $isMobile={isMobile}>
      <StyledMenu
        sx={isOpenProfileMenu && !isMobile ? { display: 'block' } : {}}
        className={isOpenProfileMenu && isMobile ? 'mobile-variant' : ''}
      >
        <StyledMenuList $isMobile={isMobile}>
          {/* PROFILE BLOCK for mobile */}
          {isMobile && (
            <StyledMenuItem $isMobile={isMobile}>
              <AvatarBox sx={{ justifyContent: 'space-between', width: '100%' }}>
                <StyledAvatarHolder>
                  <UserAvatar width='41px' height='41px' sex={sex.key} role={role.key} />
                  <InfoUserBox>
                    <StyledName>{stringSlice(first_name, TRUNCATED_USER_NAME)}</StyledName>
                    <Role sx={{ fontSize: 14 }}>{role.value}</Role>
                  </InfoUserBox>
                </StyledAvatarHolder>
                {/* TODO: change image cup below to the switcher component */}
                <img src={SwitcherIcon} alt='temporary' />
              </AvatarBox>
            </StyledMenuItem>
          )}
          {/* SWITCH ROLE */}
          {role_switcher && (
            <StyledMenuItem onClick={updateRoleHandler}>
              <ListItemIcon>
                <SwitchRoleIcon fill='#adb4c2' className='menu-icon' />
              </ListItemIcon>
              <StyledListItemText primary={`Switch to ${roleName}`} />
            </StyledMenuItem>
          )}
          {/* PROFILE for desktop */}
          {!isMobile && (
            <StyledMenuItem onClick={profileHandler}>
              <ListItemIcon>
                <UserIcon fill='#adb4c2' className='menu-icon' />
              </ListItemIcon>
              <StyledListItemText primary='Your profile' />
            </StyledMenuItem>
          )}
          {/* YOUR WORKSPACE */}
          {role.key === INVENTORY_MANAGER && !isMobile && (
            <StyledMenuItem onClick={yourWorkspaceHandler}>
              <ListItemIcon>
                <WorkspaceIcon className='round-icon' />
              </ListItemIcon>
              <StyledListItemText primary='Your workspace' />
            </StyledMenuItem>
          )}
          {/* MANAGE PROJECTS */}
          {role.key === INVENTORY_MANAGER &&
            isDefaultManagerInCentralLabBool &&
            !isMobile &&
            isLegalDrugs && (
              <StyledMenuItem onClick={manageProjectsHandler}>
                <ListItemIcon>
                  <ManageProjectsIcon className='round-icon filled' />
                </ListItemIcon>
                <StyledListItemText primary='Manage projects' />
              </StyledMenuItem>
            )}
          {/* FEEDBACK FORM */}
          <StyledMenuItem onClick={feedbackFormHandler}>
            <ListItemIcon>
              <LetterIcon className='round-icon' />
            </ListItemIcon>
            <StyledListItemText primary='Feedback form' />
          </StyledMenuItem>
          {/* LOG OUT */}
          <StyledMenuItem $isMobile={isMobile} onClick={logoutHandler}>
            <ListItemIcon>
              <ExitIcon fill='#adb4c2' className='menu-icon' />
            </ListItemIcon>
            <StyledListItemText primary='Log Out' />
          </StyledMenuItem>
        </StyledMenuList>
      </StyledMenu>
    </Popper>
  )
}
