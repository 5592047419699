import { useTheme } from '@mui/material'

import { UserRole, UserSex } from '@models/common/user'
import { ReactComponent as AvatarLaborantMale } from '@assets/icons/laborant-male.svg'
import { ReactComponent as AvatarManagerMale } from '@assets/icons/manager-male.svg'
import { ReactComponent as AvatarLaborantFemale } from '@assets/icons/laborant-female.svg'
import { ReactComponent as AvatarManagerFemale } from '@assets/icons/manager-female.svg'

interface Props {
  sex?: UserSex
  role?: UserRole
  width?: string
  height?: string
}

export const UserAvatar: React.FC<Props> = ({ sex, role, width, height }) => {
  const theme = useTheme()

  if (sex && role) {
    if (sex === 'male') {
      if (role === 'inventory_manager') {
        return <AvatarManagerMale width={width} height={height} fill={theme.palette.primary.main} />
      } else {
        return (
          <AvatarLaborantMale width={width} height={height} fill={theme.palette.primary.main} />
        )
      }
    } else {
      if (role === 'inventory_manager') {
        return (
          <AvatarManagerFemale width={width} height={height} fill={theme.palette.primary.main} />
        )
      } else {
        return (
          <AvatarLaborantFemale width={width} height={height} fill={theme.palette.primary.main} />
        )
      }
    }
  }
  return null
}
