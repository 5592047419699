import React from 'react'

import { ISample } from '@models/samples'
import { NotesField } from '@components/shared/sampleFields'
import { SAMPLES } from '@constants/queries'

import { StyledTableCell } from '../styles'

interface NotesProps {
  sample: ISample
}

export const Notes: React.FC<NotesProps> = ({ sample }) => {
  const { notes } = sample

  return (
    <StyledTableCell mr='48px'>
      <NotesField notes={notes} id={sample.id} content={SAMPLES} btnText='sample' />
    </StyledTableCell>
  )
}
