import { styled, Stepper, Box, StepperProps } from '@mui/material'
import { BoxProps } from '@mui/system'
import React from 'react'

interface StyledBoxProps extends BoxProps {
  $isMobile?: boolean
  $isTwoStepsInTest?: boolean
}

export const StyledBox = styled<React.FC<StyledBoxProps>>(Box)`
  display: flex;
  justify-content: ${({ $isMobile }) => ($isMobile ? 'center' : 'flex-start')};
  align-items: ${({ $isMobile }) => ($isMobile ? 'center' : '')};
  height: ${({ $isMobile }) => ($isMobile ? '' : '100%')};
  padding: ${({ $isMobile, $isTwoStepsInTest }) =>
    $isMobile ? ($isTwoStepsInTest ? '42px 42px 42px 26px' : '42px') : ''};
`

interface StyledStepperProps extends StepperProps {
  $isMobile?: boolean
  $alignItems?: string
  $topDivider?: string
  $leftDivider?: string
  $isLast?: boolean
}

export const StyledStepper = styled<React.FC<StyledStepperProps>>(Stepper)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  align-items: ${({ $alignItems }) => $alignItems};

  &.MuiStepper-vertical {
    overflow: inherit;
    height: 100%;
  }

  & .MuiStep-vertical {
    position: relative;
  }

  &.MuiStepper-horizontal {
    overflow: inherit;
    width: 100%;
  }

  & .MuiStep-horizontal {
    display: flex;
    padding: 0;
    width: 100%;
  }

  & .MuiIcon-root {
    width: 24px;
    height: 24px;
    display: inline-block;
    background-color: ${({ theme }) => theme.additional.stroke.main};
    border-radius: 50%;
  }

  & .MuiDivider-root {
    top: ${({ $isMobile, $topDivider }) => ($isMobile ? '' : $topDivider ? $topDivider : '23px')};
    left: ${({ $isMobile, $leftDivider }) =>
      $isMobile ? '' : $leftDivider ? $leftDivider : '12px'};
    height: ${({ $isMobile }) => ($isMobile ? '' : '100%')};
    position: ${({ $isMobile }) => ($isMobile ? '' : 'absolute')};
    width: ${({ $isMobile }) => ($isMobile ? '90px' : '')};
    border-left: 1px solid #e5e9f1;
    align-self: ${({ $isMobile }) => ($isMobile ? 'center' : '')};
    display: ${({ $isLast }) => ($isLast ? 'none' : 'block')};
  }

  & .MuiTypography-root {
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
  }

  & .MuiStep-root.completed .MuiIcon-root {
    background-color: #e2f5ea;
  }

  & .MuiStep-root.awaiting .MuiIcon-root {
    background-color: #eaf8fc;
    color: #076aa2;
  }

  .MuiStep-root.awaiting.opacity {
    opacity: 0.5;
  }

  & .MuiStep-root.in_progress .MuiIcon-root {
    background-color: #fcf5dd;
    color: #b08904;
  }

  & .MuiStep-root.Failed .MuiIcon-root {
    background-color: #fce4e4;
    color: #af4645;
  }
`

export const StyledStepBoxMobile = styled<React.FC<StyledBoxProps>>(Box)`
  display: flex;
  flex-direction: column;
`

export const StyledText = styled('span')`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8f929b;
`
