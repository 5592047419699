import React from 'react'
import { useMediaQuery } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import { useUpdateTest } from '@hooks/queries'
import { Modal } from '@components/shared'
import { NEGATIVE, POSITIVE } from '@constants/tasks'
import { IApisWithPercentage } from '@models/common/app'
import { ButtonBox, FailedButton, PassedButton } from '@styles'
import { SCREENING_GROUP, TestGroupName, TestResultType, VISUAL_GROUP } from '@models/tests'
import { ITestButton } from '@models/tasks'

import { StyledWrapper, StyledTitle } from './styles'
import { HPLCTestResult } from './HPLCTestResult'

interface TestResultsModalProps {
  taskId: number
  testGroupName: TestGroupName
  onClose: () => void
  taskApis: IApisWithPercentage[]
  resultBtnName?: ITestButton
  editTestResult?: boolean
}

export const TestResultsModal: React.FC<TestResultsModalProps> = props => {
  const { onClose, taskApis, taskId, resultBtnName, editTestResult, testGroupName } = props
  const methods = useForm()
  const isMobile = useMediaQuery('(max-width:767px)')
  const isScreeningTest = testGroupName === SCREENING_GROUP || testGroupName === VISUAL_GROUP

  const updateTestM = useUpdateTest(editTestResult)

  const [mainApis, setMainApis] = React.useState<Omit<IApisWithPercentage, 'name'>[]>([])
  const [isErrorUpdateTest, setIsErrorUpdateTest] = React.useState<boolean>(false)
  const [isLoadingUpdateTest, setIsLoadingUpdateTest] = React.useState<boolean>(false)

  const changeMainApis = (date: Omit<IApisWithPercentage, 'name'>) =>
    setMainApis([...mainApis.filter(apis => apis.id !== date.id), date])

  const completeHandler: SubmitHandler<Record<string, string>> = data => {
    onClose()

    const formattedData = {
      result: data.result,
      main_apis: mainApis.length ? mainApis : undefined,
    }

    updateTestM.mutate({ taskId, data: formattedData })
  }

  const resultHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    result: TestResultType
  ) => {
    methods.register('result', { value: result })
    methods.handleSubmit(completeHandler)(e)
  }

  React.useEffect(() => {
    window.addEventListener('keydown', event => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    })

    return () => {
      window.removeEventListener('keydown', () => {})
    }
  }, [])

  const isFormErrors = Object.keys(methods.formState.errors).length !== 0
  const isDisableButton =
    updateTestM.isLoading || isErrorUpdateTest || isLoadingUpdateTest || isFormErrors

  return (
    <Modal open onClose={onClose} className='testing-modal'>
      <StyledWrapper $isMobile={isMobile}>
        <StyledTitle
          $isMobile={isMobile}
          variant={isMobile ? 'subtitle1' : 'h3'}
          position='relative'
        >
          {isScreeningTest
            ? 'Please select result of test'
            : 'Please type percentage of API content'}
        </StyledTitle>

        <FormProvider {...methods}>
          <form>
            {!isScreeningTest && (
              <HPLCTestResult
                changeMainApis={changeMainApis}
                editTestResult={editTestResult}
                taskApis={taskApis}
                taskId={taskId}
                setIsErrorUpdateTest={setIsErrorUpdateTest}
                setIsLoadingUpdateTest={setIsLoadingUpdateTest}
                isLoadingUpdateTest={isLoadingUpdateTest}
                isErrorUpdateTest={isErrorUpdateTest}
              />
            )}

            <ButtonBox $isMobile={isMobile}>
              <FailedButton
                disabled={isDisableButton}
                variant='contained'
                sx={{
                  marginRight: isMobile ? '' : '16px',
                  marginTop: isMobile ? '16px' : '32px',
                  width: isMobile ? '100%' : '205px',
                  order: isMobile ? 2 : 1,
                }}
                onClick={e => resultHandler(e, NEGATIVE)}
              >
                {resultBtnName?.negative}
              </FailedButton>

              <PassedButton
                disabled={isDisableButton}
                variant='contained'
                sx={{
                  marginTop: isMobile ? '16px' : '32px',
                  width: isMobile ? '100%' : '205px',
                  order: isMobile ? 1 : 2,
                }}
                onClick={e => resultHandler(e, POSITIVE)}
              >
                {resultBtnName?.positive}
              </PassedButton>
            </ButtonBox>
          </form>
        </FormProvider>
      </StyledWrapper>
    </Modal>
  )
}
