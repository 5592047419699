import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'

import { userSendFeedbackForm } from '@api/user'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useSendFeedbackForm = () => {
  const dispatch = useDispatch()
  const mutation = useMutation<unknown, IError, FormData>(userSendFeedbackForm, {
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
  return mutation
}
