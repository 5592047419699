import React from 'react'

import { Input } from '@components/shared/Input'

interface SearchFiltersProps {
  searchFiltersHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const SearchFilters: React.FC<SearchFiltersProps> = React.memo(
  ({ searchFiltersHandler }) => {
    return (
      <Input
        sx={{
          marginTop: '-18px',
          zIndex: 10,
          padding: '20px 24px',
          position: 'sticky',
          top: '38px',
          background: '#fff',
        }}
        variant={'outlined'}
        width='calc(100% - 48px)'
        bgColorDefault
        placeholder='Search...'
        borderColor='#E1E7F3'
        onChange={searchFiltersHandler}
      />
    )
  }
)
