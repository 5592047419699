import React from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import { HiOutlineSearch } from 'react-icons/hi'

import { Input } from '@components/shared/Input'

interface SearchProps {
  inputOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Search: React.FC<SearchProps> = React.memo(({ inputOnChange }) => {
  return (
    <Input
      height={48}
      width='320px'
      mb='16px'
      onChange={inputOnChange}
      variant='outlined'
      placeholder='Enter laboratory or manager...'
      InputProps={{
        startAdornment: (
          <InputAdornment position='end'>
            <IconButton disabled edge='start' sx={{ paddingRight: '0' }}>
              <HiOutlineSearch color='#ADB4C2' />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
})
