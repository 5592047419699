import * as React from 'react'
import { FormControlLabel } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { cloneDeep } from 'lodash'

import { Checkbox } from '@components/shared'
import { PureTooltip } from '@components/ui/PureTooltip'
import { ReactComponent as AddIcon } from '@assets/icons/add-icon.svg'
import { SUBSTANCES, RESULT_RECOGNIZED } from '@constants/tests'

import { SubstancesItemGroup } from './SubstancesItemGroup'
import { StyledAddSubstancesBtn } from './styles'

export const SubstancesRecognized = () => {
  const { control, register, setValue, getValues } = useFormContext()
  const [isNotRecognized, setIsNotRecognized] = React.useState(false)
  const { fields } = useFieldArray({
    control,
    name: SUBSTANCES,
  })

  React.useEffect(() => {
    setValue(SUBSTANCES, [{ name: '', percentage: '', is_not_determined: false }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddNewSubstances = () => {
    if (!isNotRecognized && fields.length < 5) {
      const clonedFormSubstances = cloneDeep(getValues(SUBSTANCES))
      setValue(SUBSTANCES, [
        ...clonedFormSubstances,
        { name: '', percentage: '', is_not_determined: false },
      ])
    }
  }

  const handleRemoveItemGroup = (id: string, index: number) => {
    const clonedFormSubstances = cloneDeep(getValues(SUBSTANCES))
    clonedFormSubstances.splice(index, 1)
    setValue(SUBSTANCES, clonedFormSubstances)
  }

  const handleNotRecognized = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setValue(RESULT_RECOGNIZED, checked)
    setIsNotRecognized(checked)
  }

  return (
    <>
      <FormControlLabel
        control={<Checkbox {...register(RESULT_RECOGNIZED)} onChange={handleNotRecognized} />}
        label='Substances were not recognized'
        sx={{ marginBottom: '30px' }}
      />
      {fields.map((substance, idx) => (
        <SubstancesItemGroup
          key={substance.id}
          index={idx}
          isNotRecognized={isNotRecognized}
          isShowRemoveBtn={fields.length > 1}
          handleRemoveItemGroup={() => handleRemoveItemGroup(substance.id, idx)}
        />
      ))}
      <PureTooltip
        title='Maximum of 5 substances test results can be added'
        titleStyle={{ width: 213 }}
        shouldBeHidden={fields.length < 5}
      >
        <StyledAddSubstancesBtn
          type='button'
          $disabled={isNotRecognized || fields.length > 4}
          onClick={handleAddNewSubstances}
        >
          <AddIcon />
          Add substance
        </StyledAddSubstancesBtn>
      </PureTooltip>
    </>
  )
}
