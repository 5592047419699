import React from 'react'
import { Paper, PaperProps } from '@mui/material'

import { StyledDescription } from '@components/shared/AddAutocompleteNewData/styles'

import { AddButton } from './styles'

interface AutocompletePaperProps extends PaperProps {
  shouldRenderBtn?: boolean
  onBtnClick?: React.MouseEventHandler<HTMLButtonElement>
  btnIsLoading?: boolean
  btnText?: string
  btnIcon?: JSX.Element
  inputValue?: string
}

export const AutocompletePaper: React.FC<AutocompletePaperProps> = props => {
  const {
    inputValue,
    children,
    btnText,
    btnIcon,
    shouldRenderBtn,
    onBtnClick,
    btnIsLoading,
    ...restProps
  } = props

  const thereIsAMatch = React.useMemo(() => {
    let flag = false

    React.Children.forEach(children, child => {
      if (
        !!child &&
        typeof (child as React.ReactElement).props.children !== 'string' &&
        (child as React.ReactElement).props.children.some(
          (optionChild: React.ReactElement) =>
            optionChild.key &&
            (optionChild.key as string).toLowerCase() === inputValue?.trim().toLowerCase()
        )
      ) {
        flag = true
      }
    })

    return flag
  }, [children, inputValue])

  return (
    <Paper {...restProps}>
      {children}
      {shouldRenderBtn && !thereIsAMatch && (
        <AddButton
          fullWidth
          variant='text'
          loadingPosition='start'
          loading={btnIsLoading}
          startIcon={btnIcon}
          onMouseDown={onBtnClick}
        >
          <StyledDescription>
            {btnIsLoading ? 'Adding...' : inputValue || btnText}
          </StyledDescription>
        </AddButton>
      )}
    </Paper>
  )
}

AutocompletePaper.defaultProps = {
  shouldRenderBtn: false,
  onBtnClick: () => {},
  btnIsLoading: false,
  btnText: '',
  btnIcon: <></>,
  inputValue: '',
}
