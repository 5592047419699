import { TabContext } from '@mui/lab'
import React from 'react'

import { ISampleInfoTabValue, ISampleTasks } from '@models/samples'
import { useFetchSampleTasks } from '@hooks/queries'
import { FullPageError } from '@components/shared'
import { StyledTabPanel } from '@styles'

import { Loader } from './Loader'
import { TabButtons } from './TabButtons'
import { TabBasicInfo } from './TabBasicInfo'
import { TabTestingInfo } from './TabTestingInfo'

interface TabsProps {
  sampleId: number
  specificId?: string
}

export const Tabs: React.FC<TabsProps> = ({ sampleId, specificId }) => {
  const [currentTab, setCurrentTab] = React.useState<ISampleInfoTabValue>('basic info')
  const fetchSampleTasksQ = useFetchSampleTasks(sampleId)

  const sampleTasks = fetchSampleTasksQ.data?.results

  if (fetchSampleTasksQ.isLoading) {
    return <Loader />
  }

  if (fetchSampleTasksQ.isError) {
    return <FullPageError imgVariant='fail' errorTextTitle='Something went wrong...' pt='20px' />
  }

  if (!sampleTasks?.length) {
    return (
      <FullPageError imgVariant='common' errorTextTitle='There is no testing info yet' pt='20px' />
    )
  }

  return (
    <TabContext value={currentTab}>
      <TabButtons onChangeTab={setCurrentTab} />
      <StyledTabPanel value='basic info'>
        <TabBasicInfo
          specificId={specificId}
          sampleId={sampleId}
          tasks={sampleTasks as ISampleTasks}
        />
      </StyledTabPanel>
      <StyledTabPanel value='testing info'>
        <TabTestingInfo tasks={sampleTasks as ISampleTasks} />
      </StyledTabPanel>
    </TabContext>
  )
}
