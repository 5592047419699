import React, { useEffect } from 'react'
import { Box } from '@mui/material'

import { useActionsStickyBox } from '@hooks/common'
import { StyledTableBox } from '@styles'
import { IUsers } from '@models/common/user'
import { useAppSelector } from '@hooks/redux'
import { workSpaceInfo } from '@state/workSpace'
import { selectUser, selectUserLaboratory } from '@state/user'
import { HorizontalDragCup, HorizontalDragLayer } from '@components/shared'
import { draggableHorizontalScroll } from '@helpers/draggableHorizontalScroll'

import { TableHead } from './TableHead'
import { TableBody } from './TableBody'
import { StyledTableHeadStickyWrapper } from './styles'
import { TableHeader } from '../TableHeader'

interface TableProps {
  users?: IUsers
  isLoading: boolean
  isFetchingNextPage?: boolean
  setEditUserIsOpen: (arg1: boolean) => void
}

export const Table: React.FC<TableProps> = ({
  isFetchingNextPage,
  users,
  setEditUserIsOpen,
  isLoading,
}) => {
  const tableHeadRef = React.useRef<HTMLDivElement | null>(null)
  const isSticky = useActionsStickyBox(tableHeadRef, '150px 0px 0px 0px')

  const horizontalDragCup = React.useRef<HTMLDivElement | null>(null)
  const draggableHorizontalRef = React.useRef<HTMLDivElement | null>(null)
  const draggableHorizontalLayerRef = React.useRef<HTMLDivElement | null>(null)

  const currentLaboratory = useAppSelector(selectUserLaboratory)
  const { can_manage_users } = useAppSelector(selectUser)
  const { selectedLaboratory } = useAppSelector(workSpaceInfo)
  const isNotOwnerLaboratory = selectedLaboratory?.id !== currentLaboratory.id || !can_manage_users

  useEffect(() => {
    if (draggableHorizontalRef.current && horizontalDragCup.current) {
      const { removeDraggableEvents } = draggableHorizontalScroll(
        draggableHorizontalRef,
        draggableHorizontalLayerRef,
        horizontalDragCup
      )

      return () => removeDraggableEvents()
    }
  }, [])

  const horizontalScrollHandler: React.UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    tableHeadRef.current?.scrollTo(currentTarget.scrollLeft, 0)
  }

  return (
    <>
      <StyledTableHeadStickyWrapper>
        <TableHeader buttonDisable={isNotOwnerLaboratory} setEditUserIsOpen={setEditUserIsOpen} />
      </StyledTableHeadStickyWrapper>

      <Box sx={{ width: 'auto' }}>
        {!!users?.length && !isFetchingNextPage && (
          <TableHead ref={tableHeadRef} hideBorder={!isSticky} />
        )}
        <HorizontalDragCup ref={horizontalDragCup} />
        <StyledTableBox ref={draggableHorizontalRef} onScroll={horizontalScrollHandler}>
          <HorizontalDragLayer ref={draggableHorizontalLayerRef} />
          <TableBody
            isNotOwnerLaboratory={isNotOwnerLaboratory}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            setEditUserIsOpen={setEditUserIsOpen}
            users={users}
          />
        </StyledTableBox>
      </Box>
    </>
  )
}
