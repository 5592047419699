import React from 'react'

import { ISample } from '@models/samples'
import { useAppSelector } from '@hooks/redux'
import { useToggleSample } from '@hooks/common'
import { Checkbox } from '@components/shared'
import { StyledTableSampleCell } from '@styles'
import { selectTestReports, selectTestReportsState } from '@state/testReports'

interface TogglerProps {
  report: ISample
  hideBorder?: boolean
}

export const Toggler: React.FC<TogglerProps> = React.memo(props => {
  const { report, hideBorder } = props

  const { selectedTestReports } = useAppSelector(selectTestReportsState)
  const { selectSampleHandler, isSelected } = useToggleSample(
    report.id,
    selectTestReports,
    selectedTestReports
  )

  React.useEffect(() => {}, [selectedTestReports])

  return (
    <StyledTableSampleCell hideBorder={hideBorder} sx={{ justifyContent: 'flex-start' }}>
      <Checkbox padding='0' ml='0' checked={isSelected} onClick={selectSampleHandler} />
    </StyledTableSampleCell>
  )
})
