import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Box, IconButton } from '@mui/material'
import { v4 as uuid } from 'uuid'

import { Button } from '@components/shared'
import { IErrorsEditLaboratory } from '@models/yourWorkspace'
import { ErrorBox, ErrorForAllFields } from '@components/shared/ErrorBox'
import { Image } from '@styles'
import deleteIcon from '@assets/icons/delete-icon-active.svg'

import * as Styled from '../../../styles'
import { InputField } from '../InputField'

interface EmailsProps {
  apiErrorMessage?: IErrorsEditLaboratory
}

export const Emails: React.FC<EmailsProps> = ({ apiErrorMessage }) => {
  const { control, formState } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
  })

  const handleAppend = (value: Partial<unknown> | Partial<unknown>[]) => {
    append(value)
  }

  const handleRemove = (index: number | number[] | undefined) => {
    remove(index)
  }

  const emailFields = !fields.length ? [{ id: uuid(), email: '', department: '' }] : fields

  const getEmail = (index: number) => {
    if (!!formState.errors?.emails) {
      if (formState.errors?.emails[`${index}`]?.email)
        return formState.errors?.emails[`${index}`]?.email.message
    }
    return ''
  }

  const getDepartment = (index: number) => {
    if (!!formState.errors?.emails) {
      if (formState.errors?.emails[`${index}`]?.department)
        return formState.errors?.emails[`${index}`]?.department.message
    }
    return ''
  }

  const isUserAbleToAddNewEmail = fields.length < 3
  const errorForAllEmailsFields = apiErrorMessage?.emails

  return (
    <>
      <Box display='flex'>
        <Styled.Label>Email(s)</Styled.Label>
        <Styled.Label ml='183px'>Department(s)</Styled.Label>
      </Box>
      <>
        <Styled.StyledBox $isErrorForAllFields={!!errorForAllEmailsFields}>
          {emailFields.map((item, index) => (
            <React.Fragment key={item.id}>
              <Styled.FieldsBox $isErrorForAllFields={!!errorForAllEmailsFields}>
                <InputField
                  name={`emails.${index}.email`}
                  width='239px'
                  apiError={getEmail(index)}
                />
                <InputField
                  name={`emails.${index}.department`}
                  width='239px'
                  ml='4px'
                  apiError={getDepartment(index)}
                />

                {index > 0 && (
                  <IconButton
                    onClick={() => handleRemove(index)}
                    sx={{ width: '40px', height: '40px', marginLeft: '4px' }}
                  >
                    <Image component='img' src={deleteIcon} width='24px' height='24px' />
                  </IconButton>
                )}
              </Styled.FieldsBox>

              <ErrorBox errorMainField={getEmail(index)} errorDepartment={getDepartment(index)} />
            </React.Fragment>
          ))}
        </Styled.StyledBox>

        {errorForAllEmailsFields && (
          <ErrorForAllFields errorForAllFields={errorForAllEmailsFields} />
        )}
      </>

      {isUserAbleToAddNewEmail && (
        <Button
          ml='-2px'
          mb='18px'
          type='button'
          onClick={() => handleAppend({ email: '', department: '' })}
          variant='text'
          startIconAdd
        >
          Add email
        </Button>
      )}
    </>
  )
}
