import React from 'react'

import { ButtonBox, StyledBox } from '@styles'
import { Button, Modal } from '@components/shared'
import wrongQrCode from '@assets/images/wrong-qr-code.svg'

import { StyledImg, StyledParagraph, StyledTitle } from './styles'

interface ScanQRcodeModalProps {
  isMobile?: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ScanQRcodeModal: React.FC<ScanQRcodeModalProps> = props => {
  const { isMobile, onClose, onConfirm } = props

  React.useEffect(() => {
    onConfirm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const confirmHandler = () => {
    onConfirm()
    onClose()
  }

  return (
    <Modal open onClose={onClose}>
      <>
        <StyledBox>
          <StyledImg src={wrongQrCode} alt='Wrong QR' />
          <StyledTitle variant='h3'>Wrong way | turn back</StyledTitle>
          <StyledParagraph sx={{ marginBottom: 3 }}>
            The website you're trying to visit <br /> isn't owned by ARTiFACTS Verify
          </StyledParagraph>
        </StyledBox>

        <ButtonBox $isMobile={isMobile}>
          <Button
            variant='contained'
            width='205px'
            onClick={confirmHandler}
            sx={{ order: isMobile ? 1 : 2 }}
          >
            Scan
          </Button>
        </ButtonBox>
      </>
    </Modal>
  )
}
