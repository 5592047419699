import { styled, Typography } from '@mui/material'

export const StyledTitle = styled(Typography)`
  text-align: center;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.palette.text.primary};
`

export const StyledParagraph = styled(Typography)`
  text-align: center;
  margin-right: 6px;
  color: ${({ theme }) => theme.palette.text.secondary};
`
