import React from 'react'
import { Typography } from '@mui/material'

import { NoSearchedParamsIcon } from '@components/icons/NoSearchedParamsIcon'

interface NoSearchedParamsProps {
  text: string
}

export const NoSearchedParams: React.FC<NoSearchedParamsProps> = ({ text }) => {
  return (
    <>
      <NoSearchedParamsIcon />
      <Typography variant='h4' color='#778299' textAlign='center'>
        {text}
      </Typography>
    </>
  )
}
