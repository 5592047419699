import React, { useRef } from 'react'
import { format } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Box, Typography, useMediaQuery } from '@mui/material'

import { Button, InfoBoxTemperature, Modal } from '@components/shared'
import { useSendPackages } from '@hooks/queries'
import { validationSchemas } from '@services/validation'
import { IPackage, ISendPackagesData, ISendPackagesFormData } from '@models/packages'
import {
  ButtonBox,
  StyledModalContent,
  StyledModalScroll,
  StyledModalSubtitle,
  StyledModalTitle,
  StyledShadowBox,
} from '@styles'
import { SampleOptionsProvider } from '@context'
import { infoBoxText } from '@components/shared/InfoBoxes/InfoBoxTemperature'
import {
  MAX_TEMPERATURE,
  MIN_TEMPERATURE,
  TEMPERATURE_FORMAT,
  TOXICITY_LEVEL,
} from '@constants/samples'
import { FAHRENHEIT } from '@constants/common'
import { obtainingCorrectTemperature } from '@helpers/obtainingCorrectTemperature'
import { checkSkills } from '@helpers/obtainingSkills'
import { skills } from '@constants/mappings'
import { useAppSelector } from '@hooks/redux'
import { selectAppFlags } from '@state/app'
import { TENANT_TYPE } from '@constants/tenants'
import { useTenant } from '@hooks/common/useTenant'

import { DestinationField } from './components/DestinationField'
import { ExpectedDateField } from './components/ExpectedDateField'
import { StorageConditionsDetails } from './components/StorageConditionsDetails'
import { DeliveryServiceField } from './components/DeliveryServiceField'
import { TrackingNumberField } from './components/TrackingNumberField'
import { PackageTypeField } from './components/PackageTypeField'

interface SendPackageModalProps {
  onClose: () => void
  selectedPackages: IPackage[]
}

export const SendPackageModal: React.FC<SendPackageModalProps> = ({
  selectedPackages,
  onClose,
}) => {
  const scrollBlockRef = React.useRef<HTMLDivElement | null>(null)
  const { isAddingAttachment } = useAppSelector(selectAppFlags)
  const sendPackagesM = useSendPackages()
  const isMobile = useMediaQuery('(max-width:767px)')
  const [isOpenExitModal, setIsOpenExitModal] = React.useState(false)
  const { isTenantType } = useTenant()
  const { isSampleTemperatureSensitive, isSampleToxic } = checkSkills(selectedPackages[0])

  const getDefaultValues = () => {
    let temperatureFormat = isSampleTemperatureSensitive ? FAHRENHEIT : undefined
    let minTemperature = selectedPackages[0].min_temperature
    let maxTemperature = selectedPackages[0].max_temperature

    if (isSampleTemperatureSensitive) {
      if (
        selectedPackages[0].min_temperature_measurement_format.key ===
        selectedPackages[0].max_temperature_measurement_format.key
      ) {
        temperatureFormat = selectedPackages[0].max_temperature_measurement_format.key
      } else {
        if (selectedPackages[0].min_temperature_measurement_format.key !== FAHRENHEIT)
          minTemperature = obtainingCorrectTemperature(
            FAHRENHEIT,
            selectedPackages[0].min_temperature!
          )
        if (selectedPackages[0].max_temperature_measurement_format.key !== FAHRENHEIT)
          maxTemperature = obtainingCorrectTemperature(
            FAHRENHEIT,
            selectedPackages[0].max_temperature!
          )
      }
    }

    const initialValues = {
      [TOXICITY_LEVEL]: selectedPackages[0].toxicity_level?.key,
      [TEMPERATURE_FORMAT]: temperatureFormat,
      [MIN_TEMPERATURE]: minTemperature,
      [MAX_TEMPERATURE]: maxTemperature,
      title: null,
      url: null,
    }
    return initialValues
  }

  const formMethods = useForm<ISendPackagesFormData>({
    resolver: yupResolver(validationSchemas.sendPackages),
    mode: 'onChange',
    context: { isEditing: isSampleTemperatureSensitive || isSampleToxic },
    defaultValues: getDefaultValues(),
  })

  const sendPackagesHandler: SubmitHandler<ISendPackagesFormData> = async ({
    expected_date,
    destination,
    toxicity_level,
    max_temperature,
    min_temperature,
    temperature_measurement_format,
    delivery_service,
    tracking_number,
    package_type,
  }) => {
    const formattedDeliveryService = delivery_service ? Number(delivery_service) : delivery_service
    const data: ISendPackagesData = {
      destination,
      expected_date: expected_date ? format(expected_date, 'y-MM-dd') : '',
      package_ids: selectedPackages.map(({ id }) => id),
      toxicity_level,
      min_temperature,
      min_temperature_measurement_format: temperature_measurement_format,
      max_temperature,
      max_temperature_measurement_format: temperature_measurement_format,
    }

    if (delivery_service) {
      data.delivery_service = formattedDeliveryService
    }

    if (tracking_number) {
      data.tracking_number = tracking_number
    }

    if (package_type) {
      data.package_type = package_type
    }

    await wrapperSaveFunction()
    sendPackagesM.mutate(data, { onSettled: () => wrapperCloseFunction(true) })
  }

  const childRef = useRef<{
    handleExitEditing: Function
    handleCloseEditing: Function
    handleSave: Function
  }>()

  const wrapperSaveFunction = () => {
    // check that the ref exists to avoid errors
    if (!childRef.current) return

    childRef.current.handleSave()
  }

  const wrapperCloseFunction = (closeWithSaving?: boolean) => {
    // check that the ref exists to avoid errors
    if (!childRef.current) {
      onClose()
      return
    }

    childRef.current.handleCloseEditing(closeWithSaving)
  }

  const wrapperExitFunction = () => {
    // check that the ref exists to avoid errors
    if (!childRef.current) return
    childRef.current.handleExitEditing()
  }

  const requiredLaboratorySkills = []
  isSampleToxic && requiredLaboratorySkills.push(skills[0].id)
  isSampleTemperatureSensitive && requiredLaboratorySkills.push(skills[1].id)

  const ConditionalComponent = isTenantType(TENANT_TYPE.streetDrugs)
    ? React.Fragment
    : SampleOptionsProvider

  return (
    <>
      <Modal
        open
        onClose={() => wrapperCloseFunction()}
        disableAutoFocus
        disableEscapeKeyDown
        isPaddingAbsence
      >
        <Box>
          <form onSubmit={formMethods.handleSubmit(sendPackagesHandler)}>
            <FormProvider {...formMethods}>
              <StyledShadowBox padding={isMobile ? '20px' : '25px'}>
                <StyledModalTitle variant='h3'>
                  Send {selectedPackages.length}{' '}
                  {selectedPackages.length > 1 ? 'packages' : 'package'}
                </StyledModalTitle>
                <StyledModalSubtitle withoutMarginBelow>
                  {selectedPackages.length > 1 ? 'Packages' : 'Package'} will move to outgoing
                  packages tab
                </StyledModalSubtitle>
              </StyledShadowBox>

              <StyledModalContent pt='0' $isMobile={isMobile}>
                <StyledModalScroll $isMobile={isMobile} ref={scrollBlockRef}>
                  <InfoBoxTemperature
                    temperatureText={
                      isSampleTemperatureSensitive && infoBoxText.temperature.package
                    }
                    toxicityText={isSampleToxic && infoBoxText.toxicity.package}
                  />
                  <ConditionalComponent>
                    <Typography sx={{ fontWeight: 'bold', marginBottom: '24px' }}>
                      Delivery details
                    </Typography>
                    <DestinationField requiredLaboratorySkills={requiredLaboratorySkills} />
                    <Box sx={{ display: 'flex' }}>
                      <ExpectedDateField />
                    </Box>

                    <DeliveryServiceField />

                    <TrackingNumberField />

                    <PackageTypeField />

                    {isSampleTemperatureSensitive || isSampleToxic ? (
                      <StorageConditionsDetails
                        scrollBlockRef={scrollBlockRef}
                        ref={childRef}
                        packageId={String(selectedPackages[0].id)}
                        onClose={onClose}
                        setIsOpenExitModal={setIsOpenExitModal}
                      />
                    ) : null}
                  </ConditionalComponent>
                </StyledModalScroll>
              </StyledModalContent>

              <StyledShadowBox
                sx={{ display: 'flex', justifyContent: 'center' }}
                padding='24px 10px'
              >
                <ButtonBox
                  $isMobile={isMobile}
                  width='100%'
                  sx={{ justifyContent: 'center', margin: '0 14px' }}
                >
                  <Button
                    type='button'
                    variant='outlined'
                    color='secondary'
                    width='205px'
                    height='48px'
                    sx={{ marginRight: '28px' }}
                    onClick={() => wrapperCloseFunction()}
                  >
                    Cancel
                  </Button>

                  <Button
                    type='submit'
                    variant='contained'
                    width='205px'
                    height='48px'
                    disabled={childRef?.current && isAddingAttachment}
                    loading={sendPackagesM.isLoading}
                  >
                    Send
                  </Button>
                </ButtonBox>
              </StyledShadowBox>
            </FormProvider>
          </form>
        </Box>
      </Modal>

      {/* EXIT MODAL FROM CONTENT MODAL */}
      <Modal open={isOpenExitModal} onClose={() => setIsOpenExitModal(false)}>
        <>
          <StyledModalTitle variant='h3'>
            Are you sure you want to cancel editing attachments?
          </StyledModalTitle>
          <ButtonBox $isMobile={isMobile}>
            <Button
              type='button'
              variant='outlined'
              color='secondary'
              width='205px'
              mr={isMobile ? '' : '60px'}
              onClick={wrapperExitFunction}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              mt={isMobile ? '16px' : ''}
              width='205px'
              onClick={() => setIsOpenExitModal(false)}
            >
              Continue editing
            </Button>
          </ButtonBox>
        </>
      </Modal>
    </>
  )
}
