import React from 'react'
import { Box } from '@mui/material'

import { ISample } from '@models/samples'
import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'

import { StyledTableCell } from '../styles'
import { SubText, StyledTypography } from './styles'

interface ManufacturerProps {
  sample: ISample
}

export const Manufacturer: React.FC<ManufacturerProps> = ({ sample }) => {
  const { manufacturer, brand } = sample

  return (
    <StyledTableCell>
      <Box>
        <Tooltip
          isPureTooltip
          title={manufacturer?.name || ''}
          maxWidth={210}
          positionLeft={20}
          shouldBeHidden={
            ((manufacturer && manufacturer.name.length) || 0) <= TRUNCATED_TEXT_LENGTH_S
          }
        >
          <StyledTypography>
            {manufacturer ? stringSlice(manufacturer.name, TRUNCATED_TEXT_LENGTH_S) : '-'}
          </StyledTypography>
        </Tooltip>

        <Tooltip
          isPureTooltip
          title={brand?.name || ''}
          maxWidth={210}
          positionLeft={50}
          positionTop={-10}
          shouldBeHidden={((brand && brand.name.length) || 0) <= TRUNCATED_TEXT_LENGTH_S}
        >
          <SubText>{brand ? stringSlice(brand.name, TRUNCATED_TEXT_LENGTH_S) : '-'}</SubText>
        </Tooltip>
      </Box>
    </StyledTableCell>
  )
}
