import { Typography } from '@mui/material'
import styled from 'styled-components'

export const TypographyStyled = styled(Typography)`
  white-space: nowrap;
  display: inline;
`

export const StyledTitleLink = styled(Typography)`
  display: inline;
  padding: 0;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
`
