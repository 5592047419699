import { CELSIUS } from '@constants/common'

export const obtainingCorrectTemperature = (formatOfTemperature: string, value: string) => {
  if (formatOfTemperature === CELSIUS) {
    // Fahrenheit to celsius
    if (value) return `${(+value - 32) * (5 / 9)}`
  } else {
    // Celsius to fahrenheit
    if (value) return `${(+value * 9) / 5 + 32}`
  }

  return value
}

export const getTemperatureSymbol = (format?: string | null) => {
  if (!format) {
    return
  }
  return format === CELSIUS ? '°C' : '°F'
}
