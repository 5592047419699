import React from 'react'
import { BoxProps, styled, Box, Typography } from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'

import {
  TableHeadWrapperBoxProps,
  TableCellProps,
  StyledTextCellProps,
  TableRequestsRowProps,
  ITableTaskRowProps,
} from './sampleTable.types'

const TableHeadWrapperConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'hideBorder':
      case 'top':
      case 'backgroundColor':
        return false

      default:
        return true
    }
  },
}

const TableCellConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'hideBorder':
      case 'isClickable':
      case 'padding':
      case 'header':
      case 'isLastSample':
      case 'isRequestContent':
      case '$isMobile':
      case 'bgColor':
        return false

      default:
        return true
    }
  },
}

const TableRowConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'shouldDisplayActionBtn':
        return false

      default:
        return true
    }
  },
}

const TableTasksRowConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'isAwaitingContentType':
        return false
      case '$isBigDesktop':
        return false

      default:
        return true
    }
  },
}

export const StyledTableHeadWrapper = styled<React.FC<TableHeadWrapperBoxProps>>(
  Box,
  TableHeadWrapperConfig
)`
  position: sticky;
  overflow: auto;
  top: ${({ top }) => (top ? top : '161px')};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 150)};
  overflow-x: ${({ overflowX }) => (overflowX ? overflowX : 'hidden')};
  box-shadow: ${({ hideBorder }) =>
    hideBorder ? 'none' : '0px 6px 15px rgba(53, 108, 151, 0.12)'};
  transition: box-shadow 500ms linear;
  background: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.additional.stroke.main};

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledTableHead = styled<React.FC<StyledTextCellProps>>(Box, TableCellConfig)`
  width: ${({ width }) => (width ? width : 'fit-content')};

  & > div div {
    padding: 15px 0;

    &:nth-child(1) {
      padding: ${({ isRequestContent }) => (isRequestContent ? '' : '15px 66px 15px 40px')};
    }

    &.tableTasks:nth-child(1) {
      padding: ${({ reportsContent, isRequestContent, padding }) =>
        reportsContent
          ? '15px 25px 15px 48px'
          : isRequestContent
          ? ''
          : padding
          ? padding
          : '15px 25px'};
    }

    &:last-child {
      padding: ${({ padding }) => padding || '15px 66px 15px 32px'};
    }
  }
`

export const StyledTableRow = styled<React.FC<{ shouldDisplayActionBtn?: boolean }>>(
  Box,
  TableRowConfig
)`
  display: grid;
  grid-template-columns: 114px [sample-ID]215px [manufacturer]186px [address-of-manufacturer]220px [sample-notes]240px [drug-class]212px [dosage]212px [units]150px [total-value]160px [apis]390px [excipients]390px [expiration-date]179px [reistration-status]200px [action] ${({
      shouldDisplayActionBtn,
    }) => (shouldDisplayActionBtn ? '264px' : '150px')};
`

export const StyledTableTasksRow = styled<React.FC<ITableTaskRowProps>>(Box, TableTasksRowConfig)`
  display: grid;
  grid-template-columns: ${({ isAwaitingContentType, isHasToxicity, isHasTemperature }) =>
    isAwaitingContentType
      ? `[task-ID]147px [test-type]155px [sample-ID]215px${
          isHasToxicity ? ' [toxicity]135px' : ''
        }${
          isHasTemperature ? ' [temperature]233px ' : ' '
        }[manufacturer]184px [task-notes]240px [drug-class]181px [dosage]168px [units]150px [apis]390px [excipients]390px [expiration-date]179px [action]154px`
      : `[task-ID]124px [test-type]155px [result]190px [sample-ID]215px${
          isHasToxicity ? ' [toxicity]135px' : ''
        }${
          isHasTemperature ? ' [temperature]233px ' : ' '
        }[testing-date]172px [APIs-percentage]173px [manufacturer]184px [task-notes]252px [drug-class]181px [dosage]168px [units]150px [apis]390px [excipients]390px [expiration-date]179px [action]151px`};
`

export const StyledTableReportsRow = styled<React.FC<{ isAwaitingContentType?: boolean }>>(
  Box,
  TableTasksRowConfig
)`
  display: grid;
  grid-template-columns: [checkbox]120px [sample-ID]215px [pharmacy-name]165px [manufacturer]184px [notes]237px [drug-class]181px [dosage]140px [units]155px [value]160px [apis]390px [excipients]390px [last-updated]395px [action]154px;
`

export const StyledRequestsTableRow = styled<React.FC<TableRequestsRowProps>>(
  Box,
  TableTasksRowConfig
)`
  display: grid;
  grid-template-columns: ${({ contentType, isSwitchedOnTesting }) =>
    contentType === 'incoming' && isSwitchedOnTesting
      ? '[checkbox]80px [sample-ID]215px [test-type]155px [from-laboratory]191px [manufacturer]184px [sample-notes]236px [drug-class]181px [dosage]140px [units]123px [apis]390px [excipients]390px [expiration-date]246px [sender]246px [action]355px'
      : contentType === 'incoming' && !isSwitchedOnTesting
      ? '[checkbox]60px [sample-ID]215px  [from-laboratory]191px [manufacturer]184px [pharmacy-name]185px [sample-notes]236px [drug-class]181px [dosage]140px [units]123px [apis]390px [excipients]390px [sender]246px [action]355px'
      : contentType === 'outgoing' && isSwitchedOnTesting
      ? '[sample-ID]235px [test-type]155px [to-laboratory]191px [request-status]180px [manufacturer]184px [sample-notes]236px [drug-class]181px [dosage]140px [units]123px [apis]390px [excipients]390px [expiration-date]246px [action]265px'
      : contentType === 'outgoing' && !isSwitchedOnTesting
      ? '[sample-ID]235px  [to-laboratory]191px [purchase-status]165px [manufacturer]184px [pharmacy-name]185px [sample-notes]236px [drug-class]181px [dosage]140px [units]123px [apis]390px [excipients]390px  [action]265px'
      : '[checkbox]80px [sample-ID]215px [current-location]191px [manufacturer]184px [sample-notes]236px [drug-class]181px [dosage]140px [units]123px [apis]390px [excipients]390px [expiration-date]246px [action]264px'};
`

export const StyledTableContactsLabRow = styled(Box)`
  display: grid;
  grid-template-columns: [laboratory-name]274px [address]263px [default-manager]243px [group]193px [types-of-testing]166px [delivery-services]243px;
`

export const StyledTableListOfUsersRow = styled(Box)`
  display: grid;
  grid-template-columns: [user-name]330px [avatar]120px [role]210px [phone-number]200px [email]252px [time-location]150px [action]140px [stub]auto;
`

export const SubText = styled('span')`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #8f929b;
`

export const StyledTableSampleCell = styled<React.FC<TableCellProps>>(Box, TableCellConfig)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: ${({ $isMobile }) => ($isMobile ? 'wrap' : 'nowrap')};

  padding: ${({ $isMobile, $isRequestContent }) =>
    $isMobile ? '' : $isRequestContent ? '8px 0' : '16px 0'};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ hideBorder, $isMobile, theme }) =>
    hideBorder || $isMobile ? 'transparent' : `4px solid ${theme.palette.background.default}`};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};

  &:nth-child(1) {
    position: relative;
    padding: ${({ padding, $isMobile, $isRequestContent }) =>
      $isMobile || $isRequestContent ? '' : padding ? padding : '16px 66px 16px 40px'};
    border-top-left-radius: ${({ header }) => (header ? '10px' : 'none')};
    padding-left: ${({ $plFirstChild }) => $plFirstChild};

    &::before {
      content: '';
      display: ${({ header, isLastSample }) => (header || isLastSample ? 'block' : 'none')};
      position: absolute;
      top: ${({ header }) => (header ? '0px' : '')};
      bottom: ${({ header }) => (header ? '' : '0px')};
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: ${({ header }) => (header ? ' 10px 10px 0 0' : '10px 0 0 10px')};
      border-color: ${({ theme, header }) =>
        header
          ? `${theme.palette.background.default} transparent
        transparent transparent`
          : `transparent transparent transparent ${theme.palette.background.default} `};
    }

    &::after {
      content: '';
      display: ${({ header, isLastSample }) => (header || isLastSample ? 'block' : 'none')};
      position: absolute;
      top: ${({ header }) => (header ? '0.8px' : '')};
      bottom: ${({ header }) => (header ? '' : '0px')};
      left: ${({ header }) => (header ? '1px' : '0.6px')};
      width: 15px;
      height: 15px;
      border-radius: 60%;
      background-color: #ffffff;
    }

    &::before {
      content: '';
      display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 0 10px;
      border-color: transparent transparent transparent
        ${({ theme }) => theme.palette.background.default};
    }

    &::after {
      content: '';
      display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
      position: absolute;
      bottom: 0;
      left: 0.6px;
      width: 15px;
      height: 15px;
      border-radius: 60%;
      background-color: #ffffff;
    }

    padding: ${({ padding, $isMobile, $isRequestContent }) =>
      padding ? padding : $isMobile || $isRequestContent ? '' : '15px 66px 15px 40px'};
  }
`

export const FixedTableCell = styled<React.FC<TableCellProps>>(Box, TableCellConfig)`
  position: sticky;
  padding: ${({ padding, $isMobile }) => (padding ? padding : $isMobile ? '' : '16px 30px')};

  display: flex;
  align-items: ${({ $alignItems }) => ($alignItems ? $alignItems : 'flex-start')};
  justify-content: ${({ $justifyContent }) => ($justifyContent ? $justifyContent : 'center')};
  right: 0;
  background-color: ${({ theme, bgColor, isPackSelected }) =>
    isPackSelected ? theme.primary[30] : bgColor || theme.palette.common.white};
  border-bottom: 4px solid
    ${({ hideBorder, $isMobile, theme }) =>
      hideBorder || $isMobile ? 'transparent' : theme.palette.background.default};

  cursor: auto;

  &:first-child {
    border-radius: 10px 0 0 10px;
  }

  box-shadow: ${({ $isMobile }) =>
    $isMobile ? 'transparent' : '-15px 0 15px -5px rgba(53, 108, 151, 0.12)'};
  border-top-left-radius: ${({ header }) => (header ? '10px' : '0')};
  border-bottom-left-radius: ${({ isLastSample }) => (isLastSample ? '10px' : '0')};

  &::before {
    content: '';
    display: ${({ header, isLastSample }) => (header || isLastSample ? 'block' : 'none')};
    position: absolute;
    top: ${({ header }) => (header ? '0' : '')};
    bottom: ${({ header }) => (header ? '' : '0')};
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${({ header }) => (header ? '0 10px 10px 0' : '0 0 10px 10px')};
    border-color: ${({ theme, header }) =>
      header
        ? `transparent ${theme.palette.background.default} transparent`
        : `transparent transparent ${theme.palette.background.default}
        transparent`};
  }

  &::after {
    content: '';
    display: ${({ header, isLastSample }) => (header || isLastSample ? 'block' : 'none')};
    position: absolute;
    bottom: 0.8px;
    right: 0.6px;
    width: 15px;
    height: 15px;
    border-radius: 60%;
    background-color: ${({ theme, isPackSelected }) =>
      isPackSelected ? theme.primary[30] : '#ffffff'};
  }
  box-shadow: ${({ $isMobile }) =>
    $isMobile ? 'transparent' : '-15px 0 15px -5px rgba(53, 108, 151, 0.12)'};
`

export const StyledTextCell = styled<React.FC<StyledTextCellProps>>(Typography, TableCellConfig)`
  width: 100%;
  margin-right: ${({ $isMobile, mr }) => ($isMobile ? '0' : mr ? mr : '48px')};
  overflow: hidden;
  word-break: break-word;
`

export const StyledTableBox = styled<React.FC<{ $background?: boolean } & BoxProps>>(Box)`
  margin-top: -12px;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: ${({ theme, $background }) => !$background && theme.palette.common.white};
`

interface DataBoxProps extends BoxProps {
  $alignSelf?: string
  $isMobile?: boolean
}

export const DataBox = styled<React.FC<DataBoxProps>>(Box)`
  width: ${({ $isMobile }) => ($isMobile ? '100%' : '')};
  display: flex;
  align-self: ${({ $alignSelf }) => ($alignSelf ? $alignSelf : 'flex-start')};

  position: relative;
  &:hover .popper::before {
    display: block;
  }

  &:hover .MuiPaper-elevation {
    display: block;
  }
`

export const DataIcon = styled('img')`
  width: 18px;
  height: 18px;
  margin-left: 5px;
`

export const StyledCupLabel = styled('span')`
  color: #8f929b;
  padding: 16px 34px 0 0;
`

// * [HR
export const StyledHrTableTasksRow = styled<React.FC<ITableTaskRowProps>>(Box, TableTasksRowConfig)`
  display: grid;
  grid-template-columns: ${({ isAwaitingContentType, $isBigDesktop }) =>
    isAwaitingContentType
      ? `[photo]137px [test-type]163px [sample-ID]215px [sample-name]250px [task-notes]236px [physical-form-of-sample]243px [weight]160px [expected-main-substance]254px [expected-other-substance]254px [action]154px`
      : `[test-type]200px [sample-ID]215px [sample-name]250px [task-notes]236px [results]378px [testing-dates] 215px `};
`
interface StyledHrTableProps {
  $isBigDesktop: boolean
}

export const StyledHrTableReportsRow = styled<React.FC<StyledHrTableProps>>(Box)`
  display: grid;
  grid-template-columns: [photo]140px [sample-ID]183px [sample-name]200px [screening-result]380px [assay-result]380px [collection-date]180px [collection-location] 200px;
`

export const StyledHrTableHrSampleDetailsRow = styled<React.FC<StyledHrTableProps>>(Box)`
  display: grid;
  grid-template-columns: [test-type]194px [test-results]373px [test-location]196px [testing-date]153px [task-notes]232px [attachments] ${({
      $isBigDesktop,
    }) => ($isBigDesktop ? 'auto' : '303px')};
`

export const StyledSampleImage = styled('img')`
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 4px;
`

interface HrSamplesInPackageRowProps {
  contentType?: string
}

export const StyledHrSamplesInPackageTableRow = styled<React.FC<HrSamplesInPackageRowProps>>(Box)`
  display: grid;
  grid-template-columns: []115px [photo]137px [sample-ID]215px [sample-name]250px [screening-result]380px [assay-result]380px [sample-notes]240px[physical-form-of-sample]243px [weight]160px [action]154px;
`
// * HR]
