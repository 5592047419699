import * as React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import KeyboardArrowUpIcon from '@assets/icons/sort-up.svg'
import KeyboardArrowDownIcon from '@assets/icons/sort-down.svg'
import { ISample } from '@models/samples'
import { TooltipMobile } from '@components/shared/Tooltip/components'
import { MobileRow, StyledCardBox } from '@styles'
import { ActionCell } from '@components/modules/PackageDetails/PackageDetailsContent'
import { SpecificId } from '@components/shared/sampleFields'

interface MobileCellProps {
  isMobile: boolean
  sample: ISample
  actionBtnText?: 'Remove' | 'Missed'
  shouldDisplayActionBtn?: boolean
  shouldDisplayMissedText?: boolean
  shouldOpenCard: (e: React.SyntheticEvent<Element, Event>) => void
  openCard: boolean
}

export const MobileCell: React.FC<MobileCellProps> = props => {
  const {
    isMobile,
    sample,
    actionBtnText,
    shouldDisplayActionBtn,
    shouldDisplayMissedText,
    openCard,
    shouldOpenCard,
  } = props

  return (
    <>
      <MobileRow>
        <StyledCardBox onClick={shouldOpenCard}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <IconButton sx={{ width: '24px', height: '24px' }}>
              {openCard ? (
                <img src={KeyboardArrowUpIcon} alt='up' />
              ) : (
                <img src={KeyboardArrowDownIcon} alt='down' />
              )}
            </IconButton>

            <Typography variant='h5' sx={{ display: 'inline-flex' }} ml={1}>
              Sample ID
              <span style={{ fontWeight: 'normal', display: 'inline-blok', marginLeft: '4px' }}>
                <SpecificId
                  specificId={sample.specific_id}
                  truncatedLength={5}
                  laboratory={sample.laboratory}
                />
              </span>
            </Typography>

            {sample.missed && (
              <TooltipMobile
                id='not-expand'
                title='This sample is missed'
                placement='top-start'
                mlIcon='4px'
                withoutTruncateIcon
                width='25px'
              >
                <></>
              </TooltipMobile>
            )}
          </Box>

          <ActionCell
            isMobile={isMobile}
            sample={sample}
            actionBtnText={actionBtnText}
            shouldDisplayActionBtn={shouldDisplayActionBtn}
            shouldDisplayMissedText={shouldDisplayMissedText}
          />
        </StyledCardBox>
      </MobileRow>
    </>
  )
}
