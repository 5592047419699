import { StyledInnerTableCell } from './styles'

export const TableCellWrapper: React.FC<{
  isEditing: boolean
  stylesEditForm?: React.CSSProperties
  stylesCreateForm?: React.CSSProperties
  [key: string]: any
}> = ({ isEditing, children, stylesEditForm, stylesCreateForm, ...props }) => {
  return isEditing ? (
    <div style={stylesEditForm} {...props}>
      {children}
    </div>
  ) : (
    <StyledInnerTableCell style={stylesCreateForm} {...props}>
      {children}
    </StyledInnerTableCell>
  )
}
