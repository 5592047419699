import { styled, MenuItem, Typography } from '@mui/material'

import { AutocompleteWrapper } from '@components/shared'
import { AutocompleteWrapperProps } from '@components/shared/AutocompleteWrapper/AutocompleteWrapper'

interface StyledAutocompleteWrapperProps extends AutocompleteWrapperProps {
  $left?: string
}

export const StyledAutocompleteWrapper = styled<React.FC<StyledAutocompleteWrapperProps>>(
  AutocompleteWrapper
)`
  & .MuiInputBase-adornedStart .MuiInputAdornment-positionStart {
    position: absolute;
    left: ${({ $left }) => $left};
  }
`

export const StyledMenuItem = styled(MenuItem)`
  padding-left: 26px;
`

export const StyledName = styled(Typography)`
  display: inline-block;

  color: ${({ theme }) => theme.palette.text.primary};
`

export const StyledRole = styled(Typography)`
  margin-left: auto;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.palette.secondary.light};
`

export const StyledIcon = styled('img')`
  margin-right: 15px;
`
