import {
  ICreateSampleTable,
  IDetailsSampleBasicTable,
  IDetailsSamplesTab,
  IDetailsSampleTestingTable,
  ISamplesTab,
  ISampleTasksTable,
  IViewSampleTableAwaiting,
  IViewSampleTableCompleted,
  IViewSampleTableInsidePackage,
  IDetailsTaskBasicTable,
  IDetailsTaskTestingTable,
  IViewSampleInModalTable,
} from '@models/samples'
import { IPackagesTab } from '@models/packages'
import { ITasksTab } from '@models/tasks'
import { AttachmentSection, IModalContentTypeValue } from '@models/attachments'
import { IViewReportsTable } from '@models/testingReports'
import { IRequestsTab, IViewRequestsTable } from '@models/requests'
import { IYourWorkspaceTab } from '@models/yourWorkspace'
import toxicIcon from '@assets/icons/toxic.svg'
import tempSensitiveIcon from '@assets/icons/temp-sensitive.svg'
import toxicMobileIcon from '@assets/icons/toxic-mobile.svg'
import tempSensitiveMobileIcon from '@assets/icons/temp-sensitive-mobile.svg'
import { TOXICITY, TEMPERATURE } from '@constants/tasks'
import { LCMS, NEARIR, VISUAL_INSPECTION } from '@models/tests'

import {
  SORT_BY_COLLECTION_DATE,
  SORT_BY_COLLECTION_LOCATION,
  SORT_BY_EXPIRATION_DATE,
  SORT_BY_LABORATORY,
  SORT_BY_LAST_UPDATED,
  SORT_BY_MANUFACTURER,
  SORT_BY_RESULT,
  SORT_BY_SPECIFIC_ID,
  SORT_BY_TEST_TYPE,
  SORT_BY_TESTING_DATE,
  SORT_BY_SAMPLE_NAME_FIELD,
  SORT_BY_SAMPLE_NAME,
  SORT_BY_PHYSICAL_FORM,
  SORT_BY_WEIGHT,
} from './common'
import {
  ATTACHMENT_MATERIAL_SECTION,
  ATTACHMENT_MATERIAL_TITLE,
  ATTACHMENT_PHOTO_OF_SAMPLE_SECTION,
  ATTACHMENT_PHOTO_OF_SAMPLE_TITLE,
  ATTACHMENT_STORAGE_SECTION,
  ATTACHMENT_STORAGE_TITLE,
  ATTACHMENT_TEST_MINILAB_TITLE,
} from './tests'
import { TEMPERATURE_NAME, TOXIC_NAME } from './samples'

// * [Samples

export const sampleTabs: ISamplesTab[] = [
  { label: 'Active', value: 'active' },
  { label: 'Archive', value: 'archive' },
]

// sample_columns customize table
export const viewSampleTableWidths = [
  { id: 1, width: '150px' },
  { id: 2, width: '170px' },
  { id: 3, width: '240px' },
  { id: 4, width: '212px' },
  { id: 5, width: '390px' },
  { id: 6, width: '390px' },
  { id: 7, width: '212px' },
  { id: 8, width: '179px' },
  { id: 9, width: '131px' },
  { id: 10, width: '131px' },
  { id: 11, width: '200px' },
]

// projects_columns customize table
export const viewProjectTableWidths = [
  { id: 1, width: '250px' },
  { id: 2, width: '250px' },
]

export const hrViewSampleTableWidths = [
  { id: 1, width: '133px' }, //Photo
  { id: 2, width: '185px' }, //Sample ID
  { id: 3, width: '250px' }, //Sample name
  { id: 4, width: '190px' }, //Submitter code
  { id: 5, width: '272px' }, //Expected main substance(s)
  { id: 6, width: '256px' }, //Sample notes
  { id: 7, width: '249px' }, //Physical form of sample
  { id: 8, width: '186px' }, //Weight
  { id: 9, width: '179px' }, //Collection date
  { id: 10, width: '183px' }, //Collection location
  { id: 11, width: '131px' }, //Package ID
]
// * samples columns names and ids
// { component: Units, id: 1 },
// { component: Manufacturer, id: 2 },
// { component: Notes, id: 3 },
// { component: DrugClass, id: 4 },
// { component: APIsCell, id: 5 },
// { component: ExcipientsCell, id: 6 },
// { component: Dosage, id: 7 },
// { component: ExpirationDateCell, id: 8 },
// { component: Destination, id: 9 },
// { component: PackageID, id: 10 },

export const viewAdditionalTasksWidths = [
  { id: 1, width: '160px' },
  { id: 2, width: '167px' },
  { id: 3, width: '180px' },
  { id: 4, width: '253px' },
  { id: 5, width: '500px' },
  { id: 6, width: '224px' },
  { id: 7, width: '241px' },
  { id: 8, width: '243px' },
]
export const hrViewAdditionalTasksWidths = [
  { id: 1, width: '180px' }, // Status
  { id: 2, width: '170px' }, // Test type
  { id: 3, width: '460px' }, // Lab assistant
  { id: 4, width: '160px' }, // Assigned at
  { id: 5, width: '190px' }, // Task notes
]

export const viewSampleInModalTable: IViewSampleInModalTable = {
  tableHeadCells: [
    { id: 1, content: 'Sample ID' },
    { id: 2, content: 'Manufacturer name' },
    { id: 3, content: 'APIs' },
    { id: 4, content: 'Expiration date' },
  ],
}

// * [HR
export const viewHrSampleInModalTable = [
  { id: 1, content: 'Photo' },
  { id: 2, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
  { id: 3, content: 'Sample name', ordering: SORT_BY_SAMPLE_NAME, infoIcon: true },
  { id: 4, content: 'Physical form of sample', ordering: SORT_BY_PHYSICAL_FORM },
  { id: 5, content: 'Weight', ordering: SORT_BY_WEIGHT },
]
// * HR]

export const viewSampleTableInsidePackage: IViewSampleTableInsidePackage = {
  tableHeadCells: [
    { id: 1, content: '#' },
    { id: 2, content: 'Sample ID' },
    { id: 3, content: 'Manufacturer name' },
    { id: 4, content: 'Address of manufacturer' },
    { id: 5, content: 'Sample notes' },
    { id: 6, content: 'Drug class' },
    { id: 7, content: 'Dosage' },
    { id: 8, content: 'Units' },
    { id: 9, content: 'Total value' },
    { id: 10, content: 'APIs' },
    { id: 11, content: 'Excipients' },
    { id: 12, content: 'Expiration date' },
    { id: 13, content: 'Regulatory regist. status' },
  ],
}

// * [HR
export const viewHrSampleTableInsidePackage = [
  { id: 1, content: '#' },
  { id: 2, content: 'Photo' },
  { id: 3, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
  { id: 4, content: 'Sample name', ordering: SORT_BY_SAMPLE_NAME, infoIcon: true },
  { id: 5, content: 'Screening result(s)' },
  { id: 6, content: 'Assay result(s)' },
  { id: 7, content: 'Sample notes' },
  { id: 8, content: 'Physical form of sample', ordering: SORT_BY_PHYSICAL_FORM },
  { id: 9, content: 'Weight', ordering: SORT_BY_WEIGHT },
]
// * HR]

export const createSampleTable: ICreateSampleTable = {
  tableHeadCells: [
    { id: 1, content: '#' },
    { id: 3, content: 'Drug class', isRequired: true },
    { id: 4, content: 'Dosage', isRequired: true },
    { id: 5, content: 'Units', isRequired: true },
    { id: 6, content: 'Value' },
    { id: 7, content: 'Main APIs', isRequired: true },
    { id: 8, content: 'APIs' },
    { id: 9, content: 'Sample Notes' },
    { id: 10, content: 'Lot' },
    { id: 11, content: "Buyer's name" },
    { id: 12, content: 'Purchase date' },
    { id: 13, content: 'Town purchased' },
    { id: 14, content: 'Pharmacy name' },
    { id: 15, content: 'Pharmacy GPS', hasTooltip: true },
    { id: 16, content: 'Manufacturer name' },
    { id: 17, content: 'Address of manufacturer' },
    { id: 18, content: 'Brand' },
    { id: 19, content: 'Manufacture date' },
    { id: 20, content: 'Expiration date' },
    { id: 21, content: 'Regulatory regist. status' },
    { id: 22, content: 'Country of origin' },
  ],
}

export const headerCellsData = [
  { cellName: '#', width: 60 },
  { cellName: 'Drug class', width: 286, isRequired: true },
  { cellName: 'Dosage', width: 286, isRequired: true },
  { cellName: 'Units', width: 324, isRequired: true },
  { cellName: 'Value', width: 286 },
  { cellName: 'Main APIs', width: 286, isRequired: true },
  { cellName: 'Tests', width: 286 },
  { cellName: 'Excipients', width: 286 },
  { cellName: 'Sample type', width: 220 },
  { cellName: 'Toxicity', width: 286 },
  { cellName: 'Storage temperature', width: 320 },
  { cellName: 'Storage notes', width: 286 },
  { cellName: 'Storage documents', width: 280 },
  { cellName: 'Sample Notes', width: 286 },
  { cellName: 'Lot', width: 286 },
  { cellName: "Buyer's name", width: 286 },
  { cellName: 'Purchase date', width: 200 },
  { cellName: 'Town purchased', width: 286 },
  { cellName: 'Pharmacy name', width: 286 },
  {
    cellName: 'Pharmacy GPS',
    width: 286,
    tooltip: 'Type address or coordinates Ex.: -77.508333° or 77°30.5`S or 77°30`29.9988``S',
  },
  { cellName: 'Manufacturer name', width: 286 },
  { cellName: 'Address of manufacturer', width: 536 },
  { cellName: 'Brand', width: 286 },
  { cellName: 'Manufacture date', width: 210 },
  { cellName: 'Expiration date', width: 210 },
  { cellName: 'Regulatory regist. status', width: 300 },
  { cellName: 'Country of origin', width: 286 },
  { cellName: 'Project', width: 286 },
  { cellName: 'Funding', width: 286 },
]

// * Samples]

// * [Sample Details

export const sampleDetailsTabs: IDetailsSamplesTab[] = [
  { label: 'Basic info', value: 'basic info' },
  { label: 'Testing info', value: 'testing info' },
]

export const detailsSampleBasicTable: IDetailsSampleBasicTable = {
  tableHeadCells: [
    { id: 1, content: 'Task ID' },
    { id: 2, content: 'Task status' },
    { id: 3, content: 'Location' },
    { id: 4, content: 'Test type' },
    { id: 5, content: 'Serial number of equipment' },
  ],
}

export const detailsSampleTestingTable: IDetailsSampleTestingTable = {
  tableHeadCells: [
    { id: 1, content: 'Testing date' },
    { id: 2, content: 'Task assignee' },
    { id: 3, content: 'APIs' },
    { id: 4, content: 'Task result' },
    { id: 5, content: 'Task notes' },
    { id: 6, content: 'Attachments' },
  ],
}

// * Samples Details]

// * [Task Details

export const detailsTaskBasicTable: IDetailsTaskBasicTable = {
  tableHeadCells: [
    { id: 1, content: 'Task ID' },
    { id: 2, content: 'Test type' },
    { id: 3, content: 'Serial number of equipment' },
    { id: 4, content: 'Task status' },
  ],
}

export const detailsTaskTestingTable: IDetailsTaskTestingTable = {
  tableHeadCells: [
    { id: 1, content: 'Testing date' },
    { id: 2, content: 'APIs' },
    { id: 3, content: 'Result' },
    { id: 4, content: 'Task notes' },
    { id: 5, content: 'Attachments' },
  ],
}

// * Task Details]

// * [Sample Tasks

export const sampleTasksTable: ISampleTasksTable = {
  tableHeadCells: [
    { id: 1, content: 'Task' },
    { id: 2, content: 'Task status' },
    { id: 3, content: 'Task result' },
    { id: 4, content: 'APIs' },
    { id: 5, content: 'Test type' },
    { id: 6, content: 'Task assignee' },
    { id: 7, content: 'Attachments' },
    { id: 8, content: 'Task notes' },
    { id: 9, content: 'Assignee date' },
  ],
}
// * Samples Tasks]

// * [Packages

export const packageTabs: IPackagesTab[] = [
  { label: 'Your packages', value: 'packages' },
  { label: 'Incoming packages', value: 'incoming packages' },
  { label: 'Outgoing packages', value: 'outgoing packages' },
]

export const packageTableColumnHeaders = [
  { id: 1, content: 'Package ID', ordering: SORT_BY_SPECIFIC_ID },
  { id: 2, content: 'Destination' },
  { id: 3, content: 'Delivery status' },
  { id: 4, content: 'Quantity' },
  { id: 5, content: 'Package notes' },
  { id: 6, content: 'Delivery date' },
  { id: 7, content: 'Last update' },
]

export const incomingPackageTableColumnHeaders = [
  { id: 1, content: 'Package ID', ordering: SORT_BY_SPECIFIC_ID },
  { id: 2, content: 'From laboratory' },
  { id: 3, content: 'Delivery status' },
  { id: 4, content: 'Quantity' },
  { id: 5, content: 'Delivery service' },
  { id: 6, content: 'Tracking number' },
  { id: 7, content: 'Package notes' },
  { id: 8, content: 'Package type' },
  { id: 9, content: 'Delivery date' },
  { id: 10, content: 'Last update' },
]

export const outgoingPackageTableColumnHeaders = [
  { id: 1, content: 'Package ID', ordering: SORT_BY_SPECIFIC_ID },
  { id: 2, content: 'Destination' },
  { id: 3, content: 'Delivery status' },
  { id: 4, content: 'Quantity' },
  { id: 5, content: 'Delivery service' },
  { id: 6, content: 'Tracking number' },
  { id: 7, content: 'Package notes' },
  { id: 8, content: 'Package type' },
  { id: 9, content: 'Delivery date' },
  { id: 10, content: 'Last update' },
]

// * Packages]

// * [Packages

export const tasksTabs: ITasksTab[] = [
  { label: 'Awaiting', value: 'awaiting' },
  { label: 'Completed', value: 'completed' },
]

export const TasksTableColumnHeaders = [
  { id: 1, content: 'Package ID', ordering: SORT_BY_SPECIFIC_ID },
  { id: 2, content: '' },
  { id: 3, content: '' },
  { id: 4, content: '' },
  { id: 5, content: '' },
  { id: 6, content: '' },
  { id: 7, content: 'Action' },
]

// * Packages]

// * [List of tasks

export const viewSampleTableAwaiting: IViewSampleTableAwaiting = {
  tableHeadCells: [
    { id: 1, content: 'Task ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 2, content: 'Test type', ordering: SORT_BY_TEST_TYPE },
    { id: 3, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 4, content: TOXICITY },
    { id: 5, content: TEMPERATURE },
    { id: 6, content: 'Manufacturer name' },
    { id: 7, content: 'Task notes' },
    { id: 8, content: 'Drug class' },
    { id: 9, content: 'Dosage' },
    { id: 10, content: 'Units' },
    { id: 11, content: 'APIs' },
    { id: 12, content: 'Excipients' },
    { id: 13, content: 'Expiration date', ordering: SORT_BY_EXPIRATION_DATE },
  ],
}

export const viewSampleTableCompleted: IViewSampleTableCompleted = {
  tableHeadCells: [
    { id: 1, content: 'Task ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 2, content: 'Test type', ordering: SORT_BY_TEST_TYPE },
    { id: 3, content: 'Result', ordering: SORT_BY_RESULT },
    { id: 4, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 5, content: TOXICITY },
    { id: 6, content: TEMPERATURE },
    { id: 7, content: 'APIs' },
    { id: 8, content: 'Testing date' },
    { id: 9, content: 'Manufacturer name' },
    { id: 10, content: 'Task notes' },
    { id: 11, content: 'Drug class' },
    { id: 12, content: 'Dosage' },
    { id: 13, content: 'Units' },
    { id: 14, content: 'APIs' },
    { id: 15, content: 'Excipients' },
    { id: 16, content: 'Expiration date', ordering: SORT_BY_EXPIRATION_DATE },
  ],
}
// * [HR
export const hRViewSampleTableAwaiting: IViewSampleTableAwaiting = {
  tableHeadCells: [
    { id: 1, content: 'Photo' },
    { id: 2, content: 'Test type', ordering: SORT_BY_TEST_TYPE },
    { id: 3, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 4, content: 'Sample name' },
    { id: 5, content: 'Task notes' },
    { id: 6, content: 'Physical form of sample' },
    { id: 7, content: 'Weight' },
    { id: 8, content: 'Expected main substance(s)' },
    { id: 9, content: 'Expected other substance(s)' },
  ],
}

export const hRViewSampleTableCompleted: IViewSampleTableCompleted = {
  tableHeadCells: [
    { id: 1, content: 'Test type', ordering: SORT_BY_TEST_TYPE },
    { id: 2, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 3, content: 'Sample name', ordering: SORT_BY_SAMPLE_NAME_FIELD },
    { id: 4, content: 'Task notes' },
    { id: 5, content: 'Result(s)' },
    { id: 6, content: 'Testing date', ordering: SORT_BY_TESTING_DATE },
  ],
}
// * HR]

// * List of tasks]

// * [Attachments
export interface IDataModalsContent {
  id: number
  sectionTitle: string
  section: AttachmentSection
  isWithoutLink?: boolean
}

export type IDataModalsContents = IDataModalsContent[]
interface IDataModals {
  // TODO
  modalTitle: string
  modalSubTitle: string
  content:
    | IDataModalsContents
    | {
        PAD: IDataModalsContents
        MINILAB: IDataModalsContents
        HPLC: IDataModalsContents
        [LCMS]: IDataModalsContents
        [NEARIR]: IDataModalsContents
        [VISUAL_INSPECTION]: IDataModalsContents
      }
  contentType: IModalContentTypeValue
}

interface IPhotoDataModal {
  modalTitle: string
  modalSubTitle: string
  content: IDataModalsContent[]
  contentType: IModalContentTypeValue
}

export const dataModals: {
  materials: IDataModals
  results: IDataModals
  storage: IDataModals
  photo: IPhotoDataModal
  package_storage: IDataModals
} = {
  photo: {
    modalTitle: 'Attach materials',
    modalSubTitle: 'Please add photo of sample (only 1)',
    contentType: 'sample',
    content: [
      {
        id: 1,
        sectionTitle: '',
        section: {
          key: ATTACHMENT_PHOTO_OF_SAMPLE_SECTION,
          value: ATTACHMENT_PHOTO_OF_SAMPLE_TITLE,
        },
        isWithoutLink: true,
      },
    ],
  },
  materials: {
    modalTitle: 'Attach materials',
    modalSubTitle: 'Please attach your analysis results here.',
    contentType: 'package',
    content: [
      {
        id: 1,
        sectionTitle: '',
        section: { key: ATTACHMENT_MATERIAL_SECTION, value: ATTACHMENT_MATERIAL_TITLE },
      },
    ],
  },
  storage: {
    modalTitle: 'Attach materials',
    modalSubTitle: 'Please attach storage documents here (3 max)',
    contentType: 'sample',
    content: [
      {
        id: 1,
        sectionTitle: '',
        section: { key: ATTACHMENT_STORAGE_SECTION, value: ATTACHMENT_STORAGE_TITLE },
      },
    ],
  },
  package_storage: {
    modalTitle: 'Attach materials',
    modalSubTitle: 'Please attach storage documents here (3 max)',
    contentType: 'package',
    content: [
      {
        id: 1,
        sectionTitle: '',
        section: { key: ATTACHMENT_STORAGE_SECTION, value: ATTACHMENT_STORAGE_TITLE },
      },
    ],
  },
  results: {
    modalTitle: 'Attach results',
    modalSubTitle: 'Please attach your analysis results here',
    contentType: 'task',
    content: {
      PAD: [
        {
          id: 1,
          sectionTitle: '1. Test report',
          section: { key: 'test_report', value: 'Material' },
        },
        {
          id: 2,
          sectionTitle: '2. Photo of sample',
          section: { key: 'photo_of_sample', value: 'Material' },
        },
      ],
      [NEARIR]: [
        {
          id: 1,
          sectionTitle: '1. Test report',
          section: { key: 'test_report', value: 'Material' },
        },
        {
          id: 2,
          sectionTitle: '2. Photo of sample',
          section: { key: 'photo_of_sample', value: 'Material' },
        },
      ],
      MINILAB: [
        {
          id: 1,
          sectionTitle: '1. Test report',
          section: { key: 'test_report', value: 'Material' },
        },
        {
          id: 2,
          sectionTitle: `2. ${ATTACHMENT_TEST_MINILAB_TITLE}`,
          section: { key: 'photo_of_sample', value: 'Material' },
        },
      ],
      HPLC: [
        {
          id: 1,
          sectionTitle: '1. Test report',
          section: { key: 'test_report', value: 'Material' },
        },
        {
          id: 2,
          sectionTitle: '2. Photo of sample',
          section: { key: 'photo_of_sample', value: 'Material' },
        },
        {
          id: 3,
          sectionTitle: '3. Equipment numbers, control chart, and system suitability',
          section: { key: 'equipment_number', value: 'Material' },
        },
      ],
      [LCMS]: [
        {
          id: 1,
          sectionTitle: '1. Test report',
          section: { key: 'test_report', value: 'Material' },
        },
        {
          id: 2,
          sectionTitle: '2. Photo of sample',
          section: { key: 'photo_of_sample', value: 'Material' },
        },
        {
          id: 3,
          sectionTitle: '3. Equipment numbers, control chart, and system suitability',
          section: { key: 'equipment_number', value: 'Material' },
        },
      ],
      [VISUAL_INSPECTION]: [
        {
          id: 1,
          sectionTitle: '1. Photo of sample',
          section: { key: 'photo_of_sample', value: 'Material' },
        },
      ],
    },
  },
}

// * Attachments]

// * [Testing reports

export const viewReportsTable: IViewReportsTable = {
  tableHeadCells: [
    { id: 1, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 3, content: 'Pharmacy name' },
    { id: 4, content: 'Manufacturer name' },
    { id: 5, content: 'Sample notes' },
    { id: 6, content: 'Drug class' },
    { id: 7, content: 'Dosage' },
    { id: 8, content: 'Units' },
    { id: 9, content: 'Total value' },
    { id: 10, content: 'APIs' },
    { id: 11, content: 'Excipients' },
    { id: 12, content: 'Last updated', ordering: SORT_BY_LAST_UPDATED },
  ],
}

export const filterTestLocation = [
  {
    value: 'placeholder',
    label: 'Test location',
  },
  {
    value: 'clear',
    label: 'Clear all',
  },
]

export const filterPharmacy = [
  {
    value: 'placeholder',
    label: 'Pharmacy',
  },
  {
    value: 'clear',
    label: 'Clear all',
  },
]

export const reportsTasksTable: ISampleTasksTable = {
  tableHeadCells: [
    { id: 1, content: 'Task' },
    { id: 2, content: 'Task status' },
    { id: 3, content: 'Task result' },
    { id: 4, content: 'APIs' },
    { id: 5, content: 'Test type' },
    { id: 6, content: 'Location' },
    { id: 7, content: 'Test notes' },
    { id: 8, content: 'Testing date' },
    { id: 9, content: 'Attachments' },
  ],
}

// * Testing reports]

// * [Requests

export const requestTableColumnHeaders: IViewRequestsTable = {
  tableHeadCells: [
    { id: 1, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 2, content: 'Current location' },
    { id: 3, content: 'Manufacturer name', ordering: SORT_BY_MANUFACTURER },
    { id: 4, content: 'Sample notes' },
    { id: 5, content: 'Drug class' },
    { id: 6, content: 'Dosage' },
    { id: 7, content: 'Units' },
    { id: 8, content: 'APIs' },
    { id: 9, content: 'Excipients' },
    { id: 10, content: 'Expiration date', ordering: SORT_BY_EXPIRATION_DATE },
  ],
}

export const incomingTestingRequestTableHeaders: IViewRequestsTable = {
  tableHeadCells: [
    { id: 1, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 2, content: 'Test type' },
    { id: 3, content: 'From laboratory', ordering: SORT_BY_LABORATORY },
    { id: 4, content: 'Manufacturer name', ordering: SORT_BY_MANUFACTURER },
    { id: 5, content: 'Sample notes' },
    { id: 6, content: 'Drug class' },
    { id: 7, content: 'Dosage' },
    { id: 8, content: 'Units' },
    { id: 9, content: 'APIs' },
    { id: 10, content: 'Excipients' },
    { id: 11, content: 'Expiration date', ordering: SORT_BY_EXPIRATION_DATE },
    { id: 12, content: 'Sender name' },
  ],
}

export const incomingBuyingRequestTableHeaders: IViewRequestsTable = {
  tableHeadCells: [
    { id: 1, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 2, content: 'From laboratory', ordering: SORT_BY_LABORATORY },
    { id: 3, content: 'Manufacturer name', ordering: SORT_BY_MANUFACTURER },
    { id: 4, content: 'Pharmacy name' },
    { id: 5, content: 'Sample notes' },
    { id: 6, content: 'Drug class' },
    { id: 7, content: 'Dosage' },
    { id: 8, content: 'Units' },
    { id: 10, content: 'APIs' },
    { id: 11, content: 'Excipients' },
    { id: 12, content: 'Sender name' },
  ],
}

export const outgoingTestingRequestTableHeaders: IViewRequestsTable = {
  tableHeadCells: [
    { id: 1, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 2, content: 'Test type' },
    { id: 3, content: 'To location' },
    { id: 4, content: 'Request status' },
    { id: 5, content: 'Manufacturer name', ordering: SORT_BY_MANUFACTURER },
    { id: 6, content: 'Sample notes' },
    { id: 7, content: 'Drug class' },
    { id: 8, content: 'Dosage' },
    { id: 9, content: 'Units' },
    { id: 10, content: 'APIs' },
    { id: 11, content: 'Excipients' },
    { id: 12, content: 'Expiration date', ordering: SORT_BY_EXPIRATION_DATE },
  ],
}

export const outgoingBuyingRequestTableHeaders: IViewRequestsTable = {
  tableHeadCells: [
    { id: 1, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 2, content: 'To location' },
    { id: 3, content: 'Purchase status' },
    { id: 4, content: 'Manufacturer name', ordering: SORT_BY_MANUFACTURER },
    { id: 5, content: 'Pharmacy name' },
    { id: 6, content: 'Sample notes' },
    { id: 7, content: 'Drug class' },
    { id: 8, content: 'Dosage' },
    { id: 9, content: 'Units' },
    { id: 10, content: 'APIs' },
    { id: 11, content: 'Excipients' },
  ],
}

export const requestTabs: IRequestsTab[] = [
  { label: 'All samples', value: 'samples' },
  { label: 'Incoming requests', value: 'incoming requests' },
  { label: 'Outgoing requests', value: 'outgoing requests' },
]

// * Requests]

// * [Your Workspace page

export const yourWorkspaceTabs: IYourWorkspaceTab[] = [
  { label: 'Contact info', value: 'contact_info' },
  { label: 'Users', value: 'users' },
]

export const yourWorkspaceTabsTableHeaders = [
  { id: 1, content: 'Laboratory name' },
  { id: 2, content: 'Address' },
  { id: 3, content: 'Default manager' },
  { id: 4, content: 'Group' },
  { id: 5, content: 'Types of testing' },
  { id: 6, content: 'Delivery services' },
]

export const ListOfUsersTabsTableHeaders = [
  { content: 'User name', ordering: ['first_name', 'last_name'] },
  { content: 'Avatar' },
  { content: 'Role', ordering: ['role'] },
  { content: 'Phone number' },
  { content: 'Email' },
  { content: 'Time location' },
  { content: 'Action' },
]

export const skills = [
  {
    id: 1,
    name: TOXIC_NAME,
  },
  {
    id: 2,
    name: TEMPERATURE_NAME,
  },
]

const skillIcon: Record<
  string,
  {
    mobile: string
    default: string
  }
> = {
  [skills[0].id]: { mobile: toxicMobileIcon, default: toxicIcon },
  [skills[1].id]: { mobile: tempSensitiveMobileIcon, default: tempSensitiveIcon },
}

const tooltipTitle = (skillName: string) => ({
  user: `Has permission to work with ${skillName}`,
  laboratory: `Works with ${skillName} samples`,
})

export const skillDetails = {
  icon: (id: number) => skillIcon[id],
  tooltipTitle: (name: string) => tooltipTitle(name),
}
// * Your Workspace page]

// * [HR Testing reports

export const viewHrReportsTable: IViewReportsTable = {
  tableHeadCells: [
    { id: 1, content: 'Photo' },
    { id: 2, content: 'Sample ID', ordering: SORT_BY_SPECIFIC_ID },
    { id: 3, content: 'Sample name' },
    { id: 4, content: 'Screening result(s)' },
    { id: 5, content: 'Assay result(s)' },
    { id: 6, content: 'Collection date', ordering: SORT_BY_COLLECTION_DATE },
    { id: 7, content: 'Collection location', ordering: SORT_BY_COLLECTION_LOCATION },
  ],
}

// * HR Testing reports]

// * [HR Sample details

export const viewHrSampleDetailsTable: IDetailsSampleBasicTable = {
  tableHeadCells: [
    { id: 1, content: 'Test type' },
    { id: 2, content: 'Test result(s)' },
    { id: 3, content: 'Test location' },
    { id: 4, content: 'Testing date' },
    { id: 5, content: 'Task notes' },
    { id: 6, content: 'Attachments' },
  ],
}

// * HR Sample details]
