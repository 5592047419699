import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

import { useAppDispatch } from '@hooks/redux'
import { IPackage } from '@models/packages'

export const useTogglePackage = (
  packageData: IPackage,
  selectCallback: ActionCreatorWithPayload<IPackage[], string>,
  selectedPackages: IPackage[]
) => {
  const dispatch = useAppDispatch()

  const indexOfPackage = selectedPackages.map(({ id }) => id).indexOf(packageData.id)

  const selectPackageHandler = () => {
    let newCheckedPackageId: IPackage[] = []

    if (indexOfPackage === -1) {
      newCheckedPackageId = newCheckedPackageId.concat(selectedPackages, packageData)
    } else if (indexOfPackage === 0) {
      newCheckedPackageId = newCheckedPackageId.concat(selectedPackages.slice(1))
    } else if (indexOfPackage === selectedPackages.length - 1) {
      newCheckedPackageId = newCheckedPackageId.concat(selectedPackages.slice(0, -1))
    } else if (indexOfPackage > 0) {
      newCheckedPackageId = newCheckedPackageId.concat(
        selectedPackages.slice(0, indexOfPackage),
        selectedPackages.slice(indexOfPackage + 1)
      )
    }

    dispatch(selectCallback(newCheckedPackageId))
  }

  return { selectPackageHandler, isSelected: indexOfPackage !== -1 }
}
