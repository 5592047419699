import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

import { checkStepOfTest, stepsAssayGroup, stepsScreeningGroup } from '@helpers/ui/tests'
import { AWAITING } from '@constants/tasks'

import { TasksStep } from './Step'
import { StyledBox, StyledStepBoxMobile } from './styles'

interface StepMobileProps {
  taskStep: number
  actualStep: number
  isTwoStepsInTest: boolean
  testTypeName?: string
}

export const StepMobile: React.FC<StepMobileProps> = props => {
  const { taskStep, actualStep, isTwoStepsInTest, testTypeName } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  checkStepOfTest(taskStep, isTwoStepsInTest)

  let stepsOfTest
  if (isTwoStepsInTest) {
    stepsOfTest = stepsScreeningGroup(testTypeName)
  } else {
    stepsOfTest = stepsAssayGroup
  }

  return (
    <Box sx={{ width: '100%', bgcolor: '#fff' }}>
      <StyledBox $isMobile={isMobile} $isTwoStepsInTest={isTwoStepsInTest}>
        {stepsOfTest.map(({ title, ml, status }, idx) => (
          <StyledStepBoxMobile key={idx} $isTwoStepsInTest={isTwoStepsInTest}>
            <TasksStep isMobile={isMobile} idx={idx} stepCounter={actualStep} stepColor={status} />
            <Typography
              variant='body2'
              fontWeight={500}
              color={status === AWAITING ? 'primary.main' : '#616D84'}
              mt={1}
              ml={ml ? ml : '-10px'}
            >
              {title}
            </Typography>
          </StyledStepBoxMobile>
        ))}
      </StyledBox>
    </Box>
  )
}
