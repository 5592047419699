import { Box } from '@mui/material'
import ContentLoader from 'react-content-loader'

export const Loader: React.FC = props => {
  return (
    <Box width='50%'>
      <ContentLoader
        speed={2}
        width={305}
        height={455}
        viewBox='0 0 305 455'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}
      >
        <rect x='0' y='0' rx='4' ry='4' width='305' height='28' />
        <rect x='0' y='60' rx='4' ry='4' width='246' height='20' />
        <rect x='0' y='95' rx='4' ry='4' width='144' height='20' />
        <rect x='0' y='140' rx='4' ry='4' width='224' height='20' />
        <rect x='0' y='175' rx='4' ry='4' width='98' height='20' />
        <rect x='0' y='400' rx='4' ry='4' width='112' height='20' />
        <rect x='0' y='435' rx='4' ry='4' width='87' height='20' />
        <rect x='0' y='220' rx='4' ry='4' width='124' height='20' />
        <rect x='0' y='255' rx='14' ry='14' width='87' height='28' />
        <rect x='97' y='255' rx='14' ry='14' width='96' height='28' />
        <rect x='0' y='311' rx='4' ry='4' width='124' height='20' />
        <rect x='0' y='346' rx='14' ry='14' width='102' height='28' />
        <rect x='112' y='346' rx='14' ry='14' width='102' height='28' />
      </ContentLoader>
    </Box>
  )
}
