import { IChoice } from '@models/common/app'

import { StyledBox } from './styles'
import { RenderIcon } from './components/RenderIcon'
import { RenderToxicityTooltipTitle } from './components/RenderToxicityTooltipTitle'
import { RenderTempSensitiveTooltipTitle } from './components/RenderTempSensitiveTooltipTitle'

interface TempSensitiveToxicityIconsProps {
  toxicity?: IChoice | null
  minTempSensitive?: string | null
  maxTempSensitive?: string | null
  mt?: string
  ml?: string
  temperatureMeasurementFormat?: string
  miniIcons?: boolean
  maxTemperatureMeasurementFormat?: string
  minTemperatureMeasurementFormat?: string
}

export const TempSensitiveToxicityIcons: React.FC<TempSensitiveToxicityIconsProps> = props => {
  const {
    toxicity,
    minTempSensitive,
    maxTempSensitive,
    mt,
    ml,
    temperatureMeasurementFormat,
    miniIcons = false,
    maxTemperatureMeasurementFormat,
    minTemperatureMeasurementFormat,
  } = props
  const isTempSensitiveToxicitySample = !!toxicity?.key || !!minTempSensitive || !!maxTempSensitive

  const toxicTooltipTitle = <RenderToxicityTooltipTitle toxicityValue={toxicity?.value} />

  const tempSensitiveTooltipTitle = (
    <RenderTempSensitiveTooltipTitle
      minTempSensitive={minTempSensitive}
      maxTempSensitive={maxTempSensitive}
      temperatureMeasurementFormat={temperatureMeasurementFormat}
      maxTemperatureMeasurementFormat={maxTemperatureMeasurementFormat}
      minTemperatureMeasurementFormat={minTemperatureMeasurementFormat}
    />
  )

  return (
    <>
      {isTempSensitiveToxicitySample ? (
        <StyledBox mt={mt} ml={ml}>
          {!!toxicity?.value && (
            <RenderIcon idx={1} tooltipTitle={toxicTooltipTitle} miniIcons={miniIcons} ml={ml} />
          )}

          {!!minTempSensitive && !!maxTempSensitive && (
            <RenderIcon idx={2} tooltipTitle={tempSensitiveTooltipTitle} miniIcons={miniIcons} />
          )}
        </StyledBox>
      ) : null}
    </>
  )
}
