import React from 'react'
import { Box } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { Attachment } from '@components/shared'
import { StyledDetailsLabel } from '@styles'
import { TRUNCATED_ATTACHMENTS_TASK_DETAILS_TEXT } from '@constants/common'

interface AttachmentsMobileProps {
  data?: ITaskLab
}

export const AttachmentsMobile: React.FC<AttachmentsMobileProps> = ({ data }) => {
  return (
    <Box pl='16px' pr='16px'>
      <StyledDetailsLabel variant='h5'>Storage documents</StyledDetailsLabel>
      {!!data?.sample_attachments?.length ? (
        <Box width='100%'>
          {data?.sample_attachments?.map(attachment => (
            <Attachment
              truncatedTextLength={TRUNCATED_ATTACHMENTS_TASK_DETAILS_TEXT}
              cantDelete
              key={attachment.id}
              attachment={attachment}
              mr='15px'
            />
          ))}
        </Box>
      ) : (
        '-'
      )}
    </Box>
  )
}
