import { Collapse } from '@mui/material'
import { StringParam, useQueryParams } from 'use-query-params'
import { useEffect } from 'react'

import { useFetchStatisticTestReports } from '@hooks/queries'
import { filterStringParamsQueries } from '@components/shared/FilterAside/constants'
import { IStatisticData } from '@models/testingReports'

import { StyledWidgetsInfo } from './styles'
import { MainStatistic } from './components/MainStatistic'
import { SamplesStatistic } from './components/SamplesStatistic'
import { TestsStatistic } from './components/TestsStatistic'

interface StatisticDataProps {
  isOpenWidgets: boolean
  setStatisticTitle: React.Dispatch<React.SetStateAction<string>>
}

export const StatisticData: React.FC<StatisticDataProps> = ({
  isOpenWidgets,
  setStatisticTitle,
}) => {
  const statisticDataQ = useFetchStatisticTestReports<IStatisticData>()
  const statisticData = statisticDataQ.data
  const [
    {
      desired_test_reports,
      apis,
      excipients,
      drug_classes,
      manufacturers,
      testingDate,
      laboratories,
      taskResult,
      test_types,
    },
  ] = useQueryParams({
    desired_test_reports: StringParam,
    ...filterStringParamsQueries,
  })
  const isFilterApplied =
    desired_test_reports ||
    apis ||
    excipients ||
    drug_classes ||
    manufacturers ||
    testingDate ||
    laboratories ||
    taskResult ||
    test_types

  useEffect(() => {
    if (isFilterApplied) setStatisticTitle('Test report statistics')
    else setStatisticTitle('Summary statistics')
  }, [isFilterApplied, setStatisticTitle])

  return (
    <>
      <Collapse in={isOpenWidgets} unmountOnExit>
        <StyledWidgetsInfo>
          <MainStatistic statistic={statisticData} />
          <SamplesStatistic statistic={statisticData} isFilterApplied={!!isFilterApplied} />
          <TestsStatistic statistic={statisticData} isFilterApplied={!!isFilterApplied} />
        </StyledWidgetsInfo>
      </Collapse>
    </>
  )
}
