import { useMutation, useQueryClient } from 'react-query'

import { sendPackages } from '@api/packages'
import { PACKAGES } from '@constants/queries'
import { useAppDispatch } from '@hooks/redux'
import { showNotification } from '@helpers/app'
import { selectPackages } from '@state/packages'
import { ISendPackagesData } from '@models/packages'
import { notifications } from '@constants/notifications'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useSendPackages = () => {
  const dispatch = useAppDispatch()
  const client = useQueryClient()

  const mutation = useMutation<unknown, IError, ISendPackagesData>(sendPackages, {
    onSuccess: (data, { package_ids }) => {
      dispatch(selectPackages([]))

      if (package_ids.length > 1) showNotification(notifications.sendPackagesSuccessfully)
      else showNotification(notifications.sendPackageSuccessfully)
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
      showNotification(notifications.sendPackagesFailed)
    },
    onSettled: () => {
      client.invalidateQueries(PACKAGES)
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
