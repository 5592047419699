import { useAppDispatch } from '@hooks/redux'
import { setAddSampleButtonPushed } from '@state/app'
import { ButtonBox, StyledModalSubtitle, StyledModalTitle } from '@styles'

import { Button, Modal } from '..'

interface ConfirmationModalProps {
  isMobile?: boolean
  title: string
  subtitle?: string | React.ReactElement
  subtitleLikeTitle?: boolean
  cancelBtnText?: string
  confirmButtonText?: string
  onClose: () => void
  onConfirm: () => void
  isDisabled?: boolean
  mb?: string
  goBackModal?: boolean
  extraOnConfirm?: () => void
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = props => {
  const {
    isMobile,
    title,
    subtitle,
    subtitleLikeTitle,
    cancelBtnText = 'Cancel',
    confirmButtonText = 'Okay',
    onClose,
    onConfirm,
    isDisabled = false,
    mb,
    goBackModal,
    extraOnConfirm,
  } = props

  const dispatch = useAppDispatch()

  const confirmHandler = () => {
    onConfirm()
    onClose()
    // for helper tooltip about cope of samples
    dispatch(setAddSampleButtonPushed(0))
  }

  const cancelBtnHandler = () => {
    if (extraOnConfirm) extraOnConfirm()
    onClose()
  }

  return (
    <Modal open onClose={onClose}>
      <>
        <StyledModalTitle
          variant={isMobile ? 'subtitle2' : 'h3'}
          sx={{ marginBottom: subtitle ? '' : isMobile ? (mb ? mb : '57px') : '76px' }}
        >
          {title}
        </StyledModalTitle>

        {subtitle && (
          <StyledModalSubtitle mb={mb} subtitleLikeTitle={subtitleLikeTitle}>
            {subtitle}
          </StyledModalSubtitle>
        )}

        <ButtonBox $isMobile={isMobile}>
          <Button
            disabled={isDisabled}
            variant={goBackModal ? 'contained' : 'outlined'}
            width={isMobile ? '100%' : '205px'}
            mr={isMobile || goBackModal ? '' : '60px'}
            mt={isMobile ? '16px' : ''}
            onClick={cancelBtnHandler}
            sx={{ order: isMobile || goBackModal ? 2 : 1 }}
          >
            {cancelBtnText}
          </Button>

          <Button
            disabled={isDisabled}
            variant={goBackModal ? 'outlined' : 'contained'}
            width={isMobile ? '100%' : '205px'}
            onClick={confirmHandler}
            sx={{ order: isMobile || goBackModal ? 1 : 2 }}
            mr={goBackModal ? '60px' : ''}
          >
            {confirmButtonText}
          </Button>
        </ButtonBox>
      </>
    </Modal>
  )
}
