import { TabPanel } from '@mui/lab'
import {
  styled,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  Box,
  Typography,
  Table,
  TypographyProps,
} from '@mui/material'

export const DetailsStyledRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & .MuiFormControlLabel-root {
    width: 100%;
    max-width: 220px;
    margin-bottom: 0;
  }
`

export const StyledDetailsPageBox = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 12px;
`

export const StyledDetailsTable = styled(Table)`
  text-align: left;
  padding-left: 80px;
`

export const StyledDetailsTableRow = styled(TableRow)`
  display: flex;
  width: 100%;

  & th,
  & td {
    width: 20%;
    font-size: 1rem;
    flex-grow: 1;
  }

  & th:first-child {
    padding-left: 40px;
  }
`

export const StyledDetailsTableHead = styled(TableHead)`
  display: flex;
  width: 100%;

  & th,
  & td {
    width: 20%;
    font-size: 1rem;
    flex-grow: 1;
  }

  & th:first-child {
    padding-left: 40px;
  }
  & th {
    border-bottom: none;
  }
`

export const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`
export const StyledDetailsTableCell = styled(TableCell)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.common.white,
  borderBottom: '4px solid #f8fbff',
  paddingLeft: '30px',
  paddingRight: '30px',

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.primary[10],
  },
}))

export const DetailsAttachedFileBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`

export const StyledDetailsBox = styled('div')`
  display: flex;
  justify-content: space-between;

  & > div,
  & > form {
    width: 28.5%;
  }
`

export const StyledInfoBox = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-bottom: 24px;
  background-color: #ffffff;
  box-shadow: -5px 0 15px rgba(53, 108, 151, 0.12);
  border-radius: 10px;
`

export const StyledInfoBoxSkill = styled('div')`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
`

export const StyledTitle = styled(Typography)`
  margin-bottom: 28px;
`

interface StyledDetailsLabelProps extends TypographyProps {
  mb?: string
}

export const StyledDetailsLabel = styled<React.FC<StyledDetailsLabelProps>>(Typography)`
  margin-bottom: ${({ mb }) => (mb ? mb : '16px')};
`

export const StyledParagraph = styled(Typography)`
  margin-bottom: 24px;
`

export const StyledFlexRow = styled('div')`
  display: flex;

  & > div {
    width: 100%;
    max-width: 170px;

    &:first-of-type {
      margin-right: 30px;
    }
  }
`

//Mobile

export const StyledDetailsTableMobile = styled(Box)`
  text-align: left;
`

export const TableHeadMobile = styled(Box)`
  padding: 14px;
  background-color: ${({ theme }) => theme.primary[10]};
`

export const StyledDetailsTableCellMobile = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
`
