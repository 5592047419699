import React from 'react'
import { Box } from '@mui/system'
import { useDispatch } from 'react-redux'

import { Tooltip, Button } from '@components/shared'
import { sampleCreate } from '@state/sampleCreate'
import { sampleCreateIds, seCopySampleId } from '@state/app'
import { useAppSelector } from '@hooks/redux'

import { CopyButtonTypes } from '../sampleCreate.types'
import { HelperTooltipWrapper } from './HelperTooltipWrapper'

export const CopyButton: React.FC<CopyButtonTypes> = React.memo(props => {
  const { sampleId, idx } = props

  const dispatch = useDispatch()

  const [shouldBeDisableCopy, setShouldBeDisableCopy] = React.useState(false)

  const sampleIds = useAppSelector(sampleCreateIds)
  const { samples } = useAppSelector(sampleCreate)

  const currentSample = samples.filter(sample => sample.id === sampleId)[0] as any

  React.useEffect(() => {
    setShouldBeDisableCopy(false)
    for (let key in currentSample)
      if (currentSample[key].error) {
        setShouldBeDisableCopy(true)
      }
  }, [currentSample])

  return (
    <>
      <Tooltip
        title='All errors must be fixed before copying'
        shouldBeHidden={!shouldBeDisableCopy}
      >
        <Box>
          {/* tooltip helper */}
          <HelperTooltipWrapper idx={idx} isDisableCopyTooltip={shouldBeDisableCopy} />

          <Button
            variant='outlined'
            disabled={sampleIds.length >= 100 || shouldBeDisableCopy}
            type='button'
            onClick={() => dispatch(seCopySampleId(sampleId))}
            width='90px'
            height='32px'
            padding='0'
            mr='18px'
            fz={14}
          >
            Copy
          </Button>
        </Box>
      </Tooltip>
    </>
  )
})
