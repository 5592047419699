import React from 'react'
import { useDispatch } from 'react-redux'
import { useController, useWatch } from 'react-hook-form'

import { SampleOptionsContext } from '@context'
import { PureDropdownAutocomplete } from '@components/ui'
import { setCellValueToSample } from '@state/sampleCreate'
import { IOptionExtended } from '@components/ui/PureDropdownAutocomplete/pureDropdownAutocomplete.types'
import { MAIN_APIS, TOXIC_NAME } from '@constants/samples'
import { REQUIRED_FIELD } from '@constants/validation'
import { ReactComponent as ToxicMobileIcon } from '@assets/icons/toxic.svg'
import { Tooltip } from '@components/shared'
import { toxicityTooltip } from '@helpers/ui/samples'
import { obtainingSkills } from '@helpers/obtainingSkills'
import { useAppSelector } from '@hooks/redux'
import { sampleCreateUIData } from '@state/common/sampleCreateUIState'

import { PureCellPropTypes } from './pureFields.types'
import { TableCellWrapper } from './TableCellWrapper'
import { StyledAdditionalText } from './styles'

export interface ToxicityProps extends PureCellPropTypes {
  isOnlyToxicity?: boolean
  scrollBlockRef?: React.MutableRefObject<HTMLDivElement | null>
}

export const Toxicity: React.FC<ToxicityProps> = props => {
  const {
    initialVal,
    cellName,
    sampleId,
    isCopy,
    name,
    control,
    width,
    clearErrors,
    isEditing = false,
    isOnlyToxicity = false,
    scrollBlockRef,
  } = props
  const { toxicityLevel } = React.useContext(SampleOptionsContext)
  const { isToxicityAdded } = useAppSelector(sampleCreateUIData)
  const initialValue = initialVal.val
    ? toxicityLevel.find(level => level.key === initialVal.val)?.key || null
    : null
  const checkInitialValuesRef = React.useRef<boolean>(false)
  const [toxicity, setToxicity] = React.useState<string | null>(initialValue)
  // This ref fixed bug with store/hook-form error
  const deleteFlagRef = React.useRef(false)

  const dispatch = useDispatch()
  const fieldApiName = isEditing ? [MAIN_APIS] : [`${sampleId}_${MAIN_APIS}`]
  const [api] = useWatch({ control, name: fieldApiName })
  const isHaveToxicityInSkills =
    isOnlyToxicity || !!obtainingSkills(api).find(skill => skill.name === TOXIC_NAME)

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: { required: isHaveToxicityInSkills ? REQUIRED_FIELD : false },
    control,
    defaultValue:
      (initialVal.val && toxicityLevel.find(level => level.key === initialVal.val)) || '',
  })

  const setCellValue = (val: string, error: string) => {
    !isEditing &&
      dispatch(
        setCellValueToSample({
          cellValue: { val, error },
          cellName,
          sampleId,
        })
      )
    if (!error) field.onChange({ target: { value: val } })
  }

  React.useEffect(() => {
    if (error && error.message && isHaveToxicityInSkills && !field.value) {
      setCellValue(field.value, error.message)
    } else if (error && error.message && !isHaveToxicityInSkills && clearErrors) {
      deleteFlagRef.current = true
      clearErrors(name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, error])

  React.useEffect(() => {
    if (!initialVal.val) checkInitialValuesRef.current = true

    if (!checkInitialValuesRef.current) {
      if (isHaveToxicityInSkills && api?.length && api?.length === 1) {
        setCellValue(initialValue || api[0].toxicity_level?.key, '')
        setToxicity(initialValue || api[0].toxicity_level?.key)
      } else if (field.value) {
        setToxicity(field.value)
      }
    }

    if (checkInitialValuesRef.current) {
      if (isHaveToxicityInSkills && api?.length && api?.length === 1) {
        setCellValue(api[0].toxicity_level?.key || toxicityLevel[0].key, '')
        setToxicity(api[0].toxicity_level?.key || toxicityLevel[0].key)
      } else {
        setCellValue('', deleteFlagRef.current ? '' : (error && error.message) || '')
        setToxicity(null)
      }
    } else {
      checkInitialValuesRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api])

  const onDropdownHandle = (valueKey: string) => {
    setCellValue(valueKey, '')
    setToxicity(valueKey)
  }

  return (() => {
    if (isOnlyToxicity || (api?.length && isHaveToxicityInSkills)) {
      // If we have toxicity skill and APIs selected
      return (
        <TableCellWrapper
          isEditing={isEditing}
          stylesEditForm={{ width: '100%', background: '#fff' }}
          stylesCreateForm={{ minWidth: width }}
          id={name}
        >
          <Tooltip
            scrollBlockRef={scrollBlockRef}
            isPureTooltip
            title={toxicityTooltip(toxicity || '')}
            maxWidth={200}
            shouldBeHidden={!toxicityTooltip(toxicity || '').length}
          >
            <PureDropdownAutocomplete
              scrollBlockRef={scrollBlockRef}
              isEditing={isEditing}
              startIcon={<ToxicMobileIcon />}
              name={name}
              isCopy={isCopy}
              isDropdown
              options={(toxicityLevel as IOptionExtended[]) || []}
              initialVal={toxicity || ''}
              onDropdownChange={(value: IOptionExtended) => onDropdownHandle(value.key as string)}
              errorMessage={error?.message}
              withTooltip
            />
          </Tooltip>
        </TableCellWrapper>
      )
    } else if (api?.length && !isHaveToxicityInSkills) {
      // If we don't have toxicity skill
      return isEditing || isToxicityAdded ? (
        <TableCellWrapper isEditing={isEditing} stylesCreateForm={{ minWidth: width }}>
          <StyledAdditionalText>For chemotherapy only</StyledAdditionalText>
        </TableCellWrapper>
      ) : null
    } else {
      // If APIs are not selected
      return isEditing || isToxicityAdded ? (
        <TableCellWrapper isEditing={isEditing} stylesCreateForm={{ minWidth: width }}>
          -
        </TableCellWrapper>
      ) : null
    }
  })()
}
