import { StringParam, useQueryParam } from 'use-query-params'

import { Tabs } from './Tabs'
import { WorkspaceContent } from './WorkspaceContent'
import { YourWorkspaceHeader } from './WorkspaceHeader'

export const Workspace = () => {
  const [queryString] = useQueryParam('workspace_type', StringParam)

  return (
    <>
      <YourWorkspaceHeader />
      <Tabs />
      <WorkspaceContent contentType={queryString} />
    </>
  )
}
