import { TabPanel } from '@mui/lab'
import { styled, FormControlLabel } from '@mui/material'

export const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`

export const StyledLabel = styled(FormControlLabel)`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: 0;

  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: ${({ theme }) => theme.palette.text.primary};
  max-width: 204px;
`
