import React from 'react'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '@hooks/redux'
import { tooltipStateDataPosition, setDataAndPosition } from '@state/common/tooltipState'

import { TooltipPositionStyles } from './pureTooltip.types'
import * as Styled from './styles'

export const PureTooltipContainer: React.FC = () => {
  const tooltipRef = React.useRef<HTMLDivElement | null>(null)
  const tooltipDataPosition = useAppSelector(tooltipStateDataPosition)
  const dispatch = useDispatch()

  const handleScroll = () => {
    if (tooltipRef.current && tooltipDataPosition) {
      dispatch(setDataAndPosition(null))
    }
  }

  const handleMoveOutside = (e: MouseEvent) => {
    const target = e.target as Element
    if (tooltipDataPosition?.wrapperRefCurrent && tooltipRef.current) {
      if (
        !target.closest('.pure-tooltip-wrapper') &&
        !tooltipRef.current.contains(target) &&
        !tooltipDataPosition.wrapperRefCurrent.contains(target)
      ) {
        dispatch(setDataAndPosition(null))
      }
    }
  }

  React.useEffect(() => {
    const tooltip = tooltipRef.current
    if (tooltip && tooltipDataPosition) {
      /* ----- SETTING STYLES ----- */
      const {
        position: { top, height: parentHeight },
      } = tooltipDataPosition

      if (placement === 'bottom') {
        const correctTop = top + window.scrollY + parentHeight + tooltip.offsetHeight + 16 // 16 - it's margin from parent block
        tooltip.style.top = `${correctTop}px`
      } else {
        // position by default ("top")
        const correctTop = top + window.scrollY - tooltip.offsetHeight - 16 // 16 - it's margin from parent block
        tooltip.style.top = `${correctTop}px`
      }
      tooltip.style.transform = `translateX(-${tooltip.offsetWidth / 2}px)`
      tooltip.style.opacity = '1'
      tooltip.style.top = `${tooltip.style.top} - ${tooltip.offsetHeight}px`

      /* ----- SETTING EVENT FOR HOVER EFFECT ----- */
      document.addEventListener('mousemove', handleMoveOutside)
      document.addEventListener('scroll', handleScroll)
    } else {
      document.removeEventListener('mousemove', handleMoveOutside)
      document.removeEventListener('scroll', handleScroll)
    }

    return () => {
      document.removeEventListener('mousemove', handleMoveOutside)
      document.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipDataPosition, tooltipRef])

  if (!tooltipDataPosition) return null
  const {
    position: { left, maxWidth },
    title,
    placement,
    styles: propStyles,
  } = tooltipDataPosition

  const tooltipPositionStyle = (() => {
    const style: TooltipPositionStyles = { left }
    if (maxWidth) style.maxWidth = maxWidth
    if (propStyles) return { ...style, ...propStyles }
    return style
  })()

  const tooltipClassNames: string = (() => {
    const baseClassName = 'pure-tooltip'
    if (placement === 'bottom') return `${baseClassName} placement-bottom`
    // Top by default
    return `${baseClassName} placement-top`
  })()

  return (
    <Styled.TooltipWrapper
      ref={tooltipRef}
      className={tooltipClassNames}
      style={tooltipPositionStyle}
    >
      {title}
    </Styled.TooltipWrapper>
  )
}
