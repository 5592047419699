import React from 'react'
import { QrReader } from 'react-qr-reader'
import { Box, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { Button } from '@components/shared'
import { useToggle } from '@hooks/common'
import { QrCodeIcon } from '@components/icons/QrCodeIcon'

import { ScanQRcodeModal } from '../Modals'

export const MobileStub: React.FC = () => {
  const [QRLink, setQRLink] = React.useState('')
  const [shouldScanQR, setShouldScanQR] = useToggle()
  const [isModalOpen, toggleModal] = useToggle()

  const history = useHistory()
  const isActualLink = QRLink.includes('/app/packages')

  React.useEffect(() => {
    if (QRLink && isActualLink) {
      const packageDetailsLink = new URL(QRLink).pathname
      history.push(packageDetailsLink)
    }
    if (!!QRLink && !isActualLink) {
      toggleModal()
    }
    setQRLink('')
  }, [history, QRLink, isActualLink, toggleModal])

  return (
    <>
      <Box>
        {shouldScanQR && (
          <QrReader
            onResult={result => {
              if (!!result) {
                setQRLink(result.toString())
              }
            }}
            constraints={{ facingMode: 'environment' }}
          />
        )}

        {!shouldScanQR && !isModalOpen && (
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            height='72vh'
            justifyContent='space-between'
          >
            <Box display='flex' flexDirection='column' alignItems='center' m='auto'>
              <Box mb='48px'>
                <QrCodeIcon />
              </Box>
              <Typography
                sx={{ textAlign: 'center' }}
                fontWeight='700'
                mb='40px'
                color='secondary.light'
                variant='body1'
              >
                Please scan the QR - code to see the content.
              </Typography>
            </Box>
            <Button onClick={setShouldScanQR} type='button' width='100%' variant='contained'>
              Scan
            </Button>
          </Box>
        )}
      </Box>

      {isModalOpen && (
        <ScanQRcodeModal isMobile onClose={toggleModal} onConfirm={setShouldScanQR} />
      )}
    </>
  )
}
