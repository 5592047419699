import React, { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { StyledLabel } from '@styles'
import { Input } from '@components/shared'

export const TrackingNumberField: React.FC = () => {
  const { setValue, trigger, formState } = useFormContext()

  const [trackingNumber, setTrackingNumber] = useState<any>('')

  const trackingNumberError = formState.errors['tracking_number']

  const handleChange = useCallback(
    (event: any) => {
      setTrackingNumber(event?.target?.value)
      setValue('tracking_number', event?.target?.value)
      trigger(['tracking_number'])
    },
    [setValue, trigger]
  )

  return (
    <>
      <StyledLabel
        mb='25px'
        label='Tracking Number'
        control={
          <Input
            fullWidth
            height={48}
            variant='outlined'
            value={trackingNumber}
            onChange={handleChange}
            placeholder='Type here values...'
            error={!!trackingNumberError}
            helperText={trackingNumberError?.message}
          />
        }
      />
    </>
  )
}
