import React from 'react'
import { useFormContext } from 'react-hook-form'

import { useToggle } from '@hooks/common'
import { Button } from '@components/shared'
import { CopySamples } from '@components/modules'
import { useFieldArrayFormContext } from '@context'
import { SAMPLE_CREATION } from '@constants/samples'
import { INewSamples } from '@models/samples'

interface CopySampleButtonProps {
  sampleIdx: number
}

export const CopySampleButton: React.FC<CopySampleButtonProps> = ({ sampleIdx }) => {
  const { fields: samples, prepend } = useFieldArrayFormContext()
  const [isCopyModalOpen, toggleCopyModal] = useToggle()
  const { getValues } = useFormContext()

  const copySampleHandler = (data: { copy: string }) => {
    const name = `${SAMPLE_CREATION}.${sampleIdx}`
    const copiedSamples = new Array(Number(data.copy)).fill({ ...getValues(name), isCopy: true })

    prepend(copiedSamples)
    toggleCopyModal()
  }

  return (
    <>
      <Button
        variant='outlined'
        disabled={samples.length >= 100}
        onClick={toggleCopyModal}
        width='90px'
        height='32px'
        padding='0'
        mr='18px'
        fz={14}
      >
        Copy
      </Button>

      {isCopyModalOpen && (
        <CopySamples
          samples={samples as INewSamples}
          onCopy={copySampleHandler}
          onClose={toggleCopyModal}
        />
      )}
    </>
  )
}
