import React from 'react'
import { Box, IconButton, Typography, useTheme } from '@mui/material'

import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { CardRow, CardTitleCell } from '@styles'

interface MobileDetailsCardProps {
  title?: string
  isDataTypeComponent?: boolean
  data?: any
  minWidth?: string
  editButtonAction?: (() => void) | null
  edit?: boolean
  isComponentHidden?: boolean
}

export const MobileDetailsCard: React.FC<MobileDetailsCardProps> = props => {
  const {
    title,
    isDataTypeComponent,
    data,
    minWidth,
    editButtonAction,
    edit = true,
    isComponentHidden = false,
  } = props
  const theme = useTheme()

  return (
    <>
      {!isComponentHidden && (
        <CardRow>
          <CardTitleCell sx={{ display: 'flex', alignItems: 'center' }} minWidth={minWidth}>
            <Typography variant='h5'>{title}</Typography>
            {editButtonAction && edit && (
              <IconButton onClick={editButtonAction}>
                <EditIcon
                  fill={theme.palette.primary.main}
                  style={{ width: '16px', height: '16px' }}
                />
              </IconButton>
            )}
          </CardTitleCell>

          <Box sx={{ width: '100%' }}>
            {isDataTypeComponent ? data : <Typography variant='body1'>{data || '-'}</Typography>}
          </Box>
        </CardRow>
      )}
    </>
  )
}
