import { styled, TableHead, TableRow } from '@mui/material'

export const StyledDetailsTableRow = styled(TableRow)`
  display: flex;
  width: 100%;

  & th:first-child,
  & td:first-child {
    width: 14%;
    padding: 16px 0;
  }

  & th:nth-child(2),
  & td:nth-child(2) {
    width: 16%;
    padding: 16px 0;
  }

  & th:nth-child(3),
  & td:nth-child(3) {
    width: 12%;
    padding: 16px 0;
  }

  & th:nth-child(4),
  & td:nth-child(4) {
    width: 15%;
    padding: 16px 0;
  }

  & th:nth-child(5),
  & td:nth-child(5) {
    width: 20%;
    padding: 16px 0;
  }

  & th:nth-child(6),
  & td:nth-child(6) {
    width: 20%;
    padding: 16px 0;
  }
`

export const StyledDetailsTableHead = styled(TableHead)`
  display: flex;
  width: 100%;

  & th,
  & td {
    width: 14.3%;
    font-size: 1rem;
    flex-grow: 1;
  }

  & th:first-child {
    padding-left: 40px;
  }
  & th {
    border-bottom: none;
  }
`
