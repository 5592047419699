import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'

import {
  deleteAllSelectedFilters,
  isDisabledApplyAll,
  deleteAllAppliedFilters,
  setIsFilterOpen,
  filtersAside,
  filtersActualPage,
  saveNewFiltersSamplesPage,
  applyFiltersOnPage,
  setStatedEnteringDate,
  selectFilterError,
  updateResetRadioBtnSampleStatus,
} from '@state/filters'
import { Button } from '@components/shared'
import { ReactComponent as CloseFilterMenuIcon } from '@assets/icons/close-icon.svg'
import { useAppSelector } from '@hooks/redux'
import { StyledPageSubTitle } from '@styles'
import { useSaveFilters } from '@hooks/queries'
import { updateFiltersForUserState } from '@state/user'
import { ISavedFiltersFromUser } from '@models/common/user'

import { StyledDrawer, ContentBox, ButtonBox, StyledDrawerHeader } from './styles'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

export const FilterAside: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:767px)')
  const isFilterOpen = useAppSelector(filtersAside)
  const pageForFilters = useAppSelector(filtersActualPage)
  const filterError = useAppSelector(selectFilterError)
  const saveFiltersM = useSaveFilters()
  const isDisabledApplyAllButton = useAppSelector(isDisabledApplyAll)
  const getSavedFilters = useAppSelector(saveNewFiltersSamplesPage) as ISavedFiltersFromUser
  const [updatedFilters, setUpdatedFilters] = React.useState<ISavedFiltersFromUser | null>(null)

  const incorrectFilterData = filterError[0] || filterError[1]

  React.useEffect(() => {
    setUpdatedFilters(getSavedFilters)
  }, [getSavedFilters])

  const toggleDrawerHandler =
    (anchor: Anchor, open: boolean, icon: boolean) =>
    (e: React.KeyboardEvent | React.MouseEvent) => {
      const checkEvent =
        e.type === 'keydown' &&
        ((e as React.KeyboardEvent).key === 'Tab' || (e as React.KeyboardEvent).key === 'Shift')

      if (checkEvent) return

      if (!icon) {
        applyAllFiltersHandler()
        if (isMobile) dispatch(setIsFilterOpen({ ...isFilterOpen, [anchor]: open }))
        return
      }

      if (icon) {
        dispatch(setIsFilterOpen({ ...isFilterOpen, [anchor]: open }))
      }
    }

  const applyAllFiltersHandler = () => {
    if (updatedFilters) {
      dispatch(updateFiltersForUserState(updatedFilters))

      saveFiltersM.mutate(updatedFilters)
    }
  }

  const clearAllFiltersHandler = () => {
    dispatch(setStatedEnteringDate({ order: 0, type: '' }))
    dispatch(deleteAllSelectedFilters())
    dispatch(deleteAllAppliedFilters())
    dispatch(updateResetRadioBtnSampleStatus(false))
    setUpdatedFilters({
      page_title: pageForFilters,
      filters: [],
    })
    dispatch(
      updateFiltersForUserState({
        page_title: pageForFilters,
        filters: [],
      })
    )
    dispatch(
      applyFiltersOnPage({
        page_title: pageForFilters,
        filters: [],
      })
    )

    saveFiltersM.mutate({
      page_title: pageForFilters,
      filters: [],
    })
  }

  return (
    <Box position='relative'>
      {/*FILTER ASIDE*/}
      <StyledDrawer
        variant='persistent'
        $isMobile={isMobile}
        height={'100vh'}
        anchor='right'
        open={isFilterOpen['right']}
        onClose={toggleDrawerHandler('right', false, false)}
      >
        {/*FILTER HEADER*/}
        <StyledDrawerHeader>
          <StyledPageSubTitle mb='0px' variant='subtitle2'>
            Filter
          </StyledPageSubTitle>
          <Box>
            <CloseFilterMenuIcon
              onClick={toggleDrawerHandler('right', false, true)}
              style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '29px' }}
            />
          </Box>
        </StyledDrawerHeader>
        {/*FILTER CONTENT*/}
        <ContentBox id='content-scroll-box'>
          <Box role='presentation'>{children}</Box>
        </ContentBox>
        {/*FILTER FOOTER*/}
        <ButtonBox $isMobile={isMobile}>
          <Button
            variant='outlined'
            width={isMobile ? '45%' : '90px'}
            height={isMobile ? '48px' : '28px'}
            fz={isMobile ? 16 : 14}
            padding='2px 0px'
            disabled={saveFiltersM.isLoading}
            onClick={clearAllFiltersHandler}
          >
            Clear all
          </Button>

          <Button
            variant='contained'
            width={isMobile ? '45%' : '90px'}
            height={isMobile ? '48px' : '28px'}
            fz={14}
            padding='2px 0px'
            disabled={isDisabledApplyAllButton || saveFiltersM.isLoading || incorrectFilterData}
            onClick={toggleDrawerHandler('right', false, false)}
          >
            Apply
          </Button>
        </ButtonBox>
      </StyledDrawer>
    </Box>
  )
}
