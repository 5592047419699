import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { addNewManufacturer } from '@api/samples'
import { MANUFACTURERS } from '@constants/queries'
import { setMaintenanceModePage } from '@state/user'

export const useAddNewManufacturer = () => {
  const client = useQueryClient()
  const dispatch = useDispatch()

  const mutation = useMutation((name: string) => addNewManufacturer(name), {
    onSuccess: () => {
      client.invalidateQueries(MANUFACTURERS)
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
