import { useTheme } from '@mui/material'

export const FilterMenuCloseIcon = () => {
  const theme = useTheme()

  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='20' fill={theme.palette.primary.main} />
      <rect x='8' y='12' width='24' height='2' rx='1' fill='white' />
      <rect x='11' y='19' width='18' height='2' rx='1' fill='white' />
      <rect x='14' y='26' width='12' height='2' rx='1' fill='white' />
    </svg>
  )
}
