import React from 'react'
import { useFormContext } from 'react-hook-form'

import { DatePicker } from '@components/shared'
import { EXPECTED_DATE } from '@constants/packages'
import { StyledLabel } from '@styles'

import { StyledTextField } from './styles'

export const ExpectedDateField: React.FC<{}> = () => {
  const { formState, trigger, setValue } = useFormContext()
  const expectedDateError = formState.errors[EXPECTED_DATE]

  const [expectedDate, setDesireDate] = React.useState<Date | null>(null)
  const onChangeHandler = async (v: unknown) => {
    await setDesireDate(v as Date)
    await setValue(EXPECTED_DATE, v as Date)
    trigger([EXPECTED_DATE])
  }
  return (
    <StyledLabel
      sx={{ marginBottom: '25px' }}
      label='Expected delivery date'
      control={
        <DatePicker
          width='220px'
          height={48}
          disablePast
          value={expectedDate}
          onChange={onChangeHandler}
          renderInput={params => (
            <StyledTextField
              {...params}
              placeholder='MM/DD/YYYY'
              error={!!expectedDateError}
              helperText={expectedDateError?.message}
              value={params.inputProps?.value}
              inputProps={{ onChange: () => false }}
            />
          )}
        />
      }
    />
  )
}
