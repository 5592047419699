import { Button, styled, ButtonProps as MUIButtonProps, Box } from '@mui/material'
import { MuiStyledOptions } from '@mui/system'
import { StyledConfig } from 'styled-components'

const buttonConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'borderColor':
        return false

      default:
        return true
    }
  },
}

interface ButtonProps extends MUIButtonProps {
  borderColor?: string
}

export const StyledButton = styled<React.FC<ButtonProps>>(Button, buttonConfig)`
  border-radius: 2px;
  background-color: #fff;
  padding: 8px 22px;
  width: 86px;
  font-size: 14px;

  &.MuiButtonGroup-grouped:not(:last-of-type) {
    border-right: 2px solid ${({ borderColor }) => borderColor || 'transparent'};
  }

  &.Mui-disabled {
    background-color: #e1e7f3;
  }
`

export const CircularProgressWrapper = styled(Box)`
  width: 5px;
`
