import React from 'react'
import ContentLoader from 'react-content-loader'

export const UsersTableLoader: React.FC = props => {
  return (
    <ContentLoader
      speed={2}
      width={1360}
      height={414}
      viewBox='0 0 1360 414'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      {...props}
    >
      <path d='M 94.348 33 c 0.576 0 1.043 0.467 1.043 1.044 v 6.26 h 6.261 a 1.044 1.044 0 10 2.087 h -6.26 v 6.261 a 1.044 1.044 0 1 -2.088 0 v -6.26 h -6.26 a 1.044 1.044 0 10 -2.088 h 6.26 v -6.26 c 0 -0.577 0.468 -1.044 1.044 -1.044 z' />
      <rect x='116' y='32' rx='7' ry='7' width='64' height='14' />
      <rect x='30' y='17' rx='4' ry='4' width='202' height='48' />
      <path d='M 920 33 a 6 6 0 100 12 6 6 a 0 0 -12 z m -8 6 a 8 8 0 1114.32 4.906 l 5.387 5.387 a 1 1 0 11 -1.414 1.414 l -5.387 -5.387 A 8 8 0 1912 39 z' />
      <rect x='944' y='32' rx='7' ry='7' width='129' height='14' />
      <rect x='64' y='101' rx='7' ry='7' width='78' height='14' />
      <path d='M 55.016 172.559 c 0 2.204 -0.593 4.269 -1.629 6.044 H 32.613 a 11.957 11.957 0 1 -1.629 -6.044 c 0 -6.637 5.38 -12.016 12.016 -12.016 s 12.016 5.379 12.016 12.016 z' />
      <path d='M 34.817 175.677 c -1.617 0.815 -2.306 2.587 -2.443 3.353 c 1.492 3.143 5.347 5.957 10.658 5.957 c 6.223 0 10.695 -5.204 10.595 -5.994 c -0.327 -2.556 -3.194 -3.603 -3.438 -3.709 c -0.309 -0.134 -1.632 -0.718 -1.944 -0.907 c -0.495 -1.039 -2.076 -1.786 -2.805 -2.03 l 0.25 -0.363 c 1.058 -0.248 3.137 -0.47 3.27 -0.999 c 2.173 -8.632 -3.84 -11.92 -4.66 -11.921 c -0.797 -0.002 -0.97 0.209 -1.319 0.231 c -0.348 0.022 -0.75 -0.232 -1.249 -0.233 c -1.438 0 -7.061 4.057 -4.813 11.791 c 0.14 0.483 2.615 1.108 3.35 1.131 l 0.282 0.363 c -1.768 0.406 -2.621 1.523 -2.827 2.03 c -0.295 0.094 -1.29 0.486 -2.907 1.3 z' />
      <rect x='64' y='163' rx='7' ry='7' width='125' height='14' />
      <path d='M 55.016 241.559 c 0 2.083 -0.53 4.042 -1.462 5.75 H 32.447 a 11.966 11.966 0 1 -1.463 -5.75 c 0 -6.637 5.38 -12.016 12.016 -12.016 s 12.016 5.379 12.016 12.016 z' />
      <path d='M 38.038 244.259 c -4.698 1.222 -4.866 2.411 -5.848 3.456 c 2.357 4.789 8.179 6.218 10.795 6.333 c 5.902 0.065 11.025 -5.513 10.813 -6.333 c -0.43 -1.673 -4.06 -3.001 -5.822 -3.456 c -0.962 -0.938 -1.809 -1.176 -2.116 -1.426 c -0.247 -0.201 -0.223 -0.82 -0.18 -1.105 c 0.78 -0.766 1.215 -2.524 1.334 -3.308 c 0.41 0 0.761 -0.696 0.886 -1.044 c 0.636 -1.26 0.12 -1.701 -0.216 -1.764 c 0.253 -2.65 0.908 -2.766 0.908 -3.716 c -0.127 -2.143 -2.629 -2.924 -2.766 -3.103 c -0.137 -0.18 0.359 -0.358 0.327 -0.442 c -0.031 -0.085 0.148 -0.39 -3.145 -0.316 c -3.293 0.074 -3.114 1.982 -3.758 2.225 c -0.644 0.243 -1.91 0.982 -1.668 1.678 c 0.195 0.558 0.595 2.675 0.771 3.664 c -0.96 0.254 -0.528 1.15 -0.285 1.774 c 0.322 0.829 0.675 1.147 0.94 1.044 c -0.068 1.073 0.86 2.652 1.333 3.308 c 0.268 0.368 0.045 0.564 -0.23 1.105 c -0.83 0 -1.727 0.951 -2.073 1.426 z' />
      <rect x='64' y='232' rx='7' ry='7' width='157' height='14' />
      <path d='M 34.41 312.998 c 1.157 -0.514 1.912 -1.052 2.358 -1.672 c 0.46 -0.641 0.55 -1.31 0.506 -1.977 a 9.099 9.099 0 0 -0.135 -0.991 l -0.05 -0.274 c -0.045 -0.245 -0.09 -0.491 -0.13 -0.752 c -0.107 -0.703 -0.164 -1.461 -0.02 -2.32 c 0.136 -0.812 0.01 -1.513 -0.295 -2.104 a 3.505 3.505 0 0 -0.739 -0.956 A 11.89 11.89 0 143 299.617 c 2.657 0 5.111 0.868 7.095 2.335 c -0.29 0.269 -0.547 0.585 -0.74 0.956 c -0.305 0.591 -0.43 1.292 -0.294 2.104 c 0.143 0.859 0.087 1.617 -0.02 2.32 c -0.04 0.261 -0.085 0.507 -0.13 0.752 l -0.05 0.274 c -0.06 0.331 -0.114 0.665 -0.135 0.991 c -0.045 0.667 0.045 1.336 0.506 1.977 c 0.446 0.62 1.2 1.158 2.357 1.672 c 1.38 0.614 2.285 1.315 2.872 1.925 c -0.165 0.563 -0.37 1.108 -0.613 1.633 H 32.152 a 11.858 11.858 0 1 -0.613 -1.633 c 0.587 -0.611 1.491 -1.311 2.872 -1.925 z m 0 0 l -0.202 -0.457 l 0.203 0.457 s 0 0 0 0 z m -3.346 -1.063 a 11.912 11.912 0 14.053 -9.346 c 0.267 0.227 0.487 0.485 0.639 0.779 c 0.202 0.39 0.299 0.871 0.197 1.479 c -0.168 1.001 -0.099 1.875 0.017 2.636 c 0.042 0.277 0.092 0.546 0.138 0.796 l 0.047 0.256 c 0.058 0.325 0.104 0.613 0.121 0.881 c 0.035 0.521 -0.038 0.934 -0.32 1.326 c -0.296 0.412 -0.865 0.86 -1.952 1.343 h 0 c -1.195 0.531 -2.08 1.132 -2.735 1.713 a 11.966 11.966 0 1 -0.205 -1.863 z m 23.878 -0.376 a 11.973 11.973 0 1 -0.21 2.239 c -0.655 -0.581 -1.541 -1.182 -2.736 -1.713 l -0.204 0.456 l 0.203 -0.456 c -1.086 -0.483 -1.655 -0.931 -1.951 -1.343 c -0.282 -0.392 -0.355 -0.805 -0.32 -1.326 c 0.017 -0.268 0.063 -0.556 0.121 -0.881 l 0.047 -0.256 c 0.046 -0.25 0.096 -0.519 0.138 -0.796 c 0.116 -0.761 0.185 -1.635 0.017 -2.636 c -0.102 -0.608 -0.005 -1.089 0.197 -1.479 c 0.152 -0.294 0.372 -0.552 0.639 -0.779 a 11.93 11.93 0 14.059 8.97 z' />
      <path d='M 33.916 314.807 c -0.926 0.293 -1.71 1.539 -2.009 2.172 c 1.33 2.333 4.64 6.754 11.24 6.754 c 6.027 0 9.774 -4.211 10.765 -6.465 c -0.281 -0.615 -1.284 -2.161 -2.234 -2.461 c -0.95 -0.3 -3.416 -1.208 -4.53 -1.624 c -0.313 -0.49 -1.216 -1.349 -1.74 -1.424 l -0.147 -0.76 c 0.458 -0.469 1.37 -1.827 1.35 -3.503 c 0.28 -0.074 0.848 -0.36 0.885 -0.909 c 0.229 -0.259 0.612 -0.941 0.314 -1.592 c 0.35 -1.223 0.407 -3.528 -0.982 -4.334 c 0 -1.201 -1.29 -2.652 -3.893 -2.665 h -0.276 c -5.496 0.027 -5.54 4.677 -4.875 6.999 c -0.298 0.651 0.085 1.333 0.314 1.592 c 0.037 0.549 0.605 0.835 0.884 0.909 c -0.018 1.676 0.893 3.034 1.35 3.503 l -0.145 0.76 c -0.525 0.075 -1.428 0.934 -1.74 1.424 c -1.115 0.416 -3.581 1.324 -4.531 1.624 z' />
      <path d='M 31.885 317.027 c 0.281 -0.615 1.081 -1.92 2.031 -2.22 c 0.95 -0.3 3.416 -1.208 4.53 -1.624 c 0.313 -0.49 1.216 -1.349 1.74 -1.424 l 0.147 -0.76 c -0.458 -0.469 -1.37 -1.827 -1.35 -3.503 c -0.28 -0.074 -0.848 -0.36 -0.885 -0.909 c -0.229 -0.259 -0.612 -0.941 -0.314 -1.592 c -0.665 -2.322 -0.621 -6.972 4.875 -6.999 h 0.28 m -0.004 0 c 2.602 0.013 3.893 1.464 3.893 2.665 c 1.39 0.806 1.332 3.111 0.982 4.334 c 0.298 0.651 -0.085 1.333 -0.314 1.592 c -0.037 0.549 -0.605 0.835 -0.884 0.909 c 0.018 1.676 -0.893 3.034 -1.35 3.503 l 0.145 0.76 c 0.525 0.075 1.428 0.934 1.74 1.424 c 1.115 0.416 3.581 1.324 4.531 1.624 c 0.95 0.3 1.953 1.846 2.234 2.461 c -0.991 2.254 -4.738 6.465 -10.765 6.465 c -6.607 0 -9.918 -4.431 -11.244 -6.762' />
      <rect x='64' y='302' rx='7' ry='7' width='160' height='14' />
      <path d='M 55.016 381.559 c 0 2.204 -0.593 4.269 -1.629 6.044 H 32.613 a 11.957 11.957 0 1 -1.629 -6.044 c 0 -6.637 5.38 -12.016 12.016 -12.016 s 12.016 5.379 12.016 12.016 z' />
      <path d='M 34.817 384.677 c -1.617 0.815 -2.306 2.587 -2.443 3.353 c 1.492 3.143 5.347 5.957 10.658 5.957 c 6.223 0 10.695 -5.204 10.595 -5.994 c -0.327 -2.556 -3.194 -3.603 -3.438 -3.709 c -0.309 -0.134 -1.632 -0.718 -1.944 -0.907 c -0.495 -1.039 -2.076 -1.786 -2.805 -2.03 l 0.25 -0.363 c 1.058 -0.248 3.137 -0.47 3.27 -0.999 c 2.173 -8.632 -3.84 -11.92 -4.66 -11.921 c -0.797 -0.002 -0.97 0.209 -1.319 0.231 c -0.348 0.022 -0.75 -0.232 -1.249 -0.233 c -1.438 0 -7.061 4.057 -4.813 11.791 c 0.14 0.483 2.615 1.108 3.35 1.131 l 0.282 0.363 c -1.768 0.406 -2.621 1.523 -2.827 2.03 c -0.295 0.094 -1.29 0.486 -2.907 1.3 z' />
      <rect x='64' y='372' rx='7' ry='7' width='108' height='14' />
      <rect x='280' y='101' rx='7' ry='7' width='47' height='14' />
      <rect x='280' y='161' rx='7' ry='7' width='53' height='14' />
      <rect x='280' y='231' rx='7' ry='7' width='53' height='14' />
      <rect x='280' y='301' rx='7' ry='7' width='36' height='14' />
      <rect x='280' y='371' rx='7' ry='7' width='36' height='14' />
      <rect x='391' y='101' rx='7' ry='7' width='33' height='14' />
      <rect x='391' y='161' rx='7' ry='7' width='119' height='14' />
      <rect x='391' y='231' rx='7' ry='7' width='64' height='14' />
      <rect x='391' y='301' rx='7' ry='7' width='146' height='14' />
      <rect x='391' y='371' rx='7' ry='7' width='146' height='14' />
      <rect x='595' y='101' rx='7' ry='7' width='106' height='14' />
      <rect x='597' y='161' rx='7' ry='7' width='132' height='14' />
      <rect x='597' y='231' rx='7' ry='7' width='132' height='14' />
      <rect x='597' y='301' rx='7' ry='7' width='132' height='14' />
      <rect x='597' y='371' rx='7' ry='7' width='132' height='14' />
      <rect x='787' y='101' rx='7' ry='7' width='47' height='14' />
      <rect x='787' y='161' rx='7' ry='7' width='176' height='14' />
      <rect x='787' y='231' rx='7' ry='7' width='176' height='14' />
      <rect x='787' y='301' rx='7' ry='7' width='176' height='14' />
      <rect x='787' y='371' rx='7' ry='7' width='176' height='14' />
      <rect x='1021' y='101' rx='7' ry='7' width='76' height='14' />
      <rect x='1021' y='162' rx='7' ry='7' width='84' height='14' />
      <rect x='1021' y='232' rx='7' ry='7' width='84' height='14' />
      <rect x='1021' y='302' rx='7' ry='7' width='84' height='14' />
      <rect x='1021' y='372' rx='7' ry='7' width='84' height='14' />
      <path d='M 1204.5 171 c 0 5.848 -4.72 10.613 -10.56 10.684 h -19.76 c -5.89 0 -10.68 -4.794 -10.68 -10.684 c 0 -5.891 4.79 -10.685 10.68 -10.685 c 0 0 0 0 0 0 l 19.76 0.002 c 5.84 0.069 10.56 4.834 10.56 10.683 z m -1.61 0 c 0 -5.007 -4.07 -9.079 -9.07 -9.079 c -5.01 0 -9.08 4.072 -9.08 9.079 c 0 5.006 4.07 9.078 9.08 9.078 c 5 0 9.07 -4.072 9.07 -9.078 z' />
      <path d='M 1237.29 161.293 a 1.006 1.006 0 11.42 0 l 4 4 a 1 1 0 10 1.414 l -13 13 a 1 1 0 1 -0.71 0.293 h -4 c -0.55 0 -1 -0.448 -1 -1 v -4 c 0 -0.265 0.11 -0.52 0.29 -0.707 l 10 -10 l 3 -3 z m -2.29 5.121 l -9 9 V 178 h 2.59 l 9 -9 l -2.59 -2.586 z m 4 1.172 l 1.59 -1.586 l -2.59 -2.586 l -1.59 1.586 l 2.59 2.586 z' />
      <path d='M 1234.29 164.293 l -10 10 a 1.03 1.03 0 0 -0.29 0.707 v 4 c 0 0.552 0.45 1 1 1 h 4 a 1 1 0 0.71 -0.293 l 13 -13 a 1 1 0 0 -1.414 l -4 -4 a 1.006 1.006 0 0 -1.42 0 l -3 3 z m 0 0 s 0 0 0 0 z m -8.29 11.121 l 9 -9 l 2.59 2.586 l -9 9 H 1226 v -2.586 z m 13 -7.828 l -2.59 -2.586 l 1.59 -1.586 l 2.59 2.586 l -1.59 1.586 z' />
      <path d='M 1204.5 241 c 0 5.848 -4.72 10.613 -10.56 10.684 h -19.76 c -5.89 0 -10.68 -4.794 -10.68 -10.684 c 0 -5.891 4.79 -10.685 10.68 -10.685 c 0 0 0 0 0 0 l 19.76 0.002 c 5.84 0.069 10.56 4.834 10.56 10.683 z m -1.61 0 c 0 -5.007 -4.07 -9.079 -9.07 -9.079 c -5.01 0 -9.08 4.072 -9.08 9.079 c 0 5.006 4.07 9.078 9.08 9.078 c 5 0 9.07 -4.072 9.07 -9.078 z' />
      <path d='M 1237.29 231.293 a 1.006 1.006 0 11.42 0 l 4 4 a 1 1 0 10 1.414 l -13 13 a 1 1 0 1 -0.71 0.293 h -4 c -0.55 0 -1 -0.448 -1 -1 v -4 c 0 -0.265 0.11 -0.52 0.29 -0.707 l 10 -10 l 3 -3 z m -2.29 5.121 l -9 9 V 248 h 2.59 l 9 -9 l -2.59 -2.586 z m 4 1.172 l 1.59 -1.586 l -2.59 -2.586 l -1.59 1.586 l 2.59 2.586 z' />
      <path d='M 1234.29 234.293 l -10 10 a 1.03 1.03 0 0 -0.29 0.707 v 4 c 0 0.552 0.45 1 1 1 h 4 a 1 1 0 0.71 -0.293 l 13 -13 a 1 1 0 0 -1.414 l -4 -4 a 1.006 1.006 0 0 -1.42 0 l -3 3 z m 0 0 s 0 0 0 0 z m -8.29 11.121 l 9 -9 l 2.59 2.586 l -9 9 H 1226 v -2.586 z m 13 -7.828 l -2.59 -2.586 l 1.59 -1.586 l 2.59 2.586 l -1.59 1.586 z' />
      <path d='M 1204.5 311 c 0 5.848 -4.72 10.613 -10.56 10.684 h -19.76 c -5.89 0 -10.68 -4.794 -10.68 -10.684 c 0 -5.891 4.79 -10.685 10.68 -10.685 c 0 0 0 0 0 0 l 19.76 0.002 c 5.84 0.069 10.56 4.834 10.56 10.683 z m -1.61 0 c 0 -5.007 -4.07 -9.079 -9.07 -9.079 c -5.01 0 -9.08 4.072 -9.08 9.079 c 0 5.006 4.07 9.078 9.08 9.078 c 5 0 9.07 -4.072 9.07 -9.078 z' />
      <path d='M 1237.29 301.293 a 1.006 1.006 0 11.42 0 l 4 4 a 1 1 0 10 1.414 l -13 13 a 1 1 0 1 -0.71 0.293 h -4 c -0.55 0 -1 -0.448 -1 -1 v -4 c 0 -0.265 0.11 -0.52 0.29 -0.707 l 10 -10 l 3 -3 z m -2.29 5.121 l -9 9 V 318 h 2.59 l 9 -9 l -2.59 -2.586 z m 4 1.172 l 1.59 -1.586 l -2.59 -2.586 l -1.59 1.586 l 2.59 2.586 z' />
      <path d='M 1234.29 304.293 l -10 10 a 1.03 1.03 0 0 -0.29 0.707 v 4 c 0 0.552 0.45 1 1 1 h 4 a 1 1 0 0.71 -0.293 l 13 -13 a 1 1 0 0 -1.414 l -4 -4 a 1.006 1.006 0 0 -1.42 0 l -3 3 z m 0 0 s 0 0 0 0 z m -8.29 11.121 l 9 -9 l 2.59 2.586 l -9 9 H 1226 v -2.586 z m 13 -7.828 l -2.59 -2.586 l 1.59 -1.586 l 2.59 2.586 l -1.59 1.586 z' />
      <path d='M 1163.5 381.184 c 0 -5.848 4.72 -10.614 10.56 -10.684 h 19.76 c 5.89 0 10.68 4.793 10.68 10.684 c 0 5.891 -4.79 10.684 -10.68 10.684 c 0 0 0 0 0 0 l -19.76 -0.002 c -5.84 -0.068 -10.56 -4.834 -10.56 -10.682 z m 1.61 0 c 0 5.006 4.07 9.078 9.07 9.078 c 5.01 0 9.08 -4.072 9.08 -9.078 c 0 -5.006 -4.07 -9.078 -9.08 -9.078 c -5 0 -9.07 4.072 -9.07 9.078 z' />
      <path d='M 1237.29 371.293 a 1.006 1.006 0 11.42 0 l 4 4 a 1 1 0 10 1.414 l -13 13 a 1 1 0 1 -0.71 0.293 h -4 c -0.55 0 -1 -0.448 -1 -1 v -4 c 0 -0.265 0.11 -0.52 0.29 -0.707 l 10 -10 l 3 -3 z m -2.29 5.121 l -9 9 V 388 h 2.59 l 9 -9 l -2.59 -2.586 z m 4 1.172 l 1.59 -1.586 l -2.59 -2.586 l -1.59 1.586 l 2.59 2.586 z' />
      <path d='M 1234.29 374.293 l -10 10 a 1.03 1.03 0 0 -0.29 0.707 v 4 c 0 0.552 0.45 1 1 1 h 4 a 1 1 0 0.71 -0.293 l 13 -13 a 1 1 0 0 -1.414 l -4 -4 a 1.006 1.006 0 0 -1.42 0 l -3 3 z m 0 0 s 0 0 0 0 z m -8.29 11.121 l 9 -9 l 2.59 2.586 l -9 9 H 1226 v -2.586 z m 13 -7.828 l -2.59 -2.586 l 1.59 -1.586 l 2.59 2.586 l -1.59 1.586 z' />
      <rect x='1163' y='101' rx='7' ry='7' width='47' height='14' />
    </ContentLoader>
  )
}
