import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMediaQuery } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { Button, Modal } from '@components/shared'
import {
  ButtonBox,
  StyledForm,
  StyledModalContent,
  StyledModalScroll,
  StyledModalSubtitle,
  StyledModalTitle,
  StyledShadowBox,
} from '@styles'
import { editUserSchema } from '@services/validation'
import { useEditUser } from '@hooks/queries/useEditUser'
import { IErrorsEditUser } from '@models/yourWorkspace'
import { LABORATORY_ASSISTANT, MALE } from '@constants/user'
import { useAppSelector } from '@hooks/redux'
import { workSpaceInfo } from '@state/workSpace'
import { ISkill } from '@models/common/app'
import { IEditUserRequestData } from '@api/user'
import { IUser, UserRole, UserSex } from '@models/common/user'
import { useCreateUser } from '@hooks/queries/useCreateUser'
import { TENANT_TYPE } from '@constants/tenants'
import { useTenant } from '@hooks/common/useTenant'

import { Fields } from './components/Fields'

interface EditUserProps {
  onClose: () => void
}

export const EditUser: React.FC<EditUserProps> = ({ onClose }) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const isMobileByHeight = useMediaQuery('(max-height:767px)')
  const { isTenantType } = useTenant()

  const createUserM = useCreateUser()
  const editUserM = useEditUser()
  const { selectedUser: currentUser } = useAppSelector(workSpaceInfo)
  const userId = currentUser?.id

  const errMessage: IErrorsEditUser = editUserM.error?.errorObject || createUserM.error?.errorObject

  type FormData = Pick<
    IUser,
    | 'first_name'
    | 'last_name'
    | 'role_switcher'
    | 'can_manage_users'
    | 'phone_number'
    | 'email'
    | 'timezone'
    | 'timezone_autodetect'
  > & {
    skills: Record<number, boolean>
    role: UserRole
    sex: UserSex
  }

  const defaultSkills = userId ? (currentUser?.skills as ISkill[]) : []

  const formMethods = useForm<FormData>({
    resolver: yupResolver(editUserSchema),
    mode: 'onSubmit',
    defaultValues: {
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name,
      role: (currentUser?.role || LABORATORY_ASSISTANT) as UserRole,
      role_switcher: currentUser?.role_switcher,
      sex: (currentUser?.sex || MALE) as UserSex,
      can_manage_users: currentUser?.can_manage_users,
      skills: defaultSkills?.reduce((acc, current) => {
        acc[current.id] = true
        return acc
      }, {} as Record<number, boolean>),
      phone_number: currentUser?.phone_number,
      email: currentUser?.email,
      timezone: currentUser?.timezone,
      timezone_autodetect: userId ? currentUser?.timezone_autodetect : true,
    },
  })

  const { handleSubmit, watch } = formMethods
  watch([
    'timezone',
    'timezone_autodetect',
    'can_manage_users',
    'role_switcher',
    'sex',
    'role',
    'phone_number',
    'email',
  ])

  const editHandler = (formData: FormData) => {
    let formattedData = formData as unknown as IEditUserRequestData

    if (isTenantType(TENANT_TYPE.legalDrugs)) {
      const skills = Object.entries(formData.skills)
        .filter(skill => skill[1])
        .map(skill => Number(skill[0]))

      formattedData = { ...formData, skills } as unknown as IEditUserRequestData
    }

    if (formattedData.role_switcher) delete (formattedData as any).role
    if (formattedData.phone_number) formattedData.phone_number = '+' + formattedData.phone_number
    if (userId) {
      const editedData = {} as IEditUserRequestData
      for (let key in formData) {
        if ((currentUser as any)[key] !== (formattedData as any)[key])
          (editedData as any)[key] = (formattedData as any)[key]
      }

      editUserM.mutate(
        { data: editedData, params: { id: userId } },
        {
          onSuccess: () => {
            onClose()
          },
        }
      )
    } else {
      createUserM.mutate(
        { data: formattedData },
        {
          onSuccess: () => {
            onClose()
          },
        }
      )
    }
  }

  return (
    <Modal open isPaddingAbsence onClose={onClose} disableAutoFocus={true}>
      <FormProvider {...formMethods}>
        <StyledForm onSubmit={handleSubmit(editHandler)} noValidate>
          <StyledShadowBox shadow={false}>
            <StyledModalTitle sx={{ marginBottom: '4px' }} variant='h3'>
              {userId ? 'Edit user' : 'Add user'}
            </StyledModalTitle>
            {!userId && (
              <StyledModalSubtitle withoutMarginBelow>
                After adding the welcome letter will be sent to this user`s e-mail
              </StyledModalSubtitle>
            )}
          </StyledShadowBox>

          <StyledModalContent pt='0' $isMobile={isMobile}>
            <StyledModalScroll height={isMobileByHeight ? '350px' : '500px'} $isMobile={isMobile}>
              <Fields apiErrors={errMessage} currentUser={currentUser} />
            </StyledModalScroll>
          </StyledModalContent>
          <StyledShadowBox shadow={false}>
            <ButtonBox sx={{ justifyContent: 'space-between' }}>
              <Button
                type='button'
                variant='outlined'
                color='secondary'
                width='202px'
                height='48px'
                onClick={onClose}
              >
                Cancel
              </Button>

              <Button
                type='submit'
                variant='contained'
                width='202px'
                height='48px'
                loading={editUserM.isLoading}
                disabled={editUserM.isLoading}
              >
                {userId ? 'Save' : 'Add user'}
              </Button>
            </ButtonBox>
          </StyledShadowBox>
        </StyledForm>
      </FormProvider>
    </Modal>
  )
}
