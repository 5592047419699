import React from 'react'
import { useController, useWatch } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useTheme } from '@mui/material'

import { MAIN_APIS } from '@constants/samples'
import { setCellValueToSample } from '@state/sampleCreate'
import { obtainingSkills } from '@helpers/obtainingSkills'
import { PureInput } from '@components/ui'
import { useAppSelector } from '@hooks/redux'
import { sampleCreateUIData } from '@state/common/sampleCreateUIState'
import { ReactComponent as AddIcon } from '@assets/icons/add-icon-blue.svg'

import { PureCellPropTypes } from './pureFields.types'
import { StyledAdditionalText, StyledActionBtn } from './styles'
import { TableCellWrapper } from './TableCellWrapper'

export const StorageNotes: React.FC<PureCellPropTypes> = props => {
  const {
    initialVal,
    cellName,
    sampleId,
    rules,
    isCopy,
    name,
    control,
    rows,
    width,
    isEditing = false,
  } = props
  const { isToxicityAdded, isTemperatureAdded } = useAppSelector(sampleCreateUIData)
  const isShowAllCell = isToxicityAdded || isTemperatureAdded
  const fieldApiName = isEditing ? [MAIN_APIS] : [`${sampleId}_${MAIN_APIS}`]
  const [api] = useWatch({ control, name: fieldApiName })
  const dispatch = useDispatch()
  const cleanNotesRef = React.useRef<boolean>(false)
  const [isNotesActive, setIsNotesActive] = React.useState<boolean>(false)
  const theme = useTheme()

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: initialVal?.val || '' })
  const [notes, setNotes] = React.useState<string>(
    (isEditing ? field.value : (initialVal?.val as string)) || ''
  )
  const allSkills = obtainingSkills(api)

  const setCellValue = (val: string, error: string) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val, error },
        cellName,
        sampleId,
      })
    )
  }

  React.useEffect(() => {
    if (!initialVal?.val) {
      cleanNotesRef.current = true
    }
    if (!cleanNotesRef.current) {
      if (field.value) {
        setNotes(field.value)
      } else if (api?.length && api?.length === 1 && api[0].storage_notes) {
        field.onChange({ target: { value: api[0].storage_notes } })
        setNotes(api[0].storage_notes)
        setIsNotesActive(true)
      }
    }
    if (cleanNotesRef.current) {
      if (api.length === 1 && api[0].storage_notes) {
        setNotes(api[0].storage_notes || field.value)
        setIsNotesActive(true)
        !isEditing && setCellValue(api[0].storage_notes, '')
        field.onChange({ target: { value: api[0].storage_notes } })
      } else {
        setNotes('')
        !isEditing && setCellValue('', '')
        field.onChange({ target: { value: '' } })
        if (isNotesActive) setIsNotesActive(false)
      }
    } else {
      cleanNotesRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api])

  React.useEffect(() => {
    if (!!error && error.message && !isEditing) setCellValue(field.value, error.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onInputChange = (value: string) => {
    field.onChange({ target: { value } })
    !isEditing && setCellValue(value, '')
    setNotes(value)
  }

  return (() => {
    // If APIs selected
    if (api.length) {
      // If we have skills
      if (allSkills.length || notes) {
        if (isNotesActive || notes) {
          return (
            <TableCellWrapper
              isEditing={isEditing}
              stylesCreateForm={{ minWidth: width }}
              stylesEditForm={{ width: '100%' }}
              id={name}
            >
              <PureInput
                isCopy={isCopy}
                name={name}
                style={{ background: '#fff' }}
                value={notes}
                onInputChange={onInputChange}
                rows={rows}
                errorMessage={error?.message || ''}
              />
            </TableCellWrapper>
          )
        } else {
          return (
            <TableCellWrapper isEditing={isEditing} stylesCreateForm={{ minWidth: width }}>
              <StyledActionBtn onClick={() => setIsNotesActive(true)}>
                <AddIcon fill={theme.palette.primary.main} />
                Add Notes
              </StyledActionBtn>
            </TableCellWrapper>
          )
        }
      } else {
        // If we don't have skills
        return isEditing || isShowAllCell ? (
          <TableCellWrapper isEditing={isEditing} stylesCreateForm={{ minWidth: width }}>
            <StyledAdditionalText>
              For chemotherapy and temperature-sensitive only
            </StyledAdditionalText>
          </TableCellWrapper>
        ) : null
      }
    }
    // If APIs are not selected
    return isEditing || isShowAllCell ? (
      <TableCellWrapper isEditing={isEditing} stylesCreateForm={{ minWidth: width }}>
        -
      </TableCellWrapper>
    ) : null
  })()
}
