import React from 'react'

import { ISamples } from '@models/samples'
import { Checkbox } from '@components/shared'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { MAX_SELECTED_SAMPLE_NUMBER } from '@constants/samples'
import { selectSamples, selectSamplesState } from '@state/samples'

import { StyledTableCell } from '../styles'

interface SamplesTogglerProps {
  samples: ISamples
  isSticky: boolean
}

export const SamplesToggler: React.FC<SamplesTogglerProps> = ({ samples, isSticky }) => {
  const { selectedSamples } = useAppSelector(selectSamplesState)
  const dispatch = useAppDispatch()

  const samplesWithoutPackage = samples.filter(sample => sample.allow_packaging)
  const selectedSamplesLength = selectedSamples.length
  const samplesWithoutPackageLength = samplesWithoutPackage.length

  const needsToBeShownIndeterminateIcon =
    selectedSamplesLength > 0 &&
    ((selectedSamplesLength !== samplesWithoutPackageLength &&
      selectedSamplesLength < MAX_SELECTED_SAMPLE_NUMBER) ||
      (samplesWithoutPackageLength < MAX_SELECTED_SAMPLE_NUMBER &&
        selectedSamplesLength < samplesWithoutPackageLength))

  const needsToBeShownCheckedIcon =
    samplesWithoutPackageLength > 0 &&
    (selectedSamplesLength === MAX_SELECTED_SAMPLE_NUMBER ||
      selectedSamplesLength === samplesWithoutPackageLength)

  const checkedAllSamplesHandler = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) {
      dispatch(
        selectSamples(samplesWithoutPackage.slice(0, MAX_SELECTED_SAMPLE_NUMBER).map(s => s.id))
      )
    } else {
      dispatch(selectSamples([]))
    }
  }

  return (
    <StyledTableCell isSticky={isSticky}>
      <Checkbox
        ml='44px'
        padding='0'
        indeterminate={needsToBeShownIndeterminateIcon}
        checked={needsToBeShownCheckedIcon}
        onChange={checkedAllSamplesHandler}
      />
    </StyledTableCell>
  )
}
