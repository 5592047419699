import React from 'react'
import { Box } from '@mui/material'

import { StyledTitle, StyledDetailsLabel } from '@styles'
import { ITaskLab } from '@models/tasks'
import { Attachment, TempSensitiveToxicityIcons } from '@components/shared'
import { checkSkills } from '@helpers/obtainingSkills'
import { SpecificId } from '@components/shared/sampleFields'
import { TRUNCATED_ATTACHMENTS_TASK_DETAILS_TEXT } from '@constants/common'

import { Loader } from './components'

interface IdentificationProps {
  task?: ITaskLab
  isLoading: boolean
}

export const Identification: React.FC<IdentificationProps> = ({ isLoading, task }) => {
  const { isSampleTemperatureSensitive, isSampleToxic } = checkSkills(task)

  if (isLoading) return <Loader />

  return (
    <div>
      {(isSampleTemperatureSensitive || isSampleToxic) && (
        <>
          <StyledTitle variant='h3' mb='32px' fz={24}>
            Attachments
          </StyledTitle>

          <StyledDetailsLabel variant='h5'>Storage documents</StyledDetailsLabel>
          {!!task?.sample_attachments?.length ? (
            <Box width='250px' mb='40px'>
              {task?.sample_attachments?.map(attachment => (
                <Attachment
                  truncatedTextLength={TRUNCATED_ATTACHMENTS_TASK_DETAILS_TEXT}
                  cantDelete
                  key={attachment.id}
                  attachment={attachment}
                  mr='15px'
                />
              ))}
            </Box>
          ) : (
            '-'
          )}
        </>
      )}

      <StyledTitle mb={'30px'} variant='h3' fz={24}>
        Identification
      </StyledTitle>

      <StyledDetailsLabel variant='h5'>Sample ID</StyledDetailsLabel>
      <Box display='flex' alignItems='center'>
        {task && (
          <SpecificId
            labelTooltip={task.initial_laboratory?.label_tooltip}
            specificId={String(task.sample)}
          />
        )}

        <TempSensitiveToxicityIcons
          toxicity={task?.toxicity_level}
          minTempSensitive={task?.min_temperature}
          maxTempSensitive={task?.max_temperature}
          temperatureMeasurementFormat={task?.temperature_measurement_format?.key}
        />
      </Box>
    </div>
  )
}
