import React from 'react'
import { Box } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { debounce } from 'lodash'

import { selectedFilters as filters } from '@state/filters'
import { useToggle, useCustomLazyScroll } from '@hooks/common'
import { useAppSelector } from '@hooks/redux'
import {
  CREATED_BY_SAMPLES_TITLE,
  LABORATORIES_FILTER,
  PACKAGE_DESTINATION_TITLE,
  PACKAGE_FROM_LABORATORY_TITLE,
  REQUESTS_ALL_SAMPLES_TITLE,
  REQUESTS_INCOMING_TITLE,
  REQUESTS_OUTGOING_TITLE,
  selectedFiltersSlice,
  TEST_REPORTS_LOCATION_TITLE,
} from '@components/shared/FilterAside/constants'
import { useFetchLaboratories } from '@hooks/queries'
import { ParagraphLink } from '@components/shared/ParagraphLink'
import { FilterLoader } from '@components/shared/FilterAside'
import { PackageContentType } from '@models/packages'
import { selectUserLaboratory } from '@state/user'
import { IRequestsTabValue } from '@models/requests'
import { REPORTS, STREET_SAMPLES } from '@constants/queries'

import { CardRow, StyledShowMoreLinkBox } from './styles'
import { FilterCheckbox } from '../components/FilterCheckbox'
import { FilterSelectAll } from '../components/FilterSelectAll'
import { SearchFilters } from '../components/SearchFilters'
import { FilterTopTitle } from '../components/FilterTopTitle'

interface LaboratoriesFilterProps {
  contentType: PackageContentType | typeof REPORTS | IRequestsTabValue | typeof STREET_SAMPLES
  displayCurrentLaboratory?: boolean
  isWithoutSearch?: boolean
  isShowCleanNumb?: boolean
}
export const LaboratoriesFilter: React.FC<LaboratoriesFilterProps> = ({
  contentType,
  displayCurrentLaboratory = false,
  isWithoutSearch,
  isShowCleanNumb,
}) => {
  const { id: currentLaboratoryId } = useAppSelector(selectUserLaboratory)
  const selectedFilters = useAppSelector(filters)
  const [isFilterClose, setIsFilterClose] = useToggle()
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const [isShowMore, setIsShowMore] = React.useState<boolean>(true)

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useFetchLaboratories(searchQuery)

  const filterNumb = data
    ? isShowCleanNumb
      ? data?.pages[0].count
      : data?.pages[0].count - 1
    : undefined
  const filterCounter =
    selectedFilters[selectedFiltersSlice.laboratories].filters.length || undefined

  let filterTitle

  const contentScrollBox = document.getElementById('content-scroll-box')
  const parentRef = React.useRef<HTMLElement | HTMLDivElement | null>(contentScrollBox)
  const scrollTriggerRef = React.useRef<HTMLDivElement | null>(null)
  const wrapRef = React.useRef<HTMLDivElement | null>(null)

  useCustomLazyScroll(parentRef, scrollTriggerRef, fetchNextPage, isShowMore, hasNextPage)

  const quiresList = data?.pages
    .map(page => page.results)
    .flat()
    .filter(({ id }) => displayCurrentLaboratory || id !== currentLaboratoryId)
    .map(item => ({ ...item, id: String(item.id) }))

  const queriesListItems = React.useMemo(() => {
    if (quiresList) {
      if (isShowMore) return quiresList.slice(0, 5)
      else return quiresList
    }
  }, [quiresList, isShowMore])

  React.useEffect(() => {
    if (isShowMore && wrapRef && wrapRef.current && contentScrollBox) {
      // number 63 - it's aside header height
      contentScrollBox.scrollTo({ top: wrapRef.current.offsetTop - 63 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowMore])

  switch (contentType) {
    case 'incoming':
      filterTitle = PACKAGE_FROM_LABORATORY_TITLE
      break
    case 'outgoing':
    case 'default':
      filterTitle = PACKAGE_DESTINATION_TITLE
      break
    case 'reports':
      filterTitle = TEST_REPORTS_LOCATION_TITLE
      break
    case 'samples':
      filterTitle = REQUESTS_ALL_SAMPLES_TITLE
      break
    case 'street-samples':
      filterTitle = CREATED_BY_SAMPLES_TITLE
      break
    case 'incoming requests':
      filterTitle = REQUESTS_INCOMING_TITLE
      break
    case 'outgoing requests':
      filterTitle = REQUESTS_OUTGOING_TITLE
      break
    default:
      break
  }

  const choiceQuery = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }, [])

  const debounceChoiceQuery = debounce(choiceQuery, 500)

  return (
    <div ref={wrapRef}>
      <FilterTopTitle
        filterNumb={filterNumb}
        isFilterClose={isFilterClose}
        setIsFilterClose={setIsFilterClose}
        titleOfFilter={filterTitle}
        filterCounter={filterCounter}
      />
      <Collapse in={!isFilterClose} unmountOnExit>
        <CardRow>
          {!isWithoutSearch && <SearchFilters searchFiltersHandler={debounceChoiceQuery} />}
          <Box sx={{ width: '100%' }}>
            {(isFetching || isLoading) && isShowMore ? (
              <Box width='100%'>
                <FilterLoader />
              </Box>
            ) : (
              <div style={{ paddingBottom: '12px' }}>
                {quiresList && !searchQuery && (
                  <FilterSelectAll type={LABORATORIES_FILTER} items={quiresList} />
                )}

                {queriesListItems &&
                  queriesListItems.map((item, idx) => {
                    if (idx === queriesListItems.length - 10) {
                      return (
                        <React.Fragment key={item.id}>
                          <div ref={scrollTriggerRef} />

                          <FilterCheckbox type={LABORATORIES_FILTER} item={item} />
                        </React.Fragment>
                      )
                    }
                    return <FilterCheckbox type={LABORATORIES_FILTER} item={item} key={item.id} />
                  })}
                {data && data?.pages[0].count > 5 && (
                  <StyledShowMoreLinkBox>
                    <ParagraphLink
                      sx={{ padding: '11px 24px 12px' }}
                      onClick={() => setIsShowMore(!isShowMore)}
                    >
                      Show {isShowMore ? 'more' : 'less'}
                    </ParagraphLink>
                  </StyledShowMoreLinkBox>
                )}
              </div>
            )}
          </Box>
        </CardRow>
      </Collapse>
    </div>
  )
}
