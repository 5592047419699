import React from 'react'
import { SelectProps } from '@mui/material'

import { StyledDropdown } from './styles'

export interface StyledDropdownProps extends SelectProps {
  bgColor?: string
  width?: string
  height?: number
  mr?: string
  mb?: string
  fontSize?: string
  padding?: string
  borderRadius?: string
  borderLeftColor?: string
  position?: string
  placeholderColor?: string
}

export const Dropdown = React.forwardRef<HTMLInputElement, StyledDropdownProps>((props, ref) => {
  const { children, ...restProps } = props

  return (
    <StyledDropdown ref={ref} {...restProps}>
      {children}
    </StyledDropdown>
  )
})
