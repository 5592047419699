import { styled, FormControlLabel, Typography } from '@mui/material'

export const StyledTitle = styled(Typography)`
  margin-bottom: 40px;
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const StyledLabel = styled(FormControlLabel)`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: 0;
  margin-bottom: 35px;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: ${({ theme }) => theme.palette.text.primary};

  &:last-child {
    margin-top: 40px;
  }
  & .MuiFormControlLabel-label {
    font-weight: 700;
    margin-bottom: 8.4px;
  }
  & .MuiFormHelperText-root {
    margin-top: 4px;
    margin-left: 0;
    margin-right: 0;
  }
`
