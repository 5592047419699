// * [View list of requests constants

export const MAX_SELECTED_REQUEST_NUMBER = 200
export const REQUESTS_AMOUNT_LOADING_LIMIT = 50

export const AWAITING_REQUEST_STATUS = 'awaiting'
export const ACCEPTED_REQUEST_STATUS = 'accepted'
export const BOUGHT_REQUEST_STATUS = 'bought'
export const REJECTED_REQUEST_STATUS = 'rejected'
export const AUTO_REJECTED_REQUEST_STATUS = 'split'
export const NOT_FOUND_REQUEST_STATUS = 'not_found'

export const TESTING_REQUEST_TYPE = 'testing'
export const BUYING_REQUEST_TYPE = 'purchase'
export const ALL_SAMPLES_REQUEST_TYPE = 'allSamples'

export const DESIRED_REQUEST = 'desired_request'
// * View list of requests constants]
