import React from 'react'
import { Collapse, useMediaQuery } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'

import { BTS, FTS, IDPAD, IStep, TandemMS, XTS } from '@models/tests'
import { COMPLETED, NOT_COMPLETED } from '@constants/tasks'
import { IAttachmentList, IAttachments } from '@models/attachments'
import { useFetchAttachments } from '@hooks/queries'
import { findLostLoadingAttachment } from '@helpers/findLostLoadingAttachment'
import {
  ATTACHMENT_TEST_STRIP_TITLE,
  ADDITIONAL_INFO,
  RESULTS_STEP_TITLE,
  ATTACHMENT_EQUIPMENT_NUMBER_TITLE,
  ATTACHMENT_TEST_INFO_SECTION,
  ATTACHMENT_EQUIPMENT_NUMBER_SECTION,
  TEST_INFO_TITLE,
  ATTACHMENT_TEST_REPORT_TITLE,
} from '@constants/tests'
import { IHrTaskLab } from '@models/tasks'

import { CompletedStep } from '../CompletedStep'
import { AttachmentsSection, AttachmentsSectionTitle, FinishTest } from './components'
import { StepContainerExpandedNoSidebar } from '../Testing/styles'
import { AttachmentsSectionTandemMS } from './components/AttachmentsSection/AttachmentsSectionTandemMS'

export const Attachments: React.FC<IStep<IHrTaskLab>> = props => {
  const { isOpen, shouldRender, actualStep, task } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const formMethods = useForm()

  const fetchAttachmentsQ = useFetchAttachments({
    content_type: 'task',
    object_id: task.id,
  })

  const [isStepCompleted, setIsStepCompleted] = React.useState(!isOpen)
  const [attachments, setAttachments] = React.useState<IAttachments>([])
  const isTandemMSTest = task.test_type.name === TandemMS
  const isTestReport = task.test_type.name === IDPAD

  const attachmentList: IAttachmentList = React.useMemo(() => {
    if (isTandemMSTest)
      return [
        { title: TEST_INFO_TITLE, counter: 1, section: ATTACHMENT_TEST_INFO_SECTION },
        {
          title: ATTACHMENT_EQUIPMENT_NUMBER_TITLE,
          counter: 2,
          section: ATTACHMENT_EQUIPMENT_NUMBER_SECTION,
        },
      ]
    else if (isTestReport)
      return [{ title: ATTACHMENT_TEST_REPORT_TITLE, counter: 1, section: 'test_report' }]
    else return [{ title: ATTACHMENT_TEST_STRIP_TITLE, counter: 1, section: 'photo_of_test_strip' }]
  }, [isTandemMSTest, isTestReport])

  const stepTitle = [FTS, BTS, XTS].includes(task.test_type.name)
    ? ADDITIONAL_INFO
    : RESULTS_STEP_TITLE

  React.useEffect(() => {
    if (fetchAttachmentsQ.data) {
      setAttachments(findLostLoadingAttachment(fetchAttachmentsQ.data, attachments))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAttachmentsQ.data])

  React.useEffect(() => {
    setIsStepCompleted(!isOpen)
  }, [isOpen])

  const isAttachmentsLoading = attachments.some(item => item.loading)

  return shouldRender ? (
    <>
      {!isMobile && (
        <CompletedStep
          status={task.step === 4 ? COMPLETED : NOT_COMPLETED}
          counter={actualStep}
          title={stepTitle}
          open={isStepCompleted}
        />
      )}

      <Collapse in={!isStepCompleted} unmountOnExit>
        <StepContainerExpandedNoSidebar>
          <AttachmentsSectionTitle actualStep={actualStep} stepTitle={stepTitle} />

          <FormProvider {...formMethods}>
            {isTandemMSTest ? (
              <AttachmentsSectionTandemMS
                taskId={task.id}
                attachments={attachments}
                setAttachments={setAttachments}
                attachmentList={attachmentList}
              />
            ) : (
              <AttachmentsSection
                taskId={task.id}
                attachments={attachments}
                setAttachments={setAttachments}
                attachmentList={attachmentList}
                isTestReport={isTestReport}
                isMobile={isMobile}
              />
            )}

            <FinishTest
              taskId={task.id}
              loading={isAttachmentsLoading}
              task={task}
              isTestReport={isTestReport}
            />
          </FormProvider>
        </StepContainerExpandedNoSidebar>
      </Collapse>
    </>
  ) : null
}
