import { Box, CircularProgress, IconButton, InputAdornment, Typography } from '@mui/material'
import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import InfiniteScroll from 'react-infinite-scroller'
import { HiOutlineSearch } from 'react-icons/hi'

import {
  useSearchSuggestionsAddSamples,
  useSearchSuggestionsIntoPackage,
  useSearchSuggestionsPackage,
  useSearchSuggestionsRequests,
  useSearchSuggestionsRequestsAllSamples,
  useSearchSuggestionsSamples,
  useSearchSuggestionsTasks,
  useSearchSuggestionsTestsReports,
} from '@hooks/queries'
import { selectSearchSuggestions, setSearchSuggestion } from '@state/app'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { Input } from '@components/shared'
import { useToggle } from '@hooks/common'

import { StyledInfiniteScroll } from './styles'
import { ScrollSuggestionNextSamplesLoader } from './ScrollSuggestionNextSamplesLoader'

interface AutocompleteSearchProps {
  width: string
  placeholder: string
  setQueryString: (arg1: string | undefined) => void
  queryString: string | undefined | null
  searchIn:
    | 'samples'
    | 'tasks'
    | 'addSamples'
    | 'request'
    | 'testReports'
    | 'package'
    | 'samplesIntoPackage'
    | 'requestAllSamples'
  type?: string | undefined | null | string[]
}

const searchSuggestionsHooks = {
  samples: useSearchSuggestionsSamples,
  samplesIntoPackage: useSearchSuggestionsIntoPackage,
  tasks: useSearchSuggestionsTasks,
  addSamples: useSearchSuggestionsAddSamples,
  request: useSearchSuggestionsRequests,
  requestAllSamples: useSearchSuggestionsRequestsAllSamples,
  testReports: useSearchSuggestionsTestsReports,
  package: useSearchSuggestionsPackage,
}

export const AutocompleteSearch: React.FC<AutocompleteSearchProps> = React.memo(props => {
  const { width, placeholder, setQueryString, searchIn, queryString, type } = props

  const dispatch = useAppDispatch()
  const suggestionSampleSearching = useAppSelector(selectSearchSuggestions)
  const [inputIsFocuses, setInputIsFocuses] = useToggle(false)
  const [autocompleteIsHovered, setAutocompleteIsHovered] = useToggle(false)
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const suggestionsSampleQ = searchSuggestionsHooks[searchIn](type, inputIsFocuses)
  const scrollParentRef = React.useRef(null)

  const possibleSamples = suggestionsSampleQ.data?.pages
    .map(page => [...page.results.suggestions])
    .flat()

  const sampleOnClick = (possibleSample: string) => {
    dispatch(setSearchSuggestion(possibleSample))
    debounced(possibleSample)
  }

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setSearchSuggestion(e.currentTarget.value))

  const debounced = useDebouncedCallback((sample: string) => {
    if (sample) setQueryString(sample)
    else setQueryString(undefined)
  }, 300)

  const keyPressHandle = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') debounced(suggestionSampleSearching)
  }

  React.useEffect(() => {
    if (inputRef.current)
      inputRef.current.addEventListener('keypress', keyPressHandle as () => void)

    return () => {
      if (inputRef.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        inputRef.current.removeEventListener('keypress', keyPressHandle as () => void)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionSampleSearching, possibleSamples])

  React.useEffect(() => {
    if (!queryString) dispatch(setSearchSuggestion(''))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  React.useEffect(() => {
    if (!suggestionSampleSearching && !inputIsFocuses) setQueryString(undefined)
  }, [suggestionSampleSearching, setQueryString, inputIsFocuses])

  const renderRowsLoader = (lines: number) =>
    new Array(lines).fill('').map((_, idx) => (
      <>
        <ScrollSuggestionNextSamplesLoader key={idx} />
        <br />
      </>
    ))

  return (
    <Box sx={{ position: 'relative' }}>
      <Input
        inputRef={inputRef}
        height={48}
        width={width}
        onChange={inputOnChange}
        onFocus={setInputIsFocuses}
        onBlur={setInputIsFocuses}
        variant='outlined'
        placeholder={placeholder}
        value={suggestionSampleSearching}
        InputProps={{
          startAdornment: (
            <InputAdornment position='end'>
              <IconButton disabled edge='start' sx={{ paddingRight: '0' }}>
                <HiOutlineSearch color='#ADB4C2' />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: suggestionsSampleQ.isLoading ? (
            <CircularProgress size={16} sx={{ position: 'absolute', right: '20px' }} />
          ) : null,
        }}
      />
      {(autocompleteIsHovered || inputIsFocuses) && possibleSamples && possibleSamples.length > 0 && (
        <StyledInfiniteScroll
          onMouseEnter={setAutocompleteIsHovered}
          onMouseLeave={setAutocompleteIsHovered}
          ref={scrollParentRef}
        >
          <InfiniteScroll
            hasMore={suggestionsSampleQ.hasNextPage}
            loadMore={suggestionsSampleQ.fetchNextPage as (page: number) => void}
            useWindow={false}
            getScrollParent={() => scrollParentRef.current}
          >
            {possibleSamples?.map((possibleSample, idx) => {
              return (
                <Typography
                  mt='9px'
                  mb={possibleSamples.length === idx ? '19px' : '0'}
                  variant='body1'
                  sx={{ cursor: 'pointer' }}
                  key={idx}
                  onClick={() => sampleOnClick(possibleSample)}
                >
                  <Typography
                    sx={{ display: 'inline-block' }}
                    component='span'
                    color='#0F1934'
                    variant='body1'
                  >
                    {possibleSample.slice(0, suggestionSampleSearching.length)}
                  </Typography>
                  <Typography
                    sx={{ display: 'inline-block' }}
                    component='span'
                    color='secondary.light'
                    variant='body1'
                  >
                    {possibleSample.slice(suggestionSampleSearching.length)}
                  </Typography>
                </Typography>
              )
            })}
            {suggestionsSampleQ.isFetchingNextPage && renderRowsLoader(10)}
          </InfiniteScroll>
        </StyledInfiniteScroll>
      )}
    </Box>
  )
})
