import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

export const getFormattedDate = (
  pattern: string,
  dateString?: string | null,
  timezone?: string | null
) => {
  return !timezone
    ? dateString && format(new Date(dateString), pattern)
    : dateString && formatInTimeZone(new Date(dateString), timezone, pattern)
}
