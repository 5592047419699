import React from 'react'
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useTheme,
} from '@mui/material'
import { StringParam, useQueryParam, useQueryParams } from 'use-query-params'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import { Button, Tooltip } from '@components/shared'
import { PageHeaderWrapper, Image, StyledClearAll, Link } from '@styles'
import addIcon from '@assets/icons/add-icon.svg'
import { ReactComponent as SearchIcon } from '@assets/icons/search-icon-blue.svg'
import CsvIcon from '@assets/icons/csv-icon-blue.svg'
import ZipIcon from '@assets/icons/zip-icon-blue.svg'
import XlsIcon from '@assets/icons/xls-icon.svg'
import XlsWhiteIcon from '@assets/icons/xls-white-icon.svg'
import { DESIRED_SAMPLES, DESIRED_SAMPLES_INSIDE_PACKAGE } from '@constants/samples'
import { selectIsSamplesListLoaded } from '@state/exportSamples'
import { getSampleQueryString } from '@helpers/api'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'
import { http } from '@services/http'
import { UrlManager } from '@helpers/url'

import { AutocompleteSearch } from '../AutocompleteSearch'
import { SamplesHeaderProps } from './sampleHeader.types'
import { ExportModal } from './components/ExportModal'

export const SamplesHeader: React.FC<SamplesHeaderProps> = React.memo(props => {
  const samplesListLoaded = useSelector(selectIsSamplesListLoaded)
  const {
    mb,
    isMobile,
    titleText,
    buttonText,
    btnLink,
    searchInsidePackage,
    variant: paragraphVariant,
    shouldHiddenActionBtn = false,
    shouldDisableActionBtn = false,
    shouldHiddenSearchField = false,
    shouldBeHiddenSearchFieldMobile,
    setShouldBeHiddenSearchFieldMobile,
    generateXLS,
    generateXLSButtonIsDisabled,
    tooltipTitle = '',
  } = props

  const { packageId } = useParams<{ packageId: string }>()
  const theme = useTheme()
  const { isTenantType } = useTenant()
  const isLegalDrugs = isTenantType(TENANT_TYPE.legalDrugs)

  const options = ['Export samples', 'Export attachments']
  const icons = [CsvIcon, ZipIcon]
  const endpoints = ['export_csv', 'export_attachments']

  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false)
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const [queryStringParam, setQueryStringParam] = useQueryParam(
    searchInsidePackage ? DESIRED_SAMPLES_INSIDE_PACKAGE : DESIRED_SAMPLES,
    StringParam
  )
  const [queryString] = useQueryParams({
    apis: StringParam,
    desired_samples: StringParam,
    excipients: StringParam,
    sample_status: StringParam,
    taskResult: StringParam,
    taskStatus: StringParam,
    assignees: StringParam,
  })

  const capitalizedTaskResult = queryString.taskResult
    ?.split(',')
    .map(item => {
      return item.charAt(0).toUpperCase() + item.slice(1)
    })
    .join(',')

  const searchQueries = {
    apis: queryString.apis,
    samples: queryString.desired_samples,
    excipients: queryString.excipients,
    sample_status: queryString.sample_status,
    taskResult: capitalizedTaskResult,
    task_status: queryString.taskStatus,
    assignees: queryString.assignees,
  }

  const filteredQueryString = getSampleQueryString(searchQueries)

  let assigneesObj: { inventory_manager: string[]; laboratory_assistant: string[] } = {
    inventory_manager: [],
    laboratory_assistant: [],
  }

  if (queryString.assignees) {
    queryString.assignees.split(',').map(item => {
      if (item.slice(item.indexOf('.') + 1) === '1') {
        assigneesObj.inventory_manager.push(item.substring(0, item.indexOf('.')))
      } else {
        assigneesObj.laboratory_assistant.push(item.substring(0, item.indexOf('.')))
      }
      return assigneesObj
    })
  }

  const isAssigneesExist =
    assigneesObj.inventory_manager?.length > 0 || assigneesObj.laboratory_assistant?.length > 0
  const assigneesJSON = isAssigneesExist && JSON.stringify(assigneesObj)
  const assigneeParam = `${isAssigneesExist ? '&assignees=' + assigneesJSON : ''}`

  const isTaskResultExist = queryString.taskResult?.length
  const taskResultParam = `${isTaskResultExist ? '&task_result=' + capitalizedTaskResult : ''}`

  const shouldHiddenSearch =
    shouldBeHiddenSearchFieldMobile && isMobile && !shouldHiddenSearchField
      ? true
      : !isMobile
      ? !shouldHiddenSearchField
      : false

  const { apiUrl } = new UrlManager()

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const clickOnExport = (endpoint: string) => {
    if (selectedIndex !== 0) {
      try {
        http.get<any[]>(
          `${apiUrl}samples/${endpoint}/?${filteredQueryString}${assigneeParam}${taskResultParam}`
        )
        handleOpenModal()
      } catch (err) {
        console.error(err)
      }
    } else {
      window.open(
        `${apiUrl}samples/${endpoint}/?${filteredQueryString}${assigneeParam}${taskResultParam}`,
        '_self'
      )
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            display: shouldBeHiddenSearchFieldMobile ? 'none' : 'inline-block',
            minWidth: '150px',
          }}
          variant={isMobile ? 'subtitle2' : paragraphVariant}
          mb={mb}
        >
          {titleText}
        </Typography>

        <PageHeaderWrapper $isMobile={isMobile}>
          {shouldHiddenSearch && (
            <AutocompleteSearch
              width={isMobile ? '100%' : '320px'}
              placeholder='Enter sample ID...'
              setQueryString={setQueryStringParam}
              queryString={queryStringParam}
              searchIn={searchInsidePackage ? 'samplesIntoPackage' : 'samples'}
              type={searchInsidePackage ? packageId : ''}
            />
          )}
          {isMobile && (
            <>
              {!shouldBeHiddenSearchFieldMobile ? (
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                  <IconButton
                    sx={{ width: '24px', height: '24px', padding: '18px' }}
                    onClick={setShouldBeHiddenSearchFieldMobile}
                  >
                    <SearchIcon fill={theme.palette.primary.main} />
                  </IconButton>
                </Box>
              ) : (
                <StyledClearAll
                  $alignSelf='center'
                  $marginLeft='8px'
                  onClick={setShouldBeHiddenSearchFieldMobile}
                  variant='outlined'
                >
                  Cancel
                </StyledClearAll>
              )}
            </>
          )}

          {generateXLSButtonIsDisabled && !isMobile && generateXLS && searchInsidePackage && (
            <Button
              bgColor={shouldHiddenActionBtn ? '' : '#FFFFFF'}
              ml='16px'
              onClick={generateXLS}
              variant={shouldHiddenActionBtn ? 'contained' : 'outlined'}
            >
              <img src={shouldHiddenActionBtn ? XlsWhiteIcon : XlsIcon} alt='xls icon' />

              <Typography color={shouldHiddenActionBtn ? '' : '#616D84'} ml='8px' variant='body1'>
                Download sample list
              </Typography>
            </Button>
          )}

          {!isMobile && isLegalDrugs && !searchInsidePackage && (
            <Box sx={{ position: 'relative', height: '48px', ml: '16px' }}>
              <ButtonGroup
                variant={'outlined'}
                ref={anchorRef}
                sx={{ height: '100%' }}
                disabled={!samplesListLoaded}
              >
                <Tooltip title={options[selectedIndex]}>
                  <Button
                    sx={{
                      justifyContent: 'flex-start',
                      width: '210px',
                      borderColor: '#3698E5!important',
                    }}
                    variant={'outlined'}
                    onClick={() => clickOnExport(endpoints[selectedIndex])}
                    bgColor={'#FFFFFF'}
                    color='primary'
                  >
                    <img src={icons[selectedIndex]} alt='icon' />
                    <Typography color='#3698E5' ml='8px' variant='body1'>
                      {options[selectedIndex]}
                    </Typography>
                  </Button>
                </Tooltip>

                <Button
                  size='small'
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label='select merge strategy'
                  aria-haspopup='menu'
                  bgColor={'#FFFFFF'}
                  color='primary'
                  sx={{
                    borderColor: '#3698E5!important',
                  }}
                  onClick={handleToggle}
                >
                  {open ? (
                    <ArrowDropUpIcon color='primary' />
                  ) : (
                    <ArrowDropDownIcon color='primary' />
                  )}
                </Button>
              </ButtonGroup>
              <Popper
                style={{ zIndex: 999, width: '100%' }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id='split-button-menu' autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              disabled={index === 2}
                              selected={index === selectedIndex}
                              onClick={event => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          )}

          {!shouldHiddenActionBtn && !isMobile && (
            <Tooltip isPureTooltip title={tooltipTitle} shouldBeHidden={!tooltipTitle}>
              <Link
                to={btnLink || ''}
                onClick={event => {
                  if (shouldDisableActionBtn) event.preventDefault()
                }}
                $isTextDecorationAbsence
              >
                <Button
                  variant='contained'
                  height='48px'
                  width='205px'
                  ml='16px'
                  disabled={shouldDisableActionBtn}
                  startIcon={
                    <Image src={addIcon} width='24px' height='24px' mr='2px' component='img' />
                  }
                >
                  {buttonText}
                </Button>
              </Link>
            </Tooltip>
          )}
        </PageHeaderWrapper>
      </Box>
      {isOpenModal && <ExportModal onClose={handleCloseModal} />}
    </>
  )
})
