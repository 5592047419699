import { Box, styled } from '@mui/material'

export const ImageBox = styled(Box)`
  display: flex;
  justify-content: center;
`

export const Container = styled(Box)`
  width: 480px;
`
