import { PureTooltip } from '@components/ui/PureTooltip'
import { ReactComponent as InfoIcon } from '@assets/icons/info.svg'

export const InfoIconSampleName = () => {
  return (
    <PureTooltip
      maxWidth={230}
      positionLeft={-5}
      title='Additional identifier provided by a user'
      titleStyle={null}
    >
      <InfoIcon width='16px' height='16px' style={{ marginLeft: 8, padding: '0' }} />
    </PureTooltip>
  )
}
