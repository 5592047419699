import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'

import { ReactComponent as InfoCircle } from '@assets/icons/info-circle-blue.svg'
import { StyledTableSampleCell } from '@styles'
import { ISample } from '@models/samples'
import { Tooltip } from '@components/shared'

interface TotalValueProps {
  report: ISample
  hideBorder?: boolean
}

export const TotalValue: React.FC<TotalValueProps> = ({ report, hideBorder }) => {
  const { price_per_unit, total_price } = report
  const theme = useTheme()

  const totalPrice = total_price ? Math.floor(total_price ? total_price : 1).toFixed(2) : '-'
  const pricePerUnit = price_per_unit && Number(price_per_unit).toFixed(2)

  return (
    <StyledTableSampleCell isClickable hideBorder={hideBorder}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography marginRight='8px' variant='body1'>
          ≈ {totalPrice}
        </Typography>
        <Typography marginRight='8px' color='#616D84' fontSize='14px' variant='body1'>
          USD
        </Typography>
        <Tooltip color='#616D84' title={`≈ ${pricePerUnit || '-'} USD per unit`}>
          <Box>
            <InfoCircle fill={theme.palette.primary.main} />
          </Box>
        </Tooltip>
      </Box>
    </StyledTableSampleCell>
  )
}
