import ContentLoader from 'react-content-loader'

export const TableLoader: React.FC = props => {
  return (
    <ContentLoader
      speed={1.8}
      width='100%'
      height='100%'
      viewBox='0 0 1426 946'
      backgroundColor='#f3f3f3'
      foregroundColor='#e3e3e3'
      {...props}
    >
      <path d='M 0 138 h 1426 v 2 H 0 z M 0 58 h 2 v 80 H 0 z M 1424 58 h 2 v 80 h -2 z' />
      <rect x='34' y='81' rx='4' ry='4' width='138' height='34' />
      <rect x='204' y='81' rx='4' ry='4' width='188' height='34' />
      <rect x='424' y='81' rx='4' ry='4' width='188' height='34' />
      <rect x='644' y='81' rx='4' ry='4' width='188' height='34' />
      <rect x='864' y='81' rx='4' ry='4' width='188' height='34' />
      <rect x='1084' y='81' rx='4' ry='4' width='310' height='34' />
      <path d='M 0 10 C 0 4.477 4.477 0 10 0 h 1406 c 5.52 0 10 4.477 10 10 v 9 H 0 v -9 z M 0 39 h 1426 v 19 H 0 z M 0 19 h 34 v 20 H 0 z M 172 19 h 32 v 20 h -32 z M 392 19 h 32 v 20 h -32 z M 612 19 h 32 v 20 h -32 z M 832 19 h 32 v 20 h -32 z M 1052 19 h 32 v 20 h -32 z M 1394 19 h 32 v 20 h -32 z' />
    </ContentLoader>
  )
}
