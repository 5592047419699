import React from 'react'
import { UseMutationResult } from 'react-query'
import { Typography, useMediaQuery } from '@mui/material'

import { Modal } from '@components/shared'
import { StyledModalTitle } from '@styles'
import { ContentOfPage } from '@constants/queries'
import { ITaskNote, IUpdatedTaskNote } from '@models/tasks'

import { EditTaskNotesField } from '../EditTaskNotesField'

interface EditNotesModalProps {
  sampleId: number | string
  taskId: number
  note?: ITaskNote
  contentType: ContentOfPage
  toggleEditing: () => void
  withoutTitle?: boolean
  updateTaskNotesM: UseMutationResult<unknown, unknown, IUpdatedTaskNote, unknown>
  isEditNotesTaskInfoInTest?: boolean
}

export const EditTaskNotesModal: React.FC<EditNotesModalProps> = props => {
  const {
    note,
    sampleId,
    taskId,
    toggleEditing,
    updateTaskNotesM,
    contentType,
    withoutTitle,
    isEditNotesTaskInfoInTest,
  } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const fontSizeOfTitle = isEditNotesTaskInfoInTest && !isMobile ? 'h3' : 'subtitle2'
  const marginForTitle = isEditNotesTaskInfoInTest && !isMobile ? '8px' : ''

  return (
    <Modal open onClose={toggleEditing} className='testing-modal'>
      <>
        <StyledModalTitle variant={fontSizeOfTitle} sx={{ marginBottom: '6px' }}>
          <b>Edit task notes</b>
          <Typography component='span' display='block' sx={{ marginTop: marginForTitle }}>
            for Sample ID {sampleId}
          </Typography>
        </StyledModalTitle>

        <EditTaskNotesField
          contentType={contentType}
          note={note}
          taskId={taskId}
          toggleEditing={toggleEditing}
          updateTaskNotesM={updateTaskNotesM}
          withoutTitle={withoutTitle}
          isEditNotesTaskInfoInTest={isEditNotesTaskInfoInTest}
        />
      </>
    </Modal>
  )
}
