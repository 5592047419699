import React from 'react'

import { ITaskLab, ITasksTabValue } from '@models/tasks'
import { StyledTableSampleCell } from '@styles'
import { TaskNotesField } from '@components/shared'

interface NotesProps {
  task: ITaskLab
  isMobile?: boolean
  contentType: ITasksTabValue
}

export const Notes: React.FC<NotesProps> = ({ task, isMobile, contentType }) => {
  return (
    <StyledTableSampleCell $isMobile={isMobile} sx={{ flexDirection: 'column' }}>
      <TaskNotesField
        isAbleToAddTaskNotes={task.is_able_to_add_task_notes}
        width={isMobile ? '' : '204px'}
        notes={task.task_notes}
        sampleId={task.sample}
        taskId={task.id}
        contentType={contentType}
      />
    </StyledTableSampleCell>
  )
}
