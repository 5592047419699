import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Autocomplete, Box, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useAppSelector } from '@hooks/redux'
import { ILaboratory } from '@models/common/app'
import { DESTINATION } from '@constants/packages'
import { selectUserLaboratory } from '@state/user'
import { useFetchLaboratories } from '@hooks/queries'
import { Input, AutocompleteWrapper, ParagraphLink } from '@components/shared'
import { StyledLabel, StyledLabIcon } from '@styles'
import { setLaboratoryForWorkspace } from '@state/workSpace'
import { protectedRoutes } from '@configs/routes.config'

interface DestinationFieldProps {
  requiredLaboratorySkills: Array<number>
}

export const DestinationField: React.FC<DestinationFieldProps> = ({ requiredLaboratorySkills }) => {
  const { setValue, trigger, register, formState } = useFormContext()
  const destinationError = formState.errors[DESTINATION]
  const [destination, setDestination] = React.useState<ILaboratory | null>(null)
  const [topScroll, setTopScroll] = React.useState<{ oldScrollTop: number; isScrollTop: boolean }>({
    oldScrollTop: 0,
    isScrollTop: false,
  })

  const { id: currentLaboratoryId } = useAppSelector(selectUserLaboratory)
  const { data, isFetching, fetchNextPage, hasNextPage } = useFetchLaboratories(
    destination?.name,
    undefined,
    requiredLaboratorySkills
  )

  const laboratoriesExceptCurrent =
    data?.pages
      .map(page => page.results)
      .flat()
      .filter(({ id }) => id !== currentLaboratoryId) || []

  const onChangeHandler = async (e: any, v: ILaboratory | null) => {
    await setDestination(v)
    if (v) await setValue(DESTINATION, v.id)
    trigger([DESTINATION])
  }
  const dispatch = useDispatch()
  const history = useHistory()
  const pageTitle = 'Your packages'

  const viewLaboratoryInfoEndPoint = () => {
    if (destination) {
      dispatch(setLaboratoryForWorkspace(destination))
    }

    history.replace({
      pathname: protectedRoutes.yourWorkspace.path,
      state: [
        {
          path: protectedRoutes.packages.path,
          url: protectedRoutes.packages.path,
          search: `?package_type=default`,
          title: pageTitle,
        },
      ],
    })
  }

  return (
    <>
      <StyledLabel
        mb='25px'
        label='Destination'
        control={
          <AutocompleteWrapper>
            <Autocomplete
              fullWidth
              size='small'
              loading={isFetching}
              value={destination}
              onChange={onChangeHandler}
              options={laboratoriesExceptCurrent}
              noOptionsText='Nothing found'
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              ListboxProps={{
                onScroll: event => {
                  const listboxNode = event.currentTarget

                  const isFetchNextPage =
                    hasNextPage &&
                    Math.round(listboxNode.scrollTop + listboxNode.clientHeight) ===
                      listboxNode.scrollHeight

                  if (isFetchNextPage) {
                    const top = Math.round(listboxNode.scrollHeight - listboxNode.clientHeight)
                    fetchNextPage()
                    setTopScroll({ oldScrollTop: top, isScrollTop: true })
                  }

                  if (listboxNode.scrollHeight > topScroll.oldScrollTop && topScroll.isScrollTop) {
                    listboxNode.scrollTo(0, topScroll.oldScrollTop)

                    setTopScroll(prev => ({ ...prev, isScrollTop: false }))
                  }
                },
              }}
              renderInput={params => (
                <Input
                  autoFocus
                  variant='outlined'
                  placeholder='Type here values...'
                  error={!!destinationError}
                  helperText={destinationError?.message}
                  {...params}
                  {...register(DESTINATION, {
                    setValueAs: () => destination?.id || null,
                  })}
                />
              )}
            />
          </AutocompleteWrapper>
        }
      />

      {destination && (
        <Box display='flex' alignItems='center' mb='35px'>
          <StyledLabIcon
            mr='8px'
            sx={{ position: 'static' }}
            color={destination.icon_text_color}
            bgColor={destination.icon_background_color}
            width={'32px'}
            top='6px'
          >
            <Typography fontSize={9} fontWeight={600}>
              {destination.icon_text}
            </Typography>
          </StyledLabIcon>

          <ParagraphLink onClick={viewLaboratoryInfoEndPoint}>{destination.name}</ParagraphLink>
        </Box>
      )}
    </>
  )
}
