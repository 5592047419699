import React from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useDispatch } from 'react-redux'

import { Button, Tooltip } from '@components/shared'
import { TooltipMobile } from '@components/shared/Tooltip/components'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_LENGTH_L } from '@constants/common'
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { StyledDetailsLabel, StyledEditIconButton, StyledParagraph, StyledTextCell } from '@styles'
import { useAppSelector } from '@hooks/redux'
import { editingNotesFieldId, editNotes } from '@state/samples'

interface ViewNotesFieldProps {
  notes?: string | null
  editDetailsNotes?: boolean
  toggleEditing: () => void
  withoutTitle?: boolean
  withoutTruncate?: boolean
  mr?: string
  id: number
  btnText?: string
  labelText?: string
  $left?: string
}

export const ViewNotesField: React.FC<ViewNotesFieldProps> = props => {
  const {
    notes,
    withoutTruncate,
    editDetailsNotes,
    toggleEditing,
    withoutTitle,
    id,
    btnText,
    labelText,
    mr,
    $left,
  } = props

  const dispatch = useDispatch()

  const { currentEditingFieldNotesId, isEditingNotes } = useAppSelector(editNotes)
  const isMobile = useMediaQuery('(max-width:767px)')
  const theme = useTheme()

  const editNotesHandler = React.useCallback(() => {
    dispatch(editingNotesFieldId(id))
    toggleEditing()
  }, [dispatch, id, toggleEditing])

  const isCurrentSampleEditing = currentEditingFieldNotesId === id && isEditingNotes

  const renderEditIconButton = () => (
    <>
      {isCurrentSampleEditing ? (
        <Box width='26px'>
          <CircularProgress size={16} />
        </Box>
      ) : (
        <StyledEditIconButton
          onClick={editNotesHandler}
          id='not-expand'
          padding='0'
          ml={withoutTitle ? '' : '8px'}
          $position={isMobile ? 'absolute' : ''}
          mr={mr}
          mt='-5px'
          $left={$left}
        >
          <IconButton>
            <EditIcon fill={theme.palette.primary.main} style={{ width: '16px', height: '16px' }} />
          </IconButton>
        </StyledEditIconButton>
      )}
    </>
  )

  const renderAddBtnCellInTable = () => (
    <>
      {isCurrentSampleEditing ? (
        <Box ml='auto' mr='auto'>
          <CircularProgress size={16} />
        </Box>
      ) : (
        <Button
          id='not-expand'
          variant='text'
          fz={14}
          lh='20px'
          onClick={editNotesHandler}
          startIconAdd
        >
          Add {btnText} note
        </Button>
      )}
    </>
  )

  const editNotesCellInTable = () => (
    <Tooltip
      isPureTooltip
      maxWidth={210}
      title={notes || ''}
      shouldBeHidden={Number(notes?.length) <= TRUNCATED_TEXT_LENGTH_L}
    >
      <StyledTextCell mr='0'>
        {notes ? <>{stringSlice(notes, TRUNCATED_TEXT_LENGTH_L)}</> : '-'}
      </StyledTextCell>
    </Tooltip>
  )

  const editNotesInDetails = () => (
    <>
      <Box display='flex' mb={1} position='relative'>
        {!withoutTitle && (
          <StyledDetailsLabel mb='0' variant='h5'>
            {labelText} notes
          </StyledDetailsLabel>
        )}

        {renderEditIconButton()}
      </Box>

      <StyledParagraph variant='body1'>{notes || '-'}</StyledParagraph>
    </>
  )

  const renderViewNotesField = () => (
    <Box>
      {isMobile ? (
        <TooltipMobile
          title={notes || ''}
          shouldBeHidden={(notes?.length || 0) <= TRUNCATED_TEXT_LENGTH_L}
        >
          <Typography variant='body1'>
            {notes ? stringSlice(notes, TRUNCATED_TEXT_LENGTH_L) : '-'}
          </Typography>
        </TooltipMobile>
      ) : (
        <>{withoutTruncate ? editNotesInDetails() : editNotesCellInTable()}</>
      )}
    </Box>
  )
  const hasEditIcon = (notes && !editDetailsNotes) || (editDetailsNotes && isMobile)

  return (
    <>
      {!notes && !editDetailsNotes ? renderAddBtnCellInTable() : renderViewNotesField()}

      {hasEditIcon && renderEditIconButton()}
    </>
  )
}
