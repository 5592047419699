import React from 'react'
import { MenuItem, useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'

import { selectedFilters as filters, editSelectedFilters } from '@state/filters'
import { useAppSelector } from '@hooks/redux'
import { Checkbox } from '@components/shared/Checkbox'
import { IStringOption, typeOfFilter } from '@models/filters'
import {
  REQUEST_STATUS,
  TASK_RESULT,
  TASK_STATUS,
  DELIVERY_STATUS,
  selectedFiltersSlice,
  SAMPLE_STATUS,
} from '@components/shared/FilterAside/constants'
import { Chip } from '@components/shared/Chip'
import { stringSlice } from '@helpers/ui/common'
import { Tooltip } from '@components/shared/Tooltip'
import { StyledRadio } from '@styles'

import * as Styled from './styles'

interface FilterCheckboxProps {
  item: IStringOption
  type: typeOfFilter
  labOwner?: boolean
}

export const FilterCheckbox: React.FC<FilterCheckboxProps> = React.memo(
  ({ item, type, labOwner }) => {
    const selectedFilters = useAppSelector(filters)
    const isMobile = useMediaQuery('(max-width:767px)')
    const dispatch = useDispatch()
    const filterNameRef = React.useRef<HTMLParagraphElement>(null)
    const isRadioBtnList = type === SAMPLE_STATUS

    const isChecked = selectedFilters[selectedFiltersSlice[type]].filters.find(({ id }) => {
      return id === item.id
    })

    const checkboxOnclick = () => {
      dispatch(editSelectedFilters({ type: selectedFiltersSlice[type], item, isRadioBtnList }))
    }

    const isListWithChipComponent =
      type === TASK_STATUS ||
      type === TASK_RESULT ||
      type === REQUEST_STATUS ||
      type === DELIVERY_STATUS ||
      type === SAMPLE_STATUS

    return (
      <MenuItem
        sx={{ padding: isMobile ? '0 24px' : '10px 0 10px 24px' }}
        key={item.id}
        onClick={() => checkboxOnclick()}
      >
        {isRadioBtnList ? (
          <StyledRadio padding='0' mr='6px' checked={!!isChecked} />
        ) : (
          <Checkbox sx={{ padding: '0px 9px 0 0' }} checked={!!isChecked} />
        )}

        <>
          {isListWithChipComponent ? (
            <Chip status={item.id} label={item.name} />
          ) : (
            <Tooltip
              isPureTooltip
              title={`${item.name} ${(labOwner && '(you)') || ''}`}
              shouldBeHidden={`${item.name} ${(labOwner && '(you)') || ''}`.length <= 20}
              maxWidth={200}
              positionLeft={25}
            >
              <Styled.FilterName ref={filterNameRef}>
                {stringSlice(`${item.name} ${(labOwner && '(you)') || ''}`, 20)}
              </Styled.FilterName>
            </Tooltip>
          )}
        </>
      </MenuItem>
    )
  }
)
