import { useQueries } from 'react-query'

import { IGroup } from '@models/common/app'
import { addAnotherTest } from '@api/samples'

export const useAddAnotherMultipleTests = (data: IGroup[]) => {
  return useQueries(
    data.map(anotherTest => ({
      queryKey: ['object', anotherTest.group],
      queryFn: () => addAnotherTest(anotherTest),
    }))
  )
}
