import React from 'react'

import { PureRadioButtonsPropTypes } from './pureRadioButtons.types'
import * as Styled from './styles'

export const PureRadioButtons: React.FC<PureRadioButtonsPropTypes> = props => {
  const {
    initialValue,
    name,
    data,
    onChange,
    direction = 'row',
    disabled = false,
    styles = {},
    labelStyles = {},
  } = props

  return (
    <Styled.Wrap $direction={direction} sx={styles}>
      {data.map((item, idx) => (
        <Styled.Label key={idx} $direction={direction} $disabled={disabled} style={labelStyles}>
          <input
            type='radio'
            name={name}
            disabled={disabled}
            defaultChecked={!!(initialValue && initialValue === item.id)}
            onChange={() => onChange(item.id)}
          />
          <span data-type='cup' />
          <span data-type='text'>{item.label}</span>
        </Styled.Label>
      ))}
    </Styled.Wrap>
  )
}
