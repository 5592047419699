import React from 'react'
import { Box } from '@mui/system'
import { useQueryClient } from 'react-query'
import { Autocomplete } from '@react-google-maps/api'

import { SubText } from '@styles'
import { IEditInfoLaboratoryData, IErrorsEditLaboratory } from '@models/yourWorkspace'
import { IError, ISkillsData } from '@models/common/app'
import { SkillsField, TenantSpecificComponent } from '@components/shared'
import { SKILLS } from '@constants/queries'
import { TENANT_TYPE } from '@constants/tenants'

import * as Styled from '../styles'
import { PhoneNumbers } from './fields/PhoneNumbers/PhoneNumbers'
import { Emails } from './fields/Emails/Emails'
import { InputField } from './fields/InputField'
import { ConfirmDeleteSkillsModal } from '../../ConfirmDeleteSkillsModal'

interface ContactsProps {
  apiErrorsEditLaboratory?: IError | null
  isConfirmDeleteSkillsModalOpen: boolean
  toggleConfirmDeleteSkillsModal: () => void
  editInfoLaboratoryData?: IEditInfoLaboratoryData | {}
}

export const Contacts: React.FC<ContactsProps> = props => {
  const {
    apiErrorsEditLaboratory,
    isConfirmDeleteSkillsModalOpen,
    toggleConfirmDeleteSkillsModal,
    editInfoLaboratoryData,
  } = props

  const apiErrorMessage: IErrorsEditLaboratory = apiErrorsEditLaboratory?.errorObject

  const queryClient = useQueryClient()
  const allSkills = queryClient.getQueryData<ISkillsData>(SKILLS)

  return (
    <>
      <Box mb='16px'>
        <Styled.Label>Address</Styled.Label>

        <Autocomplete>
          <InputField name='address' width='479px' placeholder='Start typing address' />
        </Autocomplete>
      </Box>

      <PhoneNumbers apiErrorMessage={apiErrorMessage} />

      <Box mb='16px'>
        <Styled.Label>
          Manager <SubText>(Default)</SubText>
        </Styled.Label>
        <InputField name='manager' isDisabled width='479px' />
      </Box>

      <Emails apiErrorMessage={apiErrorMessage} />

      <TenantSpecificComponent desiredTenant={TENANT_TYPE.legalDrugs}>
        <SkillsField apiErrorMessage={apiErrorMessage} skillList={allSkills?.results} />
      </TenantSpecificComponent>

      {isConfirmDeleteSkillsModalOpen && (
        <ConfirmDeleteSkillsModal
          onClose={toggleConfirmDeleteSkillsModal}
          editInfoLaboratoryData={editInfoLaboratoryData}
        />
      )}
    </>
  )
}
