import { styled } from '@mui/material/styles'

export const PhotoImg = styled('img')<{ width?: string; height?: string; mr?: string }>`
  border-radius: 4px;
  cursor: pointer;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-right: ${({ mr }) => mr || '0'};
  object-fit: cover;
`
