import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useController, useWatch } from 'react-hook-form'
import { useTheme } from '@mui/material'

import { PureDropdownAutocomplete } from '@components/ui'
import { ICurrencyOption } from '@models/common/app'
import { setCellValueToSample } from '@state/sampleCreate'
import { ReactComponent as InfoCircleIcon } from '@assets/icons/info-circle-blue.svg'
import { Tooltip } from '@components/shared/Tooltip'
import { DEFAULT_VALUE_TYPE_NAME, UNITS, VALUE, VALUE_TYPE } from '@constants/samples'

import { PureCellPropTypes } from './pureFields.types'

export const Value: React.FC<PureCellPropTypes> = ({
  name,
  cellName,
  dropdownCellName = '',
  initialVal,
  dropdownInitialVal,
  sampleId,
  options,
  rules,
  control,
  inputPlaceholder,
  isCopy,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  // Input react-hook-form controller
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules, control, defaultValue: initialVal.val })
  // Dropdown react-hook-form controller
  const { field: dropDownField } = useController({
    name: `${sampleId}_${dropdownCellName}`,
    control,
    defaultValue: initialVal.val,
  })
  // useWatch from react-hook-form for price calculate
  const [units, valueType, value] = useWatch({
    control,
    name: [`${sampleId}_${UNITS}`, `${sampleId}_${VALUE_TYPE}`, `${sampleId}_${VALUE}`],
  })

  const selectedValueType =
    options && (options.find(option => option.id === valueType) as ICurrencyOption)

  const [prevValueType, setPrevValueType] = React.useState<number>(valueType)

  React.useEffect(() => {
    const defaultValueType =
      options && options.find(option => option.name === DEFAULT_VALUE_TYPE_NAME)

    if (defaultValueType && !dropdownInitialVal?.val) {
      dispatch(
        setCellValueToSample({
          cellValue: { val: defaultValueType.id, valName: defaultValueType.name, error: '' },
          cellName: dropdownCellName,
          sampleId,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (error && error.message) {
      dispatch(
        setCellValueToSample({
          cellValue: { val: field.value, error: error.message },
          cellName,
          sampleId,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  React.useEffect(() => {
    if (prevValueType !== valueType) setPrevValueType(valueType)
  }, [valueType, prevValueType, selectedValueType])

  const totalCalculation = +(Math.floor(units) * value).toFixed(4)
  const calculationInUSD = +(
    value / (selectedValueType?.price ? +selectedValueType?.price : 1)
  ).toFixed(4)
  const totalCalculationInUSD = +(
    totalCalculation / (selectedValueType?.price ? +selectedValueType?.price : 1)
  ).toFixed(4)

  const onInputChange = (val: string) => {
    field.onChange({ target: { value: val } })

    dispatch(
      setCellValueToSample({
        cellValue: { val, error: '' },
        cellName,
        sampleId,
      })
    )
  }

  const onDropdownChange = (val: ICurrencyOption) => {
    dropDownField.onChange({ target: { value: val.id } })

    dispatch(
      setCellValueToSample({
        cellValue: { val: val.id, valName: val.name, error: '' },
        cellName: dropdownCellName as string,
        sampleId,
      })
    )
  }

  const dropdownDefaultUSDValue = options?.find(
    item => item.name === dropdownInitialVal?.valName
  )?.id

  const infoIconPosition = (() => {
    const valueLength = selectedValueType?.name.length
    if (valueLength) {
      if (valueLength > 3) return valueLength * 6 + 82
    }
    return 82
  })()

  return (
    <div style={{ position: 'relative' }}>
      {/* ----- INFO ICON WITH TOOLTIP ----- */}
      <Tooltip title={`≈ ${calculationInUSD} USD`} shouldBeHidden={!(!isNaN(value) && value > 0)}>
        <InfoCircleIcon
          fill={theme.palette.primary.main}
          style={{ position: 'absolute', top: 12, right: infoIconPosition, zIndex: 10 }}
        />
      </Tooltip>
      {/* ----- DROPDOWN ----- */}
      <PureDropdownAutocomplete
        isCopy={isCopy}
        name={name}
        isDropdownWithInput
        inputPlaceholder={inputPlaceholder}
        options={options || []}
        errorMessage={error?.message || ''}
        initialVal={dropdownDefaultUSDValue as number | null}
        inputInitialVal={initialVal.val as string}
        onInputChange={onInputChange}
        onDropdownChange={onDropdownChange}
      />
      {/* ----- TOTAL PRICE NOTIFICATION ----- */}
      {!isNaN(totalCalculation) && totalCalculation > 0 && (
        <p style={{ fontSize: '14px', marginTop: 13 }}>
          <span style={{ color: '#8F929B' }}>Total: </span>
          <span>
            {totalCalculation} {selectedValueType?.name}
          </span>
          {selectedValueType?.name !== DEFAULT_VALUE_TYPE_NAME && (
            <span style={{ color: '#8F929B' }}> (≈ {totalCalculationInUSD} USD)</span>
          )}
        </p>
      )}
    </div>
  )
}
