import { NavLink, NavLinkProps } from 'react-router-dom'
import { styled as styledMUI, Typography } from '@mui/material'
import styled from 'styled-components'

interface StyledNavProps extends NavLinkProps {
  $isTenantLegalDrugs?: boolean
}
export const NavTabs = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledTabs = styledMUI<React.FC<StyledNavProps>>(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.00938em;
  color: ${({ $isTenantLegalDrugs }) => ($isTenantLegalDrugs ? '#c2cadb' : '#616D84')};


  &:not(:last-child) {
    margin-right: 30px;
  }

  &.active {
    color: ${({ theme, $isTenantLegalDrugs }) =>
      $isTenantLegalDrugs ? theme.palette.common.white : theme.palette.primary.main};



    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: ${({ theme, $isTenantLegalDrugs }) =>
        $isTenantLegalDrugs ? theme.palette.common.white : theme.palette.primary.main};
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }
`

export const StyledTab = styledMUI(Typography)<{ $isLegalDrugs: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.00938em;
  line-height: 18.4px;
  color: ${({ $isLegalDrugs }) => ($isLegalDrugs ? '#c2cadb' : '#616D84')};

  &:not(:last-child) {
    margin-right: 30px;
  }
`
