import React from 'react'

import { IViewSampleCell } from '@models/samples/ui'
import { StyledTableSampleCell, StyledTableTitle } from '@styles'

interface TableSortButtonProps extends Omit<IViewSampleCell, 'id'> {
  hideBorder: boolean
}

export const TableColumnTitle: React.FC<TableSortButtonProps> = props => {
  const { content, hideBorder } = props

  return (
    <>
      <StyledTableSampleCell hideBorder={hideBorder} className='tableTasks'>
        <StyledTableTitle>{content}</StyledTableTitle>
      </StyledTableSampleCell>
    </>
  )
}
