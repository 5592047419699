import React from 'react'
import { FieldValues } from 'react-hook-form/dist/types/fields'

import { AddingTestModal } from '@components/modules/Modals/AddingTestModal'
import { MAX_TASKS_TO_ADD } from '@constants/tasks'
import { HrTestTypes, IAvailableTestTypesInfo } from '@models/yourWorkspace'
import { useAddAnotherMultipleTests } from '@hooks/queries/useAddTestsForMultipleSamples'
import { IGroup } from '@models/common/app'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'

import { ComponentForAnotherTestsCheck } from './ComponentForAnotherTestsCheck'
import { AddTestsPropTypes } from './addTests.types'

export const AddTests: React.FC<AddTestsPropTypes> = props => {
  const {
    tasks,
    expandedSamples,
    sampleId,
    selectedSamples = [],
    greatestTasksAmount,
    onClose,
  } = props

  const createFlagRef = React.useRef<boolean>(false)
  const [anotherData, setAnotherData] = React.useState<IGroup[]>([])
  const [mainTestData, setMainTestData] = React.useState<number[] | HrTestTypes[]>([])

  const addAnotherMultipleTestsM = useAddAnotherMultipleTests<number[] | HrTestTypes[]>(
    sampleId,
    expandedSamples
  )
  const { isTenantType } = useTenant()

  React.useEffect(() => {
    if (addAnotherMultipleTestsM.isSuccess) {
      createFlagRef.current = false
      setAnotherData([])
      setMainTestData([])
      addAnotherMultipleTestsM.reset()
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAnotherMultipleTestsM])

  const createTests = (data: number[] | HrTestTypes[]) => {
    if (!createFlagRef.current) {
      addAnotherMultipleTestsM.mutate({
        test_types: data,
        samples: sampleId ? [sampleId] : selectedSamples,
      })
      createFlagRef.current = true
    }
  }

  const correctTestsFormat = (data: { id: number; count: number; methods?: number[] }[]) => {
    if (data.length) {
      const allIds = data.map(({ id, count, methods }) => {
        const arrWithId = []
        if (isTenantType(TENANT_TYPE.streetDrugs) && methods?.length) {
          arrWithId.push({ id, methods: methods || [] })
        } else {
          for (let i = 0; i < count; i++) {
            if (isTenantType(TENANT_TYPE.streetDrugs)) {
              arrWithId.push({ id, methods: [] })
            } else {
              arrWithId.push(id)
            }
          }
        }
        return arrWithId
      })
      return allIds.flat()
    }
    return []
  }

  const continueCreating = (data: any[], mainData: any[]) => {
    let createdAnotherTests = []
    if (isTenantType(TENANT_TYPE.streetDrugs)) {
      createdAnotherTests = (data as IAvailableTestTypesInfo[]).map(test => ({
        id: test.id,
        methods: test.methods || [],
      }))
    } else {
      createdAnotherTests = (data as IAvailableTestTypesInfo[]).map(test => test.id)
    }
    if (mainData.length) {
      createTests([...mainData, ...createdAnotherTests])
    } else {
      createTests(createdAnotherTests as any)
    }
  }

  const handleCreateTests = (
    anotherData: FieldValues,
    testsData: { id: number; count: number; methods?: number[] }[]
  ) => {
    const data2Send = []
    for (let key in anotherData) {
      if (anotherData[key]) {
        const groupId = key.split('__')[1]
        data2Send.push({ group: +groupId, name: anotherData[key] })
      }
    }

    if (data2Send.length) {
      setMainTestData(correctTestsFormat(testsData) as any)
      setAnotherData(data2Send)
    } else {
      createTests(correctTestsFormat(testsData) as any)
    }
  }

  return (
    <>
      <AddingTestModal
        onClose={onClose}
        availableTestsToSet={MAX_TASKS_TO_ADD - (greatestTasksAmount || tasks?.length || 0)}
        handleCreateTests={handleCreateTests}
      />
      {!!anotherData.length && (
        <ComponentForAnotherTestsCheck
          anotherData={anotherData}
          continueCreating={data => continueCreating(data, mainTestData)}
        />
      )}
    </>
  )
}
