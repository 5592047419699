import { styled, Typography } from '@mui/material'

export const StyledTitle = styled(Typography)`
  text-align: center;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.palette.text.primary};
`

export const StyledTextBox = styled('div')`
  display: flex;
  justify-content: center;
  min-height: 24px;
`
export const StyledParagraph = styled(Typography)`
  text-align: center;
  margin-right: 6px;
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const StyledLink = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`
