import React from 'react'

import { NoSearchedParams } from '@components/shared'

import { StyledNoSearchedUsersBox } from './styles'

export const NoSearchedUsers: React.FC = () => {
  return (
    <StyledNoSearchedUsersBox>
      <NoSearchedParams text='No users with such parameters' />
    </StyledNoSearchedUsersBox>
  )
}
