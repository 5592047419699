import { http } from '@services/http'
import { ISavedFiltersFromUser } from '@models/common/user'

export const saveFilters = async (data: ISavedFiltersFromUser) => {
  try {
    return http.put('/users/save-filters/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}
