import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { Attachment, Button } from '@components/shared'
import { TRUNCATED_ATTACHMENTS_TASK_DETAILS_TEXT } from '@constants/common'
import { useFetchAttachments } from '@hooks/queries'
import { protectedRoutes } from '@configs/routes.config'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { selectTasks, setAddedAttachments, setIsEditAttachments } from '@state/tasks'
import { useFetchMutateAttachments } from '@hooks/queries/useFetchAttachments'
import { IAttachments } from '@models/attachments'
import { AddAttachedMaterialBox } from '@components/modules/PackageDetails/PackageDetailsContent/components/PackageInfo/PackageAttachments/components/AddAttachment/styles'
import { Paragraph } from '@styles'

import { EditAttachments } from './styles'

interface AttachmentsProps {
  taskId?: number
  mrForBtn?: string
  isAbleToEditTask?: boolean
}

export const Attachments: React.FC<AttachmentsProps> = ({
  taskId,
  mrForBtn,
  isAbleToEditTask = true,
}) => {
  const [attachments, setAttachments] = React.useState<IAttachments | undefined>([])
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false)
  const { isEditAttachments, addedAttachments } = useAppSelector(selectTasks)
  const theme = useTheme()

  const history = useHistory()
  const dispatch = useAppDispatch()

  const fetchMutateAttachments = useFetchMutateAttachments({
    content_type: 'task',
    object_id: taskId,
  })
  const fetchAttachmentsQ = useFetchAttachments({
    content_type: 'task',
    object_id: taskId,
    isFetch: isEditAttachments,
  })

  React.useEffect(() => {
    if (!isEditAttachments && !fetchAttachmentsQ.data && isEditMode) {
      fetchMutateAttachments.mutate()
      setIsEditMode(false)
    }
    if (!isEditAttachments && fetchAttachmentsQ.data) {
      setAttachments(fetchAttachmentsQ.data)
    } else if (fetchMutateAttachments.data && !isEditMode) {
      setAttachments(fetchMutateAttachments.data as IAttachments)
    }
  }, [fetchAttachmentsQ.data, fetchMutateAttachments, isEditAttachments, isEditMode])

  const attachMaterialsEndpoint = {
    pathname: protectedRoutes.attachResults.path
      .replace(':taskId', String(taskId))
      .replace(':modalType', 'results'),
    state: history.location.state,
  }

  const handleSetEditMode = () => {
    if (addedAttachments) dispatch(setAddedAttachments(null))
    dispatch(setIsEditAttachments(true))
    setIsEditMode(true)
  }

  return (
    <>
      {attachments?.map(attachment => (
        <Attachment
          truncatedTextLength={TRUNCATED_ATTACHMENTS_TASK_DETAILS_TEXT}
          cantDelete
          key={attachment.id}
          attachment={attachment}
          mr={mrForBtn}
        />
      ))}

      {isAbleToEditTask && !!attachments?.length && (
        <EditAttachments
          to={attachMaterialsEndpoint}
          $isTextDecorationAbsence
          onClick={handleSetEditMode}
        >
          <EditIcon fill={theme.palette.primary.main} />
          <Typography
            color='primary.main'
            sx={{ marginLeft: '12px' }}
            variant='body1'
            fontWeight={500}
          >
            Edit
          </Typography>
        </EditAttachments>
      )}

      {isAbleToEditTask && !attachments?.length && (
        <Box>
          <AddAttachedMaterialBox
            to={attachMaterialsEndpoint}
            $isTextDecorationAbsence
            onClick={handleSetEditMode}
          >
            <Button id='not-expand' variant='text' fz={14} lh='20px' startIconAdd>
              Add new material
            </Button>
          </AddAttachedMaterialBox>
          <Paragraph variant='body2' color='secondary.light'>
            All popular format of image, microsoft document, PDF and spreadsheet
          </Paragraph>
        </Box>
      )}
      {!isAbleToEditTask && !attachments?.length && <Box>-</Box>}
    </>
  )
}
