import React from 'react'

import { Button } from '@components/shared'
import { useGenerateLabels } from '@hooks/queries'
import { MAX_SELECTED_SAMPLE_NUMBER } from '@constants/samples'

interface DownloadLabelsProps {
  selectedSamples: number[]
  selectedSamplesAmount: number
}

export const DownloadLabels: React.FC<DownloadLabelsProps> = props => {
  const { selectedSamples, selectedSamplesAmount } = props

  const generateSampleLabelsM = useGenerateLabels()

  const downloadLabelsHandler = () => {
    generateSampleLabelsM.mutate({ ids: selectedSamples, entity: 'samples' })
  }

  return (
    <Button
      variant='outlined'
      height='48px'
      mr='16px'
      bgColor='#ffffff'
      textColor='#616D84'
      disabled={generateSampleLabelsM.isLoading}
      loading={generateSampleLabelsM.isLoading}
      onClick={downloadLabelsHandler}
    >
      {selectedSamplesAmount > 1 ? 'Download labels' : 'Download label'} ({selectedSamplesAmount}/
      {MAX_SELECTED_SAMPLE_NUMBER})
    </Button>
  )
}
