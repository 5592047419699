import styled from 'styled-components'
import { Link as LinkMUI, LinkProps as LinkMUIProps, Typography } from '@mui/material'
import { Link as RouterLink, LinkProps as LinkRouterProps } from 'react-router-dom'
import React from 'react'

interface PureLinkProps {
  $mb?: string
  $isDeactivate?: boolean
  $isBoldFirstElement?: boolean
  $isShouldToAddStyles?: boolean
  $isTextDecorationAbsence?: boolean
}

type LinkWithRouterProps = LinkRouterProps & PureLinkProps
type LinkProps = LinkMUIProps & PureLinkProps

export const Link = styled<React.FC<LinkWithRouterProps>>(RouterLink)`
  margin-bottom: ${({ $mb }) => $mb || ''};
  margin-top: ${({ $isShouldToAddStyles }) => ($isShouldToAddStyles ? '16px' : '')};
  font-weight: normal;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: ${({ theme, $isDeactivate }) =>
    $isDeactivate ? theme.palette.secondary.light : theme.palette.primary.main};
  text-decoration: ${({ $isTextDecorationAbsence }) =>
    $isTextDecorationAbsence ? 'none' : 'underline'};
  width: fit-content;

  &:first-child {
    font-weight: ${({ $isBoldFirstElement }) => ($isBoldFirstElement ? 'bold' : 'normal')};
  }
`

export const PureLink = styled<React.FC<LinkProps>>(LinkMUI)`
  margin-bottom: ${({ $mb }) => $mb || ''};
  margin-top: ${({ $isShouldToAddStyles }) => ($isShouldToAddStyles ? '16px' : '')};
  font-weight: normal;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: ${({ theme, $isDeactivate }) =>
    $isDeactivate ? theme.palette.secondary.light : theme.palette.primary.main};
  text-decoration: ${({ $isTextDecorationAbsence }) =>
    $isTextDecorationAbsence ? 'none' : 'underline'};
  width: fit-content;

  &:first-child {
    font-weight: ${({ $isBoldFirstElement }) => ($isBoldFirstElement ? 'bold' : 'normal')};
  }
`

export const PureFile = styled<React.FC<LinkProps>>(Typography)`
  margin-bottom: ${({ $mb }) => $mb || ''};
  margin-top: ${({ $isShouldToAddStyles }) => ($isShouldToAddStyles ? '16px' : '')};
  font-weight: normal;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: ${({ theme, $isDeactivate }) =>
    $isDeactivate ? theme.palette.secondary.light : theme.palette.primary.main};
  width: fit-content;

  &:first-child {
    font-weight: ${({ $isBoldFirstElement }) => ($isBoldFirstElement ? 'bold' : 'normal')};
  }
`
