import React from 'react'

import { SubText } from '@styles'
import { IOption } from '@models/common/app'

interface WeightProps {
  weight: string
  dosage_type: IOption
}

export const Weight: React.FC<WeightProps> = ({ weight, dosage_type }) => {
  const parsedWeight = parseFloat(weight)

  return (
    <>
      <span>
        {parsedWeight || parsedWeight === 0 ? (
          <>
            {parsedWeight}
            <SubText sx={{ marginLeft: '8px', lineHeight: '20px', fontSize: 14 }}>
              {dosage_type?.name}
            </SubText>
          </>
        ) : (
          '-'
        )}
      </span>
    </>
  )
}
