import React from 'react'
import { Box } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { Chip, TaskNotesField } from '@components/shared'
import { StyledDetailsTableCell } from '@styles'
import { TASKS } from '@constants/queries'
import { EditTestResult } from '@components/shared/EditTestResult'

import { ApiContent, Attachments } from './components'
import { TestingDate } from './components/TestingDate'
import { StyledDetailsTableRow } from './styles'

interface TaskRowProps {
  task: ITaskLab
  setIsResultsModalOpen: () => void
}

export const TaskRow: React.FC<TaskRowProps> = ({ task, setIsResultsModalOpen }) => {
  return (
    <>
      <StyledDetailsTableRow>
        <StyledDetailsTableCell sx={{ display: 'flex', flexDirection: 'column' }}>
          <TestingDate task={task} />
        </StyledDetailsTableCell>

        <StyledDetailsTableCell>
          <Box>
            <ApiContent task={task} buttonOnClick={setIsResultsModalOpen} />
          </Box>
        </StyledDetailsTableCell>

        <StyledDetailsTableCell>
          <Box>
            <Chip label={task?.result_name} status={task?.result.key} />
            {task.is_able_to_edit_task && <EditTestResult buttonOnClick={setIsResultsModalOpen} />}
          </Box>
        </StyledDetailsTableCell>

        <StyledDetailsTableCell
          sx={{ paddingRight: '17.2px', paddingLeft: '17.2px', display: 'block' }}
        >
          <Box sx={{ marginRight: '50px' }}>
            <TaskNotesField
              isAbleToAddTaskNotes={task.is_able_to_add_task_notes}
              notes={task.task_notes}
              sampleId={task.sample}
              taskId={task.id}
              contentType={TASKS}
            />
          </Box>
        </StyledDetailsTableCell>

        <StyledDetailsTableCell
          sx={{
            flexDirection: 'column',
          }}
        >
          <Attachments
            taskId={task?.id}
            mrForBtn={'25px'}
            isAbleToEditTask={task.is_able_to_edit_task}
          />
        </StyledDetailsTableCell>
      </StyledDetailsTableRow>
    </>
  )
}
