import { styled, Typography } from '@mui/material'

export const StyledTitle = styled(Typography)`
  text-align: center;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.palette.text.primary};
`

export const StyledParagraph = styled(Typography)`
  text-align: center;
  margin-bottom: 24px;
  color: #616d84;
`

export const StyledImg = styled('img')`
  width: 102px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
`
