import React from 'react'
import { Box, Icon, Typography, useTheme } from '@mui/material'

import { ISample } from '@models/samples'
import { SubText, StyledTableSampleCell } from '@styles'
import { unitIconSDeterminer } from '@helpers/ui/typeOfUnits'

interface UnitsProps {
  report: ISample
  hideBorder?: boolean
}

export const Units: React.FC<UnitsProps> = ({ report, hideBorder }) => {
  const { units, unit_type } = report
  const theme = useTheme()

  const UnitIconType = unitIconSDeterminer(unit_type?.id || 1)

  return (
    <StyledTableSampleCell isClickable hideBorder={hideBorder}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{units}</Typography>
        {unit_type && (
          <>
            <SubText sx={{ marginLeft: '8px', lineHeight: '20px', fontSize: 14 }}>
              {unit_type.name.toLocaleLowerCase()}
            </SubText>
            <Icon sx={{ marginLeft: '5px' }}>
              <UnitIconType fill={theme.palette.primary.main} stroke={theme.palette.primary.main} />
            </Icon>
          </>
        )}
      </Box>
    </StyledTableSampleCell>
  )
}
