import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'

import { ISample } from '@models/samples'
import { useToggle } from '@hooks/common'
import { CardRow, CardTitleCell, MobileCollapseRow } from '@styles'
import {
  APIsCell,
  ExcipientsCell,
  Dosage,
  DrugClass,
  ExpirationDateCell,
  Manufacturer,
  Notes,
  AddressOfManufacturer,
  RegistrationStatus,
} from '@components/modules/PackageDetails/PackageDetailsContent'
import { Brand } from '@components/shared'

import { MobileCell } from '../MobileCell'

interface RowProps {
  isMobile: boolean
  sample: ISample
  actionBtnText?: 'Remove' | 'Missed'
  shouldDisplayActionBtn?: boolean
  shouldDisplayMissedText?: boolean
}

export const Row: React.FC<RowProps> = props => {
  const { isMobile, sample, actionBtnText, shouldDisplayActionBtn, shouldDisplayMissedText } = props
  const [openCard, setOpenCard] = useToggle()

  const rows = [
    { id: 1, title: 'Manufacturer', component: Manufacturer },
    { id: 2, title: 'Brand', component: Brand },
    { id: 2, title: 'Address of manufacturer', component: AddressOfManufacturer },
    { id: 3, title: 'Sample notes', component: Notes },
    { id: 4, title: 'Drug class', component: DrugClass },
    { id: 5, title: 'Dosage', component: Dosage },
    { id: 6, title: 'APIs', component: APIsCell },
    { id: 7, title: 'Excipients', component: ExcipientsCell },
    { id: 8, title: 'Expiration date', component: ExpirationDateCell },
    { id: 9, title: 'Regulatory regist. status', component: RegistrationStatus },
  ]

  const openCardHandler = (e: React.SyntheticEvent<Element, Event>) => {
    const notExpandElement = (e.target as HTMLButtonElement).closest('#not-expand')
    const notExpandedPortal = (e.target as HTMLParagraphElement).closest('[role=presentation]')

    if (notExpandElement || notExpandedPortal) {
      return
    }

    setOpenCard()
  }

  return (
    <>
      <MobileCell
        sample={sample}
        isMobile={isMobile}
        actionBtnText={actionBtnText}
        shouldOpenCard={openCardHandler}
        openCard={openCard}
        shouldDisplayActionBtn={shouldDisplayActionBtn}
        shouldDisplayMissedText={shouldDisplayMissedText}
      />

      <MobileCollapseRow>
        <Collapse in={openCard} timeout='auto' unmountOnExit>
          <Box sx={{ backgroundColor: '#fff', paddingBottom: '36px', width: '100%' }}>
            {rows.map(({ id, title, component: Component }) => (
              <CardRow key={id}>
                <CardTitleCell>
                  <Typography variant='h5'>{title}</Typography>
                </CardTitleCell>

                <Box sx={{ width: '100%' }}>
                  <Component
                    sample={sample}
                    brand={sample.brand?.name}
                    direction='column'
                    isMobile={isMobile}
                  />
                </Box>
              </CardRow>
            ))}
          </Box>
        </Collapse>
      </MobileCollapseRow>
    </>
  )
}
