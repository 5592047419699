import React from 'react'
import { TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Control, RegisterOptions, useController } from 'react-hook-form'

import { DatePicker as StyledDatePicker } from '@components/shared'
import { IOption } from '@models/common/app'
import { setCellValueToSample } from '@state/sampleCreate'
import { MANUFACTURE_DATE } from '@constants/samples'

import { IPlaceOption } from '../GMPlaceAutocomplete'

interface DatePickerProps {
  name: string
  isCopy?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  disableFuture?: boolean
  disablePast?: boolean
  control?: Control
  sampleId: string
  cellName: string
  initialVal: {
    val: number | null | IOption | string | IOption[] | Date | IPlaceOption
    error: string
  }
}

export const DatePicker: React.FC<DatePickerProps> = React.memo(props => {
  const {
    name,
    isCopy,
    rules,
    disableFuture,
    disablePast,
    initialVal,
    control,
    sampleId,
    cellName,
  } = props
  const [storeError, setStoreError] = React.useState<string>('')
  const dispatch = useDispatch()
  const {
    field,
    fieldState: { isTouched, error },
  } = useController({ name, control, rules, defaultValue: initialVal.val })

  React.useEffect(() => {
    field.onChange(field.value)
  }, [field.value, field])

  const setCellValue = (val: Date, error: string) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val, error },
        cellName,
        sampleId,
      })
    )
  }

  React.useEffect(() => {
    if (initialVal.error) setStoreError(initialVal.error)
    if (isCopy && !initialVal.error && cellName === MANUFACTURE_DATE) {
      field.onChange({ target: { value: field.value } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setCellValue(field.value, error?.message || '')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <StyledDatePicker
      height={42}
      disableFuture={disableFuture}
      disablePast={disablePast}
      onOpen={field.onBlur}
      bgColor={isCopy && !isTouched ? '#FFF5D6' : '#fff'}
      renderInput={params => (
        <TextField
          {...params}
          error={!!error || !!storeError}
          onBlur={field.onBlur}
          placeholder='MM/DD/YYYY'
          helperText={error?.message || storeError}
          value={params.inputProps?.value}
          inputProps={{ onChange: () => false }}
          disabled
          sx={{
            '& .MuiOutlinedInput-root.Mui-disabled fieldset': {
              borderColor: error || storeError ? '#AF4645' : '',
            },
          }}
        />
      )}
      {...field}
      onChange={date => {
        field.onChange({ target: { value: date } })
        setCellValue(date as Date, '')
      }}
    />
  )
})
