import { ReactNode } from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export const LoaderEditInfo = (
  props: JSX.IntrinsicAttributes & IContentLoaderProps & { children?: ReactNode }
) => (
  <ContentLoader
    speed={2}
    width={286}
    height={628}
    viewBox='0 0 286 628'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    {...props}
  >
    <rect x='0' y='0' rx='7' ry='7' width='76' height='14' />
    <rect x='0' y='70' rx='7' ry='7' width='76' height='14' />
    <rect x='197' y='59' rx='10' ry='10' width='89' height='31' />
    <rect x='1' y='117' rx='5' ry='5' width='23' height='23' />
    <rect x='38' y='116' rx='7' ry='7' width='196' height='14' />
    <rect x='1' y='157' rx='5' ry='5' width='23' height='23' />
    <rect x='38' y='156' rx='7' ry='7' width='196' height='14' />
    <rect x='1' y='197' rx='5' ry='5' width='23' height='23' />
    <rect x='38' y='196' rx='5' ry='5' width='132' height='10' />
    <rect x='38' y='214' rx='7' ry='7' width='132' height='14' />
    <rect x='38' y='245' rx='5' ry='5' width='132' height='10' />
    <rect x='38' y='263' rx='7' ry='7' width='132' height='14' />
    <rect x='1' y='295' rx='5' ry='5' width='23' height='23' />
    <rect x='38' y='294' rx='5' ry='5' width='132' height='10' />
    <rect x='38' y='312' rx='7' ry='7' width='196' height='14' />
    <rect x='1' y='344' rx='5' ry='5' width='23' height='23' />
    <rect x='38' y='343' rx='5' ry='5' width='132' height='10' />
    <rect x='38' y='361' rx='7' ry='7' width='196' height='14' />
    <rect x='0' y='419' rx='7' ry='7' width='76' height='14' />
    <rect x='1' y='466' rx='5' ry='5' width='23' height='23' />
    <rect x='38' y='465' rx='7' ry='7' width='132' height='14' />
    <rect x='1' y='506' rx='5' ry='5' width='23' height='23' />
    <rect x='1' y='546' rx='5' ry='5' width='23' height='23' />
    <rect x='38' y='505' rx='7' ry='7' width='132' height='14' />
    <rect x='38' y='545' rx='7' ry='7' width='132' height='14' />
    <rect x='0' y='601' rx='7' ry='7' width='234' height='14' />
  </ContentLoader>
)
