import { Box, Typography } from '@mui/material'

import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'

import { FooterBox } from './styles'

const linkSX = {
  color: '#182A62',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    color: '#505C88',
  },
  '&:visited': {
    color: '#182A62',
  },
}

export const FooterMobile = () => {
  const { isTenantType } = useTenant()
  return (
    <FooterBox className='mobile-footer'>
      {isTenantType(TENANT_TYPE.streetDrugs) ? (
        <Typography>{new Date().getFullYear()}. All rights reserved</Typography>
      ) : (
        <Typography>
          Copyright &copy; {new Date().getFullYear()} ARTiFACTS.{' '}
          <Box
            component={'a'}
            href='https://artifacts.ai/privacy-policy/'
            target='_blank'
            sx={linkSX}
          >
            Privacy Policy
          </Box>{' '}
          and{' '}
          <Box
            component={'a'}
            href='https://artifacts.ai/terms-of-use/'
            target='_blank'
            sx={linkSX}
          >
            Terms and Conditions
          </Box>
        </Typography>
      )}
    </FooterBox>
  )
}
