import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { updateSample } from '@api/samples'
import { showNotification } from '@helpers/app'
import { IUpdatedSample } from '@models/samples'
import { notifications } from '@constants/notifications'
import { SAMPLES, SAMPLE_TASKS } from '@constants/queries'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useUpdateSample = () => {
  const dispatch = useDispatch()
  const client = useQueryClient()

  const mutation = useMutation<unknown, IError, IUpdatedSample>(updateSample, {
    onSuccess: (data, vars) => {
      showNotification(notifications.samplesUpdatedSuccessfully)
      client.invalidateQueries(['samplesBlockchainHistory'])
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
      showNotification(notifications.samplesUpdatedFailed)
    },
    onSettled: () => {
      client.invalidateQueries(SAMPLES)
      client.invalidateQueries(SAMPLE_TASKS)
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
