import { styled as styledMUI, Box, Typography, BoxProps } from '@mui/material'
import styled from 'styled-components'
import React from 'react'

export const StyledModalScroll = styledMUI(Box)<{ height: string }>`
  height: ${({ height }) => height};
  overflow: auto;
  width: 530px;
  padding: 24px 4px 25px 24px;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #e5e9f1;
    border-radius: 72px;
    margin: 24px 0;
  }

  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #adb4c2;
  }
`

export const Label = styledMUI(Typography)`
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1a2233;
`

interface FieldsBoxProps extends BoxProps {
  $isErrorForAllFields?: boolean
  $isError?: boolean
}

export const StyledBox = styledMUI<React.FC<FieldsBoxProps>>(Box)`
  width: 100%;
  padding: ${({ $isErrorForAllFields }) => ($isErrorForAllFields ? '0.5px' : '0')};
  border-radius: 4px;
  border: ${({ $isErrorForAllFields }) => ($isErrorForAllFields ? '1px solid #AF4645' : 'none')};
`

export const FieldsBox = styledMUI<React.FC<FieldsBoxProps>>(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: ${({ $isErrorForAllFields }) => ($isErrorForAllFields ? '0px' : '24px')};
  }
`

export const LabelCheckboxSkillsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const LabelCheckboxSkills = styled.span`
  margin-left: 5px;
`

export const StyledTextSkills = styled.span`
  color: #8f929b;
`

export const StyledParagraphNumber = styled.div`
  height: 18px;
  width: 18px;
  background-color: #eaf8fc;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #076aa2;
`

export const StyledBoxRemoveSkill = styledMUI<React.FC<FieldsBoxProps>>(Box)`
  padding: 24px; 
  border: 1px solid #E5E9F1;
  border-radius: 8px;
  box-shadow: -5px 0px 15px rgb(53, 108, 151, 0.12);
  color: #616D84;
  width: 83%;
`

export const StyledTypographyRemoveSkill = styledMUI(Typography)`
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`
