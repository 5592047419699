import { IconButton, IconButtonProps, styled } from '@mui/material'

interface StyledIconButtonProps extends IconButtonProps {
  $isPhotoInModal?: boolean
}

export const StyledIconButton = styled<React.FC<StyledIconButtonProps>>(IconButton)`
  width: 40px;
  height: 40px;
  margin-left: 4px;
  background-color: ${({ theme }) => theme.additional.chosen};
  position: absolute;
  right: ${({ $isPhotoInModal }) => ($isPhotoInModal ? '83px' : '153px')};
  top: ${({ $isPhotoInModal }) => ($isPhotoInModal ? '25px' : '93px')};

  & > svg {
    fill: ${({ theme }) => theme.additional.icons};
  }

  &:hover {
    background-color: ${({ theme }) => theme.additional.chosen};

    & > svg {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }
`
