import { HiOutlineSearch } from 'react-icons/hi'
import { IconButton, InputAdornment } from '@mui/material'
import { StringParam, useQueryParam } from 'use-query-params'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { Input } from '@components/shared'
import addIcon from '@assets/icons/add-icon.svg'
import disabledAddIcon from '@assets/icons/disabled-add-icon.svg'
import { setUserForEditing } from '@state/workSpace'

import { AddIcon, StyledBox, StyledButton } from './styles'

interface TableHeaderProps {
  setEditUserIsOpen: (arg1: boolean) => void
  buttonDisable: boolean
}

export const TableHeader: React.FC<TableHeaderProps> = ({ setEditUserIsOpen, buttonDisable }) => {
  const [, setSearchingQueryString] = useQueryParam('desired_user', StringParam)
  const dispatch = useDispatch()

  useEffect(() => {
    setSearchingQueryString(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const inputOnChangeDebounced = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) setSearchingQueryString(e.target.value)
    else setSearchingQueryString(undefined)
  }, 300)

  const addUserHandler = () => {
    setEditUserIsOpen(true)
    dispatch(setUserForEditing(undefined))
  }

  return (
    <StyledBox>
      <StyledButton disabled={buttonDisable} onClick={addUserHandler}>
        <AddIcon color='red' src={buttonDisable ? disabledAddIcon : addIcon} alt='add user' />
        Add user
      </StyledButton>
      <Input
        height={48}
        width='350px'
        onChange={inputOnChangeDebounced}
        variant='outlined'
        placeholder='Enter user name...'
        bgColorDefault
        InputProps={{
          startAdornment: (
            <InputAdornment position='end'>
              <IconButton disabled edge='start' sx={{ paddingRight: '0' }}>
                <HiOutlineSearch color='#ADB4C2' />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </StyledBox>
  )
}
