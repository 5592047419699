import { ITasksTabValue } from '@models/tasks'

import { StyledTabPanel } from './styles'
import { AwaitingTasks, CompletedTasks } from './components'

interface ITab {
  component: React.FC
  value: ITasksTabValue
}

export const TabContent = () => {
  const tabs: ITab[] = [
    { component: AwaitingTasks, value: 'awaiting' },
    { component: CompletedTasks, value: 'completed' },
  ]

  const renderTab = ({ component: Tab, value }: ITab) => (
    <StyledTabPanel key={value} value={value}>
      <Tab />
    </StyledTabPanel>
  )

  return <>{tabs.map(tab => renderTab(tab))}</>
}
