import { Box, styled } from '@mui/material'

export const FixedTableHead = styled(Box)`
  position: sticky;
  right: 0px;

  padding: 12px 0 12px 32px;
  border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};
  background-color: ${({ theme }) => theme.palette.common.white};
  z-index: 100;
  box-shadow: -15px 0 15px -5px rgba(53, 108, 151, 0.12);
  border-top-left-radius: 10px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 0;
    border-color: transparent ${({ theme }) => theme.palette.background.default} transparent;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0.8px;
    right: 1px;
    width: 17px;
    height: 17px;
    border-radius: 60%;
    background-color: #ffffff;
  }
`
