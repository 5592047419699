import React from 'react'

import { ISubsample, ISubsamples } from '@models/samples'

import { Tabs } from './Tabs'
import { Header } from './Header'
import { StyledSubSampleBox } from './styles'

interface SubSampleInfoProps {
  subSamples: ISubsamples
  sampleId: string
  specificId?: string
}

export const SubSampleInfo: React.FC<SubSampleInfoProps> = ({
  subSamples,
  sampleId,
  specificId,
}) => {
  const unsplittedSubSamples = subSamples.filter(({ splitted }) => !splitted)
  const defaultSubsample = unsplittedSubSamples[0]

  const [selectedSubSample, setSelectedSubSample] = React.useState<ISubsample | undefined>(
    defaultSubsample
  )

  return (
    <StyledSubSampleBox>
      <Header
        subSamples={unsplittedSubSamples}
        selectedSubSample={selectedSubSample}
        onSubsampleChange={setSelectedSubSample}
      />
      <Tabs specificId={specificId} sampleId={selectedSubSample?.id || Number(sampleId)} />
    </StyledSubSampleBox>
  )
}
