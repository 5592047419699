import React from 'react'

type F = (boxRef: React.MutableRefObject<HTMLDivElement | null>, rootMargin: string) => boolean

export const useActionsStickyBox: F = (boxRef, rootMargin) => {
  const [isSticky, setIsSticky] = React.useState(false)

  const options = React.useMemo(
    () => ({
      root: null,
      rootMargin,
    }),
    [rootMargin]
  )

  const createObserver: IntersectionObserverCallback = entries => {
    const [entry] = entries
    setIsSticky(entry.isIntersecting)
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver(createObserver, options)
    const boxRefObserve = boxRef.current

    if (boxRefObserve) {
      observer.observe(boxRefObserve)
    }

    return () => {
      if (boxRefObserve) {
        observer.unobserve(boxRefObserve)
      }
    }
  }, [boxRef, isSticky, options])

  return isSticky
}
