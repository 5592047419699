import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'

import { fetchDrugClassesF } from '@api/samples'
import { DRUG_CLASSES } from '@constants/queries'
import { IOptionData } from '@models/samples'

export const useFetchDrugClassesFilters = (
  desiredDrugClass: string | undefined
): UseInfiniteQueryResult<IOptionData> => {
  return useInfiniteQuery([DRUG_CLASSES, desiredDrugClass], fetchDrugClassesF, {
    getNextPageParam: lastPage => {
      if (lastPage.current_page < lastPage.count_pages) return lastPage.current_page + 1
      return false
    },
  })
}
