import {
  ISample,
  IAssigneeData,
  INewSamples,
  ISampleTask,
  ISamplesData,
  IUpdatedSample,
  ISampleTaskData,
  INewSampleOption,
  ISplitSampleVars,
  ISampleLabelsData,
  IMissedSampleData,
  IUpdatedSampleTasks,
  IDeleteSamplesInsidePackage,
  IOptionData,
} from '@models/samples'
import { http } from '@services/http'
import { IChoice, IGroup, IOption, ISearchSuggestionsData } from '@models/common/app'
import { getSampleQueryString } from '@helpers/api'
import { SAMPLES_AMOUNT_LOADING_LIMIT } from '@constants/samples'
import { IAddExtraTask, IDeleteExtraTask } from '@models/tasks'
import {
  AddressesTypes,
  IPlaceOption,
  PlaceForSaveType,
} from '@components/shared/sampleFields/GMPlaceAutocomplete'
import { IAssigneeFilters, IAssigneesFilterWithTypeOfLab } from '@models/filters'
import { FILTER_COUNT } from '@components/shared/FilterAside/constants'
import { IAvailableTestTypesInfo } from '@models/yourWorkspace'

// * [Samples

export const fetchSamples = <T>(queryKey: any) => {
  const { queryKey: queryKeyNested, pageParam: page = 1 } = queryKey
  const [
    ,
    samples,
    ordering,
    free_samples,
    apis,
    excipients,
    assignees,
    task_status,
    taskResult,
    sample_status,
    physical_form,
    laboratories,
    started_collection_date,
    initial_laboratory,
  ] = queryKeyNested

  const searchQueries = {
    page,
    limit: SAMPLES_AMOUNT_LOADING_LIMIT,
    samples,
    ordering,
    free_samples,
    apis,
    excipients,
    task_status,
    sample_status,
    physical_form,
    laboratories,
    started_collection_date,
    initial_laboratory,
  }

  const queryString = getSampleQueryString(searchQueries)

  const isAssigneesExist =
    assignees.inventory_manager.length > 0 || assignees.laboratory_assistant.length > 0
  const assigneesJSON = isAssigneesExist && JSON.stringify(assignees)
  const assigneeParam = `${isAssigneesExist ? '&assignees=' + assigneesJSON : ''}`

  const isTaskResultExist = taskResult?.length
  const taskResultParam = `${isTaskResultExist ? '&task_result=' + taskResult : ''}`

  try {
    const samplesEndpoint = `/samples/?${queryString}${assigneeParam}${taskResultParam}`
    return http.get<ISamplesData<T>>(samplesEndpoint)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchSample = <T>(sampleId: string) => {
  try {
    return http.get<T>(`/samples/${sampleId}/`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createSamples = async (samples: INewSamples) => {
  try {
    return http.post('/samples/', samples)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateSample = async ({ id, ...sample }: IUpdatedSample) => {
  try {
    return http.patch(`/samples/${id}/`, sample)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const splitSample = ({ id, data }: ISplitSampleVars) => {
  try {
    return http.post(`/samples/${id}/split/`, data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const generateSampleLabels = (data: ISampleLabelsData) => {
  try {
    return http.post<Blob, ISampleLabelsData>(`/samples/generate_pdf/`, data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const makeRetest = (sampleId: number) => {
  try {
    return http.patch<ISample, {}>(`/samples/${sampleId}/retest/`, {})
  } catch (error) {
    return Promise.reject(error)
  }
}

export const searchSuggestionsSamples = ({ queryKey, pageParam = 1 }: any) => {
  try {
    return http.get<ISearchSuggestionsData>(
      `/samples/specific_id_suggestions/?suggestion_start=${queryKey[0]}&page=${pageParam}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const searchSuggestionsAddSamples = ({ queryKey, pageParam }: any) => {
  try {
    return http.get<ISearchSuggestionsData>(
      `/samples/specific_id_suggestions/?suggestion_start=${queryKey[0]}&free_samples=true${
        pageParam ? '&page=' + pageParam : ''
      }`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

// * [Sample options

export const fetchApisF = ({ pageParam = 1, ...payload }) => {
  const desiredFilter = payload.queryKey[1]

  const url = `?limit=${FILTER_COUNT}&page=${pageParam}`
  try {
    return http.get<IOptionData>(`/apis/${url}${desiredFilter ? '&search=' + desiredFilter : ''}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchExcipientsF = ({ pageParam = 1, ...payload }) => {
  const desiredFilter = payload.queryKey[1]

  const url = `?limit=${FILTER_COUNT}&page=${pageParam}`
  try {
    return http.get<IOptionData>(
      `/excipients/${url}${desiredFilter ? '&search=' + desiredFilter : ''}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchAssigneePersonF = ({ pageParam = 1, ...payload }) => {
  const [, desiredFilter] = payload.queryKey

  const url = `?limit=${FILTER_COUNT}&page=${pageParam}`

  const searchParam = desiredFilter ? '&username=' + desiredFilter : ''

  try {
    return http.get<IAssigneeFilters>(`/users/assignees/${url}${searchParam}`)
  } catch (error) {
    return Promise.reject(error)
  }
}
export const fetchAllAssigneesPersons = ({ pageParam = 1, ...payload }) => {
  const [, desiredFilter] = payload.queryKey

  const url = `?page=${pageParam}&limit=1`

  const searchParam = desiredFilter ? '&username=' + desiredFilter : ''

  try {
    return http.get<IAssigneesFilterWithTypeOfLab>(`/laboratories/assignees/${url}${searchParam}`)
  } catch (error) {
    return Promise.reject(error)
  }
}
export const fetchBrands = () => {
  try {
    return http.get<IOption[]>('/brands/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addNewBrand = async (brandName: string) => {
  try {
    return http.post<IOption, INewSampleOption>('/brands/', { name: brandName })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchCountries = () => {
  try {
    return http.get<IOption[]>('/countries/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchDrugClassesF = ({ pageParam = 1, ...payload }) => {
  const desiredFilter = payload.queryKey[1]
  const url = `?limit=${FILTER_COUNT}&page=${pageParam}`

  try {
    return http.get<IOptionData>(
      `/drug_classes/${url}${desiredFilter ? '&search=' + desiredFilter : ''}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchManufacturerAddresses = () => {
  try {
    return http.get<AddressesTypes[]>('/manufacturer_addresses/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchManufacturers = ({ pageParam = 1, ...payload }) => {
  const desiredOption = payload.queryKey[1]
  const url = `?limit=${FILTER_COUNT}&page=${pageParam}`

  try {
    return http.get<IOptionData>(
      `/manufacturers/${url}${desiredOption ? '&search=' + desiredOption : ''}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchManufacturersF = ({ pageParam = 1, ...payload }) => {
  const desiredFilter = payload.queryKey[1]

  const url = `?limit=${FILTER_COUNT}&page=${pageParam}`
  try {
    return http.get<IOptionData>(
      `/manufacturers/${url}${desiredFilter ? '&search=' + desiredFilter : ''}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addNewManufacturer = async (manufacturerName: string) => {
  try {
    return http.post<IOption, INewSampleOption>('/manufacturers/', { name: manufacturerName })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addAnotherTest = async (data: IGroup) => {
  try {
    return http.post<IAvailableTestTypesInfo, IGroup>('/test_types/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addTasksTest = async (data: any) => {
  try {
    return http.post<IAvailableTestTypesInfo[], IGroup>('/samples/tasks/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchPharmacies = () => {
  try {
    return http.get<IOption[]>('/pharmacies/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addNewPharmacy = async (pharmacyName: string) => {
  try {
    return http.post<IOption, INewSampleOption>('/pharmacies/', { name: pharmacyName })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addNewAddressManufacturer = async ({ description, place_id }: PlaceForSaveType) => {
  try {
    return http.post<IPlaceOption, PlaceForSaveType>('/manufacturer_addresses/', {
      description,
      place_id,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchTowns = () => {
  try {
    return http.get<AddressesTypes[]>('/towns/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addNewTowns = async ({ description, place_id }: PlaceForSaveType) => {
  try {
    return http.post<IPlaceOption, PlaceForSaveType>('/towns/', {
      description,
      place_id,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchDosageType = () => {
  try {
    return http.get<IOption[]>('/dosage_types/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchValueType = () => {
  try {
    return http.get<IOption[]>('/currencies/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchToxicityLevel = () => {
  try {
    return http.get<IChoice[]>('/choices/toxicity_level/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchRegulatoryRegistrationsType = () => {
  try {
    return http.get<IOption[]>('/regulatory_registrations/')
  } catch (error) {
    return Promise.reject(error)
  }
}

// * Sample options]

// * [Sample tasks

export const fetchTasksBySampleId = (sampleId: number, status?: string) => {
  const taskStatus = status ? '&status=' + status : ''
  try {
    return http.get<ISampleTaskData>(`/tasks/?sample_id=${sampleId}${taskStatus}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchAllowExtraTask = (sampleId: number) => {
  try {
    return http.get<{ allow: boolean }>(`/samples/${sampleId}/extra_task/`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addExtraTaskBySampleId = ({ sampleId, testTypeGroupId }: IAddExtraTask) => {
  try {
    return http.post<ISampleTask, {}>(
      `/samples/${sampleId}/extra_task/?test_type_group_id=${testTypeGroupId}`,
      {}
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteExtraTask = ({ taskId }: IDeleteExtraTask) => {
  try {
    return http.delete(`/tasks/${taskId}/`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchAssigneePerson = ({ pageParam = 1, ...payload }) => {
  const [, sampleId, searchVal] = payload.queryKey
  const url = `?is_active=true&sample_id=${sampleId}&page=${pageParam}&limit=50`
  const searchParam = searchVal ? '&username=' + searchVal : ''

  try {
    return http.get<IAssigneeData>(`/users/assignees/${url}${searchParam}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateTasksAssignee = (task: IUpdatedSampleTasks) => {
  const { assignee, assignee_role, taskId, test_type, sample_id } = task

  try {
    return http.patch(
      `/tasks/${taskId}/`,
      test_type ? { test_type, sample_id } : { assignee, assignee_role }
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
// * Sample tasks]

// * [Sample inside package

export const fetchSamplesInsidePackage = <T>(queryKey: any) => {
  const { queryKey: queryKeyNested, pageParam: page = 1 } = queryKey
  const [, packageId, samples, ordering] = queryKeyNested

  const searchQueries = { limit: SAMPLES_AMOUNT_LOADING_LIMIT, page, samples, ordering }
  const queryString = getSampleQueryString(searchQueries)

  try {
    return http.get<ISamplesData<T>>(`/packages/${packageId}/samples/?${queryString}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteSamplesInsidePackage = ({
  packageId,
  sample_ids,
}: IDeleteSamplesInsidePackage) => {
  try {
    return http.delete(`/packages/${packageId}/samples/`, { sample_ids })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const markSampleAsMissed = async ({ packageId, sampleId }: IMissedSampleData) => {
  try {
    return http.patch(`/packages/${packageId}/samples/`, { sample_id: sampleId })
  } catch (error) {
    return Promise.reject(error)
  }
}

// * Sample inside package]
