import { useMutation, useQueryClient } from 'react-query'

import { INCOMING_REQUESTS, SAMPLES } from '@constants/queries'
import { useAppDispatch } from '@hooks/redux'
import { selectSamples } from '@state/samples'
import { createPackages } from '@api/packages'
import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'
import { ICreatePackagesData, IPackage } from '@models/packages'
import { selectRequests } from '@state/requests'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useCreatePackages = (createPackageFrom: 'samples' | 'requests') => {
  const dispatch = useAppDispatch()
  const client = useQueryClient()

  const mutation = useMutation<IPackage, IError, ICreatePackagesData>(createPackages, {
    onSuccess: ({ specific_id: packageSpecificId }, vars) => {
      dispatch(selectSamples([]))

      showNotification({
        ...notifications.createPackagesSuccessfully,
        title: `Package ID = ${packageSpecificId} was created, you can send it now`,
      })

      if (createPackageFrom === 'requests') dispatch(selectRequests([]))
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
      showNotification(notifications.createPackagesFailed)
    },
    onSettled: () => {
      if (createPackageFrom === 'samples') client.invalidateQueries(SAMPLES)
      else client.invalidateQueries(INCOMING_REQUESTS)
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
