import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { TASKS } from '@constants/queries'
import { fetchTask } from '@api/tasks'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export function useFetchTask<T>(taskId: number) {
  const dispatch = useDispatch()
  return useQuery<unknown, IError, T>([TASKS, taskId], fetchTask.bind(null, taskId), {
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
