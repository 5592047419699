import { IApis, IOption } from '@models/common/app'

export const stringSlice = (string: string, length: number): string => {
  return string.length <= length ? string : `${string.slice(0, length)}...`
}

export const getApisWithType = (apis: IApis) => {
  const combineApisWithType = (apis: IOption[], type: 'main' | 'optional') => {
    return apis.map(main => ({ type, ...main }))
  }

  const { main_apis: mainApis = [] } = apis

  const mainApisWithType = combineApisWithType(mainApis, 'main')
  const allApisWithType = [...mainApisWithType]

  return { mainApisWithType, allApisWithType }
}

export const truncateArray = (arr: string[], maxLength: number) => {
  let currentLength = 0

  return arr.slice().map(str => {
    if (currentLength + str.length <= maxLength) {
      currentLength += str.length
      return str
    } else {
      const remainingSpace = maxLength - currentLength
      if (remainingSpace > 0) {
        currentLength += remainingSpace
        return str.slice(0, remainingSpace)
      }
      return ''
    }
  })
}
