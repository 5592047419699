import React from 'react'
import { Typography } from '@mui/material'

import { Chip } from '@components/shared'
import { ISampleTask } from '@models/samples'
import { COMPLETED, NOT_COMPLETED, RETEST_AT_ND } from '@constants/tasks'
import { ContentType } from '@models/common/ui'

interface TaskResultProps {
  task: ISampleTask
  contentType: ContentType
}

export const TaskResult: React.FC<TaskResultProps> = ({ task, contentType }) => {
  const isRetestAtNd = task.result?.value === RETEST_AT_ND && contentType === 'samples'

  if (task.test_type.is_other) return null

  return (
    <>
      {task.result?.key !== NOT_COMPLETED && task.status.key === COMPLETED ? (
        <Chip
          status={isRetestAtNd ? RETEST_AT_ND : task.result?.key}
          label={isRetestAtNd ? RETEST_AT_ND : task.result_name}
        />
      ) : (
        <Typography>-</Typography>
      )}
    </>
  )
}
