import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

import {
  InitialStateTypes,
  ValueForSampleTypes,
  CopySampleTypes,
  IInitialSample,
} from './sampleCreateState.types'
import { sampleCreateInitialSamplesData } from './sampleCreateInitialSamplesData'

const initialState: InitialStateTypes = {
  samples: [],
  showNotification: false,
}

export const sampleCreateSlice = createSlice({
  name: 'sampleCreate',
  initialState,
  reducers: {
    setCellValueToSample: (state, { payload }: PayloadAction<ValueForSampleTypes>) => {
      state.samples = state.samples.map(item => {
        if (item.id === payload.sampleId) {
          return { ...item, [payload.cellName]: payload.cellValue }
        }
        return item
      })
    },
    createSample: (state, { payload }: PayloadAction<string>) => {
      state.samples.unshift({ id: payload, ...sampleCreateInitialSamplesData })
    },
    copySample: (state, { payload }: PayloadAction<CopySampleTypes>) => {
      const sampleCopies: IInitialSample[] = [...cloneDeep(state.samples)]
      const newIds = payload.newIds.map(newId => {
        const clonedSample = cloneDeep(state.samples.find(item => item.id === payload.copySampleId))
        if (clonedSample) {
          clonedSample.id = newId
          clonedSample.isCopy = true
        }
        return clonedSample
      }) as IInitialSample[]
      state.samples = [...newIds, ...sampleCopies]
    },
    deleteSample: (state, { payload }: PayloadAction<string>) => {
      state.samples = state.samples.filter(item => item.id !== payload)
    },
    cleanStore: (state, { payload }: PayloadAction<string>) => {
      state.samples = [{ id: payload, ...sampleCreateInitialSamplesData }]
    },
    setCreateSampleNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.showNotification = payload
    },
  },
})

export const {
  reducer: sampleCreateReducer,
  actions: {
    setCellValueToSample,
    createSample,
    copySample,
    deleteSample,
    cleanStore,
    setCreateSampleNotification,
  },
} = sampleCreateSlice
