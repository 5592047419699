import { Box, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { useToggle } from '@hooks/common'
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from '@components/shared/ExpandableExcipients/styles'
import { TandemMSEditResults } from '@components/harmModules/HrTasksDetails'
import { useAppSelector } from '@hooks/redux'
import { isLoading, updatedTestId } from '@state/test'

import { TandemMSResultsProps } from '../tandemMS.types'
import { TandemMSResultsItems } from './TandemMSResultsItems'

export const TandemMSResults: React.FC<TandemMSResultsProps> = props => {
  const { substanceResult, marginTop, task, contentType } = props
  const theme = useTheme()

  const [isExpanded, toggleExpanded] = useToggle()
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false)
  const isTestUpdating = useAppSelector(isLoading)
  const testUpdatedId = useAppSelector(updatedTestId)

  const checkSubstanceLength = substanceResult && substanceResult.length <= 4 ? 4 : 3

  const prunedResults = substanceResult?.slice(0, checkSubstanceLength)
  const restResults = substanceResult?.filter(item => !prunedResults?.includes(item))
  const isOneResult = prunedResults?.length === 1 || !prunedResults?.length

  const showButtonText = isExpanded ? 'Show less' : `Show more`

  const editTestHandler = () => {
    if (isTestUpdating) return
    setIsOpenEditModal(true)
  }
  const isEditLoading = task?.id === testUpdatedId && isTestUpdating

  return (
    <Box display='flex' flexDirection='column'>
      <StyledAccordion square elevation={0} disableGutters id='not-expand' expanded={isExpanded}>
        <StyledAccordionSummary direction='column' sx={{ cursor: 'default' }} $flexGrow='0'>
          <TandemMSResultsItems
            substanceResult={prunedResults}
            marginBottom={contentType === 'completed' ? '10px' : '37px'}
          />
        </StyledAccordionSummary>

        <StyledAccordionDetails
          borderBottom='none'
          direction='column'
          $marginBottom='0'
          sx={{ marginTop }}
        >
          <TandemMSResultsItems substanceResult={restResults} marginBottom='0' />
        </StyledAccordionDetails>
      </StyledAccordion>

      {/* show more/less button */}
      {substanceResult && substanceResult.length > checkSubstanceLength && (
        <Typography
          mt='12px'
          color='primary.main'
          sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
          onClick={toggleExpanded}
        >
          {showButtonText}
        </Typography>
      )}

      {contentType === 'completed' && (
        <Box onClick={editTestHandler} display='flex' mt={isOneResult ? '1px' : '12px'}>
          <EditIcon
            fill={isEditLoading ? theme.palette.secondary.light : theme.palette.primary.main}
            style={{ cursor: isEditLoading ? 'default' : 'pointer' }}
          />
          <Typography
            color={isEditLoading ? 'secondary.light' : 'primary.main'}
            sx={{ marginLeft: '12px', cursor: isEditLoading ? 'default' : 'pointer' }}
            variant='body1'
            fontWeight={500}
          >
            Edit
          </Typography>
        </Box>
      )}

      {isOpenEditModal && task && (
        <TandemMSEditResults
          task={task}
          onClose={() => {
            setIsOpenEditModal(false)
          }}
          isEditFromCompletedTable
        />
      )}
    </Box>
  )
}
