import ContentLoader from 'react-content-loader'

export const Loader: React.FC = props => {
  return (
    <div>
      <ContentLoader
        speed={2}
        width={340}
        height={270}
        viewBox='0 0 340 270'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}
      >
        <rect x='0' y='0' rx='4' ry='4' width='305' height='28' />
        <rect x='0' y='60' rx='4' ry='4' width='87' height='20' />
        <rect x='0' y='95' rx='4' ry='4' width='54' height='20' />
        <rect x='0' y='139' rx='4' ry='4' width='112' height='20' />
        <rect x='0' y='176' rx='4' ry='4' width='214' height='16' />
        <rect x='0' y='202' rx='4' ry='4' width='305' height='16' />
        <rect x='0' y='228' rx='4' ry='4' width='261' height='16' />
        <rect x='0' y='254' rx='4' ry='4' width='340' height='16' />
      </ContentLoader>
    </div>
  )
}
