import React from 'react'

import { TableCells } from '../TableCells'
import { StyledTableRow } from '../../styles'

interface TableRowProps {
  baseName: string
  counter: number
  isCopy: boolean
  sampleIdx: number
  isLastSample?: boolean
}

export const TableRow: React.FC<TableRowProps> = React.memo(props => (
  <StyledTableRow>
    <TableCells {...props} />
  </StyledTableRow>
))
