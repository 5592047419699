import { Box, styled } from '@mui/material'

export const DropdownInputContainer = styled(Box)`
  position: relative;

  & .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e1e7f3;
  }
`

export const DropdownContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  &:after {
    content: '';
    position: absolute;
    background-color: #e1e7f3;
    width: 1px;
    height: 24px;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
  }

  & .MuiInput-underline {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
`
