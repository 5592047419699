import { styled } from '@mui/material'

export const Wrapper = styled('div')`
  position: relative;
  display: initial;

  &:hover .pure-tooltip {
    display: block;
  }
`
export const TooltipWrapper = styled('div')`
  z-index: 1500;
  position: absolute;
  padding: 16px;
  width: auto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: #616d84;
  border: 1px solid #d9dde4;
  border-radius: 4px;
  background: #fff;
  box-shadow: 1px 12px 20px rgba(103, 122, 170, 0.08);
  opacity: 0;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    width: 100%;
    height: 18px;
  }
  &:after {
    position: absolute;
    content: '';
    left: 50%;
    width: 10px;
    height: 10px;
    border-right: 1px solid #d9dde4;
    border-bottom: 1px solid #d9dde4;
    background: #fff;
  }
  &.placement-top {
    &:before {
      bottom: -18px;
    }
    &:after {
      bottom: -6px;
      transform: translateX(-50%) rotate(45deg);
    }
  }
  &.placement-bottom {
    &:before {
      top: -18px;
    }
    &:after {
      top: -6px;
      transform: translateX(-50%) rotate(-135deg);
    }
  }
`
