import React from 'react'

import { TRUNCATED_ATTACHMENTS_SAMPLE_DETAILS_TEXT } from '@constants/common'
import { Attachment } from '@components/shared'
import { ISampleTask } from '@models/samples'
import { StyledDetailsTableCell } from '@styles'

interface AttachmentsProps {
  task: ISampleTask
  mrForBtn?: string
}

export const Attachments: React.FC<AttachmentsProps> = ({ task, mrForBtn }) => {
  const { attachments } = task

  return (
    <StyledDetailsTableCell
      sx={{
        paddingRight: '17.2px',
        paddingLeft: '17.2px',
        flexDirection: 'column',
      }}
    >
      {!!attachments.length
        ? attachments?.map(attachment => (
            <Attachment
              truncatedTextLength={TRUNCATED_ATTACHMENTS_SAMPLE_DETAILS_TEXT}
              cantDelete
              key={attachment.id}
              attachment={attachment}
              mr={mrForBtn}
            />
          ))
        : '-'}
    </StyledDetailsTableCell>
  )
}
