import React from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Typography, useMediaQuery } from '@mui/material'

import { StyledDetailsBox, StyledDetailsPageBox } from '@styles'
import { Breadcrumbs } from '@components/shared'
import { protectedRoutes } from '@configs/routes.config'
import { IBreadcrumbsRoute } from '@models/common/app'
import { isContainRoute } from '@helpers/routes'

interface PageHeaderProps {
  taskId: string
}

export const PageHeader: React.FC<PageHeaderProps> = ({ taskId }) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const { state } = useLocation<IBreadcrumbsRoute[]>()
  const { path, url } = useRouteMatch()
  const history = useHistory()

  const pageTitle = `${protectedRoutes.tasksDetails.name} ${taskId}`

  React.useEffect(() => {
    if (state && !isContainRoute(state, url)) {
      history.replace({ state: [...state, { path, url, title: pageTitle }] })
    }
  }, [history, pageTitle, path, state, url])

  return (
    <>
      <StyledDetailsPageBox>
        <StyledDetailsBox>
          <Typography variant={isMobile ? 'h3' : 'h1'}>Task details</Typography>
        </StyledDetailsBox>
      </StyledDetailsPageBox>

      <Breadcrumbs mb={isMobile ? '24px' : '48px'} />
    </>
  )
}
