interface DateBoxProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  bgColor?: string
  width?: string
  height?: number
  mr?: string
  m?: string
}

export const DateBox: React.FC<DateBoxProps> = props => <div {...props} />
