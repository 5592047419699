import { Typography } from '@mui/material'

interface InconclusiveResultProps {
  mb?: string
  testTypeName?: string
}

export const InconclusiveResult: React.FC<InconclusiveResultProps> = ({ mb, testTypeName }) => {
  return (
    <Typography variant='body1' color='secondary.light' mb={mb}>
      {`Inconclusive ${testTypeName} result`}
    </Typography>
  )
}
