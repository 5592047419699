import React from 'react'
import { ChipProps as MUIChipProps, useMediaQuery } from '@mui/material'

import { CANCELED, PICKED_UP, UNPACKED, WAS_SPLIT } from '@constants/packages'
import {
  AWAITING,
  COMPLETED,
  FAILED,
  IN_PROGRESS,
  NEGATIVE,
  PASSED,
  POSITIVE,
  RETEST_AT_ND,
  HIDDEN,
  SUSPICIOUS,
  OKAY,
  INCONCLUSIVE,
} from '@constants/tasks'
import {
  ACCEPTED_REQUEST_STATUS,
  REJECTED_REQUEST_STATUS,
  NOT_FOUND_REQUEST_STATUS,
  BOUGHT_REQUEST_STATUS,
} from '@constants/requests'
import { IN_TEST, NEW, TESTED } from '@constants/samples'

import { IconChip, StyledChip, StyledChipMain } from './styles'

type TChipColor =
  | 'primary'
  | 'default'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'secondary'
  | undefined

interface ChipProps extends MUIChipProps {
  label?: string
  status?: string | null
}

// task/package/test reports/test status only
export const Chip: React.FC<ChipProps> = props => {
  const { status, label, ...restProps } = props
  let chipColor: TChipColor
  let labelText

  const isAdditionalStatus = status === CANCELED || status === WAS_SPLIT
  const isMobile = useMediaQuery('(max-width:767px)')

  switch (status) {
    case AWAITING:
    case RETEST_AT_ND:
    case UNPACKED:
    case NEW:
      chipColor = 'info'
      break
    case IN_PROGRESS:
    case IN_TEST:
      chipColor = 'warning'
      break
    case COMPLETED:
    case PASSED:
    case POSITIVE:
    case PICKED_UP:
    case ACCEPTED_REQUEST_STATUS:
    case BOUGHT_REQUEST_STATUS:
    case OKAY:
    case TESTED:
      chipColor = 'success'
      break

    case FAILED:
    case NEGATIVE:
    case REJECTED_REQUEST_STATUS:
    case NOT_FOUND_REQUEST_STATUS:
    case SUSPICIOUS:
      chipColor = 'error'
      break
    case HIDDEN:
    case INCONCLUSIVE:
    case 'methodChip':
      chipColor = 'secondary'

      break
    default:
      chipColor = 'default'
      break
  }

  return (
    <StyledChip
      $isLightGrey={status === INCONCLUSIVE}
      $isMethodChip={status === 'methodChip'}
      $isAdditionalStatus={isAdditionalStatus}
      label={label ? label : labelText}
      color={chipColor}
      $isMobile={isMobile}
      {...restProps}
    />
  )
}

// main/optional apis only
interface ChipApiProps {
  label: string
  type: string
}

export const ChipApi: React.FC<ChipApiProps> = ({ label, type }) => {
  const isMainChip = type === 'main'
  const isExcipientsChip = type === 'excipients'

  return (
    <StyledChipMain
      $isMainChip={isMainChip || isExcipientsChip}
      icon={<IconChip $isMainChip={isMainChip} />}
      label={label}
    />
  )
}
