import * as React from 'react'
import { v4 as uuid } from 'uuid'

import { DataIcon, StyledMobileBox } from '@styles'
import infoIcon from '@assets/icons/info-circle.svg'
import { useToggle } from '@hooks/common'
import { InfoIconTruncate } from '@components/shared'

import { StyledTooltip } from './StyledTooltip'
import { TooltipProps } from '../tooltip.types'

// tooltip for mobile - onClick open
export const TooltipMobile: React.FC<TooltipProps> = props => {
  const { title, shouldBeHidden, children, withoutTruncateIcon, mlIcon, placement, width } = props
  const [toggleTooltip, toggleTooltipHandler] = useToggle()

  return (
    <>
      {shouldBeHidden ? (
        children
      ) : (
        <div style={{ width: width ? width : '100%' }} key={uuid()}>
          <StyledTooltip
            placement={placement || 'top'}
            PopperProps={{
              disablePortal: true,
            }}
            arrow
            onClose={toggleTooltipHandler}
            open={toggleTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={title}
            sx={{ zIndex: 1500 }}
          >
            <StyledMobileBox $isMobile $withoutTruncateIcon={withoutTruncateIcon}>
              {children}
              {withoutTruncateIcon && (
                <DataIcon
                  src={infoIcon}
                  alt='Warning'
                  onClick={toggleTooltipHandler}
                  id='not-expand'
                  style={{ marginLeft: mlIcon ? mlIcon : '' }}
                />
              )}
              {!withoutTruncateIcon && (
                <InfoIconTruncate toggleTooltipHandler={toggleTooltipHandler} />
              )}
            </StyledMobileBox>
          </StyledTooltip>
        </div>
      )}
    </>
  )
}
