import React from 'react'

import { IHRSamples, ISamples } from '@models/samples'
import { Checkbox } from '@components/shared'
import { useAppDispatch } from '@hooks/redux'
import { selectSamplesInModal } from '@state/packages'

import { StyledTableCell } from '../styles'

interface SamplesTogglerProps {
  samples: ISamples | IHRSamples
}

export const SamplesToggler: React.FC<SamplesTogglerProps> = ({ samples }) => {
  const dispatch = useAppDispatch()

  const checkedAllSamplesInModalHandler = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) dispatch(selectSamplesInModal(samples.slice(0).map(s => s.id)))
    else dispatch(selectSamplesInModal([]))
  }

  return (
    <StyledTableCell>
      <Checkbox onChange={checkedAllSamplesInModalHandler} />
    </StyledTableCell>
  )
}
