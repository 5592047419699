import React from 'react'
import { Box } from '@mui/system'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { IconButton } from '@mui/material'
import { v4 as uuid } from 'uuid'

import { Button } from '@components/shared'
import deleteIcon from '@assets/icons/delete-icon-active.svg'
import { Image } from '@styles'
import { IErrorsEditLaboratory, phoneNumbersErrorKeys } from '@models/yourWorkspace'
import { ErrorBox, ErrorForAllFields } from '@components/shared/ErrorBox'

import * as Styled from '../../../styles'
import { PhoneField } from './PhoneField'
import { InputField } from '../InputField'

interface PhoneNumbersProps {
  apiErrorMessage?: IErrorsEditLaboratory
}

export const PhoneNumbers: React.FC<PhoneNumbersProps> = ({ apiErrorMessage }) => {
  const { control, formState } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'phone_numbers',
  })
  const [error, setError] = React.useState<string[]>([])

  React.useEffect(() => {
    if (apiErrorMessage) {
      const getIndexFieldWithError = Object.keys(apiErrorMessage)
        .filter(item => phoneNumbersErrorKeys.includes(item))
        .map(err => err.slice(-1))

      setError(getIndexFieldWithError)
    }
  }, [apiErrorMessage])

  const handleAppend = (value: Partial<unknown> | Partial<unknown>[]) => {
    append(value)
  }

  const handleRemove = (index: number | number[] | undefined) => {
    const check = error.filter(i => +i !== index)
    // we need to check the error array and the index of the field we removed
    // so that there is no overlap of an error from the previous field in the field
    // where there could not be an error (for errors, we save the index of the field where the error was)
    const checkIndex = error.includes('2') && index === 1

    if (checkIndex) {
      const updateError = error.filter(i => +i !== 2)
      setError([...updateError, '1'])
    } else {
      setError(check)
    }
    remove(index)
  }

  const isUserAbleToAddNewNumber = fields.length < 3
  const phoneFields = !fields.length ? [{ id: uuid(), phone_number: '', department: '' }] : fields

  const errorForAllPhoneFields = apiErrorMessage?.phone_numbers

  const getDepartment = (index: number) => {
    if (!!formState.errors?.phone_numbers) {
      if (formState.errors?.phone_numbers[`${index}`]?.department)
        return formState.errors?.phone_numbers[`${index}`]?.department.message
    }
    return ''
  }

  return (
    <>
      <Box display='flex'>
        <Styled.Label>Phone number(s)</Styled.Label>
        <Styled.Label ml='119px'>Department(s)</Styled.Label>
      </Box>

      <>
        <Styled.StyledBox $isErrorForAllFields={!!errorForAllPhoneFields}>
          {phoneFields.map((item, index) => (
            <React.Fragment key={index}>
              <Styled.FieldsBox $isErrorForAllFields={!!errorForAllPhoneFields}>
                <>
                  <Box>
                    <PhoneField
                      name={`phone_numbers.${index}.phone_number`}
                      phoneError={error.includes(`${index}`)}
                      setError={setError}
                      error={error}
                      index={index}
                    />
                  </Box>

                  <InputField
                    name={`phone_numbers.${index}.department`}
                    width='232px'
                    ml='173px'
                    apiError={getDepartment(index)}
                  />
                </>

                {index > 0 && (
                  <IconButton
                    onClick={() => handleRemove(index)}
                    sx={{ width: '40px', height: '40px', marginLeft: '4px' }}
                  >
                    <Image component='img' src={deleteIcon} width='24px' height='24px' />
                  </IconButton>
                )}
              </Styled.FieldsBox>

              <ErrorBox
                errorMainField={
                  error.includes(`${index}`) ? 'The phone number entered is not valid' : ''
                }
                errorDepartment={getDepartment(index)}
              />
            </React.Fragment>
          ))}
        </Styled.StyledBox>

        {errorForAllPhoneFields && <ErrorForAllFields errorForAllFields={errorForAllPhoneFields} />}
      </>

      {isUserAbleToAddNewNumber && (
        <Button
          ml='-2px'
          mb='18px'
          type='button'
          onClick={() => handleAppend({ phone_number: '', department: '' })}
          variant='text'
          startIconAdd
        >
          Add phone number
        </Button>
      )}
    </>
  )
}
