import React from 'react'
import { useFormContext } from 'react-hook-form'

import { AvailableSectionSettings } from '@models/tasks'
import { TEST_STRIP, TEST_BRAND, TEST_BATCH } from '@constants/tests'
import { Input } from '@components/shared'
import { StyledLabel } from '@components/modules/LoginForm/styles'
import { MAX_CHARACTERS, SPECIAL_CHARACTERS_ALLOWED } from '@constants/validation'

import { StyledTitle, StyledResultsBox } from '../styles'

export const TestStripDetails = () => {
  const {
    getValues,
    register,
    formState: { errors },
  } = useFormContext()
  const { task } = getValues()

  const { ordering_key: order, title } = React.useMemo(() => {
    return task.available_section_settings.find(
      (item: AvailableSectionSettings) => item.section.name === TEST_STRIP
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rules = {
    maxLength: {
      value: 150,
      message: MAX_CHARACTERS(150),
    },
    validate: {
      specialCharacters: (v: string) => {
        return v === '' || /^[A-Za-z0-9-/,':;_ ]+$/.test(v) || SPECIAL_CHARACTERS_ALLOWED
      },
    },
  }

  return (
    <StyledResultsBox $mb={0} $order={order}>
      <StyledTitle>{`${order}. ${title}`}</StyledTitle>
      <StyledLabel
        label='Brand'
        sx={{ marginTop: 0 }}
        control={
          <Input
            width='100%'
            variant='outlined'
            height={48}
            type='text'
            placeholder='Type here...'
            error={!!errors.fields?.[TEST_BRAND]?.message}
            helperText={errors.fields?.[TEST_BRAND]?.message}
            {...register(`fields.${TEST_BRAND}`, rules)}
          />
        }
      />
      <StyledLabel
        label='Batch/lot number'
        sx={{ marginTop: '20px' }}
        control={
          <Input
            width='100%'
            variant='outlined'
            height={48}
            type='text'
            placeholder='Type here...'
            error={!!errors.fields?.[TEST_BATCH]?.message}
            helperText={errors.fields?.[TEST_BATCH]?.message}
            {...register(`fields.${TEST_BATCH}`, rules)}
          />
        }
      />
    </StyledResultsBox>
  )
}
