import React from 'react'
import { Box, IconButtonProps, useTheme } from '@mui/material'

import { ReactComponent as SearchIconBlue } from '@assets/icons/search-icon-blue.svg'

interface SearchIconProps extends IconButtonProps {
  setOpenSearch?: () => void
}

export const SearchIcon: React.FC<SearchIconProps> = ({ setOpenSearch }) => {
  const theme = useTheme()

  return (
    <Box
      padding='5px'
      width='24px'
      height='24px'
      sx={{ cursor: 'pointer' }}
      onClick={setOpenSearch}
    >
      <SearchIconBlue fill={theme.palette.primary.main} />
    </Box>
  )
}
