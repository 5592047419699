import React from 'react'
import { Box } from '@mui/material'

import {
  TemperatureSensitiveToxicityFields,
  TempSensitiveToxicityIcons,
  Tooltip,
} from '@components/shared'
import { selectUser } from '@state/user'
import { IPackage } from '@models/packages'
import { formatDate } from '@helpers/dates'
import { useAppSelector } from '@hooks/redux'
import { datePatterns } from '@constants/dates'
import { StyledParagraph, StyledPageSubTitle, StyledPageLabel } from '@styles'
import { SpecificId } from '@components/shared/sampleFields'
import { protectedRoutes } from '@configs/routes.config'
import { DETAILS, FAHRENHEIT } from '@constants/common'
import { getTemperature } from '@helpers/formatTemperature'

interface ViewPackageDetailsProps
  extends Pick<
    IPackage,
    | 'id'
    | 'specific_id'
    | 'quantity'
    | 'updated_at'
    | 'units'
    | 'unit_type'
    | 'currency'
    | 'price_per_unit'
    | 'total_price'
    | 'source'
    | 'max_temperature'
    | 'min_temperature'
    | 'max_temperature_measurement_format'
    | 'min_temperature_measurement_format'
    | 'toxicity_level'
    | 'delivery_service'
    | 'tracking_number'
    | 'package_type'
  > {}

export const PackageInfoMain: React.FC<ViewPackageDetailsProps> = ({
  id,
  specific_id,
  quantity,
  updated_at,
  units,
  unit_type,
  currency,
  price_per_unit,
  total_price,
  source,
  max_temperature,
  min_temperature,
  max_temperature_measurement_format,
  min_temperature_measurement_format,
  toxicity_level,
  delivery_service,
  tracking_number,
  package_type,
}) => {
  const { timezone } = useAppSelector(selectUser)

  const packageInfoMain = [
    {
      title: 'Delivery service',
      data: delivery_service?.name || '-',
    },
    {
      title: 'Tracking number',
      data: tracking_number || '-',
    },
    {
      title: 'Package type',
      data: package_type || '-',
    },
    {
      title: 'Quantity',
      data: quantity,
    },
    {
      title: 'Last updated',
      data: formatDate(updated_at, datePatterns.timeDayMonthYear, timezone),
    },
  ]

  const { maxTemperature, minTemperature, temperatureFormat } = React.useMemo(
    () =>
      getTemperature(
        FAHRENHEIT,
        min_temperature,
        max_temperature,
        min_temperature_measurement_format.key,
        max_temperature_measurement_format.key
      ),
    [
      max_temperature,
      max_temperature_measurement_format.key,
      min_temperature,
      min_temperature_measurement_format.key,
    ]
  )

  return (
    <Box>
      <StyledPageSubTitle variant='h3'>Main info</StyledPageSubTitle>
      <Box mb='24px'>
        <StyledPageLabel variant='h5'>Package ID:</StyledPageLabel>
        <Box display='flex' alignContent='center'>
          <SpecificId
            labelTooltip={source.label_tooltip}
            specificId={specific_id}
            laboratory={source}
            pathForLink={`${protectedRoutes.packages.path}/${id}${DETAILS}`}
            titleForPathLink={`${protectedRoutes.packageDetails.name} ${specific_id}`}
          />
          <TempSensitiveToxicityIcons
            toxicity={toxicity_level}
            minTempSensitive={minTemperature}
            maxTempSensitive={maxTemperature}
            temperatureMeasurementFormat={temperatureFormat}
            mt='-5px'
          />
        </Box>
      </Box>
      <TemperatureSensitiveToxicityFields
        toxicityLevelValue={toxicity_level?.value}
        minTemperature={minTemperature || undefined}
        maxTemperature={maxTemperature || undefined}
        temperatureMeasurementFormatKey={temperatureFormat}
        mb='24px'
      />
      {packageInfoMain.map(({ title, data }, idx) => {
        if (idx === 0)
          return (
            <React.Fragment key={idx}>
              <StyledPageLabel variant='h5'>{title}:</StyledPageLabel>
              <Tooltip title={source.label_tooltip || ''}>
                <StyledParagraph variant='body1' sx={{ width: 'max-content' }}>
                  {data}
                </StyledParagraph>
              </Tooltip>
            </React.Fragment>
          )
        else
          return (
            <React.Fragment key={idx}>
              <StyledPageLabel variant='h5'>{title}:</StyledPageLabel>
              <StyledParagraph variant='body1'>{data}</StyledParagraph>
            </React.Fragment>
          )
      })}
    </Box>
  )
}
