import {
  styled,
  Table,
  TableCell,
  TableRow,
  TableCellProps as MuiTableCellProps,
  TableRowProps as MuiTableRowProps,
  Typography,
} from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import { MutableRefObject } from 'react'

import { ContentType } from '@models/common/ui'
import { ITableColumnWidths } from '@models/common/user'

interface TableCellProps extends MuiTableCellProps {
  hideBorder?: boolean | MutableRefObject<HTMLDivElement | null>
  isClickable?: boolean
  isTableTasksContentType?: boolean
  isRowWithOtherTest?: boolean
  contentType?: ContentType
}

const TableCellConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'hideBorder':
      case 'isClickable':
      case 'isTableTasksContentType':
      case 'isRowWithOtherTest':
      case 'contentType':
        return false

      default:
        return true
    }
  },
}
interface TableRowProps extends MuiTableRowProps {
  contentType: ContentType
  columns?: ITableColumnWidths
}

const TableRowConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'contentType':
        return false

      default:
        return true
    }
  },
}

export const StyledTable = styled(Table)`
  &.MuiTable-root {
    width: auto;
    min-width: 1440px;
  }
`

export const StyledRow = styled<React.FC<TableRowProps>>(TableRow, TableRowConfig)`
  display: grid;

  grid-template-columns: ${({ contentType, columns }) =>
    contentType === 'samples'
      ? `[task]206px ${
          columns && columns.map(column => column.width).join(' ')
        } []1fr [action]267px`
      : '[task]206px [task-status]160px [task-result]167px [apis]180px [test-type]253px [location]166px [task-notes]237px [testing-date]172px [attachments]241px []950px [action]154px'};
`

export const StyledOptionalRow = styled(TableRow)`
  display: grid;
  grid-template-columns: [task]2760px [action]267px;
`

export const StyledCell = styled(TableCell)`
  position: absolute;
  left: 155px;
`

export const StyledTypography = styled(Typography)`
  padding-top: 2px;
  font-weight: 500;
  color: #616d84;
`

export const FixedSampleTaskCell = styled<React.FC<TableCellProps>>(TableCell, TableCellConfig)`
  position: sticky;
  padding: 29px 0px 29px 32px;

  display: flex;
  align-items: center;
  right: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: 4px solid
    ${({ hideBorder, theme }) => (hideBorder ? 'transparent' : theme.palette.background.default)};

  cursor: auto;

  &:first-child {
    border-top-left-radius: 10px;
    border-radius: 10px 0px 0px 10px;
  }

  box-shadow: -15px 0 15px -5px rgba(53, 108, 151, 0.12);

  //hide bottom shadow
  &::after {
    content: '';
    display: block;
    height: 4px;
    width: ${({ contentType }) => (contentType === 'samples' ? '267px' : '154px')};
    background-color: ${({ theme }) => theme.palette.background.default};
    position: absolute;
    bottom: -4px;
    right: 0;
  }
`
