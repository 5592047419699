import React, { useEffect } from 'react'
import { Autocomplete, AutocompleteChangeReason, CircularProgress } from '@mui/material'
import { RegisterOptions, useController } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { IOption } from '@models/common/app'
import { AutocompleteWrapper, Input } from '@components/shared'
import { useFetchApisFilters } from '@hooks/queries'
import { setEditSampleApisValues } from '@state/app'
import { IMainApis } from '@models/samples'
import { PureChip } from '@components/ui'

import { StyledBox, StyledStack } from './styles'

interface ApiFieldProps {
  name: string
  isCopy?: boolean
  isOptional?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}

export const ApiField: React.FC<ApiFieldProps> = React.memo(props => {
  const { name, isCopy, rules, isOptional } = props
  const dispatch = useDispatch()

  const [apisWasTouched, setApisWasTouched] = React.useState(false)
  const [searchApi, setSearchApi] = React.useState<string>('')

  const [topScroll, setTopScroll] = React.useState<{ oldScrollTop: number; isScrollTop: boolean }>({
    oldScrollTop: 0,
    isScrollTop: false,
  })

  const { field, fieldState } = useController({ name, rules, defaultValue: [] })
  const { data, isFetching, fetchNextPage, hasNextPage } = useFetchApisFilters(searchApi)
  const options = data && data.pages.map(page => page.results).flat()

  const { isTouched, error } = fieldState
  const isHighlighting = isCopy && !isTouched && !apisWasTouched
  const apis: IMainApis[] = field.value || []

  useEffect(() => {
    dispatch(setEditSampleApisValues(apis))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apis])

  const onChangeHandler = (e: any, value: IOption[] | null, reason: AutocompleteChangeReason) => {
    if (reason === 'clear') {
      const newValue = [...(field.value as Array<any>)]
      newValue.pop()
      field.onChange({ target: { value: newValue } })
    } else {
      field.onChange({ target: { value: value } })
    }
  }

  const deleteApiHandler = (id: number) => {
    setApisWasTouched(true)
    field.onChange({ target: { value: apis.filter(({ id: apiId }) => apiId !== id) } })
  }

  const onInputChangeHandler = (e: any, v: string) => {
    setSearchApi(v)
  }

  return (
    <StyledBox>
      <AutocompleteWrapper height={42} bgColor={isHighlighting ? '#FFF5D6' : '#fff'}>
        <Autocomplete
          disableClearable
          multiple
          fullWidth
          size='small'
          options={options || []}
          loading={isFetching}
          filterSelectedOptions
          noOptionsText='Nothing found'
          getOptionLabel={option => option.name}
          getOptionDisabled={!isOptional ? () => apis.length === 3 : undefined}
          onInputChange={onInputChangeHandler}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderTags={value => <span>{value[value.length - 1].name}</span>}
          ListboxProps={{
            onScroll: event => {
              const listboxNode = event.currentTarget
              const isFetchNextPage =
                hasNextPage &&
                Math.round(listboxNode.scrollTop + listboxNode.clientHeight) ===
                  listboxNode.scrollHeight

              if (isFetchNextPage) {
                const top = Math.round(listboxNode.scrollHeight - listboxNode.clientHeight)
                fetchNextPage()
                setTopScroll({ oldScrollTop: top, isScrollTop: true })
              }
              if (listboxNode.scrollHeight > topScroll.oldScrollTop && topScroll.isScrollTop) {
                listboxNode.scrollTo(0, topScroll.oldScrollTop)
                setTopScroll(prev => ({ ...prev, isScrollTop: false }))
              }
            },
          }}
          renderInput={params => (
            <Input
              variant='outlined'
              placeholder={!apis.length ? 'Search here...' : ''}
              error={!!error}
              helperText={error && error.message}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isFetching ? (
                      <CircularProgress color='primary' sx={{ marginRight: '44px' }} size={16} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          {...field}
          onChange={onChangeHandler}
        />
      </AutocompleteWrapper>

      <StyledStack spacing={0.8}>
        {apis.map((api, idx) => (
          <PureChip
            tooltipWidth={'calc(100% - 38px)'}
            truncatedLabelLength={23}
            key={api.id}
            isMainChip
            label={api.name}
            onDelete={() => deleteApiHandler(api.id)}
          />
        ))}
      </StyledStack>
    </StyledBox>
  )
})
