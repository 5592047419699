import React from 'react'
import { useController, Control } from 'react-hook-form'
import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'

import { setCellValueToSample } from '@state/sampleCreate'
import { IOption } from '@models/common/app'
import { StyledRadio } from '@styles'

import { IPlaceOption } from '../GMPlaceAutocomplete'

interface RadioButtonsProps {
  name: string
  isCopy?: boolean
  options?: IOption[]
  cellName: string
  initialVal: {
    val: number | null | IOption | string | IOption[] | Date | IPlaceOption
    error: string
  }
  control?: Control
  sampleId: string
}

export const RadioButtons: React.FC<RadioButtonsProps> = React.memo(props => {
  const { name, options, control, sampleId, initialVal, cellName } = props
  const { field } = useController({ name, control, defaultValue: initialVal.val })
  const dispatch = useDispatch()

  return (
    <Box>
      <RadioGroup
        aria-labelledby='demo-controlled-radio-buttons-group'
        name='controlled-radio-buttons-group'
        onChange={({ target: { value } }) => {
          field.onChange({ target: { value } })
          dispatch(
            setCellValueToSample({
              cellValue: { val: value as string, error: '' },
              cellName,
              sampleId,
            })
          )
        }}
        value={field.value}
        sx={{ width: '100%', flexDirection: 'row' }}
      >
        {options?.map(option => (
          <FormControlLabel
            key={option.id}
            sx={{ display: 'flex', width: 'max-content' }}
            value={option.id}
            control={<StyledRadio />}
            label={
              <Typography
                color={option.id === Number(field.value) ? '#0F1934' : '#616D84'}
                marginTop='-6px'
                component='sub'
              >
                {option.name}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </Box>
  )
})
