import React from 'react'
import { Box } from '@mui/material'

import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { StyledTextCell, SubText } from '@styles'

interface ISampleNameProps {
  name: string
  submitter_code?: string
}

const TRUNCATED_TEXT_LENGTH = 35

export const SampleName: React.FC<ISampleNameProps> = ({ name, submitter_code }) => {
  return (
    <Box display='flex' flexDirection='column'>
      <Tooltip
        isPureTooltip
        title={name || ''}
        shouldBeHidden={!name?.length || name?.length < TRUNCATED_TEXT_LENGTH}
        maxWidth={220}
      >
        <StyledTextCell mb='4px'>
          {name ? stringSlice(name, TRUNCATED_TEXT_LENGTH) : '-'}
        </StyledTextCell>
      </Tooltip>

      {submitter_code ? (
        <SubText>
          {submitter_code ? 'Code: ' + stringSlice(submitter_code, TRUNCATED_TEXT_LENGTH) : '-'}
        </SubText>
      ) : null}
    </Box>
  )
}
