import React from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
  LocalizationProvider,
  DatePickerProps as DatePickerPropsMUI,
  DatePicker as DatePickerMUI,
} from '@mui/lab'

import { StyledDateBox as DateBox } from './styles'

export interface DatePickerProps extends DatePickerPropsMUI {
  width?: string
  height?: number
  bgColor?: string
  m?: string
  mr?: string
}

export const DatePicker: React.FC<DatePickerProps> = React.forwardRef<
  HTMLDivElement,
  DatePickerProps
>((props, ref) => {
  const { width, height, bgColor, mr, m, ...restProps } = props

  return (
    <DateBox width={width} height={height} bgColor={bgColor} mr={mr} m={m}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePickerMUI ref={ref} {...restProps} />
      </LocalizationProvider>
    </DateBox>
  )
})
