import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ISavedFiltersFromUser, ITableColumns, IUser } from '@models/common/user'
import { USER } from '@constants/common'
import { IEmailField } from '@models/common/forms'
import { IErrorsEditUser } from '@models/yourWorkspace'
import { IChoice } from '@models/common/app'

import { auth, logout, update } from './user.actions'

interface IEmailSendingStatuses {
  firstTry: boolean
  secondTry: boolean
}

export interface ICustomizeModalType {
  title: string
  active: boolean
}

interface CustomizeTableColumnsState {
  customizeTableColumns: {
    customizeModalType: ICustomizeModalType[]
    columns: ITableColumns[]
    sample_columns: ITableColumns
    task_columns: ITableColumns
    componentShouldBeRerender: boolean
  }
}

type UpdateUserRequestStatus = 'idle' | 'successful' | 'error'

export interface UserState
  extends Omit<IUser, 'task_column_settings' | 'sample_column_settings'>,
    CustomizeTableColumnsState {
  emailSendingStatuses: IEmailSendingStatuses
  isAuthorized: boolean
  isFetching: boolean
  error: string
  userSwitchedRole: boolean
  hasOtherActiveSession: boolean
  isMaintenanceModePage: boolean
  isMaintenanceModeUnsubscribePage: boolean
  maintenanceModeUserEmail: IEmailField
  webSocketConnected: boolean | null
  isErrorPage: boolean
  is_active: boolean
  is_default_manager: boolean
  isAutoLogout: boolean
  apiErrorsUserUpdate: IErrorsEditUser | null
  activation_status_description: string
  isSuccessfulUpdatedProfile: UpdateUserRequestStatus
  temperature_measurement_format: IChoice
  isMainFooterHidden: boolean
}

const user: IUser = JSON.parse(localStorage.getItem(USER) || '""')

const initialState: UserState = {
  id: '',
  email: user.email || '',
  first_name: user.first_name || '',
  last_name: user.last_name || '',
  sex: user.sex || '',
  role: user.role || '',
  laboratory: user.laboratory || '',
  phone_number: user.phone_number || '',
  timezone: user.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || null,
  timezone_autodetect: user.timezone_autodetect || false,
  role_switcher: user.role_switcher,
  emailSendingStatuses: {
    firstTry: false,
    secondTry: false,
  },
  isAuthorized: false,
  isFetching: false,
  error: '',
  userSwitchedRole: false,
  customizeTableColumns: {
    customizeModalType: [
      { title: 'Samples', active: true },
      { title: 'Tasks', active: false },
    ],
    columns: [],
    sample_columns: [],
    task_columns: [],
    componentShouldBeRerender: false,
  },
  hasOtherActiveSession: false,
  isMaintenanceModePage: false,
  isMaintenanceModeUnsubscribePage: false,
  maintenanceModeUserEmail: { email: '' },
  webSocketConnected: true,
  isErrorPage: false,
  isAutoLogout: false,
  is_active: false,
  is_default_manager: false,
  saved_filters: user.saved_filters,
  apiErrorsUserUpdate: null,
  activation_status_description: '',
  isSuccessfulUpdatedProfile: 'idle',
  can_manage_users: false,
  skills: [],
  temperature_measurement_format: { key: '', value: '' },
  isMainFooterHidden: false,
  is_default_manager_in_central_lab: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (
      state,
      { payload }: PayloadAction<IUser & { emailSendingStatuses?: IEmailSendingStatuses }>
    ) => {
      localStorage.setItem(USER, JSON.stringify(payload))

      state.email = payload.email
      state.first_name = payload.first_name
      state.last_name = payload.last_name
      state.sex = payload.sex
      state.role = payload.role
      state.laboratory = payload.laboratory
      state.phone_number = payload.phone_number
      state.role_switcher = payload.role_switcher
      state.can_manage_users = payload.can_manage_users

      state.timezone = payload.timezone
      state.timezone_autodetect = payload.timezone_autodetect

      state.emailSendingStatuses.firstTry = !!payload.emailSendingStatuses?.firstTry
      state.emailSendingStatuses.secondTry = !!payload.emailSendingStatuses?.secondTry

      state.customizeTableColumns.sample_columns = payload.sample_column_settings
      state.customizeTableColumns.task_columns = payload.task_column_settings
      state.saved_filters = payload.saved_filters
      state.skills = payload.skills
      state.temperature_measurement_format = payload.temperature_measurement_format
      state.is_default_manager_in_central_lab = payload.is_default_manager_in_central_lab
    },
    setTimezone: (state, { payload }: PayloadAction<string | null>) => {
      state.timezone = payload
    },
    // setTimezone: (state, { payload }: PayloadAction<string | null>) => {
    //   state.timezone = payload
    // },
    setSwitcherForRole: (state, { payload }: PayloadAction<boolean>) => {
      state.userSwitchedRole = payload
    },
    setIsAuthorized: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthorized = payload
    },
    resetEmailSendingStatuses: state => {
      state.emailSendingStatuses.firstTry = false
      state.emailSendingStatuses.secondTry = false
    },
    toggleCustomizeModalType: ({ customizeTableColumns }, { payload }: PayloadAction<number>) => {
      customizeTableColumns.customizeModalType = customizeTableColumns.customizeModalType.map(
        (column, idx) => {
          if (idx === payload) return { ...column, active: true }
          return { ...column, active: false }
        }
      )
    },
    setEditableColumns: (
      { customizeTableColumns },
      { payload }: PayloadAction<ITableColumns[]>
    ) => {
      customizeTableColumns.columns = payload
    },
    columnCheckboxToggler: (
      { customizeTableColumns },
      { payload: { id, activeTab } }: PayloadAction<{ id: number; activeTab: number }>
    ) => {
      customizeTableColumns.columns[activeTab] = customizeTableColumns.columns[activeTab].map(
        column => {
          if (column.id === id) return { ...column, activated: !column.activated }
          return column
        }
      )
    },
    reorderColumn: (
      { customizeTableColumns },
      {
        payload: { id, idx, activeTab },
      }: PayloadAction<{ id: number; idx: number; activeTab: number }>
    ) => {
      const filteredColumns = customizeTableColumns.columns[activeTab].filter(
        column => column.id !== id
      )

      customizeTableColumns.columns[activeTab] = [
        ...[...filteredColumns].splice(0, idx + 1),
        ...customizeTableColumns.columns[activeTab].filter(column => column.id === id),
        ...[...filteredColumns].splice(idx + 1, customizeTableColumns.columns[activeTab].length),
      ]
    },
    setSampleColumns: (
      { customizeTableColumns },
      { payload }: PayloadAction<Omit<ITableColumns, 'show'>>
    ) => {
      customizeTableColumns.sample_columns = payload
    },
    setTaskColumns: (
      { customizeTableColumns },
      { payload }: PayloadAction<Omit<ITableColumns, 'show'>>
    ) => {
      customizeTableColumns.task_columns = payload
    },
    setHasOtherActiveSession: (state, { payload }: PayloadAction<boolean>) => {
      state.hasOtherActiveSession = payload
    },
    setMaintenanceModePage: (state, { payload }: PayloadAction<boolean>) => {
      state.isMaintenanceModePage = payload
    },
    setMaintenanceModeUnsubscribePage: (state, { payload }: PayloadAction<boolean>) => {
      state.isMaintenanceModeUnsubscribePage = payload
    },
    setMaintenanceModeUserEmail: (state, { payload }: PayloadAction<IEmailField>) => {
      state.maintenanceModeUserEmail = payload
    },
    rerenderTablesComponent: ({ customizeTableColumns }) => {
      customizeTableColumns.componentShouldBeRerender =
        !customizeTableColumns.componentShouldBeRerender
    },
    setWebSocketConnection: (state, { payload }: PayloadAction<boolean>) => {
      state.webSocketConnected = payload
    },
    setIsErrorPage: (state, { payload }: PayloadAction<boolean>) => {
      state.isErrorPage = payload
    },
    setIsAutoLogout: (state, { payload }: PayloadAction<boolean>) => {
      state.isAutoLogout = payload
    },
    updateFiltersForUserState: (state, { payload }: PayloadAction<ISavedFiltersFromUser>) => {
      const filterPrevState = state.saved_filters.filter(i => i.page_title !== payload.page_title)
      state.saved_filters = [
        ...[...filterPrevState],
        { page_title: payload.page_title, filters: payload.filters },
      ]
    },
    setApiErrorUpdatePhoneOrEmail: (state, { payload }: PayloadAction<IErrorsEditUser | null>) => {
      state.apiErrorsUserUpdate = payload
    },
    setIsSuccessfulUpdatedProfile: (state, { payload }: PayloadAction<UpdateUserRequestStatus>) => {
      state.isSuccessfulUpdatedProfile = payload
    },
    setIsMainFooterHidden: (state, { payload }: PayloadAction<boolean>) => {
      state.isMainFooterHidden = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(auth.pending, state => {
      state.isFetching = true
    })

    builder.addCase(auth.fulfilled, (state, { payload }) => {
      state.isAuthorized = true
      state.isFetching = false
      state.error = ''
    })

    builder.addCase(auth.rejected, (state, { error }) => {
      state.isAuthorized = false
      state.isFetching = false
      state.error = error.message || ''
    })

    builder.addCase(logout.rejected, (state, { error }) => {
      state.error = error.message || ''
    })

    builder.addCase(update.pending, state => {
      state.isFetching = true
    })

    builder.addCase(update.fulfilled, state => {
      state.isFetching = false
    })

    builder.addCase(update.rejected, (state, { error }) => {
      state.isFetching = false
      state.error = error.message || ''
    })
  },
})

export const {
  reducer: userReducer,
  actions: {
    setUser,
    setTimezone,
    setSwitcherForRole,
    resetEmailSendingStatuses,
    toggleCustomizeModalType,
    setEditableColumns,
    columnCheckboxToggler,
    reorderColumn,
    setSampleColumns,
    setTaskColumns,
    setHasOtherActiveSession,
    setMaintenanceModePage,
    setMaintenanceModeUserEmail,
    setMaintenanceModeUnsubscribePage,
    rerenderTablesComponent,
    setWebSocketConnection,
    setIsErrorPage,
    setIsAutoLogout,
    updateFiltersForUserState,
    setApiErrorUpdatePhoneOrEmail,
    setIsSuccessfulUpdatedProfile,
    setIsAuthorized,
    setIsMainFooterHidden,
  },
} = userSlice
