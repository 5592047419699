import { IAttachmentResultData } from '@models/attachments'
import {
  attachmentsCreateSample,
  hrAttachmentsCreateSample,
  setCreateSampleAttachments,
} from '@state/app'
import { useAppDispatch, useAppSelector } from '@hooks/redux'

export const useApplyNewAttachmentsToSample = () => {
  const dispatch = useAppDispatch()
  const addedCreateSampleAttachments = useAppSelector(attachmentsCreateSample)

  const applyNewAttachmentsToSample = (attachments: IAttachmentResultData) => {
    const sampleId = addedCreateSampleAttachments.sampleId
    const cellValue = attachments.result

    dispatch(
      setCreateSampleAttachments({
        cellValue,
        sampleId,
        isApplyNewAttachments: true,
      })
    )
  }

  return { applyNewAttachmentsToSample }
}

export const useAttachmentsLength = () => {
  const addedCreateSampleAttachments = useAppSelector(attachmentsCreateSample)

  const getAttachmentsLength = (objectId: number, attachmentsLength: number) => {
    const storageLength = addedCreateSampleAttachments.cellValue.length

    return !objectId ? storageLength : attachmentsLength
  }

  return { getAttachmentsLength }
}

export const useHrAttachmentsLength = () => {
  const addedHrCreateSampleAttachments = useAppSelector(hrAttachmentsCreateSample)
  return addedHrCreateSampleAttachments.attachments?.length || 0
}
