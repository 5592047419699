import React from 'react'
import { Backdrop, useTheme } from '@mui/material'

import { AttachmentPhoto } from '@models/samples'
import downloadIcon from '@assets/icons/download-icon.svg'
import closeIcon from '@assets/icons/close-icon.svg'
import { PureLink } from '@styles'

import { StyledIconButton } from './styles'

interface BackdropPhotoProps {
  photo?: AttachmentPhoto | null
  isOpenBackdrop: boolean
  handleBackdropClose: () => void
  isPhotoInModal?: boolean
}

export const BackdropPhoto: React.FC<BackdropPhotoProps> = props => {
  const { photo, isOpenBackdrop, handleBackdropClose, isPhotoInModal } = props

  const theme = useTheme()
  const backdropRef = React.useRef<HTMLDivElement | null>(null)

  const backdropHandler = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (backdropRef.current === e.target) handleBackdropClose()
  }

  return (
    <Backdrop
      id='not-expand'
      ref={backdropRef}
      sx={{ color: theme.container.popupBackground, zIndex: theme => theme.zIndex.drawer + 1 }}
      open={isOpenBackdrop}
      onClick={backdropHandler}
    >
      <img
        src={photo?.link}
        style={{
          maxWidth: isPhotoInModal ? '900px' : '70vw',
          maxHeight: '70vh',
        }}
        alt='sample'
      ></img>

      <StyledIconButton $isPhotoInModal={isPhotoInModal}>
        <PureLink href={photo?.link || ''}>
          <img src={downloadIcon} alt='document' />
        </PureLink>
      </StyledIconButton>

      <StyledIconButton
        onClick={handleBackdropClose}
        sx={{
          width: '40px',
          height: '40px',
          marginLeft: '4px',
          backgroundColor: theme.additional.chosen,
          position: 'absolute',
          right: isPhotoInModal ? 25 : 91,
          top: isPhotoInModal ? 25 : 93,
        }}
      >
        <img src={closeIcon} alt='closeIcon icon' />
      </StyledIconButton>
    </Backdrop>
  )
}
