import { Box, BoxProps, styled } from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'

interface TableCellProps extends BoxProps {
  isLastSample?: boolean
}

const TableCellConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'isLastSample':
        return false

      default:
        return true
    }
  },
}

export const StyledTableCell = styled<React.FC<TableCellProps>>(Box, TableCellConfig)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 16px 0;
  padding-right: 36px;
  border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};

  &:first-child {
    padding: 24px 24px;
    font-weight: 700;

    &::before {
      content: '';
      display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 0 10px;
      border-color: transparent transparent transparent
        ${({ theme }) => theme.palette.background.default};
    }

    &::after {
      content: '';
      display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
      position: absolute;
      bottom: 0px;
      left: 0.6px;
      width: 15px;
      height: 15px;
      border-radius: 60%;
      background-color: #ffffff;
    }
  }
`
