import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { Autocomplete, FormControlLabel, Stack } from '@mui/material'

import { useFetchTimezones } from '@hooks/queries'
import { AutocompleteWrapper, Checkbox, Input } from '@components/shared'

export interface TimezoneFieldProps {
  name: string
}

export const TimezoneField: React.FC<TimezoneFieldProps> = ({ name }) => {
  const { field: checkboxField } = useController({ name: 'timezone_autodetect' })
  const { register, getValues, setValue, watch } = useFormContext()
  const { name: fieldName } = register(name)
  watch(name)

  const { data } = useFetchTimezones()

  const hasTimezoneAutodetection = checkboxField.value

  React.useEffect(() => {
    if (hasTimezoneAutodetection) {
      const value = data?.find(
        ({ timezone_name }) => timezone_name === Intl.DateTimeFormat().resolvedOptions().timeZone
      )

      setValue(name, value)
    }
  }, [data, hasTimezoneAutodetection, name, setValue])

  const inputOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = data?.find(
      ({ timezone_representation_name }) => timezone_representation_name === event.target.value
    )
    setValue(name, value)
  }

  return (
    <>
      <AutocompleteWrapper height={48}>
        <Autocomplete
          disabled={hasTimezoneAutodetection}
          options={data || []}
          noOptionsText='Nothing found'
          value={getValues(name) || null}
          getOptionLabel={option => option.timezone_representation_name}
          isOptionEqualToValue={(option, value) => option.timezone_name === value.timezone_name}
          renderInput={params => (
            <Input variant='outlined' {...params} name={fieldName} onBlur={inputOnBlur} />
          )}
          sx={{
            '& .MuiInputBase-root': {
              paddingTop: '5px',
              paddingBottom: '5px',
            },
          }}
        />
      </AutocompleteWrapper>

      <Stack direction='row' spacing={1} alignItems='center' sx={{ marginTop: '6.5px' }}>
        <FormControlLabel
          control={<Checkbox {...checkboxField} checked={checkboxField.value} />}
          label='Set time location automatically'
        />
      </Stack>
    </>
  )
}
