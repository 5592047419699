import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FiPlus as AddIcon } from 'react-icons/fi'
import { PaperProps as PaperPropsMUI } from '@mui/material'

import { formFieldErrors } from '@constants/errors'
import { AutocompletePaper } from '@components/shared'
import { BRAND, MANUFACTURER, PHARMACY } from '@constants/samples'
import { getAddBtnText, isValueGreaterThanMax } from '@helpers/ui/autocomplete'
import { useAddNewBrand, useAddNewManufacturer, useAddNewPharmacy } from '@hooks/queries'

interface PaperProps extends PaperPropsMUI {
  inputValue: string
  name: string
  hasError: boolean
  type: string
}

export const Paper: React.FC<PaperProps> = props => {
  const { inputValue, name, type, hasError, ...restProps } = props

  const { setError, clearErrors } = useFormContext()

  const isInputValueGreaterThanMax = isValueGreaterThanMax(inputValue, 50)

  const AddNewBrandM = useAddNewBrand()
  const AddNewPharmacyM = useAddNewPharmacy()
  const AddNewManufacturerM = useAddNewManufacturer()

  const isLoading =
    type === PHARMACY
      ? AddNewPharmacyM.isLoading
      : type === MANUFACTURER
      ? AddNewManufacturerM.isLoading
      : type === BRAND
      ? AddNewBrandM.isLoading
      : false

  const isIdle =
    type === PHARMACY
      ? AddNewPharmacyM.isIdle
      : type === MANUFACTURER
      ? AddNewManufacturerM.isIdle
      : type === BRAND
      ? AddNewBrandM.isIdle
      : false

  const addNewPharmacyHandler: React.MouseEventHandler<HTMLButtonElement> = e => {
    if (isInputValueGreaterThanMax) {
      setError(name, formFieldErrors.maxValueIsFifty)
    } else {
      e.preventDefault()

      switch (type) {
        case PHARMACY:
          return AddNewPharmacyM.mutate(inputValue)

        case MANUFACTURER:
          return AddNewManufacturerM.mutate(inputValue)

        case BRAND:
          return AddNewBrandM.mutate(inputValue)
      }
    }
  }

  React.useEffect(() => {
    if (hasError && !isInputValueGreaterThanMax) clearErrors(name)
  }, [name, hasError, clearErrors, isInputValueGreaterThanMax])

  return (
    <AutocompletePaper
      btnIsLoading={isLoading}
      onBtnClick={addNewPharmacyHandler}
      btnIcon={<AddIcon color='primary' />}
      btnText={getAddBtnText({ isIdle, isLoading })}
      inputValue={inputValue}
      shouldRenderBtn={!!inputValue}
      {...restProps}
    />
  )
}
