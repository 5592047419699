import { styled, Box, BoxProps } from '@mui/material'

interface ActionsStickyBoxProps extends BoxProps {
  isShowPharmacyFilter?: boolean
  $isMobile?: boolean
  $padding?: boolean
  $reportsContent?: boolean
  zIndex?: number
  top?: string
}

export const StyledActionsStickyBox = styled<React.FC<ActionsStickyBoxProps>>(Box)`
  position: sticky;
  top: ${({ top }) => (top ? top : '59px')};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 200)};
  padding: ${({ $isMobile, $padding }) =>
    $isMobile && $padding ? '17px 6px 16px 9px' : $isMobile ? '18px 0 24px 0' : '24px 0'};
  background: ${({ theme }) => theme.palette.background.default};
  margin: -8px -8px -8px 0;

  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: ${({ $reportsContent }) => ($reportsContent ? 'wrap' : 'no-wrap')};

  & .MuiSelect-root {
    margin: 8px;
    width: 25ch;

    &:last-of-type {
      margin-right: 0;
    }
  }
`
