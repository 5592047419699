import React from 'react'

import { ISampleTask } from '@models/samples'
import { AWAITING, IN_PROGRESS } from '@constants/tasks'

import { EditTaskAssignee } from './EditTaskAssignee'
import { ViewTaskAssignee } from './ViewTaskAssignee'

interface TaskAssigneeProps {
  task: ISampleTask
  sampleId: number
}

export const TaskAssignee: React.FC<TaskAssigneeProps> = props => {
  const { task, sampleId } = props

  const isEditAssignee = [IN_PROGRESS, AWAITING].includes(task.status.key)

  if (task.test_type.is_other) return null

  return (
    <>
      {isEditAssignee ? (
        <EditTaskAssignee sampleId={sampleId} task={task} />
      ) : (
        <ViewTaskAssignee task={task} />
      )}
    </>
  )
}
