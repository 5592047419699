import React from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import { ISamples } from '@models/samples'
import { useAppSelector } from '@hooks/redux'
import { selectSamplesState } from '@state/samples'
import { ConditionalHidden, ParentLinksRow } from '@components/shared'
import { hasParentInSameSubGroup } from '@helpers/ui/samples'
import { SAMPLES } from '@constants/queries'

import { SampleRow } from '../SampleRows'

interface TableBodyProps {
  samples: ISamples
  totalSamples?: number
  setTotalExpandedCells: (arg0: number) => void
  totalExpandedCells: number
  setReferredTableWidth: (arg0: string) => void
  referredTableWidth: string
  isFetchingSamples?: boolean
  isLoadingSamples?: boolean
}

export const TableBody: React.FC<TableBodyProps> = React.memo(props => {
  const {
    samples,
    totalSamples,
    setTotalExpandedCells,
    totalExpandedCells,
    setReferredTableWidth,
    referredTableWidth,
    isFetchingSamples,
    isLoadingSamples,
  } = props

  const [queryString] = useQueryParam('ordering', StringParam)
  const { selectedSamples } = useAppSelector(selectSamplesState)

  const renderSampleBody = React.useMemo(() => {
    if (samples) {
      return (
        <>
          {samples.map((sample, idx) => {
            const { parents, parents_specific_ids } = sample
            const isLastSample = totalSamples === idx + 1

            const prevSampleParents = samples[idx - 1]?.parents || []
            const isSameGroup = prevSampleParents.some(parent => parents.includes(parent))
            const isSameSubGroup = hasParentInSameSubGroup(prevSampleParents, parents)
            // Check below if this is another subgroup when subgroups more than one
            const isAnotherSubGroup = (() => {
              let flag = true
              prevSampleParents.forEach(parent => {
                if (flag && !parents.includes(parent)) flag = false
              })
              return !flag
            })()
            const needsToRenderParentLinks =
              (!!parents.length && !isSameGroup) || isSameSubGroup || isAnotherSubGroup

            const shouldBeHiddenSplitLinks = !queryString

            return (
              <React.Fragment key={sample.id}>
                <ConditionalHidden condition={shouldBeHiddenSplitLinks}>
                  {needsToRenderParentLinks && (
                    <ParentLinksRow
                      paddingRight='65px'
                      width='1px'
                      nextParents={parents}
                      parentsSpecificIds={parents_specific_ids}
                      prevParents={prevSampleParents}
                      isSameSubGroup={isSameSubGroup}
                      samples={samples}
                      contentType={SAMPLES}
                    />
                  )}
                </ConditionalHidden>
                <SampleRow
                  totalExpandedCells={totalExpandedCells}
                  setTotalExpandedCells={setTotalExpandedCells}
                  setReferredTableWidth={setReferredTableWidth}
                  referredTableWidth={referredTableWidth}
                  isLastSample={isLastSample}
                  sample={sample}
                  parents={parents}
                  hiddenSplitLinks={shouldBeHiddenSplitLinks}
                  isSampleSelected={selectedSamples.some(packageId => packageId === sample.id)}
                  isFetchingSamples={isFetchingSamples}
                  isLoadingSamples={isLoadingSamples}
                />
              </React.Fragment>
            )
          })}
        </>
      )
    }
  }, [
    isFetchingSamples,
    isLoadingSamples,
    queryString,
    referredTableWidth,
    samples,
    selectedSamples,
    setReferredTableWidth,
    setTotalExpandedCells,
    totalExpandedCells,
    totalSamples,
  ])

  return <>{renderSampleBody}</>
})
