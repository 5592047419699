import React from 'react'

import { ISample } from '@models/samples'
import { NotesField } from '@components/shared/sampleFields'
import { StyledTableSampleCell } from '@styles'
import { REPORTS } from '@constants/queries'

interface NotesProps {
  report: ISample
  hideBorder?: boolean
}

export const Notes: React.FC<NotesProps> = ({ report, hideBorder }) => {
  const { notes } = report

  return (
    <StyledTableSampleCell isClickable hideBorder={hideBorder}>
      <NotesField notes={notes} id={report.id} content={REPORTS} mr='48px' />
    </StyledTableSampleCell>
  )
}
