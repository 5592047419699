import React from 'react'

import { USER } from '@constants/common'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { auth, selectUser } from '@state/user'

export const useAuth = () => {
  const dispatch = useAppDispatch()

  const { isAuthorized, error, isFetching } = useAppSelector(selectUser)
  const user = localStorage.getItem(USER)

  const isAuthorizing = (!!user && !isAuthorized && !error) || isFetching
  const shouldAuthorize = isAuthorizing && !isFetching

  React.useEffect(() => {
    if (shouldAuthorize) dispatch(auth())
  }, [dispatch, shouldAuthorize])

  return { isAuthorized, isAuthorizing, hasUserInStorage: !!user }
}
