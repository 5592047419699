import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'

import { useFetchTask } from '@hooks/queries'
import { FullPageError, InfoBoxTemperature } from '@components/shared'
import { editingNotesFieldId, isEditingNotes } from '@state/samples'
import { infoBoxText } from '@components/shared/InfoBoxes/InfoBoxTemperature'
import { ITaskLab } from '@models/tasks'

import { PageHeader, TaskInfo, SubTaskInfo } from './components'
import { TaskInfoMobile } from './components/TaskInfoMobile'

interface MatchParams {
  taskId: string
}

export const TasksDetails: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const { params } = useRouteMatch<MatchParams>()
  const dispatch = useDispatch()

  const fetchTaskQ = useFetchTask<ITaskLab>(Number(params.taskId))
  const task = fetchTaskQ.data

  const refetchTask = () => fetchTaskQ.refetch()
  const isSampleTemperatureSensitive = task?.max_temperature || task?.max_temperature

  React.useEffect(() => {
    if (fetchTaskQ.isFetching) {
      dispatch(isEditingNotes(true))
    } else if (fetchTaskQ.isFetched) {
      dispatch(isEditingNotes(false))
      dispatch(editingNotesFieldId(null))
    }
  }, [dispatch, fetchTaskQ.isFetched, fetchTaskQ.isFetching])

  const renderTaskDetails = React.useMemo(() => {
    if (isMobile) {
      return <TaskInfoMobile task={task} isLoading={fetchTaskQ.isLoading} />
    } else {
      return <TaskInfo task={task} isLoading={fetchTaskQ.isLoading} />
    }
  }, [fetchTaskQ.isLoading, isMobile, task])

  return (
    <>
      <PageHeader taskId={params.taskId} />

      {fetchTaskQ.isError ? (
        <FullPageError
          errorTextTitle={`Something went wrong with task details loading`}
          btnText='Refresh'
          onBtnClick={refetchTask}
          pt='40px'
        />
      ) : (
        <>
          {isSampleTemperatureSensitive && (
            <InfoBoxTemperature temperatureText={infoBoxText.temperature.sample} />
          )}

          {renderTaskDetails}
          <SubTaskInfo task={task} isLoading={fetchTaskQ.isLoading} />
        </>
      )}
    </>
  )
}
