import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'

import { updateTasksAssignee } from '@api/samples'
import { IUpdatedSampleTasks } from '@models/samples'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useUpdateTaskAssignee = () => {
  const dispatch = useDispatch()
  const mutation = useMutation<unknown, IError, IUpdatedSampleTasks>(updateTasksAssignee, {
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
