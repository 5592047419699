import React from 'react'
import { Box } from '@mui/material'

import { StyledTableHeadWrapper, StyledTableListOfUsersRow } from '@styles'
import { ListOfUsersTabsTableHeaders } from '@constants/mappings'

import { TableHeadCell } from './TableHeadCell'
import { StyledTableHead } from './styles'

interface TableHeadProps {
  hideBorder: boolean
}

export const TableHead = React.forwardRef<HTMLDivElement, TableHeadProps>(({ hideBorder }, ref) => {
  return (
    <StyledTableHeadWrapper
      ref={ref}
      hideBorder={hideBorder}
      zIndex={350}
      top='155px'
      marginBottom='4px'
    >
      <StyledTableHead padding='15px 0 15px 66px'>
        <StyledTableListOfUsersRow>
          {ListOfUsersTabsTableHeaders.map(({ content, ordering }, idx) => (
            <TableHeadCell
              key={idx}
              content={content}
              ordering={ordering}
              hideBorder={hideBorder}
            />
          ))}
          <Box sx={{ background: '#FFFF', whiteSpace: 'nowrap' }}>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </Box>
        </StyledTableListOfUsersRow>
      </StyledTableHead>
    </StyledTableHeadWrapper>
  )
})
