import React from 'react'
import { useController } from 'react-hook-form'
import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material'

import { IOption } from '@models/common/app'
import { StyledRadio } from '@styles'

interface RadioButtonsProps {
  name: string
  isCopy?: boolean
  options?: IOption[]
}

export const RadioButtons: React.FC<RadioButtonsProps> = React.memo(({ name, options }) => {
  const {
    field: { onChange, value },
  } = useController({ name })
  return (
    <Box>
      <RadioGroup
        aria-labelledby='demo-controlled-radio-buttons-group'
        name='controlled-radio-buttons-group'
        value={value}
        onChange={onChange}
        sx={{ width: '100%', flexDirection: 'row' }}
      >
        {options?.map(option => (
          <FormControlLabel
            key={option.id}
            sx={{ display: 'flex', width: 'max-content' }}
            value={option.id}
            control={<StyledRadio />}
            label={
              <Typography
                color={option.id === Number(value) ? '#0F1934' : '#616D84'}
                marginTop='-6px'
                component='sub'
              >
                {option.name}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </Box>
  )
})
