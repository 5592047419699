import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { v4 as uuid } from 'uuid'
import { useQueryParams } from 'use-query-params'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '@hooks/redux'
import { FullPageError, MobileTasksTabLoader, TasksCompletedTabLoader } from '@components/shared'
import { useFetchTasksListCompleted } from '@hooks/queries'
import { editingNotesFieldId, isEditingNotes } from '@state/samples'
import {
  applyFiltersOnPage,
  deleteAllAppliedFilters,
  deleteAllSelectedFilters,
  filtersAside,
  setIsFilterOpen,
  setPageForFilters,
} from '@state/filters'
import {
  clearAllFilterStringParamsQueries,
  filterStringParamsQueries,
} from '@components/shared/FilterAside/constants'
import { savedFilters } from '@state/user'
import { ITasksLab } from '@models/tasks'

import { TasksContent, TasksFilters, TasksFiltersMobile } from '../..'

export const CompletedTasks = () => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const dispatch = useDispatch()
  const [{ apis, excipients, drug_classes, manufacturers, testingDate }, setQueryString] =
    useQueryParams(filterStringParamsQueries)
  const isFilterOpen = useAppSelector(filtersAside)

  const fetchTasksListCompletedQ = useFetchTasksListCompleted<ITasksLab>()

  const tasks = fetchTasksListCompletedQ.data?.pages.map(page => [...page.results]).flat()
  const totalTasks = fetchTasksListCompletedQ.data?.pages[0].count

  const hasTableNextPage = fetchTasksListCompletedQ.hasNextPage
  const fetchTableNextPage = fetchTasksListCompletedQ.fetchNextPage as (page: number) => void
  const isFetchingTableNextPage = fetchTasksListCompletedQ.isFetchingNextPage

  const refetchTasks = () => fetchTasksListCompletedQ.refetch()

  const isNoTasksForFilter =
    !tasks?.length && (apis || excipients || drug_classes || manufacturers || testingDate)

  // [saved filters
  const actualPageForSavedFilters = 'tasks_completed'
  const getSavedFiltersFromUser = useAppSelector(savedFilters)
  const samplesFilters = getSavedFiltersFromUser.filter(i => {
    return i.page_title === actualPageForSavedFilters
  })[0]

  const [updatedFilters, setUpdatedFilters] = React.useState(samplesFilters)

  const isPageHasSavedFilters = updatedFilters.filters.some(i => i.values.length !== 0)

  // saved filters]

  // [useEffects for saved filters
  React.useEffect(() => {
    setUpdatedFilters(samplesFilters)
  }, [samplesFilters])

  React.useEffect(() => {
    if (isPageHasSavedFilters) dispatch(applyFiltersOnPage(samplesFilters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samplesFilters, isPageHasSavedFilters])
  // useEffects for saved filters]

  React.useEffect(() => {
    dispatch(setIsFilterOpen({ ...isFilterOpen, right: false }))
    dispatch(setPageForFilters(actualPageForSavedFilters))
    return () => {
      dispatch(deleteAllAppliedFilters())
      dispatch(deleteAllSelectedFilters())
      setQueryString(clearAllFilterStringParamsQueries)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (fetchTasksListCompletedQ.isFetching) {
      dispatch(isEditingNotes(true))
    } else if (fetchTasksListCompletedQ.isFetched) {
      dispatch(isEditingNotes(false))
      dispatch(editingNotesFieldId(null))
    }
  }, [dispatch, fetchTasksListCompletedQ.isFetched, fetchTasksListCompletedQ.isFetching])

  const renderFilter = React.useMemo(() => {
    if (isMobile) {
      return (
        <TasksFiltersMobile contentType='completed' isPageHasSavedFilters={isPageHasSavedFilters} />
      )
    } else {
      return <TasksFilters contentType='completed' isPageHasSavedFilters={isPageHasSavedFilters} />
    }
  }, [isMobile, isPageHasSavedFilters])

  if (fetchTasksListCompletedQ.isLoading && !isMobile)
    return (
      <Box mt={3} key={uuid()}>
        <TasksCompletedTabLoader />
      </Box>
    )

  if (fetchTasksListCompletedQ.isLoading && isMobile)
    return (
      <Box mt={3}>
        <MobileTasksTabLoader />
      </Box>
    )

  if (fetchTasksListCompletedQ.isError) {
    return (
      <FullPageError
        errorTextTitle='Something went wrong with completed tasks loading'
        btnText='Refresh'
        onBtnClick={refetchTasks}
        pt='20px'
      />
    )
  }

  if (isNoTasksForFilter)
    return (
      <>
        {!tasks?.length && <>{renderFilter}</>}
        <FullPageError
          imgVariant='common'
          errorTextTitle={`No completed tasks with such parameters`}
          pt='20px'
          mb={1}
        />
      </>
    )

  return (
    <>
      {!!tasks?.length && <>{renderFilter}</>}

      <TasksContent
        contentType='completed'
        totalTasks={totalTasks}
        tasks={tasks}
        hasNextPage={hasTableNextPage}
        fetchNextPage={fetchTableNextPage}
        isFetchingNextPage={isFetchingTableNextPage}
      />
    </>
  )
}
