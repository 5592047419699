import React from 'react'
import { MuiStyledOptions } from '@mui/system'
import { StyledConfig } from 'styled-components'
import { styled, Typography, TypographyProps } from '@mui/material'

const paragraphConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'mb':
        return false

      case 'color':
        return false

      default:
        return true
    }
  },
}

interface ParagraphProps extends TypographyProps {
  mb?: string
  color?: string
}

export const Paragraph = styled<React.FC<ParagraphProps>>(Typography, paragraphConfig)`
  margin-bottom: ${({ mb }) => mb || ''};
  color: ${({ color }) => color || ''};
`
