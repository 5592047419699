import React from 'react'
import { Typography } from '@mui/material'

import { detailsSampleTestingTable } from '@constants/mappings'
import { StyledDetailsTableCell } from '@styles'

import { StyledDetailsTableHead, StyledDetailsTableRow } from '../styles'

export const TableHead = () => {
  return (
    <StyledDetailsTableHead>
      <StyledDetailsTableRow>
        {detailsSampleTestingTable.tableHeadCells.map(({ id, content }) => (
          <StyledDetailsTableCell sx={{ paddingRight: '17.2px', paddingLeft: '5px' }} key={id}>
            <Typography variant='h5'>{content}</Typography>
          </StyledDetailsTableCell>
        ))}
      </StyledDetailsTableRow>
    </StyledDetailsTableHead>
  )
}
