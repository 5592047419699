import { styled, Box } from '@mui/material'

export const StyledViewBox = styled(Box)`
  display: flex;
  width: 100%;
  padding: 2px;
  padding-right: 5px;
  overflow-x: scroll;
  background-color: ${({ theme }) => theme.palette.background.default};

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: -10px;
    top: 45px;
    width: 30px;
    height: 35%;
    background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8));
    z-index: 2;
  }
`
