import React from 'react'

import { ISamples } from '@models/samples'
import { StyledTableRow } from '@styles'
import { IPackage } from '@models/packages'

import { SampleCells } from '../SampleRows/SampleCells'

interface TableBodyProps {
  samples?: ISamples
  totalSamples?: number
  actionBtnText?: 'Remove' | 'Missed'
  shouldDisplayActionBtn?: boolean
  shouldDisplayMissedText?: boolean
  packagesData?: IPackage
}

export const TableBody: React.FC<TableBodyProps> = props => {
  const {
    samples,
    totalSamples,
    actionBtnText,
    shouldDisplayActionBtn,
    shouldDisplayMissedText,
    packagesData,
  } = props
  return (
    <>
      {samples?.map((sample, idx) => {
        const isLastSample = totalSamples === idx + 1

        return (
          <StyledTableRow key={sample.id} shouldDisplayActionBtn={shouldDisplayActionBtn}>
            <SampleCells
              sample={sample}
              isLastSample={isLastSample}
              counter={idx}
              actionBtnText={actionBtnText}
              shouldDisplayActionBtn={shouldDisplayActionBtn}
              shouldDisplayMissedText={shouldDisplayMissedText}
              packagesData={packagesData}
            />
          </StyledTableRow>
        )
      })}
    </>
  )
}
