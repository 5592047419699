import * as React from 'react'
import { CircularProgress } from '@mui/material'

import { fileTypeConverter } from '@helpers/ui/attachments'
import { BackdropPhoto } from '@components/shared/BackdropPhoto'
import { AttachmentPhoto } from '@models/samples'

import { IPhotoAttachments } from './photoAttachmentsButtons.types'
import * as Styled from './styles'

export const PhotoPreview: React.FC<IPhotoAttachments> = ({ loadingPhoto, initialAttachments }) => {
  const [isOpenBackDrop, setIsOpenBackDrop] = React.useState<boolean>(false)

  const previewIcon = (name: string) => {
    const { iconType } = fileTypeConverter(name)
    return <Styled.PhotoImg src={iconType} alt='document' />
  }

  return loadingPhoto ? (
    <Styled.LoadingPhotoBox>
      <Styled.PreviewIconBox>
        <CircularProgress size={40} />
      </Styled.PreviewIconBox>
      {previewIcon(loadingPhoto.name)}
    </Styled.LoadingPhotoBox>
  ) : initialAttachments.length ? (
    <>
      <Styled.PhotoImg
        src={initialAttachments[0].link}
        alt='photo'
        onClick={() => setIsOpenBackDrop(true)}
      />
      <BackdropPhoto
        photo={initialAttachments[0] as unknown as AttachmentPhoto}
        isOpenBackdrop={isOpenBackDrop}
        handleBackdropClose={() => setIsOpenBackDrop(false)}
      />
    </>
  ) : null
}
