import { Box, BoxProps, Button, ButtonProps, Radio, styled } from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import React from 'react'

interface StyledMobileBoxProps extends BoxProps {
  $isMobile?: boolean
  $withoutTruncateIcon?: boolean
}

const StyledMobileBoxConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case '$isMobile':
        return false

      default:
        return true
    }
  },
}

export const StyledMobileBox = styled<React.FC<StyledMobileBoxProps>>(Box, StyledMobileBoxConfig)`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: ${({ $withoutTruncateIcon }) => ($withoutTruncateIcon ? '' : 'space-between')};
`

interface StyledFlexContainerProps extends BoxProps {
  $isMobile: boolean
  $isMaintenanceModePage?: boolean
}

export const StyledFlexContainer = styled<React.FC<StyledFlexContainerProps>>(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;

  @media (max-width: 767px) {
    display: ${({ $isMaintenanceModePage }) => ($isMaintenanceModePage ? '' : 'grid')};
    justify-content: unset;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr auto;

    .mobile-header {
      position: static;
    }

    &:nth-child(1) {
      grid-row: 1 / 2;
    }

    &:nth-child(2) {
      grid-row: 2 / 3;
    }

    &:nth-child(3) {
      grid-row: 3 / 4;
    }
  }
`

export const StyledAttachedBox = styled<React.FC<StyledMobileBoxProps>>(Box, StyledMobileBoxConfig)`
  background: #ffffff;
  border-radius: 10px;
  padding: ${({ $isMobile }) => ($isMobile ? '0 16px 25px 16px' : '32px')};
  width: ${({ $isMobile }) => ($isMobile ? '' : '260px')};
  min-height: ${({ $isMobile }) => ($isMobile ? '' : '110px')};
`

export const StyledAttachedMaterials = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledPackageInfo = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  & > div {
    width: 100%;
    max-width: 408px;
  }
  StyledAttachedMaterials & > div:last-of-type {
    margin-left: auto;
  }

  & .MuiTypography-caption {
    color: #8f929b;
  }
`

interface StyledClearAllProps extends ButtonProps {
  $alignSelf?: string
  $marginLeft?: string
}

export const StyledClearAll = styled<React.FC<StyledClearAllProps>>(Button)`
  font-weight: normal;
  align-self: ${({ $alignSelf }) => ($alignSelf ? $alignSelf : '')};
  margin-left: ${({ $marginLeft }) => ($marginLeft ? $marginLeft : '')};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: ${({ theme }) => theme.palette.primary.main};
  border-color: transparent;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    border-color: transparent;
  }
`

export const StyledRadio = styled(Radio)`
  color: ${({ theme }) => theme.palette.primary.main};

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledFilterBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 6px;
`

export const TableHeaderItem = styled(Box)`
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 19px;
  border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};
`

interface StyledLabIconProps extends BoxProps {
  color?: string
  bgColor?: string
  width?: string
  height?: string
  top?: string
  left?: string
}

export const StyledLabIcon = styled<React.FC<StyledLabIconProps>>(Box)`
  position: absolute;
  top: ${({ top }) => top || '10px'};
  left: ${({ left }) => left || '0px'};
  width: ${({ width }) => width};
  height: ${({ height }) => height || '32px'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
`

export const AddOptionBtn = styled('li')`
  position: relative;
  margin-left: 16px;
  padding: 6px 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
  border: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    top: 45%;
    transform: translateY(-50%);
    background: ${({ theme }) => theme.palette.primary.main};
  }
  &:before {
    left: 0;
    width: 8px;
    height: 2px;
  }
  &:after {
    left: 3px;
    width: 2px;
    height: 8px;
  }
`
