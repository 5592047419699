import React from 'react'

import { ISample } from '@models/samples'
import { StyledDetailsBox } from '@styles'
import { checkSkills } from '@helpers/obtainingSkills'
import { InfoBoxTemperature } from '@components/shared'
import { infoBoxText } from '@components/shared/InfoBoxes/InfoBoxTemperature'

import { ViewMainInfo } from './ViewMainInfo'
import { ViewPurchaseDetails } from './ViewPurchaseDetails'
import { ViewManufactureDetails } from './ViewManufactureDetails'

interface ViewSampleDetailsProps {
  sample: ISample
  isLoading: boolean
}

export const ViewSampleDetails: React.FC<ViewSampleDetailsProps> = ({ sample, isLoading }) => {
  const { isSampleTemperatureSensitive } = checkSkills(sample.main_apis)

  return (
    <>
      {isSampleTemperatureSensitive && (
        <InfoBoxTemperature temperatureText={infoBoxText.temperature.sample} />
      )}

      <StyledDetailsBox>
        <ViewMainInfo sample={sample} isLoading={isLoading} />
        <ViewPurchaseDetails sample={sample} isLoading={isLoading} />
        <ViewManufactureDetails sample={sample} isLoading={isLoading} />
      </StyledDetailsBox>
    </>
  )
}
