import { Button, ButtonProps } from '@mui/material'
import { Box } from '@mui/system'
import styled from 'styled-components'

export const StyledSwitchBox = styled(Box)`
  padding: 6px;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.additional.stroke.main};
  border-radius: 71px;
  z-index: 249;
`

interface StyledSwitchButtonProps extends ButtonProps {
  $isActiveButton?: boolean
  $isInactiveButton?: boolean
}

export const StyledSwitchButton = styled<React.FC<StyledSwitchButtonProps>>(Button)`
  padding: 6px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 71px;
  &.Mui-disabled {
    background-color: transparent;
  }
`

export const StyledSwitchButtonTesting = styled(StyledSwitchButton)`
  background-color: ${({ theme, $isActiveButton }) =>
    $isActiveButton ? '#ffffff' : theme.palette.primary.main};
  color: ${({ $isActiveButton }) => ($isActiveButton ? '#8F929B' : '#ffffff')};

  &:hover {
    background-color: ${({ theme, $isActiveButton }) =>
      $isActiveButton ? '#ffffff' : theme.palette.primary.main};
    color: ${({ $isActiveButton }) => ($isActiveButton ? '#000' : '#ffffff')};
  }
`

export const StyledSwitchButtonBuying = styled(StyledSwitchButton)`
  background-color: ${({ theme, $isActiveButton }) =>
    $isActiveButton ? theme.palette.primary.main : '#ffffff'};
  color: ${({ $isActiveButton }) => ($isActiveButton ? '#ffffff' : '#8F929B')};

  &:hover {
    background-color: ${({ theme, $isActiveButton }) =>
      $isActiveButton ? theme.palette.primary.main : '#ffffff'};
    color: ${({ $isActiveButton }) => ($isActiveButton ? '#ffffff' : '#000')};
  }
`
