import ContentLoader from 'react-content-loader'

export const ScrollNextSamplesLoader: React.FC = props => (
  <ContentLoader
    speed={1.8}
    width='100%'
    height='100%'
    viewBox='0 0 1426 68'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    {...props}
  >
    <path d='M 0 66 h 1426 v 2 H 0 z M 0 0 h 2 v 66 H 0 z M 1424 0 h 2 v 66 h -2 z' />
    <rect x='34' y='23' rx='4' ry='4' width='20' height='20' />
    <rect x='86' y='23' rx='4' ry='4' width='86' height='20' />
    <rect x='204' y='23' rx='4' ry='4' width='188' height='20' />
    <rect x='424' y='23' rx='4' ry='4' width='188' height='20' />
    <rect x='644' y='23' rx='4' ry='4' width='188' height='20' />
    <rect x='864' y='23' rx='4' ry='4' width='188' height='20' />
    <rect x='1084' y='23' rx='4' ry='4' width='310' height='20' />
  </ContentLoader>
)
