import React from 'react'
import { useDispatch } from 'react-redux'
import { Autocomplete as AutocompleteMUI, CircularProgress } from '@mui/material'
import { useController } from 'react-hook-form'
import { debounce } from 'lodash'

import { IOption } from '@models/common/app'
import { AutocompleteWrapper, Input, Tooltip } from '@components/shared'
import { setCellValueToSample } from '@state/sampleCreate'
import { useFetchManufacture } from '@hooks/queries'

import { Paper } from './Paper'
import { AutocompleteProps } from './Autocomplete.type'

export const ManufactureField: React.FC<AutocompleteProps> = React.memo(props => {
  const {
    name,
    enableToAddOptions,
    newOptionsType,
    rules,
    pr,
    mr,
    initialVal,
    control,
    sampleId,
    cellName,
  } = props

  const optionsRef = React.useRef<IOption[] | null>(null)
  const [inputValue, setInputValue] = React.useState<string>('')
  const [storeError, setStoreError] = React.useState<string>('')
  const [topScroll, setTopScroll] = React.useState<{ oldScrollTop: number; isScrollTop: boolean }>({
    oldScrollTop: 0,
    isScrollTop: false,
  })
  const dispatch = useDispatch()
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: initialVal?.val })

  const setCellValue = (val: IOption | null, error: string) => {
    if (cellName && sampleId) {
      dispatch(
        setCellValueToSample({
          cellValue: { val, error },
          cellName,
          sampleId,
        })
      )
    }
  }
  const isAddedNewManufacturer = !!inputValue.length

  const searchQuery = field?.value?.name === inputValue ? '' : inputValue

  const { data, isFetching, fetchNextPage, hasNextPage } = useFetchManufacture(searchQuery)
  const options = data && data.pages.map(page => page.results).flat()

  React.useEffect(() => {
    if (initialVal && initialVal.error) setStoreError(initialVal.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!!error && error.message) setCellValue(field.value, error.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  React.useEffect(() => {
    if (!!options) {
      if (optionsRef.current && options.length > optionsRef.current.length) {
        const newOption = options.filter(
          option => !optionsRef.current?.some(currentOption => currentOption.id === option.id)
        )

        if (newOption.length && inputValue === newOption[0].name) {
          field.onChange({ target: { value: newOption[0] } })
          setCellValue(newOption[0], '')
        }
      }

      optionsRef.current = options
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const onInputChangeHandler = (e: any, v: string) => {
    setInputValue(v)
  }
  const debounceChoiceQuery = debounce(onInputChangeHandler, 500)

  return (
    <AutocompleteWrapper pr={pr} height={42} bgColor={'#fff'}>
      <AutocompleteMUI
        fullWidth
        size='small'
        loading={isFetching || false}
        options={options || []}
        noOptionsText='Nothing found'
        getOptionLabel={option => option.name || inputValue}
        onInputChange={enableToAddOptions ? debounceChoiceQuery : undefined}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={params => (
          <Tooltip
            isPureTooltip
            maxWidth={230}
            shouldBeHidden={(field.value?.name.length || 0) <= 37}
            title={field.value?.name || ''}
          >
            <Input
              pr={pr}
              error={!!error || !!storeError}
              variant='outlined'
              helperText={error?.message || storeError}
              placeholder='Type here values...'
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isFetching ? (
                      <CircularProgress
                        color='primary'
                        sx={{ marginRight: mr || '4px' }}
                        size={16}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          </Tooltip>
        )}
        PaperComponent={
          enableToAddOptions && newOptionsType && isAddedNewManufacturer
            ? props => (
                <Paper
                  inputValue={inputValue}
                  name={name}
                  hasError={!!error || !!storeError}
                  type={newOptionsType}
                  {...props}
                />
              )
            : undefined
        }
        ListboxProps={{
          onScroll: event => {
            const listboxNode = event.currentTarget

            const isFetchNextPage =
              hasNextPage &&
              Math.round(listboxNode.scrollTop + listboxNode.clientHeight) ===
                listboxNode.scrollHeight

            if (isFetchNextPage && fetchNextPage && setTopScroll) {
              const top = Math.round(listboxNode.scrollHeight - listboxNode.clientHeight)

              fetchNextPage()
              setTopScroll({ oldScrollTop: top, isScrollTop: true })
            }

            if (
              topScroll &&
              listboxNode.scrollHeight > topScroll.oldScrollTop &&
              topScroll.isScrollTop &&
              setTopScroll
            ) {
              listboxNode.scrollTo(0, topScroll.oldScrollTop)

              setTopScroll(prev => ({ ...prev, isScrollTop: false }))
            }
          },
        }}
        {...field}
        onChange={(e: any, v: IOption | null) => {
          field.onChange({ target: { value: v } })
          setCellValue(v, '')
        }}
      />
    </AutocompleteWrapper>
  )
})
