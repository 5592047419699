import React from 'react'
import { cloneDeep } from 'lodash'

import { useAddAttachments } from '@hooks/queries'
import { IAttachments } from '@models/attachments'

import { AttachFile } from './AttachFile'
import { AttachLinkForm } from './AttachLinkForm'
import { AttachedMaterialsList } from './AttachedMaterialsList'
import { PureAttachmentsPropTypes } from './pureAttachments.types'
import { StyledOrCup } from './styles'

export const PureAttachments: React.FC<PureAttachmentsPropTypes> = ({
  initialAttachments,
  modalType,
  contentType,
  section,
  sectionTitle,
  setAttachmentsToParent,
  maxNumberOfAttachments = 3,
  attachmentsIsLoading,
  objectId,
}) => {
  const deletedInitialAttachmentIdsRef = React.useRef<string[]>([])
  const deletedInitialAttachmentIds = deletedInitialAttachmentIdsRef.current
  const attachmentsFlagRef = React.useRef(false)
  const [attachments, setAttachments] = React.useState<IAttachments>(initialAttachments)
  const addAttachmentsM = useAddAttachments()

  React.useEffect(() => {
    if (addAttachmentsM.isSuccess) {
      const oldAttachments = cloneDeep(attachments)

      addAttachmentsM.data.result.forEach(newAttach => {
        const oldAttachIndex = oldAttachments.findIndex(
          oldAttach => oldAttach.name === newAttach.name && typeof oldAttach.id === 'string'
        )
        oldAttachments.splice(oldAttachIndex, 1)
      })

      setAttachments([...oldAttachments, ...addAttachmentsM.data.result])
      attachmentsIsLoading(false)
      addAttachmentsM.reset()
    }

    if (addAttachmentsM.isLoading) attachmentsIsLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAttachmentsM])

  React.useEffect(() => {
    // We skip the first call to the local state of attachments by the "attachmentsFlagRef" when
    // initial attachments are added to the local state of attachments when mounting a component
    if (attachmentsFlagRef.current && !attachments.some(item => typeof item.id === 'string')) {
      setAttachmentsToParent(attachments)
    }
    if (!attachmentsFlagRef.current) {
      attachmentsFlagRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments])

  const removeAttachment = (id: string) => {
    if (objectId && initialAttachments.find(initAttach => initAttach.id === +id)) {
      deletedInitialAttachmentIds.push(id)
    }
    setAttachments(prevState => prevState.filter(item => item.id !== +id))
  }

  return (
    <>
      {/* DROP ZONE COMPONENT */}
      <AttachFile
        deletedInitialAttachmentIds={deletedInitialAttachmentIds}
        objectId={objectId}
        section={section}
        modalType={modalType}
        contentType={contentType}
        attachments={attachments}
        sectionTitle={sectionTitle}
        setAttachments={setAttachments}
        addingError={addAttachmentsM.error}
        addAttachments={addAttachmentsM.mutateAsync}
        maxNumberOfAttachments={maxNumberOfAttachments}
      />
      <StyledOrCup variant='body1'>or</StyledOrCup>
      {/* ADD LINK COMPONENT */}
      <AttachLinkForm
        section={section}
        attachments={attachments}
        contentType={contentType}
        sectionTitle={sectionTitle}
        setAttachments={setAttachments}
        isDeactivate={attachments.length >= maxNumberOfAttachments}
        addAttachments={addAttachmentsM.mutateAsync}
        placeholders={{
          url: 'Put here URL in this format https:/...',
          title: 'Type here Title...',
        }}
      />
      {/* ALL ATTACHMENT MATERIALS */}
      <AttachedMaterialsList attachments={attachments} removeAttachment={removeAttachment} />
    </>
  )
}
