import React from 'react'
import { TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import dayjs, { Dayjs } from 'dayjs'
import { useDispatch } from 'react-redux'

import sideArrow from '@assets/icons/side-arrow.svg'
import { setFilterError } from '@state/filters'

import * as DatePickerStyled from './../styles'

interface DateFieldsProps {
  setEnteringDate: (arg1: 0 | 1) => void
  enteringDate: number
  inputStartDate: (date: Dayjs | null) => void
  inputFinishDate: (date: Dayjs | null) => void
  startDate: null | string
  finishDate: null | string
  onBlurStartDate: any
  onBlurFinishDate: any
}

export const DateFields: React.FC<DateFieldsProps> = React.memo(
  ({
    setEnteringDate,
    enteringDate,
    inputStartDate,
    inputFinishDate,
    startDate,
    finishDate,
    onBlurStartDate,
    onBlurFinishDate,
  }) => {
    const dispatch = useDispatch()

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePickerStyled.StyledDateFieldWrapper>
          <DatePickerStyled.StyledDesktopDatePickerWrapper
            $enteringDate={enteringDate === 0}
            onFocus={e => setEnteringDate(0)}
            onBlur={onBlurStartDate}
          >
            <DesktopDatePicker
              key='0'
              onError={error => dispatch(setFilterError({ value: Boolean(error), ind: 0 }))}
              value={startDate?.replace('null', '') ? dayjs(startDate) : null}
              onChange={inputStartDate}
              renderInput={params => <TextField {...params} />}
            />
          </DatePickerStyled.StyledDesktopDatePickerWrapper>
          <DatePickerStyled.StyledIcon>
            <img src={sideArrow} alt='side arrow' />
          </DatePickerStyled.StyledIcon>
          <DatePickerStyled.StyledDesktopDatePickerWrapper
            $enteringDate={enteringDate === 1}
            onFocus={() => setEnteringDate(1)}
            onBlur={onBlurFinishDate}
          >
            <DesktopDatePicker
              key='1'
              onError={error => dispatch(setFilterError({ value: Boolean(error), ind: 1 }))}
              value={finishDate?.replace('null', '') ? dayjs(finishDate) : null}
              onChange={inputFinishDate}
              renderInput={params => <TextField {...params} />}
            />
          </DatePickerStyled.StyledDesktopDatePickerWrapper>
        </DatePickerStyled.StyledDateFieldWrapper>
      </LocalizationProvider>
    )
  }
)
