import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Chip, ParagraphLink } from '@components/shared'
import { ISampleTask } from '@models/samples'
import { StyledDetailsTableCell, StyledDetailsTableRow } from '@styles'
import { setLaboratoryForWorkspace } from '@state/workSpace'
import { protectedRoutes } from '@configs/routes.config'
import { DETAILS } from '@constants/common'

interface TaskRowProps {
  task: ISampleTask
  sampleId: number
  specificId?: string
}

export const TaskRow: React.FC<TaskRowProps> = ({ task, sampleId, specificId }) => {
  const { id, status, laboratory, test_type, serial_number_of_equipment } = task
  const dispatch = useDispatch()
  const history = useHistory()

  const viewLaboratoryInfoEndPoint = () => {
    if (laboratory) {
      dispatch(setLaboratoryForWorkspace(laboratory))
    }

    history.replace({
      pathname: protectedRoutes.yourWorkspace.path,
      state: [
        {
          path: `${protectedRoutes.samples.path}/${sampleId}${DETAILS}`,
          url: `${protectedRoutes.samples.path}/${sampleId}${DETAILS}`,
          title: `Sample ${specificId}`,
        },
      ],
    })
  }

  return (
    <StyledDetailsTableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        'td:first-child': { paddingLeft: '40px' },
      }}
    >
      <StyledDetailsTableCell>{id || '-'}</StyledDetailsTableCell>

      <StyledDetailsTableCell>
        {status.value ? <Chip label={status.value} status={status.key} /> : '-'}
      </StyledDetailsTableCell>

      <StyledDetailsTableCell>
        <ParagraphLink onClick={viewLaboratoryInfoEndPoint}>{laboratory.name || '-'}</ParagraphLink>
      </StyledDetailsTableCell>

      <StyledDetailsTableCell>{test_type.name || '-'}</StyledDetailsTableCell>

      <StyledDetailsTableCell>{serial_number_of_equipment || '-'}</StyledDetailsTableCell>
    </StyledDetailsTableRow>
  )
}
