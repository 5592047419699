import React from 'react'
import { useDispatch } from 'react-redux'
import { useForm, useWatch } from 'react-hook-form'
import { IconButton } from '@mui/material'

import { SampleOptionsContext } from '@context'
import * as sampleConstants from '@constants/samples'
import { useAppSelector } from '@hooks/redux'
import activeDeleteIcon from '@assets/icons/delete-icon-active.svg'
import disabledDeleteIcon from '@assets/icons/delete-icon-disabled.svg'
import { deleteSample, sampleCreate } from '@state/sampleCreate'
import { setTriggerFlag } from '@state/common/sampleCreateUIState'
import {
  deleteSampleCreateId,
  sampleCreateIds,
  seCopySampleId,
  setAddSampleButtonPushed,
  addSampleButtonPushedNumb,
} from '@state/app'
import {
  ATTACHMENTS,
  SAMPLE_TYPE,
  STORAGE_NOTES,
  TEMPERATURE_FORMAT,
  TOXICITY_LEVEL,
} from '@constants/samples'
import { IInitialSample } from '@state/sampleCreate/sampleCreateState.types'

import * as Styled from '../styles'
import { TableRowTypes } from '../sampleCreate.types'
import { bodyCellsData } from '../maps/bodyCellsData'
import { GetStoreSampleData } from '../components'
import { CopyButton } from './CopyButton'

export const TableRow: React.FC<TableRowTypes> = React.memo(
  ({ rowNumber, sampleId, isLastSample, control, idx, clearErrors }) => {
    const hiddenTrigger = React.useRef<HTMLButtonElement | null>(null)
    const [storeSample, setStoreSample] = React.useState<IInitialSample | undefined | null>(null)
    const addSampleButtonPushed = useAppSelector(addSampleButtonPushedNumb)
    const projectOfSample = useAppSelector(sampleCreate).samples.find(
      sample => sample.id === sampleId
    )?.project.val
    const dispatch = useDispatch()
    const sampleOptions = React.useContext(SampleOptionsContext)
    const sampleIds = useAppSelector(sampleCreateIds)
    const { trigger } = useForm()
    const [manufactureDate, expirationDate] = useWatch({
      control,
      name: [
        `${sampleId}_${sampleConstants.MANUFACTURE_DATE}`,
        `${sampleId}_${sampleConstants.EXPIRATION_DATE}`,
      ],
    })

    const fundingOfProjects = React.useMemo(() => {
      const funding =
        projectOfSample &&
        projectOfSample?.funding.map(({ value, ...rest }: any) => ({ ...rest, name: value }))
      return funding
    }, [projectOfSample])

    const cells = React.useMemo(() => {
      if (storeSample) {
        return (
          bodyCellsData(
            sampleOptions,
            sampleId,
            manufactureDate,
            expirationDate,
            storeSample,
            control,
            clearErrors,
            fundingOfProjects
          ) || []
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sampleOptions, sampleId, manufactureDate, expirationDate, storeSample, control])

    React.useEffect(() => {
      sampleIds.length > 1 && hiddenTrigger.current?.click()
    }, [sampleIds])

    const handleDeleteSample = () => {
      dispatch(deleteSampleCreateId(sampleId))
      dispatch(deleteSample(sampleId))
      dispatch(setTriggerFlag())

      // for helper tooltip about cope of samples
      if (addSampleButtonPushed < 3) dispatch(setAddSampleButtonPushed(addSampleButtonPushed + 1))
    }

    const cellsWithoutStyledWrap = [
      SAMPLE_TYPE,
      TOXICITY_LEVEL,
      TEMPERATURE_FORMAT,
      STORAGE_NOTES,
      ATTACHMENTS,
    ]

    return !storeSample ? (
      <GetStoreSampleData sampleId={sampleId} setStoreSample={setStoreSample} />
    ) : (
      <>
        <button
          style={{ display: 'none' }}
          type='button'
          ref={hiddenTrigger}
          onClick={() => trigger()}
        />
        <div style={{ display: 'flex' }} className='create-sample-check-scroll-row'>
          <Styled.FirstTableCell>{rowNumber + 1}.</Styled.FirstTableCell>
          {cells?.length
            ? cells?.map(({ field: Field, width, ...props }, idx) => {
                if (idx === cells.length - 1) {
                  const scrollCupChild = document.getElementsByClassName(
                    'horizontal-scroll-box-inside'
                  )[0] as HTMLDivElement
                  const firstRow = document.getElementsByClassName(
                    'create-sample-check-scroll-row'
                  )[0]
                  if (
                    scrollCupChild &&
                    firstRow &&
                    scrollCupChild.scrollWidth !== firstRow.scrollWidth
                  ) {
                    scrollCupChild.style.width = `${firstRow.scrollWidth}px`
                  }
                  setTimeout(() => dispatch(seCopySampleId('')), 100)
                }

                return cellsWithoutStyledWrap.includes(props.cellName) ? (
                  <Field width={width} {...props} key={`${props.name}_${idx}`} />
                ) : (
                  <Styled.TableCell
                    id={props.name}
                    key={`${props.name}_${idx}`}
                    style={{ minWidth: width }}
                  >
                    <Field {...props} width={width} />
                  </Styled.TableCell>
                )
              })
            : null}

          <Styled.FixedTableCell $width={200} isLastSample={isLastSample}>
            <CopyButton sampleId={sampleId} idx={idx} />

            {!isLastSample && (
              <IconButton
                aria-label='delete'
                disabled={sampleIds.length < 2}
                onClick={handleDeleteSample}
              >
                <img
                  style={{ width: 24, height: 24 }}
                  src={sampleIds.length < 2 ? disabledDeleteIcon : activeDeleteIcon}
                  alt='delete'
                />
              </IconButton>
            )}
          </Styled.FixedTableCell>
        </div>
      </>
    )
  }
)
