import { useMutation, useQueryClient } from 'react-query'

import { USERS } from '@components/shared/FilterAside/constants'
import { IError } from '@models/common/app'
import { editUser, HttpServiceRequestParam, IEditUserRequestData } from '@api/user'

export const useEditUser = (type?: 'create' | 'edit') => {
  const client = useQueryClient()

  const mutation = useMutation<
    IEditUserRequestData,
    IError,
    HttpServiceRequestParam<Partial<IEditUserRequestData>, { id: string }>
  >(editUser, {
    onSuccess: () => {
      client.invalidateQueries([USERS])
    },
  })

  return mutation
}
