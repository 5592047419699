import { Typography, styled } from '@mui/material'

export const StyledInfoChip = styled('span')`
  color: #616d84;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
  margin-left: 8px;
  border-radius: 53px;
  background: #f4f6fa;
`

export const StyledTitle = styled(Typography)`
  display: flex;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 8px;
`

export const StyledCheckboxBox = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 22px;
`
