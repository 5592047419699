import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'

import { ASSIGNEE_PERSON } from '@constants/queries'
import { fetchAllAssigneesPersons } from '@api/samples'
import { IAssigneesFilterWithTypeOfLab } from '@models/filters'

export const useFetchAssigneeFilterWithTypeOfLab = (
  desiredAssignee: string | undefined
): UseInfiniteQueryResult<IAssigneesFilterWithTypeOfLab> => {
  return useInfiniteQuery([ASSIGNEE_PERSON, desiredAssignee], fetchAllAssigneesPersons, {
    getNextPageParam: lastPage => {
      if (lastPage.current_page < lastPage.count_pages) return lastPage.current_page + 1
      return false
    },
  })
}
