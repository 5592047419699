import React from 'react'

export const useCustomLazyScroll = (
  parentRef: React.RefObject<HTMLElement | HTMLDivElement>,
  childRef: React.RefObject<HTMLDivElement>,
  callback?: () => void,
  isLoadMore?: boolean,
  hasNextPage?: boolean
) => {
  const observer = React.useRef<IntersectionObserver | null>(null)

  React.useEffect(() => {
    const options = {
      root: parentRef.current,
      rootMargin: '0px',
      threshold: 0,
    }

    observer.current = new IntersectionObserver(([target]) => {
      if (target.isIntersecting && !isLoadMore && hasNextPage && callback) {
        callback()
      }
    }, options)

    if (childRef.current) {
      observer.current.observe(childRef.current)
    }

    return function () {
      if (observer.current && childRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.current.unobserve(childRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, isLoadMore, hasNextPage, parentRef])
}
