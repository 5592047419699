import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { IconButton, Typography, Box, useTheme } from '@mui/material'

import { selectUser } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { useFetchBlockchainHistory } from '@hooks/queries'
import { BlockchainHistoryType } from '@models/common/app'
import { StyledPageSubTitle } from '@styles'
import { ReactComponent as BlockchainMenuOpen } from '@assets/icons/blockchain-menu-open-icon.svg'

import {
  StyledHistoryListWrapper,
  StyledDrawer,
  StyledDrawerHeader,
  StyledColoredParagraph,
  DrawerOpenButton,
  StyledArrowBackBox,
} from './styles'
import { RenderBlockchainHistory } from './Actions/RenderBlockchainHistory'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

interface BlockchainHistoryProps {
  itemId: number
  blockchainHistoryType: BlockchainHistoryType
}

export const BlockchainHistory: React.FC<BlockchainHistoryProps> = ({
  itemId,
  blockchainHistoryType,
}) => {
  const theme = useTheme()
  const { timezone } = useAppSelector(selectUser)
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const { data, isLoading, hasNextPage, fetchNextPage, isFetching } = useFetchBlockchainHistory(
    itemId,
    blockchainHistoryType
  )

  // This useEffect is only for rerender component after refetching
  React.useEffect(() => {}, [isFetching])

  const blockchainHistory = data?.pages?.map(page => [...page.results]).flat()

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (e: React.KeyboardEvent | React.MouseEvent) => {
      if (
        e.type === 'keydown' &&
        ((e as React.KeyboardEvent).key === 'Tab' || (e as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setState({ ...state, [anchor]: open })
    }

  return (
    <>
      <DrawerOpenButton>
        <Typography>Blockchain history</Typography>
        <IconButton disabled={isLoading} onClick={toggleDrawer('right', true)}>
          <Box onClick={toggleDrawer('right', false)}>
            <BlockchainMenuOpen fill={theme.palette.primary.main} />
          </Box>
        </IconButton>
      </DrawerOpenButton>
      <StyledDrawer anchor='right' open={state['right']} onClose={toggleDrawer('right', false)}>
        <StyledDrawerHeader>
          <Box>
            <StyledPageSubTitle variant='h4'>Blockchain history</StyledPageSubTitle>
            <StyledColoredParagraph variant='body2'>
              {data ? `Total ${data.pages[0].count}` : 'Loading...'}
            </StyledColoredParagraph>
          </Box>
          <StyledArrowBackBox onClick={toggleDrawer('right', false)}>
            <BlockchainMenuOpen fill={theme.palette.primary.main} />
          </StyledArrowBackBox>
        </StyledDrawerHeader>

        <StyledHistoryListWrapper role='presentation'>
          <InfiniteScroll
            hasMore={hasNextPage}
            loadMore={fetchNextPage as (page: number) => void}
            useWindow={false}
          >
            <RenderBlockchainHistory blockchainHistory={blockchainHistory} timezone={timezone} />
          </InfiniteScroll>
        </StyledHistoryListWrapper>
      </StyledDrawer>
    </>
  )
}
