import * as React from 'react'
import { Box, FormControlLabel } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { cloneDeep } from 'lodash'

import { Checkbox } from '@components/shared'
import { PureTooltip } from '@components/ui/PureTooltip'
import { ReactComponent as AddIcon } from '@assets/icons/add-icon.svg'
import { RESULT_RECOGNIZED, SUBSTANCES } from '@constants/tests'

import { SubstancesItemGroup } from './SubstancesItemGroup'
import { StyledAddSubstancesBtn } from './styles'

export const SubstancesRecognized = () => {
  const { register, setValue, getValues, control } = useFormContext()
  const { task } = getValues()
  const [isNotRecognized, setIsNotRecognized] = React.useState(task.result_is_not_recognized)

  const { fields, append } = useFieldArray({
    control,
    name: `fields.${SUBSTANCES}`,
  })

  const newSubstance = {
    name: '',
    percentage: '',
    is_not_determined: false,
    clinically_meaningful: '',
    concentration: '',
    concentration_type: '',
  }

  React.useEffect(() => {
    if (!fields.length) {
      append(newSubstance)
    }
    return () => {
      setIsNotRecognized(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddNewSubstances = () => {
    if (!isNotRecognized && fields.length < 10) {
      append(newSubstance)
    }
  }

  const handleRemoveItemGroup = (index: number) => {
    const clonedFormSubstances = cloneDeep(getValues(`fields.${SUBSTANCES}`))
    clonedFormSubstances.splice(index, 1)
    setValue(`fields.${SUBSTANCES}`, clonedFormSubstances)
  }

  const handleNotRecognized = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setValue(`fields.${RESULT_RECOGNIZED}`, checked)
    setIsNotRecognized(checked)
  }

  return (
    <Box display='flex' flexDirection='column'>
      <>
        <FormControlLabel
          control={
            <Checkbox
              {...register(`fields.${RESULT_RECOGNIZED}`)}
              checked={isNotRecognized}
              onChange={handleNotRecognized}
            />
          }
          label='Substances were not recognized'
          sx={{ marginBottom: '30px' }}
        />
        {fields.map((field, idx) => (
          <SubstancesItemGroup
            key={field.id}
            index={idx}
            isNotRecognized={isNotRecognized}
            handleRemoveItemGroup={() => handleRemoveItemGroup(idx)}
            isOneSubstance={fields.length === 1}
          />
        ))}
      </>

      <PureTooltip
        title='Maximum of 10 substances test results can be added'
        titleStyle={{ width: 213 }}
        shouldBeHidden={fields.length < 10}
      >
        <StyledAddSubstancesBtn
          type='button'
          $disabled={isNotRecognized || fields.length > 9}
          onClick={handleAddNewSubstances}
        >
          <AddIcon />
          Add substance
        </StyledAddSubstancesBtn>
      </PureTooltip>
    </Box>
  )
}
