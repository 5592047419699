import { Box, styled } from '@mui/material'

import deleteChipIcon from '@assets/icons/delete-chip-icon.svg'

interface WrapperProps {
  $isMainChip?: boolean
}

export const Wrapper = styled<React.FC<WrapperProps>>(Box)`
  display: flex;
  align-items: center;
  margin: 0 5px 5px 0;
  padding: 0 6px 0 12px;
  height: 32px;
  font-size: 14px;
  border-radius: 16px;
  font-weight: ${({ $isMainChip }) => ($isMainChip ? 600 : 400)};
  background: ${({ theme, $isMainChip }) => ($isMainChip ? theme.primary[20] : theme.primary[30])};
`

export const DeleteBtn = styled('button')`
  margin-left: 6px;
  padding: 0;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background: ${() => `url(${deleteChipIcon}) 50% no-repeat`};
  background-size: cover;
`

export const StartChipIcon = styled('span')`
  display: block;
  margin-right: 6px;
  width: 6px;
  min-width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.main};
`
