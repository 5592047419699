import { CircularProgress } from '@mui/material'

import { useDeleteExtraTask } from '@hooks/queries'
import deleteIcon from '@assets/icons/delete-icon-active.svg'
import { Image } from '@styles'
import { notifications } from '@constants/notifications'
import { showNotification } from '@state/app'
import { useAppSelector } from '@hooks/redux'
import { selectUserLaboratory } from '@state/user'
import { useToggle } from '@hooks/common'
import { ConfirmationModal } from '@components/shared'
import { ITasksLab } from '@models/tasks'

import { StyledIconButton } from '../ExpandedTableBody/styles'

interface ExtraButtonProps {
  taskId: string
  sampleId: number
  laboratoryId: number
  testName: string
  isFetchingSamples?: boolean
}
export const DeleteExtraTaskButton: React.FC<ExtraButtonProps> = props => {
  const { taskId, sampleId, laboratoryId, testName, isFetchingSamples } = props
  const { id: currentLaboratoryId } = useAppSelector(selectUserLaboratory)
  const [isConfirmationModalOpen, toggleConfirmationModal] = useToggle()

  const deleteExtraTaskM = useDeleteExtraTask<ITasksLab>()

  const deleteExtraTaskHandler = () => {
    deleteExtraTaskM.mutate(
      { taskId, sampleId },
      {
        onError: () => {
          showNotification(notifications.deleteExtraTaskFailed)
        },
      }
    )
  }

  if (laboratoryId !== currentLaboratoryId) {
    return null
  }

  if (deleteExtraTaskM.isLoading)
    return (
      <CircularProgress sx={{ position: 'absolute', left: '50px' }} size={16} color='secondary' />
    )

  return (
    <>
      <StyledIconButton onClick={toggleConfirmationModal} disabled={isFetchingSamples}>
        <Image component='img' src={deleteIcon} width='24px' height='24px' sx={{}} />
      </StyledIconButton>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          title={`Delete ${testName} test?`}
          subtitle='You can add tests from table then.'
          cancelBtnText='No, return to table'
          confirmButtonText='Yes, delete test'
          onClose={toggleConfirmationModal}
          onConfirm={deleteExtraTaskHandler}
        />
      )}
    </>
  )
}
