import React from 'react'
import { TableBody } from '@mui/material'

import { ISampleTasks } from '@models/samples'
import { StyledDetailsTable } from '@styles'

import { TaskRow } from './TaskRow'
import { TableHead } from './TableHead'

interface TabBasicInfoProps {
  tasks: ISampleTasks
  sampleId: number
  specificId?: string
}

export const TabBasicInfo: React.FC<TabBasicInfoProps> = ({ tasks, sampleId, specificId }) => {
  return (
    <StyledDetailsTable>
      <TableHead />
      <TableBody>
        {tasks.map(task => (
          <TaskRow specificId={specificId} sampleId={sampleId} key={task.id} task={task} />
        ))}
      </TableBody>
    </StyledDetailsTable>
  )
}
