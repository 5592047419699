import React from 'react'

import { TasksStep } from '@components/shared'
import { AWAITING } from '@constants/tasks'
import { StyledPageSubTitle } from '@styles'
import { RESULTS_STEP_TITLE } from '@constants/tests'

import { StepHeader } from '../../../Testing/styles'

interface HeaderProps {
  actualStep: number
}

export const Header: React.FC<HeaderProps> = ({ actualStep }) => {
  return (
    <StepHeader className='test-header'>
      <TasksStep idx={actualStep - 1} stepCounter={actualStep} stepColor={AWAITING} />
      <StyledPageSubTitle variant='h3'>{RESULTS_STEP_TITLE}</StyledPageSubTitle>
    </StepHeader>
  )
}
