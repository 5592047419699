import { useQueryParams, StringParam } from 'use-query-params'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { ISamplesData } from '@models/samples'
import { fetchSamplesInsidePackage } from '@api/samples'
import { PACKAGES, SAMPLES_IN_PACKAGE } from '@constants/queries'
import { setMaintenanceModePage } from '@state/user'

export const useFetchSamplesInsidePackage = <T>(packageId: string) => {
  const [queryString] = useQueryParams({
    ordering: StringParam,
    desired_samples_inside_package: StringParam,
  })

  const client = useQueryClient()
  const dispatch = useDispatch()

  const { ordering, desired_samples_inside_package: desiredSamples } = queryString

  const queryKey = [SAMPLES_IN_PACKAGE, packageId, desiredSamples, ordering]

  return useInfiniteQuery<ISamplesData<T>, IError>(queryKey, fetchSamplesInsidePackage, {
    onSuccess: () => {
      client.invalidateQueries([PACKAGES, packageId])
    },
    getNextPageParam: lastPage => {
      const { next: nextPageUrl } = lastPage.links

      if (nextPageUrl) {
        const queryString = nextPageUrl.slice(nextPageUrl.indexOf('?'))
        const urlParams = new URLSearchParams(queryString)
        const nextPage = urlParams.get('page')

        return nextPage
      }
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
