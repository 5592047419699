import React from 'react'
import { Box, Typography } from '@mui/material'

import { Chip, TaskNotesField } from '@components/shared'
import { ISampleTask } from '@models/samples'
import { datePatterns } from '@constants/dates'
import { NOT_COMPLETED } from '@constants/tasks'
import { getFormattedDate } from '@helpers/ui/date'
import { useAppSelector } from '@hooks/redux'
import { selectUser, selectUserLaboratory } from '@state/user'
import { StyledDetailsTableCell, SubText } from '@styles'
import { SAMPLE_TASKS } from '@constants/queries'

import { ApiContent, Attachments } from './Cells'
import { StyledDetailsTableRow } from '../styles'
import { TestingDate } from './Cells/TestingDate'

interface TaskRowProps {
  task: ISampleTask
}

export const TaskRow: React.FC<TaskRowProps> = ({ task }) => {
  const { assignee, assignee_date, result, result_name } = task

  const { timezone } = useAppSelector(selectUser)
  const {
    group: { is_able_to_view_results },
  } = useAppSelector(selectUserLaboratory)

  const assigneeName =
    assignee?.first_name && assignee?.last_name
      ? `${assignee?.first_name} ${assignee?.last_name}`
      : '-'

  return (
    <StyledDetailsTableRow
      key={task.id}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        'td:first-child': { paddingLeft: '40px' },
      }}
    >
      <StyledDetailsTableCell sx={{ display: 'flex', flexDirection: 'column' }}>
        <TestingDate task={task} />
      </StyledDetailsTableCell>

      <StyledDetailsTableCell sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
        <Box mb={'7px'}>
          <SubText>Assignee</SubText>
          <Typography>{assigneeName}</Typography>
        </Box>

        <Box>
          <SubText sx={{ marginBottom: '2px' }}>Assignee date</SubText>
          <Typography>
            {getFormattedDate(datePatterns.timeDayMonth, assignee_date, timezone)}
          </Typography>
        </Box>
      </StyledDetailsTableCell>

      <ApiContent task={task} />

      <StyledDetailsTableCell>
        {!is_able_to_view_results && task.result === null ? (
          <Chip label='Hidden' status='hidden' />
        ) : (
          <>
            {result?.key === NOT_COMPLETED ? (
              '-'
            ) : (
              <Chip label={result_name} status={result?.key} />
            )}
          </>
        )}
      </StyledDetailsTableCell>

      <StyledDetailsTableCell>
        <Box mr='20px' width='100%'>
          <TaskNotesField
            isAbleToAddTaskNotes={task.is_able_to_add_task_notes}
            notes={task.task_notes}
            sampleId={task.sample}
            taskId={task.id}
            contentType={SAMPLE_TASKS}
          />
        </Box>
      </StyledDetailsTableCell>
      <Attachments task={task} mrForBtn={'25px'} />
    </StyledDetailsTableRow>
  )
}
