import React from 'react'

import { PhysicalForm as SharedPhysicalForm } from '@components/shared/Hr'
import { IHRSample } from '@models/samples'

import { StyledTableCell } from '../../styles'

interface PhysicalFormProps {
  sample: IHRSample
  isMobile?: boolean
}

export const PhysicalForm: React.FC<PhysicalFormProps> = ({ sample, isMobile }) => {
  return (
    <StyledTableCell>
      <SharedPhysicalForm physical_form={sample?.physical_form} />
    </StyledTableCell>
  )
}
