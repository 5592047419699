import { PieChart, Pie, Legend } from 'recharts'
import { Box, Typography } from '@mui/material'

import { IWidgetData } from '@models/testingReports'

interface WidgetProps {
  data: IWidgetData[]
  widgetTitle: string
  legendWidth: string
  totalCount: number
}

export const Widget: React.FC<WidgetProps> = ({ data, widgetTitle, legendWidth, totalCount }) => {
  const renderLegend = (value: string, entry: any) => {
    return (
      <Box display='inline-flex' width={legendWidth} justifyContent='space-between'>
        <Typography fontSize={12} color='#616D84' ml='4px'>
          {value}
        </Typography>

        <Box fontSize={13} display='flex' alignContent='space-between'>
          <Typography color='#1D2129' fontWeight={700} display='inline-block' mr='5px' width='40px'>
            {entry?.payload?.payload?.payload?.percent
              ? entry.payload.payload.payload.percent
              : '-'}
          </Typography>
          <Typography color='#778299' display='inline-block' width='30px'>
            ({entry?.payload?.payload?.payload?.value ? entry.payload.payload.payload.value : '0'})
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Typography fontWeight={700}>{widgetTitle}</Typography>
      <PieChart width={480} height={200}>
        {/* [ text inside the pie */}
        <text
          x={104}
          y={95}
          fontSize={18}
          fontWeight={700}
          textAnchor='middle'
          dominantBaseline='middle'
        >
          {totalCount}
        </text>
        <text
          x={104}
          y={115}
          textAnchor='middle'
          fill='#8E9ABB'
          fontSize={13}
          dominantBaseline='middle'
        >
          {widgetTitle}
        </text>
        {/* text inside the pie ] */}

        <Legend
          height={36}
          iconType='circle'
          layout='vertical'
          verticalAlign='middle'
          iconSize={10}
          formatter={renderLegend}
        />

        <Pie
          data={data}
          cx={100}
          cy={100}
          innerRadius={60}
          outerRadius={80}
          paddingAngle={0}
          dataKey='value'
        />
      </PieChart>
    </>
  )
}
