import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'

import { Button } from '@components/shared'
import { ButtonBox } from '@styles'
import padReaderLogo from '@assets/images/PAD-reader-logo.svg'
import { useLogIntoPadReaderApp } from '@hooks/queries'

import { Image as MobileImage, LogInLink } from '../styles'
import { StyledBox } from './styles'

export const MobileModal = () => {
  const history = useHistory()
  const scanQrForPadApp = useLogIntoPadReaderApp()
  const padApp = scanQrForPadApp.data

  const goBack = () => history.goBack()

  return (
    <>
      <StyledBox>
        <MobileImage src={padReaderLogo} />
        <Typography variant='subtitle1'>PADReader</Typography>
      </StyledBox>

      <ButtonBox $isMobile>
        <Box width='90%' position='fixed' sx={{ bottom: '90px' }}>
          {padApp && (
            <Button sx={{ padding: '0' }} type='submit' variant='contained' width='100%' mb='16px'>
              <LogInLink href={padApp.link}>Open the PADReader app</LogInLink>
            </Button>
          )}

          <Button
            type='button'
            variant='outlined'
            color='secondary'
            bgColor='#fff'
            width='100%'
            onClick={goBack}
          >
            Back
          </Button>
        </Box>
      </ButtonBox>
    </>
  )
}
