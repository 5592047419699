import React from 'react'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'

import { FullPageError } from '@components/shared'
import { useFetchSampleTasks } from '@hooks/queries'
import { ContentType } from '@models/common/ui'
import { ISampleTasks, ISampleTasksTable } from '@models/samples'
import { editingNotesFieldId, isEditingNotes } from '@state/samples'

import { StyledTable } from './styles'
import { ExpandedTableHead } from './ExpandedTableHead/ExpandedTableHead'
import { ExpandedTableBody } from './ExpandedTableBody/ExpandedTableBody'
import { TasksLoader } from './TasksLoader'

interface SampleTasksProps {
  contentType: ContentType
  sampleId: number
  labId?: number
  isFetchingSamples?: boolean
  headerContent: ISampleTasksTable
  referredWidth?: string
  greatestTasksAmount?: number
  splitStatus?: string
}

export const SampleTasks: React.FC<SampleTasksProps> = React.memo(props => {
  const {
    contentType,
    isFetchingSamples,
    sampleId,
    headerContent,
    referredWidth,
    labId,
    greatestTasksAmount,
    splitStatus,
  } = props
  const dispatch = useDispatch()

  const sampleTasksByIdQ = useFetchSampleTasks(sampleId)
  const tasks = sampleTasksByIdQ

  React.useEffect(
    // Removing query cache in react-query
    () => () => {
      sampleTasksByIdQ.remove()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const tasksData = sampleTasksByIdQ.data?.results
  const tasksRules = sampleTasksByIdQ.data?.properties
  const tasksIsLoading = tasks && tasks?.isLoading
  const tasksIsError = tasks && tasks?.isError

  const refetchTasks = () => tasks.refetch()

  React.useEffect(() => {
    if (sampleTasksByIdQ.isFetching) {
      dispatch(isEditingNotes(true))
    } else if (sampleTasksByIdQ.isFetched) {
      dispatch(isEditingNotes(false))
      dispatch(editingNotesFieldId(null))
    }
  }, [dispatch, sampleTasksByIdQ.isFetched, sampleTasksByIdQ.isFetching])

  return (
    <Box>
      {tasksIsError && (
        <FullPageError
          errorTextTitle='Something went wrong with tasks loading'
          btnText='Refresh'
          onBtnClick={refetchTasks}
          pt='20px'
        />
      )}
      {tasksIsLoading && (
        <Box sx={{ paddingLeft: '80px', paddingTop: '32px' }}>
          <TasksLoader />
        </Box>
      )}
      <StyledTable>
        {!!tasksData?.length && (
          <ExpandedTableHead headerContent={headerContent} contentType={contentType} />
        )}
        <ExpandedTableBody
          isFetchingSamples={isFetchingSamples}
          greatestTasksAmount={greatestTasksAmount}
          labId={labId}
          referredWidth={referredWidth}
          sampleId={sampleId}
          tasks={tasksData as ISampleTasks}
          splitStatus={splitStatus}
          contentType={contentType}
          tasksIsLoading={tasksIsLoading}
          tasksIsFetching={sampleTasksByIdQ.isFetching}
          isAbleToCreateTask={tasksRules?.is_able_to_create_task}
        />
      </StyledTable>
    </Box>
  )
})
