import { useTheme } from '@mui/material'

interface PillsImageProps {
  width?: string
  height?: string
}

export const PillsImage: React.FC<PillsImageProps> = props => {
  const { width = '236px', height = '236px' } = props
  const theme = useTheme()

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 236 236'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle r='9.07692' transform='matrix(-1 0 0 1 208.771 217.846)' fill={theme.primary[20]} />
      <circle r='9.07692' transform='matrix(-1 0 0 1 226.923 48.409)' fill={theme.primary[20]} />
      <circle r='4.53846' transform='matrix(-1 0 0 1 13.6139 192.128)' fill={theme.primary[20]} />
      <circle r='4.53846' transform='matrix(-1 0 0 1 25.7194 52.9486)' fill={theme.primary[20]} />
      <circle r='4.53846' transform='matrix(-1 0 0 1 158.844 4.53846)' fill={theme.primary[20]} />
      <circle r='7.5641' transform='matrix(-1 0 0 1 34.7949 219.357)' fill={theme.primary[20]} />
      <circle r='15.1282' transform='matrix(-1 0 0 1 33.282 24.2063)' fill={theme.primary[20]} />
      <path
        d='M197.162 156.263C188.329 171.276 170.12 177.353 154.354 171.431C152.467 170.726 150.614 169.845 148.818 168.788C147.023 167.732 145.353 166.54 143.82 165.233C130.986 154.326 127.455 135.459 136.288 120.446C145.122 105.433 163.332 99.3534 179.098 105.275C180.986 105.98 182.838 106.861 184.634 107.918C186.43 108.974 188.099 110.166 189.632 111.473C202.465 122.379 205.995 141.251 197.162 156.263Z'
        fill='#F9F6F9'
      />
      <path
        d='M189.632 111.472L154.354 171.43C152.467 170.725 150.614 169.844 148.818 168.787C147.023 167.731 145.353 166.539 143.82 165.232L179.098 105.273C180.985 105.979 182.837 106.86 184.633 107.916C186.43 108.973 188.099 110.164 189.632 111.472Z'
        fill='#D6F5FD'
      />
      <path
        d='M112.312 59.4576C119.643 75.2578 114.294 93.6932 100.461 103.3C98.8081 104.452 97.0305 105.476 95.1403 106.352C93.2501 107.23 91.3211 107.926 89.3741 108.445C73.1066 112.805 55.5754 104.986 48.2438 89.1861C40.9122 73.3859 46.2597 54.9471 60.0927 45.3401C61.746 44.1883 63.5233 43.1648 65.4135 42.2879C67.3037 41.4111 69.2327 40.7144 71.1796 40.1957C87.4475 35.8356 104.98 43.6574 112.312 59.4576Z'
        fill='#F9F6F9'
      />
      <path
        d='M161.315 171.431C164.218 172.52 167.201 173.206 170.194 173.499C164.928 174.021 159.512 173.367 154.353 171.431C152.466 170.728 150.614 169.847 148.818 168.789C147.022 167.731 145.351 166.54 143.82 165.235C130.985 154.329 127.456 135.459 136.287 120.446C143.5 108.193 156.953 101.889 170.233 103.212C159.393 104.284 149.198 110.341 143.249 120.446C134.418 135.459 137.947 154.329 150.781 165.235C152.313 166.54 153.984 167.731 155.78 168.789C157.576 169.847 159.428 170.728 161.315 171.431Z'
        fill='#D6F5FD'
      />
      <path
        d='M83.7745 109.466C69.238 110.918 54.74 103.183 48.2447 89.1861C40.9142 73.3862 46.2606 54.9444 60.094 45.3404C61.7473 44.1882 63.5225 43.1648 65.4127 42.2876C67.3029 41.4104 69.2346 40.7144 71.1805 40.1957C75.3471 39.0783 79.5975 38.7617 83.7397 39.1722C81.8671 39.3601 79.9979 39.698 78.1425 40.1957C76.1966 40.7147 74.2645 41.4108 72.3747 42.2879C70.4845 43.1651 68.7092 44.1886 67.0559 45.3408C53.2226 54.9447 47.8761 73.3865 55.2067 89.1864C60.6613 100.941 71.755 108.276 83.7745 109.466Z'
        fill='#D6F5FD'
      />
      <path
        d='M71.1798 40.1992L100.461 103.304C98.8082 104.456 97.0306 105.479 95.1404 106.356C93.2502 107.233 91.3212 107.929 89.3743 108.448L60.0928 45.3436C61.7461 44.1918 63.5234 43.1683 65.4136 42.2914C67.3038 41.4143 69.2328 40.7179 71.1798 40.1992Z'
        fill='#D6F5FD'
      />
      <path
        d='M155.855 109.742L142.656 124.168L133.828 125.504C124.536 135.66 96.621 107.61 105.913 97.4536L105.871 90.5109L119.068 76.0859C128.36 65.9296 144.13 65.2261 154.289 74.5205C159.367 79.1665 162.083 85.4314 162.366 91.8067C162.647 98.1797 160.501 104.664 155.855 109.742Z'
        fill={theme.palette.primary.main}
      />
      <path
        d='M142.655 124.167L138.832 128.345L129.456 138.594C120.164 148.75 104.394 149.448 94.238 140.156C84.0789 130.862 83.3782 115.095 92.6701 104.938L100.461 96.4228L105.869 90.5117L142.655 124.167Z'
        fill='#E4F6FF'
      />
      <path
        d='M111.34 141.11L123.965 156.041C132.854 166.552 134.804 187.857 124.293 196.745C113.779 205.636 94.7822 198.747 85.8936 188.235L73.2679 173.305C64.3793 162.794 65.6923 147.063 76.2066 138.172C81.4621 133.727 88.022 131.833 94.3818 132.365C100.738 132.899 106.895 135.854 111.34 141.11Z'
        fill={theme.palette.primary.main}
      />
      <path
        d='M123.965 156.043L136.591 170.974C145.479 181.485 144.161 197.215 133.65 206.104C123.135 214.995 107.408 213.679 98.5191 203.168L85.8931 188.238L123.965 156.043Z'
        fill='#E4F6FF'
      />
    </svg>
  )
}
