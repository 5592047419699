import React from 'react'
import { debounce, Typography } from '@mui/material'

import { useFetchLaboratories } from '@hooks/queries'
import { useAppSelector } from '@hooks/redux'
import { selectUserLaboratory } from '@state/user'
import { StyledActionsStickyBox } from '@styles'
import { workSpaceInfo } from '@state/workSpace'

import { OtherLaboratories } from './components/OtherLaboratories'
import { Search } from './components/OtherLaboratories/Search'
import { MapStub } from './components/MapStub'
import * as Styled from './styles'
import { EditInfo } from './components/EditInfo'

export const ContactInformation = () => {
  const { id: currentLaboratoryId } = useAppSelector(selectUserLaboratory)
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const { selectedLaboratory } = useAppSelector(workSpaceInfo)

  const { data, isLoading, isError, isFetching, fetchNextPage, hasNextPage } = useFetchLaboratories(
    searchQuery,
    true
  )
  const getData = data?.pages.map(page => page.results).flat()
  const laboratories = !!selectedLaboratory
    ? getData?.filter(({ id }) => id !== selectedLaboratory.id)
    : getData?.filter(({ id }) => id !== currentLaboratoryId)

  const inputOnChangeHandler = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearchQuery(e.target.value)
  }

  const debounceInputOnChange = debounce(inputOnChangeHandler, 500)

  return (
    <>
      <Styled.MapStubBox>
        <MapStub address={selectedLaboratory?.address} />
        <EditInfo />
      </Styled.MapStubBox>

      {/* Table Other Laboratories with search input */}
      <StyledActionsStickyBox sx={{ justifyContent: 'space-between' }}>
        <Typography variant='h3'>Other laboratories</Typography>
        <Search inputOnChange={debounceInputOnChange} />
      </StyledActionsStickyBox>

      <OtherLaboratories
        laboratories={laboratories}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetching}
        isLoading={isLoading}
        isError={isError}
      />
    </>
  )
}
