import { Box } from '@mui/material'
import { useController } from 'react-hook-form'

import { StylePhoneInput } from '@components/modules/Modals/EditUser/styles'

interface PhoneFieldProps {
  name: string
  phoneError?: boolean
  setError: (value: React.SetStateAction<string[]>) => void
  index: number
  error: string[]
}

export const PhoneField: React.FC<PhoneFieldProps> = props => {
  const { name, phoneError, setError, index, error } = props
  const { field } = useController({ name })

  const updateError = error.filter(i => +i !== index)

  const changeHandler = (val: string) => {
    if (`+${val}` !== field.value && error.includes(`${index}`)) {
      setError(updateError)
    }
    field.onChange(() => val)
  }

  return (
    <Box>
      <StylePhoneInput
        country={'us'}
        specialLabel={''}
        placeholder={field.value}
        inputProps={{ name, type: name, autoComplete: 'off' }}
        masks={{ fr: '(...) ..-..-..', at: '(...) ...-....' }}
        inputStyle={{
          height: '48px',
          fontSize: '16px',
          color: '#0F1934',
          marginLeft: '74px',
          paddingLeft: '12px',
          width: '166px',
          marginTop: '-1px',
          borderColor: `${phoneError ? '#AF4645' : '#E1E7F3'}`,
          paddingRight: '0px',
        }}
        containerStyle={{
          border: `1px solid ${phoneError ? '#AF4645' : '#E1E7F3'}`,
          borderRadius: '4px',
          height: '45.5px',
          width: '70px',
        }}
        dropdownStyle={{ marginLeft: '-1px', width: '240px', top: '50px' }}
        {...field}
        onChange={changeHandler}
      />
    </Box>
  )
}
