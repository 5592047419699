import { Box } from '@mui/material'

import { ISample } from '@models/samples'
import { ExpandableExcipients } from '@components/shared'
import { StyledTableSampleCell } from '@styles'

interface APIsCellProps {
  report: ISample
  hideBorder?: boolean
}

export const ExcipientsCell: React.FC<APIsCellProps> = ({ report, hideBorder }) => {
  return (
    <StyledTableSampleCell isClickable hideBorder={hideBorder}>
      <Box>
        <ExpandableExcipients excipients={report.excipients} borderBottom='none' direction='row' />
      </Box>
    </StyledTableSampleCell>
  )
}
