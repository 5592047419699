import { FC } from 'react'
import { styled as styledMUI, Drawer, Typography, Link, LinkProps } from '@mui/material'
import styled from 'styled-components'

export const StyledDrawer = styledMUI(Drawer)`
  .MuiPaper-elevation {
    width: 408px;
    margin-top: 60px;
    box-shadow: -5px 0px 15px rgba(53, 108, 151, 0.12);
    overflow: hidden;
  }

  .MuiBackdrop-root {
    background-color: transparent;
  }

  .MuiTypography-h4 {
    font-size: 1.125rem;
    line-height: 1.25;
  }
`

export const StyledDrawerHeader = styled.div`
  padding: 45px 80px 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.additional.stroke.main};

  .MuiTypography-h4 {
    font-size: 1.125rem;
    margin-bottom: 10px;
  }

  .MuiIconButton-root {
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    overflow: hidden;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover,
    :focus,
    :active {
      background-color: ${({ theme }) => theme.primary[50]};
    }

    img {
      filter: brightness(0) invert(1);
      transform: rotate(180deg);
    }
  }
`

export const DrawerOpenButton = styled.div`
  display: flex;
  align-items: center;

  & .MuiTypography-root {
    margin-right: 24px;
    font-weight: 500;
  }

  .MuiIconButton-root {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #e5e5e6;
    overflow: hidden;

    img {
      margin-left: -3px;
    }
  }
`

export const ListItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`

export const ListItemBody = styled.div`
  display: grid;
  grid-template-columns: minmax(68px, auto) 1fr;
  grid-template-rows: repeat(2, auto);
  grid-gap: 8px;
`

export const StyledColoredParagraph = styledMUI(Typography)`
  color: #616d84;
`

export const StyledHistoryListWrapper = styled.div`
  overflow-y: auto;
  padding-bottom: 52px;

  .MuiListItem-root {
    display: flex;
    flex-direction: column;
    align-items: unset;
    padding: 25px;
    border-bottom: 1px solid ${({ theme }) => theme.additional.stroke.main};
    font-size: 0.75rem;
  }

  .MuiTypography-body1 {
    font-size: inherit;
    line-height: 1.5;
  }
`
export const StyledLink = styledMUI<FC<LinkProps & { disabled?: boolean }>>(Link)`
  color: ${({ disabled }) => (disabled ? '#929292' : '')};
  cursor: ${({ disabled }) => (disabled ? 'default' : '')};
  text-decoration-color: ${({ disabled }) => (disabled ? '#929292' : '')};
  width: 100%;
`

export const StyledArrowBackBox = styled('div')`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transform: rotate(180deg);
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    & > svg {
      fill: #fff;
    }
  }
  & > svg {
    transition: fill 0.1s ease-out;
    transform: translate(80%, 90%);
  }
`
