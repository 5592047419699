import React from 'react'
import { Box, Collapse } from '@mui/material'

import { useToggle } from '@hooks/common'
import { MobileDetailsInfoCard } from '@components/shared'
import { StyledBoxMobile } from '@styles'
import { ITaskLab } from '@models/tasks'
import { checkSkills } from '@helpers/obtainingSkills'

import { MainInfoMobile } from './components/MainInfoMobile'
import { IdentificationMobile } from './components/IdentificationMobile'
import { ManufactureDetailsMobile } from './components/ManufactureDetailsMobile'
import { AttachmentsMobile } from './components/AttachmentsMobile'

interface TaskInfoMobileProps {
  task?: ITaskLab
  isLoading?: boolean
}

export const TaskInfoMobile: React.FC<TaskInfoMobileProps> = ({ task, isLoading }) => {
  const [openMainInfo, setIsOpenMainInfo] = useToggle()
  const [openAttachments, setIsOpenAttachments] = useToggle()
  const [openIdentification, setIsOpenIdentification] = useToggle()
  const [openManufactureDetails, setIsOpenManufactureDetails] = useToggle()

  const { isSampleTemperatureSensitive, isSampleToxic } = checkSkills(task)

  const cards = [
    {
      title: 'Main info',
      component: MainInfoMobile,
      data: task,
      open: openMainInfo,
      setIsOpen: setIsOpenMainInfo,
      isShowComponent: true,
    },
    {
      title: 'Attachments',
      component: AttachmentsMobile,
      data: task,
      open: openAttachments,
      setIsOpen: setIsOpenAttachments,
      isShowComponent: !!isSampleTemperatureSensitive || !!isSampleToxic,
    },
    {
      title: 'Identification',
      component: IdentificationMobile,
      data: task,
      open: openIdentification,
      setIsOpen: setIsOpenIdentification,
      isShowComponent: true,
    },
    {
      title: 'Manufacture details',
      component: ManufactureDetailsMobile,
      data: task,
      open: openManufactureDetails,
      setIsOpen: setIsOpenManufactureDetails,
      isShowComponent: true,
    },
  ]

  return (
    <>
      {cards.map(({ title, data, open, setIsOpen, component: Component, isShowComponent }) => (
        <React.Fragment key={title}>
          {isShowComponent && (
            <StyledBoxMobile>
              <MobileDetailsInfoCard title={title} open={open} setIsOpen={setIsOpen} />

              <Box>
                <Collapse in={open} timeout='auto' unmountOnExit>
                  <Component data={data} />
                </Collapse>
              </Box>
            </StyledBoxMobile>
          )}
        </React.Fragment>
      ))}
    </>
  )
}
