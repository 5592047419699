import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { SAMPLE_TASKS } from '@constants/queries'
import { fetchTasksBySampleId } from '@api/samples'
import { ISampleTaskData } from '@models/samples'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useFetchSampleTasks = (sampleId: number, status?: string) => {
  const dispatch = useDispatch()
  return useQuery<ISampleTaskData, IError>(
    [SAMPLE_TASKS, sampleId],
    fetchTasksBySampleId.bind(null, sampleId, status),
    {
      onError: error => {
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
      },
    }
  )
}
