import { ExpandableExcipients } from '@components/shared'
import { ISample } from '@models/samples'

import { StyledTableCell } from '../styles'

interface APIsCellProps {
  sample: ISample
}

export const ExcipientsCell: React.FC<APIsCellProps> = ({ sample }) => {
  const { excipients } = sample

  return (
    <StyledTableCell>
      {excipients.length ? (
        <ExpandableExcipients excipients={excipients} borderBottom='none' />
      ) : (
        '-'
      )}
    </StyledTableCell>
  )
}
