import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { StringParam, useQueryParams } from 'use-query-params'

import { cancelOutgoingRequest } from '@api/requests'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'
import { OUTGOING_REQUESTS } from '@constants/queries'

export const useCancelOutgoingRequest = () => {
  const client = useQueryClient()
  const dispatch = useDispatch()

  const stringParams = {
    status: StringParam,
  }

  const [queryString] = useQueryParams(stringParams)
  const queryStringStatus = queryString.status?.split(',')

  const mutation = useMutation<unknown, IError, string>(cancelOutgoingRequest, {
    onSuccess: () => {
      if (!!queryStringStatus) client.invalidateQueries(OUTGOING_REQUESTS)
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
