import React from 'react'
import { Box, CircularProgress, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { useDispatch } from 'react-redux'

import { Button, Tooltip } from '@components/shared'
import { TooltipMobile } from '@components/shared/Tooltip/components'
import { stringSlice, truncateArray } from '@helpers/ui/common'
import { TRUNCATED_TASK_NOTES_TEXT_LENGTH_L, TRUNCATED_USER_NAME_IN_NOTES } from '@constants/common'
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { StyledEditIconButton, StyledTextCell, SubText } from '@styles'
import { useAppSelector } from '@hooks/redux'
import { editingNotesFieldId, editNotes } from '@state/samples'
import { ITaskNote } from '@models/tasks'
import { selectUser } from '@state/user'

interface ViewNotesFieldProps {
  note?: ITaskNote
  isCellWithoutNotes?: boolean
  toggleEditing: () => void
  taskInfoInTest?: boolean
  top?: string
  left?: string
  taskId: number
  isAbleToAddTaskNotes?: boolean
  notesWithoutTruncate?: boolean
  mtEditIcon?: string
}

export const ViewTaskNotesField: React.FC<ViewNotesFieldProps> = props => {
  const {
    note,
    isCellWithoutNotes,
    taskInfoInTest,
    toggleEditing,
    taskId,
    top,
    left,
    isAbleToAddTaskNotes,
    notesWithoutTruncate,
    mtEditIcon,
  } = props

  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:767px)')
  const theme = useTheme()

  const checkNotes = (note?: string) => {
    if (note) {
      // notes from PAD reader with '\r\n
      if (note.includes('\r\n')) return note.split('\r\n')
      // notes from user with /n
      else if (note.includes('\n')) return note.split('\n')
      // simple notes
      else return note
    }
    return ''
  }

  const notes = checkNotes(note?.notes)
  const checkTruncateLength = Array.isArray(notes) ? 68 : TRUNCATED_TASK_NOTES_TEXT_LENGTH_L

  const truncatedNotes = Array.isArray(notes) ? truncateArray(notes, checkTruncateLength) : notes

  const noteTruncateLength = notesWithoutTruncate ? 500 : checkTruncateLength

  const { first_name: firstName, last_name: lastName } = useAppSelector(selectUser)
  const { currentEditingFieldNotesId, isEditingNotes } = useAppSelector(editNotes)

  const editNotesHandler = React.useCallback(() => {
    dispatch(editingNotesFieldId(taskId))
    toggleEditing()
  }, [dispatch, taskId, toggleEditing])

  const isCurrentTaskEditing = currentEditingFieldNotesId === taskId && isEditingNotes
  const isNeedToChangeBtnPosition = taskInfoInTest || isMobile

  const renderEditIconButton = () => (
    <>
      {isCurrentTaskEditing ? (
        <CircularProgress size={16} sx={{ marginTop: '15px' }} />
      ) : (
        <StyledEditIconButton
          onClick={editNotesHandler}
          id='not-expand'
          padding='0'
          ml='8px'
          mb='-5px'
          mt={mtEditIcon}
          position={isNeedToChangeBtnPosition ? 'absolute' : 'static'}
          $left={left ? left : isNeedToChangeBtnPosition ? '-50px' : ''}
          $top={top ? top : isNeedToChangeBtnPosition ? '-5px' : ''}
          alignSelf={isNeedToChangeBtnPosition ? '' : 'start'}
        >
          <IconButton>
            <EditIcon fill={theme.palette.primary.main} style={{ width: '16px', height: '16px' }} />
          </IconButton>
        </StyledEditIconButton>
      )}
    </>
  )

  const renderAddBtnCellInTable = () => (
    <>
      {isCurrentTaskEditing ? (
        <Box ml='auto' mr='auto' mt={isMobile ? '' : '10px'}>
          <CircularProgress size={16} sx={{ marginTop: '10px' }} />
        </Box>
      ) : (
        <Button
          id='not-expand'
          variant='text'
          width='150px'
          fz={14}
          lh='20px'
          sx={{ margin: taskInfoInTest ? '-8px' : '' }}
          onClick={editNotesHandler}
          startIconAdd
        >
          Add task note
        </Button>
      )}
    </>
  )

  const editNotesCell = () => (
    <>
      {!isMobile ? (
        <div>
          <Tooltip
            isPureTooltip
            maxWidth={250}
            title={note?.notes || ''}
            shouldBeHidden={(note?.notes.length || 0) <= noteTruncateLength}
          >
            <Box>
              {/* user name */}
              <SubText sx={{ whiteSpace: 'nowrap', display: 'block', marginBottom: '2px' }}>
                {stringSlice(
                  `${note?.user.first_name} ${note?.user.last_name}`,
                  TRUNCATED_USER_NAME_IN_NOTES
                )}
              </SubText>

              {/* task notes */}

              {truncatedNotes &&
                note?.notes &&
                Array.isArray(truncatedNotes) &&
                truncatedNotes.map((text, idx) => (
                  <StyledTextCell key={idx} mr='0'>
                    {text}
                    {idx + 1 !== truncatedNotes.length && <br />}
                    {idx + 1 === truncatedNotes.length &&
                      note.notes.length > noteTruncateLength && <span>...</span>}
                  </StyledTextCell>
                ))}

              {typeof notes === 'string' && (
                <StyledTextCell mr='0'>
                  {(notes && stringSlice(notes, noteTruncateLength)) || '-'}
                </StyledTextCell>
              )}
            </Box>
          </Tooltip>
        </div>
      ) : (
        <TooltipMobile
          width='124px'
          title={note?.notes || ''}
          shouldBeHidden={(note?.notes.length || 0) <= noteTruncateLength}
        >
          <Box>
            {/* mobile user name */}
            <SubText sx={{ whiteSpace: 'nowrap', display: 'block', marginBottom: '2px' }}>
              {stringSlice(
                `${note?.user.first_name} ${note?.user.last_name}`,
                TRUNCATED_USER_NAME_IN_NOTES
              )}
            </SubText>

            {/* mobile task notes */}

            {truncatedNotes &&
              note?.notes &&
              Array.isArray(truncatedNotes) &&
              truncatedNotes.map((text, idx) => (
                <StyledTextCell key={idx} mr='0'>
                  {text}
                  {idx + 1 !== truncatedNotes.length && <br />}
                  {idx + 1 === truncatedNotes.length && note.notes.length > noteTruncateLength && (
                    <span>...</span>
                  )}
                </StyledTextCell>
              ))}

            {typeof notes === 'string' && (
              <StyledTextCell mr='0'>
                {(notes && stringSlice(notes, noteTruncateLength)) || '-'}
              </StyledTextCell>
            )}
          </Box>
        </TooltipMobile>
      )}
    </>
  )

  const renderNotesCell = () => (
    <>
      {!isCellWithoutNotes && note ? (
        <>
          {editNotesCell()}
          {!note.read_only && renderEditIconButton()}
        </>
      ) : (
        <>
          {isAbleToAddTaskNotes ? (
            <Box>
              {!note?.notes && (
                <SubText sx={{ whiteSpace: 'nowrap', display: 'block', marginBottom: '2px' }}>
                  {stringSlice(`${firstName} ${lastName}`, TRUNCATED_USER_NAME_IN_NOTES)}
                </SubText>
              )}
              {renderAddBtnCellInTable()}
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )

  return (
    <Box display='flex' justifyContent='flex-start' mb='10px'>
      {renderNotesCell()}
    </Box>
  )
}
