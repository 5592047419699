import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { StringParam, useQueryParams } from 'use-query-params'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { fetchPackages } from '@api/packages'
import { IPackagesData } from '@models/packages'
import { INCOMING_PACKAGES, OUTGOING_PACKAGES, PACKAGES } from '@constants/queries'
import { setMaintenanceModePage } from '@state/user'
import { filterStringParamsQueries } from '@components/shared/FilterAside/constants'

export const useFetchPackages = (): UseInfiniteQueryResult<IPackagesData> => {
  const [queryString] = useQueryParams({
    package_type: StringParam,
    ordering: StringParam,
    desired_package: StringParam,
    ...filterStringParamsQueries,
  })
  const { package_type, ordering, desired_package, laboratories, delivery_status, lastUpdates } =
    queryString
  const dispatch = useDispatch()

  const packageType =
    package_type === 'incoming'
      ? INCOMING_PACKAGES
      : package_type === 'outgoing'
      ? OUTGOING_PACKAGES
      : PACKAGES

  const deliveryStatusObj = delivery_status?.split(',')
  const queryKey = [
    packageType,
    ordering,
    desired_package,
    laboratories,
    deliveryStatusObj,
    lastUpdates,
  ]

  return useInfiniteQuery<IPackagesData, IError>(queryKey, fetchPackages, {
    getNextPageParam: lastPage => {
      const { next: nextPageUrl } = lastPage.links

      if (nextPageUrl) {
        const queryString = nextPageUrl.slice(nextPageUrl.indexOf('?'))
        const urlParams = new URLSearchParams(queryString)
        const nextPage = urlParams.get('page')

        return nextPage
      }
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
