import React from 'react'
import { useDispatch } from 'react-redux'
import { useQueryParams } from 'use-query-params'

import {
  deleteAllAppliedFilters,
  deleteAllSelectedFilters,
  filtersActualPage,
  setStatedEnteringDate,
  updateResetRadioBtnSampleStatus,
} from '@state/filters'
import { StyledClearAll } from '@styles'
import { useSaveFilters } from '@hooks/queries'
import { useAppSelector } from '@hooks/redux'
import { updateFiltersForUserState } from '@state/user'

import { clearAllFilterStringParamsQueries, filterStringParamsQueries } from '../constants'

export const ClearAllFilters = () => {
  const dispatch = useDispatch()
  const [, setQueryString] = useQueryParams(filterStringParamsQueries)
  const pageForFilters = useAppSelector(filtersActualPage)
  const saveFiltersM = useSaveFilters()

  const deleteAllFiltersHandler = () => {
    dispatch(setStatedEnteringDate({ order: 0, type: '' }))
    dispatch(deleteAllSelectedFilters())
    dispatch(deleteAllAppliedFilters())
    dispatch(updateResetRadioBtnSampleStatus(false))
    setQueryString(clearAllFilterStringParamsQueries)
    dispatch(
      updateFiltersForUserState({
        page_title: pageForFilters,
        filters: [],
      })
    )
    saveFiltersM.mutate({
      page_title: pageForFilters,
      filters: [],
    })
  }

  return (
    <StyledClearAll sx={{ width: '105px' }} variant='outlined' onClick={deleteAllFiltersHandler}>
      Clear all
    </StyledClearAll>
  )
}
