import React from 'react'
import { useDispatch } from 'react-redux'
import { cloneDeep } from 'lodash'
import { useQueryClient } from 'react-query'

import { PureChip, PureDropdownAutocomplete } from '@components/ui'
import { TEST_TYPES } from '@constants/queries'
import { setCellValueToSample } from '@state/sampleCreate'
import { AddingAnotherTestModal } from '@components/modules/Modals/AddingAnotherTestModal'
import { IAvailableTestTypesInfo } from '@models/yourWorkspace'
import { IGroup } from '@models/common/app'
import { useAddAnotherTest } from '@hooks/queries/useAddAnotherTest'
import { PureTooltip } from '@components/ui/PureTooltip'
import { formationTests } from '@helpers/formationTests'

import { ITestTypesOption, PureCellPropTypes } from '../pureFields.types'
import { TestsOptions } from './TestsOptions'
import { StyledChipWrap } from '../styles'

export const Tests: React.FC<PureCellPropTypes> = ({
  name,
  cellName,
  sampleId,
  initialVal,
  inputPlaceholder,
  isCopy,
}) => {
  const dispatch = useDispatch()
  const addAnotherTestM = useAddAnotherTest()
  const queryClient = useQueryClient()
  const testTypesData = queryClient.getQueryData<IAvailableTestTypesInfo[]>([TEST_TYPES, true])
  const correctOptions = React.useMemo(() => formationTests(testTypesData || []), [testTypesData])

  const [tests, setTests] = React.useState<ITestTypesOption[]>([])
  const [toggleShowOptions, setToggleShowOptions] = React.useState<boolean>(false)
  const [groupData, setGroupData] = React.useState<IGroup | null>(null)

  React.useEffect(() => {
    if (initialVal.val && Array.isArray(initialVal.val) && initialVal.val.length) {
      setTests(initialVal.val as ITestTypesOption[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (addAnotherTestM.data) {
      const { id, name, is_other } = addAnotherTestM.data
      onDropdownChange({ id, name, is_other }, true)
      setGroupData(null)
      addAnotherTestM.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAnotherTestM.data])

  const setCellValue = (val: ITestTypesOption[]) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val, error: '' },
        cellName,
        sampleId,
      })
    )
  }

  const onDropdownChange = (val: ITestTypesOption, isMutate?: boolean) => {
    setTests(prevState => {
      const newArr = [...prevState, val]
      setCellValue(newArr)
      return newArr
    })
    if (!isMutate) setToggleShowOptions(prevState => !prevState)
  }

  const handleDeleteSelectedTests = (index: number) => {
    const newArr = cloneDeep(tests)
    newArr.splice(index, 1)
    setCellValue(newArr)
    setTests(newArr)
  }

  const handleAddTest = (groupData: IGroup) => {
    setGroupData(groupData)
    setToggleShowOptions(prevState => !prevState)
  }

  const handleCloseModal = (groupData?: IGroup) => {
    if (groupData) addAnotherTestM.mutate(groupData)
    else setGroupData(null)
  }

  return (
    <>
      {/* DROPDOWN */}
      <PureTooltip
        title='The maximum number of tests is 10'
        titleStyle={null}
        shouldBeHidden={tests.length < 10}
      >
        <PureDropdownAutocomplete
          // IF YOU USE "optionsComponent", YOU ALWAYS MUST USE "toggleShowOptions" prop
          toggleShowOptions={toggleShowOptions}
          optionsComponent={
            <TestsOptions
              options={correctOptions}
              onDropdownChange={onDropdownChange}
              handleAddTest={handleAddTest}
            />
          }
          disabled={tests.length >= 10}
          isDropdown
          isCopy={isCopy}
          name={name}
          inputPlaceholder={inputPlaceholder}
          options={[]}
        />
      </PureTooltip>
      {/* LIST OF SELECTED TESTS */}
      <StyledChipWrap>
        {tests.map(({ name }, idx) => (
          <PureChip
            truncatedLabelLength={27}
            key={idx}
            label={name}
            onDelete={() => handleDeleteSelectedTests(idx)}
          />
        ))}
      </StyledChipWrap>
      {/* ADDING ANOTHER TESTS MODAL */}
      {groupData ? (
        <AddingAnotherTestModal onClose={handleCloseModal} groupData={groupData} />
      ) : null}
    </>
  )
}
