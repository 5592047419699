import ContentLoader from 'react-content-loader'

export const MobileTasksTabLoader: React.FC = props => {
  return (
    <div>
      <ContentLoader
        speed={1.8}
        width='100%'
        viewBox='0 0 340 763'
        backgroundColor='#f3f3f3'
        foregroundColor='#e3e3e3'
        {...props}
      >
        <rect x='0' y='43' rx='4' ry='4' width='336' height='75' />
        <rect x='0' y='133' rx='4' ry='4' width='336' height='75' />
        <rect x='0' y='0' rx='4' ry='4' width='54' height='21' />
        <rect x='275' y='0' rx='4' ry='4' width='61' height='21' />
      </ContentLoader>
    </div>
  )
}
