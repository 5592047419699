import { useQuery } from 'react-query'

import { fetchTenant } from '@api/tenant'
import { TENANT_TYPES } from '@constants/queries'
import { useAppDispatch } from '@hooks/redux'
import { setTenant } from '@state/config'
import { UrlManager } from '@helpers/url'

export const useFetchTenant = () => {
  const dispatch = useAppDispatch()
  const { isTenantPage } = new UrlManager()

  return useQuery(TENANT_TYPES, fetchTenant, {
    enabled: isTenantPage,
    onSettled: (data: any) => {
      // sessionStorage.setItem('isAuth', 'true')
      dispatch(setTenant(data))
      document.title = `ARTiFACTS ${data ? data?.name : ''}`
    },
    retry: false,
  })
}
