import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useQueryParams, StringParam } from 'use-query-params'
import { useMediaQuery } from '@mui/material'

import { FullPageError } from '@components/shared'
import { TableLoader } from '@components/modules/SampleCreation/components'
import { ISamples } from '@models/samples'
import { NoFilteredSamples } from '@components/modules/Samples/SampleTabs/NoSamples'
import { IPackage } from '@models/packages'

import { TableSamples } from './components/TableSamples'

interface TableSamplesInsideProps {
  samples?: ISamples
  totalSamples?: number
  isLoading: boolean
  hasNextPage?: boolean
  fetchNextPage: (page: number) => void
  isFetchingNextPage: boolean
  actionBtnText?: 'Remove' | 'Missed'
  shouldDisplayActionBtn?: boolean
  shouldDisplayMissedText?: boolean
  packagesData?: IPackage
}

export const TableSamplesInside: React.FC<TableSamplesInsideProps> = props => {
  const {
    samples,
    totalSamples,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    actionBtnText,
    shouldDisplayActionBtn,
    shouldDisplayMissedText,
    packagesData,
  } = props

  const isMobile = useMediaQuery('(max-width:767px)')

  const [queryString] = useQueryParams({
    desired_samples_inside_package: StringParam,
  })

  if (isLoading && !isMobile) return <TableLoader />

  if (!samples?.length && !isMobile && queryString.desired_samples_inside_package)
    return <NoFilteredSamples />

  if (!samples?.length && !isMobile) {
    return (
      <FullPageError
        imgVariant='common'
        errorTextTitle={`There are no Samples added yet`}
        errorText='Get started by adding samples'
        pt='20px'
        mb={1}
      />
    )
  }

  return (
    <>
      <InfiniteScroll hasMore={hasNextPage} loadMore={fetchNextPage}>
        <TableSamples
          samples={samples}
          isFetchingNextPage={isFetchingNextPage}
          actionBtnText={actionBtnText}
          shouldDisplayActionBtn={shouldDisplayActionBtn}
          shouldDisplayMissedText={shouldDisplayMissedText}
          totalSamples={totalSamples}
          packagesData={packagesData}
        />
      </InfiniteScroll>
    </>
  )
}
