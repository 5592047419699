import React from 'react'
import { Box, Typography } from '@mui/material'
import { RegisterOptions, useWatch } from 'react-hook-form'

import { Dropdown, Input } from '@components/shared/sampleFields'
import { DEFAULT_VALUE_TYPE_NAME, UNITS, VALUE, VALUE_TYPE } from '@constants/samples'
import { ICurrencyOption } from '@models/common/app'

import { DropdownContainer, DropdownInputContainer } from './styles'

interface PriceValueInputProps {
  name: string
  dropdownName?: string
  options?: ICurrencyOption[]
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  placeholder?: string
  width?: string
  dropdownWidth?: string
  inputDisabled?: boolean
  isCopy?: boolean
  baseName?: string
  type?: string
  pr?: string
  fixedPrice?: number
}

export const PriceValue: React.FC<PriceValueInputProps> = React.memo(props => {
  const {
    name,
    dropdownName = '',
    options,
    placeholder,
    rules,
    width,
    inputDisabled,
    isCopy,
    baseName,
    type,
    pr,
    fixedPrice,
  } = props
  const [units, valueType, value] = useWatch({
    name: [
      `${type === 'create_samples' ? `${baseName}${UNITS}` : `${UNITS}`}`,
      `${type === 'create_samples' ? `${baseName}${VALUE_TYPE}` : `${VALUE_TYPE}`}`,
      `${type === 'create_samples' ? `${baseName}${VALUE}` : `${VALUE}`}`,
    ],
  })
  const selectedValueType = options && options.find(option => option.id === valueType)

  const [prevValueType, setPrevValueType] = React.useState<number>(valueType)
  const [price, setPrice] = React.useState<number>(fixedPrice || 1)

  React.useEffect(() => {
    if (prevValueType !== valueType && type !== 'create_samples') {
      setPrevValueType(valueType)
      setPrice(selectedValueType?.price ? +selectedValueType?.price : 1)
    }
  }, [valueType, prevValueType, selectedValueType, type])

  const totalCalculation = +(Math.floor(units) * value).toFixed(4)
  const calculationInUSD = +(
    value /
    (type === 'create_samples' ? (selectedValueType?.price ? +selectedValueType?.price : 1) : price)
  ).toFixed(4)
  const totalCalculationInUSD = +(
    totalCalculation /
    (type === 'create_samples' ? (selectedValueType?.price ? +selectedValueType?.price : 1) : price)
  ).toFixed(4)

  const infoIconPosition = (() => {
    const valueLength = selectedValueType?.name.length
    if (valueLength) {
      if (valueLength > 3) return valueLength * 6.2 + 80
    }
    return 80
  })()

  return (
    <Box sx={{ width: width || '100%' }}>
      <DropdownInputContainer>
        <Input
          type='number'
          disabled={inputDisabled}
          placeholder={placeholder}
          rules={rules}
          width='100%'
          name={name}
          isCopy={isCopy}
          internalTooltipDescription={`≈ ${calculationInUSD} USD`}
          internalTooltipDescriptionMt={baseName ? '7px' : '15px'}
          shouldBuHiddenInternalTooltip={!(!isNaN(value) && value > 0)}
          pr={pr}
          infoIconPosition={infoIconPosition}
        />
        <DropdownContainer>
          <Dropdown variant='standard' name={dropdownName} options={options} />
        </DropdownContainer>
      </DropdownInputContainer>

      {!isNaN(totalCalculation) && totalCalculation > 0 && (
        <Box>
          <Typography fontSize='14px' mt='13px' variant='body2'>
            <Typography
              fontSize='14px'
              component={'span'}
              sx={{ display: 'inline', color: '#8F929B' }}
            >
              Total:{' '}
            </Typography>
            <Typography
              component={'span'}
              sx={{ display: 'inline' }}
              fontSize='14px'
              variant='body2'
            >
              {totalCalculation} {selectedValueType?.name}
            </Typography>
            {selectedValueType?.name !== DEFAULT_VALUE_TYPE_NAME && (
              <Typography
                fontSize='14px'
                component={'span'}
                sx={{ display: 'inline', color: '#8F929B' }}
              >
                {' '}
                (≈ {totalCalculationInUSD} USD)
              </Typography>
            )}
          </Typography>
        </Box>
      )}
    </Box>
  )
})
