const ENV_PREFIXES = Object.freeze({
  prod: '',
  local: '',
  dev: 'dev',
  stage: 'stage',
  custom: 'custom',
  pad: 'pad',
  analytics: 'analytics',
})

const ENV_PREFIXES_VALUES = Object.values(ENV_PREFIXES)
const API_PORT = '8000'

export class UrlManager {
  tenant: string | null = null
  env: string | null = null
  publicHostname: string | null = null
  isTenantPage: boolean = false

  get apiUrl() {
    return this.getApiUrl(this.tenant)
  }

  get publicApiUrl() {
    return this.getApiUrl()
  }

  get publicClientUrl() {
    const urlObject = new URL(this.clientUrl)

    urlObject.hostname = [this.env, this.publicHostname].filter(Boolean).join('.')
    urlObject.pathname = ''
    urlObject.search = ''
    urlObject.hash = ''

    return urlObject.toString()
  }

  constructor(public clientUrl: string = window.location.href) {
    const publicUrlFirstSubdomainIndex = -2
    const clientUrlObject = new URL(this.clientUrl)
    const { hostname } = clientUrlObject
    const subdomainParts = hostname.split('.')
    this.publicHostname = subdomainParts.slice(publicUrlFirstSubdomainIndex).join('.')
    const tenantOrEnvSubdomain = subdomainParts.slice(0, publicUrlFirstSubdomainIndex).join('')

    this.isTenantPage = this.isSubdomainContainTenant(tenantOrEnvSubdomain)
    this.env = this.getEnvFromSubdomain(tenantOrEnvSubdomain)
    this.tenant = this.getTenantFromSubdomain(tenantOrEnvSubdomain)
  }

  getApiUrl(tenant?: string | null) {
    const urlObject = new URL(this.clientUrl)
    const { port } = urlObject

    urlObject.hostname = `${[tenant, 'api', this.env].filter(Boolean).join('-')}.${
      this.publicHostname
    }`
    urlObject.port = port ? API_PORT : ''
    urlObject.pathname = ''
    urlObject.search = ''
    urlObject.hash = ''

    return urlObject.toString()
  }

  isSubdomainContainTenant(subdomain: string) {
    return ENV_PREFIXES_VALUES.every(envPrefix => envPrefix !== subdomain)
  }

  getEnvFromSubdomain(subdomain: string) {
    const isSubdomainContainEnvOnly = !this.isSubdomainContainTenant(subdomain)

    if (isSubdomainContainEnvOnly) {
      return subdomain
    }

    const envNameFromTenantSubdomain = subdomain.split('-').slice(-1).join('')

    return ENV_PREFIXES_VALUES.some(envPrefix => envPrefix === envNameFromTenantSubdomain)
      ? envNameFromTenantSubdomain
      : ENV_PREFIXES.prod
  }

  getTenantFromSubdomain(subdomain: string) {
    if (!this.isSubdomainContainTenant(subdomain)) {
      return null
    }

    const lastPartFromSubdomain = subdomain.split('-').slice(-1).join('')
    const envNameFromTenantSubdomain =
      ENV_PREFIXES_VALUES.find(envValue => envValue === lastPartFromSubdomain) ?? ENV_PREFIXES.prod

    return subdomain.replace(new RegExp(`-${envNameFromTenantSubdomain}$`), '')
  }
}
