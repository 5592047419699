import React from 'react'
import fileSize from 'filesize'
import { Box, Icon, Typography, useMediaQuery } from '@mui/material'
import { useDropzone } from 'react-dropzone'

import dropzoneIcon from '@assets/icons/dropzone-icon-grey.svg'
import ActiveDropzoneIcon from '@assets/icons/dropzone-icon-white.svg'
import { DropZoneBox } from '@components/shared/DropZone/styles'
import { MAX_NUMBER_OF_ATTACHMENTS_FEEDBACK } from '@constants/user'
import { stringSlice } from '@helpers/ui/common'

import { AttachFileProps } from './attachFile.types'

export const AttachFile: React.FC<AttachFileProps> = ({
  attachmentsLength,
  addingError,
  onDrop,
  shouldDisabled,
  isAppropriateSizeOfAllFiles,
}) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
    isDragActive,
  } = useDropzone({
    onDrop,
    disabled: shouldDisabled,
    maxFiles: MAX_NUMBER_OF_ATTACHMENTS_FEEDBACK - attachmentsLength,
    validator: file => {
      const maxLengthOfFileName = 80
      const maxFileSize = 25000000

      if (file.name.length > maxLengthOfFileName) {
        return {
          code: 'name-too-large',
          message: `Name is larger than ${maxLengthOfFileName} characters`,
        }
      } else if (file.size > maxFileSize || !isAppropriateSizeOfAllFiles) {
        return {
          code: 'size-too-large',
          message: ` Maximum size of file attachments cannot exceed 25 MB.`,
        }
      }

      return null
    },
  })

  const isMobile = useMediaQuery('(max-width:767px)')

  const isAddingFailed = !!(addingError || fileRejections.length)

  const renderRejectBox = React.useMemo(() => {
    if (fileRejections.length) {
      return (
        <>
          {fileRejections.map(({ file, errors }, idx) => {
            return (
              <Box key={idx}>
                {errors.map(({ code, message }) => {
                  if (code === 'too-many-files') {
                    if (idx === 0) {
                      return (
                        <Typography key={code} className='dropzone-error' display='block'>
                          Maximum 4 files can be attached to a section
                        </Typography>
                      )
                    }
                  } else {
                    return (
                      <Typography key={code} className='dropzone-error' display='block'>
                        {stringSlice(`${idx + 1}. ${file.name} (${fileSize(file.size)})`, 35)} -
                        {message}
                      </Typography>
                    )
                  }

                  return null
                })}
              </Box>
            )
          })}
        </>
      )
    } else if (!!fileRejections.length || addingError?.message) {
      return (
        <Typography className='dropzone-error'>Failed to load ({addingError?.message})</Typography>
      )
    } else if (!isAppropriateSizeOfAllFiles) {
      return (
        <Typography className='dropzone-error'>
          Maximum size of file attachments cannot exceed 25 MB.
        </Typography>
      )
    } else {
      return (
        <Typography component='p' className='dropzone-note'>
          All popular format of image, microsoft document, PDF and spreadsheet
        </Typography>
      )
    }
  }, [addingError?.message, fileRejections, isAppropriateSizeOfAllFiles])

  return (
    <DropZoneBox
      isActive={isDragActive}
      isLoadingFailed={!!isAddingFailed || !isAppropriateSizeOfAllFiles}
      disabled={shouldDisabled}
      isMobile={isMobile}
    >
      <Box {...getRootProps({ className: 'fileDrop', isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <Icon sx={{ width: '58px', height: '58px' }}>
          <img src={!isDragActive ? dropzoneIcon : ActiveDropzoneIcon} alt='dropzone icon' />
        </Icon>
        {shouldDisabled ? (
          <Typography className='dropzone-text'>Attached maximum number of attachments</Typography>
        ) : !isDragActive ? (
          <Typography component='p' className='dropzone-text'>
            Drag & Drop or{' '}
            <Typography color='primary.main' component='span' className='dropzone-link'>
              Select file
            </Typography>
          </Typography>
        ) : (
          <Typography component='p' className='dropzone-text' sx={{ color: '#FFFFFF' }}>
            Upload relevant files here
          </Typography>
        )}

        {!isDragActive && (
          <Typography component='sub' sx={{ display: isMobile ? 'block' : '' }}>
            Maximum upload file size: 25MB
          </Typography>
        )}
      </Box>
      <>{renderRejectBox}</>
    </DropZoneBox>
  )
}
