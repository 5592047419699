// * [Statuses

export const AWAITING = 'awaiting'
export const IN_PROGRESS = 'in_progress'
export const COMPLETED = 'completed'

// * Statuses]

// * [Results

export const NOT_COMPLETED = 'not_completed'
export const PASSED = 'passed'
export const FAILED = 'failed'
export const SUSPICIOUS = 'suspicious'
export const OKAY = 'okay'
export const POSITIVE = 'positive'
export const NEGATIVE = 'negative'
export const RETEST_AT_ND = 'Retest at ND'
export const HIDDEN = 'hidden'
export const INCONCLUSIVE = 'inconclusive'

// * Results]

// * [Attachments of tasks

export const FILE = 'file'
export const LINK = 'link'

// * Attachments of tasks]

export const DESIRED_TASKS = 'desired_tasks'

// * List of tasks table head titles

export const TOXICITY = 'Toxicity'
export const TEMPERATURE = 'Storage temperature'

// ----------------------------

export const MAX_TASKS_TO_ADD = 10
export const LIMITATION_OF_MULTIPLE_TESTS = 100
