import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TooltipInitialStateType, TooltipPositionsDataType } from './tooltipState.types'

const initialState: TooltipInitialStateType = {
  dataAndPositions: null,
}

export const tooltipStateSlice = createSlice({
  name: 'tooltip',
  initialState,
  reducers: {
    setDataAndPosition: (state, { payload }: PayloadAction<TooltipPositionsDataType | null>) => {
      state.dataAndPositions = payload
    },
  },
})

export const {
  reducer: tooltipStateReducer,
  actions: { setDataAndPosition },
} = tooltipStateSlice
