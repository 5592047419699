import { TasksStep } from '@components/shared'
import { TESTING_STEP_TITLE } from '@constants/tests'
import { StyledPageSubTitle } from '@styles'

import { StepHeader } from '../../../styles'

interface HeaderProps {
  actualStep: number
}

export const Header: React.FC<HeaderProps> = ({ actualStep }) => {
  return (
    <StepHeader className='test-header'>
      <TasksStep idx={actualStep - 1} stepCounter={actualStep} stepColor='awaiting' />
      <StyledPageSubTitle variant='h3'>{TESTING_STEP_TITLE}</StyledPageSubTitle>
    </StepHeader>
  )
}
