import * as React from 'react'
import { Box, Typography, useTheme } from '@mui/material'

import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { IHrTaskLab, ITaskApiResult, ITasksTabValue } from '@models/tasks'
import { TRUNCATED_ATTACHMENTS_PACKAGE_DETAILS_TEXT } from '@constants/common'
import { stringSlice } from '@helpers/ui/common'
import { Tooltip } from '@components/shared'
import { GreyCup as StyledGreyCup } from '@components/shared/Hr/IdPAD/styles'
import { removeTrailingZeros } from '@helpers/removeTrailingZeros'
import { ReactComponent as NotDeterminedIcon } from '@assets/icons/minus-in-circle.svg'
import { HrIdPADEditResults } from '@components/harmModules/HrTasksDetails/components/HrTaskInfo'
import { useAppSelector } from '@hooks/redux'
import { isLoading, updatedTestId } from '@state/test'
import { useToggle } from '@hooks/common'

import * as Styled from './styles'

interface SubstancesProps {
  substancesResult?: ITaskApiResult[]
  mb?: string
  showMore?: boolean
  contentType?: ITasksTabValue
  task?: IHrTaskLab
}

export const Substances: React.FC<SubstancesProps> = props => {
  const { substancesResult = [], mb = '6px', showMore = true, contentType, task } = props
  const theme = useTheme()

  const [isExpanded, toggleExpanded] = useToggle()
  const [isOpenEditModal, setIsOpenEditModal] = React.useState<boolean>(false)
  const isTestUpdating = useAppSelector(isLoading)
  const testUpdatedId = useAppSelector(updatedTestId)

  const checkSubstanceLength = substancesResult.length <= 4 ? 4 : 3

  const isMoreThanFour = substancesResult.length > checkSubstanceLength
  const isEditLoading = task?.id === testUpdatedId && isTestUpdating

  const prunedResults = substancesResult.slice(0, checkSubstanceLength)
  const displayedResult =
    showMore && isMoreThanFour && !isExpanded ? prunedResults : substancesResult

  return (
    <Box display='flex' flexDirection='column' width='350px'>
      {!substancesResult.length ? (
        <Typography variant='body1' color='primary.light' sx={{ marginBottom: mb }}>
          Substances were not recognized
        </Typography>
      ) : (
        <div style={{ width: '100%' }}>
          {displayedResult.map((substance: ITaskApiResult, idx) => (
            <Styled.SubstanceItem key={idx} $mb={mb}>
              <Tooltip
                isPureTooltip
                title={substance.name}
                shouldBeHidden={substance.name.length <= TRUNCATED_ATTACHMENTS_PACKAGE_DETAILS_TEXT}
              >
                <Typography variant='body1'>
                  {stringSlice(substance.name, TRUNCATED_ATTACHMENTS_PACKAGE_DETAILS_TEXT)}
                </Typography>
              </Tooltip>
              {substance.percentage ? (
                <Typography variant='body1'>
                  {removeTrailingZeros(substance.percentage)}
                  <StyledGreyCup>%</StyledGreyCup>
                </Typography>
              ) : (
                <Tooltip isPureTooltip title='Not determined'>
                  <NotDeterminedIcon />
                </Tooltip>
              )}
            </Styled.SubstanceItem>
          ))}

          {isMoreThanFour && showMore && (
            <Styled.ShowMoreBtn onClick={toggleExpanded}>
              {isExpanded ? 'Show less' : 'Show more'}
            </Styled.ShowMoreBtn>
          )}
        </div>
      )}

      {contentType === 'completed' && (
        <Box onClick={() => setIsOpenEditModal(true)} display='flex' mt='16px'>
          <EditIcon
            fill={isEditLoading ? theme.palette.secondary.light : theme.palette.primary.main}
            style={{ cursor: isEditLoading ? 'default' : 'pointer' }}
          />
          <Typography
            color={isEditLoading ? 'secondary.light' : 'primary.main'}
            sx={{ marginLeft: '12px', cursor: isEditLoading ? 'default' : 'pointer' }}
            variant='body1'
            fontWeight={500}
          >
            Edit
          </Typography>
        </Box>
      )}

      {/* EDIT RESULTS */}
      {isOpenEditModal && task && (
        <HrIdPADEditResults
          task={task}
          onClose={() => setIsOpenEditModal(false)}
          isEditFromCompletedTable
        />
      )}
    </Box>
  )
}
