import { IconButton, Badge, Box, useMediaQuery, CircularProgress } from '@mui/material'
import React from 'react'

import { ReactComponent as KeyboardArrowUpIcon } from '@assets/icons/sort-up.svg'
import { ReactComponent as KeyboardArrowDownIcon } from '@assets/icons/sort-down.svg'

import * as Styled from '../allFilters/styles'

interface FilterTopTitleProps {
  titleOfFilter?: string
  filterNumb: number | undefined
  filterCounter: number | undefined
  isFilterClose: boolean
  setIsFilterClose: () => void
  isLoading?: boolean
}

export const FilterTopTitle: React.FC<FilterTopTitleProps> = React.memo(props => {
  const { titleOfFilter, filterCounter, isFilterClose, setIsFilterClose, filterNumb, isLoading } =
    props

  const isMobile = useMediaQuery('(max-width:767px)')
  return (
    <Styled.StyledCardBox
      onClick={setIsFilterClose}
      $isFilterClose={isFilterClose}
      $isMobile={isMobile}
    >
      <Box>
        <Styled.FilterTitle>{titleOfFilter}</Styled.FilterTitle>
        <Styled.FilterNumber>({filterNumb})</Styled.FilterNumber>
        {isLoading && <CircularProgress sx={{ marginLeft: '4px' }} size={16} />}
      </Box>
      <Styled.StyledBox>
        {filterCounter && <Badge badgeContent={filterCounter} max={9999} />}
        <IconButton sx={{ width: '24px', height: '24px' }}>
          {!isFilterClose ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Styled.StyledBox>
    </Styled.StyledCardBox>
  )
})
