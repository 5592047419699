import React from 'react'
import { Box } from '@mui/system'
import { useMediaQuery } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'

import { Button, Modal, Switcher } from '@components/shared'
import {
  ButtonBox,
  StyledForm,
  StyledModalContent,
  StyledModalTitle,
  StyledShadowBox,
} from '@styles'
import {
  setIsOpenEditLaboratoryModal,
  setWasOpenedAdditionalInfo,
  toggleEditLaboratoryModalType,
  workSpaceInfo,
} from '@state/workSpace'
import { useAppSelector } from '@hooks/redux'
import { useEditLaboratory } from '@hooks/queries'
import { editLaboratoryInfoSchema } from '@services/validation'
import {
  IEditInfoLaboratoryData,
  IEditInfoLaboratoryFormData,
  IEmailsInfo,
  IPhoneNumbersInfo,
} from '@models/yourWorkspace'
import { ILaboratory } from '@models/common/app'
import { useToggle } from '@hooks/common'
import { selectConfig } from '@state/config'
import { TENANT_TYPE } from '@constants/tenants'

import { StyledModalScroll } from './styles'
import { AdditionalInfo, Contacts } from './components'

interface EditInfoLaboratoryProps {
  currentLaboratory?: ILaboratory
}

export const EditInfoLaboratory: React.FC<EditInfoLaboratoryProps> = props => {
  const { currentLaboratory } = props
  const isSmallHeight = useMediaQuery('(max-height:700px)')
  const dispatch = useDispatch()
  const { mutate, isLoading, error: apiErrorsEditLaboratory } = useEditLaboratory()
  const { tenant } = useAppSelector(selectConfig)
  const isVerifyTenant = tenant?.type === TENANT_TYPE.legalDrugs

  const [editInfoLaboratoryData, setEditInfoLaboratoryData] = React.useState({})
  const [isConfirmDeleteSkillsModalOpen, toggleConfirmDeleteSkillsModal] = useToggle()

  const { editLaboratoryModalType, isWasOpenedAdditionalInfo } = useAppSelector(workSpaceInfo)
  const isEditContactsTub = editLaboratoryModalType[0].active

  React.useEffect(() => {
    return () => {
      dispatch(setWasOpenedAdditionalInfo(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultValues = React.useMemo(
    () => ({
      address: currentLaboratory?.address || '',
      manager: `${currentLaboratory?.manager.first_name} ${currentLaboratory?.manager.last_name}`,
      phone_numbers: currentLaboratory?.phone_numbers?.map(({ department, phone_number }) => ({
        phone_number,
        department,
      })),
      emails:
        currentLaboratory?.emails?.map(({ department, email }) => ({
          email,
          department,
        })) || [],
      skills: (currentLaboratory?.skills || []).reduce((acc, current) => {
        acc[current.id] = true
        return acc
      }, {} as Record<number, boolean>),
    }),
    [
      currentLaboratory?.address,
      currentLaboratory?.emails,
      currentLaboratory?.manager.first_name,
      currentLaboratory?.manager.last_name,
      currentLaboratory?.phone_numbers,
      currentLaboratory?.skills,
    ]
  )

  type FormData = {
    address: string
    manager: string
    phone_numbers: Array<Pick<IPhoneNumbersInfo, 'phone_number' | 'department'>>
    emails: Array<Pick<IEmailsInfo, 'email' | 'department'>>
    skills: Record<number, boolean>
  }

  const formMethods = useForm<FormData>({
    resolver: yupResolver(editLaboratoryInfoSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  })

  const deliveryServices =
    currentLaboratory?.delivery_services
      ?.map(({ id }) => ({
        id,
      }))
      .map(i => i.id) || []

  const testTypes =
    currentLaboratory?.test_types
      ?.map(({ id }) => ({
        id,
      }))
      .map(i => i.id) || []
  const skillsIds = currentLaboratory?.skills ? currentLaboratory?.skills.map(item => item.id) : []

  const editInfoLaboratoryHandler = (data: IEditInfoLaboratoryFormData) => {
    const isNoUncheckedSkills = skillsIds?.every(id => data.skills[id])

    let test_types
    let delivery_services
    let formData: IEditInfoLaboratoryData | null = null

    const address = data.address
    const phone_numbers = data.phone_numbers
      .filter(item => !!item?.phone_number?.length)
      .map(item => {
        if (item.phone_number.includes('+')) return item
        else return { ...item, phone_number: `+${item.phone_number}` }
      })

    const emails = data.emails.filter(item => !!item.email.length)
    const skills = Object.entries(data.skills)
      .filter(skill => skill[1])
      .map(skill => Number(skill[0]))

    if (isWasOpenedAdditionalInfo) {
      test_types = data.test_types
      delivery_services = data.delivery_services
    } else {
      test_types = testTypes
      delivery_services = deliveryServices
    }

    if (currentLaboratory) {
      formData = {
        id: currentLaboratory.id,
        data: { address, phone_numbers, emails, test_types, delivery_services },
      }
      if (isVerifyTenant) {
        formData.data.skills = skills
      }
    }

    if (!isNoUncheckedSkills && formData) {
      toggleConfirmDeleteSkillsModal()
      setEditInfoLaboratoryData(formData)
    } else if (formData) {
      mutate(formData)
    }
  }

  return (
    <Modal isPaddingAbsence open onClose={() => dispatch(setIsOpenEditLaboratoryModal(false))}>
      <FormProvider {...formMethods}>
        <StyledForm
          style={{ display: isConfirmDeleteSkillsModalOpen ? 'none' : 'flex' }}
          onSubmit={formMethods.handleSubmit(editInfoLaboratoryHandler)}
        >
          <Box>
            <StyledShadowBox>
              <StyledModalTitle sx={{ marginBottom: '12px' }} variant='h3'>
                Edit info
              </StyledModalTitle>

              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Switcher
                  buttonsTitles={editLaboratoryModalType}
                  $buttonWidth='314px'
                  functionToggler={toggleEditLaboratoryModalType}
                  isEditLabInfoModal
                />
              </Box>
            </StyledShadowBox>

            <StyledModalContent pt='0'>
              <StyledModalScroll height={isSmallHeight ? '230px' : '450px'}>
                {isEditContactsTub && (
                  <Contacts
                    apiErrorsEditLaboratory={apiErrorsEditLaboratory}
                    isConfirmDeleteSkillsModalOpen={isConfirmDeleteSkillsModalOpen}
                    toggleConfirmDeleteSkillsModal={toggleConfirmDeleteSkillsModal}
                    editInfoLaboratoryData={editInfoLaboratoryData}
                  />
                )}
                {!isEditContactsTub && <AdditionalInfo currentLaboratory={currentLaboratory} />}
              </StyledModalScroll>
            </StyledModalContent>

            <StyledShadowBox padding='24px 70px'>
              <ButtonBox>
                <Button
                  type='button'
                  variant='outlined'
                  color='secondary'
                  width='202px'
                  height='48px'
                  onClick={() => dispatch(setIsOpenEditLaboratoryModal(false))}
                >
                  Cancel
                </Button>
                <Box>
                  <Button
                    disabled={!formMethods.formState.errors}
                    variant='contained'
                    width='202px'
                    height='48px'
                    loading={isLoading}
                    type='submit'
                  >
                    Save
                  </Button>
                </Box>
              </ButtonBox>
            </StyledShadowBox>
          </Box>
        </StyledForm>
      </FormProvider>
    </Modal>
  )
}
