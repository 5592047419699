import InfiniteScroll from 'react-infinite-scroller'
import React from 'react'
import { useDispatch } from 'react-redux'
import { StringParam, useQueryParams } from 'use-query-params'

import { useFetchPackages } from '@hooks/queries'
import { PackageContentType } from '@models/packages'
import { FullPageError, TopProgressBar } from '@components/shared'
import { editingNotesFieldId, isEditingNotes } from '@state/samples'
import {
  applyFiltersOnPage,
  deleteAllAppliedFilters,
  deleteAllSelectedFilters,
  filtersAside,
  setIsFilterOpen,
  setPageForFilters,
} from '@state/filters'
import { useAppSelector } from '@hooks/redux'
import {
  clearAllFilterStringParamsQueries,
  filterStringParamsQueries,
} from '@components/shared/FilterAside/constants'
import { PageTitleForFilter } from '@models/common/user'
import { savedFilters } from '@state/user'

import { Table } from './components'
import { PackagesTableLoader } from '../../loaders'
import { PackageFilters } from '../../filters'

interface PackagesContentProps {
  contentType: PackageContentType
}

export const PackagesContent: React.FC<PackagesContentProps> = ({ contentType }) => {
  const fetchPackagesQ = useFetchPackages()
  const dispatch = useDispatch()
  const isFilterOpen = useAppSelector(filtersAside)

  const refetchPackages = () => fetchPackagesQ.refetch()
  const totalPackages = fetchPackagesQ.data?.pages ? fetchPackagesQ.data.pages[0].count : 0

  const [{ desired_package, laboratories, delivery_status, lastUpdates }, setQueriesString] =
    useQueryParams({
      desired_package: StringParam,
      ...filterStringParamsQueries,
    })

  const packages = fetchPackagesQ.data?.pages?.map(page => [...page.results]).flat()
  const packageTypeText = contentType === 'default' ? '' : `${contentType} `
  const isNoPackagesForFilter =
    (!packages?.length && (laboratories || delivery_status || lastUpdates)) ||
    (!packages?.length && desired_package)

  // [saved filters
  const actualPageForSavedFilters: PageTitleForFilter =
    contentType === 'default' ? 'packages' : `packages_${contentType}`
  const getSavedFiltersFromUser = useAppSelector(savedFilters)
  const samplesFilters = getSavedFiltersFromUser.filter(i => {
    return i.page_title === actualPageForSavedFilters
  })[0]

  const [updatedFilters, setUpdatedFilters] = React.useState(samplesFilters)

  const isPageHasSavedFilters = updatedFilters.filters.some(i => i.values.length !== 0)
  // saved filters]

  React.useEffect(() => {
    dispatch(setIsFilterOpen({ ...isFilterOpen, right: false }))
    dispatch(setPageForFilters(actualPageForSavedFilters))
    return () => {
      dispatch(deleteAllAppliedFilters())
      dispatch(deleteAllSelectedFilters())
      setQueriesString(clearAllFilterStringParamsQueries)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (fetchPackagesQ.isFetching) {
      dispatch(isEditingNotes(true))
    } else if (fetchPackagesQ.isFetched) {
      dispatch(isEditingNotes(false))
      dispatch(editingNotesFieldId(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPackagesQ.isFetched, fetchPackagesQ.isFetching])

  React.useEffect(() => {
    setUpdatedFilters(samplesFilters)
    if (isPageHasSavedFilters) dispatch(applyFiltersOnPage(samplesFilters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samplesFilters, isPageHasSavedFilters])

  if (fetchPackagesQ.isLoading) {
    return <PackagesTableLoader />
  }

  if (fetchPackagesQ.isError) {
    return (
      <FullPageError
        errorTextTitle={`Something went wrong with ${packageTypeText}packages loading`}
        btnText='Refresh'
        onBtnClick={refetchPackages}
        pt='20px'
      />
    )
  }

  if (isNoPackagesForFilter) {
    return (
      <>
        <PackageFilters contentType={contentType} isPageHasSavedFilters={isPageHasSavedFilters} />
        <FullPageError
          imgVariant='common'
          errorTextTitle={`No ${packageTypeText}packages with such parameters`}
          pt='20px'
        />
      </>
    )
  }

  if (!packages?.length) {
    return (
      <FullPageError
        imgVariant='common'
        errorTextTitle={`There are no ${packageTypeText}packages yet`}
        pt='20px'
      />
    )
  }

  return (
    <>
      {packages?.length && (
        <PackageFilters contentType={contentType} isPageHasSavedFilters={isPageHasSavedFilters} />
      )}

      <InfiniteScroll
        hasMore={fetchPackagesQ.hasNextPage}
        loadMore={fetchPackagesQ.fetchNextPage as (page: number) => void}
      >
        {fetchPackagesQ.isFetching && <TopProgressBar />}
        <Table packages={packages} contentType={contentType} totalPackages={totalPackages} />
      </InfiniteScroll>
    </>
  )
}
