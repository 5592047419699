import React from 'react'

import { IPackage, PackageContentType } from '@models/packages'

import { TableCells } from '../TableCells'
import { StyledTableRow } from '../styles'

interface TableRowProps {
  pack: IPackage
  isPackSelected: boolean
  isLastPack: boolean
  contentType: PackageContentType
}

export const TableRow: React.FC<TableRowProps> = React.memo(
  ({ pack, isPackSelected, contentType, isLastPack }) => {
    return (
      <StyledTableRow
        padding={contentType === 'default' ? '' : '0 0 0 44px'}
        isDefaultContentType={contentType === 'default'}
        hideBorder={isLastPack}
        isPackSelected={isPackSelected}
      >
        <TableCells
          pack={pack}
          contentType={contentType}
          isLastPack={isLastPack}
          isPackSelected={isPackSelected}
        />
      </StyledTableRow>
    )
  }
)
