import { Box, styled } from '@mui/material'

export const StyledInfiniteScroll = styled(Box)`
  position: absolute;
  width: 100%;
  overflow: auto;
  max-height: 200px;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.additional.stroke.main};
  border-radius: 4px;
  margin-top: 3px;
  padding-left: 12px;
  padding-bottom: 11px;
  z-index: 449;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #e5e9f1;
  }

  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #adb4c2;
  }
`
