import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { useDispatch } from 'react-redux'

import {
  selectedFilters as filters,
  deleteSelectedFilters,
  deleteAppliedFilters,
  filtersActualPage,
  setStatedEnteringDate,
} from '@state/filters'
import { Tooltip } from '@components/shared'
import { useAppSelector } from '@hooks/redux'
import { SubText } from '@styles'
import { ReactComponent as DeleteIcon } from '@assets/icons/delete-filter-icon.svg'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_FILTER_OPTIONS_LENGTH } from '@constants/common'
import { typeOfFilter } from '@models/filters'
import { RequestContentType, SwitcherContentType } from '@models/requests'
import { TESTING_REQUEST_TYPE } from '@constants/requests'
import { PackageContentType } from '@models/packages'
import { useSaveFilters } from '@hooks/queries'
import { savedFilters, updateFiltersForUserState } from '@state/user'
import { transformDateFormat } from '@helpers/dates'

import { StyledFilterBox, TooltipScrollContent } from '../styles'
import {
  appliedFiltersSlice,
  EXPIRATION_DATE,
  LABORATORIES_FILTER,
  LAST_UPDATES,
  PACKAGE_DESTINATION_TITLE,
  PACKAGE_FROM_LABORATORY_TITLE,
  PURCHASE_STATUS_FILTER_TITLE,
  REQUESTS_ALL_SAMPLES_TITLE,
  REQUESTS_INCOMING_TITLE,
  REQUESTS_OUTGOING_TITLE,
  REQUEST_STATUS,
  REQUEST_STATUS_FILTER_TITLE,
  selectedFiltersSlice,
  TESTING_DATE,
  TEST_REPORTS_LOCATION_TITLE,
  COLLECTION_DATE,
  INITIAL_LABORATORY_FILTER,
  CREATED_BY_TITLE,
} from '../constants'

interface ViewSelectedFiltersProps {
  type: typeOfFilter
  typeOfRequestTab?: SwitcherContentType
  typeOfPackagesTab?: PackageContentType
  testReportsPage?: boolean
  contentType?: RequestContentType
}

export const ViewSelectedFilters: React.FC<ViewSelectedFiltersProps> = props => {
  const { type, typeOfRequestTab, typeOfPackagesTab, testReportsPage, contentType } = props
  const theme = useTheme()

  const dispatch = useDispatch()
  const selectedFilters = useAppSelector(filters)
  const saveFiltersM = useSaveFilters()
  const actualPageForSavedFilters = useAppSelector(filtersActualPage)
  const arrNames = selectedFilters[appliedFiltersSlice[type]].map(({ name }: { name: any }) => name)

  const optionNames =
    selectedFilters[appliedFiltersSlice[type]].length === 1
      ? arrNames.join(' ')
      : arrNames.join(', ')

  let viewFilterTitle =
    type === INITIAL_LABORATORY_FILTER
      ? CREATED_BY_TITLE
      : selectedFilters[selectedFiltersSlice[type]].title_ui

  switch (type) {
    case REQUEST_STATUS:
      viewFilterTitle =
        typeOfRequestTab === TESTING_REQUEST_TYPE
          ? REQUEST_STATUS_FILTER_TITLE
          : PURCHASE_STATUS_FILTER_TITLE
      break
    case LABORATORIES_FILTER:
      if (typeOfPackagesTab === 'incoming') viewFilterTitle = PACKAGE_FROM_LABORATORY_TITLE
      else if (typeOfPackagesTab === 'outgoing' || typeOfPackagesTab === 'default')
        viewFilterTitle = PACKAGE_DESTINATION_TITLE
      else if (testReportsPage) viewFilterTitle = TEST_REPORTS_LOCATION_TITLE
      else if (contentType === 'default') viewFilterTitle = REQUESTS_ALL_SAMPLES_TITLE
      else if (contentType === 'incoming') viewFilterTitle = REQUESTS_INCOMING_TITLE
      else if (contentType === 'outgoing') viewFilterTitle = REQUESTS_OUTGOING_TITLE
      break

    default:
      break
  }

  let [firstDateValue, secondDateValue] = `${selectedFilters[
    appliedFiltersSlice[type]
  ][0]?.name?.replaceAll('-', '/')} - ${selectedFilters[
    appliedFiltersSlice[type]
  ][1]?.name?.replaceAll('-', '/')}`
    .replace('null', '')
    .replaceAll(' ', '')
    .split('-')

  const dateValue =
    firstDateValue && secondDateValue
      ? [firstDateValue, secondDateValue]
      : firstDateValue
      ? [firstDateValue, firstDateValue]
      : [secondDateValue, secondDateValue]

  const parseDateValue = `${transformDateFormat(dateValue[0]).replaceAll(
    '-',
    '/'
  )} - ${transformDateFormat(dateValue[1]).replaceAll('-', '/')}`

  const getSavedFiltersFromUser = useAppSelector(savedFilters)
  const samplesFilters = getSavedFiltersFromUser.filter(i => {
    return i.page_title === actualPageForSavedFilters
  })[0]

  const deleteFilterHandler = React.useCallback(() => {
    const updatedType = type === INITIAL_LABORATORY_FILTER ? LABORATORIES_FILTER : type
    const deleteOldDataOfFilter = samplesFilters.filters.filter(
      item => item.query_param !== updatedType
    )
    const updatedFilters = [...deleteOldDataOfFilter, { query_param: updatedType, values: [] }]

    dispatch(setStatedEnteringDate({ order: 0, type: '' }))
    dispatch(deleteSelectedFilters(selectedFiltersSlice[updatedType]))
    dispatch(deleteAppliedFilters(appliedFiltersSlice[updatedType]))
    dispatch(
      updateFiltersForUserState({
        page_title: actualPageForSavedFilters,
        filters: updatedFilters,
      })
    )

    saveFiltersM.mutate({
      page_title: actualPageForSavedFilters,
      filters: updatedFilters,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samplesFilters.filters, type, actualPageForSavedFilters, saveFiltersM])

  return (
    <StyledFilterBox
      display='flex'
      alignItems='center'
      key={selectedFilters[selectedFiltersSlice[type]].title_ui}
    >
      {/* {type of filter} */}
      <SubText sx={{ marginRight: '2px' }}>{viewFilterTitle}:</SubText>

      {/* selected items */}
      <Tooltip
        // title={`${optionNames}`}
        title={<TooltipScrollContent>{optionNames}</TooltipScrollContent>}
        shouldBeHidden={(`${optionNames}`.length || 0) <= TRUNCATED_FILTER_OPTIONS_LENGTH}
      >
        <Typography fontSize={12} mr='4px'>
          {type === TESTING_DATE ||
          type === LAST_UPDATES ||
          type === EXPIRATION_DATE ||
          type === COLLECTION_DATE
            ? parseDateValue
            : stringSlice(optionNames, TRUNCATED_FILTER_OPTIONS_LENGTH)}
        </Typography>
      </Tooltip>

      {/* delete items of type */}
      <DeleteIcon
        fill={theme.palette.primary.main}
        onClick={deleteFilterHandler}
        style={{ cursor: 'pointer' }}
      />
    </StyledFilterBox>
  )
}
