import { styled, Box } from '@mui/material'

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 469px;
`

export const StyledModalScroll = styled(Box)<{ height: string }>`
  height: ${({ height }) => height};
  overflow: auto;
  width: 460px;
  padding: 0px 54px;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #e5e9f1;
    border-radius: 72px;
    margin: 24px 0;
  }

  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #adb4c2;
  }
`

export const CounterElement = styled(Box)`
  padding: 8px 16px 12px;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.additional.stroke.main};
  display: flex;
  align-items: center;
`
export const CounterPanel = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-top: 4px;
`

export const InputContainer = styled(Box)`
  display: flex;
  align-items: center;
`
