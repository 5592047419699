import React, { useEffect } from 'react'

import { ISamples } from '@models/samples'
import { useActionsStickyBox, useToggle } from '@hooks/common'
import { ScrollNextSamplesLoader, HorizontalDragLayer, HorizontalDragCup } from '@components/shared'
import { draggableHorizontalScroll } from '@helpers/draggableHorizontalScroll'
import { CustomizeTableColumns } from '@components/modules/Modals'

import { TableHead } from './TableHead'
import { TableBody } from './TableBody'
import { StyledTableBox } from './styles'

interface TableProps {
  samples: ISamples
  isArchive?: boolean
  isFetchingNextPage?: boolean
  totalSamples?: number
  isFetchingSamples?: boolean
  isLoadingSamples?: boolean
}

export const Table: React.FC<TableProps> = props => {
  const { samples, isFetchingNextPage, totalSamples, isFetchingSamples, isLoadingSamples } = props

  const tableHeadRef = React.useRef<HTMLDivElement | null>(null)
  const horizontalDragCup = React.useRef<HTMLDivElement | null>(null)
  const draggableHorizontalRef = React.useRef<HTMLDivElement | null>(null)
  const draggableHorizontalLayerRef = React.useRef<HTMLDivElement | null>(null)
  const isSticky = useActionsStickyBox(tableHeadRef, '-250px 0px 0px 0px')
  const [isCustomizeTableColumnsModalOpen, toggleCustomizeTableColumnsModalOpen] = useToggle()

  const [totalExpandedCells, setTotalExpandedCells] = React.useState(0)
  const [referredTableWidth, setReferredTableWidth] = React.useState('')

  useEffect(() => {
    if (draggableHorizontalRef.current) {
      const { removeDraggableEvents } = draggableHorizontalScroll(
        draggableHorizontalRef,
        draggableHorizontalLayerRef,
        horizontalDragCup
      )

      return () => removeDraggableEvents()
    }
  }, [])

  const horizontalScrollHandler: React.UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    tableHeadRef.current?.scrollTo(currentTarget.scrollLeft, 0)
  }

  const renderRowsLoader = () =>
    new Array(10).fill('').map((_, idx) => <ScrollNextSamplesLoader key={idx} />)

  return (
    <>
      <TableHead
        referredTableWidth={referredTableWidth}
        toggleCustomizeTableColumnsModalOpen={toggleCustomizeTableColumnsModalOpen}
        ref={tableHeadRef}
        samples={samples}
        isSticky={isSticky}
      />
      <HorizontalDragCup ref={horizontalDragCup} />
      <StyledTableBox ref={draggableHorizontalRef} onScroll={horizontalScrollHandler}>
        <HorizontalDragLayer ref={draggableHorizontalLayerRef} />
        <TableBody
          totalExpandedCells={totalExpandedCells}
          setTotalExpandedCells={setTotalExpandedCells}
          setReferredTableWidth={setReferredTableWidth}
          referredTableWidth={referredTableWidth}
          samples={samples}
          totalSamples={totalSamples}
          isFetchingSamples={isFetchingSamples}
          isLoadingSamples={isLoadingSamples}
        />
      </StyledTableBox>
      {isFetchingNextPage && renderRowsLoader()}
      {isCustomizeTableColumnsModalOpen && (
        <CustomizeTableColumns onClose={toggleCustomizeTableColumnsModalOpen} />
      )}
    </>
  )
}
