import React, { useCallback, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import Collapse from '@mui/material/Collapse'

import { selectedFilters as filters } from '@state/filters'
import { useToggle } from '@hooks/common'
import { useAppSelector } from '@hooks/redux'
import {
  TEST_TYPE_FILTER_TITLE,
  selectedFiltersSlice,
} from '@components/shared/FilterAside/constants'
import { ParagraphLink } from '@components/shared/ParagraphLink'
import { IAvailableTestTypesInfo } from '@models/yourWorkspace'
import { TEST_TYPES } from '@constants/queries'
import { ASSAY_GROUP, SCREENING_GROUP, TestGroupName, VISUAL_GROUP } from '@models/tests'
import { useFetchTestTypes } from '@hooks/queries'

import { CardRow, StyledShowMoreLinkBox } from './styles'
import { FilterCheckbox } from '../components/FilterCheckbox'
import { FilterSelectAll } from '../components/FilterSelectAll'
import { SearchFilters } from '../components/SearchFilters'
import { FilterTopTitle } from '../components/FilterTopTitle'

export const TestTypeFilter = React.memo(() => {
  const selectedFilters = useAppSelector(filters)

  // * the request includes all types of tests and is only used for the reports page
  const { data: testTypesData } = useFetchTestTypes(true)
  const wrapRef = React.useRef<HTMLDivElement | null>(null)

  const [isFilterClose, setIsFilterClose] = useToggle()
  const [isShowMore, setIsShowMore] = React.useState<boolean>(true)
  const [testTypes, setTestTypes] = React.useState<IAvailableTestTypesInfo[]>([])

  const filterCounter = selectedFilters[selectedFiltersSlice.test_types].filters.length || undefined
  const showNumberOfItems = 4

  useEffect(() => {
    if (testTypesData) setTestTypes(testTypesData)
  }, [testTypesData])

  const listOfTestsByType = useCallback(
    (testGroupName: TestGroupName) => {
      return (
        testTypes
          ?.filter(option => option.group?.name === testGroupName)
          .map(i => ({ id: String(i.id), name: i.name })) || []
      )
    },
    [testTypes]
  )

  const screeningListItems = React.useMemo(() => {
    if (!!listOfTestsByType(SCREENING_GROUP)) {
      if (isShowMore) return listOfTestsByType(SCREENING_GROUP).slice(0, showNumberOfItems)
      else return listOfTestsByType(SCREENING_GROUP)
    }
  }, [isShowMore, listOfTestsByType])

  const showNumberOfAssayListItems = React.useMemo(() => {
    if (!!listOfTestsByType(SCREENING_GROUP)) {
      if (showNumberOfItems - listOfTestsByType(SCREENING_GROUP).length <= 0) return 0
      else return showNumberOfItems - listOfTestsByType(SCREENING_GROUP).length
    }
  }, [listOfTestsByType])

  const assayListItems = React.useMemo(() => {
    if (!!listOfTestsByType(ASSAY_GROUP)) {
      if (isShowMore) return listOfTestsByType(ASSAY_GROUP).slice(0, showNumberOfAssayListItems)
      else return listOfTestsByType(ASSAY_GROUP)
    }
  }, [isShowMore, listOfTestsByType, showNumberOfAssayListItems])

  const searchInputQuery = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value.toLocaleLowerCase()

      const updateListOfTypes = testTypesData?.filter(test =>
        test.name.toLocaleLowerCase().includes(query)
      )
      setTestTypes(updateListOfTypes || [])
    },
    [testTypesData]
  )

  return (
    <div ref={wrapRef}>
      <FilterTopTitle
        filterNumb={testTypesData?.length || 0}
        isFilterClose={isFilterClose}
        setIsFilterClose={setIsFilterClose}
        titleOfFilter={TEST_TYPE_FILTER_TITLE}
        filterCounter={filterCounter}
      />

      <Collapse in={!isFilterClose} unmountOnExit>
        <CardRow>
          {/* Search by test type name on FE side */}
          {!!testTypesData?.length && <SearchFilters searchFiltersHandler={searchInputQuery} />}

          <Box sx={{ width: '100%' }}>
            {/* Select all */}
            {!!testTypes.length && (
              <FilterSelectAll
                type={TEST_TYPES}
                items={testTypes.map(({ id, name }) => ({
                  id: String(id),
                  name,
                }))}
              />
            )}

            {/* List of items */}

            {/* {Visual inspection test} */}

            {listOfTestsByType(VISUAL_GROUP)?.map(test => {
              return <FilterCheckbox type={TEST_TYPES} item={test} key={test.id} />
            })}

            {/* {Screening test} */}

            {!!screeningListItems?.length && (
              <Typography ml='24px' fontSize='12px' color='secondary.light'>
                Screening test
              </Typography>
            )}

            {screeningListItems?.map(test => {
              return <FilterCheckbox type={TEST_TYPES} item={test} key={test.id} />
            })}

            {/* {Assay test} */}

            {!!assayListItems?.length && (
              <Typography ml='24px' fontSize='12px' color='secondary.light'>
                Assay test
              </Typography>
            )}

            {assayListItems?.map(test => {
              return <FilterCheckbox type={TEST_TYPES} item={test} key={test.id} />
            })}

            {testTypes && testTypes.length > showNumberOfItems && (
              <StyledShowMoreLinkBox>
                <ParagraphLink
                  sx={{ padding: '11px 24px 12px' }}
                  onClick={() => setIsShowMore(!isShowMore)}
                >
                  Show {isShowMore ? 'more' : 'less'}
                </ParagraphLink>
              </StyledShowMoreLinkBox>
            )}
          </Box>
        </CardRow>
      </Collapse>
    </div>
  )
})
