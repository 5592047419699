import React from 'react'
import { useMediaQuery } from '@mui/material'

import logoVerify from '@assets/icons/logo-white-verify.svg'
import logoScreenr from '@assets/icons/logo-white-screenr.svg'
import maintenanceImg from '@assets/images/maintenance.svg'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'
import { StyledFlexContainer } from '@styles'

import { Image, Logo, OtherSessionTitle, OtherSessionBox } from './styles'

interface OtherSessionPageProps {}

export const OtherSessionPage: React.FC<OtherSessionPageProps> = () => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const { isTenantType } = useTenant()
  const isLegalDrugs = isTenantType(TENANT_TYPE.legalDrugs)

  return (
    <StyledFlexContainer $isMobile={isMobile}>
      <OtherSessionBox $isMobile={isMobile}>
        <Logo
          src={isLegalDrugs ? logoVerify : logoScreenr}
          alt='logo'
          width='150px'
          height='45px'
        />
        <OtherSessionTitle $isMobile={isMobile}>
          Cannot login due to active session in another website. Please log out there and proceed
          with logging in here.
        </OtherSessionTitle>

        {!isMobile && <Image src={maintenanceImg} alt='image' />}
      </OtherSessionBox>
    </StyledFlexContainer>
  )
}
