import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { REQUIRED_FIELD } from '@constants/validation'
import { Button, Input, Modal } from '@components/shared'
import { copySample } from '@state/sampleCreate'
import {
  setCopySampleCreateId,
  seCopySampleId,
  sampleCreateIds,
  copySampleId,
  setAddSampleButtonPushed,
} from '@state/app'
import { LIMITATION_ON_SAMPLE_CREATION } from '@constants/samples'
import { useAppSelector } from '@hooks/redux'

import { StyledForm, StyledLabel, StyledTitle, ButtonBox } from './styles'

export const CopySample: React.FC = () => {
  const { register, formState, getValues } = useForm<{ copy: string }>({
    mode: 'onChange',
  })
  const sampleIds = useAppSelector(sampleCreateIds)
  const copySampleIdStr = useAppSelector(copySampleId)
  const [isCopying, setIsCopying] = React.useState(false)
  const maxCopies = LIMITATION_ON_SAMPLE_CREATION - sampleIds.length
  const prevMaxCopiesCheck = +getValues('copy') > maxCopies

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (formState.errors.copy || !copySampleIdStr) {
      setIsCopying(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  const setNewIds = (numberOfCopies: number) => {
    const newIds = []
    for (let i = 0; i < numberOfCopies; i++) {
      newIds.push(uuid())
    }
    return newIds
  }

  const handleCopySample = () => {
    setIsCopying(true)
    setTimeout(() => {
      const newIds = setNewIds(+getValues('copy'))
      dispatch(copySample({ copySampleId: copySampleIdStr, newIds }))
      dispatch(setCopySampleCreateId(newIds))
    }, 50)
    dispatch(setAddSampleButtonPushed(5))
  }

  const onClose = () => dispatch(seCopySampleId(''))

  return (
    <Modal open={!!copySampleIdStr} onClose={onClose} disableAutoFocus={true}>
      <StyledForm>
        <StyledTitle variant='h3'>How many copies do you want to create?</StyledTitle>

        <StyledLabel
          label='Enter the number of copies'
          control={
            <Input
              autoFocus
              defaultValue='1'
              dataTestId='input-copy'
              width='140px'
              variant='outlined'
              type='copy'
              error={!!formState.errors.copy}
              helperText={
                prevMaxCopiesCheck
                  ? `Max ${maxCopies} copy at once`
                  : formState.errors.copy && formState.errors.copy.message
              }
              {...register('copy', {
                required: REQUIRED_FIELD,
                validate: {
                  digit: v => !isNaN(Number(v)) || 'The value must be a digit',
                },
                max: {
                  value: maxCopies,
                  message: `Max ${maxCopies} copy at once`,
                },
                min: {
                  value: 1,
                  message: `Must be at least 1 copy`,
                },
              })}
            />
          }
        />
        <ButtonBox>
          <Button type='button' variant='outlined' width='205px' onClick={onClose}>
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            width='205px'
            height='44px'
            loadingIndicator='Copying...'
            loading={isCopying}
            disabled={!!formState.errors.copy || prevMaxCopiesCheck}
            onClick={handleCopySample}
          >
            Copy
          </Button>
        </ButtonBox>
      </StyledForm>
    </Modal>
  )
}
