import React from 'react'
import { Box, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import { IDeliveryServicesInfo } from '@models/yourWorkspace'
import { Checkbox } from '@components/shared'
import { useFetchDeliveryServices } from '@hooks/queries'

import { StyledOptionTypography } from './styles'

interface DeliveryServicesProps {
  selectedDelivery?: IDeliveryServicesInfo[]
  deliveryServices?: IDeliveryServicesInfo[]
  name: string
}

export const DeliveryServices: React.FC<DeliveryServicesProps> = props => {
  const { name, selectedDelivery = [] } = props
  const { control, setValue } = useFormContext()
  const deliveryServicesQ = useFetchDeliveryServices()

  const options = deliveryServicesQ.data?.map(i => i.name) || []
  const selectedOptions = selectedDelivery.map(i => i.name)
  const allOptions = deliveryServicesQ.data || []

  const [selected, setSelected] = React.useState<string[]>(selectedOptions)
  const [error, setError] = React.useState<boolean>(false)

  const isAllSelected = options.length > 0 && selected.length === options.length

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options.length ? [] : options)
      return
    }
    setSelected(value as string[])
  }

  const delivery_services = allOptions
    ?.filter(item => selected.includes(item.name))
    .map(({ id }) => id)

  React.useEffect(() => {
    // Max 30 delivery services
    if (delivery_services.length > 30) {
      setError(true)
    } else setError(false)

    setValue(name, delivery_services)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery_services, name])

  return (
    <div>
      <Controller
        render={() => {
          return (
            <Select
              fullWidth
              multiple
              value={selected}
              onChange={handleChange}
              sx={{ border: error ? '1px solid #AF4645' : 'none' }}
              renderValue={selected => (
                <Box display='flex' justifyContent='space-between'>
                  <Typography>{selected.join(', ')}</Typography>
                  <Typography fontWeight={500}>({selected.length})</Typography>
                </Box>
              )}
            >
              <MenuItem value='all' sx={{ padding: '10px 0 10px 24px' }}>
                <Checkbox
                  sx={{ padding: '0px 9px 0 0' }}
                  checked={isAllSelected}
                  indeterminate={selected.length > 0 && selected.length < options.length}
                />
                <StyledOptionTypography>
                  {isAllSelected ? 'Clear' : 'Select'} all
                </StyledOptionTypography>
              </MenuItem>

              {options.map(item => {
                return (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={selected.indexOf(item) > -1} />
                    <ListItemText primary={item} />
                  </MenuItem>
                )
              })}
            </Select>
          )
        }}
        control={control}
        name={name}
      />

      {error && (
        <Typography fontSize='12px' color='#AF4645'>
          Max 30 delivery services
        </Typography>
      )}
    </div>
  )
}
