import { FormProvider, useForm } from 'react-hook-form'

import { StyledDetailsBox } from '@styles'
import { ISample } from '@models/samples'
import { SampleOptionsProvider } from '@context'
import { parseSampleData } from '@helpers/ui/samples'
import { InfoBoxTemperature } from '@components/shared'
import { infoBoxText } from '@components/shared/InfoBoxes/InfoBoxTemperature'

import { EditButtons } from './EditButtons'
import { EditMainInfo } from './EditMainInfo'
import { EditPurchaseDetails } from './EditPurchaseDetails'
import { EditManufacturerDetails } from './EditManufacturerDetails'
import { InfoBoxWrapper } from './InfoBoxWrapper'

interface EditSampleDetailsProps {
  sample: ISample
  toggleEditing: () => void
}

export const EditSampleDetails: React.FC<EditSampleDetailsProps> = ({ sample, toggleEditing }) => {
  const formMethods = useForm({ defaultValues: parseSampleData(sample) })

  return (
    <>
      <FormProvider {...formMethods}>
        <InfoBoxWrapper>
          <InfoBoxTemperature temperatureText={infoBoxText.temperature.sample} />
        </InfoBoxWrapper>

        <StyledDetailsBox>
          <SampleOptionsProvider>
            <EditMainInfo sample={sample} />
            <EditPurchaseDetails sample={sample} />
            <EditManufacturerDetails sample={sample} />
          </SampleOptionsProvider>
        </StyledDetailsBox>

        <EditButtons sampleId={sample.id} toggleEditing={toggleEditing} />
      </FormProvider>
    </>
  )
}
