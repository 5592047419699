import React from 'react'
import { Box } from '@mui/system'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useFormContext, useWatch } from 'react-hook-form'

import { Checkbox } from '@components/shared'
import { IErrorsEditLaboratory } from '@models/yourWorkspace/ui'
import { skillDetails } from '@constants/mappings'
import { ISkill } from '@models/common/app'
import { ErrorBox } from '@components/shared/ErrorBox'

import * as Styled from './styles'

interface ISkillsProps {
  skillList?: ISkill[]
  apiErrorMessage?: IErrorsEditLaboratory
}

export const SkillsField: React.FC<ISkillsProps> = props => {
  const { apiErrorMessage, skillList = [] } = props
  const { register, control } = useFormContext()

  const field = useWatch({ control, name: 'skills' })

  if (!skillList.length) {
    return null
  }

  return (
    <>
      <Styled.Label>
        Permission to work with samples <Styled.StyledTextSkills>(skills)</Styled.StyledTextSkills>
      </Styled.Label>
      <Box mb='10px'>
        {skillList.map(skill => {
          return (
            <FormControlLabel
              key={skill.id}
              control={
                <Checkbox {...register(`skills[${skill.id}]`)} checked={field[skill.id] || false} />
              }
              label={
                <Styled.LabelCheckboxSkillsWrapper>
                  <img src={skillDetails.icon(skill.id).mobile} alt={'icon'} />
                  <Styled.LabelCheckboxSkills>{skill.name}</Styled.LabelCheckboxSkills>
                </Styled.LabelCheckboxSkillsWrapper>
              }
            />
          )
        })}
      </Box>
      {apiErrorMessage?.skills ? (
        <ErrorBox errorMainField={apiErrorMessage?.skills.join('\n')} />
      ) : null}
    </>
  )
}
