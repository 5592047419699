import React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'

import {
  StyledModalContent,
  StyledModalScroll,
  StyledModalTitle,
  StyledShadowBox,
  StyledTitle,
} from '@styles'
import { RESULTS_STEP_TITLE } from '@constants/tests'
import { FullPageError, Modal, ParagraphLink } from '@components/shared'
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { PhotoWithAttachments as SharedPhotoWithAttachments } from '@components/shared/Hr'
import { TandemMSEditResults } from '@components/harmModules/HrTasksDetails'

import { Loader } from '../../../harmModules/HrTasksDetails/components/HrTaskInfo/components/Loader'
import { HrTandemMSResultsPropTypes } from './tandemMS.types'
import * as Styled from './styles'
import { TandemMSResultsItems } from './Results/TandemMSResultsItems'

export const HrTandemMSResults: React.FC<HrTandemMSResultsPropTypes> = ({ isLoading, task }) => {
  const theme = useTheme()
  const [isOpenEditModal, setIsOpenEditModal] = React.useState<boolean>(false)
  const [isOpenSubstanceModal, setIsOpenSubstanceModal] = React.useState<boolean>(false)
  const isMobile = useMediaQuery('(max-width:767px)')

  const isResultsEmpty = task?.step === -1

  const prunedNumber = 4
  const prunedResults = task?.substance_result?.slice(0, prunedNumber)

  const equipmentNumberAttachment = React.useMemo(
    () => task?.attachments.filter(att => att.attachment_section.key === 'equipment_number'),
    [task?.attachments]
  )
  const testInfoAttachment = React.useMemo(
    () => task?.attachments.filter(att => att.attachment_section.key === 'test_info'),
    [task?.attachments]
  )

  if (isLoading) return <Loader />
  return (
    <>
      <Box width={isResultsEmpty ? '30%' : '50%'}>
        <StyledTitle mb='32px' variant='h3' fz={24}>
          {RESULTS_STEP_TITLE}
          {task?.is_able_to_edit_task && (
            <Styled.ImageButton onClick={() => setIsOpenEditModal(true)}>
              <EditIcon fill={theme.palette.primary.main} />
            </Styled.ImageButton>
          )}
        </StyledTitle>

        {isResultsEmpty ? (
          <FullPageError
            imgVariant='common'
            errorTextTitle='There are no test results yet'
            pt='70px'
            svgWidth='110px'
            svgHeight='110px'
          />
        ) : (
          <>
            {/* ----- TEST REPORT ----- */}
            <Typography variant='subtitle1' mb='24px'>
              Test report
            </Typography>
            {/* Preparation for test */}
            <Styled.DetailsBox>
              <Typography variant='h5' width='218px' mb='18px'>
                Preparation for test
              </Typography>
              <Box mb='18px' width='calc(100% - 218px)'>
                {task?.preparation ? (
                  <Typography variant='body1'>{task?.preparation}</Typography>
                ) : (
                  <Typography variant='body1' color='primary.light'>
                    Information has not been provided
                  </Typography>
                )}
              </Box>
            </Styled.DetailsBox>
            {/* Substances recognized */}
            <Typography variant='h5' width='218px' mb='18px'>
              Substances recognized
            </Typography>
            <TandemMSResultsItems substanceResult={prunedResults} testDetailsPage />
            {Number(task?.substance_result?.length) > prunedNumber && (
              <ParagraphLink mt='16px' onClick={() => setIsOpenSubstanceModal(true)}>
                Show more results
              </ParagraphLink>
            )}
            {/* Attachments */}
            <Styled.DetailsBox>
              <Typography variant='h5' width='218px' mt='18px' mb='18px'>
                Attachments
              </Typography>
              <Box mt='18px' mb='18px' width='calc(100% - 218px)'>
                {testInfoAttachment?.length ? (
                  <SharedPhotoWithAttachments
                    attachments={testInfoAttachment}
                    isGoodQualityOfPhoto
                    width='103px'
                    height='103px'
                    mr='8px'
                  />
                ) : (
                  <Typography variant='body1' color='primary.light' sx={{ marginBottom: '37px' }}>
                    Attachments were not provided
                  </Typography>
                )}
              </Box>
            </Styled.DetailsBox>
            <Typography variant='subtitle1' mb='18px'>
              Equipment numbers, control chart, and system suitability
            </Typography>
            <Styled.DetailsBox>
              <Typography variant='h5' width='218px' mb='18px'>
                Notes
              </Typography>
              <Box mb='18px' width='calc(100% - 218px)'>
                {task?.test_notes ? (
                  <Typography variant='body1'>{task?.test_notes}</Typography>
                ) : (
                  <Typography variant='body1' color='primary.light'>
                    Information has not been provided
                  </Typography>
                )}
              </Box>
            </Styled.DetailsBox>
            {/* Attachments */}
            <Styled.DetailsBox>
              <Typography variant='h5' width='218px' mb='18px'>
                Attachments
              </Typography>
              <Box mb='18px' width='calc(100% - 218px)'>
                {equipmentNumberAttachment?.length ? (
                  <SharedPhotoWithAttachments
                    attachments={equipmentNumberAttachment}
                    isGoodQualityOfPhoto
                    width='103px'
                    height='103px'
                    mr='8px'
                  />
                ) : (
                  <Typography variant='body1' color='primary.light' sx={{ marginBottom: '37px' }}>
                    Attachments were not provided
                  </Typography>
                )}
              </Box>
            </Styled.DetailsBox>
          </>
        )}
      </Box>
      {/* SUBSTANCE MODAL */}
      <Modal
        open={isOpenSubstanceModal}
        onClose={() => setIsOpenSubstanceModal(false)}
        disableAutoFocus
        isPaddingAbsence
      >
        <>
          <StyledShadowBox padding={isMobile ? '20px' : '25px'}>
            <StyledModalTitle variant='h3'>Substances recognized</StyledModalTitle>
          </StyledShadowBox>
          <StyledModalContent pt='0' $isMobile={isMobile}>
            <StyledModalScroll $isMobile={isMobile}>
              <TandemMSResultsItems substanceResult={task?.substance_result} />
            </StyledModalScroll>
          </StyledModalContent>
        </>
      </Modal>
      {/* EDIT RESULTS */}
      {isOpenEditModal && task && (
        <TandemMSEditResults
          task={task}
          onClose={() => {
            setIsOpenEditModal(false)
          }}
        />
      )}
    </>
  )
}
