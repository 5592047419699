import { useMutation, useQueryClient } from 'react-query'

import { splitSample } from '@api/samples'
import { SAMPLES } from '@constants/queries'
import { useAppDispatch } from '@hooks/redux'
import { showNotification } from '@helpers/app'
import { ISplitSampleVars } from '@models/samples'
import { notifications } from '@constants/notifications'
import { selectSamples, startSplitting } from '@state/samples'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useSplitSample = () => {
  const client = useQueryClient()
  const dispatch = useAppDispatch()

  const mutation = useMutation<unknown, IError, ISplitSampleVars>(splitSample, {
    onSuccess: (data, vars) => {
      dispatch(startSplitting(vars.id))
      dispatch(selectSamples([]))

      showNotification({
        ...notifications.samplesSplitSuccessfully,
        title: `${vars.data.count_of_sub_samples.length} - sub-samples were created successfully`,
      })
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
      showNotification(notifications.samplesSplitError)
    },
    onSettled: () => {
      client.invalidateQueries(SAMPLES)
    },
  })

  return mutation
}
