import React from 'react'
import { useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { Box, useMediaQuery } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroller'

import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { IPackage } from '@models/packages'
import { PACKAGES, TENANT_TYPES } from '@constants/queries'
import { DESIRED_SAMPLES } from '@constants/samples'
import { protectedRoutes } from '@configs/routes.config'
import { Modal, Button, Tooltip } from '@components/shared'
import { useAddSamplesIntoPackage, useFetchSamples } from '@hooks/queries'
import { FREE_SAMPLES, MAX_SELECTED_PACKAGE_NUMBER } from '@constants/packages'
import {
  ButtonBox,
  StyledModalTitle,
  StyledModalSubtitle,
  StyledShadowBox,
  StyledModalContent,
} from '@styles'
import { selectSamplesInModal, selectSamplesInModalState } from '@state/packages'
import { NoFilteredSamples } from '@components/modules/Samples/SampleTabs/NoSamples'
import { AutocompleteSearch } from '@components/shared/AutocompleteSearch'
import { ISamples, IHRSamples } from '@models/samples'
import { TENANT_TYPE } from '@constants/tenants'
import { Tenant } from '@models/common/app'

import { Table } from './components/Table'
import { StyledModalScroll } from './styles'

interface IParams {
  packageId: string
}

export const AddSamples: React.FC = () => {
  const { packageId } = useParams<IParams>()
  const client = useQueryClient()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const isSmallHeight = useMediaQuery('(max-height:700px)')

  const tenantType = queryClient.getQueryData<Tenant>(TENANT_TYPES)
  const isVerifyTenant = tenantType?.type === TENANT_TYPE.legalDrugs
  type ISamplesInterface = typeof isVerifyTenant extends true ? ISamples : IHRSamples

  const addSamplesM = useAddSamplesIntoPackage(packageId)
  const fetchSamplesQ = useFetchSamples<ISamplesInterface>()
  const samples = fetchSamplesQ.data?.pages.map(page => [...page.results]).flat()

  const scrollParentRef = React.useRef(null)
  const quantity = client.getQueryData<IPackage>([PACKAGES, packageId])?.quantity || 0

  const [filteringQueryString, setFilteringQueryString] = useQueryParam(FREE_SAMPLES, StringParam)
  const [searchingQueryString, setSearchingQueryString] = useQueryParam(
    DESIRED_SAMPLES,
    StringParam
  )

  const selectedSamplesInModal = useAppSelector(selectSamplesInModalState)

  const closeModalHandler = () =>
    history.replace({
      pathname: protectedRoutes.packageDetails.path.replace(':packageId', `${packageId}`),
      state: history.location.state,
    })

  const buttonOnClickHandler = () => {
    addSamplesM.mutate(
      { id: Number(packageId), data: { samples: selectedSamplesInModal } },
      {
        onSettled: () => {
          closeModalHandler()
          dispatch(selectSamplesInModal([]))
        },
      }
    )
  }

  React.useEffect(() => {
    setFilteringQueryString('true')
  }, [filteringQueryString, setFilteringQueryString])

  return (
    <Modal isPaddingAbsence open onClose={closeModalHandler}>
      <Box>
        <StyledShadowBox>
          <StyledModalTitle variant='h3'>Add samples</StyledModalTitle>
          <StyledModalSubtitle withoutMarginBelow>
            Choose samples for adding to package
          </StyledModalSubtitle>
        </StyledShadowBox>

        <StyledModalContent>
          <Box sx={{ paddingLeft: '35px', marginBottom: '5px' }}>
            <AutocompleteSearch
              width='100%'
              placeholder='Enter sample ID...'
              setQueryString={setSearchingQueryString}
              queryString={searchingQueryString}
              searchIn='addSamples'
            />
          </Box>
          <StyledModalScroll height={isSmallHeight ? '220px' : '450px'} ref={scrollParentRef}>
            <InfiniteScroll
              hasMore={fetchSamplesQ.hasNextPage}
              loadMore={fetchSamplesQ.fetchNextPage as (page: number) => void}
              useWindow={false}
              getScrollParent={() => scrollParentRef.current}
            >
              {!samples?.length && !fetchSamplesQ.isLoading ? (
                <NoFilteredSamples />
              ) : (
                <Table
                  isFetchingNextPage={fetchSamplesQ.isFetching}
                  isLoading={fetchSamplesQ.isLoading}
                  samples={(samples as ISamplesInterface) || []}
                  isVerifyTenant={isVerifyTenant}
                />
              )}
            </InfiniteScroll>
          </StyledModalScroll>
        </StyledModalContent>

        <StyledShadowBox>
          <ButtonBox sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type='button'
              variant='outlined'
              color='secondary'
              width='225px'
              height='48px'
              onClick={closeModalHandler}
            >
              Cancel
            </Button>

            <Tooltip
              title={`Maximum number of samples per package ${MAX_SELECTED_PACKAGE_NUMBER}`}
              shouldBeHidden={
                quantity + selectedSamplesInModal.length <= MAX_SELECTED_PACKAGE_NUMBER
              }
            >
              <Box>
                <Button
                  ml='28px'
                  type='submit'
                  variant='contained'
                  width='225px'
                  height='48px'
                  loading={addSamplesM.isLoading}
                  disabled={
                    quantity + selectedSamplesInModal.length > MAX_SELECTED_PACKAGE_NUMBER ||
                    !selectedSamplesInModal.length
                  }
                  onClick={buttonOnClickHandler}
                >
                  Add samples ({selectedSamplesInModal.length}/
                  {MAX_SELECTED_PACKAGE_NUMBER - quantity})
                </Button>
              </Box>
            </Tooltip>
          </ButtonBox>
        </StyledShadowBox>
      </Box>
    </Modal>
  )
}
