import InfiniteScroll from 'react-infinite-scroller'
import { Box } from '@mui/material'
import React from 'react'
import { useQueryParams } from 'use-query-params'
import { useDispatch } from 'react-redux'

import { useFetchUsers } from '@hooks/queries/useFetchUsers'
import { EditUser } from '@components/modules/Modals'
import { useAppSelector } from '@hooks/redux'
import { workSpaceInfo } from '@state/workSpace'
import { FilterAside } from '@components/shared/FilterAside'
import { UserStatusFilter } from '@components/shared/filters/allFilters'
import { savedFilters } from '@state/user'
import {
  applyFiltersOnPage,
  deleteAllAppliedFilters,
  deleteAllSelectedFilters,
  filtersAside,
  setIsFilterOpen,
  setPageForFilters,
} from '@state/filters'
import {
  clearAllFilterStringParamsQueries,
  filterStringParamsQueries,
} from '@components/shared/FilterAside/constants'

import { Table } from './components/Table'
import { TabFilters } from './Filters'

export const TabListOfUsers = () => {
  const { selectedLaboratory } = useAppSelector(workSpaceInfo)

  const [, setQueryString] = useQueryParams({
    ...filterStringParamsQueries,
  })
  const isFilterOpen = useAppSelector(filtersAside)

  const dispatch = useDispatch()
  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useFetchUsers(
    selectedLaboratory?.id
  )
  const [editUserIsOpen, setEditUserIsOpen] = React.useState<boolean>(false)

  const actualPageForSavedFilters = 'manage_users'

  const getSavedFiltersFromUser = useAppSelector(savedFilters)
  const usersFilters = getSavedFiltersFromUser.filter(i => {
    return i.page_title === actualPageForSavedFilters
  })[0]
  const [updatedFilters, setUpdatedFilters] = React.useState(usersFilters)
  const isPageHasSavedFilters = updatedFilters.filters.some(i => i.values.length !== 0)

  React.useEffect(() => {
    // *  open/close filter aside
    dispatch(setIsFilterOpen({ ...isFilterOpen, right: false }))
    dispatch(setPageForFilters(actualPageForSavedFilters))

    return () => {
      dispatch(deleteAllAppliedFilters())
      dispatch(deleteAllSelectedFilters())
      setQueryString(clearAllFilterStringParamsQueries)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setUpdatedFilters(usersFilters)
  }, [usersFilters])

  React.useEffect(() => {
    if (isPageHasSavedFilters) dispatch(applyFiltersOnPage(usersFilters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersFilters, isPageHasSavedFilters])

  const editUserClose = () => {
    setEditUserIsOpen(false)
  }

  const users = data?.pages.map(page => page.results).flat()

  return (
    <>
      <Box sx={{ marginTop: '30px', marginBottom: '-10px' }}>
        <TabFilters isPageHasSavedFilters={true} />
      </Box>
      <FilterAside>
        <Box>
          <UserStatusFilter />
        </Box>
      </FilterAside>
      <Box sx={{ marginTop: '33px' }}>
        <InfiniteScroll hasMore={hasNextPage} loadMore={fetchNextPage as (page: number) => void}>
          <Table
            isLoading={isLoading}
            setEditUserIsOpen={setEditUserIsOpen}
            users={users}
            isFetchingNextPage={isFetching}
          />
        </InfiniteScroll>

        {editUserIsOpen && <EditUser onClose={editUserClose} />}
      </Box>
    </>
  )
}
