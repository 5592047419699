import { styled, Box } from '@mui/material'

export const StyledModalScroll = styled(Box)<{ height: string }>`
  height: ${({ height }) => height};
  overflow: auto;
  width: 460px;
  padding: 24px 25px;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #e5e9f1;
    border-radius: 72px;
    margin: 24px 0;
  }

  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #adb4c2;
  }
`
