import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useController } from 'react-hook-form'

import { PureDropdownAutocomplete } from '@components/ui'
import { IOption } from '@models/common/app'
import { setCellValueToSample } from '@state/sampleCreate'

import { PureCellPropTypes } from './pureFields.types'

export const Dosage: React.FC<PureCellPropTypes> = ({
  name,
  cellName,
  dropdownCellName,
  initialVal,
  dropdownInitialVal,
  sampleId,
  options,
  rules,
  control,
  isCopy,
}) => {
  const dispatch = useDispatch()
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules, control, defaultValue: initialVal.val })

  React.useEffect(() => {
    if (error && error.message) {
      dispatch(
        setCellValueToSample({
          cellValue: { val: field.value, error: error.message },
          cellName,
          sampleId,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onInputChange = (val: string) => {
    field.onChange({ target: { value: val } })
    dispatch(
      setCellValueToSample({
        cellValue: { val, error: '' },
        cellName,
        sampleId,
      })
    )
  }

  const onDropdownChange = (val: IOption) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val: val.id, error: '' },
        cellName: dropdownCellName as string,
        sampleId,
      })
    )
  }

  return (
    <PureDropdownAutocomplete
      name={name}
      isCopy={isCopy}
      isDropdownWithInput
      options={(options as IOption[]) || []}
      errorMessage={error?.message || ''}
      initialVal={dropdownInitialVal?.val as number | null}
      inputInitialVal={initialVal.val as string}
      onInputChange={onInputChange}
      onDropdownChange={onDropdownChange}
    />
  )
}
