import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'

import { ReactComponent as CirclesIcon } from '@assets/icons/yourWorkSpaceIcons/circles.svg'
import { ReactComponent as PillIcon } from '@assets/icons/yourWorkSpaceIcons/pill.svg'
import { ReactComponent as TruckIcon } from '@assets/icons/yourWorkSpaceIcons/truck.svg'
import { ILaboratory } from '@models/common/app'

import * as Styled from '../../../styles'

interface AdditionalInfoProps {
  currentLaboratory?: ILaboratory
}

export const AdditionalInfo: React.FC<AdditionalInfoProps> = props => {
  const { currentLaboratory } = props
  const theme = useTheme()

  const testTypes = currentLaboratory?.test_types?.map(testType => testType.name).join(', ')
  const deliveryServices = currentLaboratory?.delivery_services
    ?.map(testType => testType.name)
    .join(', ')

  return (
    <Box mb='34px'>
      <Typography variant='subtitle1' mb='27px'>
        Additional info
      </Typography>

      <Styled.AdditionalInfoBox>
        <CirclesIcon stroke={theme.palette.primary.main} style={Styled.iconStyles} />
        <Typography>{currentLaboratory?.group.name}</Typography>
      </Styled.AdditionalInfoBox>

      <Styled.AdditionalInfoBox>
        <PillIcon stroke={theme.palette.primary.main} style={Styled.iconStyles} />

        <Typography>{testTypes?.length ? testTypes : '-'}</Typography>
      </Styled.AdditionalInfoBox>

      <Styled.AdditionalInfoBox>
        <TruckIcon stroke={theme.palette.primary.main} style={Styled.iconStyles} />
        <Typography>{deliveryServices?.length ? deliveryServices : '-'}</Typography>
      </Styled.AdditionalInfoBox>
    </Box>
  )
}
