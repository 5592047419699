// * [Dynamic Protected Pages

const emailConfirmationRegexp = /^\/app\/profile\/email_confirmation\/\d+\/\w{20,}\/\S+@\S+\.\S+$/
const sampleDetailsRegexp = /^\/app\/samples\/\d+\/details$/
const taskDetailsRegexp = /^\/app\/tasks\/\d+\/details$/
const packageInfoRegexp = /^\/app\/packages\/\d+\/details$/
const addSamplesToPackage = /^\/app\/packages\/\d+\/details\/add_samples$/
const attachMaterialsModal = /^\/app\/packages\/\d+\/details\/attach\/materials$/
const attachPackageStorageModal = /^\/app\/packages\/\d+\/details\/attach\/package_storage$/
const attachStorageModal = /^\/app\/samples\/\d+\/details\/attach\/storage$/
const createSampleAttachStorageModal = /^\/app\/samples\/create\/attach\/storage$/
const attachResultModal = /^\/app\/tasks\/\d+\/details\/attach\/results$/
const generateQr = /^\/app\/packages\/\d+\/details\/generate_qr$/
const testRegexp = /^\/app\/test\/screening|assay|visual\/\d+$/
const logIntoPadAppMobile = /^\/app\/tasks\/log_into_pad_app$/
// Harm
const createHrSampleAttachPhotoModal = /^\/app\/samples\/create\/attach\/photo$/
const viewHrSampleAttachPhotoModal = /^\/app\/samples\/\d+\/details\/attach\/photo$/
const editHrSampleAttachPhotoModal = /^\/app\/samples\/\d+\/details\/edit\/attach\/photo$/

export const dynamicProtectedPages = [
  emailConfirmationRegexp,
  sampleDetailsRegexp,
  taskDetailsRegexp,
  packageInfoRegexp,
  attachPackageStorageModal,
  addSamplesToPackage,
  attachMaterialsModal,
  attachResultModal,
  generateQr,
  testRegexp,
  logIntoPadAppMobile,
  attachStorageModal,
  createSampleAttachStorageModal,
  createHrSampleAttachPhotoModal,
  viewHrSampleAttachPhotoModal,
  editHrSampleAttachPhotoModal,
]

// * Dynamic Protected Pages]

// * [Dynamic Public Pages

export const maintenanceModeUnsubscribeRoute = /^\/subscriptions\/manage\/\S+@\S+\.\S+$/
export const emailConfirmationRoute = /\/email_confirmation\//

const recoverPassRegexp = /^\/account\/recover_password\/\d+\/\w{20,}\/\w{1,}$/
export const dynamicPublicPages = [recoverPassRegexp]

// * Dynamic Public Pages]
