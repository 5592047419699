import React from 'react'
import { TextField, useMediaQuery } from '@mui/material'
import { LocalizationProvider, StaticDatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import dayjs from 'dayjs'

import * as DatePickerStyled from './../styles'

interface CalendarProps {
  enteringDate: number
  startDate: null | string
  finishDate: null | string
  renderDayInPicker: (date: Date) => JSX.Element
  onMonthChange: (date: Date) => void
  onYearChange: (date: Date) => void
}

export const Calendar: React.FC<CalendarProps> = React.memo(
  ({ enteringDate, startDate, finishDate, renderDayInPicker, onMonthChange, onYearChange }) => {
    const isMobile = useMediaQuery('(max-width:767px)')

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePickerStyled.StyledWrapperStaticDatePicker
          $isMobile={isMobile}
          $enteringDate={enteringDate}
        >
          <StaticDatePicker
            onChange={() => {}}
            displayStaticWrapperAs='desktop'
            label='Basic example'
            value={new Date(String(dayjs(!enteringDate ? startDate : finishDate)))}
            renderInput={params => <TextField {...params} />}
            renderDay={renderDayInPicker}
            onMonthChange={onMonthChange}
            onYearChange={onYearChange}
            autoFocus={true}
          />
        </DatePickerStyled.StyledWrapperStaticDatePicker>
      </LocalizationProvider>
    )
  }
)
