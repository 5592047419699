import { useAppSelector } from '@hooks/redux'
import { selectedFilters as filters, selectedFiltersLength } from '@state/filters'
import {
  ClearAllFilters,
  FilterAsideButton,
  SetParamsOfFilter,
  ViewSelectedFilters,
} from '@components/shared/FilterAside'
import { StyledFilterBox } from '@styles'
import {
  APIS,
  ASSIGNEES,
  EXCIPIENTS,
  EXPIRATION_DATE,
  SAMPLE_STATUS,
  TASK_RESULT,
  TASK_STATUS,
} from '@components/shared/FilterAside/constants'

export const TabFilters: React.FC<{ isPageHasSavedFilters: boolean }> = ({
  isPageHasSavedFilters,
}) => {
  const {
    appliedApisSelectedFilters,
    appliedExcipientsSelectedFilters,
    appliedAssigneesSelectedFilters,
    appliedTaskStatusSelectedFilters,
    appliedTaskResultSelectedFilters,
    appliedExpirationDateSelectedFilters,
    appliedSampleStatusSelectedFilters,
  } = useAppSelector(filters)

  const allFiltersLength = useAppSelector(selectedFiltersLength)
  const isShouldShowClearAll = Boolean(allFiltersLength)

  return (
    <>
      <SetParamsOfFilter
        isPageHasSavedFilters={isPageHasSavedFilters}
        appliedApisSelectedFilters={appliedApisSelectedFilters}
        appliedExcipientsSelectedFilters={appliedExcipientsSelectedFilters}
        appliedAssigneesSelectedFilters={appliedAssigneesSelectedFilters}
        appliedTaskResultSelectedFilters={appliedTaskResultSelectedFilters}
        appliedTaskStatusSelectedFilters={appliedTaskStatusSelectedFilters}
        appliedExpirationDateSelectedFilters={appliedExpirationDateSelectedFilters}
        appliedSampleStatusSelectedFilters={appliedSampleStatusSelectedFilters}
      />
      <StyledFilterBox>
        <FilterAsideButton />

        {!!appliedApisSelectedFilters.length && <ViewSelectedFilters type={APIS} />}
        {!!appliedExcipientsSelectedFilters.length && <ViewSelectedFilters type={EXCIPIENTS} />}
        {!!appliedTaskStatusSelectedFilters.length && <ViewSelectedFilters type={TASK_STATUS} />}
        {!!appliedTaskResultSelectedFilters.length && <ViewSelectedFilters type={TASK_RESULT} />}
        {!!appliedAssigneesSelectedFilters.length && <ViewSelectedFilters type={ASSIGNEES} />}
        {!!appliedExpirationDateSelectedFilters.length && (
          <ViewSelectedFilters type={EXPIRATION_DATE} />
        )}
        {!!appliedSampleStatusSelectedFilters.length && (
          <ViewSelectedFilters type={SAMPLE_STATUS} />
        )}

        {isShouldShowClearAll && <ClearAllFilters />}
      </StyledFilterBox>
    </>
  )
}
