import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { INotification } from '@models/common/app'
import { IAttachments, IAttachmentWithThumbnail } from '@models/attachments'
import { IMainApis } from '@models/samples'

type AppFlags =
  | 'isChangePasswordFailed'
  | 'isChangePasswordSuccessfully'
  | 'isPasswordRecoveryTokenInvalid'
  | 'isPasswordRecoveryEmailNotSent'
  | 'isLoginLockout'
  | 'isAccountVerificationErrorModalOpen'
  | 'isPasswordChangedModalOpen'
  | 'isPasswordErrorModalOpen'
  | 'isSendingErrorModalOpen'
  | 'isLinkExpiredModalOpen'
  | 'isAddingAttachment'
export interface IStorageAttachments {
  cellValue: IAttachments
  sampleId: string
  isApplyNewAttachments: boolean
}

export type HrCreateSampleAttachmentTypes = {
  attachments?: IAttachmentWithThumbnail[] | null
  exitAttachModal?: 'save' | 'cancel' | 'pending' | 'delete-in-modal'
}

export interface AppState {
  sampleCreateIds: string[]
  notifications: INotification[]
  flags: Record<AppFlags, boolean>
  suggestionSampleSearching: string
  isTouchedFields: boolean
  copySampleId: string
  addSampleButtonPushed: number
  createSampleAttachments: IStorageAttachments
  editSampleApisValues: IMainApis[]
  editFundingList: any
  hrCreateSampleAttachments: {
    attachments: IAttachmentWithThumbnail[] | null
    exitAttachModal: 'save' | 'cancel' | 'pending' | 'delete-in-modal'
  }
  isLoadingCreatePackage: boolean
}

const initialState: AppState = {
  sampleCreateIds: [],
  editSampleApisValues: [],
  editFundingList: [],
  notifications: [],
  flags: {
    isChangePasswordFailed: false,
    isChangePasswordSuccessfully: false,
    isPasswordRecoveryTokenInvalid: false,
    isPasswordRecoveryEmailNotSent: false,
    isLoginLockout: false,
    isAccountVerificationErrorModalOpen: false,
    isPasswordChangedModalOpen: false,
    isPasswordErrorModalOpen: false,
    isSendingErrorModalOpen: false,
    isLinkExpiredModalOpen: false,
    isAddingAttachment: false,
  },
  suggestionSampleSearching: '',
  isTouchedFields: false,
  copySampleId: '',
  addSampleButtonPushed: 0,
  createSampleAttachments: {
    cellValue: [],
    sampleId: '',
    isApplyNewAttachments: false,
  },
  hrCreateSampleAttachments: { attachments: null, exitAttachModal: 'cancel' },
  isLoadingCreatePackage: false,
}

export const userSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    reset: state => {
      state.notifications = []
    },
    showNotification: (state, { payload }: PayloadAction<INotification>) => {
      state.notifications.push(payload)
    },
    hideNotification: state => {
      state.notifications.shift()
    },
    toggleFlag: (state, { payload }: PayloadAction<AppFlags>) => {
      state.flags[payload] = !state.flags[payload]
    },
    setSearchSuggestion: (state, { payload }: PayloadAction<string>) => {
      state.suggestionSampleSearching = payload.toUpperCase()
    },
    setEditSampleApisValues: (state, { payload }: PayloadAction<IMainApis[]>) => {
      state.editSampleApisValues = payload
    },
    setEditFundingList: (state, { payload }: PayloadAction<any | null>) => {
      state.editFundingList = payload
    },
    // Sample Creation
    setSampleCreateId: (state, { payload }: PayloadAction<string>) => {
      state.sampleCreateIds = [payload, ...state.sampleCreateIds]
    },
    setCopySampleCreateId: (state, { payload }: PayloadAction<string[]>) => {
      state.sampleCreateIds = [...payload, ...state.sampleCreateIds]
    },
    deleteSampleCreateId: (state, { payload }: PayloadAction<string>) => {
      state.sampleCreateIds = state.sampleCreateIds.filter(id => id !== payload)
    },
    setCleanStoreSampleCreateId: (state, { payload }: PayloadAction<string>) => {
      state.sampleCreateIds = [payload]
    },
    setIsTouchedFields: (state, { payload }: PayloadAction<boolean>) => {
      state.isTouchedFields = payload
    },
    seCopySampleId: (state, { payload }: PayloadAction<string>) => {
      state.copySampleId = payload
    },
    setAddSampleButtonPushed: (state, { payload }: PayloadAction<number>) => {
      state.addSampleButtonPushed = payload
    },
    setCreateSampleAttachments: (state, { payload }: PayloadAction<IStorageAttachments>) => {
      if (payload.isApplyNewAttachments) {
        state.createSampleAttachments = {
          ...state.createSampleAttachments,
          cellValue: [...state.createSampleAttachments.cellValue, ...payload.cellValue],
        }
      } else {
        state.createSampleAttachments = payload
      }
    },
    removeCreateSampleAttachments: (state, { payload }: PayloadAction<number>) => {
      const updatedAttaches = state.createSampleAttachments?.cellValue.filter(
        item => item.id !== payload
      )
      state.createSampleAttachments.cellValue = updatedAttaches
    },
    setHrCreateSampleAttachments: (
      state,
      { payload }: PayloadAction<HrCreateSampleAttachmentTypes | null>
    ) => {
      if (payload) {
        if (typeof payload.attachments !== 'undefined') {
          state.hrCreateSampleAttachments.attachments = payload.attachments
        }
        if (payload.exitAttachModal) {
          state.hrCreateSampleAttachments.exitAttachModal = payload.exitAttachModal
        }
      } else {
        state.hrCreateSampleAttachments.attachments = null
        state.hrCreateSampleAttachments.exitAttachModal = 'cancel'
      }
    },
    setIsLoadingCreatePackage: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingCreatePackage = payload
    },
    // --------------
  },
})

export const {
  reducer: appReducer,
  actions: {
    reset: resetAppState,
    showNotification,
    hideNotification,
    toggleFlag,
    setSearchSuggestion,
    setSampleCreateId,
    setCopySampleCreateId,
    deleteSampleCreateId,
    setCleanStoreSampleCreateId,
    setIsTouchedFields,
    seCopySampleId,
    setAddSampleButtonPushed,
    removeCreateSampleAttachments,
    setCreateSampleAttachments,
    setEditSampleApisValues,
    setHrCreateSampleAttachments,
    setIsLoadingCreatePackage,
    setEditFundingList,
  },
} = userSlice
