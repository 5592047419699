import React from 'react'
import { TabList } from '@mui/lab'

import { yourWorkspaceTabs } from '@constants/mappings'
import { StyledTab, StyledTabBox } from '@styles'
import { IYourWorkspaceTabValue } from '@models/yourWorkspace'

interface TabButtonsProps {
  onChangeTab: (tab: IYourWorkspaceTabValue) => void
  onChangeQuery: (newValue: string | null | undefined) => void
}

export const TabButtons: React.FC<TabButtonsProps> = props => {
  const { onChangeTab, onChangeQuery } = props

  const changeTabHandler = (e: React.SyntheticEvent, tab: IYourWorkspaceTabValue) => {
    onChangeTab(tab)
    onChangeQuery(tab)
  }

  return (
    <StyledTabBox mr='10px'>
      <TabList onChange={changeTabHandler}>
        {yourWorkspaceTabs.map(tab => (
          <StyledTab key={tab.value} {...tab} iconPosition='end' />
        ))}
      </TabList>
    </StyledTabBox>
  )
}
