import React from 'react'
import { FormControlLabelProps, RadioGroup, useRadioGroup } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { StyledRadio } from '@styles'

import { StyledFormControl, StyledFormControlLabel } from '../styles'

interface Props {
  setIsCheckedValue: React.Dispatch<React.SetStateAction<boolean>>
}

export const FTSTestResult: React.FC<Props> = ({ setIsCheckedValue }) => {
  const { register } = useFormContext<Record<string, string>>()

  const results = [
    { value: 'positive', label: 'Positive' },
    { value: 'negative', label: 'Negative' },
    { value: 'invalid', label: 'Inconclusive' },
  ]

  const MyFormControlLabel = (props: FormControlLabelProps) => {
    const radioGroup = useRadioGroup()
    let checked = false

    if (radioGroup) {
      checked = radioGroup.value === props.value
      setIsCheckedValue(!!radioGroup.value)
    }

    return <StyledFormControlLabel {...register('result')} checked={checked} {...props} />
  }

  return (
    <StyledFormControl>
      <RadioGroup name='use-radio-group'>
        {results.map(({ value, label }) => (
          <MyFormControlLabel key={value} value={value} control={<StyledRadio />} label={label} />
        ))}
      </RadioGroup>
    </StyledFormControl>
  )
}
