import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useUpdateTest } from '@hooks/queries'
import { Button, Modal } from '@components/shared'
import { ButtonBox, SubText } from '@styles'
import { TandemMS, VISUAL_GROUP, SCREENING_GROUP } from '@models/tests'
import { IHrTaskLab } from '@models/tasks'
import { useAppSelector } from '@hooks/redux'
import {
  isAssistantAddedNewTandemMSTest,
  isResultRecognizedTandemMSCheckboxState,
  setIsAddedNewTandemMSTest,
  tandemMSData,
} from '@state/test'
import { useAddAnotherMultipleTests } from '@hooks/queries/useAddTestsForMultipleSamples'
import { completeTest } from '@state/tasks'
import { protectedRoutes } from '@configs/routes.config'
import { checkFTSTypeOfTest } from '@helpers/ui/checkFTSTypeOfTest'

import { StyledWrapper, StyledTitle } from './styles'
import { FTSTestResult } from './FTSTestResult'
import { TandemMSResult } from './TandemMSResult'
import { finishTandemMSTestHelper } from '../../../Attachments/components/FinishTest/helpers'

interface TestResultsModalProps {
  onClose: () => void
  editTestResult?: boolean
  task: IHrTaskLab
}

export const TestResultsModal: React.FC<TestResultsModalProps> = props => {
  const { onClose, task, editTestResult } = props

  const methods = useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const isMobile = useMediaQuery('(max-width:767px)')

  const testGroupName = task.test_type.group.name
  const testName = task.test_type.name
  const taskId = task.id
  const sampleId = task.sample_id

  const tandemMSData2Send = useAppSelector(tandemMSData)
  const isAddedNewTandemMSTest = useAppSelector(isAssistantAddedNewTandemMSTest)
  const isResultNotRecognized = useAppSelector(isResultRecognizedTandemMSCheckboxState)

  const addNewTandemMSTestsM = useAddAnotherMultipleTests(sampleId, undefined, taskId)

  const isScreeningTest = testGroupName === SCREENING_GROUP || testGroupName === VISUAL_GROUP
  const [isCheckedValue, setIsCheckedValue] = useState(false)
  const [finishTandemMSMethodId, setFinishTandemMSMethodId] = useState<number | undefined>()
  const isTandemMSTest = testName === TandemMS
  const updateTestM = useUpdateTest(editTestResult)

  const redirectToNewTestPage = (id: number) => {
    history.replace({
      pathname: `${protectedRoutes.test.path
        .replace(':testType', task.test_type.group.name.toLowerCase())
        .replace(':taskId', String(id))}`,
      state: [
        {
          path: protectedRoutes.tasks.path,
          url: protectedRoutes.tasks.path,
          search: `?tasks_type=awaiting`,
          title: `Awaiting tasks`,
          testTitle: `Tandem MS test`,
        },
      ],
    })

    dispatch(setIsAddedNewTandemMSTest(false))
  }

  const onSubmit = async (data: { result: string }) => {
    if (isTandemMSTest) {
      const tandemMSMethodId = task.test_type?.methods?.find(i => i.name === data.result)?.id
      if (tandemMSMethodId && !isAddedNewTandemMSTest)
        // * user be able to update or select a Tandem MS method before add result to test
        updateTestM.mutate({ taskId, data: { method: tandemMSMethodId } })
      else {
        // * when the user has selected a new Tandem MS - the previous Tandem MS has finished
        if (tandemMSData2Send) {
          const data = finishTandemMSTestHelper(tandemMSData2Send, isResultNotRecognized)
          updateTestM.mutate(
            { taskId, data },
            {
              onSuccess: () => {
                setFinishTandemMSMethodId(tandemMSMethodId)
              },
            }
          )

          dispatch(completeTest(taskId))
        }
      }
    } else updateTestM.mutate({ taskId, data })
  }

  useEffect(() => {
    // * user be able immediately create a new Tandem MS test with method
    // * after the previous Tandem MS test finished successfully
    if (finishTandemMSMethodId) {
      addNewTandemMSTestsM.mutate(
        {
          test_types: [{ methods: [finishTandemMSMethodId], id: task.test_type.id }],
          samples: [sampleId],
        },
        {
          onSuccess: data => {
            redirectToNewTestPage(data[0].id)
          },
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishTandemMSMethodId, sampleId, task.test_type.id])

  const renderModalTitle = (() => {
    if (isScreeningTest) return 'Please select result of test'
    if (isTandemMSTest && task.step < 2) {
      if (task.test_type_method?.name) return 'Change  Tandem MS method'
      else if (!task.test_type_method) return 'Select Tandem MS method'
    } else return 'Add another Tandem MS method'
  })()

  const submitButtonText = (() => {
    if (isTandemMSTest && task.step < 2) {
      return 'Save and add result'
    } else if (isAddedNewTandemMSTest) return 'Add method'
    else return 'Add result'
  })()

  return (
    <Modal open onClose={onClose} className='testing-modal'>
      <StyledWrapper $isMobile={isMobile}>
        <StyledTitle
          $isMobile={isMobile}
          variant={isMobile ? 'subtitle1' : 'h3'}
          position='relative'
        >
          {renderModalTitle}

          {isTandemMSTest && task.step > 2 && (
            <SubText sx={{ display: 'block', marginTop: '8px' }}>
              New Tandem MS method will be added to Awaiting list. Current test will be finished
            </SubText>
          )}
        </StyledTitle>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {checkFTSTypeOfTest(testName) && (
              <FTSTestResult setIsCheckedValue={setIsCheckedValue} />
            )}

            {isTandemMSTest && (
              <TandemMSResult
                setIsCheckedValue={setIsCheckedValue}
                tandemMSMethods={task.test_type?.methods}
                selectedTandemMSMethod={task.test_type_method}
                isAddedNewTandemMSTest={isAddedNewTandemMSTest}
              />
            )}

            <ButtonBox $isMobile={isMobile}>
              <Button
                type='button'
                variant='outlined'
                color='secondary'
                bgColor='#fff'
                width={isMobile ? '100%' : '202px'}
                height='48px'
                mt={isMobile ? '16px' : '32px'}
                mr={isMobile ? '' : '16px'}
                sx={{ order: isMobile ? 2 : 1 }}
                fz={isMobile ? 16 : 14}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='contained'
                width={isMobile ? '100%' : '202px'}
                height='48px'
                mt={isMobile ? '16px' : '32px'}
                sx={{ order: isMobile ? 1 : 2 }}
                fz={isMobile ? 16 : 14}
                loading={updateTestM.isLoading}
                disabled={!isCheckedValue}
              >
                {submitButtonText}
              </Button>
            </ButtonBox>
          </form>
        </FormProvider>
      </StyledWrapper>
    </Modal>
  )
}
