import { Box } from '@mui/material'

import { selectUser } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { UserAvatar } from '@components/shared'

import { AvatarBox, Name, Role } from './styles'

export const BriefInfo = () => {
  const { first_name, role, sex } = useAppSelector(selectUser)

  return (
    <AvatarBox>
      <Box mb='33px'>
        <UserAvatar width='120px' height='120px' sex={sex.key} role={role.key} />
      </Box>
      <Name>{first_name}</Name>
      <Role label={role.value} />
    </AvatarBox>
  )
}
