import * as React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material'

import KeyboardArrowUpIcon from '@assets/icons/sort-up.svg'
import KeyboardArrowDownIcon from '@assets/icons/sort-down.svg'
import { StyledCardBox } from '@styles'
import { ITaskLab, ITasksTabValue } from '@models/tasks'
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { useToggle } from '@hooks/common'
import { TestResultsModal } from '@components/modules/Tests/components/contents/TestContent/components/Test/components/steps/Testing/components'
import { TempSensitiveToxicityIcons } from '@components/shared'

import { ActionCell } from '../../Table/TasksRows/TasksCells/ActionCell'
import { Result } from '../../Table/TasksRows/TasksCells/Result'

interface MobileCellProps {
  task: ITaskLab
  isMobile: boolean
  contentType: ITasksTabValue
  setOpenCard: () => void
  openCard: boolean
}

export const MobileCell: React.FC<MobileCellProps> = props => {
  const { isMobile, task, contentType, openCard, setOpenCard } = props
  const theme = useTheme()

  const isAwaitingContentType = contentType === 'awaiting'
  const [isResultsModalOpen, setIsResultsModalOpen] = useToggle()

  return (
    <StyledCardBox onClick={setOpenCard}>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ width: '24px', height: '24px' }}>
            {openCard ? (
              <img src={KeyboardArrowUpIcon} alt='up' />
            ) : (
              <img src={KeyboardArrowDownIcon} alt='down' />
            )}
          </IconButton>
          <Box display='inline-flex' fontWeight={700} ml='8px' flexWrap='wrap' alignItems='center'>
            <Box mb='8px' display='flex'>
              Task ID
              <span
                style={{
                  display: 'inline-block',
                  fontWeight: 'normal',
                  marginLeft: '8px',
                }}
              >
                {task.id}
              </span>
            </Box>
            <Box mb='8px' mr='8px'>
              <TempSensitiveToxicityIcons
                toxicity={task.toxicity_level}
                minTempSensitive={task.min_temperature}
                maxTempSensitive={task.max_temperature}
                temperatureMeasurementFormat={task.temperature_measurement_format?.key}
                miniIcons
              />
            </Box>
          </Box>
        </Box>

        {isAwaitingContentType ? (
          <></>
        ) : (
          <Box ml='34px'>
            <ActionCell
              isMobile={isMobile}
              isAwaitingContentType={isAwaitingContentType}
              contentType={contentType}
              task={task}
            />
          </Box>
        )}
      </Box>

      {isAwaitingContentType ? (
        <ActionCell
          isMobile={isMobile}
          isAwaitingContentType={isAwaitingContentType}
          contentType={contentType}
          task={task}
        />
      ) : (
        <Box sx={{ flexDirection: 'row', display: 'flex' }}>
          <Result task={task} isMobile={isMobile} />
          {task.is_able_to_edit_task && (
            <IconButton onClick={setIsResultsModalOpen}>
              <EditIcon
                fill={theme.palette.primary.main}
                style={{ width: '16px', height: '16px' }}
              />
            </IconButton>
          )}
        </Box>
      )}

      {task && isResultsModalOpen && (
        <TestResultsModal
          editTestResult
          testGroupName={task.test_type.group.name}
          taskId={task.id}
          taskApis={task.main_apis}
          onClose={setIsResultsModalOpen}
          resultBtnName={task.available_result}
        />
      )}
    </StyledCardBox>
  )
}
