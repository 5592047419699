import React from 'react'
import { ListItem, ListItemText } from '@mui/material'

import { ISampleTask } from '@models/samples'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_APIS_LENGTH } from '@constants/common'
import { Tooltip } from '@components/shared'

import { StyledList } from '../../ExpandedTableBody/styles'

interface ApiListProps {
  task: ISampleTask
}

export const ApiList: React.FC<ApiListProps> = ({ task }) => {
  if (task.test_type.is_other) return null

  return (
    <>
      <StyledList>
        {task.main_apis?.map(api => {
          const apiResult = api.percentage && `${parseFloat(api.percentage)}%`
          const checkLength = api.name.length > TRUNCATED_TEXT_APIS_LENGTH
          const apiText = checkLength ? stringSlice(api.name, TRUNCATED_TEXT_APIS_LENGTH) : api.name

          return (
            <ListItem key={api.id}>
              <Tooltip
                isPureTooltip
                title={api.name || ''}
                shouldBeHidden={!checkLength}
                positionLeft={30}
              >
                <ListItemText primary={apiText || '-'} />
              </Tooltip>

              <ListItemText secondary={apiResult || '-'} />
            </ListItem>
          )
        })}
      </StyledList>
    </>
  )
}
