import { Control, UseFormClearErrors, Validate } from 'react-hook-form'
import { isAfter, isToday } from 'date-fns'

import { ISampleOptionsContext } from '@context'
import * as sampleConstants from '@constants/samples'
import * as Cell from '@components/shared/sampleFields/createSampleCells'
import { MAIN_APIS_REQUIRED, MAX_CHARACTERS, REQUIRED_FIELD } from '@constants/validation'
import { IInitialSample } from '@state/sampleCreate/sampleCreateState.types'

import * as NewCells from '../components/TableCells'

export const bodyCellsData = (
  sampleOptions: ISampleOptionsContext,
  sampleId: string,
  manufactureDate: Date,
  expirationDate: Date,
  storeSample: IInitialSample | undefined,
  control: Control,
  clearErrors: UseFormClearErrors<any>,
  fundingOfProjects?: any
) => {
  return [
    {
      name: `${sampleId}_${sampleConstants.DRUG_CLASS}`,
      initialVal: storeSample?.[sampleConstants.DRUG_CLASS] || { val: null, error: '' },
      cellName: sampleConstants.DRUG_CLASS,
      sampleId,
      width: 286,
      isCopy: storeSample?.isCopy || false,
      field: NewCells.DrugClasses,
      rules: { required: REQUIRED_FIELD },
      control,
    },
    {
      name: `${sampleId}_${sampleConstants.DOSAGE}`,
      cellName: sampleConstants.DOSAGE,
      dropdownCellName: sampleConstants.DOSAGE_TYPE,
      initialVal: storeSample?.[sampleConstants.DOSAGE] || { val: '', error: '' },
      dropdownInitialVal: storeSample?.[sampleConstants.DOSAGE_TYPE] || { val: 1, error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      options: [...sampleOptions.dosageType].reverse(),
      rules: {
        required: REQUIRED_FIELD,
        maxLength: {
          value: 13,
          message: 'Max 13 characters',
        },
        min: {
          value: 0,
          message: `The value cannot be negative`,
        },
        validate: {
          digit: (v: string) => {
            return !isNaN(Number(v)) || 'Format must be either 1 or 1.01'
          },
          greaterThanMinValue: (v: string) => {
            return (
              !(v?.split('.')[1]?.length > 6) ||
              'Ensure this value is greater than or equal to 0.000001'
            )
          },
          dosageIntFormat: (v: string) => {
            return (
              /^\d{1,6}$|^\d{1,6}\.\d{1,6}$/.test(v) ||
              'Maximum number of characters 6 after dot and 6 before dot'
            )
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
      control,
      field: NewCells.Dosage,
    },
    {
      name: `${sampleId}_${sampleConstants.UNITS}`,
      cellName: sampleConstants.UNITS,
      dropdownCellName: sampleConstants.UNIT_TYPE,
      initialVal: storeSample?.[sampleConstants.UNITS] || { val: null, error: '' },
      dropdownInitialVal: storeSample?.[sampleConstants.UNIT_TYPE] || { val: 1, error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 324,
      options: sampleOptions.unitType,
      rules: {
        required: REQUIRED_FIELD,
        validate: {
          decimal: (v: string) => {
            return +v % 1 === 0 || !!isNaN(Number(v)) || 'Value cannot be decimal'
          },
          then1000: (v: string) => {
            return !(+v > 1000) || 'Value cannot be more than 1000'
          },
          сanNotBeZero: (v: string) => {
            return !(+v === 0) || 'Value cannot be 0'
          },
          negative: (v: string) => {
            return !(+v < 0) || "Value can't be negative"
          },
          digit: (v: string) => {
            return !isNaN(Number(v)) || 'May contain only numbers'
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
      control,
      field: NewCells.Units,
      inputPlaceholder: 'Amount',
    },
    {
      name: `${sampleId}_${sampleConstants.VALUE}`,
      cellName: sampleConstants.VALUE,
      dropdownCellName: sampleConstants.VALUE_TYPE,
      initialVal: storeSample?.[sampleConstants.VALUE] || { val: null, error: '' },
      dropdownInitialVal: storeSample?.[sampleConstants.VALUE_TYPE] || {
        val: null,
        valName: sampleConstants.DEFAULT_VALUE_TYPE_NAME,
        error: '',
      },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      options: sampleOptions.valueType,
      rules: {
        validate: {
          negative: (v: string) => {
            return !(+v < 0) || "Value can't be negative"
          },
          digit: (v: string) => {
            return !isNaN(Number(v)) || 'May contain only numbers'
          },
          min: (v: string) => {
            return !(v?.split('.')[1]?.length > 4) || 'Min 0.0001 number'
          },
          max: (v: string) => {
            return !(+v > 999999999) || 'Max 999999999 number'
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
      control,
      field: NewCells.Value,
      inputPlaceholder: 'Value per 1 unit',
    },
    {
      name: `${sampleId}_${sampleConstants.MAIN_APIS}`,
      cellName: sampleConstants.MAIN_APIS,
      initialVal: storeSample?.[sampleConstants.MAIN_APIS] || { val: [], error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      rules: { required: MAIN_APIS_REQUIRED },
      control,
      field: NewCells.Apis,
      inputPlaceholder: 'Search here...',
    },
    {
      name: `${sampleId}_${sampleConstants.TESTS}`,
      cellName: sampleConstants.TESTS,
      initialVal: storeSample?.[sampleConstants.TESTS] || { val: [], error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      field: NewCells.Tests,
      inputPlaceholder: 'Select to add...',
    },
    {
      name: `${sampleId}_${sampleConstants.EXCIPIENTS}`,
      cellName: sampleConstants.EXCIPIENTS,
      initialVal: storeSample?.[sampleConstants.EXCIPIENTS] || { val: [], error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      control,
      field: NewCells.Excipients,
      inputPlaceholder: 'Search here...',
    },
    {
      name: `${sampleId}_${sampleConstants.SAMPLE_TYPE}`,
      cellName: sampleConstants.SAMPLE_TYPE,
      initialVal: storeSample?.[sampleConstants.SAMPLE_TYPE] || { val: null, error: '' },
      sampleId,
      width: 220,
      control,
      field: NewCells.SampleType,
    },
    {
      name: `${sampleId}_${sampleConstants.TOXICITY_LEVEL}`,
      initialVal: storeSample?.[sampleConstants.TOXICITY_LEVEL] || { val: '', error: '' },
      cellName: sampleConstants.TOXICITY_LEVEL,
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      control,
      field: NewCells.Toxicity,
      clearErrors,
    },
    {
      name: `${sampleId}_${sampleConstants.TEMPERATURE_FORMAT}`,
      initialVal: storeSample?.[sampleConstants.TEMPERATURE_FORMAT] || { val: '', error: '' },
      multipleInitialValues: [
        {
          name: `${sampleId}_${sampleConstants.MAX_TEMPERATURE}`,
          cellName: sampleConstants.MAX_TEMPERATURE,
          ...(storeSample?.[sampleConstants.MAX_TEMPERATURE] || { val: '', error: '' }),
        },
        {
          name: `${sampleId}_${sampleConstants.MIN_TEMPERATURE}`,
          cellName: sampleConstants.MIN_TEMPERATURE,
          ...(storeSample?.[sampleConstants.MIN_TEMPERATURE] || { val: '', error: '' }),
        },
      ],
      cellName: sampleConstants.TEMPERATURE_FORMAT,
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 320,
      control,
      clearErrors,
      field: NewCells.StorageTemperature,
    },
    {
      name: `${sampleId}_${sampleConstants.STORAGE_NOTES}`,
      initialVal: storeSample?.[sampleConstants.STORAGE_NOTES] || { val: '', error: '' },
      cellName: sampleConstants.STORAGE_NOTES,
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      rules: {
        maxLength: {
          value: 500,
          message: MAX_CHARACTERS(500),
        },
      },
      rows: 3,
      control,
      field: NewCells.StorageNotes,
    },
    {
      name: `${sampleId}_${sampleConstants.ATTACHMENTS}`,
      initialVal: storeSample?.[sampleConstants.ATTACHMENTS] || { val: [], error: '' },
      cellName: sampleConstants.ATTACHMENTS,
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 280,
      control,
      field: NewCells.StorageDocuments,
    },
    {
      name: `${sampleId}_${sampleConstants.NOTES}`,
      cellName: sampleConstants.NOTES,
      initialVal: storeSample?.[sampleConstants.NOTES] || { val: '', error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      rules: {
        maxLength: {
          value: 150,
          message: MAX_CHARACTERS(150),
        },
        validate: {
          dangerousChars: (v: string) => {
            return /^(?![=+\-@\t\r]).*/.test(v) || 'Field starts with dangerous char.'
          },
        },
      },
      rows: 3,
      control,
      field: NewCells.CommonInput,
    },
    {
      name: `${sampleId}_${sampleConstants.LOT}`,
      cellName: sampleConstants.LOT,
      initialVal: storeSample?.[sampleConstants.LOT] || { val: '', error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      rules: {
        maxLength: {
          value: 50,
          message: 'Max 50 characters',
        },
        validate: {
          dangerousChars: (v: string) => {
            return /^(?![=+\-@\t\r]).*/.test(v) || 'Field starts with dangerous char.'
          },
        },
      },
      control,
      field: NewCells.CommonInput,
    },
    {
      name: `${sampleId}_${sampleConstants.BUYERS_NAME}`,
      cellName: sampleConstants.BUYERS_NAME,
      initialVal: storeSample?.[sampleConstants.BUYERS_NAME] || { val: '', error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      rules: {
        maxLength: {
          value: 50,
          message: 'Max 50 characters',
        },
      },
      control,
      field: NewCells.CommonInput,
    },
    {
      name: `${sampleId}_${sampleConstants.PURCHASE_DATE}`,
      cellName: sampleConstants.PURCHASE_DATE,
      initialVal: storeSample?.[sampleConstants.PURCHASE_DATE] || { val: new Date(), error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 200,
      rules: {
        validate: {
          purchaseDateLaterThanManufacture: (purchaseDate: Date) => {
            return (
              !Boolean(purchaseDate && manufactureDate) ||
              isAfter(purchaseDate, manufactureDate) ||
              'Purchase date must be later than manufacture date'
            )
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
      control,
      field: Cell.DatePicker,
      disableFuture: true,
    },
    {
      name: `${sampleId}_${sampleConstants.TOWN}`,
      cellName: sampleConstants.TOWN,
      initialVal: storeSample?.[sampleConstants.TOWN] || { val: null, error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      gpOptions: sampleOptions.towns,
      rules: {
        maxLength: {
          value: 150,
          message: 'Max 150 characters',
        },
        validate: {
          dangerousChars: (v: string) => {
            return /^(?![=+\-@\t\r]).*/.test(v) || 'Field starts with dangerous char.'
          },
        },
      },
      field: NewCells.GoggleMapPlaceField,
      control,
    },
    {
      name: `${sampleId}_${sampleConstants.PHARMACY}`,
      cellName: sampleConstants.PHARMACY,
      initialVal: storeSample?.[sampleConstants.PHARMACY] || { val: null, error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      options: sampleOptions.pharmacies,
      field: NewCells.CommonAutocomplete,
      control,
    },
    {
      name: `${sampleId}_${sampleConstants.PHARMACY_GPS}`,
      cellName: sampleConstants.PHARMACY_GPS,
      initialVal: storeSample?.[sampleConstants.PHARMACY_GPS] || { val: '', error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      rules: {
        maxLength: {
          value: 50,
          message: 'Max 50 characters',
        },
      },
      control,
      field: NewCells.CommonInput,
      inputPlaceholder: 'Type address or coordinates...',
    },
    {
      name: `${sampleId}_${sampleConstants.MANUFACTURER}`,
      field: NewCells.Manufacturer,
      isCopy: storeSample?.isCopy || false,
      cellName: sampleConstants.MANUFACTURER,
      initialVal: storeSample?.[sampleConstants.MANUFACTURER] || { val: null, error: '' },
      control,
      width: 286,
      sampleId,
    },
    {
      name: `${sampleId}_${sampleConstants.ADDRESS_OF_MANUFACTURER}`,
      cellName: sampleConstants.ADDRESS_OF_MANUFACTURER,
      initialVal: storeSample?.[sampleConstants.ADDRESS_OF_MANUFACTURER] || {
        val: null,
        error: '',
      },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 536,
      gpOptions: sampleOptions.manufacturerAddresses,
      rules: {
        maxLength: {
          value: 150,
          message: 'Max 150 characters',
        },
        validate: {
          dangerousChars: (v: string) => {
            return /^(?![=+\-@\t\r]).*/.test(v) || 'Field starts with dangerous char.'
          },
        },
      },
      control,
      field: NewCells.GoggleMapPlaceField,
    },
    {
      name: `${sampleId}_${sampleConstants.BRAND}`,
      cellName: sampleConstants.BRAND,
      initialVal: storeSample?.[sampleConstants.BRAND] || { val: null, error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      options: sampleOptions.brands,
      rules: {
        validate: {
          dangerousChars: (v: string) => {
            return /^(?![=+\-@\t\r]).*/.test(v) || 'Field starts with dangerous char.'
          },
        },
      },
      control,
      field: NewCells.CommonAutocomplete,
    },
    {
      name: `${sampleId}_${sampleConstants.MANUFACTURE_DATE}`,
      cellName: sampleConstants.MANUFACTURE_DATE,
      initialVal: storeSample?.[sampleConstants.MANUFACTURE_DATE] || { val: null, error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 210,
      rules: {
        validate: {
          manufactureDateEarlierThanExpiration: (manufactureDate: Date) => {
            return (
              !Boolean(manufactureDate && expirationDate) ||
              !(isToday(manufactureDate) && isToday(expirationDate)) ||
              'Manufacture date must be earlier than the expiration date'
            )
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
      control,
      field: Cell.DatePicker,
      disableFuture: true,
    },
    {
      name: `${sampleId}_${sampleConstants.EXPIRATION_DATE}`,
      cellName: sampleConstants.EXPIRATION_DATE,
      initialVal: storeSample?.[sampleConstants.EXPIRATION_DATE] || { val: null, error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 210,
      control,
      field: Cell.DatePicker,
      disablePast: true,
    },
    {
      name: `${sampleId}_${sampleConstants.REGULATORY_REGISTRATION}`,
      cellName: sampleConstants.REGULATORY_REGISTRATION,
      initialVal: storeSample?.[sampleConstants.REGULATORY_REGISTRATION] || { val: 1, error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 300,
      options: sampleOptions.regulatoryRegistrationsType,
      control,
      field: Cell.RadioButtons,
    },
    {
      name: `${sampleId}_${sampleConstants.COUNTRY}`,
      cellName: sampleConstants.COUNTRY,
      initialVal: storeSample?.[sampleConstants.COUNTRY] || { val: null, error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      options: sampleOptions.countries,
      control,
      field: NewCells.CommonAutocomplete,
    },
    {
      name: `${sampleId}_${sampleConstants.PROJECT}`,
      cellName: sampleConstants.PROJECT,
      initialVal: storeSample?.[sampleConstants.PROJECT] || { val: [], error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      control,
      field: NewCells.Project,
      inputPlaceholder: 'Search here...',
    },
    {
      name: `${sampleId}_${sampleConstants.FUNDING}`,
      cellName: sampleConstants.FUNDING,
      initialVal: storeSample?.[sampleConstants.FUNDING] || { val: [], error: '' },
      isCopy: storeSample?.isCopy || false,
      sampleId,
      width: 286,
      options: fundingOfProjects,
      control,
      field: NewCells.Funding,
      inputPlaceholder: 'Search here...',
    },
  ]
}
