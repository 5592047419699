import React from 'react'

import { StyledTableContactsLabRow } from '@styles'
import { ILaboratory } from '@models/common/app'

import { TableCells } from './TableCells'

interface TableBodyProps {
  laboratories?: ILaboratory[]
}

export const TableBody: React.FC<TableBodyProps> = props => {
  const { laboratories } = props

  return (
    <>
      {laboratories?.map((laboratory, idx) => {
        return (
          <StyledTableContactsLabRow key={idx}>
            <TableCells laboratory={laboratory} />
          </StyledTableContactsLabRow>
        )
      })}
    </>
  )
}
