import { Typography } from '@mui/material'

import { toxicityTooltip } from '@helpers/ui/samples'

import { StyledText } from '../styles'

export const RenderToxicityTooltipTitle: React.FC<{ toxicityValue?: string }> = ({
  toxicityValue,
}) => {
  return (
    <StyledText>
      Toxic level:
      <Typography component='span' fontWeight={700} fontSize='13px'>
        {` ${toxicityValue} `}
        <Typography component='span' fontSize='13px'>
          {toxicityTooltip(toxicityValue) ? `(${toxicityTooltip(toxicityValue)})` : ''}
        </Typography>
      </Typography>
    </StyledText>
  )
}
