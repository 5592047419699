import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useFetchTask } from '@hooks/queries'
import { FullPageError } from '@components/shared'
import { editingNotesFieldId, isEditingNotes } from '@state/samples'
import { IHrTaskLab } from '@models/tasks'

import { PageHeader, HrTaskInfo } from './components'

interface MatchParams {
  taskId: string
}

export const HrTasksDetails: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>()
  const dispatch = useDispatch()

  const fetchTaskQ = useFetchTask<IHrTaskLab>(Number(params.taskId))
  const task = fetchTaskQ.data

  const refetchTask = () => fetchTaskQ.refetch()

  React.useEffect(() => {
    if (fetchTaskQ.isFetching) {
      dispatch(isEditingNotes(true))
    } else if (fetchTaskQ.isFetched) {
      dispatch(isEditingNotes(false))
      dispatch(editingNotesFieldId(null))
    }
  }, [dispatch, fetchTaskQ.isFetched, fetchTaskQ.isFetching])

  if (fetchTaskQ.isError) {
    return (
      <FullPageError
        errorTextTitle={`Something went wrong with task details loading`}
        btnText='Refresh'
        onBtnClick={refetchTask}
        pt='40px'
      />
    )
  }

  return (
    <>
      <PageHeader taskId={params.taskId} />

      <HrTaskInfo task={task} isLoading={fetchTaskQ.isLoading || fetchTaskQ.isFetching} />
    </>
  )
}
