import React from 'react'

import { IPhysicalForm } from '@models/samples'
import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { StyledTextCell } from '@styles'

interface PhysicalFormProps {
  physical_form: IPhysicalForm | null
}

const TRUNCATED_TEXT_LENGTH = 65

export const PhysicalForm: React.FC<PhysicalFormProps> = ({ physical_form }) => {
  return (
    <>
      <Tooltip
        isPureTooltip
        title={physical_form?.name || ''}
        shouldBeHidden={!physical_form?.name || physical_form.name.length < TRUNCATED_TEXT_LENGTH}
      >
        <StyledTextCell>
          {physical_form?.name ? stringSlice(physical_form.name, TRUNCATED_TEXT_LENGTH) : '-'}
        </StyledTextCell>
      </Tooltip>
    </>
  )
}
