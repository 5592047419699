import React from 'react'

import {
  StyledTableContactsLabRow,
  StyledTableHead,
  StyledTableHeadWrapper,
  StyledTableSampleCell,
  StyledTableTitle,
} from '@styles'
import { yourWorkspaceTabsTableHeaders } from '@constants/mappings'

interface TableHeadProps {
  hideBorder: boolean
}

export const TableHead = React.forwardRef<HTMLDivElement, TableHeadProps>(({ hideBorder }, ref) => {
  return (
    <StyledTableHeadWrapper
      ref={ref}
      hideBorder={hideBorder}
      zIndex={350}
      top='155px'
      backgroundColor='#ffffff'
      marginBottom='4px'
    >
      <StyledTableHead padding='15px 0' width='100%'>
        <StyledTableContactsLabRow>
          {yourWorkspaceTabsTableHeaders.map(({ id, content }, idx) => (
            <StyledTableSampleCell hideBorder key={idx} padding='15px 0'>
              <StyledTableTitle>{content}</StyledTableTitle>
            </StyledTableSampleCell>
          ))}
        </StyledTableContactsLabRow>
      </StyledTableHead>
    </StyledTableHeadWrapper>
  )
})
