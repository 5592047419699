import React from 'react'
import { Box, useMediaQuery } from '@mui/material'

import { StyledModalTitle, SubText } from '@styles'
import { Button, Modal } from '@components/shared'
import { AutoLogoutImage } from '@components/icons'

interface LogInAfterAutoLogoutProps {
  title: string
  description: string
  isTimeOut?: boolean
  onClose: () => void
  isDisabled?: boolean
}

export const AutoLogoutModal: React.FC<LogInAfterAutoLogoutProps> = props => {
  const { onClose, title, description, isTimeOut } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  return (
    <Modal
      open
      onClose={onClose}
      padding={isTimeOut ? (isMobile ? '50px' : '64px 206px') : '50px 140px'}
    >
      <Box width={isMobile ? '239px' : isTimeOut ? '288px' : '320px'} m='auto' textAlign='center'>
        <StyledModalTitle
          variant={isMobile ? 'subtitle2' : 'h3'}
          sx={
            isTimeOut
              ? { marginBottom: isMobile ? '32px' : '70px' }
              : { marginBottom: isMobile ? '-6px' : '-14px' }
          }
        >
          {title}
        </StyledModalTitle>
        {isTimeOut && <AutoLogoutImage />}

        <Box sx={{ marginTop: isMobile ? '16px' : '24px', textAlign: 'center' }}>
          <SubText style={!isTimeOut ? { padding: '0 20px', display: 'inline-block' } : {}}>
            {description}
          </SubText>
        </Box>
        <Button
          variant='contained'
          width={isMobile ? '100%' : '205px'}
          mt={isMobile ? '24px' : '40px'}
          onClick={onClose}
        >
          Got it
        </Button>
      </Box>
    </Modal>
  )
}
