import { http } from '@services/http'
import {
  IPaddAppLogIn,
  IUpdatedTaskNote,
  UpdateResultTypes,
  UpdateTandemResultTypes,
  UpdateIdPADResultTypes,
} from '@models/tasks'
import { IChoice, ISearchSuggestionsData } from '@models/common/app'

export const fetchTask = <T>(taskId: number) => {
  try {
    return http.get<T>(`/tasks/${taskId}/`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateTask = async ({
  taskId,
  fields,
}: {
  taskId: number
  fields: UpdateResultTypes | UpdateTandemResultTypes | UpdateIdPADResultTypes
}) => {
  try {
    return http.patch(`/tasks/${taskId}/`, fields)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateTaskNotes = async ({ taskId, notes: taskNotes }: IUpdatedTaskNote) => {
  try {
    return http.patch(`/tasks/${taskId}/task-notes/`, { notes: taskNotes })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchLogIntoPadReaderApp = async () => {
  try {
    return http.get<IPaddAppLogIn>(`/o/qr/`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const searchSuggestionsTasks = ({ queryKey, pageParam }: any) => {
  try {
    return http.get<ISearchSuggestionsData>(
      `/tasks/get_sample_specific_id_suggestions/?suggestion_start=${queryKey[0]}${
        pageParam ? '&page=' + pageParam : ''
      }${queryKey[1] !== 'awaiting' ? '&completed=true' : ''}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

// * [HR
export const fetchClinicallyMeaningful = () => {
  try {
    return http.get<IChoice[]>('/choices/clinically_meaningful/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchConcentrationType = () => {
  try {
    return http.get<IChoice[]>('/choices/concentration_type/')
  } catch (error) {
    return Promise.reject(error)
  }
}

// * HR]
