import { useAppSelector } from '@hooks/redux'
import { selectPackagesState } from '@state/packages'
import { PackageContentType } from '@models/packages'
import { StyledActionsStickyBox, StyledFilterBox } from '@styles'
import { selectedFilters as filters, selectedFiltersLength } from '@state/filters'
import {
  ClearAllFilters,
  FilterAsideButton,
  SetParamsOfFilter,
  ViewSelectedFilters,
} from '@components/shared/FilterAside'
import {
  DELIVERY_STATUS,
  LABORATORIES_FILTER,
  LAST_UPDATES,
} from '@components/shared/FilterAside/constants'

import { PackageActions } from '../actions'

interface PackageFiltersProps {
  contentType: PackageContentType
  isPageHasSavedFilters: boolean
}

export const PackageFilters: React.FC<PackageFiltersProps> = props => {
  const { contentType, isPageHasSavedFilters } = props

  const { selectedPackages } = useAppSelector(selectPackagesState)
  const {
    appliedLaboratoriesSelectedFilters,
    appliedDeliveryStatusSelectedFilters,
    appliedLastUpdatesSelectedFilters,
  } = useAppSelector(filters)
  const allFiltersLength = useAppSelector(selectedFiltersLength)
  const isShouldShowClearAll = Boolean(allFiltersLength)

  const hasSelectedPackages = !!selectedPackages.length && contentType === 'default'

  return (
    <>
      <SetParamsOfFilter
        packageContentType={contentType}
        isPageHasSavedFilters={isPageHasSavedFilters}
        appliedLaboratoriesSelectedFilters={appliedLaboratoriesSelectedFilters}
        appliedDeliveryStatusSelectedFilters={appliedDeliveryStatusSelectedFilters}
        appliedLastUpdatesSelectedFilters={appliedLastUpdatesSelectedFilters}
      />

      <StyledActionsStickyBox>
        {hasSelectedPackages ? (
          <PackageActions />
        ) : (
          <StyledFilterBox>
            <FilterAsideButton />

            {!!appliedLaboratoriesSelectedFilters.length && (
              <ViewSelectedFilters type={LABORATORIES_FILTER} typeOfPackagesTab={contentType} />
            )}

            {!!appliedDeliveryStatusSelectedFilters.length && (
              <ViewSelectedFilters type={DELIVERY_STATUS} typeOfPackagesTab={contentType} />
            )}

            {!!appliedLastUpdatesSelectedFilters.length && (
              <ViewSelectedFilters type={LAST_UPDATES} typeOfPackagesTab={contentType} />
            )}

            {isShouldShowClearAll && <ClearAllFilters />}
          </StyledFilterBox>
        )}
      </StyledActionsStickyBox>
    </>
  )
}
