import { Box, BoxProps, Icon, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

interface StyledDayItemProps extends BoxProps {
  $range?: boolean
  $startRange?: boolean
  $endRange?: boolean
}
interface StyledDayTextProps extends BoxProps {
  $selected?: boolean
  $disabled?: boolean
  $currentTodayDate?: boolean
}

interface StyledWrapperStaticDatePickerProps extends BoxProps {
  $isMobile?: boolean
  $enteringDate: number
}
interface StyledDesktopDatePickerWrapperProps extends BoxProps {
  $enteringDate: boolean
}

export const StyledDateFieldWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 16px 0 22px;
`

export const StyledDesktopDatePickerWrapper = styled<React.FC<StyledDesktopDatePickerWrapperProps>>(
  Box
)`
  background: ${({ theme }) => theme.palette.background.default};

  & .MuiOutlinedInput-notchedOutline {
    border: ${({ $enteringDate }) => ($enteringDate ? '2px solid #85d1f7' : '')};
    border-radius: 4px;
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid #85d1f7;
    border-radius: 4px;
  }
  & .MuiInputAdornment-root {
    display: none;
  }
  & .MuiInputBase-input {
    padding: 10px;
    width: 80px;
    font-size: 14px;
    font-weight: 500;
    color: #1a2233;
    line-height: 20px;
  }
  & .MuiInputBase-formControl {
    padding: 0;
  }
`

export const StyledIcon = styled(Icon)`
  width: 12px;
  margin: -8px 6px 0;
`
export const StyledWrapperStaticDatePicker = styled<React.FC<StyledWrapperStaticDatePickerProps>>(
  Box
)`
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #e1e7f3;
  border-top: 1px solid #e1e7f3;
  margin-top: 11px;
  margin-bottom: 7px;
  filter: drop-shadow(-5px -5px 15px rgba(53, 108, 151, 0.12));

  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    top: -5px;
    transform: rotate(45deg);
    left: ${({ $enteringDate, $isMobile }) =>
      $enteringDate === 0 ? ($isMobile ? '36%' : '28%') : $isMobile ? '63%' : '71%'};
  }

  & .PrivatePickersYear-yearButton {
    font-weight: 400 !important;
  }

  & .PrivatePickersSlideTransition-root {
    min-height: 210px;
  }

  & .MuiCalendarPicker-root {
    width: 240px;
    margin-left: 14px;
    margin: ${({ $isMobile }) => $isMobile && '0 auto'};
    & > div {
      position: relative;
    }
    & > div:first-child > div {
      margin-left: 20px;
      max-width: 300px;
      z-index: 999;
    }
    & div[role='presentation'] {
      position: absolute;
      left: 52.5%;
      margin: 0 !important;
      transform: translateX(-50%);
    }
  }

  & .MuiIconButton-edgeEnd {
    position: absolute;
    left: 0;
    top: 0;
  }

  div[role='main'] {
    display: none;
  }

  & .MuiIconButton-edgeStart {
    position: absolute;
    right: 0;
    top: -1px;
  }
`
export const StyledDayItem = styled<React.FC<StyledDayItemProps>>(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ $range }) => ($range ? '' : '50%')};
  border-top-left-radius: ${({ $startRange }) => ($startRange ? '50%' : '')};
  border-bottom-left-radius: ${({ $startRange }) => ($startRange ? '50%' : '')};
  border-top-right-radius: ${({ $endRange }) => ($endRange ? '50%' : '')};
  border-bottom-right-radius: ${({ $endRange }) => ($endRange ? '50%' : '')};

  background-color: ${({ $range }) => ($range ? '#D6F5FD' : 'transparent')};
`

export const StyledDayText = styled<React.FC<StyledDayTextProps>>(Box)`
  font-size: 16px;
  line-height: 19px;
  color: ${({ $selected, $disabled }) =>
    $selected ? '#FFFFFF' : $disabled ? '#ADB4C2' : '#0f1934'};
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.palette.primary.main : 'transparent'};
  border-radius: 50%;
  border: ${({ $currentTodayDate }) => ($currentTodayDate ? '1px solid #adb4c2' : '')};
  box-sizing: border-box;

  width: 100%;
  height: 100%;

  font-size: 16px;
  line-height: 19px;
  width: 33.5px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
`

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 16px;
  line-height: 19px;
  margin-left: -6px;
  cursor: pointer;
`
