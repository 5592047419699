import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { ITaskLab } from '@models/tasks'
import { ITestData } from '@models/tests'
import { updateTestCancelManualWay } from '@api/tests'
import { TASKS } from '@constants/queries'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useUpdateManualTest = () => {
  const client = useQueryClient()
  const dispatch = useDispatch()

  const mutation = useMutation<ITaskLab, IError, ITestData>(updateTestCancelManualWay, {
    onSuccess: (_, { taskId }) => {
      client.invalidateQueries([TASKS, taskId])
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
