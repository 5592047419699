import React from 'react'

import { IHRSamples, ISamples } from '@models/samples'

import { StyledTableHeadWrapper } from './styles'
import { SamplesToggler } from './SamplesToggler'
import { TableHeadTitles } from './TableHeadTitles'
import { StyledTableRow } from '../SampleRows/styles'

interface TableHeadProps {
  samples: ISamples | IHRSamples
  isVerifyTenant: boolean
}

export const TableHead = React.forwardRef<HTMLDivElement, TableHeadProps>(
  ({ samples, isVerifyTenant }, ref) => {
    return (
      <StyledTableHeadWrapper ref={ref}>
        <StyledTableRow isVerifyTenant={isVerifyTenant}>
          <SamplesToggler samples={samples} />

          <TableHeadTitles isVerifyTenant={isVerifyTenant} />
        </StyledTableRow>
      </StyledTableHeadWrapper>
    )
  }
)
