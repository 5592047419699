import { StandardTextFieldProps } from '@mui/material'
import React from 'react'

import openedLock from '@assets/icons/opened-lock.svg'
import lockedLock from '@assets/icons/locked-lock.svg'
import { useAppDispatch } from '@hooks/redux'
import { Tooltip } from '@components/shared'
import { changeUnitValue, lockUnit, normalizeUnitValue } from '@state/samples'
import { ISplitUnit } from '@models/samples'
import { useToggle } from '@hooks/common'

import { InputContainer, StyledIconButton, StyledInput } from './styles'

interface UnitProps extends StandardTextFieldProps {
  unit: ISplitUnit
  idx: number
  subSamples: number
  totalUnits: number
  maxLimitPerUnit: number
  globalErrorStatus: boolean
  globalRecountStatus: boolean
}

export const Unit: React.FC<UnitProps> = React.memo(
  ({
    idx,
    unit: { value: uncontrolledValue, blockedValue },
    subSamples,
    totalUnits,
    maxLimitPerUnit,
    globalErrorStatus,
    globalRecountStatus,
    ...props
  }) => {
    const [unitIsHovered, setUnitIsHovered] = React.useState(false)
    const [errorStatus, setErrorStatus] = React.useState(false)
    const [inputIsFocused, setInputIsFocused] = React.useState(false)
    const [value, setValue] = React.useState(String(uncontrolledValue))
    const [toggleValidationNotification, toggleValidationNotificationHandler] = useToggle()
    const [maxLimitPerUnitPrev, setMaxLimitPerUnitPrev] = React.useState<number>()

    const dispatch = useAppDispatch()

    React.useEffect(() => {
      setValue(String(uncontrolledValue))
    }, [uncontrolledValue])

    React.useEffect(() => {
      setErrorStatus(globalErrorStatus)
    }, [globalErrorStatus])

    const lockOnClick = () => {
      blockedValue && setUnitIsHovered(false)
      dispatch(lockUnit(idx))
    }

    const unitsOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      if (+target.value < 0) setErrorStatus(true)
      else if (+target.value > 1000) setErrorStatus(true)
      else if (+target.value === 0) setErrorStatus(true)
      else setErrorStatus(globalErrorStatus || false)
      dispatch(changeUnitValue({ idx, value: +target.value }))
    }

    const unitsOnFocus = () => {
      setInputIsFocused(true)
      setMaxLimitPerUnitPrev(maxLimitPerUnit)
    }

    const unitsOnBlur = () => {
      setInputIsFocused(false)
      dispatch(normalizeUnitValue(idx))
      if (!(uncontrolledValue > 0 && uncontrolledValue <= maxLimitPerUnit))
        toggleValidationNotificationHandler()
    }

    return (
      <Tooltip
        title={
          inputIsFocused
            ? `The value must be between 1 and ${maxLimitPerUnit}`
            : blockedValue
            ? 'You can unlock this value'
            : 'You can lock this value and recount the rest'
        }
      >
        <InputContainer
          errorStatus={errorStatus}
          lockedValue={!!blockedValue}
          recountStatus={globalRecountStatus}
          onMouseEnter={() => setUnitIsHovered(true)}
          onMouseLeave={() => setUnitIsHovered(false)}
        >
          <Tooltip title='You can lock this value and recount the rest'>
            <>
              <StyledIconButton disableFocusRipple={true} onClick={lockOnClick}>
                {!blockedValue && unitIsHovered && <img src={openedLock} alt='locked lock' />}
                {blockedValue && <img src={lockedLock} alt='locked lock' />}
              </StyledIconButton>
              <Tooltip
                toggleValidationNotification={toggleValidationNotification}
                toggleValidationNotificationHandler={toggleValidationNotificationHandler}
                title={`The value must be between 1 and ${
                  !inputIsFocused ? maxLimitPerUnitPrev : maxLimitPerUnit
                }`}
              >
                <StyledInput
                  type='number'
                  onChange={unitsOnChange}
                  onBlur={unitsOnBlur}
                  value={value}
                  onFocus={unitsOnFocus}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      color: errorStatus ? '#AF4645' : '',
                    },
                  }}
                  variant='standard'
                  {...props}
                  disabled={!!blockedValue}
                />
              </Tooltip>
            </>
          </Tooltip>
        </InputContainer>
      </Tooltip>
    )
  }
)
