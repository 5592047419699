import * as React from 'react'

import { PureTooltip } from '@components/ui/PureTooltip'
import { stringSlice } from '@helpers/ui/common'

import { PureChipPropTypes } from './pureChip.types'
import * as Styled from './styles'

export const PureChip: React.FC<PureChipPropTypes> = ({
  label,
  onDelete,
  isMainChip = false,
  truncatedLabelLength,
  tooltipWidth,
  isExcipientsChip = false,
}) => {
  const truncatedName = truncatedLabelLength
    ? stringSlice(label || '', truncatedLabelLength)
    : label
  const shouldBeHidden = !!(truncatedLabelLength && label.length > truncatedLabelLength)

  return (
    <Styled.Wrapper $isMainChip={isMainChip || isExcipientsChip}>
      {isMainChip && <Styled.StartChipIcon />}
      <PureTooltip
        width={tooltipWidth}
        shouldBeHidden={!shouldBeHidden}
        title={label}
        titleStyle={null}
      >
        {truncatedName}
      </PureTooltip>
      <Styled.DeleteBtn type='button' onClick={() => onDelete()} />
    </Styled.Wrapper>
  )
}
