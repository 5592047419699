import React from 'react'
import { Box, Typography } from '@mui/material'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { FlexWrapper, PageTitle, StyledLabIcon } from '@styles'
import { useAppSelector } from '@hooks/redux'
import { IBreadcrumbsRoute, ILaboratory } from '@models/common/app'
import { setLaboratoryForWorkspace, workSpaceInfo } from '@state/workSpace'
import { selectUserLaboratory } from '@state/user'
import { Breadcrumbs, Tooltip } from '@components/shared'
import { isContainRoute } from '@helpers/routes'
import { skillDetails } from '@constants/mappings'
import { useFetchLaboratories } from '@hooks/queries'

import { LaboratoriesField } from './LaboratoriesField'
import { StyledImageSkill, StyledSkillsWrapper } from './styles'

export const YourWorkspaceHeader = React.memo(() => {
  const history = useHistory()
  const { state } = useLocation<IBreadcrumbsRoute[]>()
  const { path, url } = useRouteMatch()
  const dispatch = useDispatch()

  const { selectedLaboratory } = useAppSelector(workSpaceInfo)
  const currentLaboratory = useAppSelector(selectUserLaboratory)

  const [currentLaboratoryData, setCurrentLaboratory] = React.useState<ILaboratory | null>(null)
  // we need this request for the name of the laboratory, because when the user enters from another page on the link,
  // we don't have information for lab icon, skills
  const { data: dataLaboratoryByName } = useFetchLaboratories(selectedLaboratory?.name, true)
  const laboratoryByName = dataLaboratoryByName?.pages.map(page => page.results).flat()[0]

  const laboratorySkills = currentLaboratoryData?.skills || []

  const isCurrentLaboratory =
    currentLaboratoryData && currentLaboratory.id === currentLaboratoryData.id

  React.useEffect(() => {
    if (state && !isContainRoute(state, url)) {
      history.replace({ state: [...state, { path, url, title: selectedLaboratory?.name }] })
    }

    if (state && currentLaboratory.name !== selectedLaboratory?.name) {
      const checkState = state[state.length - 1].path.includes('your_workspace')

      if (checkState)
        state[state.length - 1].title = selectedLaboratory?.name || currentLaboratory.name
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, selectedLaboratory?.name, path, state, url])

  React.useEffect(() => {
    if (laboratoryByName) {
      setCurrentLaboratory(laboratoryByName)
      dispatch(setLaboratoryForWorkspace(laboratoryByName))
    } else {
      setCurrentLaboratory(currentLaboratory)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLaboratory, laboratoryByName])

  return (
    <>
      <FlexWrapper mb='0px' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Box display='flex'>
          {currentLaboratoryData && (
            <StyledLabIcon
              color={currentLaboratoryData.icon_text_color}
              bgColor={currentLaboratoryData.icon_background_color}
              width={'32px'}
              top='6px'
            >
              <Typography fontSize={9} fontWeight={600}>
                {currentLaboratoryData.icon_text}
              </Typography>
            </StyledLabIcon>
          )}
          <Box ml='54px' mb='12px' display='flex' alignItems='center'>
            <PageTitle>{isCurrentLaboratory ? 'Your workspace' : 'Workspace'}</PageTitle>
            {!!laboratorySkills &&
              laboratorySkills.map(skill => (
                <StyledSkillsWrapper>
                  <Tooltip key={skill.id} title={skillDetails.tooltipTitle(skill.name).laboratory}>
                    <StyledImageSkill src={skillDetails.icon(skill.id).default} alt='icon' />
                  </Tooltip>
                </StyledSkillsWrapper>
              ))}
          </Box>
        </Box>

        <LaboratoriesField />
      </FlexWrapper>
      <Breadcrumbs mb='32px' />
    </>
  )
})
