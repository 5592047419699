import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { addTasksTest } from '@api/samples'
import { setMaintenanceModePage } from '@state/user'
import { SAMPLE_TASKS, SAMPLES } from '@constants/queries'
import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'
import { completeTest } from '@state/tasks'

export const useAddAnotherMultipleTests = <TestTypeT>(
  sampleId?: number,
  expandedSamples?: number[],
  taskId?: number
) => {
  const dispatch = useDispatch()
  const client = useQueryClient()

  const mutation = useMutation(
    (data: { test_types: TestTypeT; samples: number[] }) => addTasksTest(data),
    {
      onSuccess: data => {
        const testText = data.length === 1 ? 'test was' : 'tests were'

        if (sampleId) {
          // If this is not multiple adding test to the sample
          client.invalidateQueries([SAMPLE_TASKS, sampleId])
        } else if (expandedSamples?.length) {
          // We update all open samples and additional menus with tasks if this is multiple adding a test to the sample
          expandedSamples.forEach(sampleId => {
            client.invalidateQueries([SAMPLE_TASKS, sampleId])
          })
        }
        // Updating samples
        client.invalidateQueries([SAMPLES])
        // Success notification
        showNotification({
          ...notifications.tasksCreateSuccessfully,
          title: `${data.length} ${testText} successfully added to the sample`,
        })
        if (taskId) {
          showNotification({
            ...notifications.taskFinishedSuccess,
            title: `Task = ${taskId} is finished`,
          })
          dispatch(completeTest(null))
        }
      },
      onError: (error: IError) => {
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
        showNotification(notifications.tasksCreateFailed)
      },
    }
  )

  return mutation
}
