import React from 'react'

import { IViewSampleCell } from '@models/samples/ui'
import { StyledTableSampleCell, StyledTableTitle } from '@styles'

interface TableSortButtonProps extends Omit<IViewSampleCell, 'id'> {
  hideBorder: boolean
}

export const TableColumnTitle: React.FC<TableSortButtonProps> = ({ content, hideBorder }) => {
  return (
    <StyledTableSampleCell hideBorder={hideBorder} header>
      <StyledTableTitle>{content}</StyledTableTitle>
    </StyledTableSampleCell>
  )
}
