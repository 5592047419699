import * as React from 'react'
import { format } from 'date-fns'

import { IOption } from '@models/common/app'
import { INewSamples } from '@models/samples'
import { datePatterns } from '@constants/dates'
import {
  ADDRESS_OF_MANUFACTURER,
  MAIN_APIS,
  EXCIPIENTS,
  TOWN,
  VALUE,
  ATTACHMENTS,
  TEMPERATURE_FORMAT,
  TOXICITY_LEVEL,
  FUNDING,
  PROJECT,
} from '@constants/samples'
import { PlaceForSaveType } from '@components/shared/sampleFields/GMPlaceAutocomplete'

import { conversionSampleOptions } from './samples'

export const parseSampleFormData = (data: INewSamples) => {
  return data.map(sample => {
    let addressOfManufacturer: PlaceForSaveType | null = null
    let towns: PlaceForSaveType | null = null

    const createPlaceAddressData = (val: PlaceForSaveType) => {
      const { place_id, description } = val
      addressOfManufacturer = { place_id, description }
      return []
    }
    const createPlaceTownData = (val: PlaceForSaveType) => {
      const { place_id, description } = val
      towns = { place_id, description }
      return []
    }

    let generatedSample = Object.fromEntries(
      Object.entries(sample).map(([key, val]) => {
        if (key.includes('date') && val) {
          return [key, format(val, datePatterns.yearMonthDay)]
        } else if (conversionSampleOptions.includes(key) && val) {
          return [key, val.id]
        } else if ([MAIN_APIS, EXCIPIENTS, FUNDING].includes(key) && val) {
          return [key, (val as IOption[]).map(({ id }) => id)]
        } else if (key.includes(`${ADDRESS_OF_MANUFACTURER}_detail`) && val) {
          createPlaceAddressData(val)
        } else if (key.includes(ADDRESS_OF_MANUFACTURER) && val) {
          createPlaceAddressData(val)
        } else if (key.includes(`${TOWN}_detail`) && val) {
          createPlaceTownData(val)
        } else if (key.includes(TOWN) && val) {
          createPlaceTownData(val)
        } else if (key.includes(ATTACHMENTS) && val) {
          return []
        } else if (key.includes(PROJECT) && val) {
          return [key, val.id]
        }
        if ((key.includes(TEMPERATURE_FORMAT) || key.includes(TOXICITY_LEVEL)) && !val) {
          return []
        }

        if ([VALUE].includes(key) && isNaN(val)) {
          return []
        }

        return [key, val]
      })
    )

    if (addressOfManufacturer) {
      const {
        place_id: manufacturer_address_place_id,
        description: manufacturer_address_description,
      } = addressOfManufacturer
      generatedSample = {
        ...generatedSample,
        manufacturer_address_place_id,
        manufacturer_address_description,
      }
    }
    if (towns) {
      const { place_id: town_place_id, description: town_description } = towns
      generatedSample = {
        ...generatedSample,
        town_place_id,
        town_description,
      }
    }

    return generatedSample
  })
}

export const handleStopEnterEAndArrows = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'e' || e.key === 'E' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    e.preventDefault()
  }
}

export const handleStopWheelNumber = (e: WheelEvent, inputName?: string) => {
  if (document.activeElement?.nodeName === 'INPUT') {
    const input = document.activeElement as HTMLInputElement
    if (input.name === inputName) {
      e.preventDefault()
    }
  }
}
