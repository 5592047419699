import React from 'react'
import { Box } from '@mui/system'

import { StyledSampleSplitLine, StyledTableReportsRow } from '@styles'
import { ISample } from '@models/samples'
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from '@components/shared/ExpandableApis/styles'
import { useToggle } from '@hooks/common'
import { SampleTasks } from '@components/modules'
import { REPORTS } from '@constants/queries'
import { reportsTasksTable } from '@constants/mappings'
import { ConditionalHidden } from '@components/shared'

import { SampleCells } from './ReportsCells'

interface ReportsRowsProps {
  report: ISample
  isLastReport: boolean
  parents: number[]
  hiddenSplitLinks: boolean
}

export const ReportsRows: React.FC<ReportsRowsProps> = props => {
  const { report, isLastReport, parents, hiddenSplitLinks } = props
  const [isExpandedRow, toggleExpanded] = useToggle()

  const headerContent = reportsTasksTable

  const changeHandler = (e: React.SyntheticEvent<Element, Event>) => {
    const notExpandElement = (e.target as HTMLButtonElement).closest('#not-expand')
    const notExpandedPortal = (e.target as HTMLParagraphElement).closest('[role=presentation]')
    const netExpandedCheckbox = (e.target as HTMLInputElement).classList.contains(
      'PrivateSwitchBase-input'
    )

    if (notExpandElement || notExpandedPortal || netExpandedCheckbox) {
      return
    }

    toggleExpanded()
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: isExpandedRow ? 'max-content' : '100%',
      }}
    >
      <Box>
        <ConditionalHidden condition={hiddenSplitLinks}>
          {parents.map(parent => (
            <StyledSampleSplitLine key={parent} />
          ))}
        </ConditionalHidden>
      </Box>

      <StyledAccordion expanded={isExpandedRow} onChange={changeHandler} key={report.id}>
        <StyledAccordionSummary direction='row'>
          <StyledTableReportsRow key={report.id}>
            <SampleCells
              report={report}
              isLastReport={isLastReport && !isExpandedRow}
              hideBorder={isExpandedRow}
            />
          </StyledTableReportsRow>
        </StyledAccordionSummary>

        <StyledAccordionDetails direction='row'>
          {isExpandedRow && (
            <SampleTasks contentType={REPORTS} sampleId={report.id} headerContent={headerContent} />
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </Box>
  )
}
