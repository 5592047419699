import React from 'react'

import dndGrasper from '@assets/icons/and-grasper-icon.svg'
import { columnCheckboxToggler, selectUserCustomizeTableColumns } from '@state/user'
import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { StyleCard, StyleCheckbox, StyleIcon, StyleTypography } from './styles'

interface CardProps {
  id: number
  dndDisabled?: boolean
  title: string
  checked: boolean
  isDragging?: boolean
}

export const Card: React.FC<CardProps> = ({ id, title, checked, dndDisabled, isDragging }) => {
  const dispatch = useAppDispatch()
  const { customizeModalType } = useAppSelector(selectUserCustomizeTableColumns)

  return (
    <StyleCard isDragging={isDragging}>
      <StyleCheckbox
        disabled={dndDisabled}
        onClick={() =>
          dispatch(columnCheckboxToggler({ id, activeTab: customizeModalType[0].active ? 0 : 1 }))
        }
        checked={checked}
      />

      <StyleTypography>{title}</StyleTypography>

      {!dndDisabled && (
        <StyleIcon>
          <img src={dndGrasper} alt='dnd grasper' />
        </StyleIcon>
      )}
    </StyleCard>
  )
}
