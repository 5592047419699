import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { addNewAddressManufacturer } from '@api/samples'
import { MANUFACTURER_ADDRESSES } from '@constants/queries'
import {
  AddressesTypes,
  PlaceForSaveType,
} from '@components/shared/sampleFields/GMPlaceAutocomplete'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useAddNewAddressManufacturer = () => {
  const client = useQueryClient()
  const dispatch = useDispatch()

  const mutation = useMutation((data: PlaceForSaveType) => addNewAddressManufacturer(data), {
    onMutate: () => client.getQueryData<AddressesTypes[]>(MANUFACTURER_ADDRESSES),
    onSuccess: (newAddress, vars, prevAddresses) => {
      if (prevAddresses)
        client.setQueryData([MANUFACTURER_ADDRESSES], [...prevAddresses, newAddress])
    },
    onSettled: () => client.invalidateQueries(MANUFACTURER_ADDRESSES),
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
