import React from 'react'
import { Typography, useTheme } from '@mui/material'

import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'

import { StyledEditButton } from './styles'

interface EditTestResultProps {
  buttonOnClick: () => void
}

export const EditTestResult: React.FC<EditTestResultProps> = ({ buttonOnClick }) => {
  const theme = useTheme()

  return (
    <StyledEditButton onClick={buttonOnClick}>
      <EditIcon fill={theme.palette.primary.main} style={{ width: '22px', height: '22px' }} />
      <Typography color='primary.main' sx={{ marginLeft: '12px' }} variant='body1' fontWeight={500}>
        Edit
      </Typography>
    </StyledEditButton>
  )
}
