import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface testReportsState {
  selectedTestReports: number[]
  isOpenWidgets: boolean
}

const initialState: testReportsState = {
  selectedTestReports: [],
  isOpenWidgets: true,
}

export const userSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    selectTestReports: (state, { payload }: PayloadAction<number[]>) => {
      state.selectedTestReports = payload
    },
    setOpenWidgets: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenWidgets = payload
    },
  },
})

export const {
  reducer: testReportsReducer,
  actions: { selectTestReports, setOpenWidgets },
} = userSlice
