import { QueryFunctionContext } from 'react-query'

import { http } from '@services/http'
import { ISampleLabelsData } from '@models/samples'
import {
  ICounters,
  Timezones,
  IBlockchainHistoryData,
  BlockchainHistoryType,
  IChoice,
  ILaboratoryData,
  ISkillsData,
  IUserData,
  IOption,
} from '@models/common/app'
import { ICustomizeTableColumnsData } from '@models/common/user'
import { IUseCustomizeTableColumnsResult } from '@hooks/queries/useCustomizeTableColumns'
import { FILTER_COUNT } from '@components/shared/FilterAside/constants'
import { IAvailableTestTypesInfo, IDeliveryServicesInfo, ITestResults } from '@models/yourWorkspace'

export const fetchUsers = ({ pageParam = 1, ...payload }) => {
  const [, ordering, desired_user, desiredLaboratory, userStatus] = payload.queryKey

  const search = `${desired_user ? '&username=' + desired_user : ''}`
  const order = `${ordering ? '&ordering=' + ordering : ''}`
  const usersOfLaboratory = `${desiredLaboratory ? '&laboratory_id=' + desiredLaboratory : ''}`
  const userStatusFilter = `${userStatus ? '&status=' + userStatus : ''}`

  try {
    return http.get<IUserData>(
      `/users/?limit=${FILTER_COUNT}&page=${
        !pageParam ? 1 : pageParam
      }${search}${order}${usersOfLaboratory}${userStatusFilter}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchLaboratories = async ({ pageParam = 1, ...payload }) => {
  const [, desiredFilter, contactInformation, skills] = payload.queryKey

  const url = `?limit=${FILTER_COUNT}&page=${pageParam}`
  const search = `${desiredFilter ? '&search=' + desiredFilter : ''}`
  const contactInfo = `${contactInformation ? '&contact_information=true' : ''} `
  const selectedSkills = skills ? `&skills=${skills}` : ''

  try {
    return http.get<ILaboratoryData>(`/laboratories/${url}${search}${contactInfo}${selectedSkills}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchPhysicalForms = async ({ pageParam = 1, ...payload }) => {
  const [, desiredFilter] = payload.queryKey
  const search = `${desiredFilter ? '?search=' + desiredFilter : ''}`

  try {
    return http.get<IOption[]>(`/physical_forms/${search}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchSkills = async () => {
  try {
    return http.get<ISkillsData>('/skills/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchCounters = async () => {
  try {
    return http.get<ICounters>('/counters/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const generateLabels = async (data: ISampleLabelsData) => {
  try {
    return http.post<Blob, { ids: number[] }>(`/generate_pdf/`, data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchTimezones = async () => {
  try {
    return http.get<Timezones>('/timezones/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchBlockchainHistory = async (
  itemId: number,
  blockchainHistoryType: BlockchainHistoryType,
  { pageParam = 1 }: QueryFunctionContext
) => {
  try {
    return http.get<IBlockchainHistoryData>(
      `/${blockchainHistoryType}/${itemId}/blockchain-history/?page=${pageParam}&limit=20`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const customizeTableColumns = (data: ICustomizeTableColumnsData) => {
  try {
    return http.patch<IUseCustomizeTableColumnsResult, {}>(`/users/customize-columns/`, data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchTaskStatus = () => {
  try {
    return http.get<IChoice[]>('/choices/task_status/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchSampleStatus = () => {
  try {
    return http.get<IChoice[]>('/choices/sample_status/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchUserStatus = () => {
  try {
    return http.get<IChoice[]>('/choices/user_status/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchPackageDeliveryStatus = () => {
  try {
    return http.get<IChoice[]>('/choices/package_delivery_status/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchRequestStatus = () => {
  try {
    return http.get<IChoice[]>('/choices/request_status/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchResultResult = () => {
  try {
    return http.get<ITestResults[]>('/tasks/results/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchTestTypes = ({ pageParam = 1, ...payload }) => {
  const [, availableTestTypes] = payload.queryKey
  const url = !!availableTestTypes ? '?available_test_types=true' : ''

  try {
    return http.get<IAvailableTestTypesInfo[]>(`/test_types/${url}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchDeliveryServices = () => {
  try {
    return http.get<IDeliveryServicesInfo[]>('/delivery_services/')
  } catch (error) {
    return Promise.reject(error)
  }
}
