import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'

import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'
import { UpdateResultTypes, UpdateTandemResultTypes, UpdateIdPADResultTypes } from '@models/tasks'
import { updateTask } from '@api/tasks'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useUpdateTask = () => {
  const dispatch = useDispatch()

  const mutation = useMutation<
    unknown,
    IError,
    { taskId: number; fields: UpdateResultTypes | UpdateTandemResultTypes | UpdateIdPADResultTypes }
  >(updateTask, {
    onSuccess: () => {
      showNotification(notifications.taskResultsUpdatedSuccessfully)
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
      showNotification(notifications.taskResultsUpdatedFailed)
    },
  })

  return mutation
}
