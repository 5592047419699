import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'

import { fetchManufacturersF } from '@api/samples'
import { MANUFACTURERS } from '@constants/queries'
import { IOptionData } from '@models/samples'

export const useFetchManufacturersFilters = (
  desiredManufacture: string | undefined
): UseInfiniteQueryResult<IOptionData> => {
  return useInfiniteQuery([MANUFACTURERS, desiredManufacture], fetchManufacturersF, {
    getNextPageParam: lastPage => {
      if (lastPage.current_page < lastPage.count_pages) return lastPage.current_page + 1
      return false
    },
  })
}
