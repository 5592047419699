import React from 'react'
import { Typography, useTheme } from '@mui/material'

import { ReactComponent as IconPill } from '@assets/icons/statistic/pill-icon.svg'
import { ReactComponent as IconFlask } from '@assets/icons/statistic/flask-icon.svg'
import { IStatisticData } from '@models/testingReports'
import { SubText } from '@styles'

import * as Styled from '../styles'

interface StatisticDataProps {
  statistic?: IStatisticData
}

export const MainStatistic: React.FC<StatisticDataProps> = ({ statistic }) => {
  const theme = useTheme()

  const mainData = [
    {
      count: statistic?.collected_samples || 0,
      icon: IconPill,
      subText: 'Collected samples',
      widthIcon: '38px',
      heightIcon: '38px',
    },
    {
      count: statistic?.tests_count || 0,
      icon: IconFlask,
      subText: 'Tests',
      widthIcon: '25px',
      heightIcon: '38px',
    },
  ]

  return (
    <Styled.MainBox width='30%' $main>
      <Typography fontWeight={700} mb='20px'>
        Total
      </Typography>
      {mainData.map(({ count, icon: Icon, subText, widthIcon, heightIcon }, idx) => (
        <Styled.ContentBox key={idx}>
          <Styled.MainText>{count}</Styled.MainText>

          <Styled.SubTextBox>
            <Icon width={widthIcon} height={heightIcon} stroke={theme.palette.primary.main} />
            <SubText style={{ display: 'inline-block', marginLeft: '8px' }}>{subText}</SubText>
          </Styled.SubTextBox>
        </Styled.ContentBox>
      ))}
    </Styled.MainBox>
  )
}
