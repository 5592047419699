import React from 'react'

import { IPackage, PackageContentType } from '@models/packages'
import { NotesField } from '@components/shared/sampleFields'

import { StyledTableCell } from '../../../styles'

interface PackageNotesProps {
  pack: IPackage
  contentType: PackageContentType
}

export const PackageNotes: React.FC<PackageNotesProps> = React.memo(({ pack, contentType }) => {
  const content =
    contentType === 'default'
      ? 'packages'
      : contentType === 'outgoing'
      ? 'outgoingPackages'
      : 'incomingPackages'

  return (
    <StyledTableCell sx={{ marginRight: '48px' }}>
      <NotesField
        width='100%'
        notes={pack.notes}
        id={pack.id}
        content={content}
        btnText='package'
        notesLength={500}
      />
    </StyledTableCell>
  )
})
