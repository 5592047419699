import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'

import { ReactComponent as InfoCircle } from '@assets/icons/info-circle-blue.svg'
import { StyledTableSampleCell } from '@styles'
import { ISample } from '@models/samples'
import { Tooltip } from '@components/shared'

interface TotalValueProps {
  sample: ISample
  isMobile?: boolean
}

export const TotalValue: React.FC<TotalValueProps> = ({ sample, isMobile }) => {
  const { price_per_unit, total_price } = sample
  const theme = useTheme()

  const totalPrice = total_price ? parseFloat((+total_price).toFixed(4)) : '-'
  const pricePerUnit = price_per_unit ? parseFloat((+price_per_unit).toFixed(4)) : '-'

  return (
    <StyledTableSampleCell $isMobile={isMobile} $alignItems='flex-start'>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography marginRight='8px' variant='body1'>
          ≈ {totalPrice}
        </Typography>
        <Typography marginRight='8px' color='#616D84' fontSize='14px' variant='body1'>
          USD
        </Typography>
        <Tooltip color='#616D84' title={`≈ ${pricePerUnit} USD per unit`}>
          <Box>
            <InfoCircle fill={theme.palette.primary.main} />
          </Box>
        </Tooltip>
      </Box>
    </StyledTableSampleCell>
  )
}
