import React from 'react'
import { useDispatch } from 'react-redux'
import { useController } from 'react-hook-form'

import { PureDropdownAutocomplete } from '@components/ui'
import { useAddNewManufacturer, useFetchManufacture } from '@hooks/queries'
import { setCellValueToSample } from '@state/sampleCreate'
import { IOption } from '@models/common/app'

import { PureCellPropTypes } from './pureFields.types'

export const Manufacturer: React.FC<PureCellPropTypes> = props => {
  const { control, name, cellName, sampleId, rules, initialVal, isCopy } = props
  const [searchQuery, setSearchQuery] = React.useState('')
  const dispatch = useDispatch()
  const AddNewManufacturerM = useAddNewManufacturer()

  const { data, isFetching } = useFetchManufacture(searchQuery)
  const options = data && data.pages.map(page => page.results).flat()

  const setCellValue = (val: IOption | null, error: string) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val, error },
        cellName,
        sampleId,
      })
    )
  }

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: initialVal.val })

  React.useEffect(() => {
    if (error && error.message) setCellValue(field.value, error.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onDropdownChange = (val: IOption) => {
    field.onChange({ target: { value: val } })
    setCellValue(val, '')
  }

  const addNewManufacturerHandler = (v: string) =>
    AddNewManufacturerM.mutate(v, { onSuccess: data => setCellValue(data as IOption, '') })

  return (
    <PureDropdownAutocomplete
      isAlwaysShowAddNewItem
      isCopy={isCopy}
      isLoading={isFetching || AddNewManufacturerM.isLoading}
      initialVal={initialVal.val as IOption | null}
      name={name}
      options={options || []}
      errorMessage={error?.message || ''}
      onInputChange={val => setSearchQuery(val)}
      onDropdownChange={onDropdownChange}
      onAddNewItem={(v: string) => addNewManufacturerHandler(v)}
    />
  )
}
