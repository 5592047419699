import { createPortal } from 'react-dom'

import { usePortal } from '@hooks/common'
import { NOTIFICATIONS_PORTAL } from '@constants/common'

import { NotificationsStack } from './NotificationsStack'

export const Notifications = () => {
  const container = usePortal(NOTIFICATIONS_PORTAL)

  return createPortal(<NotificationsStack />, container)
}
