import React from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { FileRejection } from 'react-dropzone'

import { useToggle } from '@hooks/common'
import { useRemoveAttachments } from '@hooks/queries'
import { AttachLinkModal } from '@components/modules'
import { MAX_NUMBER_OF_ATTACHMENTS } from '@constants/tests'
import { AttachedMaterialItem, Button, DropZone } from '@components/shared'
import { IAttachment, IAttachments } from '@models/attachments'
import { StyledDetailsLabel, StyledPageSubTitle } from '@styles'
import {
  TRUNCATED_ATTACHED_MODAL_MOBILE_TEXT,
  TRUNCATED_ATTACHED_MODAL_TEXT,
} from '@constants/common'
import {
  setNewAttachments4Notification,
  setIsOpenAttachmentsNotification,
  selectTasks,
} from '@state/tasks'
import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { AttachLinkBox } from '../../../../../Testing/styles'
import { IAttachmentProps } from './attachment.types'

export const Attachment: React.FC<IAttachmentProps> = props => {
  const {
    taskId,
    title,
    counter,
    section,
    attachments,
    setAttachments,
    addAttachmentsM,
    width,
    height,
    ml,
    testStepWithNotes,
    testStepWithNotesText,
  } = props

  const isMobile = useMediaQuery('(max-width:767px)')
  const dispatch = useAppDispatch()
  const [isAttachLinkModalOpen, toggleAttachLinkModal] = useToggle()
  const removeAttachmentsM = useRemoveAttachments(taskId)
  const { isOpenAttachmentsNotification } = useAppSelector(selectTasks)

  const removeAttachment = (id: string) => removeAttachmentsM.mutate(id)

  const formatSize = (fileSize: number) => {
    let differentTypeSize
    let i = 0
    let type = ['B', 'KB', 'MB', 'GB']
    while ((fileSize / 1000) | 0 && i < type.length - 1) {
      if (fileSize > 1000 && fileSize < 1024) differentTypeSize = fileSize
      fileSize /= 1024
      i++
    }
    return differentTypeSize
      ? `${differentTypeSize.toFixed(2)} ${type[i - 1]}`
      : `${fileSize.toFixed(2)} ${type[i]}`
  }

  const onDropHandler = React.useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const formData: FormData = new FormData()

      const data = {
        attachment_type: 'file',
        object_id: String(taskId),
        content_type: 'task',
        attachment_section: section,
      }

      const attachmentIds: string[] = []
      const hasAddingError = !!fileRejections.length

      let attachments4Notification: IAttachments = []
      acceptedFiles.forEach(file => {
        const attachmentId = uuid()
        attachmentIds.push(attachmentId)

        const attachment: IAttachment = {
          id: attachmentId,
          name: file.name,
          loading: true,
          attachment_type: {
            key: 'file',
            value: 'File',
          },
          attachment_section: {
            key: section,
            value: 'Test report',
          },
          size: formatSize(file.size),
        }

        if (!hasAddingError) {
          setAttachments(prev => [...prev, attachment])
          attachments4Notification.push(attachment)
        }
        Object.entries({ ...data, file }).forEach(([key, value]) => formData.append(key, value))
      })
      if (attachments4Notification.length) {
        dispatch(setNewAttachments4Notification(attachments4Notification))
        if (!isOpenAttachmentsNotification) dispatch(setIsOpenAttachmentsNotification(true))
      }

      if (!hasAddingError) {
        addAttachmentsM.mutateAsync(formData).catch(() => {
          setAttachments(prev => prev.filter(({ id }) => !attachmentIds.includes(id.toString())))
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addAttachmentsM, taskId, section, setAttachments, isOpenAttachmentsNotification]
  )

  return (
    <Box width={width} ml={ml}>
      {title && counter !== 1 && !testStepWithNotes && (
        <Typography variant='h4' component='h4'>
          {`${counter}. ${title}`}
        </Typography>
      )}

      {testStepWithNotes && (
        <StyledDetailsLabel mb='9px'>
          {testStepWithNotesText ? testStepWithNotesText : 'Task report attachments'}
        </StyledDetailsLabel>
      )}

      <DropZone
        height={height}
        onDrop={onDropHandler}
        addingError={addAttachmentsM.error}
        attachmentsLength={attachments.length}
        section={section}
      />

      <AttachLinkBox>
        <Typography mt='32px'>or</Typography>
        <Button
          ml='-2'
          variant='text'
          disabled={attachments.length === MAX_NUMBER_OF_ATTACHMENTS}
          startIconAdd={attachments.length < MAX_NUMBER_OF_ATTACHMENTS}
          onClick={toggleAttachLinkModal}
        >
          Attach a link
        </Button>
      </AttachLinkBox>

      {!testStepWithNotes && !!attachments.length && (
        <StyledPageSubTitle sx={{ marginBottom: '16px' }} variant='body1'>
          Attached materials
        </StyledPageSubTitle>
      )}

      {!testStepWithNotes &&
        attachments.map(attachment => (
          <Box mb='40px'>
            <AttachedMaterialItem
              key={attachment.id}
              truncatedTextLength={
                isMobile ? TRUNCATED_ATTACHED_MODAL_MOBILE_TEXT : TRUNCATED_ATTACHED_MODAL_TEXT
              }
              attachment={attachment}
              removeAttachment={removeAttachment}
            />
          </Box>
        ))}

      {isAttachLinkModalOpen && (
        <AttachLinkModal
          title='Attach link'
          cancelBtnText='Cancel'
          confirmButtonText='Insert link'
          section={section}
          taskId={String(taskId)}
          attachments={attachments}
          onClose={toggleAttachLinkModal}
          setAttachments={setAttachments}
          addAttachments={addAttachmentsM.mutateAsync}
        />
      )}
    </Box>
  )
}
