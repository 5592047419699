import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'

import { IError, IGroup } from '@models/common/app'
import { addAnotherTest } from '@api/samples'
import { setMaintenanceModePage } from '@state/user'

export const useAddAnotherTest = () => {
  const dispatch = useDispatch()

  const mutation = useMutation((data: IGroup) => addAnotherTest(data), {
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
