import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'
import { fetchDeliveryServices } from '@api/common'

export const useFetchDeliveryServices = () => {
  const dispatch = useDispatch()

  return useQuery('delivery_services', fetchDeliveryServices, {
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
