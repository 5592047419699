import { useMediaQuery } from '@mui/material'
import React from 'react'

import { StyledContainer, StyledContainerMobile } from './styles'

export const MainContainer: React.FC = ({ children }) => {
  const $isMobile = useMediaQuery('(max-width:767px)')
  return (
    <>
      {$isMobile ? (
        <StyledContainerMobile>{children}</StyledContainerMobile>
      ) : (
        <StyledContainer>{children}</StyledContainer>
      )}
    </>
  )
}
