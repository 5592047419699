import React from 'react'
import { Modal as ModalMUI, ModalProps as ModalPropsMUI, useMediaQuery } from '@mui/material'

import close from '@assets/icons/close-icon.svg'

import { ModalBackdrop, ModalContent, StyledIconButton, ModalContentProps } from './styles'

export interface ModalProps extends ModalPropsMUI, ModalContentProps {
  onClose?: () => void
  isPaddingAbsence?: boolean
  btnZIndex?: number
  padding?: string
  noYScroll?: boolean
}

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const { onClose, children, isPaddingAbsence, btnZIndex, padding, noYScroll, ...restProps } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const isMobileHeight = useMediaQuery('(max-height:400px)')

  return (
    <ModalMUI
      sx={{ overflowY: !noYScroll ? 'scroll' : 'auto' }}
      BackdropComponent={ModalBackdrop}
      onClose={onClose}
      ref={ref}
      {...restProps}
    >
      <ModalContent
        isPaddingAbsence={isPaddingAbsence}
        $isMobile={isMobile}
        $isMobileHeight={isMobileHeight}
        padding={padding}
      >
        <StyledIconButton onClick={onClose} $isMobile={isMobile} $zIndex={btnZIndex}>
          <img src={close} alt='close' />
        </StyledIconButton>
        {children}
      </ModalContent>
    </ModalMUI>
  )
})
