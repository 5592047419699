import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { IDeleteSamplesInsidePackage } from '@models/samples'
import { deleteSamplesInsidePackage } from '@api/samples'
import { SAMPLES_IN_PACKAGE } from '@constants/queries'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useRemoveSamplesInsidePackage = () => {
  const dispatch = useDispatch()
  const client = useQueryClient()

  const mutation = useMutation<unknown, IError, IDeleteSamplesInsidePackage>(
    deleteSamplesInsidePackage,
    {
      onSuccess: (data, { packageId }) => {
        client.invalidateQueries([SAMPLES_IN_PACKAGE, packageId])
        client.invalidateQueries(['packagesBlockchainHistory'])
      },
      onError: (error: IError) => {
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
      },
      retry: 1,
      retryDelay: 1500,
    }
  )

  return mutation
}
