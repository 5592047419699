import React from 'react'
import { useMediaQuery } from '@mui/material'

import { StyledFlexContainer } from '@styles'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'

import { GeneralPage } from './GeneralPage'
import { SubscribedPage } from './SubscribedPage'
import { UnsubscribedPage } from './UnsubscribedPage'

export const MaintenanceMode = React.memo(() => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const { isMaintenanceModePage, maintenanceModeUserEmail, isMaintenanceModeUnsubscribePage } =
    useAppSelector(selectUser)
  const isEmailSent = !!maintenanceModeUserEmail.email

  return (
    <StyledFlexContainer $isMaintenanceModePage={isMaintenanceModePage} $isMobile={isMobile}>
      {!isEmailSent && !isMaintenanceModeUnsubscribePage && <GeneralPage />}

      {isEmailSent && <SubscribedPage />}
      {isMaintenanceModeUnsubscribePage && <UnsubscribedPage />}
    </StyledFlexContainer>
  )
})
