import React from 'react'
import Collapse from '@mui/material/Collapse'
import { MenuList, Box } from '@mui/material'

import { useToggle } from '@hooks/common'
import {
  selectedFiltersSlice,
  TASK_STATUS,
  TASK_STATUS_FILTER_TITLE,
} from '@components/shared/FilterAside/constants'
import { useFetchTaskStatusFilters } from '@hooks/queries/filters/useFetchTaskStatusFilters'
import { FilterLoader } from '@components/shared/FilterAside'
import { selectedFilters as filters } from '@state/filters'
import { useAppSelector } from '@hooks/redux'

import { CardRow, StyledCollapseRow } from './styles'
import { FilterTopTitle } from '../components/FilterTopTitle'
import { FilterSelectAll } from '../components/FilterSelectAll'
import { FilterCheckbox } from '../components/FilterCheckbox'

export const TaskStatusFilter = React.memo(() => {
  const [isFilterClose, setIsFilterClose] = useToggle()
  const selectedFilters = useAppSelector(filters)

  const { data, isLoading, isFetching } = useFetchTaskStatusFilters()

  const taskStatusList = data?.map(item => ({ name: item.value, id: item.key }))

  const filterCounter = selectedFilters[selectedFiltersSlice.taskStatus].filters.length || undefined

  return (
    <>
      <FilterTopTitle
        filterNumb={data?.length}
        isFilterClose={isFilterClose}
        setIsFilterClose={setIsFilterClose}
        titleOfFilter={TASK_STATUS_FILTER_TITLE}
        filterCounter={filterCounter}
      />
      <StyledCollapseRow>
        <Collapse in={!isFilterClose} timeout='auto' unmountOnExit>
          <CardRow>
            <MenuList sx={{ width: '100%' }}>
              {isFetching || isLoading ? (
                <Box width='100%'>
                  <FilterLoader />
                </Box>
              ) : (
                <div>
                  {taskStatusList && <FilterSelectAll type={TASK_STATUS} items={taskStatusList} />}
                  {taskStatusList?.map(taskStatus => (
                    <FilterCheckbox type={TASK_STATUS} item={taskStatus} key={taskStatus.id} />
                  ))}
                </div>
              )}
            </MenuList>
          </CardRow>
        </Collapse>
      </StyledCollapseRow>
    </>
  )
})
