import React from 'react'
import { Autocomplete as AutocompleteMUI } from '@mui/material'
import { RegisterOptions, useController } from 'react-hook-form'

import { IOption } from '@models/common/app'
import { AutocompleteWrapper, Input, Tooltip } from '@components/shared'

import { Paper } from './Paper'

interface AutocompleteProps {
  name: string
  pr?: string
  isCopy?: boolean
  options?: IOption[]
  newOptionsType?: string
  enableToAddOptions?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}

export const Autocomplete: React.FC<AutocompleteProps> = React.memo(props => {
  const { name, isCopy, options, enableToAddOptions, newOptionsType, rules, pr } = props
  const optionsRef = React.useRef<IOption[] | null>(null)
  const { field, fieldState } = useController({ name, rules, defaultValue: null })
  const [inputValue, setInputValue] = React.useState<string>('')

  const { isTouched, error } = fieldState
  const isHighlighting = isCopy && !isTouched

  React.useEffect(() => {
    if (!!options) {
      if (optionsRef.current && options.length > optionsRef.current.length) {
        const newOption = options.filter(
          option => !optionsRef.current?.some(currentOption => currentOption.id === option.id)
        )

        if (newOption.length && inputValue === newOption[0].name) {
          field.onChange({ target: { value: newOption[0] } })
        }
      }

      optionsRef.current = options
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const onChangeHandler = (e: any, v: IOption | null) => {
    field.onChange({ target: { value: v } })
  }

  const onInputChangeHandler = (e: any, v: string) => {
    setInputValue(v)
  }

  return (
    <>
      {options && (
        <AutocompleteWrapper pr={pr} height={42} bgColor={isHighlighting ? '#FFF5D6' : '#fff'}>
          <AutocompleteMUI
            fullWidth
            size='small'
            options={options}
            noOptionsText='Nothing found'
            getOptionLabel={option => option.name || inputValue}
            onInputChange={enableToAddOptions ? onInputChangeHandler : undefined}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => (
              <Tooltip
                isPureTooltip
                maxWidth={230}
                shouldBeHidden={(field.value?.name?.length || 0) <= 37}
                title={field.value?.name || ''}
              >
                <Input
                  pr={pr}
                  error={!!error}
                  variant='outlined'
                  helperText={error?.message}
                  placeholder='Type here values...'
                  {...params}
                />
              </Tooltip>
            )}
            PaperComponent={
              enableToAddOptions && newOptionsType
                ? props => (
                    <Paper
                      inputValue={inputValue}
                      name={name}
                      hasError={!!error}
                      type={newOptionsType}
                      {...props}
                    />
                  )
                : undefined
            }
            {...field}
            onChange={onChangeHandler}
          />
        </AutocompleteWrapper>
      )}
    </>
  )
})
