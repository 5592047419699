import { IconButton, styled } from '@mui/material'

export const StyledAddSubstancesBtn = styled('button')<{ $disabled: boolean; $isEdit?: boolean }>`
  margin-bottom: ${({ $isEdit }) => ($isEdit ? '10px' : '50px')};
  border: none;
  background: transparent;
  padding: 0;
  font-weight: 500;
  color: ${({ theme, $disabled }) => ($disabled ? theme.palette.secondary.light : '#5E91F7')};
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  text-decoration: underline;

  & svg {
    vertical-align: middle;
    margin: -4px 4px 0 0;

    & path {
      fill: ${({ theme, $disabled }) => ($disabled ? theme.palette.secondary.light : '#5E91F7')};
    }
  }
`

export const StyledSubstanceBox = styled('div')<{ $isEdit?: boolean }>`
  margin-right: ${({ $isEdit }) => ($isEdit ? '0px' : '16px')};
  max-width: ${({ $isEdit }) => ($isEdit ? '530px' : '408px')};
  width: 100%;
  margin-bottom: ${({ $isEdit }) => ($isEdit ? '16px' : '0')};
`

export const StyledSubstancesWrap = styled('div')<{ $isEdit?: boolean }>`
  display: flex;
  flex-wrap: ${({ $isEdit }) => ($isEdit ? 'wrap' : 'nowrap')};
`

export const StyledProportionBox = styled('div')<{ $isEdit?: boolean }>`
  max-width: ${({ $isEdit }) => ($isEdit ? '380px' : '450px')};
  width: 100%;
  margin-bottom: 30px;
`

export const StyledPercentageWarning = styled('p')`
  margin: 5px 0 0;
  color: #b08904;
  font-size: 12px;
  line-height: normal;
`
export const StyledDeleteButton = styled(IconButton)<{ $isEdit?: boolean }>`
  align-self: flex-start;
  margin: ${({ $isEdit }) => ($isEdit ? '-60px 0 0 6px' : '30px 0 0 8px')};
`
