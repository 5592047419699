import React from 'react'

import { IPackage, PackageContentType } from '@models/packages'

import {
  Action,
  Toggler,
  Quantity,
  PackageID,
  LastUpdates,
  Destination,
  FromLaboratory,
  DeliveryDate,
  DeliveryStatus,
} from './components'
import { DeliveryService } from './components/DeliveryService'
import { TrackingNumber } from './components/TrackingNumber'
import { PackageNotes } from './components/PackageNotes'
import { PackageType } from './components/PackageType'

interface TableCellsProps {
  pack: IPackage
  contentType: PackageContentType
  isLastPack: boolean
  isPackSelected?: boolean
}

export const TableCells: React.FC<TableCellsProps> = React.memo(
  ({ pack, contentType, isLastPack, isPackSelected }) => {
    const defaultCells = [
      PackageID,
      Destination,
      DeliveryStatus,
      Quantity,
      PackageNotes,
      DeliveryDate,
      LastUpdates,
      Action,
    ]

    const tabCells = [
      PackageID,
      contentType === 'incoming' ? FromLaboratory : Destination,
      DeliveryStatus,
      Quantity,
      DeliveryService,
      TrackingNumber,
      PackageNotes,
      PackageType,
      DeliveryDate,
      LastUpdates,
      Action,
    ]

    const cells = contentType === 'default' ? defaultCells : tabCells

    return (
      <>
        {contentType === 'default' && (
          <Toggler pack={pack} contentType={contentType} isLastPack={isLastPack} />
        )}

        {cells.map((Cell, idx) => (
          <Cell
            key={idx}
            pack={pack}
            isLastPack={isLastPack}
            contentType={contentType}
            isPackSelected={isPackSelected}
          />
        ))}
      </>
    )
  }
)
