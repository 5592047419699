import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import unionTop from '@assets/images/union-top.svg'
import unionBottom from '@assets/images/union-bottom.svg'
import union from '@assets/images/union.svg'

interface MaintenanceModeBoxProps extends BoxProps {
  $isMobile: boolean
}

export const MaintenanceModeBox = styled<React.FC<MaintenanceModeBoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 4%;
  width: 100vw;
  height: ${({ $isMobile }) => ($isMobile ? '97vh' : '100vh')};
  background-color: #0a2c6d;
  color: #ffffff;
  background-image: url(${unionTop}), url(${unionBottom});
  background-size: cover cover;
  background-repeat: no-repeat no-repeat;
  background-position: top right, bottom left;
`

export const Logo = styled('img')`
  align-self: flex-start;
  display: block;
`

interface MaintenanceTextProps extends TypographyProps {
  $isMobile: boolean
}

export const MaintenanceTitle = styled<React.FC<MaintenanceTextProps>>(Typography)`
  margin-top: ${({ $isMobile }) => ($isMobile ? '52px' : '2%')};
  font-size: ${({ $isMobile }) => ($isMobile ? '32px' : '48px')};
  line-height: ${({ $isMobile }) => ($isMobile ? '37.5px' : '56px')};
  font-weight: 700;
  text-align: center;
`

export const MaintenanceSubTitle = styled<React.FC<MaintenanceTextProps>>(Typography)`
  margin-top: ${({ $isMobile }) => ($isMobile ? '0.5%' : '1%')};
  margin-bottom: ${({ $isMobile }) => ($isMobile ? '12px' : '10%')};
  font-size: ${({ $isMobile }) => ($isMobile ? '16px' : '24px')};
  line-height: ${({ $isMobile }) => ($isMobile ? '21px' : '31px')};
  font-weight: 400;
  text-align: center;
  color: #e1e7f3;
`

export const Image = styled('img')`
  display: block;
  width: 70%;
  height: auto;
  margin-left: 30px;
`

interface InfoPageBoxProps {
  $isSubscribeContent?: boolean
  $isMobile: boolean
}

export const InfoPageBox = styled<React.FC<InfoPageBoxProps>>(Box)`
  max-width: 500px;
  margin: auto;
  padding: 68px 75px;
  text-align: center;
  background-color: ${({ $isSubscribeContent, $isMobile }) =>
    $isMobile ? 'transparent' : $isSubscribeContent ? '#234484' : '#ffffff'};
  border: 1px solid
    ${({ $isSubscribeContent, $isMobile }) =>
      $isMobile ? 'transparent' : $isSubscribeContent ? ' #3371bc' : '#E5E9F1'};
  border-radius: ${({ $isSubscribeContent, $isMobile }) =>
    !$isMobile && $isSubscribeContent ? '4px' : '10px'};
`

interface ModeLinkProps extends LinkProps {
  color: string
}

export const ModeLink = styled<React.FC<ModeLinkProps>>(Link)`
  text-decoration: underline;
  margin-left: 8px;
  color: ${({ color }) => color};
  cursor: pointer;
`

export const UnsubscribePageBox = styled<React.FC<MaintenanceModeBoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.background.default};
  background-image: url(${union});
  background-repeat: no-repeat;
  background-position: 100% 8%;
`

export const HeaderBox = styled<React.FC<MaintenanceModeBoxProps>>(Box)`
  background-color: ${({ theme }) => theme.primary[90]};
  padding: ${({ $isMobile }) => ($isMobile ? '15px' : '15px 80px')};
`
