import React from 'react'

import { ITaskLab } from '@models/tasks'
import { Chip } from '@components/shared'
import { StyledDetailsTableRow, StyledDetailsTableCell } from '@styles'

interface TaskRowProps {
  task?: ITaskLab
}

export const TaskRow: React.FC<TaskRowProps> = ({ task }) => {
  const cells = [
    task?.id,
    task?.test_type.name,
    task?.serial_number_of_equipment || '-',
    task?.status,
  ]

  return (
    <StyledDetailsTableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0, paddingLeft: '40px' },
        'td:first-child': { paddingLeft: '40px' },
      }}
    >
      {cells.map((cell, idx) => (
        <StyledDetailsTableCell key={idx}>
          {idx === 3 ? <Chip label={task?.status.value} status={task?.status.key} /> : cell}
        </StyledDetailsTableCell>
      ))}
    </StyledDetailsTableRow>
  )
}
