import { Box } from '@mui/material'
import { BoxProps, styled, keyframes } from '@mui/system'
import React from 'react'

export const AttachedMaterialContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`

interface AttachedMaterialBoxProps extends BoxProps {
  $isMobile?: boolean
}

export const AttachedMaterialBox = styled<React.FC<AttachedMaterialBoxProps>>(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ $isMobile }) => ($isMobile ? '20px' : '10px')};
  padding: ${({ $isMobile }) => ($isMobile ? '15px 5px 15px 15px' : '22px')};
  margin-bottom: 8px;
  width: ${({ $isMobile }) => ($isMobile ? '80%' : '100%')};
`
export const MaterialInfo = styled(Box)`
  margin-left: 14px;
  flex: 1 1 auto;
`

interface IconBoxProps extends BoxProps {
  $isMobile?: boolean
}

export const IconBox = styled<React.FC<IconBoxProps>>(Box)`
  padding: ${({ $isMobile }) => ($isMobile ? '18px 0 24px 16px' : '18px 0 24px 24px')};
  flex: 1 1 auto;
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 18px;
  }
`

const circulatingAnimation = keyframes`
 from { transform:rotate(0deg); }
 to { transform:rotate(360deg); }
`

export const CircularAnimatedIcon = styled('img')`
  animation: ${circulatingAnimation} 1s linear infinite;
`
