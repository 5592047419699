import React from 'react'

import { ITaskLab } from '@models/tasks'
import { StyledDetailsBox } from '@styles'

import { ManufactureDetails, MainInfo, Identification } from './components'

interface TaskInfoProps {
  task?: ITaskLab
  isLoading: boolean
}

export const TaskInfo: React.FC<TaskInfoProps> = ({ isLoading, task }) => {
  return (
    <StyledDetailsBox>
      <MainInfo task={task} isLoading={isLoading} />
      <Identification task={task} isLoading={isLoading} />
      <ManufactureDetails task={task} isLoading={isLoading} />
    </StyledDetailsBox>
  )
}
