import React from 'react'

import { useToggle } from '@hooks/common'
import { IPackage } from '@models/packages'
import { Button, Tooltip } from '@components/shared'
import { SendPackageModal } from '@components/modules'
import { selectTasks, setAddedAttachments, setIsEditAttachments } from '@state/tasks'
import { useAppDispatch, useAppSelector } from '@hooks/redux'

interface SendProps {
  pack: IPackage
}

export const Send: React.FC<SendProps> = ({ pack }) => {
  const { addedAttachments } = useAppSelector(selectTasks)
  const dispatch = useAppDispatch()

  const [isSendPackageModalOpen, toggleSendPackageModal] = useToggle()

  const handleSetEditMode = () => {
    if (addedAttachments) dispatch(setAddedAttachments(null))
    dispatch(setIsEditAttachments(true))
    toggleSendPackageModal()
  }

  return (
    <>
      <Tooltip shouldBeHidden={!!pack.quantity} title='There are no samples in this package'>
        <div>
          <Button
            variant={!pack.quantity ? 'contained' : 'outlined'}
            width='90px'
            padding='0'
            mr='24px'
            fz={15}
            disabled={!pack.quantity}
            onClick={handleSetEditMode}
          >
            Send
          </Button>
        </div>
      </Tooltip>

      {isSendPackageModalOpen && (
        <SendPackageModal selectedPackages={[pack]} onClose={toggleSendPackageModal} />
      )}
    </>
  )
}
