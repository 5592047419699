import React from 'react'

import { ISample } from '@models/samples'
import { StyledTableSampleCell } from '@styles'
import { SpecificId } from '@components/shared/sampleFields'
import { protectedRoutes } from '@configs/routes.config'
import { IPackage } from '@models/packages'
import { DETAILS } from '@constants/common'
import { TempSensitiveToxicityIcons } from '@components/shared'

interface SampleIDProps {
  sample: ISample
  packagesData?: IPackage
}

export const SampleID: React.FC<SampleIDProps> = ({ sample, packagesData }) => {
  return (
    <StyledTableSampleCell sx={{ position: 'relative' }}>
      <SpecificId
        labelTooltip={sample.initial_laboratory.label_tooltip}
        specificId={sample.specific_id}
        laboratory={sample.laboratory}
        pathForLink={`${protectedRoutes.packages.path}/${packagesData?.id}${DETAILS}`}
        titleForPathLink={`${protectedRoutes.packageDetails.name} ${packagesData?.specific_id}`}
      />

      <TempSensitiveToxicityIcons
        toxicity={sample.toxicity_level}
        minTempSensitive={sample.min_temperature}
        maxTempSensitive={sample.max_temperature}
        temperatureMeasurementFormat={sample.temperature_measurement_format?.key}
        mt='-5px'
      />
    </StyledTableSampleCell>
  )
}
