export const REQUIRED_FIELD = 'Please fill in the field'

export const EMAIL_MATCH = 'Invalid email address'

export const INVALID_FORMAT = 'Invalid format'

export const PASSWORDS_MATCH = "Entered password don't match"
export const PASSWORD_MIN_LENGTH = 'Password must be at least 8 characters'

export const FIRST_NAME_REQUIRED = 'First name is required'
export const LAST_NAME_REQUIRED = 'Last name is required'
export const FIRST_NAME_MAX_LENGTH = 'Maximum 25 characters'
export const LAST_NAME_MAX_LENGTH = 'Maximum 25 characters'

export const MAIN_APIS_REQUIRED = 'At least one main API must be added to the sample'

export const ATTACH_LINK_TITLE_MAX_LENGTH = 'Ensure this field has no more than 80 characters.'
export const ATTACH_LINK_URL_MAX_LENGTH = 'Ensure this field has no more than 200 characters.'

export const INVALID_URL = 'Enter valid URL'

export const MIN_CHARACTERS = (numb: number) => `Minimum ${numb} characters`
export const MAX_CHARACTERS = (numb: number) => `Maximum ${numb} characters`

export const SUBMITTER_CODE_CHARACTERS =
  'Code must have 5 characters, only digits and letters are allowed'
export const SUBMITTER_CODE_ALLOWED = 'Only digits and letters are allowed'
export const SPECIAL_CHARACTERS_ALLOWED =
  "Please provide a valid input. Special characters allowed: - / , ' : ; _"
export const NUMERIC_DECIMAL = 'Maximum number of characters 6 after dot and 6 before dot'
export const NOT_NEGATIVE = 'The number cannot be negative'

export const DANGEROUS_CHAR = 'Field starts with dangerous char'

export const DANGEROUS_FIRST_CHARACTER = 'The @, -, +  must not be the first character'

export const WEIGHT_ALLOWED = 'Only digits are allowed'

export const EMAIL_VALIDATION_REGEX =
  /^(?!\.)(?!.*\.\.)[a-zA-Z0-9!#$%&'*+ /=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+ /=?^_`{|}~-]+)*\+?[a-zA-Z0-9!#$%&'*+ /=?^_`{|}~-]*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/
