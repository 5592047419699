import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'

import logoVerify from '@assets/icons/logo-white-verify.svg'
import logoScreenr from '@assets/icons/logo-white-screenr.svg'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'

import { InfoPage } from '../InfoPage'
import { Logo, MaintenanceModeBox } from '../styles'

export const SubscribedPage: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const { maintenanceModeUserEmail } = useAppSelector(selectUser)
  const theme = useTheme()
  const { isTenantType } = useTenant()
  const isLegalDrugs = isTenantType(TENANT_TYPE.legalDrugs)

  const text = ` After finishing working on our system we well notify you on 
  ${maintenanceModeUserEmail.email} mail so you can try all of new features
 and enjoy improvements`

  return (
    <MaintenanceModeBox $isMobile={isMobile}>
      <Logo src={isLegalDrugs ? logoVerify : logoScreenr} alt='logo' width='150px' height='45px' />
      <InfoPage
        title='You successfully subscribed'
        text={text}
        linkDescription='Subscribed by accident?'
        modeLinkTitle='Unsubscribe'
        link={`/subscriptions/manage/${maintenanceModeUserEmail.email}`}
        typeOfContent='subscribe'
        iconColor={theme.primary[30]}
        titleColor={theme.primary[30]}
        textColor='#E1E7F3'
        linkColor={theme.primary[20]}
        email={maintenanceModeUserEmail.email}
      />
    </MaintenanceModeBox>
  )
}
