import { styled, FormControlLabel, FormControlLabelProps, Radio, FormControl } from '@mui/material'
import React from 'react'

//[Radio buttons group
export const StyledFormControl = styled(FormControl)`
  display: block;
  margin-left: 70px;

  & .MuiFormControlLabel-root {
    margin-right: 6px;
  }
`

export const StyledRadio = styled(Radio)`
  color: #adb4c2;
  padding: 4px;

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & .MuiSvgIcon-root {
    font-size: 20px;
  }
`
interface StyledLabelProps extends FormControlLabelProps {
  $isMobile?: boolean
  checked: boolean
}

export const StyledFormControlLabel = styled<React.FC<StyledLabelProps>>(FormControlLabel)`
  color: ${({ theme, checked }) =>
    checked ? theme.palette.text.primary : theme.palette.secondary.light};
`
//Radio buttons group]
