import { Box, styled } from '@mui/material'

export const StyledDetailsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};
  padding: 15px 10px 25px 20px;
`
