import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { useDispatch } from 'react-redux'

import { fetchLaboratories } from '@api/common'
import { LABORATORIES_FILTER } from '@components/shared/FilterAside/constants'
import { IError, ILaboratoryData } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useFetchLaboratories = (
  desiredLaboratory?: string | null,
  contactInformation?: boolean,
  skills?: Array<number> | null
): UseInfiniteQueryResult<ILaboratoryData, IError> => {
  const dispatch = useDispatch()

  return useInfiniteQuery(
    [LABORATORIES_FILTER, desiredLaboratory, contactInformation, skills],
    fetchLaboratories,
    {
      getNextPageParam: lastPage => {
        const { next: nextPageUrl } = lastPage.links

        if (nextPageUrl) {
          const queryString = nextPageUrl.slice(nextPageUrl.indexOf('?'))
          const urlParams = new URLSearchParams(queryString)
          const nextPage = urlParams.get('page')

          return nextPage
        }
      },

      onError: error => {
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
      },
    }
  )
}
