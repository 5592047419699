import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IPackage } from '@models/packages'

export interface PackagesState {
  selectedPackages: IPackage[]
  selectedSamplesInModal: number[]
  canceledOutgoingPackages: number[]
}

const initialState: PackagesState = {
  selectedPackages: [],
  selectedSamplesInModal: [],
  canceledOutgoingPackages: [],
}

export const userSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    selectPackages: (state, { payload }: PayloadAction<IPackage[]>) => {
      state.selectedPackages = payload
    },
    cancelOutgoingPackage: (state, { payload }: PayloadAction<number>) => {
      state.canceledOutgoingPackages.push(payload)
    },
    clearCanceledPackages: state => {
      state.canceledOutgoingPackages = []
    },
    selectSamplesInModal: (state, { payload }: PayloadAction<number[]>) => {
      state.selectedSamplesInModal = payload
    },
  },
})

export const {
  reducer: packagesReducer,
  actions: { selectPackages, cancelOutgoingPackage, clearCanceledPackages, selectSamplesInModal },
} = userSlice
