import React from 'react'

import { viewSampleTableInsidePackage } from '@constants/mappings'
import { FixedTableCell, StyledTableTitle } from '@styles'

import { TableColumnTitle } from '../components/TableColumnTitle'

interface TableHeadTitlesProps {
  hideBorder: boolean
}

export const TableHeadTitles = React.memo<TableHeadTitlesProps>(({ hideBorder }) => {
  return (
    <>
      {viewSampleTableInsidePackage.tableHeadCells.map(({ id, ...props }) => (
        <TableColumnTitle key={id} {...props} hideBorder={hideBorder} />
      ))}

      <FixedTableCell hideBorder={hideBorder} header>
        <StyledTableTitle>Action</StyledTableTitle>
      </FixedTableCell>
    </>
  )
})
