import { useMutation } from 'react-query'

import { useAppDispatch } from '@hooks/redux'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'
import { saveFilters } from '@api/filters'
import { ISavedFiltersFromUser } from '@models/common/user'

export const useSaveFilters = () => {
  const dispatch = useAppDispatch()
  const mutation = useMutation<unknown, IError, ISavedFiltersFromUser>(saveFilters, {
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
