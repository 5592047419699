import { stringify } from 'query-string'
import { ArrayParam, encodeQueryParams, NumberParam, StringParam } from 'use-query-params'

import {
  IPackage,
  IPackagesData,
  ISendPackagesData,
  ICreatePackagesData,
  IUpdatedPackageData,
  IAddSamplesIntoPackageData,
  IGeneratePackageQrData,
  IUpdatedDeliveryStatusRequestData,
  IUpdatedPackageNotes,
} from '@models/packages'
import { http } from '@services/http'
import { INCOMING_PACKAGES, OUTGOING_PACKAGES } from '@constants/queries'
import { ISearchSuggestionsData } from '@models/common/app'
import { parseDate } from '@helpers/dates'

// * [Packages

const packageListQueryParams = {
  page: StringParam,
  limit: NumberParam,
  packages: StringParam,
  package_type: StringParam,
  ordering: StringParam,
  laboratories: StringParam,
  delivery_status: ArrayParam,
  started_updated_at: StringParam,
  ended_updated_at: StringParam,
}

const PACKAGES_AMOUNT_LOADING_LIMIT = 50

export const fetchPackages = async (queryKey: any) => {
  const { queryKey: queryKeyNested, pageParam = 1 } = queryKey
  const [packageType, ordering, desired_package, laboratories, delivery_status, lastUpdates] =
    queryKeyNested

  const parsedLastUpdates = parseDate(lastUpdates)

  const searchParams = {
    limit: PACKAGES_AMOUNT_LOADING_LIMIT,
    page: pageParam,
    package_type:
      packageType === INCOMING_PACKAGES
        ? 'incoming'
        : packageType === OUTGOING_PACKAGES
        ? 'outgoing'
        : packageType,
    ordering,
    packages: desired_package,
    laboratories,
    delivery_status,
    started_updated_at:
      lastUpdates && (parsedLastUpdates[0] ? parsedLastUpdates[0] : parsedLastUpdates[1]),
    ended_updated_at:
      lastUpdates && (parsedLastUpdates[1] ? parsedLastUpdates[1] : parsedLastUpdates[0]),
  }

  const encodedQuery = encodeQueryParams(packageListQueryParams, searchParams)
  const queryString = stringify(encodedQuery)

  try {
    const packagesEndpoint = `/packages/?${queryString}`
    return http.get<IPackagesData>(packagesEndpoint)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchPackage = async <T>(packageId: string) => {
  try {
    return http.get<T>(`/packages/${packageId}/`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getDeliveryServices = async <T>() => {
  try {
    return http.get<T>(`/delivery_services/`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createPackages = async (sampleIds: ICreatePackagesData) => {
  try {
    return http.post<IPackage, ICreatePackagesData>('/packages/', sampleIds)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const sendPackages = async (data: ISendPackagesData) => {
  try {
    return http.post('/packages/send/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updatePackage = async ({ id, data }: IUpdatedPackageData) => {
  try {
    return http.patch<IPackage, IUpdatedDeliveryStatusRequestData | IUpdatedPackageNotes>(
      `/packages/${id}/`,
      data
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addSamplesIntoPackage = ({ id, data }: IAddSamplesIntoPackageData) => {
  try {
    return http.post(`/packages/${id}/samples/`, data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const generatePackageQr = async ({ packageId, downloadQrCode }: IGeneratePackageQrData) => {
  try {
    return http.get<Blob>(
      `/packages/${packageId}/generate_qr_code/?download_qr_code=${downloadQrCode}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const generatePackageXLS = async (packageId: string) => {
  try {
    return http.get<Blob>(`/packages/${packageId}/generate-xlsx-file/`, {
      responseType: 'arraybuffer',
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const searchSuggestionsPackage = ({ queryKey, pageParam }: any) => {
  try {
    return http.get<ISearchSuggestionsData>(
      `/packages/specific_id_suggestions/?suggestion_start=${queryKey[0]}${
        pageParam ? '&page=' + pageParam : ''
      }${queryKey[1] ? '&package_type=' + queryKey[1] : ''}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const searchSuggestionsSamplesIntoPackage = ({ queryKey, pageParam }: any) => {
  try {
    return http.get<ISearchSuggestionsData>(
      `/packages/samples_specific_id_suggestions/?suggestion_start=${queryKey[0]}${
        pageParam ? '&page=' + pageParam : ''
      }${'&package_id=' + queryKey[1]}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

// * Packages]
