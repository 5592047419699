import React from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Chip, ParagraphLink } from '@components/shared'
import { IPackage } from '@models/packages'
import { getFormattedDate } from '@helpers/ui/date'
import { StyledParagraph, StyledPageSubTitle, StyledPageLabel } from '@styles'
import { datePatterns } from '@constants/dates'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'
import { NotesField } from '@components/shared/sampleFields'
import { PACKAGES } from '@constants/queries'
import { setLaboratoryForWorkspace } from '@state/workSpace'
import { ILaboratory } from '@models/common/app'
import { protectedRoutes } from '@configs/routes.config'
import { DETAILS } from '@constants/common'

interface ViewPackageDetailsProps
  extends Omit<
    IPackage,
    | 'quantity'
    | 'updated_at'
    | 'samples'
    | 'has_missed_samples'
    | 'units'
    | 'unit_type'
    | 'currency'
    | 'price_per_unit'
    | 'total_price'
    | 'toxicity_level'
    | 'min_temperature'
    | 'max_temperature'
    | 'max_temperature_measurement_format'
    | 'min_temperature_measurement_format'
  > {}

export const PackageInfoDelivery: React.FC<ViewPackageDetailsProps> = props => {
  const {
    id,
    destination,
    delivery_status,
    expected_date,
    actual_date,
    source,
    notes,
    specific_id,
  } = props
  const { timezone } = useAppSelector(selectUser)
  const history = useHistory()
  const dispatch = useDispatch()

  const viewLaboratoryInfoEndPoint = (laboratory: ILaboratory) => {
    if (laboratory) {
      dispatch(setLaboratoryForWorkspace(laboratory))
    }

    history.replace({
      pathname: protectedRoutes.yourWorkspace.path,
      state: [
        {
          path: `${protectedRoutes.packages.path}/${id}${DETAILS}`,
          url: `${protectedRoutes.packages.path}/${id}${DETAILS}`,
          title: `${protectedRoutes.packageDetails.name} ${specific_id}`,
        },
      ],
    })
  }

  return (
    <div>
      <StyledPageSubTitle variant='h3'>Delivery details</StyledPageSubTitle>

      <StyledPageLabel variant='h5'>From location:</StyledPageLabel>
      {source.name ? (
        <ParagraphLink
          mb='16px'
          onClick={() => {
            if (source) viewLaboratoryInfoEndPoint(source)
          }}
          variant='body1'
        >
          {source.name}
        </ParagraphLink>
      ) : (
        <StyledParagraph variant='body1'>-</StyledParagraph>
      )}

      <StyledPageLabel variant='h5'>Destination:</StyledPageLabel>
      {destination?.name ? (
        <ParagraphLink
          mb='16px'
          onClick={() => {
            if (destination) viewLaboratoryInfoEndPoint(destination)
          }}
          variant='body1'
        >
          {destination?.name}
        </ParagraphLink>
      ) : (
        <StyledParagraph variant='body1'>-</StyledParagraph>
      )}

      <StyledPageLabel variant='h5'>Delivery status:</StyledPageLabel>
      <Box mb={3}>
        <Chip label={delivery_status.value} status={delivery_status.key} />
      </Box>

      <StyledPageLabel variant='h5'>Delivery date:</StyledPageLabel>

      <StyledParagraph variant='caption'>Expected date</StyledParagraph>
      <StyledParagraph variant='body1' sx={{ marginBottom: '12px !important' }}>
        {getFormattedDate(datePatterns.monthDayYear, expected_date, timezone) || '-'}
      </StyledParagraph>

      <StyledParagraph variant='caption'>Actual date</StyledParagraph>
      <StyledParagraph variant='body1'>
        {getFormattedDate(datePatterns.monthDayYear, actual_date, timezone) || '-'}
      </StyledParagraph>

      <NotesField
        notes={notes}
        id={id}
        labelText='Package'
        content={PACKAGES}
        withoutTruncate
        editDetailsNotes
        notesLength={500}
      />
    </div>
  )
}
