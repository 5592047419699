import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  APIS,
  APIS_FILTER_TITLE,
  appliedFiltersSlice,
  ASSIGNEE_FILTER_TITLE,
  ASSIGNEES,
  COLLECTION_DATE,
  COLLECTION_DATE_FILTER_TITLE,
  DELIVERY_STATUS,
  DRUG_CLASS_FILTER_TITLE,
  DRUG_CLASSES,
  EXCIPIENTS,
  EXCIPIENTS_FILTER_TITLE,
  EXPIRATION_DATE,
  EXPIRATION_DATE_FILTER_TITLE,
  LABORATORIES_FILTER,
  LAST_UPDATES,
  LAST_UPDATES_FILTER_TITLE,
  MANUFACTURE_FILTER_TITLE,
  MANUFACTURERS,
  PACKAGE_DELIVERY_STATUS_TITLE,
  PHYSICAL_FORM_TITLE,
  REQUEST_STATUS,
  SAMPLE_STATUS,
  SAMPLE_STATUS_FILTER_TITLE,
  selectedFiltersSlice,
  TASK_RESULT,
  TASK_RESULT_FILTER_TITLE,
  TASK_STATUS,
  TASK_STATUS_FILTER_TITLE,
  TEST_TYPE_FILTER_TITLE,
  TESTING_DATE,
  TESTING_DATE_FILTER_TITLE,
  typeOfApplySlice,
  typeOfSelectSlice,
  USER_STATUS,
  USER_STATUS_FILTER_TITLE,
} from '@components/shared/FilterAside/constants'
import { IStringOption } from '@models/filters'
import { EnteringDateType, ISavedFiltersFromUser, PageTitleForFilter } from '@models/common/user'
import { TEST_TYPES, PHYSICAL_FORM } from '@constants/queries'

import { FiltersState, FiltersMenuState } from './filters.types'

const initialState: FiltersState = {
  isFilterOpen: {
    top: false,
    left: false,
    bottom: false,
    right: false,
  },
  filtersPage: 'samples',
  isResetSampleStatusSelected: false,
  apisSelectedFilters: { title_ui: APIS_FILTER_TITLE, title_url: APIS, filters: [] },
  excipientsSelectedFilters: {
    title_ui: EXCIPIENTS_FILTER_TITLE,
    title_url: EXCIPIENTS,
    filters: [],
  },
  drugClassesSelectedFilters: {
    title_ui: DRUG_CLASS_FILTER_TITLE,
    title_url: DRUG_CLASSES,
    filters: [],
  },
  manufacturersSelectedFilters: {
    title_ui: MANUFACTURE_FILTER_TITLE,
    title_url: MANUFACTURERS,
    filters: [],
  },
  assigneesSelectedFilters: { title_ui: ASSIGNEE_FILTER_TITLE, title_url: ASSIGNEES, filters: [] },
  taskStatusSelectedFilters: {
    title_ui: TASK_STATUS_FILTER_TITLE,
    title_url: TASK_STATUS,
    filters: [],
  },
  taskResultSelectedFilters: {
    title_ui: TASK_RESULT_FILTER_TITLE,
    title_url: TASK_RESULT,
    filters: [],
  },
  requestStatusSelectedFilters: {
    title_ui: REQUEST_STATUS,
    title_url: REQUEST_STATUS,
    filters: [],
  },
  laboratoriesSelectedFilters: {
    title_ui: LABORATORIES_FILTER,
    title_url: LABORATORIES_FILTER,
    filters: [],
  },
  deliveryStatusSelectedFilters: {
    title_ui: PACKAGE_DELIVERY_STATUS_TITLE,
    title_url: DELIVERY_STATUS,
    filters: [],
  },
  testingDateSelectedFilters: {
    title_ui: TESTING_DATE_FILTER_TITLE,
    title_url: TESTING_DATE,
    filters: [],
  },
  collectionDateSelectedFilters: {
    title_ui: COLLECTION_DATE_FILTER_TITLE,
    title_url: COLLECTION_DATE,
    filters: [],
  },
  lastUpdatesSelectedFilters: {
    title_ui: LAST_UPDATES_FILTER_TITLE,
    title_url: LAST_UPDATES,
    filters: [],
  },
  expirationDateSelectedFilters: {
    title_ui: EXPIRATION_DATE_FILTER_TITLE,
    title_url: EXPIRATION_DATE,
    filters: [],
  },
  userStatusSelectedFilters: {
    title_ui: USER_STATUS_FILTER_TITLE,
    title_url: USER_STATUS,
    filters: [],
  },
  sampleStatusSelectedFilters: {
    title_ui: SAMPLE_STATUS_FILTER_TITLE,
    title_url: SAMPLE_STATUS,
    filters: [],
  },
  testTypeSelectedFilters: {
    title_ui: TEST_TYPE_FILTER_TITLE,
    title_url: TEST_TYPES,
    filters: [],
  },
  physicalFormSelectedFilters: {
    title_ui: PHYSICAL_FORM_TITLE,
    title_url: PHYSICAL_FORM,
    filters: [],
  },

  appliedApisSelectedFilters: [],
  appliedExcipientsSelectedFilters: [],
  appliedDrugClassesSelectedFilters: [],
  appliedManufacturersSelectedFilters: [],
  appliedAssigneesSelectedFilters: [],
  appliedTaskStatusSelectedFilters: [],
  appliedTaskResultSelectedFilters: [],
  appliedRequestStatusSelectedFilters: [],
  appliedLaboratoriesSelectedFilters: [],
  appliedDeliveryStatusSelectedFilters: [],
  appliedTestingDateSelectedFilters: [],
  appliedCollectionDateSelectedFilters: [],
  appliedLastUpdatesSelectedFilters: [],
  appliedExpirationDateSelectedFilters: [],
  appliedUserStatusSelectedFilters: [],
  appliedSampleStatusSelectedFilters: [],
  appliedTestTypeSelectedFilters: [],
  appliedPhysicalFormSelectedFilters: [],
  enteringStoreDate: { order: 0, type: '' },
  filterError: [false, false],
}

export const filtersStateSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setIsFilterOpen: (state, { payload }: PayloadAction<FiltersMenuState>) => {
      state.isFilterOpen = payload
    },
    editSelectedFilters: (
      state,
      {
        payload,
      }: PayloadAction<{
        type: typeOfSelectSlice
        item: IStringOption
        isRadioBtnList?: boolean
      }>
    ) => {
      if (
        state[payload.type].filters.find(({ id }) => id === payload.item.id) &&
        !payload.isRadioBtnList
      )
        state[payload.type].filters = state[payload.type].filters.filter(
          ({ id }) => id !== payload.item.id
        )
      else if (!payload.isRadioBtnList) state[payload.type].filters.push(payload.item)
      else state[payload.type].filters = [payload.item]
    },
    toggleSelectionAll: (
      state,
      {
        payload,
      }: PayloadAction<{
        items: IStringOption[]
        type: typeOfSelectSlice
        isRadioBtnList: boolean
      }>
    ) => {
      const selectedAll = payload.items.every(({ id }) =>
        state[payload.type].filters.find(({ id: statedId }) => statedId === id)
      )
      if (selectedAll || payload.isRadioBtnList) state[payload.type].filters = []
      else state[payload.type].filters = payload.items
    },
    deleteAllSelectedFilters: state => {
      for (let item in selectedFiltersSlice) {
        state[selectedFiltersSlice[item]].filters = []
      }
    },

    deleteAllAppliedFilters: state => {
      for (let item in appliedFiltersSlice) {
        state[appliedFiltersSlice[item]] = []
      }
    },
    deleteAppliedFilters: (state, { payload }: PayloadAction<typeOfApplySlice>) => {
      state[payload] = []
    },
    deleteSelectedFilters: (state, { payload }: PayloadAction<typeOfSelectSlice>) => {
      state[payload].filters = []
    },
    clearFilter: (state, { payload }: PayloadAction<typeOfSelectSlice>) => {
      state[payload].filters = []
    },

    // save filters
    applyFiltersOnPage: (state, { payload }: PayloadAction<ISavedFiltersFromUser>) => {
      let updatedFilter

      if (!payload.filters.length) {
        for (let typeOfFilter in appliedFiltersSlice) {
          state[appliedFiltersSlice[typeOfFilter]] = []
        }
        for (let typeOfFilter in selectedFiltersSlice) {
          state[selectedFiltersSlice[typeOfFilter]].filters = []
        }
      } else {
        for (let typeOfFilter in selectedFiltersSlice) {
          if (payload.filters !== undefined) {
            updatedFilter = payload.filters.filter(
              i => i.query_param === state[selectedFiltersSlice[typeOfFilter]].title_url
            )[0]?.values as IStringOption[]

            state[selectedFiltersSlice[typeOfFilter]].filters = updatedFilter
            state[appliedFiltersSlice[typeOfFilter]] =
              state[selectedFiltersSlice[typeOfFilter]].filters
          }
        }
      }
    },

    setPageForFilters: (state, { payload }: PayloadAction<PageTitleForFilter>) => {
      state.filtersPage = payload
    },

    setStatedEnteringDate: (state, { payload }: PayloadAction<EnteringDateType>) => {
      state.enteringStoreDate.order = payload.order
      state.enteringStoreDate.type = payload.type
    },

    setFilterError: (
      state,
      { payload: { value, ind } }: PayloadAction<{ value: boolean; ind: 0 | 1 }>
    ) => {
      state.filterError[ind] = value
    },

    updateResetRadioBtnSampleStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.isResetSampleStatusSelected = payload
    },
  },
})

export const {
  reducer: filtersReducer,
  actions: {
    setIsFilterOpen,
    editSelectedFilters,
    toggleSelectionAll,
    deleteAllSelectedFilters,
    deleteAllAppliedFilters,
    deleteAppliedFilters,
    deleteSelectedFilters,
    clearFilter,
    applyFiltersOnPage,
    setPageForFilters,
    setStatedEnteringDate,
    setFilterError,
    updateResetRadioBtnSampleStatus,
  },
} = filtersStateSlice
