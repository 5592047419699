import React from 'react'
import { Typography } from '@mui/material'

import { Chip } from '@components/shared'
import { useAppSelector } from '@hooks/redux'
import { CANCELED } from '@constants/packages'
import { selectPackagesState } from '@state/packages'
import { IPackage, PackageContentType } from '@models/packages'

import { StyledTableCell } from '../../../styles'

interface IDeliveryStatusProps {
  pack: IPackage
  contentType: PackageContentType
}

export const DeliveryStatus: React.FC<IDeliveryStatusProps> = React.memo(
  ({ pack }) => {
    const { canceledOutgoingPackages } = useAppSelector(selectPackagesState)

    const isPackageCancelled = canceledOutgoingPackages.includes(pack.id)

    return (
      <StyledTableCell>
        {pack.delivery_status.key === 'not_sent' ? (
          <Typography color='8F929B'>{pack.delivery_status.value}</Typography>
        ) : (
          <Chip
            status={isPackageCancelled ? CANCELED : pack.delivery_status.key}
            label={isPackageCancelled ? 'Canceled' : pack.delivery_status.value}
          />
        )}
      </StyledTableCell>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.pack.delivery_status.key === nextProps.pack.delivery_status.key
  }
)
