import { useAppDispatch } from '@hooks/redux'
import { ISelectedRequest } from '@models/requests'

export const useToggleRequest = (
  requestData: ISelectedRequest,
  selectCallback: (requests: ISelectedRequest[]) => {
    payload: ISelectedRequest[]
    type: string
  },
  selectedRequests: ISelectedRequest[]
) => {
  const dispatch = useAppDispatch()

  const indexOfRequest = selectedRequests
    .map(({ requestId }) => requestId)
    .indexOf(requestData.requestId)

  const selectRequestHandler = () => {
    let newCheckedRequest: ISelectedRequest[] = []

    if (indexOfRequest === -1) {
      newCheckedRequest = newCheckedRequest.concat(selectedRequests, requestData)
    } else if (indexOfRequest === 0) {
      newCheckedRequest = newCheckedRequest.concat(selectedRequests.slice(1))
    } else if (indexOfRequest === selectedRequests.length - 1) {
      newCheckedRequest = newCheckedRequest.concat(selectedRequests.slice(0, -1))
    } else if (indexOfRequest > 0) {
      newCheckedRequest = newCheckedRequest.concat(
        selectedRequests.slice(0, indexOfRequest),
        selectedRequests.slice(indexOfRequest + 1)
      )
    }

    dispatch(selectCallback(newCheckedRequest))
  }

  return { selectRequestHandler, isSelected: indexOfRequest !== -1 }
}
