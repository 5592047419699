import React from 'react'
import { Box, Typography } from '@mui/material'

import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'
import { StyledTableSampleCell, SubText } from '@styles'
import { ISample } from '@models/samples'

interface ManufacturerProps {
  report: ISample
  hideBorder?: boolean
}

export const Manufacturer: React.FC<ManufacturerProps> = ({ report, hideBorder }) => {
  const { manufacturer, brand } = report

  return (
    <StyledTableSampleCell isClickable hideBorder={hideBorder}>
      <Box>
        <Tooltip
          isPureTooltip
          maxWidth={200}
          title={manufacturer?.name || ''}
          shouldBeHidden={
            ((manufacturer && manufacturer.name.length) || 0) <= TRUNCATED_TEXT_LENGTH_S
          }
        >
          <Typography>
            {manufacturer ? stringSlice(manufacturer.name, TRUNCATED_TEXT_LENGTH_S) : '-'}
          </Typography>
        </Tooltip>

        <Tooltip
          title={brand?.name || ''}
          shouldBeHidden={((brand && brand.name.length) || 0) <= TRUNCATED_TEXT_LENGTH_S}
        >
          <SubText>{brand ? stringSlice(brand.name, TRUNCATED_TEXT_LENGTH_S) : '-'}</SubText>
        </Tooltip>
      </Box>
    </StyledTableSampleCell>
  )
}
