import { Box, styled } from '@mui/material'

export const ImageBox = styled(Box)`
  display: flex;
  justify-content: center;
`

export const Container = styled(Box)`
  width: 480px;
`

export const Image = styled('img')`
  display: block;
  width: 174px;
  height: 174px;
  margin: auto;
  margin-bottom: 16px;
  box-shadow: 0px 0px 9.00783px rgba(20, 50, 114, 0.149);
  border-radius: 6px;

  align-self: center;
  object-fit: none;
`

export const LogInLink = styled('a')`
  padding: 13px 22px;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`
