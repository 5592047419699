import React from 'react'

import { useToggle } from '@hooks/common'
import { TestResultsModal } from '@components/modules/Tests/components/contents/TestContent/components/Test/components/steps/Testing/components'

import { Notes } from './Notes'
import { Dosage } from './Dosage'
import { APIsCell } from './APIsCell'
// import { Toxicity } from './Toxicity'
// import { Temperature } from './Temperature'
import { ExcipientsCell } from './ExcipientsCell'
import { SampleID } from './SampleID'
import { DrugClass } from './DrugClass'
import { Manufacturer } from './Manufacturer'
import { ExpirationDateCell } from './ExpirationDateCell'
import { ActionCell } from './ActionCell'
import { TaskId } from './TaskId'
import { TestType } from './TestType'
import { Result } from './Result'
import { TestingDate } from './TestingDate'
import { ApisPercentage } from './ApisPercentage'
import { Units } from './Units'
import { TasksCellsProps } from './tasksCells.types'

export const TasksCells: React.FC<TasksCellsProps> = props => {
  const { task, isAwaitingContentType, isLastTask, contentType } = props

  const [isResultsModalOpen, setIsResultsModalOpen] = useToggle()

  const cells = [
    Manufacturer,
    Notes,
    DrugClass,
    Dosage,
    Units,
    APIsCell,
    ExcipientsCell,
    ExpirationDateCell,
  ]

  return (
    <>
      <TaskId task={task} />
      <TestType task={task} />

      {!isAwaitingContentType && <Result buttonOnClick={setIsResultsModalOpen} task={task} />}

      <SampleID task={task} />

      {/* ----- THESE TWO FIELDS BELOW MAY BE ADDED IN THE FUTURE ----- */}
      {/*{isHasToxicity && <Toxicity task={task} />}*/}
      {/*{isHasTemperature && <Temperature task={task} />}*/}
      {/* ------------------------------------------------------------- */}

      {!isAwaitingContentType && (
        <>
          <ApisPercentage task={task} />
          <TestingDate task={task} />
        </>
      )}

      {cells.map((Cell, idx) => (
        <Cell key={idx} task={task} contentType={contentType} />
      ))}

      <ActionCell
        task={task}
        isAwaitingContentType={isAwaitingContentType}
        contentType={contentType}
        isLastTask={isLastTask}
      />

      {task && isResultsModalOpen && (
        <TestResultsModal
          editTestResult
          testGroupName={task.test_type.group.name}
          taskId={task.id}
          taskApis={task.main_apis}
          onClose={setIsResultsModalOpen}
          resultBtnName={task.available_result}
        />
      )}
    </>
  )
}
