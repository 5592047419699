import React from 'react'
import { useFormContext } from 'react-hook-form'

import { TEST_NOTES, TEST_FOR_PREPARATION, TEST_PREPARATION } from '@constants/tests'
import { AvailableSectionSettings } from '@models/tasks'
import { Input } from '@components/shared'
import { StyledLabel } from '@components/modules/LoginForm/styles'
import { MAX_CHARACTERS } from '@constants/validation'

import { StyledTitle, StyledResultsBox } from '../styles'
import { CommonNotesPropTypes } from '../screeningEditResults.types'

export const CommonNotes: React.FC<CommonNotesPropTypes> = ({ name, placeholder }) => {
  const {
    getValues,
    register,
    formState: { errors },
  } = useFormContext()
  const { task } = getValues()

  const { ordering_key: order, title } = React.useMemo(() => {
    if (name === TEST_NOTES) {
      return task.available_section_settings.find(
        (item: AvailableSectionSettings) => item.section.name === TEST_NOTES
      )
    } else if (name === TEST_PREPARATION) {
      return task.available_section_settings.find(
        (item: AvailableSectionSettings) => item.section.name === TEST_FOR_PREPARATION
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledResultsBox $mb={24} $order={order}>
      <StyledTitle>{`${order}. ${title}`}</StyledTitle>
      <StyledLabel
        label='Notes'
        $isResize
        sx={{ marginTop: 0 }}
        control={
          <Input
            width='100%'
            maxWidth='100%'
            variant='outlined'
            multiline
            height={34}
            rows={5}
            placeholder={placeholder || 'Type here...'}
            error={!!errors.fields?.[name]?.message}
            helperText={errors.fields?.[name]?.message}
            {...register(`fields.${name}`, {
              maxLength: {
                value: 200,
                message: MAX_CHARACTERS(200),
              },
            })}
          />
        }
      />
    </StyledResultsBox>
  )
}
