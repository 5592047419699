import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '@hooks/redux'
import { filtersAside, setIsFilterOpen } from '@state/filters'
import { FilterMenuCloseIcon, FilterMenuOpenIcon } from '@components/icons'

import { OpenButton } from './styles'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

export const FilterAsideButton = () => {
  const dispatch = useDispatch()
  const isFilterOpen = useAppSelector(filtersAside)
  const isMobile = useMediaQuery('(max-width:767px)')

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (e: React.KeyboardEvent | React.MouseEvent) => {
      if (
        e.type === 'keydown' &&
        ((e as React.KeyboardEvent).key === 'Tab' || (e as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      dispatch(setIsFilterOpen({ ...isFilterOpen, [anchor]: open }))
    }

  const renderOpenFilter = React.useMemo(() => {
    if (!isMobile && !isFilterOpen.right) {
      return <FilterMenuOpenIcon />
    } else if (!isMobile && isFilterOpen.right) {
      return <FilterMenuCloseIcon />
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterOpen.right, isMobile])

  return (
    <OpenButton
      mr={3}
      onClick={toggleDrawer('right', !isFilterOpen.right)}
      style={{ cursor: 'pointer' }}
    >
      {isMobile && (
        <Box mr='10px'>
          <FilterMenuOpenIcon />
        </Box>
      )}
      <Typography variant={isMobile ? 'h5' : 'subtitle1'} fontWeight={500}>
        Filter
      </Typography>
      {renderOpenFilter}
    </OpenButton>
  )
}
