import { styled, Drawer, Box, DrawerProps, BoxProps } from '@mui/material'

interface StyledDrawerHeaderProps extends DrawerProps {
  height: string
  $isMobile: boolean
}

export const StyledDrawer = styled<React.FC<StyledDrawerHeaderProps>>(Drawer)`
  .MuiPaper-elevation {
    top: 0;
    right: 0;
    width: ${({ $isMobile }) => ($isMobile ? '100%' : '265px')};
    height: ${({ height }) => height};
    box-shadow: -5px 0 15px rgba(53, 108, 151, 0.12) !important;
    overflow: hidden;
    border-color: none !important;
  }

  .MuiBackdrop-root {
    background-color: transparent;
  }

  .MuiTypography-h4 {
    font-size: 1.125rem;
    line-height: 1.25;
  }
`

export const StyledDrawerHeader = styled(Box)`
  padding: 18px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: -5px 0 15px rgba(53, 108, 151, 0.12);
  z-index: 30;

  .MuiTypography-h4 {
    font-size: 1.125rem;
    margin-bottom: 1px;
  }

  .MuiIconButton-root {
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border: 50%;
    overflow: hidden;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background-color: rgb(37, 106, 160);
    }

    img {
      filter: brightness(0) invert(1);
      transform: rotate(180deg);
    }
  }
`

export const OpenButton = styled(Box)`
  display: flex;
  align-items: center;

  & .MuiTypography-root {
    margin-right: 24px;
    font-weight: 500;
  }

  .MuiIconButton-root {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #e5e5e6;
    border: 50%;
    overflow: hidden;
  }
`

export const ContentBox = styled(Box)`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 7px;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #e5e9f1;
    border-radius: 72px;
    margin: 24px 0;
  }

  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #adb4c2;
  }
`

interface ButtonBoxProps {
  $isMobile: boolean
}

export const ButtonBox = styled<React.FC<ButtonBoxProps>>(Box)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: ${({ $isMobile }) => ($isMobile ? '0 10px 20px 10px' : '0 10px')};
  height: ${({ $isMobile }) => ($isMobile ? '120px' : '70px')};

  box-shadow: -5px 0px 15px rgba(53, 108, 151, 0.12);
`
interface StyledFilterBoxProps extends BoxProps {
  zIndex?: string
}

export const StyledFilterBox = styled<React.FC<StyledFilterBoxProps>>(Box)`
  margin: 4px 8px 4px 0;
  padding: 9px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #e1e7f3;
  border-radius: 28px;
  background-color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: ${({ zIndex }) => zIndex};
`

export const TooltipScrollContent = styled(Box)`
  max-height: 160px;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 2px;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #e5e9f1;
    border-radius: 72px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #adb4c2;
  }
`
