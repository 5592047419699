import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'

import { IAttachmentList, IAttachments } from '@models/attachments'
import { useAddAttachments, useRemoveAttachments } from '@hooks/queries'
import { StyledDetailsLabel, SubText } from '@styles'
import { Input } from '@components/shared/sampleFields'
import { MAX_CHARACTERS } from '@constants/validation'
import { Checkbox } from '@components/shared'
import { useAppSelector } from '@hooks/redux'
import { isResultRecognizedTandemMSCheckboxState, setIsResultNotRecognized } from '@state/test'
import { TEST_NOTES } from '@constants/tests'

import { Attachment } from './components'
import { StyledCheckboxBox, StyledInfoChip, StyledTitle } from './components/Attachment/styles'
import { JSONField } from './components/Fields/JSONField'
import { AttachedMaterialsTandemMS } from './components/Fields/AttachedMaterialsTandemMS'

interface AttachmentsSectionTandemMSProps {
  taskId: number
  attachments: IAttachments
  setAttachments: React.Dispatch<React.SetStateAction<IAttachments>>
  attachmentList: IAttachmentList
}

export const AttachmentsSectionTandemMS: React.FC<AttachmentsSectionTandemMSProps> = props => {
  const { taskId, attachmentList, attachments, setAttachments } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  const attachmentsBySection = (section: number) =>
    attachments.filter(
      ({ attachment_section: attachmentSection }) =>
        attachmentSection.key === attachmentList[section].section
    )

  const addAttachmentsM = useAddAttachments()
  const dispatch = useDispatch()
  const isResultNotRecognized = useAppSelector(isResultRecognizedTandemMSCheckboxState)

  const removeAttachmentsM = useRemoveAttachments(taskId)
  const removeAttachment = (id: string) => removeAttachmentsM.mutate(id)

  const handleCheckboxClick = () => {
    dispatch(setIsResultNotRecognized(!isResultNotRecognized))
  }

  return (
    <Box className='test-body'>
      {/* -----------------1------------------ */}
      {attachmentList[0].title && (
        <Box mb='35px' width='500px'>
          <StyledTitle variant='subtitle1'>
            {`${attachmentList[0].counter}. ${attachmentList[0].title}`}
            <StyledInfoChip>JSON</StyledInfoChip>
          </StyledTitle>
          <SubText style={{ fontSize: 14 }}>
            Copy and paste your JSON test report to automatically convert and organize structured
            data.
          </SubText>
        </Box>
      )}

      <StyledCheckboxBox>
        <Checkbox checked={isResultNotRecognized} onChange={handleCheckboxClick}></Checkbox>
        Substances were not recognized
      </StyledCheckboxBox>

      <Box display={isMobile ? '' : 'flex'} mb='40px'>
        <Box width='100%' mb={isMobile ? '24px' : ''}>
          {/* JSON Field */}
          <JSONField isResultNotRecognized={isResultNotRecognized} />
        </Box>

        {/* Attachments */}
        {attachments && (
          <Attachment
            key={attachmentList[0].counter}
            {...attachmentList[0]}
            taskId={taskId}
            attachments={attachmentsBySection(0)}
            setAttachments={setAttachments}
            addAttachmentsM={addAttachmentsM}
            width='100%'
            ml='15px'
            height='226px'
            testStepWithNotes
            testStepWithNotesText='Attachments'
          />
        )}
      </Box>

      <AttachedMaterialsTandemMS
        attachmentsBySection={attachmentsBySection(0)}
        removeAttachment={removeAttachment}
      />

      {/* -----------------2------------------ */}
      {attachmentList[1].title && (
        <Typography variant='h4' component='h4'>
          {`${attachmentList[1].counter}. ${attachmentList[1].title}`}
        </Typography>
      )}

      <Box display={isMobile ? '' : 'flex'} mb='40px'>
        <Box width='100%' mb={isMobile ? '24px' : ''}>
          {/* Notes Field */}
          <StyledDetailsLabel mb='9px'>Notes</StyledDetailsLabel>
          <Input
            name={TEST_NOTES}
            rows={isMobile ? 6 : 9}
            multiline
            rules={{
              maxLength: {
                value: 200,
                message: MAX_CHARACTERS(200),
              },
            }}
            mb='8px'
            mrErr='0'
            width={'100%'}
            placeholder='Type notes here...'
          />
        </Box>

        {/* Attachments */}
        {attachments && (
          <Attachment
            key={attachmentList[1].counter}
            {...attachmentList[1]}
            taskId={taskId}
            attachments={attachmentsBySection(1)}
            setAttachments={setAttachments}
            addAttachmentsM={addAttachmentsM}
            width='100%'
            ml='15px'
            height='226px'
            testStepWithNotes
            testStepWithNotesText='Attachments'
          />
        )}
      </Box>

      <AttachedMaterialsTandemMS
        attachmentsBySection={attachmentsBySection(1)}
        removeAttachment={removeAttachment}
      />
    </Box>
  )
}
