import React from 'react'
import fileSize from 'filesize'
import { Box, Icon, useMediaQuery } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { FileRejection } from 'react-dropzone'

import mailIcon from '@assets/icons/mail-icon.svg'
import { Modal, Button, AttachedMaterialItem, Input } from '@components/shared'
import {
  ButtonBox,
  StyledModalTitle,
  StyledShadowBox,
  StyledModalContent,
  StyledLabel,
  StyledModalScroll,
} from '@styles'
import {
  TRUNCATED_ATTACHED_MODAL_MOBILE_TEXT,
  TRUNCATED_ATTACHED_MODAL_TEXT,
} from '@constants/common'
import { IAttachment, IAttachments } from '@models/attachments'
import { REQUIRED_FIELD } from '@constants/validation'
import { useToggle } from '@hooks/common'
import { IFormFeedbackFields } from '@models/common/user'
import { useSendFeedbackForm } from '@hooks/queries/useSendFeedbackForm'
import { ATTACHMENT_FEEDBACK_SECTION } from '@constants/tests'
import { MAX_NUMBER_OF_ATTACHMENTS_FEEDBACK } from '@constants/user'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'

import {
  FeedbackLink,
  StyledShadowBoxTitle,
  StyledLabel as StyledLabelDesc,
  SymbolLengthCounter,
} from './styles'
import { NotificationModal } from './NotificationModal'
import { AttachFile } from './AttachFile'

interface FeedbackFormProps {
  toggleModal: () => void
  errorPage?: boolean
}

export const FeedbackForm: React.FC<FeedbackFormProps> = ({ toggleModal, errorPage }) => {
  const formMethods = useForm<IFormFeedbackFields>()
  const { isTenantType } = useTenant()
  const isLegalDrugs = isTenantType(TENANT_TYPE.legalDrugs)
  const isMobile = useMediaQuery('(max-width:767px)')
  const [isNotificationModalOpen, toggleNotificationModal] = useToggle()
  const [sentFormStatus, setSentFormStatus] = React.useState('')

  const [attachmentsFromDrop, setAttachmentsFromDrop] = React.useState<File[]>([])
  const [attachments, setAttachments] = React.useState<IAttachments>([])

  const { errors } = formMethods.formState

  const sendFeedbackM = useSendFeedbackForm()

  const removeAttachment = (id: string, key: string) => {
    const deleteAttach = attachments.filter(attachment => attachment.id !== id)
    const deleteFile = deleteAttach
      .map(file => attachmentsFromDrop.filter(i => i.name === file.name))
      .flat()

    setAttachments(deleteAttach)
    setAttachmentsFromDrop(deleteFile)
  }

  const isAppropriateSizeOfAllFiles =
    attachmentsFromDrop.map(item => item.size).reduce((a, b) => a + b, 0) < 25000000

  const subjectData = formMethods.watch('subject')
  const descriptionData = formMethods.watch('description')

  const onDropHandler = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const attachmentIds: string[] = []
    const hasAddingError = !!fileRejections.length

    acceptedFiles.forEach(file => {
      const attachmentId = uuid()
      attachmentIds.push(attachmentId)

      const attachment: IAttachment = {
        id: attachmentId,
        name: file.name,
        withoutDownload: true,
        loading: false,
        size: `${fileSize(file.size)}`,
        attachment_section: {
          key: ATTACHMENT_FEEDBACK_SECTION,
          value: ATTACHMENT_FEEDBACK_SECTION,
        },
      }

      if (!hasAddingError) {
        setAttachments(prev => [...prev, attachment])
        setAttachmentsFromDrop(prev => [...prev, file])
      }
    })
  }

  const closeModals = () => {
    toggleNotificationModal()
    toggleModal()
  }

  const sendFeedbackHandler = (data: IFormFeedbackFields) => {
    const formData: FormData = new FormData()

    attachmentsFromDrop.forEach(file => {
      Object.entries({ file }).forEach(([key, value]) => formData.append(key, value))
    })

    formData.append('subject', data.subject)
    formData.append('description', data.description)

    sendFeedbackM.mutate(formData, {
      onSuccess: () => {
        setSentFormStatus('success')
        toggleNotificationModal()
      },
    })
  }

  if (sendFeedbackM.error && sendFeedbackM.error?.status !== 400) {
    setSentFormStatus('error')
    toggleNotificationModal()
  }

  if (sentFormStatus === 'error') {
    return (
      <>
        {isNotificationModalOpen && <NotificationModal variant={'error'} onClose={closeModals} />}
      </>
    )
  }

  if (sentFormStatus === 'success') {
    return (
      <>
        {isNotificationModalOpen && <NotificationModal variant={'success'} onClose={closeModals} />}
      </>
    )
  }

  const shouldDisableSubmitBtn =
    !!errors.description ||
    !!errors.subject ||
    !!errors.file ||
    descriptionData?.trim().length === 0 ||
    subjectData?.trim().length === 0

  return (
    <Modal btnZIndex={500} isPaddingAbsence open onClose={toggleModal} noYScroll>
      <Box>
        <StyledShadowBoxTitle $isMobile={isMobile}>
          <StyledModalTitle variant='h3' width='100%'>
            Any questions or ideas for improving {isLegalDrugs ? 'Verify' : 'Screenr'}?
          </StyledModalTitle>
          <StyledModalTitle variant='h5'>
            Please fill out the form below or contact us directly via email:
          </StyledModalTitle>

          <Box display='flex' alignItems='center' justifyContent='center'>
            <Icon style={{ marginRight: 11, minWidth: 20 }}>
              <img src={mailIcon} alt='letter icon' />
            </Icon>

            <FeedbackLink
              href='mailto:verify.usercare@artifactsofresearch.io'
              style={isMobile ? { fontSize: 14 } : {}}
            >
              verify.usercare@artifactsofresearch.io
            </FeedbackLink>
          </Box>
        </StyledShadowBoxTitle>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(sendFeedbackHandler)}>
            <StyledModalContent pt='0' $isMobile={isMobile}>
              <StyledModalScroll width='85%' height='45vh' position='relative' $isMobile={isMobile}>
                <StyledLabel
                  label='Subject'
                  $feedbackForm
                  mb='0'
                  control={
                    <Input
                      width='100%'
                      variant='outlined'
                      type='text'
                      autoFocus
                      mb='6px'
                      inputProps={{
                        maxLength: 200,
                      }}
                      placeholder='Type here subject...'
                      error={!!errors.subject?.message}
                      helperText={errors.subject?.message}
                      {...formMethods.register('subject', {
                        required: REQUIRED_FIELD,
                      })}
                    />
                  }
                />
                <SymbolLengthCounter>
                  {subjectData ? subjectData.trim().length : 0}/200
                </SymbolLengthCounter>

                <StyledLabelDesc>Description</StyledLabelDesc>
                <Input
                  width='100%'
                  variant='outlined'
                  rows={6}
                  multiline={true}
                  type='text'
                  autoFocus
                  mb='6px'
                  placeholder='Type here description...'
                  error={!!errors.description?.message}
                  helperText={errors.description?.message}
                  inputProps={{
                    maxLength: 1000,
                  }}
                  {...formMethods.register('description', {
                    required: REQUIRED_FIELD,
                  })}
                />
                <SymbolLengthCounter>
                  {descriptionData ? descriptionData.trim().length : 0}/1000
                </SymbolLengthCounter>

                <Box mt={isMobile ? '16px' : '40px'}>
                  Attachments
                  <SymbolLengthCounter display='inline-block' sx={{ marginLeft: '8px' }}>
                    {attachments ? attachments.length : 0}/4
                  </SymbolLengthCounter>
                </Box>

                {/* AttachFile */}
                <AttachFile
                  onDrop={onDropHandler}
                  addingError={sendFeedbackM.error}
                  attachmentsLength={attachments.length}
                  shouldDisabled={!(MAX_NUMBER_OF_ATTACHMENTS_FEEDBACK - attachments.length)}
                  isAppropriateSizeOfAllFiles={isAppropriateSizeOfAllFiles}
                />

                <Box mt='16px'>
                  {attachments?.map((attachment: IAttachment) => {
                    return (
                      <React.Fragment key={uuid()}>
                        <AttachedMaterialItem
                          truncatedTextLength={
                            isMobile
                              ? TRUNCATED_ATTACHED_MODAL_MOBILE_TEXT
                              : TRUNCATED_ATTACHED_MODAL_TEXT
                          }
                          attachment={attachment}
                          removeAttachment={removeAttachment}
                          disabled={false}
                        />
                      </React.Fragment>
                    )
                  })}
                </Box>
              </StyledModalScroll>
            </StyledModalContent>

            <StyledShadowBox $isMobile={isMobile}>
              <ButtonBox sx={{ justifyContent: 'center' }} $isMobile={isMobile}>
                <Button
                  type='button'
                  variant='outlined'
                  width='225px'
                  height='48px'
                  mb={isMobile ? '16px' : '0'}
                  onClick={toggleModal}
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  width='225px'
                  height='48px'
                  ml={isMobile ? '0' : '28px'}
                  disabled={(shouldDisableSubmitBtn && !errorPage) || !isAppropriateSizeOfAllFiles}
                  loading={sendFeedbackM.isLoading}
                >
                  Submit
                </Button>
              </ButtonBox>
            </StyledShadowBox>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  )
}
