import React from 'react'
import { Box, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox, Tooltip } from '@components/shared'
import { ITestTypesInfo } from '@models/yourWorkspace'
import { ASSAY_GROUP, SCREENING_GROUP, VISUAL_GROUP } from '@models/tests'
import { useFetchTestTypes } from '@hooks/queries'
import { stringSlice } from '@helpers/ui/common'

import { StyledOptionTypography } from './styles'

interface TestTypeProps {
  name: string
  selectedTests?: ITestTypesInfo[]
}

export const TestType: React.FC<TestTypeProps> = props => {
  const { name, selectedTests = [] } = props
  const { control, setValue } = useFormContext()
  const { data: testTypesData } = useFetchTestTypes(true)
  const options = testTypesData?.map(i => i.name) || []
  const selectedOptions = selectedTests.map(i => i.name)
  const allOptions = testTypesData || []

  const [selected, setSelected] = React.useState<string[]>(selectedOptions)
  const [error, setError] = React.useState<boolean>(false)

  const isAllSelected = options.length > 0 && selected.length === options.length

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options.length ? [] : options)
      return
    }
    setSelected(value as string[])
  }

  const test_types = allOptions?.filter(item => selected.includes(item.name)).map(({ id }) => id)

  React.useEffect(() => {
    // Max 50 tests
    if (test_types.length > 50) {
      setError(true)
    } else setError(false)

    setValue(name, test_types)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, test_types])

  const screeningTests = allOptions
    .filter(option => option.group?.name === SCREENING_GROUP)
    .map(i => i.name)

  const assayTests = allOptions
    .filter(option => option.group?.name === ASSAY_GROUP)
    .map(i => i.name)

  const visualInspection = allOptions
    .filter(option => option.group?.name === VISUAL_GROUP)
    .map(i => i.name)

  return (
    <div>
      <Controller
        render={() => {
          return (
            <Select
              fullWidth
              multiple
              value={selected}
              onChange={handleChange}
              sx={{ border: error ? '1px solid #AF4645' : 'none' }}
              renderValue={selected => (
                <Box display='flex' justifyContent='space-between'>
                  <Tooltip
                    isPureTooltip
                    title={selected.join(', ') || ''}
                    shouldBeHidden={(selected.join(', ').length || 0) <= 60}
                    maxWidth={200}
                  >
                    <Typography>{stringSlice(selected.join(', '), 60)}</Typography>
                  </Tooltip>

                  <Typography fontWeight={500}>({selected.length})</Typography>
                </Box>
              )}
            >
              <MenuItem value='all' sx={{ padding: '10px 0 10px 24px' }}>
                <Checkbox
                  sx={{ padding: '0px 9px 0 0' }}
                  checked={isAllSelected}
                  indeterminate={selected.length > 0 && selected.length < options.length}
                />
                <StyledOptionTypography>
                  {isAllSelected ? 'Clear' : 'Select'} all
                </StyledOptionTypography>
              </MenuItem>
              {/* Visual inspection test */}

              {visualInspection.map(item => {
                return (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={selected.indexOf(item) > -1} />
                    <ListItemText primary={item} />
                  </MenuItem>
                )
              })}

              {/* Screening test */}
              {!!screeningTests.length && (
                <Typography padding='8px 24px' fontWeight={500}>
                  Screening test
                </Typography>
              )}
              {screeningTests.map(item => {
                return (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={selected.indexOf(item) > -1} />
                    <ListItemText primary={item} />
                  </MenuItem>
                )
              })}

              {/* Assay test */}
              {!!assayTests.length && (
                <Typography padding='8px 24px' fontWeight={500}>
                  Assay test
                </Typography>
              )}
              {assayTests.map(item => {
                return (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={selected.indexOf(item) > -1} />
                    <ListItemText primary={item} />
                  </MenuItem>
                )
              })}
            </Select>
          )
        }}
        control={control}
        name={name}
      />

      {error && (
        <Typography fontSize='12px' color='#AF4645'>
          Max 50 tests
        </Typography>
      )}
    </div>
  )
}
