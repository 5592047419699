import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { QueryObserverResult } from 'react-query'

import { IHrTaskLab } from '@models/tasks'
import { protectedRoutes } from '@configs/routes.config'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { completeTest } from '@state/tasks'
import { StepMobile, AttachmentsNotification } from '@components/shared'
import { SCREENING_GROUP, TandemMS } from '@models/tests'
import { isAssistantAddedNewTandemMSTest, setIsResultNotRecognized } from '@state/test'
import { IError } from '@models/common/app'

import { Testing, Attachments } from './components'

interface TestProps {
  task: IHrTaskLab
  refetchTask: () => Promise<QueryObserverResult<IHrTaskLab, IError>>
}

export const Test: React.FC<TestProps> = ({ task, refetchTask }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const isMobile = useMediaQuery('(max-width:767px)')
  const isScreeningGroup = task.test_type.group.name === SCREENING_GROUP
  const isTwoStepsInTest = isScreeningGroup
  const checkTest = task.step <= 2

  const isAddedNewTandemMSTest = useAppSelector(isAssistantAddedNewTandemMSTest)
  const isResultSectionOpen = isAddedNewTandemMSTest ? isAddedNewTandemMSTest : task.step === 3
  const isTandemMS = task.test_type.name === TandemMS

  const steps = [
    {
      id: 1,
      component: Testing,
      isOpen: checkTest,
      actualStep: 1,
      shouldRender: true,
    },
    {
      id: 2,
      component: Attachments,
      isOpen: isResultSectionOpen,
      actualStep: 2,
      shouldRender: true,
    },
  ]

  React.useEffect(() => {
    if (task.step === 4 && !isTandemMS) {
      history.push(protectedRoutes.tasks.path)

      // dispatch the task id for show the notification on the Awaiting list of tasks
      dispatch(completeTest(task.id))
    }

    return () => {
      dispatch(setIsResultNotRecognized(false))
    }
  }, [task.step, history, dispatch, task.id, isTandemMS])

  return (
    <>
      {isMobile && (
        <StepMobile
          taskStep={task.step}
          testTypeName={task.test_type.name}
          actualStep={isTwoStepsInTest ? 2 : 3}
          isTwoStepsInTest={isTwoStepsInTest}
        />
      )}
      {steps.map(({ component: Step, id, ...restProps }) => (
        <Step key={id} {...restProps} task={task} />
      ))}
      {!isMobile && <AttachmentsNotification task={task} />}
    </>
  )
}
