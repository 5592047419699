import React from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { TableHeader } from '@components/modules/SampleCreate/components/TableHeader'
import { StyledTableBox } from '@styles'
import { HorizontalDragCup, HorizontalDragLayer } from '@components/shared'
import { TableRow } from '@components/modules/SampleCreate/components/TableRow'
import { useAppSelector } from '@hooks/redux'
import { useActionsStickyBox } from '@hooks/common'
import { cleanFlags } from '@state/common/sampleCreateUIState'
import { draggableHorizontalScroll } from '@helpers/draggableHorizontalScroll'
import { sampleCreateIds, setCleanStoreSampleCreateId, setSampleCreateId } from '@state/app'
import { cleanStore, createSample } from '@state/sampleCreate'

import { RemovedApisFromStore } from './RemovedApisFromStore'
import { TablePropTypes } from '../sampleCreate.types'

export const Table: React.FC<TablePropTypes> = ({
  draggableHorizontalRef,
  control,
  clearErrors,
}) => {
  const tableHeadRef = React.useRef<HTMLDivElement | null>(null)
  const horizontalDragCup = React.useRef<HTMLDivElement | null>(null)
  const draggableHorizontalLayerRef = React.useRef<HTMLDivElement | null>(null)
  // TODO: Try to understand, why do not work correctly hook below
  const isSticky = useActionsStickyBox(tableHeadRef, '-450px 0px 0px 0px')
  const sampleIds = useAppSelector(sampleCreateIds)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!sampleIds.length) {
      const id = uuid()
      dispatch(createSample(id))
      dispatch(setSampleCreateId(id))
    }

    return () => {
      const id = uuid()
      dispatch(cleanStore(id))
      dispatch(setCleanStoreSampleCreateId(id))
      dispatch(cleanFlags())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (draggableHorizontalRef?.current) {
      const { removeDraggableEvents } = draggableHorizontalScroll(
        draggableHorizontalRef,
        draggableHorizontalLayerRef,
        horizontalDragCup
      )
      return () => removeDraggableEvents()
    }
  }, [draggableHorizontalRef])

  const horizontalScrollHandler: React.UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    tableHeadRef.current?.scrollTo(currentTarget.scrollLeft, 0)
    let pureDropdownAutocomplete = document.getElementById('pure-dropdown-options-list')
    if (pureDropdownAutocomplete) {
      pureDropdownAutocomplete.style.transform = `translateX(-${currentTarget.scrollLeft}px)`
    }
  }

  return (
    <>
      <TableHeader isSticky={isSticky} ref={tableHeadRef} />
      <HorizontalDragCup ref={horizontalDragCup} />
      <StyledTableBox ref={draggableHorizontalRef} onScroll={horizontalScrollHandler}>
        <HorizontalDragLayer ref={draggableHorizontalLayerRef} />
        <>
          {sampleIds.map((sampleId, idx) => (
            <TableRow
              control={control}
              clearErrors={clearErrors}
              key={`${sampleId}_${idx}`}
              rowNumber={sampleIds.length - (idx + 1)}
              sampleId={sampleId}
              isLastSample={sampleIds.length === idx + 1}
              idx={idx}
            />
          ))}
        </>
      </StyledTableBox>
      {/* A component that checks if Apis have been removed from the sample store */}
      <RemovedApisFromStore />
    </>
  )
}
