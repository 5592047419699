import { useMediaQuery } from '@mui/material'

import { IStatisticData, IWidgetData } from '@models/testingReports'
import { Widget } from '@components/shared'

import * as Styled from '../styles'
import { totalCount } from '../helpers/helpers'

interface StatisticDataProps {
  statistic?: IStatisticData
  isFilterApplied: boolean
}

export const TestsStatistic: React.FC<StatisticDataProps> = ({ statistic, isFilterApplied }) => {
  const isLaptop = useMediaQuery('(max-width:1300px)')

  const totalTasks =
    isFilterApplied && statistic
      ? totalCount(statistic.task_results) + totalCount(statistic.task_statuses)
      : statistic?.tests_count || 0

  const percent = (name: string) => {
    let resultWithPercentStatuses = 0
    let resultWithPercentResults = 0

    if (statistic)
      if (name === 'awaiting' || name === 'in_progress') {
        // for task_statuses
        resultWithPercentStatuses = (statistic?.task_statuses[name] / totalTasks) * 100
      } else {
        // for task_results
        resultWithPercentResults = (statistic?.task_results[name] / totalTasks) * 100
      }

    // view for task_statuses
    if (resultWithPercentStatuses < 1 && !!statistic?.task_statuses[name]) return '< 1%'
    else if (resultWithPercentStatuses > 1 && !!statistic?.task_statuses[name])
      return `${Math.round(resultWithPercentStatuses)}%`

    // view for task_results
    if (resultWithPercentResults < 1 && !!statistic?.task_results[name]) return '< 1%'
    else if (resultWithPercentResults > 1 && !!statistic?.task_results[name])
      return `${Math.round(resultWithPercentResults)}%`

    return '-'
  }

  const data: IWidgetData[] = [
    {
      name: 'Awaiting',
      value: statistic?.task_statuses.awaiting || 0,
      percent: percent('awaiting'),
      fill: '#8DDEFD',
    },
    {
      name: 'In progress',
      value: statistic?.task_statuses.in_progress || 0,
      percent: percent('in_progress'),
      fill: '#FFCA67',
    },
    {
      name: 'Passed/Okay',
      value: statistic?.task_results.positive || 0,
      percent: percent('positive'),
      fill: '#5FD07C',
    },
    {
      name: 'Failed/Suspicious',
      value: statistic?.task_results.negative || 0,
      percent: percent('negative'),
      fill: '#FF8B8B',
    },
  ]

  return (
    <Styled.MainBox width='40%' $tests $isLaptop={isLaptop}>
      <Widget data={data} widgetTitle='Tests' legendWidth='200px' totalCount={totalTasks} />
    </Styled.MainBox>
  )
}
