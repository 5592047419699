import React, { useEffect } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { TabContext } from '@mui/lab'

import { IYourWorkspaceTabValue } from '@models/yourWorkspace'

import { TabButtons } from './TabButtons/TabButtons'

export const Tabs = () => {
  // ! workspace_type - check naming for BE
  const [queryString, setQueryString] = useQueryParam('workspace_type', StringParam)

  let queryTab: IYourWorkspaceTabValue

  switch (queryString) {
    case 'contact_info':
      queryTab = 'contact_info'
      break

    case 'users':
      queryTab = 'users'
      break

    default:
      queryTab = 'contact_info'
      break
  }

  const [currentTab, setCurrentTab] = React.useState<IYourWorkspaceTabValue>(queryTab)

  useEffect(() => {
    if (!queryString) setCurrentTab('contact_info')
  }, [queryString])

  return (
    <TabContext value={currentTab}>
      <TabButtons onChangeTab={setCurrentTab} onChangeQuery={setQueryString} />
    </TabContext>
  )
}
