import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ATTACHMENT_TEST_REPORT_SECTION, TEST_NOTES } from '@constants/tests'
import { AvailableSectionSettings } from '@models/tasks'
import { Input } from '@components/shared'
import { StyledLabel } from '@components/modules/LoginForm/styles'
import { MAX_CHARACTERS } from '@constants/validation'

import { StyledTitle, StyledResultsBox } from '../../ScreeningEditResults/styles'

export const Notes: React.FC = () => {
  const {
    getValues,
    register,
    formState: { errors },
  } = useFormContext()
  const { task } = getValues()

  const { ordering_key: order, title } = React.useMemo(() => {
    return task.available_section_settings.find(
      (item: AvailableSectionSettings) => item.section.name === ATTACHMENT_TEST_REPORT_SECTION
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledResultsBox $mb={24} $order={order}>
      <StyledTitle>{`${order}. ${title}`}</StyledTitle>
      <StyledLabel
        label='Notes'
        $isResize
        sx={{ marginTop: 0 }}
        control={
          <Input
            width='100%'
            variant='outlined'
            multiline
            height={34}
            rows={5}
            placeholder='Type notes here...'
            error={!!errors.fields?.[TEST_NOTES]?.message}
            helperText={errors.fields?.[TEST_NOTES]?.message}
            {...register(`fields.${TEST_NOTES}`, {
              maxLength: {
                value: 200,
                message: MAX_CHARACTERS(200),
              },
            })}
          />
        }
      />
    </StyledResultsBox>
  )
}
