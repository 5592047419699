import { styled, Box } from '@mui/material'
import { BoxProps } from '@mui/system'
import React from 'react'

interface StepHeaderProps extends BoxProps {
  $isMobile?: boolean
}

export const StepHeader = styled<React.FC<StepHeaderProps>>(Box)`
  display: ${({ $isMobile }) => ($isMobile ? '' : 'flex')};

  h3 {
    margin: 0 0 2px 16px;
    line-height: 1;
  }

  .MuiStepper-root .MuiDivider-root {
    display: none;
  }
`
