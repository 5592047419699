import { styled as styledMUI, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledTableTitle = styledMUI(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`

export const StyledTableCell = styled.div<{ isClickable?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.common.white};
  cursor: ${props => (props.isClickable ? 'pointer' : 'default')};
  padding: 0;

  &:nth-child(1) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 0;
      border-color: ${({ theme }) => theme.palette.background.default} transparent transparent
        transparent;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0.8px;
      left: 1px;
      width: 17px;
      height: 17px;
      border-radius: 60%;
      background-color: #ffffff;
    }
  }

  &:nth-child(2) {
    padding-left: 16px;
  }

  & .MuiOutlinedInput-input {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  & .MuiSvgIcon-fontSizeMedium {
    border-radius: 5px;
  }
`

export const StyledTableSortWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-left: 8px;
  & .MuiButtonBase-root {
    padding: 6px;
  }

  svg:nth-of-type(2) {
    margin-top: -8px;
  }
`
