import React from 'react'

import { useAppSelector } from '@hooks/redux'
import { selectPackagesState } from '@state/packages'

import { SendPackage, DownloadLabels } from './components'

export const PackageActions: React.FC = () => {
  const { selectedPackages } = useAppSelector(selectPackagesState)
  const selectedPackagesAmount = selectedPackages.length

  const actionButtons = [SendPackage, DownloadLabels]

  return (
    <>
      {actionButtons.map((ActionButton, idx) => (
        <ActionButton
          key={idx}
          selectedPackages={selectedPackages}
          selectedPackagesAmount={selectedPackagesAmount}
        />
      ))}
    </>
  )
}
