import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IUpdatedHrTestData } from '@models/tests'

export interface TestState {
  isResultNotRecognized: boolean
  isAddedNewTandemMSTest: boolean
  tandemMSTestData?: IUpdatedHrTestData | null
  isInvalidJsonApiError: boolean
  isTestUpdating: boolean
  updatedTestId: number | null
}

const initialState: TestState = {
  isResultNotRecognized: false,
  isAddedNewTandemMSTest: false,
  tandemMSTestData: null,
  isInvalidJsonApiError: false,
  isTestUpdating: false,
  updatedTestId: null,
}

export const userSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    setIsResultNotRecognized: (state, { payload }: PayloadAction<boolean>) => {
      state.isResultNotRecognized = payload
    },
    setIsAddedNewTandemMSTest: (state, { payload }: PayloadAction<boolean>) => {
      state.isAddedNewTandemMSTest = payload
    },
    setTandemMSData2Send: (state, { payload }: PayloadAction<IUpdatedHrTestData>) => {
      state.tandemMSTestData = payload
    },
    setIsInvalidJsonApiError: (state, { payload }: PayloadAction<boolean>) => {
      state.isInvalidJsonApiError = payload
    },
    setIsTestUpdating: (state, { payload }: PayloadAction<boolean>) => {
      state.isTestUpdating = payload
    },
    setTestUpdatingId: (state, { payload }: PayloadAction<number | null>) => {
      state.updatedTestId = payload
    },
    // --------------
  },
})

export const {
  reducer: testReducer,
  actions: {
    setIsResultNotRecognized,
    setIsAddedNewTandemMSTest,
    setTandemMSData2Send,
    setIsInvalidJsonApiError,
    setIsTestUpdating,
    setTestUpdatingId,
  },
} = userSlice
