import React from 'react'
import { UseMutationResult } from 'react-query'

import { Modal } from '@components/shared'
import { StyledModalTitle } from '@styles'
import { IUpdatedSample } from '@models/samples'
import { ContentOfPage } from '@constants/queries'
import { IPackage, IUpdatedPackageData } from '@models/packages'

import { EditNotesField } from '../EditNotesField'

interface EditNotesModalMobileProps {
  id: number
  notes?: string | null
  content: ContentOfPage
  toggleEditing: () => void
  withoutTitle?: boolean
  updateSampleM: UseMutationResult<unknown, unknown, IUpdatedSample, unknown>
  updatePackageM: UseMutationResult<IPackage, unknown, IUpdatedPackageData, unknown>
  labelText?: string
  notesLength?: number
}

export const EditNotesModalMobile: React.FC<EditNotesModalMobileProps> = props => {
  const {
    notes,
    id,
    toggleEditing,
    updateSampleM,
    updatePackageM,
    content,
    withoutTitle,
    labelText,
    notesLength,
  } = props

  return (
    <Modal open onClose={toggleEditing} className='testing-modal'>
      <>
        <StyledModalTitle variant='subtitle2' sx={{ marginBottom: '6px' }}>
          <b>{labelText} notes</b>
        </StyledModalTitle>
        <StyledModalTitle variant='subtitle2' sx={{ marginBottom: '14px' }}>
          <b>ID</b> {id}
        </StyledModalTitle>

        <EditNotesField
          content={content}
          notes={notes}
          notesLength={notesLength}
          id={id}
          toggleEditing={toggleEditing}
          updateSampleM={updateSampleM}
          updatePackageM={updatePackageM}
          labelText={labelText}
          withoutTitle={withoutTitle}
        />
      </>
    </Modal>
  )
}
