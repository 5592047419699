import React from 'react'
import { useQueryClient } from 'react-query'

import { useToggle } from '@hooks/common'
import { IPackage } from '@models/packages'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { showNotification } from '@helpers/app'
import { cancelOutgoingPackage } from '@state/packages'
import { notifications } from '@constants/notifications'
import { Button, ConfirmationModal } from '@components/shared'
import { useUpdatePackage } from '@hooks/queries'
import { OUTGOING_PACKAGES } from '@constants/queries'
import { selectedFiltersLength } from '@state/filters'

interface CancelProps {
  pack: IPackage
  mr?: string
}

export const Cancel: React.FC<CancelProps> = ({ pack, mr }) => {
  const [isConfirmationModalOpen, toggleConfirmationModal] = useToggle()
  const { mutate } = useUpdatePackage()
  const dispatch = useAppDispatch()
  const client = useQueryClient()
  const allFiltersLength = useAppSelector(selectedFiltersLength)
  const isFilteredPackages = Boolean(allFiltersLength)

  const confirmCancellationHandler = () => {
    mutate(
      { id: pack.id, data: { delivery_status: 'not_sent' } },
      {
        onSuccess: () => {
          dispatch(cancelOutgoingPackage(pack.id))
          if (isFilteredPackages)
            client.invalidateQueries([OUTGOING_PACKAGES, null, null, null, null, null])
        },
        onError: () => {
          showNotification(notifications.canceledPackageDeliveryFailed)
        },
      }
    )
  }

  return (
    <>
      <Button
        variant='outlined'
        width='90px'
        padding='0'
        mr={mr}
        fz={15}
        onClick={toggleConfirmationModal}
      >
        Cancel
      </Button>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          title='Cancel delivery'
          subtitle='Are you sure you want to cancel delivery?'
          cancelBtnText='No'
          confirmButtonText='Yes'
          onClose={toggleConfirmationModal}
          onConfirm={confirmCancellationHandler}
        />
      )}
    </>
  )
}
