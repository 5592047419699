import {
  styled,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material'
import React from 'react'
import PhoneInput from 'react-phone-input-2'

export const StyledBox = styled(Box)``

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  height: 48px;

  & .MuiFormControlLabel-label {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`

export const StyledRadio = styled(Radio)`
  color: ${({ theme }) => theme.palette.primary.main};

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledFieldBox = styled(Box)``

export const StyledFormControlLabel = styled<React.FC<FormControlLabelProps & { $color?: string }>>(
  FormControlLabel
)`
  & .MuiFormControlLabel-label {
    color: ${({ $color }) => $color || ''};
  }
`
export const StylePhoneInput = styled(PhoneInput)``
