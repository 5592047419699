import React from 'react'
import { Typography } from '@mui/material'

import { detailsTaskBasicTable } from '@constants/mappings'
import { StyledDetailsTableHead, StyledDetailsTableRow, StyledDetailsTableCell } from '@styles'

export const TableHead = () => {
  return (
    <StyledDetailsTableHead>
      <StyledDetailsTableRow>
        {detailsTaskBasicTable.tableHeadCells.map(({ id, content }) => (
          <StyledDetailsTableCell key={id}>
            <Typography variant='h5'>{content}</Typography>
          </StyledDetailsTableCell>
        ))}
      </StyledDetailsTableRow>
    </StyledDetailsTableHead>
  )
}
