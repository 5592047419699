import React from 'react'
import { useFormContext } from 'react-hook-form'

import { FEMALE, MALE } from '@constants/user'

import styles from './Radio.module.css'
import { StyledFormControlLabel, StyledRadio, StyledRadioGroup } from '../styles'

export const AvatarField = () => {
  const { register, getValues } = useFormContext()

  return (
    <StyledRadioGroup sx={{ marginLeft: '10px' }} aria-label='sex'>
      <StyledFormControlLabel
        sx={{ marginRight: '30px' }}
        {...register('sex')}
        label='Male'
        value={MALE}
        checked={getValues('sex') === MALE}
        $color={getValues('sex') === MALE ? '#0F1934' : '#616D84'}
        control={
          <StyledRadio
            checkedIcon={<span className={styles.checkedIcon} />}
            icon={<span className={styles.icon} />}
            sx={{ padding: '0px', paddingRight: '8px' }}
          />
        }
      />
      <StyledFormControlLabel
        sx={{ marginRight: '30px' }}
        {...register('sex')}
        label='Female'
        value={FEMALE}
        checked={getValues('sex') === FEMALE}
        $color={getValues('sex') === FEMALE ? '#0F1934' : '#616D84'}
        control={
          <StyledRadio
            checkedIcon={<span className={styles.checkedIcon} />}
            icon={<span className={styles.icon} />}
            sx={{ padding: '0px', paddingRight: '8px' }}
          />
        }
      />
    </StyledRadioGroup>
  )
}
