import { Box, styled } from '@mui/material'

export const StyledModalScroll = styled(Box)<{ height: string }>`
  position: relative;
  height: ${({ height }) => height};
  min-width: 832px;
  padding: 0 30px 24px 30px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #e5e9f1;
    border-radius: 72px;
    margin: 24px 0;
  }

  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #adb4c2;
  }
`
