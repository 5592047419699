import React from 'react'

import { ISamples } from '@models/samples'
import { useAppSelector } from '@hooks/redux'
import { selectUserCustomizeTableColumns } from '@state/user'
import { viewSampleTableWidths } from '@constants/mappings'
import { ITableColumnWidths } from '@models/common/user'

import { StyledTableHeadWrapper } from './styles'
import { SamplesToggler } from './SamplesToggler'
import { TableHeadTitles } from './TableHeadTitles'
import { StyledTableRow } from '../SampleRows/styles'

interface CheckedTableProps {
  samples: ISamples
  isSticky: boolean
  toggleCustomizeTableColumnsModalOpen: () => void
  referredTableWidth: string
}

export const TableHead = React.forwardRef<HTMLDivElement, CheckedTableProps>(
  ({ samples, isSticky, toggleCustomizeTableColumnsModalOpen, referredTableWidth }, ref) => {
    const { sample_columns } = useAppSelector(selectUserCustomizeTableColumns)

    const filteredSampleTableWidths = sample_columns
      .filter(column => column.activated)
      .map(column =>
        viewSampleTableWidths.find(cell => cell.id === column.id)
      ) as ITableColumnWidths

    return (
      <StyledTableHeadWrapper ref={ref} isSticky={isSticky}>
        <StyledTableRow
          $isBorderBottom
          sx={{ width: referredTableWidth }}
          columns={filteredSampleTableWidths}
        >
          <SamplesToggler samples={samples} isSticky={isSticky} />

          <TableHeadTitles
            toggleCustomizeTableColumnsModalOpen={toggleCustomizeTableColumnsModalOpen}
            isSticky={isSticky}
          />
        </StyledTableRow>
      </StyledTableHeadWrapper>
    )
  }
)
