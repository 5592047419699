import React from 'react'

import { ISampleTask } from '@models/samples'
import { Chip } from '@components/shared'

interface TaskStatusProps {
  task: ISampleTask
}

export const TaskStatus: React.FC<TaskStatusProps> = props => {
  const { task } = props
  if (task.test_type.is_other) return null

  return <>{task.status && <Chip status={task.status.key} label={task.status.value} />}</>
}
