import { Box, Icon, Typography, useMediaQuery, useTheme } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { TRUNCATED_TEXT_LENGTH_L, TRUNCATED_TEXT_LENGTH_S } from '@constants/common'
import {
  Brand,
  ExpandableApis,
  TaskNotesField,
  TempSensitiveToxicityIcons,
  Tooltip,
} from '@components/shared'
import { TooltipMobile } from '@components/shared/Tooltip/components'
import { getApisWithType, stringSlice } from '@helpers/ui/common'
import { SubText } from '@styles'
import { unitIconSDeterminer } from '@helpers/ui/typeOfUnits'
import { TASKS } from '@constants/queries'
import { SpecificId } from '@components/shared/sampleFields'

import { StepSidebarRow } from '../steps/Testing/styles'

interface InfoProps {
  task: ITaskLab
}

export const Info: React.FC<InfoProps> = ({ task }) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const theme = useTheme()

  const { allApisWithType } = getApisWithType({ main_apis: task.main_apis })
  const UnitIconType = unitIconSDeterminer(task?.unit_type.id)

  return (
    <Box className='test-sidebar' sx={{ padding: isMobile ? '0 15px' : '' }}>
      {!isMobile && <Typography variant='h4'>Main info section</Typography>}
      <Box>
        <StepSidebarRow $inlineFlex>
          <Box>
            <Typography variant='h5' component='span'>
              Sample ID
            </Typography>
          </Box>

          <Box display='inline-flex'>
            <SpecificId
              labelTooltip={task.initial_laboratory?.label_tooltip}
              specificId={`${task.sample}`}
            />
            <TempSensitiveToxicityIcons
              toxicity={task.toxicity_level}
              minTempSensitive={task.min_temperature}
              maxTempSensitive={task.max_temperature}
              temperatureMeasurementFormat={task.temperature_measurement_format?.key}
              mt='-5px'
            />
          </Box>
        </StepSidebarRow>

        <StepSidebarRow>
          <Box>
            <Typography variant='h5' component='span'>
              Manufacturer
            </Typography>
          </Box>

          {isMobile ? (
            <Box sx={{ width: '100%' }}>
              <TooltipMobile
                title={task.manufacturer?.name || ''}
                shouldBeHidden={
                  ((task.manufacturer && task.manufacturer?.name.length) || 0) <=
                  TRUNCATED_TEXT_LENGTH_S
                }
                alignItems='flex-start'
              >
                <Typography variant='body1'>
                  {task.manufacturer
                    ? stringSlice(task.manufacturer?.name, TRUNCATED_TEXT_LENGTH_S)
                    : '-'}
                </Typography>
              </TooltipMobile>
            </Box>
          ) : (
            <Box>
              <Tooltip
                title={task.manufacturer?.name || '-'}
                shouldBeHidden={(task.manufacturer?.name.length || 0) <= TRUNCATED_TEXT_LENGTH_L}
                placement='top-end'
                sx={{ cursor: 'pointer' }}
              >
                <Typography variant='body1' component='span'>
                  {task.manufacturer?.name
                    ? stringSlice(task.manufacturer.name, TRUNCATED_TEXT_LENGTH_L)
                    : '-'}
                </Typography>
              </Tooltip>
              <br />
              <Tooltip
                title={task.brand?.name || '-'}
                shouldBeHidden={(task.brand?.name.length || 0) <= TRUNCATED_TEXT_LENGTH_L}
                placement='top-end'
              >
                <SubText sx={{ cursor: 'pointer' }}>
                  {task.brand?.name ? stringSlice(task.brand?.name, TRUNCATED_TEXT_LENGTH_L) : '-'}
                </SubText>
              </Tooltip>
            </Box>
          )}
        </StepSidebarRow>

        {isMobile && (
          <StepSidebarRow>
            <Box>
              <Typography variant='h5' component='span'>
                Brand
              </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Brand brand={task.brand?.name} />
            </Box>
          </StepSidebarRow>
        )}

        <StepSidebarRow sx={{ position: 'relative' }}>
          <Box>
            <Typography variant='h5' component='span'>
              Task notes
            </Typography>
          </Box>

          <TaskNotesField
            isAbleToAddTaskNotes={task.is_able_to_add_task_notes}
            notes={task.task_notes}
            sampleId={task.sample}
            taskId={task.id}
            contentType={TASKS}
            left='75px'
            taskInfoInTest
          />
        </StepSidebarRow>

        <StepSidebarRow>
          <Box>
            <Typography variant='h5' component='span'>
              Drug class
            </Typography>
          </Box>

          <Box>
            {isMobile ? (
              <TooltipMobile
                title={task.drug_class.name || ''}
                shouldBeHidden={task.drug_class.name.length <= TRUNCATED_TEXT_LENGTH_L}
                placement='top-end'
              >
                <Typography variant='body1' component='span'>
                  {task.drug_class.name
                    ? stringSlice(task.drug_class.name, TRUNCATED_TEXT_LENGTH_L)
                    : '-'}
                </Typography>
              </TooltipMobile>
            ) : (
              <Tooltip
                title={task.drug_class.name || ''}
                shouldBeHidden={task.drug_class.name.length <= TRUNCATED_TEXT_LENGTH_L}
                placement='top-end'
              >
                <Typography variant='body1' component='span'>
                  {task.drug_class.name
                    ? stringSlice(task.drug_class.name, TRUNCATED_TEXT_LENGTH_L)
                    : '-'}
                </Typography>
              </Tooltip>
            )}
          </Box>
        </StepSidebarRow>

        <StepSidebarRow>
          <Box>
            <Typography variant='h5' component='span'>
              Dosage
            </Typography>
          </Box>

          <Box>
            <Typography variant='body1' component='span'>
              {parseFloat(task.dosage)}
            </Typography>
            &nbsp;
            {task.dosage_type && (
              <Typography variant='body2' component='span' className='text-colored'>
                <SubText sx={{ marginLeft: '8px', lineHeight: '20px', fontSize: 14 }}>
                  {task.dosage_type.name || ''}
                </SubText>
              </Typography>
            )}
          </Box>
        </StepSidebarRow>

        <StepSidebarRow>
          <Box>
            <Typography variant='h5' component='span'>
              Units
            </Typography>
          </Box>

          <Box>
            <Typography variant='body1' component='span'>
              {task.units}
            </Typography>
            &nbsp;
            {task.unit_type && (
              <Typography variant='body2' component='span' className='text-colored'>
                <SubText
                  sx={{
                    marginLeft: '8px',
                    lineHeight: '20px',
                    fontSize: 14,
                    display: 'inline-flex',
                  }}
                >
                  {task.unit_type.name.toLocaleLowerCase()}
                  <Icon sx={{ marginLeft: '7px' }}>
                    <UnitIconType
                      fill={theme.palette.primary.main}
                      stroke={theme.palette.primary.main}
                    />
                  </Icon>
                </SubText>
              </Typography>
            )}
          </Box>
        </StepSidebarRow>

        <StepSidebarRow $isMobile={isMobile}>
          <Box>
            <Typography variant='h5' component='span'>
              APIs
            </Typography>
          </Box>

          <Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <ExpandableApis
                bgColor='transparent'
                borderBottom='none'
                direction={'column'}
                apis={allApisWithType}
              />
            </Box>
          </Box>
        </StepSidebarRow>
      </Box>
    </Box>
  )
}
