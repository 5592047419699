import { Box } from '@mui/material'
import { styled } from '@mui/system'

interface AttachmentBoxProps {
  width?: string
}

export const AttachmentBox = styled<React.FC<AttachmentBoxProps>>(Box)`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13.5px;
`

export const AttachmentName = styled(Box)`
  flex: 1 1 auto;
  margin-left: 10px;
`
