export const SAMPLE_NAME = 'name'
export const MAIN_SUBSTANCES = 'expected_main_substances'
export const SUBMITTER_CODE = 'submitter_code'
export const OTHER_SUBSTANCES = 'expected_other_substances'
export const SAMPLE_NOTES = 'notes'
export const PHYSICAL_FORM = 'physical_form'
export const DOSAGE_TYPE = 'dosage_type'
export const COLLECTION_DATE = 'collection_date'
export const SAMPLE_WEIGHT = 'weight'
export const COLLECTION_LOCATION = 'collection_location'
export const SAMPLE_QUESTIONS = 'questions'
export const SAMPLE_EFFECT = 'effect'
export const SAMPLE_ATTACHMENTS = 'attachments'
export const CLINICALLY_MEANINGFUL = 'clinically_meaningful'
export const CONCENTRATION_TYPE = 'concentration_type'
export const CONCENTRATION = 'concentration'
