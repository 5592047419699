import { styled } from '@mui/material/styles'

export const TestItem = styled('li')`
  padding: 6px 16px;
  border: 0;
  cursor: pointer;

  &:hover {
    background: #0000000a;
  }
`

export const GroupTitle = styled('li')`
  padding: 6px 16px;
  font-size: 12px;
  color: #8f929b;
`
