import TopBarProgress from 'react-topbar-progress-indicator'

TopBarProgress.config({
  barColors: {
    ' 0': '#FFB135',
    ' 1.0 ': '#FFCC33',
  },
  shadowBlur: 0,
})

export const TopProgressBar: React.FC = () => <TopBarProgress />
