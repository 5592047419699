import React from 'react'

import { PageErrorWithFeedback } from '@components/shared'

export interface IDefaultErrorBoundaryProps {}

export interface IDefaultErrorBoundaryState {
  hasError: boolean
  currentPathname: string
}

export class ErrorBoundary extends React.Component<
  IDefaultErrorBoundaryProps,
  IDefaultErrorBoundaryState
> {
  public readonly state: Readonly<IDefaultErrorBoundaryState> = {
    hasError: false,
    currentPathname: '',
  }

  componentDidCatch() {
    this.setState({ hasError: true, currentPathname: window.location.pathname })
  }

  shouldComponentUpdate(): boolean {
    if (this.state.hasError && this.state.currentPathname !== window.location.pathname) {
      this.setState({ hasError: false, currentPathname: '' })
    }
    return true
  }

  public render() {
    return this.state.hasError ? <PageErrorWithFeedback /> : this.props.children
  }
}
