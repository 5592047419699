import React from 'react'
import fileSize from 'filesize'
import { Box, Icon, Typography, useMediaQuery } from '@mui/material'
import { FileRejection, useDropzone } from 'react-dropzone'

import dropzoneIcon from '@assets/icons/dropzone-icon-grey.svg'
import ActiveDropzoneIcon from '@assets/icons/dropzone-icon-white.svg'
import { IError } from '@models/common/app'
import downloadIcon from '@assets/icons/download-icon-white.svg'
import { AttachSection } from '@models/attachments'
import { validExtensions, validPhotoExtensions } from '@constants/common'
import { ModalTypes } from '@models/packages'
import { MAX_NUMBER_OF_ATTACHMENTS } from '@constants/tests'

import { DropZoneBox } from './styles'
import { Button } from '../Button'

interface DropZoneProps {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void
  addingError: IError | null
  attachmentsLength: number
  height?: string
  modalType?: ModalTypes
  section?: AttachSection
  maxNumberOfAttachments?: number
}

export const DropZone: React.FC<DropZoneProps> = ({
  maxNumberOfAttachments,
  onDrop,
  addingError,
  attachmentsLength,
  height,
  section,
  modalType,
}) => {
  const attachmentsNumber =
    modalType === 'photo' ? 1 : maxNumberOfAttachments || MAX_NUMBER_OF_ATTACHMENTS
  const shouldDisabled = attachmentsNumber - attachmentsLength <= 0
  const isMobile = useMediaQuery('(max-width:767px)')

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
    isDragActive,
  } = useDropzone({
    onDrop,
    disabled: shouldDisabled,
    maxFiles: attachmentsNumber - attachmentsLength,
    validator: file => {
      const maxLengthOfFileName = 80
      const maxFileSize = modalType === 'photo' ? 25000000 : 1000000000
      const validExtensionsArr = modalType === 'photo' ? validPhotoExtensions : validExtensions

      if (file.name.length > maxLengthOfFileName) {
        return {
          code: 'name-too-large',
          message: `Name is larger than ${maxLengthOfFileName} characters`,
        }
      } else if (file.size > maxFileSize) {
        return {
          code: 'size-too-large',
          message: `The file larger than ${modalType === 'photo' ? '25 MB' : '1 GB'}`,
        }
      } else if (file.type && !validExtensionsArr.includes(file.type.split('/')[1])) {
        return {
          code: 'file-invalid-type',
          message: `The extension is not valid`,
        }
      } else if (!file.type) {
        const splittedFileName = file.name.split('.')
        const fileTypeFromName = splittedFileName[splittedFileName.length - 1]
        if (!file.type && !validExtensionsArr.includes(fileTypeFromName)) {
          return {
            code: 'file-invalid-type',
            message: `The extension is not valid`,
          }
        }
      }

      return null
    },
  })
  const errorSection = addingError?.message.split('\n')
  const isAddingFailed = !!((errorSection && errorSection[1] === section) || fileRejections.length)

  return (
    <DropZoneBox
      isActive={isDragActive}
      isLoadingFailed={!!isAddingFailed}
      disabled={shouldDisabled}
      isMobile={isMobile}
      height={height}
    >
      <Box {...getRootProps({ className: 'fileDrop', isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {isMobile ? (
          <Button
            mb='8px'
            disabled={shouldDisabled}
            variant='contained'
            startIcon={<img src={downloadIcon} alt='download icon' />}
          >
            Select file
          </Button>
        ) : (
          <>
            <Icon sx={{ width: '58px', height: '58px' }}>
              <img src={!isDragActive ? dropzoneIcon : ActiveDropzoneIcon} alt='dropzone icon' />
            </Icon>

            {shouldDisabled ? (
              <Typography className='dropzone-text'>
                Attached maximum number of attachments
              </Typography>
            ) : !isDragActive ? (
              <Typography component='p' className='dropzone-text'>
                Drag & Drop or{' '}
                <Typography color='primary.main' component='span' className='dropzone-link'>
                  Select file
                </Typography>
              </Typography>
            ) : (
              <Typography component='p' className='dropzone-text' sx={{ color: '#FFFFFF' }}>
                Upload relevant files here
              </Typography>
            )}
          </>
        )}

        {!isDragActive && (
          <Typography component='sub' sx={{ display: isMobile ? 'block' : '' }}>
            Maximum upload file size: {modalType === 'photo' ? '25MB' : '1GB'}
          </Typography>
        )}
      </Box>
      {fileRejections.length ? (
        <>
          {fileRejections.map(({ file, errors }, idx) => {
            return (
              <Box key={idx}>
                {errors.map(({ code, message }) => {
                  if (code === 'too-many-files') {
                    if (idx === 0) {
                      return (
                        <Typography key={code} className='dropzone-error' display='block'>
                          Maximum {attachmentsNumber} files or links can be attached to a section
                        </Typography>
                      )
                    }
                  } else {
                    return (
                      <Typography key={code} className='dropzone-error' display='block'>
                        {`${idx + 1}. ${file.name} (${fileSize(file.size)}) - ${message}`}
                      </Typography>
                    )
                  }

                  return null
                })}
              </Box>
            )
          })}
        </>
      ) : isAddingFailed && errorSection ? (
        <Typography className='dropzone-error'>Failed to load ({errorSection[0]})</Typography>
      ) : (
        <Typography
          component='p'
          className='dropzone-note'
          sx={modalType === 'photo' ? { marginBottom: '16px' } : {}}
        >
          {modalType === 'photo'
            ? 'Supported formats: .jpg, .jpeg, .jp2, .tiff, .png, .svg, .bmp'
            : 'All popular format of image, microsoft document, PDF and spreadsheet'}
        </Typography>
      )}
    </DropZoneBox>
  )
}
