import { useAppSelector } from '@hooks/redux'
import { selectedFilters as filters, selectedFiltersLength } from '@state/filters'
import {
  ClearAllFilters,
  FilterAsideButton,
  SetParamsOfFilter,
  ViewSelectedFilters,
} from '@components/shared/FilterAside'
import { StyledFilterBox } from '@styles'
import { USER_STATUS } from '@components/shared/FilterAside/constants'

interface SetParamsOfFilterProps {
  isPageHasSavedFilters: boolean
}

export const TabFilters: React.FC<SetParamsOfFilterProps> = ({ isPageHasSavedFilters }) => {
  const { appliedUserStatusSelectedFilters } = useAppSelector(filters)

  const allFiltersLength = useAppSelector(selectedFiltersLength)
  const isShouldShowClearAll = Boolean(allFiltersLength)

  return (
    <>
      <SetParamsOfFilter
        isPageHasSavedFilters={isPageHasSavedFilters}
        appliedUserStatusSelectedFilters={appliedUserStatusSelectedFilters}
      />
      <StyledFilterBox>
        <FilterAsideButton />
        {!!appliedUserStatusSelectedFilters.length && <ViewSelectedFilters type={USER_STATUS} />}
        {isShouldShowClearAll && <ClearAllFilters />}
      </StyledFilterBox>
    </>
  )
}
