import React from 'react'
import { useController } from 'react-hook-form'
import { FormControlLabel } from '@mui/material'

import { StyledRadio, StyledRadioGroup } from '@styles'

export interface RadioGroupProps {
  name: string
}

export const RadioGroup: React.FC<RadioGroupProps> = ({ name }) => {
  const { field } = useController({ name })

  return (
    <StyledRadioGroup aria-label='avatar' {...field}>
      <FormControlLabel value='male' label='Male' control={<StyledRadio />} />
      <FormControlLabel value='female' label='Female' control={<StyledRadio />} />
    </StyledRadioGroup>
  )
}
