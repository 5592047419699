import React from 'react'

import { StyledTableCell } from '../../styles'

interface CounterProps {
  counter?: number
  isLastSample?: boolean
}

export const Counter: React.FC<CounterProps> = React.memo(({ counter, isLastSample }) => {
  return <StyledTableCell isLastSample={isLastSample}>{counter}.</StyledTableCell>
})
