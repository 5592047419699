import { Box, IconButton, styled, TextField } from '@mui/material'

export const InputContainer = styled(Box)<{
  errorStatus: boolean
  recountStatus: boolean
  lockedValue: boolean
}>`
  position: relative;
  width: 41.6px;
  height: 41.6px;
  margin: 0 1px;
  margin-bottom: 2px;

  display: flex;
  align-items: center;

  border-radius: 4px;
  border: 1px solid
    ${({ errorStatus, lockedValue, theme }) =>
      !lockedValue && errorStatus ? '#AF4645' : theme.additional.stroke.main};
  background-color: ${({ recountStatus, lockedValue }) =>
    !lockedValue && recountStatus ? '#FFF5D6' : ''};
`

export const StyledInput = styled(TextField)`
  & .MuiInput-underline {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }

  .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.additional.stroke.main};
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: -37%;
  left: -37%;
  z-index: 9999;

  &:hover {
    background: none;
  }
`
