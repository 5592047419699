import {
  styled,
  FormControlLabel,
  Typography,
  FormControlLabelProps,
  TypographyProps,
} from '@mui/material'
import { Box, BoxProps } from '@mui/system'
import React from 'react'

interface StyledWrapperProps extends BoxProps {
  $isMobile?: boolean
}

export const StyledWrapper = styled<React.FC<StyledWrapperProps>>(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ $isMobile }) => ($isMobile ? '' : '469px')};
`

interface StyledTitleProps extends TypographyProps {
  $isMobile?: boolean
}

export const StyledTitle = styled<React.FC<StyledTitleProps>>(Typography)`
  text-align: center;
  margin-bottom: ${({ theme, $isMobile }) => ($isMobile ? '15px' : theme.typography.pxToRem(32))};
  color: ${({ theme }) => theme.palette.text.primary};
`

interface StyledLabelProps extends FormControlLabelProps {
  $isMobile?: boolean
  mb?: string
}

export const StyledLabel = styled<React.FC<StyledLabelProps>>(FormControlLabel)`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: 0;
  margin-bottom: ${({ mb }) => mb || '8px'};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: #1a2233;
  position: relative;
  width: 100%;

  .MuiTextField-root {
    width: 100%;
  }

  .MuiCardMedia-root {
    position: absolute;
    right: 17px;
    bottom: 10px;
  }

  .MuiTypography-root {
    margin-bottom: 6px;
  }

  .MuiInputBase-input {
    padding-right: 45px;
  }
`
