import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { selectUser } from '@state/user'
import { USER } from '@constants/common'
import { useAppSelector } from '@hooks/redux'
import { useConfirmEmail } from '@hooks/queries'
import { IConfirmationEmailData } from '@models/common/user'

type MatchParams = IConfirmationEmailData

export const EmailConfirmation: React.FC = () => {
  const { isAuthorized, error, isFetching } = useAppSelector(selectUser)
  const { params } = useRouteMatch<MatchParams>()
  const { mutate, isLoading } = useConfirmEmail()

  const user = localStorage.getItem(USER)
  const isAuthorizing = (!!user && !isAuthorized && !error) || isFetching || isLoading
  React.useEffect(() => {
    if (!isAuthorizing) mutate(params)
  }, [isAuthorizing, params, mutate])

  return null
}
