import { Box, BoxProps, styled } from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import { MutableRefObject } from 'react'

interface TableHeadWrapperBoxProps extends BoxProps {
  isSticky: boolean | MutableRefObject<HTMLDivElement | null>
}

const TableHeadWrapperConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'isSticky':
        return false

      default:
        return true
    }
  },
}

export const StyledTableHeadWrapper = styled<React.FC<TableHeadWrapperBoxProps>>(
  Box,
  TableHeadWrapperConfig
)`
  position: sticky;
  overflow: auto;
  top: 0;
  padding-top: ${({ isSticky }) => (isSticky ? 'none' : '65px')};
  z-index: 150;
  overflow-x: hidden;

  box-shadow: ${({ isSticky }) => (!isSticky ? 'none' : '0px 6px 15px rgba(53, 108, 151, 0.12)')};
  transition: box-shadow 500ms linear;
  background-color: ${({ theme }) => theme.palette.background.default};

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledTableHead = styled(Box)`
width: fit-content;
  
  & > div div {
    padding: 15px 0;

  &:nth-child(1) {
    padding: 15px 0 15px 24px;
  }

  &:last-child {
      padding: 15px 0px 15px 32px;
    }
`
