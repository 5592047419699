import { Box, styled } from '@mui/material'

export const StyledEditButton = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: 14px;
  font-weight: 600;
`
