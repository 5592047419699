import React from 'react'
import Collapse from '@mui/material/Collapse'
import { MenuList, Box } from '@mui/material'

import { useToggle } from '@hooks/common'
import {
  REQUEST_STATUS,
  selectedFiltersSlice,
  REQUEST_STATUS_FILTER_TITLE,
  PURCHASE_STATUS_FILTER_TITLE,
} from '@components/shared/FilterAside/constants'
import { FilterLoader } from '@components/shared/FilterAside'
import { selectedFilters as filters } from '@state/filters'
import { useAppSelector } from '@hooks/redux'
import { useFetchRequestStatusFilters } from '@hooks/queries'
import { TESTING_REQUEST_TYPE } from '@constants/requests'
import { selectRequestsState } from '@state/requests'

import { CardRow, StyledCollapseRow } from './styles'
import { FilterTopTitle } from '../components/FilterTopTitle'
import { FilterSelectAll } from '../components/FilterSelectAll'
import { FilterCheckbox } from '../components/FilterCheckbox'

export const StatusFilter = React.memo(() => {
  const [isFilterClose, setIsFilterClose] = useToggle()
  const selectedFilters = useAppSelector(filters)
  const { switchedContentType } = useAppSelector(selectRequestsState)

  const { data, isLoading, isFetching } = useFetchRequestStatusFilters()

  const dataList = data?.map(item => ({ name: item.value, id: item.key }))
  const filterCounter = selectedFilters[selectedFiltersSlice.status].filters.length || undefined
  const isPurchasePage = switchedContentType !== TESTING_REQUEST_TYPE

  const statusList =
    isPurchasePage && dataList
      ? [{ name: 'Awaiting', id: 'awaiting' }, ...dataList.splice(-2, 2)]
      : dataList?.splice(0, 3)

  const filterTitle = isPurchasePage ? PURCHASE_STATUS_FILTER_TITLE : REQUEST_STATUS_FILTER_TITLE

  return (
    <>
      <FilterTopTitle
        filterNumb={statusList?.length}
        isFilterClose={isFilterClose}
        setIsFilterClose={setIsFilterClose}
        titleOfFilter={filterTitle}
        filterCounter={filterCounter}
      />
      <StyledCollapseRow>
        <Collapse in={!isFilterClose} timeout='auto' unmountOnExit>
          <CardRow>
            <MenuList sx={{ width: '100%' }}>
              {isFetching || isLoading ? (
                <Box width='100%'>
                  <FilterLoader />
                </Box>
              ) : (
                <div>
                  {statusList && <FilterSelectAll type={REQUEST_STATUS} items={statusList} />}
                  {statusList?.map(status => (
                    <FilterCheckbox type={REQUEST_STATUS} item={status} key={status.id} />
                  ))}
                </div>
              )}
            </MenuList>
          </CardRow>
        </Collapse>
      </StyledCollapseRow>
    </>
  )
})
