import React from 'react'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '@hooks/redux'
import { sampleCreateUIData, setRemovedSkills } from '@state/common/sampleCreateUIState'
import { sampleCreate } from '@state/sampleCreate'
import { MAIN_APIS } from '@constants/samples'
import { IMainApis } from '@models/samples'

export const RemovedApisFromStore: React.FC = () => {
  const { isToxicityAdded, isTemperatureAdded, triggerFlag } = useAppSelector(sampleCreateUIData)
  const { samples } = useAppSelector(sampleCreate)
  const dispatch = useDispatch()

  React.useEffect(() => {
    let isHaveToxicity = false
    let isHaveTemperature = false

    samples.forEach(sample => {
      const sampleApiData = sample[MAIN_APIS].val as IMainApis[]
      if (!isHaveToxicity) {
        if (sampleApiData.some(api => api.skills.some(skillApi => skillApi.id === 1))) {
          isHaveToxicity = true
        }
      }
      if (!isHaveTemperature) {
        if (sampleApiData.some(api => api.skills.some(skillApi => skillApi.id === 2))) {
          isHaveTemperature = true
        }
      }
    })

    const removedArr = []
    if (isToxicityAdded && !isHaveToxicity) removedArr.push(1)
    if (isTemperatureAdded && !isHaveTemperature) removedArr.push(2)

    if (removedArr.length) dispatch(setRemovedSkills(removedArr))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFlag])

  return null
}
