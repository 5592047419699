import { ExpirationDate } from '@components/shared'
import { ITaskLab } from '@models/tasks'
import { StyledTableSampleCell } from '@styles'

interface ExpirationDateCellProps {
  task: ITaskLab
  isMobile?: boolean
}

export const ExpirationDateCell: React.FC<ExpirationDateCellProps> = ({ task, isMobile }) => {
  const { expiration_date: expDate } = task
  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      <ExpirationDate expirationDate={expDate} />
    </StyledTableSampleCell>
  )
}
