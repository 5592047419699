import React from 'react'
import { Box, useMediaQuery } from '@mui/material'

import { Button, Modal } from '@components/shared'
import { HrTestMethod } from '@models/tasks'

import * as Styled from './styles'

interface DrugsModalProps {
  open: boolean
  method: HrTestMethod | null
  onClose: () => void
}

export const DrugsModal: React.FC<DrugsModalProps> = ({ open, method, onClose }) => {
  const isMobile = useMediaQuery('(max-width:767px)')

  return (
    <Modal padding='0px' open={open} onClose={() => onClose()}>
      <Styled.Wrapper style={{ width: isMobile ? 'auto' : '575px' }}>
        {/* Modal TITLE and SUBTITLE */}
        <Styled.HeadBox>
          <Styled.StyledTitle variant={isMobile ? 'subtitle1' : 'h3'} sx={{ marginBottom: '4px' }}>
            {method?.name}
          </Styled.StyledTitle>
          <Styled.Subtitle>{method?.description}</Styled.Subtitle>
        </Styled.HeadBox>
        {/* Content TESTS LIST */}
        <Styled.ContentBox>
          <Box sx={{ padding: '0px 90px' }}>
            {method?.drugs.map((drug, idx) => (
              <p key={idx}>
                {idx + 1 + '. '}
                {drug.name}
              </p>
            ))}
          </Box>
        </Styled.ContentBox>
        {/* Modal ACTION BUTTONS */}
        <Styled.ButtonBox>
          <Button
            type='button'
            variant='outlined'
            color='secondary'
            sx={{ marginRight: '28px' }}
            width='200px'
            height='48px'
            onClick={() => onClose()}
          >
            Go back
          </Button>
        </Styled.ButtonBox>
      </Styled.Wrapper>
    </Modal>
  )
}
