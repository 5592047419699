import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormControlLabel } from '@mui/material'

import { Checkbox } from '@components/shared'
import { INVENTORY_MANAGER, LABORATORY_ASSISTANT } from '@constants/user'

import styles from './Radio.module.css'
import { StyledFormControlLabel, StyledRadio, StyledRadioGroup } from '../styles'

export interface RoleFieldProps {
  isLabAssistant: boolean
}

export const RoleField: React.FC<RoleFieldProps> = ({ isLabAssistant }) => {
  const { register, setValue, getValues } = useFormContext()

  const role_switcher = getValues('role_switcher')
  const role = getValues('role')

  React.useEffect(() => {
    if (role_switcher) setValue('role', INVENTORY_MANAGER)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role_switcher])

  return (
    <>
      <StyledRadioGroup aria-label='role' sx={{ marginLeft: '10px' }}>
        <StyledFormControlLabel
          {...register('role')}
          sx={{ marginRight: '30px', opacity: role_switcher ? '0.45' : '1' }}
          label='Laboratory assistant'
          value={LABORATORY_ASSISTANT}
          checked={role === LABORATORY_ASSISTANT}
          disabled={role_switcher}
          $color={role === LABORATORY_ASSISTANT ? '#0F1934' : '#616D84'}
          control={
            <StyledRadio
              checkedIcon={<span className={styles.checkedIcon} />}
              icon={<span className={styles.icon} />}
              sx={{ padding: '0px', paddingRight: '8px' }}
            />
          }
        />
        <StyledFormControlLabel
          sx={{ opacity: role_switcher ? '0.75' : '1' }}
          {...register('role')}
          label='Inventory manager'
          value={INVENTORY_MANAGER}
          checked={role === INVENTORY_MANAGER}
          disabled={role_switcher}
          $color={role === INVENTORY_MANAGER ? '#0F1934' : '#616D84'}
          control={
            <StyledRadio
              checkedIcon={<span className={styles.checkedIcon} />}
              icon={<span className={styles.icon} />}
              sx={{ padding: '0px', paddingRight: '8px' }}
            />
          }
        />
      </StyledRadioGroup>
      {isLabAssistant && (
        <FormControlLabel
          sx={{ marginTop: '-8px', marginLeft: '-9px' }}
          {...register('role_switcher')}
          control={<Checkbox checked={getValues('role_switcher') || false} />}
          label='Role switcher'
        />
      )}
    </>
  )
}
