import { styled, FormControlLabel, Typography } from '@mui/material'

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 469px;
`

export const StyledLabel = styled(FormControlLabel)`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  align-items: center;
  margin: 0;
  margin-bottom: 44px;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: #616d84;

  & .MuiTypography-root {
    margin-bottom: 54px;
  }
`

export const StyledTitle = styled(Typography)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(8)};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const ButtonBox = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
