import React from 'react'

import { ITaskLab } from '@models/tasks'
import { selectUser } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { datePatterns } from '@constants/dates'
import { getFormattedDate } from '@helpers/ui/date'
import { StyledTitle, StyledDetailsLabel, StyledParagraph, StyledFlexRow } from '@styles'

import { Loader } from './components'

interface ManufactureDetailsProps {
  task?: ITaskLab
  isLoading: boolean
}

export const ManufactureDetails: React.FC<ManufactureDetailsProps> = ({ isLoading, task }) => {
  const { timezone } = useAppSelector(selectUser)

  const formattedManufactureDate = getFormattedDate(
    datePatterns.fullDate,
    task?.manufacture_date,
    timezone
  )

  const formattedExpirationDate = getFormattedDate(
    datePatterns.fullDate,
    task?.expiration_date,
    timezone
  )

  if (isLoading) return <Loader />

  return (
    <div>
      <StyledTitle mb={'32px'} variant='h3' fz={24}>
        Manufacture details
      </StyledTitle>

      <StyledDetailsLabel variant='h5'>Manufacture name</StyledDetailsLabel>
      <StyledParagraph variant='body1'> {task?.manufacturer?.name || '-'} </StyledParagraph>

      <StyledDetailsLabel variant='h5'>Address of manufacturer</StyledDetailsLabel>
      <StyledParagraph variant='body1'>
        {task?.address_of_manufacturer?.description || '-'}
      </StyledParagraph>

      <StyledDetailsLabel variant='h5'>Brand</StyledDetailsLabel>
      <StyledParagraph variant='body1'> {task?.brand?.name || '-'} </StyledParagraph>

      <StyledFlexRow>
        <div>
          <StyledDetailsLabel variant='h5'>Manufacture date</StyledDetailsLabel>
          <StyledParagraph variant='body1'> {formattedManufactureDate || '-'} </StyledParagraph>
        </div>
        <div>
          <StyledDetailsLabel variant='h5'>Expiration date</StyledDetailsLabel>
          <StyledParagraph variant='body1'> {formattedExpirationDate || '-'} </StyledParagraph>
        </div>
      </StyledFlexRow>

      <StyledDetailsLabel variant='h5'>Country of origin</StyledDetailsLabel>
      <StyledParagraph variant='body1'> {task?.country?.name || '-'} </StyledParagraph>
    </div>
  )
}
