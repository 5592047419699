import React from 'react'
import { Typography, useMediaQuery } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ButtonBox, Image } from '@styles'
import error from '@assets/images/error-404.svg'
import { FeedbackForm } from '@components/modules/FeedbackForm'
import { useToggle } from '@hooks/common'
import { setIsErrorPage } from '@state/user'

import { Button } from '../Button'
import { StyledBox } from './styles'

export const PageErrorWithFeedback: React.FC = () => {
  const [isModalOpen, toggleModal] = useToggle()
  const dispatch = useDispatch()
  const history = useHistory()
  const isMobile = useMediaQuery('(max-width:767px)')
  const isLaptop = useMediaQuery('(max-width:1028px)')

  const goBackHandler = () => history.goBack()

  React.useEffect(() => {
    dispatch(setIsErrorPage(true))
    return () => {
      dispatch(setIsErrorPage(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledBox>
      <Image
        width='236px'
        height='236px'
        mb={isMobile ? '24px' : '32px'}
        src={error}
        component='img'
      />

      <Typography variant='h4' mb={isLaptop ? '6px' : '16px'} textAlign='center'>
        Oops! Something went wrong…
      </Typography>
      <Typography
        width={isMobile ? '315px' : '500px'}
        textAlign='center'
        color='#616D84'
        variant='body2'
        mb={isLaptop ? '6px' : '16px'}
      >
        Help us figure out what went wrong by writing in the feedback form what you did before this
        error appeared.
      </Typography>

      <ButtonBox $isMobile={isMobile}>
        <Button
          variant='outlined'
          sx={{
            marginRight: isMobile ? '' : '16px',
            marginTop: isMobile ? '16px' : '32px',
            marginBottom: isLaptop ? '8px' : '',
            width: isMobile ? '100%' : '205px',
            order: isMobile ? 2 : 1,
          }}
          onClick={goBackHandler}
        >
          Go back
        </Button>
        <Button
          variant='contained'
          sx={{
            marginTop: isMobile ? '16px' : '32px',
            width: isMobile ? '100%' : '205px',
            order: isMobile ? 1 : 2,
          }}
          onClick={toggleModal}
        >
          Feedback form
        </Button>
      </ButtonBox>
      {isModalOpen && <FeedbackForm errorPage toggleModal={toggleModal} />}
    </StyledBox>
  )
}
