import { Box, IconButton, Typography, styled } from '@mui/material'

export const TextLink = styled('span')`
  cursor: pointer;
  color: #5e91f7;
  text-decoration: underline;
`
export const StyledTitle = styled(Typography)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(8)};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  height: 100%;
`

export const ButtonBox = styled('div')`
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -5px 0 15px rgba(53, 108, 151, 0.12);
  border-radius: 10px 10px 0 0;
`
export const HeadBox = styled('div')`
  padding: 20px 30px;
  box-shadow: -5px 0 15px rgba(53, 108, 151, 0.12);
  border-radius: 0 0 10px 10px;
`
export const ContentBox = styled('div')`
  padding: 0 30px 8px;
  overflow-y: auto;
  flex: 1;
`
export const Subtitle = styled('p')`
  margin: 0;
  color: #8f929b;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
`

export const ImageButton = styled(IconButton)`
  margin-left: 14px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid #e5e5e6;
  width: 40px;
  height: 40px;
`
export const DetailsBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`
