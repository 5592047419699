import { stringify } from 'query-string'
import { encodeQueryParams, NumberParam, StringParam } from 'use-query-params'

import { http } from '@services/http'
import { ISamplesData, ISampleTaskData } from '@models/samples'
import { ISearchSuggestionsData } from '@models/common/app'
import { filterStringParamsQueries } from '@components/shared/FilterAside/constants'
import { parseDate } from '@helpers/dates'

const reportsListQueryParams = {
  page: StringParam,
  limit: NumberParam,
  samples: StringParam,
  ordering: StringParam,
  ...filterStringParamsQueries,
  started_date: StringParam,
  finished_date: StringParam,
  laboratories: StringParam,
}

const REPORTS_AMOUNT_LOADING_LIMIT = 50

export const fetchReports = async <T>(queryKey: any) => {
  const { queryKey: queryKeyNested, pageParam = 1 } = queryKey
  const [
    ,
    desired_test_reports,
    ordering,
    apis,
    excipients,
    assignees,
    drug_classes,
    manufacturers,
    testingDate,
    laboratories,
    taskResult,
    test_types,
    started_collection_date,
  ] = queryKeyNested

  const parsedTestingDate = parseDate(testingDate)
  const parsedCollectionDate = parseDate(started_collection_date)

  const searchParams = {
    page: pageParam,
    limit: REPORTS_AMOUNT_LOADING_LIMIT,
    samples: desired_test_reports,
    ordering,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    started_date:
      testingDate && (parsedTestingDate[0] ? parsedTestingDate[0] : parsedTestingDate[1]),
    finished_date:
      testingDate && (parsedTestingDate[1] ? parsedTestingDate[1] : parsedTestingDate[0]),
    laboratories,
    test_types,
    started_collection_date:
      started_collection_date && (parsedCollectionDate[0] || parsedCollectionDate[1]),
    ended_collection_date:
      started_collection_date && (parsedCollectionDate[1] || parsedCollectionDate[0]),
  }

  const encodedQuery = encodeQueryParams(reportsListQueryParams, searchParams)
  const queryString = stringify(encodedQuery)

  const assigneesJSON = assignees && JSON.stringify(assignees)
  const assigneeQuery = assignees && assignees.length ? '&assignees=' + assigneesJSON : ''
  const isTaskResultExist = taskResult?.length
  const taskResultParam = `${isTaskResultExist ? '&task_result=' + taskResult : ''}`

  try {
    const reportsEndpoint = `/testing_reports/?${queryString}${assigneeQuery}${taskResultParam}`

    return http.get<ISamplesData<T>>(reportsEndpoint)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchReportsTasksBySampleId = (sampleId: number) => {
  try {
    return http.get<ISampleTaskData>(`/tasks/?sample_id=${sampleId}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const searchSuggestionsTestReports = ({ queryKey, pageParam }: any) => {
  try {
    return http.get<ISearchSuggestionsData>(
      `/testing_reports/specific_id_suggestions/?suggestion_start=${queryKey[0]}${
        pageParam ? '&page=' + pageParam : ''
      }${
        !queryKey[1]
          ? '&result=positive'
          : queryKey[1] === 'passed'
          ? '&result=positive'
          : '&result=negative'
      }`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchStatistic = async <T>(queryKey: any) => {
  const { queryKey: queryKeyNested, pageParam = 1 } = queryKey
  const [
    ,
    desired_test_reports,
    apis,
    excipients,
    assignees,
    drug_classes,
    manufacturers,
    testingDate,
    laboratories,
    taskResult,
    test_types,
  ] = queryKeyNested

  const parsedTestingDate = parseDate(testingDate)

  const searchParams = {
    limit: REPORTS_AMOUNT_LOADING_LIMIT,
    page: pageParam,
    samples: desired_test_reports,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    started_date:
      testingDate && (parsedTestingDate[0] ? parsedTestingDate[0] : parsedTestingDate[1]),
    finished_date:
      testingDate && (parsedTestingDate[1] ? parsedTestingDate[1] : parsedTestingDate[0]),
    laboratories,
    test_types,
  }

  const encodedQuery = encodeQueryParams(reportsListQueryParams, searchParams)
  const queryString = stringify(encodedQuery)
  const assigneesJSON = assignees && JSON.stringify(assignees)
  const assigneeQuery = assignees && assignees.length ? '&assignees=' + assigneesJSON : ''

  const isTaskResultExist = taskResult?.length
  const taskResultParam = `${isTaskResultExist ? '&task_result=' + taskResult : ''}`

  try {
    return http.get<T>(
      `/testing_reports/statistics?${queryString}${assigneeQuery}${taskResultParam}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
