import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { markSampleAsMissed } from '@api/samples'
import { SAMPLES_IN_PACKAGE } from '@constants/queries'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useMarkSampleAsMissed = () => {
  const dispatch = useDispatch()
  const client = useQueryClient()

  const mutation = useMutation(markSampleAsMissed, {
    onSuccess: () => {
      client.invalidateQueries(SAMPLES_IN_PACKAGE)
      client.invalidateQueries(['packagesBlockchainHistory'])
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
