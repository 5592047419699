import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ISelectedRequest, SwitcherContentType } from '@models/requests'
import { TESTING_REQUEST_TYPE } from '@constants/requests'

export interface RequestsState {
  selectedSampleRequests: number[]
  selectedRequests: ISelectedRequest[]
  requestedSampleParents: number[]
  canceledOutgoingRequests: number[]
  switchedContentType: SwitcherContentType
  listOfUnits: number[]
  constantlyAmountOfUnits: string
  isRequestingOfSample: boolean
}

const initialState: RequestsState = {
  selectedSampleRequests: [],
  selectedRequests: [],
  requestedSampleParents: [],
  canceledOutgoingRequests: [],
  switchedContentType: TESTING_REQUEST_TYPE,
  listOfUnits: [],
  constantlyAmountOfUnits: '1',
  isRequestingOfSample: false,
}

export const userSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    selectRequests: (state, { payload }: PayloadAction<ISelectedRequest[]>) => {
      state.selectedRequests = payload
    },
    selectSampleRequests: (state, { payload }: PayloadAction<number[]>) => {
      state.selectedSampleRequests = payload
    },
    cancelOutgoingRequest: (state, { payload }: PayloadAction<number>) => {
      state.canceledOutgoingRequests.push(payload)
    },
    clearCanceledRequests: state => {
      state.canceledOutgoingRequests = []
    },
    switchContentType: (state, { payload }: PayloadAction<SwitcherContentType>) => {
      state.switchedContentType = payload
    },
    generateListOfUnits: (
      state,
      { payload }: PayloadAction<{ amountOfSamples: number; amountOfUnits: number }>
    ) => {
      const listOfUnits: number[] = []
      for (let i = 0; i < payload.amountOfSamples; i++) listOfUnits.push(payload.amountOfUnits)

      state.listOfUnits = listOfUnits
    },
    changeUnitValue: (state, { payload }: PayloadAction<{ idx: number; value: string }>) => {
      state.listOfUnits[payload.idx] = +payload.value
    },
    normalizeUnitValue: (state, { payload }: PayloadAction<number>) => {
      if (state.listOfUnits[payload] <= 0) state.listOfUnits[payload] = 1
      if (state.listOfUnits[payload] > 1000) state.listOfUnits[payload] = 1000
      if (isNaN(state.listOfUnits[payload])) state.listOfUnits[payload] = 1
    },
    setRequestedSample: (state, { payload }: PayloadAction<number[]>) => {
      state.requestedSampleParents = payload
    },
  },
})

export const {
  reducer: requestsReducer,
  actions: {
    selectSampleRequests,
    selectRequests,
    cancelOutgoingRequest,
    clearCanceledRequests,
    switchContentType,
    generateListOfUnits,
    changeUnitValue,
    normalizeUnitValue,
    setRequestedSample,
  },
} = userSlice
