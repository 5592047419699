import React from 'react'
import { Typography } from '@mui/material'

import { detailsTaskTestingTable } from '@constants/mappings'
import { StyledDetailsTableHead, StyledDetailsTableCell } from '@styles'

import { StyledDetailsTableRow } from '../TaskRow/styles'

export const TableHead = () => {
  return (
    <StyledDetailsTableHead>
      <StyledDetailsTableRow>
        {detailsTaskTestingTable.tableHeadCells.map(({ id, content }) => (
          <StyledDetailsTableCell key={id}>
            <Typography variant='h5'>{content}</Typography>
          </StyledDetailsTableCell>
        ))}
      </StyledDetailsTableRow>
    </StyledDetailsTableHead>
  )
}
