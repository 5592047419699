import { styled, Switch } from '@mui/material'

export const StyledSwitch = styled(Switch)`
  width: 72px;
  height: 40px;

  margin-left: -12px;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin-left: 16px;
    margin-top: 15px;
  }

  .MuiSwitch-thumb {
    background-color: #fff;
    box-shadow: none;
    height: 20px;
  }

  .MuiSwitch-thumb::before {
    display: none;
    background-color: transparent;
  }

  .Mui-checked + .MuiSwitch-thumb {
    background-color: #fff;
  }

  .MuiSwitch-track {
    background-color: #e1e7f3;
    opacity: 1;
    height: 26px;
    width: 160px;
    border-radius: 30px;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: #7ede6e;
    opacity: 1;
  }
`
