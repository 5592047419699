import React from 'react'

import { Button } from '@components/shared'
import { useGenerateLabels } from '@hooks/queries'
import { IPackage } from '@models/packages'
import { MAX_SELECTED_PACKAGE_NUMBER } from '@constants/packages'

interface DownloadLabelsProps {
  selectedPackages: IPackage[]
  selectedPackagesAmount: number
}

export const DownloadLabels: React.FC<DownloadLabelsProps> = props => {
  const { selectedPackages, selectedPackagesAmount } = props

  const generatePackageLabelsM = useGenerateLabels()

  const downloadLabelsHandler = () => {
    generatePackageLabelsM.mutate({
      ids: selectedPackages.map(({ id }) => id),
      entity: 'packages',
    })
  }

  return (
    <Button
      variant='outlined'
      height='48px'
      mr='16px'
      bgColor='#ffffff'
      textColor='#616D84'
      disabled={generatePackageLabelsM.isLoading}
      loading={generatePackageLabelsM.isLoading}
      onClick={downloadLabelsHandler}
    >
      {selectedPackagesAmount > 1 ? 'Download labels' : 'Download label'} ({selectedPackagesAmount}/
      {MAX_SELECTED_PACKAGE_NUMBER})
    </Button>
  )
}
