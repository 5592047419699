import React from 'react'
import { AiOutlineFolder } from 'react-icons/ai'
import { useMediaQuery } from '@mui/material'

import { sampleDetailsTabs } from '@constants/mappings'
import { ISampleInfoTabValue } from '@models/samples'

import { StyledTab, StyledTabList } from './styles'

interface TabButtonsProps {
  onChangeTab: (tab: ISampleInfoTabValue) => void
}

export const TabButtons: React.FC<TabButtonsProps> = ({ onChangeTab }) => {
  const isMobile = useMediaQuery('(max-width:767px)')

  const changeTabHandler = (e: React.SyntheticEvent, tab: ISampleInfoTabValue) => onChangeTab(tab)

  return (
    <StyledTabList onChange={changeTabHandler} sx={{ marginLeft: isMobile ? '15px' : '0' }}>
      {sampleDetailsTabs.map(tab => (
        <StyledTab
          sx={{ padding: isMobile ? '12px' : '18px 36px' }}
          icon={<AiOutlineFolder />}
          iconPosition='start'
          key={tab.value}
          {...tab}
        />
      ))}
    </StyledTabList>
  )
}
