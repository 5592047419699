import React from 'react'

import { ISample } from '@models/samples'
import { useAppSelector } from '@hooks/redux'
import { useToggleSample } from '@hooks/common'
import { selectSamples, selectSamplesState } from '@state/samples'
import { Checkbox, Tooltip } from '@components/shared'
import { MAX_SELECTED_SAMPLE_NUMBER } from '@constants/samples'

import { StyledCheckboxWrapper } from './styles'
import { StyledTableCell } from '../styles'

interface TogglerProps {
  sample: ISample
  isLastSample?: boolean
  isSampleSelected?: boolean
  isFetchingSamples?: boolean
}

export const Toggler: React.FC<TogglerProps> = React.memo(props => {
  const { sample, isLastSample, isSampleSelected, isFetchingSamples } = props

  const { selectedSamples } = useAppSelector(selectSamplesState)
  const { selectSampleHandler, isSelected } = useToggleSample(
    sample.id,
    selectSamples,
    selectedSamples
  )

  const handleCheckbox = () => {
    selectSampleHandler()
  }

  React.useEffect(() => {}, [selectedSamples])

  const needsToDisable =
    (selectedSamples.length >= MAX_SELECTED_SAMPLE_NUMBER &&
      !selectedSamples.find(sampleId => sampleId === sample.id)) ||
    !sample.allow_packaging ||
    isFetchingSamples

  const tooltipText = sample.package
    ? 'This sample already has a package'
    : !sample.allow_packaging
    ? 'The sample has an "In progress" task'
    : 'Maximum number of samples per package 200'

  return (
    <StyledTableCell
      isLastSample={isLastSample}
      isSampleSelected={isSampleSelected}
      paddingTop='9px'
    >
      <Tooltip
        isPureTooltip
        title={sample.split_status || tooltipText}
        shouldBeHidden={(sample.allow_packaging && !needsToDisable) || isFetchingSamples}
        maxWidth={210}
        positionLeft={-22}
      >
        <StyledCheckboxWrapper id='not-expand'>
          <Checkbox
            padding='0'
            checked={isSelected}
            disabled={needsToDisable}
            onClick={handleCheckbox}
          />
        </StyledCheckboxWrapper>
      </Tooltip>
    </StyledTableCell>
  )
})
