import { CELSIUS, FAHRENHEIT } from '@constants/common'

import { obtainingCorrectTemperature } from './obtainingCorrectTemperature'

const format = [FAHRENHEIT, CELSIUS] as const

export const getTemperature = (
  desiredFormat: typeof format[number],
  min_temperature: string | null,
  max_temperature: string | null,
  min_temperature_measurement_format: string,
  max_temperature_measurement_format: string
) => {
  if (min_temperature_measurement_format === max_temperature_measurement_format) {
    return {
      temperatureFormat: max_temperature_measurement_format,
      minTemperature: min_temperature,
      maxTemperature: max_temperature,
    }
  } else {
    const { formattedMaxTemp, formattedMinTemp } = formatTemperature(
      desiredFormat,
      min_temperature,
      max_temperature,
      min_temperature_measurement_format,
      max_temperature_measurement_format
    )
    return {
      temperatureFormat: desiredFormat,
      minTemperature: formattedMinTemp,
      maxTemperature: formattedMaxTemp,
    }
  }
}
export const formatTemperature = (
  desiredFormat: typeof format[number],
  min_temperature: string | null,
  max_temperature: string | null,
  min_temperature_measurement_format: string,
  max_temperature_measurement_format: string
) => {
  let formattedMinTemp = min_temperature
  let formattedMaxTemp = max_temperature
  if (min_temperature_measurement_format && max_temperature_measurement_format) {
    if (min_temperature_measurement_format !== desiredFormat)
      formattedMinTemp = obtainingCorrectTemperature(desiredFormat, formattedMinTemp!)
    if (max_temperature_measurement_format !== desiredFormat)
      formattedMaxTemp = obtainingCorrectTemperature(desiredFormat, formattedMaxTemp!)
  }

  return {
    formattedMinTemp,
    formattedMaxTemp,
  }
}
