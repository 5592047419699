import { useMediaQuery } from '@mui/material'

import { IStatisticData, IWidgetData } from '@models/testingReports'
import { Widget } from '@components/shared'

import * as Styled from '../styles'
import { totalCount } from '../helpers/helpers'

interface StatisticDataProps {
  statistic?: IStatisticData
  isFilterApplied: boolean
}

export const SamplesStatistic: React.FC<StatisticDataProps> = ({ statistic, isFilterApplied }) => {
  const isLaptop = useMediaQuery('(max-width:1300px)')

  const totalSamples =
    isFilterApplied && statistic
      ? totalCount(statistic.sample_statuses)
      : statistic?.collected_samples || 0

  const percent = (name: string) => {
    let resultWithPercent = 0
    if (!!statistic?.sample_statuses && !!statistic?.sample_statuses) {
      resultWithPercent = (statistic.sample_statuses[name] / totalSamples) * 100
    }

    if (resultWithPercent < 1 && !!statistic?.sample_statuses[name]) return '< 1%'
    else if (resultWithPercent > 1) return `${Math.round(resultWithPercent)}%`

    return '-'
  }

  const data: IWidgetData[] = [
    {
      name: 'New',
      value: statistic?.sample_statuses.new || 0,
      percent: percent('new'),
      fill: '#8DDEFD',
    },
    {
      name: 'In test',
      value: statistic?.sample_statuses.in_test || 0,
      percent: percent('in_test'),
      fill: '#FFCA67',
    },
    {
      name: 'Tested',
      value: statistic?.sample_statuses.tested || 0,
      percent: percent('tested'),
      fill: '#5FD07C',
    },
  ]

  return (
    <Styled.MainBox width='40%' $isLaptop={isLaptop}>
      <Widget data={data} widgetTitle='Samples' legendWidth='180px' totalCount={totalSamples} />
    </Styled.MainBox>
  )
}
