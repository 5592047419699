import styled from 'styled-components'

export const StyledDatesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & p:nth-child(2) {
    margin-bottom: 12px;
  }
`
