import React from 'react'

// import { TabContext } from '@mui/lab'
// import { ArchiveTab } from './ArchiveTab'
// import { TabButtons } from './TabButtons'
// import { StyledTabPanel } from './styles'
// import { ISamplesTabValue } from '@models/samples'
import { selectExpandedSamples } from '@state/samples'
import { useAppDispatch } from '@hooks/redux'

import { ActiveTab } from './ActiveTab'

export const SampleTabs = React.memo(() => {
  // const [currentTab, setCurrentTab] = React.useState<ISamplesTabValue>('active')
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    return () => {
      dispatch(selectExpandedSamples({ sampleId: null }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ActiveTab />

  // * use this rendering for Sample list when we need Active and Archive tabs (delete rendering for ActiveTab only above)
  // return (
  //   <TabContext value={currentTab}>
  //     <TabButtons onChangeTab={setCurrentTab} />
  //     <StyledTabPanel value='active'>
  //       <ActiveTab />
  //     </StyledTabPanel>
  //     <StyledTabPanel value='archive'>
  //       <ArchiveTab />
  //     </StyledTabPanel>
  //   </TabContext>
  // )
})
