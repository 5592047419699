import React from 'react'
import { useQueryClient } from 'react-query'

import { IHRSample, ISample } from '@models/samples'
import { Tenant } from '@models/common/app'
import { TENANT_TYPES } from '@constants/queries'
import { TENANT_TYPE } from '@constants/tenants'

import { APIsCell } from './CellsVerify/APIsCell'
import { SampleID } from './CellsVerify/SampleID'
import { Manufacturer } from './CellsVerify/Manufacturer'
import { ExpirationDateCell } from './CellsVerify/ExpirationDateCell'
import { Toggler } from './Toggler'
import { SampleName } from './CellsScreenr/SampleName'
import { PhysicalForm } from './CellsScreenr/PhysicalForm'
import { Weight } from './CellsScreenr/Weight'
import { HrSampleID } from './CellsScreenr/SampleID'
import { Photo } from './CellsScreenr/Photo'

interface SampleCellsProps {
  sample: ISample | IHRSample
  isSampleSelected?: boolean
}

export const SampleCells: React.FC<SampleCellsProps> = React.memo(
  ({ sample, isSampleSelected }) => {
    const queryClient = useQueryClient()
    const tenantType = queryClient.getQueryData<Tenant>(TENANT_TYPES)
    const isVerify = tenantType?.type === TENANT_TYPE.legalDrugs

    const cellsVerify = [Toggler, SampleID, Manufacturer, APIsCell, ExpirationDateCell]
    const cellsScreenr = [Toggler, Photo, HrSampleID, SampleName, PhysicalForm, Weight]

    return (
      <>
        {isVerify ? (
          <>
            {cellsVerify.map((Cell, idx) => (
              <Cell
                key={idx}
                sample={sample as ISample}
                isSampleSelected={isSampleSelected}
                isVerify={isVerify}
              />
            ))}
          </>
        ) : (
          <>
            {cellsScreenr.map((Cell, idx) => (
              <Cell
                key={idx}
                sample={sample as IHRSample}
                isSampleSelected={isSampleSelected}
                isVerify={isVerify}
              />
            ))}
          </>
        )}
      </>
    )
  }
)
