import { useInfiniteQuery } from 'react-query'
import { StringParam, useQueryParams } from 'use-query-params'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { fetchReports } from '@api/testingReports'
import { ISamplesData } from '@models/samples'
import { setMaintenanceModePage } from '@state/user'
import { filterStringParamsQueries } from '@components/shared/FilterAside/constants'
import { TEST_REPORTS } from '@constants/testReports'

export const useFetchReports = <T>() => {
  const dispatch = useDispatch()

  const [queryString] = useQueryParams({
    ordering: StringParam,
    desired_test_reports: StringParam,
    ...filterStringParamsQueries,
  })

  const {
    ordering,
    desired_test_reports,
    apis,
    excipients,
    assignees,
    drug_classes,
    manufacturers,
    testingDate,
    laboratories,
    taskResult,
    test_types,
    started_collection_date,
  } = queryString

  const assigneesModified =
    assignees &&
    assignees.split(',').map(item => {
      const typeOfRole =
        item.slice(item.indexOf('.') + 1) === '1'
          ? { role: 'inventory_manager' }
          : { role: 'laboratory_assistant' }

      return { id: item.substring(0, item.indexOf('.')), ...typeOfRole }
    })

  const capitalizedTaskResult = taskResult
    ?.split(',')
    .map(item => {
      return item.charAt(0).toUpperCase() + item.slice(1)
    })
    .join(',')

  const queryKey = [
    TEST_REPORTS,
    desired_test_reports,
    ordering,
    apis,
    excipients,
    assigneesModified,
    drug_classes,
    manufacturers,
    testingDate,
    laboratories,
    capitalizedTaskResult,
    test_types,
    started_collection_date,
  ]

  return useInfiniteQuery<ISamplesData<T>, IError>(queryKey, fetchReports, {
    getNextPageParam: lastPage => {
      const { next: nextPageUrl } = lastPage.links

      if (nextPageUrl) {
        const queryString = nextPageUrl.slice(nextPageUrl.indexOf('?'))
        const urlParams = new URLSearchParams(queryString)
        const nextPage = urlParams.get('page')

        return nextPage
      }
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
