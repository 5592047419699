import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { addAttachments } from '@api/attachments'
import { ATTACHMENTS } from '@constants/queries'
import { IAttachmentResultData, IAttachments } from '@models/attachments'
import { IError } from '@models/common/app'
import { toggleFlag } from '@state/app'
import { setMaintenanceModePage } from '@state/user'
import { setAddedAttachments } from '@state/tasks'

export const useAddAttachments = (isEditAttachments?: boolean) => {
  const client = useQueryClient()
  const dispatch = useDispatch()
  const mutation = useMutation<IAttachmentResultData, IError, FormData>(addAttachments, {
    onMutate: () => {
      dispatch(toggleFlag('isAddingAttachment'))
    },
    onSuccess: attachments => {
      if (!isEditAttachments) {
        const { object_id } = attachments.result[0]
        client.setQueryData<IAttachments | undefined>([ATTACHMENTS, object_id], oldData => {
          return oldData && [...oldData, ...attachments.result]
        })

        client.invalidateQueries([ATTACHMENTS, object_id])
      }

      // put added attachments data to store, because sometimes cache doesn't clean,
      // and we get correct data from BE but not correct data from "useMutation()" hook
      dispatch(setAddedAttachments(attachments))
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    onSettled: (data, error, variables, context) => {
      dispatch(toggleFlag('isAddingAttachment'))
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
