import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'

import { selectUser, setIsAutoLogout } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { protectedRoutes } from '@configs/routes.config'
import logoVerify from '@assets/icons/logo-white-verify.svg'
import logoScreenr from '@assets/icons/logo-screenr.svg'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_USER_NAME } from '@constants/common'
import { useToggle } from '@hooks/common'
import { FeedbackForm } from '@components/modules/FeedbackForm'
import { selectSamplesChanged, setIsChangedField } from '@state/samples'
import { ConfirmationModal, UserAvatar } from '@components/shared'
import { AutoLogOut } from '@helpers/ui/AutoLogOut'
import { ReactComponent as MicroscopeIcon } from '@assets/icons/mini-microscope-icon.svg'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'

import { Navigation } from './Navigation'
import { ProfileMenu } from './ProfileMenu'
import {
  StyledNav,
  AvatarBox,
  Logo,
  Name,
  Role,
  InfoUserBox,
  MenuContainer,
  StyledBurgerMenu,
  StyledText,
  StyledBox,
} from './styles'

export const Header: React.FC = React.memo(() => {
  const [isOpenProfileMenu, setIsOpenProfileMenu] = React.useState<boolean>(false)
  const { first_name, role, sex, laboratory } = useAppSelector(selectUser)
  const { isTenantType } = useTenant()
  const isLegalDrugs = isTenantType(TENANT_TYPE.legalDrugs)

  const history = useHistory()
  const isMobile = useMediaQuery('(max-width:767px)')
  const isTablet = useMediaQuery('(max-width:1024px)')
  const { startInterval, tracker, cleanUp } = AutoLogOut()

  const [isModalOpen, toggleModal] = useToggle()

  const getLocation = document.location.origin
  const isTestLocation =
    getLocation.includes('dev') || getLocation.includes('stage') || getLocation.includes('custom')

  const handleClickProfileMenu = () => {
    if (isTablet) {
      setIsOpenProfileMenu(true)
    }
  }

  const isAuto = localStorage.getItem('auto-logout')

  React.useEffect(() => {
    dispatch(setIsAutoLogout(isAuto === 'true'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuto])

  React.useEffect(() => {
    startInterval()
    tracker()
    return () => cleanUp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // * [ check is any fields in create sample page was changed
  const dispatch = useDispatch()

  const [showModal, setShowModal] = useToggle()
  const isChangedField = useAppSelector(selectSamplesChanged)

  const goNavLinkConfirmation = () => {
    history.replace({
      pathname: protectedRoutes.home.path,
    })
    dispatch(setIsChangedField(false))
  }

  const homeButtonHandler = () => {
    if (isChangedField) {
      setShowModal()
    } else history.push(protectedRoutes.home.path)
  }
  // * check is any fields in create sample page was changed ]

  return (
    <>
      <StyledNav $isMobile={isMobile} $isTablet={isTablet} $isTenantLegalDrugs={isLegalDrugs}>
        <Box display='flex' alignItems='center'>
          <Logo
            src={isLegalDrugs ? logoVerify : logoScreenr}
            alt='logo'
            onClick={homeButtonHandler}
          />
          {isTestLocation && (
            <StyledBox>
              <StyledText>Training env</StyledText>
              <MicroscopeIcon />
            </StyledBox>
          )}
        </Box>

        {!isMobile ? (
          <>
            <Navigation role={role.key} lab={laboratory} />
            <Box display='flex' alignItems='center'>
              <MenuContainer>
                <AvatarBox onClick={handleClickProfileMenu}>
                  <UserAvatar width='41px' height='41px' sex={sex.key} role={role.key} />
                  <InfoUserBox>
                    <Name $isTenantLegalDrugs={isLegalDrugs}>
                      {stringSlice(first_name, TRUNCATED_USER_NAME)}
                    </Name>
                    <Role $isTenantLegalDrugs={isLegalDrugs}>{role.value}</Role>
                  </InfoUserBox>
                </AvatarBox>
                <ProfileMenu isOpenProfileMenu={isOpenProfileMenu} toggleModal={toggleModal} />
              </MenuContainer>
            </Box>
          </>
        ) : (
          <>
            <StyledBurgerMenu
              type='button'
              className={isOpenProfileMenu ? 'open-menu' : ''}
              onClick={() => setIsOpenProfileMenu(!isOpenProfileMenu)}
            >
              <span />
            </StyledBurgerMenu>
            <ProfileMenu isOpenProfileMenu={isOpenProfileMenu} isMobile toggleModal={toggleModal} />
          </>
        )}
      </StyledNav>

      {isModalOpen && <FeedbackForm toggleModal={toggleModal} />}

      {showModal && (
        <ConfirmationModal
          title={'Are you sure you want to cancel sample creation?'}
          cancelBtnText='Continue creation'
          confirmButtonText='Cancel'
          goBackModal
          onClose={setShowModal}
          onConfirm={goNavLinkConfirmation}
        />
      )}
    </>
  )
})
