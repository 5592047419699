import React from 'react'
import { Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { Tooltip, Button } from '@components/shared'
import { ReactComponent as IconForGpsTooltip } from '@assets/icons/gps-tooltip-icon.svg'
import { createSample } from '@state/sampleCreate'
import {
  sampleCreateIds,
  setSampleCreateId,
  setAddSampleButtonPushed,
  addSampleButtonPushedNumb,
} from '@state/app'
import { useAppSelector } from '@hooks/redux'
import { headerCellsData } from '@constants/mappings'
import { sampleCreateUIData } from '@state/common/sampleCreateUIState'

import * as Styled from '../styles'
import { TableHeaderPropTypes, TableHeaderTypes } from '../sampleCreate.types'

export const TableHeader = React.forwardRef<HTMLDivElement, TableHeaderPropTypes>(
  ({ isSticky }, ref) => {
    const dispatch = useDispatch()
    const allSampleCreateIds = useAppSelector(sampleCreateIds)
    const { isToxicityAdded, isTemperatureAdded } = useAppSelector(sampleCreateUIData)
    const isShowAllCell = isToxicityAdded || isTemperatureAdded
    const addSampleButtonPushed = useAppSelector(addSampleButtonPushedNumb)

    const createNewSample = () => {
      const id = uuid()
      dispatch(createSample(id))
      dispatch(setSampleCreateId(id))

      // for helper tooltip about cope of samples
      if (addSampleButtonPushed < 3) dispatch(setAddSampleButtonPushed(addSampleButtonPushed + 1))
    }

    return (
      <>
        <Styled.TableHeaderWrap ref={ref} isSticky={isSticky}>
          <Styled.TableHeader>
            {headerCellsData.map(
              ({ cellName, isRequired, tooltip, width }: TableHeaderTypes, idx) => {
                if (
                  (cellName === 'Sample type' ||
                    cellName === 'Storage notes' ||
                    cellName === 'Storage documents') &&
                  !isShowAllCell
                ) {
                  return null
                }
                if (cellName === 'Toxicity' && !isToxicityAdded) return null
                if (cellName === 'Storage temperature' && !isTemperatureAdded) return null

                return (
                  <Styled.TableHeaderItem key={`${cellName}_${idx}`} $width={width}>
                    <Typography component='span' fontWeight={700}>
                      {cellName}
                    </Typography>

                    {isRequired && (
                      <Typography
                        variant='body1'
                        color='secondary.light'
                        sx={{ marginLeft: '8px' }}
                      >
                        (Required)
                      </Typography>
                    )}
                    {tooltip && (
                      <Tooltip title={tooltip}>
                        <IconForGpsTooltip
                          style={{ marginLeft: '8px' }}
                          width='24px'
                          height='24px'
                        />
                      </Tooltip>
                    )}
                  </Styled.TableHeaderItem>
                )
              }
            )}
            <Styled.TableHeaderItem $width={200}>Action</Styled.TableHeaderItem>
          </Styled.TableHeader>

          <Styled.AddNewSampleRow>
            <Styled.TableCell>
              <Button
                variant='text'
                height='48px'
                width='155px'
                ml='4px'
                onClick={createNewSample}
                disabled={allSampleCreateIds.length >= 100}
                startIconAdd
              >
                Add sample
              </Button>
            </Styled.TableCell>
            <Styled.FixedTableCell $width={200} />
          </Styled.AddNewSampleRow>
        </Styled.TableHeaderWrap>
      </>
    )
  }
)
