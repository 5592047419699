import React from 'react'

import { ITaskLab } from '@models/tasks'
import { StyledTableSampleCell } from '@styles'

interface NotesProps {
  task: ITaskLab
  $isMobile?: boolean
}

export const TaskId: React.FC<NotesProps> = ({ task, $isMobile }) => {
  const { id: TaskId } = task

  return <StyledTableSampleCell $isMobile={$isMobile}>{TaskId}</StyledTableSampleCell>
}
