import React from 'react'
import { useFormContext } from 'react-hook-form'

import { PureAttachments } from '@components/shared/Hr'
import {
  ATTACHMENT_TEST_STRIP_TITLE,
  ATTACHMENT_FTS_SECTION,
  TEST_STRIP_ATTACHMENTS,
} from '@constants/tests'
import { IAttachments } from '@models/attachments'
import { AvailableSectionSettings } from '@models/tasks'

import { StyledTitle, StyledResultsBox } from '../styles'

export const PhotoOfTestStrip = () => {
  const { getValues, setValue } = useFormContext()
  const { task } = getValues()

  const { ordering_key: order, title } = React.useMemo(() => {
    return task.available_section_settings.find(
      (item: AvailableSectionSettings) => item.section.name === ATTACHMENT_FTS_SECTION
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEditedAttachments = (data: IAttachments) => {
    setValue(
      `fields.${TEST_STRIP_ATTACHMENTS}`,
      data.map(attachment => attachment.id)
    )
  }

  return (
    <StyledResultsBox $mb={24} $order={order}>
      <StyledTitle>{`${order}. ${title}`}</StyledTitle>
      <PureAttachments
        contentType='task'
        modalType='materials'
        section={ATTACHMENT_FTS_SECTION}
        initialAttachments={task.attachments}
        sectionTitle={ATTACHMENT_TEST_STRIP_TITLE}
        setAttachmentsToParent={handleEditedAttachments}
        attachmentsIsLoading={(loading: boolean) => setValue('isLoading', loading)}
      />
    </StyledResultsBox>
  )
}
