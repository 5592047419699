import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Button } from '@components/shared'
import { INewSample } from '@models/samples'
import { useUpdateSample } from '@hooks/queries'
import { parseSampleFormData } from '@helpers/forms'
import { FUNDING } from '@constants/samples'

import { ButtonBox } from './styles'

interface EditButtonsProps {
  sampleId: number
  toggleEditing: () => void
}

export const EditButtons: React.FC<EditButtonsProps> = ({ sampleId, toggleEditing }) => {
  const { handleSubmit, setError } = useFormContext()

  const updateSampleM = useUpdateSample()

  const editSamplesHandler = (data: INewSample) => {
    if (data.project?.name && data.funding.length === 0) {
      setError(FUNDING, {
        type: 'manual',
        message: 'Project should be with Funding. Please delete Project or add Funding.',
      })
      return
    }
    const parsedData = parseSampleFormData([data])[0] as INewSample
    updateSampleM.mutate({ ...parsedData, id: sampleId }, { onSettled: toggleEditing })
  }

  return (
    <ButtonBox>
      <Button
        type='button'
        variant='outlined'
        color='secondary'
        bgColor='#fff'
        width='205px'
        mr='20px'
        onClick={toggleEditing}
      >
        Cancel
      </Button>
      <Button
        type='submit'
        variant='contained'
        width='205px'
        height='44px'
        loading={updateSampleM.isLoading}
        onClick={handleSubmit(editSamplesHandler)}
      >
        Save
      </Button>
    </ButtonBox>
  )
}
