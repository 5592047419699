import { styled, Box } from '@mui/material'

export const StyledBox = styled(Box)`
  display: flex;
  align-items: end;

  & .MuiTypography-root {
    margin-right: 32px;
  }

  & .MuiChip-root {
    margin-bottom: 5px;
  }
`

export const ChipGroup = styled('div')`
  display: flex;

  margin: 0 -5px;

  & .MuiChip-root {
    margin: 0 5px;
  }
`
