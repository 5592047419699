import { FlexWrapper } from '@styles'

import { WholeInfo, BriefInfo } from './components'

interface UserInfoProps {
  isEdit: boolean
  timezone: string | null
}

export const UserInfo: React.FC<UserInfoProps> = ({ isEdit, timezone }) => {
  return (
    <FlexWrapper mb='40px'>
      <BriefInfo />
      <WholeInfo isEdit={isEdit} timezone={timezone} />
    </FlexWrapper>
  )
}
