import { ExpirationDate } from '@components/shared'
import { ISample } from '@models/samples'

import { StyledTableCell } from '../styles'

interface ExpirationDateCellProps {
  sample: ISample
}

export const ExpirationDateCell: React.FC<ExpirationDateCellProps> = ({ sample }) => {
  const { expiration_date: expDate } = sample

  return (
    <StyledTableCell>
      <ExpirationDate expirationDate={expDate} />
    </StyledTableCell>
  )
}
