import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useToggle } from '@hooks/common'
import { useFetchSample } from '@hooks/queries'
import { FullPageError } from '@components/shared'
import { editingNotesFieldId, isEditingNotes } from '@state/samples'
import { ISample } from '@models/samples'
import { setEditFundingList } from '@state/app'

import { SampleInfo, SubSampleInfo, PageHeader as SampleDetailsPageHeader } from './components'

interface MatchParams {
  sampleId: string
}

export const SampleDetails: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>()
  const dispatch = useDispatch()

  const fetchSampleQ = useFetchSample<ISample>(params.sampleId)
  const [isEditing, toggleEditing] = useToggle()
  const sample = fetchSampleQ.data

  const refetchSample = () => fetchSampleQ.refetch()

  React.useEffect(() => {
    if (fetchSampleQ.isFetching) {
      dispatch(isEditingNotes(true))
    } else if (fetchSampleQ.isFetched) {
      dispatch(isEditingNotes(false))
      dispatch(editingNotesFieldId(null))
    }
  }, [dispatch, fetchSampleQ.isFetched, fetchSampleQ.isFetching])

  const onCancel = React.useCallback(() => {
    toggleEditing()
    dispatch(setEditFundingList([]))
  }, [dispatch, toggleEditing])

  return (
    <>
      {sample && (
        <SampleDetailsPageHeader
          sample={sample}
          sampleId={params.sampleId}
          toggleEditing={onCancel}
          isEditing={isEditing}
        />
      )}

      {fetchSampleQ.isError ? (
        <FullPageError
          errorTextTitle={`Something went wrong with sample details loading`}
          btnText='Refresh'
          onBtnClick={refetchSample}
          pt='40px'
        />
      ) : (
        <>
          <SampleInfo
            sample={sample}
            isLoading={fetchSampleQ.isLoading}
            isEditing={isEditing}
            toggleEditing={toggleEditing}
          />
          {!!sample && (
            <SubSampleInfo
              specificId={sample?.specific_id}
              sampleId={params.sampleId}
              subSamples={sample?.sub_samples || []}
            />
          )}
        </>
      )}
    </>
  )
}
