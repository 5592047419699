import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { useDispatch } from 'react-redux'

import { fetchBlockchainHistory } from '@api/common'
import { BlockchainHistoryType, IBlockchainHistoryData, IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useFetchBlockchainHistory = (
  itemId: number,
  blockchainHistoryType: BlockchainHistoryType
): UseInfiniteQueryResult<IBlockchainHistoryData> => {
  const dispatch = useDispatch()
  return useInfiniteQuery<IBlockchainHistoryData, IError>(
    [`${blockchainHistoryType}BlockchainHistory`, itemId],
    fetchBlockchainHistory.bind(null, itemId, blockchainHistoryType),
    {
      getNextPageParam: lastPage => {
        const { next: nextPageUrl } = lastPage.links

        if (nextPageUrl) {
          const queryString = nextPageUrl.slice(nextPageUrl.indexOf('?'))
          const urlParams = new URLSearchParams(queryString)
          const nextPage = urlParams.get('page')

          return nextPage
        }
      },
      onError: error => {
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
      },
      refetchInterval: 7000,
    }
  )
}
