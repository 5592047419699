import React from 'react'
import { Box } from '@mui/material'

import { TandemMS, idPAD } from '@models/tests'
import { HrIdPADResults } from '@components/shared/Hr/IdPAD/HrIdPADResults'
import { HrTandemMSResults } from '@components/shared/Hr/TandemMS/HrTandemMSResults'

import { HrMainInfo } from './components/HrMainInfo'
import { HrTaskInfoPropTypes } from './hrTaskInfo.types'
import { HrScreeningResults } from './components/HrScreeningResults'

export const HrTaskInfo: React.FC<HrTaskInfoPropTypes> = ({ task, isLoading }) => {
  const isIdPAD = !!(task && task.test_type.name === idPAD)
  const isTandemMS = !!(task && task.test_type.name === TandemMS)

  const renderResults = () => {
    if (isIdPAD) {
      return <HrIdPADResults task={task} isLoading={isLoading} />
    }
    if (isTandemMS) {
      return <HrTandemMSResults task={task} isLoading={isLoading} />
    }
    return <HrScreeningResults task={task} isLoading={isLoading} />
  }

  return (
    <Box display='flex'>
      <HrMainInfo task={task} isLoading={isLoading} />
      {renderResults()}
    </Box>
  )
}
