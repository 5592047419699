import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { DELIVERY_SERVICES } from '@constants/queries'
import { getDeliveryServices } from '@api/packages'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export function useGetDeliveryServices<T>() {
  const dispatch = useDispatch()
  return useQuery<unknown, IError, T>([DELIVERY_SERVICES], () => getDeliveryServices(), {
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    retry: 1,
    retryDelay: 1500,
  })
}
