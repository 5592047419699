import React from 'react'
import { Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { IPackage, PackageContentType } from '@models/packages'
import { stringSlice } from '@helpers/ui/common'
import { setLaboratoryForWorkspace } from '@state/workSpace'
import { protectedRoutes } from '@configs/routes.config'
import { ParagraphLink, Tooltip } from '@components/shared'
import { TRUNCATED_TEXT_LOCATION } from '@constants/common'

import { StyledTableCell } from '../../../styles'

interface DestinationProps {
  pack: IPackage
  contentType: PackageContentType
}

export const FromLaboratory: React.FC<DestinationProps> = React.memo(
  props => {
    const { pack, contentType } = props

    const dispatch = useDispatch()
    const history = useHistory()
    const pageTitle = contentType === 'default' ? 'Your packages' : `${contentType} packages`

    const viewLaboratoryInfoEndPoint = () => {
      if (pack.source) {
        dispatch(setLaboratoryForWorkspace(pack.source))
      }

      history.replace({
        pathname: protectedRoutes.yourWorkspace.path,
        state: [
          {
            path: protectedRoutes.packages.path,
            url: protectedRoutes.packages.path,
            search: `?package_type=${contentType}`,
            title: pageTitle,
          },
        ],
      })
    }

    return (
      <StyledTableCell>
        {pack.destination ? (
          <Tooltip
            title={pack.source.name || ''}
            shouldBeHidden={
              ((pack.source.name && pack.source.name.length) || 0) <= TRUNCATED_TEXT_LOCATION
            }
          >
            <ParagraphLink onClick={viewLaboratoryInfoEndPoint}>
              {stringSlice(pack.source.name, TRUNCATED_TEXT_LOCATION)}
            </ParagraphLink>
          </Tooltip>
        ) : (
          <Typography color='secondary.light'>Not selected</Typography>
        )}
      </StyledTableCell>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.pack.destination === nextProps.pack.destination
  }
)
