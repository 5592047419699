import { Box, Typography } from '@mui/material'

import { StyledErrorBox } from './styles'

interface ErrorBoxProps {
  errorMainField?: string
  errorDepartment?: string
  errorForAllFields?: string[]
}

export const ErrorBox: React.FC<ErrorBoxProps> = props => {
  const { errorMainField, errorDepartment } = props

  return (
    <StyledErrorBox $isError={!!errorMainField}>
      {errorMainField && (
        <Typography fontSize='12px' color='#AF4645' mt='4px' width='242px'>
          {errorMainField}
        </Typography>
      )}

      {errorDepartment && (
        <Typography fontSize='12px' color='#AF4645' mt='4px' ml={!!errorMainField ? '0' : '242px'}>
          {errorDepartment}
        </Typography>
      )}
    </StyledErrorBox>
  )
}

export const ErrorForAllFields: React.FC<ErrorBoxProps> = props => {
  const { errorForAllFields } = props

  return (
    <Box sx={{ height: '16px', marginBottom: '24px' }}>
      <Typography fontSize='12px' color='#AF4645' mt='4px'>
        {errorForAllFields}
      </Typography>
    </Box>
  )
}
