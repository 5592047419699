import { lazy } from 'react'

import { UserRole } from '@models/common/user'
import { IProtectedRoutes, IPublicRoutes } from '@models/common/routes'
import { INVENTORY_MANAGER, LABORATORY_ASSISTANT } from '@constants/user'
import {
  BASE_APP_ROUTE,
  DETAILS,
  LOGIN_ROUTE,
  RECOVER_PASS_ROUTE,
  TENANTS_ROUTE,
} from '@constants/common'
// import { PurchaseSamples } from '@components/modules/Modals/PurchaseSamples'
import { MobileModal } from '@components/modules/Modals/LogIntoPadApp/MobileModal'
import { OtherSessionPage } from '@components/modules/OtherSessionPage'

const AddSamples = lazy(() =>
  import('@components/modules/Modals/AddSamples').then(module => ({
    default: module.AddSamples,
  }))
)
const EditAttachedMaterials = lazy(() =>
  import('@components/modules/Modals/EditAttachedMaterials').then(module => ({
    default: module.EditAttachedMaterials,
  }))
)
const GeneratePackageQr = lazy(() =>
  import('@components/modules/Modals/GeneratePackageQr').then(module => ({
    default: module.GeneratePackageQr,
  }))
)
const MaintenanceMode = lazy(() =>
  import('@components/modules/MaintenanceMode').then(module => ({
    default: module.MaintenanceMode,
  }))
)
const Profile = lazy(() =>
  import('../pages/Profile').then(module => ({
    default: module.Profile,
  }))
)
const Samples = lazy(() =>
  import('../pages/Samples').then(module => ({
    default: module.Samples,
  }))
)
const SampleCreation = lazy(() =>
  import('../pages/SampleCreation').then(module => ({
    default: module.SampleCreation,
  }))
)
const ForgotPassword = lazy(() =>
  import('../pages/ForgotPassword').then(module => ({
    default: module.ForgotPassword,
  }))
)
const Packages = lazy(() =>
  import('../pages/Packages').then(module => ({
    default: module.Packages,
  }))
)
const TestingReports = lazy(() =>
  import('../pages/TestingReports').then(module => ({
    default: module.TestingReports,
  }))
)
const Tasks = lazy(() =>
  import('../pages/Tasks').then(module => ({
    default: module.Tasks,
  }))
)
const Home = lazy(() =>
  import('../pages/Home').then(module => ({
    default: module.Home,
  }))
)
const SampleDetails = lazy(() =>
  import('../pages/SampleDetails').then(module => ({
    default: module.SampleDetails,
  }))
)
const PackageDetails = lazy(() =>
  import('../pages/PackageDetails').then(module => ({
    default: module.PackageDetails,
  }))
)
const Test = lazy(() =>
  import('../pages/Test').then(module => ({
    default: module.Test,
  }))
)
const TasksDetails = lazy(() =>
  import('../pages/TasksDetails').then(module => ({
    default: module.TasksDetails,
  }))
)
const YourWorkspacePage = lazy(() =>
  import('../pages/YourWorkspace').then(module => ({
    default: module.YourWorkspacePage,
  }))
)
const ManageProjects = lazy(() =>
  import('../pages/ManageProjects').then(module => ({
    default: module.ManageProjects,
  }))
)
const LoginToProd = lazy(() =>
  import('../pages/LoginToProd').then(module => ({
    default: module.LoginToProd,
  }))
)
const Tenants = lazy(() =>
  import('../pages/Tenants').then(module => ({
    default: module.Tenants,
  }))
)
const RequestAccess = lazy(() =>
  import('../pages/RequestAccess').then(module => ({
    default: module.RequestAccess,
  }))
)
const RecoverPass = lazy(() =>
  import('../pages/RecoverPass').then(module => ({
    default: module.RecoverPass,
  }))
)
// * [Access control by user roles

const onlyManager: UserRole[] = [INVENTORY_MANAGER]
const onlyAssistant: UserRole[] = [LABORATORY_ASSISTANT]
const eachRole: UserRole[] = [INVENTORY_MANAGER, LABORATORY_ASSISTANT]

// * Access control by user roles]

// * [Access control by user laboratory

const allowedCreateSamples = { createSamples: true }

// * Access control by user laboratory]

// * [Public routes should be added here

export const publicRoutes: IPublicRoutes = {
  login: {
    id: 1,
    path: LOGIN_ROUTE,
    exact: false,
    component: LoginToProd,
  },
  forgotPassword: {
    id: 2,
    path: `/account/forgot_password`,
    exact: true,
    component: ForgotPassword,
  },
  recoverPassword: {
    id: 3,
    path: `${RECOVER_PASS_ROUTE}/:user_id/:token/:tenant_name`,
    exact: true,
    component: RecoverPass,
  },
  maintenanceModePage: {
    id: 4,
    path: '/subscriptions',
    exact: true,
    component: MaintenanceMode,
  },
  otherSessionPage: {
    id: 5,
    path: '/otherSession',
    exact: true,
    component: OtherSessionPage,
  },
  tenants: {
    id: 6,
    path: TENANTS_ROUTE,
    exact: true,
    component: Tenants,
  },
  requestAccess: {
    id: 7,
    path: `/account/request_access`,
    exact: true,
    component: RequestAccess,
  },
}

// * Public routes should be added here]

// * [Protected routes should be added here
// ? if you added new rout and it doesn't work - check routs in file routes.ts
// ? if you added new modal to page and it empty page behind of modal - check exact: false (mast be) for this page where you added modal

export const protectedRoutes: IProtectedRoutes = {
  home: {
    id: 1,
    path: `${BASE_APP_ROUTE}/home`,
    exact: true,
    access: eachRole,
    component: Home,
    name: 'Home',
  },
  samples: {
    id: 2,
    path: `${BASE_APP_ROUTE}/samples`,
    exact: true,
    access: onlyManager,
    component: Samples,
    name: 'Sample list',
  },
  sampleDetails: {
    id: 3,
    path: `${BASE_APP_ROUTE}/samples/:sampleId${DETAILS}`,
    exact: false,
    access: onlyManager,
    component: SampleDetails,
    name: 'Sample',
  },
  tasks: {
    id: 4,
    path: `${BASE_APP_ROUTE}/tasks`,
    exact: true,
    access: onlyAssistant,
    component: Tasks,
    name: 'List of tasks',
  },
  createSample: {
    id: 5,
    path: `${BASE_APP_ROUTE}/samples/create`,
    exact: false,
    access: [allowedCreateSamples, ...eachRole],
    component: SampleCreation,
    name: 'Create samples',
  },
  profile: {
    id: 6,
    path: `${BASE_APP_ROUTE}/profile`,
    exact: true,
    access: eachRole,
    component: Profile,
    name: 'Profile',
  },
  // Requests for future release
  // requests: {
  //   id: 8,
  //   path: `${BASE_APP_ROUTE}/requests`,
  //   exact: false,
  //   access: eachRole,
  //   component: Requests,
  //   name: 'Your requests',
  // },
  packages: {
    id: 9,
    path: `${BASE_APP_ROUTE}/packages`,
    exact: true,
    access: eachRole,
    component: Packages,
    name: 'Your packages',
  },
  packageDetails: {
    id: 10,
    path: `${BASE_APP_ROUTE}/packages/:packageId${DETAILS}`,
    exact: false,
    access: eachRole,
    component: PackageDetails,
    name: 'Package',
  },
  testingReports: {
    id: 11,
    path: `${BASE_APP_ROUTE}/testing_reports`,
    exact: true,
    access: eachRole,
    component: TestingReports,
    name: 'Test reports',
  },
  addSamples: {
    id: 12,
    path: `${BASE_APP_ROUTE}/packages/:packageId${DETAILS}/add_samples`,
    exact: true,
    access: eachRole,
    component: AddSamples,
    name: 'Add samples',
  },
  test: {
    id: 13,
    path: `${BASE_APP_ROUTE}/test/:testType/:taskId`,
    exact: true,
    access: onlyAssistant,
    component: Test,
    name: '',
  },
  tasksDetails: {
    id: 14,
    path: `${BASE_APP_ROUTE}/tasks/:taskId${DETAILS}`,
    exact: false,
    access: eachRole,
    component: TasksDetails,
    name: 'Task',
  },
  attachMaterials: {
    id: 15,
    path: `${BASE_APP_ROUTE}/packages/:packageId${DETAILS}/attach/:modalType`,
    exact: false,
    access: eachRole,
    component: EditAttachedMaterials,
    name: 'Shipment materials',
  },
  attachShipmentMaterials: {
    id: 16,
    path: `${BASE_APP_ROUTE}/packages/:packageId${DETAILS}/attach/:modalType`,
    exact: false,
    access: eachRole,
    component: EditAttachedMaterials,
    name: 'Storage documents',
  },
  attachResults: {
    id: 17,
    path: `${BASE_APP_ROUTE}/tasks/:taskId${DETAILS}/attach/:modalType`,
    exact: false,
    access: eachRole,
    component: EditAttachedMaterials,
    name: 'Attach results',
  },
  attachStorage: {
    id: 18,
    path: `${BASE_APP_ROUTE}/samples/:sampleId${DETAILS}/attach/:modalType`,
    exact: false,
    access: eachRole,
    component: EditAttachedMaterials,
    name: 'Attach storage',
  },
  createSampleAttachStorage: {
    id: 19,
    path: `${BASE_APP_ROUTE}/samples/create/attach/:modalType`,
    exact: false,
    access: eachRole,
    component: EditAttachedMaterials,
    name: 'Attach storage Create sample',
  },
  generateQr: {
    id: 20,
    path: `${BASE_APP_ROUTE}/packages/:packageId${DETAILS}/generate_qr`,
    exact: true,
    access: eachRole,
    component: GeneratePackageQr,
    name: 'Generate QR code',
  },
  // Requests for future release
  // purchaseSamples: {
  //   id: 21,
  //   path: `${BASE_APP_ROUTE}/requests/purchase_samples`,
  //   exact: true,
  //   access: eachRole,
  //   component: PurchaseSamples,
  //   name: 'Request samples',
  // },
  logIntoPadAppMobile: {
    id: 22,
    path: `${BASE_APP_ROUTE}/tasks/log_into_pad_app`,
    exact: true,
    access: eachRole,
    component: MobileModal,
    name: 'Log into Pad App mobile',
  },
  yourWorkspace: {
    id: 23,
    path: `${BASE_APP_ROUTE}/your_workspace`,
    exact: true,
    access: eachRole,
    component: YourWorkspacePage,
    name: 'Your workspace',
  },
  createHrSampleAttachPhoto: {
    id: 24,
    path: `${BASE_APP_ROUTE}/samples/create/attach/:modalType`,
    exact: false,
    access: eachRole,
    component: EditAttachedMaterials,
    name: 'Attach photo Create sample',
  },
  viewHrSampleAttachPhoto: {
    id: 25,
    path: `${BASE_APP_ROUTE}/samples/:sampleId${DETAILS}/attach/:modalType`,
    exact: false,
    access: eachRole,
    component: EditAttachedMaterials,
    name: 'Attach photo View sample',
  },
  editHrSampleAttachPhoto: {
    id: 26,
    path: `${BASE_APP_ROUTE}/samples/:sampleId${DETAILS}/edit/attach/:modalType`,
    exact: false,
    access: eachRole,
    component: EditAttachedMaterials,
    name: 'Attach photo Edit sample',
  },
  manageProjects: {
    id: 27,
    path: `${BASE_APP_ROUTE}/manage_projects`,
    exact: true,
    access: onlyManager,
    component: ManageProjects,
    name: 'Manage projects',
  },
}
// * Protected routes should be added here]
