import { Box, BoxProps, styled } from '@mui/material'

interface TableCellProps extends BoxProps {
  isLastSample?: boolean
  isSampleSelected?: boolean
}

export const StyledTableCell = styled<React.FC<TableCellProps>>(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  margin-right: 10px;
`
