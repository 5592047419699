import React from 'react'
import { Box, useMediaQuery } from '@mui/material'

import { ConfirmationModal } from '@components/shared'
import { ASSAY_GROUP, IDPAD, TandemMS } from '@models/tests'
import { useUpdateManualTest, useUpdateTest } from '@hooks/queries'
import { useToggle } from '@hooks/common'
import { IHrTaskLab, ITestButton } from '@models/tasks'

import { TestResultsModal } from '../TestResultsModal'
import { AddResultStep } from './AddResultStep'

interface BodyProps {
  isTestStarted: boolean
  isResultsModalOpen: boolean
  toggleResultsModal: () => void
  setIsTestStarted: React.Dispatch<React.SetStateAction<boolean>>
  resultBtnName?: ITestButton
  task: IHrTaskLab
}

export const Body: React.FC<BodyProps> = props => {
  const { isTestStarted, isResultsModalOpen, toggleResultsModal, setIsTestStarted, task } = props

  const [isCancelModalOpen, toggleCancelModal] = useToggle()
  const taskId = task.id
  const currentStep = task.step
  const testGroupName = task.test_type.group.name
  const testName = task.test_type.name

  const isMobile = useMediaQuery('(max-width:767px)')

  const updateTestM = useUpdateTest()
  const cancelManualWayM = useUpdateManualTest()

  const nextStepHandler = () => {
    setIsTestStarted(true)
    if (testName !== IDPAD) toggleResultsModal()
    updateTestM.mutate({ taskId })
  }

  const finishTestHandler = () => {
    if (!isResultsModalOpen) toggleResultsModal()
  }

  React.useEffect(() => {
    if (currentStep === 0 && testGroupName === ASSAY_GROUP) nextStepHandler()
    if (currentStep === 0 && testName === TandemMS) nextStepHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, testGroupName])

  const cancelManualWayHandler = () => {
    cancelManualWayM.mutate({ taskId })
    setIsTestStarted(false)
  }

  return (
    <>
      <Box className='test-body'>
        <AddResultStep
          currentStep={currentStep}
          isLoading={updateTestM.isLoading}
          isTestStarted={isTestStarted}
          finishTestHandler={finishTestHandler}
          nextStepHandler={nextStepHandler}
          toggleResultsModal={toggleResultsModal}
          isTandemMSTest={testName === TandemMS}
          isTandemMSMethodSelected={!task.test_type_method}
        />

        {isResultsModalOpen && <TestResultsModal onClose={toggleResultsModal} task={task} />}
      </Box>

      {isCancelModalOpen && (
        <ConfirmationModal
          isMobile={isMobile}
          title='Cancel manual way?'
          subtitle='All your progress will be reset and you will be able to test through the PAD reader app'
          isDisabled={updateTestM.isLoading}
          confirmButtonText='Cancel anyway'
          cancelBtnText='Keep testing'
          onClose={toggleCancelModal}
          onConfirm={cancelManualWayHandler}
          mb='80px'
        />
      )}
    </>
  )
}
