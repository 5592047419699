import React from 'react'
import { Icon, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'

import { SubText, StyledTableSampleCell } from '@styles'
import { ITaskLab } from '@models/tasks'
import { unitIconSDeterminer } from '@helpers/ui/typeOfUnits'

interface UnitsProps {
  task: ITaskLab
  isMobile?: boolean
}

export const Units: React.FC<UnitsProps> = ({ task, isMobile }) => {
  const { units, unit_type } = task
  const theme = useTheme()

  const UnitIconType = unitIconSDeterminer(unit_type?.id || 1)

  return (
    <StyledTableSampleCell $isMobile={isMobile} $alignItems='flex-start'>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{units || '-'}</Typography>
        {unit_type && (
          <>
            <SubText sx={{ marginLeft: '8px', lineHeight: '20px', fontSize: 14 }}>
              {unit_type.name.toLocaleLowerCase()}
            </SubText>
            <Icon sx={{ marginLeft: '5px' }}>
              <UnitIconType fill={theme.palette.primary.main} stroke={theme.palette.primary.main} />
            </Icon>
          </>
        )}
      </Box>
    </StyledTableSampleCell>
  )
}
