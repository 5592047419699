import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'

import { ReactComponent as CopyIcon } from '@assets/icons/yourWorkSpaceIcons/copy-icon.svg'
import { ReactComponent as MapIcon } from '@assets/icons/yourWorkSpaceIcons/map-icon.svg'
import { ReactComponent as PhoneIcon } from '@assets/icons/yourWorkSpaceIcons/phone-call.svg'
import { ReactComponent as UserIcon } from '@assets/icons/yourWorkSpaceIcons/user.svg'
import { ReactComponent as MailIcon } from '@assets/icons/yourWorkSpaceIcons/mail.svg'
import { Button, Tooltip } from '@components/shared'
import { ILaboratory } from '@models/common/app'
import { SubText } from '@styles'
import { stringSlice } from '@helpers/ui/common'

import * as Styled from '../../../styles'
import { StyledParagraphLink } from '../../../../TabListOfUsers/components/Table/styles'

interface ContactsInfoProps {
  currentLaboratory?: ILaboratory
  copyInfoToClipboard: () => void
}

const linkSX = {
  textDecoration: 'none',
  color: 'black',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    textDecoration: 'underline',
  },
}

const spanSX = {
  color: 'black',
}

export const ContactsInfo: React.FC<ContactsInfoProps> = props => {
  const { currentLaboratory, copyInfoToClipboard } = props
  const defaultManager = currentLaboratory?.manager
    ? `${currentLaboratory?.manager.first_name} ${currentLaboratory?.manager.last_name}`
    : '-'
  const theme = useTheme()

  return (
    <Box mb='32px'>
      <Styled.ContactsBox>
        <Typography variant='subtitle1'>Contacts</Typography>

        {/* Copy info of laboratory */}
        <Button
          variant='outlined'
          color='secondary'
          width='90px'
          height='32px'
          disabled={!currentLaboratory}
          onClick={copyInfoToClipboard}
        >
          <CopyIcon fill={theme.palette.primary.main} style={{ marginRight: '8px' }} />
          Copy
        </Button>
      </Styled.ContactsBox>

      {/* Contacts info */}
      {/* address */}
      <Styled.ContactsInfoBox>
        <MapIcon stroke={theme.palette.primary.main} style={Styled.iconStyles} />
        <Tooltip
          title={currentLaboratory?.address || ''}
          shouldBeHidden={(currentLaboratory?.address?.length || 0) <= 50}
        >
          <Typography
            id='address'
            component={currentLaboratory?.address ? 'a' : 'span'}
            href={
              currentLaboratory?.address &&
              `https://maps.google.com/?q=${currentLaboratory?.address}`
            }
            target='_blank'
            sx={currentLaboratory?.address ? linkSX : spanSX}
          >
            {currentLaboratory?.address?.length ? stringSlice(currentLaboratory?.address, 50) : '-'}
          </Typography>
        </Tooltip>
      </Styled.ContactsInfoBox>

      {/* phone_numbers */}
      <Styled.ContactsInfoBox>
        <PhoneIcon stroke={theme.palette.primary.main} style={Styled.iconStyles} />

        <Box display='flex' flexDirection='column'>
          {currentLaboratory?.phone_numbers?.length
            ? currentLaboratory?.phone_numbers?.map(({ department, phone_number }, idx) => (
                <React.Fragment key={idx}>
                  <SubText id='phone_department' style={{ marginBottom: '4px' }}>
                    {department || '-'}
                  </SubText>
                  <Typography id='phone'>{phone_number}</Typography>
                </React.Fragment>
              ))
            : '-'}
        </Box>
      </Styled.ContactsInfoBox>

      {/* manager */}
      <Styled.ContactsInfoBox>
        <UserIcon stroke={theme.palette.primary.main} style={Styled.iconStyles} />
        <Box>
          <SubText style={{ marginBottom: '4px' }}>Main manager</SubText>
          <Tooltip title={defaultManager || ''} shouldBeHidden={(defaultManager.length || 0) <= 20}>
            <Typography>{stringSlice(defaultManager, 20)}</Typography>
          </Tooltip>
        </Box>
      </Styled.ContactsInfoBox>

      {/* emails */}
      <Styled.ContactsInfoBox>
        <MailIcon stroke={theme.palette.primary.main} style={Styled.iconStyles} />

        <Box display='flex' flexDirection='column'>
          {currentLaboratory?.emails?.length
            ? currentLaboratory?.emails?.map(({ department, email }, idx) => (
                <Box mb='8px' display='flex' flexDirection='column' key={idx}>
                  <SubText style={{ marginBottom: '4px' }}>{department || '-'}</SubText>
                  <Tooltip title={email} shouldBeHidden={email.length < 23}>
                    <StyledParagraphLink style={{ marginLeft: '0' }} href={`mailto: ${email}`}>
                      {stringSlice(email, 23)}
                    </StyledParagraphLink>
                  </Tooltip>
                </Box>
              ))
            : '-'}
        </Box>
      </Styled.ContactsInfoBox>
    </Box>
  )
}
