import React from 'react'

import { AttachmentPhoto } from '@models/samples'
import { ReactComponent as FrameIcon } from '@assets/icons/frame.svg'
import { BackdropPhoto } from '@components/shared/BackdropPhoto'

import { PhotoImg } from './styles'

interface PhotoProps {
  attachments?: AttachmentPhoto[]
  width?: string
  height?: string
  mr?: string
  isGoodQualityOfPhoto?: boolean
  photoInModal?: boolean
}

export const Photo: React.FC<PhotoProps> = props => {
  const {
    attachments,
    width = '45px',
    height = '45px',
    mr,
    isGoodQualityOfPhoto,
    photoInModal,
  } = props

  const [openBackdrop, setBackdropOpen] = React.useState(false)
  const [openPhoto, setOpenPhoto] = React.useState<AttachmentPhoto | null>(null)

  const handleBackdropClose = () => {
    setBackdropOpen(false)
  }

  const handleBackdropOpen = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    event.stopPropagation()
    if (!attachments?.length) return
    setBackdropOpen(true)
    event.stopPropagation()
  }

  const isAttachments = attachments?.some(element => typeof element === 'object')

  return (
    <>
      {isAttachments ? (
        attachments?.map(photo => (
          <React.Fragment key={photo?.id}>
            <PhotoImg
              loading='eager'
              src={isGoodQualityOfPhoto ? photo?.link : photo?.thumbnail_link}
              alt='sample'
              onClick={e => {
                if (photo?.link) {
                  handleBackdropOpen(e)
                  setOpenPhoto(photo)
                }
              }}
              width={width}
              height={height}
              mr={mr}
            />
          </React.Fragment>
        ))
      ) : (
        <FrameIcon width={width} height={height} />
      )}
      <BackdropPhoto
        photo={openPhoto}
        isOpenBackdrop={openBackdrop}
        handleBackdropClose={handleBackdropClose}
        isPhotoInModal={photoInModal}
      />
    </>
  )
}
