import { IChoice, IOption } from '@models/common/app'

export const obtainingSkills = (apiData: { skills: IOption[] }[]): IOption[] => {
  if (!!apiData?.length) {
    if (apiData.length === 1) {
      return apiData[0].skills
    } else if (apiData.length > 1) {
      let clearSkills: IOption[] = []

      apiData.forEach(api => {
        if (api.skills.length) {
          if (!clearSkills.length) {
            clearSkills = [...api.skills]
          } else {
            api.skills.forEach(apiSkill => {
              if (!clearSkills.some(clearSkill => clearSkill.name === apiSkill.name)) {
                clearSkills.push(apiSkill)
              }
            })
          }
        }
      })

      return clearSkills
    }
  }

  return []
}

type Value = {
  max_temperature?: string | null
  min_temperature?: string | null
  toxicity_level?: IChoice | null
}

export const checkSkills = (value: Value[] | Value | null | undefined) => {
  if (!value) {
    return {}
  }

  const resultObj = Array.isArray(value) ? value : [value]

  const isSampleTemperatureSensitive = resultObj.some(
    api => api?.min_temperature || api?.max_temperature
  )
  const isSampleToxic = resultObj.some(api => api?.toxicity_level?.key)

  return {
    isSampleTemperatureSensitive,
    isSampleToxic,
  }
}
