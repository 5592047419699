import { StyledDetailsLabel } from '@styles'

import { TemperatureField } from './TemperatureField'
import { StorageNotes } from './StorageNotes'
import { ToxicityField } from './ToxicityField'

interface TemperatureSensitiveToxicityFieldsProps {
  toxicityLevelValue?: string
  storageNotes?: string | null
  minTemperature?: string | null
  maxTemperature?: string | null
  temperatureMeasurementFormatKey?: string | null
  maxTemperatureMeasurementFormat?: string
  minTemperatureMeasurementFormat?: string
  mb?: string
}

export const TemperatureSensitiveToxicityFields: React.FC<TemperatureSensitiveToxicityFieldsProps> =
  props => {
    const {
      maxTemperature,
      minTemperature,
      toxicityLevelValue,
      storageNotes,
      temperatureMeasurementFormatKey,
      maxTemperatureMeasurementFormat,
      minTemperatureMeasurementFormat,
      mb,
    } = props

    return (
      <>
        {toxicityLevelValue && (
          <>
            <StyledDetailsLabel variant='h5' sx={{ marginTop: '24px' }}>
              Toxicity
            </StyledDetailsLabel>
            <ToxicityField toxicityLevelValue={toxicityLevelValue} mb={mb} />
          </>
        )}

        {maxTemperature && minTemperature && (
          <>
            <StyledDetailsLabel variant='h5'>Storage temperature</StyledDetailsLabel>
            <TemperatureField
              minTemperature={minTemperature}
              maxTemperature={maxTemperature}
              minTemperatureMeasurementFormat={minTemperatureMeasurementFormat}
              maxTemperatureMeasurementFormat={maxTemperatureMeasurementFormat}
              temperatureMeasurementFormatKey={temperatureMeasurementFormatKey}
            />
          </>
        )}

        <StorageNotes storageNotes={storageNotes} />
      </>
    )
  }
