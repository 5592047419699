import { useInfiniteQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { setIsAuthorized, setMaintenanceModePage } from '@state/user'
import { IUser } from '@models/common/user'
import { fetchUser } from '@api/user'
import { USER } from '@constants/common'

export const useCheckProfile = () => {
  const dispatch = useDispatch()
  return useInfiniteQuery<IUser, IError>(USER, fetchUser, {
    onError: error => {
      if (error.status === 403 && error.message === 'Your account was deactivated') {
        localStorage.removeItem('_expiredTime')
        localStorage.removeItem('USER')
        localStorage.setItem('user_deactivated', 'true')
        dispatch(setIsAuthorized(false))
      }
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    refetchInterval: 300000,
  })
}
