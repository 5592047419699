import { IAttachment } from '@models/attachments'
import { AttachedMaterialItem } from '@components/shared'
import { StyledPageSubTitle } from '@styles'
import { TRUNCATED_ATTACHED_MODAL_TEXT } from '@constants/common'

interface AttachedMaterialsTandemMSProps {
  attachmentsBySection: IAttachment[]
  removeAttachment: (id: string) => void
}

export const AttachedMaterialsTandemMS: React.FC<AttachedMaterialsTandemMSProps> = props => {
  const { attachmentsBySection, removeAttachment } = props

  return (
    <>
      {!!attachmentsBySection.length && (
        <StyledPageSubTitle sx={{ marginBottom: '16px' }} variant='body1'>
          Attached materials
        </StyledPageSubTitle>
      )}

      {attachmentsBySection.map(attachment => (
        <AttachedMaterialItem
          key={attachment.id}
          truncatedTextLength={TRUNCATED_ATTACHED_MODAL_TEXT}
          attachment={attachment}
          removeAttachment={removeAttachment}
        />
      ))}
    </>
  )
}
