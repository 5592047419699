import { styled, Box, Icon } from '@mui/material'
import Collapse from '@mui/material/Collapse'

export const StyledAttachLinkCollapse = styled(Box)`
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 26px;
`

export const ButtonBox = styled(Box)<{ isDeactivate?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme, isDeactivate }) =>
    isDeactivate ? theme.palette.secondary.light : theme.palette.primary.main};
  padding: 20px 24px;
  cursor: ${({ isDeactivate }) => (isDeactivate ? '' : 'pointer')};
`

export const StyledIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledCollapse = styled(Collapse)`
  padding: 0 20px;
`
