import { Box, BoxProps, styled as styledMUI } from '@mui/material'
import styled, { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import React from 'react'

//Mobile table

export const MobileRow = styled(Box)`
  border-radius: 4px;
  margin-top: 8px;
`

export const MobileCollapseRow = styled(Box)`
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

interface StyledBoxMobileProps extends BoxProps {
  bg?: string
  mb?: string
  textEndPosition?: boolean
}

export const StyledCardBox = styled<React.FC<StyledBoxMobileProps>>(Box)`
  padding: 24px 14px 24px 16px;
  display: flex;
  align-items: center;
  justify-content: ${({ textEndPosition }) => (textEndPosition ? 'flex-start' : 'space-between')};
  align-items: flex-start;
  background-color: ${({ bg }) => (bg ? bg : '#fff')};
  border-radius: 4px;
`

export const StyledBoxMobile = styled<React.FC<StyledBoxMobileProps>>(Box)`
  background-color: ${({ bg }) => (bg ? bg : '#fff')};
  border-radius: 4px;
  margin-bottom: ${({ mb }) => (mb ? mb : '16px')};
`

export const CardRow = styled(Box)`
  padding: 12px 15px 12px 20px;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
`

interface CardTitleCellProps extends BoxProps {
  minWidth?: string
}

const CardTitleCellConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'minWidth':
        return false

      default:
        return true
    }
  },
}

export const CardTitleCell = styledMUI<React.FC<CardTitleCellProps>>(Box, CardTitleCellConfig)`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '130px')} ;
`
