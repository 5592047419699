import { styled, FormControlLabel, Typography } from '@mui/material'

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 469px;

  @media (max-width: 767px) {
    max-width: 100%;
  }

  .MuiInputBase-root input {
    @media (max-width: 767px) {
      padding: 12.5px 12px;
    }
  }
`

export const StyledLabel = styled(FormControlLabel)`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: 0;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: ${({ theme }) => theme.palette.text.primary};

  .MuiTypography-root {
    @media (max-width: 767px) {
      margin-bottom: 4px;
    }
  }

  .MuiFormControlLabel-label {
    margin-bottom: 5px;
  }
`

export const StyledTitle = styled(Typography)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(8)};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const StyledParagraph = styled(Typography)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.text.secondary};

  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  }
`

export const ButtonBox = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 48px;
  }

  .MuiButton-root {
    @media (max-width: 767px) {
      width: 100%;
      margin-top: 16px;
      height: 48px;

      &:first-of-type {
        order: 2;
        margin-top: 16px;
      }
    }
  }
`
