import { TabList } from '@mui/lab'
import { styled, Tab } from '@mui/material'

export const StyledTab = styled(Tab)`
  color: #616d84;
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  min-height: 48px;

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) => theme.primary[10]};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  & .MuiTab-iconWrapper {
    width: 24px;
    height: 24px;
  }
`

export const StyledTabList = styled(TabList)`
  & .MuiTabs-indicator {
    background-color: transparent;
  }
`
