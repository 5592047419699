import { Box, styled } from '@mui/material'

export const MapStubBox = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 56px 0 64px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const MapStub = styled('img')`
  position: relative;
  display: block;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
`
export const EditInfoBox = styled(Box)`
  box-sizing: border-box;
  width: 383px;
  padding: 36px 48px;
  background-color: #fff;
  box-shadow: 5px -6px 34px rgba(31, 56, 143, 0.08);
  border-radius: 4px;
`

export const IconEdit = styled('img')`
  width: 19px;
  height: 19px;
`

export const ContactsBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const FlexBox = styled(Box)`
  display: flex;
  justify-content: start;
  align-items: flex-start;
`

export const ContactsInfoBox = styled(FlexBox)`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const AdditionalInfoBox = styled(FlexBox)`
  align-items: center;

  margin-bottom: 21px;
`

export const iconStyles = { marginRight: '17px', width: '30px', height: '30px' }

export const ParagraphLink = styled(Box)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`
