import React from 'react'
import { Box, Typography } from '@mui/material'

import { useAddAttachments } from '@hooks/queries'
import { StyledDetailsLabel } from '@styles'
import { Input } from '@components/shared/sampleFields'
import { MAX_CHARACTERS } from '@constants/validation'
import { TEST_NOTES } from '@constants/tests'

import { Attachment } from './components'
import { FTSResultsFields } from './components/Fields'
import { SubstancesRecognized } from './components/SubstancesRecognized'
import { IBodyProps } from './attachmentsSection.types'

export const AttachmentsSection: React.FC<IBodyProps> = props => {
  const { taskId, attachmentList, attachments, setAttachments, isTestReport, isMobile } = props

  const addAttachmentsM = useAddAttachments()

  return (
    <Box className='test-body'>
      {isTestReport && (
        <>
          <Typography variant='subtitle2' mb='24px'>
            1. Substances recognized
            <span style={{ color: '#8F929B' }}> (Required)</span>
          </Typography>
          <SubstancesRecognized />
        </>
      )}

      {attachmentList[0].title && (
        <Typography variant='subtitle2' mb='24px'>
          {`${isTestReport ? '2' : '1'}. ${attachmentList[0].title}`}
        </Typography>
      )}

      {attachments &&
        attachmentList.map(attachment => {
          const attachmentsBySection = attachments.filter(
            ({ attachment_section: attachmentSection }) =>
              attachmentSection.key === attachment.section
          )

          return isTestReport ? (
            <Box display={isMobile ? '' : 'flex'} mb='40px' key={attachment.counter}>
              <Box width='50%' mb={isMobile ? '24px' : ''} mr={isMobile ? '' : '15px'}>
                <StyledDetailsLabel mb='9px'>Notes</StyledDetailsLabel>
                <Input
                  name={TEST_NOTES}
                  rows={isMobile ? 6 : 9}
                  multiline
                  rules={{
                    maxLength: {
                      value: 200,
                      message: MAX_CHARACTERS(200),
                    },
                  }}
                  mb='8px'
                  mrErr='0'
                  width='100%'
                  placeholder='Type notes here...'
                />
              </Box>
              <div style={{ width: '50%' }}>
                <StyledDetailsLabel mb='9px'>Attachments</StyledDetailsLabel>
                <Attachment
                  height='226px'
                  key={attachment.counter}
                  {...attachment}
                  taskId={taskId}
                  attachments={attachmentsBySection}
                  setAttachments={setAttachments}
                  addAttachmentsM={addAttachmentsM}
                />
              </div>
            </Box>
          ) : (
            <Attachment
              key={attachment.counter}
              {...attachment}
              taskId={taskId}
              attachments={attachmentsBySection}
              setAttachments={setAttachments}
              addAttachmentsM={addAttachmentsM}
            />
          )
        })}
      {!isTestReport && <FTSResultsFields />}
    </Box>
  )
}
