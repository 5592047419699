import React from 'react'

import { ITaskLab } from '@models/tasks'
import { StyledTableSampleCell, StyledCupLabel } from '@styles'
import { ToxicityField } from '@components/shared/TemperatureSensitiveToxicityFields/ToxicityField'

export const Toxicity: React.FC<{ task: ITaskLab; isMobile?: boolean }> = ({ task, isMobile }) => {
  return task.toxicity_level ? (
    <StyledTableSampleCell $isMobile={isMobile}>
      <ToxicityField toxicityLevelValue={task.toxicity_level.value} tooltipPlacement='top' />
    </StyledTableSampleCell>
  ) : (
    <StyledCupLabel>For toxic only</StyledCupLabel>
  )
}
