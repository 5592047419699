import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Input } from '@components/shared'
import { StyledLabel } from '@components/modules/LoginForm/styles'
import { MAX_CHARACTERS } from '@constants/validation'

import { StyledResultsBox } from '../styles'
import { CommonNotesPropTypes } from '../tandemMSEditResults.types'

export const CommonNotes: React.FC<CommonNotesPropTypes> = ({ name, placeholder, label }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <StyledResultsBox $mb={24}>
      <StyledLabel
        label={label}
        $isResize
        sx={{ marginTop: 0 }}
        control={
          <Input
            width='100%'
            maxWidth='100%'
            variant='outlined'
            multiline
            height={34}
            rows={5}
            placeholder={placeholder || 'Type here...'}
            error={!!errors.fields?.[name]?.message}
            helperText={errors.fields?.[name]?.message}
            {...register(`fields.${name}`, {
              maxLength: {
                value: 200,
                message: MAX_CHARACTERS(200),
              },
            })}
          />
        }
      />
    </StyledResultsBox>
  )
}
