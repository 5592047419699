import { Box, styled } from '@mui/material'

interface StyledTableRowProps {
  isVerifyTenant: boolean
}

export const StyledTableRow = styled<React.FC<StyledTableRowProps>>(Box)`
  display: grid;
  grid-template-columns: ${({ isVerifyTenant }) =>
    isVerifyTenant
      ? '[checkbox]46px [sample-ID]215px [manufacturer]182px [apis]370px [expiration-date]131px'
      : '[checkbox]46px [photo]90px [sample-ID]183px  [sample-name]200px [physical-form-of-sample]243px [weight]160px'};
`
