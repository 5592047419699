import { TempType } from '@constants/common'
import { getTemperatureSymbol } from '@helpers/obtainingCorrectTemperature'

import { ActionsTypesProps } from './Actions.types'

export const ActionWithTemperature: React.FC<ActionsTypesProps> = props => {
  const { entities, action } = props
  // const tempTypeFromUser = useAppSelector(userTemperatureFormat)

  const getTemperature = (offset: number, length: number, format?: TempType | null) => {
    // const isSampleHasSameTempFormatAsUser = format === tempTypeFromUser.key
    const tempText = action.slice(offset, offset + length)
    const temperature = tempText.substring(0, tempText.length - 2)
    const tempSymbol = getTemperatureSymbol(format)
    // const tempSymbol = getTemperatureSymbol(tempTypeFromUser.key)

    // if (isSampleHasSameTempFormatAsUser) {
    //   return ` ${Math.round(+temperature)}${tempSymbol} `
    // } else {
    //   const obtainedTemperature = obtainingCorrectTemperature(tempTypeFromUser.key, temperature)
    //   return ` ${Math.round(+obtainedTemperature)}${tempSymbol} `
    // }
    // const tempSymbol = getTemperatureSymbol(tempTypeFromUser.key)

    return ` ${Math.round(+temperature)}${tempSymbol} `
  }

  return (
    <>
      {action.slice(0, 31)}
      {getTemperature(
        entities[0].offset,
        entities[0].length,
        entities[0].additional_data?.format
      )}{' '}
      -{' '}
      {getTemperature(entities[1].offset, entities[1].length, entities[1].additional_data?.format)}
    </>
  )
}
