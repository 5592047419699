import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useForm, FormProvider } from 'react-hook-form'

import { Modal } from '@components/shared'

import { StyledTitle } from '../../../../pages/ForgotPassword/styles'
import { EditTestResultsModalTypes } from './editTestResultsModal.types'
import * as Styled from './styles'
import { ActionFooterButtons } from './ActionFooterButtons'

export const EditTestResultsModal: React.FC<EditTestResultsModalTypes> = ({
  children,
  onClose,
  isCloseFromSomeComponentInside,
  initialFieldsData,
  modalTitle,
  modalSubtitle,
  handleAction,
  isLoading,
}) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const formMethods = useForm({
    defaultValues: {
      ...initialFieldsData,
      isLoading: false,
      isCloseModal: false,
      isActionBtnDisable: true,
    },
  })

  React.useEffect(() => {
    if (isLoading) formMethods.setValue('isLoading', true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const selfClose = isCloseFromSomeComponentInside
    ? () => formMethods.setValue('isCloseModal', true)
    : onClose

  return (
    <Modal padding='0' open onClose={selfClose}>
      <Styled.Wrapper style={{ width: isMobile ? 'auto' : '649px' }}>
        {/* Modal TITLE and SUBTITLE */}
        <Styled.HeadBox>
          <StyledTitle variant={isMobile ? 'subtitle1' : 'h3'} sx={{ marginBottom: '4px' }}>
            {modalTitle}
          </StyledTitle>
          {modalSubtitle && <Styled.Subtitle>{modalSubtitle}</Styled.Subtitle>}
        </Styled.HeadBox>
        <FormProvider {...formMethods}>
          {/* Modal CONTENT */}
          {children}
          {/* Modal ACTION BUTTONS */}
          <ActionFooterButtons onClose={selfClose} handleAction={handleAction} />
        </FormProvider>
      </Styled.Wrapper>
    </Modal>
  )
}
