import { Box, styled as styledMUI } from '@mui/material'
import styled from 'styled-components'
import React from 'react'
import { BoxProps } from '@mui/system'

import { StyledModalTitle } from '@styles'

interface StyledFormProps extends BoxProps {
  $isMobile?: boolean
}
export const StyledForm = styledMUI<React.FC<StyledFormProps>>(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ $isMobile }) => ($isMobile ? '' : '470px')};
`

export const StyledAttachModalTitle = styled(StyledModalTitle)`
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(32)};
`

interface StyledButtonBoxProps {
  $isMobile?: boolean
}

export const StyledButtonBox = styledMUI<React.FC<StyledButtonBoxProps>>(Box)`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : '')};
`
