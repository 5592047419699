import { CANCELED, IN_PROGRESS, NOT_SENT, PICKED_UP, UNPACKED } from '@constants/packages'
import {
  DOSAGE,
  DOSAGE_TYPE,
  UNIT_TYPE,
  VALUE_TYPE,
  VALUE,
  REGULATORY_REGISTRATION,
  TOXICITY_LEVEL,
  TEMPERATURE_FORMAT,
} from '@constants/samples'
import { ISample } from '@models/samples'

export const hasParentInSameSubGroup = (prevParents: number[], nextParents: number[]) => {
  const parentsLength = prevParents.length
  const lastPrevParent = prevParents[parentsLength - 1]
  const lastNextParent = nextParents[parentsLength - 1]

  const isSameSubGroup = prevParents[parentsLength - 2] === nextParents[parentsLength - 2]
  const isDifferentTopParent = lastPrevParent !== lastNextParent

  return isSameSubGroup && isDifferentTopParent
}

export const parseSampleData = (sample: ISample) => {
  const { split_status, parent, parents, sub_samples, laboratory, native_price, ...initialSample } =
    sample

  return Object.fromEntries(
    Object.entries(initialSample).map(([key, val]) => {
      if (key === DOSAGE_TYPE || key === UNIT_TYPE) return [key, val.id]
      if (key === VALUE_TYPE) return [key, val?.id ? val.id : 159]
      if (key === VALUE) return [key, String(parseFloat(String(native_price)))]
      if (key === DOSAGE) return [key, parseFloat(val)]
      if (key === REGULATORY_REGISTRATION) return [key, val.id]
      if (key.includes('date') && val) return [key, new Date(val)]
      if (key === TOXICITY_LEVEL) return [key, val?.key]
      if (key === TEMPERATURE_FORMAT) return [key, val?.key]
      return [key, val]
    })
  )
}

export type TPackageStatusStatus =
  | typeof NOT_SENT
  | typeof IN_PROGRESS
  | typeof PICKED_UP
  | typeof UNPACKED
  | typeof CANCELED
  | undefined

export const getActionBtnInfo = (
  packageStatus: TPackageStatusStatus,
  isCurrentUserRecipient?: boolean
) => {
  let actionBtnText: 'Remove' | 'Missed' | undefined
  let shouldDisplayActionBtn

  switch (packageStatus) {
    case NOT_SENT:
      shouldDisplayActionBtn = true
      actionBtnText = 'Remove'
      break

    case UNPACKED:
      shouldDisplayActionBtn = !!isCurrentUserRecipient
      actionBtnText = 'Missed'
      break

    default:
      shouldDisplayActionBtn = false
      break
  }

  return { actionBtnText, shouldDisplayActionBtn }
}

export const toxicityTooltip = (value?: any) => {
  let tooltipText = ''
  const checkValue = typeof value === 'string' ? value : value?.key

  switch (checkValue) {
    case 'Very High':
    case 'very_high':
      return (tooltipText = 'Toxic, mutagenic, carcinogenic, harms human fertility, teratogen')
    case 'High':
    case 'high':
      return (tooltipText = 'Mutagenic, carcinogenic, harms human fertility, teratogen')
    case 'Moderate':
    case 'moderate':
      return (tooltipText = 'Refer to MSDS for handling information')

    default:
      return tooltipText
  }
}
