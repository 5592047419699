import React from 'react'

import { useAppSelector } from '@hooks/redux'
import { selectSamplesState } from '@state/samples'
import { ISamples } from '@models/samples'

import { CreatePackage, DownloadLabels, AddTestsActiveBtn } from './ActionButtons'

interface TabSelectedCheckboxesProps {
  samples: ISamples
  isFetching: boolean
}

export const TabSelectedCheckboxes: React.FC<TabSelectedCheckboxesProps> = ({
  samples,
  isFetching,
}) => {
  const { selectedSamples } = useAppSelector(selectSamplesState)
  const selectedSamplesAmount = selectedSamples.length

  const actionButtons = [CreatePackage, DownloadLabels, AddTestsActiveBtn]

  return (
    <>
      {actionButtons.map((ActionButton, idx) => (
        <React.Fragment key={idx}>
          <ActionButton
            samples={samples}
            isFetching={isFetching}
            selectedSamples={selectedSamples}
            selectedSamplesAmount={selectedSamplesAmount}
          />
        </React.Fragment>
      ))}
    </>
  )
}
