/* ----------------------------------------------------------------------------------------
  This Component is "Autocomplete" by default but can be just a "Dropdown" or a small "Dropdown" with "Input"
  ***
  If this Component must be just a Dropdown - you must add "isDropdown" in props
  If this Component must be a small Dropdown with Input together - you must add "isDropdownWithInput" in props
  If this Component must always show "AddNewItem" button - you must add "isAlwaysShowAddNewItem" and "onAddNewItem" in props
---------------------------------------------------------------------------------------- */

import React from 'react'
import { useDispatch } from 'react-redux'

import { IOption } from '@models/common/app'
import { setDataAndPosition, setIsLoading, setInputValueToStore } from '@state/common/dropdownState'
import { IPlaceOption } from '@components/shared/sampleFields/GMPlaceAutocomplete'
import { Tooltip } from '@components/shared'

import {
  IOptionExtended,
  PureDropdownAutocompletePropTypes,
} from './pureDropdownAutocomplete.types'
import { StyledErrorMessage } from '../commonUIStyles'
import { CleanInputIcon } from './CleanInputIcon'
import * as Styled from './styles'

// -------------------------
// For some unknown reason, in some rare cases, the state variable (showList) of the component
// is reset to the starting value, which stops showing the list of options.
// All state functions (setShowList) were checked for calls in all places.
// At the moment of changing the state variable to the starting value, the state functions do not work.
// The component is also checked for mount and unmount - this does not happen either.
// ***
// This variable below (isCloseOptions), which is outside the component, solves this problem
// -------------------------
let isCloseOptions = true

export const PureDropdownAutocomplete: React.FC<PureDropdownAutocompletePropTypes> = ({
  startIcon,
  optionsComponent,
  isAlwaysShowAddNewItem = false,
  toggleShowOptions,
  isLoading,
  isDropdown,
  isDropdownWithInput,
  inputPlaceholder,
  errorMessage,
  name,
  type = 'text',
  options,
  initialVal,
  inputInitialVal,
  onDropdownChange,
  onInputChange,
  onAddNewItem,
  isCopy = false,
  disabled = false,
  disabledOptions = false,
  fetchNextPage,
  hasNextPage = false,
  withTooltip = false,
  isCleanInputAfterSelectOption,
  isEditing = false,
  inputChangeValidation,
  scrollBlockRef,
  inputHeight,
  updatedInputVal,
  isLongValueLength,
}) => {
  const initialRefFlag = React.useRef<boolean>(false)
  const wrapperRef = React.useRef<HTMLDivElement | null>(null)
  const inputBtnWrapRef = React.useRef<HTMLDivElement | null>(null)
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = React.useState<string>(inputInitialVal || '')
  const [dropdownValue, setDropdownValue] = React.useState<IOptionExtended>()
  const [list, setList] = React.useState<IOption[] | IOptionExtended[] | IPlaceOption[]>([])
  const [showList, setShowList] = React.useState<boolean>(false)
  const [isCopyField, setIsCopyField] = React.useState<boolean>(isCopy)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (updatedInputVal && updatedInputVal !== inputValue) setInputValue(updatedInputVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedInputVal])

  React.useEffect(() => {
    const hideList = () => {
      isCloseOptions = true
      setShowList(false)
    }

    const handleSecondClickOutside = (e: MouseEvent) => {
      if (disabled) return
      const target = e.target as Element
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(target) &&
        !target?.closest('#pure-dropdown-options-list')
      ) {
        hideList()
      }
    }

    const handleWheelMouse = (e: MouseEvent) => {
      e.preventDefault()
    }

    document.addEventListener('mousedown', handleSecondClickOutside)

    if (scrollBlockRef?.current) {
      scrollBlockRef.current.addEventListener('scroll', hideList)
    }
    if (inputRef?.current) {
      inputRef.current.addEventListener('wheel', handleWheelMouse)
    }

    return () => {
      dispatch(setDataAndPosition(null))
      dispatch(setIsLoading(false))
      dispatch(setInputValueToStore(''))
      document.removeEventListener('mousedown', handleSecondClickOutside)

      if (scrollBlockRef?.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        scrollBlockRef.current.removeEventListener('scroll', hideList)
      }
      if (inputRef?.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        inputRef.current.removeEventListener('wheel', handleWheelMouse)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (typeof toggleShowOptions === 'boolean' && showList) {
      isCloseOptions = true
      setShowList(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleShowOptions])

  const setValueToState = (option: IOptionExtended, isClearInput?: boolean) => {
    if (!isDropdownWithInput && !isDropdown) {
      if (!isClearInput) setInputValue(option.name)
      else if (isClearInput && inputValue) setInputValue('')
    } else {
      setDropdownValue(option)
    }
  }

  React.useEffect(() => {
    if (initialVal) {
      if (typeof initialVal === 'number') {
        const currentOption = options.find(option => option.id === initialVal)
        if (currentOption) {
          setValueToState(currentOption)
        }
      } else if (typeof initialVal === 'string') {
        const currentOption = options.find(option => option.key === initialVal)
        if (currentOption) {
          setValueToState(currentOption)
        }
      } else {
        if (!initialRefFlag.current) {
          initialRefFlag.current = true
          setValueToState(initialVal)
        }
      }
    } else {
      setDropdownValue({ id: 0, name: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialVal, options])

  React.useEffect(() => {
    if (typeof isLoading !== 'undefined') dispatch(setIsLoading(isLoading))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  React.useEffect(() => setList(options), [options])

  const handleChangeDropdown = (item: IOptionExtended) => {
    if (disabled) return
    isCloseOptions = true
    setShowList(false)
    setValueToState(item, isCleanInputAfterSelectOption)
    if (!!onDropdownChange) onDropdownChange(item)
  }

  React.useEffect(() => {
    if (showList && wrapperRef.current) {
      const { top, height, left, width } = wrapperRef.current.getBoundingClientRect()
      let dropdownBtn: HTMLButtonElement | null = null

      if (isDropdownWithInput) {
        dropdownBtn = wrapperRef.current?.getElementsByClassName(
          'pure-dropdown-with-input'
        )[0] as HTMLButtonElement
      }

      const correctWidth = dropdownBtn ? dropdownBtn.getBoundingClientRect().width : width
      const correctLeft = dropdownBtn ? dropdownBtn.getBoundingClientRect().left : left

      const allWidth = (() => {
        if (isDropdownWithInput && inputBtnWrapRef.current) {
          return {
            width: correctWidth,
            minWidth: correctWidth,
            maxWidth: inputBtnWrapRef.current.getBoundingClientRect().width,
          }
        }
        return { width: correctWidth }
      })()

      dispatch(
        setDataAndPosition({
          position: {
            top: Math.round(top + window.scrollY) + height,
            left: correctLeft,
            ...allWidth,
          },
          disableAddNewItem: !!errorMessage,
          isAlwaysShowAddNewItem,
          disabledOptions,
          options: list,
          optionsComponent,
          dropdownActions: {
            handleChangeDropdown,
            handleAddNewItem: onAddNewItem,
          },
          lazyScrollData: { fetchNextPage, hasNextPage },
          withTooltip,
        })
      )
    } else if (!showList && isCloseOptions) {
      dispatch(setDataAndPosition(null))
      dispatch(setIsLoading(false))
      dispatch(setInputValueToStore(''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showList, list, errorMessage])

  const handleClickContainer = () => {
    if (disabled) return

    if (inputBtnWrapRef.current && !inputBtnWrapRef.current.classList.contains('border-focused')) {
      inputBtnWrapRef.current.classList.add('border-focused')
    }
    if (isCopyField) setIsCopyField(false)
  }

  const handleClickInput = (e: React.MouseEvent<HTMLInputElement>) => {
    if (disabled) return

    if (!isDropdownWithInput && !disabled) {
      const target = e.target as HTMLInputElement

      if (!target.value) {
        if (showList) {
          isCloseOptions = true
          setShowList(false)
        } else {
          isCloseOptions = false
          setShowList(true)
        }
      } else if (!showList) {
        isCloseOptions = false
        setShowList(true)
      }
    }
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    if (disabled) return

    if (!e && inputRef.current) inputRef.current.focus()

    const { value } = e ? e.target : { value: '' }
    if (!showList && !isDropdownWithInput) {
      isCloseOptions = false
      setShowList(true)
    }
    // Setting input value to the Store if we have the option to add a new item
    if (!!onAddNewItem || (isAlwaysShowAddNewItem && !!onAddNewItem)) {
      dispatch(setInputValueToStore(value))
    }

    if (!!onInputChange) {
      onInputChange(value)
    } else {
      const data = options.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase()))
      setList(data)
    }
    if (!!inputChangeValidation) inputChangeValidation(value)

    setInputValue(value)
  }

  const iconAtDropdownBtn: JSX.Element | null = React.useMemo(() => {
    if ((dropdownValue && dropdownValue.icon) || (options.length && options[0].icon)) {
      const Icon =
        dropdownValue?.icon || (options[0].icon as React.FC<React.SVGProps<SVGSVGElement>>)
      const iconColor: string = dropdownValue?.iconColor || options[0].iconColor || ''

      return (
        <Styled.IconWrap style={{ textAlign: 'center' }}>
          <Icon fill={iconColor} stroke={iconColor} />
        </Styled.IconWrap>
      )
    }

    return null
  }, [dropdownValue, options])

  const dropDownName = dropdownValue?.hasOwnProperty('key')
    ? dropdownValue?.value || options[0]?.value || ''
    : dropdownValue?.name || options[0]?.name || ''

  const handleToggleBtn = () => {
    if (!disabled) {
      if (showList) {
        isCloseOptions = true
        setShowList(false)
      } else {
        isCloseOptions = false
        setShowList(true)
      }
    }
  }

  const inputBtnWrapClassNames: string = (() => {
    let classNames = ''
    if (errorMessage) classNames = `${classNames} border-error`
    if (disabled) classNames = `${classNames} border-disabled`
    return classNames
  })()

  const inputStyles = (() => {
    let obj: React.CSSProperties = {}
    if (isCopyField) obj.background = '#FFF5D6'
    if (inputHeight) obj.height = inputHeight
    return obj
  })()

  return (
    <Styled.Wrapper ref={wrapperRef}>
      <Styled.InputBtnWrap
        style={inputStyles}
        ref={inputBtnWrapRef}
        className={inputBtnWrapClassNames}
        onClick={handleClickContainer}
      >
        {/* ----- INPUT START ICON ----- */}
        {startIcon && <Styled.StartIconBox>{startIcon}</Styled.StartIconBox>}
        {/* ----- INPUT ----- */}
        {!isDropdown && (
          <Tooltip
            shouldBeHidden={!withTooltip || inputValue.length <= 17 || !isLongValueLength}
            title={inputValue}
          >
            <Styled.Input
              ref={inputRef}
              style={startIcon ? { paddingLeft: 37 } : {}}
              type={type}
              autoComplete='off'
              disabled={disabled}
              name={name}
              value={inputValue}
              placeholder={inputPlaceholder || 'Type here values...'}
              onChange={handleChangeInput}
              onClick={handleClickInput}
            />
          </Tooltip>
        )}
        {(isDropdown || isDropdownWithInput) && (
          <Styled.ToggleBtn
            type='button'
            style={startIcon ? { paddingLeft: 37 } : {}}
            className={isDropdownWithInput ? 'pure-dropdown-with-input' : ''}
            onClick={handleToggleBtn}
            disabled={disabled}
          >
            {/* ----- DROPDOWN BTN ----- */}
            {iconAtDropdownBtn}
            {isDropdown && inputPlaceholder ? (
              <Styled.DropdownPlaceholder>{inputPlaceholder}</Styled.DropdownPlaceholder>
            ) : (
              dropDownName
            )}
          </Styled.ToggleBtn>
        )}
        {/* ----- CLEAN INPUT BUTTON ICON ----- */}
        {!disabled && !isDropdown && !isDropdownWithInput && inputValue && (
          <Styled.CleanInputBtnIcon type='button' onClick={() => handleChangeInput(null)}>
            <CleanInputIcon />
          </Styled.CleanInputBtnIcon>
        )}
        {/* ----- DROPDOWN ARROW ----- */}
        <Styled.ArrowIcon className={showList ? 'arrow-icon-rotated' : ''} />
        {/* ----- BORDER OF ALL COMPONENT FOR FOCUS ----- */}
        <span className={'pure-border'} />
      </Styled.InputBtnWrap>
      {/* ----- ERROR MESSAGE ----- */}
      {errorMessage && (
        <StyledErrorMessage className='is-absolute' isEditing={isEditing}>
          {errorMessage}
        </StyledErrorMessage>
      )}
    </Styled.Wrapper>
  )
}
