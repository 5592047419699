import React from 'react'
import { useDispatch } from 'react-redux'
import { useQueryParams, StringParam } from 'use-query-params'
import InfiniteScroll from 'react-infinite-scroller'
import { Box } from '@mui/material'

import { useAppSelector } from '@hooks/redux'
import {
  deleteAllAppliedFilters,
  deleteAllSelectedFilters,
  filtersAside,
  setIsFilterOpen,
  setPageForFilters,
  applyFiltersOnPage,
} from '@state/filters'
import {
  clearAllFilterStringParamsQueries,
  filterStringParamsQueries,
} from '@components/shared/FilterAside/constants'
import { FullPageError, TasksCompletedTabLoader } from '@components/shared'
import { useFetchReports } from '@hooks/queries'
import { editingNotesFieldId, isEditingNotes } from '@state/samples'
import { savedFilters } from '@state/user'
import { PageTitleForFilter } from '@models/common/user'
import { selectTestReports } from '@state/testReports'
import { ISamples } from '@models/samples'

import { Table } from './components'
import { ReportsFiltersView } from '../ReportsFiltersView'
import { StatisticsWidgets } from '../StatisticsWidgets'

export const ReportsContent = () => {
  const fetchReportsQ = useFetchReports<ISamples>()
  const dispatch = useDispatch()
  const [, setQueryString] = useQueryParams(filterStringParamsQueries)

  const refetchReports = () => fetchReportsQ.refetch()

  //Add new type of filter if some filter was added
  const [
    {
      desired_test_reports,
      apis,
      excipients,
      drug_classes,
      manufacturers,
      testingDate,
      laboratories,
      taskResult,
      test_types,
    },
  ] = useQueryParams({
    desired_test_reports: StringParam,
    ...filterStringParamsQueries,
  })
  const isFilterOpen = useAppSelector(filtersAside)

  const reports = fetchReportsQ.data?.pages.map(page => [...page.results]).flat()
  const totalReports = fetchReportsQ.data?.pages[0].count

  const hasTableNextPage = fetchReportsQ.hasNextPage
  const fetchTableNextPage = fetchReportsQ.fetchNextPage as (page: number) => void
  const isFetchingTableNextPage = fetchReportsQ.isFetchingNextPage

  //Add new type of filter if some filter was added
  const isNoSamplesForFilter =
    (!reports?.length && desired_test_reports) ||
    (!reports?.length &&
      (apis ||
        excipients ||
        drug_classes ||
        manufacturers ||
        testingDate ||
        laboratories ||
        taskResult ||
        test_types))

  // [saved filters
  const actualPageForSavedFilters: PageTitleForFilter = 'test_reports'

  const getSavedFiltersFromUser = useAppSelector(savedFilters)
  const samplesFilters = getSavedFiltersFromUser.filter(i => {
    return i.page_title === actualPageForSavedFilters
  })[0]
  const [updatedFilters, setUpdatedFilters] = React.useState(samplesFilters)

  const isPageHasSavedFilters = updatedFilters.filters.some(i => i.values.length !== 0)
  // saved filters]

  React.useEffect(() => {
    dispatch(setIsFilterOpen({ ...isFilterOpen, right: false }))
    dispatch(setPageForFilters(actualPageForSavedFilters))
    return () => {
      dispatch(deleteAllAppliedFilters())
      dispatch(deleteAllSelectedFilters())
      setQueryString(clearAllFilterStringParamsQueries)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setUpdatedFilters(samplesFilters)
  }, [samplesFilters])

  React.useEffect(() => {
    if (isPageHasSavedFilters) dispatch(applyFiltersOnPage(samplesFilters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samplesFilters, isPageHasSavedFilters])

  React.useEffect(() => {
    if (fetchReportsQ.isFetching) {
      dispatch(isEditingNotes(true))
    } else if (fetchReportsQ.isFetched) {
      dispatch(isEditingNotes(false))
      dispatch(editingNotesFieldId(null))
    }
  }, [dispatch, fetchReportsQ.isFetched, fetchReportsQ.isFetching])

  React.useEffect(() => {
    dispatch(selectTestReports([]))
  }, [dispatch])

  if (fetchReportsQ.isLoading)
    return (
      <Box mt={3}>
        <TasksCompletedTabLoader />
      </Box>
    )

  if (fetchReportsQ.isError) {
    return (
      <FullPageError
        errorTextTitle='Something went wrong with testing reports loading'
        btnText='Try again'
        onBtnClick={refetchReports}
        pt='20px'
      />
    )
  }

  if (isNoSamplesForFilter)
    return (
      <>
        {!reports?.length && <ReportsFiltersView isPageHasSavedFilters={isPageHasSavedFilters} />}
        <FullPageError
          imgVariant='common'
          errorTextTitle={`No testing reports with such parameters`}
          pt='20px'
          mb={1}
        />
      </>
    )

  if (!reports?.length)
    return (
      <FullPageError
        imgVariant='common'
        errorTextTitle={`There are no testing reports yet`}
        pt='20px'
        mb={1}
      />
    )

  return (
    <>
      {/* Applied filters view */}
      {!!reports?.length && <ReportsFiltersView isPageHasSavedFilters={isPageHasSavedFilters} />}

      {/*Statistic widgets */}
      <StatisticsWidgets />

      {/* Test reports table */}
      <InfiniteScroll hasMore={hasTableNextPage} loadMore={fetchTableNextPage}>
        <Table
          reports={reports}
          isFetchingNextPage={isFetchingTableNextPage}
          totalReports={totalReports}
        />
      </InfiniteScroll>
    </>
  )
}
