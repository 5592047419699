import React from 'react'
import { Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { protectedRoutes } from '@configs/routes.config'
import { selectUserLaboratory } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { Image } from '@styles'
import addIcon from '@assets/icons/add-icon.svg'
import { Button } from '@components/shared'
import { PillsImage } from '@components/icons'

import { StyledBox } from './styles'

export const NoAddedSamples: React.FC = () => {
  const {
    group: { sample_creator: sampleCreator },
  } = useAppSelector(selectUserLaboratory)
  const history = useHistory()

  const redirectToCreateSample = () => history.push(protectedRoutes.createSample.path)

  return (
    <StyledBox>
      <PillsImage />
      <Typography variant='h4' mb={1} color='#778299'>
        There are no Samples added yet
      </Typography>
      <Typography variant='body2' mb={6} color='#778299'>
        Get started by adding new samples
      </Typography>

      {sampleCreator && (
        <Button
          variant='contained'
          height='48px'
          width='205px'
          startIcon={<Image src={addIcon} width='24px' height='24px' mr='2px' component='img' />}
          onClick={redirectToCreateSample}
        >
          Create samples
        </Button>
      )}
    </StyledBox>
  )
}
