import { BoxProps, styled, Box, styled as styledMUI, Typography } from '@mui/material'

export const StyledTableHeadWrapper = styled<React.FC<BoxProps>>(Box)`
  position: sticky;
  top: 0;
  overflow: auto;
  z-index: 300;
  overflow-x: hidden;
  background: white;
  height: 50px;
  align-items: flex-end;
  display: flex;
  margin-bottom: 16px;
`
export const StyledTableTitle = styledMUI(Typography)`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`

export const StyledTableCell = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  margin-right: 10px;
`
