import React from 'react'
import { FiPlus as AddIcon } from 'react-icons/fi'

import { AddButton } from '@components/shared/AutocompletePaper/styles'

import { AddAutocompleteNewDataProps } from './AddAutocompleteNewData.types'
import { NotFoundLabel, StyledWrapper, StyledDescription } from './styles'

export const AddAutocompleteNewData: React.FC<AddAutocompleteNewDataProps> = props => {
  const { description, handleAdd, isBtnLoading, isWithoutLabel } = props

  return (
    <StyledWrapper>
      {!isWithoutLabel && <NotFoundLabel variant='body2'>Nothing found</NotFoundLabel>}
      <AddButton
        fullWidth
        variant='text'
        loadingPosition='start'
        loading={isBtnLoading}
        startIcon={<AddIcon color='primary' />}
        onMouseDown={handleAdd}
      >
        <StyledDescription>{isBtnLoading ? 'Adding...' : description}</StyledDescription>
      </AddButton>
    </StyledWrapper>
  )
}
