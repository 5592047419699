import { Typography } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'

import { protectedRoutes } from '@configs/routes.config'
import { FlexWrapper, Link } from '@styles'
import { Tooltip } from '@components/shared'
import { DETAILS } from '@constants/common'
import { IBreadcrumbsRoute } from '@models/common/app'
import { useAppDispatch } from '@hooks/redux'
import { setLaboratoryForWorkspace } from '@state/workSpace'
import { ISample } from '@models/samples'

interface SampleDetailsPackageHistoryProps<T> {
  sample: T
}

export const SampleDetailsPackageHistory = <T extends ISample>({
  sample,
}: SampleDetailsPackageHistoryProps<T>) => {
  const { state } = useLocation<IBreadcrumbsRoute[]>()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const packagesForHistory = sample.packages

  const viewLaboratoryInfoEndPoint = () => {
    if (sample.initial_laboratory) {
      dispatch(setLaboratoryForWorkspace(sample.initial_laboratory))
    }

    history.replace({
      pathname: protectedRoutes.yourWorkspace.path,
      state: [
        {
          path: `${protectedRoutes.samples.path}/${sample.id}${DETAILS}`,
          url: `${protectedRoutes.samples.path}/${sample.id}${DETAILS}`,
          title: `Sample ${sample.specific_id}`,
        },
      ],
    })
  }

  return (
    <>
      {!packagesForHistory.length ? (
        <Typography>-</Typography>
      ) : (
        <FlexWrapper $direction='column'>
          {packagesForHistory.map(({ id, specific_id, specific_id_tooltip }, idx) => {
            const packageInfoEndpoint = {
              pathname: `${protectedRoutes.packages.path}/${id}${DETAILS}`,
              state: history.location.state && state.length >= 3 ? state.slice(0, -1) : state,
            }

            return (
              <Tooltip
                key={id}
                title={specific_id_tooltip || ''}
                tooltipWithLink={!!sample.initial_laboratory}
                onLinkHandler={viewLaboratoryInfoEndPoint}
                specificId={sample.specific_id || '-'}
                labelTooltip={specific_id_tooltip}
                tooltipWithId={sample.specific_id.split('-')[1].length > 8}
              >
                <Link
                  to={packageInfoEndpoint}
                  $mb={idx !== packagesForHistory.length - 1 ? '24px' : ''}
                  $isBoldFirstElement
                >
                  {specific_id}
                </Link>
              </Tooltip>
            )
          })}
        </FlexWrapper>
      )}
    </>
  )
}
