import React from 'react'
import { Icon, IconButton, useMediaQuery } from '@mui/material'

import { stringSlice } from '@helpers/ui/common'
import { fileTypeConverter } from '@helpers/ui/attachments'
import { Tooltip } from '@components/shared'
import { IAttachment } from '@models/attachments'
import deleteIcon from '@assets/icons/delete-icon-disabled.svg'
import downloadIcon from '@assets/icons/download-icon.svg'
import copyIcon from '@assets/icons/copy-icon.svg'
import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'
import { PureFile, PureLink, SubText } from '@styles'

import { AttachmentBox, AttachmentName } from './styles'

interface AttachmentProps {
  attachment: IAttachment
  cantDelete?: boolean
  removeAttachment?: (id: string) => void
  removeAttachmentLoading?: boolean
  truncatedTextLength?: number
  width?: string
  mr?: string
  isPackageAttachment?: boolean
  isOtherTestType?: boolean
}

export const Attachment: React.FC<AttachmentProps> = props => {
  const {
    attachment,
    cantDelete,
    removeAttachment,
    truncatedTextLength,
    width,
    mr,
    isPackageAttachment,
    removeAttachmentLoading,
    isOtherTestType,
  } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  const { id, name, link, size, attachment_type } = attachment
  const { iconType, fileType } = fileTypeConverter(name)
  const truncatedName = truncatedTextLength ? stringSlice(name || '', truncatedTextLength) : name

  const removeAttachmentItem = () => {
    removeAttachment && removeAttachment(String(id))
  }

  const copyLinkToClipboard = () => {
    link &&
      navigator.clipboard.writeText(link).then(() => {
        showNotification(notifications.copiedLinkToClipboard)
      })
  }

  if (isOtherTestType) return null

  return (
    <AttachmentBox width={width}>
      <Icon sx={{ width: '40px', height: '40px' }}>
        <img src={iconType} alt='document' />
      </Icon>

      <AttachmentName>
        <Tooltip
          title={
            attachment_type?.key === 'link'
              ? 'Click to open link'
              : link
              ? 'Click to download file'
              : "We're processing this file. Please try to download it later."
          }
        >
          {link ? (
            <>
              <PureLink
                target={attachment_type?.key === 'link' ? '_blank' : ''}
                href={link || ''}
                $isTextDecorationAbsence={fileType !== 'link'}
                $isDeactivate={!link}
              >
                {truncatedName}
              </PureLink>
              <br />
            </>
          ) : (
            <PureFile $isTextDecorationAbsence={fileType !== 'link'} $isDeactivate={!link}>
              {truncatedName}
            </PureFile>
          )}
        </Tooltip>
        <SubText>{size}</SubText>
      </AttachmentName>

      {cantDelete ? (
        attachment_type?.key === 'link' ? (
          <IconButton onClick={copyLinkToClipboard} sx={{ marginRight: mr }}>
            <img src={copyIcon} alt='document' />
          </IconButton>
        ) : (
          <IconButton disabled={!link} sx={{ marginRight: mr }}>
            <PureLink href={link || ''}>
              <img src={downloadIcon} alt='document' />
            </PureLink>
          </IconButton>
        )
      ) : (
        <IconButton
          disabled={
            (!(fileType !== 'link' ? link : link) && !isPackageAttachment) ||
            removeAttachmentLoading
          }
          onClick={removeAttachmentItem}
          sx={{ padding: isMobile ? '0' : '8px' }}
        >
          <img src={deleteIcon} alt='delete' />
        </IconButton>
      )}
    </AttachmentBox>
  )
}
