import React from 'react'
import { MenuItem } from '@mui/material'
import { useController } from 'react-hook-form'

import { IOption } from '@models/common/app'
import { Dropdown as StyledDropdown } from '@components/shared'

interface DropdownProps {
  name: string
  isCopy?: boolean
  options?: IOption[]
  variant?: 'standard' | 'outlined' | 'filled'
  width?: string
  fontSize?: string
  padding?: string
  borderRadius?: string
  borderLeftColor?: string
  position?: string
}

export const Dropdown: React.FC<DropdownProps> = React.memo(
  ({ isCopy, name, options, variant, width, ...restProps }) => {
    const { field, fieldState } = useController({ name })
    const isHighlighting = isCopy && !fieldState.isTouched

    return (
      <>
        {options && options.length > 0 && (
          <StyledDropdown
            width={width}
            variant={variant || 'outlined'}
            sx={{ backgroundColor: variant ? 'transparent' : '' }}
            {...field}
            {...restProps}
            bgColor={isHighlighting ? '#FFF5D6' : '#fff'}
          >
            {options.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </StyledDropdown>
        )}
      </>
    )
  }
)
