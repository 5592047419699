import React from 'react'

import { Tabs } from './components'

interface TasksProps {
  shouldHiddenTabButtons: boolean
  shouldHiddenTabButtonsHandler: () => void
}

export const Tasks: React.FC<TasksProps> = props => {
  const { shouldHiddenTabButtons, shouldHiddenTabButtonsHandler } = props
  return (
    <Tabs
      shouldHiddenTabButtons={shouldHiddenTabButtons}
      shouldHiddenTabButtonsHandler={shouldHiddenTabButtonsHandler}
    />
  )
}
