import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { MAIN_APIS } from '@constants/samples'
import { checkSkills } from '@helpers/obtainingSkills'

export const InfoBoxWrapper: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { control } = useFormContext()
  const apis = useWatch({ control, name: MAIN_APIS })

  const { isSampleTemperatureSensitive } = checkSkills(apis)

  if (!isSampleTemperatureSensitive) {
    return null
  }

  return children
}
