import React from 'react'
import { Typography } from '@mui/material'

import { ITaskApiResult } from '@models/tasks'
import { removeTrailingZeros } from '@helpers/removeTrailingZeros'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_ATTACHMENTS_PACKAGE_DETAILS_TEXT } from '@constants/common'
import { Tooltip } from '@components/shared'

import { SubstanceResultPropTypes } from './IdPAD.types'
import * as Styled from './styles'

export const SubstanceResult: React.FC<SubstanceResultPropTypes> = ({
  substanceResult,
  resultIsNotRecognized,
  mb,
}) => {
  return (
    <>
      {resultIsNotRecognized || (substanceResult && !substanceResult.length) ? (
        <Typography variant='body1' color='primary.light' sx={{ marginBottom: mb }}>
          Substances were not recognized
        </Typography>
      ) : (
        <Styled.SubstancesList $mb={mb}>
          {substanceResult?.map((substance: ITaskApiResult) => {
            const isLowHighPercentage = (() => {
              if (!substance.percentage) return false
              return Number(substance.percentage) < 0 || Number(substance.percentage) > 100
            })()

            return (
              <Styled.SubstancesItem key={substance.id} mb={isLowHighPercentage ? 14 : 6}>
                <Tooltip
                  isPureTooltip
                  maxWidth={250}
                  title={substance.name || ''}
                  shouldBeHidden={
                    (substance.name?.length || 0) <= TRUNCATED_ATTACHMENTS_PACKAGE_DETAILS_TEXT
                  }
                >
                  <Typography sx={{ maxWidth: '150px' }}>
                    {stringSlice(substance.name || '', TRUNCATED_ATTACHMENTS_PACKAGE_DETAILS_TEXT)}
                  </Typography>
                </Tooltip>
                {substance.percentage ? (
                  <div style={{ position: 'relative' }}>
                    <Typography>
                      {removeTrailingZeros(substance.percentage)}
                      <Styled.GreyCup>%</Styled.GreyCup>
                    </Typography>

                    {isLowHighPercentage && (
                      <Styled.WarningMessage>
                        It could be something wrong with testing results. Contact
                        <br />
                        Central laboratory for support
                      </Styled.WarningMessage>
                    )}
                  </div>
                ) : (
                  <Styled.DeterminedCup>not determined</Styled.DeterminedCup>
                )}
              </Styled.SubstancesItem>
            )
          })}
        </Styled.SubstancesList>
      )}
    </>
  )
}
