import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { useDispatch } from 'react-redux'
import { StringParam, useQueryParams } from 'use-query-params'

import { fetchUsers } from '@api/common'
import { USERS } from '@components/shared/FilterAside/constants'
import { IError, IUserData } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useFetchUsers = (
  desiredLaboratory?: number
): UseInfiniteQueryResult<IUserData, IError> => {
  const [queryString] = useQueryParams({
    ordering: StringParam,
    desired_user: StringParam,
    userStatus: StringParam,
  })

  const { ordering, desired_user, userStatus } = queryString
  const formattedOrdering = ordering?.includes('role')
    ? ordering + ',first_name,last_name'
    : ordering

  const dispatch = useDispatch()

  return useInfiniteQuery(
    [USERS, formattedOrdering, desired_user, desiredLaboratory, userStatus],
    fetchUsers,
    {
      getNextPageParam: lastPage => {
        const { next: nextPageUrl } = lastPage.links

        if (nextPageUrl) {
          const queryString = nextPageUrl.slice(nextPageUrl.indexOf('?'))
          const urlParams = new URLSearchParams(queryString)
          const nextPage = urlParams.get('page')

          return nextPage
        }
      },
      onError: error => {
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
      },
    }
  )
}
