import React from 'react'
import { TableBody, useMediaQuery } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { StyledDetailsTable, StyledDetailsTableMobile, TableHeadMobile } from '@styles'

import { TableHead, TaskRow } from './components'
import { TaskRowMobile } from '../TaskRowMobile'

interface TabBasicInfoProps {
  task?: ITaskLab
}

export const TabBasicInfo: React.FC<TabBasicInfoProps> = ({ task }) => {
  const isMobile = useMediaQuery('(max-width:767px)')

  return (
    <>
      {!isMobile ? (
        <StyledDetailsTable>
          <TableHead />
          <TableBody>
            <TaskRow task={task} />
          </TableBody>
        </StyledDetailsTable>
      ) : (
        <StyledDetailsTableMobile>
          <TableHeadMobile></TableHeadMobile>
          {task && <TaskRowMobile task={task} contentType='basic' />}
        </StyledDetailsTableMobile>
      )}
    </>
  )
}
