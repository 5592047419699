import { useMediaQuery } from '@mui/material'
import React from 'react'

import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'
import { CheckDeactivateUser } from '@components/modules/CheckDeactivateUser'

import { Header } from './Header'
import { Footer } from './Footer'
import { MainContainer } from './MainContainer'
import { ErrorBoundary } from '../ErrorBoundary'
import { CentredMain, Main } from './styles'
import { FooterMobile } from './FooterMobile'

interface LayoutProps {
  isNotFound?: boolean
}

export const Layout: React.FC<LayoutProps> = ({ children, isNotFound }) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const isMaintenanceModePage = false
  const { isErrorPage, isMainFooterHidden } = useAppSelector(selectUser)

  const renderFooter = React.useMemo(() => {
    if (isMobile) {
      return <FooterMobile />
    } else {
      return (
        <Footer
          position={isMaintenanceModePage ? 'fixed' : ''}
          isFooterHidden={isMainFooterHidden}
        />
      )
    }
  }, [isMainFooterHidden, isMaintenanceModePage, isMobile])

  const renderMainSection = React.useMemo(() => {
    if (isNotFound) {
      return <CentredMain>{children}</CentredMain>
    } else {
      return (
        <>
          <Main $isErrorPage={isErrorPage}>
            <ErrorBoundary>
              <MainContainer>{children}</MainContainer>
            </ErrorBoundary>
          </Main>

          {renderFooter}
        </>
      )
    }
  }, [children, isErrorPage, isNotFound, renderFooter])

  return (
    <>
      <CheckDeactivateUser />

      <Header />

      {/* WILL BE IMPLEMENT IN NEXT RELEASES */}
      {/* <SocketNotification /> */}

      {renderMainSection}
    </>
  )
}

Layout.defaultProps = {
  isNotFound: false,
}
