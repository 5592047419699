import { styled as styledMUI, Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const Label = styledMUI(Typography)`
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1a2233;
`

export const LabelCheckboxSkillsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const LabelCheckboxSkills = styled.span`
  margin-left: 5px;
`

export const StyledTextSkills = styled.span`
  color: #8f929b;
`
export const StyledParagraphNumber = styled.div`
  height: 18px;
  width: 18px;
  background-color: #eaf8fc;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #076aa2;
`

export const StyledBoxRemoveSkill = styledMUI(Box)`
padding: 24px; 
border: 1px solid #E5E9F1;
border-radius: 8px;
box-shadow: -5px 0px 15px rgb(53, 108, 151, 0.12);
color: #616D84;
width: 83%;
`
export const StyledTypographyRemoveSkill = styledMUI(Typography)`
font-size: 14px;
margin-bottom: 10px;

&:last-child {
  margin-bottom:  0px;
}
`
