import { BoxProps, styled, Box } from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import { MutableRefObject } from 'react'

interface TableHeadWrapperBoxProps extends BoxProps {
  isSticky: boolean | MutableRefObject<HTMLDivElement | null>
}

interface TableCellProps extends BoxProps {
  isSticky?: boolean | MutableRefObject<HTMLDivElement | null>
  isClickable?: boolean
}

const TableHeadWrapperConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'isSticky':
        return false

      default:
        return true
    }
  },
}

const TableCellConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'isSticky':
      case 'isClickable':
        return false

      default:
        return true
    }
  },
}

export const StyledTableHeadWrapper = styled<React.FC<TableHeadWrapperBoxProps>>(
  Box,
  TableHeadWrapperConfig
)`
  position: sticky;
  overflow: auto;
  top: 147px;
  z-index: 300;
  overflow-x: hidden;
  box-shadow: ${({ isSticky }) => (isSticky ? 'none' : '0px 6px 15px rgba(53, 108, 151, 0.12)')};
  transition: box-shadow 500ms linear;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledTableHead = styled('div')`
  width: fit-content;
`

export const StyledTableCell = styled<React.FC<TableCellProps>>(Box, TableCellConfig)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: ${({ theme }) => theme.palette.common.white};

  padding: 0;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};

  border-bottom: 4px solid
    ${({ isSticky, theme }) => (isSticky ? theme.palette.background.default : 'transparent')};

  &:nth-child(1) {
    padding-right: 16px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 0;
      border-color: ${({ theme }) => theme.palette.background.default} transparent transparent
        transparent;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0.8px;
      left: 1px;
      width: 17px;
      height: 17px;
      border-radius: 60%;
      background-color: #ffffff;
    }
  }

  &:nth-child(2) {
    padding-left: 54px;
  }

  & .MuiOutlinedInput-input {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  & .MuiSvgIcon-fontSizeMedium {
    border-radius: 5px;
  }
`

export const FixedTableCell = styled<React.FC<TableCellProps>>(Box, TableCellConfig)`
  position: sticky;

  display: flex;
  align-items: center;
  right: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: 4px solid
    ${({ isSticky, theme }) => (isSticky ? theme.palette.background.default : 'transparent')};
  padding: 17px 30px;
  cursor: auto;

  &:first-child {
    border-top-left-radius: 10px;
    border-radius: 10px 0px 0px 10px;
  }

  box-shadow: -5px 9px 20px -5px rgba(53, 108, 151, 0.12);
  transition: box-shadow 500ms linear;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 0;
    border-color: transparent ${({ theme }) => theme.palette.background.default} transparent;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0.8px;
    right: 1px;
    width: 17px;
    height: 17px;
    border-radius: 60%;
    background-color: #ffffff;
  }
`
