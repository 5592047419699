import React from 'react'
import { Box, useMediaQuery } from '@mui/material'

import { useToggle } from '@hooks/common'
import { useUpdatePackage, useUpdateSample } from '@hooks/queries'
import { ContentOfPage } from '@constants/queries'

import { EditNotesField } from './EditNotesField'
import { ViewNotesField } from './ViewNotesField'
import { EditNotesModalMobile } from './EditNotesModalMobile'

interface NotesFieldProps {
  id: number
  notes?: string | null
  content: ContentOfPage
  editDetailsNotes?: boolean
  withoutTitle?: boolean
  withoutTruncate?: boolean
  mr?: string
  btnText?: string
  labelText?: string
  $left?: string
  width?: string
  notesLength?: number
}

export const NotesField: React.FC<NotesFieldProps> = props => {
  const {
    notes,
    id,
    withoutTruncate,
    editDetailsNotes,
    withoutTitle,
    content,
    btnText,
    labelText,
    mr,
    $left,
    width,
    notesLength,
  } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  const [isEditing, toggleEditing] = useToggle()

  const updateSampleM = useUpdateSample()
  const updatePackageM = useUpdatePackage()

  return (
    <Box position='relative' display='flex' width={width || '100%'} justifyContent='space-between'>
      {isEditing ? (
        <>
          {isMobile ? (
            <EditNotesModalMobile
              content={content}
              notes={notes}
              id={id}
              toggleEditing={toggleEditing}
              updatePackageM={updatePackageM}
              updateSampleM={updateSampleM}
              labelText={labelText}
              withoutTitle={withoutTitle}
              notesLength={notesLength}
            />
          ) : (
            <EditNotesField
              content={content}
              notes={notes}
              id={id}
              notesLength={notesLength}
              toggleEditing={toggleEditing}
              editDetailsNotes={editDetailsNotes}
              updateSampleM={updateSampleM}
              updatePackageM={updatePackageM}
              labelText={labelText}
              withoutTitle={withoutTitle}
            />
          )}
        </>
      ) : (
        <ViewNotesField
          notes={notes}
          btnText={btnText}
          toggleEditing={toggleEditing}
          withoutTruncate={withoutTruncate}
          editDetailsNotes={editDetailsNotes}
          withoutTitle={withoutTitle}
          labelText={labelText}
          id={id}
          mr={mr}
          $left={$left}
        />
      )}
    </Box>
  )
}
