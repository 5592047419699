import React from 'react'

import { ISample } from '@models/samples'
import { datePatterns } from '@constants/dates'
import { getFormattedDate } from '@helpers/ui/date'
import { StyledFlexRow, StyledDetailsLabel, StyledParagraph, StyledTitle } from '@styles'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'
import { SampleDetailsPackageHistory } from '@components/shared'

import { ManufactureDetailsLoader } from '../../Loaders'

interface ViewManufactureDetailsProps {
  sample: ISample
  isLoading: boolean
}

export const ViewManufactureDetails: React.FC<ViewManufactureDetailsProps> = ({
  sample,
  isLoading,
}) => {
  const { timezone } = useAppSelector(selectUser)

  const formattedManufactureDate = getFormattedDate(
    datePatterns.fullDate,
    sample.manufacture_date,
    timezone
  )
  const formattedExpirationDate = getFormattedDate(
    datePatterns.fullDate,
    sample.expiration_date,
    timezone
  )

  if (isLoading) return <ManufactureDetailsLoader />
  return (
    <div>
      <StyledTitle variant='h3' mb='28px' fz={24}>
        Manufacture details
      </StyledTitle>

      <StyledDetailsLabel variant='h5'>Manufacture name</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{sample?.manufacturer?.name || '-'}</StyledParagraph>

      <StyledDetailsLabel variant='h5'>Address of manufacturer</StyledDetailsLabel>
      <StyledParagraph variant='body1'>
        {sample?.address_of_manufacturer?.description || '-'}
      </StyledParagraph>

      <StyledDetailsLabel variant='h5'>Brand</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{sample?.brand?.name || '-'}</StyledParagraph>
      <StyledFlexRow>
        <div>
          <StyledDetailsLabel variant='h5'>Manufacture date</StyledDetailsLabel>
          <StyledParagraph variant='body1'>{formattedManufactureDate || '-'}</StyledParagraph>
        </div>
        <div>
          <StyledDetailsLabel variant='h5'>Expiration date</StyledDetailsLabel>
          <StyledParagraph variant='body1'>{formattedExpirationDate || '-'}</StyledParagraph>
        </div>
      </StyledFlexRow>

      <StyledDetailsLabel variant='h5'>Country of origin</StyledDetailsLabel>
      <StyledParagraph variant='body1'>{sample?.country?.name || '-'}</StyledParagraph>

      <StyledTitle variant='h3' sx={{ marginTop: '40px' }} mb='32px' fz={24}>
        Package history
      </StyledTitle>

      <SampleDetailsPackageHistory sample={sample} />
    </div>
  )
}
