import React from 'react'
import { Box } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { selectUser } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { datePatterns } from '@constants/dates'
import { getFormattedDate } from '@helpers/ui/date'
import { Brand, MobileDetailsCard } from '@components/shared'

import { Manufacture } from './components/Manufacture'
import { ManufacturerAddress } from './components/ManufacturerAddress'

interface ManufactureDetailsMobileProps {
  data?: ITaskLab
  isLoading?: boolean
}

export const ManufactureDetailsMobile: React.FC<ManufactureDetailsMobileProps> = ({ data }) => {
  const { timezone } = useAppSelector(selectUser)

  const formattedManufactureDate = getFormattedDate(
    datePatterns.fullDate,
    data?.manufacture_date,
    timezone
  )
  const formattedExpirationDate = getFormattedDate(
    datePatterns.fullDate,
    data?.expiration_date,
    timezone
  )

  const taskInfo = [
    {
      id: 1,
      title: 'Manufacture name',
      data: <Manufacture manufacturer={data?.manufacturer?.name} />,
      isDataTypeComponent: true,
    },
    {
      id: 2,
      title: 'Address of manufacturer',
      data: <ManufacturerAddress address={data?.address_of_manufacturer?.description} />,
      isDataTypeComponent: true,
    },
    { id: 3, title: 'Brand', data: <Brand brand={data?.brand?.name} />, isDataTypeComponent: true },
    { id: 4, title: 'Manufacture date', data: formattedManufactureDate },
    { id: 5, title: 'Expiration date', data: formattedExpirationDate },
    { id: 6, title: 'Country of origin', data: data?.country?.name },
  ]

  return (
    <Box pb='12px'>
      {taskInfo.map(({ id, title, isDataTypeComponent, data }) => (
        <MobileDetailsCard
          key={id}
          title={title}
          isDataTypeComponent={isDataTypeComponent}
          data={data}
          minWidth='159px'
        />
      ))}
    </Box>
  )
}
