import React from 'react'
import { Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { DETAILS } from '@constants/common'
import { useAppSelector } from '@hooks/redux'
import { selectPackagesState } from '@state/packages'
import { protectedRoutes } from '@configs/routes.config'
import { IPackage, PackageContentType } from '@models/packages'
import { IN_PROGRESS, PICKED_UP, UNPACKED } from '@constants/packages'
import { ParagraphLink, TenantSpecificComponent } from '@components/shared'
import { FixedTableCell } from '@styles'
import { TENANT_TYPE } from '@constants/tenants'
import { useTenant } from '@hooks/common/useTenant'

import { Send, Cancel, Unpacked, PickUp } from './components'

interface ActionProps {
  pack: IPackage
  contentType: PackageContentType
  isLastPack?: boolean
  isPackSelected?: boolean
}

export const Action: React.FC<ActionProps> = ({
  pack,
  contentType,
  isLastPack,
  isPackSelected,
}) => {
  const { canceledOutgoingPackages } = useAppSelector(selectPackagesState)
  const history = useHistory()
  const { isTenantType } = useTenant()

  const pageTitle = contentType === 'default' ? 'Your packages' : `${contentType} packages`

  const packageInfoEndpoint = () => {
    history.replace({
      pathname: `${protectedRoutes.packages.path}/${pack.id}${DETAILS}`,
      state: [
        {
          path: protectedRoutes.packages.path,
          url: protectedRoutes.packages.path,
          search: `?package_type=${contentType}`,
          title: pageTitle,
        },
      ],
    })
  }

  const isPackageCancelled = canceledOutgoingPackages.includes(pack.id)

  const renderActionButton = () => {
    switch (contentType) {
      case 'incoming':
        if (pack.delivery_status.key === IN_PROGRESS) {
          return <PickUp pack={pack} mr={isTenantType('legal_drugs') ? '24px' : '0px'} />
        } else if (pack.delivery_status.key === PICKED_UP) {
          return <Unpacked pack={pack} mr={isTenantType('legal_drugs') ? '24px' : '0px'} />
        } else if (pack.delivery_status.key === UNPACKED && isTenantType(TENANT_TYPE.streetDrugs)) {
          return (
            <Typography sx={{ width: '180px' }} color='#616D84' textAlign='center'>
              Package already unpacked
            </Typography>
          )
        } else return null

      case 'outgoing':
        if (isPackageCancelled) {
          return (
            <Typography
              sx={{ width: '90px', marginRight: isTenantType('legal_drugs') ? '24px' : '0px' }}
              color='#616D84'
              textAlign='center'
            >
              Canceled
            </Typography>
          )
        } else if (pack.delivery_status.key === IN_PROGRESS) {
          return <Cancel pack={pack} mr={isTenantType('legal_drugs') ? '24px' : '0px'} />
        } else if (
          pack.delivery_status.key === PICKED_UP &&
          isTenantType(TENANT_TYPE.streetDrugs)
        ) {
          return (
            <Typography sx={{ width: '180px' }} color='#616D84' textAlign='center'>
              Package already delivered
            </Typography>
          )
        } else if (pack.delivery_status.key === UNPACKED && isTenantType(TENANT_TYPE.streetDrugs)) {
          return (
            <Typography sx={{ width: '180px' }} color='#616D84' textAlign='center'>
              Package already unpacked
            </Typography>
          )
        } else return null

      default:
        return <Send pack={pack} />
    }
  }

  return (
    <FixedTableCell
      id='not-expand'
      hideBorder
      header={false}
      $alignItems='center'
      isLastSample={isLastPack}
      sx={{ borderBottomLeftRadius: isLastPack ? '10px' : '0px' }}
      isPackSelected={isPackSelected}
    >
      {renderActionButton()}
      <TenantSpecificComponent desiredTenant={TENANT_TYPE.legalDrugs}>
        <ParagraphLink onClick={packageInfoEndpoint}>View details</ParagraphLink>
      </TenantSpecificComponent>
    </FixedTableCell>
  )
}
