import { IconButton, styled } from '@mui/material'

export const StyledButton = styled(IconButton)`
  position: fixed;
  width: 40px;
  height: 40px;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid #e5e5e6;
`
