import React from 'react'
import { Box, ButtonProps as ButtonPropsMUI, CircularProgress, useTheme } from '@mui/material'

import { ReactComponent as AddIconBlue } from '@assets/icons/add-icon-blue.svg'

import { StyledButton, StyledChildren } from './styles'

export interface ButtonProps extends ButtonPropsMUI {
  width?: string
  height?: string
  padding?: string
  textColor?: string
  bgColor?: string
  fz?: number
  fw?: number
  mr?: string
  ml?: string
  mt?: string
  mb?: string
  lh?: string
  loading?: boolean
  loadingIndicator?: React.ReactNode
  startIconAdd?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    loading = false,
    loadingIndicator: indicator,
    disabled,
    endIcon,
    startIcon,
    startIconAdd,
    fz,
    lh,
    fullWidth,
    ...restProps
  } = props
  const theme = useTheme()

  const loadingIndicator = indicator ? (
    <Box component='span' sx={{ position: 'absolute' }}>
      {indicator}
    </Box>
  ) : (
    <CircularProgress sx={{ position: 'absolute' }} size={16} color='secondary' />
  )

  return (
    <StyledButton
      ref={ref}
      {...restProps}
      endIcon={!loading && endIcon}
      disabled={disabled || loading}
      startIcon={!loading && startIcon}
    >
      {loading && loadingIndicator}
      <StyledChildren $fz={fz} $loading={loading} $lh={lh}>
        {startIconAdd && (
          <AddIconBlue fill={theme.palette.primary.main} style={{ marginRight: '8px' }} />
        )}
        {children}
      </StyledChildren>
    </StyledButton>
  )
})
