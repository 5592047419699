import React from 'react'

import { useFieldArrayFormContext } from '@context'
import { generateNewSample } from '@helpers/samples'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { selectSamplesCreating, endCreating } from '@state/samples'
import { Button } from '@components/shared'

import { StyledTableButtonRow, StyledTableCell } from '../Table/styles'
import { FixedTableCell } from '../Table/components/TableRow/styles'

export const AddNewSample = () => {
  const { fields: samples, prepend } = useFieldArrayFormContext()

  const isCreating = useAppSelector(selectSamplesCreating)
  const dispatch = useAppDispatch()

  const addSampleHandler = () => prepend(generateNewSample())

  React.useEffect(
    () => () => {
      if (isCreating) dispatch(endCreating())
    },
    [dispatch, isCreating]
  )

  return (
    <StyledTableButtonRow>
      <StyledTableCell>
        <Button
          variant='text'
          height='48px'
          width='155px'
          ml='28px'
          onClick={addSampleHandler}
          disabled={samples.length >= 100}
          startIconAdd
        >
          Add sample
        </Button>
      </StyledTableCell>
      <FixedTableCell></FixedTableCell>
    </StyledTableButtonRow>
  )
}
