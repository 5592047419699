import { Box, styled as styledMUI, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledTableTitle = styledMUI(Typography)`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`

export const SortBox = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  & .MuiButtonBase-root {
    padding: 6px;
  }
`

export const StubCell = styled<React.FC<{ isSticky?: boolean }>>(Box)`
  background-color: #fff;
  border-bottom: 4px solid
    ${({ isSticky, theme }) => (isSticky ? theme.palette.background.default : 'transparent')}; ;
`
