import React from 'react'
import { useHistory } from 'react-router-dom'

import { IHRSample } from '@models/samples'
import { SpecificId } from '@components/shared/sampleFields'
import { protectedRoutes } from '@configs/routes.config'
import { IHrPackage } from '@models/packages'
import { DETAILS } from '@constants/common'
import { PrevLocationState } from '@models/common/app'

import { StyledTableCell } from '../../styles'

interface SampleIDProps {
  sample: IHRSample
  packagesData?: IHrPackage
}

export const SampleID: React.FC<SampleIDProps> = ({ sample, packagesData }) => {
  const history = useHistory()
  const locationState = history.location.state as Array<PrevLocationState>

  return (
    <StyledTableCell sx={{ position: 'relative' }}>
      <SpecificId
        labelTooltip={sample.initial_laboratory.label_tooltip}
        specificId={sample.specific_id}
        laboratory={sample.laboratory}
        sampleId={sample.id}
        historyLocationState={locationState}
        pathForLink={`${protectedRoutes.packages.path}/${packagesData?.id}${DETAILS}`}
        titleForPathLink={`${protectedRoutes.packageDetails.name} ${packagesData?.specific_id}`}
      />
    </StyledTableCell>
  )
}
