import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { ATTACHMENTS } from '@constants/queries'
import { fetchAttachments, fetchAttachmentsForMutate } from '@api/attachments'
import { IAttachments, IFetchAttachments } from '@models/attachments'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useFetchAttachments = (data: IFetchAttachments) => {
  const dispatch = useDispatch()
  const client = useQueryClient()

  return useQuery<unknown, IError, IAttachments>(
    [ATTACHMENTS, data.object_id],
    fetchAttachments.bind(null, data),
    {
      onSuccess: () => {
        client.invalidateQueries(['packagesBlockchainHistory', data.object_id])
      },
      onError: error => {
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
      },
      enabled: !!data.object_id,
    }
  )
}

export const useFetchMutateAttachments = (data: IFetchAttachments) => {
  const client = useQueryClient()
  const dispatch = useDispatch()

  const mutation = useMutation(fetchAttachmentsForMutate.bind(null, data), {
    onSuccess: () => {
      client.invalidateQueries(['packagesBlockchainHistory', data.object_id])
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
