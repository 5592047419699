import styled from 'styled-components'

export const StyledTableHead = styled.div`
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: ${({ theme }) => theme.header.height + 96}px;
  z-index: 10;
  background-color: ${({ theme }) => theme.palette.common.white};
`
