import React from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { useDispatch } from 'react-redux'

import { ButtonBox, StyledModalSubtitle, StyledModalTitle } from '@styles'
import { Button, Modal } from '@components/shared'
import { ReactComponent as MissedIcon } from '@assets/icons/info-circle-xs.svg'
import { IEditInfoLaboratoryData } from '@models/yourWorkspace'
import { useEditLaboratory } from '@hooks/queries'
import { setIsOpenEditLaboratoryModal } from '@state/workSpace'

import * as Styled from './styles'

interface ConfirmationModalProps {
  onClose: () => void
  editInfoLaboratoryData?: IEditInfoLaboratoryData | {}
}

export const ConfirmDeleteSkillsModal: React.FC<ConfirmationModalProps> = props => {
  const { onClose, editInfoLaboratoryData } = props
  const { mutate, isLoading } = useEditLaboratory()
  const dispatch = useDispatch()

  const confirmHandler = () => {
    mutate(editInfoLaboratoryData as IEditInfoLaboratoryData, {
      onSuccess: () => {
        dispatch(setIsOpenEditLaboratoryModal(false))
        onClose()
      },
    })
  }

  const infoText = [
    {
      id: 1,
      text: 'Send all untested samples to the Site that works with this type of samples',
    },
    {
      id: 2,
      text: 'Laboratory Assistants can test samples that are In-progress or Manager can assign such samples to themselves',
    },
    {
      id: 3,
      text: 'All Awaiting tasks are unassigned from the Laboratory Assistant and assigned to the Main Manager',
    },
  ]

  return (
    <Modal open onClose={onClose}>
      <Box width='480px'>
        <StyledModalTitle variant='h3' sx={{ marginBottom: '8px' }}>
          Remove skill(s) from the Site?
        </StyledModalTitle>
        <StyledModalSubtitle mb='17px'>
          Please make sure you have read instructions below.
        </StyledModalSubtitle>

        <Styled.StyledBoxRemoveSkill>
          <Box display='flex' mb='26px' alignItems='center'>
            <MissedIcon width='24px' height='24px' style={{ marginRight: '8px' }} />
            <Typography fontSize={14} color='#0F1934' fontWeight={500}>
              After skill(s) removal:
            </Typography>
          </Box>

          {infoText.map(item => (
            <Box key={item.id} display='flex' mb='16px'>
              <Styled.StyledParagraphNumber>{item.id}</Styled.StyledParagraphNumber>
              <Styled.StyledTypographyRemoveSkill>{item.text}</Styled.StyledTypographyRemoveSkill>
            </Box>
          ))}
        </Styled.StyledBoxRemoveSkill>

        <ButtonBox>
          <Button variant='outlined' width='205px' mr='60px' onClick={onClose}>
            No, return to editing
          </Button>

          <Button variant='contained' width='205px' disabled={isLoading} onClick={confirmHandler}>
            Yes, remove skill(s)
          </Button>
        </ButtonBox>
      </Box>
    </Modal>
  )
}
