import { MuiStyledOptions } from '@mui/system'
import styled, { StyledConfig } from 'styled-components'
import { styled as styledMUI, Checkbox } from '@mui/material'

import checkedIcon from '@assets/icons/checked-icon-path.svg'
import indeterminateIcon from '@assets/icons/checked-icon-indeterminate-path.svg'

import { CheckboxProps } from './Checkbox'

const checkboxConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'padding':
        return false
      case 'ml':
        return false
      case 'mb':
        return false

      default:
        return true
    }
  },
}

export const StyledCheckbox = styledMUI<React.FC<CheckboxProps>>(Checkbox, checkboxConfig)`
  padding: ${({ padding }) => padding || ''};
  margin-left: ${({ ml }) => ml || ''};
  margin-bottom: ${({ mb }) => mb || ''};
`

export const BpIcon = styled.span<{ borderColor?: string }>`
  border-radius: 5px;
  width: 16px;
  height: 16px;
  border: 1px solid ${({ borderColor }) => borderColor};
`

export const BpCheckedIcon = styled(BpIcon)`
  border-color: transparent;
  background-color: ${({ theme }) => theme.palette.primary.main};

  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(${checkedIcon});
    background-repeat: no-repeat;
    background-position: center;
  }
  & input:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const BpIndeterminateIcon = styled(BpIcon)`
  border-color: transparent;
  background-color: ${({ theme }) => theme.palette.primary.main};

  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(${indeterminateIcon});
    background-repeat: no-repeat;
    background-position: center;
  }
  & input:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`
