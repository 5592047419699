import { ITaskLab } from '@models/tasks'
import { http } from '@services/http'
import { ITestData, IUpdatedTestData } from '@models/tests'

export const updateTest = async ({ taskId, data = {} }: ITestData) => {
  try {
    return http.patch<ITaskLab, IUpdatedTestData>(`/tasks/${taskId}/test/`, data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const editTestResult = async ({ taskId, data = {} }: ITestData) => {
  try {
    return http.patch<ITaskLab, IUpdatedTestData>(`/tasks/${taskId}/`, data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateTestCancelManualWay = async ({ taskId, data = {} }: ITestData) => {
  try {
    return http.patch<ITaskLab, IUpdatedTestData>(`/tasks/${taskId}/cancel_testing/`, data)
  } catch (error) {
    return Promise.reject(error)
  }
}
