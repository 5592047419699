import { useDispatch } from 'react-redux'

import { logout } from '@state/user'

export const AutoLogOut = () => {
  let timeoutTracker: NodeJS.Timeout | undefined
  let interval: NodeJS.Timeout
  const dispatch = useDispatch()

  const startInterval = () => {
    updateExpiredTime()

    interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem('_expiredTime') || `null`)

      if (+expiredTime < Date.now()) {
        localStorage.setItem('auto-logout', 'true')
        localStorage.removeItem('_expiredTime')

        if (timeoutTracker) {
          clearTimeout(timeoutTracker)
          dispatch(logout())
        }

        cleanUp()
      }
    }, 1000)
  }

  const updateExpiredTime = () => {
    if (timeoutTracker) {
      clearTimeout(timeoutTracker)
    }

    timeoutTracker = setTimeout(() => {
      const time = Date.now() + 21600000 //expire after 6 hours
      localStorage.setItem('_expiredTime', `${time}`)
      localStorage.setItem('auto-logout', 'false')
    }, 1000)
  }

  const eventHandler = () => updateExpiredTime()

  const tracker = () => {
    window.addEventListener('mousemove', eventHandler)
    window.addEventListener('scroll', eventHandler)
    window.addEventListener('keydown', eventHandler)
  }

  const cleanUp = () => {
    localStorage.removeItem('_expiredTime')
    clearInterval(interval)
    window.removeEventListener('mousemove', eventHandler)
    window.removeEventListener('scroll', eventHandler)
    window.removeEventListener('keydown', eventHandler)
  }

  return { startInterval, tracker, cleanUp }
}
