import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'

interface TableCellProps extends BoxProps {
  isLastSample?: boolean
  isSampleSelected?: boolean
  paddingTop?: string
}

const TableCellConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'isLastSample':
      case 'isSampleSelected':
      case 'paddingTop':
        return false

      default:
        return true
    }
  },
}

export const StyledTableCell = styled<React.FC<TableCellProps>>(Box, TableCellConfig)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0 16px;
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '')};

  &:nth-child(1) {
    padding-right: 16px;

    &::before {
      content: '';
      display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
      position: absolute;
      bottom: 4px;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 0 10px;
      border-color: transparent transparent transparent
        ${({ theme }) => theme.palette.background.default};
    }

    &::after {
      content: '';
      display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
      position: absolute;
      bottom: 4px;
      left: 0.6px;
      width: 15px;
      height: 15px;
      border-radius: 60%;
      background-color: ${({ theme, isSampleSelected }) =>
        isSampleSelected ? theme.primary[30] : '#ffffff'};
    }
  }

  &:nth-child(2) {
    padding-left: 18px;
    padding-right: 48px;
    justify-content: flex-start;
    margin-left: 10px;
  }

  & .MuiOutlinedInput-input {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  & .MuiSvgIcon-fontSizeMedium {
    border-radius: 5px;
  }
`

export const FixedTableCell = styled<React.FC<TableCellProps>>(Box, TableCellConfig)`
  position: sticky;

  display: flex;
  flex: 1 1 auto;
  right: 0;
  background-color: ${({ theme, isSampleSelected }) =>
    isSampleSelected ? theme.primary[30] : theme.palette.common.white};
  padding: 16px 30px;
  cursor: auto;

  box-shadow: -5px 9px 20px -5px rgba(53, 108, 151, 0.12);
  border-bottom-left-radius: ${({ isLastSample }) => (isLastSample ? '10px' : '0px')};

  //for corners border-radius
  &::before {
    content: '';
    display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent transparent ${({ theme }) => theme.palette.background.default}
      transparent;
  }

  &::after {
    content: '';
    display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
    position: absolute;
    bottom: 0.8px;
    right: 0.6px;
    width: 15px;
    height: 15px;
    border-radius: 60%;
    background-color: ${({ theme, isSampleSelected }) =>
      isSampleSelected ? theme.primary[30] : '#ffffff'};
  }

  //hide shadow
  & .hide-shadow::before {
    content: '';
    display: block;
    height: 4px;
    width: 280px;
    background-color: ${({ theme }) => theme.palette.background.default};
    position: absolute;
    top: -4px;
    right: 0;
  }

  & .hide-shadow::after {
    content: '';
    display: block;
    height: 4px;
    width: 280px;
    background-color: ${({ theme }) => theme.palette.background.default};
    position: absolute;
    bottom: -4px;
    right: 0;
  }
`

interface StyledTextCellProps extends TypographyProps {
  mr?: string
}

export const StyledTextCell = styled<React.FC<StyledTextCellProps>>(Typography)`
  width: 100%;
  margin-right: ${({ mr }) => (mr ? mr : '48px')};

  overflow: hidden;
  text-overflow: ellipsis;
`
