import { StringParam, useQueryParams } from 'use-query-params'
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { fetchRequests } from '@api/requests'
import { REQUESTS, INCOMING_REQUESTS, OUTGOING_REQUESTS } from '@constants/queries'
import { IRequestsData } from '@models/requests'
import { setMaintenanceModePage } from '@state/user'
import { filterStringParamsQueries } from '@components/shared/FilterAside/constants'

export const useFetchRequests = (): UseInfiniteQueryResult<IRequestsData> => {
  const stringParams = {
    ordering: StringParam,
    requests_type: StringParam,
    view_type: StringParam,
    desired_request: StringParam,
    ...filterStringParamsQueries,
  }

  const [queryString] = useQueryParams(stringParams)
  const dispatch = useDispatch()
  const {
    ordering,
    requests_type: requestSwitcherType,
    view_type,
    desired_request,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    status,
    expirationDate,
    laboratories,
  } = queryString

  const requestType =
    view_type === 'incoming'
      ? INCOMING_REQUESTS
      : view_type === 'outgoing'
      ? OUTGOING_REQUESTS
      : REQUESTS

  const statusObj = status?.split(',')

  const queryKey = [
    requestType,
    requestSwitcherType,
    desired_request,
    ordering,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    statusObj,
    expirationDate,
    laboratories,
  ]

  return useInfiniteQuery<IRequestsData, IError>(queryKey, fetchRequests, {
    getNextPageParam: lastPage => {
      const { next: nextPageUrl } = lastPage.links

      if (nextPageUrl) {
        const queryString = nextPageUrl.slice(nextPageUrl.indexOf('?'))
        const urlParams = new URLSearchParams(queryString)
        const nextPage = urlParams.get('page')

        return nextPage
      }
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
