import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'
import { fetchClinicallyMeaningful } from '@api/tasks'
import { CLINICALLY_MEANINGFUL } from '@constants/hrSamples'

export const useFetchClinicallyMeaningful = () => {
  const dispatch = useDispatch()

  return useQuery([CLINICALLY_MEANINGFUL], fetchClinicallyMeaningful, {
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
