import { fileTypes } from '@constants/common'
import { FileTypes } from '@models/attachments'

export const fileTypeConverter = (fileName: string) => {
  const fileExtension = fileName.split('.')[fileName.split('.').length - 1] as FileTypes

  return {
    iconType: fileTypes[fileExtension] || fileTypes.link,
    fileType: fileTypes[fileExtension] || 'link',
  }
}
