import React from 'react'
import { useMediaQuery } from '@mui/material'

import { ISample } from '@models/samples'
import { IPackage } from '@models/packages'

import { Notes } from './Notes'
import { Dosage } from './Dosage'
import { APIsCell } from './APIsCell'
import { ExcipientsCell } from './ExcipientsCell'
import { SampleID } from './SampleID'
import { DrugClass } from './DrugClass'
import { Manufacturer } from './Manufacturer'
import { ExpirationDateCell } from './ExpirationDateCell'
import { RegistrationStatus } from './RegistrationStatus'
import { Counter } from './Counter/Counter'
import { ActionCell } from './ActionCell'
import { Units } from './Units'
import { TotalValue } from './TotalValue'
import { AddressOfManufacturer } from './AddressOfManufacturer'

interface SampleCellsProps {
  sample: ISample
  isLastSample?: boolean
  counter: number
  actionBtnText?: 'Remove' | 'Missed'
  shouldDisplayActionBtn?: boolean
  shouldDisplayMissedText?: boolean
  packagesData?: IPackage
}

export const SampleCells: React.FC<SampleCellsProps> = props => {
  const {
    sample,
    isLastSample,
    counter,
    actionBtnText,
    shouldDisplayActionBtn,
    shouldDisplayMissedText,
    packagesData,
  } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  const cells = [
    Counter,
    SampleID,
    Manufacturer,
    AddressOfManufacturer,
    Notes,
    DrugClass,
    Dosage,
    Units,
    TotalValue,
    APIsCell,
    ExcipientsCell,
    ExpirationDateCell,
    RegistrationStatus,
  ]
  return (
    <>
      {cells.map((Cell, idx) => (
        <Cell
          key={idx}
          sample={sample}
          counter={counter}
          isLastSample={isLastSample}
          isMobile={isMobile}
          packagesData={packagesData}
        />
      ))}

      <ActionCell
        sample={sample}
        isLastSample={isLastSample}
        actionBtnText={actionBtnText}
        shouldDisplayActionBtn={shouldDisplayActionBtn}
        shouldDisplayMissedText={shouldDisplayMissedText}
      />
    </>
  )
}
