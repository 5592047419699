import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Tooltip as TooltipMUI, tooltipClasses } from '@mui/material'

import { TooltipProps } from '@components/shared/Tooltip/tooltip.types'

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <TooltipMUI {...props} classes={{ popper: className }} />
))(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: '#616D84',
    maxWidth: maxWidth || 202,
    marginBottom: 0,
    padding: '15px',
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(15),
    fontWeight: 400,
    border: '1px solid #D9DDE4',
    boxShadow: '1px 12px 20px rgba(103, 122, 170, 0.08)',
    borderRadius: '4px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#ffffff',
    marginBottom: '70px',
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    color: '#ffffff',
    borderBottomStyle: 'solid',
    borderRightStyle: 'solid',
    borderBottomColor: '#d9dde4',
    borderRightColor: '#d9dde4',
    borderBottomWidth: '1px',
    borderRightWidth: '1px',
  },
}))
