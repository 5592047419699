import React from 'react'

import { ISample } from '@models/samples'
import { SpecificId } from '@components/shared/sampleFields'
import { TempSensitiveToxicityIcons } from '@components/shared'

import { StyledTableCell } from '../../styles'

interface SampleIDProps {
  sample: ISample
  packageId?: string
}

export const SampleID: React.FC<SampleIDProps> = ({ sample, packageId }) => {
  const {
    specific_id: specificId,
    toxicity_level,
    min_temperature,
    max_temperature,
    temperature_measurement_format,
  } = sample

  return (
    <StyledTableCell sx={{ marginTop: '-34px' }}>
      <SpecificId labelTooltip={sample.initial_laboratory.label_tooltip} specificId={specificId} />

      <TempSensitiveToxicityIcons
        toxicity={toxicity_level}
        minTempSensitive={min_temperature}
        maxTempSensitive={max_temperature}
        temperatureMeasurementFormat={temperature_measurement_format?.key}
        mt='-5px'
      />
    </StyledTableCell>
  )
}
