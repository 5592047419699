import React from 'react'
import { Typography, useMediaQuery } from '@mui/material'

import { PillsImage } from '@components/icons'

import { StyledBox } from './styles'

export const NoFilteredSamples: React.FC = () => {
  const $isMobile = useMediaQuery('(max-width:767px)')

  return (
    <StyledBox sx={{ paddingTop: $isMobile ? '10px' : '80px' }}>
      <PillsImage />
      <Typography variant='h4' color='#778299' textAlign='center'>
        No samples with such parameters
      </Typography>
    </StyledBox>
  )
}
