import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useMediaQuery } from '@mui/material'

import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'
import { completeTest, selectTasks } from '@state/tasks'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { FullPageError } from '@components/shared'

import { Table } from './components'
import { MobileTable } from './components/MobileTable'
import { TasksContentProps } from './taskContent.types'

export const TasksContent: React.FC<TasksContentProps> = props => {
  const { contentType, tasks, hasNextPage, fetchNextPage, isFetchingNextPage, totalTasks } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const isHasToxicityRef = React.useRef<boolean>(false)
  const isHasTemperatureRef = React.useRef<boolean>(false)

  const { completedTest } = useAppSelector(selectTasks)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    // Do we have toxicity or temperature in tasks
    tasks?.forEach(task => {
      if (!isHasToxicityRef.current && task.toxicity_level) isHasToxicityRef.current = true
      if (!isHasTemperatureRef.current && task.max_temperature) isHasTemperatureRef.current = true
    })
  }, [tasks, contentType])

  React.useEffect(() => {
    if (completedTest) {
      showNotification({
        ...notifications.taskFinishedSuccess,
        title: `Task = ${completedTest} is finished`,
      })
    }
    dispatch(completeTest(null))
  }, [completedTest, dispatch])

  if (!tasks?.length) {
    return (
      <>
        <FullPageError
          imgVariant='common'
          errorTextTitle={
            contentType === 'awaiting'
              ? 'There are no awaiting tasks yet'
              : 'There are no completed tasks yet'
          }
          pt='20px'
          mb={1}
        />
      </>
    )
  }

  return (
    <>
      <InfiniteScroll hasMore={hasNextPage} loadMore={fetchNextPage}>
        {isMobile ? (
          <MobileTable
            isMobile={isMobile}
            tasks={tasks}
            contentType={contentType}
            isFetchingNextPage={isFetchingNextPage}
          />
        ) : (
          <Table
            contentType={contentType}
            tasks={tasks}
            isFetchingNextPage={isFetchingNextPage}
            totalTasks={totalTasks}
          />
        )}
      </InfiniteScroll>
    </>
  )
}
