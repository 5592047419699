import { MuiStyledOptions } from '@mui/system'
import { styled as styledMUI, Button as ButtonMUI } from '@mui/material'
import styled, { StyledConfig } from 'styled-components'

import { ButtonProps } from './Button'

const buttonConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'width':
      case 'height':
      case 'padding':
      case 'textColor':
      case 'bgColor':
      case 'fz':
      case 'mr':
      case 'ml':
      case 'mt':
      case 'mb':
      case 'lh':
      case 'fv':
        return false

      default:
        return true
    }
  },
}

export const StyledButton = styledMUI<React.FC<ButtonProps>>(ButtonMUI, buttonConfig)`
  width: ${({ width }) => width || ''};
  font-weight: ${({ fw }) => fw};
  height: ${({ height }) => height || ''};
  padding: ${({ padding }) => padding || ''};
  margin-right: ${({ mr }) => mr || ''};
  margin-left: ${({ ml }) => ml || ''};
  margin-top: ${({ mt }) => mt || ''};
  margin-bottom: ${({ mb }) => mb || ''};

  &:first-child {
  margin-top: ${({ mt }) => mt || ''};

  }
  background-color: ${({ bgColor, disabled }) => (!disabled && bgColor) || ''};
  color: ${({ variant, textColor, theme }) => {
    if (textColor) return textColor

    switch (variant) {
      case 'outlined':
        return theme.palette.secondary.main

      default:
        return ''
    }
  }};
  border-color: ${({ variant, theme }) => {
    switch (variant) {
      case 'contained':
        return 'transparent'

      case 'outlined':
        return theme.additional.stroke.main

      default:
        return ''
    }
  }};

  &:not(.Mui-disabled):focus {
    background-color: ${({ theme, variant }) => {
      switch (variant) {
        case 'contained':
          return theme.primary[60]

        default:
          return ''
      }
    }};
    border: ${({ variant, theme }) => {
      switch (variant) {
        case 'outlined':
          return '1px solid ' + theme.palette.primary.main

        default:
          return ''
      }
    }};
  }

  &:not(.Mui-disabled):hover {
    background-color: ${({ variant, theme }) => {
      switch (variant) {
        case 'contained':
          return theme.primary[60]

        case 'outlined':
          return theme.primary[10]

        default:
          return ''
      }
    }};


    border-color: ${({ variant }) => {
      switch (variant) {
        case 'outlined':
          return 'transparent'

        default:
          return ''
      }
    }};
  }

  &:not(.Mui-disabled):active {
    background-color: ${({ variant, theme }) => {
      switch (variant) {
        case 'contained':
          return theme.primary[40]

        case 'outlined':
          return theme.primary[10]

        default:
          return ''
      }
    }};
    border: ${({ variant, theme }) => {
      switch (variant) {
        case 'outlined':
          return '1px solid ' + theme.palette.primary.main

        default:
          return ''
      }
    }};
  }

  &.Mui-disabled {
    &.MuiButton-contained {
      background-color: {({ theme }) => theme.additional.stroke.main};
      color: #8F929B;
    }

    &.MuiButton-outlined {
      background-color: #FFFFFF;
      border: 1px solid {({ theme }) => theme.additional.stroke.main};
      color: #8F929B;
    }

    .MuiCardMedia-root {
      filter: brightness(0.5);
    }
  }
`

export const StyledChildren = styled.span<{ $loading: boolean; $fz?: number; $lh?: string }>`
  display: flex;
  align-items: center;
  opacity: ${({ $loading }) => ($loading ? '0' : '1')};
  font-size: ${({ $fz }) => $fz || '16px'};
  font-weight: 500;
  line-height: ${({ $lh }) => $lh};
`
