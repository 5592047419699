import React from 'react'
import { useDispatch } from 'react-redux'
import { useController } from 'react-hook-form'

import { PureChip, PureDropdownAutocomplete } from '@components/ui'
import { useFetchExcipients } from '@hooks/queries'
import { setCellValueToSample } from '@state/sampleCreate'
import { IOption } from '@models/common/app'
import { IOptionExtended } from '@components/ui/PureDropdownAutocomplete/pureDropdownAutocomplete.types'

import { PureCellPropTypes } from './pureFields.types'
import { StyledChipWrap } from './styles'

export const Excipients: React.FC<PureCellPropTypes> = ({
  control,
  name,
  cellName,
  sampleId,
  rules,
  initialVal,
  inputPlaceholder,
  isCopy,
}) => {
  const [searchQuery, setSearchQuery] = React.useState('')
  const [excipients, setExcipients] = React.useState<IOption[]>([])
  const dispatch = useDispatch()

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: initialVal.val })

  const { data, isFetching } = useFetchExcipients(searchQuery)
  const pureOptions = data && data.pages.map(page => page.results).flat()

  const correctOptions = React.useMemo(() => {
    if (pureOptions) {
      return pureOptions?.filter(({ id }) => !excipients.some(option => option.id === id)) || []
    }
    return []
  }, [pureOptions, excipients])

  const setCellValue = (val: IOption[] | null, error: string) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val, error },
        cellName,
        sampleId,
      })
    )
  }

  React.useEffect(() => {
    if (initialVal.val && Array.isArray(initialVal.val) && initialVal.val.length) {
      setExcipients(initialVal.val)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (error && error.message) setCellValue(field.value, error.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onDropdownChange = (val: IOption) => {
    setExcipients(prevState => {
      const newArr = [...prevState, val]
      field.onChange({ target: { value: newArr } })
      setCellValue(newArr, '')
      return newArr
    })
  }

  const handleDeleteSelectedExcipients = (id: number) => {
    const newArr = excipients.filter(({ id: excipientId }) => excipientId !== id)
    field.onChange({
      target: { value: newArr },
    })
    setCellValue(newArr, '')
    setExcipients(newArr)
  }

  return (
    <>
      <PureDropdownAutocomplete
        disabledOptions={excipients.length >= 5}
        isCopy={isCopy}
        isLoading={isFetching}
        name={name}
        inputPlaceholder={inputPlaceholder}
        options={correctOptions as IOptionExtended[]}
        errorMessage={error?.message || ''}
        onInputChange={val => setSearchQuery(val)}
        onDropdownChange={onDropdownChange}
        isCleanInputAfterSelectOption
      />
      <StyledChipWrap>
        {excipients.map(excipient => (
          <PureChip
            truncatedLabelLength={27}
            key={excipient.id}
            label={excipient.name}
            onDelete={() => handleDeleteSelectedExcipients(excipient.id)}
            isExcipientsChip
          />
        ))}
      </StyledChipWrap>
    </>
  )
}
