import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, IconButton, InputAdornment, Typography } from '@mui/material'

import { Input } from '@components/shared'
import {
  MAX_CHARACTERS,
  MIN_CHARACTERS,
  REQUIRED_FIELD,
  INVALID_FORMAT,
} from '@constants/validation'
import { StyledDetailsLabel } from '@styles'
import { PureRadioButtons } from '@components/ui'
import { ReactComponent as PercentageIcon } from '@assets/icons/percent-icon.svg'
import { ReactComponent as DeleteIcon } from '@assets/icons/delete-icon-active.svg'
import { handleStopEnterEAndArrows, handleStopWheelNumber } from '@helpers/forms'

import { SubstancesItemGroupPropTypes } from './substancesRecognized.types'
import {
  StyledSubstanceBox,
  StyledProportionBox,
  StyledPercentageWarning,
  StyledDeleteButton,
  StyledSubstancesWrap,
} from './styles'

export const SubstancesItemGroup: React.FC<SubstancesItemGroupPropTypes> = props => {
  const percentageRef = React.useRef<HTMLInputElement | null>(null)
  const { index, handleRemoveItemGroup, isNotRecognized, isShowRemoveBtn = true, isEdit } = props
  const {
    watch,
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext()
  const radiosData = React.useMemo(() => {
    return [
      { id: '0', label: 'Percentage' },
      { id: '1', label: 'Not determined' },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const keyName = isEdit ? 'fields.substances' : 'substances'
  const errorNameKey = isEdit
    ? errors.fields?.substances?.[index]?.name?.message
    : errors.substances?.[index]?.name?.message
  const errorPercentageKey = isEdit
    ? errors.fields?.substances?.[index]?.percentage?.message
    : errors.substances?.[index]?.percentage?.message
  const percentageVal = watch(`${keyName}.${index}.percentage`)
  const isDetermined = watch(`${keyName}.${index}.is_not_determined`)
  const labelMb = (() => {
    if (!!errorPercentageKey) {
      return '36px'
    } else if (
      (+percentageVal < 0 && +percentageVal >= -100) ||
      (+percentageVal > 100 && +percentageVal <= 200)
    ) {
      return '50px'
    }
    return '28px'
  })()

  const isShowWarning =
    (+percentageVal < 0 || +percentageVal > 100) &&
    !(+percentageVal < -100 || +percentageVal > 200) &&
    !errors.substances?.[index]?.percentage?.message &&
    !isDetermined

  const percentageRegister = register(`${keyName}.${index}.percentage`, {
    required: isDetermined || isNotRecognized ? false : REQUIRED_FIELD,
    validate: {
      minNumb: (v: string) => {
        return isDetermined || isNotRecognized || +v >= -100 || 'Minimum -100%'
      },
      maxNumb: (v: string) => {
        return isDetermined || isNotRecognized || +v <= 200 || 'Maximum 200%'
      },
      invalidFormat: (v: string) => {
        return isDetermined || isNotRecognized || v.trim() !== '-0' || INVALID_FORMAT
      },
      numericDecimal: (v: string) => {
        return (
          isDetermined ||
          isNotRecognized ||
          /^-?\d+(\.\d{1,5})?$/.test(v) ||
          'Maximum 5 characters after dot'
        )
      },
    },
  })

  React.useEffect(() => {
    const currentElement = percentageRef.current
    if (currentElement) {
      const inputName = currentElement.querySelector('input')?.name
      currentElement.addEventListener(
        'wheel',
        (e: WheelEvent) => handleStopWheelNumber(e, inputName),
        { passive: false }
      )

      return () => {
        currentElement.removeEventListener('wheel', (e: WheelEvent) =>
          handleStopWheelNumber(e, inputName)
        )
      }
    }
  }, [])

  // CLEAR ALL ERRORS after toggle recognized checkbox
  React.useEffect(() => {
    if (isNotRecognized) {
      if (!!errorNameKey) {
        clearErrors(`${keyName}.${index}.name`)
      }
      if (!!errorPercentageKey) {
        clearErrors(`${keyName}.${index}.percentage`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotRecognized])
  // CLEAR PERCENTAGE ERROR after toggle radio-buttons
  React.useEffect(() => {
    if (isDetermined && !!errorPercentageKey) {
      clearErrors(`${keyName}.${index}.percentage`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetermined])

  const handleChangeRadioBtns = (val: string) => {
    setValue(`${keyName}.${index}.is_not_determined`, !!+val)
    // setIsNotDetermined(!!+val)
  }

  return (
    <>
      {isEdit && (
        <Box display='flex' alignItems='center' justifyContent='space-between' mb='16px'>
          <Typography color={isNotRecognized ? 'primary.light' : 'text.primary'} variant='h5'>
            {`Substance ${index + 1}`}
          </Typography>

          {isShowRemoveBtn && (
            <IconButton onClick={handleRemoveItemGroup} disabled={isNotRecognized}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      )}

      <StyledSubstancesWrap $isEdit={isEdit}>
        <StyledSubstanceBox $isEdit={isEdit}>
          <StyledDetailsLabel mb='9px' color={isNotRecognized ? 'primary.light' : 'text.primary'}>
            Substance
          </StyledDetailsLabel>
          <Input
            width='100%'
            height={48}
            variant='outlined'
            type='text'
            disabled={isNotRecognized}
            placeholder='Type substance here...'
            error={!!errorNameKey}
            helperText={errorNameKey}
            {...register(`${keyName}.${index}.name`, {
              required: isNotRecognized ? false : REQUIRED_FIELD,
              minLength: { value: 2, message: MIN_CHARACTERS(2) },
              maxLength: { value: 255, message: MAX_CHARACTERS(255) },
            })}
          />
        </StyledSubstanceBox>

        <StyledProportionBox $isEdit={isEdit}>
          <StyledDetailsLabel mb='9px' color={isNotRecognized ? 'primary.light' : 'text.primary'}>
            Proportion
          </StyledDetailsLabel>
          <div style={{ display: 'flex' }}>
            <PureRadioButtons
              styles={{ whiteSpace: 'nowrap', marginRight: '-20px', paddingTop: '16px' }}
              name={`substances__${index}`}
              data={radiosData}
              initialValue={`${+!!isDetermined}`}
              labelStyles={{ marginBottom: labelMb }}
              onChange={handleChangeRadioBtns}
              direction='column'
              disabled={isNotRecognized}
            />
            <div style={{ width: '100%' }}>
              <Input
                width='100%'
                height={48}
                disabled={isNotRecognized || isDetermined}
                variant='outlined'
                type='number'
                placeholder='Type number here...'
                error={!!errorPercentageKey}
                helperText={errorPercentageKey}
                {...percentageRegister}
                ref={(node: HTMLInputElement | null) => {
                  percentageRef.current = node
                  percentageRegister.ref(node)
                }}
                onKeyDown={handleStopEnterEAndArrows}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <PercentageIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {isShowWarning && (
                <StyledPercentageWarning>
                  It could be something wrong with testing results. Contact Central laboratory for
                  support
                </StyledPercentageWarning>
              )}
            </div>
          </div>
        </StyledProportionBox>

        {isShowRemoveBtn && !isEdit && (
          <StyledDeleteButton
            onClick={handleRemoveItemGroup}
            disabled={isNotRecognized}
            $isEdit={isEdit}
          >
            <DeleteIcon />
          </StyledDeleteButton>
        )}
      </StyledSubstancesWrap>
    </>
  )
}
