import React, { useEffect } from 'react'

import { useFieldArrayFormContext } from '@context'
import { useActionsStickyBox } from '@hooks/common'
import { draggableHorizontalScroll } from '@helpers/draggableHorizontalScroll'
import { StyledTableBox } from '@styles'
import { HorizontalDragCup, HorizontalDragLayer } from '@components/shared'

import { TableBody, TableHead } from './components'

export const Table: React.FC = () => {
  const draggableHorizontalRef = React.useRef<HTMLDivElement | null>(null)
  const horizontalDragCup = React.useRef<HTMLDivElement | null>(null)
  const draggableHorizontalLayerRef = React.useRef<HTMLDivElement | null>(null)
  const tableHeadRef = React.useRef<HTMLDivElement | null>(null)
  const isSticky = useActionsStickyBox(tableHeadRef, '-450px 0px 0px 0px')

  useEffect(() => {
    if (draggableHorizontalRef.current) {
      const { removeDraggableEvents } = draggableHorizontalScroll(
        draggableHorizontalRef,
        draggableHorizontalLayerRef,
        horizontalDragCup
      )
      return () => removeDraggableEvents()
    }
  }, [])

  const horizontalScrollHandler: React.UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    tableHeadRef.current?.scrollTo(currentTarget.scrollLeft, 0)
  }

  const { fields: samples } = useFieldArrayFormContext()

  return (
    <>
      <TableHead ref={tableHeadRef} isSticky={isSticky} />
      <HorizontalDragCup ref={horizontalDragCup} />
      <StyledTableBox ref={draggableHorizontalRef} onScroll={horizontalScrollHandler}>
        <HorizontalDragLayer ref={draggableHorizontalLayerRef} />
        <TableBody samples={samples} />
      </StyledTableBox>
    </>
  )
}
