import React from 'react'
import { useMediaQuery } from '@mui/material'

import { Image, Paragraph, StyledBox } from '@styles'
import { Modal } from '@components/shared'
import success from '@assets/images/success.svg'

export const ExportModal: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  return (
    <Modal padding='64px 139px' open onClose={onClose}>
      <StyledBox width='372px'>
        <Image src={success} width='80px' height='80px' mb='16px' component='img' />
        <Paragraph
          variant={isMobile ? 'subtitle1' : 'h3'}
          color='#0F1934'
          align='center'
          mb={'8px'}
        >
          Attachments to the samples are being processed
        </Paragraph>

        <Paragraph color='#495C82' align='center' sx={{ lineHeight: '24px' }}>
          Once we are ready, we will send the email with attachments to you. Please note that link
          will expire in 7 days. If the samples you want to export do not contain any attachments,
          we will not send an email.
        </Paragraph>
      </StyledBox>
    </Modal>
  )
}
