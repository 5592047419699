import React from 'react'
import { useController, RegisterOptions } from 'react-hook-form'
import { Box, useTheme } from '@mui/material'

import { ReactComponent as InfoCircle } from '@assets/icons/info-circle-blue.svg'
import { Input as StyledInput } from '@components/shared'
import { Tooltip } from '@components/shared/Tooltip'

export interface InputProps {
  name: string
  isCopy?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  multiline?: boolean
  rows?: string | number
  placeholder?: string
  defaultValue?: string
  width?: string
  disabled?: boolean
  mb?: string
  mr?: string
  pr?: string
  mrErr?: string
  borderRadius?: string
  borderColor?: string
  type?: string
  internalTooltipDescription?: string
  internalTooltipDescriptionMt?: string
  shouldBuHiddenInternalTooltip?: boolean
  $helperTextNoWrap?: boolean
  isTextAria?: boolean
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  infoIconPosition?: number
}

export const Input: React.FC<InputProps> = React.memo(props => {
  const {
    name,
    isCopy,
    rules,
    placeholder,
    defaultValue,
    disabled,
    type,
    internalTooltipDescription,
    internalTooltipDescriptionMt,
    shouldBuHiddenInternalTooltip,
    $helperTextNoWrap,
    isTextAria,
    onKeyDown,
    multiline = false,
    infoIconPosition = 80,
    ...restProps
  } = props

  const theme = useTheme()
  const { field, fieldState } = useController({ name, rules, defaultValue })
  const { isTouched, error } = fieldState
  const isHighlighting = isCopy && !isTouched

  const inputOnWheel = () => {
    if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
  }

  return (
    <Box sx={{ width: '100%' }}>
      <StyledInput
        multiline={multiline}
        type={type}
        onWheel={inputOnWheel}
        disabled={disabled}
        fullWidth
        height={42}
        error={!!error}
        variant='outlined'
        placeholder={!defaultValue ? placeholder || 'Type here values...' : ''}
        helperText={error && error.message}
        bgColor={isHighlighting ? '#FFF5D6' : '#fff'}
        {...field}
        onKeyDown={e => {
          if (onKeyDown) onKeyDown(e)
          else return
        }}
        value={field.value || +field.value === 0 ? field.value : ''}
        $helperTextNoWrap={$helperTextNoWrap}
        {...restProps}
      />
      {internalTooltipDescription && (
        <Box
          sx={{
            position: 'absolute',
            right: `${infoIconPosition}px`,
            top: internalTooltipDescriptionMt,
            display: 'flex',
          }}
        >
          <Tooltip
            title={internalTooltipDescription}
            shouldBeHidden={shouldBuHiddenInternalTooltip}
          >
            <InfoCircle fill={theme.palette.primary.main} />
          </Tooltip>
        </Box>
      )}
    </Box>
  )
})
