import React from 'react'
import { Box } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { debounce } from 'lodash'

import { selectedFilters as filters } from '@state/filters'
import { useToggle } from '@hooks/common'
import { useAppSelector } from '@hooks/redux'
import { PHYSICAL_FORM_TITLE, selectedFiltersSlice } from '@components/shared/FilterAside/constants'
import { useFetchPhysicalForms } from '@hooks/queries'
import { ParagraphLink } from '@components/shared/ParagraphLink'
import { FilterLoader } from '@components/shared/FilterAside'
import { PHYSICAL_FORM } from '@constants/queries'

import { CardRow, StyledShowMoreLinkBox } from './styles'
import { FilterCheckbox } from '../components/FilterCheckbox'
import { FilterSelectAll } from '../components/FilterSelectAll'
import { SearchFilters } from '../components/SearchFilters'
import { FilterTopTitle } from '../components/FilterTopTitle'

export const PhysicalFormFilter: React.FC = () => {
  const selectedFilters = useAppSelector(filters)
  const [isFilterClose, setIsFilterClose] = useToggle()
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const [isShowMore, setIsShowMore] = React.useState<boolean>(true)

  const { data, isLoading, isFetching } = useFetchPhysicalForms(searchQuery)

  const filterNumb = data ? data.pages[0].length : undefined
  const filterCounter =
    selectedFilters[selectedFiltersSlice.physical_form].filters.length || undefined

  const contentScrollBox = document.getElementById('content-scroll-box')
  const scrollTriggerRef = React.useRef<HTMLDivElement | null>(null)
  const wrapRef = React.useRef<HTMLDivElement | null>(null)

  const queriesListItems = React.useMemo(() => {
    if (data?.pages && data.pages[0]) {
      const items = data.pages[0].map(({ id, name }) => ({ id: `${id}`, name }))
      if (isShowMore) return items.slice(0, 5)
      else return items
    }
    return []
  }, [data, isShowMore])

  React.useEffect(() => {
    if (isShowMore && wrapRef && wrapRef.current && contentScrollBox) {
      // number 63 - it's aside header height
      contentScrollBox.scrollTo({ top: wrapRef.current.offsetTop - 63 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowMore])

  const choiceQuery = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }, [])

  const debounceChoiceQuery = debounce(choiceQuery, 500)

  return (
    <div ref={wrapRef}>
      <FilterTopTitle
        filterNumb={filterNumb}
        isFilterClose={isFilterClose}
        setIsFilterClose={setIsFilterClose}
        titleOfFilter={PHYSICAL_FORM_TITLE}
        filterCounter={filterCounter}
      />
      <Collapse in={!isFilterClose} unmountOnExit>
        <CardRow>
          <SearchFilters searchFiltersHandler={debounceChoiceQuery} />
          <Box sx={{ width: '100%' }}>
            {(isFetching || isLoading) && isShowMore ? (
              <Box width='100%'>
                <FilterLoader />
              </Box>
            ) : (
              <div style={{ paddingBottom: '12px' }}>
                {!queriesListItems.length && !searchQuery && (
                  <FilterSelectAll type={PHYSICAL_FORM} items={queriesListItems} />
                )}

                {queriesListItems.map((item, idx) => {
                  if (idx === queriesListItems.length - 10) {
                    return (
                      <React.Fragment key={item.id}>
                        <div ref={scrollTriggerRef} />

                        <FilterCheckbox type={PHYSICAL_FORM} item={item} />
                      </React.Fragment>
                    )
                  }
                  return <FilterCheckbox type={PHYSICAL_FORM} item={item} key={`${item.id}`} />
                })}
                {data && data.pages[0].length > 5 && (
                  <StyledShowMoreLinkBox>
                    <ParagraphLink
                      sx={{ padding: '11px 24px 12px' }}
                      onClick={() => setIsShowMore(!isShowMore)}
                    >
                      Show {isShowMore ? 'more' : 'less'}
                    </ParagraphLink>
                  </StyledShowMoreLinkBox>
                )}
              </div>
            )}
          </Box>
        </CardRow>
      </Collapse>
    </div>
  )
}
