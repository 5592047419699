import React from 'react'
import { useForm } from 'react-hook-form'

import { SampleOptionsContext } from '@context'
import { TableLoader } from '@components/modules/SampleCreation/components'
import { CopySample } from '@components/modules'

import { PageHeader, Table, ErrorNotification, PageExitModalWrapper } from './components'

export const SampleCreate: React.FC = () => {
  const draggableHorizontalRef = React.useRef<HTMLDivElement | null>(null)

  const { isLoading: isLoadingOptions } = React.useContext(SampleOptionsContext)
  const { control, handleSubmit, clearErrors, setError } = useForm({ mode: 'onChange' })

  return (
    <>
      <PageHeader handleSubmit={handleSubmit} setError={setError} />
      {isLoadingOptions ? (
        <div style={{ marginTop: '53px' }}>
          <TableLoader />{' '}
        </div>
      ) : (
        <>
          <ErrorNotification draggableHorizontalRef={draggableHorizontalRef} />
          <Table
            draggableHorizontalRef={draggableHorizontalRef}
            control={control}
            clearErrors={clearErrors}
          />
          <PageExitModalWrapper />
          {/* Modal for copy sample */}
          <CopySample />
        </>
      )}
    </>
  )
}
