import { ITaskApiResult, UpdateTandemResultTypes } from '@models/tasks'
import {
  ATTACHMENTS,
  DELETED_RECOGNIZED,
  RESULT_RECOGNIZED,
  SUBSTANCES,
  TEST_NOTES,
  TEST_PREPARATION,
} from '@constants/tests'

export const tandemMsData2Send = (
  formData: UpdateTandemResultTypes,
  substance_result?: ITaskApiResult[]
) => {
  const initialSubstanceId = substance_result?.map(i => i.id)
  const dataId = formData.substances.map(i => i.itemId)
  const deletedSubstanceIds = initialSubstanceId?.filter(item => !dataId.includes(item))

  const isResultIsNotRecognized = formData.result_is_not_recognized

  const attachments = [...formData.equipment_number, ...formData.test_info]

  const updatedSubstance = formData.substances.map((item: ITaskApiResult) => {
    const { itemId, ...rest } = item

    return {
      ...rest,
      id: item.itemId,
      clinically_meaningful: item.clinically_meaningful?.key,
      concentration_type: item.concentration_type?.key || item.concentration_type,
    }
  })

  const updatedFormData = {
    [ATTACHMENTS]: attachments,
    [TEST_NOTES]: formData.test_notes,
    [TEST_PREPARATION]: formData.preparation,
    [SUBSTANCES]: isResultIsNotRecognized ? [] : updatedSubstance,
    [RESULT_RECOGNIZED]: formData.result_is_not_recognized,
  }

  const data2send = (() => {
    if (!updatedSubstance.length && !isResultIsNotRecognized) {
      // if  user deleted all substances and didn't check isResultIsNotRecognized with checkbox
      return {
        ...updatedFormData,
        [RESULT_RECOGNIZED]: true,
      } as UpdateTandemResultTypes
    } else if (!!deletedSubstanceIds?.length && !isResultIsNotRecognized)
      return {
        ...updatedFormData,
        [DELETED_RECOGNIZED]: deletedSubstanceIds,
      } as UpdateTandemResultTypes
    else return updatedFormData as UpdateTandemResultTypes
  })()

  return data2send
}
