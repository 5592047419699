import { useMutation, useQueryClient } from 'react-query'

import { updateAttachments } from '@api/attachments'
import { ATTACHMENTS } from '@constants/queries'
import { IAttachmentResultData, IAttachments, PutAttachmentsTypes } from '@models/attachments'
import { IError } from '@models/common/app'

export const useUpdateAttachments = (object_id: number) => {
  const client = useQueryClient()

  const mutation = useMutation<IAttachmentResultData, IError, PutAttachmentsTypes>(
    updateAttachments,
    {
      onSuccess: () => {
        client.setQueryData<IAttachments | undefined>([ATTACHMENTS, object_id], data => data)
        client.invalidateQueries([ATTACHMENTS, object_id])
      },
      retry: 1,
      retryDelay: 1500,
    }
  )

  return mutation
}
