import { Box, Typography } from '@mui/material'

import logoPad from '@assets/icons/footer-logo-pad.svg'
import logo from '@assets/icons/footer-logo.svg'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'

import { FooterBox, LogoImg } from './styles'

const linkSX = {
  color: '#182A62',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    color: '#505C88',
  },
  '&:visited': {
    color: '#182A62',
  },
}

interface FooterProps {
  position?: string
  isFooterHidden?: boolean
}

export const Footer: React.FC<FooterProps> = ({ position, isFooterHidden }) => {
  const { isTenantType } = useTenant()
  return (
    <FooterBox position={position} isFooterHidden={isFooterHidden}>
      <LogoImg className='footer-logo-pad' src={logoPad} alt='logo PAD project' />
      {isTenantType(TENANT_TYPE.streetDrugs) ? (
        <Typography>{new Date().getFullYear()}. All rights reserved</Typography>
      ) : (
        <Typography fontSize={'15px'}>
          Copyright &copy; {new Date().getFullYear()} ARTiFACTS.{' '}
          <Box
            component={'a'}
            href='https://artifacts.ai/privacy-policy/'
            target='_blank'
            sx={linkSX}
          >
            Privacy Policy
          </Box>{' '}
          and{' '}
          <Box
            component={'a'}
            href='https://artifacts.ai/terms-of-use/'
            target='_blank'
            sx={linkSX}
          >
            Terms and Conditions
          </Box>
        </Typography>
      )}
      <LogoImg src={logo} alt='logo' />
    </FooterBox>
  )
}
