import React from 'react'

import { TableColumnHeader } from '@components/shared'
import { StyledTableTitle, FixedTableCell } from '@styles'
import { IColumnHeader } from '@models/common/ui'

interface ColumnHeadersProps {
  content: IColumnHeader[]
  hideBorder: boolean
}

export const ColumnHeaders: React.FC<ColumnHeadersProps> = React.memo(({ content, hideBorder }) => {
  return (
    <>
      {content.map(({ id, ...props }, idx) => {
        return <TableColumnHeader key={id} {...props} />
      })}
      <FixedTableCell
        padding='16px 30px'
        hideBorder
        header
        sx={{
          borderTopLeftRadius: '10px',
          boxShadow: '-7px -4px 20px -5px rgba(53, 108, 151, 0.12)',
        }}
      >
        <StyledTableTitle>Action</StyledTableTitle>
      </FixedTableCell>
    </>
  )
})
