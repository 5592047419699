import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import React from 'react'

import { useToggle } from '@hooks/common'
import { protectedRoutes } from '@configs/routes.config'
import { selectSamplesChanged, setIsChangedField } from '@state/samples'
import { useAppSelector } from '@hooks/redux'
import { ConfirmationModal } from '@components/shared'

import { Table } from './components'

export const CreateSamplesContent = () => {
  // * [ check is any fields was changed
  const isChangedFieldFromStore = useAppSelector(selectSamplesChanged)
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    formState: { touchedFields },
  } = useFormContext()

  const [showModal, setShowModal] = useToggle()
  const [isChangedAnyField, setIsChangedAnyField] = React.useState(false)

  const isChangedField =
    !isChangedFieldFromStore && touchedFields?.sampleCreation
      ? !!touchedFields?.sampleCreation.length
      : false

  const goBackConfirmation = () => {
    history.replace({
      pathname: protectedRoutes.samples.path,
    })
    setIsChangedAnyField(false)
    dispatch(setIsChangedField(false))
  }

  const onBackButtonEvent = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    history.replace({
      state: [
        {
          path: protectedRoutes.samples.path,
          url: protectedRoutes.samples.path,
          title: 'Sample list',
        },
      ],
    })

    if (isChangedAnyField) {
      setShowModal()
    } else {
      history.goBack()
    }
  }

  React.useEffect(() => {
    window.history.pushState(null, protectedRoutes.samples.path, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)

    if (isChangedField) {
      setIsChangedAnyField(true)
      dispatch(setIsChangedField(true))
    }

    if (isChangedAnyField) {
      window.onbeforeunload = () => {
        return 'Are you sure you want to cancel sample creation?'
      }
    }

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
      window.onbeforeunload = () => {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedField, isChangedAnyField])
  // * check is any fields was changed ]

  return (
    <>
      <Table />

      {showModal && (
        <ConfirmationModal
          title={'Are you sure you want to cancel sample creation?'}
          cancelBtnText='Continue creation'
          confirmButtonText='Cancel'
          goBackModal
          onClose={setShowModal}
          onConfirm={goBackConfirmation}
        />
      )}
    </>
  )
}
