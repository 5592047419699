import { useTheme } from '@mui/material'

export const CustomizeTableColumnsIcon = () => {
  const theme = useTheme()

  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='19.9999' cy='19.9987' r='17.1667' fill='white' stroke='#E5E5E6' />
      <path
        d='M25.25 27.5C26.0784 27.5 26.75 26.8284 26.75 26C26.75 25.1716 26.0784 24.5 25.25 24.5C24.4216 24.5 23.75 25.1716 23.75 26C23.75 26.8284 24.4216 27.5 25.25 27.5Z'
        fill={theme.palette.primary.main}
      />
      <path
        d='M31.0828 25.6087C30.6193 24.4281 29.8197 23.4095 28.7829 22.679C27.7461 21.9486 26.5177 21.5384 25.25 21.4994C23.9823 21.5384 22.7539 21.9486 21.7171 22.679C20.6803 23.4095 19.8807 24.4281 19.4173 25.6087L19.25 25.9994L19.4173 26.3902C19.8807 27.5708 20.6803 28.5894 21.7171 29.3199C22.7539 30.0503 23.9823 30.4604 25.25 30.4994C26.5177 30.4604 27.7461 30.0503 28.7829 29.3199C29.8197 28.5894 30.6193 27.5708 31.0828 26.3902L31.25 25.9994L31.0828 25.6087ZM25.25 28.9994C24.6567 28.9994 24.0766 28.8235 23.5833 28.4938C23.0899 28.1642 22.7054 27.6957 22.4784 27.1475C22.2513 26.5993 22.1919 25.9961 22.3076 25.4142C22.4234 24.8322 22.7091 24.2977 23.1287 23.8781C23.5482 23.4586 24.0828 23.1728 24.6647 23.0571C25.2467 22.9413 25.8499 23.0007 26.3981 23.2278C26.9462 23.4549 27.4148 23.8394 27.7444 24.3327C28.0741 24.8261 28.25 25.4061 28.25 25.9994C28.249 26.7948 27.9326 27.5573 27.3702 28.1197C26.8078 28.6821 26.0453 28.9984 25.25 28.9994ZM17.2993 23.5994C16.6858 23.1391 16.2001 22.5296 15.8884 21.8288C15.5767 21.128 15.4493 20.3592 15.5182 19.5953C15.5872 18.8314 15.8503 18.0979 16.2825 17.4642C16.7147 16.8306 17.3017 16.318 17.9877 15.975C18.6737 15.632 19.4361 15.4701 20.2023 15.5045C20.9685 15.539 21.7132 15.7688 22.3656 16.172C23.0181 16.5752 23.5567 17.1385 23.9302 17.8083C24.3038 18.4782 24.4999 19.2324 24.5 19.9994H23C23 19.4881 22.8693 18.9852 22.6203 18.5385C22.3712 18.0919 22.0122 17.7163 21.5772 17.4475C21.1422 17.1786 20.6457 17.0255 20.1348 17.0025C19.624 16.9795 19.1157 17.0875 18.6584 17.3162C18.201 17.5448 17.8096 17.8867 17.5215 18.3091C17.2334 18.7316 17.058 19.2207 17.0121 19.73C16.9662 20.2393 17.0512 20.7519 17.2591 21.2191C17.467 21.6863 17.7909 22.0926 18.2 22.3994L17.2993 23.5994Z'
        fill={theme.palette.primary.main}
      />
      <path
        d='M29.9786 16.283L28.2086 13.217C28.0355 12.9163 27.7633 12.6849 27.4386 12.5625C27.1139 12.4401 26.7567 12.4342 26.4281 12.5457L24.6026 13.1638C24.288 12.9519 23.9593 12.762 23.6186 12.5952L23.2406 10.7053C23.1725 10.3653 22.9888 10.0595 22.7206 9.8398C22.4525 9.62007 22.1165 9.5 21.7699 9.5H18.2299C17.8831 9.50004 17.5471 9.62022 17.2789 9.84009C17.0108 10.06 16.8271 10.366 16.7591 10.706L16.3811 12.5945C16.0366 12.7597 15.7041 12.9486 15.3859 13.16L13.5709 12.5457C13.2425 12.4346 12.8857 12.4407 12.5613 12.5631C12.2369 12.6855 11.965 12.9166 11.7919 13.217L10.0219 16.283C9.84849 16.5832 9.78444 16.9342 9.84064 17.2763C9.89684 17.6184 10.0698 17.9305 10.3301 18.1595L11.7784 19.4323C11.7656 19.6213 11.7499 19.8088 11.7499 20C11.7499 20.1935 11.7574 20.3848 11.7709 20.5745L10.3309 21.8405C10.0703 22.0693 9.89706 22.3813 9.84059 22.7234C9.78411 23.0655 9.84791 23.4166 10.0211 23.717L11.7911 26.783C11.9643 27.0837 12.2364 27.3151 12.5612 27.4375C12.8859 27.5599 13.243 27.5658 13.5716 27.4543L15.3971 26.8363C15.7116 27.0485 16.0404 27.2387 16.3811 27.4055L16.7584 29.294C16.8264 29.6342 17.0102 29.9403 17.2785 30.1601C17.5468 30.38 17.883 30.5001 18.2299 30.5H19.2499V29H18.2299L17.6974 26.3375C16.9607 26.0639 16.2769 25.665 15.6761 25.1585L13.0909 26.033L11.3209 22.967L13.3646 21.1708C13.2248 20.3952 13.223 19.601 13.3594 18.8247L11.3209 17.0323L13.0909 13.967L15.6611 14.837C16.2662 14.3298 16.9554 13.9323 17.6974 13.6625L18.2299 11H21.7699L22.3024 13.6625C23.039 13.9362 23.7228 14.335 24.3236 14.8415L26.9089 13.967L28.6789 17.033L26.5804 18.872L27.5689 20L29.6689 18.1595C29.9294 17.9307 30.1027 17.6187 30.1592 17.2766C30.2156 16.9345 30.1518 16.5834 29.9786 16.283Z'
        fill={theme.palette.primary.main}
      />
    </svg>
  )
}
