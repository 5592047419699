import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import { IoClose } from 'react-icons/io5'
import {
  Collapse,
  styled,
  IconButton,
  Typography,
  ButtonUnstyled,
  CollapseProps,
  alpha,
  IconButtonProps,
} from '@mui/material'

interface StyledNotificationProps extends CollapseProps {
  isAuthorized: boolean
  $isMobile?: boolean
}

const styledNotificationConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'isAuthorized':
      case '$isMobile':
        return false
      default:
        return true
    }
  },
}

export const StyledNotification = styled<React.FC<StyledNotificationProps>>(
  Collapse,
  styledNotificationConfig
)`
  position: fixed;
  top: ${({ isAuthorized, $isMobile, theme }) =>
    isAuthorized ? ($isMobile ? '' : `${theme.header.height + 40}px`) : '50px'};
  bottom: ${({ $isMobile }) => ($isMobile ? '29px' : '')};
  right: ${({ $isMobile }) => ($isMobile ? '20px' : '80px')};
  box-shadow: 1px 3px 20px rgba(229, 233, 241, 0.8);
  z-index: 500;

  & .MuiPaper-root {
    border-radius: 10px;
  }

  & .MuiAlert-icon {
    display: none;
  }

  & .MuiAlert-message {
    width: ${({ $isMobile }) => ($isMobile ? '260px' : '366px')};
    padding: 20px;
  }
  & .MuiAlert-action {
    padding: 0;
  }
`

export const TitleBox = styled('div')`
  display: flex;
`

export const NotificationIcon = styled('img')`
  align-items: self-end;
  width: 20px;
  height: 20px;
`

interface StyledIconButtonProps extends IconButtonProps {
  statusType: 'success' | 'error'
}

const styledIconConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'statusType':
        return false
      default:
        return true
    }
  },
  skipSx: true,
}

export const StyledIconButton = styled<React.FC<StyledIconButtonProps>>(
  IconButton,
  styledIconConfig
)`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 2px 2px 8px rgba(71, 93, 168, 0.2);
  position: absolute;
  top: -10px;
  left: -10px;

  &:hover {
    background-color: ${({ statusType, theme }) =>
      alpha(statusType === 'error' ? theme.palette.error.light : theme.palette.success.light, 0.6)};

    & > svg {
      fill: ${({ theme }) => theme.palette.common.white};
    }
  }

  &:target {
    display: none;
  }
`

export const IconClose = styled(IoClose)`
  width: 20px;
  height: 20px;
`

export const StyledTitle = styled(Typography)`
  margin-left: 10px;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #41495a;
`

export const StyledText = styled(Typography)`
  margin-left: 32px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #8f929b;
`

export const StyledButton = styled(ButtonUnstyled)`
  display: block;
  margin-left: auto;
  padding: 8px 26px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
  background: ${({ theme }) => theme.palette.common.white};
  border: transparent;
  border-radius: 4px;
  cursor: pointer;
`
