import React from 'react'

import { InconclusiveResult, PosNegResult } from '@components/shared/Hr'
import { INCONCLUSIVE } from '@constants/tasks'
import { IHrTaskLab } from '@models/tasks'

import * as Styled from '../../styles'

interface ResultsProps {
  task: IHrTaskLab
}

export const Results: React.FC<ResultsProps> = ({ task }) => {
  const resultOfTest = task?.result_name?.toLocaleLowerCase()

  return (
    <>
      {task?.substance_result?.map(({ name }, idx) => (
        <React.Fragment key={idx}>
          {resultOfTest === INCONCLUSIVE ? (
            <Styled.DetailsBox mb='32px'>
              <InconclusiveResult testTypeName={task.test_type?.name} />
            </Styled.DetailsBox>
          ) : (
            <Styled.DetailsBox mb='32px'>
              <PosNegResult
                name={name}
                resultOfTest={task?.result_name}
                short={false}
                widthName='216px'
                widthResult='90%'
              />
            </Styled.DetailsBox>
          )}
        </React.Fragment>
      ))}
    </>
  )
}
