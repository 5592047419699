import React from 'react'
import { TableCell } from '@mui/material'

import { ISampleTask, ISampleTasks } from '@models/samples'
import { ContentType } from '@models/common/ui'
import { FAILED, RETEST_AT_ND } from '@constants/tasks'
import { useAppSelector } from '@hooks/redux'
import { selectUserCustomizeTableColumns } from '@state/user'
import { viewAdditionalTasksWidths } from '@constants/mappings'
import { ITableColumnWidths } from '@models/common/user'
import { REPORTS } from '@constants/queries'

import { ExpandedTableCells } from '../ExpandedTableCells'
import { StyledTableBody } from './styles'
import { StyledRow, FixedSampleTaskCell, StyledOptionalRow } from '../styles'
import { ExtraButton } from '../ExtraButton'

interface ExpandedTableBodyProps {
  contentType: ContentType
  tasks?: ISampleTasks
  sampleId: number
  labId?: number
  referredWidth?: string
  greatestTasksAmount?: number
  splitStatus?: string
  isFetchingSamples?: boolean
  isAbleToCreateTask?: boolean
  tasksIsLoading: boolean
  tasksIsFetching: boolean
}

export const ExpandedTableBody: React.FC<ExpandedTableBodyProps> = props => {
  const {
    contentType,
    tasks,
    sampleId,
    greatestTasksAmount,
    referredWidth,
    labId,
    tasksIsLoading,
    tasksIsFetching,
    isFetchingSamples,
    isAbleToCreateTask,
    splitStatus,
  } = props
  const { task_columns } = useAppSelector(selectUserCustomizeTableColumns)
  const stepCounter = tasks?.length
  const checkingTask = tasks ? [...tasks] : []

  const updatedTasks = checkingTask.map(task => {
    if (task.needs_retesting) {
      const taskWithNewTestResult: ISampleTask = {
        ...task,
        result: {
          key: FAILED,
          value: RETEST_AT_ND,
        },
      }
      return taskWithNewTestResult
    } else {
      return task
    }
  })

  // Change another tests positions for the tasks view
  const sortedUpdatedTasks = React.useMemo(() => {
    const withoutOtherTests = updatedTasks.filter(task => !task.test_type.is_other)
    const withOtherTests = updatedTasks.filter(task => task.test_type.is_other)
    return [...withoutOtherTests, ...withOtherTests]
  }, [updatedTasks])

  const filteredSampleTableWidths = task_columns
    .filter(taskColumn => taskColumn.activated)
    .map(column =>
      viewAdditionalTasksWidths.find(cell => cell.id === column.id)
    ) as ITableColumnWidths

  return (
    <>
      <StyledTableBody $contentType={contentType}>
        {sortedUpdatedTasks?.map((task: ISampleTask, idx: number) => (
          <React.Fragment key={task.id}>
            <StyledRow
              sx={{ width: referredWidth ? referredWidth : '' }}
              columns={filteredSampleTableWidths}
              contentType={contentType}
            >
              <ExpandedTableCells
                labId={labId}
                key={task.id}
                contentType={contentType}
                task={task}
                idx={idx}
                isSamples
                stepCounter={stepCounter ? stepCounter : 1}
                sampleId={sampleId}
                canDeleteTask={task.is_removable && contentType !== REPORTS}
                isAbleToAddTask={true}
                isFetchingSamples={isFetchingSamples}
              />
            </StyledRow>
          </React.Fragment>
        ))}
        {contentType !== REPORTS && !tasksIsLoading && (
          <StyledOptionalRow>
            <TableCell>
              <ExtraButton
                labId={labId}
                isFetchingSamples={isFetchingSamples}
                greatestTasksAmount={greatestTasksAmount}
                tasks={sortedUpdatedTasks}
                sampleId={sampleId}
                splitStatus={splitStatus}
                tasksIsFetching={tasksIsFetching}
                isAbleToCreateTask={isAbleToCreateTask}
              />
            </TableCell>

            <FixedSampleTaskCell
              sx={{
                borderBottom: 'none',
                boxShadow: '-5px 9px 20px -5px rgba(53, 108, 151, 0.12)',
              }}
            ></FixedSampleTaskCell>
          </StyledOptionalRow>
        )}
      </StyledTableBody>
    </>
  )
}
