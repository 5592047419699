import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { Typography, useMediaQuery } from '@mui/material'
import React from 'react'

import { ReactComponent as InfoIcon } from '@assets/icons/info.svg'
import { Breadcrumbs, ParagraphLink, Tooltip } from '@components/shared'
import { IBreadcrumbsRoute } from '@models/common/app'
import { isContainRoute } from '@helpers/routes'
import { HrTestMethod } from '@models/tasks'
import { useAppDispatch } from '@hooks/redux'
import { setDataAndPosition } from '@state/common/tooltipState'
import { DrugsModal } from '@components/shared/Hr/TandemMS'
import { isOtherMethod } from '@components/shared/Hr/TandemMS/helpers'

import { StyledHeaderBox, StyledTandemMSChip } from './styles'

interface ITestParams {
  taskId: string
  testType: string
}

interface PageHeaderProps {
  isTandemMsTest?: boolean
  tandemMsMethod?: HrTestMethod
}

export const PageHeader: React.FC<PageHeaderProps> = props => {
  const { isTandemMsTest, tandemMsMethod } = props

  const { taskId } = useParams<ITestParams>()
  const isMobile = useMediaQuery('(max-width:767px)')
  const history = useHistory()
  const { state } = useLocation<IBreadcrumbsRoute[]>()
  const { path, url } = useRouteMatch()
  const currentPageTask = `Task ${taskId}`
  const dispatch = useAppDispatch()
  const [openModal, setOpenModal] = React.useState(false)

  const currentPageTitle = (() => {
    const title = state?.find(i => i.testTitle)
    return title?.testTitle || ''
  })()

  React.useEffect(() => {
    if (state && !isContainRoute(state, url)) {
      history.replace({ state: [...state, { path, url, title: currentPageTask }] })
    }
  }, [currentPageTask, history, path, state, url])
  const tandemMsMethodText = !tandemMsMethod?.name ? 'Not specified' : tandemMsMethod.name

  const tooltipTitle = React.useMemo(() => {
    if (tandemMsMethod?.name) {
      return (
        <>
          <Typography m='0' fontSize='13px'>
            <span style={{ fontWeight: 500 }}>{tandemMsMethod.name}</span> (
            {tandemMsMethod.description})
          </Typography>
          <ParagraphLink
            fontSize='13px'
            width='fit-content'
            onClick={() => {
              dispatch(setDataAndPosition(null)) //hide tooltip
              setOpenModal(true)
            }}
          >
            List of supported drugs
          </ParagraphLink>
        </>
      )
    } else
      return (
        <Typography m='0' fontSize='13px'>
          <Typography m='0' fontSize='13px' fontWeight={500}>
            Method was not specified by manager
          </Typography>
          Responsibility for method selection lies with you.
        </Typography>
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tandemMsMethod?.description, tandemMsMethod?.name])

  return (
    <>
      <StyledHeaderBox>
        <Typography variant={isMobile ? 'h3' : 'h1'}>{currentPageTitle}</Typography>

        {isTandemMsTest && (
          <Tooltip
            isPureTooltip
            title={tooltipTitle}
            maxWidth={200}
            shouldBeHidden={isOtherMethod(tandemMsMethod?.name) || false}
          >
            <StyledTandemMSChip>
              {tandemMsMethodText}
              {!isOtherMethod(tandemMsMethod?.name) && <InfoIcon style={{ marginLeft: '4px' }} />}
            </StyledTandemMSChip>
          </Tooltip>
        )}
      </StyledHeaderBox>

      {tandemMsMethod ? (
        <DrugsModal open={openModal} method={tandemMsMethod} onClose={() => setOpenModal(false)} />
      ) : null}
      <Breadcrumbs mb={isMobile ? '40px' : '48px'} />
    </>
  )
}
