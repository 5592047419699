import React from 'react'
import { useMediaQuery } from '@mui/material'

import { AttachedMaterialItem } from '@components/shared'
import {
  TRUNCATED_ATTACHED_MODAL_MOBILE_TEXT,
  TRUNCATED_ATTACHED_MODAL_TEXT,
} from '@constants/common'
import { StyledPageSubTitle } from '@styles'
import { IAttachment } from '@models/attachments'

import { AttachedMaterialsListPropTypes } from '../pureAttachments.types'

export const AttachedMaterialsList: React.FC<AttachedMaterialsListPropTypes> = props => {
  const { attachments, removeAttachment } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  return (
    <>
      {!!attachments.length && (
        <StyledPageSubTitle sx={{ marginBottom: '16px' }} variant='body1'>
          Attached materials
        </StyledPageSubTitle>
      )}

      {attachments.map((attachment: IAttachment) => (
        <AttachedMaterialItem
          key={attachment.id}
          truncatedTextLength={
            isMobile ? TRUNCATED_ATTACHED_MODAL_MOBILE_TEXT : TRUNCATED_ATTACHED_MODAL_TEXT
          }
          attachment={attachment}
          removeAttachment={removeAttachment}
        />
      ))}
    </>
  )
}
