import React from 'react'

import { ISample } from '@models/samples'
import { StyledTableSampleCell, SubText } from '@styles'

interface DosageProps {
  sample: ISample
  isMobile?: boolean
}

export const Dosage: React.FC<DosageProps> = ({ sample, isMobile }) => {
  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      {parseFloat(sample.dosage)}
      <SubText sx={{ marginLeft: '8px', lineHeight: '20px', fontSize: 14 }}>
        {sample.dosage_type?.name || ''}
      </SubText>
    </StyledTableSampleCell>
  )
}
