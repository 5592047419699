import React from 'react'

import { IGroup } from '@models/common/app'
import { useAddAnotherMultipleTests } from '@hooks/queries/useAddAnotherMultipleTests'

export const ComponentForAnotherTestsCheck: React.FC<{
  anotherData: IGroup[]
  continueCreating: (data: any[]) => void
}> = ({ anotherData, continueCreating }) => {
  const createFlagRef = React.useRef<boolean>(false)
  const result = useAddAnotherMultipleTests(anotherData)

  if (
    !result.some(test => test.error) &&
    !result.some(test => !test.data) &&
    !createFlagRef.current
  ) {
    continueCreating(result.map(test => test.data))
    createFlagRef.current = true
  }

  return null
}
