import { styled, Typography } from '@mui/material'

export const StyledTypography = styled(Typography)`
  margin-bottom: 2px;

  & .MuiTypography-body2 {
    display: block;
    color: #616d84;
  }

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`
