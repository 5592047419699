import React from 'react'
import { Box, Icon, Typography, useTheme } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { getApisWithType } from '@helpers/ui/common'
import { ExpandableApis, ExpandableExcipients, MobileDetailsCard } from '@components/shared'
import { unitIconSDeterminer } from '@helpers/ui/typeOfUnits'
import { TemperatureField } from '@components/shared/TemperatureSensitiveToxicityFields/TemperatureField'
import { StorageNotes } from '@components/shared/TemperatureSensitiveToxicityFields/StorageNotes'
import { ToxicityField } from '@components/shared/TemperatureSensitiveToxicityFields'

interface MainInfoMobileProps {
  data?: ITaskLab
}

export const MainInfoMobile: React.FC<MainInfoMobileProps> = ({ data }) => {
  const theme = useTheme()
  const { mainApisWithType } = getApisWithType({ main_apis: data?.main_apis })

  const UnitIconType = unitIconSDeterminer(data?.unit_type.id || 1)
  const dosage = data?.dosage && parseFloat(data?.dosage)

  const taskInfo = [
    { id: 1, title: 'Drug class', data: data?.drug_class.name },
    { id: 2, title: 'Dosage', data: `${dosage} ${data?.dosage_type?.name}` },
    {
      id: 3,
      title: 'Units',
      data: (
        <Typography component='span' sx={{ display: 'flex' }}>
          {data?.units}
          {data?.unit_type?.id ? (
            <>
              <Typography
                component='span'
                marginLeft='8px'
                variant='body1'
                color='secondary.light'
                fontSize='14px'
              >
                {data?.unit_type.name.toLocaleLowerCase()} {data?.unit_type.description}
              </Typography>
              <Icon sx={{ marginLeft: '5px' }}>
                <UnitIconType
                  fill={theme.palette.primary.main}
                  stroke={theme.palette.primary.main}
                />
              </Icon>
            </>
          ) : (
            '-'
          )}
        </Typography>
      ),
    },
    {
      id: 4,
      title: 'Main APIs',
      data: (
        <ExpandableApis
          bgColor='transparent'
          borderBottom='none'
          apis={mainApisWithType}
          direction='column'
        />
      ),
      isDataTypeComponent: true,
    },
    {
      id: 5,
      title: 'Excipients',
      data:
        data && data.excipients.length ? (
          <ExpandableExcipients
            bgColor='transparent'
            borderBottom='none'
            excipients={data.excipients}
            direction='column'
          />
        ) : (
          '-'
        ),
      isDataTypeComponent: true,
    },
    {
      id: 7,
      title: 'Toxicity',
      data: (
        <ToxicityField toxicityLevelValue={data?.toxicity_level?.value} tooltipPlacement='top' />
      ),
      isDataTypeComponent: true,
      isComponentHidden: !data?.toxicity_level?.value,
    },
    {
      id: 8,
      title: 'Storage temperature',
      data: (
        <TemperatureField
          minTemperature={data?.min_temperature}
          maxTemperature={data?.max_temperature}
          temperatureMeasurementFormatKey={data?.temperature_measurement_format.key}
        />
      ),
      isDataTypeComponent: true,
      isComponentHidden: !data?.min_temperature && !data?.max_temperature,
    },
    {
      id: 9,
      title: 'Storage notes',
      data: <StorageNotes storageNotes={data?.storage_notes} />,
      isDataTypeComponent: true,
      isComponentHidden: !data?.storage_notes?.length,
    },
    {
      id: 10,
      title: 'Regulatory regist. status',
      data: data?.regulatory_registration_status.name,
    },
  ]

  return (
    <Box pb='12px'>
      {taskInfo.map(({ id, title, isDataTypeComponent, data, isComponentHidden }) => (
        <MobileDetailsCard
          key={id}
          title={title}
          isDataTypeComponent={isDataTypeComponent}
          data={data}
          minWidth='159px'
          isComponentHidden={isComponentHidden}
        />
      ))}
    </Box>
  )
}
