// ! IYourWorkspaceTabValue - check naming for BE
export type IYourWorkspaceTabValue = 'contact_info' | 'users'

export type IYourWorkspaceTabLabel = 'Contact info' | 'Users'

export interface IYourWorkspaceTab {
  value: IYourWorkspaceTabValue
  label: IYourWorkspaceTabLabel
}

export interface IErrorsEditLaboratory {
  phone_numbers_0: string[]
  phone_numbers_1: string[]
  phone_numbers_2: string[]
  phone_numbers: string[]
  emails: string[]
  skills: string[]
}

export const phoneNumbersErrorKeys = ['phone_numbers_0', 'phone_numbers_1', 'phone_numbers_2']

export interface IErrorsEditUser {
  phone_number: string[]
  email: string[]
}
