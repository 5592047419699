import {
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
  AccordionDetailsProps,
  AccordionSummaryProps,
  Box,
} from '@mui/material'
import { StyledConfig } from 'styled-components'
import { BoxProps, MuiStyledOptions } from '@mui/system'

const styledAccordionConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'bgColor':
        return false
      default:
        return true
    }
  },
}

const styledAccordionSummaryConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'direction':
        return false
      default:
        return true
    }
  },
}

const styledAccordionDetailsConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'borderBottom':
      case 'direction':
      case 'isOptionalApis':
        return false
      default:
        return true
    }
  },
}

interface StyledAccordionProps extends AccordionProps {
  bgColor?: string
}

interface StyledAccordionDetailsProps extends AccordionDetailsProps {
  borderBottom?: string
  direction: 'row' | 'column'
  isOptionalApis?: boolean
  $marginBottom?: string
}

export const StyledAccordion = styled<React.FC<StyledAccordionProps>>(
  Accordion,
  styledAccordionConfig
)`
  border-color: transparent;
  background-color: ${({ theme, bgColor }) => bgColor || 'inherit'};
  margin: 0;
  padding: 0;
  border-bottom: none;

  &.Mui-expanded {
    margin: 0;
  }

  &.MuiAccordion-root.MuiAccordion-rounded {
    box-shadow: none;
  }

  &:not(:last-child) {
    border-bottom: 0;
  }

  &:before {
    display: none;
  }

  & .MuiCollapse-entered {
    background-color: ${({ theme, bgColor }) => bgColor || theme.palette.common.white};
    width: fit-content;
  }
`

interface StyledAccordionSummaryProps extends AccordionSummaryProps {
  direction: 'row' | 'column'
  $flexGrow?: string
}

export const StyledAccordionSummary = styled<React.FC<StyledAccordionSummaryProps>>(
  AccordionSummary,
  styledAccordionSummaryConfig
)`
  display: inline-flex;
  justify-content: space-evenly;
  min-width: fit-content;
  flex-direction: ${({ direction }) => direction};
  align-items: flex-start;

  margin: 0;
  padding: 0;
  border: transparent;

  & .MuiAccordionSummary-expandIconWrapper {
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    margin: 0;
    padding: 0;
    transform: none;
  }

  & .MuiAccordionSummary-content {
    flex-direction: ${({ direction }) => direction};
    margin: 0;
    padding: 0;
    cursor: default;
    flex-grow: ${({ $flexGrow }) => ($flexGrow ? $flexGrow : 1)};
  }
`

export const StyledAccordionDetails = styled<React.FC<StyledAccordionDetailsProps>>(
  AccordionDetails,
  styledAccordionDetailsConfig
)`
  display: ${({ direction }) => (direction === 'column' ? '' : 'flex')};
  flex-wrap: wrap;
  margin: 0.2;
  padding: 0;
  border-bottom: ${({ theme, borderBottom }) =>
    borderBottom || `4px solid ${theme.palette.background.default}`};

  & div:first-child {
    margin-bottom: ${({ direction, $marginBottom }) =>
      direction === 'column' && !$marginBottom ? '3px' : ''};
  }

  & div:not(:first-child) {
    margin-bottom: ${({ direction, $marginBottom }) =>
      direction === 'column' && !$marginBottom ? '3px' : ''};
  }
`

interface StyledBoxProps extends BoxProps {
  $direction?: string
}

export const StyledBox = styled<React.FC<StyledBoxProps>>(Box)`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ $direction }) => ($direction === 'column' ? '6px' : '')};
`
