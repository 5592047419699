import React from 'react'

import { CopySampleButton } from './CopySampleButton'
import { DeleteSampleButton } from './DeleteSampleButton'
import { FixedTableCell } from './styles'

interface ActionProps {
  sampleIdx: number
  isLastSample?: boolean
}

export const Action: React.FC<ActionProps> = React.memo(({ sampleIdx, isLastSample }) => {
  const actionButtons = [CopySampleButton, DeleteSampleButton]

  return (
    <FixedTableCell isLastSample={isLastSample}>
      {actionButtons.map((Button, idx) => (
        <Button key={idx} sampleIdx={sampleIdx} />
      ))}
    </FixedTableCell>
  )
})
