import { ExpandableApis } from '@components/shared'
import { getApisWithType } from '@helpers/ui/common'
import { ISample } from '@models/samples'

import { StyledTableCell } from '../../styles'

interface APIsCellProps {
  sample: ISample
}

export const APIsCell: React.FC<APIsCellProps> = ({ sample }) => {
  const { main_apis, optional_apis } = sample

  const { allApisWithType } = getApisWithType({ main_apis, optional_apis })

  return (
    <StyledTableCell>
      <ExpandableApis apis={allApisWithType} borderBottom='none' />
    </StyledTableCell>
  )
}
