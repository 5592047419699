import React from 'react'
import { FormControlLabel, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { Checkbox, Input, TenantSpecificComponent } from '@components/shared'
import { StyledLabel } from '@styles'
import { LABORATORY_ASSISTANT } from '@constants/user'
import { IErrorsEditUser } from '@models/yourWorkspace'
import { IUser } from '@models/common/user'
import { TENANT_TYPE } from '@constants/tenants'

import { StyledBox, StyledFieldBox } from '../styles'
import { TimezoneField } from './TimezoneField'
import { PhoneField } from './PhoneField'
import { RoleField } from './RoleField'
import { AvatarField } from './AvatarField'
import { SkillsInfo } from './SkillsInfo'

interface FieldsProps {
  apiErrors: IErrorsEditUser
  currentUser?: IUser
}

export const Fields: React.FC<FieldsProps> = ({ apiErrors, currentUser }) => {
  const { register, getValues, formState } = useFormContext()
  const { errors } = formState

  const isLabAssistant = getValues('role') !== LABORATORY_ASSISTANT

  return (
    <StyledBox>
      <StyledLabel
        label='First name'
        mb='16px'
        control={
          <Input
            height={48}
            width='468px'
            dataTestId='input-first_name'
            variant='outlined'
            type='first_name'
            placeholder='Type here first name....'
            error={!!errors.first_name?.message}
            helperText={errors.first_name?.message}
            {...register('first_name')}
          />
        }
      />

      <StyledLabel
        label='Last name'
        mb='16px'
        control={
          <Input
            height={48}
            width='468px'
            dataTestId='input-last_name'
            variant='outlined'
            type='last_name'
            placeholder='Type here your last name...'
            error={!!errors.last_name?.message}
            helperText={errors.last_name?.message}
            {...register('last_name')}
          />
        }
      />

      <StyledFieldBox sx={{ marginBottom: '18px' }}>
        <Typography color='#0F1934'>Avatar</Typography>
        <AvatarField />
      </StyledFieldBox>

      <StyledFieldBox sx={{ marginBottom: '22px' }}>
        <Typography color='#0F1934'>Role</Typography>
        <RoleField isLabAssistant={isLabAssistant} />
      </StyledFieldBox>

      {isLabAssistant && (
        <StyledFieldBox sx={{ marginBottom: '32px' }}>
          <FormControlLabel
            {...register('can_manage_users')}
            control={<Checkbox checked={getValues('can_manage_users') || false} />}
            label='Manage users'
            sx={{ marginLeft: '-9px' }}
          />
          <Typography color='#616D84' sx={{ fontSize: '14px' }}>
            Access to add new users, edit users and change status
          </Typography>
        </StyledFieldBox>
      )}

      <TenantSpecificComponent desiredTenant={TENANT_TYPE.legalDrugs}>
        <SkillsInfo currentUser={currentUser} />
      </TenantSpecificComponent>

      <StyledFieldBox sx={{ marginBottom: '16px' }}>
        <Typography mb='4px' color='#0F1934'>
          Phone number
        </Typography>
        <PhoneField phoneError={apiErrors?.phone_number && apiErrors?.phone_number[0]} />
      </StyledFieldBox>

      <StyledLabel
        label='Email'
        mb='16px'
        control={
          <Input
            height={48}
            width='468px'
            dataTestId='input-email'
            variant='outlined'
            type='email'
            placeholder='Type here your email...'
            error={!!errors.email?.message || !!apiErrors?.email}
            helperText={errors.email?.message || (apiErrors?.email && apiErrors?.email[0])}
            {...register('email')}
          />
        }
      />

      <StyledFieldBox>
        <TimezoneField />
        <FormControlLabel
          {...register('timezone_autodetect')}
          control={<Checkbox checked={getValues('timezone_autodetect') || false} />}
          label='Set time zone automatically'
          sx={{ marginTop: '5px', marginLeft: '-9px' }}
        />
      </StyledFieldBox>
    </StyledBox>
  )
}
