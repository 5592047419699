import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'

import { generatePackageQr } from '@api/packages'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useDownloadPackagedQr = (packageId: string) => {
  const dispatch = useDispatch()
  const mutation = useMutation<Blob, IError, 'print' | 'download'>(
    () => generatePackageQr({ packageId, downloadQrCode: true }),
    {
      onSuccess: (data, oldData) => {
        const qrBlob = new Blob([data], { type: 'application/pdf' })
        const qrLink = URL.createObjectURL(qrBlob)

        if (oldData === 'print') window.open(qrLink)?.print()
        else {
          const link = document.createElement('a')
          const fileName = `package_QR_${packageId}.pdf`
          link.href = qrLink
          link.setAttribute('download', fileName)
          document.body.appendChild(link)

          link.click()

          URL.revokeObjectURL(link.href)
          link.parentNode?.removeChild(link)
        }
      },
      onError: error => {
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
      },
      retry: 1,
      retryDelay: 1500,
    }
  )

  return mutation
}
