import React from 'react'

import { TasksStep } from '@components/shared'
import { AWAITING } from '@constants/tasks'
import { StyledPageSubTitle } from '@styles'

import { StepHeader } from '../../../Testing/styles'

interface AttachmentsSectionTitleProps {
  actualStep: number
  stepTitle: string
}

export const AttachmentsSectionTitle: React.FC<AttachmentsSectionTitleProps> = ({
  actualStep,
  stepTitle,
}) => {
  return (
    <StepHeader className='test-header'>
      <TasksStep idx={actualStep - 1} stepCounter={actualStep} stepColor={AWAITING} />
      <StyledPageSubTitle variant='h3'>{stepTitle}</StyledPageSubTitle>
    </StepHeader>
  )
}
