import * as React from 'react'
import throttle from 'lodash/throttle'
import { useController, useFormContext } from 'react-hook-form'

import { formFieldErrors } from '@constants/errors'
import { isValueGreaterThanMax } from '@helpers/ui/autocomplete'
import { useAddNewAddressManufacturer } from '@hooks/queries/useAddNewAddressManufacturer'
import { useAddNewTowns } from '@hooks/queries/useAddNewTowns'
import { ADDRESS_OF_MANUFACTURER, TOWN } from '@constants/samples'

import {
  PlaceType,
  GMPlaceAutocompleteTypes,
  IPlaceOption,
  PlaceDetailTypes,
} from './gMPlaceAutocomplete.types'
import { GMPlaceAutocompleteRender } from './GMPlaceAutocompleteRender'

const autocompleteService = { current: null }
const detailService = { current: null }

export const GMPlaceAutocomplete: React.FC<GMPlaceAutocompleteTypes> = props => {
  const { isCopy, addressId, name, rules, dataOptions } = props
  const { setError, clearErrors } = useFormContext()
  const { field, fieldState } = useController({ name, rules, defaultValue: null })
  const { field: fieldDetail } = useController({
    name: `${name}_detail`,
    rules,
    defaultValue: null,
  })
  const { field: oldField, fieldState: oldFieldState } = useController({
    name,
    rules,
    defaultValue: null,
  })
  const { isTouched, error } = fieldState
  const { isTouched: isOldTouched, error: oldError } = oldFieldState

  const [value, setValue] = React.useState<PlaceType | null>(field.value)
  const [oldDataValue, setOldDataValue] = React.useState<IPlaceOption | null>(field.value)
  const [inputValue, setInputValue] = React.useState<string>('')
  const [showGP, setShowGP] = React.useState<boolean>(false)
  const [options, setOptions] = React.useState<readonly PlaceType[]>([])

  const addNewManufacturerAddress = useAddNewAddressManufacturer()
  const addNewTowns = useAddNewTowns()
  const isInputValueGreaterThanMax = isValueGreaterThanMax(inputValue, 500)

  const isHighlighting = isCopy && !isTouched
  const isOldHighlighting = isCopy && !isOldTouched

  const fetchPredictions = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        ;(autocompleteService.current as any).getPlacePredictions(request, callback)
      }, 200),
    []
  )
  const fetchDetails = React.useMemo(
    () =>
      throttle(
        (
          request: { placeId: string },
          callback: (results: PlaceDetailTypes, status: string) => void
        ) => {
          ;(detailService.current as any).getDetails(request, callback)
        },
        200
      ),
    []
  )

  const addNewData = React.useMemo(() => {
    switch (true) {
      case name.includes(TOWN):
        return addNewTowns
      case name.includes(ADDRESS_OF_MANUFACTURER):
        return addNewManufacturerAddress
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Formatting data from server form MUI Autocomplete options
  const serverOptions = React.useMemo((): IPlaceOption[] | [] => {
    if (dataOptions?.length) {
      return dataOptions.map(item => ({
        ...item,
        name: item.description,
      }))
    }
    return []
  }, [dataOptions])

  // Filling 'react-hook-form' and component state with data from the server
  React.useEffect(() => {
    if (addressId && dataOptions) {
      const oldAddress = dataOptions?.find(oldAddress => oldAddress.id === addressId)
      if (oldAddress) {
        oldField.onChange({
          target: { value: { ...oldAddress, name: oldAddress.description } },
        })
        setOldDataValue({ ...oldAddress, name: oldAddress.description })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Searching and formatting Google Place results
  React.useEffect(() => {
    let active = true
    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    if (showGP) {
      fetchPredictions({ input: inputValue }, (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = []
          if (value) newOptions = [value]
          if (results) newOptions = [...newOptions, ...results]

          const objForAddNewAddressBtn = {
            description: inputValue,
            place_id: '',
            structured_formatting: {
              main_text: '@this_option_for_save@',
              secondary_text: '',
              main_text_matched_substrings: [],
            },
          }
          newOptions = [...newOptions, objForAddNewAddressBtn]
          setOptions(newOptions)
        }
      })
    }

    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  // Cleaning 'react-hook-form' errors
  React.useEffect(() => {
    if (!isInputValueGreaterThanMax) clearErrors(name)
  }, [name, clearErrors, isInputValueGreaterThanMax])

  // Filling 'react-hook-form' with data from the server after successfully creating new address
  React.useEffect(() => {
    if ((addNewManufacturerAddress.isSuccess || addNewTowns.isSuccess) && showGP) {
      const newItem = addNewTowns.isSuccess ? addNewTowns.data : addNewManufacturerAddress.data
      if (newItem) {
        setShowGP(false)
        setOldDataValue({ ...newItem, name: newItem.description })
        oldField.onChange({ target: { value: { ...newItem, name: newItem.description } } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewManufacturerAddress.isSuccess, addNewTowns.isSuccess])

  // Select option on main autocomplete
  const onChange = (e: React.SyntheticEvent, val: IPlaceOption | null): void => {
    if (val) {
      const oldAddress = dataOptions?.find(oldAddress => oldAddress.id === val.id)
      if (oldAddress) {
        setOldDataValue({ ...oldAddress, name: oldAddress.description })
      }
    } else {
      setOldDataValue(val)
    }

    oldField.onChange({ target: { value: val } })
  }

  const onInputChange = (e: React.SyntheticEvent, newInputValue: string): void => {
    let isFound = false
    if (!newInputValue) setOldDataValue(null)

    dataOptions?.forEach(item => {
      if (item.description.toLowerCase().includes(newInputValue.trim().toLowerCase())) {
        isFound = true
      }
    })

    if ((!isFound && !showGP) || !e) setShowGP(true)
    else if (isFound && showGP) setShowGP(false)

    setInputValue(newInputValue)
  }

  // Select option on Google Place Autocomplete
  const onGPChange = (e: React.SyntheticEvent, newValue: PlaceType | null): void => {
    setOptions(newValue ? [newValue, ...options] : options)
    setValue(newValue)
    oldField.onChange({ target: { value: newValue } })
    let detailValue = { ...newValue }

    if (newValue && newValue.place_id) {
      if (!detailService.current) {
        detailService.current = new (window as any).google.maps.places.PlacesService(
          document.createElement('div')
        )
      }
      fetchDetails({ placeId: newValue.place_id }, (results: PlaceDetailTypes, status: string) => {
        if (results && detailValue && status === 'OK') {
          detailValue.description = results.formatted_address
          fieldDetail.onChange({ target: { value: detailValue } })
        }
      })
    }
  }

  const handleAddNewAddress = (option: PlaceType): void => {
    if (isInputValueGreaterThanMax) {
      setError(name, formFieldErrors.maxValueIsFifty)
    } else {
      const { description, place_id } = option
      addNewData?.mutate({ description, place_id: place_id || '' })
    }
  }

  return (
    <GMPlaceAutocompleteRender
      isHighlighting={isHighlighting}
      isOldHighlighting={isOldHighlighting}
      showGP={showGP}
      serverOptions={serverOptions}
      oldField={oldField}
      onChange={onChange}
      onInputChange={onInputChange}
      oldDataValue={oldDataValue}
      options={options}
      value={value}
      onGPChange={onGPChange}
      error={error}
      oldError={oldError}
      isLoading={addNewData?.isLoading || false}
      inputValue={inputValue}
      handleAddNewAddress={handleAddNewAddress}
    />
  )
}
