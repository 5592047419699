import React from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import { ISamples } from '@models/samples'
import { ConditionalHidden, ParentLinksRow } from '@components/shared'
import { hasParentInSameSubGroup } from '@helpers/ui/samples'
import { TEST_REPORTS } from '@constants/testReports'

import { ReportsRows } from '../ReportsRows'

interface TableBodyProps {
  reports?: ISamples
  totalReports?: number
}

export const TableBody: React.FC<TableBodyProps> = props => {
  const { reports, totalReports } = props

  const [queryString] = useQueryParam('ordering', StringParam)
  const shouldBeHiddenSplitLinks = !queryString

  return (
    <>
      {reports?.map((report, idx) => {
        const { parents, parents_specific_ids } = report
        const prevSampleParents = reports[idx - 1]?.parents || []
        const isSameSubGroup = hasParentInSameSubGroup(prevSampleParents, parents)
        const isSameGroup = prevSampleParents.some(parent => parents.includes(parent))
        const isAnotherSubGroup = (() => {
          let flag = true
          prevSampleParents.forEach(parent => {
            if (flag && !parents.includes(parent)) flag = false
          })
          return !flag
        })()

        const isLastReport = totalReports === idx + 1
        const needsToRenderParentLinks =
          (!!parents.length && !isSameGroup) || isSameSubGroup || isAnotherSubGroup

        return (
          <React.Fragment key={report.id}>
            <ConditionalHidden condition={shouldBeHiddenSplitLinks}>
              {needsToRenderParentLinks && (
                <ParentLinksRow
                  paddingRight='65px'
                  width='1px'
                  nextParents={parents}
                  parentsSpecificIds={parents_specific_ids}
                  prevParents={prevSampleParents}
                  isSameSubGroup={isSameSubGroup}
                  samples={reports}
                  contentType={TEST_REPORTS}
                />
              )}
            </ConditionalHidden>

            <ReportsRows
              parents={parents}
              hiddenSplitLinks={shouldBeHiddenSplitLinks}
              isLastReport={isLastReport}
              report={report}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}
