import { Box, styled } from '@mui/material'

import { Button } from '@components/shared'

export const StyledBox = styled(Box)`
  background-color: #ffffff;
  padding: 17px 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0px;
`
export const AddIcon = styled('img')`
  width: 24px;
  height: 24px;
  margin-right: 13px;
`

export const StyledButton = styled(Button)`
  padding: 14px 50px;
  height: 48px;
`
