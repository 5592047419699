import React from 'react'

import { IPackage } from '@models/packages'
import { Button } from '@components/shared'
import { useUpdatePackage } from '@hooks/queries'

interface PickUpProps {
  pack: IPackage
  mr?: string
}

export const PickUp: React.FC<PickUpProps> = ({ pack, mr }) => {
  const { mutate, isLoading } = useUpdatePackage()

  const pickUpHandler = () => mutate({ id: pack.id, data: { delivery_status: 'picked_up' } })

  return (
    <Button
      variant='outlined'
      width='90px'
      padding='0'
      mr={mr}
      fz={15}
      disabled={isLoading}
      onClick={pickUpHandler}
    >
      Pick up
    </Button>
  )
}
