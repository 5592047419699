import React from 'react'
import { Box, useTheme } from '@mui/material'

import { IHRSample, ISample } from '@models/samples'

import { SampleCells } from './SampleCells'
import { StyledTableRow } from '../styles'

interface SampleRowProps {
  sample: ISample | IHRSample
  isSampleSelected: boolean
  isVerifyTenant: boolean
}

export const SampleRow: React.FC<SampleRowProps> = React.memo(props => {
  const { sample, isSampleSelected, isVerifyTenant } = props
  const theme = useTheme()

  return (
    <Box
      sx={{
        padding: '10px 0 12px 0',
        marginBottom: '3px',
        backgroundColor: isSampleSelected ? theme.primary[30] : '',
      }}
    >
      <StyledTableRow isVerifyTenant={isVerifyTenant}>
        <SampleCells sample={sample} isSampleSelected={isSampleSelected} />
      </StyledTableRow>
    </Box>
  )
})
