import { useMutation } from 'react-query'

import { customizeTableColumns } from '@api/common'
import { ICustomizeTableColumnsData } from '@models/common/user'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import {
  selectUserCustomizeTableColumns,
  setSampleColumns,
  setTaskColumns,
  setMaintenanceModePage,
  rerenderTablesComponent,
} from '@state/user'
import { IError } from '@models/common/app'

export interface IUseCustomizeTableColumnsResult {
  task_column_settings?: { column: number; activated: boolean }[]
  sample_column_settings?: { column: number; activated: boolean }[]
}

export const useCustomizeTableColumns = () => {
  const { columns } = useAppSelector(selectUserCustomizeTableColumns)
  const dispatch = useAppDispatch()

  const mutation = useMutation<IUseCustomizeTableColumnsResult, IError, ICustomizeTableColumnsData>(
    customizeTableColumns,
    {
      onSuccess: () => {
        dispatch(setSampleColumns(columns[0].filter(column => column.id !== 0)))
        dispatch(setTaskColumns(columns[1].filter(column => column.id !== 0)))
        dispatch(rerenderTablesComponent())
      },
      onError: error => {
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
      },
    }
  )
  return mutation
}
