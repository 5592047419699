import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { useDebounce } from 'use-debounce/lib'

import { useAppSelector } from '@hooks/redux'
import { IError, ISearchSuggestionsData } from '@models/common/app'
import { selectSearchSuggestions } from '@state/app'
import { searchSuggestionsTasks } from '@api/tasks'
import { DEBOUNCE_TIMING } from '@constants/common'

export const useSearchSuggestionsTasks = (
  type: string | undefined | null | string[],
  enabled: boolean
): UseInfiniteQueryResult<ISearchSuggestionsData> => {
  const suggestionSampleSearching = useAppSelector(selectSearchSuggestions)
  const [debouncedSearchQuery] = useDebounce(suggestionSampleSearching, DEBOUNCE_TIMING)

  const endpointPayload = [debouncedSearchQuery, type || 'awaiting']

  return useInfiniteQuery<ISearchSuggestionsData, IError>(endpointPayload, searchSuggestionsTasks, {
    getNextPageParam: lastPage => {
      const { next: nextPageUrl } = lastPage.links

      if (nextPageUrl) {
        const queryString = nextPageUrl.slice(nextPageUrl.indexOf('?'))
        const urlParams = new URLSearchParams(queryString)
        const nextPage = urlParams.get('page')

        return nextPage
      }
    },
    retry: 0,
    enabled,
  })
}
