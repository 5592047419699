import React from 'react'

import { IPackages, PackageContentType } from '@models/packages'
import {
  incomingPackageTableColumnHeaders,
  outgoingPackageTableColumnHeaders,
  packageTableColumnHeaders,
} from '@constants/mappings'
import { StyledTableHeadWrapper } from '@styles'

import { PackagesToggler, ColumnHeaders } from './components'
import { StyledTableRow } from '../styles'
import { StyledTableHead } from './styles'

interface TableHeadProps {
  packages: IPackages
  contentType: PackageContentType
  hideBorder: boolean
}

export const TableHead = React.forwardRef<HTMLDivElement, TableHeadProps>(
  ({ packages, contentType, hideBorder }, ref) => {
    const isDefaultContentType = contentType === 'default'
    let tableTitles

    switch (contentType) {
      case 'incoming':
        tableTitles = incomingPackageTableColumnHeaders
        break

      case 'outgoing':
        tableTitles = outgoingPackageTableColumnHeaders
        break

      default:
        tableTitles = packageTableColumnHeaders
        break
    }
    return (
      <StyledTableHeadWrapper
        ref={ref}
        hideBorder={hideBorder}
        top='145px'
        borderBottom={!hideBorder ? '' : '4px solid rgb(248, 251, 255)'}
      >
        <StyledTableHead>
          <StyledTableRow header isDefaultContentType={isDefaultContentType} hideBorder>
            {isDefaultContentType && <PackagesToggler packages={packages} />}

            <ColumnHeaders hideBorder={!hideBorder} content={tableTitles} />
          </StyledTableRow>
        </StyledTableHead>
      </StyledTableHeadWrapper>
    )
  }
)
