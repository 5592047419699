import { TypographyProps } from '@mui/material'
import React from 'react'

import { StyledParagraphLink } from './styles'

export interface ParagraphLinkProps extends TypographyProps {
  alignSelf?: string
  marginBottom?: string
  fontWeight?: string
  fontHeight?: string
  fontSize?: string
  width?: string
  $isShouldToAddStyles?: boolean
}

export const ParagraphLink = React.forwardRef<HTMLParagraphElement, ParagraphLinkProps>(
  (props, ref) => {
    const { ...restProps } = props
    return <StyledParagraphLink ref={ref} {...restProps} />
  }
)
