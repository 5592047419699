import { useAppSelector } from '@hooks/redux'
import { TenantType } from '@models/common/app'
import { selectConfig } from '@state/config'

export const useTenant = () => {
  const { tenant: currentTenant } = useAppSelector(selectConfig)

  function isTenantType(tenantType: TenantType) {
    return tenantType === currentTenant?.type
  }
  function isTenantName(tenantName: string) {
    return tenantName === currentTenant?.name
  }
  return { isTenantType, isTenantName }
}
