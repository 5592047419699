import React from 'react'
import { CircularProgress, Typography } from '@mui/material'

import { Modal } from '@components/shared'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { resetEmailSendingStatuses, selectUser, update } from '@state/user'
import { StyledBox, Image } from '@styles'
import mail from '@assets/images/mail.svg'

import { StyledParagraph, StyledTextBox, StyledTitle, StyledLink } from './styles'

interface EmailConfirmationSentProps {
  onClose: () => void
  email: string
}

export const EmailConfirmationSent: React.FC<EmailConfirmationSentProps> = ({ email, onClose }) => {
  const { isFetching, emailSendingStatuses } = useAppSelector(selectUser)

  const dispatch = useAppDispatch()

  const sendEmailHandler = () => {
    dispatch(update({ email }))
  }

  React.useEffect(
    () => () => {
      dispatch(resetEmailSendingStatuses())
    },
    [dispatch]
  )

  return (
    <Modal open onClose={onClose}>
      <StyledBox>
        <Image src={mail} width='80px' height='80px' component='img' />
        <StyledTitle variant='h3'>Confirm your email!</StyledTitle>
        <StyledParagraph sx={{ marginBottom: 3 }}>
          We’ve sent you the confirmation link on your
          <br /> email! Check it out and let’s start!
        </StyledParagraph>
        <StyledTextBox>
          {isFetching ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {emailSendingStatuses.secondTry ? (
                <Typography color='#18954B'>Sent</Typography>
              ) : (
                <>
                  <StyledParagraph>Haven`t received?</StyledParagraph>
                  <StyledLink onClick={sendEmailHandler}>Send again</StyledLink>
                </>
              )}
            </>
          )}
        </StyledTextBox>
      </StyledBox>
    </Modal>
  )
}
