import { styled as styledMUI } from '@mui/material'

export const StyledAdditionalText = styledMUI('p')`
  margin: 0;
  color: #8f929b;
  line-height: 19px;
`

export const StyledInnerTableCell = styledMUI('div')`
  padding: 16px 36px 16px 0;
  box-sizing: border-box;
  border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};
  position: relative;
`

export const StyledGreyIconColor = styledMUI('div')`
  & svg,
  & svg path {
    fill: #adb4c2;
  }
`

export const StyledTemperatureInputBox = styledMUI('div')`
  display: flex;
  margin-bottom: 18px;
`

export const StyledActionBtn = styledMUI('button')`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
  border: 0;
  cursor: pointer;
  background: transparent;

  & svg {
    display: inline-block;
    text-align: center;
    margin-right: 13px;
  }
`

export const StyledTypeItem = styledMUI('div')`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & svg {
    display: inline-block;
    margin-right: 4px;
  }
`

export const StyledSpanMaxMin = styledMUI('span')`
  color: #616D84;
  margin: 13px 0;
  margin-right: 6px;
`

export const StyledChipWrap = styledMUI('div')`
  display: flex;
  flex-wrap: wrap;
  padding-top: 6px;
`

export const StyledInputLabel = styledMUI('div')<{ mb?: number; fw?: number }>`
  margin: ${({ mb }) => `18px 0 ${mb ? mb : 5}px`};
  line-height: 19px;
  font-weight: ${({ fw }) => `${fw || 400}`};
  color: ${({ theme }) => theme.palette.text.primary};
  letter-spacing: 0.00938em;

`
