import { Box, styled, Typography, TypographyProps } from '@mui/material'
import React from 'react'

export const StyledShadowBoxTitle = styled<React.FC<{ $isMobile: boolean }>>(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ $isMobile }) => ($isMobile ? '20px 40px' : '24px 40px')};
  overflow: auto;
  z-index: 400;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(53, 108, 151, 0.12);

  .feedback-modal-img {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
`

export const FeedbackLink = styled('a')`
  color: ${({ theme }) => theme.palette.primary.main};
`

interface StyledLabelProps extends TypographyProps {
  mb?: string
}

export const StyledLabel = styled<React.FC<StyledLabelProps>>(Typography)`
  position: relative;
  margin-bottom: 5px;

  &::after {
    content: 'Required';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
  }

  @media (max-width: 767px) {
    &.MuiTypography-body1 {
      font-size: ${({ theme }) => theme.typography.pxToRem(16)};

      &::after {
        font-size: ${({ theme }) => theme.typography.pxToRem(16)};
      }
    }
  }
`

interface SymbolLengthCounterProps extends TypographyProps {
  display?: string
}

export const SymbolLengthCounter = styled<React.FC<SymbolLengthCounterProps>>(Typography)`
  display: ${({ display }) => display};
  margin-bottom: 16px;
  text-align: right;
  color: #8f929b;
`
