import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DropdownOptionsDataType, InitialStateTypes } from './dropdownState.types'

const initialState: InitialStateTypes = {
  optionsAndPosition: null,
  isLoading: false,
  inputValue: '',
}

export const dropdownStateSlice = createSlice({
  name: 'dropdown',
  initialState,
  reducers: {
    setDataAndPosition: (state, { payload }: PayloadAction<DropdownOptionsDataType | null>) => {
      state.optionsAndPosition = payload
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setInputValueToStore: (state, { payload }: PayloadAction<string>) => {
      state.inputValue = payload
    },
  },
})

export const {
  reducer: dropdownStateReducer,
  actions: { setDataAndPosition, setIsLoading, setInputValueToStore },
} = dropdownStateSlice
