import React from 'react'

import { Button } from '@components/shared'

export const SaveInfo = () => {
  return (
    <Button variant='contained' type='submit' width='205px'>
      Save
    </Button>
  )
}
