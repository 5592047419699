import ContentLoader from 'react-content-loader'

export const ManufactureDetailsLoader: React.FC = props => {
  return (
    <div>
      <ContentLoader
        speed={1.8}
        width='100%'
        viewBox='0 0 305 515'
        backgroundColor='#f3f3f3'
        foregroundColor='#e3e3e3'
        {...props}
      >
        <rect x='0' y='0' rx='4' ry='4' width='305' height='28' />
        <rect x='0' y='373' rx='4' ry='4' width='305' height='28' />
        <rect x='0' y='60' rx='4' ry='4' width='72' height='20' />
        <rect x='0' y='95' rx='4' ry='4' width='144' height='20' />
        <rect x='0' y='140' rx='4' ry='4' width='114' height='20' />
        <rect x='0' y='175' rx='4' ry='4' width='163' height='20' />
        <rect x='0' y='210' rx='4' ry='4' width='126' height='20' />
        <rect x='0' y='245' rx='4' ry='4' width='93' height='20' />
        <rect x='0' y='280' rx='4' ry='4' width='181' height='20' />
        <rect x='0' y='315' rx='4' ry='4' width='114' height='20' />
        <rect x='0' y='425' rx='4' ry='4' width='76' height='20' />
        <rect x='0' y='460' rx='4' ry='4' width='93' height='20' />
        <rect x='0' y='495' rx='4' ry='4' width='76' height='20' />
      </ContentLoader>
    </div>
  )
}
