import { styled } from '@mui/material'

import dropdownArrowUrl from '@assets/icons/sort-down.svg'
import { customScrollBarStyles } from '@components/ui/commonUIStyles'

const iconsStyle = `
  position: absolute;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  cursor: pointer;
`

export const InputBtnWrap = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  padding: 3px 0 3px 3px;
  border-radius: 4px;
  border: 1px solid #e1e7f3;
  height: 42px;
  box-sizing: border-box;

  &:not(.border-disabled):hover {
    border-color: #0f1934;
  }

  &.border-disabled {
    .pure-border {
      border: none;
    }
  }
  &.border-error {
    border-color: #af4645;
    & .pure-border {
      border-color: #af4645;
    }
  }
  > * {
    overflow: hidden;
    border-radius: 4px;
    border: none;
    height: 100%;
  }
  & .pure-border {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 4px;
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    box-sizing: border-box;
    display: none;
  }
`

export const StartIconBox = styled('div')`
  left: 0;
  padding-left: 4px;
  width: 33px;
  ${iconsStyle}
`

export const CleanInputBtnIcon = styled('button')`
  right: 34px;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  ${iconsStyle}
  &:hover > div {
    background: rgba(0, 0, 0, 0.04);
  }
`

export const CleanIconBox = styled('div')`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 2px;
  border-radius: 50%;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  & svg {
    fill: #adb4c2;
  }
`

export const Input = styled('input')`
  position: relative;
  z-index: 1;
  flex: 1;
  padding: 0 58px 0 10px;
  min-width: 0;
  outline: transparent;
  background: transparent;

  &:disabled {
    color: #8f929b;
  }

  &::placeholder {
    color: #8f929b;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    & ~ .pure-border {
      display: block;
    }
  }
`

export const ToggleBtn = styled('button')`
  position: relative;
  z-index: 1;
  overflow: hidden;
  flex: 1;
  padding: 0 34px 0 8px;
  text-align: left;
  background: transparent;
  cursor: pointer;
  &.pure-dropdown-with-input {
    position: relative;
    flex: none;
    padding-right: 32px;
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      content: '';
      height: 24px;
      border-left: 1px solid #e1e7f3;
      transform: translateY(-50%);
    }
  }
  &:focus {
    & ~ .pure-border {
      display: block;
    }
  }
`

export const ArrowIcon = styled('span')`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 36px;
  height: 100%;
  background: ${`transparent url(${dropdownArrowUrl}) 50% no-repeat`};
  &.arrow-icon-rotated {
    transform: rotate(180deg);
  }
`

export const ListWrap = styled('div')`
  position: absolute;
  z-index: 1000;
  overflow: auto;
  height: 0;
  border: 1px solid #e1e7f3;
  border-radius: 4px;
  background: #fff;
  ${customScrollBarStyles}
`

export const List = styled('ul')`
  box-sizing: border-box;
  margin: 0;
  padding: 8px 0;
  list-style: none;
`

export const Item = styled('li')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px;
  cursor: pointer;
  &:hover {
    background-color: #0000000a;
  }
`

export const IconWrap = styled('span')`
  display: inline-flex;
  justify-content: center;
  text-align: center;
  margin-right: 7px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
`

export const OptionDescription = styled('span')`
  display: inline-block;
  margin-left: 25px;
  font-size: 14px;
  color: #8f929b;
`

export const DropdownPlaceholder = styled('span')`
  display: inline-block;
  padding-top: 2px;
  color: #8f929b;
`
