import React from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useTheme } from '@mui/material'

import { ISample } from '@models/samples'
import { BlockchainHistory, Breadcrumbs } from '@components/shared'
import { protectedRoutes } from '@configs/routes.config'
import { PageTitle, StyledDetailsPageBox } from '@styles'
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { IBreadcrumbsRoute } from '@models/common/app'
import { isContainRoute } from '@helpers/routes'

import { StyledBox, StyledImageButton } from './styles'

interface PageHeaderProps {
  sample: ISample
  sampleId: string
  isEditing: boolean
  toggleEditing: () => void
}

export const PageHeader: React.FC<PageHeaderProps> = props => {
  const { sample, sampleId, isEditing, toggleEditing } = props
  const { state } = useLocation<IBreadcrumbsRoute[]>()
  const { path, url } = useRouteMatch()
  const theme = useTheme()

  const history = useHistory()
  const pageTitle = `${protectedRoutes.sampleDetails.name} ${sample.specific_id}`

  React.useEffect(() => {
    if (state && !isContainRoute(state, url)) {
      history.replace({ state: [...state, { path, url, title: pageTitle }] })
    }
  }, [history, pageTitle, path, state, url])

  const renderEditButton = () => {
    const canEditSample = !isEditing && sample?.allow_editing

    return (
      canEditSample && (
        <StyledImageButton onClick={toggleEditing}>
          <EditIcon fill={theme.palette.primary.main} />
        </StyledImageButton>
      )
    )
  }

  return (
    <>
      <StyledDetailsPageBox>
        <StyledBox>
          <PageTitle>Sample details</PageTitle>

          {renderEditButton()}
        </StyledBox>

        <BlockchainHistory blockchainHistoryType='samples' itemId={Number(sampleId)} />
      </StyledDetailsPageBox>

      <Breadcrumbs />
    </>
  )
}
