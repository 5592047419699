import React from 'react'
import { Autocomplete, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '@hooks/redux'
import { IHrLaboratory, ILaboratory } from '@models/common/app'
import { selectUserLaboratory } from '@state/user'
import { useFetchLaboratories } from '@hooks/queries'
import { Input, AutocompleteWrapper, Tooltip } from '@components/shared'
import { setLaboratoryForWorkspace, workSpaceInfo } from '@state/workSpace'

export const LaboratoriesField = React.memo(() => {
  const dispatch = useDispatch()
  const currentLaboratory = useAppSelector(selectUserLaboratory)
  const { selectedLaboratory } = useAppSelector(workSpaceInfo)
  const [searchLaboratory, setSearchLaboratory] = React.useState<string>('')

  const [laboratory, setLaboratory] = React.useState<ILaboratory | IHrLaboratory | null>(
    currentLaboratory
  )
  const [topScroll, setTopScroll] = React.useState<{ oldScrollTop: number; isScrollTop: boolean }>({
    oldScrollTop: 0,
    isScrollTop: false,
  })
  const searchQuery = laboratory?.name === searchLaboratory ? '' : searchLaboratory

  const { data, isFetching, fetchNextPage, hasNextPage } = useFetchLaboratories(searchQuery)
  const laboratoryByName = data?.pages.map(page => page.results).flat()

  React.useEffect(() => {
    dispatch(setLaboratoryForWorkspace(currentLaboratory))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!!selectedLaboratory) {
      dispatch(setLaboratoryForWorkspace(selectedLaboratory))
      setLaboratory(selectedLaboratory)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLaboratory])

  const onChangeHandler = (e: any, v: ILaboratory | IHrLaboratory | null) => {
    if (v) {
      dispatch(setLaboratoryForWorkspace(v))
    }

    setLaboratory(v)
  }

  const onInputChangeHandler = (e: any, v: string) => {
    setSearchLaboratory(v)
  }

  const onBlurHandler = () => {
    if (!laboratory) {
      setLaboratory(currentLaboratory)
      dispatch(setLaboratoryForWorkspace(currentLaboratory))
    }
  }
  return (
    <Tooltip title={laboratory?.name || ''} shouldBeHidden={(laboratory?.name.length || 0) <= 29}>
      <AutocompleteWrapper width='290px' padding='9.6px 57px 12px 9.6px;'>
        <Autocomplete
          size='small'
          loading={isFetching}
          value={laboratory}
          onBlur={onBlurHandler}
          renderTags={item => {
            return (
              <Typography style={{ maxWidth: 220 }} noWrap={true}>
                {item}
              </Typography>
            )
          }}
          onChange={onChangeHandler}
          onInputChange={onInputChangeHandler}
          options={laboratoryByName || []}
          noOptionsText='Nothing found'
          getOptionLabel={option => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          ListboxProps={{
            onScroll: event => {
              const listboxNode = event.currentTarget

              const isFetchNextPage =
                hasNextPage &&
                Math.round(listboxNode.scrollTop + listboxNode.clientHeight) ===
                  listboxNode.scrollHeight

              if (isFetchNextPage) {
                const top = Math.round(listboxNode.scrollHeight - listboxNode.clientHeight)
                fetchNextPage()
                setTopScroll({ oldScrollTop: top, isScrollTop: true })
              }

              if (listboxNode.scrollHeight > topScroll.oldScrollTop && topScroll.isScrollTop) {
                listboxNode.scrollTo(0, topScroll.oldScrollTop)

                setTopScroll(prev => ({ ...prev, isScrollTop: false }))
              }
            },
          }}
          renderInput={params => (
            <Input
              autoFocus
              variant='outlined'
              placeholder='Search or select laboratory...'
              {...params}
            />
          )}
        />
      </AutocompleteWrapper>
    </Tooltip>
  )
})
