import ContentLoader from 'react-content-loader'

export const TasksLoader: React.FC = props => (
  <ContentLoader
    speed={2}
    width={1110}
    height={131}
    viewBox='0 0 1110 131'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    {...props}
  >
    <path d='M 954 91 h 121 v 18 H 954 z M 954 48 h 121 v 18 H 954 z' />
    <rect x='72' y='95' rx='14' ry='14' width='109' height='28' />
    <rect x='219' y='94' rx='14' ry='14' width='67' height='28' />
    <rect x='0' y='96' rx='11' ry='11' width='24' height='22' />
    <path d='M 365 98 h 93 v 18 h -93 z M 625 98 h 280 v 18 H 625 z M 511 98 h 49 v 18 h -49 z' />
    <rect x='73' y='44' rx='14' ry='14' width='109' height='28' />
    <rect x='220' y='43' rx='14' ry='14' width='67' height='28' />
    <rect x='0' y='48' rx='11' ry='11' width='24' height='22' />
    <rect x='710' y='43' rx='11' ry='11' width='110' height='22' />
    <rect x='839' y='43' rx='11' ry='11' width='65' height='22' />
    <path d='M 365 48 h 93 v 18 h -93 z M 0 0 h 43 v 18 H 0 z M 73 0 h 87 v 18 H 73 z M 219 0 h 87 v 18 h -87 z M 365 0 h 43 v 18 h -43 z M 511 0 h 81 v 18 h -81 z M 625 0 h 126 v 18 H 625 z M 957 0 h 97 v 18 h -97 z M 625 48 h 66 v 18 h -66 z M 511 48 h 49 v 18 h -49 z' />
  </ContentLoader>
)
