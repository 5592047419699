import { Box, IconButton, styled } from '@mui/material'

export const DetailsBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

export const ImageButton = styled(IconButton)`
  margin-left: 14px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid #e5e5e6;
  width: 40px;
  height: 40px;
`

export const SubstancesList = styled('ul')`
  margin: 0 0 37px;
  padding: 0;
  list-style: none;
`

export const SubstancesItem = styled('li')`
  position: relative;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
`

export const GreyCup = styled('span')`
  vertical-align: top;
  padding-left: 2px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.secondary.light};
`
