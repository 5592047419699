import React from 'react'
import { Typography } from '@mui/material'

import { StyledTableSampleCell } from '@styles'
import { ISample } from '@models/samples'
import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'

interface PharmacyNameProps {
  report: ISample
  hideBorder?: boolean
}

export const PharmacyName: React.FC<PharmacyNameProps> = ({ report, hideBorder }) => {
  return (
    <StyledTableSampleCell isClickable hideBorder={hideBorder}>
      <Tooltip
        isPureTooltip
        maxWidth={200}
        title={report.pharmacy?.name || ''}
        shouldBeHidden={
          ((report.pharmacy && report.pharmacy.name.length) || 0) <= TRUNCATED_TEXT_LENGTH_S
        }
      >
        <Typography>
          {report.pharmacy ? stringSlice(report.pharmacy?.name, TRUNCATED_TEXT_LENGTH_S) : '-'}
        </Typography>
      </Tooltip>
    </StyledTableSampleCell>
  )
}
