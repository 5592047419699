import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { RegisterOptions, useController } from 'react-hook-form'
import { red } from '@mui/material/colors'

import { Input } from '@components/shared/sampleFields'
import { IUnitsOption } from '@models/common/app'
import { unitIconSDeterminer } from '@helpers/ui/typeOfUnits'

import {
  DropdownContainer,
  DropdownInputContainer,
  StyledDropdown,
  StyledIcon,
  StyledMenuItem,
} from './styles'

interface UnitsProps {
  name: string
  dropdownName?: string
  dropdownOptions?: IUnitsOption[]
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  placeholder?: string
  defaultValue?: string
  width?: string
  dropdownWidth?: string
  inputDisabled?: boolean
  isCopy?: boolean
  pr?: string
}

export const Units: React.FC<UnitsProps> = React.memo(props => {
  const {
    name,
    dropdownName = '',
    dropdownOptions,
    placeholder,
    rules,
    defaultValue,
    width,
    inputDisabled,
    dropdownWidth,
    isCopy,
    pr,
  } = props
  const { field, fieldState } = useController({ name: dropdownName })
  const isHighlighting = isCopy && !fieldState.isTouched
  const theme = useTheme()

  return (
    <Box sx={{ width: width || '100%' }}>
      <DropdownInputContainer>
        <Input
          type='number'
          disabled={inputDisabled}
          placeholder={placeholder}
          defaultValue={defaultValue}
          rules={rules}
          width='100%'
          name={name}
          isCopy={isCopy}
          pr={pr}
        />
        <DropdownContainer>
          <StyledDropdown
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '280px',
                  height: '300px',
                  color: red,
                },
              },
            }}
            variant='standard'
            width={dropdownWidth || ''}
            sx={{ backgroundColor: 'transparent' }}
            {...field}
            bgColor={isHighlighting ? '#FFF5D6' : '#fff'}
          >
            {dropdownOptions &&
              dropdownOptions.map(({ id, name, description }) => {
                const UnitIconType = unitIconSDeterminer(id)

                return (
                  <StyledMenuItem key={id} value={id}>
                    <StyledIcon>
                      <UnitIconType
                        fill={inputDisabled ? '#8F929B' : theme.palette.primary.main}
                        stroke={inputDisabled ? '#8F929B' : theme.palette.primary.main}
                      />
                    </StyledIcon>

                    <Typography
                      sx={{ flex: '1 1 auto', display: 'inline' }}
                      fontSize='16px'
                      variant='body2'
                    >
                      {name}
                    </Typography>

                    <Typography fontSize='14px' color='secondary.light' variant='body1'>
                      {description}
                    </Typography>
                  </StyledMenuItem>
                )
              })}
          </StyledDropdown>
        </DropdownContainer>
      </DropdownInputContainer>
    </Box>
  )
})
