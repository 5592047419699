import * as React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { Autocomplete, Box, Typography } from '@mui/material'

import { CLINICALLY_MEANINGFUL } from '@constants/hrSamples'
import { useFetchClinicallyMeaningful } from '@hooks/queries'
import { REQUIRED_FIELD } from '@constants/validation'
import { AutocompleteWrapper, Input, Tooltip } from '@components/shared'
import { IChoice } from '@models/common/app'
import { StyledDetailsLabel } from '@styles'
import { SUBSTANCES } from '@constants/tests'

import { SubstancesItemGroupPropTypes } from '../substancesRecognized.types'

export const ClinicallyMeaningful: React.FC<SubstancesItemGroupPropTypes> = props => {
  const { index, isNotRecognized } = props
  const name = `fields.${SUBSTANCES}.${index}.${CLINICALLY_MEANINGFUL}`

  const rules = {
    required: isNotRecognized ? false : REQUIRED_FIELD,
  }

  const { clearErrors, formState, control } = useFormContext()
  const errors = formState.errors

  const { field } = useController({
    name,
    control,
    rules,
  })

  const errorField = errors.fields?.substances?.[index]?.clinically_meaningful
  const fieldValue = field.value?.value

  const { data: testClinicallyMeaningfulData, isFetching } = useFetchClinicallyMeaningful()
  const clinicallyMeaningfulOptions = testClinicallyMeaningfulData || []

  // CLEAR ERRORS after toggle recognized checkbox
  React.useEffect(() => {
    if (isNotRecognized) {
      if (!!errorField?.message) {
        clearErrors(name)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotRecognized])

  return (
    <Box width='100%'>
      <StyledDetailsLabel color={isNotRecognized ? 'primary.light' : 'text.primary'} mb='4px'>
        Clinically meaningful
      </StyledDetailsLabel>
      <AutocompleteWrapper
        $isFieldValue={!!fieldValue?.length}
        $widthAutocomplete='230px'
        padding='10px 54px 10px 10px'
      >
        <Autocomplete
          size='small'
          loading={isFetching}
          renderTags={item => {
            return (
              <Typography style={{ maxWidth: 220 }} noWrap={true}>
                {item}
              </Typography>
            )
          }}
          disabled={isNotRecognized}
          options={clinicallyMeaningfulOptions || []}
          noOptionsText='Nothing found'
          getOptionLabel={option => option.value || ''}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          renderInput={params => (
            <Tooltip title={fieldValue || ''} shouldBeHidden={(fieldValue?.length || 0) <= 21}>
              <Input
                variant='outlined'
                placeholder='Select...'
                error={!!errorField?.message}
                helperText={errorField?.message}
                {...params}
              />
            </Tooltip>
          )}
          {...field}
          onChange={(e: any, v: IChoice | null) => {
            field.onChange({ target: { value: v } })
          }}
        />
      </AutocompleteWrapper>
    </Box>
  )
}
