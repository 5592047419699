import React from 'react'
import { useDispatch } from 'react-redux'

import { setDataAndPosition } from '@state/common/tooltipState'
import { TooltipPositionsDataType } from '@state/common/tooltipState/tooltipState.types'

import { PureTooltipProps } from './pureTooltip.types'
import * as Styled from './styles'

export const PureTooltip: React.FC<PureTooltipProps> = ({
  children,
  title,
  placement,
  titleStyle,
  maxWidth,
  positionLeft,
  positionTop,
  shouldBeHidden,
  width,
  display,
  commonStyles,
  scrollBlockRef,
}) => {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const filtersScrollBlock = document.getElementById('content-scroll-box')
    const cleanStore = () => {
      dispatch(setDataAndPosition(null))
    }

    if (scrollBlockRef?.current) {
      ;(scrollBlockRef.current as HTMLDivElement).addEventListener('scroll', cleanStore)
    } else if (filtersScrollBlock) {
      filtersScrollBlock.addEventListener('scroll', cleanStore)
    }

    return () => {
      if (scrollBlockRef?.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ;(scrollBlockRef.current as HTMLDivElement).removeEventListener('scroll', cleanStore)
      } else if (filtersScrollBlock) {
        filtersScrollBlock.removeEventListener('scroll', cleanStore)
      }
      cleanStore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMouseOver = () => {
    if (wrapperRef.current && !shouldBeHidden) {
      const { top, left, height, width } = wrapperRef.current.getBoundingClientRect()

      const data: TooltipPositionsDataType = {
        position: {
          left: positionLeft ? left + width / 2 - positionLeft : left + width / 2,
          height,
          top: positionTop ? top - positionTop : top,
        },
        title,
        wrapperRefCurrent: wrapperRef.current,
      }
      if (maxWidth) data.position.maxWidth = maxWidth
      if (placement) data.placement = placement
      if (titleStyle) data.styles = titleStyle

      dispatch(setDataAndPosition(data))
    }
  }

  const wrapStyles = (() => {
    const styles: React.CSSProperties = commonStyles || {}
    if (width) styles.width = width
    if (display) styles.display = display
    return styles
  })()

  return (
    <Styled.Wrapper
      style={wrapStyles}
      ref={wrapperRef}
      onMouseOver={handleMouseOver}
      className='pure-tooltip-wrapper'
    >
      {children}
    </Styled.Wrapper>
  )
}
