import { http } from '@services/http'
import { IProjectsData, IOptionData } from '@models/projects'
import { PROJECTS_AMOUNT_LOADING_LIMIT } from '@constants/projects'

export const fetchProjects = <T>(queryKey: any) => {
  const { pageParam: page = 1 } = queryKey

  const url = `?limit=${PROJECTS_AMOUNT_LOADING_LIMIT}&page=${page}`

  try {
    const projectsEndpoint = `/projects/${url}`
    return http.get<IProjectsData<T>>(projectsEndpoint)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchProjectsF = ({ pageParam = 1, ...payload }) => {
  const desiredFilter = payload.queryKey[1]
  const url = `?limit=${PROJECTS_AMOUNT_LOADING_LIMIT}&page=${pageParam}`

  try {
    return http.get<IOptionData>(
      `/projects/${url}${desiredFilter ? '&search=' + desiredFilter : ''}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateProject = ({ id, ...project }: any) => {
  try {
    return http.patch(`/projects/${id}/`, project)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createProject = ({ ...project }: any) => {
  try {
    return http.post(`/projects/`, project)
  } catch (error) {
    return Promise.reject(error)
  }
}
