import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { Link, useParams } from 'react-router-dom'

import { Footer } from '@components/modules/Layout/Footer'
import logoVerify from '@assets/icons/logo-white-verify.svg'
import logoScreenr from '@assets/icons/logo-white-screenr.svg'
import { FooterMobile } from '@components/modules/Layout/FooterMobile'
import { protectedRoutes } from '@configs/routes.config'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'

import { HeaderBox, Logo, UnsubscribePageBox } from '../styles'
import { InfoPage } from '../InfoPage'

interface IParams {
  email: string
}

export const UnsubscribedPage: React.FC = () => {
  const { isMaintenanceModePage } = useAppSelector(selectUser)
  const isMobile = useMediaQuery('(max-width:767px)')
  const { email } = useParams<IParams>()
  const theme = useTheme()
  const { isTenantType } = useTenant()
  const isLegalDrugs = isTenantType(TENANT_TYPE.legalDrugs)

  const text =
    'You have been successfully removed from this subscriber list and won’t receive any further emails about deployment'

  return (
    <UnsubscribePageBox $isMobile={isMobile}>
      <HeaderBox $isMobile={isMobile}>
        <Link to={protectedRoutes.home.path}>
          <Logo
            src={isLegalDrugs ? logoVerify : logoScreenr}
            alt='logo'
            width='99px'
            height='30px'
          />
        </Link>
      </HeaderBox>

      <InfoPage
        typeOfContent='unsubscribe'
        title='Thank you'
        text={text}
        linkDescription='Unsubscribed by accident?'
        modeLinkTitle='Subscribe again'
        link='/'
        iconColor='#0A2C6D'
        titleColor='#0F1934'
        textColor='#616D84'
        linkColor={theme.palette.primary.main}
        email={email}
      />

      {isMobile ? <FooterMobile /> : <Footer position={isMaintenanceModePage ? 'fixed' : ''} />}
    </UnsubscribePageBox>
  )
}
