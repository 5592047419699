import styled from 'styled-components'

export const Main = styled.main<{ $isErrorPage: boolean }>`
  background: ${({ theme }) => theme.palette.background.default};
  flex: 1 1 auto;
  display: ${({ $isErrorPage }) => ($isErrorPage ? 'flex' : '')};
  align-items: ${({ $isErrorPage }) => ($isErrorPage ? 'center' : '')};
  justify-content: ${({ $isErrorPage }) => ($isErrorPage ? 'center' : '')};
  margin-top: 60px;
`

export const CentredMain = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  background: ${({ theme }) => theme.palette.background.default};
  margin-top: 60px;
`
