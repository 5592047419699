import React from 'react'

import { TenantType } from '@models/common/app'
import { useAppSelector } from '@hooks/redux'
import { selectConfig } from '@state/config'

interface TenantSpecificComponentProps {
  desiredTenant: TenantType | TenantType[]
}

export const TenantSpecificComponent: React.FC<TenantSpecificComponentProps> = ({
  children,
  desiredTenant,
}) => {
  const { tenant } = useAppSelector(selectConfig)
  const hasMatchTenant = Array.isArray(desiredTenant)
    ? desiredTenant.includes(tenant?.type!)
    : desiredTenant === tenant?.type

  return hasMatchTenant ? <>{children}</> : null
}
