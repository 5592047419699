import { AxiosRequestConfig } from 'axios'

import { axiosInstance as axios } from '@configs/axios.config'

const request = async <T>(config: AxiosRequestConfig): Promise<T> => {
  try {
    const response = await axios.request<T>(config)

    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const http = {
  get: <T>(url: string, config: AxiosRequestConfig = {}): Promise<T> => {
    return request<T>({
      url,
      method: 'GET',
      ...config,
    })
  },
  post: <T, D>(url: string, data: D, config: AxiosRequestConfig<D> = {}): Promise<T> => {
    return request<T>({
      url,
      method: 'POST',
      ...config,
      data,
    })
  },
  put: <T, D>(url: string, data: D, config: AxiosRequestConfig<D> = {}): Promise<T> => {
    return request<T>({
      url,
      method: 'PUT',
      ...config,
      data,
    })
  },
  patch: <T, D>(url: string, data: D, config: AxiosRequestConfig<D> = {}): Promise<T> => {
    return request<T>({
      url,
      method: 'PATCH',
      ...config,
      data,
    })
  },
  delete: <T, D>(url: string, data?: D): Promise<T> => {
    return request<T>({
      url,
      method: 'DELETE',
      data,
    })
  },
}
