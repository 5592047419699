import ContentLoader from 'react-content-loader'

export const PackagesTableLoader: React.FC = props => {
  return (
    <ContentLoader
      speed={1.8}
      width='100%'
      height='100%'
      viewBox='0 0 1426 1015'
      backgroundColor='#f3f3f3'
      foregroundColor='#e3e3e3'
      {...props}
    >
      <path d='M 0 124 h 1426 v 2 H 0 z M 0 58 h 2 v 66 H 0 z M 1424 58 h 2 v 66 h -2 z' />
      <rect x='34' y='81' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='81' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='81' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='81' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='81' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='81' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='81' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 192 h 1426 v 2 H 0 z M 0 126 h 2 v 66 H 0 z M 1424 126 h 2 v 66 h -2 z' />
      <rect x='34' y='149' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='149' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='149' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='149' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='149' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='149' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='149' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 260 h 1426 v 2 H 0 z M 0 194 h 2 v 66 H 0 z M 1424 194 h 2 v 66 h -2 z' />
      <rect x='34' y='217' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='217' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='217' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='217' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='217' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='217' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='217' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 328 h 1426 v 2 H 0 z M 0 262 h 2 v 66 H 0 z M 1424 262 h 2 v 66 h -2 z' />
      <rect x='34' y='285' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='285' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='285' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='285' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='285' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='285' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='285' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 396 h 1426 v 2 H 0 z M 0 330 h 2 v 66 H 0 z M 1424 330 h 2 v 66 h -2 z' />
      <rect x='34' y='353' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='353' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='353' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='353' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='353' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='353' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='353' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 464 h 1426 v 2 H 0 z M 0 398 h 2 v 66 H 0 z M 1424 398 h 2 v 66 h -2 z' />
      <rect x='34' y='421' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='421' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='421' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='421' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='421' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='421' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='421' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 532 h 1426 v 2 H 0 z M 0 466 h 2 v 66 H 0 z M 1424 466 h 2 v 66 h -2 z' />
      <rect x='34' y='489' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='489' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='489' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='489' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='489' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='489' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='489' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 600 h 1426 v 2 H 0 z M 0 534 h 2 v 66 H 0 z M 1424 534 h 2 v 66 h -2 z' />
      <rect x='34' y='557' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='557' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='557' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='557' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='557' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='557' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='557' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 668 h 1426 v 2 H 0 z M 0 602 h 2 v 66 H 0 z M 1424 602 h 2 v 66 h -2 z' />
      <rect x='34' y='625' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='625' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='625' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='625' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='625' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='625' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='625' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 736 h 1426 v 2 H 0 z M 0 670 h 2 v 66 H 0 z M 1424 670 h 2 v 66 h -2 z' />
      <rect x='34' y='693' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='693' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='693' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='693' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='693' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='693' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='693' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 804 h 1426 v 2 H 0 z M 0 738 h 2 v 66 H 0 z M 1424 738 h 2 v 66 h -2 z' />
      <rect x='34' y='761' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='761' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='761' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='761' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='761' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='761' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='761' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 872 h 1426 v 2 H 0 z M 0 806 h 2 v 66 H 0 z M 1424 806 h 2 v 66 h -2 z' />
      <rect x='34' y='829' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='829' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='829' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='829' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='829' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='829' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='829' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 940 h 1426 v 2 H 0 z M 0 874 h 2 v 66 H 0 z M 1424 874 h 2 v 66 h -2 z' />
      <rect x='34' y='897' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='897' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='897' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='897' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='897' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='897' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='897' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 1013 h 1426 v 2 H 0 z M 0 945 h 2 v 68 H 0 z M 1424 945 h 2 v 68 h -2 z' />
      <rect x='34' y='970' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='970' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='970' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='970' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='970' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='970' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='970' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 10 C 0 4.477 4.477 0 10 0 h 1406 c 5.52 0 10 4.477 10 10 v 9 H 0 v -9 z M 0 39 h 1426 v 19 H 0 z M 0 19 h 34 v 20 H 0 z M 54 19 h 32 v 20 H 54 z M 172 19 h 32 v 20 h -32 z M 392 19 h 32 v 20 h -32 z M 612 19 h 32 v 20 h -32 z M 832 19 h 32 v 20 h -32 z M 1052 19 h 32 v 20 h -32 z M 1394 19 h 32 v 20 h -32 z' />
    </ContentLoader>
  )
}
