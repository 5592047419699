import React from 'react'
import { Typography } from '@mui/material'
import { useDispatch } from 'react-redux'

import { sampleCreate, setCreateSampleNotification } from '@state/sampleCreate'
import { useAppSelector } from '@hooks/redux'
import { MAX_TEMPERATURE, MIN_TEMPERATURE, TEMPERATURE_FORMAT } from '@constants/samples'
import iconError from '@assets/icons/notification-error.svg'

import {
  ButtonBox,
  IconClose,
  NotificationIcon,
  StyledBox,
  StyledButton,
  StyledIconButton,
  StyledNotification,
} from './styles'

interface ErrorNotificationProps {
  draggableHorizontalRef: React.RefObject<HTMLDivElement>
}

export const ErrorNotification: React.FC<ErrorNotificationProps> = ({ draggableHorizontalRef }) => {
  const { showNotification, samples } = useAppSelector(sampleCreate)
  const dispatch = useDispatch()

  const [currentFixingError, setCurrentFixingError] = React.useState(-1)
  const [errorsList, setErrorsList] = React.useState<string[]>([])
  const [prevId, setPrevId] = React.useState('')

  const countOfErrors = errorsList.length

  const setPosition = (x: number, y: number) => {
    draggableHorizontalRef.current?.scrollTo(x, 0)
    window.scrollTo(0, y)
  }

  const getElementPosition = (id: string) => {
    setPosition(0, 0)

    const prevElement = document.getElementById(prevId)?.querySelector('fieldset')
    if (prevElement) prevElement.style.borderWidth = '1px'

    const element = document.getElementById(id)?.querySelector('fieldset')
    if (element) element.style.borderWidth = '2px'
    setPrevId(id)

    const { x, y } = document.getElementById(id)?.getBoundingClientRect() as {
      x: number
      y: number
    }

    const offsetHeight = document.getElementById(id)?.offsetHeight || 0
    const offsetWidth = document.getElementById(id)?.offsetWidth || 0

    const margin =
      (document.getElementsByTagName('main')[0].firstChild as HTMLElement)?.offsetLeft + 35

    return { x: x + offsetWidth / 2 - margin, y: y + offsetHeight / 2 }
  }

  const next = () => {
    let newCurrentFixingError = currentFixingError
    if (currentFixingError >= errorsList.length - 1) newCurrentFixingError = 0
    else newCurrentFixingError = currentFixingError + 1

    const { x, y } = getElementPosition(errorsList[newCurrentFixingError])
    setPosition(
      x - (draggableHorizontalRef.current?.offsetWidth || 0) / 2,
      y - window.innerHeight / 2
    )

    setCurrentFixingError(newCurrentFixingError)
  }

  const previous = () => {
    let newCurrentFixingError = currentFixingError
    if (currentFixingError <= 0) newCurrentFixingError = errorsList.length - 1
    else newCurrentFixingError = currentFixingError - 1

    const { x, y } = getElementPosition(errorsList[newCurrentFixingError])
    setPosition(
      x - (draggableHorizontalRef.current?.offsetWidth || 0) / 2,
      y - window.innerHeight / 2
    )

    setCurrentFixingError(newCurrentFixingError)
  }

  React.useEffect(() => {
    if (errorsList.length < 1) {
      const prevElement = document.getElementById(prevId)?.querySelector('fieldset')
      if (prevElement) prevElement.style.borderWidth = '1px'
      dispatch(setCreateSampleNotification(false))
    }
  }, [errorsList.length, dispatch, prevId])

  React.useEffect(() => {
    setErrorsList([])
    const newErrorsList: string[] = []
    samples.forEach((item: any) => {
      for (let key in item) {
        if (item[key].error) {
          if (key === MIN_TEMPERATURE || key === MAX_TEMPERATURE) {
            newErrorsList.push(item.id + '_' + TEMPERATURE_FORMAT)
          } else {
            newErrorsList.push(item.id + '_' + key)
          }
        }
      }

      setErrorsList(newErrorsList)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samples])

  return (
    <>
      {errorsList.length >= 1 && showNotification && (
        <StyledNotification in={true}>
          <StyledIconButton
            onClick={() => dispatch(setCreateSampleNotification(false))}
            size='small'
          >
            <IconClose />
          </StyledIconButton>

          <StyledBox>
            <NotificationIcon src={iconError} alt='status-icon' />
            {countOfErrors > 1 ? (
              <Typography fontSize='16px' fontWeight='500'>
                Please correct{' '}
                <Typography component='span' color='#AF4645'>
                  {countOfErrors} errors
                </Typography>{' '}
                in some fields before completing the sample creation{' '}
              </Typography>
            ) : (
              <Typography fontSize='16px' fontWeight='500'>
                Please correct{' '}
                <Typography component='span' color='#AF4645'>
                  1 error
                </Typography>{' '}
                in field before completing the sample creation
              </Typography>
            )}
          </StyledBox>

          {countOfErrors > 1 ? (
            <ButtonBox>
              <StyledButton onClick={previous} variant='text'>
                <Typography component='span' color='#8f929b' fontSize='14px'>
                  Previous
                </Typography>
              </StyledButton>
              <StyledButton sx={{ marginLeft: '6px' }} onClick={next}>
                Next
              </StyledButton>
            </ButtonBox>
          ) : (
            <ButtonBox>
              <StyledButton onClick={next}>Correct</StyledButton>
            </ButtonBox>
          )}
        </StyledNotification>
      )}
    </>
  )
}
