import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { IOption, IError } from '@models/common/app'
import { addNewPharmacy } from '@api/samples'
import { PHARMACIES } from '@constants/queries'
import { setMaintenanceModePage } from '@state/user'

export const useAddNewPharmacy = () => {
  const dispatch = useDispatch()
  const client = useQueryClient()

  const mutation = useMutation((name: string) => addNewPharmacy(name), {
    onMutate: () => client.getQueryData<IOption[]>(PHARMACIES),
    onSuccess: (newPharmacy, vars, prevPharmacies) => {
      if (prevPharmacies) client.setQueryData([PHARMACIES], [...prevPharmacies, newPharmacy])
    },
    onSettled: () => client.invalidateQueries(PHARMACIES),
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
