import React from 'react'
import { OutlinedTextFieldProps } from '@mui/material'

import { StyledInput } from './styles'

export interface InputProps extends OutlinedTextFieldProps {
  dataTestId?: string
  width?: string
  height?: number
  bgColor?: string
  bgColorDefault?: boolean
  maintenanceModeColor?: boolean
  mr?: string
  ml?: string
  $isMobile?: boolean
  mb?: string
  pr?: string
  mrErr?: string
  borderRadius?: string
  borderColor?: string
  $helperTextNoWrap?: boolean
  $textColor?: string
  maxWidth?: string
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return <StyledInput ref={ref} {...props} />
})
