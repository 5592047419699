import React from 'react'

import { IPackages } from '@models/packages'
import { Checkbox } from '@components/shared'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { MAX_SELECTED_PACKAGE_NUMBER } from '@constants/packages'
import { selectPackages, selectPackagesState } from '@state/packages'
import { StyledTableCell } from '@styles'

interface PackagesTogglerProps {
  packages: IPackages
}

export const PackagesToggler: React.FC<PackagesTogglerProps> = ({ packages }) => {
  const { selectedPackages } = useAppSelector(selectPackagesState)
  const dispatch = useAppDispatch()

  const filledPacks = packages.filter(pack => !!pack.quantity)
  const selectedPackagesLength = selectedPackages.length
  const filledPacksLength = filledPacks.length

  const needsToBeShownIndeterminateIcon =
    selectedPackagesLength > 0 &&
    ((selectedPackagesLength !== filledPacksLength &&
      selectedPackagesLength < MAX_SELECTED_PACKAGE_NUMBER) ||
      (filledPacksLength < MAX_SELECTED_PACKAGE_NUMBER &&
        selectedPackagesLength < filledPacksLength))

  const needsToBeShownCheckedIcon =
    filledPacksLength > 0 &&
    (selectedPackagesLength === MAX_SELECTED_PACKAGE_NUMBER ||
      selectedPackagesLength === filledPacksLength)

  const checkedAllPackagesHandler = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) {
      const selectedPacks = filledPacks.slice(0, MAX_SELECTED_PACKAGE_NUMBER)

      dispatch(selectPackages(selectedPacks))
    } else dispatch(selectPackages([]))
  }

  return (
    <StyledTableCell>
      <Checkbox
        ml='44px'
        padding='0'
        indeterminate={needsToBeShownIndeterminateIcon}
        checked={needsToBeShownCheckedIcon}
        onChange={checkedAllPackagesHandler}
      />
    </StyledTableCell>
  )
}
