import { useHistory } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { createSamples } from '@api/samples'
import { SAMPLES } from '@constants/queries'
import { INewSamples } from '@models/samples'
import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'
import { protectedRoutes } from '@configs/routes.config'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'
import { setIsChangedField } from '@state/samples'

export const useCreateSamples = () => {
  const dispatch = useDispatch()
  const client = useQueryClient()
  const history = useHistory()

  const mutation = useMutation<unknown, IError, INewSamples>(createSamples, {
    onSuccess: (data, vars) => {
      if (vars.length > 1) showNotification(notifications.samplesCreatedSuccessfully)
      else showNotification(notifications.sampleCreatedSuccessfully)
      history.push(protectedRoutes.samples.path)
    },
    onError: (error, vars) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
      if (vars.length > 1) showNotification(notifications.samplesCreatedError)
      else showNotification(notifications.sampleCreatedError)
    },
    onSettled: () => {
      client.invalidateQueries(SAMPLES)
      dispatch(setIsChangedField(false))
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
