import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'
import { fetchTestTypes } from '@api/common'
import { TEST_TYPES } from '@constants/queries'

export const useFetchTestTypes = (isAuthorized?: boolean, availableTestTypes?: boolean) => {
  const dispatch = useDispatch()

  return useQuery([TEST_TYPES, availableTestTypes], fetchTestTypes, {
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    enabled: isAuthorized,
  })
}
