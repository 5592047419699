import React from 'react'
import { Typography, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { ISample } from '@models/samples'
import { SampleOptionsContext } from '@context'
import { MAIN_APIS_REQUIRED, MAX_CHARACTERS, REQUIRED_FIELD } from '@constants/validation'
import { ApiField, ExcipientsField, Input, Funding } from '@components/shared/sampleFields'
import {
  DOSAGE,
  DOSAGE_TYPE,
  DRUG_CLASS,
  MAIN_APIS,
  NOTES,
  EXCIPIENTS,
  REGULATORY_REGISTRATION,
  TOXICITY_LEVEL,
  STORAGE_NOTES,
  TEMPERATURE_FORMAT,
  PROJECT,
  FUNDING,
} from '@constants/samples'
import { StyledTitle } from '@styles'
import { DropdownInput } from '@components/shared/sampleFields/DropdownInput'
import { RadioButtons } from '@components/shared/sampleFields/RadioButtons'
import { DrugClassesField, Project } from '@components/shared/sampleFields/createSampleCells'
import {
  StorageNotes,
  StorageTemperature,
  Toxicity,
} from '@components/modules/SampleCreate/components/TableCells'
import { useAppSelector } from '@hooks/redux'
import { selectedApis } from '@state/app'
import { checkSkills } from '@helpers/obtainingSkills'

import { MainInfoLoader } from '../../Loaders'
import { StyledForm, StyledLabel } from '../styles'

interface EditMainInfoProps {
  sample: ISample
}

export const EditMainInfo: React.FC<EditMainInfoProps> = ({ sample }) => {
  const { isLoading, dosageType, regulatoryRegistrationsType } =
    React.useContext(SampleOptionsContext)
  const { clearErrors } = useFormContext()
  const editSampleApisValues = useAppSelector(selectedApis)

  const { isSampleTemperatureSensitive, isSampleToxic } = checkSkills(editSampleApisValues)
  const isStorageNotesHidden = !isSampleTemperatureSensitive && !isSampleToxic

  const fields = [
    {
      label: 'Drug class',
      name: DRUG_CLASS,
      field: DrugClassesField,
      pr: '32px',
      rules: { required: REQUIRED_FIELD },
      styles: { left: '85px' },
      isComponentHidden: false,
    },

    {
      label: 'Dosage',
      name: DOSAGE,
      dropdownName: DOSAGE_TYPE,
      field: DropdownInput,
      dropdownOptions: [...dosageType].reverse(),
      pr: '68px',
      rules: {
        required: REQUIRED_FIELD,
        maxLength: {
          value: 13,
          message: 'Max 13 characters',
        },
        min: {
          value: 0,
          message: `The value cannot be negative`,
        },
        validate: {
          digit: (v: string) => {
            return !isNaN(Number(v)) || 'Format must be either 1 or 1.01'
          },
          dosageIntFormat: (v: string) => {
            return (
              /^\d{1,6}$|^\d{1,6}\.\d{1,6}$/.test(v) ||
              'Maximum number of characters 6 after dot and 6 before dot'
            )
          },
          greaterThanMinValue: (v: string) => {
            return Number(v) >= 0.000001 || 'Ensure this value is greater than or equal to 0.000001'
          },
        },
      },
      styles: { left: '64px' },
      isComponentHidden: false,
    },

    {
      label: 'Main APIs',
      name: MAIN_APIS,
      field: ApiField,
      rules: { required: MAIN_APIS_REQUIRED },
      styles: { left: '83px' },
      isComponentHidden: false,
    },

    {
      label: 'Excipients',
      name: EXCIPIENTS,
      field: ExcipientsField,
      isComponentHidden: false,
    },

    {
      label: 'Toxicity',
      name: TOXICITY_LEVEL,
      field: Toxicity,
      initialVal: { val: ' ', error: '' },
      isEditing: true,
      isComponentHidden: !isSampleToxic,
    } as any,

    {
      label: 'Storage temperature',
      name: TEMPERATURE_FORMAT,
      field: StorageTemperature,
      initialVal: { val: ' ', error: '' },
      isEditing: true,
      clearErrors: clearErrors,
    } as any,

    {
      label: 'Storage notes',
      name: STORAGE_NOTES,
      initialVal: { val: ' ', error: '' },
      rules: {
        maxLength: {
          value: 500,
          message: MAX_CHARACTERS(500),
        },
      },
      rows: 3,
      field: StorageNotes,
      isEditing: true,
      isComponentHidden: isStorageNotesHidden,
    },

    {
      label: 'Regulatory registration status in the country',
      name: REGULATORY_REGISTRATION,
      field: RadioButtons,
      options: regulatoryRegistrationsType,
      isComponentHidden: false,
    },

    {
      label: 'Project',
      name: PROJECT,
      field: Project,
      pr: '32px',
      isComponentHidden: false,
      funding: sample?.funding,
      projectName: sample?.project?.name,
    },
    {
      label: 'Funding',
      name: FUNDING,
      field: Funding,
      sample: sample,
      isComponentHidden: false,
    },

    {
      label: 'Sample notes',
      name: NOTES,
      field: Input,
      rows: 3,
      multiline: true,
      rules: {
        maxLength: {
          value: 150,
          message: MAX_CHARACTERS(150),
        },
      },
      isComponentHidden: false,
    },
  ]

  if (isLoading) return <MainInfoLoader />

  return (
    <StyledForm>
      <StyledTitle variant='h3' mb='28px' fz={24}>
        Main info
      </StyledTitle>

      {fields.map(({ field: Field, label, isComponentHidden, ...props }, idx) => {
        return (
          <Box sx={{ position: 'relative' }} key={props.name}>
            {idx === fields.length - 1 && (
              <>
                <StyledTitle variant='h3' mb='28px' fz={24}>
                  Identification
                </StyledTitle>

                <Typography variant='h5'>Sample ID</Typography>
                <Typography mb='43px'>{sample.specific_id}</Typography>
              </>
            )}

            {props.rules?.required && !isComponentHidden && (
              <Typography
                variant='body1'
                color='#616D84'
                sx={{ position: 'absolute', left: props.styles?.left }}
              >
                (Required)
              </Typography>
            )}
            {label === 'Storage temperature' ? (
              <Field {...props} />
            ) : (
              !isComponentHidden && (
                <StyledLabel
                  key={props.name}
                  label={label}
                  control={<Field {...props} />}
                  sx={{ position: 'relative' }}
                />
              )
            )}
          </Box>
        )
      })}
    </StyledForm>
  )
}
