import React from 'react'

import { useAppSelector } from '@hooks/redux'
import { selectSamplesState } from '@state/samples'
import { Button } from '@components/shared'
import { AddTests } from '@components/shared/AddTests'
import { LIMITATION_OF_MULTIPLE_TESTS, MAX_TASKS_TO_ADD } from '@constants/tasks'
import { PureTooltip } from '@components/ui/PureTooltip'
import { ISamples } from '@models/samples'

interface AddTestsActiveBtnProps {
  samples: ISamples
  selectedSamples: number[]
  selectedSamplesAmount: number
  isFetching: boolean
}

export const AddTestsActiveBtn: React.FC<AddTestsActiveBtnProps> = props => {
  const { selectedSamplesAmount, selectedSamples, isFetching, samples } = props
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const { expandedSamples } = useAppSelector(selectSamplesState)

  const selectedSampleFromBE = samples.filter(sample => selectedSamples.includes(sample.id))

  const greatestTasksAmount: number = React.useMemo(() => {
    if (selectedSampleFromBE.length) {
      const biggestSample = selectedSampleFromBE.reduce((max, obj) => {
        return obj.tasks_count > max.tasks_count ? obj : max
      })
      return biggestSample.tasks_count
    }
    return 0
  }, [selectedSampleFromBE])

  const tooltipTitle =
    selectedSamples.length > LIMITATION_OF_MULTIPLE_TESTS
      ? `Tests can be added to a maximum of ${LIMITATION_OF_MULTIPLE_TESTS} samples at a time`
      : `A maximum of ${MAX_TASKS_TO_ADD} tests can be added`

  return (
    <>
      <PureTooltip
        title={tooltipTitle}
        shouldBeHidden={
          !(greatestTasksAmount >= 10) && !(selectedSamples.length > LIMITATION_OF_MULTIPLE_TESTS)
        }
        titleStyle={{ width: 170 }}
      >
        <Button
          sx={{ marginRight: '16px' }}
          variant='outlined'
          height='48px'
          onClick={() => setShowModal(true)}
          bgColor='#ffffff'
          disabled={
            selectedSamples.length > LIMITATION_OF_MULTIPLE_TESTS ||
            isFetching ||
            greatestTasksAmount >= 10
          }
          textColor='#616D84'
        >
          Add tests ({selectedSamplesAmount}/{LIMITATION_OF_MULTIPLE_TESTS})
        </Button>
      </PureTooltip>
      {showModal && (
        <AddTests
          expandedSamples={expandedSamples}
          greatestTasksAmount={greatestTasksAmount}
          selectedSamples={selectedSamples}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}
