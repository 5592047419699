import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { addSamplesIntoPackage } from '@api/packages'
import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'
import { SAMPLES_IN_PACKAGE, SAMPLES } from '@constants/queries'
import { IAddSamplesIntoPackageData } from '@models/packages'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useAddSamplesIntoPackage = (packageId: string) => {
  const dispatch = useDispatch()
  const client = useQueryClient()

  const mutation = useMutation<unknown, IError, IAddSamplesIntoPackageData>(addSamplesIntoPackage, {
    onSuccess: (data, vars) => {
      client.invalidateQueries([SAMPLES_IN_PACKAGE, packageId])
      client.removeQueries(SAMPLES)
      client.invalidateQueries(['packagesBlockchainHistory'])
      showNotification(notifications.addedSamplesSuccessfully)
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
      showNotification(notifications.addedSamplesFailed)
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
