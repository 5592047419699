import { differenceInDays, startOfToday } from 'date-fns'
import { Typography, useMediaQuery } from '@mui/material'

import { selectUser } from '@state/user'
import { Tooltip } from '@components/shared'
import { TooltipMobile } from '@components/shared/Tooltip/components'
import { useAppSelector } from '@hooks/redux'
import { datePatterns } from '@constants/dates'
import { getFormattedDate } from '@helpers/ui/date'
import { DataBox, DataIcon } from '@styles'
import infoIcon from '@assets/icons/info-circle.svg'

interface ExpirationDateProps {
  expirationDate: string | null
  alignSelf?: string
}

export const ExpirationDate: React.FC<ExpirationDateProps> = ({ expirationDate, alignSelf }) => {
  const { timezone } = useAppSelector(selectUser)
  const isMobile = useMediaQuery('(max-width:767px)')

  const sampleExpirationDays = expirationDate
    ? differenceInDays(new Date(expirationDate), startOfToday())
    : -1

  const sampleExpirationDate = getFormattedDate(datePatterns.monthYear, expirationDate, timezone)

  return (
    <>
      {expirationDate ? (
        <DataBox aria-haspopup $alignSelf={alignSelf} $isMobile={isMobile}>
          {!isMobile ? sampleExpirationDate : ''}

          {sampleExpirationDays <= 7 && (
            <>
              {isMobile ? (
                <TooltipMobile
                  withoutTruncateIcon
                  mlIcon='8px'
                  title={
                    sampleExpirationDays < 0
                      ? 'Sample has expired'
                      : `An expiration date of this sample ends ${
                          sampleExpirationDays > 0 ? `in ${sampleExpirationDays} days` : 'today'
                        }`
                  }
                  placement='top-end'
                >
                  <Typography>{sampleExpirationDate}</Typography>
                </TooltipMobile>
              ) : (
                <Tooltip
                  isPureTooltip
                  positionLeft={-3}
                  maxWidth={210}
                  positionTop={0}
                  title={
                    sampleExpirationDays < 0
                      ? 'Sample has expired'
                      : `An expiration date of this sample ends ${
                          sampleExpirationDays > 0 ? `in ${sampleExpirationDays} days` : 'today'
                        }`
                  }
                >
                  <DataIcon src={infoIcon} alt='Warning' />
                </Tooltip>
              )}
            </>
          )}
        </DataBox>
      ) : (
        '-'
      )}
    </>
  )
}
