import React from 'react'
import { CircularProgress, Stack, Typography } from '@mui/material'

import { ISample } from '@models/samples'
import { TempSensitiveToxicityIcons, Tooltip } from '@components/shared'
import { SpecificId } from '@components/shared/sampleFields'
import { useAppSelector } from '@hooks/redux'
import { selectSamplesSplit } from '@state/samples'
import { ReactComponent as MissedIcon } from '@assets/icons/info-circle-xs.svg'
import { ReactComponent as RetestIcon } from '@assets/icons/retest.svg'
import { StyledLabIcon } from '@styles'
import { protectedRoutes } from '@configs/routes.config'

import { StyledTableCell } from '../styles'
import { StyledRoundBadge } from './styles'

interface SampleIDProps {
  sample: ISample
}

export const SampleID: React.FC<SampleIDProps> = ({ sample }) => {
  const {
    id: sampleId,
    icon: iconDescription,
    specific_id: specificId,
    icon_tooltip: iconTooltip,
    is_lab_icon: isLabIcon,
    icon_text_color: iconTextColor,
    icon_background_color: iconBgColor,
    initial_laboratory,
    laboratory,
  } = sample

  const { isSplitting, currentSplitSampleId } = useAppSelector(selectSamplesSplit)

  const isCurrentSampleSplitting = isSplitting && currentSplitSampleId === sampleId

  const renderIcon = React.useMemo(() => {
    if (iconDescription && isLabIcon) {
      return (
        <StyledLabIcon
          color={iconTextColor}
          bgColor={iconBgColor}
          width={'32px'}
          top='-5px'
          left='-35px'
        >
          <Typography fontSize={9} fontWeight={600}>
            {iconDescription}
          </Typography>
        </StyledLabIcon>
      )
    } else if (iconDescription === 'missed') {
      return (
        <MissedIcon width='18px' height='18px' style={{ position: 'absolute', left: '-26px' }} />
      )
    } else if (iconDescription === 'retest') {
      return (
        <RetestIcon
          width='18px'
          height='18px'
          style={{ position: 'absolute', left: '-26px', top: '-8px' }}
        />
      )
    } else {
      return <StyledRoundBadge $color={iconBgColor} />
    }
  }, [iconDescription, iconBgColor, iconTextColor, isLabIcon])

  return (
    <StyledTableCell sx={{ position: 'relative' }}>
      <Stack
        alignItems='flex-start'
        justifyContent='start'
        flexDirection='row'
        ml='17px'
        sx={{ width: 'calc(100% - 28px)' }}
      >
        {iconDescription && (
          <Tooltip
            isPureTooltip
            title={iconTooltip}
            placement='top-start'
            maxWidth={210}
            positionLeft={20}
            positionTop={15}
          >
            {renderIcon}
          </Tooltip>
        )}
        <SpecificId
          specificId={specificId || '-'}
          labelTooltip={initial_laboratory?.label_tooltip || laboratory?.label_tooltip}
          typographyStyles={{ marginLeft: '8px', width: '100%', display: 'flex' }}
          laboratory={initial_laboratory || laboratory}
          pathForLink={protectedRoutes.samples.path}
          titleForPathLink='Sample list'
        />

        <TempSensitiveToxicityIcons
          toxicity={sample.toxicity_level}
          minTempSensitive={sample.min_temperature}
          maxTempSensitive={sample.max_temperature}
          temperatureMeasurementFormat={sample.temperature_measurement_format?.key}
          mt='-5px'
        />

        {isCurrentSampleSplitting && (
          <CircularProgress sx={{ position: 'absolute', right: '15px' }} size={16} />
        )}
      </Stack>
    </StyledTableCell>
  )
}
