import {
  LOT,
  TOWN,
  BRAND,
  NOTES,
  DOSAGE,
  COUNTRY,
  PHARMACY,
  MAIN_APIS,
  EXCIPIENTS,
  DRUG_CLASS,
  BUYERS_NAME,
  DOSAGE_TYPE,
  MANUFACTURER,
  PHARMACY_GPS,
  PURCHASE_DATE,
  EXPIRATION_DATE,
  SAMPLE_CREATION,
  MANUFACTURE_DATE,
  REGULATORY_REGISTRATION,
  UNITS,
  UNIT_TYPE,
  VALUE,
  VALUE_TYPE,
  ADDRESS_OF_MANUFACTURER,
} from '@constants/samples'
import { IOption } from '@models/common/app'
import { PlaceType } from '@components/shared/sampleFields/GMPlaceAutocomplete'

export const generateNewSample = () => {
  return {
    [BRAND]: null,
    [BUYERS_NAME]: '',
    [COUNTRY]: null,
    [DRUG_CLASS]: null,
    [DOSAGE]: '',
    [DOSAGE_TYPE]: 1,
    [EXPIRATION_DATE]: null,
    [UNITS]: null,
    [UNIT_TYPE]: 1,
    [VALUE]: null,
    [VALUE_TYPE]: 159,
    [LOT]: '',
    [MAIN_APIS]: [],
    [EXCIPIENTS]: [],
    [MANUFACTURE_DATE]: null,
    [MANUFACTURER]: null,
    [ADDRESS_OF_MANUFACTURER]: null,
    [NOTES]: '',
    [PHARMACY]: null,
    [PHARMACY_GPS]: '',
    [REGULATORY_REGISTRATION]: 1,
    [PURCHASE_DATE]: new Date(),
    [TOWN]: null,
    isCopy: false,
  }
}

export interface IGeneratedSample {
  [DRUG_CLASS]: IOption | null
  [DOSAGE]: string | null
  [DOSAGE_TYPE]: number
  [VALUE]: number | null
  [VALUE_TYPE]: number
  [NOTES]: string
  [LOT]: string
  [BUYERS_NAME]: string
  [PURCHASE_DATE]: Date | null
  [TOWN]: IOption | null
  [PHARMACY]: IOption | null
  [PHARMACY_GPS]: string
  [MANUFACTURER]: IOption | null
  [ADDRESS_OF_MANUFACTURER]: PlaceType | null
  [BRAND]: IOption | null
  [MAIN_APIS]: IOption[]
  [EXCIPIENTS]: IOption[]
  [MANUFACTURE_DATE]: Date | null
  [EXPIRATION_DATE]: Date | null
  [COUNTRY]: IOption | null
  [REGULATORY_REGISTRATION]: number | null
  [UNITS]: number | null
  [UNIT_TYPE]: number
  isCopy: boolean
}

export type SampleCreationForm = { [SAMPLE_CREATION]: IGeneratedSample[] }

export const conversionSampleOptions = [DRUG_CLASS, PHARMACY, MANUFACTURER, BRAND, COUNTRY]

export const numerateOptions = (options?: IOption[]) => {
  if (!options) return

  let counter = 0
  return options?.map((option, id) => {
    // Skip the Unknown option and start numeration with the next option
    if (id > 0) {
      counter++
      return { ...option, name: counter + '. ' + option.name }
    }
    return option
  })
}
