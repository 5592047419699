import { Fragment, useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import { Link } from '@mui/material'

import { IEntitiesBlockchain } from '@models/common/app'

import { ActionsTypesProps } from './Actions.types'

export const ActionWithLink: React.FC<ActionsTypesProps> = props => {
  const { entities, action } = props

  const linkText = useCallback(
    (entity: IEntitiesBlockchain) => {
      return action.slice(entity.offset, entity.offset + entity.length)
    },
    [action]
  )

  return (
    <>
      {entities.map(entity => (
        <Fragment key={uuid()}>
          {/* start text without link (offset from BE) */}
          {action.slice(0, entity.offset)}
          {/* the rest of the text with link */}
          <Link
            target={entity.additional_data?.status?.key ? '' : '_blank'}
            href={entity.additional_data?.url}
          >
            {/* It is the rest of the action (length of all action from BE) except start text  */}
            {linkText(entity)}
          </Link>
          {action.includes('missed') && action.substring(entity.offset + entity.length)}
        </Fragment>
      ))}
    </>
  )
}
