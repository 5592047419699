import { combineReducers } from '@reduxjs/toolkit'

import { queryClient } from '@app'
import { USER } from '@constants/common'

import { tasksReducer } from './tasks'
import { samplesReducer } from './samples'
import { packagesReducer } from './packages'
import { requestsReducer } from './requests'
import { sampleCreateReducer } from './sampleCreate'
import { dropdownStateReducer } from './common/dropdownState'
import { tooltipStateReducer } from './common/tooltipState'
import { sampleCreateUIReducer } from './common/sampleCreateUIState'
import { appReducer, resetAppState } from './app'
import { auth, logout, userReducer } from './user'
import { filtersReducer } from './filters'
import { testReportsReducer } from './testReports'
import { workSpaceReducer } from './workSpace'
import { configReducer } from './config'
import { testReducer } from './test'
import { exportSamplesReducer } from './exportSamples'
import { sessionReducer } from './session'

export const createRootReducer = () => {
  const rootReducerFn = combineReducers({
    app: appReducer,
    user: userReducer,
    config: configReducer,
    exportSamples: exportSamplesReducer,
    samples: samplesReducer,
    packages: packagesReducer,
    sampleCreate: sampleCreateReducer,
    dropdownState: dropdownStateReducer,
    tooltipState: tooltipStateReducer,
    sampleCreateUIState: sampleCreateUIReducer,
    tasks: tasksReducer,
    requests: requestsReducer,
    filters: filtersReducer,
    testReports: testReportsReducer,
    workSpace: workSpaceReducer,
    test: testReducer,
    session: sessionReducer,
  })

  return (
    state: Parameters<typeof rootReducerFn>[0],
    action: Parameters<typeof rootReducerFn>[1]
  ) => {
    const shouldToClean =
      action.type === logout.fulfilled.type ||
      action.type === resetAppState.type ||
      action.type === auth.rejected.type

    if (shouldToClean) {
      localStorage.removeItem(USER)
      queryClient.getQueryCache().clear()
    }

    return rootReducerFn(shouldToClean ? undefined : state, action)
  }
}

export const rootReducer = createRootReducer()
