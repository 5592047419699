import React from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { UseMutationResult, useQueryClient } from 'react-query'
import { Typography, useMediaQuery } from '@mui/material'

import { useAppSelector } from '@hooks/redux'
import { Button } from '@components/shared'
import { ButtonBox, StyledForm, SubText } from '@styles'
import { NOTES } from '@constants/samples'
import { ContentOfPage } from '@constants/queries'
import { Input } from '@components/shared/sampleFields'
import { ITaskNote, IUpdatedTaskNote } from '@models/tasks'
import { selectUser } from '@state/user'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_USER_NAME_IN_NOTES } from '@constants/common'
import { MAX_CHARACTERS } from '@constants/validation'

interface EditNotesFieldProps {
  taskId: number
  note?: ITaskNote
  contentType: ContentOfPage
  toggleEditing: () => void
  withoutTitle?: boolean
  updateTaskNotesM: UseMutationResult<unknown, unknown, IUpdatedTaskNote, unknown>
  isEditNotesTaskInfoInTest?: boolean
  editDetailsNotes?: boolean
}

export const EditTaskNotesField: React.FC<EditNotesFieldProps> = props => {
  const {
    note,
    taskId,
    toggleEditing,
    updateTaskNotesM,
    isEditNotesTaskInfoInTest,
    contentType,
    editDetailsNotes,
  } = props

  const client = useQueryClient()
  const isMobile = useMediaQuery('(max-width:767px)')
  const { first_name: firstName, last_name: lastName } = useAppSelector(selectUser)

  const formMethods = useForm({ defaultValues: { notes: note?.notes } })

  const editHandler: SubmitHandler<IUpdatedTaskNote> = data => {
    const taskNote = !!data.notes ? data : { notes: '' }

    updateTaskNotesM.mutate(
      { ...taskNote, taskId },
      {
        onSettled: () => {
          client.invalidateQueries([contentType])
          toggleEditing()
        },
      }
    )
  }

  const setFormWidth = () => {
    if (isEditNotesTaskInfoInTest && !isMobile) return '480px'
    else if (isMobile) return '100%'
    else if (editDetailsNotes) return '350px'
    else return '190px'
  }

  const ButtonBoxSize = isMobile || isEditNotesTaskInfoInTest ? '100%' : '190px'
  const buttonWidth = isMobile ? '100%' : isEditNotesTaskInfoInTest && !isMobile ? '204px' : '90px'
  const buttonHeight = isMobile || isEditNotesTaskInfoInTest ? '48px' : '32px'
  const buttonFontSize = isMobile || isEditNotesTaskInfoInTest ? 16 : 14
  const buttonMarginTop = isMobile ? '16px' : isEditNotesTaskInfoInTest && !isMobile ? '48px' : ''

  return (
    <>
      <FormProvider {...formMethods}>
        <StyledForm
          onSubmit={formMethods.handleSubmit(editHandler)}
          id='not-expand'
          style={{
            width: setFormWidth(),
            marginBottom: isMobile ? '' : '10px',
          }}
        >
          {!isMobile && !isEditNotesTaskInfoInTest ? (
            <SubText sx={{ whiteSpace: 'nowrap', marginBottom: '2px' }}>
              {stringSlice(`${firstName} ${lastName}`, TRUNCATED_USER_NAME_IN_NOTES)}
            </SubText>
          ) : (
            <Typography mb='9px' mt='24px'>
              {stringSlice(`${firstName} ${lastName}`, TRUNCATED_USER_NAME_IN_NOTES)}
            </Typography>
          )}

          <Input
            name={NOTES}
            rows={editDetailsNotes || isMobile ? 6 : 4}
            multiline={true}
            rules={{
              maxLength: {
                value: 500,
                message: MAX_CHARACTERS(500),
              },
            }}
            mb='8px'
            mrErr='0'
            type='text'
          />

          <ButtonBox width={ButtonBoxSize} $isMobile={isMobile}>
            <Button
              type='button'
              variant='outlined'
              color='secondary'
              bgColor='#fff'
              width={buttonWidth}
              height={buttonHeight}
              mr={isMobile ? '' : '8px'}
              mt={buttonMarginTop}
              sx={{ order: isMobile ? 2 : 1 }}
              fz={buttonFontSize}
              onClick={toggleEditing}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              width={buttonWidth}
              height={buttonHeight}
              mt={buttonMarginTop}
              sx={{ order: isMobile ? 1 : 2 }}
              fz={buttonFontSize}
              loading={updateTaskNotesM.isLoading}
            >
              Save
            </Button>
          </ButtonBox>
        </StyledForm>
      </FormProvider>
    </>
  )
}
