import { Backdrop, IconButton, IconButtonProps } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export interface ModalContentProps {
  isPaddingAbsence?: boolean
  $isMobile?: boolean
  $isMobileHeight?: boolean
  padding?: string
}

export const ModalBackdrop = styledMUI(Backdrop)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(119, 130, 153, 0.5);
`

export const ModalContent = styled.div<ModalContentProps>`
  position: absolute;
  top: ${({ $isMobileHeight }) => ($isMobileHeight ? '75%' : '50%')};
  left: 50%;

  transform: translate(-50%, -50%);
  width: ${({ $isMobile }) => ($isMobile ? '290px' : '')};
  padding: ${({ isPaddingAbsence, $isMobile, padding }) =>
    isPaddingAbsence ? '' : $isMobile ? '40px 25px' : padding ? padding : '64px 90px'};
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid #e5e9f1;
  border-radius: ${({ $isMobile }) => ($isMobile ? '20px' : '10px')};

  @media (max-width: 767px) {
    box-sizing: border-box;
    max-width: calc(100% - 40px);
    width: 100%;
    padding: ${({ isPaddingAbsence }) => (isPaddingAbsence ? '' : '40px 20px')};
  }

  .MuiTypography-body1 {
    @media (max-width: 767px) {
      font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    }
  }
`

interface StyledIconButtonProps extends IconButtonProps {
  $isMobile?: boolean
  $zIndex?: number
}

export const StyledIconButton = styledMUI<React.FC<StyledIconButtonProps>>(IconButton)`
  position: absolute;
  top:  ${({ $isMobile }) => ($isMobile ? '15px' : '20px')};
  right: ${({ $isMobile }) => ($isMobile ? '15px' : '25px')};
  width: 14px;
  height: 14px;
  z-index: ${({ $zIndex }) => $zIndex || '100'};
  padding: 15px;

  @media (max-width: 767px) {
    top: 15px;
    right: 15px;
  }
`
