import ContentLoader from 'react-content-loader'

export const TableLoader: React.FC = props => {
  return (
    <ContentLoader
      speed={1.8}
      width='100%'
      height='100%'
      viewBox='0 0 1426 946'
      backgroundColor='#f3f3f3'
      foregroundColor='#e3e3e3'
      {...props}
    >
      <path d='M 0 204 h 1426 v 2 H 0 z M 0 138 h 2 v 66 H 0 z M 1424 138 h 2 v 66 h -2 z' />
      <rect x='34' y='161' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='161' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='161' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='161' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='161' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='161' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='161' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 272 h 1426 v 2 H 0 z M 0 206 h 2 v 66 H 0 z M 1424 206 h 2 v 66 h -2 z' />
      <rect x='34' y='229' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='229' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='229' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='229' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='229' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='229' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='229' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 340 h 1426 v 2 H 0 z M 0 274 h 2 v 66 H 0 z M 1424 274 h 2 v 66 h -2 z' />
      <rect x='34' y='297' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='297' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='297' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='297' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='297' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='297' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='297' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 408 h 1426 v 2 H 0 z M 0 342 h 2 v 66 H 0 z M 1424 342 h 2 v 66 h -2 z' />
      <rect x='34' y='365' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='365' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='365' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='365' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='365' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='365' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='365' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 476 h 1426 v 2 H 0 z M 0 410 h 2 v 66 H 0 z M 1424 410 h 2 v 66 h -2 z' />
      <rect x='34' y='433' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='433' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='433' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='433' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='433' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='433' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='433' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 544 h 1426 v 2 H 0 z M 0 478 h 2 v 66 H 0 z M 1424 478 h 2 v 66 h -2 z' />
      <rect x='34' y='501' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='501' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='501' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='501' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='501' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='501' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='501' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 612 h 1426 v 2 H 0 z M 0 546 h 2 v 66 H 0 z M 1424 546 h 2 v 66 h -2 z' />
      <rect x='34' y='569' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='569' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='569' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='569' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='569' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='569' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='569' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 680 h 1426 v 2 H 0 z M 0 614 h 2 v 66 H 0 z M 1424 614 h 2 v 66 h -2 z' />
      <rect x='34' y='637' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='637' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='637' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='637' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='637' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='637' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='637' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 748 h 1426 v 2 H 0 z M 0 682 h 2 v 66 H 0 z M 1424 682 h 2 v 66 h -2 z' />
      <rect x='34' y='705' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='705' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='705' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='705' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='705' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='705' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='705' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 816 h 1426 v 2 H 0 z M 0 750 h 2 v 66 H 0 z M 1424 750 h 2 v 66 h -2 z' />
      <rect x='34' y='773' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='773' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='773' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='773' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='773' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='773' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='773' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 884 h 1426 v 2 H 0 z M 0 818 h 2 v 66 H 0 z M 1424 818 h 2 v 66 h -2 z' />
      <rect x='34' y='841' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='841' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='841' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='841' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='841' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='841' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='841' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 952 h 1426 v 2 H 0 z M 0 886 h 2 v 66 H 0 z M 1424 886 h 2 v 66 h -2 z' />
      <rect x='34' y='909' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='909' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='909' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='909' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='909' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='909' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='909' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 1020 h 1426 v 2 H 0 z M 0 954 h 2 v 66 H 0 z M 1424 954 h 2 v 66 h -2 z' />
      <rect x='34' y='977' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='977' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='977' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='977' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='977' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='977' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='977' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 1093 h 1426 v 2 H 0 z M 0 1025 h 2 v 68 H 0 z M 1424 1025 h 2 v 68 h -2 z' />
      <rect x='34' y='1050' rx='4' ry='4' width='20' height='20' />
      <rect x='86' y='1050' rx='4' ry='4' width='86' height='20' />
      <rect x='204' y='1050' rx='4' ry='4' width='188' height='20' />
      <rect x='424' y='1050' rx='4' ry='4' width='188' height='20' />
      <rect x='644' y='1050' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='1050' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='1050' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 90 c 0 -5.523 4.477 -10 10 -10 h 1406 c 5.52 0 10 4.477 10 10 v 9 H 0 v -9 z M 0 119 h 1426 v 19 H 0 z M 0 99 h 34 v 20 H 0 z M 54 99 h 32 v 20 H 54 z M 172 99 h 32 v 20 h -32 z M 392 99 h 32 v 20 h -32 z M 612 99 h 32 v 20 h -32 z M 832 99 h 32 v 20 h -32 z M 1052 99 h 32 v 20 h -32 z M 1394 99 h 32 v 20 h -32 z' />
    </ContentLoader>
  )
}
