import { Box } from '@mui/material'
import { useMemo } from 'react'

import { ActionWithLink } from './ActionWithLink'
import { ActionWithHiddenResult } from './ActionWithHiddenResult'
import { ActionsTypesProps } from './Actions.types'
import { ActionWithTemperature } from './ActionWithTemperature'

export const BlockchainActionBox: React.FC<ActionsTypesProps> = props => {
  const { entities, action } = props
  // offset - index of symbol from start
  // length - number of string length
  // Attached link with name Test2
  // Attached link with name - it's number of offset
  //  Test2 - - it's number of length

  // Attached link with name Test2 was renamed
  //  slice(0, offset)  slice(offset, offset + length) slice(offset + length)

  const renderAction = useMemo(() => {
    if (!!entities.length) {
      if (entities[0].type === 'url' || entities[0].type === 'attachment') {
        // can be string after link
        return <ActionWithLink entities={entities} action={action} />
      } else if (entities[0].type === 'classified') {
        // hidden test result for University
        return <ActionWithHiddenResult entities={entities} action={action} />
      } else if (entities[0].type === 'temperature') {
        // changed storage temperature
        return <ActionWithTemperature entities={entities} action={action} />
      }
    }
    return action
  }, [action, entities])

  return <Box sx={{ color: '#616d84' }}>{renderAction}</Box>
}
