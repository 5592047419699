import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Tenant } from '@models/common/app'

export interface SessionState {
  isLoading: boolean
  isAuthenticated: boolean
}

const initialState: SessionState = {
  isLoading: true,
  isAuthenticated: false,
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setAuthenticated: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticated = payload
      state.isLoading = false
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
  },
})

export const {
  reducer: sessionReducer,
  actions: { setAuthenticated, setLoading },
} = sessionSlice
