import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'

import { StyledParagraph } from '@styles'
import { toxicityTooltip } from '@helpers/ui/samples'
import { ReactComponent as InfoCircle } from '@assets/icons/info-circle-blue.svg'

import { Tooltip } from '../Tooltip'
import { StyledTooltip } from '../Tooltip/components'

interface TemperatureFieldProps {
  toxicityLevelValue?: string
  tooltipPlacement?: 'top-end' | 'top'
  mb?: string
}

export const ToxicityField: React.FC<TemperatureFieldProps> = props => {
  const { toxicityLevelValue, mb, tooltipPlacement } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const theme = useTheme()

  const tooltipText = toxicityLevelValue ? toxicityTooltip(toxicityLevelValue) : ''
  return (
    <>
      {toxicityLevelValue &&
        (isMobile ? (
          <StyledTooltip
            placement='top'
            arrow
            onClick={e => e.stopPropagation()}
            enterTouchDelay={0}
            title={tooltipText}
            sx={{ zIndex: 1500 }}
          >
            <Box display='flex' width='fit-content'>
              <Typography mr='8px'>{toxicityLevelValue}</Typography>
              <InfoCircle fill={theme.palette.primary.main} />
            </Box>
          </StyledTooltip>
        ) : (
          <Tooltip
            title={tooltipText}
            shouldBeHidden={!tooltipText.length}
            placement={tooltipPlacement || 'top-end'}
          >
            <Box display='flex' alignItems='center' width='fit-content' mb={mb}>
              <StyledParagraph mb='0' variant='body1'>
                {toxicityLevelValue}
              </StyledParagraph>
              <InfoCircle fill={theme.palette.primary.main} />
            </Box>
          </Tooltip>
        ))}
    </>
  )
}
