import { viewHrSampleInModalTable, viewSampleInModalTable } from '@constants/mappings'
import { CellsOrdering } from '@components/shared'
import { InfoIconSampleName as SharedInfoIcon } from '@components/shared/Hr'

import { StyledTableCell, StyledTableTitle } from '../styles'

interface TableHeadTitlesProps {
  isVerifyTenant: boolean
}
export const TableHeadTitles: React.FC<TableHeadTitlesProps> = ({ isVerifyTenant }) => {
  return (
    <>
      {isVerifyTenant ? (
        <>
          {viewSampleInModalTable.tableHeadCells.map(({ id, content }) => (
            <StyledTableCell key={id}>
              <StyledTableTitle>{content}</StyledTableTitle>
            </StyledTableCell>
          ))}
        </>
      ) : (
        <>
          {viewHrSampleInModalTable.map(({ id, content, ordering, infoIcon }) => (
            <StyledTableCell key={id}>
              <StyledTableTitle>{content}</StyledTableTitle>
              {ordering && <CellsOrdering ordering={ordering} />}
              {infoIcon && <SharedInfoIcon />}
            </StyledTableCell>
          ))}
        </>
      )}
    </>
  )
}
