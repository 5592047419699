import { styled, List, TableBody, TableBodyProps, IconButton } from '@mui/material'

import { ContentType } from '@models/common/ui'
import { REPORTS } from '@constants/queries'

interface StyledTableBodyProps extends TableBodyProps {
  $contentType?: ContentType
}

export const StyledTableBody = styled<React.FC<StyledTableBodyProps>>(TableBody)`
  & .MuiTableCell-body {
    padding: 15px 0 15px 0;

    &:first-child {
      padding: 15px 0 15px 80px;
    }
    font-size: 16px;
    line-height: 19px;
    border: none;
  }

  & .MuiTableRow-root {
    vertical-align: initial;

    & td:first-child {
      padding: ${({ $contentType }) =>
        $contentType === 'samples' ? '15px 0 0 119px' : '15px 0 0 47px'};
    }
  }

  & .MuiTableRow-root:last-child {
    & .MuiTableCell-body:first-child {
      & .MuiDivider-root {
        height: ${({ $contentType }) => ($contentType === REPORTS ? '0' : '100%')};
      }
    }
  }
`

export const StyledList = styled(List)`
  padding: 6px 0;
  margin: 0;
  padding: 0;

  & .MuiListItemText-root {
    margin: 0;
  }

  & .MuiListItem-root {
    margin: 0;
    padding: 0;

    display: block;
    font-size: 16px;
    line-height: 19px;
    color: #0f1934;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  & .MuiListItemText-secondary {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #616d84;
    white-space: nowrap;
  }
`

export const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50px;
  top: 7px;
  cursor: pointer;
`
