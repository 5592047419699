import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { protectedRoutes } from '@configs/routes.config'
import { useAppDispatch } from '@hooks/redux'
import { completeTest } from '@state/tasks'
import { StepMobile, AttachmentsNotification } from '@components/shared'
import { SCREENING_GROUP } from '@models/tests'
import { isVisualTestGroup } from '@helpers/ui/tests'

import { Testing, Attachments } from './components'

interface TestProps {
  task: ITaskLab
}

export const Test: React.FC<TestProps> = ({ task }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const isMobile = useMediaQuery('(max-width:767px)')
  const isScreeningGroup = task.test_type.group.name === SCREENING_GROUP
  const isTwoStepsInTest = isScreeningGroup || isVisualTestGroup(task.test_type.group.name)

  const checkTest = task.step <= 2

  const steps = [
    {
      id: 1,
      component: Testing,
      isOpen: checkTest,
      actualStep: 1,
      shouldRender: true,
    },
    {
      id: 2,
      component: Attachments,
      isOpen: task.step === 3,
      actualStep: 2,
      shouldRender: true,
    },
  ]

  React.useEffect(() => {
    if (task.step === 4) {
      history.push(protectedRoutes.tasks.path)
      dispatch(completeTest(task.id))
    }
  }, [task.step, history, dispatch, task.id])

  return (
    <>
      {isMobile && (
        <StepMobile
          taskStep={task.step}
          actualStep={isTwoStepsInTest ? 2 : 3}
          isTwoStepsInTest={isTwoStepsInTest}
        />
      )}
      {steps.map(({ component: Step, id, ...restProps }) => (
        <Step key={id} {...restProps} task={task} />
      ))}
      {!isMobile && <AttachmentsNotification task={task} />}
    </>
  )
}
