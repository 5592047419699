import React from 'react'
import { Box } from '@mui/material'

import { Tooltip } from '@components/shared'
import { ISample } from '@models/samples'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'

import { StyledTableCell } from '../styles'
import { StyledTypography } from './styles'

interface ProjectProps {
  sample: ISample
}

export const Project: React.FC<ProjectProps> = ({ sample }) => {
  const { project } = sample

  return (
    <StyledTableCell>
      <Box>
        {project ? (
          <Tooltip title={project.name}>
            <StyledTypography>
              {stringSlice(project.name, TRUNCATED_TEXT_LENGTH_S)}
            </StyledTypography>
          </Tooltip>
        ) : (
          <StyledTypography>{'-'}</StyledTypography>
        )}
      </Box>
    </StyledTableCell>
  )
}
