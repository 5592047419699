import React from 'react'

import { SampleName as SharedSampleName } from '@components/shared/Hr/SampleName'
import { IHRSample } from '@models/samples'

import { StyledTableCell } from '../../styles'

interface SampleNameProps {
  sample: IHRSample
}

export const SampleName: React.FC<SampleNameProps> = ({ sample }) => {
  return (
    <StyledTableCell>
      <SharedSampleName name={sample?.name} submitter_code={sample?.submitter_code} />
    </StyledTableCell>
  )
}
