import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import { styled } from '@mui/material'

import { DateBox } from './DateBox'

const styledDateBoxConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'width':
        return false
      case 'height':
        return false
      case 'bgColor':
        return false
      case 'mr':
        return false
      case 'm':
        return false

      default:
        return true
    }
  },
}

export const StyledDateBox = styled(DateBox, styledDateBoxConfig)`
  width: ${({ width }) => width};
  margin-right: ${({ mr }) => mr};
  margin: ${({ m }) => m};

  & .MuiFormControl-root {
    & .MuiInputBase-input {
      padding: ${({ theme, height }) => theme.spacing(height ? (height - 23) / 2 / 8 : 1.2, 1.5)};
      font-size: ${({ theme }) => theme.typography.pxToRem(16)};
      border-radius: ${({ theme }) => theme.typography.pxToRem(4)};
      -webkit-text-fill-color: #8f929b;
      &::placeholder {
        opacity: 1;
      }
    }

    & .MuiOutlinedInput-root.Mui-disabled fieldset {
      border: 1px solid ${({ theme }) => theme.additional.stroke.main};
    }

    & .MuiInputBase-root {
      background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.palette.common.white)};
    }

    & .MuiSvgIcon-root {
      color: #adb4c2;
    }
  }
`
