import { Box } from '@mui/material'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import ReactDOM from 'react-dom'

interface PortalAwareItemProps {
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
}

const portal = document.createElement('div')
document.body.appendChild(portal)

export const PortalAwareItem: React.FC<PortalAwareItemProps> = ({
  children,
  provided,
  snapshot,
}) => {
  const usePortal = snapshot.isDragging

  const child = (
    <Box
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      sx={{ marginTop: '8px' }}
    >
      {children}
    </Box>
  )

  if (!usePortal) {
    return child
  }

  return ReactDOM.createPortal(child, portal)
}
