import * as React from 'react'
import { Box, IconButton, Typography } from '@mui/material'

import { ReactComponent as DeleteIcon } from '@assets/icons/delete-icon-active.svg'

import { Substance } from './components/Substance'
import { ClinicallyMeaningful } from './components/ClinicallyMeaningful'
import { Concentration } from './components/Concentration'
import { SubstancesItemGroupPropTypes } from './substancesRecognized.types'

export const SubstancesItemGroup: React.FC<SubstancesItemGroupPropTypes> = props => {
  const { index, handleRemoveItemGroup, isNotRecognized, isOneSubstance } = props

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between' mb='16px'>
        <Typography
          color={isNotRecognized ? 'primary.light' : 'text.primary'}
          variant='h5'
        >{`Substance ${index + 1}`}</Typography>

        {!isOneSubstance && (
          <IconButton onClick={handleRemoveItemGroup} disabled={isNotRecognized}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>

      <Box mb='24px'>
        {/* Substance */}
        <Substance index={index} isNotRecognized={isNotRecognized} />
        <div style={{ display: 'flex' }}>
          {/* Proportion */}
          <Concentration index={index} isNotRecognized={isNotRecognized} />
          {/* Clinically meaningful */}
          <ClinicallyMeaningful index={index} isNotRecognized={isNotRecognized} />
        </div>
      </Box>
    </>
  )
}
