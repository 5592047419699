import React from 'react'

import { useAppSelector } from '@hooks/redux'
import { selectPackagesState } from '@state/packages'
import { IPackages, PackageContentType } from '@models/packages'

import { TableRow } from '../TableRow'

interface TableBodyProps {
  packages: IPackages
  contentType: PackageContentType
  totalPackages?: number
}

export const TableBody: React.FC<TableBodyProps> = ({ packages, contentType, totalPackages }) => {
  const { selectedPackages } = useAppSelector(selectPackagesState)

  return (
    <div>
      {packages.map((pack, idx) => {
        const isLastPack = totalPackages === idx + 1
        return (
          <TableRow
            key={pack.id}
            pack={pack}
            contentType={contentType}
            isPackSelected={selectedPackages.some(({ id }) => id === pack.id)}
            isLastPack={isLastPack}
          />
        )
      })}
    </div>
  )
}
