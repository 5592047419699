import React from 'react'
import { StringParam, useQueryParams } from 'use-query-params'

import { StyledTableListOfUsersRow } from '@styles'
import { IUsers } from '@models/common/user'

import { TableCells } from './TableCells'
import { UsersTableLoader } from './UsersTableLoader'
import { NoSearchedUsers } from './NoSearchedUsers'

interface TableBodyProps {
  users?: IUsers
  setEditUserIsOpen: (arg1: boolean) => void
  isLoading: boolean
  isFetchingNextPage?: boolean
  isNotOwnerLaboratory: boolean
}

export const TableBody: React.FC<TableBodyProps> = ({
  users,
  setEditUserIsOpen,
  isLoading,
  isFetchingNextPage,
  isNotOwnerLaboratory,
}) => {
  const [desired_user] = useQueryParams({
    desired_user: StringParam,
  })

  if (!users?.length && desired_user && !isFetchingNextPage) return <NoSearchedUsers />
  if (isLoading) return <UsersTableLoader />

  return (
    <>
      {users?.map((user, idx) => {
        return (
          <StyledTableListOfUsersRow key={idx}>
            <TableCells
              isNotOwnerLaboratory={isNotOwnerLaboratory}
              setEditUserIsOpen={setEditUserIsOpen}
              user={user}
            />
          </StyledTableListOfUsersRow>
        )
      })}
      {isFetchingNextPage && <UsersTableLoader />}
    </>
  )
}
