import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { Typography, useMediaQuery } from '@mui/material'
import React from 'react'

import { Breadcrumbs } from '@components/shared'
import { IBreadcrumbsRoute } from '@models/common/app'
import { isContainRoute } from '@helpers/routes'

interface ITestParams {
  taskId: string
  testType: string
}

export const PageHeader = () => {
  const { taskId } = useParams<ITestParams>()
  const isMobile = useMediaQuery('(max-width:767px)')
  const history = useHistory()
  const { state } = useLocation<IBreadcrumbsRoute[]>()
  const { path, url } = useRouteMatch()
  const currentPageTask = `Task ${taskId}`

  const currentPageTitle = (() => {
    const title = state?.find(i => i.testTitle)
    return title?.testTitle || ''
  })()

  React.useEffect(() => {
    if (state && !isContainRoute(state, url)) {
      history.replace({ state: [...state, { path, url, title: currentPageTask }] })
    }
  }, [currentPageTask, history, path, state, url])

  return (
    <>
      <Typography variant={isMobile ? 'h3' : 'h1'} mb={isMobile ? '12px' : 1}>
        {currentPageTitle}
      </Typography>
      <Breadcrumbs mb={isMobile ? '40px' : '48px'} />
    </>
  )
}
