import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'

import { Button } from '@components/shared'
import { Image, ButtonBox, StyledPageSubTitle, InfoMessage } from '@styles'
import infoIcon from '@assets/icons/info-circle-grey.svg'

interface AddResultStepProps {
  currentStep: number
  isLoading?: boolean
  isTestStarted: boolean
  finishTestHandler: () => void
  nextStepHandler: () => void
  toggleResultsModal: () => void
  isTandemMSTest: boolean
  isTandemMSMethodSelected: boolean
}

export const AddResultStep: React.FC<AddResultStepProps> = props => {
  const {
    isLoading,
    isTestStarted,
    currentStep,
    finishTestHandler,
    nextStepHandler,
    toggleResultsModal,
    isTandemMSTest,
    isTandemMSMethodSelected,
  } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  const addResultHandler = () => {
    if (isTandemMSTest) toggleResultsModal()
    else {
      if (isTestStarted) finishTestHandler()
      else nextStepHandler()
    }
  }

  const renderDescription = React.useMemo(() => {
    if (isTandemMSTest) {
      const text = !isTandemMSMethodSelected ? 'change' : 'choose'
      return (
        <>
          <StyledPageSubTitle mb='24px' variant='h3'>
            Select method
          </StyledPageSubTitle>
          <Typography variant='body1' lineHeight='150%'>
            Begin by clicking on the <b>“Select method”</b> button.{' '}
            <span>
              {`This will open a pop-up where you can ${text} your preferred testing method.`}
            </span>
          </Typography>
        </>
      )
    } else
      return (
        <>
          <StyledPageSubTitle mb='24px' variant='h3'>
            Add result
          </StyledPageSubTitle>
          <Typography variant='body1' lineHeight='150%'>
            Please click on the <b>"Add result"</b> button when you have the test results.
          </Typography>
        </>
      )
  }, [isTandemMSMethodSelected, isTandemMSTest])

  const descriptionUnderButton = isTandemMSTest
    ? 'Test will become "In progress" when you select method on the next step.'
    : 'Test will become "In progress" when you click the button.'

  const buttonText = isTandemMSTest ? 'Select method' : 'Add result'

  return (
    <>
      {renderDescription}
      <ButtonBox mb='16px' mt='32px' $isMobile={isMobile} $isCancelManualTestBtnHidden>
        <Button
          variant='contained'
          mb={isMobile ? '16px' : ''}
          width={isMobile ? '100%' : '205px'}
          height='48px'
          loading={isLoading}
          disabled={isLoading}
          onClick={addResultHandler}
        >
          {buttonText}
        </Button>
      </ButtonBox>

      {!isTestStarted && currentStep < 0 && (
        <InfoMessage>
          <Box>
            <Image component='img' src={infoIcon} width='24px' height='24px' />
            <Typography variant='body2' width={isTandemMSTest ? '250px' : '224px'}>
              {descriptionUnderButton}
            </Typography>
          </Box>
        </InfoMessage>
      )}
    </>
  )
}
