import { INotifications } from '@models/common/app'

export const notifications: INotifications = {
  projectUpdateSuccess: {
    type: 'success',
    title: 'List of Projects is updated',
    buttonText: 'Got It',
  },
  projectUpdateFailed: {
    type: 'error',
    title: 'Something went wrong with updating List of Projects',
    buttonText: 'Got It',
  },
  emailConfirmation: {
    type: 'success',
    title: 'Your email address confirmed',
    buttonText: 'Got It',
  },
  sentEmailConfirmationFailed: {
    type: 'error',
    title: 'Email has not been sending',
    message: 'Something went wrong with sending email.',
    buttonText: 'Got It',
  },
  sentEmailConfirmationSuccess: {
    type: 'success',
    title: "We've sent you the confirmation link on your email!",
    message: 'Check it out!',
    buttonText: 'Got It',
  },
  sentEmailToRecoverPass: {
    type: 'success',
    title: "We've sent you the recovery password link on your email!",
    message: 'Check it out',
    buttonText: 'Got It',
  },
  sampleCreatedSuccessfully: {
    type: 'success',
    title: 'Sample was created successfully',
    buttonText: 'Got It',
  },
  samplesCreatedSuccessfully: {
    type: 'success',
    title: 'Samples were created successfully',
    buttonText: 'Got It',
  },
  sampleCreatedError: {
    type: 'error',
    title: 'Something went wrong with creating the sample',
  },
  samplesCreatedError: {
    type: 'error',
    title: 'Something went wrong with creating the samples',
  },
  samplesUpdatedSuccessfully: {
    type: 'success',
    title: 'Sample info was successfully changed',
    buttonText: 'Got It',
  },
  samplesUpdatedFailed: {
    type: 'error',
    title: 'Something went wrong with editing sample info',
  },
  samplesSplitSuccessfully: {
    type: 'success',
    title: 'Sub-samples were created successfully',
    buttonText: 'Got It',
  },
  samplesSplitError: {
    type: 'error',
    title: 'Something went wrong with creating sub-samples',
  },
  createPackagesSuccessfully: {
    type: 'success',
    title: 'Packages were created, you can sent it now',
    buttonText: 'Got It',
  },
  createPackagesFailed: {
    type: 'error',
    title: 'Something went wrong with package creation',
    buttonText: 'Got It',
  },
  sendPackageSuccessfully: {
    type: 'success',
    title: 'Package moved to outgoing packages',
    buttonText: 'Got It',
  },
  sendPackagesSuccessfully: {
    type: 'success',
    title: 'Packages moved to outgoing packages',
    buttonText: 'Got It',
  },
  sendPackagesFailed: {
    type: 'error',
    title: 'Something went wrong with sending',
    buttonText: 'Got It',
  },
  unpackedPackagesSuccessfully: {
    type: 'success',
    title: 'The package has been successfully unpacked',
    buttonText: 'Got It',
  },
  unpackedPackagesFailed: {
    type: 'error',
    title: 'Something went wrong with unpacking package',
    buttonText: 'Got It',
  },
  canceledPackageDeliveryFailed: {
    type: 'error',
    title: 'Something went wrong with the canceling package delivery',
    buttonText: 'Got It',
  },
  addedSamplesSuccessfully: {
    type: 'success',
    title: 'We have successfully updated the number of samples inside',
    buttonText: 'Got It',
  },
  addedSamplesFailed: {
    type: 'error',
    title: 'Something went wrong with updating the quality of samples inside',
    buttonText: 'Got It',
  },
  copiedLinkToClipboard: {
    type: 'success',
    title: 'Link copied to clipboard',
    buttonText: 'Got It',
    isCopiedLink: true,
  },
  purchasedSamplesFailed: {
    type: 'error',
    title: 'Something went wrong with the request',
    buttonText: 'Got It',
  },
  purchasedSamplesSuccessfully: {
    type: 'success',
    title: 'The purchase request moved to Outgoing requests',
    buttonText: 'Got It',
  },
  requestSamplesSuccessfully: {
    type: 'success',
    title: 'The sample moved to Outgoing requests',
    buttonText: 'Got It',
  },
  requestSamplesFailed: {
    type: 'error',
    title: 'Something went wrong with sending request',
    buttonText: 'Got It',
  },
  requestSamplesRejectionError: {
    type: 'error',
    title: 'Something went wrong with the rejection of the request',
    buttonText: 'Got It',
  },
  requestSamplesAcceptingError: {
    type: 'error',
    title: 'Something went wrong with the accepting of the request',
    buttonText: 'Got It',
  },
  requestSamplesAwaitingError: {
    type: 'error',
    title: 'Something went wrong with returning request to neutral state',
    buttonText: 'Got It',
  },
  deleteExtraTaskFailed: {
    type: 'error',
    title: 'Something went wrong with deleting',
    buttonText: 'Got It',
  },
  purchaseRequestSuccessfully: {
    type: 'success',
    title: 'The purchase request moved to Outgoing requests',
    buttonText: 'Got It',
  },
  purchaseRequestFailed: {
    type: 'error',
    title: 'Something went wrong with sending purchase request',
    buttonText: 'Got It',
  },
  packageUpdatedSuccessfully: {
    type: 'success',
    title: 'Package note was successfully changed',
    buttonText: 'Got It',
  },
  packageUpdateFailed: {
    type: 'error',
    title: 'Something went wrong with editing package note',
  },
  taskUpdatedSuccessfully: {
    type: 'success',
    title: 'Task notes was successfully changed',
    buttonText: 'Got It',
  },
  taskUpdatedFailed: {
    type: 'error',
    title: 'Something went wrong with editing task notes info',
  },
  taskResultsUpdatedSuccessfully: {
    type: 'success',
    title: 'Test results was successfully changed',
    buttonText: 'Got It',
  },
  taskResultsUpdatedFailed: {
    type: 'error',
    title: 'Something went wrong with editing test results info',
  },
  taskFinishedSuccess: {
    type: 'success',
    title: '',
    buttonText: 'Got It',
  },
  taskFinishedFailed: {
    type: 'error',
    title: 'Something went wrong',
    buttonText: 'Got It',
  },
  copiedLabContactsToClipboard: {
    type: 'success',
    title: 'Contacts copied to clipboard',
    buttonText: 'Got It',
    isCopiedLink: true,
  },
  tasksCreateSuccessfully: {
    type: 'success',
    title: 'Tests were successfully added to the sample',
    buttonText: 'Got It',
  },
  tasksCreateFailed: {
    type: 'error',
    title: 'Something went wrong with adding tests to the sample',
  },
  uploadingPhotoFailed: {
    type: 'error',
    title: 'Something went wrong with uploading photo',
  },
}
