import React from 'react'
import { Typography } from '@mui/material'

import { ISample } from '@models/samples'
import { Tooltip } from '@components/shared'
import { StyledTableSampleCell, Image } from '@styles'
import infoIcon from '@assets/icons/info-circle-xs.svg'

interface SampleIDProps {
  counter: number
  sample: ISample
  isLastSample?: boolean
}

export const Counter: React.FC<SampleIDProps> = ({ counter, sample, isLastSample }) => {
  return (
    <StyledTableSampleCell isLastSample={isLastSample}>
      {sample.missed && (
        <Tooltip title='This sample is missed' placement='top-start'>
          <Image
            component='img'
            src={infoIcon}
            width='18px'
            height='18px'
            sx={{ position: 'absolute', left: '15px', top: '16px' }}
          />
        </Tooltip>
      )}

      <Typography>{counter + 1}</Typography>
    </StyledTableSampleCell>
  )
}
