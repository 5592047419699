import * as React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { Box, FormControlLabel } from '@mui/material'
import { cloneDeep } from 'lodash'

import { AvailableSectionSettings } from '@models/tasks'
import {
  SUBSTANCES_RECOGNIZED,
  RESULT_RECOGNIZED,
  SUBSTANCES,
  DELETED_RECOGNIZED,
} from '@constants/tests'
import { Checkbox } from '@components/shared'
import { ReactComponent as AddIcon } from '@assets/icons/add-icon.svg'
import { StyledAddSubstancesBtn, SubstancesItemGroup } from '@components/harmModules/HrTests'
import { PureTooltip } from '@components/ui/PureTooltip'

import { StyledTitle, StyledResultsBox } from '../../ScreeningEditResults/styles'

export const Substances = () => {
  const { control, getValues, setValue, register, clearErrors } = useFormContext()
  const { task } = getValues()
  const { fields } = useFieldArray({
    control,
    name: `fields.${SUBSTANCES}`,
  })
  const [isNotRecognized, setIsNotRecognized] = React.useState(false)

  React.useEffect(() => {
    setIsNotRecognized(task[RESULT_RECOGNIZED])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { ordering_key: order, title } = React.useMemo(() => {
    return task.available_section_settings.find(
      (item: AvailableSectionSettings) => item.section.name === SUBSTANCES_RECOGNIZED
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddNewSubstances = () => {
    if (!isNotRecognized && fields.length < 5) {
      const clonedFormSubstances = cloneDeep(getValues(`fields.${SUBSTANCES}`))
      setValue(`fields.${SUBSTANCES}`, [
        ...clonedFormSubstances,
        { name: '', percentage: '', is_not_determined: false },
      ])
    }
  }

  const handleRemoveItemGroup = (id: string, index: number) => {
    const clonedFormSubstances = cloneDeep(getValues(`fields.${SUBSTANCES}`))
    const oldSubstanceId = getValues(`fields.${SUBSTANCES}`)[index].id

    if (!!oldSubstanceId) {
      setValue(`fields.${DELETED_RECOGNIZED}`, [
        ...getValues(`fields.${DELETED_RECOGNIZED}`),
        oldSubstanceId,
      ])
    }

    clonedFormSubstances.splice(index, 1)
    setValue(`fields.${SUBSTANCES}`, clonedFormSubstances)
    clearErrors()
  }

  const handleNotRecognized = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setValue(`fields.${RESULT_RECOGNIZED}`, checked)
    setIsNotRecognized(checked)
  }

  return (
    <StyledResultsBox $mb={24} $order={order}>
      <StyledTitle>{`${order}. ${title}`}</StyledTitle>
      <Box display='flex' flexDirection='column'>
        <FormControlLabel
          control={
            <Checkbox
              {...register(`fields.${RESULT_RECOGNIZED}`)}
              defaultChecked={task[RESULT_RECOGNIZED]}
              onChange={handleNotRecognized}
            />
          }
          label='Substances were not recognized'
          sx={{ marginBottom: '30px' }}
        />

        {fields.map((item, idx) => (
          <SubstancesItemGroup
            isEdit
            key={item.id}
            index={idx}
            isNotRecognized={isNotRecognized}
            isShowRemoveBtn={fields.length > 1}
            handleRemoveItemGroup={() => handleRemoveItemGroup(item.id, idx)}
          />
        ))}
        <PureTooltip
          title='Maximum of 5 substances test results can be added'
          titleStyle={{ width: 213 }}
          shouldBeHidden={fields.length < 5}
        >
          <StyledAddSubstancesBtn
            $isEdit
            type='button'
            $disabled={isNotRecognized || fields.length > 4}
            onClick={handleAddNewSubstances}
          >
            <AddIcon />
            Add substance
          </StyledAddSubstancesBtn>
        </PureTooltip>
      </Box>
    </StyledResultsBox>
  )
}
