import { styled, Typography } from '@mui/material'

export const SubText = styled('span')`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #8f929b;
`

export const StyledTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 170px;
  padding-right: 48px;
  box-sizing: border-box;
`
