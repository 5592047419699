import React from 'react'
import { Box, useMediaQuery } from '@mui/material'

import { StyledPageSubTitle, StyledAttachedBox, StyledAttachedMaterials } from '@styles'
import { useFetchAttachments, useFetchMutateAttachments } from '@hooks/queries/useFetchAttachments'
import { useRemoveAttachments } from '@hooks/queries'
import { Attachment } from '@components/shared'
import { TRUNCATED_ATTACHMENTS_PACKAGE_DETAILS_TEXT } from '@constants/common'
import { IPackage } from '@models/packages'
import { NOT_SENT } from '@constants/packages'
import { useAppSelector } from '@hooks/redux'
import { selectTasks } from '@state/tasks'
import { IAttachments } from '@models/attachments'
import { ATTACHMENT_MATERIAL_SECTION, ATTACHMENT_STORAGE_SECTION } from '@constants/tests'
import { checkSkills } from '@helpers/obtainingSkills'

import { AddAttachment } from './components'

interface PackageAttachmentsProps {
  data?: IPackage
}

let isRemoveAttachmentId: string | null = null

export const PackageAttachments: React.FC<PackageAttachmentsProps> = ({ data }) => {
  const [attachments, setAttachments] = React.useState<IAttachments | undefined>([])
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false)

  const isMobile = useMediaQuery('(max-width:767px)')
  const { isEditAttachments } = useAppSelector(selectTasks)
  const { isSampleTemperatureSensitive, isSampleToxic } = checkSkills(data)
  const fetchMutateAttachments = useFetchMutateAttachments({
    content_type: 'package',
    object_id: data?.id,
  })

  const fetchAttachmentsQ = useFetchAttachments({
    content_type: 'package',
    object_id: data?.id,
    isFetch: isEditAttachments,
  })

  React.useEffect(() => {
    return () => {
      if (isRemoveAttachmentId) isRemoveAttachmentId = null
    }
  }, [])

  const removeAttachmentsM = useRemoveAttachments(data?.id)
  const removeAttachment = (id: string) => {
    isRemoveAttachmentId = id
    removeAttachmentsM.mutate(id)
  }

  React.useEffect(() => {
    if (!isEditAttachments && isEditMode) {
      fetchMutateAttachments.mutate()
      setIsEditMode(false)
    } else if (!isEditAttachments && fetchMutateAttachments.data) {
      setAttachments(fetchMutateAttachments.data as IAttachments)
    } else if (!isEditAttachments && fetchAttachmentsQ.data && !fetchMutateAttachments.data) {
      setAttachments(fetchAttachmentsQ.data)
    }

    if (
      removeAttachmentsM &&
      removeAttachmentsM.isSuccess &&
      isRemoveAttachmentId === removeAttachmentsM.variables
    ) {
      isRemoveAttachmentId = null
      fetchMutateAttachments.mutate()
    }
  }, [
    fetchAttachmentsQ.data,
    fetchMutateAttachments,
    fetchMutateAttachments.data,
    removeAttachmentsM,
    isEditAttachments,
    isEditMode,
  ])

  const isDeliveryStatusNotSent = data?.delivery_status.key === NOT_SENT
  const shouldRenderAttachSection =
    !isDeliveryStatusNotSent && attachments && attachments?.length < 1
  const isHasShipmentMaterials = !!attachments?.some(
    attachment => attachment.attachment_section.key === ATTACHMENT_MATERIAL_SECTION
  )
  const isHasStorageDoc = !!attachments?.some(
    attachment => attachment.attachment_section.key === ATTACHMENT_STORAGE_SECTION
  )
  const isShowEditShipment = !!(
    attachments &&
    attachments.filter(
      attachment => attachment.attachment_section.key === ATTACHMENT_MATERIAL_SECTION
    ).length < 3
  )
  const isShowEditStorage = !!(
    attachments &&
    attachments.filter(
      attachment => attachment.attachment_section.key === ATTACHMENT_STORAGE_SECTION
    ).length < 3
  )

  return (
    <>
      {!fetchAttachmentsQ.isLoading && !shouldRenderAttachSection && (
        <Box sx={{ marginLeft: isMobile ? '0' : 'auto', marginRight: '0' }}>
          <StyledAttachedBox $isMobile={isMobile}>
            {!isMobile && (
              <StyledPageSubTitle sx={{ fontSize: '18px' }} variant='h5'>
                Shipment materials
              </StyledPageSubTitle>
            )}

            <StyledAttachedMaterials>
              {attachments?.map(attachment =>
                attachment.attachment_section.key === ATTACHMENT_MATERIAL_SECTION ? (
                  <Attachment
                    key={attachment.id}
                    cantDelete={!isDeliveryStatusNotSent}
                    truncatedTextLength={TRUNCATED_ATTACHMENTS_PACKAGE_DETAILS_TEXT}
                    attachment={attachment}
                    removeAttachment={removeAttachment}
                    removeAttachmentLoading={removeAttachmentsM.isLoading}
                    isPackageAttachment
                  />
                ) : null
              )}
            </StyledAttachedMaterials>

            {attachments && isDeliveryStatusNotSent && isShowEditShipment && (
              <AddAttachment
                packageId={data?.id}
                setIsEditMode={() => setIsEditMode(true)}
                btnName={!isHasShipmentMaterials ? 'Add shipment materials' : 'Edit'}
                modalType='materials'
              />
            )}

            <div style={{ marginBottom: 30 }} />

            {isSampleTemperatureSensitive || isSampleToxic ? (
              <>
                {!isMobile && (
                  <StyledPageSubTitle sx={{ fontSize: '18px' }} variant='h5'>
                    Storage documents
                  </StyledPageSubTitle>
                )}

                <StyledAttachedMaterials>
                  {attachments?.map(attachment =>
                    attachment.attachment_section.key === ATTACHMENT_STORAGE_SECTION ? (
                      <Attachment
                        key={attachment.id}
                        cantDelete={!isDeliveryStatusNotSent}
                        truncatedTextLength={TRUNCATED_ATTACHMENTS_PACKAGE_DETAILS_TEXT}
                        attachment={attachment}
                        removeAttachment={removeAttachment}
                        removeAttachmentLoading={removeAttachmentsM.isLoading}
                        isPackageAttachment
                      />
                    ) : null
                  )}
                </StyledAttachedMaterials>

                {attachments && isDeliveryStatusNotSent && isShowEditStorage && (
                  <AddAttachment
                    packageId={data?.id}
                    setIsEditMode={() => setIsEditMode(true)}
                    btnName={!isHasStorageDoc ? 'Add storage documents' : 'Edit'}
                    modalType='package_storage'
                  />
                )}
              </>
            ) : null}
          </StyledAttachedBox>
        </Box>
      )}
    </>
  )
}
