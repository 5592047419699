import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { COUNTERS } from '@constants/queries'
import { fetchCounters } from '@api/common'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useFetchCounters = () => {
  const dispatch = useDispatch()
  return useQuery(COUNTERS, fetchCounters, {
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
