// ? Role Laborant

import { SAMPLES_AMOUNT_LOADING_LIMIT } from '@constants/samples'
import { getSampleQueryString } from '@helpers/api'
import { parseDate } from '@helpers/dates'
import { http } from '@services/http'

// * [Tasks list

export const fetchTasksList = <T>(queryKey: any) => {
  const { queryKey: queryKeyNested, pageParam: page = 1 } = queryKey
  const [, sample_specific_id, ordering, apis, excipients, drug_classes, manufacturers] =
    queryKeyNested

  const searchQueries = {
    limit: SAMPLES_AMOUNT_LOADING_LIMIT,
    page,
    ordering,
    sample_specific_id,
    apis,
    excipients,
    drug_classes,
    manufacturers,
  }
  const queryString = getSampleQueryString(searchQueries)

  const urlParams = sample_specific_id ? '&sample_specific_id=' + sample_specific_id : ''

  try {
    return http.get<T>(`/tasks/?${queryString}${urlParams}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchTasksListCompleted = <T>(queryKey: any) => {
  const { queryKey: desired_tasks, pageParam: page = 1 } = queryKey
  const [
    ,
    sample_specific_id,
    ordering,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    testingDate,
  ] = desired_tasks

  const parsedTestingDate = parseDate(testingDate)

  const searchQueries = {
    limit: SAMPLES_AMOUNT_LOADING_LIMIT,
    page,
    sample_specific_id,
    ordering,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    started_date:
      testingDate && (parsedTestingDate[0] ? parsedTestingDate[0] : parsedTestingDate[1]),
    finished_date:
      testingDate && (parsedTestingDate[1] ? parsedTestingDate[1] : parsedTestingDate[0]),
  }
  const queryString = getSampleQueryString(searchQueries)

  const urlParams = sample_specific_id ? '&sample_specific_id=' + sample_specific_id : ''
  try {
    return http.get<T>(`/tasks/?${queryString}&completed=completed${urlParams}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

// * Tasks list ]
