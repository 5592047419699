import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { StringParam, useQueryParams } from 'use-query-params'
import { Box, Typography } from '@mui/material'

import { IPackage } from '@models/packages'
import { ISamples } from '@models/samples'
import { Button, SamplesHeader } from '@components/shared'
import { StyledActionsStickyBox } from '@styles'
import { useToggle } from '@hooks/common'
import xlsGrey from '@assets/icons/xls-gray-icon.svg'
import xlsWhite from '@assets/icons/xls-white-icon.svg'

import { PackageInfoMobile } from './PackageInfoMobile'
import { MobileTable } from './PackageInfoMobile/components'

interface PackageDetailsMobileProps {
  isMobile: boolean
  packagesData?: IPackage
  samples?: ISamples
  isLoadingTable: boolean
  isLoadingPackageDetails: boolean
  hasNextPage?: boolean
  fetchNextPage: (page: number) => void
  isFetchingNextPage: boolean
  actionBtnText?: 'Remove' | 'Missed'
  shouldDisplayActionBtn?: boolean
  shouldDisplayMissedText?: boolean
  isPackageStatusNotSent?: boolean
  generateXLS?: () => void
  shouldHiddenActionBtn?: boolean
}

export const PackageDetailsMobile: React.FC<PackageDetailsMobileProps> = props => {
  const {
    isMobile,
    packagesData,
    samples,
    isLoadingTable,
    isLoadingPackageDetails,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    actionBtnText,
    shouldDisplayActionBtn,
    shouldDisplayMissedText,
    isPackageStatusNotSent,
    generateXLS,
    shouldHiddenActionBtn = false,
  } = props

  const [shouldBeHiddenSearchFieldMobile, setShouldBeHiddenSearchFieldMobile] = useToggle()
  const [queryString] = useQueryParams({
    desired_samples_inside_package: StringParam,
  })

  return (
    <>
      {packagesData && (
        <PackageInfoMobile
          packagesData={packagesData}
          isLoadingPackageDetails={isLoadingPackageDetails}
          isPackageStatusNotSent={isPackageStatusNotSent}
        />
      )}

      {samples && samples?.length > 0 && (
        <Box sx={{ marginTop: '40px', marginBottom: '14px', height: '50px' }}>
          {isMobile && generateXLS && (
            <Button
              bgColor={shouldHiddenActionBtn ? '' : '#FFFFFF'}
              onClick={generateXLS}
              variant={shouldHiddenActionBtn ? 'contained' : 'outlined'}
              width='100%'
              height='48px'
            >
              {shouldHiddenActionBtn ? (
                <img src={xlsWhite} alt='xls icon' />
              ) : (
                <img src={xlsGrey} alt='xls icon' />
              )}
              <Typography color={shouldHiddenActionBtn ? '' : '#616D84'} ml='12px' variant='body1'>
                Generate customs XLSX
              </Typography>
            </Button>
          )}
        </Box>
      )}

      <StyledActionsStickyBox $isMobile={isMobile} $padding>
        <SamplesHeader
          shouldBeHiddenSearchFieldMobile={shouldBeHiddenSearchFieldMobile}
          setShouldBeHiddenSearchFieldMobile={setShouldBeHiddenSearchFieldMobile}
          variant='subtitle2'
          isMobile={isMobile}
          searchInsidePackage={true}
          buttonText='Add samples'
          titleText='Samples inside'
          shouldHiddenSearchField={!samples?.length && !queryString.desired_samples_inside_package}
          generateXLS={generateXLS}
        />
      </StyledActionsStickyBox>
      <InfiniteScroll hasMore={hasNextPage} loadMore={fetchNextPage}>
        <MobileTable
          isMobile={isMobile}
          samples={samples}
          isLoadingTable={isLoadingTable}
          isFetchingNextPage={isFetchingNextPage}
          actionBtnText={actionBtnText}
          shouldDisplayActionBtn={shouldDisplayActionBtn}
          shouldDisplayMissedText={shouldDisplayMissedText}
        />
      </InfiniteScroll>
    </>
  )
}
