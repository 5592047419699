import React from 'react'
import { useDispatch } from 'react-redux'
import {
  AutocompleteInputChangeReason,
  Autocomplete as AutocompleteMUI,
  CircularProgress,
} from '@mui/material'
import { RegisterOptions, Control, useController } from 'react-hook-form'

import { IOption } from '@models/common/app'
import { AutocompleteWrapper, Input, Tooltip } from '@components/shared'
import { setCellValueToSample } from '@state/sampleCreate'
import { useFetchDrugClassesFilters } from '@hooks/queries'
import { numerateOptions } from '@helpers/samples'

import { IPlaceOption } from '../GMPlaceAutocomplete'

interface DrugClassesFieldProps {
  name: string
  pr?: string
  mr?: string
  isCopy?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  control?: Control
  sampleId?: string
  cellName?: string
  initialVal?: {
    val: number | null | IOption | string | IOption[] | Date | IPlaceOption
    error: string
  }
}

export const DrugClassesField: React.FC<DrugClassesFieldProps> = React.memo(props => {
  const { name, isCopy, rules, pr, mr, initialVal, control, sampleId, cellName } = props

  const [storeError, setStoreError] = React.useState<string>('')
  const [searchQuery, setSearchQuery] = React.useState('')
  const [topScroll, setTopScroll] = React.useState<{ oldScrollTop: number; isScrollTop: boolean }>({
    oldScrollTop: 0,
    isScrollTop: false,
  })
  const dispatch = useDispatch()
  const {
    field,
    fieldState: { isTouched, error },
  } = useController({ name, control, rules, defaultValue: initialVal?.val })

  const setCellValue = (val: IOption | null, error: string) => {
    if (cellName && sampleId) {
      dispatch(
        setCellValueToSample({
          cellValue: { val, error },
          cellName,
          sampleId,
        })
      )
    }
  }

  const { data, isFetching, fetchNextPage, hasNextPage } = useFetchDrugClassesFilters(searchQuery)
  const options = data && data.pages.map(page => page.results).flat()

  React.useEffect(() => {
    if (!error) setStoreError('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, error])

  React.useEffect(() => {
    if (initialVal?.error) setStoreError(initialVal.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialVal?.error])

  React.useEffect(() => {
    if (!!error && error.message) setCellValue(field.value, error.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onInputChangeHandler = (e: any, v: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') setSearchQuery(v)
    else setSearchQuery('')
  }

  const valueForTooltip = field.value?.name ? field.value.name : ''

  return (
    <AutocompleteWrapper
      pr={pr}
      height={42}
      bgColor={isCopy && !isTouched ? '#FFF5D6' : '#fff'}
      heightPopper='20px'
    >
      <AutocompleteMUI
        fullWidth
        size='small'
        loading={isFetching}
        options={numerateOptions(options) || []}
        noOptionsText='Nothing found'
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={onInputChangeHandler}
        ListboxProps={{
          onScroll: event => {
            const listboxNode = event.currentTarget

            const isFetchNextPage =
              hasNextPage &&
              Math.round(listboxNode.scrollTop + listboxNode.clientHeight) ===
                listboxNode.scrollHeight

            if (isFetchNextPage) {
              const top = Math.round(listboxNode.scrollHeight - listboxNode.clientHeight)
              fetchNextPage()
              setTopScroll({ oldScrollTop: top, isScrollTop: true })
            }

            if (listboxNode.scrollHeight > topScroll.oldScrollTop && topScroll.isScrollTop) {
              listboxNode.scrollTo(0, topScroll.oldScrollTop)

              setTopScroll(prev => ({ ...prev, isScrollTop: false }))
            }
          },
        }}
        renderInput={params => (
          <Tooltip title={valueForTooltip} shouldBeHidden={valueForTooltip.length <= 17}>
            <Input
              pr={pr}
              error={!!error || !!storeError}
              variant='outlined'
              helperText={error?.message || storeError}
              placeholder='Type here values...'
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isFetching ? (
                      <CircularProgress
                        color='primary'
                        sx={{ marginRight: mr || '4px' }}
                        size={16}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          </Tooltip>
        )}
        {...field}
        onChange={(e: any, v: IOption | null) => {
          field.onChange({ target: { value: v } })
          setCellValue(v, '')
        }}
      />
    </AutocompleteWrapper>
  )
})
