import React from 'react'

import { StyledTableSampleCell, SubText } from '@styles'
import { ISample } from '@models/samples'

interface DosageProps {
  report: ISample
  hideBorder?: boolean
}

export const Dosage: React.FC<DosageProps> = ({ report, hideBorder }) => {
  return (
    <StyledTableSampleCell isClickable hideBorder={hideBorder}>
      {parseFloat(report.dosage)}
      {report.dosage_type && (
        <SubText sx={{ marginLeft: '8px', lineHeight: '20px', fontSize: 14 }}>
          {report.dosage_type.name || ''}
        </SubText>
      )}
    </StyledTableSampleCell>
  )
}
