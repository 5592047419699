import React from 'react'
import { Box, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'

import {
  Photo as SharedPhoto,
  SampleName as SharedSampleName,
  TestingDate as SharedTestingDate,
} from '@components/shared/Hr'
import { StyledTitle } from '@styles'
import { SpecificId } from '@components/shared/sampleFields'
import { TaskNotesField } from '@components/shared'
import { TASKS } from '@constants/queries'
import { protectedRoutes } from '@configs/routes.config'
import { DETAILS } from '@constants/common'
import { useAppSelector } from '@hooks/redux'
import { selectUserRole } from '@state/user'
import { LABORATORY_ASSISTANT } from '@constants/user'
import { TandemMS } from '@models/tests'
import { MethodChip } from '@components/shared/Hr/TandemMS'
import { IBreadcrumbsRoute } from '@models/common/app'

import * as Styled from '../styles'
import { TextField } from './MainInfoCells'
import { Loader } from '../Loader'
import { HrTaskInfoPropTypes } from '../../hrTaskInfo.types'

export const HrMainInfo: React.FC<HrTaskInfoPropTypes> = ({ isLoading, task }) => {
  const role = useAppSelector(selectUserRole)
  const { state } = useLocation<IBreadcrumbsRoute[]>()

  if (isLoading) return <Loader />

  const cells = [
    { title: 'Task ID', component: TextField, data: task?.id },
    {
      title: 'Test type',
      component: (props: any) => (
        <>
          <TextField {...props} />
          {task?.test_type.name === TandemMS && (
            <MethodChip testTypeMethod={task.test_type_method} chipStatus={null} />
          )}
        </>
      ),
      data: task?.test_type.name,
    },
    { title: 'Sample ID', component: SpecificId },
    { title: 'Sample name', component: SharedSampleName },
    { title: 'Photo of sample', component: SharedPhoto },
    { title: 'Task notes', component: TaskNotesField },
    { title: 'Testing date', component: SharedTestingDate, data: task?.finished_at },
  ]

  const checkCell = (title: string): boolean => {
    return title === 'Photo of sample'
  }

  const checkState = state.some(path => path.path.includes('app/samples'))

  const specificIdManagerData = (() => {
    if (role.key !== LABORATORY_ASSISTANT && task) {
      return {
        laboratory: task.initial_laboratory,
        pathForLink: `${protectedRoutes.tasks.path}/${task.id}${DETAILS}`,
        titleForPathLink: `${protectedRoutes.tasksDetails.name} ${task.id}`,
      }
    }
    return {}
  })()

  return (
    <Box mr='48px' width='50%'>
      <StyledTitle mb={'32px'} variant='h3' fz={24}>
        Main info
      </StyledTitle>

      {cells.map(({ title, component: Cell, data }, idx) => (
        <Styled.DetailsBox key={idx}>
          {/* titles */}
          <Typography variant='h5' width='177px' mb='18px'>
            {title}
          </Typography>

          {/* cells with data */}
          <Box mb='18px' width='90%'>
            {task && (
              <Cell
                sampleId={task.sample_id}
                data={data as string}
                //SharedSampleName
                name={task.name}
                submitter_code={task.submitter_code}
                //Sample ID
                withoutLink={role.key === LABORATORY_ASSISTANT || checkState}
                specificId={`${task.sample}`}
                typographyStyles={{ display: 'inline-block' }}
                labelTooltip={task.initial_laboratory?.label_tooltip}
                {...specificIdManagerData}
                //SharedPhoto
                attachments={task.sample_attachments}
                height={checkCell(title) ? '103px' : ''}
                width={checkCell(title) ? '103px' : '80%'}
                // TaskNotesField
                contentType={TASKS}
                taskId={task.id}
                notes={task.task_notes}
                isAbleToAddTaskNotes={task.is_able_to_add_task_notes}
                notesWithoutTruncate
                mtEditIcon='8px'
                editDetailsNotes
              />
            )}
          </Box>
        </Styled.DetailsBox>
      ))}
    </Box>
  )
}
