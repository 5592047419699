import { styled, TableRow } from '@mui/material'

export const StyledDetailsTableRow = styled(TableRow)`
  display: flex;
  width: 100%;

  & th:first-child,
  & td:first-child {
    width: 17%;
    padding: 16px 0 0 40px;
  }

  & th:nth-child(2),
  & td:nth-child(2) {
    width: 14%;
    padding: 16px 0;
  }

  & th:nth-child(3),
  & td:nth-child(3) {
    width: 17%;
    padding: 16px 0;
  }

  & th:nth-child(4),
  & td:nth-child(4) {
    width: 25%;
    padding: 16px 0;
  }

  & th:nth-child(5),
  & td:nth-child(5) {
    width: 25%;
    padding: 16px 0;
  }
`
