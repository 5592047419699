import { useMutation, useQueryClient } from 'react-query'

import { USERS } from '@components/shared/FilterAside/constants'
import { IError } from '@models/common/app'
import { editUser, HttpServiceRequestParam, IEditUserRequestData } from '@api/user'

export const useToggleActivateUser = () => {
  const client = useQueryClient()

  const mutation = useMutation<
    IEditUserRequestData,
    IError,
    HttpServiceRequestParam<{ is_active: boolean }, { id: string }>
  >(editUser, {
    onSuccess: () => {
      client.invalidateQueries([USERS])
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
