import { Box, styled as styledMUI, Typography, TypographyProps } from '@mui/material'
import styled from 'styled-components'
import React from 'react'

type StyledNavProps = {
  $isMobile: boolean
  $isTablet: boolean
  $isTenantLegalDrugs?: boolean
}

interface StyledName extends TypographyProps {
  $isTenantLegalDrugs?: boolean
}

export const StyledNav = styled<React.FC<StyledNavProps>>(Box)`
  background-color: ${({ theme }) => theme.primary[90]};
  border-bottom: ${({ theme, $isTenantLegalDrugs }) =>
    $isTenantLegalDrugs ? 'none' : `1px solid ${theme.additional.stroke.main}`};
  padding: ${({ $isTablet }) => ($isTablet ? '0 24px 0 20px' : '0 80px')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 450;
  height: ${({ $isMobile }) => ($isMobile ? '70px' : '60px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Logo = styled.img`
  display: block;
  width: 145px;
  height: 40px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 0;
  margin-left: -7px;

  @media (min-width: 768px) {
    margin-left: -15px;
  }
`

export const MenuContainer = styled(Box)`
  position: relative;
  margin-left: 13px;

  &:hover .popper::before {
    display: block;
  }

  &:hover .popper > .MuiPaper-elevation {
    display: block;
  }
`

export const AvatarBox = styled(Box)`
  display: flex;
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: pointer;
`

export const InfoUserBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 15px;
`

export const Name = styledMUI<React.FC<StyledName>>(Typography)`
  color: ${({ theme, $isTenantLegalDrugs }) =>
    $isTenantLegalDrugs ? theme.palette.common.white : theme.palette.text.primary};
`

export const Role = styledMUI<React.FC<StyledName>>(Typography)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${({ $isTenantLegalDrugs }) => ($isTenantLegalDrugs ? '#a1a8b5' : '#616D84')};

`

export const StyledBurgerMenu = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;

  span,
  span:before,
  span:after {
    display: block;
    height: 1.6px;
    background: #ffffff;
    transition: all 0.4s ease-out;
    backface-visibility: hidden;
  }

  span {
    position: relative;
    width: 24px;

    &:after,
    &:before {
      width: 100%;
      position: absolute;
      content: '';
      top: 7px;
    }

    &:before {
      top: -7px;
    }
  }

  &.open-menu > span {
    transform: rotate(45deg);

    &::before {
      transform: translate(0px, 7.4px) rotate(-90deg);
    }

    &:after {
      transform: translate(0px, -7.4px) rotate(-90deg);
    }
  }
`

export const StyledText = styledMUI(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color:  #935110 ;
`

export const StyledBox = styledMUI(Typography)`
  display: flex;
  align-items: center;
  padding: 2px 4px 2px 10px;
  margin-left: 16px;
  height: 24px;
  background: #ffffff;
  border-radius: 40px;
`
