import * as React from 'react'

import { PureInputPropTypes } from './pureInput.types'
import { StyledErrorMessage } from '../commonUIStyles'
import * as Styled from './styles'

export const PureInput: React.FC<PureInputPropTypes> = ({
  name = 'pure-input',
  type,
  rows,
  placeholder = 'Type here values...',
  initialVal = '',
  value = '',
  onInputChange,
  startIcon,
  endIcon,
  style = {},
  errorMessage,
  isCopy = false,
}) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const [isCopyField, setIsCopyField] = React.useState<boolean>(isCopy)
  const [val, setVal] = React.useState<string>(value || initialVal || '')

  React.useEffect(() => {
    if (value || value === '') setVal(value)
    if (initialVal || initialVal === '') setVal(initialVal)
  }, [value, initialVal])

  // "e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>" ->
  // -> This and many other options are not suitable for the event, because TS does not see "e.nativeEvent.data".
  // Unfortunately, I could not find the correct typification. So I left "e: any"
  const handleChangeInput = (e: any) => {
    onInputChange(e.target.value)
    setVal(e.target.value)
  }

  const handleClick = () => {
    if (isCopyField) setIsCopyField(false)
  }

  const inputStyles = (() => {
    let style: React.CSSProperties = {}

    if (isCopyField) style.background = '#FFF5D6'
    if (startIcon) style.paddingLeft = 37
    if (endIcon) style.paddingRight = 37

    return style
  })()

  return rows ? (
    <div style={style}>
      {/* ----- TEXTAREA ----- */}
      <Styled.Textarea
        className={errorMessage ? 'input-error' : ''}
        style={isCopyField ? { background: '#FFF5D6' } : {}}
        value={val.replace(/\./g, '') || value.replace(/\./g, '')}
        placeholder={placeholder}
        name={name}
        rows={rows}
        onChange={handleChangeInput}
        onClick={handleClick}
      />
      {/* ----- ERROR MESSAGE ----- */}
      {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </div>
  ) : (
    // ----- INPUT BLOCK ----- //
    <div style={{ position: 'relative', ...style }}>
      {/* ----- START ICON ----- */}
      {startIcon && <Styled.IconBox $position='left'>{startIcon}</Styled.IconBox>}
      {/* ----- INPUT ----- */}
      <Styled.Input
        ref={inputRef}
        className={errorMessage ? 'input-error' : ''}
        autoComplete='off'
        style={inputStyles}
        value={val || value}
        name={name}
        placeholder={placeholder}
        type={type}
        onChange={handleChangeInput}
        onClick={handleClick}
      />
      {/* ----- END ICON ----- */}
      {endIcon && <Styled.IconBox $position='right'>{endIcon}</Styled.IconBox>}
      {/* ----- ERROR MESSAGE ----- */}
      {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </div>
  )
}
