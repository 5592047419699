import React from 'react'
import { isAfter } from 'date-fns'
import { useWatch, Validate } from 'react-hook-form'
import { Box } from '@mui/material'

import {
  LOT,
  TOWN,
  PHARMACY,
  BUYERS_NAME,
  PHARMACY_GPS,
  PURCHASE_DATE,
  MANUFACTURE_DATE,
  UNITS,
  UNIT_TYPE,
  VALUE_TYPE,
  VALUE,
} from '@constants/samples'
import { ISample } from '@models/samples'
import { Tooltip } from '@components/shared'
import { SampleOptionsContext } from '@context'
import {
  Autocomplete,
  DatePicker,
  GMPlaceAutocomplete,
  Input,
  PriceValue,
} from '@components/shared/sampleFields'
import { Image, StyledTitle } from '@styles'
import iconForGpsTooltip from '@assets/icons/gps-tooltip-icon.svg'
import { REQUIRED_FIELD } from '@constants/validation'
import { Units } from '@components/shared/sampleFields/Units'

import { PurchaseDetailsLoader } from '../../Loaders'
import { StyledForm, StyledLabel } from '../styles'

interface EditPurchaseDetailsProps {
  sample: ISample
}

export const EditPurchaseDetails: React.FC<EditPurchaseDetailsProps> = ({ sample }) => {
  const { towns, pharmacies, valueType, unitType, isLoading } =
    React.useContext(SampleOptionsContext)

  const disableUnitsField = Boolean(sample.split_status || sample.sub_samples?.length)

  const manufactureDate = useWatch({ name: MANUFACTURE_DATE })
  const fields = [
    {
      label: 'Units',
      name: UNITS,
      dropdownName: UNIT_TYPE,
      field: Units,
      dropdownOptions: unitType,
      placeholder: 'Amount',
      inputDisabled: disableUnitsField,
      pr: '160px',
      rules: !disableUnitsField
        ? {
            required: REQUIRED_FIELD,
            validate: {
              decimal: (v: string) => {
                return +v % 1 === 0 || !!isNaN(Number(v)) || 'Value cannot be decimal'
              },
              then10000: (v: string) => {
                return !(+v > 1000) || 'Value cannot be more than 1000'
              },
              сanNotBeZero: (v: string) => {
                return !(+v === 0) || disableUnitsField || 'Value cannot be 0'
              },
              negative: (v: string) => {
                return !(+v < 0) || "Value can't be negative"
              },
              digit: (v: string) => {
                return !isNaN(Number(v)) || 'May contain only numbers'
              },
            } as Validate<any> | Record<string, Validate<any>> | undefined,
          }
        : {},
      styles: { left: '44px' },
    },

    {
      label: 'Value',
      name: VALUE,
      dropdownName: VALUE_TYPE,
      options: valueType,
      field: PriceValue,
      type: 'edit_samples',
      fixedPrice: sample.fixed_price || 0,
      dropdownWidth: '80px',
      placeholder: 'Value per 1 unit',
      pr: '112px',
      rules: {
        validate: {
          negative: (v: string) => {
            return !(+v < 0) || "Value can't be negative"
          },
          min: (v: string) => {
            return !(v?.split('.')[1]?.length > 4) || 'Min 0.0001 number'
          },
          max: (v: string) => {
            return !(+v > 999999999) || 'Max 999999999 number'
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
    },
    {
      label: 'Lot',
      name: LOT,
      field: Input,
      rules: {
        maxLength: {
          value: 50,
          message: 'Max 50 characters',
        },
        validate: {
          dangerousChars: (v: string) => {
            return /^(?![=+\-@\t\r]).*/.test(v) || 'Field starts with dangerous char.'
          },
        },
      },
    },

    {
      label: "Buyer's name",
      name: BUYERS_NAME,
      field: Input,
      rules: {
        maxLength: {
          value: 50,
          message: 'Max 50 characters',
        },
      },
    },

    {
      label: 'Purchase date',
      name: PURCHASE_DATE,
      field: DatePicker,
      disableFuture: true,
      width: '220px',
      rules: {
        validate: {
          purchaseDateLaterThanManufacture: (purchaseDate: Date) => {
            return (
              !Boolean(purchaseDate && manufactureDate) ||
              isAfter(purchaseDate, manufactureDate) ||
              'Purchase date must be later than manufacture date'
            )
          },
        } as Validate<any> | Record<string, Validate<any>> | undefined,
      },
    },

    {
      label: 'Town purchased',
      name: TOWN,
      field: GMPlaceAutocomplete,
      dataOptions: towns,
      addressId: sample.town?.id,
      pr: '62px',
    },

    {
      label: 'Pharmacy name',
      name: PHARMACY,
      field: Autocomplete,
      pr: '30px',
      options: pharmacies,
      newOptionsType: PHARMACY,
      enableToAddOptions: true,
    },

    {
      label: 'Pharmacy GPS',
      name: PHARMACY_GPS,
      placeholder: 'Type address or coordinates...',
      field: Input,
      rules: {
        maxLength: {
          value: 50,
          message: 'Max 50 characters',
        },
      },
    },
  ]

  if (isLoading) return <PurchaseDetailsLoader />

  return (
    <StyledForm>
      <StyledTitle variant='h3' mb='28px' fz={24}>
        Purchase details
      </StyledTitle>

      {fields.map(({ field: Field, label, ...props }) => {
        return (
          <Box key={props.name} sx={{ position: 'relative' }}>
            <StyledLabel label={label} control={<Field {...props} />} />
            {props.name === PHARMACY_GPS && (
              <Box sx={{ position: 'absolute', top: '-1px', left: '117px' }}>
                <Tooltip
                  title='Type address or coordinates
  Ex.: -77.508333° or 77°30.5`S or 77°30`29.9988``S'
                >
                  <Image
                    src={iconForGpsTooltip}
                    width='24px'
                    height='24px'
                    component='img'
                    mt='-3px'
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
        )
      })}
    </StyledForm>
  )
}
