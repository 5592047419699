import React from 'react'
import { Box } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { StyledTableSampleCell } from '@styles'
import { Chip } from '@components/shared'
import { EditTestResult } from '@components/shared/EditTestResult'

interface ResultProps {
  task: ITaskLab
  isMobile?: boolean
  buttonOnClick?: () => void
}

export const Result: React.FC<ResultProps> = ({ task, isMobile, buttonOnClick }) => {
  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      <Box>
        <Chip label={task.result_name} status={task.result.key} />
        {buttonOnClick && task.is_able_to_edit_task && (
          <EditTestResult buttonOnClick={buttonOnClick} />
        )}
      </Box>
    </StyledTableSampleCell>
  )
}
