import React from 'react'

import { useAppSelector } from '@hooks/redux'
import { sampleCreate } from '@state/sampleCreate'
import { IInitialSample } from '@state/sampleCreate/sampleCreateState.types'
import { GetStoreSampleDataTypes } from '@components/modules/SampleCreate/sampleCreate.types'

export const GetStoreSampleData: React.FC<GetStoreSampleDataTypes> = ({
  sampleId,
  setStoreSample,
}) => {
  const { samples } = useAppSelector(sampleCreate)
  const storeSample: IInitialSample | undefined = samples.find(sample => sample.id === sampleId)

  React.useEffect(() => {
    if (storeSample) setStoreSample(storeSample)
  }, [setStoreSample, storeSample])

  return <></>
}
