import React from 'react'

import { TasksStep } from '@components/shared'
import { ISampleTask } from '@models/samples'
import { ContentType } from '@models/common/ui'
import { SAMPLES } from '@constants/queries'

interface TaskProps {
  task: ISampleTask
  stepCounter: number
  idx: number
  isAbleToAddTask?: boolean
  isSamples?: boolean
  contentType: ContentType
}

export const Task: React.FC<TaskProps> = props => {
  const { stepCounter, task, idx, isAbleToAddTask, contentType, isSamples } = props

  return (
    <>
      {task.status && (
        <TasksStep
          isSamples={isSamples}
          key={task.id}
          idx={idx}
          stepCounter={stepCounter}
          stepColor={task.status.key}
          isAbleToAddTask={isAbleToAddTask}
          topDivider={'40px'}
          leftDivider={contentType === SAMPLES ? '131px' : '58px'}
        />
      )}
    </>
  )
}
