import { Box } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { ExpandableExcipients } from '@components/shared'
import { StyledTableSampleCell } from '@styles'

interface APIsCellProps {
  task: ITaskLab
  isMobile?: boolean
}

export const ExcipientsCell: React.FC<APIsCellProps> = ({ task, isMobile }) => {
  const { excipients } = task

  return (
    <StyledTableSampleCell $isMobile={isMobile} sx={{ padding: isMobile ? '0' : '16px 0 19px' }}>
      <Box>
        {excipients.length ? (
          <ExpandableExcipients
            excipients={excipients}
            borderBottom='none'
            direction={isMobile ? 'column' : 'row'}
          />
        ) : (
          '-'
        )}
      </Box>
    </StyledTableSampleCell>
  )
}
