import { useTheme } from '@mui/material'

export const ArrowTopIcon = () => {
  const theme = useTheme()

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='8'
      viewBox='0 0 14 8'
      fill={theme.palette.primary.main}
    >
      <path d='M13.7071 7.70711C13.3166 8.09763 12.6834 8.09763 12.2929 7.70711L7 2.41421L1.70711 7.70711C1.31658 8.09763 0.683417 8.09763 0.292893 7.70711C-0.0976314 7.31658 -0.0976313 6.68342 0.292894 6.29289L6.29289 0.292893C6.68342 -0.0976321 7.31658 -0.097632 7.70711 0.292893L13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711Z' />
    </svg>
  )
}
