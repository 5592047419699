export const datePatterns = {
  fullDate: 'MM/dd/y',
  timeDayMonth: 'p, MMM d',
  timeDayMonthYear: 'p, MMM d y',
  monthDayYear: 'MMM d, y',
  fullMonthDayYear: 'MMMM d, y',
  monthYear: 'MMM y',
  yearMonthDay: 'y-MM-dd',
  blockchain: "MM/dd/y 'AT' p",
  fullMonthDate: 'MMMM do, yyyy',
}
