import React from 'react'

import { ITaskLab } from '@models/tasks'
import { StyledTableSampleCell, SubText } from '@styles'

interface DosageProps {
  task: ITaskLab
  isMobile?: boolean
}

export const Dosage: React.FC<DosageProps> = ({ task, isMobile }) => {
  const { dosage: sampleDosage, dosage_type } = task

  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      {parseFloat(sampleDosage)}
      {dosage_type && (
        <SubText sx={{ marginLeft: '8px', lineHeight: '20px', fontSize: 14 }}>
          {dosage_type.name || ''}
        </SubText>
      )}
    </StyledTableSampleCell>
  )
}
