import { styled } from '@mui/material'

export const StyledSectionsBox = styled('div')<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ $isMobile }) => ($isMobile ? '16px 30px 30px' : '16px 82px 60px')};
  overflow-y: auto;
`

export const StyledResultsBox = styled('div')<{ $mb: number; $order?: number }>`
  order: ${({ $order }) => $order};
  margin-bottom: ${({ $mb }) => `${$mb}px`};
`
export const StyledTitle = styled('h3')`
  margin: 0 0 24px;
  font-size: 18px;
  font-weight: 500;
  & span {
    font-weight: 400;
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`
