import { StringParam, useQueryParams } from 'use-query-params'
import { useInfiniteQuery } from 'react-query'

import { IError } from '@models/common/app'
import { fetchSamples } from '@api/samples'
import { SAMPLES } from '@constants/queries'
import { ISamplesData } from '@models/samples'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { endSplitting, selectSamplesSplit } from '@state/samples'
import { setMaintenanceModePage } from '@state/user'

export const useFetchSamples = <T>() => {
  const [queryString] = useQueryParams({
    ordering: StringParam,
    desired_samples: StringParam,
    free_samples: StringParam,
    apis: StringParam,
    excipients: StringParam,
    assignees: StringParam,
    taskStatus: StringParam,
    taskResult: StringParam,
    sample_status: StringParam,
    physical_form: StringParam,
    laboratories: StringParam,
    started_collection_date: StringParam,
    initial_laboratory: StringParam,
  })

  const { isSplitting } = useAppSelector(selectSamplesSplit)
  const dispatch = useAppDispatch()

  const {
    ordering,
    desired_samples: desiredSamples,
    free_samples,
    apis,
    excipients,
    assignees,
    taskStatus,
    taskResult,
    sample_status,
    physical_form,
    laboratories,
    started_collection_date,
    initial_laboratory,
  } = queryString

  let assigneesObj: { inventory_manager: string[]; laboratory_assistant: string[] } = {
    inventory_manager: [],
    laboratory_assistant: [],
  }

  if (assignees) {
    assignees.split(',').map(item => {
      if (item.slice(item.indexOf('.') + 1) === '1') {
        assigneesObj.inventory_manager.push(item.substring(0, item.indexOf('.')))
      } else {
        assigneesObj.laboratory_assistant.push(item.substring(0, item.indexOf('.')))
      }
      return assigneesObj
    })
  }

  const capitalizedTaskResult = taskResult
    ?.split(',')
    .map(item => {
      return item.charAt(0).toUpperCase() + item.slice(1)
    })
    .join(',')

  const queryKey = [
    SAMPLES,
    desiredSamples,
    ordering,
    free_samples,
    apis,
    excipients,
    assigneesObj,
    taskStatus?.split(','),
    capitalizedTaskResult,
    sample_status,
    physical_form,
    laboratories,
    started_collection_date,
    initial_laboratory,
  ]

  return useInfiniteQuery<ISamplesData<T>, IError>(queryKey, fetchSamples, {
    getNextPageParam: lastPage => {
      const { next: nextPageUrl } = lastPage.links

      if (nextPageUrl) {
        const queryString = nextPageUrl.slice(nextPageUrl.indexOf('?'))
        const urlParams = new URLSearchParams(queryString)
        const nextPage = urlParams.get('page')

        return nextPage
      }
    },
    onSettled: () => {
      if (isSplitting) dispatch(endSplitting())
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
