import React from 'react'

import { IHRSamples, ISamples } from '@models/samples'
import { useAppSelector } from '@hooks/redux'
import { selectSamplesInModalState } from '@state/packages'

import { SampleRow } from '../SampleRows'

interface TableBodyProps {
  samples: ISamples | IHRSamples
  isVerifyTenant: boolean
}

export const TableBody: React.FC<TableBodyProps> = React.memo(({ samples, isVerifyTenant }) => {
  const selectedSamplesInModal = useAppSelector(selectSamplesInModalState)

  return (
    <>
      {samples.map(sample => (
        <React.Fragment key={sample.id}>
          <SampleRow
            sample={sample}
            isSampleSelected={selectedSamplesInModal.some(id => id === sample.id)}
            isVerifyTenant={isVerifyTenant}
          />
        </React.Fragment>
      ))}
    </>
  )
})
