import { styled } from '@mui/system'

import { Link } from '@styles'

export const AddAttachedMaterialBox = styled(Link)`
  display: flex;
  align-items: unset;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`
