import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'

import { generatePackageXLS } from '@api/packages'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useGeneratePackageXLS = ({
  packageId,
  specificId,
}: {
  packageId: string
  specificId: string
}) => {
  const dispatch = useDispatch()
  const mutation = useMutation<Blob, IError>(() => generatePackageXLS(packageId), {
    onSuccess: (data, oldData) => {
      const xlsBlob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const xlsLink = URL.createObjectURL(xlsBlob)

      const file = document.createElement('a')
      const fileName = `${specificId}.xlsx`
      file.href = xlsLink
      file.setAttribute('download', fileName)
      document.body.appendChild(file)

      file.click()

      URL.revokeObjectURL(file.href)
      file.parentNode?.removeChild(file)
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
