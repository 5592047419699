import { FormControlLabel, styled as styledMUI } from '@mui/material'

export const StyledLabel = styledMUI(FormControlLabel)`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: 0;
  margin-bottom: 43px;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: ${({ theme }) => theme.palette.text.primary};
  max-width: 408px;

  & .MuiFormControlLabel-label {
    font-weight: 400;
    margin-bottom: 5px;
  }

  & .MuiFormHelperText-root {
    margin-top: 4px;
    margin-left: 0;
    margin-right: 0;
  }
`
