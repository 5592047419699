import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'

import { InputProps } from './Input'

const styledInputConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'dataTestId':
      case 'width':
      case 'maxWidth':
      case 'height':
      case 'padding':
      case 'bgColorDefault':
      case 'maintenanceModeColor':
      case 'bgColor':
      case 'mr':
      case 'ml':
      case 'mrErr':
      case '$isMobile':
      case 'borderRadius':
      case 'borderColor':
      case '$textColor':
        return false

      default:
        return true
    }
  },
}

export const StyledInput = styled<React.FC<InputProps>>(TextField, styledInputConfig)`
  width: ${({ width }) => width};
  margin-right: ${({ mr }) => mr};
  margin-bottom: ${({ mb }) => mb};
  margin-left: ${({ ml }) => ml};
  max-width: ${({ maxWidth }) => maxWidth};

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & .MuiInputBase-input {
    padding: ${({ theme, height }) => theme.spacing(height ? (height - 23) / 2 / 8 : 1.2, 1.5)};
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    padding-right: ${({ pr }) => pr} !important;
    color: ${({ $textColor }) => $textColor};

    &::placeholder {
      color: #8f929b;
      opacity: 1;
      font-size: ${({ $isMobile }) => ($isMobile ? '14px' : '16px')};
    }
  }

  & .MuiInputBase-root {
    background-color: ${({ theme, bgColorDefault, maintenanceModeColor, bgColor }) =>
      bgColorDefault
        ? theme.palette.background.default
        : maintenanceModeColor
        ? '#234484'
        : bgColor
        ? bgColor
        : theme.palette.common.white};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '4px')};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid
      ${({ theme, borderColor, maintenanceModeColor }) =>
        maintenanceModeColor ? '#3371BC' : borderColor || theme.additional.stroke.main};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '4px')};
  }

  & .MuiInputBase-multiline {
    padding: 0;
  }

  & .MuiFormHelperText-root {
    white-space: ${({ $helperTextNoWrap }) => $helperTextNoWrap && 'nowrap'};
    & .Mui-error {
      margin-right: ${({ mrErr }) => mrErr};
    }

    & ::-webkit-scrollbar {
      height: 10px;
      width: 6px;
      background-color: #fff;
    }
  }
`
