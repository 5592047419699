import React from 'react'
import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { useUpdateTest } from '@hooks/queries'
import { Button } from '@components/shared'

import { StepFooter } from '../../../Testing/styles'

interface FinishTestProps {
  taskId: number
  loading: boolean
}

export const FinishTest: React.FC<FinishTestProps> = ({ taskId, loading = false }) => {
  const updateTestM = useUpdateTest()
  const { handleSubmit } = useFormContext()

  const finishTestHandler = (task_notes: { task_notes: string }) => {
    updateTestM.mutate({ taskId, data: { status: 'completed', ...task_notes } })
  }

  return (
    <StepFooter>
      <Box sx={{ width: 'fit-content' }}>
        <Button
          disabled={loading || updateTestM.isLoading}
          variant='contained'
          ml='auto'
          width='205px'
          height='48px'
          onClick={handleSubmit(finishTestHandler)}
        >
          Finish test
        </Button>
      </Box>
    </StepFooter>
  )
}
