import { AWAITING, COMPLETED, NOT_COMPLETED } from '@constants/tasks'
import { ADDITIONAL_INFO, RESULTS_STEP_TITLE, TESTING_STEP_TITLE } from '@constants/tests'
import { BTS, FTS, TestGroupName, VISUAL_GROUP, XTS } from '@models/tests'

interface IStepsOfTest {
  step: number
  subStep1?: number
  subStep2?: number
  title: string
  status: string
  ml?: string
}

export const stepsScreeningGroup = (testTypeName?: string): IStepsOfTest[] => {
  return [
    {
      step: -1,
      subStep1: 1,
      subStep2: 2,
      title: TESTING_STEP_TITLE,
      status: AWAITING,
    },
    {
      step: 3,
      title: [FTS, BTS, XTS].includes(testTypeName || '') ? ADDITIONAL_INFO : RESULTS_STEP_TITLE,

      status: NOT_COMPLETED,
    },
  ]
}

export const stepsAssayGroup: IStepsOfTest[] = [
  {
    step: -1,
    title: 'Description',
    status: AWAITING,
    ml: '-25px',
  },
  {
    step: 0,
    subStep1: 1,
    subStep2: 2,
    title: TESTING_STEP_TITLE,
    status: NOT_COMPLETED,
  },
  {
    step: 3,
    title: RESULTS_STEP_TITLE,
    status: NOT_COMPLETED,
  },
]

export const checkStepOfTest = (stepOfTest: number, isTwoStepsInTest: boolean): void => {
  const stepsOfTest = isTwoStepsInTest ? stepsScreeningGroup() : stepsAssayGroup

  // we need subStep1 and subStep2 because until the step 3 the test has open Testing step with status awaiting
  stepsOfTest.map(s => {
    if (s.step === stepOfTest || s.subStep1 === stepOfTest || s.subStep2 === stepOfTest) {
      s.status = AWAITING
    } else if (s.step < stepOfTest) {
      s.status = COMPLETED
    } else {
      s.status = NOT_COMPLETED
    }
    return s.status
  })
}

export const isVisualTestGroup = (testGroupName: TestGroupName): boolean => {
  const isVisualGroup = testGroupName === VISUAL_GROUP
  return isVisualGroup
}
