import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { TIMEZONES } from '@constants/queries'
import { fetchTimezones } from '@api/common'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useFetchTimezones = () => {
  const dispatch = useDispatch()
  return useQuery(TIMEZONES, fetchTimezones, {
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    retry: 1,
    retryDelay: 1500,
  })
}
