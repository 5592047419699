import React from 'react'

import { ITaskLab } from '@models/tasks'

import { Header, Tabs } from './components'
import { StyledSubSampleBox } from './styles'

interface SubTaskInfoProps {
  task?: ITaskLab
  isLoading: boolean
}

export const SubTaskInfo: React.FC<SubTaskInfoProps> = ({ task, isLoading }) => {
  return (
    <StyledSubSampleBox>
      <Header />
      <Tabs isLoading={isLoading} task={task} />
    </StyledSubSampleBox>
  )
}
