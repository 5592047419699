import React from 'react'
import { TabList } from '@mui/lab'
import { useMediaQuery } from '@mui/material'

import { tasksTabs } from '@constants/mappings'
import { StyledTab, StyledTabBox } from '@styles'
import { ITasksTabValue } from '@models/tasks'
import { SearchIcon } from '@components/shared'

interface TabButtonsProps {
  onChangeTab: (tab: ITasksTabValue) => void
  onChangeQuery: (newValue: string | null | undefined) => void
  shouldHiddenTabButtonsHandler?: () => void
  shouldHiddenTabButtons: boolean
}

export const TabButtons: React.FC<TabButtonsProps> = props => {
  const { onChangeTab, onChangeQuery, shouldHiddenTabButtons, shouldHiddenTabButtonsHandler } =
    props
  const $isMobile = useMediaQuery('(max-width:767px)')

  const changeTabHandler = (e: React.SyntheticEvent, tab: ITasksTabValue) => {
    onChangeTab(tab)

    onChangeQuery(tab)
  }

  return (
    <>
      {!shouldHiddenTabButtons && (
        <StyledTabBox mr='10px'>
          <TabList onChange={changeTabHandler}>
            {tasksTabs.map(tab => (
              <StyledTab key={tab.value} {...tab} iconPosition='end' $isMobile={$isMobile} $tasks />
            ))}
          </TabList>
        </StyledTabBox>
      )}

      {$isMobile && !shouldHiddenTabButtons && (
        <SearchIcon setOpenSearch={shouldHiddenTabButtonsHandler} />
      )}
    </>
  )
}
