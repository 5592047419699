import React from 'react'
import { useLocation } from 'react-router-dom'

import { Tooltip } from '@components/shared'
import { IBreadcrumbsRoute } from '@models/common/app'
import { removeRemainingCrumbs } from '@helpers/routes'

import { StyledLink, StyledTypography, StyledTypographyLink } from '../styles'

interface CrumbProps {
  id: string
  path: string
  title: string
  url: string
  search: string
  isLastRout: boolean
  isChangedField?: boolean
  onLinkHandler?: (path: string) => void
  packageTooltip?: string
}

export const Crumb: React.FC<CrumbProps> = props => {
  const { title, url, search, isLastRout, isChangedField, onLinkHandler, packageTooltip } = props

  const { state } = useLocation<IBreadcrumbsRoute[]>()
  const routTo = {
    pathname: url,
    search,
    state: state ? removeRemainingCrumbs(state, url) : [],
  }

  return (
    <>
      {isLastRout ? (
        <>
          {packageTooltip && packageTooltip ? (
            <Tooltip title={packageTooltip || ''}>
              <StyledTypography>{title}</StyledTypography>
            </Tooltip>
          ) : (
            <StyledTypography>{title}</StyledTypography>
          )}
        </>
      ) : (
        <>
          {isChangedField ? (
            <StyledTypographyLink
              onClick={() => {
                if (onLinkHandler) onLinkHandler(routTo.pathname)
              }}
            >
              {title}
            </StyledTypographyLink>
          ) : (
            <StyledLink to={routTo}>{`${title[0].toUpperCase() + title.slice(1)}`}</StyledLink>
          )}
        </>
      )}
    </>
  )
}
