import { styled, Select } from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'

import { StyledDropdownProps } from './Dropdown'

const styledDropdownConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'bgColor':
      case 'width':
      case 'height':
      case 'mr':
      case 'mb':
      case 'fontSize':
      case 'padding':
      case 'borderLeftColor':
      case 'borderRadius':
      case 'position':
      case 'placeholderColor':
        return false

      default:
        return true
    }
  },
}

export const StyledDropdown = styled<React.FC<StyledDropdownProps>>(Select, styledDropdownConfig)`
  position: ${({ position }) => position};
  margin-right: ${({ mr }) => mr};
  margin-bottom: ${({ mb }) => mb};
  width: ${({ width }) => (width ? width : '100%')};
  background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.palette.common.white)};
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ padding }) => padding};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '4px')};
  color: ${({ placeholderColor }) => placeholderColor};

  & .MuiSelect-select {
    padding: ${({ theme, height }) => theme.spacing(height ? (height - 23) / 2 / 8 : 1.2, 1.5)};
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.additional.stroke.main};
    border-left-color: ${({ borderLeftColor }) => borderLeftColor};
  }

  & .MuiSelect-iconOutlined,
  & .MuiSelect-iconStandard {
    color: ${({ theme }) => theme.text.subtitles.main};
  }

  & .MuiSelect-standard {
    margin-right: 7px;
    background-color: none;
  }

  & .MuiSelect-standard:focus {
    background-color: transparent;
  }

  & .MuiSelect-iconStandard {
    margin-right: 7px;
  }
`
