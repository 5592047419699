import React from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { format } from 'date-fns'
import { UseFormHandleSubmit, FieldValues } from 'react-hook-form'

import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { Breadcrumbs, Button, ConfirmationModal } from '@components/shared'
import { IBreadcrumbsRoute, IOption } from '@models/common/app'
import { selectSamplesChanged, setIsChangedField } from '@state/samples'
import { isContainRoute } from '@helpers/routes'
import { FlexWrapper, PageTitle } from '@styles'
import { PlaceForSaveType } from '@components/shared/sampleFields/GMPlaceAutocomplete'
import { datePatterns } from '@constants/dates'
import { conversionSampleOptions } from '@helpers/samples'
import * as sampleConstants from '@constants/samples'
import { INewSamples } from '@models/samples'
import { sampleCreate, setCreateSampleNotification } from '@state/sampleCreate'
import { useCreateSamples } from '@hooks/queries'
import { setAddSampleButtonPushed } from '@state/app'

interface PageHeaderProps {
  handleSubmit: UseFormHandleSubmit<FieldValues>
  setError: any
}

export const PageHeader: React.FC<PageHeaderProps> = ({ handleSubmit, setError }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { state } = useLocation<IBreadcrumbsRoute[]>()
  const { path, url } = useRouteMatch()
  const { samples } = useAppSelector(sampleCreate)
  const createSamplesM = useCreateSamples()

  // * [ check is any fields in create sample page was changed
  const [showModal, setShowModal] = React.useState(false)
  const [navLinkPath, setNavLinkPath] = React.useState('')
  const isChangedField = useAppSelector(selectSamplesChanged)

  const onLinkHandler = (path: string) => {
    if (setNavLinkPath) setNavLinkPath(path)

    if (isChangedField && setShowModal) {
      setShowModal(true)
    }
  }

  const goNavLinkConfirmation = () => {
    history.replace({
      pathname: navLinkPath,
    })
    dispatch(setIsChangedField(false))
  }

  // * check is any fields in create sample page was changed ]

  React.useEffect(() => {
    if (state && !isContainRoute(state, url)) {
      history.replace({
        state: [...state, { path, url, title: 'Create samples' }],
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, path, state, url])

  const onSubmit = handleSubmit(() => {
    let flag = false
    samples.forEach(sample => {
      if (sample.project.val?.name && (!sample.funding.val || sample.funding.val.length === 0)) {
        setError(`${sample.id}_${sampleConstants.FUNDING}`, {
          type: 'manual',
          message: 'Project should be with Funding. Please delete Project or add Funding.',
        })
        flag = true
      }
    })
    if (!flag) {
      const formattedData = samples.map(sample => {
        let addressOfManufacturer: PlaceForSaveType | null = null
        let towns: PlaceForSaveType | null = null

        const createPlaceAddressData = (val: PlaceForSaveType) => {
          const { place_id, description } = val
          addressOfManufacturer = { place_id, description }
          return []
        }
        const createPlaceTownData = (val: PlaceForSaveType) => {
          const { place_id, description } = val
          towns = { place_id, description }
          return []
        }

        let generatedSample = Object.fromEntries(
          Object.entries(sample).map(([key, value]) => {
            const val = value.val

            if (key.includes('date') && val) {
              return [key, format(val, datePatterns.yearMonthDay)]
            } else if (conversionSampleOptions.includes(key) && val) {
              return [key, val.id]
            } else if (key.includes(sampleConstants.REGULATORY_REGISTRATION) && val) {
              return [key, val]
            } else if (
              [
                sampleConstants.MAIN_APIS,
                sampleConstants.EXCIPIENTS,
                sampleConstants.TESTS,
              ].includes(key) &&
              val
            ) {
              return [key, (val as IOption[]).map(({ id }) => id)]
            } else if (key.includes(`${sampleConstants.ADDRESS_OF_MANUFACTURER}_detail`) && val) {
              createPlaceAddressData(val)
            } else if (key.includes(sampleConstants.ADDRESS_OF_MANUFACTURER) && val) {
              createPlaceAddressData(val)
            } else if (key.includes(`${sampleConstants.TOWN}_detail`) && val) {
              createPlaceTownData(val)
            } else if (key.includes(sampleConstants.TOWN) && val) {
              createPlaceTownData(val)
            } else if (key.includes(sampleConstants.ATTACHMENTS) && val) {
              return [key, val.map(({ id }: { id: number }) => id)]
            } else if (key.includes('isCopy')) {
              return [key, value]
            } else if (key.includes(sampleConstants.PROJECT) && val) {
              return [key, val.id]
            } else if (key.includes(sampleConstants.FUNDING)) {
              return [key, val?.map(({ id }: { id: number }) => id) || []]
            }

            if ([sampleConstants.VALUE].includes(key) && !val) return []
            if (key.includes(sampleConstants.SAMPLE_TYPE)) return []
            if (
              (key.includes(sampleConstants.TEMPERATURE_FORMAT) ||
                key.includes(sampleConstants.TOXICITY_LEVEL)) &&
              !val
            ) {
              return []
            }

            return [key, val]
          })
        )

        if (addressOfManufacturer) {
          const {
            place_id: manufacturer_address_place_id,
            description: manufacturer_address_description,
          } = addressOfManufacturer
          generatedSample = {
            ...generatedSample,
            manufacturer_address_place_id,
            manufacturer_address_description,
          }
        }
        if (towns) {
          const { place_id: town_place_id, description: town_description } = towns
          generatedSample = {
            ...generatedSample,
            town_place_id,
            town_description,
          }
        }

        return generatedSample
      }) as INewSamples

      createSamplesM.mutate(formattedData)
    }
  })

  const completeCreationOnClick = () => {
    onSubmit()
    dispatch(setCreateSampleNotification(true))
    dispatch(setAddSampleButtonPushed(0))
  }

  return (
    <>
      <FlexWrapper mb='0px'>
        <PageTitle mb={1.5}>Create samples </PageTitle>
        <Button
          variant='contained'
          ml='auto'
          width='205px'
          height='48px'
          disabled={createSamplesM.isLoading}
          loading={createSamplesM.isLoading}
          onClick={completeCreationOnClick}
        >
          Complete creation
        </Button>
      </FlexWrapper>

      <Breadcrumbs mb='0px' isChangedField={isChangedField} onLinkHandler={onLinkHandler} />

      {showModal && (
        <ConfirmationModal
          title={'Are you sure you want to cancel sample creation?'}
          cancelBtnText='Continue creation'
          confirmButtonText='Cancel'
          goBackModal
          onClose={() => setShowModal(false)}
          onConfirm={goNavLinkConfirmation}
        />
      )}
    </>
  )
}
