import React, { useEffect } from 'react'

import { ISamples } from '@models/samples'
import { useActionsStickyBox } from '@hooks/common'
import { StyledTableBox } from '@styles'
import { ScrollNextSamplesLoader, HorizontalDragLayer, HorizontalDragCup } from '@components/shared'
import { draggableHorizontalScroll } from '@helpers/draggableHorizontalScroll'
import { IPackage } from '@models/packages'

import { TableHead } from './TableHead'
import { TableBody } from './TableBody'

interface TableProps {
  samples?: ISamples
  totalSamples?: number
  isFetchingNextPage?: boolean
  actionBtnText?: 'Remove' | 'Missed'
  shouldDisplayActionBtn?: boolean
  shouldDisplayMissedText?: boolean
  packagesData?: IPackage
}

export const TableSamples: React.FC<TableProps> = props => {
  const {
    samples,
    totalSamples,
    isFetchingNextPage,
    actionBtnText,
    shouldDisplayActionBtn,
    shouldDisplayMissedText,
    packagesData,
  } = props

  const draggableHorizontalRef = React.useRef<HTMLDivElement | null>(null)
  const horizontalDragCup = React.useRef<HTMLDivElement | null>(null)
  const draggableHorizontalLayerRef = React.useRef<HTMLDivElement | null>(null)
  const tableHeadRef = React.useRef<HTMLDivElement | null>(null)
  const isSticky = useActionsStickyBox(tableHeadRef, '-250px 0px 0px 0px')

  useEffect(() => {
    if (draggableHorizontalRef.current) {
      const { removeDraggableEvents } = draggableHorizontalScroll(
        draggableHorizontalRef,
        draggableHorizontalLayerRef,
        horizontalDragCup
      )
      return () => removeDraggableEvents()
    }
  }, [])

  const horizontalScrollHandler: React.UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    tableHeadRef.current?.scrollTo(currentTarget.scrollLeft, 0)
  }

  const renderRowsLoader = () =>
    new Array(10).fill('').map((_, idx) => <ScrollNextSamplesLoader key={idx} />)

  return (
    <>
      <TableHead
        ref={tableHeadRef}
        hideBorder={isSticky}
        shouldDisplayActionBtn={shouldDisplayActionBtn}
      />
      <HorizontalDragCup ref={horizontalDragCup} />
      <StyledTableBox ref={draggableHorizontalRef} onScroll={horizontalScrollHandler}>
        <HorizontalDragLayer ref={draggableHorizontalLayerRef} />
        <TableBody
          samples={samples}
          totalSamples={totalSamples}
          actionBtnText={actionBtnText}
          shouldDisplayActionBtn={shouldDisplayActionBtn}
          shouldDisplayMissedText={shouldDisplayMissedText}
          packagesData={packagesData}
        />
      </StyledTableBox>
      {isFetchingNextPage && renderRowsLoader()}
    </>
  )
}
