import React from 'react'
import { Box, Collapse, useTheme } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { useToggle } from '@hooks/common'
import { MobileDetailsInfoCard } from '@components/shared'
import { StyledBoxMobile } from '@styles'

import { Info } from '../Info'

interface InfoProps {
  task: ITaskLab
}

export const InfoMobile: React.FC<InfoProps> = ({ task }) => {
  const [openMainInfo, setIsOpenMainInfo] = useToggle()
  const theme = useTheme()

  const cards = [
    {
      title: 'Main info section',
      component: Info,
      task,
      open: openMainInfo,
      setIsOpen: setIsOpenMainInfo,
    },
  ]

  return (
    <>
      {cards.map(({ title, task, open, setIsOpen, component: Component }) => (
        <StyledBoxMobile key={title} bg={theme.primary[30]} mb='0'>
          <MobileDetailsInfoCard
            title={title}
            open={open}
            setIsOpen={setIsOpen}
            bg={theme.primary[30]}
            textEndPosition
          />

          <Box>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Component task={task} />
            </Collapse>
          </Box>
        </StyledBoxMobile>
      ))}
    </>
  )
}
