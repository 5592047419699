import React from 'react'
import { Box, Collapse } from '@mui/material'
import { debounce } from 'lodash'

import { ParagraphLink } from '@components/shared/ParagraphLink'
import { selectedFilters as filters } from '@state/filters'
import {
  ASSIGNEE_FILTER_TITLE,
  selectedFiltersSlice,
} from '@components/shared/FilterAside/constants'
import { useToggle, useCustomLazyScroll } from '@hooks/common'
import { useAppSelector } from '@hooks/redux'
import { useFetchAssigneeFilters } from '@hooks/queries'
import { selectUser } from '@state/user'
import { FilterLoader } from '@components/shared/FilterAside'

import { FilterTopTitle } from '../../components/FilterTopTitle'
import { CardRow, StyledShowMoreLinkBox } from '../styles'
import { SearchFilters } from '../../components/SearchFilters'
import { FilterCheckbox } from '../../components/FilterCheckbox'
import { FilterSelectAll } from '../../components/FilterSelectAll'

export const AssigneeFilter = () => {
  const [isFilterClose, setIsFilterClose] = useToggle()
  const [desiredAssignee, setDesiredAssignee] = React.useState<string>()
  const [isShowMore, setIsShowMore] = React.useState<boolean>(true)
  const selectedFilters = useAppSelector(filters)

  const { laboratory } = useAppSelector(selectUser)
  const { data, fetchNextPage, hasNextPage, isLoading, isFetching } =
    useFetchAssigneeFilters(desiredAssignee)

  const contentScrollBox = document.getElementById('content-scroll-box')
  const parentRef = React.useRef<HTMLElement | HTMLDivElement | null>(contentScrollBox)
  const scrollTriggerRef = React.useRef<HTMLDivElement | null>(null)
  const wrapRef = React.useRef<HTMLDivElement | null>(null)
  const showNumberOfItems = 5

  useCustomLazyScroll(parentRef, scrollTriggerRef, fetchNextPage, isShowMore, hasNextPage)

  const assistants = data?.pages
    .map(page => page.results.laboratory_assistants)
    .flat()
    .map(item => ({ ...item, id: String(`${item?.id}.2`) }))

  const filterCounter = selectedFilters[selectedFiltersSlice.assignees].filters.length || undefined

  const choiceDesiredAssignee = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | undefined) =>
      event && setDesiredAssignee(event.target.value),
    []
  )

  const debounceChoiceDesiredAssignee = debounce(choiceDesiredAssignee, 500)

  React.useEffect(() => {
    if (isShowMore && wrapRef && wrapRef.current && contentScrollBox) {
      // number 63 - it's aside header height
      contentScrollBox.scrollTo({ top: wrapRef.current.offsetTop - 63 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowMore])

  const assistantsListItems = React.useMemo(() => {
    if (assistants) {
      if (isShowMore) return assistants.slice(0, showNumberOfItems)
      else return assistants
    }
  }, [assistants, isShowMore])

  return (
    <div ref={wrapRef}>
      <FilterTopTitle
        filterNumb={Number(assistants?.length)}
        isFilterClose={isFilterClose}
        setIsFilterClose={setIsFilterClose}
        titleOfFilter={ASSIGNEE_FILTER_TITLE}
        filterCounter={filterCounter}
      />
      <Collapse in={!isFilterClose} unmountOnExit>
        <CardRow>
          <SearchFilters searchFiltersHandler={debounceChoiceDesiredAssignee} />
          <Box sx={{ width: '100%' }}>
            {(isFetching || isLoading) && isShowMore ? (
              <Box width='100%'>
                <FilterLoader />
              </Box>
            ) : (
              <div style={{ paddingBottom: '12px' }}>
                {assistants && !desiredAssignee && (
                  <FilterSelectAll
                    type='assignees'
                    items={assistants.map(({ last_name, first_name, id }) => ({
                      id,
                      name: `${first_name} ${last_name}`,
                    }))}
                  />
                )}

                {assistantsListItems &&
                  assistantsListItems.map(({ last_name, first_name, id }, idx) => {
                    if (idx === assistantsListItems.length - 10) {
                      return (
                        <React.Fragment key={id}>
                          <div ref={scrollTriggerRef} />

                          <FilterCheckbox
                            type='assignees'
                            item={{ id, name: `${first_name} ${last_name}` }}
                            key={id}
                            labOwner={id === String(laboratory.id)}
                          />
                        </React.Fragment>
                      )
                    }
                    return (
                      <FilterCheckbox
                        type='assignees'
                        item={{ id, name: `${first_name} ${last_name} ` }}
                        key={id}
                        labOwner={id === String(laboratory.id)}
                      />
                    )
                  })}

                {data && Number(assistants?.length) > 5 && !desiredAssignee && (
                  <StyledShowMoreLinkBox>
                    <ParagraphLink
                      sx={{ padding: '11px 24px 12px' }}
                      onClick={() => setIsShowMore(!isShowMore)}
                    >
                      Show {isShowMore ? 'more' : 'less'}
                    </ParagraphLink>
                  </StyledShowMoreLinkBox>
                )}
              </div>
            )}
          </Box>
        </CardRow>
      </Collapse>
    </div>
  )
}
