import { styled, Typography } from '@mui/material'

export const StyledTypography = styled(Typography)`
  margin-bottom: 2px;

  & .MuiTypography-body2 {
    display: block;
    color: #616d84;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`
