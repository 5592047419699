import { cloneDeep } from 'lodash'

import { IAttachment, IAttachments } from '@models/attachments'

type LoadingAttachmentsTypes = {
  attachment: IAttachment
  index: number
}

export const findLostLoadingAttachment = (fetchedData: IAttachments, attachments: IAttachments) => {
  let cloneFetchedData = cloneDeep(fetchedData)
  let loadingAttachments: LoadingAttachmentsTypes[] = []
  // Found all pending loading status attachments in component state
  // and pushed this attachments in "loadingAttachments" array with his array index
  attachments.forEach((attachment, index) => {
    // !attachment.link below - because links are quickly load, and we have a bug
    // with loading duplicate of this link in view list of attachments
    if (attachment.loading && !attachment.link) {
      loadingAttachments.push({ attachment, index })
    }
  })

  if (loadingAttachments.length) {
    // Left in "loadingAttachments" array only truly pending attachments
    loadingAttachments = loadingAttachments.filter(
      loadingAttachment =>
        !fetchedData.some(
          attachment =>
            attachment.name === loadingAttachment.attachment.name &&
            attachment.size === loadingAttachment.attachment.size &&
            attachment.attachment_section.key ===
              loadingAttachment.attachment.attachment_section.key
        )
    )
    // If after previous logic at the top of this statement we still have some loading attachments -
    // we set to cloned "fetchedData" array some loading attachments in the correct place
    if (loadingAttachments.length) {
      loadingAttachments.forEach(attachmentData => {
        cloneFetchedData.splice(attachmentData.index, 0, attachmentData.attachment)
      })
    }
  }

  return cloneFetchedData
}
