import React from 'react'
import { Typography, useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'

import { ReactComponent as LetterIcon } from '@assets/icons/letter-mode.svg'
import { unsubscribeFromMailing } from '@api/user'
import { setMaintenanceModeUnsubscribePage, setMaintenanceModeUserEmail } from '@state/user'

import { InfoPageBox, ModeLink } from '../styles'

interface InfoPageProps {
  title: string
  text: string
  linkDescription: string
  modeLinkTitle: string
  link: string
  iconColor: string
  titleColor: string
  textColor: string
  linkColor: string
  typeOfContent: 'unsubscribe' | 'subscribe'
  email: string
}

export const InfoPage: React.FC<InfoPageProps> = props => {
  const {
    title,
    text,
    linkDescription,
    modeLinkTitle,
    link,
    iconColor,
    titleColor,
    textColor,
    linkColor,
    typeOfContent,
    email,
  } = props

  const isMobile = useMediaQuery('(max-width:767px)')
  const dispatch = useDispatch()

  const isSubscribeAction = typeOfContent === 'subscribe'
  const isUnsubscribeAction = typeOfContent === 'unsubscribe'

  const actionHandler = () => {
    if (isUnsubscribeAction) {
      dispatch(setMaintenanceModeUnsubscribePage(false))
    }

    if (isSubscribeAction) {
      unsubscribeFromMailing(email)
      dispatch(setMaintenanceModeUnsubscribePage(true))
      dispatch(setMaintenanceModeUserEmail({ email: '' }))
    }
  }

  return (
    <InfoPageBox $isSubscribeContent={typeOfContent === 'subscribe'} $isMobile={isMobile}>
      <LetterIcon fill={iconColor} />
      <Typography variant='h3' color={titleColor} mt={3}>
        {title}
      </Typography>
      <Typography variant='body1' color={textColor} lineHeight='24px' mt={1}>
        {text}
      </Typography>
      <Typography variant='body1' color={textColor} lineHeight='24px' mt={3}>
        {linkDescription}
        <ModeLink to={link} onClick={actionHandler} color={linkColor}>
          {modeLinkTitle}
        </ModeLink>
      </Typography>
    </InfoPageBox>
  )
}
