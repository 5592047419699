import React from 'react'
import { Collapse, useMediaQuery } from '@mui/material'

import { TasksStep } from '@components/shared'
import { AWAITING, COMPLETED, NOT_COMPLETED } from '@constants/tasks'
import { StyledPageSubTitle } from '@styles'

import { StepContainer } from './styles'
import { StepHeader } from '../styles'

interface CompletedStepProps {
  title: string
  counter: number
  status: typeof AWAITING | typeof COMPLETED | typeof NOT_COMPLETED
  open: boolean
}

export const CompletedStep: React.FC<CompletedStepProps> = ({ title, counter, status, open }) => {
  const isMobile = useMediaQuery('(max-width:767px)')

  return (
    <Collapse in={open}>
      <StepContainer>
        <StepHeader sx={{ marginTop: '20px' }}>
          <TasksStep
            isMobile={isMobile}
            idx={counter - 1}
            stepCounter={counter}
            stepColor={status}
          />

          <StyledPageSubTitle variant='h3' sx={{ color: '#616D84' }}>
            {title}
          </StyledPageSubTitle>
        </StepHeader>
      </StepContainer>
    </Collapse>
  )
}
