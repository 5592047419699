import React from 'react'
import { TableBody, Typography, useTheme } from '@mui/material'

import { ISampleTasks } from '@models/samples'
import { StyledDetailsTable, StyledDetailsTableCell, StyledDetailsTableRow } from '@styles'
import { COMPLETED } from '@constants/tasks'
import { ReactComponent as NoTestingInfo } from '@assets/images/no-testing-info-in-subsample.svg'

import { TaskRow } from './TaskRow'
import { TableHead } from './TableHead'

interface TabTestingInfoProps {
  tasks: ISampleTasks
}

export const TabTestingInfo: React.FC<TabTestingInfoProps> = ({ tasks }) => {
  const isSampleHasCompletedTask = tasks.some(task => task.status.key === COMPLETED)
  const theme = useTheme()

  return (
    <StyledDetailsTable>
      <TableHead />

      <TableBody>
        {isSampleHasCompletedTask ? (
          <>
            {tasks.map(task => (
              <TaskRow key={task.id} task={task} />
            ))}
          </>
        ) : (
          <StyledDetailsTableRow>
            <StyledDetailsTableCell
              colSpan={4}
              sx={{ bgcolor: 'background.default', alignItems: 'center', justifyContent: 'center' }}
            >
              <NoTestingInfo fill={theme.primary[70]} />
              <Typography variant='h5' color='#778299' ml='17px'>
                There are no testing info yet
              </Typography>
            </StyledDetailsTableCell>
          </StyledDetailsTableRow>
        )}
      </TableBody>
    </StyledDetailsTable>
  )
}
