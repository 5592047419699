import React from 'react'
import { Typography } from '@mui/material'

import { getFormattedDate } from '@helpers/ui/date'
import { datePatterns } from '@constants/dates'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'

interface TestingDateProps {
  data?: string | null
}

export const TestingDate: React.FC<TestingDateProps> = ({ data }) => {
  const { timezone } = useAppSelector(selectUser)

  return (
    <Typography variant='body1'>
      {getFormattedDate(datePatterns.monthDayYear, data, timezone) || '-'}
    </Typography>
  )
}
