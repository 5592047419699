import React, { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { StyledLabel } from '@styles'
import { Input } from '@components/shared'

export const PackageTypeField: React.FC = () => {
  const { setValue, trigger, formState } = useFormContext()

  const [packageType, setPackageType] = useState<any>('')

  const packageTypeError = formState.errors['package_type']

  const handleChange = useCallback(
    (event: any) => {
      setPackageType(event?.target?.value)
      setValue('package_type', event?.target?.value)
      trigger(['package_type'])
    },
    [setValue, trigger]
  )

  return (
    <>
      <StyledLabel
        mb='25px'
        label='Package Type'
        control={
          <Input
            fullWidth
            height={48}
            variant='outlined'
            value={packageType}
            onChange={handleChange}
            placeholder='Type here values...'
            error={!!packageTypeError}
            helperText={packageTypeError?.message}
          />
        }
      />
    </>
  )
}
