import React from 'react'
import { Box, useTheme } from '@mui/material'

import { ISample } from '@models/samples'
import { useAppSelector } from '@hooks/redux'
import { selectUserCustomizeTableColumns } from '@state/user'

import { Notes } from './Notes'
import { Dosage } from './Dosage'
import { APIsCell } from './APIsCell'
import { ExcipientsCell } from './ExcipientsCell'
import { SampleID } from './SampleID'
import { DrugClass } from './DrugClass'
import { PackageID } from './PackageID'
import { ActionCell } from './ActionCell'
import { Destination } from './Destination'
import { Manufacturer } from './Manufacturer'
import { ExpirationDateCell } from './ExpirationDateCell'
import { Toggler } from './Toggler'
import { Units } from './Units'
import { SampleCellsProps } from './sampleCells.types'
import { Project } from './Project'

export const SampleCells: React.FC<SampleCellsProps> = React.memo(props => {
  const { sample, isLastSample, isSampleSelected, isFetchingSamples, isLoadingSamples } = props
  const theme = useTheme()

  const { sample_columns } = useAppSelector(selectUserCustomizeTableColumns)

  const cells = [
    { component: Units, id: 1 },
    { component: Manufacturer, id: 2 },
    { component: Notes, id: 3 },
    { component: DrugClass, id: 4 },
    { component: APIsCell, id: 5 },
    { component: ExcipientsCell, id: 6 },
    { component: Dosage, id: 7 },
    { component: ExpirationDateCell, id: 8 },
    { component: Destination, id: 9 },
    { component: PackageID, id: 10 },
    { component: Project, id: 11 },
  ]

  const filteredCells = sample_columns
    .filter(column => column.activated)
    .map(column => cells.find(cell => cell.id === column.id))

  return (
    <>
      <Toggler
        sample={sample}
        isLastSample={isLastSample}
        isSampleSelected={isSampleSelected}
        isFetchingSamples={isFetchingSamples}
      />
      <SampleID sample={sample} />

      {filteredCells.map((filteredCell, idx) => {
        const Cell = filteredCell?.component as React.FC<{
          sample: ISample
          isLastSample?: boolean
          isSampleSelected?: boolean
        }>

        return (
          <Cell
            key={idx}
            sample={sample}
            isLastSample={isLastSample}
            isSampleSelected={isSampleSelected}
          />
        )
      })}

      <Box sx={{ backgroundColor: isSampleSelected ? theme.primary[30] : '#ffffff' }}></Box>

      <ActionCell
        sample={sample}
        isLastSample={isLastSample}
        isSampleSelected={isSampleSelected}
        isFetchingSamples={isFetchingSamples}
        isLoadingSamples={isLoadingSamples}
      />
    </>
  )
})
