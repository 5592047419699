import { RootState } from '@store'
// * [Tandem MS
export const isResultRecognizedTandemMSCheckboxState = (state: RootState) =>
  state.test.isResultNotRecognized
export const isAssistantAddedNewTandemMSTest = (state: RootState) =>
  state.test.isAddedNewTandemMSTest
export const tandemMSData = (state: RootState) => state.test.tandemMSTestData
export const isInvalidJsonApiError = (state: RootState) => state.test.isInvalidJsonApiError
export const isLoading = (state: RootState) => state.test.isTestUpdating
export const updatedTestId = (state: RootState) => state.test.updatedTestId

// * Tandem MS]
