import { Box, BoxProps, styled, Table } from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import { MutableRefObject } from 'react'

interface TableCellProps extends BoxProps {
  hideBorder?: boolean | MutableRefObject<HTMLDivElement | null>
}

const TableCellConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'hideBorder':
        return false

      default:
        return true
    }
  },
}

export const StyledTable = styled(Table)`
  text-align: left;
  background-color: ${({ theme }) => theme.palette.common.white};
  overflow-x: scroll;
  cursor: pointer;
`

export const StyledTableRow = styled(Box)`
  & form {
    display: grid;
    grid-template-columns: [number]60px [drug-class]286px [dosage]286px [units]320px [price-value]300px [main-APIs]340px [APIs]340px [sample-notes]326px [lot]286px [bayer-name]286px [purchase-date]210px [town-purchased]286px [pharmacy-name]286px [pharmacy-gps]286px [manufacturer]286px [address-of-manufacturer]500px [brand]286px [manufacture-date]210px [expiration-date]210px [regulatory-registration-status-in-the-country]295px [country-of-origin]286px [action]200px;
  }
  display: grid;
  grid-template-columns: [number]60px [drug-class]286px [dosage]286px [units]320px [price-value]300px [main-APIs]340px [APIs]340px [sample-notes]326px [lot]286px [bayer-name]286px [purchase-date]210px [town-purchased]286px [pharmacy-name]286px [pharmacy-gps]286px [manufacturer]286px [address-of-manufacturer]500px [brand]286px [manufacture-date]210px [expiration-date]210px [regulatory-registration-status-in-the-country]295px [country-of-origin]286px [action]200px;
`

export const StyledTableButtonRow = styled(Box)`
  display: grid;
  grid-template-columns: [button]6245px [action]200px;
`

export const StyledTableCell = styled<React.FC<TableCellProps>>(Box, TableCellConfig)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 12px 0 12px 0;

  border-bottom: 4px solid
    ${({ hideBorder, theme }) => (hideBorder ? 'transparent' : theme.palette.background.default)};

  &:first-child {
    border-top-left-radius: 10px;
    border-radius: 10px 0px 0px 10px;
  }

  &:not(:last-child) {
    border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};
  }
`
