import { useMediaQuery } from '@mui/material'

import { StyledTooltip } from '@components/shared/Tooltip/components'
import { skillDetails } from '@constants/mappings'
import { Tooltip } from '@components/shared/Tooltip'

interface RenderIconProps {
  idx: number
  tooltipTitle: NonNullable<React.ReactNode>
  miniIcons?: boolean
  ml?: string
}

export const RenderIcon: React.FC<RenderIconProps> = ({
  idx,
  tooltipTitle,
  miniIcons = false,
  ml,
}) => {
  const isMobile = useMediaQuery('(max-width:767px)')

  // iconType for skillDetails function
  const iconType = miniIcons ? 'mobile' : 'default'
  return (
    <>
      {isMobile ? (
        <StyledTooltip
          placement='top'
          arrow
          onClick={e => e.stopPropagation()}
          enterTouchDelay={0}
          title={tooltipTitle}
          sx={{ zIndex: 1500 }}
        >
          <img
            src={skillDetails.icon(idx)[iconType]}
            alt='icon'
            style={{ marginLeft: ml ? ml : '8px' }}
          />
        </StyledTooltip>
      ) : (
        <Tooltip isPureTooltip title={tooltipTitle} positionLeft={-4} maxWidth={210}>
          <img src={skillDetails.icon(idx)[iconType]} alt='icon' style={{ marginLeft: '8px' }} />
        </Tooltip>
      )}
    </>
  )
}
