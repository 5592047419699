import React from 'react'

interface IConditionalHiddenProps {
  condition?: boolean
}

export const ConditionalHidden: React.FC<IConditionalHiddenProps> = ({
  children,
  condition = true,
}) => {
  return <>{condition ? children : null}</>
}
