import React from 'react'

import { IPackage } from '@models/packages'
import { Button } from '@components/shared'
import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'
import { useUpdatePackage } from '@hooks/queries'

interface UnpackedProps {
  pack: IPackage
  mr?: string
}

export const Unpacked: React.FC<UnpackedProps> = ({ pack, mr }) => {
  const { mutate, isLoading } = useUpdatePackage()

  const unpackedHandler = () => {
    mutate(
      { id: pack.id, data: { delivery_status: 'unpacked' } },
      {
        onSuccess: () => {
          showNotification(notifications.unpackedPackagesSuccessfully)
        },
        onError: () => {
          showNotification(notifications.unpackedPackagesFailed)
        },
      }
    )
  }

  return (
    <Button
      variant='outlined'
      width='90px'
      padding='0'
      mr={mr}
      fz={15}
      disabled={isLoading}
      onClick={unpackedHandler}
    >
      Unpack
    </Button>
  )
}
