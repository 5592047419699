import React from 'react'
import { useDispatch } from 'react-redux'
import { useController } from 'react-hook-form'

import { PureDropdownAutocomplete } from '@components/ui'
import { useFetchDrugClassesFilters } from '@hooks/queries'
import { setCellValueToSample } from '@state/sampleCreate'
import { IOption } from '@models/common/app'
import { numerateOptions } from '@helpers/samples'

import { PureCellPropTypes } from './pureFields.types'

export const DrugClasses: React.FC<PureCellPropTypes> = props => {
  const { control, name, cellName, sampleId, rules, initialVal, isCopy } = props
  const [searchQuery, setSearchQuery] = React.useState('')
  const dispatch = useDispatch()

  const { data, isFetching } = useFetchDrugClassesFilters(searchQuery)
  const options = data && data.pages.map(page => page.results).flat()

  const setCellValue = (val: IOption | null, error: string) => {
    dispatch(
      setCellValueToSample({
        cellValue: { val, error },
        cellName,
        sampleId,
      })
    )
  }

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: initialVal.val })

  React.useEffect(() => {
    if (error && error.message) setCellValue(field.value, error.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onDropdownChange = (val: IOption) => {
    field.onChange({ target: { value: val } })
    setCellValue(val, '')
  }

  return (
    <PureDropdownAutocomplete
      isCopy={isCopy}
      isLoading={isFetching}
      initialVal={initialVal.val as IOption | null}
      name={name}
      options={numerateOptions(options) || []}
      errorMessage={error?.message || ''}
      onInputChange={val => setSearchQuery(val)}
      onDropdownChange={onDropdownChange}
      withTooltip
    />
  )
}
