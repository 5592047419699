import { Box, IconButton, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'

import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { IHrLaboratory, ILaboratory } from '@models/common/app'
import { useFetchLaboratories } from '@hooks/queries'
import { useAppSelector } from '@hooks/redux'
import { setIsOpenEditLaboratoryModal, workSpaceInfo } from '@state/workSpace'
import { EditInfoLaboratory } from '@components/modules/Modals'
import { notifications } from '@constants/notifications'
import { showNotification } from '@helpers/app'

import * as Styled from '../../styles'
import { AdditionalInfo } from './AdditionalInfo/AdditionalInfo'
import { ContactsInfo } from './ContactsInfo/ContactsInfo'
import { LoaderEditInfo } from './LoaderEditInfo'

export const EditInfo = React.memo(() => {
  const { selectedLaboratory, isOpenEditLaboratoryModal } = useAppSelector(workSpaceInfo)
  const dispatch = useDispatch()
  const theme = useTheme()

  const [currentLaboratoryData, setCurrentLaboratory] = React.useState<
    ILaboratory | IHrLaboratory | null
  >(selectedLaboratory)
  const [isEditInfoModalOpen, toggleEditInfoModal] = React.useState(false)

  const { data, isLoading, isFetching } = useFetchLaboratories(selectedLaboratory?.name, true)

  React.useEffect(() => {
    toggleEditInfoModal(isOpenEditLaboratoryModal)
  }, [isOpenEditLaboratoryModal])

  React.useEffect(() => {
    setCurrentLaboratory(selectedLaboratory)
  }, [selectedLaboratory])

  const currentLaboratory = data?.pages
    .map(page => page.results)
    .flat()
    .filter(({ id }) => id === currentLaboratoryData?.id)[0]

  if (isLoading || isFetching)
    return (
      <Styled.EditInfoBox>
        <LoaderEditInfo />
      </Styled.EditInfoBox>
    )

  const defaultManager = `${currentLaboratory?.manager?.first_name} ${currentLaboratory?.manager?.last_name}`
  const numbers = currentLaboratory?.phone_numbers?.map(n => n.phone_number).join('\n')

  const emails = currentLaboratory?.emails?.map(e => e.email).join('\n')
  const address = !!currentLaboratory?.address?.length
    ? `- Address of laboratories \n${currentLaboratory?.address}`
    : ''

  const copyInfoToClipboard = () => {
    const text = `${address}\n - Phone number(s)\n${numbers}\n- Main manager \n${defaultManager}\n - Email address(es)\n${emails}`

    navigator.clipboard.writeText(text).then(() => {
      showNotification(notifications.copiedLabContactsToClipboard)
    })
  }

  const isAbleToEdit = currentLaboratory?.is_able_to_edit_contact_info

  return (
    <>
      <Styled.EditInfoBox>
        {/* Title */}
        <Box display='flex' alignItems='center' mb='38px'>
          <Typography variant='h3' mr='16px'>
            {isAbleToEdit ? 'Edit info' : 'Laboratory info'}
          </Typography>

          {/* Edit Info  */}
          {isAbleToEdit && (
            <IconButton
              sx={{ padding: '10px', border: '1px solid #E5E5E6' }}
              onClick={() => dispatch(setIsOpenEditLaboratoryModal(true))}
            >
              <EditIcon
                fill={theme.palette.primary.main}
                style={{ width: '19px', height: '19px' }}
              />
            </IconButton>
          )}
        </Box>

        {/* [Contacts */}
        <ContactsInfo
          copyInfoToClipboard={copyInfoToClipboard}
          currentLaboratory={currentLaboratory}
        />

        {/* [Additional info */}
        <AdditionalInfo currentLaboratory={currentLaboratory} />
      </Styled.EditInfoBox>

      {/* Modal for edit laboratory info */}
      {isEditInfoModalOpen && <EditInfoLaboratory currentLaboratory={currentLaboratory} />}
    </>
  )
})
