import React from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'

import { StyledTableSampleCell } from '@styles'
import { ILaboratory } from '@models/common/app'
import { stringSlice } from '@helpers/ui/common'
import { Tooltip } from '@components/shared'
import { setLaboratoryForWorkspace } from '@state/workSpace'
import toxicIcon from '@assets/icons/toxic.svg'
import tempSensitiveIcon from '@assets/icons/temp-sensitive.svg'
import { TEMPERATURE_NAME, TOXIC_NAME } from '@constants/samples'

import { ParagraphLink } from '../../../styles'

interface TableCellsProps {
  laboratory?: ILaboratory
}

const mapSkills: Record<string, any> = {
  [TEMPERATURE_NAME]: {
    icon: tempSensitiveIcon,
    tooltip: 'Works with temperature-sensitive samples',
  },
  [TOXIC_NAME]: {
    icon: toxicIcon,
    tooltip: 'Works with toxic samples',
  },
}

export const TableCells: React.FC<TableCellsProps> = props => {
  const { laboratory } = props
  const dispatch = useDispatch()

  const testTypes = laboratory?.test_types?.map(testType => testType.name).join(', ')
  const deliveryServices = laboratory?.delivery_services?.map(testType => testType.name).join(', ')
  const defaultManager = !!laboratory?.manager
    ? `${laboratory?.manager?.first_name} ${laboratory?.manager?.last_name}`
    : '-'

  const viewLaboratoryInfoEndPoint = () => {
    if (laboratory) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      dispatch(setLaboratoryForWorkspace(laboratory))
    }
  }

  return (
    <>
      <StyledTableSampleCell padding='16px 28px 34px 40px'>
        <Tooltip
          isPureTooltip
          title={laboratory?.name || ''}
          shouldBeHidden={((laboratory?.name && laboratory?.name.length) || 0) <= 14}
          maxWidth={210}
          positionLeft={0}
          positionTop={0}
        >
          <ParagraphLink onClick={viewLaboratoryInfoEndPoint}>
            <Typography mr='4px' sx={{ whiteSpace: 'nowrap' }}>
              {!!laboratory?.name ? stringSlice(laboratory.name, 14) : '-'}
            </Typography>
          </ParagraphLink>
        </Tooltip>

        {laboratory?.skills?.map(skill => (
          <Tooltip key={skill.id} title={mapSkills[skill.name].tooltip}>
            <img
              src={mapSkills[skill.name].icon}
              alt={`${skill.name} icon`}
              style={{ marginLeft: '4px' }}
            />
          </Tooltip>
        ))}
      </StyledTableSampleCell>

      <Tooltip
        title={laboratory?.address || ''}
        shouldBeHidden={((laboratory?.address && laboratory?.address.length) || 0) <= 26}
      >
        <StyledTableSampleCell sx={{ padding: '16px 48px 16px 0' }}>
          {!!laboratory?.address ? stringSlice(laboratory.address, 26) : '-'}
        </StyledTableSampleCell>
      </Tooltip>

      <Tooltip title={defaultManager || ''} shouldBeHidden={(defaultManager.length || 0) <= 20}>
        <StyledTableSampleCell sx={{ padding: '16px 48px 16px 0' }}>
          {stringSlice(defaultManager, 20)}
        </StyledTableSampleCell>
      </Tooltip>

      <StyledTableSampleCell sx={{ padding: '16px 48px 16px 0' }}>
        {laboratory?.group.name}
      </StyledTableSampleCell>

      <StyledTableSampleCell sx={{ padding: '16px 48px 16px 0' }}>
        {testTypes?.length ? testTypes : '-'}
      </StyledTableSampleCell>

      <StyledTableSampleCell sx={{ padding: '16px 48px 16px 0' }}>
        {laboratory?.delivery_services?.length ? deliveryServices : '-'}
      </StyledTableSampleCell>
    </>
  )
}
