import React, { useState } from 'react'
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormHelperText,
  TextField,
  createFilterOptions,
  useMediaQuery,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { useCreateProject, useUpdateProject } from '@hooks/queries/useFetchProject'
import { IProjects } from '@models/projects'
import { Button, Modal } from '@components/shared'
import { StyledShadowBoxTitle } from '@components/modules/FeedbackForm/styles'
import {
  ButtonBox,
  StyledModalContent,
  StyledModalScroll,
  StyledModalTitle,
  StyledShadowBox,
} from '@styles'

interface EditProjectProps {
  mode: string
  project: any
  onClose: () => void
  projects: IProjects
  refetchProjects: any
}

export const EditProject: React.FC<EditProjectProps> = props => {
  const { project, onClose, projects, mode } = props

  const [projectName, setProjectName] = useState<string>(project?.name || '')
  const [defaultProjectFunding] = useState<any>(project?.funding)
  const [options, setOptions] = useState<any>(defaultProjectFunding)
  const [errorProjectNameLength, setErrorProjectNameLength] = useState<any>(null)
  const [errorProjectNameUnique, setErrorProjectNameUnique] = useState<any>(null)
  const [errorFunding, setErrorFunding] = useState<any>(null)

  const useUpdateProjectM = useUpdateProject()
  const useCreateProjectM = useCreateProject()

  const isMobile = useMediaQuery('(max-width:767px)')

  const validationForm = React.useMemo(() => {
    const maxLength = 250
    const filteredProjects =
      mode === 'edit' ? projects.filter(proj => proj.id !== project?.id) : projects

    const pattern = /^[\s]+$/
    const isProjectNameContainsOnlySpaces = pattern.test(projectName)
    const isUniqueProjectName =
      filteredProjects.filter(proj => proj.name === projectName).length === 0
    const isValidProjectNameLength = projectName.length <= maxLength
    const isValidFundingLength =
      options.filter((option: any) => option.value.length > maxLength).length === 0
    if (isUniqueProjectName && isValidProjectNameLength) {
      setErrorProjectNameLength(null)
      setErrorProjectNameUnique(null)
    } else {
      if (!isUniqueProjectName) {
        setErrorProjectNameUnique('Project name should be unique')
      }
      if (!isValidProjectNameLength) {
        setErrorProjectNameLength('Project name should have max 250 symbols')
      }
    }
    if (isValidFundingLength) {
      setErrorFunding(null)
    } else {
      setErrorFunding('Funding should have max 250 symbols')
    }

    return (
      isUniqueProjectName &&
      isValidProjectNameLength &&
      isValidFundingLength &&
      !isProjectNameContainsOnlySpaces
    )
  }, [mode, options, project?.id, projectName, projects])

  const isChangedValues = React.useMemo(() => {
    const isChangedProjectName = project?.name !== projectName

    const isChangedFunding = project?.funding.length !== options.length && options.length > 0

    return mode === 'edit' ? isChangedFunding : isChangedProjectName && isChangedFunding
  }, [mode, options, project?.funding, project?.name, projectName])

  const autocompleteSX = {
    mb: '50px',
    width: '100%!important',
    '& > div > div': {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      '& > input': {
        width: '100%!important',
      },
    },
  }

  const sendDataHandler = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    if (mode === 'edit') {
      const filteredOptions = options.filter((option: any) => !option.id)
      useUpdateProjectM.mutate({
        ...project,
        name: projectName,
        funding: filteredOptions,
      })

      if (!useUpdateProjectM.isError) {
        onClose()
      }
    } else {
      useCreateProjectM.mutate({
        name: projectName,
        funding: options,
      })
      if (!useCreateProjectM.isError) {
        onClose()
      }
    }
  }

  const onChangeProjectName = (e: any) => {
    setProjectName(e.target.value)
  }

  const filter = createFilterOptions<any>()

  const compareData = (a: any, b: any) => {
    const diff = a.filter((e: any) => !~b.indexOf(e))
    return diff.concat(b.filter((e: any) => !~a.indexOf(e)))
  }

  return (
    <Modal btnZIndex={500} isPaddingAbsence open onClose={onClose} noYScroll>
      <Box>
        <StyledShadowBoxTitle $isMobile={isMobile}>
          <StyledModalTitle variant='h3' width='100%'>
            {`${mode === 'edit' ? 'Edit' : 'Create'} project`}
          </StyledModalTitle>

          <StyledModalTitle variant='h6' width='100%' maxWidth='300px'>
            {mode === 'create'
              ? `Attention! You cannot change the project/funding's name or remove funding after you create the project.`
              : `Attention! Project should have max 5 funding. You cannot change the funding's name or remove funding after you press "Submit" button.`}
          </StyledModalTitle>
        </StyledShadowBoxTitle>

        <form onSubmit={sendDataHandler}>
          <StyledModalContent pt='0' $isMobile={isMobile}>
            <StyledModalScroll width='85%' position='relative' $isMobile={isMobile}>
              <Box sx={{ mb: '50px' }}>
                <TextField
                  variant='outlined'
                  type='text'
                  label='Project Name'
                  fullWidth
                  placeholder='Type here project name...'
                  onChange={onChangeProjectName}
                  value={projectName}
                  disabled={mode === 'edit'}
                />
                <FormHelperText error>{errorProjectNameLength || ''}</FormHelperText>
                <FormHelperText error>{errorProjectNameUnique || ''}</FormHelperText>
              </Box>
              <Autocomplete
                sx={autocompleteSX}
                multiple
                options={options}
                value={options}
                onChange={(event: any, newValue: any) => {
                  if (newValue.length > 0 && newValue[newValue.length - 1].inputValue) {
                    setOptions([...options, { value: newValue[newValue.length - 1].inputValue }])
                  } else if (newValue?.length > 0) {
                    const clickedOption = compareData(options, newValue)
                    if (!defaultProjectFunding.includes(clickedOption[0])) {
                      setOptions(newValue)
                    }
                  } else if (newValue.length === 0 && mode === 'create') {
                    setOptions(newValue)
                  }
                }}
                getOptionLabel={(option: any) => {
                  if (option.value) {
                    return option.value
                  } else {
                    return option
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                      checkedIcon={<CheckBoxIcon fontSize='small' />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      disabled={
                        defaultProjectFunding.filter((item: any) => item.id === option.id).length >
                        0
                      }
                    />
                    {option.value}
                  </li>
                )}
                style={{ width: 500 }}
                disabled={options.length === 5}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    return (
                      <Chip
                        sx={{ maxWidth: '200px!important' }}
                        variant='outlined'
                        color='info'
                        label={option.value}
                        {...getTagProps({ index })}
                        disabled={
                          defaultProjectFunding.filter((item: any) => item.id === option.id)
                            .length > 0
                        }
                      />
                    )
                  })
                }
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  const isExisting = options.some(option => inputValue === option.value)
                  const pattern = /^[\s]+$/
                  if (
                    inputValue !== '' &&
                    !isExisting &&
                    options.length !== 5 &&
                    !pattern.test(inputValue)
                  ) {
                    filtered.push({
                      inputValue: inputValue,
                      value: `Add "${inputValue}"`,
                    })
                  }
                  return filtered
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                renderInput={params => (
                  <TextField
                    {...params}
                    // required
                    variant='outlined'
                    label='Funding'
                    placeholder='Select funding...'
                    onKeyDown={(event: any) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                        event.stopPropagation()

                        if (
                          event.target.value &&
                          options.filter((option: any) => option.value === event.target.value)
                            .length === 0
                        ) {
                          setOptions([...options, { value: event.target.value }])
                        }
                      }
                    }}
                  />
                )}
              />
              {errorFunding && <FormHelperText error>{errorFunding || ''}</FormHelperText>}
            </StyledModalScroll>
          </StyledModalContent>

          <StyledShadowBox $isMobile={isMobile}>
            <ButtonBox sx={{ justifyContent: 'center' }} $isMobile={isMobile}>
              <Button
                type='button'
                variant='outlined'
                width='225px'
                height='48px'
                mb={isMobile ? '16px' : '0'}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                disabled={!validationForm || !isChangedValues}
                type='submit'
                variant='contained'
                width='225px'
                height='48px'
                ml={isMobile ? '0' : '28px'}
              >
                Submit
              </Button>
            </ButtonBox>
          </StyledShadowBox>
        </form>
      </Box>
    </Modal>
  )
}
