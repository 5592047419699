import { Box, Icon, MenuItem, styled } from '@mui/material'

import { Dropdown } from '@components/shared'

export const DropdownInputContainer = styled(Box)`
  position: relative;

  & .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e1e7f3;
  }
`

export const StyledDropdown = styled(Dropdown)`
  & .MuiSelect-select {
    display: flex;
  }
  & .MuiSelect-select .MuiTypography-body1 {
    display: none;
  }
`

export const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
`

export const DropdownContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  &:after {
    content: '';
    position: absolute;
    background-color: #e1e7f3;
    width: 1px;
    height: 24px;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
  }

  & .MuiInput-underline {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
`
