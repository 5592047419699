import { styled } from '@mui/material'

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  height: 100%;
`
export const HeadBox = styled('div')`
  padding: 20px 30px;
  box-shadow: -5px 0 15px rgba(53, 108, 151, 0.12);
  border-radius: 0 0 10px 10px;
`
export const Subtitle = styled('p')`
  margin: 0;
  color: #8f929b;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
`
export const ButtonBox = styled('div')`
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -5px 0 15px rgba(53, 108, 151, 0.12);
  border-radius: 10px 10px 0 0;
`
