import React from 'react'

import { stringSlice } from '@helpers/ui/common'
import { ISample } from '@models/samples'
import { Tooltip } from '@components/shared'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'

import { StyledTableCell, StyledTextCell } from '../styles'

interface DrugClassProps {
  sample: ISample
}

export const DrugClass: React.FC<DrugClassProps> = ({ sample }) => {
  const { drug_class: drugClass } = sample

  return (
    <StyledTableCell>
      <Tooltip
        isPureTooltip
        title={drugClass.name || ''}
        positionLeft={20}
        maxWidth={210}
        shouldBeHidden={drugClass.name.length < TRUNCATED_TEXT_LENGTH_S}
      >
        <StyledTextCell>{stringSlice(drugClass.name, TRUNCATED_TEXT_LENGTH_S)}</StyledTextCell>
      </Tooltip>
    </StyledTableCell>
  )
}
