import jpgIcon from '@assets/icons/jpg-icon.svg'
import docIcon from '@assets/icons/document-icon.svg'
import linkIcon from '@assets/icons/attachment-icon.svg'
import pdfIcon from '@assets/icons/pdf-icon.svg'
import pngIcon from '@assets/icons/png-icon.svg'
import xlsIcon from '@assets/icons/xls-icon.svg'
import docxIcon from '@assets/icons/docx-icon.svg'
import docmIcon from '@assets/icons/docm-icon.svg'
import dotxIcon from '@assets/icons/dotx-icon.svg'
import rtfIcon from '@assets/icons/rtf-icon.svg'
import dotmIcon from '@assets/icons/dotm-icon.svg'
import dotIcon from '@assets/icons/dot-icon.svg'
import pagesIcon from '@assets/icons/pages-icon.svg'
import numbersIcon from '@assets/icons/numbers-icon.svg'
import txtIcon from '@assets/icons/txt-icon.svg'
import xlsxIcon from '@assets/icons/xlsx-icon.svg'
import xlsmIcon from '@assets/icons/xlsm-icon.svg'
import xlsbIcon from '@assets/icons/xlsb-icon.svg'
import csvIcon from '@assets/icons/csv-icon.svg'
import xltxIcon from '@assets/icons/xltx-icon.svg'
import xltIcon from '@assets/icons/xlt-icon.svg'
import rawIcon from '@assets/icons/raw-icon.svg'
import xmlIcon from '@assets/icons/xml-icon.svg'
import jpegIcon from '@assets/icons/jpeg-icon.svg'
import jp2Icon from '@assets/icons/jp2-icon.svg'
import tiffIcon from '@assets/icons/tiff-icon.svg'
import psdIcon from '@assets/icons/psd-icon.svg'
import svgIcon from '@assets/icons/svg-icon.svg'
import bmpIcon from '@assets/icons/bmp-icon.svg'
import cmblIcon from '@assets/icons/cmbl-icon.svg'
import zeroIcon from '@assets/icons/0-icon.svg'
import { ReactComponent as unitType1 } from '@assets/icons/unitType1.svg'
import { ReactComponent as unitType2 } from '@assets/icons/unitType2.svg'
import { ReactComponent as unitType3 } from '@assets/icons/unitType3.svg'
import { ReactComponent as unitType4 } from '@assets/icons/unitType4.svg'
import { ReactComponent as unitType5 } from '@assets/icons/unitType5.svg'
import { ReactComponent as unitType6 } from '@assets/icons/unitType6.svg'
import { ReactComponent as unitType7 } from '@assets/icons/unitType7.svg'
import { ReactComponent as unitType8 } from '@assets/icons/unitType8.svg'
import { ReactComponent as unitType9 } from '@assets/icons/unitType9.svg'
import { ReactComponent as unitType10 } from '@assets/icons/unitType10.svg'
import { ReactComponent as unitType11 } from '@assets/icons/unitType11.svg'
import { ReactComponent as unitType12 } from '@assets/icons/unitType12.svg'
import { ReactComponent as unitType13 } from '@assets/icons/unitType13.svg'
import { ReactComponent as unitType14 } from '@assets/icons/unitType14.svg'
import { ReactComponent as unitType15 } from '@assets/icons/unitType15.svg'
import { ReactComponent as unitType16 } from '@assets/icons/unitType16.svg'
import { ReactComponent as unitType17 } from '@assets/icons/unitType17.svg'
import { ReactComponent as unitType18 } from '@assets/icons/unitType18.svg'
import { ReactComponent as unitType19 } from '@assets/icons/unitType19.svg'
import { ReactComponent as unitType20 } from '@assets/icons/unitType20.svg'
import { ReactComponent as unitType21 } from '@assets/icons/unitType21.svg'
import { ReactComponent as unitType22 } from '@assets/icons/unitType22.svg'

// Env vars
export const DEV_ENV = 'development'
export const PROD_ENV = 'production'

// Keys for localStorage
export const USER = 'user'
export const VERIFICATION_ERROR = 'verificationError'
export const VERIFICATION_SUCCESS = 'verificationSuccess'

// Keys for Cookies
export const CSRF_TOKEN = 'csrftoken'
export const SESSION_ID = 'sessionid'

// Route constants
export const BASE_APP_ROUTE = '/app'
export const BASE_ACCESSIBLE_ROUTE = '/account'
export const LOGIN_ROUTE = `${BASE_ACCESSIBLE_ROUTE}/login`
export const TENANTS_ROUTE = `${BASE_ACCESSIBLE_ROUTE}/tenants`
export const RECOVER_PASS_ROUTE = `${BASE_ACCESSIBLE_ROUTE}/recover_password`
export const CONFIRM_EMAIL_ROUTE = `${BASE_APP_ROUTE}/profile/email_confirmation`
export const DETAILS = '/details'

// Portal IDs
export const NOTIFICATIONS_PORTAL = 'notifications'

// Local API
export const LOCAL_API = 'http://localhost:8000/'

// Web Socket API
export const WEB_SOCKET_API = 'ws/connection'

// Ordering
export const SORT_BY_SPECIFIC_ID = 'specific_id'
export const SORT_BY_SAMPLE_NAME = 'name'
export const SORT_BY_SAMPLE_NAME_FIELD = 'sample_name'
export const SORT_BY_PHYSICAL_FORM = 'physical_form'
export const SORT_BY_WEIGHT = 'weight'
export const SORT_BY_COLLECTION_DATE = 'collection_date'
export const SORT_BY_SUBMITTER_CODE = 'submitter_code'
export const SORT_BY_MANUFACTURER = 'manufacturer'
export const SORT_BY_EXPIRATION_DATE = 'expiration_date'
export const SORT_BY_TEST_TYPE = 'test_type'
export const SORT_BY_RESULT = 'result'
export const SORT_BY_LAST_UPDATED = 'lastUpdated'
export const SORT_BY_LABORATORY = 'laboratory'
export const SORT_BY_COLLECTION_LOCATION = 'collection_location'
export const SORT_BY_TESTING_DATE = 'finished_at'

// Others
export const TRUNCATED_TEXT_LENGTH_SX = 12
export const TRUNCATED_TEXT_LENGTH_S = 14
export const TRUNCATED_TEXT_LENGTH_L = 41
export const TRUNCATED_TASK_NOTES_TEXT_LENGTH_S = 20
export const TRUNCATED_TASK_NOTES_TEXT_LENGTH_L = 42
export const TRUNCATED_NOTES_TEXT_LENGTH_L = 100
export const TRUNCATED_FILTER_OPTIONS_LENGTH = 30

export const DEBOUNCE_TIMING = 300

export const TRUNCATED_USER_NAME = 20
export const TRUNCATED_TEXT_ASSIGNEE_NAME_S = 13
export const TRUNCATED_TEXT_ASSIGNEE_NAME_L = 19
export const TRUNCATED_TEXT_ASSIGNEE_ROLE = 17

export const TRUNCATED_TEXT_APIS_LENGTH = 11
export const TRUNCATED_ATTACHED_MODAL_TEXT = 25
export const TRUNCATED_USER_NAME_IN_NOTES = 25
export const TRUNCATED_TEXT_LOCATION = 15
export const TRUNCATED_ATTACHED_MODAL_MOBILE_TEXT = 14

export const TRUNCATED_ATTACHMENTS_PACKAGE_DETAILS_TEXT = 18
export const TRUNCATED_ATTACHMENTS_TASK_DETAILS_TEXT = 10
export const TRUNCATED_ATTACHMENTS_SAMPLE_DETAILS_TEXT = 10

export const TRUNCATED_ADD_SAMPLES_MODAL_MANUFACTURE_TEXT = 15
export const TRUNCATED_SUBSTANCE_TEXT = 20
export const MAINTENANCE_MODE = 'maintenance_mode'

// units type icons
export const unitsTypeIcons = {
  '1': unitType9,
  '2': unitType1,
  '3': unitType2,
  '4': unitType3,
  '5': unitType4,
  '6': unitType5,
  '7': unitType6,
  '8': unitType7,
  '9': unitType8,
  '10': unitType10,
  '11': unitType11,
  '12': unitType12,
  '13': unitType13,
  '14': unitType14,
  '15': unitType15,
  '16': unitType16,
  '17': unitType17,
  '18': unitType18,
  '19': unitType19,
  '20': unitType20,
  '21': unitType21,
  '22': unitType22,
}

// doc icons
export const fileTypes = {
  docx: docxIcon,
  docm: docmIcon,
  dotx: dotxIcon,
  dotm: dotmIcon,
  dot: dotIcon,
  doc: docIcon,
  rtf: rtfIcon,
  pages: pagesIcon,
  numbers: numbersIcon,
  pdf: pdfIcon,
  txt: txtIcon,
  xlsx: xlsxIcon,
  xlsm: xlsmIcon,
  xlsb: xlsbIcon,
  xls: xlsIcon,
  csv: csvIcon,
  xltx: xltxIcon,
  xlt: xltIcon,
  xml: xmlIcon,
  raw: rawIcon,
  jpg: jpgIcon,
  jpeg: jpegIcon,
  jp2: jp2Icon,
  tiff: tiffIcon,
  psd: psdIcon,
  png: pngIcon,
  svg: svgIcon,
  bmp: bmpIcon,
  cmbl: cmblIcon,
  link: linkIcon,
  0: zeroIcon,
}
export const validPhotoExtensions = [
  'jpg',
  'jpeg',
  'jp2',
  'tiff',
  'png',
  'svg',
  'svg+xml', // browser svg type name
  'bmp',
]
export const validExtensions = [
  'docx',
  'docm',
  'vnd.ms-word.document.macroEnabled.12', // browser docm type name
  'dotx',
  'vnd.openxmlformats-officedocument.wordprocessingml.template', // browser dotx type name
  'dotm',
  'vnd.ms-word.template.macroEnabled.12', // browser dotm type name
  'dot',
  'msword', // browser dot type name
  'msword-template', // browser dot type name
  'doc',
  'rtf',
  'pages',
  'numbers',
  'pdf',
  'txt',
  'plain', // browser txt type name
  'docx',
  'vnd.openxmlformats-officedocument.wordprocessingml.document', // browser docx type name
  'xlsx',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet', // browser xlsx type name
  'xlsm',
  'vnd.ms-excel.sheet.macroEnabled.12', // browser xlsm type name
  'xlsb',
  'vnd.ms-excel.sheet.binary.macroEnabled.12', // browser xlsb type name
  'xls',
  'vnd.ms-excel', // browser xls type name
  'csv',
  'xltx',
  'vnd.openxmlformats-officedocument.spreadsheetml.template', // browser xltx type name
  'xlt',
  'xml',
  'raw',
  'jpg',
  'jpeg',
  'jp2',
  'tiff',
  'psd',
  'vnd.adobe.photoshop', // browser psd type name
  'png',
  'svg',
  'svg+xml', // browser svg type name
  'bmp',
  'cmbl',
  '0',
]

// workspace tabs
export const USERS = 'users'
export const CONTACT_INFO = 'contact_info'

// Temperature key formats

export const CELSIUS = 'celsius'
export const FAHRENHEIT = 'fahrenheit'
export type TempType = typeof CELSIUS | typeof FAHRENHEIT
