import { Box, BoxProps, styled, Typography } from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'

export const StyledTableCell = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  border: none;
  background-color: #f8fbff;
`

const StyledSampleSplitLinkConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'height':
      case 'isTheSameSubParent':
        return false
      default:
        return true
    }
  },
  skipSx: true,
}
interface SampleSplitLinkProps extends BoxProps {
  height: number
  isTheSameSubParent?: boolean
}

export const StyledSampleSplitBox = styled<React.FC<SampleSplitLinkProps>>(
  Box,
  StyledSampleSplitLinkConfig
)`
  position: relative;
  width: 400px;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.background.default};

  &::before {
    content: '';
    width: 3px;
    height: ${({ height }) => height}px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    position: absolute;
    left: 0;
    top: ${({ isTheSameSubParent }) => (isTheSameSubParent ? '4px' : 0)};
  }

  &:nth-of-type(1) {
    left: 0;
  }

  &:nth-of-type(1),
  &:nth-of-type(2),
  &:nth-of-type(3),
  &:nth-of-type(4) {
    margin-bottom: 6px;
  }

  &:nth-of-type(2) {
    left: 7px;
  }

  &:nth-of-type(3) {
    left: 14px;
  }

  &:nth-of-type(4) {
    left: 21px;
  }

  &:nth-of-type(5) {
    left: 28px;
    margin-bottom: 5px;
  }
`
export const StyledSampleSplitLink = styled(Typography)`
  position: static;

  display: flex;
  flex-shrink: 0;
  width: fit-content;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  display: inline-block;
  padding-left: 10px;
  background-color: ${({ theme }) => theme.palette.background.default};
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`

interface StyledSplitLineProps extends BoxProps {
  $height: number
}

export const StyledSplitLine = styled<React.FC<StyledSplitLineProps>>(Box)`
  position: relative;

  &::before {
    content: '';
    width: 3px;
    height: ${({ $height }) => $height}px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    position: absolute;
    left: 0;
    top: -4px;
  }

  &:nth-of-type(1) {
    left: 0;
  }

  &:nth-of-type(2) {
    left: 7px;
  }

  &:nth-of-type(3) {
    left: 14px;
  }

  &:nth-of-type(4) {
    left: 21px;
  }

  &:nth-of-type(5) {
    left: 28px;
  }
`
