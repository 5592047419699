import React from 'react'
import { Provider } from 'react-redux'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  ExtendedStringifyOptions,
  QueryParamProvider,
  transformSearchStringJsonSafe,
} from 'use-query-params'

import { setupInterceptorsWithStore } from '@configs/axios.config'
import { ErrorBoundary } from '@components/modules'
import { Notifications } from '@components/shared'
import { store } from '@state/store'

import { defaultTheme as theme } from './AppTheme'

setupInterceptorsWithStore(store)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked',
      refetchOnWindowFocus: false,
    },
  },
})

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
}

export const AppProviders: React.FC = ({ children }) => {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Router>
              <QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
                {children}
              </QueryParamProvider>
            </Router>
            <ThemeProvider theme={theme}>
              <Notifications />
            </ThemeProvider>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} position='bottom-left' />
        </QueryClientProvider>
      </ErrorBoundary>
    </React.StrictMode>
  )
}
