import React from 'react'
import { Box } from '@mui/material'

import { IHRSamples, ISamples } from '@models/samples'

import { TableHead } from './TableHead'
import { TableBody } from './TableBody'
import { Loader } from './Loader'

interface TableProps {
  samples: ISamples | IHRSamples
  isFetchingNextPage: boolean
  isLoading: boolean
  isVerifyTenant: boolean
}

export const Table: React.FC<TableProps> = props => {
  const { samples, isFetchingNextPage, isLoading, isVerifyTenant } = props

  const tableHeadRef = React.useRef<HTMLDivElement | null>(null)

  const renderRowsLoader = () => new Array(10).fill('').map((_, idx) => <Loader key={idx} />)

  return (
    <Box>
      {!isLoading && (
        <TableHead ref={tableHeadRef} samples={samples} isVerifyTenant={isVerifyTenant} />
      )}
      <TableBody samples={samples} isVerifyTenant={isVerifyTenant} />

      {isFetchingNextPage && renderRowsLoader()}
    </Box>
  )
}
