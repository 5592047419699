import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as Success } from '@assets/login/success.svg'
import { ReactComponent as Logo } from '@assets/login/logo.svg'
import { useUrl } from '@hooks/common/useUrl'

import { containerSX, imageSX, linkSX, formContainerSX, titleSX } from './styles'
import { LOGIN_IMAGES } from '../constants'

interface LoginTemplateProps {
  title: string
  controls: any
  additionalModals?: any
  buttons?: any
  renderedText?: any
  description?: any
  isIconShown?: boolean
}

export const LoginTemplate: React.FC<LoginTemplateProps> = ({
  title,
  controls,
  additionalModals,
  buttons,
  renderedText,
  description,
  isIconShown = false,
}) => {
  const { envType } = useUrl()

  const openWebSite = () => {
    window.open('https://artifacts.ai/', '_self')
  }

  return (
    <>
      <Stack sx={containerSX} flexDirection='row' alignItems='center'>
        <img
          alt=''
          src={envType === 'Training' ? LOGIN_IMAGES.training : LOGIN_IMAGES.default}
          style={imageSX}
        />
        <Link to='#' onClick={openWebSite} style={linkSX}>
          <Logo />
        </Link>
        <Stack sx={formContainerSX}>
          {isIconShown && (
            <Typography component='div' mb='48px'>
              <Success />
            </Typography>
          )}
          <Typography component='span' sx={titleSX}>
            {title}
          </Typography>
          {description && <Stack>{description}</Stack>}
          {controls}
          {buttons && (
            <Stack direction='row' justifyContent='space-between' alignItems='center' m='30px 0'>
              {buttons}
            </Stack>
          )}
          {renderedText && (
            <Stack sx={{ '& p:last-child': { marginBottom: '0!important' } }}>{renderedText}</Stack>
          )}
        </Stack>
      </Stack>
      {additionalModals}
    </>
  )
}
