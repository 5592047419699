import { http } from '@services/http'
import { REQUESTS_AMOUNT_LOADING_LIMIT } from '@constants/requests'
import { ILaboratoryData, IOption, ISearchSuggestionsData } from '@models/common/app'
import { INCOMING_REQUESTS } from '@constants/queries'
import {
  IRequestSamplesData,
  IRequestsData,
  IUpdatedRequestData,
  IPurchaseSamplesFormattedData,
} from '@models/requests'
import { getRequestsQueryString, getSampleQueryString } from '@helpers/api'
import { FILTER_COUNT } from '@components/shared/FilterAside/constants'

export const fetchRequestSamples = (queryKey: any) => {
  const { queryKey: queryKeyNested, pageParam: page = 1 } = queryKey
  const [, samples, apis, excipients, drug_classes, manufacturers, expirationDate, laboratories] =
    queryKeyNested

  const searchQueries = {
    page,
    limit: REQUESTS_AMOUNT_LOADING_LIMIT,
    samples,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    started_expiration_date: expirationDate && expirationDate.slice(1, 11),
    ended_expiration_date: expirationDate && expirationDate.slice(13),
    laboratories,
  }

  //Add new searchQueries inside getRequestsQueryString props
  const queryString = getSampleQueryString(searchQueries)

  try {
    return http.get<IRequestSamplesData>(`/samples/available-samples-for-request/?${queryString}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const purchaseSamples = async (data: IPurchaseSamplesFormattedData[]) => {
  try {
    return http.post('/requests/purchase/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}
export const requestSamples = async (data: { samples: number[] }) => {
  try {
    return http.post('/requests/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchPurchaseSenderLaboratories = ({ pageParam = 1, ...payload }) => {
  const desiredFilter = payload.queryKey[1]
  const url = `?limit=${FILTER_COUNT}&page=${pageParam}`

  try {
    return http.get<ILaboratoryData>(
      `/laboratories/${url}&sender_of_purchase_requests=true/${
        desiredFilter ? '&search=' + desiredFilter : ''
      }`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

// * [Requests options

export const fetchUnitType = () => {
  try {
    return http.get<IOption[]>('/unit_types/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchRequests = (queryKey: any) => {
  const { queryKey: queryKeyNested, pageParam: page = 1 } = queryKey
  const [
    requestType,
    requestSwitcherType,
    desired_request,
    ordering,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    status,
    expirationDate,
    laboratories,
  ] = queryKeyNested

  const searchQueries = {
    page,
    limit: REQUESTS_AMOUNT_LOADING_LIMIT,
    ordering,
    samples: desired_request,
    request_type: requestSwitcherType,
    view_type: requestType === INCOMING_REQUESTS ? 'incoming' : 'outgoing',
    apis,
    excipients,
    drug_classes,
    manufacturers,
    status,
    started_expiration_date: expirationDate && expirationDate.slice(1, 11),
    ended_expiration_date: expirationDate && expirationDate.slice(13),
    laboratories,
  }

  //Add new searchQueries inside getRequestsQueryString props
  const queryString = getRequestsQueryString(searchQueries)

  try {
    return http.get<IRequestsData>(`/requests/?${queryString}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateIncomingRequestStatus = async (data: IUpdatedRequestData) => {
  try {
    return http.patch('/requests/status/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const cancelOutgoingRequest = (requestId: string) => {
  try {
    return http.delete(`/requests/${requestId}/`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const searchSuggestionsRequests = ({ queryKey, pageParam = 1 }: any) => {
  try {
    return http.get<ISearchSuggestionsData>(
      `/requests/sample_specific_id_suggestions/?suggestion_start=${queryKey[0]}${
        pageParam ? '&page=' + pageParam : ''
      }${queryKey[1][0] && '&view_type=' + queryKey[1][0]}${
        queryKey[1][1] && '&request_type=' + queryKey[1][1]
      }`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const searchSuggestionsRequestsAllSamples = ({ queryKey, pageParam }: any) => {
  const [suggestionSampleSearching] = queryKey
  const page = pageParam ? '&page=' + pageParam : ''

  try {
    return http.get<ISearchSuggestionsData>(
      `/samples/available_samples_for_request_specific_id_suggestions/?suggestion_start=${suggestionSampleSearching}${page}`
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
