import { Typography, useMediaQuery } from '@mui/material'

import { StyledDetailsLabel, StyledParagraph } from '@styles'
import { TRUNCATED_NOTES_TEXT_LENGTH_L } from '@constants/common'
import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'

import { TooltipMobile } from '../Tooltip/components'

interface StorageNotesProps {
  storageNotes?: string | null
}

export const StorageNotes: React.FC<StorageNotesProps> = props => {
  const { storageNotes } = props
  const isMobile = useMediaQuery('(max-width:767px)')

  return (
    <>
      {storageNotes && (
        <>
          {isMobile ? (
            <>
              <TooltipMobile
                title={storageNotes}
                shouldBeHidden={storageNotes.length < TRUNCATED_NOTES_TEXT_LENGTH_L}
              >
                <Typography variant='body1'>
                  {stringSlice(storageNotes, TRUNCATED_NOTES_TEXT_LENGTH_L)}
                </Typography>
              </TooltipMobile>
            </>
          ) : (
            <>
              <StyledDetailsLabel variant='h5'>Storage notes</StyledDetailsLabel>
              <Tooltip
                isPureTooltip
                title={storageNotes}
                positionLeft={20}
                maxWidth={210}
                shouldBeHidden={storageNotes.length < TRUNCATED_NOTES_TEXT_LENGTH_L}
              >
                <StyledParagraph variant='body1'>
                  {stringSlice(storageNotes, TRUNCATED_NOTES_TEXT_LENGTH_L)}
                </StyledParagraph>
              </Tooltip>
            </>
          )}
        </>
      )}
    </>
  )
}
