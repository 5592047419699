import React from 'react'
import { Box, Typography } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { selectUser } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { datePatterns } from '@constants/dates'
import { StyledTableSampleCell, SubText } from '@styles'
import { getFormattedDate } from '@helpers/ui/date'

interface NotesProps {
  task: ITaskLab
  isMobile?: boolean
}

export const TestingDate: React.FC<NotesProps> = ({ task, isMobile }) => {
  const { timezone } = useAppSelector(selectUser)
  const { started_at: startedAt, finished_at: finishedAt } = task

  return (
    <StyledTableSampleCell sx={{ display: 'flex' }} $isMobile={isMobile}>
      <Box>
        <Box mb={'12px'}>
          <SubText>Started</SubText>
          <Typography variant='body1'>
            {getFormattedDate(datePatterns.timeDayMonth, startedAt, timezone) || '-'}
          </Typography>
        </Box>

        <Box>
          <SubText>Finished</SubText>
          <Typography variant='body1'>
            {getFormattedDate(datePatterns.timeDayMonth, finishedAt, timezone) || '-'}
          </Typography>
        </Box>
      </Box>
    </StyledTableSampleCell>
  )
}
