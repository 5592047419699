import { styled, TableHead, TableHeadProps } from '@mui/material'

import { ContentType } from '@models/common/ui'

interface StyledTableHeadProps extends TableHeadProps {
  $contentType?: ContentType
}

export const StyledTableHead = styled<React.FC<StyledTableHeadProps>>(TableHead)`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;

  & .MuiTableCell-head {
    &:first-child {
      padding: ${({ $contentType }) =>
        $contentType === 'samples' ? '15px 0 0 119px' : '15px 0 0 47px'};
    }

    border: none;
  }
`
