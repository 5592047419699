import React from 'react'

import { StyledTableHead, StyledTableHeadWrapper, StyledTableRow } from '@styles'

import { TableHeadTitles } from './TableHeadTitles'

interface CheckedTableProps {
  hideBorder: boolean
  shouldDisplayActionBtn?: boolean
}

export const TableHead = React.forwardRef<HTMLDivElement, CheckedTableProps>((props, ref) => {
  const { hideBorder, shouldDisplayActionBtn } = props

  return (
    <StyledTableHeadWrapper ref={ref} hideBorder={hideBorder} top='155px'>
      <StyledTableHead>
        <StyledTableRow shouldDisplayActionBtn={shouldDisplayActionBtn}>
          <TableHeadTitles hideBorder={!hideBorder} />
        </StyledTableRow>
      </StyledTableHead>
    </StyledTableHeadWrapper>
  )
})
