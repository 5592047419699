import React from 'react'

import { ISample } from '@models/samples'
import { StyledTableSampleCell } from '@styles'
import { NotesField } from '@components/shared/sampleFields'
import { SAMPLES_IN_PACKAGE } from '@constants/queries'

interface NotesProps {
  sample: ISample
  isMobile?: boolean
}

export const Notes: React.FC<NotesProps> = ({ sample, isMobile }) => {
  const { notes } = sample

  return (
    <StyledTableSampleCell $isMobile={isMobile} mr={isMobile ? '0' : '48px'}>
      <NotesField
        notes={notes}
        id={sample.id}
        content={SAMPLES_IN_PACKAGE}
        btnText='sample'
        labelText='Sample'
      />
    </StyledTableSampleCell>
  )
}
