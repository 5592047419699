import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'

import { StyledTitle } from '@styles'
import { PhotoWithAttachments as SharedPhotoWithAttachments } from '@components/shared/Hr'
import { FullPageError } from '@components/shared'
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { RESULTS_STEP_TITLE } from '@constants/tests'

import * as Styled from '../styles'
import { Results, TextField } from './ScreeningCells'
import { Loader } from '../Loader'
import { HrTaskInfoPropTypes } from '../../hrTaskInfo.types'
import { ScreeningEditResults } from './ScreeningEditResults'

export const HrScreeningResults: React.FC<HrTaskInfoPropTypes> = ({ isLoading, task }) => {
  const theme = useTheme()
  const [isOpenEditModal, setIsOpenEditModal] = React.useState<boolean>(false)

  if (isLoading) return <Loader />

  const resultCells = [
    {
      title: 'Photo of test strip',
      component: SharedPhotoWithAttachments,
      attachments: task?.attachments,
      isTextField: false,
      isGoodQualityOfPhoto: true,
    },
    { title: 'Test notes', component: TextField, data: task?.test_notes, isTextField: true },
    {
      title: 'Preparation for test',
      component: TextField,
      data: task?.preparation,
      isTextField: true,
    },
  ]

  const stripCells = [
    {
      title: 'Brand',
      component: TextField,
      data: task?.test_strip_brand,
    },
    { title: 'Batch/lot number', component: TextField, data: task?.test_strip_batch },
  ]

  const cellThumbText = (isTextField: boolean) => {
    let text
    if (!isTextField) text = 'Attachments were not provided'
    else text = 'Information has not been provided'
    return (
      <Typography variant='body1' color='primary.light'>
        {text}
      </Typography>
    )
  }

  const isResultsEmpty = !task?.result_name?.length

  const checkCell = (title: string): boolean => {
    return title === 'Photo of test strip'
  }

  return (
    <Box width={isResultsEmpty ? '30%' : '50%'}>
      <StyledTitle mb='32px' variant='h3' fz={24}>
        {RESULTS_STEP_TITLE}
        {task?.is_able_to_edit_task && (
          <Styled.ImageButton
            onClick={() => {
              setIsOpenEditModal(true)
            }}
          >
            <EditIcon fill={theme.palette.primary.main} />
          </Styled.ImageButton>
        )}
      </StyledTitle>

      {isResultsEmpty ? (
        <FullPageError
          imgVariant='common'
          errorTextTitle='There are no test results yet'
          pt='70px'
          svgWidth='110px'
          svgHeight='110px'
        />
      ) : (
        <>
          {/*  Test result */}
          <Typography variant='subtitle1' mb='24px'>
            Test result
          </Typography>

          <Results task={task} />

          {resultCells.map(
            (
              { title, component: Cell, data, attachments, isTextField, isGoodQualityOfPhoto },
              idx
            ) => (
              <Styled.DetailsBox key={idx}>
                {/* titles */}
                <Typography variant='h5' width='216px' mb='18px'>
                  {title}
                </Typography>

                {/* cells with data */}
                <Box mb='18px' width='90%'>
                  {!!data?.length || !!attachments?.length ? (
                    <Cell
                      data={data}
                      //SharedPhotoWithAttachments
                      attachments={attachments}
                      isGoodQualityOfPhoto={isGoodQualityOfPhoto}
                      height={checkCell(title) ? '103px' : ''}
                      width={checkCell(title) ? '103px' : ''}
                      mr={checkCell(title) ? '8px' : ''}
                    />
                  ) : (
                    cellThumbText(isTextField as boolean)
                  )}
                </Box>
              </Styled.DetailsBox>
            )
          )}

          {/* Test strip details */}
          <Typography variant='subtitle1' mb='24px'>
            Test strip details
          </Typography>

          {stripCells.map(({ title, component: Cell, data }, idx) => (
            <Styled.DetailsBox key={idx}>
              {/* titles */}
              <Typography variant='h5' width='216px' mb='18px'>
                {title}
              </Typography>

              {/* cells with data */}
              <Box mb='18px' width='90%'>
                {!!data?.length ? <Cell data={data} /> : cellThumbText(true)}
              </Box>
            </Styled.DetailsBox>
          ))}

          {/* EDIT RESULTS */}
          {isOpenEditModal && (
            <ScreeningEditResults task={task} onClose={() => setIsOpenEditModal(false)} />
          )}
        </>
      )}
    </Box>
  )
}
