import { styled, Box, Typography, Icon } from '@mui/material'

import { Checkbox } from '@components/shared'

export const StyleCard = styled<React.FC<{ isDragging?: boolean }>>(Box)`
  width: 434px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.additional.stroke.main};
  border-radius: 4px;
  background: #ffffff;
  display: flex;
  align-items: center;
  box-shadow: ${({ isDragging }) => (isDragging ? '3px 4px 10px rgba(18, 39, 112, 0.15)' : '')};
`

export const StyleTypography = styled(Typography)`
  flex: 1 1 auto;
  padding-left: 4px;
`

export const StyleIcon = styled(Icon)`
  display: flex;
  padding-right: 6px;
  height: 18px;
`

export const StyleCheckbox = styled(Checkbox)`
  &.Mui-checked&.Mui-disabled {
    span {
      background-color: ${({ theme }) => theme.additional.stroke.main};
    }
  }
`
