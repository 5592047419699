import ContentLoader from 'react-content-loader'

export const Loader: React.FC = props => {
  return (
    <div>
      <ContentLoader
        speed={2}
        width={305}
        height={355}
        viewBox='0 0 305 355'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}
      >
        <rect x='0' y='0' rx='4' ry='4' width='305' height='28' />
        <rect x='0' y='58' rx='4' ry='4' width='203' height='20' />
        <rect x='0' y='95' rx='4' ry='4' width='116' height='20' />
        <rect x='0' y='175' rx='4' ry='4' width='82' height='20' />
        <rect x='0' y='255' rx='4' ry='4' width='82' height='20' />
        <rect x='153' y='255' rx='4' ry='4' width='82' height='20' />
        <rect x='0' y='140' rx='4' ry='4' width='139' height='20' />
        <rect x='0' y='220' rx='4' ry='4' width='120' height='20' />
        <rect x='0' y='300' rx='4' ry='4' width='120' height='20' />
        <rect x='153' y='220' rx='4' ry='4' width='120' height='20' />
        <rect x='0' y='335' rx='4' ry='4' width='82' height='20' />
      </ContentLoader>
    </div>
  )
}
