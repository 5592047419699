import React from 'react'
import { useHistory } from 'react-router-dom'

import { Modal } from '@components/shared'
import { publicRoutes } from '@configs/routes.config'
import { StyledBox, Image } from '@styles'
import mail from '@assets/images/mail.svg'

import { StyledParagraph, StyledTitle } from './styles'

export const SentPassword: React.FC<{ errorSendingClose?: () => void }> = ({
  errorSendingClose,
}) => {
  const history = useHistory()

  const onClose = () => {
    if (!!errorSendingClose) errorSendingClose()
    else history.push(publicRoutes.login.path)
  }

  return (
    <Modal open onClose={onClose}>
      <StyledBox>
        <Image src={mail} width='80px' height='80px' component='img' />
        <StyledTitle variant='h3'>Password recovery</StyledTitle>
        <StyledParagraph sx={{ marginBottom: 3 }}>
          If an account exists with the email provided, you will
          <br />
          receive an email with password reset instructions.
        </StyledParagraph>
      </StyledBox>
    </Modal>
  )
}
