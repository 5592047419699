import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IAttachmentResultData, IAttachments } from '@models/attachments'

export interface TasksState {
  completedTest: number | null
  isEditAttachments: boolean
  addedAttachments: IAttachmentResultData | null
  newAttachmentInstead: string
  newAttachments4Notification: IAttachments
  isOpenAttachmentsNotification: boolean
}

const initialState: TasksState = {
  completedTest: null,
  isEditAttachments: false,
  addedAttachments: null,
  newAttachmentInstead: '',
  newAttachments4Notification: [],
  isOpenAttachmentsNotification: false,
}

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    completeTest: (state, { payload }: PayloadAction<number | null>) => {
      state.completedTest = payload
    },
    setIsEditAttachments: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditAttachments = payload
    },
    setAddedAttachments: (state, { payload }: PayloadAction<IAttachmentResultData | null>) => {
      state.addedAttachments = payload
    },
    setNewAttachmentInstead: (state, { payload }: PayloadAction<string>) => {
      state.newAttachmentInstead = payload
    },
    setNewAttachments4Notification: (state, { payload }: PayloadAction<IAttachments>) => {
      state.newAttachments4Notification = payload
    },
    setIsOpenAttachmentsNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenAttachmentsNotification = payload
    },
  },
})

export const {
  reducer: tasksReducer,
  actions: {
    completeTest,
    setIsEditAttachments,
    setAddedAttachments,
    setNewAttachmentInstead,
    setNewAttachments4Notification,
    setIsOpenAttachmentsNotification,
  },
} = tasksSlice
