import React from 'react'
import { Box, styled } from '@mui/material'

export const Wrap = styled<React.FC<{ $direction: 'column' | 'row' }>>(Box)`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
`

export const Label = styled('label')<{ $direction: 'column' | 'row'; $disabled: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  margin-right: ${({ $direction }) => ($direction === 'row' ? '18px' : 0)};
  margin-bottom: ${({ $direction }) => ($direction === 'column' ? '18px' : 0)};
  filter: ${({ $disabled }) => `grayscale(${$disabled ? '100%' : '0%'})`};

  &:last-child {
    margin-bottom: 0 !important;
  }

  & input {
    display: none;
  }

  & span[data-type='cup'] {
    position: relative;
    display: inline-block;
    margin-right: 9px;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border: 1px solid #adb4c2;
    border-radius: 50%;
    box-sizing: border-box;
    &:after {
      display: none;
      position: absolute;
      top: 4px;
      left: 4px;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & span[data-type='text'] {
    color: #616d84;
  }

  & input:checked {
    & ~ span[data-type='cup'] {
      border-color: ${({ theme }) => theme.palette.primary.main};
      &:after {
        display: block;
      }
    }
    & ~ span[data-type='text'] {
      color: ${({ theme, $disabled }) => ($disabled ? theme.palette.secondary.light : 'black')};
    }
  }
`
