import { styled as styledMUI, FormControlLabel, Typography, Box } from '@mui/material'
import styled from 'styled-components'

type StyledLabelPropTypes = {
  $isRequired?: boolean
  $isBold?: boolean
  $isResize?: boolean
}

export const StyledLabel = styledMUI(FormControlLabel)<StyledLabelPropTypes>`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: 0;
  margin-top: ${({ theme }) => theme.typography.pxToRem(43)};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: ${({ theme }) => theme.palette.text.primary};

  @media (max-width: 767px) {
    width: 100%;
  }

  .MuiFormControl-root {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  & .MuiFormControlLabel-label {
    margin-bottom: 2px;
    font-weight: ${({ $isBold }) => ($isBold ? 700 : 400)};
    &::after {
      display: ${({ $isRequired }) => ($isRequired ? 'inline-block' : 'none')};
      margin-left: 4px;
      content: '(Required)';
      color: ${({ theme }) => theme.palette.secondary.light};
    }
  }
  & .MuiFormHelperText-root {
    margin-top: 4px;
    margin-left: 0;
    margin-right: 0;
  }

  .MuiInputBase-input {
    resize: ${({ $isResize }) => ($isResize ? 'auto' : 'none')};
    @media (max-width: 767px) {
      width: 100%;
    }
  }
`
export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;

  @media (max-width: 767px) {
    max-width: calc(100% - 40px);
    width: 100%;
  }

  .MuiButton-root {
    width: 205px;
    height: 44px;

    @media (max-width: 767px) {
      width: 100%;
      margin-top: 20px;
    }
  }
`

export const ForgotPassword = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: right;
`
interface StyledInfoBoxProps {
  $isMobile: boolean
  $isMobileHeight: boolean
}

export const StyledInfoBox = styled<React.FC<StyledInfoBoxProps>>(Box)`
  position: ${({ $isMobile, $isMobileHeight }) => ($isMobile || $isMobileHeight ? '' : 'absolute')};
  top: -140px;
  width: ${({ $isMobile }) => ($isMobile ? '' : '390px')};
  margin: ${({ $isMobile }) => ($isMobile ? '20px 18px 0 18px' : '0px')};
  padding: 24px;
  background: #ffffff;
  border: 1px solid #e5e9f1;
  box-shadow: -5px 0px 15px rgba(53, 108, 151, 0.12);
  border-radius: 10px;
  z-index: 100;
`

export const StyledInfoText = styled(Typography)`
  margin-bottom: 4px;
  font-weight: 400;
  line-height: 24px;
  color: #616d84;
`

export const StyledParagraphLink = styled('a')`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: 8px;
  cursor: pointer;
`
