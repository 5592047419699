import ContentLoader from 'react-content-loader'

export const MainInfoLoader: React.FC = props => {
  return (
    <div>
      <ContentLoader
        speed={1.8}
        width='100%'
        viewBox='0 0 340 763'
        backgroundColor='#f3f3f3'
        foregroundColor='#e3e3e3'
        {...props}
      >
        <rect x='0' y='0' rx='4' ry='4' width='305' height='28' />
        <rect x='0' y='493' rx='4' ry='4' width='305' height='28' />
        <rect x='0' y='60' rx='4' ry='4' width='246' height='20' />
        <rect x='0' y='95' rx='4' ry='4' width='144' height='20' />
        <rect x='0' y='140' rx='4' ry='4' width='224' height='20' />
        <rect x='0' y='175' rx='4' ry='4' width='98' height='20' />
        <rect x='0' y='400' rx='4' ry='4' width='112' height='20' />
        <rect x='0' y='435' rx='4' ry='4' width='87' height='20' />
        <rect x='0' y='553' rx='4' ry='4' width='87' height='20' />
        <rect x='0' y='588' rx='4' ry='4' width='54' height='20' />
        <rect x='0' y='632' rx='4' ry='4' width='112' height='20' />
        <rect x='0' y='669' rx='4' ry='4' width='214' height='16' />
        <rect x='0' y='695' rx='4' ry='4' width='305' height='16' />
        <rect x='0' y='721' rx='4' ry='4' width='261' height='16' />
        <rect x='0' y='747' rx='4' ry='4' width='340' height='16' />
        <rect x='0' y='220' rx='4' ry='4' width='124' height='20' />
        <rect x='0' y='255' rx='14' ry='14' width='87' height='28' />
        <rect x='97' y='255' rx='14' ry='14' width='96' height='28' />
        <rect x='0' y='311' rx='4' ry='4' width='124' height='20' />
        <rect x='0' y='346' rx='14' ry='14' width='102' height='28' />
        <rect x='112' y='346' rx='14' ry='14' width='102' height='28' />
      </ContentLoader>
    </div>
  )
}
