import React from 'react'

import {
  PHYSICAL_FORMS_OPTIONS,
  COLLECTION_LOCATION_OPTIONS,
  DOSAGE_TYPES_OPTIONS,
} from '@constants/hrQueries'
import { fetchCollectionLocation, fetchDosageTypes, fetchPhysicalForms } from '@api/hrSamples'
import { IOption } from '@models/common/app'
import { useQueriesTyped } from '@hooks/queries'
import { AddressesTypes } from '@components/shared/sampleFields/GMPlaceAutocomplete'

export interface IHrSampleOptionsContext {
  physicalForms: IOption[]
  dosageTypes: IOption[]
  collectionLocation: AddressesTypes[]
  isOptionsLoading: boolean
}

export const HrSampleOptionsContext = React.createContext<IHrSampleOptionsContext>({
  physicalForms: [],
  dosageTypes: [],
  collectionLocation: [],
  isOptionsLoading: true,
})

export const HrSampleOptionsProvider: React.FC = ({ children }) => {
  const fetchSampleOptionsQ = useQueriesTyped([
    { queryKey: [PHYSICAL_FORMS_OPTIONS], queryFn: fetchPhysicalForms },
    { queryKey: [DOSAGE_TYPES_OPTIONS], queryFn: fetchDosageTypes },
    { queryKey: [COLLECTION_LOCATION_OPTIONS], queryFn: fetchCollectionLocation },
  ])

  const [physicalForms, dosageTypes, collectionLocations] = fetchSampleOptionsQ

  const isOptionsLoading = fetchSampleOptionsQ.some(query => query.isLoading)

  const options = {
    physicalForms: physicalForms.data || [],
    dosageTypes: dosageTypes.data || [],
    collectionLocation: collectionLocations.data || [],
    isOptionsLoading,
  }

  return (
    <HrSampleOptionsContext.Provider value={options}>{children}</HrSampleOptionsContext.Provider>
  )
}
