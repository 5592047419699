import { styled } from '@mui/material'

import { Link } from '@styles'

export const EditAttachments = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`
