import React from 'react'
import { Alert, useMediaQuery } from '@mui/material'

import { selectUser } from '@state/user'
import { useToggle } from '@hooks/common'
import { INotification } from '@models/common/app'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { selectNotifications, hideNotification } from '@state/app'
import iconError from '@assets/icons/notification-error.svg'
import iconSuccess from '@assets/icons/notification-success.svg'
import copiedIcon from '@assets/icons/copied-icon.svg'

import {
  StyledNotification,
  StyledIconButton,
  IconClose,
  NotificationIcon,
  TitleBox,
  StyledTitle,
  StyledText,
  StyledButton,
} from './styles'

export interface NotificationProps extends INotification {
  isAuthorized: boolean
}

const Notification: React.FC<NotificationProps> = React.memo(props => {
  const { type, title, message, buttonText, onBtnClick, keepMounted, isCopiedLink, isAuthorized } =
    props
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery('(max-width:767px)')

  const [isNotificationOpen, toggleNotification] = useToggle(true)
  const notificationIcon = type === 'success' ? iconSuccess : iconError

  const actionHandler = () => {
    if (onBtnClick) onBtnClick()
    if (!keepMounted) toggleNotification()
  }

  React.useEffect(() => {
    const notificationTimer = setTimeout(() => {
      if (isNotificationOpen) toggleNotification()
    }, 5000)
    const storedNotificationTimer = setTimeout(() => dispatch(hideNotification()), 5500)

    return () => {
      clearTimeout(notificationTimer)
      clearTimeout(storedNotificationTimer)
    }
  }, [isNotificationOpen, toggleNotification, dispatch])

  const isPositionBottom = isMobile || isCopiedLink

  return (
    <StyledNotification
      in={isNotificationOpen}
      isAuthorized={isAuthorized}
      $isMobile={isPositionBottom}
    >
      <Alert
        severity={type}
        action={
          !isPositionBottom && (
            <StyledIconButton
              aria-label='close'
              color='inherit'
              size='small'
              sx={{ opacity: isNotificationOpen ? '1' : '0' }}
              statusType={type}
              onClick={toggleNotification}
            >
              <IconClose />
            </StyledIconButton>
          )
        }
      >
        <TitleBox>
          <NotificationIcon src={isCopiedLink ? copiedIcon : notificationIcon} alt='status-icon' />
          <StyledTitle>{title}</StyledTitle>
        </TitleBox>

        <StyledText sx={{ marginBottom: isMobile ? '0' : '16px' }}>{message || ''}</StyledText>

        {!!buttonText && !isPositionBottom && (
          <StyledButton onClick={actionHandler}>{buttonText}</StyledButton>
        )}
      </Alert>
    </StyledNotification>
  )
})

export const NotificationsStack = () => {
  const notifications = useAppSelector(selectNotifications)
  const { isAuthorized } = useAppSelector(selectUser)

  return (
    <>
      {notifications.map((notification, idx) => {
        return (
          <Notification
            key={notification.id ? notification.id : idx}
            {...notification}
            isAuthorized={isAuthorized}
          />
        )
      })}
    </>
  )
}
