import React from 'react'
import { CircularProgress } from '@mui/material'

import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg'
import { Tooltip } from '@components/shared'
import { useFetchAttachments } from '@hooks/queries'
import { IAttachments } from '@models/attachments'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import {
  selectTasks,
  setIsOpenAttachmentsNotification,
  setNewAttachments4Notification,
} from '@state/tasks'
import { ReactComponent as CheckIcon } from '@assets/icons/check-outlined.svg'
import { fileTypeConverter } from '@helpers/ui/attachments'
import { findLostLoadingAttachment } from '@helpers/findLostLoadingAttachment'

import {
  WrapStyled,
  CloseBtnStyled,
  AttachmentItemStyled,
  HeaderLabelStyled,
  AttachmentIconStyled,
  FileNameStyled,
  ContainerStyled,
} from './styles'
import { AttachmentsNotificationProps } from './AttachmentsNotification.types'

export const AttachmentsNotification: React.FC<AttachmentsNotificationProps> = ({ task }) => {
  const [attachments, setAttachments] = React.useState<IAttachments>([])
  const dispatch = useAppDispatch()
  const { newAttachments4Notification, isOpenAttachmentsNotification } = useAppSelector(selectTasks)
  const fetchAttachmentsQ = useFetchAttachments({
    content_type: 'task',
    object_id: task.id,
  })

  React.useEffect(() => {
    return () => {
      dispatch(setIsOpenAttachmentsNotification(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (newAttachments4Notification.length) {
      setAttachments(prevState => [...prevState, ...newAttachments4Notification])
      dispatch(setNewAttachments4Notification([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAttachments4Notification])

  React.useEffect(() => {
    if (fetchAttachmentsQ.data) {
      setAttachments(findLostLoadingAttachment(fetchAttachmentsQ.data, attachments))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAttachmentsQ.data])

  const loadingAttachmentsLength = attachments.map(i => i.loading).filter(i => i === true).length
  const attachmentsLength = attachments.length - loadingAttachmentsLength
  const wordEndingLoading = loadingAttachmentsLength === 1 ? '' : 's'
  const wordEnding = attachmentsLength === 1 ? '' : 's'

  return isOpenAttachmentsNotification ? (
    <WrapStyled>
      <HeaderLabelStyled>
        {!!loadingAttachmentsLength
          ? `Uploading ${loadingAttachmentsLength} file${wordEndingLoading}...`
          : `${attachmentsLength} upload${wordEnding} complete`}
      </HeaderLabelStyled>
      <CloseBtnStyled onClick={() => dispatch(setIsOpenAttachmentsNotification(false))}>
        <CloseIcon />
      </CloseBtnStyled>
      <ContainerStyled>
        {attachments.map(attachment => {
          const { iconType, fileType } = fileTypeConverter(attachment.name)
          const linkProps = (() => {
            if (attachment.link) return { $isLink: true, href: attachment.link }
            return {}
          })()

          return fileType !== 'link' ? (
            <AttachmentItemStyled key={attachment.id}>
              <AttachmentIconStyled>
                <img src={iconType} alt='document icon' />
              </AttachmentIconStyled>

              <FileNameStyled {...linkProps}>
                <Tooltip
                  title={
                    !!attachment.link
                      ? 'Click to download file'
                      : "We're processing this file. Please try to download it later."
                  }
                >
                  <span>{attachment.name}</span>
                </Tooltip>
              </FileNameStyled>

              {attachment.loading ? (
                <CircularProgress color='success' size={18} style={{ marginLeft: 'auto' }} />
              ) : (
                <CheckIcon style={{ minWidth: 18, marginLeft: 'auto' }} />
              )}
            </AttachmentItemStyled>
          ) : null
        })}
      </ContainerStyled>
    </WrapStyled>
  ) : null
}
