import React from 'react'
import { useHistory } from 'react-router-dom'

import { showNotification } from '@helpers/app'
import { publicRoutes } from '@configs/routes.config'
import { NotificationModal } from '@components/shared'
import { selectAppFlags, toggleFlag } from '@state/app'
import { notifications } from '@constants/notifications'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { LOGIN_ROUTE, VERIFICATION_ERROR, VERIFICATION_SUCCESS } from '@constants/common'
import { SentPassword } from '@components/modules'

export const NotificationModals = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const {
    isChangePasswordFailed,
    isPasswordRecoveryTokenInvalid,
    isChangePasswordSuccessfully,
    isPasswordRecoveryEmailNotSent,
    isLoginLockout,
    isAccountVerificationErrorModalOpen,
    isPasswordChangedModalOpen,
    isPasswordErrorModalOpen,
    isSendingErrorModalOpen,
    isLinkExpiredModalOpen,
  } = useAppSelector(selectAppFlags)

  const redirectToRecoverPass = () => {
    if (isPasswordErrorModalOpen) dispatch(toggleFlag('isPasswordErrorModalOpen'))
    else if (isLinkExpiredModalOpen) dispatch(toggleFlag('isLinkExpiredModalOpen'))

    history.push(publicRoutes.forgotPassword.path)
  }

  React.useEffect(() => {
    if (localStorage.getItem(VERIFICATION_ERROR)) {
      localStorage.removeItem(VERIFICATION_ERROR)

      dispatch(toggleFlag('isAccountVerificationErrorModalOpen'))
    } else if (localStorage.getItem(VERIFICATION_SUCCESS)) {
      localStorage.removeItem(VERIFICATION_SUCCESS)

      showNotification(notifications.emailConfirmation)
    } else if (isChangePasswordFailed) {
      dispatch(toggleFlag('isPasswordErrorModalOpen'))
      dispatch(toggleFlag('isChangePasswordFailed'))
    } else if (isChangePasswordSuccessfully) {
      dispatch(toggleFlag('isPasswordChangedModalOpen'))
      dispatch(toggleFlag('isChangePasswordSuccessfully'))
    } else if (isPasswordRecoveryTokenInvalid) {
      dispatch(toggleFlag('isLinkExpiredModalOpen'))
      dispatch(toggleFlag('isPasswordRecoveryTokenInvalid'))
    } else if (isPasswordRecoveryEmailNotSent) {
      dispatch(toggleFlag('isSendingErrorModalOpen'))
      dispatch(toggleFlag('isPasswordRecoveryEmailNotSent'))
    }
  }, [
    dispatch,
    isChangePasswordFailed,
    isChangePasswordSuccessfully,
    isPasswordRecoveryEmailNotSent,
    isPasswordRecoveryTokenInvalid,
  ])

  if (isAccountVerificationErrorModalOpen) {
    return (
      <NotificationModal
        variant='error'
        title='Account has not been verified'
        onClose={() => dispatch(toggleFlag('isAccountVerificationErrorModalOpen'))}
        description='The account confirmation link has expired'
      />
    )
  } else if (isPasswordErrorModalOpen) {
    return (
      <NotificationModal
        variant='error'
        title='Password has not been changed'
        description={
          <>
            Something went wrong... <br />
            We were unable to reset password for you
          </>
        }
        btnText='Okay'
        onBtnClick={redirectToRecoverPass}
        onClose={() => dispatch(toggleFlag('isPasswordErrorModalOpen'))}
      />
    )
  } else if (isLinkExpiredModalOpen) {
    return (
      <NotificationModal
        variant='error'
        title='The link has expired'
        description='The link is no longer valid, if necessary, repeat the request'
        btnText='Try again'
        onBtnClick={redirectToRecoverPass}
        onClose={() => dispatch(toggleFlag('isLinkExpiredModalOpen'))}
      />
    )
  } else if (isPasswordChangedModalOpen) {
    return (
      <NotificationModal
        variant='success'
        title='Password is changed successfully'
        btnText='Go to login'
        onBtnClick={() => {
          window.open(LOGIN_ROUTE, '_self')
          dispatch(toggleFlag('isPasswordChangedModalOpen'))
        }}
        onClose={() => dispatch(toggleFlag('isPasswordChangedModalOpen'))}
      />
    )
  } else if (isLoginLockout) {
    return (
      <NotificationModal
        variant='error'
        title='To protect your account, it has been temporarily locked'
        description='Please try again in 5 minutes'
        onClose={() => dispatch(toggleFlag('isLoginLockout'))}
      />
    )
  } else if (isSendingErrorModalOpen) {
    return (
      <SentPassword errorSendingClose={() => dispatch(toggleFlag('isSendingErrorModalOpen'))} />
    )
  } else return null
}
