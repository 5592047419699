import React from 'react'

import { ITaskLab } from '@models/tasks'
import { StyledTableSampleCell } from '@styles'

interface NotesProps {
  task: ITaskLab
  isMobile?: boolean
}

export const TestType: React.FC<NotesProps> = ({ task, isMobile }) => {
  return <StyledTableSampleCell $isMobile={isMobile}>{task.test_type.name}</StyledTableSampleCell>
}
