import React from 'react'

import { IHRSample, ISample } from '@models/samples'
import { useAppSelector } from '@hooks/redux'
import { useToggleSample } from '@hooks/common'
import { Checkbox } from '@components/shared'
import { selectSamplesInModal, selectSamplesInModalState } from '@state/packages'

import { StyledTableCell } from '../styles'

interface TogglerProps {
  sample: ISample | IHRSample
  isLastSample?: boolean
  isSampleSelected?: boolean
  isVerify?: boolean
}

export const Toggler: React.FC<TogglerProps> = React.memo(props => {
  const { sample, isLastSample, isSampleSelected, isVerify } = props

  const selectedSamplesInModal = useAppSelector(selectSamplesInModalState)

  const { selectSampleHandler, isSelected } = useToggleSample(
    sample.id,
    selectSamplesInModal,
    selectedSamplesInModal
  )

  return (
    <StyledTableCell
      sx={{ marginTop: isVerify ? '-33px' : '0' }}
      isLastSample={isLastSample}
      isSampleSelected={isSampleSelected}
    >
      <Checkbox checked={isSelected} onClick={selectSampleHandler} />
    </StyledTableCell>
  )
})
