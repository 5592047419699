import { useMemo } from 'react'

import { ReactComponent as NegIcon } from '@assets/icons/TandemMSResultIcons/neg-icon.svg'
import { ReactComponent as PosIcon } from '@assets/icons/TandemMSResultIcons/pos-icon.svg'
import { ReactComponent as NotIcon } from '@assets/icons/TandemMSResultIcons/none-icon.svg'
import { Tooltip } from '@components/shared'

import { TandemMSResultsProps } from '../tandemMS.types'

export const Icon: React.FC<TandemMSResultsProps> = ({ clinicallyMeaningful }) => {
  const renderIcon = useMemo(() => {
    if (clinicallyMeaningful?.key === 'not_clinically_meaningful') return <NegIcon />
    if (clinicallyMeaningful?.key === 'clinically_meaningful') return <PosIcon />
    return <NotIcon />
  }, [clinicallyMeaningful])

  return (
    <div style={{ marginLeft: '18px', display: 'inline-flex', marginTop: '5.5px' }}>
      <Tooltip isPureTooltip title={clinicallyMeaningful?.value || ''}>
        {renderIcon}
      </Tooltip>
    </div>
  )
}
