import { useMediaQuery, useTheme } from '@mui/material'

export const FilterMenuOpenIcon = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery('(max-width:767px)')

  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {!isMobile && <circle cx='21' cy='21' r='20.5' fill='white' stroke='#E5E5E6' />}
      <rect x='11' y='15' width='20' height='2' rx='1' fill={theme.palette.primary.main} />
      <rect x='13' y='20' width='15' height='2' rx='1' fill={theme.palette.primary.main} />
      <rect x='16' y='25' width='10' height='2' rx='1' fill={theme.palette.primary.main} />
    </svg>
  )
}
