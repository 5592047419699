export const containerSX: any = {
  boxSizing: 'border-box',
  padding: '32px',
  position: 'relative',
}

export const imageSX: any = {
  boxSizing: 'border-box',
  width: 'calc(50% - 32px)',
  height: 'calc(100vh - 32px* 2)',
  borderRadius: '40px',
  position: 'sticky',
  top: '32px',
  left: 0,
  alignSelf: 'flex-start',
  objectFit: 'cover',
}

export const linkSX: any = {
  position: 'fixed',
  top: '32px',
  right: '32px',
  zIndex: 9,
}

export const contentSX: any = {
  boxSizing: 'border-box',
  position: 'relative',
  padding: '60px 0 0 100px',
  width: '50%',
  display: 'flex',
}

export const formContainerSX: any = {
  padding: '50px 0 0 100px',
  maxWidth: '490px',
  width: '50%',
}

export const titleSX: any = {
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '39.01px',
  color: '#323232',
  width: '100%',
  fontFamily: '"Montserrat", sans-serif',
}

export const fieldTitleSX: any = {
  marginBottom: '8px',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: '"Montserrat", sans-serif',
  '& > .required': {
    color: '#C71C00',
  },
}

export const fieldInputSX: any = {
  borderRadius: '12px',
  outlined: 'none',
  fontFamily: '"Montserrat", sans-serif!important',
  '& > input': {
    outline: 'none!important',
  },
}

export const formControlSX: any = {
  marginTop: '20px',
}

export const forgotPasswordSX: any = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '19.5px',
  color: '#2776C4',
  textDecoration: 'underline',
  marginTop: '16px',
  width: 'fit-content',
  alignSelf: 'flex-end',
  fontFamily: '"Montserrat", sans-serif',
}

export const baseButtonSX: any = {
  width: '226px',
  height: '52px!important',
  textAlign: 'center',
  padding: '16px',
  fontSize: '16px',
  fontWeight: 500,
  borderRadius: '32px',
  fontFamily: '"Montserrat", sans-serif',
}

export const requestButtonSX: any = {
  ...baseButtonSX,
  border: '1px solid #002D89',
  color: '#002D89',
  background: '#FFFFFF',
  '&:hover': {
    background: '#2566C9',
    color: '#FFFFFF',
  },
}

export const loginButtonSX: any = {
  ...baseButtonSX,
  border: '1px solid #002D89',
  color: '#FFFFFF',
  background: '#002D89',
  '&:hover': {
    background: '#2566C9',
  },
}

export const textSX: any = {
  fontSize: '16px',
  fontWeight: 400,
  color: '#323232',
  lineHeight: '24px',
  marginBottom: '5px',
  fontFamily: '"Montserrat", sans-serif',
  '& > a': {
    color: '#2776C4',
    fontWeight: 600,
    textDecoration: 'underline',
  },
}

export const modalSX: any = {
  padding: '48px',
  background: '#FFFFFF',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '16px',
  maxWidth: '600px',
  width: '100%',
}

export const closeSX: any = {
  position: 'absolute',
  top: '30px',
  right: '50px',
  zIndex: 9,
}

export const titleModalSX: any = {
  color: '#323232',
  fontSize: '32px',
  fontWeight: 700,
  lineHeight: '39.01px',
  textAlign: 'center',
  margin: '16px 0',
  fontFamily: '"Montserrat", sans-serif',
}

export const descriptionModalSX: any = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& > span': {
    color: '#323232',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    fontFamily: '"Montserrat", sans-serif',
    marginTop: '10px',
    '& > a': {
      color: '#3698E5',
      fontWeight: 600,
    },
  },
  '& > span:first-child': {
    marginTop: 0,
  },
}

export const emailModalSX: any = {
  color: '#323232',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  fontFamily: '"Montserrat", sans-serif',
}
