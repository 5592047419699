import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useDispatch } from 'react-redux'

import { stringSlice } from '@helpers/ui/common'
import { Tooltip, UserAvatar } from '@components/shared'
import { IUser } from '@models/common/user'
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg'
import { useFetchTimezones, useToggleActivateUser } from '@hooks/queries'
import { skillDetails } from '@constants/mappings'
import { setUserForEditing } from '@state/workSpace'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'

import { StyledIconBox, StyledParagraphLink, StyledSwitch, StyledTableSampleCell } from './styles'

interface TableCellsProps {
  user?: IUser
  setEditUserIsOpen: (arg1: boolean) => void
  isNotOwnerLaboratory: boolean
}

export const TableCells: React.FC<TableCellsProps> = props => {
  const { user, setEditUserIsOpen, isNotOwnerLaboratory } = props
  const dispatch = useDispatch()
  const theme = useTheme()

  const toggleActivateUserM = useToggleActivateUser()
  const { data } = useFetchTimezones()

  const userName = `${user?.first_name} ${user?.last_name}`
  const avatar = user && (user?.sex as any) === 'male' ? 'Male' : 'Female'
  const role =
    user && (user?.role as any) === 'inventory_manager'
      ? 'Inventory manager'
      : 'Laboratory Assistant'
  const phoneNumber = user?.phone_number ? user?.phone_number : '-'
  const email = user?.email ? user?.email : ''

  const timezoneName = user?.timezone
  const computedTimeZone = data?.find(
    ({ timezone_name }) => timezone_name === timezoneName
  )?.timezone_representation_name

  const timezoneUTC = computedTimeZone?.slice(0, 11) || '-'

  const editUserModalOpen = () => {
    if (isNotOwnerLaboratory) return
    setEditUserIsOpen(true)
    dispatch(setUserForEditing(user))
  }

  const changeUserStatusHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (user) {
      toggleActivateUserM.mutate({
        data: { is_active: e.target.checked },
        params: { id: String(user.id) },
      })
    }
  }

  const switcherTooltip: string = (() => {
    if (user?.is_default_manager) return 'The default manager cannot be deactivated'
    if (user?.activation_status === 'in_progress') return user.activation_status_description
    return ''
  })()

  const isSwitcherOfStatusChecked = !!user?.is_active || user?.activation_status === 'in_progress'

  return (
    <>
      <StyledTableSampleCell>
        <Box ml='-10px'>
          <UserAvatar width='28px' height='28px' sex={user?.sex as any} role={user?.role as any} />
        </Box>

        <Box display='flex' alignItems='center'>
          <Tooltip title={userName} shouldBeHidden={userName.length < 19}>
            <Typography sx={{ whiteSpace: 'nowrap', marginBottom: '0', marginLeft: '8px' }}>
              {stringSlice(userName, 19)}
            </Typography>
          </Tooltip>

          {/* user skills icons */}
          {user?.skills && (
            <Box ml='3px' display='flex'>
              {user?.skills.map(skill => (
                <Tooltip key={skill.id} title={skillDetails.tooltipTitle(skill.name).user}>
                  <img
                    src={skillDetails.icon(skill.id).default}
                    alt={`${skill.name} icon`}
                    style={{ marginLeft: '4px' }}
                  />
                </Tooltip>
              ))}
            </Box>
          )}
        </Box>
      </StyledTableSampleCell>

      <StyledTableSampleCell>{avatar}</StyledTableSampleCell>
      <StyledTableSampleCell>{role}</StyledTableSampleCell>

      <StyledTableSampleCell>{phoneNumber}</StyledTableSampleCell>
      <StyledTableSampleCell>
        <Tooltip title={email} shouldBeHidden={email.length < 23}>
          <StyledParagraphLink href={`mailto: ${email}`}>
            {stringSlice(email, 23)}
          </StyledParagraphLink>
        </Tooltip>
      </StyledTableSampleCell>

      <StyledTableSampleCell sx={{ whiteSpace: 'normal' }}>
        <Tooltip
          title={computedTimeZone || ''}
          shouldBeHidden={(timezoneName?.length || 0) < TRUNCATED_TEXT_LENGTH_S}
        >
          <Box>
            <Typography sx={{ display: 'inline-block' }} component='span'>
              {timezoneUTC}
            </Typography>
            <Typography color='secondary.light' fontSize={12} component='span'>
              {stringSlice(timezoneName || '', TRUNCATED_TEXT_LENGTH_S)}
            </Typography>
          </Box>
        </Tooltip>
      </StyledTableSampleCell>

      <StyledTableSampleCell>
        <Tooltip title={switcherTooltip} shouldBeHidden={!switcherTooltip}>
          <div>
            <StyledSwitch
              $isStatusInProgress={user?.activation_status === 'in_progress'}
              onChange={changeUserStatusHandler}
              checked={isSwitcherOfStatusChecked}
              disabled={
                isNotOwnerLaboratory || !!user?.is_default_manager || toggleActivateUserM.isLoading
              }
            />
          </div>
        </Tooltip>

        {!user?.is_default_manager && (
          <StyledIconBox onClick={editUserModalOpen}>
            <EditIcon fill={!isNotOwnerLaboratory ? theme.palette.primary.main : '#ADB4C2'} />
          </StyledIconBox>
        )}
      </StyledTableSampleCell>
    </>
  )
}
