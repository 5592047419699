import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { UserRole } from '@models/common/user'
import { protectedRoutes } from '@configs/routes.config'
import { ILaboratory } from '@models/common/app'
import { useToggle } from '@hooks/common'
import { ConfirmationModal } from '@components/shared'
import { useAppSelector } from '@hooks/redux'
import { selectSamplesChanged, setIsChangedField } from '@state/samples'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'

import { NavTabs, StyledTab, StyledTabs } from './styles'

interface NavigationProps {
  role: UserRole
  lab: ILaboratory
}

export const Navigation: React.FC<NavigationProps> = ({ role, lab }) => {
  const { isTenantType } = useTenant()
  const isLegalDrugs = isTenantType(TENANT_TYPE.legalDrugs)
  const [navLinks, setNavLinks] = React.useState([
    {
      id: 1,
      title: 'Samples',
      to: protectedRoutes.samples.path,
      isEnabled: true,
    },
    // Requests for future release
    // {
    //   id: 2,
    //   title: 'Requests',
    //   to: protectedRoutes.requests.path,
    //   isEnabled: true,
    // },
    {
      id: 3,
      title: 'Packages',
      to: protectedRoutes.packages.path,
      isEnabled: true,
    },
  ])

  const isManager = role === 'inventory_manager'
  const isTestingReportsEnabled = lab?.group?.manage_test_reports

  React.useEffect(() => {
    if (isTestingReportsEnabled) {
      setNavLinks(prevState => [
        ...prevState,
        {
          id: 4,
          title: protectedRoutes.testingReports.name,
          to: protectedRoutes.testingReports.path,
          isEnabled: true,
        },
      ])
    }
  }, [isTestingReportsEnabled])

  // * [ check is any fields in create sample page was changed
  const history = useHistory()
  const dispatch = useDispatch()

  const [showModal, setShowModal] = useToggle()
  const [navLinkPath, setNavLinkPath] = useState('')
  const isChangedField = useAppSelector(selectSamplesChanged)

  const goNavLinkConfirmation = () => {
    history.replace({
      pathname: navLinkPath,
    })
    dispatch(setIsChangedField(false))
  }

  const onLinkHandler = (path: string) => {
    setNavLinkPath(path)

    if (isChangedField) {
      setShowModal()
    }
  }
  // * check is any fields in create sample page was changed ]

  const createEditSampleTitle = (() => {
    if (isTenantType(TENANT_TYPE.streetDrugs)) {
      return `Cancel sample ${
        history.location.pathname.includes('/details') ? 'editing' : 'creation'
      }?`
    }
    return 'Are you sure you want to cancel sample creation?'
  })()

  const createEditSampleSubTitle = (() => {
    if (isTenantType(TENANT_TYPE.streetDrugs)) {
      return (
        <span>
          If the {history.location.pathname.includes('/details') ? 'editing' : 'creation'} of the
          sample is canceled,
          <br />
          changes you made so far will not be saved.
        </span>
      )
    }
    return undefined
  })()

  return (
    <>
      {isManager && (
        <NavTabs>
          {navLinks.map(({ id, to, title, isEnabled }) => (
            <React.Fragment key={uuid()}>
              {isChangedField ? (
                <StyledTab
                  key={id}
                  $isLegalDrugs={isLegalDrugs}
                  onClick={() => onLinkHandler(to)}
                  sx={{ pointerEvents: isEnabled ? 'auto' : 'none' }}
                >
                  {title}
                </StyledTab>
              ) : (
                <StyledTabs
                  key={id}
                  to={to}
                  sx={{ pointerEvents: isEnabled ? 'auto' : 'none' }}
                  exact
                  $isTenantLegalDrugs={isLegalDrugs}
                >
                  {title}
                </StyledTabs>
              )}
            </React.Fragment>
          ))}
        </NavTabs>
      )}

      {showModal && (
        <ConfirmationModal
          title={createEditSampleTitle}
          subtitle={createEditSampleSubTitle}
          cancelBtnText={`Continue ${
            history.location.pathname.includes('/details') ? 'editing' : 'creation'
          }`}
          confirmButtonText='Cancel'
          goBackModal
          onClose={setShowModal}
          onConfirm={goNavLinkConfirmation}
        />
      )}
    </>
  )
}
