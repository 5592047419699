import { Step, Icon, Divider, Typography, Box } from '@mui/material'
import { v4 as uuid } from 'uuid'

import checkIcon from '@assets/icons/check-in-circle.svg'
import { COMPLETED, IN_PROGRESS } from '@constants/tasks'
import { PAD } from '@models/tests'

import { StyledStepper, StyledBox, StyledText } from './styles'

interface StepProps {
  idx: number
  stepCounter: number
  stepColor: string
  isAbleToAddTask?: boolean
  isMobile?: boolean
  isSamples?: boolean
  descriptionText?: string
  descriptionSubText?: string
  topDivider?: string
  leftDivider?: string
}

export const TasksStep: React.FC<StepProps> = props => {
  const {
    idx,
    stepCounter,
    stepColor,
    isAbleToAddTask,
    isMobile,
    descriptionText,
    descriptionSubText,
    topDivider,
    leftDivider,
    isSamples,
  } = props
  const taskStep = idx + 1

  let color: string
  switch (stepColor) {
    case IN_PROGRESS:
      color = 'in_progress'
      break
    case PAD:
      color = 'awaiting'
      break

    default:
      color = stepColor
      break
  }

  const alignForText = stepColor === PAD && !descriptionSubText ? 'center' : 'start'

  return (
    <StyledBox>
      <StyledStepper
        $alignItems={alignForText}
        $isMobile={isMobile}
        $topDivider={topDivider}
        $leftDivider={leftDivider}
        $isLast={taskStep === stepCounter && isSamples}
        orientation={isMobile || stepColor !== PAD ? 'horizontal' : 'vertical'}
      >
        <Step key={uuid()} className={color}>
          <Icon>
            {stepColor === COMPLETED ? (
              <img src={checkIcon} alt='completed' />
            ) : (
              <Typography>{taskStep}</Typography>
            )}
          </Icon>
          {isMobile && taskStep !== stepCounter && <Divider />}
          {(taskStep !== stepCounter || isAbleToAddTask) && !isMobile && <Divider />}
        </Step>

        {descriptionText && (
          <Box ml={2}>
            <StyledText sx={{ alignSelf: descriptionSubText && 'start' }}>
              {descriptionText} <br /> {descriptionSubText}
            </StyledText>
          </Box>
        )}
      </StyledStepper>
    </StyledBox>
  )
}
