import { Box, Typography } from '@mui/material'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { useDispatch } from 'react-redux'

import { setApiErrorUpdatePhoneOrEmail } from '@state/user'

import { buttonStyles, containerStyles, inputStyles } from './styles'

import './styles/phoneNumber.css'

interface PhoneFieldProps {
  name: string
  apiError?: string
}

export const PhoneField: React.FC<PhoneFieldProps> = ({ name, apiError }) => {
  const dispatch = useDispatch()
  const { field } = useController({ name })
  const ref = useRef<HTMLDivElement>(null)

  const [width, setWidth] = useState(0)
  const [error, setError] = useState(apiError)

  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth)
    }
  }, [])

  useEffect(() => {
    setError(apiError)
  }, [apiError])

  const changeHandler = (val: string) => {
    if (`+${val}` !== field.value) {
      setError('')
    }
    field.onChange(() => val)
  }

  useEffect(() => {
    if (field.value === 'phoneNumber') dispatch(setApiErrorUpdatePhoneOrEmail(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value])

  return (
    <Box ref={ref}>
      <PhoneInput
        country={'us'}
        specialLabel={''}
        placeholder={field.value}
        inputProps={{ name, type: name, autoComplete: 'off' }}
        inputStyle={inputStyles}
        buttonStyle={buttonStyles}
        containerStyle={{
          ...containerStyles,
          border: `1px solid ${error ? '#AF4645' : '#E1E7F3'}`,
        }}
        dropdownStyle={{ width: width, marginLeft: '-10px' }}
        masks={{ fr: '(...) ..-..-..', at: '(...) ...-....' }}
        {...field}
        onChange={changeHandler}
      />
      <Box sx={{ height: '16px' }}>
        <Typography component='p' fontSize='12px' color='#AF4645' mt='4px'>
          {error}
        </Typography>
      </Box>
    </Box>
  )
}
