import React from 'react'
import { Typography } from '@mui/material'

import { ITaskLab } from '@models/tasks'

import { StyledTypography } from './styles'

interface ApiContentProps {
  task?: ITaskLab
  buttonOnClick: () => void
}

export const ApiContent: React.FC<ApiContentProps> = ({ task, buttonOnClick }) => {
  const apis = task?.main_apis

  return (
    <>
      {apis &&
        apis.map(api => (
          <React.Fragment key={api.id}>
            <StyledTypography>{api.name}</StyledTypography>
            <Typography variant='body2' color='#616d84'>
              {(api.percentage && `${parseFloat(api.percentage)}%`) || '-'}
            </Typography>
            <br />
          </React.Fragment>
        ))}
    </>
  )
}
