import React from 'react'

import { getAllowedRoutes, getProtectedRoutes } from '@helpers/routes'
import { useAppSelector } from '@hooks/redux'
import { selectUserLaboratory, selectUserRole } from '@state/user'

export const useProtectedRoutes = () => {
  const userRole = useAppSelector(selectUserRole)
  const userLaboratory = useAppSelector(selectUserLaboratory)
  const routes = getProtectedRoutes()
  const availableRoutes = getAllowedRoutes(routes, userRole.key, userLaboratory)
  const errorRef = React.useRef('')

  if (!userRole) errorRef.current = 'You cannot use useProtectedRoutes until user authorization'
  else errorRef.current = ''

  return { availableRoutes, error: errorRef.current }
}
