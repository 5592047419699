import React from 'react'

import * as sampleConstans from '@constants/samples'

export const draggableHorizontalScroll = (
  ref: React.RefObject<HTMLDivElement>,
  layerRef: React.RefObject<HTMLDivElement>,
  cupRef: React.RefObject<HTMLDivElement>
) => {
  let isDown: boolean = false
  let startX: number
  let scrollLeft: number
  let prevMouseXPosition: number = 0
  const scrollCupChild = cupRef?.current?.getElementsByClassName(
    'horizontal-scroll-box-inside'
  )[0] as HTMLDivElement

  if (
    ref?.current &&
    cupRef?.current &&
    scrollCupChild &&
    !document.getElementsByClassName('create-sample-check-scroll-row')[0]
  ) {
    scrollCupChild.style.width = `${ref?.current.scrollWidth}px`
  }

  // Table Events
  const mouseDown = (e: MouseEvent) => {
    if (e.target) {
      const el = e.target as HTMLElement

      if (el.nodeName === 'TEXTAREA') {
        const textareaEl = e.target as HTMLTextAreaElement
        if (
          textareaEl.value &&
          (textareaEl.name.includes(sampleConstans.NOTES) ||
            textareaEl.name.includes(sampleConstans.STORAGE_NOTES))
        ) {
          return
        }
      } else if (el.nodeName === 'INPUT') {
        const inputEl = e.target as HTMLInputElement
        const names = [
          sampleConstans.DRUG_CLASS,
          sampleConstans.DOSAGE,
          sampleConstans.UNITS,
          sampleConstans.VALUE,
          sampleConstans.LOT,
          sampleConstans.BUYERS_NAME,
          sampleConstans.PHARMACY_GPS,
          sampleConstans.ADDRESS_OF_MANUFACTURER,
          sampleConstans.MAIN_APIS,
          sampleConstans.EXCIPIENTS,
          sampleConstans.TOWN,
          sampleConstans.PHARMACY,
          sampleConstans.PHARMACY_GPS,
          sampleConstans.MANUFACTURER,
          sampleConstans.BRAND,
          sampleConstans.COUNTRY,
          sampleConstans.TEMPERATURE_FORMAT,
        ]
        const isNameFit = names.some(constant => inputEl.name.includes(constant))

        if (inputEl.value && isNameFit) return
      }
    }

    isDown = true
    prevMouseXPosition = e.pageX

    if (ref.current) {
      startX = e.pageX - ref.current.offsetLeft
      scrollLeft = ref.current.scrollLeft
    }
  }

  const mouseMove = (e: MouseEvent) => {
    if (!isDown) return
    e.preventDefault()

    let pureDropdownAutocomplete = document.getElementById('pure-dropdown-options-list')

    if (ref.current && layerRef.current) {
      const x = e.pageX - ref.current.offsetLeft
      const walk = (x - startX) * 3

      if (prevMouseXPosition - 10 >= e.pageX || prevMouseXPosition + 10 <= e.pageX) {
        ref.current.scrollLeft = scrollLeft - walk
        layerRef.current.style.display = 'block'
      }

      if (pureDropdownAutocomplete) {
        pureDropdownAutocomplete.style.transform = `translateX(-${scrollLeft - walk}px)`
      }
    }
  }

  const endOfDrag = () => {
    isDown = false
    if (ref.current && layerRef.current) layerRef.current.style.display = 'none'
  }

  const onScroll = (e: Event) => {
    if (e.target && cupRef.current && ref.current) {
      cupRef.current.scrollLeft = (e.target as HTMLElement)?.scrollLeft
    }
  }

  ref.current?.addEventListener('scroll', onScroll)
  ref.current?.addEventListener('mousedown', mouseDown)
  ref.current?.addEventListener('mousemove', mouseMove)
  ref.current?.addEventListener('mouseup', endOfDrag)
  ref.current?.addEventListener('mouseleave', endOfDrag)

  // ------------------------- Custom scroll Events ----------------------------------
  const cupScroll = (e: Event) => {
    if (e.target && ref.current) {
      ref.current.scrollLeft = (e.target as HTMLElement)?.scrollLeft
    }
  }

  if (cupRef?.current) {
    cupRef.current.addEventListener('scroll', cupScroll)
  }
  // -------------------------------------------------------------------

  return {
    removeDraggableEvents: () => {
      ref.current?.removeEventListener('scroll', onScroll)
      ref.current?.removeEventListener('mousedown', mouseDown)
      ref.current?.removeEventListener('mousemove', mouseMove)
      ref.current?.removeEventListener('mouseup', endOfDrag)
      ref.current?.removeEventListener('mouseleave', endOfDrag)

      if (cupRef?.current) {
        cupRef.current.removeEventListener('scroll', cupScroll)
      }
    },
  }
}
