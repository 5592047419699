import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { setMaintenanceModePage } from '@state/user'
import { IEditInfoLaboratoryData } from '@models/yourWorkspace'
import { updateLaboratoryInfo } from '@api/user'
import { LABORATORIES, TEST_TYPES } from '@constants/queries'
import { IError } from '@models/common/app'
import { setIsOpenEditLaboratoryModal } from '@state/workSpace'

export const useEditLaboratory = () => {
  const dispatch = useDispatch()

  const client = useQueryClient()

  const mutation = useMutation<unknown, IError, IEditInfoLaboratoryData>(updateLaboratoryInfo, {
    onSuccess: () => {
      dispatch(setIsOpenEditLaboratoryModal(false))
      client.invalidateQueries([LABORATORIES])
      client.invalidateQueries([TEST_TYPES, true])
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
