import React from 'react'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'

import { ILaboratory } from '@models/common/app'
import { setWasOpenedAdditionalInfo } from '@state/workSpace'

import * as Styled from '../styles'
import { DeliveryServices } from './fields/FieldWithCheckbox/DeliveryServices'
import { TestType } from './fields/FieldWithCheckbox'

interface AdditionalInfoProps {
  currentLaboratory?: ILaboratory
}

export const AdditionalInfo: React.FC<AdditionalInfoProps> = React.memo(({ currentLaboratory }) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(setWasOpenedAdditionalInfo(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box mb='16px'>
        <Styled.Label>Test type</Styled.Label>
        <TestType selectedTests={currentLaboratory?.test_types} name='test_types' />
      </Box>

      <Box mb='16px'>
        <Styled.Label>Delivery service</Styled.Label>
        <DeliveryServices
          selectedDelivery={currentLaboratory?.delivery_services}
          name='delivery_services'
        />
      </Box>
    </>
  )
})
