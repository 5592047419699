import React from 'react'
import { Typography } from '@mui/material'

import { ISampleTask } from '@models/samples'
import { getFormattedDate } from '@helpers/ui/date'
import { datePatterns } from '@constants/dates'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'

interface AssigneeDateProps {
  task: ISampleTask
}

export const AssigneeDate: React.FC<AssigneeDateProps> = ({ task }) => {
  const { timezone } = useAppSelector(selectUser)

  if (task.test_type.is_other) return null

  return (
    <Typography variant='body1'>
      {getFormattedDate(datePatterns.timeDayMonth, task.assignee_date, timezone) || '-'}
    </Typography>
  )
}
