import { Box } from '@mui/system'
import styled from 'styled-components'

export const StyledContainer = styled(Box)`
  padding: 56px 80px 59px;
  max-width: 1440px;
  margin: 0 auto;
`

export const StyledContainerMobile = styled(Box)`
  padding: 48px 20px;
  margin: 0 auto;
`
