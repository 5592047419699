import { IOption } from '@models/common/app'

type LoadingStatus = { isIdle: boolean; isLoading: boolean }

export const getAddBtnText = ({ isIdle, isLoading }: LoadingStatus) => {
  return isIdle ? 'Add new' : isLoading ? 'Adding...' : 'Add new'
}

export const shouldRenderAddBtn = (options: IOption[], value: string) => {
  return !options.find(({ name }) => name.toLowerCase().includes(value.toLowerCase()))
}

export const isExistingValue = (options: IOption[], value: string) => {
  return !!options.find(({ name }) => name.toLowerCase() === value.toLowerCase())
}

export const isValueGreaterThanMax = (value: string, maxLength: number) => value.length > maxLength
