import React from 'react'

import { Tooltip } from '@components/shared'
import { stringSlice } from '@helpers/ui/common'
import { StyledTextCell } from '@styles'

interface ExpectedMainSubstanceProps {
  expected_main_substances: string
}

const TRUNCATED_TEXT_LENGTH = 55

export const ExpectedMainSubstance: React.FC<ExpectedMainSubstanceProps> = ({
  expected_main_substances: expectedMainSubstances,
}) => {
  return (
    <>
      <Tooltip
        isPureTooltip
        maxWidth={250}
        title={expectedMainSubstances || ''}
        shouldBeHidden={
          !expectedMainSubstances?.length || expectedMainSubstances?.length < TRUNCATED_TEXT_LENGTH
        }
      >
        <StyledTextCell>
          {expectedMainSubstances
            ? stringSlice(expectedMainSubstances, TRUNCATED_TEXT_LENGTH)
            : '-'}
        </StyledTextCell>
      </Tooltip>
    </>
  )
}
