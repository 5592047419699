import ContentLoader from 'react-content-loader'

export const Loader: React.FC = props => (
  <ContentLoader
    speed={2}
    width={790}
    height={60}
    viewBox='0 0 790 60'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    {...props}
  >
    <rect x='19' y='20' rx='4' ry='4' width='20' height='20' />
    <rect x='709' y='20' rx='4' ry='4' width='72' height='20' />
    <rect x='60' y='20' rx='4' ry='4' width='63' height='20' />
    <rect x='187' y='20' rx='4' ry='4' width='130' height='20' />
    <rect x='374' y='20' rx='4' ry='4' width='196' height='20' />
  </ContentLoader>
)
