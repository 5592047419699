import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { ATTACHMENTS } from '@constants/queries'
import { deleteAttachment } from '@api/attachments'
import { IAttachments } from '@models/attachments'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useRemoveAttachments = (id?: number) => {
  const dispatch = useDispatch()
  const client = useQueryClient()

  const mutation = useMutation<unknown, IError, string>(deleteAttachment, {
    onSuccess: (_, attachmentId) => {
      client.setQueryData<IAttachments | undefined>([ATTACHMENTS, id], oldData => {
        return oldData && [...oldData?.filter(({ id }) => id !== Number(attachmentId))]
      })
      client.invalidateQueries(['packagesBlockchainHistory', id])
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
