import { Box } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { ExpandableApis } from '@components/shared'
import { getApisWithType } from '@helpers/ui/common'
import { StyledTableSampleCell } from '@styles'

interface APIsCellProps {
  task: ITaskLab
  isMobile?: boolean
}

export const APIsCell: React.FC<APIsCellProps> = ({ task, isMobile }) => {
  const { main_apis } = task

  const { allApisWithType } = getApisWithType({ main_apis })

  return (
    <StyledTableSampleCell $isMobile={isMobile} sx={{ padding: isMobile ? '0' : '16px 0 19px' }}>
      <Box>
        <ExpandableApis
          apis={allApisWithType}
          borderBottom='none'
          direction={isMobile ? 'column' : 'row'}
        />
      </Box>
    </StyledTableSampleCell>
  )
}
