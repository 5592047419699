import React from 'react'
import { Box } from '@mui/material'

import { IPackage } from '@models/packages'
import { formatDate } from '@helpers/dates'
import { datePatterns } from '@constants/dates'
import { Chip, MobileDetailsCard } from '@components/shared'
import { DeliveryDate } from '@components/modules/Packages/components/Tabs/components/TabContent/components/contents/PackagesContent/components/Table/components/TableCells/components'
import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'
import { NotesField } from '@components/shared/sampleFields'
import { PACKAGES } from '@constants/queries'
import { TemperatureField } from '@components/shared/TemperatureSensitiveToxicityFields/TemperatureField'

interface PackageInfoMobileProps {
  data: IPackage
  packageId?: number
}

export const PackageMainInfoMobile: React.FC<PackageInfoMobileProps> = ({ data }) => {
  const { timezone } = useAppSelector(selectUser)

  const packageInfo = [
    { title: 'Package ID', data: data.id },
    {
      title: 'Toxicity',
      data: data.toxicity_level?.value || '-',
    },
    {
      title: 'Storage temperature',
      data: (
        <TemperatureField
          minTemperature={data.min_temperature || undefined}
          maxTemperature={data.max_temperature || undefined}
          temperatureMeasurementFormatKey={data.max_temperature_measurement_format?.key}
        />
      ),
    },
    {
      title: 'Delivery service',
      data: '-',
    },
    {
      title: 'Tracking number',
      data: '-',
    },
    {
      title: 'Package type',
      data: '-',
    },
    { title: 'Quantity', data: data.quantity },
    {
      title: 'Last updated',
      data: formatDate(data.updated_at, datePatterns.timeDayMonthYear, timezone),
    },
    { title: 'From location', data: data.source.name },
    { title: 'Destination', data: data.destination?.name || '-' },
    {
      title: 'Delivery status',
      data: <Chip label={data.delivery_status.value} status={data.delivery_status.key} />,
      isDataTypeComponent: true,
    },

    {
      title: 'Delivery date',
      data: <DeliveryDate pack={data} />,
      isDataTypeComponent: true,
    },
    {
      title: 'Package notes',
      data: (
        <NotesField
          notes={data.notes}
          id={data.id}
          labelText='Package'
          content={PACKAGES}
          withoutTruncate
          editDetailsNotes
          $left='-55px'
          notesLength={500}
        />
      ),
      isDataTypeComponent: true,
      position: 'relative',
    },
  ]

  return (
    <Box pb='12px' mr={6} position='relative'>
      {packageInfo.map(({ title, isDataTypeComponent, data: arrData, position }, idx) => {
        if (title === 'Toxicity' && !data.toxicity_level?.value) return null
        if (title === 'Storage temperature' && !data.min_temperature) return null

        return (
          <MobileDetailsCard
            key={idx}
            title={title}
            isDataTypeComponent={isDataTypeComponent}
            data={arrData}
            minWidth='159px'
          />
        )
      })}
    </Box>
  )
}
