import { RootState } from '@state/store'

export const selectUser = (state: RootState) => state.user
export const selectUserRole = (state: RootState) => state.user.role
export const selectUserLaboratory = (state: RootState) => state.user.laboratory
export const selectUserCustomizeTableColumns = (state: RootState) =>
  state.user.customizeTableColumns
export const savedFilters = (state: RootState) => state.user.saved_filters

export const userTemperatureFormat = (state: RootState) => state.user.temperature_measurement_format
export const isDefaultManagerInCentralLab = (state: RootState) =>
  state.user.is_default_manager_in_central_lab
