import React from 'react'
import { Typography } from '@mui/material'

import { ISample } from '@models/samples'
import { StyledTableSampleCell } from '@styles'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'
import { stringSlice } from '@helpers/ui/common'
import { Tooltip } from '@components/shared'

interface SampleIDProps {
  sample: ISample
  isMobile: boolean
}

export const AddressOfManufacturer: React.FC<SampleIDProps> = ({ sample, isMobile }) => {
  const { address_of_manufacturer } = sample

  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      <Tooltip
        title={address_of_manufacturer?.description || ''}
        shouldBeHidden={
          ((address_of_manufacturer && address_of_manufacturer?.description.length) || 0) <=
          TRUNCATED_TEXT_LENGTH_S
        }
      >
        <Typography>
          {address_of_manufacturer
            ? stringSlice(address_of_manufacturer?.description, TRUNCATED_TEXT_LENGTH_S)
            : '-'}
        </Typography>
      </Tooltip>
    </StyledTableSampleCell>
  )
}
