import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Button, Input, Modal } from '@components/shared'
import { ReactComponent as InfoIcon } from '@assets/icons/info-circle.svg'

import {
  StyledForm,
  StyledLabel,
  StyledTitle,
  ButtonBox,
} from '../../../../pages/ForgotPassword/styles'
import * as Styled from './styles'
import { AddingAnotherTestModalTypes } from './addingAnotherTestModal.types'

export const AddingAnotherTestModal: React.FC<AddingAnotherTestModalTypes> = ({
  onClose,
  groupData,
}) => {
  const [isSending, setIsSending] = React.useState<boolean>(false)
  const isMobile = useMediaQuery('(max-width:767px)')

  const { register, handleSubmit, formState } = useForm()
  const rules = {
    required: 'Please fill in the field',
    maxLength: {
      value: 50,
      message: 'Maximum 50 characters',
    },
    minLength: {
      value: 2,
      message: 'Minimum 2 characters',
    },
    validate: {
      specialCharactersAllowed: (v: string) => {
        return (
          /^[A-Za-z0-9-/,':;_ ]+$/.test(v.trim()) ||
          "Please add a valid test name. Special characters allowed: - / , ' : ; _"
        )
      },
    },
  }

  const submitHandler: SubmitHandler<{ anotherTestName: string }> = ({ anotherTestName }) => {
    setIsSending(true)
    onClose({ group: groupData.group, name: anotherTestName })
  }

  return (
    <Modal open onClose={() => onClose()}>
      <StyledForm onSubmit={handleSubmit(submitHandler)} noValidate>
        {/* TITLE */}
        <StyledTitle variant={isMobile ? 'subtitle1' : 'h3'} sx={{ marginBottom: '38px' }}>
          Add another {groupData.name} test
        </StyledTitle>
        {/* NOTIFICATION */}
        <Styled.NotificationWrap>
          <InfoIcon width={24} height={24} />
          <Styled.NotificationDescription>
            Other test types are currently not supported. Please add test results to 'Sample notes'
            field
          </Styled.NotificationDescription>
        </Styled.NotificationWrap>
        {/* INPUT */}
        <StyledLabel
          label='Test name'
          control={
            <Input
              fullWidth
              variant='outlined'
              type='text'
              placeholder='Type name'
              error={!!formState.errors.anotherTestName?.message}
              helperText={formState.errors.anotherTestName?.message}
              {...register('anotherTestName', rules)}
            />
          }
        />
        {/* ACTION BUTTONS */}
        <ButtonBox>
          <Button
            type='button'
            variant='outlined'
            color='secondary'
            mt='42px'
            width='205px'
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            mt='42px'
            width='205px'
            height='44px'
            loading={isSending}
          >
            Add another test
          </Button>
        </ButtonBox>
      </StyledForm>
    </Modal>
  )
}
