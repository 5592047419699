import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Button } from '@components/shared'
import { useCreatePackages } from '@hooks/queries'
import { MAX_SELECTED_SAMPLE_NUMBER } from '@constants/samples'
import { setIsLoadingCreatePackage } from '@state/app'

interface CreatePackageProps {
  selectedSamples: number[]
  selectedSamplesAmount: number
}

export const CreatePackage: React.FC<CreatePackageProps> = props => {
  const { selectedSamples, selectedSamplesAmount } = props
  const dispatch = useDispatch()
  const createPackagesM = useCreatePackages('samples')

  useEffect(() => {
    if (createPackagesM.isLoading) dispatch(setIsLoadingCreatePackage(true))

    return () => {
      dispatch(setIsLoadingCreatePackage(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPackagesM.isError, createPackagesM.isLoading, createPackagesM.isSuccess])

  const createPackagesHandler = () => {
    createPackagesM.mutate({ samples: selectedSamples })
  }

  return (
    <Button
      variant='contained'
      height='48px'
      mr='16px'
      onClick={createPackagesHandler}
      loading={createPackagesM.isLoading}
    >
      Create package ({selectedSamplesAmount}/{MAX_SELECTED_SAMPLE_NUMBER})
    </Button>
  )
}
