import React from 'react'
import { Typography, useMediaQuery } from '@mui/material'

import { ReactComponent as TempSensitiveMobileIcon } from '@assets/icons/temp-sensitive-mobile.svg'
import { ReactComponent as ToxicityMobileIcon } from '@assets/icons/toxic-mobile.svg'
import { StyledInfoBox, StyledInfoBoxSkill } from '@styles'

interface InfoBoxTemperatureProps {
  temperatureText?: string | null | boolean
  toxicityText?: string | null | boolean
}
export const infoBoxText = {
  temperature: {
    sample:
      'This sample requires cold chain storage/shipment and constant temperature monitoring with a smart temperature device',
    package:
      'This package includes samples that require cold chain storage/shipment and constant temperature monitoring with a smart temperature device',
  },
  toxicity: {
    package:
      'Samples have to be packaged in such a way that even if the package is crushed, the liquid inside will be absorbed and won’t contaminate the area',
  },
}

export const InfoBoxTemperature: React.FC<InfoBoxTemperatureProps> = ({
  temperatureText,
  toxicityText,
}) => {
  const isMobile = useMediaQuery('(max-width:767px)')

  if (!temperatureText && !toxicityText) {
    return null
  }

  return (
    <StyledInfoBox sx={{ width: isMobile ? '88%' : 'inherit', maxWidth: '553px' }}>
      {temperatureText && (
        <StyledInfoBoxSkill>
          <div style={{ width: '16px', height: '16px', marginRight: '8px' }}>
            <TempSensitiveMobileIcon />
          </div>
          <Typography>{temperatureText}</Typography>
        </StyledInfoBoxSkill>
      )}
      {toxicityText && (
        <StyledInfoBoxSkill>
          <div style={{ width: '16px', height: '16px', marginRight: '8px' }}>
            <ToxicityMobileIcon />
          </div>
          <Typography>{toxicityText}</Typography>
        </StyledInfoBoxSkill>
      )}
    </StyledInfoBox>
  )
}
