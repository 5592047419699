import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { StringParam, useQueryParams } from 'use-query-params'

import { updatePackage } from '@api/packages'
import { COUNTERS, INCOMING_PACKAGES } from '@constants/queries'
import { IPackage, IPackagesData, IUpdatedPackageData } from '@models/packages'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useUpdatePackage = () => {
  const dispatch = useDispatch()
  const client = useQueryClient()
  const stringParams = {
    package_type: StringParam,
    delivery_status: StringParam,
    laboratories: StringParam,
    lastUpdates: StringParam,
  }

  const [queryString] = useQueryParams(stringParams)
  const { package_type, delivery_status, laboratories, lastUpdates } = queryString
  const queryStringStatus = delivery_status?.split(',')

  const isRequestHasFilters = !!queryStringStatus || !!laboratories || !!lastUpdates

  const mutation = useMutation<IPackage, IError, IUpdatedPackageData>(updatePackage, {
    onSuccess: packageData => {
      if (package_type === 'incoming') {
        if (isRequestHasFilters) client.invalidateQueries(INCOMING_PACKAGES)
        client.setQueryData<{ pageParams: any; pages: IPackagesData[] | undefined }>(
          [INCOMING_PACKAGES, null, null, null, null, null],
          oldData => {
            return {
              pages: oldData?.pages?.map(page => ({
                ...page,
                results: page.results.map(pack => {
                  if (pack.id === packageData.id) return packageData
                  return pack
                }),
              })),
              pageParams: oldData?.pageParams,
            }
          }
        )
        client.invalidateQueries(COUNTERS)
      }
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })

  return mutation
}
