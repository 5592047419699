import { Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useFetchPackage, useGeneratePackageXLS } from '@hooks/queries'
import { IPackage } from '@models/packages'
import { PageHeaderPackageDetails } from '@components/shared'

import { PackageDetailsContent } from './PackageDetailsContent'

interface MatchParams {
  packageId: string
}

export const PackageDetails: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>()
  const isMobile = useMediaQuery('(max-width:767px)')
  const fetchPackageQ = useFetchPackage<IPackage>(params.packageId)

  const packageItem = fetchPackageQ.data

  const generateXLS = useGeneratePackageXLS({
    packageId: params.packageId,
    specificId: packageItem?.specific_id || '',
  })

  const getXlsFile = () => generateXLS.mutate()

  return (
    <>
      {packageItem && (
        <>
          {isMobile ? (
            <Typography variant='h3' mb={4}>
              Package ID: {packageItem.specific_id}
            </Typography>
          ) : (
            <PageHeaderPackageDetails
              packageLoading={fetchPackageQ.isLoading}
              packageItem={packageItem}
              packageId={params.packageId}
              packageTooltip={fetchPackageQ.data.source.name}
            />
          )}
        </>
      )}
      <PackageDetailsContent
        fetchPackageQ={fetchPackageQ}
        packageId={params.packageId}
        generateXLS={getXlsFile}
      />
    </>
  )
}
