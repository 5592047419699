import { StringParam } from 'use-query-params'

import { IFilterAppliedStateOptions, IFilterStateOptions } from '@models/filters'
import { PHYSICAL_FORM, TEST_TYPES } from '@constants/queries'

export const FILTER_COUNT = 50
// * [name types as expected backend to search
export const APIS = 'apis'
export const EXCIPIENTS = 'excipients'
export const DRUG_CLASSES = 'drug_classes'
export const ASSIGNEES = 'assignees'
export const MANUFACTURERS = 'manufacturers'
export const TASK_STATUS = 'taskStatus'
export const USER_STATUS = 'userStatus'
export const REQUEST_STATUS = 'status'
export const TASK_RESULT = 'taskResult'
export const LABORATORIES_FILTER = 'laboratories'
export const INITIAL_LABORATORY_FILTER = 'initial_laboratory'
export const PHYSICAL_FORMS = 'physical_forms'
export const DELIVERY_STATUS = 'delivery_status'
export const TESTING_DATE = 'testingDate'
export const COLLECTION_DATE = 'started_collection_date'
export const LAST_UPDATES = 'lastUpdates'
export const EXPIRATION_DATE = 'expirationDate'
export const USERS = 'users'
export const SAMPLE_STATUS = 'sample_status'
// * [name types as expected backend to search

// * [ Titles for View selected filters
export const APIS_FILTER_TITLE = 'APIs'
export const EXCIPIENTS_FILTER_TITLE = 'Excipients'
export const ASSIGNEE_FILTER_TITLE = 'Task assignee'
export const DRUG_CLASS_FILTER_TITLE = 'Drug class'
export const MANUFACTURE_FILTER_TITLE = 'Manufacturer'
export const TASK_STATUS_FILTER_TITLE = 'Task status'
export const USER_STATUS_FILTER_TITLE = 'Status'
export const TASK_RESULT_FILTER_TITLE = 'Task result'
export const PURCHASE_STATUS_FILTER_TITLE = 'Purchase status'
export const REQUEST_STATUS_FILTER_TITLE = 'Request status'
export const PACKAGE_FROM_LABORATORY_TITLE = 'From laboratory'
export const PACKAGE_DESTINATION_TITLE = 'Destination'
export const PACKAGE_DELIVERY_STATUS_TITLE = 'Delivery status'
export const TESTING_DATE_FILTER_TITLE = 'Testing date'
export const COLLECTION_DATE_FILTER_TITLE = 'Collection date'
export const LAST_UPDATES_FILTER_TITLE = 'Last updates'
export const EXPIRATION_DATE_FILTER_TITLE = 'Expiration date'
export const TEST_REPORTS_LOCATION_TITLE = 'Test location'
export const REQUESTS_ALL_SAMPLES_TITLE = 'Current location'
export const CREATED_BY_SAMPLES_TITLE = 'Created by'
export const REQUESTS_INCOMING_TITLE = 'From laboratory'
export const REQUESTS_OUTGOING_TITLE = 'To laboratory'
export const CREATED_BY_TITLE = 'Created by'
export const PHYSICAL_FORM_TITLE = 'Physical form'
export const SAMPLE_STATUS_FILTER_TITLE = 'Sample status'
export const TEST_TYPE_FILTER_TITLE = 'Test type'

export type TitleURL =
  | typeof APIS
  | typeof EXCIPIENTS
  | typeof DRUG_CLASSES
  | typeof ASSIGNEES
  | typeof MANUFACTURERS
  | typeof TASK_STATUS
  | typeof REQUEST_STATUS
  | typeof TASK_RESULT
  | typeof LABORATORIES_FILTER
  | typeof INITIAL_LABORATORY_FILTER
  | typeof DELIVERY_STATUS
  | typeof TESTING_DATE
  | typeof COLLECTION_DATE
  | typeof LAST_UPDATES
  | typeof EXPIRATION_DATE
  | typeof USER_STATUS
  | typeof SAMPLE_STATUS
  | typeof TEST_TYPES
  | typeof PHYSICAL_FORM

export type TitleUiType =
  | typeof APIS_FILTER_TITLE
  | typeof EXCIPIENTS_FILTER_TITLE
  | typeof ASSIGNEE_FILTER_TITLE
  | typeof DRUG_CLASS_FILTER_TITLE
  | typeof MANUFACTURE_FILTER_TITLE
  | typeof TASK_STATUS_FILTER_TITLE
  | typeof TASK_RESULT_FILTER_TITLE
  | typeof PURCHASE_STATUS_FILTER_TITLE
  | typeof REQUEST_STATUS_FILTER_TITLE
  | typeof PACKAGE_FROM_LABORATORY_TITLE
  | typeof PACKAGE_DESTINATION_TITLE
  | typeof PACKAGE_DELIVERY_STATUS_TITLE
  | typeof TESTING_DATE_FILTER_TITLE
  | typeof COLLECTION_DATE_FILTER_TITLE
  | typeof LAST_UPDATES_FILTER_TITLE
  | typeof EXPIRATION_DATE_FILTER_TITLE
  | typeof TEST_REPORTS_LOCATION_TITLE
  | typeof REQUESTS_ALL_SAMPLES_TITLE
  | typeof REQUESTS_INCOMING_TITLE
  | typeof REQUESTS_OUTGOING_TITLE
  | typeof REQUEST_STATUS
  | typeof LABORATORIES_FILTER
  | typeof INITIAL_LABORATORY_FILTER
  | typeof USER_STATUS_FILTER_TITLE
  | typeof SAMPLE_STATUS_FILTER_TITLE
  | typeof TEST_TYPE_FILTER_TITLE
  | typeof PHYSICAL_FORM_TITLE

// * Titles for View selected filters]

//*[Type of selected and applied filter for ViewSelectedFilters component
export type typeOfSelectSlice =
  | 'apisSelectedFilters'
  | 'excipientsSelectedFilters'
  | 'drugClassesSelectedFilters'
  | 'manufacturersSelectedFilters'
  | 'assigneesSelectedFilters'
  | 'taskStatusSelectedFilters'
  | 'taskResultSelectedFilters'
  | 'requestStatusSelectedFilters'
  | 'laboratoriesSelectedFilters'
  | 'deliveryStatusSelectedFilters'
  | 'testingDateSelectedFilters'
  | 'collectionDateSelectedFilters'
  | 'lastUpdatesSelectedFilters'
  | 'expirationDateSelectedFilters'
  | 'userStatusSelectedFilters'
  | 'sampleStatusSelectedFilters'
  | 'testTypeSelectedFilters'
  | 'physicalFormSelectedFilters'

export type typeOfApplySlice =
  | 'appliedApisSelectedFilters'
  | 'appliedExcipientsSelectedFilters'
  | 'appliedDrugClassesSelectedFilters'
  | 'appliedManufacturersSelectedFilters'
  | 'appliedAssigneesSelectedFilters'
  | 'appliedTaskStatusSelectedFilters'
  | 'appliedTaskResultSelectedFilters'
  | 'appliedRequestStatusSelectedFilters'
  | 'appliedLaboratoriesSelectedFilters'
  | 'appliedDeliveryStatusSelectedFilters'
  | 'appliedTestingDateSelectedFilters'
  | 'appliedCollectionDateSelectedFilters'
  | 'appliedLastUpdatesSelectedFilters'
  | 'appliedExpirationDateSelectedFilters'
  | 'appliedUserStatusSelectedFilters'
  | 'appliedSampleStatusSelectedFilters'
  | 'appliedTestTypeSelectedFilters'
  | 'appliedPhysicalFormSelectedFilters'

export const selectedFiltersSlice: IFilterStateOptions = {
  apis: 'apisSelectedFilters',
  excipients: 'excipientsSelectedFilters',
  drug_classes: 'drugClassesSelectedFilters',
  manufacturers: 'manufacturersSelectedFilters',
  assignees: 'assigneesSelectedFilters',
  taskStatus: 'taskStatusSelectedFilters',
  taskResult: 'taskResultSelectedFilters',
  [REQUEST_STATUS]: 'requestStatusSelectedFilters',
  [LABORATORIES_FILTER]: 'laboratoriesSelectedFilters',
  [INITIAL_LABORATORY_FILTER]: 'laboratoriesSelectedFilters',
  [DELIVERY_STATUS]: 'deliveryStatusSelectedFilters',
  [TESTING_DATE]: 'testingDateSelectedFilters',
  [COLLECTION_DATE]: 'collectionDateSelectedFilters',
  [LAST_UPDATES]: 'lastUpdatesSelectedFilters',
  [EXPIRATION_DATE]: 'expirationDateSelectedFilters',
  [USER_STATUS]: 'userStatusSelectedFilters',
  [SAMPLE_STATUS]: 'sampleStatusSelectedFilters',
  [TEST_TYPES]: 'testTypeSelectedFilters',
  [PHYSICAL_FORM]: 'physicalFormSelectedFilters',
}

export const appliedFiltersSlice: IFilterAppliedStateOptions = {
  apis: 'appliedApisSelectedFilters',
  excipients: 'appliedExcipientsSelectedFilters',
  drug_classes: 'appliedDrugClassesSelectedFilters',
  manufacturers: 'appliedManufacturersSelectedFilters',
  assignees: 'appliedAssigneesSelectedFilters',
  taskStatus: 'appliedTaskStatusSelectedFilters',
  taskResult: 'appliedTaskResultSelectedFilters',
  [REQUEST_STATUS]: 'appliedRequestStatusSelectedFilters',
  [LABORATORIES_FILTER]: 'appliedLaboratoriesSelectedFilters',
  [INITIAL_LABORATORY_FILTER]: 'appliedLaboratoriesSelectedFilters',
  [DELIVERY_STATUS]: 'appliedDeliveryStatusSelectedFilters',
  [TESTING_DATE]: 'appliedTestingDateSelectedFilters',
  [COLLECTION_DATE]: 'appliedCollectionDateSelectedFilters',
  [LAST_UPDATES]: 'appliedLastUpdatesSelectedFilters',
  [EXPIRATION_DATE]: 'appliedExpirationDateSelectedFilters',
  [USER_STATUS]: 'appliedUserStatusSelectedFilters',
  [SAMPLE_STATUS]: 'appliedSampleStatusSelectedFilters',
  [TEST_TYPES]: 'appliedTestTypeSelectedFilters',
  [PHYSICAL_FORM]: 'appliedPhysicalFormSelectedFilters',
}
//*Type of selected and applied filter for ViewSelectedFilters component]

// * [params for searching
export const filterStringParamsQueries = {
  apis: StringParam,
  excipients: StringParam,
  drug_classes: StringParam,
  manufacturers: StringParam,
  assignees: StringParam,
  taskStatus: StringParam,
  taskResult: StringParam,
  [REQUEST_STATUS]: StringParam,
  [LABORATORIES_FILTER]: StringParam,
  [INITIAL_LABORATORY_FILTER]: StringParam,
  [DELIVERY_STATUS]: StringParam,
  [TESTING_DATE]: StringParam,
  [COLLECTION_DATE]: StringParam,
  [LAST_UPDATES]: StringParam,
  [EXPIRATION_DATE]: StringParam,
  [USER_STATUS]: StringParam,
  [SAMPLE_STATUS]: StringParam,
  [TEST_TYPES]: StringParam,
  [PHYSICAL_FORM]: StringParam,
}

export const clearAllFilterStringParamsQueries = {
  apis: undefined,
  excipients: undefined,
  drug_classes: undefined,
  manufacturers: undefined,
  assignees: undefined,
  taskStatus: undefined,
  taskResult: undefined,
  [REQUEST_STATUS]: undefined,
  [LABORATORIES_FILTER]: undefined,
  [INITIAL_LABORATORY_FILTER]: undefined,
  [DELIVERY_STATUS]: undefined,
  [TESTING_DATE]: undefined,
  [COLLECTION_DATE]: undefined,
  [LAST_UPDATES]: undefined,
  [EXPIRATION_DATE]: undefined,
  [USER_STATUS]: undefined,
  [SAMPLE_STATUS]: undefined,
  [TEST_TYPES]: undefined,
  [PHYSICAL_FORM]: undefined,
}
// * params for searching]
