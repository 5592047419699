import ContentLoader from 'react-content-loader'

export const Loader: React.FC = props => {
  return (
    <ContentLoader
      speed={1.8}
      width='100%'
      viewBox='0 0 1426 516'
      backgroundColor='#f3f3f3'
      foregroundColor='#e3e3e3'
      {...props}
    >
      <path d='M 0 174 h 1426 v 2 H 0 z M 0 108 h 2 v 66 H 0 z M 1424 108 h 2 v 66 h -2 z' />
      <rect x='70' y='131' rx='4' ry='4' width='182' height='20' />
      <rect x='284' y='131' rx='4' ry='4' width='328' height='20' />
      <rect x='644' y='131' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='131' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='131' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 242 h 1426 v 2 H 0 z M 0 176 h 2 v 66 H 0 z M 1424 176 h 2 v 66 h -2 z' />
      <rect x='70' y='199' rx='4' ry='4' width='182' height='20' />
      <rect x='284' y='199' rx='4' ry='4' width='328' height='20' />
      <rect x='644' y='199' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='199' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='199' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 310 h 1426 v 2 H 0 z M 0 244 h 2 v 66 H 0 z M 1424 244 h 2 v 66 h -2 z' />
      <rect x='70' y='267' rx='4' ry='4' width='182' height='20' />
      <rect x='284' y='267' rx='4' ry='4' width='328' height='20' />
      <rect x='644' y='267' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='267' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='267' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 378 h 1426 v 2 H 0 z M 0 312 h 2 v 66 H 0 z M 1424 312 h 2 v 66 h -2 z' />
      <rect x='70' y='335' rx='4' ry='4' width='182' height='20' />
      <rect x='284' y='335' rx='4' ry='4' width='328' height='20' />
      <rect x='644' y='335' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='335' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='335' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 446 h 1426 v 2 H 0 z M 0 380 h 2 v 66 H 0 z M 1424 380 h 2 v 66 h -2 z' />
      <rect x='70' y='403' rx='4' ry='4' width='182' height='20' />
      <rect x='284' y='403' rx='4' ry='4' width='328' height='20' />
      <rect x='644' y='403' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='403' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='403' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 514 h 1426 v 2 H 0 z M 0 448 h 2 v 66 H 0 z M 1424 448 h 2 v 66 h -2 z' />
      <rect x='70' y='471' rx='4' ry='4' width='182' height='20' />
      <rect x='284' y='471' rx='4' ry='4' width='328' height='20' />
      <rect x='644' y='471' rx='4' ry='4' width='188' height='20' />
      <rect x='864' y='471' rx='4' ry='4' width='188' height='20' />
      <rect x='1084' y='471' rx='4' ry='4' width='310' height='20' />
      <path d='M 0 60 c 0 -5.523 4.477 -10 10 -10 h 1406 c 5.52 0 10 4.477 10 10 v 9 H 0 v -9 z M 0 89 h 1426 v 19 H 0 z M 0 69 h 70 v 20 H 0 z M 252 69 h 32 v 20 h -32 z M 612 69 h 32 v 20 h -32 z M 832 69 h 32 v 20 h -32 z M 1052 69 h 32 v 20 h -32 z M 1394 69 h 32 v 20 h -32 z M 70 20 C 70 8.954 78.954 0 90 0 h 142 c 11.046 0 20 8.954 20 20 v 30 H 70 V 20 z M 284 20 c 0 -11.046 8.954 -20 20 -20 h 142 c 11.046 0 20 8.954 20 20 v 30 H 284 V 20 z' />
    </ContentLoader>
  )
}
