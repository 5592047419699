import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'

import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'
import { IUpdatedTaskNote } from '@models/tasks'
import { updateTaskNotes } from '@api/tasks'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useUpdateTaskNotes = () => {
  const dispatch = useDispatch()
  const mutation = useMutation<unknown, IError, IUpdatedTaskNote>(updateTaskNotes, {
    onSuccess: (data, vars) => {
      showNotification(notifications.taskUpdatedSuccessfully)
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
      showNotification(notifications.taskUpdatedFailed)
    },
  })

  return mutation
}
