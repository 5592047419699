import React, { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { StringParam, useQueryParams } from 'use-query-params'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '@hooks/redux'
import { useFetchSamples } from '@hooks/queries'
import {
  filtersAside,
  setIsFilterOpen,
  applyFiltersOnPage,
  setPageForFilters,
  deleteAllAppliedFilters,
  deleteAllSelectedFilters,
} from '@state/filters'
import { FullPageError, SamplesTableLoader, TopProgressBar } from '@components/shared'
import { editingNotesFieldId, isEditingNotes, selectSamplesState } from '@state/samples'
import { StyledActionsStickyBox } from '@styles'
import { savedFilters, setSwitcherForRole } from '@state/user'
import {
  INITIAL_LABORATORY_FILTER,
  SAMPLE_STATUS,
  clearAllFilterStringParamsQueries,
  filterStringParamsQueries,
} from '@components/shared/FilterAside/constants'
import { ISamples } from '@models/samples'
import { LABORATORIES } from '@constants/queries'
import { setIsSamplesListLoaded } from '@state/exportSamples'

import { Table } from '../Table'
import { TabFilters } from '../Filters'
import { NoAddedSamples, NoFilteredSamples } from '../NoSamples'
import { TabSelectedCheckboxes } from './TabSelectedCheckboxes'

export const ActiveTab: React.FC = React.memo(() => {
  const [queryString, setQueryString] = useQueryParams({
    desired_samples: StringParam,
    ...filterStringParamsQueries,
  })
  const actualPageForSavedFilters = 'samples'

  const dispatch = useDispatch()
  const isFilterOpen = useAppSelector(filtersAside)
  const { selectedSamples } = useAppSelector(selectSamplesState)

  const getSavedFiltersFromUser = useAppSelector(savedFilters)
  const samplesFilters = getSavedFiltersFromUser.filter(i => {
    return i.page_title === actualPageForSavedFilters
  })[0]

  const [updatedFilters, setUpdatedFilters] = React.useState(samplesFilters)

  const isPageHasSavedFilters = updatedFilters.filters.some(i => i.values.length !== 0)

  const hasSelectedSamples = !!selectedSamples.length
  const fetchSamplesQ = useFetchSamples<ISamples>()
  const samples = fetchSamplesQ.data?.pages.map(page => [...page.results]).flat()

  const totalSamples = fetchSamplesQ.data?.pages[0].count

  const refetchSamples = () => fetchSamplesQ.refetch()

  useEffect(() => {
    if (fetchSamplesQ.isLoading) {
      dispatch(setIsSamplesListLoaded(false))
    } else {
      if (samples?.length !== 0) {
        dispatch(setIsSamplesListLoaded(true))
      } else {
        dispatch(setIsSamplesListLoaded(false))
      }
    }
  }, [dispatch, fetchSamplesQ.isLoading, samples?.length])

  React.useEffect(() => {
    // *  open/close filter aside
    dispatch(setIsFilterOpen({ ...isFilterOpen, right: false }))
    dispatch(setPageForFilters(actualPageForSavedFilters))

    return () => {
      dispatch(deleteAllAppliedFilters())
      dispatch(deleteAllSelectedFilters())
      setQueryString(clearAllFilterStringParamsQueries)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setUpdatedFilters(samplesFilters)
    if (isPageHasSavedFilters) dispatch(applyFiltersOnPage(samplesFilters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samplesFilters, isPageHasSavedFilters])

  React.useEffect(() => {
    if (fetchSamplesQ.isFetching) {
      dispatch(isEditingNotes(true))
    } else if (fetchSamplesQ.isFetched) {
      dispatch(isEditingNotes(false))
      dispatch(editingNotesFieldId(null))
      dispatch(setSwitcherForRole(false))
    }
  }, [dispatch, fetchSamplesQ.isFetched, fetchSamplesQ.isFetching])

  if (fetchSamplesQ.isLoading) return <SamplesTableLoader />

  if (fetchSamplesQ.isError) {
    return (
      <FullPageError
        errorTextTitle='Something went wrong with samples loading'
        btnText='Refresh'
        onBtnClick={refetchSamples}
        pt='20px'
      />
    )
  }

  const isNoSamplesForFilter =
    (!samples?.length && queryString.desired_samples) ||
    (!samples?.length &&
      (queryString.apis ||
        queryString.excipients ||
        queryString.assignees ||
        queryString.taskStatus ||
        queryString[SAMPLE_STATUS] ||
        queryString[INITIAL_LABORATORY_FILTER] ||
        queryString[LABORATORIES] ||
        queryString.taskResult))

  if (isNoSamplesForFilter)
    return (
      <>
        <StyledActionsStickyBox>
          {!hasSelectedSamples && <TabFilters isPageHasSavedFilters={isPageHasSavedFilters} />}
        </StyledActionsStickyBox>
        <NoFilteredSamples />
      </>
    )

  if (!samples?.length) return <NoAddedSamples />

  return (
    <>
      <StyledActionsStickyBox>
        {!hasSelectedSamples && <TabFilters isPageHasSavedFilters={isPageHasSavedFilters} />}
        {!!samples?.length && (
          <>
            {hasSelectedSamples && (
              <TabSelectedCheckboxes samples={samples} isFetching={fetchSamplesQ.isFetching} />
            )}
          </>
        )}
      </StyledActionsStickyBox>

      {isNoSamplesForFilter ? (
        <NoFilteredSamples />
      ) : (
        <>
          <InfiniteScroll
            hasMore={fetchSamplesQ.hasNextPage}
            loadMore={fetchSamplesQ.fetchNextPage as (page: number) => void}
          >
            {fetchSamplesQ.isFetching && <TopProgressBar />}

            <Table
              samples={samples}
              isFetchingSamples={fetchSamplesQ.isFetching}
              isLoadingSamples={fetchSamplesQ.isLoading}
              totalSamples={totalSamples}
              isFetchingNextPage={fetchSamplesQ.isFetchingNextPage}
            />
          </InfiniteScroll>
        </>
      )}
    </>
  )
})
