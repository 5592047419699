import { PackageContentQueryType } from '@models/packages'
import { ITasksTabValue } from '@models/tasks'
// Keys for React Query
export const VERIFY_PASSWORD_RECOVERY_TOKEN = 'account/recoverPassword'
export const CONFIRM_PASSWORD = 'account/confirmPassword'
export const LABORATORIES = 'laboratories'
export const COUNTERS = 'counters'
export const TIMEZONES = 'timezones'
export const PAD_TEST = 'PAD'

export type ContentOfPage =
  | typeof SAMPLES
  | typeof REQUEST_SAMPLES
  | typeof SAMPLES_IN_PACKAGE
  | typeof PACKAGES
  | typeof SAMPLE_TASKS
  | typeof OUTGOING_REQUESTS
  | typeof INCOMING_REQUESTS
  | typeof TASKS
  | typeof PACKAGES
  | typeof REPORTS
  | ITasksTabValue
  | PackageContentQueryType

export const ASSIGNEES = 'assignees'

export type TypeOfFilters = typeof APIS | typeof ASSIGNEES

// * [Samples

export const SAMPLES = 'samples'
export const TASKS = 'tasks'
export const STREET_SAMPLES = 'street-samples'

export const DRUG_CLASSES = 'drugClasses'
export const APIS = 'apis'
export const EXCIPIENTS = 'excipients'
export const TOWNS = 'towns'
export const PHARMACIES = 'pharmacies'
export const PHYSICAL_FORM = 'physical_form'
export const MANUFACTURERS = 'manufacturers'
export const MANUFACTURER_ADDRESSES = 'manufacturer_addresses'
export const BRANDS = 'brands'
export const COUNTRIES = 'countries'
export const SAMPLE_TASKS = 'sampleTasks'
export const ASSIGNEE_PERSON = 'assigneePerson'
export const SAMPLES_IN_PACKAGE = 'samplesInsidePackage'
export const SAMPLE_EXTRA_TASK = 'sampleExtraTask'
export const SAMPLE_EXTRA_TASK_BUTTON = 'sampleExtraTaskButton'
export const DOSAGE_TYPE = 'dosageType'
export const PURCHASE_SENDER_LABORATORIES = 'purchaseSenderLaboratories'
export const REGULATORY_REGISTRATIONS_TYPE = 'regulatory_registrations_type'

// * Samples]

// * [Packages

export const DELIVERY_SERVICES = 'deliveryServices'

export const PACKAGES = 'packages'
export const INCOMING_PACKAGES = 'incomingPackages'
export const OUTGOING_PACKAGES = 'outgoingPackages'

// * Samples]

// * [Requests

export const UNIT_TYPE = 'unitType'

// * Requests]

// * [Tasks list

export const TASKS_COMPLETED = 'completed'
export const TASKS_AWAITING = 'awaiting'

// * Tasks list]

// * [Testing reports

export const REPORTS = 'reports'
// * Testing reports]

// * [Attachments

export const ATTACHMENTS = 'attachments'
export const PHOTO_ATTACHMENTS = 'photo_attachment'

// * Attachments]

// * [RequestSamples

export const REQUESTS = 'requests'
export const REQUEST_SAMPLES = 'requestSamples'
export const INCOMING_REQUESTS = 'incomingRequests'
export const OUTGOING_REQUESTS = 'outgoingRequests'

// * RequestSamples]

// * [Skills

export const SKILLS = 'skills'

// * Skills]

// * [Test types

export const TEST_TYPES = 'test_types'

// * Test types]

// * [Tenant types

export const TENANT_TYPES = 'tenant_types'

// * Tenant types]
