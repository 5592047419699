import React from 'react'
import { Typography, useMediaQuery } from '@mui/material'

import { selectUser } from '@state/user'
import { IPackage } from '@models/packages'
import { useAppSelector } from '@hooks/redux'
import { datePatterns } from '@constants/dates'
import { getFormattedDate } from '@helpers/ui/date'

import { StyledTableCell } from '../../../styles'
import { StyledDatesWrapper } from './styles'

interface DeliveryDateProps {
  pack?: IPackage
}

export const DeliveryDate: React.FC<DeliveryDateProps> = ({ pack }) => {
  const $isMobile = useMediaQuery('(max-width:767px)')
  const dateFormat = $isMobile ? datePatterns.monthDayYear : datePatterns.fullDate
  const { timezone } = useAppSelector(selectUser)

  return (
    <StyledTableCell $isMobile={$isMobile}>
      {pack?.expected_date ? (
        <StyledDatesWrapper>
          <Typography color='secondary.light' variant='h6'>
            Expected date
          </Typography>
          <Typography>
            {getFormattedDate(dateFormat, pack.expected_date, timezone) || '-'}
          </Typography>
          <Typography color='secondary.light' variant='h6'>
            Actual date
          </Typography>
          <Typography>{getFormattedDate(dateFormat, pack.actual_date, timezone) || '-'}</Typography>
        </StyledDatesWrapper>
      ) : (
        <Typography color='secondary.light'>Not selected</Typography>
      )}
    </StyledTableCell>
  )
}
