import React from 'react'
import { Box } from '@mui/material'

import { useToggle } from '@hooks/common'
import { Button, Tooltip } from '@components/shared'
import { MAX_SELECTED_PACKAGE_NUMBER } from '@constants/packages'
import { SendPackageModal } from '@components/modules'
import { IPackage } from '@models/packages'

interface CreatePackageProps {
  selectedPackages: IPackage[]
  selectedPackagesAmount: number
}

export const SendPackage: React.FC<CreatePackageProps> = props => {
  const { selectedPackages, selectedPackagesAmount } = props
  const [isSendPackageModalOpen, toggleSendPackageModal] = useToggle()

  const packagesContainsNonRegularSample = selectedPackages.some(packageItem => {
    return packageItem.toxicity_level || packageItem.max_temperature || packageItem.min_temperature
  })

  return (
    <>
      <Tooltip
        title='Packages that contain toxic or/and temperature-sensitive samples should be sent one by one'
        shouldBeHidden={!packagesContainsNonRegularSample}
      >
        <Box>
          <Button
            variant='contained'
            height='48px'
            mr='16px'
            onClick={toggleSendPackageModal}
            disabled={packagesContainsNonRegularSample}
          >
            {selectedPackagesAmount > 1 ? 'Send packages' : 'Send package'} (
            {selectedPackagesAmount}/{MAX_SELECTED_PACKAGE_NUMBER})
          </Button>
        </Box>
      </Tooltip>

      {isSendPackageModalOpen && (
        <SendPackageModal selectedPackages={selectedPackages} onClose={toggleSendPackageModal} />
      )}
    </>
  )
}
