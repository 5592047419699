import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { useDispatch } from 'react-redux'

import { APIS } from '@constants/queries'
import { fetchApisF } from '@api/samples'
import { IOptionData } from '@models/samples'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useFetchApisFilters = (
  desiredApis: string | undefined
): UseInfiniteQueryResult<IOptionData, IError> => {
  const dispatch = useDispatch()

  return useInfiniteQuery([APIS, desiredApis], fetchApisF, {
    getNextPageParam: lastPage => {
      if (lastPage.current_page < lastPage.count_pages) return lastPage.current_page + 1
      return false
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
  })
}
