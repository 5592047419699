import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import {
  ATTACHMENT_TEST_REPORT_SECTION,
  ATTACHMENT_TEST_REPORT_TITLE,
  TEST_STRIP_ATTACHMENTS,
} from '@constants/tests'
import { PureAttachments } from '@components/shared/Hr'
import { IAttachments } from '@models/attachments'
import { StyledDetailsLabel } from '@styles'

export const Attachments = () => {
  const { getValues, setValue } = useFormContext()
  const { task } = getValues()

  const handleEditedAttachments = (data: IAttachments) => {
    setValue(
      `fields.${TEST_STRIP_ATTACHMENTS}`,
      data.map(attachment => attachment.id)
    )
  }

  return (
    <div style={{ order: 100 }}>
      <StyledDetailsLabel mb='9px'>Attachments</StyledDetailsLabel>
      <PureAttachments
        objectId={task.id}
        contentType='task'
        modalType='materials'
        section={ATTACHMENT_TEST_REPORT_SECTION}
        initialAttachments={task.attachments}
        sectionTitle={ATTACHMENT_TEST_REPORT_TITLE}
        setAttachmentsToParent={handleEditedAttachments}
        attachmentsIsLoading={(loading: boolean) => setValue('isLoading', loading)}
      />
    </div>
  )
}
