import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ISplitUnits } from '@models/samples'

const calculateMaxLimitPerUnit = (
  totalUnits: number,
  subSamples: number,
  listOfUnits: ISplitUnits
) => {
  return (
    totalUnits -
    listOfUnits.reduce(
      (counter, { blockedValue }) => (blockedValue ? counter + blockedValue : counter + 1),
      -1
    )
  )
}

const calculateLeftUnits = (totalUnits: number, listOfUnits: ISplitUnits) => {
  return totalUnits - listOfUnits.reduce((counter, { value }) => counter + value, 0)
}

export interface SamplesState {
  selectedSamples: number[]
  expandedSamples: number[]
  split: {
    currentSplitSampleId: number
    isSplitting: boolean
    totalUnits: number
    unitsLeft: number
    maxLimitPerUnit: number
    subSamples: string
    editMode: boolean
    listOfUnits: ISplitUnits
    globalErrorStatus: boolean
    globalRecountStatus: boolean
  }
  create: {
    isCreating: boolean
    isChangedField: boolean
  }
  isAddedExtraTask: boolean
  editNotes: {
    isEditingNotes: boolean
    currentEditingFieldNotesId: number | null
  }
}

const initialState: SamplesState = {
  selectedSamples: [],
  expandedSamples: [],
  split: {
    isSplitting: false,
    currentSplitSampleId: 0,
    totalUnits: 0,
    unitsLeft: 0,
    maxLimitPerUnit: 0,
    subSamples: '2',
    editMode: false,
    listOfUnits: [],
    globalErrorStatus: false,
    globalRecountStatus: false,
  },
  create: {
    isCreating: false,
    isChangedField: false,
  },
  isAddedExtraTask: true,
  editNotes: {
    isEditingNotes: false,
    currentEditingFieldNotesId: null,
  },
}

export const userSlice = createSlice({
  name: 'samples',
  initialState,
  reducers: {
    selectSamples: (state, { payload }: PayloadAction<number[]>) => {
      state.selectedSamples = payload
    },

    selectExpandedSamples: (
      state,
      {
        payload: { sampleId, deleteFlag },
      }: PayloadAction<{ sampleId: number | null; deleteFlag?: boolean }>
    ) => {
      if (!sampleId) {
        state.expandedSamples = []
      } else if (deleteFlag) {
        state.expandedSamples = state.expandedSamples.filter(id => id !== sampleId)
      } else {
        state.expandedSamples.push(sampleId)
      }
    },

    startSplitting: (state, { payload }: PayloadAction<number>) => {
      state.split.isSplitting = true
      state.split.currentSplitSampleId = payload
    },

    endSplitting: state => {
      state.split.isSplitting = false
      state.split.currentSplitSampleId = 0
    },

    editingNotesFieldId: (state, { payload }: PayloadAction<number | null>) => {
      state.editNotes.currentEditingFieldNotesId = payload
    },

    isEditingNotes: (state, { payload }: PayloadAction<boolean>) => {
      state.editNotes.isEditingNotes = payload
    },

    endCreating: state => {
      state.create.isCreating = false
    },

    startCreating: state => {
      state.create.isCreating = true
    },

    setIsChangedField: (state, { payload }: PayloadAction<boolean>) => {
      state.create.isChangedField = payload
    },

    addedExtraTask: (state, { payload }: PayloadAction<boolean>) => {
      state.isAddedExtraTask = payload
    },

    setTotalUnits: ({ split }, { payload }: PayloadAction<number>) => {
      split.totalUnits = payload
      split.maxLimitPerUnit =
        payload -
        (+split.subSamples - 1) -
        split.listOfUnits.reduce(
          (counter, { blockedValue }) => (blockedValue ? counter + blockedValue : counter),
          0
        )
    },

    changeSubSamples: ({ split }, { payload }: PayloadAction<string>) => {
      split.subSamples = payload
    },

    normalizeSubSamples: ({ split }, { payload }: PayloadAction<number>) => {
      if (+split.subSamples > (split.totalUnits > payload ? payload : split.totalUnits))
        split.subSamples = String(split.totalUnits > payload ? payload : split.totalUnits)
      else if (+split.subSamples < 2) {
        split.subSamples = '2'
      }
      split.maxLimitPerUnit = calculateMaxLimitPerUnit(
        split.totalUnits,
        +split.subSamples,
        split.listOfUnits
      )
    },

    changeEditMode: ({ split }) => {
      split.editMode = !split.editMode
    },

    recountMaxLimitPerUnit: ({ split }) => {
      split.maxLimitPerUnit = calculateMaxLimitPerUnit(
        split.totalUnits,
        +split.subSamples,
        split.listOfUnits
      )
    },

    recountListOfUnits: ({ split }, { payload }: PayloadAction<{ recount?: boolean }>) => {
      let newListOfUnits: ISplitUnits = new Array(+split.subSamples).fill({
        value: null,
        blockedValue: null,
      })

      const amountBlockedValue = split.listOfUnits?.reduce((count, unit) => {
        if (unit.blockedValue) count++
        return count
      }, 0)

      const sumBlockedValue = split.listOfUnits?.reduce((count, unit) => {
        if (unit.blockedValue) count += unit.blockedValue
        return count
      }, 0)

      const amountPerUnit = Math.floor(
        (split.totalUnits - sumBlockedValue) / (+split.subSamples - amountBlockedValue)
      )
      let residuePerUnit = Math.floor(
        (split.totalUnits - sumBlockedValue) % (+split.subSamples - amountBlockedValue)
      )

      newListOfUnits = newListOfUnits.map((unit, idx) => {
        if (split.listOfUnits[idx]?.blockedValue) {
          return {
            value: split.listOfUnits[idx].value,
            blockedValue: split.listOfUnits[idx].blockedValue,
          }
        } else {
          if (residuePerUnit) {
            residuePerUnit--
            return { value: amountPerUnit + 1, blockedValue: null }
          }
          return { value: amountPerUnit || 1, blockedValue: null }
        }
      })

      split.listOfUnits = newListOfUnits
      split.globalRecountStatus = payload.recount || false
      split.globalErrorStatus = false
      split.unitsLeft = calculateLeftUnits(split.totalUnits, split.listOfUnits)
      split.globalErrorStatus = split.unitsLeft === 0 ? false : true
    },

    lockUnit: ({ split }, { payload }: PayloadAction<number>) => {
      if (!split.listOfUnits[payload].blockedValue)
        split.listOfUnits[payload].blockedValue = split.listOfUnits[payload].value
      else split.listOfUnits[payload].blockedValue = null

      split.maxLimitPerUnit = calculateMaxLimitPerUnit(
        split.totalUnits,
        +split.subSamples,
        split.listOfUnits
      )
    },

    changeUnitValue: ({ split }, { payload }: PayloadAction<{ idx: number; value: number }>) => {
      split.listOfUnits[payload.idx].value = payload.value

      split.unitsLeft = calculateLeftUnits(split.totalUnits, split.listOfUnits)
      split.globalErrorStatus = split.unitsLeft === 0 ? false : true
    },

    normalizeUnitValue: ({ split }, { payload }: PayloadAction<number>) => {
      split.maxLimitPerUnit = calculateMaxLimitPerUnit(
        split.totalUnits,
        +split.subSamples,
        split.listOfUnits
      )

      if (split.listOfUnits[payload].value <= 0) {
        split.listOfUnits[payload].value = 1
        split.listOfUnits[payload].blockedValue = split.listOfUnits[payload].value
      } else if (split.listOfUnits[payload].value > split.maxLimitPerUnit) {
        split.listOfUnits[payload].value = split.maxLimitPerUnit
        split.listOfUnits[payload].blockedValue = split.listOfUnits[payload].value
      } else split.listOfUnits[payload].blockedValue = split.listOfUnits[payload].value

      split.unitsLeft = calculateLeftUnits(split.totalUnits, split.listOfUnits)
      split.globalErrorStatus = split.unitsLeft === 0 ? false : true
      split.maxLimitPerUnit = calculateMaxLimitPerUnit(
        split.totalUnits,
        +split.subSamples,
        split.listOfUnits
      )
    },

    resetSplitState: ({ split }) => {
      split.listOfUnits = []
      split.editMode = false
      split.totalUnits = 0
      split.subSamples = '2'
      split.unitsLeft = 0
      split.maxLimitPerUnit = 0
      split.globalErrorStatus = false
      split.globalRecountStatus = false
    },
  },
})

export const {
  reducer: samplesReducer,
  actions: {
    selectSamples,
    selectExpandedSamples,
    startSplitting,
    endSplitting,
    startCreating,
    endCreating,
    addedExtraTask,
    editingNotesFieldId,
    isEditingNotes,
    setTotalUnits,
    normalizeSubSamples,
    changeSubSamples,
    changeEditMode,
    recountListOfUnits,
    lockUnit,
    changeUnitValue,
    normalizeUnitValue,
    resetSplitState,
    recountMaxLimitPerUnit,
    setIsChangedField,
  },
} = userSlice
