import React from 'react'

import { ISample } from '@models/samples'
import { StyledTableSampleCell } from '@styles'

interface RegistrationStatusProps {
  sample: ISample
  isMobile?: boolean
}

export const RegistrationStatus: React.FC<RegistrationStatusProps> = ({ sample, isMobile }) => {
  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      {sample.regulatory_registration_status?.name || '-'}
    </StyledTableSampleCell>
  )
}
