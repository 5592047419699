import React from 'react'
import { useForm } from 'react-hook-form'

import { INewSamples } from '@models/samples'
import { REQUIRED_FIELD } from '@constants/validation'
import { Button, Input, Modal } from '@components/shared'
import { LIMITATION_ON_SAMPLE_CREATION } from '@constants/samples'

import { StyledForm, StyledLabel, StyledTitle, ButtonBox } from './styles'

interface CopySamplesProps {
  onClose: () => void
  onCopy: (data: { copy: string }) => void
  samples: INewSamples
}

export const CopySamples: React.FC<CopySamplesProps> = ({ samples, onClose, onCopy }) => {
  const { register, handleSubmit, formState } = useForm<{ copy: string }>()
  const [isCopying, setIsCopying] = React.useState(false)
  const maxCopies = LIMITATION_ON_SAMPLE_CREATION - samples.length

  const copySamplesHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!formState.errors.copy) setIsCopying(true)

    setTimeout(() => handleSubmit(onCopy)(e), 200)
  }

  React.useEffect(() => {
    if (formState.errors.copy) setIsCopying(false)
  }, [formState])

  return (
    <Modal open onClose={onClose} disableAutoFocus={true}>
      <StyledForm onSubmit={copySamplesHandler} noValidate>
        <StyledTitle variant='h3'>How many copies do you want to create?</StyledTitle>

        <StyledLabel
          label='Enter the number of copies'
          control={
            <Input
              autoFocus
              defaultValue='1'
              dataTestId='input-copy'
              width='140px'
              variant='outlined'
              type='copy'
              error={!!formState.errors.copy}
              helperText={formState.errors.copy && formState.errors.copy.message}
              {...register('copy', {
                required: REQUIRED_FIELD,
                validate: {
                  digit: v => !isNaN(Number(v)) || 'The value must be a digit',
                },
                max: {
                  value: maxCopies,
                  message: `Max ${maxCopies} copy at once`,
                },
                min: {
                  value: 1,
                  message: `Must be at least 1 copy`,
                },
              })}
            />
          }
        />

        <ButtonBox>
          <Button type='button' variant='outlined' width='205px' onClick={onClose}>
            Cancel
          </Button>

          <Button
            type='submit'
            variant='contained'
            width='205px'
            height='44px'
            loadingIndicator='Copying...'
            loading={isCopying}
            disabled={!!formState.errors.copy}
          >
            Copy
          </Button>
        </ButtonBox>
      </StyledForm>
    </Modal>
  )
}
