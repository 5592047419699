import { useMutation, useQueryClient } from 'react-query'

import { IError } from '@models/common/app'
import { createProject, updateProject } from '@api/projects'
import { showNotification } from '@helpers/app'
import { notifications } from '@constants/notifications'

export function useUpdateProject() {
  const queryClient = useQueryClient()
  const mutation = useMutation<unknown, IError, any>(updateProject, {
    onSuccess: () => {
      showNotification(notifications.projectUpdateSuccess)
      queryClient.invalidateQueries({ queryKey: ['projects'] })
    },
    onError: error => {
      showNotification(notifications.projectUpdateFailed)
    },
    retry: 1,
    // retryDelay: 1500,
  })

  return mutation
}

export function useCreateProject() {
  const queryClient = useQueryClient()
  const mutation = useMutation<unknown, IError, any>(createProject, {
    onSuccess: () => {
      showNotification(notifications.projectUpdateSuccess)
      queryClient.invalidateQueries({ queryKey: ['projects'] })
    },
    onError: error => {
      showNotification(notifications.projectUpdateFailed)
    },
    retry: 1,
  })

  return mutation
}
