import React from 'react'

import { Weight as SharedWeight } from '@components/shared/Hr'
import { IHRSample } from '@models/samples'

import { StyledTableCell } from '../../styles'

interface WeightProps {
  sample: IHRSample
}

export const Weight: React.FC<WeightProps> = ({ sample }) => {
  return (
    <StyledTableCell>
      <SharedWeight dosage_type={sample.dosage_type} weight={sample.weight} />
    </StyledTableCell>
  )
}
