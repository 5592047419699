import React, { useEffect } from 'react'
import { MenuItem, useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'

import { StyledRadio } from '@styles'
import {
  selectedFilters as filters,
  toggleSelectionAll,
  updateResetRadioBtnSampleStatus,
  updatedRadioBtnStatus,
} from '@state/filters'
import { StyledOptionTypography } from '@components/shared/sampleFields/CheckboxesInput/styles'
import { useAppSelector } from '@hooks/redux'
import { Checkbox } from '@components/shared/Checkbox'
import { IStringOption, typeOfFilter } from '@models/filters'
import { SAMPLE_STATUS, selectedFiltersSlice } from '@components/shared/FilterAside/constants'

interface FilterSelectAllProps {
  items: IStringOption[]
  type: typeOfFilter
  $isHidden?: boolean
}

export const FilterSelectAll: React.FC<FilterSelectAllProps> = React.memo(props => {
  const { type, items, $isHidden } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const dispatch = useDispatch()

  const selectedFilters = useAppSelector(filters)
  const isRadioBtnSelected = useAppSelector(updatedRadioBtnStatus)
  const isRadioBtnList = type === SAMPLE_STATUS

  const isSomeSelected = items.some(({ id }) =>
    selectedFilters[selectedFiltersSlice[type]].filters.find(({ id: statedId }) => statedId === id)
  )

  const isEverySelected = items.every(({ id }) =>
    selectedFilters[selectedFiltersSlice[type]].filters.find(({ id: statedId }) => statedId === id)
  )

  useEffect(() => {
    if (isSomeSelected && isRadioBtnList) dispatch(updateResetRadioBtnSampleStatus(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSomeSelected])

  const checkboxOnclick = () => {
    if (isRadioBtnList) {
      dispatch(updateResetRadioBtnSampleStatus(true))
    }
    dispatch(toggleSelectionAll({ type: selectedFiltersSlice[type], items, isRadioBtnList }))
  }

  const checkboxText = isEverySelected && !isRadioBtnList ? 'Clear all' : 'Select all'

  return (
    <MenuItem
      sx={{ padding: isMobile ? '0 24px' : '10px 0 10px 24px' }}
      onClick={() => checkboxOnclick()}
    >
      {isRadioBtnList ? (
        <StyledRadio padding='0' mr='6px' $isHidden={$isHidden} checked={isRadioBtnSelected} />
      ) : (
        <Checkbox
          sx={{ padding: '0px 9px 0 0' }}
          checked={isEverySelected}
          indeterminate={!isEverySelected && isSomeSelected}
        />
      )}

      <StyledOptionTypography>{isRadioBtnList ? 'Reset' : checkboxText}</StyledOptionTypography>
    </MenuItem>
  )
})
