import React from 'react'

import { ITaskLab } from '@models/tasks'
import { StyledTableSampleCell, StyledCupLabel } from '@styles'
import { TemperatureField } from '@components/shared/TemperatureSensitiveToxicityFields/TemperatureField'

export const Temperature: React.FC<{ task: ITaskLab; isMobile?: boolean }> = ({
  task,
  isMobile,
}) => {
  return task.max_temperature && task.min_temperature ? (
    <StyledTableSampleCell $isMobile={isMobile}>
      <TemperatureField
        minTemperature={task?.min_temperature}
        maxTemperature={task?.max_temperature}
        temperatureMeasurementFormatKey={task?.temperature_measurement_format.key}
      />
    </StyledTableSampleCell>
  ) : (
    <StyledCupLabel>For temperature-sensitive only</StyledCupLabel>
  )
}
