import { Box, styled, Switch, SwitchProps } from '@mui/material'

import { StyledTableHead as TableHead, StyledTableSampleCell as TableSampleCell } from '@styles'

export const StyledParagraphLink = styled('a')`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: 8px;
  cursor: pointer;
`

export const StyledTableHead = styled(TableHead)`
  & .MuiBox-root {
    &:last-child {
      padding-left: 0 !important;
    }
  }
`

interface StyledSwitchProps extends SwitchProps {
  $isStatusInProgress?: boolean
}

export const StyledSwitch = styled<React.FC<StyledSwitchProps>>(Switch)`
  width: 72px;
  height: 40px;

  margin-left: -12px;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin-left: 16px;
    margin-top: 15px;
  }

  & .Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  & .Mui-disabled.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.additional.stroke.main};
  }

  .iOBauM.Mui-disabled + .MuiSwitch-track {
    opacity: 0.7;
  }

  .MuiSwitch-thumb {
    background-color: #fff;
    box-shadow: none;
    height: 20px;
  }

  .MuiSwitch-thumb::before {
    display: none;
    background-color: transparent;
  }

  .Mui-checked + .MuiSwitch-thumb {
    background-color: #fff;
  }

  .MuiSwitch-track {
    background-color: ${({ theme }) => theme.additional.stroke.main};
    opacity: 1;
    height: 26px;
    width: 160px;
    border-radius: 30px;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme, $isStatusInProgress }) =>
      $isStatusInProgress ? '#FFCA67' : theme.palette.primary.main};
    opacity: 1;
  }
`

export const StyledTableSortWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  padding: 0 !important;

  & .MuiButtonBase-root {
    padding: 6px;
  }

  svg:nth-of-type(2) {
    margin-top: -8px;
  }
`
export const StyledTableSampleCell = styled(TableSampleCell)`
  padding: 12px 48px 12px 0;
  align-items: center;
`
export const StyledIconBox = styled(Box)`
  width: 25px;
  height: 25px;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: #f8f9f9;
  }
`

export const StyledNoSearchedUsersBox = styled(Box)`
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
`

export const StyledTableHeadStickyWrapper = styled(Box)`
  position: sticky;
  top: 60px;
  z-index: 200;
  padding-top: 5px;
  padding-bottom: 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
`
