import React from 'react'

import { stringSlice } from '@helpers/ui/common'
import { Tooltip } from '@components/shared'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'
import { StyledTableSampleCell, StyledTextCell } from '@styles'
import { ISample } from '@models/samples'

interface DrugClassProps {
  report: ISample
  hideBorder?: boolean
}

export const DrugClass: React.FC<DrugClassProps> = ({ report, hideBorder }) => {
  const { drug_class: drugClass } = report

  return (
    <StyledTableSampleCell isClickable hideBorder={hideBorder}>
      <Tooltip
        isPureTooltip
        maxWidth={200}
        title={drugClass.name}
        shouldBeHidden={drugClass.name.length < TRUNCATED_TEXT_LENGTH_S}
      >
        <StyledTextCell>{stringSlice(drugClass.name, TRUNCATED_TEXT_LENGTH_S)}</StyledTextCell>
      </Tooltip>
    </StyledTableSampleCell>
  )
}
