import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import { selectUser } from '@state/user'
import { useAppSelector } from '@hooks/redux'
import { useFetchTimezones } from '@hooks/queries'
import { TopProgressBar } from '@components/shared'
import { validationSchemas } from '@services/validation'
import { IProfileInfoFields } from '@models/common/forms'

import { ProfileContent } from './components'

export const Profile: React.FC = () => {
  const {
    first_name,
    last_name,
    email,
    sex,
    phone_number,
    timezone,
    timezone_autodetect,
    isFetching,
  } = useAppSelector(selectUser)

  const { data: timezones } = useFetchTimezones()

  const currentTimezone = timezones?.find(({ timezone_name }) => {
    if (timezone) return timezone_name === timezone
    else return timezone_name === Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  const defaultValues: IProfileInfoFields = React.useMemo(
    () => ({
      firstName: first_name,
      lastName: last_name,
      sex: sex.key,
      phoneNumber: phone_number?.slice(1) || '',
      email,
      timezone: currentTimezone || null,
      timezone_autodetect,
    }),
    [sex, email, last_name, first_name, phone_number, timezone_autodetect, currentTimezone]
  )

  const methods = useForm<IProfileInfoFields>({
    resolver: yupResolver(validationSchemas.profile),
    defaultValues,
  })

  return (
    <FormProvider {...methods}>
      {isFetching && <TopProgressBar />}
      <ProfileContent defaultValues={defaultValues} />
    </FormProvider>
  )
}
