import { styled as styledMUI, Box } from '@mui/material'
import React from 'react'

import { FieldsBoxProps } from './types'

export const StyledErrorBox = styledMUI<React.FC<FieldsBoxProps>>(Box)`
display: flex;
height: ${({ $isError }) => ($isError ? '35px' : '16px')};
margin-top: -24px;
margin-bottom: 8px;
`
