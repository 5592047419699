import { Typography, TypographyProps, ButtonProps, styled as styledMUI } from '@mui/material'
import styled from 'styled-components'
import { ImageProps } from 'styles/mui/Image'

import { Image } from '@styles'

import { Button } from '../Button'

interface StyledTypographyTittleProps extends TypographyProps {
  $isMobile: boolean
}
interface StyledButtonProps extends ButtonProps {
  $isMobile: boolean
}
interface StyledImageProps extends ImageProps {
  $isMobile: boolean
}

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #778299;
  padding-left: 20px;
  padding-right: 20px;
`

export const StyledTypographyTittle = styledMUI<React.FC<StyledTypographyTittleProps>>(Typography)`
  color: #0f1934;
  margin-top: 42px;
  font-size: ${({ $isMobile }) => ($isMobile ? '24px' : '36px')};
`

export const StyledTypographyDescription = styled(Typography)`
  color: #616d84;
  font-size: 16px;
  margin-top: 16px;
  text-align: center;
  max-width: 400px;
`

export const StylesButton = styledMUI<React.FC<StyledButtonProps>>(Button)`
  margin-top: 30px;
  height: 48px;
  width: ${({ $isMobile }) => ($isMobile ? '100%' : '205px')};
`

export const StylesImage = styledMUI<React.FC<StyledImageProps>>(Image)`
  margin-top: ${({ $isMobile }) => ($isMobile ? '60px' : '70px')};
  width: ${({ $isMobile }) => ($isMobile ? '160px' : '320px')};
  height: ${({ $isMobile }) => ($isMobile ? '160px' : '320px')};
`
