import React from 'react'
import { Typography } from '@mui/material'

import { stringSlice } from '@helpers/ui/common'
import { ITaskLab } from '@models/tasks'
import { Tooltip } from '@components/shared'
import { TooltipMobile } from '@components/shared/Tooltip/components'
import { TRUNCATED_TEXT_LENGTH_SX } from '@constants/common'
import { StyledTableSampleCell, StyledTextCell } from '@styles'

interface DrugClassProps {
  task: ITaskLab
  isMobile?: boolean
}

export const DrugClass: React.FC<DrugClassProps> = ({ task, isMobile }) => {
  const { drug_class: drugClass } = task
  const shouldBeHiddenTooltip = drugClass.name.length < TRUNCATED_TEXT_LENGTH_SX
  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      {isMobile ? (
        <TooltipMobile title={drugClass.name} shouldBeHidden={shouldBeHiddenTooltip}>
          <Typography variant='body1'>
            {drugClass ? stringSlice(drugClass.name, TRUNCATED_TEXT_LENGTH_SX) : '-'}
          </Typography>
        </TooltipMobile>
      ) : (
        <Tooltip title={drugClass.name} shouldBeHidden={shouldBeHiddenTooltip}>
          <StyledTextCell $isMobile={isMobile}>
            {stringSlice(drugClass.name, TRUNCATED_TEXT_LENGTH_SX)}
          </StyledTextCell>
        </Tooltip>
      )}
    </StyledTableSampleCell>
  )
}
