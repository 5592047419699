import React from 'react'

import { StyledTableTasksRow } from '@styles'

import { TasksCells } from '../TasksRows/TasksCells'
import { TableBodyProps } from './tableBody.types'

export const TableBody: React.FC<TableBodyProps> = props => {
  const { tasks, isAwaitingContentType, totalTasks, contentType, isHasToxicity, isHasTemperature } =
    props

  return (
    <>
      {tasks?.map((task, idx) => {
        const isLastTask = totalTasks === idx + 1
        return (
          <StyledTableTasksRow
            key={task.id}
            isAwaitingContentType={isAwaitingContentType}
            // ----- THESE TWO PROPS BELOW MAY BE ADDED IN THE FUTURE -----
            // isHasToxicity={isHasToxicity}
            // isHasTemperature={isHasTemperature}
            // ------------------------------------------------------------
            isHasToxicity={false}
            isHasTemperature={false}
          >
            <TasksCells
              task={task}
              isAwaitingContentType={isAwaitingContentType}
              contentType={contentType}
              isLastTask={isLastTask}
              isHasToxicity={isHasToxicity}
              isHasTemperature={isHasTemperature}
            />
          </StyledTableTasksRow>
        )
      })}
    </>
  )
}
