import React from 'react'
import { Breadcrumbs as BreadcrumbsMUI } from '@mui/material'
import { useLocation, useRouteMatch } from 'react-router-dom'

import navigateNextIcon from '@assets/icons/navigateNextIcon.svg'
import { IBreadcrumbsRoute } from '@models/common/app'

import { StyledIcon, StyledStack } from './styles'
import { Crumb } from './Crumb'

interface BreadcrumbsProps {
  mb?: string
  isChangedField?: boolean
  onLinkHandler?: (path: string) => void
  packageTooltip?: string
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = props => {
  const { mb, isChangedField, onLinkHandler, packageTooltip } = props

  const { state } = useLocation<IBreadcrumbsRoute[]>()
  const { url } = useRouteMatch()
  const checkUrl = state && state.length === 3 && state[1].url === url
  const breadcrumbs = state ? (checkUrl ? state.slice(0, -1) : state) : []

  return (
    <StyledStack mb={mb}>
      <BreadcrumbsMUI
        separator={<StyledIcon src={navigateNextIcon} alt='' />}
        aria-label='breadcrumbs'
      >
        {breadcrumbs.map((crumb, idx) => {
          return (
            <Crumb
              key={idx}
              isLastRout={idx === breadcrumbs.length - 1}
              {...crumb}
              isChangedField={isChangedField}
              onLinkHandler={onLinkHandler}
              packageTooltip={idx === 1 ? packageTooltip : ''}
            />
          )
        })}
      </BreadcrumbsMUI>
    </StyledStack>
  )
}
