import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

import KeyboardArrowUpIcon from '@assets/icons/sort-up.svg'
import KeyboardArrowDownIcon from '@assets/icons/sort-down.svg'
import { ReactComponent as IconStatistic } from '@assets/icons/statistic/statistic-icon.svg'
import { useAppSelector } from '@hooks/redux'
import { setOpenWidgets, widgetsState } from '@state/testReports'

import { StyledWidgetsRow, StyledWidgetsRowBox } from './styles'
import { StatisticData } from './StatisticData'

export const StatisticsWidgets = () => {
  const dispatch = useDispatch()
  const isOpenWidgets = useAppSelector(widgetsState)
  const [statisticTitle, setStatisticTitle] = useState('Summary statistics')
  const widgetsHandler = () => {
    dispatch(setOpenWidgets(!isOpenWidgets))
  }
  const theme = useTheme()

  return (
    <Box mb='32px' mt='10px'>
      <StyledWidgetsRow $isOpenWidgets={isOpenWidgets} onClick={widgetsHandler}>
        <StyledWidgetsRowBox>
          <Box display='inline-flex' alignItems='center'>
            <Typography variant='h5' mr='9px'>
              {statisticTitle}
            </Typography>
            <IconStatistic fill={theme.palette.primary.main} />
          </Box>
          <IconButton sx={{ width: '24px', height: '24px' }}>
            {isOpenWidgets ? (
              <img src={KeyboardArrowUpIcon} alt='up' />
            ) : (
              <img src={KeyboardArrowDownIcon} alt='down' />
            )}
          </IconButton>
        </StyledWidgetsRowBox>
      </StyledWidgetsRow>

      {isOpenWidgets && (
        <StatisticData isOpenWidgets={isOpenWidgets} setStatisticTitle={setStatisticTitle} />
      )}
    </Box>
  )
}
