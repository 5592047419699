import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useQueryClient } from 'react-query'
import { cloneDeep } from 'lodash'

import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { Modal, Button, Tooltip } from '@components/shared'
import {
  ButtonBox,
  StyledModalTitle,
  StyledModalSubtitle,
  StyledShadowBox,
  StyledModalContent,
  StyledModalScroll,
} from '@styles'
import { ModalTypes } from '@models/packages'
import { useFetchAttachments } from '@hooks/queries/useFetchAttachments'
import { dataModals, IDataModalsContent } from '@constants/mappings'
import { useAddAttachments, useRemoveAttachments, useUpdateAttachments } from '@hooks/queries'
import { IAttachments, PutAttachmentsTypes } from '@models/attachments'
import { TASKS } from '@constants/queries'
import { ITaskLab } from '@models/tasks'
import {
  selectTasks,
  setAddedAttachments,
  setIsEditAttachments,
  setNewAttachmentInstead,
} from '@state/tasks'
import { StyledTitle } from '@components/modules/Modals/DeactivateSamples/styles'
import { MINILAB, NEARIR, PAD, VISUAL_INSPECTION } from '@models/tests'
import { protectedRoutes } from '@configs/routes.config'
import { setCellValueToSample } from '@state/sampleCreate'
import { ATTACHMENTS } from '@constants/samples'
import {
  attachmentsCreateSample,
  hrAttachmentsCreateSample,
  removeCreateSampleAttachments,
  setCreateSampleAttachments,
  setHrCreateSampleAttachments,
} from '@state/app'

import { AttachFile, AttachLinkForm } from './components'
import { AttachedMaterialsList } from './components/AttachedMaterialsList/AttachedMaterialsList'
import { useAttachmentsLength } from './components/hooks'

interface IParams {
  modalType: ModalTypes
  packageId: string
  taskId: string
  sampleId: string
}
type DeletedAttachmentsTypes = {
  sectionKey: string
  ids: string[]
}

let deletedAttachmentsData: DeletedAttachmentsTypes[] = []
let allDeletedIds: string[] = []
let isChangesFinish: boolean = true
let isFetch: boolean = false

export const EditAttachedMaterials: React.FC = () => {
  // attachments - it's response from BE after PATCH
  const [attachments, setAttachments] = React.useState<IAttachments>([])
  const [isOpenExitModal, setIsOpenExitModal] = React.useState(false)
  const { isEditAttachments, addedAttachments, newAttachmentInstead } = useAppSelector(selectTasks)
  const { modalType, packageId, taskId, sampleId } = useParams<IParams>()
  const addedAttachmentsSample = useAppSelector(attachmentsCreateSample)
  const addedHrCreateSampleAttachments = useAppSelector(hrAttachmentsCreateSample)
  const { getAttachmentsLength } = useAttachmentsLength()
  // ! dataModals will be from BE like we have now
  const objectId = +packageId | +taskId | +sampleId
  const contentType = dataModals[modalType].contentType
  const history = useHistory()
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery('(max-width:767px)')
  const client = useQueryClient()
  const task = client.getQueryData<ITaskLab>([TASKS, objectId])
  const dataModalsContent = dataModals[modalType].content
  const contents = Array.isArray(dataModalsContent)
    ? dataModalsContent
    : dataModalsContent[task?.test_type.name || PAD || MINILAB || NEARIR || VISUAL_INSPECTION]
  const isSamplePhotoEdit = modalType === 'photo' && history.location.pathname.includes('/edit/')

  // Requests
  const addAttachmentsM = useAddAttachments(isEditAttachments)
  const updateAttachmentsM = useUpdateAttachments(objectId)
  const removeAttachmentsM = useRemoveAttachments(objectId)
  const fetchAttachmentsQ = useFetchAttachments({
    content_type: contentType,
    object_id: objectId,
    isFetch,
  })

  const exitAndCleanDeleteArrays = (): void => {
    if (isEditAttachments) dispatch(setIsEditAttachments(false))
    if (deletedAttachmentsData.length) deletedAttachmentsData = []
    if (allDeletedIds.length) allDeletedIds = []
    if (!isChangesFinish) isChangesFinish = true
    if (isFetch) isFetch = false
    if (addedAttachments) dispatch(setAddedAttachments(null))
    if (objectId) {
      history.goBack()
    } else history.push(protectedRoutes.createSample.path)
  }

  // Set edit mod flag to true on edit results page and clean added attachments on store
  React.useEffect(() => {
    if (
      !isEditAttachments &&
      (history.location.pathname.includes('details/attach/results') ||
        history.location.pathname.includes('details/attach/materials'))
    ) {
      dispatch(setIsEditAttachments(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fetch attachments

  React.useEffect(() => {
    if (
      attachments.find(attachment => attachment.loading) &&
      attachments.length !== fetchAttachmentsQ.data?.length
    ) {
    } else {
      if (
        (fetchAttachmentsQ.data && !isEditAttachments) ||
        (fetchAttachmentsQ.data && isEditAttachments && attachments.length === 0 && isChangesFinish)
      ) {
        if (isEditAttachments && !isFetch) isFetch = true
        setAttachments(fetchAttachmentsQ.data)
      }
    }
  }, [fetchAttachmentsQ.data, attachments, isEditAttachments])

  // Added fresh attaches to component state and clean @arrays from old data after deleted initial attachment
  // --------------------------
  // We get attachments data from store, because sometimes cache doesn't clean at "useMutation()" hook,
  // and we get correct data from BE but not correct data from "useMutation()" hook
  React.useEffect(() => {
    if (addedAttachments) {
      const { instead } = addedAttachments.result[0]
      // Remove deleted attachments and temporary "loading" attachment(item.id: string) from components state
      const oldAttachments = cloneDeep(attachments)
      addedAttachments.result.forEach(newAttach => {
        const oldAttachIndex = oldAttachments.findIndex(
          oldAttach => oldAttach.name === newAttach.name && typeof oldAttach.id === 'string'
        )
        oldAttachments.splice(oldAttachIndex, 1)
      })

      allDeletedIds = allDeletedIds.filter(item => +item !== instead || newAttachmentInstead)
      deletedAttachmentsData = deletedAttachmentsData.map(item => {
        if (item.ids.includes(`${instead || newAttachmentInstead}`)) {
          item.ids = item.ids.slice(0, item.ids.length - 1)
        }
        return item
      })
      if (newAttachmentInstead) {
        dispatch(setNewAttachmentInstead(''))
      }

      setAttachments([...oldAttachments, ...addedAttachments.result])
      isChangesFinish = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedAttachments, newAttachmentInstead])

  // Exit from edit attachment modal after save
  React.useEffect(() => {
    if (updateAttachmentsM.isSuccess) {
      exitAndCleanDeleteArrays()
      if (modalType === 'photo') {
        dispatch(setHrCreateSampleAttachments({ exitAttachModal: 'save' }))
      }
    } else if (updateAttachmentsM.isError && isFetch) {
      isFetch = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAttachmentsM.isSuccess])

  const removeAttachment = (id: string, sectionKey: string): void => {
    if (!isEditAttachments && !isSamplePhotoEdit) {
      removeAttachmentsM.mutate(id)
    } else {
      const removedAttachment = attachments.find(item => item.id === +id)

      if (modalType === 'photo') {
        dispatch(
          setHrCreateSampleAttachments({ attachments: null, exitAttachModal: 'delete-in-modal' })
        )
      }
      if (!objectId && modalType !== 'photo') {
        dispatch(removeCreateSampleAttachments(+id))
      }
      if (!removedAttachment?.instead) {
        // Create @arrays with deleted attaches data
        const deletedAttachmentSection = deletedAttachmentsData.find(
          item => item.sectionKey === sectionKey
        )
        if (!deletedAttachmentSection) {
          deletedAttachmentsData.push({ sectionKey, ids: [id] })
        } else {
          deletedAttachmentSection.ids.push(id)
          deletedAttachmentsData = deletedAttachmentsData.map(item => {
            if (item.sectionKey === sectionKey) return deletedAttachmentSection
            return item
          })
        }

        allDeletedIds.push(id)
      } else {
        // Push previously deleted initial attached data back to the @arrays after delete fresh attach
        deletedAttachmentsData = deletedAttachmentsData.map(item => {
          if (item.sectionKey === sectionKey) item.ids.push(`${removedAttachment.instead}`)
          return item
        })
        allDeletedIds.push(`${removedAttachment.instead}`)
      }

      setAttachments([...attachments.filter(item => item.id !== +id)])
      isChangesFinish = false
    }
  }

  const handleSave = (): void => {
    if (!objectId && modalType !== 'photo') {
      dispatch(
        setCellValueToSample({
          cellValue: { val: addedAttachmentsSample.cellValue, error: '' },
          cellName: ATTACHMENTS,
          sampleId: addedAttachmentsSample.sampleId,
        })
      )
      dispatch(
        setCreateSampleAttachments({
          cellValue: [],
          sampleId: '',
          isApplyNewAttachments: false,
        })
      )
    }

    if (isEditAttachments && !isChangesFinish && objectId && !isSamplePhotoEdit) {
      const attachmentsData =
        modalType === 'photo' ? addedHrCreateSampleAttachments.attachments || [] : attachments
      const data2Send: PutAttachmentsTypes = {
        content_type: contentType,
        object_id: objectId,
        attachments_ids: [...attachmentsData.map(attachment => +attachment.id)],
      }
      updateAttachmentsM.mutate(data2Send)
      isFetch = true
    } else {
      if (modalType === 'photo') {
        dispatch(setHrCreateSampleAttachments({ exitAttachModal: 'save' }))
      }
      exitAndCleanDeleteArrays()
    }
  }

  const handleCloseEditing = (): void => {
    if (isEditAttachments && !isChangesFinish) {
      setIsOpenExitModal(true)
    } else {
      exitAndCleanDeleteArrays()
      if (modalType === 'photo') {
        dispatch(setHrCreateSampleAttachments({ exitAttachModal: 'cancel' }))
      }
    }
  }

  const handleExitEditing = (): void => {
    setIsOpenExitModal(false)
    exitAndCleanDeleteArrays()
    if (modalType === 'photo') {
      dispatch(setHrCreateSampleAttachments({ exitAttachModal: 'cancel' }))
    }
  }

  const isSaveDisabled: boolean = React.useMemo(() => {
    const compareArr: IAttachments = []
    contents.forEach(content => {
      const attachItem = attachments.find(
        attachment => attachment.attachment_section.key === content.section.key
      )
      if (attachItem) compareArr.push(attachItem)
    })
    const isHasCustomAttach = attachments.some(attachment => typeof attachment.id === 'string')

    return (compareArr.length !== contents.length || isHasCustomAttach) && !sampleId
  }, [attachments, contents, sampleId])

  return (
    <>
      {/* CONTENT MODAL */}
      <Modal isPaddingAbsence open onClose={handleCloseEditing}>
        <Box>
          <StyledShadowBox padding={isMobile ? '20px' : '25px'}>
            <StyledModalTitle variant='h3'>{dataModals[modalType].modalTitle}</StyledModalTitle>
            <StyledModalSubtitle withoutMarginBelow>
              {dataModals[modalType].modalSubTitle}
            </StyledModalSubtitle>
          </StyledShadowBox>
          <StyledModalContent pt='0' $isMobile={isMobile}>
            <StyledModalScroll $isMobile={isMobile}>
              {contents.map((content: IDataModalsContent) => {
                const filteredAttachments = attachments?.filter(
                  attachment => attachment.attachment_section.key === content.section.key
                )
                const isFilteredHasNoDeleted = filteredAttachments.some(
                  item => !allDeletedIds.includes(`${item.id}`)
                )
                const lastDeletedAttachmentSection = deletedAttachmentsData.find(
                  item => item.sectionKey === content.section.key
                )
                const lastDeletedAttachmentId =
                  lastDeletedAttachmentSection?.ids[lastDeletedAttachmentSection.ids.length - 1]

                return (
                  <Box key={content.id} sx={modalType === 'photo' ? {} : { marginBottom: '32px' }}>
                    <Typography variant='h5' fontSize={18} mb={3} mt={3} lineHeight='24px'>
                      {content.sectionTitle}
                    </Typography>
                    {/* DROP ZONE COMPONENT */}
                    <AttachFile
                      modalType={modalType}
                      deletedAttachmentId={lastDeletedAttachmentId}
                      contentType={contentType}
                      objectId={objectId}
                      section={content.section.key}
                      sectionTitle={content.section.value}
                      addingError={addAttachmentsM.error}
                      attachments={filteredAttachments}
                      setAttachments={setAttachments}
                      addAttachments={addAttachmentsM.mutateAsync}
                    />
                    {!content.isWithoutLink && (
                      <>
                        <Typography
                          variant='body1'
                          sx={{ color: '#8F929B', textAlign: 'center', marginTop: '16px' }}
                        >
                          or
                        </Typography>
                        {/* ADD LINK COMPONENT */}
                        <AttachLinkForm
                          deletedAttachmentId={lastDeletedAttachmentId}
                          isDeactivate={
                            getAttachmentsLength(objectId, filteredAttachments.length) >= 3
                          }
                          contentType={contentType}
                          objectId={objectId}
                          section={content.section.key}
                          sectionTitle={content.section.value}
                          attachments={attachments}
                          setAttachments={setAttachments}
                          addAttachments={addAttachmentsM.mutateAsync}
                        />
                      </>
                    )}
                    {/* All Attached materials */}
                    <AttachedMaterialsList
                      modalType={modalType}
                      isSamplePhotoEdit={isSamplePhotoEdit}
                      filteredAttachments={filteredAttachments}
                      isFilteredHasNoDeleted={isFilteredHasNoDeleted}
                      allDeletedIds={allDeletedIds}
                      removeAttachment={removeAttachment}
                      objectId={objectId}
                    />
                  </Box>
                )
              })}
            </StyledModalScroll>
          </StyledModalContent>
          <StyledShadowBox sx={{ display: 'flex', justifyContent: 'center' }} padding='24px 10px'>
            <ButtonBox
              $isMobile={isMobile}
              width='100%'
              sx={{ justifyContent: 'center', margin: '0 14px' }}
            >
              <Button
                type='submit'
                variant='outlined'
                width={isMobile ? '100%' : '202px'}
                height='48px'
                mr={isMobile ? '0' : '28px'}
                onClick={handleCloseEditing}
              >
                Cancel
              </Button>

              <Tooltip
                title='Each section should contain one file or link'
                shouldBeHidden={!isSaveDisabled}
              >
                <>
                  <Button
                    type='submit'
                    disabled={addAttachmentsM.isLoading}
                    variant='contained'
                    width={isMobile ? '100%' : '202px'}
                    mt={isMobile ? '16px' : ''}
                    height='48px'
                    onClick={handleSave}
                    loading={updateAttachmentsM.isLoading}
                  >
                    Save
                  </Button>
                </>
              </Tooltip>
            </ButtonBox>
          </StyledShadowBox>
        </Box>
      </Modal>
      {/* EXIT MODAL FROM CONTENT MODAL */}
      <Modal open={isOpenExitModal} onClose={() => setIsOpenExitModal(false)}>
        <>
          <StyledTitle variant='h3'>
            Are you sure you want to cancel editing attachments?
          </StyledTitle>
          <ButtonBox $isMobile={isMobile}>
            <Button
              type='button'
              variant='outlined'
              color='secondary'
              width='205px'
              mr={isMobile ? '' : '60px'}
              onClick={handleExitEditing}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              mt={isMobile ? '16px' : ''}
              width='205px'
              onClick={() => setIsOpenExitModal(false)}
            >
              Continue editing
            </Button>
          </ButtonBox>
        </>
      </Modal>
    </>
  )
}
