import React from 'react'
import { Collapse, useMediaQuery } from '@mui/material'

import { IStep } from '@models/tests'
import { useToggle } from '@hooks/common'
import { COMPLETED, NOT_COMPLETED } from '@constants/tasks'
import { TESTING_STEP_TITLE } from '@constants/tests'
import { IHrTaskLab } from '@models/tasks'

import { Info, InfoMobile } from '../..'
import { Body, Header } from './components'
import { CompletedStep } from '../CompletedStep'
import { StepContainerExpanded } from './styles'

export const Testing: React.FC<IStep<IHrTaskLab>> = props => {
  const { isOpen, shouldRender, task, actualStep } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const [isTestStarted, setIsTestStarted] = React.useState(false)
  const [isStepCompleted, setIsStepCompleted] = React.useState(!isOpen)
  const [isResultsModalOpen, toggleResultsModal] = useToggle(task.step === 2)

  React.useEffect(() => {
    setIsStepCompleted(!isOpen)
  }, [isOpen])

  React.useEffect(() => {
    if (task.step > 0 && task.step <= 2) {
      setIsTestStarted(true)
    }
  }, [task.step])

  return (
    <>
      {isMobile && task.step <= 2 && <InfoMobile task={task} />}

      {shouldRender && (
        <>
          {!isMobile && (
            <CompletedStep
              status={task.step <= 2 ? NOT_COMPLETED : COMPLETED}
              counter={actualStep}
              open={isStepCompleted}
              title={TESTING_STEP_TITLE}
            />
          )}

          <Collapse in={!isStepCompleted} unmountOnExit>
            <StepContainerExpanded $isMobile={isMobile}>
              {!isMobile && <Header actualStep={actualStep} />}

              <Body
                task={task}
                isTestStarted={isTestStarted}
                isResultsModalOpen={isResultsModalOpen}
                toggleResultsModal={toggleResultsModal}
                setIsTestStarted={setIsTestStarted}
              />

              {!isMobile && <Info task={task} />}
            </StepContainerExpanded>
          </Collapse>
        </>
      )}
    </>
  )
}
