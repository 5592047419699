import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { SubmitHandler } from 'react-hook-form'
import { FormControl, FormHelperText, OutlinedInput, Typography } from '@mui/material'

import { toggleFlag } from '@state/app'
import { recoverPassword } from '@api/user'
import { useAppDispatch } from '@hooks/redux'
import { IEmailField } from '@models/common/forms'
import { publicRoutes } from '@configs/routes.config'
import { Button } from '@components/shared'
import { LoginTemplate } from '@components/login/LoginTemplate'
import {
  fieldInputSX,
  fieldTitleSX,
  formControlSX,
  loginButtonSX,
  requestButtonSX,
  textSX,
} from '@components/login/LoginTemplate/styles'
import { EMAIL_VALIDATION_REGEX, EMAIL_MATCH } from '@constants/validation'

export const ForgotPassword: React.FC = () => {
  const [email, setEmail] = React.useState('')
  const [emailMessageError, setEmailMessageError] = React.useState<null | string>()
  const [isSending, setIsSending] = React.useState(false)
  const [isEmailSent, setIsEmailSent] = React.useState(false)

  const dispatch = useAppDispatch()
  const history = useHistory()

  const sendingEmailWithErrorHandler = useCallback(() => {
    dispatch(toggleFlag('isPasswordRecoveryEmailNotSent'))
    history.push(publicRoutes.login.path)
  }, [dispatch, history])

  const onBlurEmail = useCallback((e: any) => {
    if (e.target.value.length > 0) {
      if (e.target.validity.valid && EMAIL_VALIDATION_REGEX.test(e.target.value)) {
        setEmailMessageError(null)
      } else {
        setEmailMessageError(EMAIL_MATCH)
      }
    }
  }, [])

  const onFocusEmail = useCallback((e: any) => {
    setEmailMessageError(null)
  }, [])

  const handleEmailChange = useCallback((e: any) => {
    setEmail(e.target.value)
  }, [])

  const openLink = useCallback((url: string) => {
    window.open(url, '_self')
  }, [])

  const submitHandler: SubmitHandler<IEmailField> = useCallback(
    async data => {
      if (email.length === 0 || emailMessageError) {
        if (email.length === 0) {
          setEmailMessageError('Please fill in the field')
          return
        }
      } else {
        try {
          setIsSending(true)
          await recoverPassword(data)

          setIsEmailSent(true)
        } catch (error) {
          sendingEmailWithErrorHandler()
        } finally {
          setIsSending(false)
        }
      }
    },
    [email.length, emailMessageError, sendingEmailWithErrorHandler]
  )

  const controls = useMemo(() => {
    return (
      <>
        {isEmailSent ? (
          ''
        ) : (
          <>
            <FormControl variant='outlined' fullWidth sx={formControlSX}>
              <Typography component='span' sx={fieldTitleSX}>
                Email{' '}
                <Typography component='span' className='required'>
                  *
                </Typography>
              </Typography>
              <OutlinedInput
                required
                sx={fieldInputSX}
                notched={false}
                value={email}
                onChange={handleEmailChange}
                error={Boolean(emailMessageError)}
                onBlur={onBlurEmail}
                onFocus={onFocusEmail}
                placeholder='Type here your email...'
                id='outlined-email'
                type='email'
                inputProps={{
                  maxLength: 320,
                }}
              />
              {!!emailMessageError && (
                <FormHelperText error id='outlined-email-error'>
                  {emailMessageError}
                </FormHelperText>
              )}
            </FormControl>
          </>
        )}
      </>
    )
  }, [email, emailMessageError, handleEmailChange, isEmailSent, onBlurEmail, onFocusEmail])

  const buttons = useMemo(() => {
    return (
      <>
        {isEmailSent ? (
          <Button sx={loginButtonSX} onClick={() => openLink(`https://artifacts.ai/`)}>
            Go back to home
          </Button>
        ) : (
          <>
            <Button sx={requestButtonSX} onClick={() => openLink(`/account/login`)}>
              Cancel
            </Button>
            <Button
              sx={loginButtonSX}
              // disabled={isButtonDisabled}
              onClick={() => submitHandler({ email })}
              loading={isSending}
            >
              Get new password
            </Button>
          </>
        )}
      </>
    )
  }, [email, isEmailSent, isSending, openLink, submitHandler])

  const description = useMemo(() => {
    return (
      <Typography sx={{ ...textSX, pt: '16px' }}>
        {isEmailSent
          ? 'You will receive an email with password reset instructions.'
          : 'We will send you a link to reset your password'}
      </Typography>
    )
  }, [isEmailSent])

  return (
    <LoginTemplate
      isIconShown={isEmailSent}
      controls={controls}
      title={isEmailSent ? 'Your request has been successfully sent' : 'Password recovery'}
      buttons={buttons}
      description={description}
    />
  )
}
