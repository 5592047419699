import ContentLoader from 'react-content-loader'

export const LoaderQrImg: React.FC = props => {
  return (
    <div>
      <ContentLoader
        speed={3}
        width={295}
        height={295}
        viewBox='0 0 295 295'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}
      >
        <path
          d='M7.8175 7.5732H7.5732V7.8175V31.27V31.5143H7.8175H31.27H31.5143V31.27V7.8175V7.5732H31.27H7.8175ZM0.244297 35.1787V3.90875C0.244297 1.88506 1.88506 0.244297 3.90875 0.244297H35.1787C37.2024 0.244297 38.8432 1.88506 38.8432 3.90875V35.1787C38.8432 37.2024 37.2024 38.8432 35.1787 38.8432H3.90875C1.88506 38.8432 0.244297 37.2024 0.244297 35.1787Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.488594'
        />
        <path
          d='M15.8791 23.206V15.8771H23.208V23.206H15.8791Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.488594'
        />
        <path
          d='M263.73 7.5732H263.485V7.8175V31.27V31.5143H263.73H287.182H287.426V31.27V7.8175V7.5732H287.182H263.73ZM291.091 38.8432H259.821C257.797 38.8432 256.156 37.2024 256.156 35.1787V3.90875C256.156 1.88506 257.797 0.244297 259.821 0.244297H291.091C293.115 0.244297 294.755 1.88506 294.755 3.90875V35.1787C294.755 37.2024 293.115 38.8432 291.091 38.8432Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.488594'
        />
        <path
          d='M271.791 23.206V15.8771H279.12V23.206H271.791Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.488594'
        />
        <path
          d='M31.27 287.428H31.5143V287.184V263.732V263.487H31.27H7.8175H7.5732V263.732V287.184V287.428H7.8175H31.27ZM3.90875 256.158H35.1787C37.2024 256.158 38.8432 257.799 38.8432 259.823V291.093C38.8432 293.116 37.2024 294.757 35.1787 294.757H3.90875C1.88506 294.757 0.244297 293.116 0.244297 291.093V259.823C0.244297 257.799 1.88506 256.158 3.90875 256.158Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.488594'
        />
        <path
          d='M15.8791 279.128V271.799H23.208V279.128H15.8791Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.488594'
        />
        <path
          d='M52.5031 20.6874V15.0852H58.1054V20.6874H52.5031Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M94.3332 20.6874V15.0852H99.9354V20.6874H94.3332Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M111.887 20.8741V21.0609H112.073H117.862V26.6631H106.284V15.0852H111.887V20.8741Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M70.2424 8.74208H70.0557V8.92882V14.9045V20.6935H64.4535V14.9045V8.92882V8.74208H64.2667H58.4778V3.13987H123.837V8.92882V14.7178H118.235V8.92882V8.74208H118.048H70.2424Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M52.5031 38.6162V27.0383H58.1054V38.6162H52.5031Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M55.6418 62.6171V57.0149H61.244V62.6171H55.6418Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M154.213 185.687L159.815 185.687L159.815 191.289L154.213 191.289L154.213 185.687Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M186.984 281.055V286.657H192.586V281.055H186.984Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M187.89 62.6171V57.0149H193.492V62.6171H187.89Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M70.432 32.8255V32.6388H70.2452H64.4563V27.0366H79.2088H79.3955V26.8498V20.8741V20.6874H79.2088H76.4077V15.0852H87.9856V20.6874H85.1845H84.9977V20.8741V26.6631H82.1966H82.0099V26.8498V38.6145H70.432V32.8255Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M85.5227 56.6405V51.0383H91.1249V56.6405H85.5227Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M160.19 215.562L165.792 215.562L165.792 221.164L160.19 221.164L160.19 215.562Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M216.845 287.117V292.72H222.447V287.117H216.845Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M217.769 56.6405V51.0383H223.371V56.6405H217.769Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M124.177 74.5624H123.99V74.7491V80.5381H118.388V68.9602H129.966V74.5624H124.177Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M142.262 254.218L142.262 254.031L142.075 254.031L136.286 254.031L136.286 248.429L147.864 248.429L147.864 260.007L142.262 260.007L142.262 254.218Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M255.556 269.274H255.369V269.087V263.298H249.767V274.876H261.345V269.274H255.556Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M226.041 38.8212V39.0079H225.854H220.065V44.6101H231.643V33.0322H226.041V38.8212Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M256.425 74.5624H256.238V74.7491V80.5381H250.636V68.9602H262.214V74.5624H256.425Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M94.3332 32.6405V27.0383H99.9354V32.6405H94.3332Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M67.2206 140.484V140.67H67.4073H73.1963V146.272H61.6184V134.695H67.2206V140.484Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M76.3427 197.258L76.1559 197.258L76.1559 197.444L76.1559 203.233L70.5537 203.233L70.5537 191.655L82.1316 191.655L82.1316 197.258L76.3427 197.258Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M198.771 203.204V203.017H198.958H204.747V197.415H193.169V208.993H198.771V203.204Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M199.469 140.484V140.67H199.655H205.444V146.272H193.866V134.695H199.469V140.484Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M124.212 26.664V21.0617H129.814V26.664H124.212Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M118.388 62.6162V51.0383H129.966V62.6162H118.388Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M154.214 248.429L165.792 248.429L165.792 260.007L154.214 260.007L154.214 248.429Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M249.72 281.228V292.805H261.298V281.228H249.72Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M237.993 44.5086H249.571V32.9307H237.993V44.5086Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M250.636 62.6162V51.0383H262.214V62.6162H250.636Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M5.9757 104.445H5.78896V104.632V128.348H0.186741V98.843H11.7647V104.445H5.9757Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M0.186741 62.6171V57.0149H29.6917V62.6171H0.186741Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M5.9757 80.5381H5.78896V80.7248V86.5138H0.186741V68.9602H11.7647V80.5381H5.9757Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M73.383 92.4964H73.1963V92.6832V98.4721H67.5941V80.7318V80.545H67.4073H61.6184V68.9671H73.1963V74.7561V74.9428H73.383H79.3587H79.5455V74.7561V68.7804V62.9914H85.1477V68.7804V68.9671H85.3344H91.1234V74.5693H85.3344H85.1477V74.7561V80.545H79.3587H79.172V80.7318V92.4964H73.383Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M124.336 203.42L124.336 203.233L124.149 203.233L118.36 203.233L118.36 197.631L136.1 197.631L136.287 197.631L136.287 197.444L136.287 191.655L147.865 191.655L147.865 203.233L142.076 203.233L141.889 203.233L141.889 203.42L141.889 209.396L141.889 209.583L142.076 209.583L148.052 209.583L153.841 209.583L153.841 215.185L148.052 215.185L147.865 215.185L147.865 215.372L147.865 221.16L142.263 221.16L142.263 215.372L142.263 215.185L142.076 215.185L136.287 215.185L136.287 209.396L136.287 209.209L136.1 209.209L124.336 209.209L124.336 203.42Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M204.778 251.23H204.591V251.043V245.254H198.989V262.995V263.182H198.802H193.013V274.759H204.591V268.97V268.784H204.778H210.753H210.94V268.97V274.946V280.735H216.542V274.946V274.759H216.729H222.518V269.157H216.729H216.542V268.97V263.182H210.753H210.567V262.995V251.23H204.778Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M205.631 92.4964H205.444V92.6832V98.4721H199.842V80.7318V80.545H199.655H193.866V68.9671H205.444V74.7561V74.9428H205.631H211.607H211.794V74.7561V68.7804V62.9914H217.396V68.7804V68.9671H217.582H223.371V74.5693H217.582H217.396V74.7561V80.545H211.607H211.42V80.7318V92.4964H205.631Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M55.4542 164.202H55.2675V164.389V170.178H49.6653V152.624H55.2675V158.413V158.6H55.4542H73.1946V164.202H70.3934H70.2067V164.389V170.178H64.6045V164.389V164.202H64.4178H55.4542Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M52.6279 185.491L52.6279 185.305L52.4412 185.305L46.6522 185.305L46.6522 179.702L64.2058 179.702L64.2058 185.305L58.4169 185.305L58.2301 185.305L58.2301 185.491L58.2301 203.232L52.6279 203.232L52.6279 200.431L52.6279 200.244L52.4412 200.244L46.6522 200.244L46.6522 194.642L52.4412 194.642L52.6279 194.642L52.6279 194.455L52.6279 185.491Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M187.06 179.478H186.873V179.291V173.502H181.271V191.055H186.873V185.266V185.08H187.06H204.8V179.478H201.999H201.812V179.291V173.502H196.21V179.291V179.478H196.023H187.06Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M187.704 164.202H187.517V164.389V170.178H181.915V152.624H187.517V158.413V158.6H187.704H205.445V164.202H202.643H202.457V164.389V170.178H196.854V164.389V164.202H196.668H187.704Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M112.225 101.459H112.038V101.646V107.435H106.436V101.646V101.459H106.249H100.46V95.8569H106.249H106.436V95.6701V83.9055H123.989V95.4834H118.201H118.014V95.6701V101.459H112.225Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M115.371 242.264L115.371 242.077L115.184 242.077L109.395 242.077L109.395 236.475L115.184 236.475L115.371 236.475L115.371 236.288L115.371 230.499L120.973 230.499L120.973 236.288L120.973 236.475L121.16 236.475L132.925 236.475L132.925 254.029L121.347 254.029L121.347 248.24L121.347 248.053L121.16 248.053L115.371 248.053L115.371 242.264Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M243.658 242.353H243.472V242.167V236.378H237.869V242.167V242.353H237.683H231.894V247.956H237.683H237.869V248.142V259.907H255.423V248.329H249.634H249.447V248.142V242.353H243.658Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M198.65 45.1424V45.3291H198.464H192.675V50.9313H198.464H198.65V51.1181V56.907H204.252V51.1181V50.9313H204.439H216.204V33.3777H204.626V39.1667V39.3534H204.439H198.65V45.1424Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M244.473 101.459H244.286V101.646V107.435H238.684V101.646V101.459H238.497H232.708V95.8569H238.497H238.684V95.6701V83.9055H256.238V95.4834H250.449H250.262V95.6701V101.459H244.473Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M103.261 71.5772H103.074V71.7639V77.5528H97.4719V59.9992H103.074V65.7882V65.9749H103.261H109.05V71.5772H103.261Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M145.249 233.296L145.249 233.109L145.062 233.109L139.273 233.109L139.273 227.507L156.827 227.507L156.827 233.109L151.038 233.109L150.851 233.109L150.851 233.296L150.851 239.085L145.249 239.085L145.249 233.296Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M234.622 272.204H234.435V272.017V266.228H228.833V283.782H234.435V277.993V277.806H234.622H240.411V272.204H234.622Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M229.153 59.7118V59.8985H228.967H223.178V65.5008H240.731V59.8985H234.942H234.756V59.7118V53.9228H229.153V59.7118Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M172.651 53.8037H172.465V53.9905V59.7794H166.863V42.2258H172.465V48.0148V48.2015H172.651H178.44V53.8037H172.651Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M43.589 68.5537H43.4022V68.7405V74.5294H37.8V56.9758H43.4022V62.7648V62.9515H43.589H49.3779V68.5537H43.589Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M151.89 42.1181V42.3048H152.076H157.865V47.907H140.312V42.3048H146.101H146.287V42.1181V36.3291H151.89V42.1181Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M18.1145 74.5624V68.9602H29.6924V74.5624H18.1145Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M49.6653 74.5624V68.9602H55.2675V74.5624H49.6653Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M142.262 179.702L147.864 179.702L147.864 185.305L142.262 185.305L142.262 179.702Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M181.036 269.086V274.688H186.639V269.086H181.036Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M181.915 74.5624V68.9602H187.517V74.5624H181.915Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M18.1145 104.445V98.843H23.7167V104.445H18.1145Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M49.6653 110.422V104.82H55.2675V110.422H49.6653Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M106.408 179.702L112.011 179.702L112.011 185.305L106.408 185.305L106.408 179.702Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M181.13 233.234V238.837H186.732V233.234H181.13Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M181.915 110.422V104.82H187.517V110.422H181.915Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M55.6418 104.445V98.843H61.244V104.445H55.6418Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M112.383 185.687L117.985 185.687L117.985 191.289L112.383 191.289L112.383 185.687Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M187.093 239.227V244.829H192.695V239.227H187.093Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M187.89 104.445V98.843H193.492V104.445H187.89Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M55.6418 152.25V146.648H61.244V152.25H55.6418Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M64.5782 185.687L70.1804 185.687L70.1804 191.289L64.5782 191.289L64.5782 185.687Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M187.218 191.414V197.016H192.82V191.414H187.218Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M187.89 152.25V146.648H193.492V152.25H187.89Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M12.1379 92.4921V86.8899H17.7401V92.4921H12.1379Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M18.1145 86.5155V80.9133H29.6924V86.5155H18.1145Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M43.6926 92.4921V86.8899H49.2948V92.4921H43.6926Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M124.336 173.734L129.938 173.734L129.938 179.336L124.336 179.336L124.336 173.734Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M175.109 251.149V256.751H180.711V251.149H175.109Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M175.939 92.4921V86.8899H181.541V92.4921H175.939Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M91.4953 89.5077V83.9055H97.0975V89.5077H91.4953Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M127.322 221.53L132.925 221.53L132.925 227.133L127.322 227.133L127.322 221.53Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M222.905 254.258V259.86H228.508V254.258H222.905Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M223.745 89.5077V83.9055H229.348V89.5077H223.745Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M136.7 8.74208V3.13987H142.303V8.74208H136.7Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M85.5227 95.4843V89.8821H91.1249V95.4843H85.5227Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M121.348 215.562L126.95 215.562L126.95 221.164L121.348 221.164L121.348 215.562Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M216.947 248.274V253.876H222.549V248.274H216.947Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M217.769 95.4843V89.8821H223.371V95.4843H217.769Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M130.724 14.7108V9.10862H136.326V14.7108H130.724Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M94.4836 110.422V104.82H100.086V110.422H94.4836Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M106.408 224.523L112.011 224.523L112.011 230.125L106.408 230.125L106.408 224.523Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M225.95 233.352V238.954H231.553V233.352H225.95Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M190.352 68.7117H195.954V63.1095H190.352V68.7117Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M226.732 110.422V104.82H232.334V110.422H226.732Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M133.325 104.445V98.843H144.903V104.445H133.325Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M138.837 24.2265V18.6242H150.415V24.2265H138.837Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M112.383 263.366L117.985 263.366L117.985 274.944L112.383 274.944L112.383 263.366Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M264.775 239.438V245.04H276.353V239.438H264.775Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M196.074 29.8367H201.677V18.2588H196.074V29.8367Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M265.575 104.445V98.843H277.153V104.445H265.575Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M29.6922 215.773V210.171H18.1143V215.773H29.6922Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M145.279 110.422V104.82H150.881V110.422H145.279Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M150.788 30.203V24.6008H156.391V30.203H150.788Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M106.408 275.32L112.011 275.32L112.011 280.922L106.408 280.922L106.408 275.32Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M276.743 233.484V239.087H282.346V233.484H276.743Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M190.049 17.9148H195.651V12.3126H190.049V17.9148Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M277.527 110.422V104.82H283.129V110.422H277.527Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M17.741 221.75V216.148H12.1388V221.75H17.741Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M82.5324 110.422V104.82H88.1347V110.422H82.5324Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M106.408 212.57L112.011 212.57L112.011 218.172L106.408 218.172L106.408 212.57Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M213.997 233.32V238.923H219.6V233.32H213.997Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M214.78 110.422V104.82H220.383V110.422H214.78Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M67.4073 110.795H67.5941V110.609V104.82H79.172V110.422H73.383H73.1963V110.609V116.397H61.6184V110.795H67.4073Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M106.035 197.444L106.035 197.631L106.222 197.631L112.011 197.631L112.011 209.209L106.408 209.209L106.408 203.42L106.408 203.233L106.222 203.233L100.433 203.233L100.433 191.655L106.035 191.655L106.035 197.444Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M198.88 232.908H199.067V233.095V238.884H210.645V233.281H204.856H204.669V233.095V227.306H193.091V232.908H198.88Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M199.655 110.795H199.842V110.609V104.82H211.42V110.422H205.631H205.444V110.609V116.397H193.866V110.795H199.655Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M85.3351 164.576H85.5218V164.39V158.601H97.0997V164.203H91.3108H91.124V164.39V170.179H79.5461V164.576H85.3351Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M52.2516 215.374L52.2516 215.561L52.4384 215.561L58.2273 215.561L58.2273 227.139L52.6251 227.139L52.6251 221.35L52.6251 221.163L52.4384 221.163L46.6494 221.163L46.6494 209.585L52.2516 209.585L52.2516 215.374Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M216.948 179.174H217.135V179.36V185.149H228.713V179.547H222.924H222.737V179.36V173.571H211.159V179.174H216.948Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M217.581 164.576H217.768V164.39V158.601H229.346V164.203H223.557H223.37V164.39V170.179H211.792V164.576H217.581Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M115.212 164.576H115.399V164.39V158.601H126.977V164.203H121.188H121.001V164.39V170.179H109.423V164.576H115.212Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M52.2516 245.249L52.2516 245.436L52.4384 245.436L58.2273 245.436L58.2273 257.014L52.6251 257.014L52.6251 251.225L52.6251 251.038L52.4384 251.038L46.6494 251.038L46.6494 239.46L52.2516 239.46L52.2516 245.249Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M246.825 179.252H247.012V179.438V185.227H258.59V179.625H252.801H252.614V179.438V173.649H241.036V179.252H246.825Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M247.462 164.576H247.649V164.39V158.601H259.227V164.203H253.438H253.251V164.39V170.179H241.673V164.576H247.462Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M142.102 62.6171H141.915V62.8038V68.5928H130.337V62.9906H136.126H136.313V62.8038V57.0149H147.891V62.6171H142.102Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M154.213 272.139L154.213 271.952L154.026 271.952L148.237 271.952L148.237 260.374L153.84 260.374L153.84 266.163L153.84 266.35L154.026 266.35L159.815 266.35L159.815 277.928L154.213 277.928L154.213 272.139Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M273.449 281.266H273.263V281.079V275.29H261.685V280.892H267.474H267.66V281.079V286.868H279.238V281.266H273.449Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M237.904 20.8299V21.0166H237.718H231.929V32.5945H237.531V26.8056V26.6188H237.718H243.507V15.0409H237.904V20.8299Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M274.352 62.6171H274.165V62.8038V68.5928H262.587V62.9906H268.376H268.563V62.8038V57.0149H280.141V62.6171H274.352Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M20.9158 173.937H21.1025V174.124V179.913H32.6804V174.311H26.8915H26.7048V174.124V168.335H15.1268V173.937H20.9158Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M88.509 131.336V125.734H94.1112V131.336H88.509Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M85.4923 218.546L91.0945 218.546L91.0945 224.148L85.4923 224.148L85.4923 218.546Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M220.029 212.422V218.024H225.631V212.422H220.029Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M220.757 131.336V125.734H226.359V131.336H220.757Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M88.509 143.289V137.687H94.1112V143.289H88.509Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M73.5392 218.546L79.1414 218.546L79.1414 224.148L73.5392 224.148L73.5392 218.546Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M220.06 200.469V206.071H225.662V200.469H220.06Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M220.757 143.289V137.687H226.359V143.289H220.757Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M79.5461 158.226V152.624H85.1483V158.226H79.5461Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M58.6036 209.585L64.2058 209.585L64.2058 215.187L58.6036 215.187L58.6036 209.585Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M211.136 185.508V191.11H216.738V185.508H211.136Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M211.792 158.226V152.624H217.394V158.226H211.792Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M76.5578 119.757H82.16V125.546V131.335H76.5578V125.546V119.757Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M97.0691 206.593L97.0691 212.195L91.2802 212.195L85.4912 212.195L85.4912 206.593L91.2802 206.593L97.0691 206.593Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M208.072 223.962H213.674V218.173V212.384H208.072V218.173V223.962Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M208.806 119.757H214.408V125.546V131.335H208.806V125.546V119.757Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M94.4836 149.258V143.655H100.086V149.258H94.4836Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M67.5685 224.523L73.1707 224.523L73.1707 230.125L67.5685 230.125L67.5685 224.523Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M226.054 194.516V200.118H231.656V194.516H226.054Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M226.732 149.258V143.655H232.334V149.258H226.732Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M139.115 164.576H139.302V164.39V158.601H144.904V170.179H127.351V164.576H139.115Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M52.2516 269.155L52.2516 269.341L52.4384 269.341L58.2273 269.341L58.2273 274.943L46.6494 274.943L46.6494 257.39L52.2516 257.39L52.2516 269.155Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M270.729 179.299H270.915V179.485V185.274H276.518V173.696H258.964V179.299H270.729Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M271.364 164.576H271.55V164.39V158.601H277.152V170.179H259.599V164.576H271.364Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M139.302 152.25V146.648H144.904V152.25H139.302Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M64.5782 269.343L70.1804 269.343L70.1804 274.945L64.5782 274.945L64.5782 269.343Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M270.876 191.641V197.243H276.478V191.641H270.876Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M271.55 152.25V146.648H277.152V152.25H271.55Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M30.425 53.8046V48.2024H36.0272V53.8046H30.425Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M118.489 47.8983V42.2961H124.092V47.8983H118.489Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M62.1379 53.8046V48.2024H67.7401V53.8046H62.1379Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M127.351 152.25V146.648H132.953V152.25H127.351Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M64.5782 257.39L70.1804 257.39L70.1804 262.992L64.5782 262.992L64.5782 257.39Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M258.925 191.609V197.212H264.527V191.609H258.925Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M259.599 152.25V146.648H265.201V152.25H259.599Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M106.538 47.8983V42.2961H112.141V47.8983H106.538Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M115.4 152.25V146.648H121.002V152.25H115.4Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M64.5782 245.437L70.1804 245.437L70.1804 251.039L64.5782 251.039L64.5782 245.437Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M246.976 191.578V197.18H252.578V191.578H246.976Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M247.648 152.25V146.648H253.25V152.25H247.648Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M94.5871 47.8983V42.2961H100.189V47.8983H94.5871Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M121.376 146.273V140.671H126.978V146.273H121.376Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M70.5528 251.413L76.1551 251.413L76.1551 257.016L70.5528 257.016L70.5528 251.413Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M252.935 197.57V203.173H258.537V197.57H252.935Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M253.622 146.273V140.671H259.225V146.273H253.622Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M100.564 41.9296V36.3274H106.166V41.9296H100.564Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M103.448 164.203V158.601H109.051V164.203H103.448Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M52.6251 233.484L58.2273 233.484L58.2273 239.086L52.6251 239.086L52.6251 233.484Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M235.054 179.594V185.196H240.656V179.594H235.054Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M235.697 164.203V158.601H241.299V164.203H235.697Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M49.6653 98.4686V92.8664H55.2675V98.4686H49.6653Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M118.359 179.702L123.962 179.702L123.962 185.305L118.359 185.305L118.359 179.702Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M181.099 245.188V250.79H186.701V245.188H181.099Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M181.915 98.4686V92.8664H187.517V98.4686H181.915Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M157.229 158.413V158.226H157.042H151.253V152.624H162.831V170.178H157.229V158.413Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M58.4169 287.264L58.6036 287.264L58.6036 287.077L58.6036 281.288L64.2058 281.288L64.2058 292.866L46.6522 292.866L46.6522 287.264L58.4169 287.264Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M288.834 185.516V185.703H288.648H282.859V191.305H294.437V173.752H288.834V185.516Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M289.479 158.413V158.226H289.292H283.503V152.624H295.081V170.178H289.479V158.413Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M151.253 146.273V140.671H156.855V146.273H151.253Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M70.5528 281.288L76.1551 281.288L76.1551 286.891L70.5528 286.891L70.5528 281.288Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M282.812 197.649V203.251H288.414V197.649H282.812Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M283.503 146.273V140.671H289.105V146.273H283.503Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M42.3762 47.828V42.2258H47.9784V47.828H42.3762Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M130.441 41.9296V36.3274H136.043V41.9296H130.441Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M74.0891 47.828V42.2258H79.6913V47.828H74.0891Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M157.042 128.724H157.229V128.537V116.773H162.831V134.326H151.253V128.724H157.042Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M88.106 287.077L88.106 287.264L88.2927 287.264L100.057 287.264L100.057 292.866L82.5038 292.866L82.5038 281.288L88.106 281.288L88.106 287.077Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M288.554 215.206H288.741V215.392V227.157H294.343V209.603H282.765V215.206H288.554Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M171.692 6.25868V6.07194H171.879L183.643 6.07194V0.469725L166.09 0.469725V12.0476H171.692V6.25868Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M289.292 128.724H289.479V128.537V116.773H295.081V134.326H283.503V128.724H289.292Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M5.97548 240.052H5.78874V239.865V228.101H0.186522V245.654H11.7644V240.052H5.97548Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M157.23 110.422V104.82H162.832V110.422H157.23Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M106.408 287.265L112.011 287.265L112.011 292.867L106.408 292.867L106.408 287.265Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M288.693 233.516V239.118H294.295V233.516H288.693Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M189.979 5.96951L195.581 5.96951V0.367295L189.979 0.367295V5.96951Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M289.476 110.422V104.82H295.078V110.422H289.476Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M5.78982 221.75V216.148H0.187607V221.75H5.78982Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M157.041 83.9055H157.228V83.7188V57.0149H162.83V98.4713H157.228V89.6945V89.5077H157.041H133.325V77.9298H144.903V83.7188V83.9055H145.09H157.041Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M132.925 287.082L132.925 287.269L133.111 287.269L159.815 287.269L159.815 292.871L118.359 292.871L118.359 287.269L127.136 287.269L127.322 287.269L127.322 287.082L127.322 263.366L138.9 263.366L138.9 274.944L133.111 274.944L132.925 274.944L132.925 275.131L132.925 287.082Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M288.428 260.001H288.615V260.187V286.891H294.217V245.435H288.615V254.212V254.399H288.428H264.712V265.976H276.29V260.187V260.001H276.477H288.428Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M216.565 5.97222V5.78547H216.752L243.456 5.78547V0.18326L201.999 0.18326V5.78547L210.776 5.78547H210.963V5.97222V29.6883H222.541V18.1103H216.752H216.565V17.9236V5.97222Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M289.291 83.9055H289.478V83.7188V57.0149H295.08V98.4713H289.478V89.6945V89.5077H289.291H265.575V77.9298H277.153V83.7188V83.9055H277.34H289.291Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M5.97612 195.226H5.78938V195.039V168.335H0.187166V209.792H5.78938V201.015V200.828H5.97612H29.6922V189.25H18.1143V195.039V195.226H17.9275H5.97612Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M112.037 125.545V125.732H112.224H118.013V137.31H100.459V125.545V125.358H100.273H94.4836V113.78H112.037V125.545Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M91.283 242.076L91.0962 242.076L91.0962 242.263L91.0962 248.052L79.5183 248.052L79.5183 230.498L91.283 230.498L91.4697 230.498L91.4697 230.312L91.4697 224.523L103.048 224.523L103.048 242.076L91.283 242.076Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M243.553 218.252V218.065H243.74H249.529V206.487H231.975V218.252V218.438H231.788H225.999V230.016H243.553V218.252Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M244.285 125.545V125.732H244.472H250.261V137.31H232.707V125.545V125.358H232.521H226.732V113.78H244.285V125.545Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M31.3651 128.536V128.723H31.5519H37.3408V134.325H25.7629V110.796H49.2922V116.585V116.772H49.479H61.2436V122.374H55.4547H55.2679V122.561V128.536V128.723H55.4547H61.2436V134.325H49.479H49.2922V134.512V140.301H43.69V122.561V122.374H43.5033H31.5519H31.3651V122.561V128.536Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M88.2956 161.406L88.1088 161.406L88.1088 161.593L88.1088 167.382L82.5066 167.382L82.5066 155.804L106.036 155.804L106.036 179.333L100.247 179.333L100.06 179.333L100.06 179.52L100.06 191.285L94.458 191.285L94.458 185.496L94.458 185.309L94.2713 185.309L88.2956 185.309L88.1088 185.309L88.1088 185.496L88.1088 191.285L82.5066 191.285L82.5066 179.52L82.5066 179.333L82.3199 179.333L76.5309 179.333L76.5309 173.731L94.2713 173.731L94.458 173.731L94.458 173.544L94.458 161.593L94.458 161.406L94.2713 161.406L88.2956 161.406Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M162.877 215.096V214.91H163.064H168.853V209.307H157.275V232.837H180.804V227.048V226.861H180.991H192.755V221.259H186.966H186.78V221.072V215.096V214.91H186.966H192.755V209.307H180.991H180.804V209.121V203.332H175.202V221.072V221.259H175.015H163.064H162.877V221.072V215.096Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M163.615 128.536V128.723H163.802H169.591V134.325H158.013V110.796H181.542V116.585V116.772H181.729H193.494V122.374H187.705H187.518V122.561V128.536V128.723H187.705H193.494V134.325H181.729H181.542V134.512V140.301H175.94V122.561V122.374H175.753H163.802H163.615V122.561V128.536Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M5.78896 152.815V152.628H5.9757H11.7647V147.026H0.186741V170.555H23.716V164.766V164.58H23.9028H35.6674V158.978H29.8785H29.6917V158.791V152.815V152.628H29.8785H35.6674V147.026H23.9028H23.716V146.839V141.05H18.1138V158.791V158.978H17.9271H5.9757H5.78896V158.791V152.815Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M138.929 116.585V116.772H139.115H150.88V122.374H145.091H144.904V122.561V134.325H139.115H138.929V134.512V140.301H133.326V122.561V122.374H133.14H127.351V110.796H138.929V116.585Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M100.247 268.968L100.06 268.968L100.06 269.155L100.06 280.919L94.458 280.919L94.458 275.13L94.458 274.943L94.2713 274.943L82.5066 274.943L82.5066 269.155L82.5066 268.968L82.3199 268.968L76.5309 268.968L76.5309 263.366L94.2713 263.366L94.458 263.366L94.458 263.179L94.458 257.39L106.036 257.39L106.036 268.968L100.247 268.968Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M270.44 227.298V227.111H270.627H282.392V221.509H276.603H276.416V221.322V209.557H270.627H270.44V209.371V203.582H264.838V221.322V221.509H264.651H258.863V233.087H270.44V227.298Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M183.94 24.376H183.754V24.1892V12.4246H178.151V18.2135V18.4003H177.965L166.2 18.4003V24.1892V24.376H166.013H160.224V29.9782L177.965 29.9782H178.151V30.1649V35.9539H189.729V24.376H183.94Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M271.177 116.585V116.772H271.364H283.128V122.374H277.339H277.152V122.561V134.325H271.364H271.177V134.512V140.301H265.575V122.561V122.374H265.388H259.599V110.796H271.177V116.585Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
        <path
          d='M24.0908 227.905V228.092H23.9041H12.1394V233.694H17.9284H18.1151V233.881V245.646H23.9041H24.0908V245.832V251.621H29.693V233.881V233.694H29.8798H35.6687V222.116H24.0908V227.905Z'
          fill='#C4C4C4'
          stroke='#C4C4C4'
          strokeWidth='0.373481'
        />
      </ContentLoader>
    </div>
  )
}
