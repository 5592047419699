import React from 'react'
import { Box, Typography } from '@mui/material'

import { ITaskLab } from '@models/tasks'
import { StyledPercentageCell, StyledTableSampleCell, SubText } from '@styles'

interface ApisPercentageProps {
  task: ITaskLab
  isMobile?: boolean
}

export const ApisPercentage: React.FC<ApisPercentageProps> = ({ task, isMobile }) => {
  const { main_apis: mainApis } = task

  return (
    <StyledTableSampleCell sx={{ display: 'flex' }} $isMobile={isMobile}>
      <Box>
        {mainApis.map(({ id, name, percentage }) => (
          <StyledPercentageCell key={id}>
            <SubText>{name}</SubText>
            <Typography variant='body1'>
              {(percentage && `${parseFloat(`${percentage}`)}%`) || '-'}
            </Typography>
          </StyledPercentageCell>
        ))}
      </Box>
    </StyledTableSampleCell>
  )
}
