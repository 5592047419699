import React from 'react'
import { useWatch } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { MAIN_APIS, TOXIC_NAME, TEMPERATURE_NAME } from '@constants/samples'
import { useAppSelector } from '@hooks/redux'
import { IOption } from '@models/common/app'
import { setCellValueToSample } from '@state/sampleCreate'
import { sampleCreateUIData } from '@state/common/sampleCreateUIState'
import { obtainingSkills } from '@helpers/obtainingSkills'
import { ReactComponent as TempSensitiveMobileIcon } from '@assets/icons/temp-sensitive-mobile.svg'
import { ReactComponent as ToxicMobileIcon } from '@assets/icons/toxic-mobile.svg'
import { ReactComponent as RegularPillIcon } from '@assets/icons/pill-icon.svg'

import { PureCellPropTypes } from './pureFields.types'
import { StyledTypeItem, StyledInnerTableCell } from './styles'

const SampleTypeIcon: React.FC<{ name: string }> = ({ name }) => {
  if (name === TOXIC_NAME) return <ToxicMobileIcon />
  else if (name === TEMPERATURE_NAME) return <TempSensitiveMobileIcon />
  else return null
}

export const SampleType: React.FC<PureCellPropTypes> = ({
  initialVal,
  cellName,
  control,
  sampleId,
  width,
  name,
}) => {
  const [types, setTypes] = React.useState<IOption[] | null>(initialVal.val as IOption[] | null)
  const dispatch = useDispatch()
  const { isToxicityAdded, isTemperatureAdded } = useAppSelector(sampleCreateUIData)
  const isShowAllCell = isToxicityAdded || isTemperatureAdded

  const [api] = useWatch({ control, name: [`${sampleId}_${MAIN_APIS}`] })

  React.useEffect(() => {
    if (api.length) {
      const allSkills = obtainingSkills(api)
      dispatch(
        setCellValueToSample({
          cellValue: { val: allSkills, error: '' },
          cellName,
          sampleId,
        })
      )
      setTypes(allSkills)
    } else {
      setTypes(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api])

  return (() => {
    // If APIs selected
    if (types) {
      if (types.length) {
        // If we have skills
        return (
          <StyledInnerTableCell id={name} style={{ minWidth: width }}>
            {types.map(item => (
              <StyledTypeItem key={item.id}>
                <SampleTypeIcon name={item.name} />
                {item.name}
              </StyledTypeItem>
            ))}
          </StyledInnerTableCell>
        )
      } else {
        // If we don't have skills
        return isShowAllCell ? (
          <StyledInnerTableCell style={{ minWidth: width }}>
            <RegularPillIcon />
            Regular
          </StyledInnerTableCell>
        ) : null
      }
    }
    // If APIs are not selected
    return isShowAllCell ? (
      <StyledInnerTableCell style={{ minWidth: width }}>-</StyledInnerTableCell>
    ) : null
  })()
}
