import { useTheme } from '@mui/material'

export const NoSearchedParamsIcon: React.FC = () => {
  const theme = useTheme()

  return (
    <svg
      width='237'
      height='236'
      viewBox='0 0 237 236'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle r='9.07692' transform='matrix(-1 0 0 1 227.423 48.409)' fill={theme.primary[20]} />
      <circle r='4.53846' transform='matrix(-1 0 0 1 14.1139 192.128)' fill={theme.primary[20]} />
      <circle r='4.53846' transform='matrix(-1 0 0 1 26.2194 52.9486)' fill={theme.primary[20]} />
      <circle r='4.53846' transform='matrix(-1 0 0 1 159.344 4.53846)' fill={theme.primary[20]} />
      <circle r='7.5641' transform='matrix(-1 0 0 1 35.2949 219.357)' fill={theme.primary[20]} />
      <circle r='5' transform='matrix(-1 0 0 1 24 14)' fill={theme.primary[20]} />
      <circle r='7.5' transform='matrix(-1 0 0 1 168 194.5)' fill={theme.primary[20]} />
      <g clip-path='url(#clip0_17177_515387)'>
        <path
          d='M79.7786 144.84C78.8435 143.915 78.8358 142.406 79.7619 141.471L90.3506 130.777C91.2764 129.841 92.7848 129.834 93.7199 130.76C94.655 131.686 94.6627 133.194 93.7366 134.129L83.1479 144.824C82.223 145.758 80.7146 145.766 79.7786 144.84Z'
          fill={theme.primary[20]}
        />
        <path
          d='M90.6155 133.93C67.0774 110.392 67.0774 72.0933 90.6152 48.5559C114.153 25.0179 152.452 25.0179 175.989 48.5556C199.527 72.0933 199.527 110.392 175.989 133.93C152.452 157.467 114.153 157.467 90.6155 133.93Z'
          fill={theme.palette.primary.main}
        />
        <path
          d='M96.9687 133.932C73.4306 110.393 73.4306 72.095 96.9684 48.5576C107.931 37.5946 122.095 31.7406 136.479 30.9891C119.979 30.1276 103.19 35.9819 90.6152 48.5573C67.0774 72.095 67.0774 110.393 90.6155 133.931C103.191 146.507 119.979 152.361 136.479 151.499C122.096 150.748 107.931 144.894 96.9687 133.932Z'
          fill='#338DEF'
        />
        <path
          d='M133.303 141.055C105.829 141.055 83.5566 118.783 83.5566 91.3087C83.5566 63.8347 105.829 41.5625 133.303 41.5625C160.777 41.5625 183.048 63.8347 183.048 91.3084C183.048 118.782 160.776 141.055 133.303 141.055Z'
          fill={theme.primary[30]}
        />
        <path
          d='M89.9095 91.3084C89.9095 64.9021 110.486 43.3047 136.479 41.6664C135.428 41.6002 134.37 41.5625 133.303 41.5625C105.829 41.5625 83.5566 63.8347 83.5566 91.3084C83.5566 119.897 107.65 142.768 136.479 140.951C110.486 139.312 89.9095 117.715 89.9095 91.3084Z'
          fill={theme.primary[70]}
        />
        <path
          d='M42.7451 187.332L37.3737 181.96C34.7765 179.363 34.7765 175.113 37.3737 172.516L72.632 137.257C74.074 135.815 76.4335 135.815 77.8755 137.257L87.1795 146.561C88.6215 148.003 88.6215 150.363 87.1795 151.805L51.6516 187.332C49.2025 189.781 45.1942 189.781 42.7451 187.332Z'
          fill={theme.palette.primary.main}
        />
        <path
          d='M57.5889 152.3L72.1367 166.848L78.8906 160.094L64.3428 145.546L57.5889 152.3Z'
          fill={theme.primary[70]}
        />
        <path
          d='M64.3438 145.547L57.5898 152.301L62.0811 156.792L68.835 150.038L64.3438 145.547Z'
          fill={theme.primary[70]}
        />
      </g>
      <g clip-path='url(#clip1_17177_515387)'>
        <path
          d='M178.496 15.6953H87.9778C85.5048 15.6953 83.5 17.6996 83.5 20.172V136.261C83.5 138.734 85.5048 140.738 87.9778 140.738H178.496C180.969 140.738 182.974 138.734 182.974 136.261V20.172C182.974 17.6996 180.969 15.6953 178.496 15.6953Z'
          fill={theme.primary[70]}
        />
        <path
          d='M177.244 128.284V23.4943C177.244 21.2344 175.155 19.4023 172.578 19.4023H93.8961C91.3191 19.4023 89.2295 21.2344 89.2295 23.4943V128.282C89.2295 130.542 91.3191 132.374 93.8961 132.374H172.578C175.155 132.374 177.244 130.542 177.244 128.282V128.284Z'
          fill={theme.primary[30]}
        />
        <path
          d='M104.686 65.1406H97.9932V71.0107H104.686V65.1406Z'
          fill='url(#paint0_linear_17177_515387)'
        />
        <path d='M104.686 73.418H97.9932V79.2881H104.686V73.418Z' fill='#E1E7F3' />
        <path d='M104.686 81.6992H97.9932V87.5693H104.686V81.6992Z' fill='#E1E7F3' />
        <path d='M104.686 89.9727H97.9932V95.8427H104.686V89.9727Z' fill='#E1E7F3' />
        <path d='M168.48 66.957H109.799V69.4309H168.48V66.957Z' fill='#E1E7F3' />
        <path d='M168.48 75.2344H109.799V77.7083H168.48V75.2344Z' fill='#E1E7F3' />
        <path d='M168.48 83.5156H109.799V85.9895H168.48V83.5156Z' fill='#E1E7F3' />
        <path d='M168.48 91.793H109.799V94.2669H168.48V91.793Z' fill='#E1E7F3' />
        <path d='M104.686 98.2539H97.9932V104.124H104.686V98.2539Z' fill='#E1E7F3' />
        <path d='M168.48 100.07H109.799V102.544H168.48V100.07Z' fill='#E1E7F3' />
        <path d='M127.439 111.48H97.9932V114.287H127.439V111.48Z' fill='#E1E7F3' />
        <path d='M145.165 118.566H97.9932V121.373H145.165V118.566Z' fill='#E1E7F3' />
        <path
          d='M155.921 11.9665H145.585V11.3588C145.585 7.84394 142.736 4.99609 139.221 4.99609H127.249C123.733 4.99609 120.885 7.84394 120.885 11.3588V11.9665H110.548C109.342 11.9665 108.363 12.9445 108.363 14.1509V23.8487C108.363 25.0551 109.342 26.0331 110.548 26.0331H155.921C157.128 26.0331 158.106 25.0551 158.106 23.8487V14.1509C158.106 12.9445 157.128 11.9665 155.921 11.9665ZM135.102 11.6932H131.367C130.197 11.6932 129.247 10.7439 129.247 9.57351C129.247 8.40309 130.197 7.4538 131.367 7.4538H135.102C136.273 7.4538 137.223 8.40309 137.223 9.57351C137.223 10.7439 136.273 11.6932 135.102 11.6932Z'
          fill='#66BAEF'
        />
        <path d='M168.479 37.5325V35.6016H131.047V37.5325H168.479Z' fill='#E1E7F3' />
        <path d='M168.479 43.9934V42.0625H131.047V43.9934H168.479Z' fill='#E1E7F3' />
        <path d='M168.479 50.4544V48.5234H131.047V50.4544H168.479Z' fill='#E1E7F3' />
        <path d='M168.479 56.9114V54.9805H131.047V56.9114H168.479Z' fill='#E1E7F3' />
        <path
          d='M124.808 46.2549C124.808 52.5583 120.512 57.8602 114.687 59.3902C114.567 59.4226 114.445 59.4531 114.322 59.4819C114.236 59.5017 114.15 59.5215 114.063 59.5394C113.486 59.6635 112.893 59.7498 112.29 59.7965C111.938 59.8235 111.582 59.8379 111.224 59.8379C110.409 59.8379 109.613 59.766 108.838 59.6293C108.045 59.4891 107.275 59.2805 106.536 59.0073C106.277 58.912 106.021 58.8095 105.77 58.698C105.599 58.6243 105.428 58.5452 105.261 58.4625C100.747 56.2547 97.6377 51.618 97.6377 46.2549C97.6377 38.7541 103.72 32.6719 111.224 32.6719C118.728 32.6719 124.81 38.7523 124.81 46.2549H124.808Z'
          fill='#D6F5FD'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_17177_515387'
          x1='62.0378'
          y1='68.0766'
          x2='181.58'
          y2='68.0766'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#B7C6ED' />
        </linearGradient>
        <clipPath id='clip0_17177_515387'>
          <rect
            width='158.266'
            height='158.266'
            fill='white'
            transform='translate(35.4014 30.9023)'
          />
        </clipPath>
        <clipPath id='clip1_17177_515387'>
          <path
            d='M83.5 5H182.974V91.0027C182.974 118.472 160.706 140.74 133.237 140.74C105.768 140.74 83.5 118.472 83.5 91.0027V5Z'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
