import React from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'
import { Box, Typography, useMediaQuery } from '@mui/material'

import { Button, ConfirmationModal, Input } from '@components/shared'
import { IApisWithPercentage } from '@models/common/app'
import { Image, ButtonBox, StyledLabel, StyledPageSubTitle, InfoMessage } from '@styles'
import { ASSAY_GROUP, TestGroupName } from '@models/tests'
import { useUpdateManualTest, useUpdateTest } from '@hooks/queries'
import infoIcon from '@assets/icons/info-circle-grey.svg'
import { useToggle } from '@hooks/common'
import { ITestButton } from '@models/tasks'
import { isVisualTestGroup } from '@helpers/ui/tests'

import { TestResultsModal } from '../TestResultsModal'
import { StepBody } from '../../styles'

interface BodyProps {
  taskId: number
  testGroupName: TestGroupName
  isTestStarted: boolean
  isResultsModalOpen: boolean
  taskApis: IApisWithPercentage[]
  toggleResultsModal: () => void
  currentStep: number
  serialNumberOfEquipment: string
  isShowCancelManualWayBtn?: boolean
  setIsTestStarted: React.Dispatch<React.SetStateAction<boolean>>
  resultBtnName?: ITestButton
}

export const Body: React.FC<BodyProps> = props => {
  const {
    isTestStarted,
    isResultsModalOpen,
    toggleResultsModal,
    taskId,
    currentStep,
    taskApis,
    serialNumberOfEquipment,
    isShowCancelManualWayBtn,
    setIsTestStarted,
    resultBtnName,
    testGroupName,
  } = props

  const [calibrationNotes, setCalibrationNotes] = React.useState(serialNumberOfEquipment)
  const [calibrationNotesError, setCalibrationNotesError] = React.useState('')
  const [isCancelModalOpen, toggleCancelModal] = useToggle()
  const isTestWithoutStartTestBtn = isVisualTestGroup(testGroupName)
  const isVisualTestStarted = currentStep === 1 && isTestWithoutStartTestBtn

  const debounced = useDebouncedCallback(() => {
    !calibrationNotesError &&
      updateTestM.mutate({ taskId, data: { serial_number_of_equipment: calibrationNotes } })
  }, 800)

  const calibrationNotesOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await setCalibrationNotes(e.target.value)
    debounced()
  }

  React.useEffect(() => {
    calibrationNotes.length > 100
      ? setCalibrationNotesError('Max 100 characters')
      : setCalibrationNotesError('')
  }, [calibrationNotes])

  const isMobile = useMediaQuery('(max-width:767px)')

  const updateTestM = useUpdateTest()
  const cancelManualWayM = useUpdateManualTest()

  const nextStepHandler = () => {
    if (isTestWithoutStartTestBtn) {
      setIsTestStarted(true)
      toggleResultsModal()
    }
    updateTestM.mutate({ taskId })
  }

  const finishTestHandler = () => {
    if (currentStep === 1 && !isTestWithoutStartTestBtn) nextStepHandler()
    if (!isResultsModalOpen) toggleResultsModal()
  }

  React.useEffect(() => {
    if (currentStep === 0 && testGroupName === ASSAY_GROUP) nextStepHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, testGroupName])

  const cancelManualWayHandler = () => {
    cancelManualWayM.mutate({ taskId })
    setIsTestStarted(false)
  }

  const actionText = (() => {
    if (isTestWithoutStartTestBtn || isTestStarted) return 'Add result'
    else return 'Start test'
  })()

  const actionTextDescription = (() => {
    if (isTestWithoutStartTestBtn) {
      return ' to select result of the test and attach results.'
    } else if (isTestStarted) {
      return ' to report the API content and test result.'
    } else return ' at the beginning of the procedure.'
  })()

  return (
    <>
      <StepBody className='test-body'>
        <StyledPageSubTitle variant='h3'>
          {isTestStarted || isVisualTestStarted ? 'Add result' : 'Start test'}
        </StyledPageSubTitle>
        <Typography variant='body1'>
          Please <b>{`click on the "${actionText}"`}</b> button
          {actionTextDescription}
        </Typography>

        {!isTestStarted && testGroupName === ASSAY_GROUP && (
          <Box
            sx={{ marginTop: '32px', position: 'relative', width: isMobile ? '300px' : '329px' }}
          >
            <Typography sx={{ position: 'absolute', right: '0', color: '#8F929B' }} variant='body1'>
              Optional
            </Typography>
            <StyledLabel
              mb='0'
              label='Serial number of equipment'
              control={
                <Input
                  value={calibrationNotes}
                  onChange={calibrationNotesOnChange}
                  variant='outlined'
                  placeholder='Please fill in the field'
                  width={isMobile ? '300px' : '329px'}
                  height={48}
                  helperText={calibrationNotesError}
                  error={Boolean(calibrationNotesError)}
                />
              }
            />
          </Box>
        )}

        <ButtonBox
          mb='15px'
          mt='40px'
          $isMobile={isMobile}
          $isCancelManualTestBtnHidden={!isShowCancelManualWayBtn}
        >
          <Button
            variant='contained'
            mb={isMobile ? '16px' : ''}
            width={isMobile ? '100%' : '205px'}
            height='48px'
            loading={updateTestM.isLoading}
            disabled={!!calibrationNotesError || updateTestM.isLoading}
            onClick={isTestStarted || isVisualTestStarted ? finishTestHandler : nextStepHandler}
          >
            {actionText}
          </Button>

          {isShowCancelManualWayBtn && (
            <Button
              variant='outlined'
              ml={isMobile ? '' : '20px'}
              width={isMobile ? '100%' : '205px'}
              height='48px'
              onClick={toggleCancelModal}
            >
              Cancel manual way
            </Button>
          )}
        </ButtonBox>

        {!isTestStarted && currentStep < 0 && (
          <InfoMessage>
            <Box width='205px'>
              <Image component='img' src={infoIcon} width='24px' height='24px' />
              <Typography variant='body2'>Test will start when you click on the button.</Typography>
            </Box>
          </InfoMessage>
        )}

        {isResultsModalOpen && (
          <TestResultsModal
            testGroupName={testGroupName}
            taskId={taskId}
            taskApis={taskApis}
            onClose={toggleResultsModal}
            resultBtnName={resultBtnName}
          />
        )}
      </StepBody>
      {isCancelModalOpen && (
        <ConfirmationModal
          isMobile={isMobile}
          title='Cancel manual way?'
          subtitle='All your progress will be reset and you will be able to test through the PAD reader app'
          isDisabled={updateTestM.isLoading}
          confirmButtonText='Cancel anyway'
          cancelBtnText='Keep testing'
          onClose={toggleCancelModal}
          onConfirm={cancelManualWayHandler}
          mb='80px'
        />
      )}
    </>
  )
}
