import { StringParam, useQueryParam } from 'use-query-params'
import { Box } from '@mui/material'

import { PageHeaderWrapper, FlexWrapper, PageTitle } from '@styles'
import { AutocompleteSearch } from '@components/shared/AutocompleteSearch'
import { DESIRED_PACKAGE } from '@constants/packages'
import { FilterAside } from '@components/shared'
import { DatePickerRangeFilter, LaboratoriesFilter } from '@components/shared/filters/allFilters'
import { PackageContentType } from '@models/packages'
import { useAppSelector } from '@hooks/redux'
import { filtersAside } from '@state/filters'
import { DeliveryStatusFilter } from '@components/shared/filters/allFilters/DeliveryStatusFilter'
import { LAST_UPDATES } from '@components/shared/FilterAside/constants'

interface PageHeaderProps {
  contentType: PackageContentType
}

export const PageHeader: React.FC<PageHeaderProps> = ({ contentType }) => {
  const [searchQueryString, setSearchQueryString] = useQueryParam(DESIRED_PACKAGE, StringParam)
  const [packageTypeQueryString] = useQueryParam('package_type', StringParam)
  const isFilterOpen = useAppSelector(filtersAside)

  return (
    <>
      <FlexWrapper>
        <PageTitle mb={5}>Package list</PageTitle>

        <PageHeaderWrapper>
          <AutocompleteSearch
            width='320px'
            placeholder='Enter package ID...'
            setQueryString={setSearchQueryString}
            queryString={searchQueryString}
            searchIn='package'
            type={packageTypeQueryString}
          />
        </PageHeaderWrapper>
      </FlexWrapper>

      {isFilterOpen.right && (
        <FilterAside>
          <Box>
            {/* list of filters here */}
            <LaboratoriesFilter contentType={contentType} />
            {contentType !== 'default' && <DeliveryStatusFilter />}
            <DatePickerRangeFilter type={LAST_UPDATES} />
          </Box>
        </FilterAside>
      )}
    </>
  )
}
