import React from 'react'
import { Box, Collapse, Typography } from '@mui/material'
import { v4 as uuid } from 'uuid'
import { debounce } from 'lodash'

import { ParagraphLink } from '@components/shared/ParagraphLink'
import { selectedFilters as filters } from '@state/filters'
import {
  ASSIGNEE_FILTER_TITLE,
  selectedFiltersSlice,
} from '@components/shared/FilterAside/constants'
import { useToggle, useCustomLazyScroll } from '@hooks/common'
import { useAppSelector } from '@hooks/redux'
import { useFetchAssigneeFilterWithTypeOfLab } from '@hooks/queries'
import { FilterLoader } from '@components/shared/FilterAside'

import { FilterTopTitle } from '../../components/FilterTopTitle'
import { CardRow, StyledShowMoreLinkBox } from '../styles'
import { SearchFilters } from '../../components/SearchFilters'
import { FilterSelectAll } from '../../components/FilterSelectAll'
import { ListOfAssignee } from './components/ListOfAssignee'

interface AssigneeFilterCentralLabProps {
  isCentralLab: boolean
}

export const AssigneeFilterCentralLab: React.FC<AssigneeFilterCentralLabProps> = React.memo(
  ({ isCentralLab }) => {
    const [isFilterClose, setIsFilterClose] = useToggle()
    const [desiredAssignee, setDesiredAssignee] = React.useState<string>()
    const [isShowMore, setIsShowMore] = React.useState<boolean>(true)
    const selectedFilters = useAppSelector(filters)

    const { data, fetchNextPage, hasNextPage, isLoading, isFetching } =
      useFetchAssigneeFilterWithTypeOfLab(desiredAssignee)

    const contentScrollBox = document.getElementById('content-scroll-box')
    const parentRef = React.useRef<HTMLElement | HTMLDivElement | null>(contentScrollBox)
    const scrollTriggerRef = React.useRef<HTMLDivElement | null>(null)
    const wrapRef = React.useRef<HTMLDivElement | null>(null)
    const showNumberOfItems = 5

    useCustomLazyScroll(parentRef, scrollTriggerRef, fetchNextPage, isShowMore, hasNextPage)

    React.useEffect(() => {
      if (isShowMore && wrapRef && wrapRef.current && contentScrollBox) {
        // number 63 - it's aside header height
        contentScrollBox.scrollTo({ top: wrapRef.current.offsetTop - 63 })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowMore])

    const listOfAssigneesWithTypeOfLab = data?.pages.map(page => page.results.assignees).flat()
    const filterCounter =
      selectedFilters[selectedFiltersSlice.assignees].filters.length || undefined

    const choiceDesiredAssignee = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement> | undefined) =>
        event && setDesiredAssignee(event.target.value),
      []
    )

    const debounceChoiceDesiredAssignee = debounce(choiceDesiredAssignee, 500)

    const modifyListItems = React.useMemo(() => {
      if (listOfAssigneesWithTypeOfLab) {
        // [show only first 5 items of list
        if (isShowMore) {
          const slicedList = listOfAssigneesWithTypeOfLab.slice(0, 1)

          const updatedList = slicedList.map(item => {
            let slicedItems = item

            if (item.laboratory_assistants.length > 5) {
              slicedItems = {
                ...item,
                inventory_managers: [],
                laboratory_assistants: item.laboratory_assistants.slice(0, showNumberOfItems),
              }

              return slicedItems
            }

            return slicedItems
          })

          return updatedList
        } else return listOfAssigneesWithTypeOfLab
        // [show only first 5 items of list]
      }
    }, [isShowMore, listOfAssigneesWithTypeOfLab])

    const assistants = listOfAssigneesWithTypeOfLab
      ?.map(a =>
        a?.laboratory_assistants.map(item => ({
          ...item,
          id: String(`${item.id}.2`),
        }))
      )
      .flat()

    return (
      <div ref={wrapRef}>
        <FilterTopTitle
          filterNumb={Number(assistants?.length)}
          isFilterClose={isFilterClose}
          setIsFilterClose={setIsFilterClose}
          titleOfFilter={ASSIGNEE_FILTER_TITLE}
          filterCounter={filterCounter}
          isLoading={isFetching || isLoading}
        />
        <Collapse in={!isFilterClose} unmountOnExit>
          <CardRow>
            <SearchFilters searchFiltersHandler={debounceChoiceDesiredAssignee} />
            <Box sx={{ width: '100%' }}>
              {(isFetching || isLoading) && isShowMore ? (
                <Box width='100%'>
                  <FilterLoader />
                </Box>
              ) : (
                <div style={{ paddingBottom: '12px' }}>
                  {assistants && !desiredAssignee && (
                    <FilterSelectAll
                      type='assignees'
                      items={assistants.map(({ last_name, first_name, id }) => ({
                        id,
                        name: `${first_name} ${last_name}`,
                      }))}
                    />
                  )}

                  {/* title for laboratory */}
                  {modifyListItems?.map(item => {
                    return (
                      <React.Fragment key={uuid()}>
                        <Typography ml='24px' mb='5px' mt='10px' fontSize='16px' fontWeight={500}>
                          {item?.laboratory}
                        </Typography>

                        {item?.laboratory_assistants &&
                          item?.laboratory_assistants.map(({ last_name, first_name, id }, idx) => {
                            const idString = String(`${id}.2`)

                            return (
                              <ListOfAssignee
                                key={idString}
                                idx={idx}
                                id={idString}
                                itemsLength={item.laboratory_assistants.length}
                                firstName={first_name}
                                lastName={last_name}
                              />
                            )
                          })}
                      </React.Fragment>
                    )
                  })}

                  {/* Show more button */}
                  {data && Number(assistants?.length) > 5 && (
                    <StyledShowMoreLinkBox>
                      <ParagraphLink
                        sx={{ padding: '11px 24px 12px' }}
                        onClick={() => setIsShowMore(!isShowMore)}
                      >
                        Show {isShowMore ? 'more' : 'less'}
                      </ParagraphLink>
                    </StyledShowMoreLinkBox>
                  )}
                </div>
              )}
            </Box>
            <div ref={scrollTriggerRef} />
          </CardRow>
        </Collapse>
      </div>
    )
  }
)
