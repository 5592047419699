import React from 'react'
import { useTheme } from '@mui/material'

import { Checkbox } from '@components/shared'
import { MAX_TASKS_TO_ADD } from '@constants/tasks'
import { PureTooltip } from '@components/ui/PureTooltip'

import { ElementWithIncreaseItsValueTypes } from './elementWithIncreaseItsValue.types'
import * as Styled from './styles'

export const ElementWithIncreaseItsValue: React.FC<ElementWithIncreaseItsValueTypes> = ({
  element,
  setValues,
  initialVal,
  isMaxValSet,
  endIcon,
  input,
  setAnotherTest,
  children,
}) => {
  const [number, setNumber] = React.useState<number>(initialVal || 1)
  const [open, setOpen] = React.useState<boolean>(false)
  const theme = useTheme()

  const handleOpen = () => {
    if (!input) {
      if (!open && !isMaxValSet) handleChangeCounter(initialVal || 1)
      else handleChangeCounter(0)
    } else if (!!input && element.groupId && !!setAnotherTest) {
      if (!open && !isMaxValSet) setAnotherTest(element.groupId)
      else setAnotherTest(element.groupId, true)
    }

    if (open) {
      setOpen(false)
    } else if (!open && !isMaxValSet) {
      setOpen(true)
    }
  }

  const handleChangeCounter = (newCount: number) => {
    setNumber(newCount)
    if (!!setValues) setValues({ id: element.id, methods: element.methodIds, count: newCount })
  }

  return (
    <Styled.Wrapper style={{ backgroundColor: open ? theme.primary[30] : '#fff' }}>
      {/* CHECKBOX and NAME */}
      <Styled.CheckboxNameWrap style={{ opacity: isMaxValSet && !open ? 0.7 : 1 }}>
        <PureTooltip
          title={`A maximum of ${MAX_TASKS_TO_ADD} tests can be added`}
          shouldBeHidden={open || (!open && !isMaxValSet)}
          titleStyle={{ width: 170 }}
        >
          <Checkbox
            onChange={handleOpen}
            disabled={!open && isMaxValSet}
            sx={{ marginRight: '6px' }}
          />
        </PureTooltip>
        <Styled.Name>
          <span style={{ marginRight: 4 }}>{element.name}</span>
          {endIcon}
        </Styled.Name>
      </Styled.CheckboxNameWrap>
      {open && (
        <>
          {/* COUNTER */}
          {!input && !children && (
            <Styled.CounterBox>
              <Styled.CounterBtn
                type='button'
                data-type='decrement'
                data-disable={`${number === 1}`}
                onClick={() => number > 1 && handleChangeCounter(number - 1)}
              />
              <Styled.CounterNumber>{number}</Styled.CounterNumber>
              <PureTooltip
                title={`A maximum of ${MAX_TASKS_TO_ADD} tests can be added`}
                shouldBeHidden={!isMaxValSet}
                titleStyle={{ width: 170 }}
                display='flex'
              >
                <Styled.CounterBtn
                  type='button'
                  data-type='increment'
                  data-disable={isMaxValSet}
                  onClick={() => !isMaxValSet && handleChangeCounter(number + 1)}
                />
              </PureTooltip>
            </Styled.CounterBox>
          )}
          {/* INPUT */}
          {input}
          {children}
        </>
      )}
    </Styled.Wrapper>
  )
}
