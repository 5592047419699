import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { generatePackageQr } from '@api/packages'
import { GENERATE_PACKAGE_QR } from '@constants/packages'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useGeneratePackageQr = (packageId: string) => {
  const dispatch = useDispatch()
  return useQuery(
    [GENERATE_PACKAGE_QR, packageId],
    () => generatePackageQr({ packageId, downloadQrCode: false }),
    {
      onError: (error: IError) => {
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
      },
      retry: 1,
      retryDelay: 1500,
    }
  )
}
