import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { EditTestResultsModal } from '@components/modules/Modals'
import {
  ATTACHMENTS,
  ATTACHMENT_EQUIPMENT_NUMBER_SECTION,
  RESULT_RECOGNIZED,
  SUBSTANCES,
  ATTACHMENT_TEST_INFO_SECTION,
  TEST_NOTES,
  TEST_PREPARATION,
  DELETED_RECOGNIZED,
} from '@constants/tests'
import { UpdateTandemResultTypes } from '@models/tasks'
import { useUpdateTask } from '@hooks/queries/useUpdateTask'
import { TASKS } from '@constants/queries'
import { COMPLETED } from '@constants/tasks'
import { setTestUpdatingId } from '@state/test'
import { removeTrailingZeros } from '@helpers/removeTrailingZeros'

import { EquipmentNumbers } from './EquipmentNumbers'
import { EditingExitModal } from './EditingExitModal'
import { TandemMSEditResultsPropTypes } from './tandemMSEditResults.types'
import { StyledSectionsBox } from './styles'
import { TestInfo } from './TestInfo'
import { tandemMsData2Send } from './helpers'

export const TandemMSEditResults: React.FC<TandemMSEditResultsPropTypes> = props => {
  const { onClose, task, isEditFromCompletedTable } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const client = useQueryClient()
  const updateTaskM = useUpdateTask()
  const dispatch = useDispatch()

  const initialAttachmentsEquipmentNumber = task.attachments
    .filter(item => item.attachment_section.key === ATTACHMENT_EQUIPMENT_NUMBER_SECTION)
    .map(attachment => attachment.id)

  const initialAttachmentsTestInfo = task.attachments
    .filter(item => item.attachment_section.key === ATTACHMENT_TEST_INFO_SECTION)
    .map(attachment => attachment.id)

  const substanceResult = task?.substance_result?.map(s => {
    return { ...s, concentration: removeTrailingZeros(s.concentration as string), itemId: s.id }
  })

  const initialFieldsData = {
    task,
    fields: {
      [ATTACHMENTS]: [],
      [ATTACHMENT_EQUIPMENT_NUMBER_SECTION]: initialAttachmentsEquipmentNumber || [],
      [ATTACHMENT_TEST_INFO_SECTION]: initialAttachmentsTestInfo || [],
      [TEST_NOTES]: task.test_notes || '',
      [TEST_PREPARATION]: task.preparation || '',
      [SUBSTANCES]: substanceResult,
      [RESULT_RECOGNIZED]: task.result_is_not_recognized,
      [DELETED_RECOGNIZED]: [],
    } as UpdateTandemResultTypes, // required "initialFieldsData" key
  }

  const handleSave = ({ fields }: { fields: UpdateTandemResultTypes }) => {
    const data = tandemMsData2Send(fields, task.substance_result)

    updateTaskM.mutate({ taskId: task.id, fields: data })
  }

  React.useEffect(() => {
    if (updateTaskM.isSuccess) {
      onClose()
      updateTaskM.reset()
      if (isEditFromCompletedTable) {
        dispatch(setTestUpdatingId(task.id))
        client.invalidateQueries(COMPLETED)
      } else client.invalidateQueries([TASKS, task.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTaskM])

  return (
    <EditTestResultsModal
      onClose={onClose}
      isCloseFromSomeComponentInside
      initialFieldsData={initialFieldsData}
      modalTitle='Edit results'
      modalSubtitle='You can edit test results here.'
      handleAction={handleSave}
      isLoading={updateTaskM.isLoading}
    >
      <StyledSectionsBox $isMobile={isMobile}>
        <TestInfo />
        <EquipmentNumbers />
        <EditingExitModal closeEditResultsModal={onClose} />
      </StyledSectionsBox>
    </EditTestResultsModal>
  )
}
