import React from 'react'

import { viewReportsTable } from '@constants/mappings'
import { FixedTableCell, StyledTableTitle } from '@styles'

import { TableColumnTitle } from '../components'

interface TableHeadTitlesProps {
  hideBorder: boolean
}

export const TableHeadTitles = React.memo<TableHeadTitlesProps>(({ hideBorder }) => {
  return (
    <>
      {viewReportsTable.tableHeadCells.map(({ id, ...props }) => (
        <TableColumnTitle key={id} {...props} hideBorder={hideBorder} />
      ))}

      <FixedTableCell
        hideBorder={hideBorder}
        sx={{
          borderTopLeftRadius: '10px',
          boxShadow: '-7px -4px 20px -5px rgba(53, 108, 151, 0.12)',
        }}
      >
        <StyledTableTitle>Action</StyledTableTitle>
      </FixedTableCell>
    </>
  )
})
