import React from 'react'
import { Box } from '@mui/material'

import { TasksStep as Step } from '@components/shared'
import { StyledModalTitle, Image } from '@styles'
import { PAD } from '@models/tests'
import { IPaddAppLogIn } from '@models/tasks'

import { ImageBox, Container } from '../styles'
import { LoaderQrImg } from './LoaderQrImg'

interface DesktopModalProps {
  padApp?: IPaddAppLogIn
  toggleModal: () => void
  isLoading: boolean
}

export const DesktopModal: React.FC<DesktopModalProps> = ({ padApp, toggleModal, isLoading }) => {
  return (
    <Container>
      {isLoading && (
        <Box textAlign='center'>
          <LoaderQrImg />
        </Box>
      )}
      {!isLoading && padApp?.image && (
        <ImageBox>
          <Box>
            <Image
              width='295px'
              height='295px'
              sx={{ objectFit: 'fill' }}
              src={`data:image/rgb;base64, ${padApp.image}`}
              component='img'
            />
          </Box>
        </ImageBox>
      )}

      <StyledModalTitle variant='h3'>Scan QR-code</StyledModalTitle>
      <Box width='295px' ml='auto' mr='auto'>
        <Box mb='18px'>
          <Step
            idx={0}
            stepCounter={1}
            stepColor={PAD}
            descriptionText='Open PADreader app on your phone'
          />
        </Box>

        <Box>
          <Step
            idx={1}
            stepCounter={2}
            stepColor={PAD}
            descriptionText='Scan this image to Log in to the'
            descriptionSubText='PADreader app'
          />
        </Box>
      </Box>
    </Container>
  )
}
