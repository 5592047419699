import React, { useState } from 'react'
import { Autocomplete, AutocompleteChangeReason } from '@mui/material'
import { RegisterOptions, useController } from 'react-hook-form'

import { IOption } from '@models/common/app'
import { AutocompleteWrapper, Input } from '@components/shared'
import { selectedFundingList } from '@state/app'
import { PureChip } from '@components/ui'
import { useAppSelector } from '@hooks/redux'
import { IFunding } from '@models/projects'

import { StyledBox, StyledStack } from './styles'

interface FundingProps {
  name: string
  isCopy?: boolean
  isOptional?: boolean
  sample?: any
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}

export const Funding: React.FC<FundingProps> = React.memo(props => {
  const { name, isCopy, rules, isOptional } = props

  const selectedFundingListFromProject = useAppSelector(selectedFundingList)

  const [itemsWasTouched, setItemsWasTouched] = React.useState(false)
  const [search, setSearch] = React.useState<string>('')

  const { field, fieldState } = useController({ name, rules, defaultValue: [] })

  const [funding, setFunding] = useState(field.value || [])

  const { isTouched, error } = fieldState
  const isHighlighting = isCopy && !isTouched && !itemsWasTouched

  const options = React.useMemo(() => {
    return selectedFundingListFromProject?.filter(
      (fund: IFunding) => fund.value.indexOf(search) !== -1
    )
  }, [search, selectedFundingListFromProject])

  React.useEffect(() => {
    if (funding?.length > 0 && selectedFundingListFromProject?.length > 0) {
      if (
        selectedFundingListFromProject?.map((item: any) => item.id).indexOf(funding[0].id) === -1
      ) {
        setFunding([])

        field.onChange({ target: { value: [] } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFundingListFromProject])

  React.useEffect(() => {
    if (funding?.length > 0 && !selectedFundingListFromProject) {
      setFunding([])

      field.onChange({ target: { value: [] } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFundingListFromProject])

  const onChangeHandler = (e: any, value: IOption[] | null, reason: AutocompleteChangeReason) => {
    if (reason === 'clear') {
      const newValue = [...(funding as Array<any>)]
      newValue.pop()
      field.onChange({ target: { value: newValue } })
      setFunding(newValue)
    } else {
      field.onChange({ target: { value: value } })
      setFunding(value)
    }
  }

  const deleteApiHandler = (id: number) => {
    setItemsWasTouched(true)
    field.onChange({
      target: { value: funding.filter(({ id: fundingId }: any) => fundingId !== id) },
    })

    setFunding(funding.filter(({ id: fundingId }: any) => fundingId !== id))
  }

  const onInputChangeHandler = (e: any, v: string) => {
    setSearch(v)
  }

  return (
    <StyledBox>
      <AutocompleteWrapper height={42} bgColor={isHighlighting ? '#FFF5D6' : '#fff'}>
        <Autocomplete
          disableClearable
          disabled={!selectedFundingListFromProject || selectedFundingListFromProject?.length === 0}
          multiple
          fullWidth
          size='small'
          options={options || []}
          filterSelectedOptions
          noOptionsText='Nothing found'
          getOptionLabel={option => option.value}
          getOptionDisabled={!isOptional ? () => funding.length === 5 : undefined}
          onInputChange={onInputChangeHandler}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderTags={value => <span>{value[value.length - 1].name}</span>}
          renderInput={params => (
            <Input
              variant='outlined'
              placeholder={!funding.length ? 'Search here...' : ''}
              error={!!error}
              helperText={error && error.message}
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          {...field}
          onChange={onChangeHandler}
        />
      </AutocompleteWrapper>

      <StyledStack spacing={0.8}>
        {funding.map((fund: any) => (
          <PureChip
            tooltipWidth={'calc(100% - 38px)'}
            truncatedLabelLength={23}
            key={fund.id}
            isMainChip
            label={fund.value}
            onDelete={() => deleteApiHandler(fund.id)}
          />
        ))}
      </StyledStack>
    </StyledBox>
  )
})
