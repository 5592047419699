import React from 'react'
import { Box, useMediaQuery } from '@mui/material'

// import sortIcon from '@assets/icons/sort-icon.svg'
import { StyledFlexBox } from '@styles'
import { useAppSelector } from '@hooks/redux'
import { selectedFilters as filters, selectedFiltersLength } from '@state/filters'
import {
  ClearAllFilters,
  FilterAsideButton,
  SetParamsOfFilter,
  ViewSelectedFilters,
} from '@components/shared/FilterAside'
import { ITasksTabValue } from '@models/tasks'
import {
  APIS,
  EXCIPIENTS,
  DRUG_CLASSES,
  MANUFACTURERS,
  TESTING_DATE,
} from '@components/shared/FilterAside/constants'

import { StyledViewBox } from './styles'

interface TasksFiltersMobileProps {
  contentType: ITasksTabValue
  isPageHasSavedFilters: boolean
}

export const TasksFiltersMobile: React.FC<TasksFiltersMobileProps> = props => {
  const { isPageHasSavedFilters } = props

  const isMobile = useMediaQuery('(max-width:767px)')
  const {
    appliedApisSelectedFilters,
    appliedExcipientsSelectedFilters,
    appliedDrugClassesSelectedFilters,
    appliedManufacturersSelectedFilters,
    appliedTestingDateSelectedFilters,
  } = useAppSelector(filters)
  const allFiltersLength = useAppSelector(selectedFiltersLength)
  const isShouldShowClearAll = Boolean(allFiltersLength)

  return (
    <>
      <SetParamsOfFilter
        isPageHasSavedFilters={isPageHasSavedFilters}
        appliedApisSelectedFilters={appliedApisSelectedFilters}
        appliedExcipientsSelectedFilters={appliedExcipientsSelectedFilters}
        appliedDrugClassesSelectedFilters={appliedDrugClassesSelectedFilters}
        appliedManufacturersSelectedFilters={appliedManufacturersSelectedFilters}
        appliedTestingDateSelectedFilters={appliedTestingDateSelectedFilters}
      />

      <StyledFlexBox
        $isMobile
        $isFilterView
        $sticky
        $top='142px'
        $padding={isMobile ? '3px 0 23px 0' : ''}
      >
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center'>
            <FilterAsideButton />
          </Box>

          {/* will be implemented later */}
          {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton disabled>
              <img src={sortIcon} alt='filter' />
            </IconButton>
            <Typography fontWeight={500}>Sort by</Typography>
          </Box> */}
        </Box>

        <StyledViewBox>
          {!!appliedApisSelectedFilters.length && <ViewSelectedFilters type={APIS} />}

          {!!appliedExcipientsSelectedFilters.length && <ViewSelectedFilters type={EXCIPIENTS} />}

          {!!appliedDrugClassesSelectedFilters.length && (
            <ViewSelectedFilters type={DRUG_CLASSES} />
          )}

          {!!appliedManufacturersSelectedFilters.length && (
            <ViewSelectedFilters type={MANUFACTURERS} />
          )}

          {!!appliedTestingDateSelectedFilters.length && (
            <ViewSelectedFilters type={TESTING_DATE} />
          )}

          {isShouldShowClearAll && <ClearAllFilters />}
        </StyledViewBox>
      </StyledFlexBox>
    </>
  )
}
