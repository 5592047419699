import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'

import { generateLabels } from '@api/common'
import { IError } from '@models/common/app'
import { setMaintenanceModePage } from '@state/user'

export const useGenerateLabels = () => {
  const dispatch = useDispatch()
  const mutation = useMutation(generateLabels, {
    onSuccess: (data, { entity }) => {
      const labelsBlob = new Blob([data], { type: 'application/pdf ' })
      const labelsLink = URL.createObjectURL(labelsBlob)
      const link = document.createElement('a')
      const fileName = `${entity.slice(0, entity.length - 1)}_labels.pdf`

      link.href = labelsLink
      link.setAttribute('download', fileName)
      document.body.appendChild(link)

      link.click()

      URL.revokeObjectURL(link.href)
      link.parentNode?.removeChild(link)
    },
    onError: (error: IError) => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    retry: 1,
    retryDelay: 1500,
  })

  return mutation
}
