import { useQuery } from 'react-query'

import { SKILLS } from '@constants/queries'
import { fetchSkills } from '@api/common'
import { IError, ISkillsData } from '@models/common/app'
import { useAppDispatch } from '@hooks/redux'
import { setMaintenanceModePage } from '@state/user'

export const useFetchSkills = (isAuthorized: boolean) => {
  const dispatch = useAppDispatch()

  return useQuery<ISkillsData, IError>(SKILLS, fetchSkills, {
    initialData: { count: 0, results: [] },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    enabled: isAuthorized,
  })
}
