import { Tenant } from '@models/common/app'
import { http } from '@services/http'

export const fetchTenant = () => {
  try {
    return http.get<Tenant>(`/tenants/tenant/`)
  } catch (error) {
    return Promise.reject(error)
  }
}
