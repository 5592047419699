import { styled, Table } from '@mui/material'

export const StyledSubSampleBox = styled('div')`
  margin-top: 32px;
`

export const StyledTable = styled(Table)`
  text-align: left;
  padding-left: 80px;
`
