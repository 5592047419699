import {
  Box,
  ListItemText,
  MenuItem,
  Paper,
  styled,
  BoxProps,
  MenuItemProps,
  MenuListProps,
  MenuList,
  Typography,
} from '@mui/material'
import React from 'react'

interface IPoperProps extends BoxProps {
  $isMobile?: boolean
}
interface IStyleMenuItemProps extends MenuItemProps {
  $isMobile?: boolean
}
interface IStyledMenuListProps extends MenuListProps {
  $isMobile?: boolean
}

export const Popper = styled<React.FC<IPoperProps>>(Box)`
  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (min-width: 768px) {
    &::before {
      content: '';
      position: absolute;
      right: 0;
      height: 20px;
      min-width: 256px;
      display: none;
    }
  }
`

export const StyledMenu = styled(Paper)`
  position: absolute;
  top: 76px;
  right: 0;
  min-width: 256px;
  border: 1.5px solid #d9dde4;
  box-sizing: border-box;
  box-shadow: -6px 0 20px rgba(103, 122, 170, 0.2);
  border-radius: 4px;
  z-index: 10000;

  &.mobile-variant {
    right: 0;
  }

  @media (min-width: 768px) {
    display: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -6px;
      right: 33px;
      width: 9px;
      height: 9px;
      border-left: 1px solid #d9dde4;
      border-top: 1px solid #d9dde4;
      transform: rotate(45deg);
      background-color: #ffffff;
    }
  }
  @media (max-width: 767px) {
    min-width: 375px;
    top: 70px;
    right: -375px;
    border: none;
    border-radius: 0;
    transition: right 0.2s ease-out;
  }
  @media (max-width: 479px) {
    min-width: 100vw;
    right: -100vw;
  }
`

export const StyledMenuList = styled<React.FC<IStyledMenuListProps>>(MenuList)`
  padding: 0;
  min-height: ${({ $isMobile }) => ($isMobile ? 'calc(100vh - 70px)' : 'auto')};
`

export const StyledMenuItem = styled<React.FC<IStyleMenuItemProps>>(MenuItem)`
  position: relative;
  padding: 10px 16px;

  @media (max-width: 767px) {
    padding: 16px;
  }

  &:last-child {
    @media (max-width: 767px) {
      position: sticky;
      top: 100%;
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid #e5e9f1;

      @media (max-width: 767px) {
        left: 16px;
        width: calc(100% - 16px * 2);
      }
    }
  }

  &:hover {
    background-color: ${({ theme, $isMobile }) => ($isMobile ? '' : theme.palette.primary.main)};
    color: #ffffff;

    .menu-icon {
      fill: #ffffff;
    }

    .round-icon {
      path {
        stroke: #ffffff;
      }
    }

    .round-icon.filled {
      path {
        fill: #ffffff;
        stroke: #ffffff;
      }
    }
  }
`

export const StyledListItemText = styled(ListItemText)`
  & span {
    font-size: 14px;
    font-weight: 500;
  }
`

export const StyledAvatarHolder = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: center;
`

export const StyledName = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #0f1934;
`
