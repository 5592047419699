import React from 'react'
import { Collapse, useMediaQuery } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'

import { useAppSelector } from '@hooks/redux'
import { selectUser } from '@state/user'
import { ASSAY_GROUP, IStep, MINILAB } from '@models/tests'
import { COMPLETED, NOT_COMPLETED } from '@constants/tasks'
import { IAttachmentList, IAttachments } from '@models/attachments'
import { useFetchAttachments } from '@hooks/queries'
import { findLostLoadingAttachment } from '@helpers/findLostLoadingAttachment'
import {
  ATTACHMENT_EQUIPMENT_NUMBER_TITLE,
  ATTACHMENT_TEST_MINILAB_TITLE,
  ATTACHMENT_TEST_REPORT_TITLE,
  ATTACHMENT_PHOTO_OF_SAMPLE_TITLE,
  RESULTS_STEP_TITLE,
} from '@constants/tests'
import { ITaskLab } from '@models/tasks'

import { CompletedStep } from '../CompletedStep'
import { Body, FinishTest, Header } from './components'
import { StepContainerExpandedNoSidebar } from '../Testing/styles'

export const Attachments: React.FC<IStep<ITaskLab>> = props => {
  const { isOpen, shouldRender, actualStep, task } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const formMethods = useForm()

  const { first_name: firstName, last_name: lastName } = useAppSelector(selectUser)
  const currentUser = task.task_notes.find(
    note => note.user.first_name === firstName && note.user.last_name === lastName
  )

  const fetchAttachmentsQ = useFetchAttachments({
    content_type: 'task',
    object_id: task.id,
  })

  const [isStepCompleted, setIsStepCompleted] = React.useState(!isOpen)
  const [attachments, setAttachments] = React.useState<IAttachments>([])
  const isAssayGroup = task.test_type.group.name === ASSAY_GROUP

  const [attachmentList, setAttachmentList] = React.useState<IAttachmentList>([
    { title: ATTACHMENT_TEST_REPORT_TITLE, counter: 1, section: 'test_report' },
    { title: ATTACHMENT_PHOTO_OF_SAMPLE_TITLE, counter: 2, section: 'photo_of_sample' },
  ])

  React.useEffect(() => {
    if (fetchAttachmentsQ.data) {
      setAttachments(findLostLoadingAttachment(fetchAttachmentsQ.data, attachments))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAttachmentsQ.data])

  React.useEffect(() => {
    setIsStepCompleted(!isOpen)
  }, [isOpen])

  const isAttachmentsLoading = attachments.some(item => item.loading)

  React.useEffect(() => {
    if (isAssayGroup) {
      setAttachmentList(prev => [
        ...prev,
        {
          title: ATTACHMENT_EQUIPMENT_NUMBER_TITLE,
          counter: 3,
          section: 'equipment_number',
        },
      ])
    } else if (task.test_type.name === MINILAB) {
      setAttachmentList([
        { title: 'Test report', counter: 1, section: 'test_report' },
        {
          title: ATTACHMENT_TEST_MINILAB_TITLE,
          counter: 2,
          section: 'photo_of_sample',
        },
      ])
    }
  }, [isAssayGroup, task.test_type.name])

  return shouldRender ? (
    <>
      {!isMobile && (
        <CompletedStep
          status={task.step === 4 ? COMPLETED : NOT_COMPLETED}
          counter={actualStep}
          title={RESULTS_STEP_TITLE}
          open={isStepCompleted}
        />
      )}

      <Collapse in={!isStepCompleted} unmountOnExit>
        <StepContainerExpandedNoSidebar>
          <Header actualStep={actualStep} />
          <FormProvider {...formMethods}>
            <Body
              taskId={task.id}
              attachments={attachments}
              setAttachments={setAttachments}
              attachmentList={attachmentList}
              note={currentUser?.read_only ? '' : currentUser?.notes}
              testGroupName={task.test_type.group.name}
            />
            <FinishTest taskId={task.id} loading={isAttachmentsLoading} />
          </FormProvider>
        </StepContainerExpandedNoSidebar>
      </Collapse>
    </>
  ) : null
}
