import React from 'react'
import { useFormContext } from 'react-hook-form'

import { PureRadioButtons } from '@components/ui'
import { TEST_RESULT } from '@constants/tests'

import { StyledTitle, StyledResultsBox } from '../styles'

export const ResultsOfTest = () => {
  const { getValues, setValue } = useFormContext()
  const { task } = getValues()

  const radiosData = React.useMemo(() => {
    const correctData: { id: string; label: string }[] = []
    for (let key in task.available_result) {
      correctData.push({ id: key, label: task.available_result[key] })
    }
    return correctData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledResultsBox $mb={16} $order={0}>
      <StyledTitle>
        Please select result of test <span>(Required)</span>
      </StyledTitle>
      <PureRadioButtons
        initialValue={task.result.key}
        data={radiosData}
        name={TEST_RESULT}
        onChange={(value: string) => setValue(`fields.${TEST_RESULT}`, value)}
        direction='column'
      />
    </StyledResultsBox>
  )
}
