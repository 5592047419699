import { StringParam, useQueryParams } from 'use-query-params'
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { useDispatch } from 'react-redux'

import { IError } from '@models/common/app'
import { IRequestSamplesData } from '@models/requests'
import { fetchRequestSamples } from '@api/requests'
import { REQUEST_SAMPLES } from '@constants/queries'
import { useAppSelector } from '@hooks/redux'
import { selectUserLaboratory, setMaintenanceModePage } from '@state/user'
import { filterStringParamsQueries } from '@components/shared/FilterAside/constants'

export const useFetchRequestSamples = (): UseInfiniteQueryResult<IRequestSamplesData> => {
  const stringParams = {
    ordering: StringParam,
    view_type: StringParam,
    desired_request: StringParam,
    ...filterStringParamsQueries,
  }

  const [queryString] = useQueryParams(stringParams)
  const {
    group: { receiver_of_test_requests },
  } = useAppSelector(selectUserLaboratory)
  const dispatch = useDispatch()

  const requestType = REQUEST_SAMPLES

  const {
    desired_request,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    expirationDate,
    laboratories,
  } = queryString
  const queryKey = [
    requestType,
    desired_request,
    apis,
    excipients,
    drug_classes,
    manufacturers,
    expirationDate,
    laboratories,
  ]

  return useInfiniteQuery<IRequestSamplesData, IError>(queryKey, fetchRequestSamples, {
    getNextPageParam: lastPage => {
      const { next: nextPageUrl } = lastPage.links

      if (nextPageUrl) {
        const queryString = nextPageUrl.slice(nextPageUrl.indexOf('?'))
        const urlParams = new URLSearchParams(queryString)
        const nextPage = urlParams.get('page')

        return nextPage
      }
    },
    onError: error => {
      if (error.status === 503) dispatch(setMaintenanceModePage(true))
    },
    enabled: receiver_of_test_requests,
  })
}
