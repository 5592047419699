import { IUpdatedHrTestData } from '@models/tests'

export const finishTandemMSTestHelper = (
  tandemMSData2Send?: IUpdatedHrTestData | null,
  isResultNotRecognized?: boolean
) => {
  const data: IUpdatedHrTestData = {
    status: 'completed',
  }

  if (tandemMSData2Send) {
    let jsonObject

    if (tandemMSData2Send.json_notes && !isResultNotRecognized)
      jsonObject = JSON.parse(tandemMSData2Send.json_notes)
    if (tandemMSData2Send.test_notes && !isResultNotRecognized)
      data.test_notes = tandemMSData2Send.test_notes
    if (isResultNotRecognized) {
      data.result_is_not_recognized = true
    } else data.json_notes = jsonObject
  }
  return data
}
