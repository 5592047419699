import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ExportSamplesState {
  isSamplesListLoaded: boolean
}

const initialState: ExportSamplesState = {
  isSamplesListLoaded: false,
}

export const exportSamplesSlice = createSlice({
  name: 'export samples',
  initialState,
  reducers: {
    setIsSamplesListLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.isSamplesListLoaded = payload
    },
  },
})

export const {
  reducer: exportSamplesReducer,
  actions: { setIsSamplesListLoaded },
} = exportSamplesSlice
