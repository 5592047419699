import React from 'react'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'
import { StringParam, useQueryParam } from 'use-query-params'

import { StyledTableSortWrapper } from '@styles'

interface CellsOrderingProps {
  ordering: string
  padding?: string
}

export const CellsOrdering: React.FC<CellsOrderingProps> = ({ ordering, padding }) => {
  //sorting has three states: off, ascending and descending order
  const options = React.useMemo(() => [undefined, ordering, `-${ordering}`], [ordering])
  const [index, setIndex] = React.useState(0)
  const [queryString, setQueryString] = useQueryParam('ordering', StringParam)

  React.useEffect(() => {
    if (ordering) {
      const optionIndex = options.findIndex(option => option === queryString)

      setIndex(optionIndex !== -1 ? optionIndex : 0)
    }
  }, [options, ordering, queryString])

  const onClickHandler = () => {
    if (!ordering) {
      return
    }

    let nextIndex = index + 1
    if (nextIndex === options.length) {
      nextIndex = 0
    }
    setQueryString(options[nextIndex])
  }

  return (
    <StyledTableSortWrapper onClick={onClickHandler} style={{ marginTop: '-2px', padding }}>
      <IoMdArrowDropup color={index === 2 ? '#000000' : '#ADB4C2'} />
      <IoMdArrowDropdown color={index === 1 ? '#000000' : '#ADB4C2'} />
    </StyledTableSortWrapper>
  )
}
