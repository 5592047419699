import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { setMaintenanceModePage } from '@state/user'
import { confirmEmail } from '@api/user'
import { IError } from '@models/common/app'
import { useAppDispatch } from '@hooks/redux'
import { IConfirmationEmailData } from '@models/common/user'
import { VERIFICATION_ERROR, VERIFICATION_SUCCESS } from '@constants/common'
import { publicRoutes } from '@configs/routes.config'

export const useConfirmEmail = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const mutation = useMutation<unknown, IError, IConfirmationEmailData>(
    confirmationData => confirmEmail(confirmationData),
    {
      onSuccess: () => localStorage.setItem(VERIFICATION_SUCCESS, 'true'),
      onError: error => {
        localStorage.setItem(VERIFICATION_ERROR, 'true')
        if (error.status === 503) dispatch(setMaintenanceModePage(true))
      },
      onSettled: () => {
        history.push(publicRoutes.login.path)
      },
    }
  )

  return mutation
}
