import { styled } from '@mui/material'

export const customScrollBarStyles = `
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: #e5e9f1;
  }
  &::-webkit-scrollbar-thumb:vertical,
  &::-webkit-scrollbar-thumb:horizontal {
    background-color: #adb4c2;
  }
`

export const StyledErrorMessage = styled('p')<{ isEditing?: boolean }>`
  margin: 4px 0 0;
  font-size: 12px;
  letter-spacing: 0.03333em;
  line-height: 1.66;
  color: #af4645;
  background-color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.background.default : '#fff'};

  &.is-absolute {
    position: absolute;
    left: 0;
    margin: 0;
    width: 100%;
  }
`
