import React from 'react'
import {
  Autocomplete,
  IconButton,
  Typography,
  TypographyProps,
  FormControlLabel,
  Box,
  Tab,
  Button,
  styled as styledMUI,
  BoxProps,
  TabProps,
  FormControlLabelProps,
  RadioGroup,
  Radio,
  RadioProps,
} from '@mui/material'
import styled from 'styled-components'

export * from './mui'
export * from './common'

export const StyledBox = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ width }) => width || '469px'};

  @media (max-width: 767px) {
    max-width: 100%;
  }
`

export const StyledPercentageCell = styled(Box)`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

interface StyledBoxProps extends BoxProps {
  padding?: string
  shadow?: boolean
  $isMobile?: boolean
}

export const StyledShadowBox = styled<React.FC<StyledBoxProps>>(Box)`
  box-shadow: ${({ shadow }) => (shadow ? '' : '-5px 0px 15px rgba(53, 108, 151, 0.12)')};
  border-radius: 10px;
  padding: ${({ $isMobile, padding }) => ($isMobile ? '20px' : padding ? padding : '24px 90px')};
  background-color: #fff;
  z-index: 1;
  position: relative;
`

export const PageTitle = styledMUI(Typography)`
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #1a2233;
`

interface StyledTypographyProps extends TypographyProps {
  $isMobile?: boolean
  mb?: string
  width?: string
}

export const StyledPageSubTitle = styledMUI<React.FC<StyledTypographyProps>>(Typography)`
  margin-bottom: ${({ $isMobile, mb }) => (mb ? mb : $isMobile ? '8px' : '28px')};
  font-weight: ${({ $isMobile }) => ($isMobile ? '500' : '')};
`

export const StyledPageLabel = styledMUI(Typography)`
  margin-bottom: 16px;
`

export const StyledParagraph = styledMUI<React.FC<StyledTypographyProps>>(Typography)`
margin-bottom: ${({ mb }) => (mb ? mb : '24px')};
margin-right: 5px;
width: ${({ width }) => width};
`

interface StyledTempParagraphProps extends BoxProps {
  $isMobile?: boolean
  width?: string
}

export const StyledTempParagraph = styled<React.FC<StyledTempParagraphProps>>(Box)`
  display: flex;
  flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : 'row')};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 24px;
  margin-right: 5px;
  width: ${({ width }) => width};
  color: #8f929b;
`

export const StyledTemperatureSpan = styledMUI('span')`
  display: inline-block;
  color:  ${({ theme }) => theme.palette.primary.main};
`

interface StyledTitleProps extends TypographyProps {
  color?: string
  mb?: string
  fz?: number
}

export const StyledTitle = styledMUI<React.FC<StyledTitleProps>>(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: ${({ theme, fz }) =>
    fz ? theme.typography.pxToRem(fz) : theme.typography.pxToRem(32)};
  color: ${({ theme, color }) => (color ? color : theme.palette.text.primary)};
  margin-bottom: ${({ mb }) => mb || ''};
  line-height: 37px;
`

/* styled Button components start */
export interface ButtonBoxProps extends BoxProps {
  $isMobile?: boolean
  width?: string
  $alignSelf?: string
  $position?: string
  padding?: string
  mt?: string
  mb?: string
  $left?: string
  $top?: string
  $isCancelManualTestBtnHidden?: boolean
}

export const ButtonBox = styledMUI<React.FC<ButtonBoxProps>>(Box)`
  display: ${({ $isCancelManualTestBtnHidden }) => ($isCancelManualTestBtnHidden ? '' : 'flex')};
  flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : '')};
  align-items: center;
  justify-content: space-evenly;
  width: ${({ width }) => width};
  margin-top: ${({ mt }) => mt};
  margin-bottom: ${({ mb }) => mb};
`

export const StyledEditIconButton = styledMUI<React.FC<ButtonBoxProps>>(Box)`
  position: ${({ $position }) => $position};
  top:  ${({ $top }) => $top || '0'};
  left: ${({ $left }) => $left || '-40px'};
  cursor: pointer;
  margin-top: ${({ mt }) => mt};
`

export const StyledButton = styledMUI(IconButton)`
  position: absolute;
  top: 6px;
  left: 14px;
  width: 24px;
  height: 24px;
  z-index: 100;
  padding: 18px;
`

export const TryButton = styledMUI(Button)`
  height: 48px;
  width: 205px;
  &:focus {
    background-color: #2776c4;
  }
  &:hover {
    background-color: #113e84;
  }
  &:active {
    background-color: #66baef;
  }
`

export const FailedButton = styledMUI(Button)`
  height: 48px;
  background-color: #FCE4E4;
  color: #AF4645;


  &:focus {
    background-color: #ffffff;
    border: 1px solid #AF4645;
  }
  &:hover {
    background-color: #F48F8F;
    color: #ffffff;
  }
  &:active {
    border: 1px solid #AF4645;
  }
`

export const PassedButton = styledMUI(Button)`
  height: 48px;
  width: 205px;
  background-color:  #E2F5EA;
  color: #18954B;


  &:focus {
    background-color: #ffffff;
    border: 1px solid #18954B;
  }
  &:hover {
    background-color: #45C67B;
    color: #ffffff;
  }
  &:active {
    border: 1px solid #18954B;
  }
`
/* styled Button components end */

export const PageHeaderWrapper = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  position: relative;
  margin-left: ${({ $isMobile }) => ($isMobile ? '' : 'auto')};
  width: ${({ $isMobile }) => $isMobile && '100%'};
  justify-content: space-between;
`

interface FlexWrapperProps {
  mb?: string
  $direction?: 'row' | 'column'
  $isMobile?: boolean
  $tasks?: boolean
  $padding?: boolean
  $withoutPadding?: boolean
}

export const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  align-items: ${({ $isMobile, $tasks }) => ($isMobile && $tasks ? 'center' : '')};
  margin-bottom: ${({ mb }) => mb || ''};
  flex-direction: ${({ $direction }) => $direction || ''};

  position: ${({ $isMobile, $tasks }) => ($isMobile && $tasks ? 'sticky' : 'relative')};
  top: ${({ $isMobile }) => ($isMobile ? '10px' : '')};
  z-index: 300;
  padding: ${({ $isMobile, $padding, $withoutPadding }) =>
    $withoutPadding ? '3px' : $padding ? '17px 0 16px 0' : $isMobile ? '3px' : '0'};
  background: ${({ theme }) => theme.palette.background.default};
`

/* styled Tab components start */
interface StyledFlexBoxProps extends TypographyProps {
  $isMobile?: boolean
  $sticky?: boolean
  $top?: string
  $withoutPadding?: boolean
  $padding?: string
  $isFilterView?: boolean
}

export const StyledFlexBox = styledMUI<React.FC<StyledFlexBoxProps>>(Box)`
  position: ${({ $sticky, $isMobile }) => ($sticky && $isMobile ? 'sticky' : 'relative')};
  top: ${({ $top }) => ($top ? $top : '59px')} ;
  z-index: 200;
  display: ${({ $isMobile, $isFilterView }) => ($isMobile && !$isFilterView ? 'flex' : '')};
  align-items: ${({ $isMobile, $isFilterView }) => ($isMobile && !$isFilterView ? 'center' : '')};
  justify-content: ${({ $isMobile, $isFilterView }) =>
    $isMobile && !$isFilterView ? 'space-between' : ''};

   padding: ${({ $isMobile, $withoutPadding, $padding }) =>
     $padding ? $padding : $withoutPadding ? '0' : $isMobile ? '13px 0' : '27px 0'};
  background: ${({ theme }) => theme.palette.background.default};
`

interface StyledTabProps extends TabProps {
  $tasks?: boolean
  $isMobile?: boolean
}

export const StyledTab = styledMUI<React.FC<StyledTabProps>>(Tab)`
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  min-height: 50px;

  &:not(:last-child) {
    margin-right: ${({ $tasks, $isMobile }) => ($tasks && $isMobile ? '20px' : '40px')} ;
  }

  & .MuiTabs-flexContainer {
    align-items: center;
  }

  & > .MuiTab-iconWrapper {
    margin-left: 16px;
  }

  & .MuiBadge-root {

    background-color: ${({ theme }) => theme.palette.warning.main};
   
    font-size: 12px;
    
    height: 20px;
    border-radius: 10px;
    margin: 0 0 0 10px;

    .MuiBadge-badge {
      font-weight: 700;
      height: 100%;
      width: 100%;
      position: relative;
      top: unset;
      right: unset;
      transform: unset;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`

interface StyledTabBoxReportsProps {
  width?: string
  mr?: string
}

export const StyledTabBox = styled.div<StyledTabBoxReportsProps>`
  width: ${({ width }) => width || 'fit-content'};
  margin-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.additional.stroke.main};

  & .MuiButtonBase-root {
    &:not(:last-child) {
      margin-right: ${({ mr }) => mr || '40px'};
    }
  }
`
/* styled form components end */

/* styled form components start */
export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

interface StyledLabelProps extends FormControlLabelProps {
  mb?: string
  color?: string
  $feedbackForm?: boolean
  width?: string
}

export const StyledLabel = styledMUI<React.FC<StyledLabelProps>>(FormControlLabel)`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: 0;
  width: ${({ width }) => width};
  margin-bottom: ${({ mb }) => (mb ? mb : '43px')};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: ${({ theme, color }) => color || theme.palette.text.primary};

  & .MuiFormControlLabel-label {
    font-weight: 400;
    margin-bottom: 5px;
  }

  & .MuiFormHelperText-root {
    margin-top: 4px;
    margin-left: 0;
    margin-right: 0;
  }

  &::after {
    content: 'Required';
    position: absolute;
    top: 0;
    right: 0;
    display: ${({ $feedbackForm }) => ($feedbackForm ? 'inline-block' : 'none')}
  }
  
  @media (max-width: 767px) {
    & .MuiFormControlLabel-label {
      font-size: ${({ theme }) => theme.typography.pxToRem(16)};
     }
     
    &::after {
      font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    }
  }
`

export const StyledAutocomplete = styledMUI(Autocomplete)`
  width: 320px;

  & .MuiOutlinedInput-root {
    padding-top: 5px;
    padding-left: 48px;
    height: 48px;
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.text.subtitles.main};
  }
  & fieldset {
    border: 1px solid ${({ theme }) => theme.additional.stroke.main};
  }
`
/* styled form components end */

/* styled Modal components start */
export const StyledModalTitle = styledMUI(Typography)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(8)};
  color: ${({ theme }) => theme.palette.text.primary};
  
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 1;
  }
`

export const StyledModalSubtitle = styled.div<{
  subtitleLikeTitle?: boolean
  withoutMarginBelow?: boolean
  mb?: string
}>`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
  margin: 0;
  margin-bottom: ${({ theme, withoutMarginBelow, mb }) =>
    withoutMarginBelow ? 0 : mb ? mb : theme.typography.pxToRem(60)};
  font-size: ${({ theme, subtitleLikeTitle }) =>
    subtitleLikeTitle ? theme.typography.pxToRem(24) : theme.typography.pxToRem(16)};
  font-weight: ${({ theme, subtitleLikeTitle }) => (subtitleLikeTitle ? 700 : 400)};
  line-height: ${({ theme, subtitleLikeTitle }) =>
    !subtitleLikeTitle ? theme.typography.pxToRem(24) : ''};
  color: ${({ theme, subtitleLikeTitle }) =>
    subtitleLikeTitle ? theme.palette.text.primary : '#616d84'};
`

interface StyledModalProps extends BoxProps {
  pt?: string
  $isMobile?: boolean
  height?: string
  width?: string
  padding?: string
}

export const StyledModalContent = styled<React.FC<StyledModalProps>>(Box)`
  padding-top: ${({ pt }) => (pt ? pt : '24px')};
  padding-right: ${({ $isMobile }) => ($isMobile ? '15px' : '35px')};
  padding-left: ${({ $isMobile }) => ($isMobile ? '15px' : '35px')};
`

export const StyledModalScroll = styled<React.FC<StyledModalProps>>(Box)`
  height: ${({ height }) => height || '310px'};
  max-width: ${({ $isMobile, width }) => ($isMobile ? '' : width ? width : '470px')};
  padding: ${({ $isMobile, padding }) =>
    $isMobile ? '24px 5px' : padding ? padding : '24px 50px'};
  overflow: auto;
  width: ${({ $isMobile, width }) => ($isMobile ? 'calc(100% - 5px)' : width)};

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #e5e9f1;
    border-radius: 72px;
    margin: 24px 0;
  }

  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #adb4c2;
  }
`

/* styled Modal components end */

/* info messages */
export const InfoMessage = styled.div`
  display: flex;
  align-items: flex-start;
  color: #8f929b;
  font-weight: 500;

  & .MuiBox-root {
    display: flex;
    align-items: flex-start;
  }

  & .MuiTypography-root {
    font-weight: inherit;
  }

  img {
    object-fit: contain;
    margin-right: 8px;
    align-self: unset;
  }
`

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

/* Split lines */

export const StyledSampleSplitLine = styled.span`
  position: absolute;
  z-index: 3;
  width: 3px;
  height: 100%;
  top: -4px;
  background-color: ${({ theme }) => theme.palette.primary.main};

  &:nth-of-type(1) {
    left: 0;
  }

  &:nth-of-type(2) {
    left: 7px;
  }

  &:nth-of-type(3) {
    left: 14px;
  }

  &:nth-of-type(4) {
    left: 21px;
  }

  &:nth-of-type(5) {
    left: 28px;
  }
`

//[Radio button

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  height: 48px;

  & .MuiFormControlLabel-label {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`

interface StyledRadioProps extends RadioProps {
  padding?: string
  mr?: string
  $isHidden?: boolean
}

export const StyledRadio = styled<React.FC<StyledRadioProps>>(Radio)`
  color: #adb4c2;
  padding: ${({ padding }) => (padding ? padding : '4px')};
  margin-right: ${({ mr, $isHidden }) => !$isHidden && mr};
  width: ${({ $isHidden }) => $isHidden && 0};
  visibility: ${({ $isHidden }) => $isHidden && 'hidden'};

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & .MuiSvgIcon-root {
    font-size: 20px;
  }
`
//Radio button]
