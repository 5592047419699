import React from 'react'

import { Button } from '@components/shared'
import { MAX_TASKS_TO_ADD } from '@constants/tasks'
import { PureTooltip } from '@components/ui/PureTooltip'
import { AddTests } from '@components/shared/AddTests'
import { useAppSelector } from '@hooks/redux'
import { selectUserLaboratory } from '@state/user'

import { ExtraButtonPropTypes } from './extraButton.types'

export const ExtraButton: React.FC<ExtraButtonPropTypes> = ({
  tasks,
  sampleId,
  tasksIsFetching,
  greatestTasksAmount,
  isFetchingSamples,
  isAbleToCreateTask,
  splitStatus,
  labId,
}) => {
  const [showModal, setShowModal] = React.useState<boolean>(false)

  const { id: userLabId } = useAppSelector(selectUserLaboratory)

  const tooltipMsg =
    !isAbleToCreateTask && splitStatus && userLabId !== labId
      ? splitStatus
      : `A maximum of ${MAX_TASKS_TO_ADD} tests can be added`

  return (
    <>
      <PureTooltip
        title={tooltipMsg}
        shouldBeHidden={
          isAbleToCreateTask &&
          !(greatestTasksAmount && greatestTasksAmount >= MAX_TASKS_TO_ADD) &&
          !(tasks?.length && tasks?.length >= MAX_TASKS_TO_ADD)
        }
        titleStyle={{ width: 170 }}
      >
        <Button
          ml='-2px'
          mb='18px'
          onClick={() => setShowModal(true)}
          variant='outlined'
          disabled={
            !isAbleToCreateTask ||
            tasksIsFetching ||
            isFetchingSamples ||
            !!(greatestTasksAmount && greatestTasksAmount >= MAX_TASKS_TO_ADD) ||
            !!(tasks?.length && tasks?.length >= MAX_TASKS_TO_ADD)
          }
          startIconAdd
        >
          Add test
        </Button>
      </PureTooltip>
      {showModal && (
        <AddTests
          tasks={tasks}
          greatestTasksAmount={greatestTasksAmount}
          sampleId={sampleId}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}
