import React from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'

import { useToggle } from '@hooks/common'
import { selectUser, setIsSuccessfulUpdatedProfile, update } from '@state/user'
import { IUpdatedUser } from '@models/common/user'
import { IProfileInfoFields } from '@models/common/forms'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { EmailConfirmationSent as EmailConfirmation } from '@components/modules'

import { ActionButtons, UserInfo } from './components'

interface ProfileContentProps {
  defaultValues: IProfileInfoFields
}

export const ProfileContent: React.FC<ProfileContentProps> = ({ defaultValues }) => {
  const {
    first_name,
    last_name,
    email,
    sex,
    phone_number,
    emailSendingStatuses,
    timezone,
    timezone_autodetect,
    isSuccessfulUpdatedProfile,
  } = useAppSelector(selectUser)

  const { handleSubmit, reset } = useFormContext()

  const [isEdit, toggleEditing] = useToggle()
  const [newEmail, setNewEmail] = React.useState(email)
  const [isOpenEmailConfirmationModal, toggleEmailConfirmationModal] = useToggle()

  const dispatch = useAppDispatch()

  const submitHandler: SubmitHandler<IProfileInfoFields> = data => {
    const formData: IUpdatedUser = {}

    if (data.phoneNumber && phone_number !== `+${data.phoneNumber}`) {
      formData.phone_number = `+${data.phoneNumber}`
    }

    if (sex.key !== data.sex) formData.sex = data.sex
    if (first_name !== data.firstName) formData.first_name = data.firstName
    if (last_name !== data.lastName) formData.last_name = data.lastName
    if (email !== data.email) formData.email = data.email
    if (data.timezone && ![timezone].includes(data.timezone.timezone_name))
      formData.timezone = data.timezone?.timezone_name

    if (timezone_autodetect !== data.timezone_autodetect) {
      formData.timezone_autodetect = data.timezone_autodetect
    }

    if (Object.keys(formData).length > 0) {
      dispatch(update(formData))
      setNewEmail(formData.email || email)
    } else {
      toggleEditing()
    }
  }

  React.useEffect(() => {
    if (isSuccessfulUpdatedProfile === 'successful') {
      toggleEditing()
    }
    return () => {
      dispatch(setIsSuccessfulUpdatedProfile('idle'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessfulUpdatedProfile])

  React.useEffect(() => {
    if (!isEdit) reset(defaultValues)
  }, [isEdit, reset, defaultValues])

  React.useEffect(() => {
    if (emailSendingStatuses.firstTry) toggleEmailConfirmationModal()
  }, [emailSendingStatuses.firstTry, toggleEmailConfirmationModal])

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <UserInfo isEdit={isEdit} timezone={timezone} />
      <ActionButtons isEdit={isEdit} toggleEditing={toggleEditing} />

      {isOpenEmailConfirmationModal && (
        <EmailConfirmation onClose={toggleEmailConfirmationModal} email={newEmail} />
      )}
    </form>
  )
}
