import * as React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import { Box, FormControl, Typography } from '@mui/material'

import { Input as InputDropdown } from '@components/shared/sampleFields'
import { StyledDetailsLabel } from '@styles'
import { useFetchConcentrationType } from '@hooks/queries'
import { StyledMenuItem } from '@components/shared/sampleFields/Units/styles'
import { REQUIRED_FIELD } from '@constants/validation'
import { CONCENTRATION, CONCENTRATION_TYPE } from '@constants/hrSamples'
import { SUBSTANCES } from '@constants/tests'

import { SubstancesItemGroupPropTypes } from '../substancesRecognized.types'
import { DropdownContainer, StyledDropdown, DropdownInputContainer } from '../styles'

export const Concentration: React.FC<SubstancesItemGroupPropTypes> = props => {
  const { index, isNotRecognized } = props
  const {
    getValues,
    clearErrors,
    setError,
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  const { data: testConcentrationData } = useFetchConcentrationType()
  const { task, fields } = getValues()

  const dropdownRules = {
    required: !isNotRecognized ? REQUIRED_FIELD : false,
  }

  const isConcentrationTypeError = errors.fields?.substances?.[index]?.concentration_type?.message
  const inputValue = fields.substances[index]?.concentration

  const inputName = `fields.${SUBSTANCES}.${index}.${CONCENTRATION}`
  const dropdownName = `fields.${SUBSTANCES}.${index}.${CONCENTRATION_TYPE}`

  const { field } = useController({
    name: dropdownName,
    rules: dropdownRules,
    control,
    defaultValue: task?.substance_result[index]?.concentration_type,
  })
  const concentrationOptions = testConcentrationData || []

  const getValueFromObjectOrString = (val: any) => {
    let newValue = ''
    if (typeof val === 'object' && val.hasOwnProperty('key')) {
      return val.key
    }
    if (typeof val === 'string') {
      return val
    }
    setValue(dropdownName, newValue)

    return newValue
  }

  // CLEAR ALL ERRORS after toggle recognized checkbox
  React.useEffect(() => {
    if (isNotRecognized) {
      if (!!errors.fields?.substances?.[index]?.concentration?.message) {
        clearErrors(inputName)
      }
      if (!!errors.fields?.substances?.[index]?.concentration_type?.message) {
        clearErrors(dropdownName)
      }
    }
  }, [clearErrors, dropdownName, errors.fields?.substances, index, inputName, isNotRecognized])

  React.useEffect(() => {
    if (isConcentrationTypeError) setError(inputName, { message: REQUIRED_FIELD })
    else if (!isConcentrationTypeError && !!inputValue) clearErrors(inputName)
  }, [
    isConcentrationTypeError,
    errors,
    inputName,
    fields.substances,
    index,
    inputValue,
    setError,
    clearErrors,
  ])

  const rules = {
    required: !isNotRecognized ? REQUIRED_FIELD : false,

    validate: {
      numericDecimal: (v: string) => {
        return (
          isNotRecognized ||
          /^\d{1,6}$|^\d{1,6}\.\d{1,6}$/.test(v) ||
          'Maximum number of characters 6 after dot and 6 before dot'
        )
      },
    },
  }

  const handleStopEnterEAndArrows = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'e' || e.key === 'E' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault()
    }

    if (fields.substances[index].concentration.length >= 13 && e.key !== 'Backspace')
      e.preventDefault()
  }

  return (
    <Box width='100%' mr='20px' position='relative'>
      <StyledDetailsLabel color={isNotRecognized ? 'primary.light' : 'text.primary'} mb='4px'>
        Concentration
      </StyledDetailsLabel>

      <DropdownInputContainer>
        <InputDropdown
          placeholder='Type here...'
          type='number'
          defaultValue={getValues().task?.substance_result[index]?.concentration || ''}
          rules={rules}
          width='100%'
          name={`fields.substances[${index}].concentration`}
          disabled={isNotRecognized}
          onKeyDown={handleStopEnterEAndArrows}
        />
        <DropdownContainer>
          <FormControl>
            <StyledDropdown
              disabled={isNotRecognized}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '88px',
                    height: '112px',
                    marginLeft: '-12px',
                  },
                },
              }}
              variant='standard'
              sx={{ backgroundColor: 'transparent' }}
              {...field}
            >
              {concentrationOptions &&
                concentrationOptions.map(({ key }) => {
                  return (
                    <StyledMenuItem key={key} value={key}>
                      <Typography fontSize='14px' variant='body1'>
                        {key}
                      </Typography>
                    </StyledMenuItem>
                  )
                })}
            </StyledDropdown>

            {!!getValueFromObjectOrString(field.value).length && (
              <Typography
                position='absolute'
                top='30%'
                left='-20px'
                color={isNotRecognized ? 'primary.light' : 'text.primary'}
              >
                {getValueFromObjectOrString(field.value)}
              </Typography>
            )}
          </FormControl>
        </DropdownContainer>
      </DropdownInputContainer>
    </Box>
  )
}
