import React from 'react'
import { TableCell } from '@mui/material'

import { Chip, Attachment, TaskNotesField } from '@components/shared'
import { ISampleTask } from '@models/samples'
import { REPORTS, SAMPLE_TASKS } from '@constants/queries'
import { ContentType } from '@models/common/ui'
import { TRUNCATED_ATTACHMENTS_SAMPLE_DETAILS_TEXT } from '@constants/common'
import { useAppSelector } from '@hooks/redux'
import { selectUserCustomizeTableColumns, selectUserLaboratory } from '@state/user'

import { FixedSampleTaskCell, StyledCell, StyledTypography } from '../styles'
import { AssigneeDate } from './AssigneeDate'
import { TestType } from './TestType'
import { DeleteExtraTaskButton } from '../DeleteExtraTaskButton'

import { ApiList, Task, TaskAssignee, TaskResult, TaskStatus, TestingDate, TestLocation } from '.'

interface ExpandedTableCellsProps {
  contentType: ContentType
  task: ISampleTask
  idx: number
  labId?: number
  stepCounter: number
  sampleId: number
  isAbleToAddTask?: boolean
  isSamples?: boolean
  canDeleteTask: boolean
  isFetchingSamples?: boolean
}

export const ExpandedTableCells: React.FC<ExpandedTableCellsProps> = props => {
  const {
    contentType,
    task,
    idx,
    stepCounter,
    sampleId,
    isAbleToAddTask,
    canDeleteTask,
    isSamples,
    isFetchingSamples,
  } = props
  const { task_columns } = useAppSelector(selectUserCustomizeTableColumns)

  const {
    id: taskId,
    test_type,
    is_able_to_add_task_notes,
    task_notes,
    sample,
    attachments,
    main_apis,
    result,
    laboratory,
  } = task
  const {
    group: { is_able_to_view_results },
  } = useAppSelector(selectUserLaboratory)

  return (
    <>
      <TableCell sx={{ position: 'relative', marginBottom: '12px' }}>
        {canDeleteTask && (
          <DeleteExtraTaskButton
            isFetchingSamples={isFetchingSamples}
            sampleId={sampleId}
            taskId={String(taskId)}
            laboratoryId={laboratory.id}
            testName={test_type.name}
          />
        )}
        <Task
          task={task}
          stepCounter={stepCounter}
          idx={idx}
          isSamples={isSamples}
          isAbleToAddTask={isAbleToAddTask}
          contentType={contentType}
        />
      </TableCell>

      {test_type.is_other && (
        <StyledCell>
          <StyledTypography>
            {`Another ${test_type.group.name} test has been added for this Sample: ${test_type.name}. Test results can be found in 'Sample notes' field`}
          </StyledTypography>
        </StyledCell>
      )}

      {contentType === REPORTS ? (
        <>
          <TableCell>
            <TaskStatus task={task} />
          </TableCell>

          <TableCell>
            <TaskResult task={task} contentType={contentType} />
          </TableCell>
          <TableCell>
            <ApiList task={task} />
          </TableCell>
          <TableCell>
            <TestType taskTestTypeName={test_type.name} isOtherTestType={test_type.is_other} />
          </TableCell>
          <TableCell>
            <TestLocation task={task} />
          </TableCell>
          <TableCell sx={{ marginRight: '45px' }}>
            <TaskNotesField
              isAbleToAddTaskNotes={is_able_to_add_task_notes}
              notes={task_notes}
              sampleId={sample}
              taskId={taskId}
              contentType={SAMPLE_TASKS}
              width='204px'
              isOtherTestType={test_type.is_other}
            />
          </TableCell>
          <TableCell>
            <TestingDate task={task} />
          </TableCell>
          <TableCell>
            {!!attachments.length
              ? attachments.map(attachment => (
                  <Attachment
                    key={attachment.id}
                    attachment={attachment}
                    cantDelete
                    truncatedTextLength={TRUNCATED_ATTACHMENTS_SAMPLE_DETAILS_TEXT}
                    isOtherTestType={test_type.is_other}
                  />
                ))
              : '-'}
          </TableCell>
        </>
      ) : (
        <>
          {task_columns.map(({ id, activated }) => {
            if (id === 1 && activated)
              return (
                <TableCell key={id}>
                  <TaskStatus task={task} />
                </TableCell>
              )
            if (id === 2 && activated)
              return (
                <TableCell key={id}>
                  {!is_able_to_view_results && result === null ? (
                    <>{!test_type.is_other ? <Chip label='Hidden' status='hidden' /> : null}</>
                  ) : (
                    <TaskResult task={task} contentType={contentType} />
                  )}
                </TableCell>
              )
            if (id === 3 && activated)
              return (
                <TableCell key={id}>
                  {!is_able_to_view_results && main_apis === null ? (
                    <>{!test_type.is_other ? <Chip label='Hidden' status='hidden' /> : null}</>
                  ) : (
                    <ApiList task={task} />
                  )}
                </TableCell>
              )
            if (id === 4 && activated)
              return (
                <TableCell key={id}>
                  <TestType
                    taskTestTypeName={test_type.name}
                    isOtherTestType={test_type.is_other}
                  />
                </TableCell>
              )
            if (id === 5 && activated)
              return (
                <TableCell key={id}>
                  <TaskAssignee task={task} sampleId={sampleId} />
                </TableCell>
              )
            if (id === 6 && activated)
              return (
                <TableCell key={id}>
                  <AssigneeDate task={task} />
                </TableCell>
              )
            if (id === 7 && activated)
              return (
                <TableCell sx={{ marginRight: '45px' }} key={id}>
                  {!!attachments.length
                    ? attachments.map(attachment => (
                        <Attachment
                          key={attachment.id}
                          attachment={attachment}
                          cantDelete
                          truncatedTextLength={TRUNCATED_ATTACHMENTS_SAMPLE_DETAILS_TEXT}
                        />
                      ))
                    : '-'}
                </TableCell>
              )
            if (id === 8 && activated)
              return (
                <TableCell sx={{ marginRight: '45px' }} key={id}>
                  <TaskNotesField
                    isAbleToAddTaskNotes={is_able_to_add_task_notes}
                    notes={task_notes}
                    sampleId={sample}
                    taskId={taskId}
                    contentType={SAMPLE_TASKS}
                    isOtherTestType={test_type.is_other}
                  />
                </TableCell>
              )
            return <React.Fragment key={id}></React.Fragment>
          })}
        </>
      )}
      <TableCell />

      <FixedSampleTaskCell
        contentType={contentType}
        isRowWithOtherTest={test_type.is_other}
        sx={{
          borderBottom: 'none',
          boxShadow: '-5px 9px 20px -5px rgba(53, 108, 151, 0.12)',
        }}
      />
    </>
  )
}
