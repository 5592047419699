import axios from 'axios'
import { Store } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

import { resetAppState } from '@state/app'
import { HttpErrors } from '@enums/httpErrors'
import { CSRF_TOKEN } from '@constants/common'
import { getLoginUrlForTenantPages, setHasOtherActiveSession } from '@state/user'
import { UrlManager } from '@helpers/url'

const { apiUrl } = new UrlManager()

export const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  config => {
    const csrfToken = Cookies.get(CSRF_TOKEN)

    if (csrfToken) config.headers = { ...config.headers, 'X-CSRFToken': csrfToken }
    else config.headers = { ...config.headers }
    return config
  },
  error => Promise.reject(error)
)

const forbiddenMessage = 'Authentication credentials were not provided.'
const otherActiveSessionMessage =
  'Cannot login due to active session in another website. Please log out there and proceed with logging in here.'

export const setupInterceptorsWithStore = (store: Store) => {
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        const { status, data } = error.response
        switch (status) {
          case HttpErrors.validation:
            return Promise.reject({
              message: Object.values(data).flat().join('\n'),
              errorObject: data,
              status,
            })
          case HttpErrors.unauthorized:
            store.dispatch(resetAppState())
            return Promise.reject({ message: 'Unauthorized', status })
          case HttpErrors.notFound:
            return Promise.reject({ message: Object.values(data).toString(), status })
          case HttpErrors.timeout:
            return Promise.reject({ message: 'Request timeout', status })
          case HttpErrors.internalError:
            return Promise.reject({ message: 'Internal server error', status })
          case HttpErrors.forbidden:
            if (
              forbiddenMessage === Object.values(data).toString() &&
              !error.config.skipForbiddenError
            ) {
              const url = getLoginUrlForTenantPages()

              url && window.open(url, '_self')
            }
            if (otherActiveSessionMessage === Object.values(data).toString()) {
              store.dispatch(setHasOtherActiveSession(true))
            } else {
              return Promise.reject({ message: Object.values(data).toString(), status })
            }
            return
          default:
            return Promise.reject({ message: 'Something went wrong', status })
        }
      }

      return Promise.reject({ message: 'Something went wrong' })
    }
  )
}
