import { useQueryParams } from 'use-query-params'
import React from 'react'
import { useLocation } from 'react-router-dom'

import {
  clearAllFilterStringParamsQueries,
  DELIVERY_STATUS,
  EXPIRATION_DATE,
  filterStringParamsQueries,
  LABORATORIES_FILTER,
  LAST_UPDATES,
  REQUEST_STATUS,
  SAMPLE_STATUS,
  TESTING_DATE,
  USER_STATUS,
  COLLECTION_DATE,
  INITIAL_LABORATORY_FILTER,
} from '@components/shared/FilterAside/constants'
import { IStringOption } from '@models/filters'
import { PHYSICAL_FORM, TEST_TYPES } from '@constants/queries'
import { TENANT_TYPE } from '@constants/tenants'
import { protectedRoutes } from '@configs/routes.config'

import { SetParamsOfFilterProps } from './filterAside.types'

export const SetParamsOfFilter: React.FC<SetParamsOfFilterProps> = props => {
  const {
    packageContentType,
    isPageHasSavedFilters,
    appliedApisSelectedFilters,
    appliedExcipientsSelectedFilters,
    appliedTaskStatusSelectedFilters,
    appliedTaskResultSelectedFilters,
    appliedAssigneesSelectedFilters,
    appliedDrugClassesSelectedFilters,
    appliedManufacturersSelectedFilters,
    appliedRequestStatusSelectedFilters,
    appliedLaboratoriesSelectedFilters,
    appliedTestingDateSelectedFilters,
    appliedCollectionDateSelectedFilters,
    appliedDeliveryStatusSelectedFilters,
    appliedLastUpdatesSelectedFilters,
    appliedExpirationDateSelectedFilters,
    appliedUserStatusSelectedFilters,
    appliedSampleStatusSelectedFilters,
    appliedTestTypeSelectedFilters,
    appliedPhysicalFormSelectedFilters,
  } = props

  const [, setQueryString] = useQueryParams({
    ...filterStringParamsQueries,
  })

  const mapFunction = (filterData: IStringOption[]): string => {
    return filterData.map(item => item.id).join(',')
  }
  const location = useLocation()

  const checkIsSamplePageOnScreenr =
    TENANT_TYPE.streetDrugs &&
    appliedLaboratoriesSelectedFilters &&
    !!appliedLaboratoriesSelectedFilters.length &&
    protectedRoutes.samples.path === location.pathname

  //for new filter check [params for searching] file constants.ts
  React.useEffect(() => {
    if (isPageHasSavedFilters) {
      if (appliedApisSelectedFilters) {
        !!appliedApisSelectedFilters.length
          ? setQueryString({ apis: mapFunction(appliedApisSelectedFilters) })
          : setQueryString({ apis: undefined })
      }

      if (appliedExcipientsSelectedFilters) {
        !!appliedExcipientsSelectedFilters.length
          ? setQueryString({ excipients: mapFunction(appliedExcipientsSelectedFilters) })
          : setQueryString({ excipients: undefined })
      }

      if (appliedTaskStatusSelectedFilters) {
        !!appliedTaskStatusSelectedFilters.length
          ? setQueryString({ taskStatus: mapFunction(appliedTaskStatusSelectedFilters) })
          : setQueryString({ taskStatus: undefined })
      }

      if (appliedTaskResultSelectedFilters) {
        !!appliedTaskResultSelectedFilters.length
          ? setQueryString({ taskResult: mapFunction(appliedTaskResultSelectedFilters) })
          : setQueryString({ taskResult: undefined })
      }

      if (appliedAssigneesSelectedFilters) {
        !!appliedAssigneesSelectedFilters.length
          ? setQueryString({ assignees: mapFunction(appliedAssigneesSelectedFilters) })
          : setQueryString({ assignees: undefined })
      }

      if (appliedDrugClassesSelectedFilters) {
        !!appliedDrugClassesSelectedFilters.length
          ? setQueryString({
              drug_classes: mapFunction(appliedDrugClassesSelectedFilters) || undefined,
            })
          : setQueryString({ drug_classes: undefined })
      }

      if (appliedManufacturersSelectedFilters) {
        !!appliedManufacturersSelectedFilters.length
          ? setQueryString({
              manufacturers: mapFunction(appliedManufacturersSelectedFilters),
            })
          : setQueryString({ manufacturers: undefined })
      }

      if (appliedRequestStatusSelectedFilters) {
        !!appliedRequestStatusSelectedFilters.length
          ? setQueryString({
              [REQUEST_STATUS]: mapFunction(appliedRequestStatusSelectedFilters),
            })
          : setQueryString({ [REQUEST_STATUS]: undefined })
      }

      if (appliedLaboratoriesSelectedFilters) {
        if (checkIsSamplePageOnScreenr) {
          setQueryString({
            [INITIAL_LABORATORY_FILTER]: mapFunction(appliedLaboratoriesSelectedFilters),
          })
        } else setQueryString({ [INITIAL_LABORATORY_FILTER]: undefined })

        if (appliedLaboratoriesSelectedFilters.length && !checkIsSamplePageOnScreenr) {
          setQueryString({
            [LABORATORIES_FILTER]: mapFunction(appliedLaboratoriesSelectedFilters),
          })
        } else setQueryString({ [LABORATORIES_FILTER]: undefined })
      }

      if (appliedTestingDateSelectedFilters) {
        !!appliedTestingDateSelectedFilters.length
          ? setQueryString({
              [TESTING_DATE]: mapFunction(appliedTestingDateSelectedFilters),
            })
          : setQueryString({ [TESTING_DATE]: undefined })
      }

      if (appliedCollectionDateSelectedFilters) {
        !!appliedCollectionDateSelectedFilters.length
          ? setQueryString({
              [COLLECTION_DATE]: mapFunction(appliedCollectionDateSelectedFilters),
            })
          : setQueryString({ [COLLECTION_DATE]: undefined })
      }

      if (packageContentType !== 'default' && appliedDeliveryStatusSelectedFilters) {
        !!appliedDeliveryStatusSelectedFilters.length
          ? setQueryString({
              [DELIVERY_STATUS]: mapFunction(appliedDeliveryStatusSelectedFilters),
            })
          : setQueryString({ [DELIVERY_STATUS]: undefined })
      }

      if (appliedLastUpdatesSelectedFilters) {
        !!appliedLastUpdatesSelectedFilters.length
          ? setQueryString({
              [LAST_UPDATES]: mapFunction(appliedLastUpdatesSelectedFilters),
            })
          : setQueryString({ [LAST_UPDATES]: undefined })
      }

      if (appliedExpirationDateSelectedFilters) {
        !!appliedExpirationDateSelectedFilters.length
          ? setQueryString({
              [EXPIRATION_DATE]: mapFunction(appliedExpirationDateSelectedFilters),
            })
          : setQueryString({ [EXPIRATION_DATE]: undefined })
      }

      if (appliedUserStatusSelectedFilters) {
        !!appliedUserStatusSelectedFilters.length
          ? setQueryString({
              [USER_STATUS]: mapFunction(appliedUserStatusSelectedFilters),
            })
          : setQueryString({ [USER_STATUS]: undefined })
      }

      if (appliedSampleStatusSelectedFilters) {
        !!appliedSampleStatusSelectedFilters.length
          ? setQueryString({ [SAMPLE_STATUS]: mapFunction(appliedSampleStatusSelectedFilters) })
          : setQueryString({ [SAMPLE_STATUS]: undefined })
      }

      if (appliedTestTypeSelectedFilters) {
        !!appliedTestTypeSelectedFilters.length
          ? setQueryString({ [TEST_TYPES]: mapFunction(appliedTestTypeSelectedFilters) })
          : setQueryString({ [TEST_TYPES]: undefined })
      }
      if (appliedPhysicalFormSelectedFilters) {
        !!appliedPhysicalFormSelectedFilters.length
          ? setQueryString({ [PHYSICAL_FORM]: mapFunction(appliedPhysicalFormSelectedFilters) })
          : setQueryString({ [PHYSICAL_FORM]: undefined })
      }
    } else {
      setQueryString(clearAllFilterStringParamsQueries)
    }
  }, [
    appliedApisSelectedFilters,
    appliedAssigneesSelectedFilters,
    appliedDeliveryStatusSelectedFilters,
    appliedDrugClassesSelectedFilters,
    appliedExcipientsSelectedFilters,
    appliedExpirationDateSelectedFilters,
    appliedLaboratoriesSelectedFilters,
    appliedLastUpdatesSelectedFilters,
    appliedManufacturersSelectedFilters,
    appliedRequestStatusSelectedFilters,
    appliedSampleStatusSelectedFilters,
    appliedTaskResultSelectedFilters,
    appliedTaskStatusSelectedFilters,
    appliedTestingDateSelectedFilters,
    appliedCollectionDateSelectedFilters,
    appliedUserStatusSelectedFilters,
    appliedTestTypeSelectedFilters,
    isPageHasSavedFilters,
    packageContentType,
    setQueryString,
    appliedPhysicalFormSelectedFilters,
    location.pathname,
    checkIsSamplePageOnScreenr,
  ])
  return null
}
