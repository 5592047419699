import React from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { useToggle } from '@hooks/common'
import { DETAILS } from '@constants/common'
import { SplitSample } from '@components/modules'
import { protectedRoutes } from '@configs/routes.config'
import { Button, ConfirmationModal, ParagraphLink, Tooltip } from '@components/shared'

import { ActionCellProps } from './actionCell.types'
import { FixedTableCell } from '../styles'

export const ActionCell: React.FC<ActionCellProps> = React.memo(props => {
  const { sample, isLastSample, isSampleSelected, isFetchingSamples, isLoadingSamples } = props
  const history = useHistory()

  const [sampleDataIsLoading, setSampleDataIsLoading] = React.useState<boolean>(false)
  const [isRetestModalOpen, toggleRetestModalOpen] = useToggle()
  const [isSplitModalOpen, toggleSplitModalOpen] = useToggle()

  // Retest logic for future
  // const makeRetestM = useMakeRetest()

  const retestHandler = () => {
    // makeRetestM.mutate(sample.id, {
    //   onSuccess: () => {
    //     client.invalidateQueries([SAMPLE_EXTRA_TASK_BUTTON, sample.id])
    //   },
    // })
  }

  const sampleDetailsEndPoint = () => {
    history.replace({
      pathname: `${protectedRoutes.samples.path}/${sample.id}${DETAILS}`,
      state: [
        {
          path: protectedRoutes.samples.path,
          url: protectedRoutes.samples.path,
          title: 'Sample list',
        },
      ],
    })
  }

  const isDisableRetestStatusBtn =
    (!!sample.split_status &&
      sample.retest_status !== 'complete' &&
      sample.retest_status !== 'retest') ||
    // makeRetestM.isLoading ||
    isFetchingSamples ||
    isLoadingSamples

  const shouldDisableButton =
    !!sample.split_status || sample.icon === 'pack' || isFetchingSamples || isLoadingSamples
  // || makeRetestM.isLoading

  const renderTooltipText = React.useMemo(() => {
    if (sample.retest_status === 'retest') return 'I agree with retest at ND'
    else if (sample.retest_status === 'retested') return 'You agreed to retest at ND'
    else return sample.split_status
  }, [sample.retest_status, sample.split_status])

  return (
    <FixedTableCell id='not-expand' isLastSample={isLastSample} isSampleSelected={isSampleSelected}>
      <Box className='hide-shadow' />
      {!sample.retest_status && (
        <Tooltip
          isPureTooltip
          title={
            sample.split_status ||
            (sample.icon === 'pack' &&
              "Roanoke's request for the HPLC task has been approved. Don't forget to send this sample.")
          }
          maxWidth={210}
          positionLeft={10}
          positionTop={0}
          shouldBeHidden={!sample.split_status || (!sample.split_status && sample.icon !== 'pack')}
        >
          <Box sx={{ marginRight: '24px' }}>
            <Button
              variant={!shouldDisableButton ? 'outlined' : 'contained'}
              width='90px'
              padding='0'
              fz={15}
              onClick={toggleSplitModalOpen}
              disabled={shouldDisableButton}
              loading={sampleDataIsLoading}
            >
              Split
            </Button>
          </Box>
        </Tooltip>
      )}

      {sample.retest_status && (
        <Tooltip
          isPureTooltip
          title={<>{renderTooltipText}</>}
          shouldBeHidden={sample.retest_status === 'complete'}
        >
          <Box sx={{ marginRight: '24px' }}>
            <Button
              variant={!isDisableRetestStatusBtn ? 'outlined' : 'contained'}
              width='90px'
              padding='0'
              fz={15}
              onClick={sample.retest_status === 'complete' ? retestHandler : toggleRetestModalOpen}
              disabled={isDisableRetestStatusBtn}
            >
              {sample.retest_status === 'complete'
                ? 'Complete'
                : sample.retest_status === 'completed'
                ? 'Completed'
                : 'Retest'}
            </Button>
          </Box>
        </Tooltip>
      )}

      {isSplitModalOpen && (
        <SplitSample
          units={sample.units}
          sampleId={sample.id}
          setSampleDataIsLoading={(val: boolean) => setSampleDataIsLoading(val)}
          onClose={toggleSplitModalOpen}
        />
      )}

      {isRetestModalOpen && (
        <ConfirmationModal
          title='Do you want the sample to be retested in Lieberman?'
          confirmButtonText='Retest'
          onConfirm={retestHandler}
          onClose={toggleRetestModalOpen}
        />
      )}

      <ParagraphLink onClick={sampleDetailsEndPoint}>View details</ParagraphLink>
    </FixedTableCell>
  )
})
