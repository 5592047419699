import React from 'react'

import { ISamples } from '@models/samples'
import { Checkbox } from '@components/shared'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { MAX_SELECTED_SAMPLE_NUMBER } from '@constants/samples'
import { StyledTableSampleCell } from '@styles'
import { selectTestReports, selectTestReportsState } from '@state/testReports'

interface ReportsTogglerProps {
  reports: ISamples
}

export const ReportsToggler: React.FC<ReportsTogglerProps> = ({ reports }) => {
  const { selectedTestReports } = useAppSelector(selectTestReportsState)
  const dispatch = useAppDispatch()

  const selectedSamplesLength = selectedTestReports.length

  const needsToBeShownIndeterminateIcon =
    selectedSamplesLength > 0 &&
    (selectedSamplesLength < MAX_SELECTED_SAMPLE_NUMBER || selectedSamplesLength < reports.length)

  const needsToBeShownCheckedIcon =
    selectedSamplesLength > 0 &&
    (selectedSamplesLength === MAX_SELECTED_SAMPLE_NUMBER ||
      selectedSamplesLength === reports.length)

  const checkedAllSamplesHandler = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked)
      dispatch(selectTestReports(reports.slice(0, MAX_SELECTED_SAMPLE_NUMBER).map(s => s.id)))
    else dispatch(selectTestReports([]))
  }

  return (
    <StyledTableSampleCell>
      <Checkbox
        padding='0'
        indeterminate={!needsToBeShownCheckedIcon && needsToBeShownIndeterminateIcon}
        checked={needsToBeShownCheckedIcon}
        onChange={checkedAllSamplesHandler}
      />
    </StyledTableSampleCell>
  )
}
