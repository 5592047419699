// * [Delivery statuses

export const NOT_SENT = 'not_sent'
export const IN_PROGRESS = 'in_progress'
export const PICKED_UP = 'picked_up'
export const UNPACKED = 'unpacked'
export const CANCELED = 'canceled'
export const WAS_SPLIT = 'Was split'

// * Delivery statuses]

// * [View list of packages constants

export const FREE_SAMPLES = 'free_samples'

// * View list of packages constants]

// * [View list of packages constants

export const MAX_SELECTED_PACKAGE_NUMBER = 200

// * View list of packages constants]

// * [Send packages

export const DESTINATION = 'destination'
export const EXPECTED_DATE = 'expected_date'

// * Send packages]

// * [Qr

export const GENERATE_PACKAGE_QR = 'generatePackageQr'
export const DOWNLOAD_PACKAGE_QR = 'downloadPackageQr'

// * Qr]

export const DESIRED_PACKAGE = 'desired_package'
