import React from 'react'

import { ColumnHeaders } from './components'
import { StyledTableRow } from '../../styles'
import { StyledTableHead, StyledTableHeadWrapper } from './styles'
import { AddNewSample } from '../../../AddNewSample'

interface TableHeadProps {
  isSticky: boolean
}

export const TableHead = React.forwardRef<HTMLDivElement, TableHeadProps>(({ isSticky }, ref) => {
  return (
    <StyledTableHeadWrapper ref={ref} isSticky={isSticky}>
      <StyledTableHead>
        <StyledTableRow>
          <ColumnHeaders hideBorder={isSticky} />
        </StyledTableRow>
      </StyledTableHead>
      <AddNewSample />
    </StyledTableHeadWrapper>
  )
})
