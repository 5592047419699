import { BoxProps, MuiStyledOptions } from '@mui/system'
import { StyledConfig } from 'styled-components'
import { Box, styled as styledMUI } from '@mui/material'

const dropZoneConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'isLoadingFailed':
        return false

      default:
        return true
    }
  },
}

interface DropZoneBoxProps extends BoxProps {
  isLoadingFailed?: boolean
  isActive?: boolean
  disabled?: boolean
  isMobile?: boolean
  height?: string
}

export const DropZoneBox = styledMUI<React.FC<DropZoneBoxProps>>(Box, dropZoneConfig)`
  width: 100%;
  cursor:${({ disabled }) => (!disabled ? 'pointer' : '')};

  .fileDrop {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;

    background: ${({ theme, isActive, isLoadingFailed, isMobile }) =>
      isLoadingFailed && isMobile
        ? '#FCE4E4'
        : isActive
        ? theme.palette.primary.main
        : theme.palette.background.default} ;
    border: ${({ isLoadingFailed }) =>
      isLoadingFailed ? '1px dashed #af4645' : '1px dashed #adb4c2'};
    color: #8f929b;

    border-radius: 10px;
    text-align: center;
    padding: ${({ isMobile }) => (isMobile ? '43px 46px' : '7% 5% 4%')} ; ;
    min-height: ${({ isMobile, height }) => (isMobile ? '' : height ? height : '254px')};

    .MuiTypography-root {
      font: inherit;
    }

    sub.MuiTypography-root {
      font-size: 0.75rem;
      line-height: 1.2;
      font-weight: 400;
    }

    .MuiTypography-root.dropzone-text {
      margin: 12px 0 8px;
    }

    .MuiCardMedia-root {
      margin: 0 auto;
    }
  }

  .dropzone-link {
    text-decoration: underline;
    cursor: pointer;
  }

  .dropzone-note {
    display: inline-block;
    font-size: 0.875rem;
    color: #8f929b;
    margin-top: 8px;
  }

  .MuiIcon-root {
  }

  .dropzone-error {
    display: inline-block;
    font-size: 0.875rem;

    color: #af4645;
    margin-top: 6px;
  }
`
