import { styled, Box, BoxProps } from '@mui/material'
import { StyledConfig } from 'styled-components'
import { MuiStyledOptions } from '@mui/system'
import React, { MutableRefObject } from 'react'

interface TableHeaderWrapProps extends BoxProps {
  isSticky: boolean | MutableRefObject<HTMLDivElement | null>
}
interface TableCellProps extends BoxProps {
  $width: number
  isLastSample?: boolean
}

const TableCellConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'isLastSample':
        return false

      default:
        return true
    }
  },
}
const TableHeaderWrapConfig: (StyledConfig<any> & MuiStyledOptions) | undefined = {
  shouldForwardProp: props => {
    switch (props) {
      case 'isSticky':
        return false

      default:
        return true
    }
  },
}

export const TableHeaderWrap = styled<React.FC<TableHeaderWrapProps>>(Box, TableHeaderWrapConfig)`
  position: sticky;
  overflow: auto;
  top: 60px;
  margin-top: 65px;
  z-index: 150;
  overflow-x: hidden;

  box-shadow: ${({ isSticky }) => (!isSticky ? 'none' : '0px 6px 15px rgba(53, 108, 151, 0.12)')};
  transition: box-shadow 500ms linear;
  background-color: #fff;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const TableHeader = styled(Box)`
  display: flex;
  background: #fff;
`

interface TableHeaderItemProps extends BoxProps {
  $width: number
}

export const TableHeaderItem = styled<React.FC<TableHeaderItemProps>>(Box)`
  display: flex;
  min-width: ${({ $width }) => `${$width}px`};
  box-sizing: border-box;
  align-items: center;
  font-weight: 700;
  line-height: 19px;
  border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};

  &:first-child {
    padding-left: 24px;
    border-radius: 10px 0 0 10px;
  }

  &:last-child {
    position: sticky;
    right: 0;
    padding: 15px 0 15px 32px;
    border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};
    background-color: ${({ theme }) => theme.palette.common.white};
    z-index: 100;
    box-shadow: -15px 0 15px -5px rgba(53, 108, 151, 0.12);
    border-top-left-radius: 10px;
  }
`

export const FirstTableCell = styled(Box)`
  min-width: 60px;
  padding: 28px 36px 0 24px;
  box-sizing: border-box;
  border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};
  font-weight: 700;
`

export const TableCell = styled(Box)`
  padding: 16px 36px 16px 0;
  box-sizing: border-box;
  border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};
`

export const FixedTableCell = styled<React.FC<TableCellProps>>(Box, TableCellConfig)`
  position: sticky;
  display: flex;
  align-items: flex-start;
  right: 0;
  box-sizing: border-box;
  min-width: ${({ $width }) => `${$width}px`};
  background-color: ${({ theme }) => theme.palette.common.white};
  cursor: auto;
  z-index: 100;
  box-shadow: -15px 0 15px -5px rgba(53, 108, 151, 0.12);
  padding: 16px 24px 16px 32px;
  border-color: transparent;

  &:first-child {
    border-top-left-radius: 10px;
    border-radius: 10px 0px 0px 10px;
  }

  border-bottom-left-radius: ${({ isLastSample }) => (isLastSample ? '10px' : '0')};
  border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};

  &::before {
    content: '';
    display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent transparent ${({ theme }) => theme.palette.background.default}
      transparent;
  }

  &::after {
    content: '';
    display: ${({ isLastSample }) => (isLastSample ? 'block' : 'none')};
    position: absolute;
    bottom: 0.8px;
    right: 0.6px;
    width: 15px;
    height: 15px;
    border-radius: 60%;
    background-color: #ffffff;
  }
`

export const AddNewSampleRow = styled(Box)`
  display: grid;
  grid-template-columns: [button]7514px [action]200px;
  background: #fff;
`
