import { styled, Box, Typography } from '@mui/material'

export const FixedTableCell = styled(Box)`
  position: sticky;
  display: flex;
  align-items: center;
  right: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  cursor: auto;
  z-index: 100;
  box-shadow: -15px 0 15px -5px rgba(53, 108, 151, 0.12);

  border-bottom: 4px solid ${({ theme }) => theme.palette.background.default};

  &:first-child {
    border-top-left-radius: 10px;
    border-radius: 10px 0px 0px 10px;
  }
`

export const StyledIdx = styled(Typography)`
  padding-top: 8px;
  font-weight: bold;
`

export const StyledIconDelete = styled('img')`
  width: 24px;
  height: 24px;
`

export const StyledCounter = styled('div')`
  position: absolute;
  top: 24px;
`
