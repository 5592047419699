import React from 'react'

import { ISample } from '@models/samples'

import { EditSampleDetails } from './EditSampleDetails'
import { ViewSampleDetails } from './ViewSampleDetails'

interface SampleInfoProps {
  sample?: ISample
  isLoading: boolean
  isEditing: boolean
  toggleEditing: () => void
}

export const SampleInfo: React.FC<SampleInfoProps> = ({
  isEditing,
  toggleEditing,
  sample,
  isLoading,
}) => {
  return (
    <>
      {isEditing && sample ? (
        <EditSampleDetails sample={sample} toggleEditing={toggleEditing} />
      ) : (
        sample && <ViewSampleDetails sample={sample} isLoading={isLoading} />
      )}
    </>
  )
}
