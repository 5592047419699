import { Box, BoxProps, Typography, styled } from '@mui/material'

interface StyledWidgetsProps extends BoxProps {
  $isOpenWidgets?: boolean
  $tests?: boolean
  $isLaptop?: boolean
  $main?: boolean
}

export const StyledWidgetsRow = styled<React.FC<StyledWidgetsProps>>(Box)`
  padding: 33px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  border-radius: ${({ $isOpenWidgets }) => ($isOpenWidgets ? '10px 10px 0 0' : '10px')};
`

export const StyledWidgetsRowBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledWidgetsInfo = styled<React.FC<StyledWidgetsProps>>(Box)`
  display: flex;
  padding: 0 24px 44px 24px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  overflow: scroll;
`

export const MainBox = styled<React.FC<StyledWidgetsProps>>(Box)`
  padding: 20px 20px 10px 20px;
  border: 1px solid #e1e7f3;
  border-radius: 10px;

  &:not(:last-child) {
    margin-right: 30px;
  }

  & .recharts-wrapper {
    margin-left: ${({ $isLaptop }) => ($isLaptop ? '-30px' : '')} !important;
  }

  & .recharts-legend-wrapper {
    top: ${({ $tests }) => ($tests ? '45px' : '62px')} !important;
    left: 225px !important;
  }

  & .recharts-legend-wrapper .recharts-default-legend .recharts-legend-item {
    margin-bottom: 14px;
  }
`

export const ContentBox = styled(Box)`
  &:first-child {
    margin-top: 27px;
  }
`

export const MainText = styled(Typography)`
  margin-bottom: 20px;
  margin-top: 30px;
  font-weight: 600;
  font-size: 32px;
`

export const SubTextBox = styled(Box)`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 33px;
  }
`
