import React from 'react'
import { Typography } from '@mui/material'

interface TextFieldProps {
  data?: string
}

export const TextField: React.FC<TextFieldProps> = ({ data }) => {
  return (
    <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
      {data || '-'}
    </Typography>
  )
}
