import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Image, Paragraph, StyledBox } from '@styles'
import success from '@assets/images/success.svg'
import error from '@assets/images/error.svg'
import { Modal } from '@components/shared'

import { FeedbackLink } from '../styles'

interface NotificationModalProps {
  title?: string
  description?: React.ReactNode
  btnText?: string
  onBtnClick?: React.MouseEventHandler<HTMLButtonElement>
  variant: 'error' | 'success'
  onClose: () => void
}

export const NotificationModal: React.FC<NotificationModalProps> = props => {
  const { variant, onClose } = props
  const isMobile = useMediaQuery('(max-width:767px)')
  const isSuccessModal = variant === 'success'

  const title = isSuccessModal ? 'Email successfully sent' : 'Email has not been sending'
  const description = isSuccessModal
    ? ' Thank you for taking the time to complete our recent feedback form. '
    : ' Sorry, something went wrong with sending form.'

  const contactDescription = isSuccessModal
    ? 'Still have questions or suggestions?'
    : 'Do you want to try again?'

  return (
    <Modal padding='64px 139px' open onClose={onClose}>
      <StyledBox width='372px'>
        <Image
          src={variant === 'success' ? success : error}
          width='80px'
          height='80px'
          mb='16px'
          component='img'
        />

        <Paragraph
          variant={isMobile ? 'subtitle1' : 'h3'}
          color='#0F1934'
          align='center'
          mb={'8px'}
        >
          {title}
        </Paragraph>

        <Paragraph color='#495C82' align='center' sx={{ lineHeight: '24px', marginBottom: '48px' }}>
          {description}
        </Paragraph>

        <Paragraph color='#495C82' align='center' sx={{ lineHeight: '24px' }}>
          {contactDescription}{' '}
          <FeedbackLink href='mailto:verify.usercare@artifactsofresearch.io'>
            Contact us
          </FeedbackLink>
        </Paragraph>
      </StyledBox>
    </Modal>
  )
}
