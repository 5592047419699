import React from 'react'
import { Box, Typography } from '@mui/material'

import { ISample } from '@models/samples'
import { Tooltip } from '@components/shared'
import { TooltipMobile } from '@components/shared/Tooltip/components'
import { stringSlice } from '@helpers/ui/common'
import { TRUNCATED_TEXT_LENGTH_S } from '@constants/common'
import { StyledTableSampleCell, SubText } from '@styles'

interface ManufacturerProps {
  sample: ISample
  isMobile?: boolean
}

export const Manufacturer: React.FC<ManufacturerProps> = ({ sample, isMobile }) => {
  const { manufacturer, brand } = sample

  return (
    <StyledTableSampleCell $isMobile={isMobile}>
      {isMobile ? (
        <Box sx={{ width: '100%' }}>
          <TooltipMobile
            title={manufacturer?.name || ''}
            shouldBeHidden={
              ((manufacturer && manufacturer?.name.length) || 0) <= TRUNCATED_TEXT_LENGTH_S
            }
          >
            <Typography variant='body1'>
              {manufacturer ? stringSlice(manufacturer?.name, TRUNCATED_TEXT_LENGTH_S) : '-'}
            </Typography>
          </TooltipMobile>
        </Box>
      ) : (
        <Box>
          <Tooltip
            title={manufacturer?.name || ''}
            shouldBeHidden={
              ((manufacturer && manufacturer.name.length) || 0) <= TRUNCATED_TEXT_LENGTH_S
            }
          >
            <Typography>
              {manufacturer ? stringSlice(manufacturer.name, TRUNCATED_TEXT_LENGTH_S) : '-'}
            </Typography>
          </Tooltip>

          <Tooltip
            title={brand?.name || ''}
            shouldBeHidden={((brand && brand.name.length) || 0) <= TRUNCATED_TEXT_LENGTH_S}
          >
            <SubText>{brand ? stringSlice(brand.name, TRUNCATED_TEXT_LENGTH_S) : '-'}</SubText>
          </Tooltip>
        </Box>
      )}
    </StyledTableSampleCell>
  )
}
