import React from 'react'

const createRootElement = (id: string) => {
  const rootContainer = document.createElement('div')
  rootContainer.setAttribute('id', id)

  return rootContainer
}

export const usePortal = (id: string) => {
  const rootElemRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const existingParent = document.querySelector(`#${id}`)
    const parentElem = existingParent || createRootElement(id)

    if (!existingParent) document.body.append(parentElem)
    if (rootElemRef.current) parentElem.append(rootElemRef.current)

    return () => {
      rootElemRef.current?.remove()
      if (!parentElem.childElementCount) parentElem.remove()
    }
  }, [id])

  return (rootElemRef.current = document.createElement('div'))
}
