import { isBefore, isFuture, isPast, isToday } from 'date-fns'

import { MAIN_APIS_REQUIRED, REQUIRED_FIELD } from './validation'

export const formFieldErrors = {
  maxValueIsFifty: {
    type: 'max',
    message: 'Max 50 characters',
  },
  requiredField: {
    type: 'required',
    message: REQUIRED_FIELD,
  },
  requiredMainApis: {
    type: 'required',
    message: MAIN_APIS_REQUIRED,
  },
}

export const formFieldValidate = {
  maxLengthIsThirteen: {
    value: 13,
    message: 'Max 13 characters',
  },
  minValueIsGreaterThanZero: {
    value: 0,
    message: `The value cannot be negative`,
  },
  earlierThanExpirationDate: (manDate: Date, expDate: Date) => {
    return (
      !manDate ||
      isBefore(manDate, expDate) ||
      'Manufacture date must be earlier than the expiration date'
    )
  },
  cantBeInFuture: (v: Date, msg: string) => !v || isPast(v) || msg,
  cantBeInPast: (v: Date, msg: string) => !v || isToday(v) || isFuture(v) || msg,
  digit: (v: string) => {
    return !isNaN(Number(v)) || 'The value must be a digit'
  },
  dosageIntFormat: (v: string) => {
    return /^\d{1,6}$/.test(v) || 'Maximum number of characters 6 after dot and 6 before dot'
  },
  dosageFloatFormat: (v: string) => {
    return /^\d{1,6}\.\d{1,6}$/.test(v) || 'Format must be either 1 or 1.01'
  },
}
