import React from 'react'
import { List, ListItem, Typography } from '@mui/material'

import { IBlockchainHistory } from '@models/common/app'
import { Tooltip } from '@components/shared'
import { datePatterns } from '@constants/dates'
import { formatDate } from '@helpers/dates'

import { ListItemHeader, ListItemBody, StyledColoredParagraph, StyledLink } from '../styles'
import { BlockchainActionBox } from './BlockchainActionBox'

interface RenderBlockchainHistoryProps {
  blockchainHistory?: IBlockchainHistory
  timezone?: string | null
}

export const RenderBlockchainHistory: React.FC<RenderBlockchainHistoryProps> = props => {
  const { blockchainHistory, timezone } = props
  const getTransaction = (statusKey: string): boolean | undefined => {
    return statusKey === 'failed' || statusKey === 'undefined'
  }

  const getAction = (action: string): string => {
    return action.replace(/@[*]+/, '@')
  }

  return (
    <List>
      {blockchainHistory?.map(
        ({
          id,
          action: uncutAction,
          entities,
          user,
          created,
          location,
          link,
          status,
          status_tooltip,
        }) => {
          return (
            <ListItem key={id}>
              <ListItemHeader>
                <Typography variant='subtitle1' sx={{ fontWeight: '700' }}>
                  {`${user.first_name} ${user.last_name}`}:
                </Typography>
                <StyledColoredParagraph>
                  {formatDate(created, datePatterns.blockchain, timezone)}
                </StyledColoredParagraph>
              </ListItemHeader>

              <ListItemBody>
                {/* Location */}
                <Typography variant='body1'>Location</Typography>
                <StyledColoredParagraph>{location.name}</StyledColoredParagraph>

                {/* Action */}
                <Typography variant='body1'>Action</Typography>
                <BlockchainActionBox entities={entities} action={getAction(uncutAction)} />

                {/* Link */}
                <Typography variant='body1'>Link</Typography>
                <Tooltip title={status_tooltip || ''} shouldBeHidden={!getTransaction(status.key)}>
                  <StyledLink
                    disabled={getTransaction(status.key)}
                    href={link || ''}
                    onClick={e => getTransaction(status.key) && e.preventDefault()}
                    target='_blank'
                    variant='body2'
                    sx={{ width: '158px' }}
                  >
                    Blockchain confirmation
                  </StyledLink>
                </Tooltip>
              </ListItemBody>
            </ListItem>
          )
        }
      )}
    </List>
  )
}
