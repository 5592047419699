import { http } from '@services/http'
import {
  AddressesTypes,
  IPlaceOption,
  PlaceForSaveType,
} from '@components/shared/sampleFields/GMPlaceAutocomplete'
import { IOption } from '@models/common/app'
import { INewHrSample2Send } from '@components/harmModules/HrCreateViewEditSample/hrCreateViewEditSample.types'
import { IUpdatedHrSample, IUpdatedHrSampleNotes } from '@models/samples'

export const fetchPhysicalForms = () => {
  try {
    return http.get<IOption[]>('/physical_forms/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchDosageTypes = () => {
  try {
    return http.get<IOption[]>('/dosage_types/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addOtherPhysicalForm = async (data: { name: string }) => {
  try {
    return http.post<IOption, { name: string }>('/physical_forms/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchCollectionLocation = () => {
  try {
    return http.get<AddressesTypes[]>('/collection_locations/')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addNewCollectionLocation = async ({ description, place_id }: PlaceForSaveType) => {
  try {
    return http.post<IPlaceOption, PlaceForSaveType>('/collection_locations/', {
      description,
      place_id,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createHrSample = async (data: INewHrSample2Send) => {
  try {
    return http.post('/samples/', data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const editHrSample = async ({ id, sample }: IUpdatedHrSample | IUpdatedHrSampleNotes) => {
  try {
    return http.patch(`/samples/${id}/`, { ...sample })
  } catch (error) {
    return Promise.reject(error)
  }
}
