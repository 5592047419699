import React, { SetStateAction } from 'react'
import { Typography, Box, useMediaQuery } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import logoVerify from '@assets/icons/logo-white-verify.svg'
import logoScreenr from '@assets/icons/logo-white-screenr.svg'
import maintenanceImg from '@assets/images/maintenance.svg'
import { StyledForm, StyledLabel } from '@styles'
import { Button, Input } from '@components/shared'
import { validationSchemas } from '@services/validation'
import { IEmailField } from '@models/common/forms'
import { subscribeToMailing } from '@api/user'
import { setMaintenanceModeUserEmail } from '@state/user'
import { useTenant } from '@hooks/common/useTenant'
import { TENANT_TYPE } from '@constants/tenants'

import { MaintenanceTitle, MaintenanceSubTitle, Image, MaintenanceModeBox, Logo } from '../styles'

interface GeneralPageProps {
  setEmail?: React.Dispatch<SetStateAction<IEmailField>>
}

export const GeneralPage: React.FC<GeneralPageProps> = ({ setEmail }) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const dispatch = useDispatch()
  const { isTenantType } = useTenant()
  const isLegalDrugs = isTenantType(TENANT_TYPE.legalDrugs)

  const formMethods = useForm<IEmailField>({
    resolver: yupResolver(validationSchemas.email),
    mode: 'onSubmit',
  })
  const { handleSubmit, formState, register } = formMethods
  const { errors } = formState

  const setEmailHandler = (email: IEmailField) => {
    if (!!email.email) {
      subscribeToMailing(email)
      dispatch(setMaintenanceModeUserEmail(email))
    }
  }

  return (
    <MaintenanceModeBox $isMobile={isMobile}>
      <Logo src={isLegalDrugs ? logoVerify : logoScreenr} alt='logo' width='150px' height='45px' />
      <MaintenanceTitle $isMobile={isMobile}>Maintenance mode</MaintenanceTitle>
      <MaintenanceSubTitle $isMobile={isMobile}>
        We are currently working on something awesome. Stay tuned!
      </MaintenanceSubTitle>

      <Box
        display={isMobile ? 'block' : 'flex'}
        width='100%'
        justifyContent='space-around'
        alignItems='center'
        ml={isMobile ? '0' : '10%'}
      >
        <Box width={isMobile ? '100%' : '50%'} mr={10}>
          <Typography mb='48px' mt='10%' textAlign={isMobile ? 'center' : 'left'}>
            Notify me when it`s ready
          </Typography>

          <StyledForm
            onSubmit={handleSubmit<IEmailField>(setEmailHandler)}
            noValidate
            style={{ maxWidth: '469px' }}
          >
            <StyledLabel
              label='Email'
              color='#ffffff'
              control={
                <Input
                  dataTestId='input-email'
                  width='100%'
                  variant='outlined'
                  type='email'
                  maintenanceModeColor
                  $textColor='#ffffff'
                  placeholder='Type here your email...'
                  error={!!errors.email?.message}
                  helperText={errors.email?.message}
                  {...register('email')}
                />
              }
            />

            <Button
              width={isMobile ? '100%' : '202px'}
              mb='10%'
              bgColor='#E1E7F3'
              textColor='#616D84'
              type='submit'
              variant='outlined'
              disabled={!!errors.email?.message}
            >
              Subscribe
            </Button>
          </StyledForm>
        </Box>
        {!isMobile && (
          <Box width='50%'>
            <Image src={maintenanceImg} alt='image' />
          </Box>
        )}
      </Box>
    </MaintenanceModeBox>
  )
}
