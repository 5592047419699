import React from 'react'
import { Box } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Typography, useMediaQuery } from '@mui/material'

import { protectedRoutes } from '@configs/routes.config'
import { setLaboratoryForWorkspace } from '@state/workSpace'
import { ParagraphLink, Tooltip } from '@components/shared'
import { StyledTooltip } from '@components/shared/Tooltip/components'
import { useTenant } from '@hooks/common/useTenant'
import { DETAILS } from '@constants/common'
import { TENANT_TYPE } from '@constants/tenants'

import { SpecificIdTypes } from './specificId.types'
import { TypographyStyled } from './styles'

export const SpecificId: React.FC<SpecificIdTypes> = props => {
  const {
    sampleId,
    specificId,
    typographyStyles,
    truncatedLength = 7,
    labelTooltip,
    laboratory,
    pathForLink,
    titleForPathLink,
    searchStateForLinkPath,
    withoutLink,
    historyLocationState,
    infoEndpoint,
  } = props

  const isSpecificId = specificId !== '-' && specificId.split('-')[1].length > 8
  const isMobile = useMediaQuery('(max-width:767px)')
  const dispatch = useDispatch()
  const history = useHistory()
  const { isTenantType } = useTenant()

  const sampleInfoEndpoint = () => {
    history.replace({
      pathname: `${protectedRoutes.samples.path}/${sampleId}${DETAILS}`,
      state: historyLocationState
        ? historyLocationState
        : [
            {
              path: pathForLink ? pathForLink : protectedRoutes.samples.path,
              url: pathForLink ? pathForLink : protectedRoutes.samples.path,
              title: titleForPathLink ? titleForPathLink : 'Sample list',
            },
          ],
    })
  }

  let tooltipWithId = React.useMemo(() => {
    if (isSpecificId) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography m='0' fontSize='13px'>
            {labelTooltip}
          </Typography>
          <Typography m='0' fontSize='13px'>
            {specificId}
          </Typography>
        </Box>
      )
    } else return <Box>{labelTooltip}</Box>
  }, [isSpecificId, labelTooltip, specificId])

  const isTooltipShouldBeHidden = !labelTooltip?.length || !specificId.length

  const updSpecificId = React.useMemo((): string => {
    if (!!specificId) {
      const id = specificId.split('-')[1]
      if (id.length > 8) {
        const idPrefix = [specificId.split('-')[0], '-', '...']
        const idArr = id.split('')
        idArr.splice(0, id.length - truncatedLength, ...idPrefix)
        return idArr.join('')
      }
      return specificId
    }
    return '-'
  }, [specificId, truncatedLength])

  const viewLaboratoryInfoEndPoint = () => {
    if (laboratory) {
      dispatch(setLaboratoryForWorkspace(laboratory))
    }

    history.replace({
      pathname: protectedRoutes.yourWorkspace.path,
      state: historyLocationState
        ? historyLocationState
        : [
            {
              path: pathForLink,
              url: pathForLink,
              search: searchStateForLinkPath || '',
              title: titleForPathLink,
            },
          ],
    })
  }

  return (
    <>
      {isMobile ? (
        <StyledTooltip
          placement='top'
          arrow
          onClick={e => e.stopPropagation()}
          enterTouchDelay={0}
          title={tooltipWithId || ''}
          sx={{ zIndex: 1500 }}
        >
          <TypographyStyled mb='8px' sx={typographyStyles}>
            {updSpecificId}
          </TypographyStyled>
        </StyledTooltip>
      ) : (
        <Tooltip
          isPureTooltip
          title={tooltipWithId || ''}
          tooltipWithLink={!!laboratory}
          onLinkHandler={viewLaboratoryInfoEndPoint}
          specificId={specificId}
          labelTooltip={`${labelTooltip}`}
          tooltipWithId={isSpecificId}
          shouldBeHidden={isTooltipShouldBeHidden}
        >
          <>
            {isTenantType(TENANT_TYPE.streetDrugs) && !withoutLink ? (
              <ParagraphLink
                sx={typographyStyles}
                id='not-expand'
                onClick={infoEndpoint || sampleInfoEndpoint}
              >
                {updSpecificId}
              </ParagraphLink>
            ) : (
              <TypographyStyled sx={typographyStyles}>{updSpecificId}</TypographyStyled>
            )}
          </>
        </Tooltip>
      )}
    </>
  )
}
